/**
 *  © 2022 -2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import { createTagList } from '../../utils/utils';
export const Unitedcompetition = (data, isAws) => {
  const sponsor = isAws
    ? data.sponsordetailinfo_s && data.sponsordetailinfo_s[0]
    : data.sponsordetailinfo_s && JSON.parse(data.sponsordetailinfo_s)[0];
  return {
    fields: {
      Image: {
        value: {
          Crops: isAws
            ? data.celumimagesvariant_s &&
              data.celumimagesvariant_s.ImageCropUrl &&
              data.celumimagesvariant_s.ImageCropUrl.CropUrl
              ? data.celumimagesvariant_s.ImageCropUrl.CropUrl
              : data.imagecropurl_s
            : data.imagecropurl_s && JSON.parse(data.imagecropurl_s),

          AltText: isAws
            ? data.celumimagesvariant_s &&
              data.celumimagesvariant_s.ImageCropUrl &&
              data.celumimagesvariant_s.ImageCropUrl.AltText
            : data.alttext_s,
          isWebpAvailable: isAws
            ? data.celumimagesvariant_s &&
              data.celumimagesvariant_s.ImageCropUrl &&
              data.celumimagesvariant_s.ImageCropUrl.isWebpAvailable
              ? data.celumimagesvariant_s.ImageCropUrl.isWebpAvailable
              : data.iswebpavailablebool_b
            : data.iswebpavailablebool_b,
        },
      },
      Teaser: {
        value: data.teaser_t ? data.teaser_t : null,
      },
      PreEntryConfirmation: {
        value: data.preentryconfirmation_t ? data.preentryconfirmation_t : null,
      },
      CompetitionType: {
        value: data.competitiontype_t ? data.competitiontype_t : null,
      },
      EnterCompetitionCTA: {
        value: data.entercompetitioncta_t ? data.entercompetitioncta_t : null,
      },
      SuccessMessage: {
        value: data.successmessage_t ? data.successmessage_t : null,
      },
      SuccessDescription: {
        value: data.detailedpagesuccessdescription_t ? data.detailedpagesuccessdescription_t : null,
      },
      FailMessage: {
        value: data.failmessage_t ? data.failmessage_t : null,
      },
      PleaseTryAgainCTA: {
        value: data.pleasetryagaincta_t ? data.pleasetryagaincta_t : null,
      },
      EntryConfirmedCTA: {
        value: data.entryconfirmedcta_t ? data.entryconfirmedcta_t : null,
      },
      CTATitleRegisteredUser: {
        value: data.ctatitleregistereduser_t ? data.ctatitleregistereduser_t : null,
      },
      CTATitleSubscribedUser: {
        value: data.ctatitlesubscribeduser_t ? data.ctatitlesubscribeduser_t : null,
      },
      EditEntryDetailsCTA: {
        value: data.editentrydetailscta_t ? data.editentrydetailscta_t : null,
      },
      ViewCompetitionDetailsCTA: {
        value: data.viewcompetitiondetailscta_t ? data.viewcompetitiondetailscta_t : null,
      },
      ViewEntryDetailsCTA: {
        value: data.viewentrydetailscta_t ? data.viewentrydetailscta_t : null,
      },
      SuccessTitle: {
        value: data.detailedpagesuccesstitle_t ? data.detailedpagesuccesstitle_t : null,
      },
      SuccessGif: {
        value: data.successgif_t ? data.successgif_t : null,
      },
      DrawerImage: {
        value: data.drawerimage_t ? data.drawerimage_t : null,
      },
      EndDate: {
        value: data.enddate_tdt ? data.enddate_tdt : null,
      },
      ClosedMessageDescription: {
        value: data.closedmessagedescription_t ? data.closedmessagedescription_t : null,
      },
      ClosedMessageTitle: {
        value: data.closedmessagetitle_t ? data.closedmessagetitle_t : null,
      },
      Icon: {
        value: data.icon_t ? data.icon_t : null,
      },

      ShortHeadline: {
        value: data.shortheadline_t,
      },
      Teaser: {
        value: data.teaser_t,
      },
      Headline: {
        value: data.headline_t,
      },
      Variant: {
        value: data.variant_t && data.variant_t.toLowerCase(),
      },
      IsLoginRequire: {
        value: data.isloginrequire_b,
      },
      ContentType: {
        value: data.contenttype_t,
      },
      CardType: {
        value: data.cardtype_t,
      },
      ContentAccess: {
        value: data.contentaccess_t ? data.contentaccess_t : null,
      },
      DestinationType: {
        value: data.destinationtype_t,
      },
      Sponsorby: sponsor
        ? {
            fields: {
              PartnerIconDark: {
                value: {
                  Crops: {
                    img1x: sponsor.PartnerIconDark,
                  },
                },
              },
              PartnerName: {
                value: sponsor.PartnerName,
              },

              CTAURL: {
                value: {
                  href: sponsor.CTAURL,
                },
              },

              MCC_MNC: {
                value: sponsor.MCC_MNC,
              },
              PartnerIconLight: {
                value: {
                  Crops: {
                    img1x: sponsor.PartnerIconLight,
                  },
                },
              },
              PartnerText: {
                value: sponsor.PartnerText,
              },
            },
          }
        : null,
      __Published: {
        value: data.appearance_tdt || data.__published_tdt,
      },
      __PublishedBy: {
        value: data.__publishedby_t,
      },
      manutds: {
        value: {
          DestinationUrl: data.destinationurl_s,
          Tags: createTagList(data.taglist_sm, data.category_s),
          Author: isAws ? data.authorname_s : data.author_t,
          Id: data.itemid_s,
        },
      },
    },
    rendering: {
      dataSource: data.itemid_s,
    },
  };
};
