/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import MutvSlider from '../MutvSlider';
import { useSponsorValue } from '../../../context/sponsorContext';
const areEqual = (prevProps, nextProps) => true;
// eslint-disable-next-line react/display-name
const Carousel = React.memo((props) => {
  const [
    { isGigyaAvilable, userLoginDetail, isUserLogin, isLoginChecked, isUserSubscribe, isUserSkyId },
    dispatch,
  ] = useSponsorValue();
  const response =
    props &&
    props.fields &&
    props.fields.MUTVScreen &&
    props.fields.MUTVScreen.value &&
    props.fields.MUTVScreen.value.Response;
  let DiscoverCarouselDetailsResponse,
    DiscoverCarouselResponse,
    LandingScreenResponse,
    OrderedCarouselResponse,
    DiscoverCarouselTitle;
  if (response) {
    DiscoverCarouselDetailsResponse = response.DiscoverCarouselDetailsResponse;
    DiscoverCarouselResponse = response.DiscoverCarouselResponse;
    LandingScreenResponse = response.LandingScreenResponse;
    OrderedCarouselResponse = response.OrderedCarouselResponse;
    DiscoverCarouselTitle =
      LandingScreenResponse &&
      LandingScreenResponse.response &&
      LandingScreenResponse.response.docs &&
      LandingScreenResponse.response.docs[0].discoverheadline_t;
  }
  return (
    <React.Fragment>
      <div className="home-row">
        {DiscoverCarouselResponse &&
          DiscoverCarouselResponse.response &&
          DiscoverCarouselResponse.response.docs &&
          DiscoverCarouselResponse.response.docs.length > 0 &&
          DiscoverCarouselResponse.response.docs.map((item, index) => (
            <MutvSlider
              key={index}
              data={item}
              cardData={DiscoverCarouselDetailsResponse.response.docs}
              isDiscover={true}
              isUserLogin={isUserLogin}
              isGigyaAvilable={isGigyaAvilable}
              userLoginDetail={userLoginDetail}
              isLoginChecked={isLoginChecked}
              dispatch={dispatch}
              DiscoverCarouselTitle={DiscoverCarouselTitle}
              isUserSubscribe={isUserSubscribe}
              isUserSkyId={isUserSkyId}
            />
          ))}
        {OrderedCarouselResponse &&
          OrderedCarouselResponse.response &&
          OrderedCarouselResponse.response.docs &&
          OrderedCarouselResponse.response.docs.length > 0 &&
          OrderedCarouselResponse.response.docs.map((item, index) => (
            <MutvSlider
              key={index}
              data={item}
              isUserLogin={isUserLogin}
              isGigyaAvilable={isGigyaAvilable}
              userLoginDetail={userLoginDetail}
              dispatch={dispatch}
              isLoginChecked={isLoginChecked}
              isUserSubscribe={isUserSubscribe}
              isUserSkyId={isUserSkyId}
            />
          ))}
      </div>
    </React.Fragment>
  );
}, areEqual);
export default Carousel;
