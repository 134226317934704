/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import { transformPrimarySecondarySponsor } from '../../utils/utils';
import ImageFallback from '../Common/ImageFallback';
import * as track from '../../utils/analytics';
import { HEADER, HEADER_PRIMARY_SPONSOR } from '../../constants/containerConstants';

const PrimarySponsor = ({
  sponsors,
  headerSponsors,
  isPowerdby,
  contentSponsorBy,
  moduleSponsorBy,
}) => {
  /**
   * Responsible for render powerdby sponsor and primary sponsor both depends upon conditions
   */
  const renderSponsor = () => {
    if (!isPowerdby) {
      return sponsors && sponsors.length > 0
        ? mapSponsorData(transformPrimarySecondarySponsor(sponsors))
        : headerSponsors && headerSponsors.length > 0 && mapSponsorData(headerSponsors);
    }
    if (isPowerdby) {
      return contentSponsorBy && contentSponsorBy.length > 0
        ? mapSponsorData(transformPrimarySecondarySponsor(contentSponsorBy))
        : moduleSponsorBy && moduleSponsorBy.length > 0 && mapSponsorData(moduleSponsorBy);
    }
  };

  /**
   *
   * @param {PartnerText to track text od sponsor analytics} PartnerText
   * @param {CTAURL to track text link} CTAURL
   * @param {clicked event object} e
   */
  const trackLink = (PartnerText, CTAURL, e) => {
    const initialData = track.data('link');
    if (!isPowerdby) {
      track.analytics(
        {
          ...initialData,
          button_name: PartnerText,
          destination_url: CTAURL,
          card_name: HEADER_PRIMARY_SPONSOR,
          container_type: HEADER,
        },
        e
      );
    } else {
      track.analytics(
        {
          ...initialData,
          button_name: PartnerText,
          destination_url: CTAURL,
        },
        e
      );
    }
  };
  /**
   *
   * @param {transformed data for rendering sponsor } sponsorItem
   */
  const mapSponsorData = (sponsorItem) => {
    return (
      sponsorItem &&
      sponsorItem.length > 0 &&
      sponsorItem.map((obj, key) => {
        if (obj) {
          const {
            PartnerIconLight,
            partnerName: PartnerName,
            partnerText: PartnerText,
            isActive: IsActive,
            CTAURL,
            CTATarget,
          } = obj;
          if (IsActive) {
            return (
              <li key={key} className={isPowerdby ? 'home-sponsor' : ''}>
                <a
                  href={CTAURL && CTAURL}
                  target={CTATarget && CTATarget}
                  onClick={(e) => trackLink(PartnerName, CTAURL, e)}
                  aria-label={isPowerdby ? 'powered by HCL Technologies' : ''}
                >
                  {isPowerdby && <span className="sponsor-txt">{PartnerText && PartnerText}</span>}
                  <ImageFallback
                    src={PartnerIconLight}
                    onErrorClass="no-img"
                    alt={PartnerName && PartnerName}
                    relativePath={false}
                  />
                </a>
              </li>
            );
          }
        }
      })
    );
  };

  return renderSponsor();
};

export default withTranslation()(React.memo(PrimarySponsor));
