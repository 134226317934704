/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
const Attacking = ({ t, item, statsType }) => {
  const buildAttackingData = (item) => {
    let graphData = {};
    if (item && item.stats && item.stats.length > 0) {
      for (let i = 0; i < item.stats.length; i++) {
        let obj = item.stats[i];
        // for tacles
        if (statsType == 'season') {
          if (obj && obj.statsKey == 'stats_GoalAssists') {
            graphData.goalAssistsKey = obj.statsKey;
            graphData.goalAssistsStatsValue = obj.statsValue;
          } else if (obj && obj.statsKey == 'stats_Assistsintentional') {
            graphData.attemptAssistsIntKey = obj.statsKey;
            graphData.attemptAssistsIntStatsValue = obj.statsValue;
          } else if (obj && obj.statsKey == 'stats_ChancesCreated') {
            graphData.keyPassesKey = obj.statsKey;
            graphData.keyPassesStatsValue = obj.statsValue;
          } else if (obj && obj.statsKey == 'stats_ThroughBalls') {
            graphData.throughBallsKey = obj.statsKey;
            graphData.throughBallsStatsValue = obj.statsValue;
          } else if (obj && obj.statsKey == 'stats_Forwardpasses') {
            graphData.forwardPassesKey = obj.statsKey;
            graphData.forwardPassesStatsValue = obj.statsValue;
          } else if (obj && obj.statsKey == 'stats_TotalTouchesInOppositionBox') {
            graphData.totalTouchesKey = obj.statsKey;
            graphData.totalTouchesStatsValue = obj.statsValue;
          }
        } else if (statsType == 'live') {
          if (obj && obj.statsKey == 'stats_GoalAssists') {
            graphData.goalAssistsKey = obj.statsKey;
            graphData.goalAssistsStatsValue = obj.statsValue;
          } else if (obj && obj.statsKey == 'stats_ChancesCreated') {
            graphData.attemptAssistsIntKey = obj.statsKey;
            graphData.attemptAssistsIntStatsValue = obj.statsValue;
          } else if (obj && obj.statsKey == 'stats_BigChancesCreated') {
            graphData.keyPassesKey = obj.statsKey;
            graphData.keyPassesStatsValue = obj.statsValue;
          } else if (obj && obj.statsKey == 'stats_SuccessfulThroughBalls') {
            graphData.throughBallsKey = obj.statsKey;
            graphData.throughBallsStatsValue = obj.statsValue;
          } else if (obj && obj.statsKey == 'stats_ThroughBalls') {
            graphData.forwardPassesKey = obj.statsKey;
            graphData.forwardPassesStatsValue = obj.statsValue;
          } else if (obj && obj.statsKey == 'stats_TotalTouchesInOppositionBox') {
            graphData.totalTouchesKey = obj.statsKey;
            graphData.totalTouchesStatsValue = obj.statsValue;
          }
        }
      }
    }
    if (
      typeof graphData.keyPassesStatsValue !== 'undefined' &&
      typeof graphData.attemptAssistsIntStatsValue != 'undefined'
    ) {
      graphData.isSingle = false;
    } else {
      graphData.isSingle = true;
    }
    return graphData;
  };
  const [graphData, setGraphData] = useState(null);
  useEffect(() => {
    setGraphData(buildAttackingData(item));
  }, [item]);
  return (
    graphData && (
      <div className="chart-wrapper">
        <h3>{t('stats_Attacking')}</h3>
        <div className="ground-area">
          <div className="stats-wrapper">
            <div className="ground-attack ">
              <div className="ground"></div>
              <div className="passes-icons" aria-hidden="true">
                <div className="forward-passes">
                  <span>{graphData.forwardPassesStatsValue}</span>
                  <i className="icon-combined-shape"></i>
                </div>
                <div className="through-balls">
                  <span>{graphData.throughBallsStatsValue}</span>
                  <i className="icon-combined-shape-green"></i>
                </div>
              </div>
              <div className="goalkeeper__area" aria-hidden="true">
                <div className="goalkeeper__area__wrapper">
                  <div className="ground halfcircle"></div>
                  <div className="notification touches">{graphData.totalTouchesStatsValue}</div>
                  <div className="innergoalkeeper__area">
                    {graphData.keyPassesStatsValue && (
                      <span className="notification assists">{graphData.keyPassesStatsValue}</span>
                    )}
                    {graphData.attemptAssistsIntStatsValue && (
                      <span className="notification chances">
                        {graphData.attemptAssistsIntStatsValue}
                      </span>
                    )}
                  </div>
                  <div className="notification bigchance">{graphData.goalAssistsStatsValue}</div>
                </div>
              </div>
            </div>
          </div>
          {statsType === 'season' && (
            <ul className="pass-notations">
              <li>
                <span className="pass-point"></span>
                <span aria-hidden="true">{t('stats_TotalTouchesInOppositionBox')}</span>
                <div className="screenreader">{`${t('stats_TotalTouchesInOppositionBox')} ${
                  graphData.totalTouchesStatsValue
                }`}</div>
              </li>
              <li>
                <span className="pass-point"></span>
                <span aria-hidden="true">{t('stats_ChancesCreated')}</span>
                <div className="screenreader">{`${t('stats_ChancesCreated')} ${
                  graphData.keyPassesStatsValue
                }`}</div>
              </li>
              <li>
                <span className="pass-point"></span>
                <span aria-hidden="true">{t('stats_Assistsintentional')}</span>
                <span className="screenreader">{`${t('stats_Assistsintentional')} ${
                  graphData.attemptAssistsIntStatsValue
                }`}</span>
              </li>
              <li>
                <span className="pass-point"></span>
                <span aria-hidden="true">{t('stats_GoalAssists')}</span>
                <div className="screenreader">{`${t('stats_GoalAssists')} ${
                  graphData.goalAssistsStatsValue
                }`}</div>
              </li>
              <li>
                <span className="pass-point"></span>
                <span aria-hidden="true">{t('stats_Forwardpasses')}</span>
                <div className="screenreader">{`${t('stats_Forwardpasses')} ${
                  graphData.forwardPassesStatsValue
                }`}</div>
              </li>
              <li>
                <span className="pass-point"></span>
                <span aria-hidden="true">{t('stats_ThroughBalls')}</span>
                <div className="screenreader">{`${t('stats_ThroughBalls')} ${
                  graphData.throughBallsStatsValue
                }`}</div>
              </li>
            </ul>
          )}
          {statsType === 'live' && (
            <ul className="pass-notations">
              <li>
                <span className="pass-point"></span>
                <span aria-hidden="true">{t('stats_TotalTouchesInOppositionBox')}</span>
                <div className="screenreader">{`${t('stats_TotalTouchesInOppositionBox')} ${
                  graphData.totalTouchesStatsValue
                }`}</div>
              </li>
              <li>
                <span className="pass-point"></span>
                <span aria-hidden="true">{t('stats_BigChancesCreated')}</span>
                <div className="screenreader">{`${t('stats_BigChancesCreated')} ${
                  graphData.keyPassesStatsValue
                }`}</div>
              </li>
              <li>
                <span className="pass-point"></span>
                <span aria-hidden="true">{t('stats_ChancesCreated')}</span>
                <span className="screenreader">{`${t('stats_ChancesCreated')} ${
                  graphData.attemptAssistsIntStatsValue
                }`}</span>
              </li>
              <li>
                <span className="pass-point"></span>
                <span aria-hidden="true">{t('stats_GoalAssists')}</span>
                <div className="screenreader">{`${t('stats_GoalAssists')} ${
                  graphData.goalAssistsStatsValue
                }`}</div>
              </li>
              <li>
                <span className="pass-point"></span>
                <span aria-hidden="true">{t('stats_ThroughBalls')}</span>
                <div className="screenreader">{`${t('stats_ThroughBalls')} ${
                  graphData.throughBallsStatsValue
                }`}</div>
              </li>
              <li>
                <span className="pass-point"></span>
                <span aria-hidden="true">{t('stats_SuccessfulThroughBalls')}</span>
                <div className="screenreader">{`${t('stats_SuccessfulThroughBalls')} ${
                  graphData.forwardPassesStatsValue
                }`}</div>
              </li>
            </ul>
          )}
        </div>
      </div>
    )
  );
};
export default withTranslation()(Attacking);
