/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
/**
 *
 * @param {humbergerLinks array for links } humbergerLinks
 * @param {all constantIds } navconstant
 */
const HumbergerLinks = ({ humbergerLinks, navconstant }) => {
  return (
    humbergerLinks &&
    humbergerLinks.map((item, i) => {
      return (
        item &&
        item.id &&
        navconstant &&
        navconstant.RegisterPageId &&
        navconstant.SigninPageId &&
        `{${item.id}}` !== navconstant.RegisterPageId.toLowerCase() &&
        `{${item.id}}` !== navconstant.SigninPageId.toLowerCase() && (
          <li className="list-item" key={i}>
            <a
              data-an-track="true"
              data-track-type="link"
              data-track-text={item && item.fields && item.fields.Name && item.fields.Name.value}
              href={
                item &&
                item.fields &&
                item.fields.TargetURL &&
                item.fields.TargetURL.value &&
                item.fields.TargetURL.value.hrefMuCustom
              }
            >
              {item && item.fields && item.fields.Name && item.fields.Name.value}
            </a>
          </li>
        )
      );
    })
  );
};

export default withTranslation()(React.memo(HumbergerLinks));
