/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import ImageGallery from '../../Cards/ImageGallery';

const HeroBannerImageGallery = (props) => {
  return (
    <div className="home-row">
      <div className="home-hero">
        <ImageGallery {...props} gridClass="grid-12" />
      </div>
    </div>
  );
};

export default HeroBannerImageGallery;
