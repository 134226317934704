/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import SignpostCard from './SignpostCard';
import { getItemId } from '../../../utils/utils';

const SignpostBlock = (props) => {
  const {
    BackgroundImage2,
    CTATitle2,
    CTAUrl2,
    Headline2,
    SubText2,
    BackgroundImage1,
    CTATitle1,
    CTAUrl1,
    Headline1,
    SubText1,
    Variant,
  } = props.fields;
  const itemId = getItemId(props);

  return (
    <div className="sign-post-row">
      <SignpostCard
        BackgroundImage={BackgroundImage1}
        CTATitle={CTATitle1}
        CTAUrl={CTAUrl1}
        Headline={Headline1}
        SubText={SubText1}
        CardVariant={Variant}
        itemId={itemId}
      />
      <SignpostCard
        BackgroundImage={BackgroundImage2}
        CTATitle={CTATitle2}
        CTAUrl={CTAUrl2}
        Headline={Headline2}
        SubText={SubText2}
        CardVariant={Variant}
        itemId={itemId}
      />
    </div>
  );
};

export default SignpostBlock;
