/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import Collection from '../../Cards/Collection';
import { HERO_COLLECTION } from '../../../constants/containerConstants';

const HeroBannerCollection = (props) => {
  return (
    <div className="home-row">
      <div className="home-hero">
        <Collection container={HERO_COLLECTION} {...props} gridClass="grid-12" />
      </div>
    </div>
  );
};

export default HeroBannerCollection;
