/**
 *  © 2022-2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import ComimgupCarousel from './ComingupCarousel';
import { ComingupProvider, useComingupValue } from '../../context/comingupCarouselContext';
import { fetchComingupData } from '../../actions/comingupCarouselAction';
import ImgCrop from '../Common/ImgCrop';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss';
import ErrorBoundary from '../Common/ErrorBoundary';

const Comingup = (props) => (
  <React.Fragment>
    <ComingupProvider>
      <ComingupContainer {...props}></ComingupContainer>
    </ComingupProvider>
  </React.Fragment>
);
export let cominUp_Response;
const ComingupContainer = (props) => {
  const [{ comingUpResponseData }, dispatch] = useComingupValue();

  const dataResponse = comingUpResponseData;
  const { t, fields } = props;
  cominUp_Response = fields;
  const heroImage = fields && fields.Image && fields.Image.value;
  const heroTitle = fields && fields.Title && fields.Title.value;

  function fetchComingupDataRemote(dispatch) {
    if (!isExperienceEditorActive()) {
      fetchComingupData(dispatch);
    }
  }

  useEffect(() => {
    fetchComingupDataRemote(dispatch);
  }, []);

  return (
    <>
      <div className="home-row">
        {isExperienceEditorActive() && (
          <div style={{ textAlign: 'left', height: '100px', width: '100%' }}>
            <b>
              Coming up Carousal Component
              <br />
            </b>
          </div>
        )}
        {Object.keys(heroImage).length !== 0 ? (
          <div className="home-hero comingupHero">
            <div className="grid-12 ratio">
              <div className="mu-content clearfix">
                <div className="mu-item comingup">
                  <div className="img-holder poll__img">
                    <ImgCrop
                      alt={heroImage.AltText}
                      title={heroImage.AltText}
                      imgCrop={heroImage.Crops}
                      isWebpAvailable={heroImage.isWebpAvailable}
                      singleSrc={false}
                      gridClass={'grid-12'}
                    />
                  </div>
                  <div className="comimgUp-section">
                    <div className="carousal">
                      <div className="container">
                        {heroTitle && (
                          <div className="card-head">
                            <h1 className="mu-item__title">{heroTitle}</h1>
                          </div>
                        )}
                        {dataResponse && (
                          <ErrorBoundary>
                            <ComimgupCarousel dataResponse={dataResponse} />
                          </ErrorBoundary>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="comimgUp-section withoutHero">
            <div className="carousal">
              <div className="container">
                {heroTitle && (
                  <div className="card-head">
                    <h1 className="mu-item__title">{heroTitle}</h1>
                  </div>
                )}
                {dataResponse && (
                  <ErrorBoundary>
                    <ComimgupCarousel dataResponse={dataResponse} />
                  </ErrorBoundary>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default withTranslation()(Comingup);
