/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useMutvListingValue } from '../../../context/mutvMyListContext';
import CarouselListing from '../Show/CarouselListing';
import NoDataFound from './NoDataFound';
import { useSponsorValue } from '../../../context/sponsorContext';
import Sticky from '../../../utils/sticky';
import { getNode, getLocalData } from '../mutvutil';
import { withTranslation } from 'react-i18next';

const Tablisting = (props) => {
  const [{ itemNumber, status }, dispatch] = useMutvListingValue();
  const [{ isLoginChecked, isUserLogin }] = useSponsorValue();
  const [isClientside, setIsClientside] = useState(false);
  const {
    t,
    allData,
    tabSelected,
    tabChange,
    loadMore,
    noDataFound,
    totalData,
    onDeleteItem,
    idsToDeletes,
    deleteAndUndoDelete,
    editAndDone,
    undoDeleteState,
    showDeleteBtn,
  } = props;
  const tabFilter = { contentList: t('RecentlyAdded'), contentHistory: t('Watching') };
  useEffect(() => {
    setIsClientside(true);
  }, []);

  const isHistoryAvalible = (filterItem) => {
    if (filterItem === 'contentHistory') {
      const localMylistData = getLocalData();
      if (
        isLoginChecked &&
        isUserLogin &&
        totalData &&
        totalData.ContentHistoryResponse &&
        totalData.ContentHistoryResponse.ContentHistory &&
        totalData.ContentHistoryResponse.ContentHistory.find((item) => !item.deletedDT)
      ) {
        return true;
      } else if (
        isLoginChecked &&
        !isUserLogin &&
        localMylistData &&
        localMylistData.ContentHistoryResponse &&
        localMylistData.ContentHistoryResponse.ContentHistory &&
        localMylistData.ContentHistoryResponse.ContentHistory.find((item) => !item.deletedDT)
      ) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  };
  const getStyle = (filterItem) => {
    if (filterItem === (tabSelected ? tabSelected : 'contentList')) {
      return 'block';
    }
    return 'none';
  };

  const checkToShowNoData = () => {
    if (tabSelected === 'contentList' && isLoginChecked) {
      return totalData ? checkConetentLength() : true;
    }
    return false;
  };

  const checkConetentLength = () => {
    const obj = getNode(totalData, tabSelected);
    if (obj) {
      const contentlength = obj.filter((item) => !item.hasOwnProperty('deletedDT'));
      if (isLoginChecked && isUserLogin) {
        return contentlength.length === 0 && status === 'fetched' ? true : false;
      } else {
        return contentlength.length === 0 ? true : false;
      }
    }
  };

  return (
    <React.Fragment>
      <div className="mylist">
        <div className="mutv-shows__filter">
          <Sticky>
            <div className="container mutv-container filters mylist">
              {isClientside && (
                <div className="mutv-shows__filter__nav item-my-lists">
                  <React.Fragment>
                    <div className="topPanel">
                      <h2 className="heading">{t('Mylist')}</h2>
                    </div>
                    <nav>
                      <div className="screenreader" aria-live="polite">
                        {tabFilter[tabSelected]}
                      </div>
                      <ul id="tabfilter">
                        {Object.keys(tabFilter).map((filterItem, index) => {
                          return (
                            isHistoryAvalible(filterItem) && (
                              <li
                                key={index}
                                filterItem={filterItem}
                                tabSelected={tabSelected}
                                className={tabSelected === filterItem ? 'active' : ''}
                                onClick={(e) => tabChange(e, filterItem, tabFilter)}
                                style={{ pointerEvents: !undoDeleteState ? 'none' : '' }}
                              >
                                <a
                                  href="#"
                                  aria-label={
                                    tabSelected === filterItem
                                      ? `${tabFilter[tabSelected]} Selected`
                                      : ''
                                  }
                                  onClick={(e) => e.preventDefault()}
                                  className="link"
                                >
                                  {tabFilter[filterItem]}
                                </a>
                              </li>
                            )
                          );
                        })}
                      </ul>
                    </nav>
                  </React.Fragment>

                  {((tabSelected === 'contentList' &&
                    allData[tabSelected] &&
                    allData[tabSelected].data.length > 0) ||
                    !undoDeleteState) && (
                    <div className="actions">
                      {showDeleteBtn && (
                        <button className="btn bdr" onClick={() => deleteAndUndoDelete()}>
                          {!undoDeleteState ? t('UndoDelete') : t('DeleteSelected')}
                        </button>
                      )}
                      <button className="btn" onClick={() => editAndDone()}>
                        {!showDeleteBtn ? t('Edit') : t('Done')}
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Sticky>
        </div>
      </div>

      <div className="container mutv-container mylist mylist-cards">
        {tabFilter &&
          Object.keys(tabFilter).map((filterItem, index) => (
            <div
              key={index}
              style={{
                display: getStyle(filterItem),
              }}
            >
              {((noDataFound && isLoginChecked && tabSelected === 'contentList') ||
                checkToShowNoData()) && <NoDataFound></NoDataFound>}

              <CarouselListing
                containerType={'containerType'}
                aspectRatio={'4x3'}
                status={allData[tabSelected].status}
                cardData={allData[tabSelected].data}
                listView={false}
                showLoadMore={allData[tabSelected].listLoader}
                loadMore={loadMore}
                showLoader={true}
                initialLoad={itemNumber}
                isContinueCard={tabSelected === 'contentHistory' ? true : false}
                showDeleteBtn={showDeleteBtn}
                onDeleteItem={onDeleteItem}
                idsToDeletes={idsToDeletes}
                mylistPage={true}
              />
            </div>
          ))}
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Tablisting);
