/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

export const MUTVContentAcessConstant = {
  REGISTERED: 'Registered',
  ACCOUNT_SUBSCRIPTION: 'Account Subscription',
};

export const MUTVContentTypeConstant = {
  SHOW: 'showcarousel',
  SHOWOFSHOWS: 'showofshows',
  EPISODE: 'episodecarousel',
  PODCAST: 'podcastlandingscreen',
};

export const MUTVSeriesTypeConstant = {
  SEASONAL: 'Seasonal',
  NONSEASONAL: 'NonSeasonal',
};

export const FREE_TO_VIEW = 'FREE_TO_VIEW';
export const REGISTERED = 'Registered';
export const ACCOUNT_SUBSCRIPTION = 'Account Subscription';
export const MAX_ITEM = 100;
export const REMAINING_TIME = 10000; //20 sec
export const ONE_MINUTE = 60;
export const TEN_SECOND = 10;
export const FIVE_SECOND = 5;
export const THIRTY_SECOND = 30;
