/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import MU from '../../constants/muConstants';
import { createDestinationUrl } from '../../utils/utils';

const TimeFilter = ({ timeFilters, t, selectedYear }) => {
  const activeTimeFilter = (filter, index) => {
    const filterName = filter ? filter.label_t : null;
    if ((MU.currentSeason.includes(selectedYear) && index === 0) || selectedYear === filterName) {
      return 'active';
    }
  };

  return (
    <div className="matches__nav bottom-filter">
      <nav aria-label={t('SeasonFilter')}>
        <ul className="matches__mainList">
          {timeFilters.doclist.docs.map((filter, index) => (
            <li key={index} className={activeTimeFilter(filter, index)}>
              <a
                className="link"
                href={createDestinationUrl(filter.destinationurl_s)}
                aria-current={activeTimeFilter(filter, index) === 'active' ? true : false}
              >
                {filter.label_t}
              </a>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default withTranslation()(TimeFilter);
