/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import Article from '../../Cards/Article';
import { HERO_ARTICLE } from '../../../constants/containerConstants';

const HeroBannerArticle = (props) => {
  return (
    <div className="home-row">
      <div className="home-hero">
        <Article container={HERO_ARTICLE} {...props} heroVarient="parent" gridClass="grid-12" />
      </div>
    </div>
  );
};

export default HeroBannerArticle;
