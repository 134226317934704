/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import Image from '../../Cards/Image';
import { SUPER_HERO_IMAGE } from '../../../constants/containerConstants';

const HeroBannerImage = (props) => {
  return (
    <div className="home-row">
      <div className="home-hero">
        <Image container={SUPER_HERO_IMAGE} {...props} gridClass="grid-12" />
      </div>
    </div>
  );
};

export default HeroBannerImage;
