/**
 *  © 2022 -2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import { buildImgUrl } from '../../../utils/utils';
import { calculatePenalty } from '../../../utils/matchUtils';
import { HomeTeam, AwayTeam } from '../../../constants/globalConstants';
import { data, analytics } from '../../../utils/analytics';
import { COMINGUPCAROUSAL, COMINGUP_RESULTS } from '../../../constants/containerConstants';

const CardResult = (props) => {
  const { item, getDateSchudle, getTimeSchudle, t } = props;
  const {
    aliascompetitionname_t,
    competitionname_t,
    ispostponed_b,
    isabandoned_b,
    matchdate_tdt,
    homePenalty,
    awayPenalty,
    resultdata_t,
    hometeamshortname_t,
    hometeam_t,
    awayteamshortname_t,
    awayteam_t,
    itemid_s,
    contenttype_t,
    taglist_sm,
    __published_tdt,
    authorname_s,
    matchdayctaurl_t,
    hometeamimage_s,
    matchdayresultctatitle_t,
    venuename_t,
    awayteamimage_s,
    _name,
  } = item;
  if (
    item.resultdata_t &&
    item.resultdata_t &&
    item.resultdata_t.AwayResult &&
    item.resultdata_t.AwayResult.PenaltyShootEntityList &&
    item.resultdata_t.AwayResult.PenaltyShootEntityList.length > 0
  ) {
    item.awayPenalty = calculatePenalty(
      item.resultdata_t.AwayResult.PenaltyShootEntityList,
      item.hometeamid_t,
      item.awayteamid_t
    );
  }
  if (
    item.resultdata_t &&
    item.resultdata_t &&
    item.resultdata_t.HomeResult &&
    item.resultdata_t.HomeResult.PenaltyShootEntityList &&
    item.resultdata_t.HomeResult.PenaltyShootEntityList.length > 0
  ) {
    item.homePenalty = calculatePenalty(
      item.resultdata_t.HomeResult.PenaltyShootEntityList,
      item.hometeamid_t,
      item.awayteamid_t
    );
  }
  const getMatchInfo = () => {
    let competitionName = aliascompetitionname_t;
    competitionName = competitionName ? competitionName : competitionname_t && competitionname_t;
    return (
      <>
        {matchdate_tdt && <p className="dateSchudle">{getDateSchudle(matchdate_tdt)}</p>}
        {competitionName && (
          <div className="subTxt">
            <p className="ellipsisTxt">
              {competitionName + ' '}
              {(homePenalty || awayPenalty) && homePenalty !== 0 && awayPenalty !== 0 && (
                <span>
                  ({t('Pens')} : {homePenalty && homePenalty}-{awayPenalty && awayPenalty})
                </span>
              )}
              {resultdata_t &&
                resultdata_t.HomeResult &&
                resultdata_t.AwayResult &&
                (resultdata_t.HomeResult.AggScore > 0 || resultdata_t.AwayResult.AggScore > 0) && (
                  <span aria-hidden="true">
                    ({t('Agg')} {resultdata_t.HomeResult.AggScore}-
                    {resultdata_t.AwayResult.AggScore})
                  </span>
                )}
            </p>
          </div>
        )}
      </>
    );
  };
  const getDateInfo = () => {
    if (isabandoned_b) {
      return <p className="timeSchudle abandoned-match">{t('Matchabandoned')}</p>;
    } else if (ispostponed_b) {
      return <p className="timeSchudle abandoned-match">{t('MatchPostponed')}</p>;
    } else {
      return (
        matchdate_tdt && (
          <p className="timeSchudle">
            {t('KickOff')} - {getTimeSchudle(matchdate_tdt)}
          </p>
        )
      );
    }
  };
  const isJsonObject = (data) => {
    try {
      JSON.parse(data);
    } catch (e) {
      return false;
    }
    return true;
  };
  const getTeamName = (teamType) => {
    if (teamType === HomeTeam) {
      return hometeamshortname_t ? hometeamshortname_t : hometeam_t ? hometeam_t : '';
    } else if (teamType === AwayTeam) {
      return awayteamshortname_t ? awayteamshortname_t : awayteam_t ? awayteam_t : '';
    }
  };
  const getTeamScore = (item, teamType) => {
    let checkJsonObj = isJsonObject(item.resultdata_t);
    let resultData;
    if (checkJsonObj) {
      resultData = item.resultdata_t && JSON.parse(item.resultdata_t);
    } else {
      resultData = item.resultdata_t;
    }
    if (teamType === HomeTeam && resultData && resultData.HomeResult) {
      return resultData.HomeResult.Score ? resultData.HomeResult.Score : 0;
    } else if (teamType === AwayTeam && resultData && resultData.AwayResult) {
      return resultData.AwayResult.Score ? resultData.AwayResult.Score : 0;
    }
  };

  const initialData = data('card');
  const triggerAnalytics = () => {
    analytics({
      ...initialData,
      card_name: competitionname_t + ': ' + hometeam_t + ' vs ' + awayteam_t,
      container_type: COMINGUPCAROUSAL,
      content_type: COMINGUP_RESULTS,
      button_name: matchdayresultctatitle_t,
      item_name: _name,
      created_datetime: __published_tdt !== undefined ? __published_tdt : 'N/A',
      destination_url: matchdayctaurl_t,
      item_id: itemid_s,
      author: authorname_s,
      tag: taglist_sm,
    });
  };

  return (
    <a
      className="card-content"
      tabIndex="-1"
      aria-hidden="true"
      style={{ outline: 'none' }}
      href={matchdayctaurl_t ? matchdayctaurl_t : 'javascript:void(0)'}
      onClick={(e) => triggerAnalytics()}
    >
      <div className="card-data matchdata card-result">
        <span className="screenreader">{contenttype_t}</span>
        <div className="leftBox">
          {getMatchInfo()}
          <div className="HomeTeam_info team_info" aria-hidden="true">
            <div className="info_logo">
              {hometeamimage_s && <img src={buildImgUrl(hometeamimage_s.img1x)} alt="" title="" />}
            </div>
            <div className="info_Txt">{getTeamName(HomeTeam)}</div>
            <div className="goal_info">{getTeamScore(item, HomeTeam)}</div>
          </div>
          <div className="AwayTeam_info team_info" aria-hidden="true">
            <div className="info_logo">
              {awayteamimage_s && <img src={buildImgUrl(awayteamimage_s.img1x)} alt="" title="" />}
            </div>
            <div className="info_Txt">{getTeamName(AwayTeam)}</div>
            <div className="goal_info">{getTeamScore(item, AwayTeam)}</div>
          </div>
        </div>
        <div className="rightBox">
          {getDateInfo()}
          {venuename_t && (
            <div className={venuename_t.length > 21 ? 'subTxt marqueeAnim' : 'subTxt'}>
              <p>{venuename_t}</p>
            </div>
          )}
          {matchdayresultctatitle_t && (
            <div className="cardBtn">{matchdayresultctatitle_t.toUpperCase()}</div>
          )}
        </div>
      </div>
    </a>
  );
};

export default withTranslation()(CardResult);
