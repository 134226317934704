/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import ImgCrop from '../Common/ImgCrop';
import * as track from '../../utils/analytics';
import {
  addSubscription,
  manageSubscription,
  isSubscribedViaCrossPlatform,
} from '../../utils/gigyaScreen';
import { getLoggedInStatus } from '../../utils/utils';
import { MYUNITED } from '../../constants/containerConstants';
import { useSponsorValue } from '../../context/sponsorContext';
import MU from '../../constants/muConstants';

const SubscribeInfo = ({ subscribeDetail }) => {
  const [
    { userLoginDetail, isUserSubscribe, isLoginChecked, isUserLogin, isUserSkyId },
  ] = useSponsorValue();
  const { CardDetails, ManageSubscription } = subscribeDetail;
  const [subscriptionDetails] = useState(
    isUserSubscribe && isSubscribedViaCrossPlatform({ userLoginDetail, ManageSubscription })
  );
  const userTitle = ({ item, isSubscriberCard }) => {
    if (isSubscriberCard && subscriptionDetails) {
      return subscriptionDetails.description;
    }
    return isUserSubscribe ? item.TitleSubscription : item.TitleNonSubscription;
  };

  const destinationURL = (item, isSubscriberCard) => {
    if (isSubscriberCard && subscriptionDetails && subscriptionDetails.destinationURL) {
      return { url: subscriptionDetails.destinationURL, target: '_blank' };
    } else if (isSubscriberCard) {
      return { url: '#', target: '_self' };
    }
    const CTAUrl = isUserSubscribe ? item.CTAUrlSubscription : item.CTAUrlNonSubscription;
    return { url: CTAUrl, target: '_self' };
  };

  const trackCard = ({ e, isSubscriberCard, destUrl, title }) => {
    let dataType = 'card';
    if (isSubscriberCard) {
      dataType = isUserSubscribe ? 'manageSubscription' : 'addSubscription';
    }
    const initialData = track.data(dataType);
    track.analytics(
      {
        ...initialData,
        card_name: title,
        destination_url: destUrl.url,
        container_type: MYUNITED,
        subscription_status: getLoggedInStatus({
          isLoginChecked,
          isUserLogin,
          isUserSubscribe,
          isUserSkyId,
        }),
      },
      e
    );
  };

  const onCardClick = ({ e, isSubscriberCard, destUrl, title }) => {
    if (isSubscriberCard) {
      const hasUrl = destUrl.url === '#';
      if (subscriptionDetails && hasUrl) {
        e.preventDefault();
      } else if (!subscriptionDetails && hasUrl) {
        if (!isUserSubscribe) {
          trackCard({ e, destUrl: { url: MU && MU.addSubscriptionUrl }, title, isSubscriberCard });
          userLoginDetail &&
            userLoginDetail.UID &&
            addSubscription({ param: userLoginDetail.UID, type: 'uid' });
        } else {
          trackCard({
            e,
            destUrl: { url: MU && MU.manageSubscriptionUrl },
            title,
            isSubscriberCard,
          });
          userLoginDetail && userLoginDetail.UID && manageSubscription(userLoginDetail.UID);
        }
      } else {
        trackCard({ e, destUrl, title, isSubscriberCard });
      }
    } else {
      trackCard({ e, destUrl, title, isSubscriberCard });
    }
  };

  const renderCard = ({ item }) => {
    const isSubscriberCard = item.CardName && item.CardName.toLowerCase() === 'subscribecard';
    const destUrl = destinationURL(item, isSubscriberCard);
    const title = userTitle({ item, isSubscriberCard });
    return (
      <div
        className="grid-6 ratio"
        onClick={(e) => {
          onCardClick({ e, isSubscriberCard, destUrl, title });
        }}
      >
        <div className="mu-content">
          <div className="mu-item article-card article">
            <a href={destUrl.url} tabIndex="-1" aria-hidden="true" target={destUrl.target}>
              <div className="img-holder">
                {item.Image && (
                  <LazyLoad once={true} offset={100}>
                    <ImgCrop imgCrop={item.Image} alt={item.Image.ImageAlt}></ImgCrop>
                  </LazyLoad>
                )}
              </div>
            </a>
            <div className="mu-item__info">
              <a className="titleCard" href={destUrl.url} target={destUrl.target}>
                {title}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="subscribe-wrapper">
        <div className="subscribe-section">
          {CardDetails && CardDetails.map((item) => item && renderCard({ item }))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(React.memo(SubscribeInfo));
