/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_SEARCH_RESULT,
  SET_SEARCH_RESULT,
  ERROR_SEARCH_RESULT,
} from '../constants/actions/actionConstant';
import { SEARCH_RESULT } from '../constants/endpoints';
import api from '../utils/muApi';

/**
 * Action for Search
 */
export const fetchSearch = (dispatch, filter, startIndex, endIndex, searchValue, searchText) => {
  const route = `${SEARCH_RESULT}result/${filter}/${startIndex}/${endIndex}/?q=${searchValue}`;
  const startTime = new Date().getTime();
  const params = { AllowMUTV: true };
  dispatch(fetchResult(startTime, searchText));
  return api
    .get({ route, params })
    .then((response) => {
      dispatch(setResult(response, startTime, searchText));
    })
    .catch((err) => {
      dispatch(error(err, searchText));
    });
};

export const fetchResult = (startTime, searchText) => ({
  type: FETCH_SEARCH_RESULT,
  startTime,
  searchText,
});

export const setResult = (response, startTime, searchText) => ({
  type: SET_SEARCH_RESULT,
  response,
  startTime,
  searchText,
});

export const error = (response, searchText) => ({
  type: ERROR_SEARCH_RESULT,
  response,
  searchText,
});
