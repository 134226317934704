/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { withTranslation } from 'react-i18next';
const Accordian = ({
  t,
  title,
  trackButton,
  headingClass,
  titleclass,
  detailclass,
  children,
  index,
  seAccordianState,
  accordianState,
  defaultOpen,
}) => {
  const [isOpen, setIsOpen] = React.useState(defaultOpen);
  const setAccordianStatus = () => {
    const openVal = isOpen;
    setIsOpen(!openVal);
    let arr = accordianState ? [...accordianState] : [];
    arr[index] = { isOpen: !openVal, index: index };
    seAccordianState(arr);
  };
  return (
    <React.Fragment>
      <div
        className={`${headingClass} ${isOpen ? 'open active' : ''}`}
        onClick={() => setAccordianStatus()}
      >
        <span className={titleclass}>{title}</span>
        <a href="javascript:void(0)" aria-label={title} aria-expanded={isOpen ? 'true' : 'false'}>
          <i className="icon-more-img"></i>
        </a>
      </div>
      <div className={`accordion-item ${detailclass} ${!isOpen ? 'collapsed' : 'notcollapsed'}`}>
        {children}
      </div>
    </React.Fragment>
  );
};

Accordian.defaultProps = {
  defaultOpen: false,
};

export default withTranslation()(React.memo(Accordian));
