/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { createContext, useContext, useReducer } from 'react';
import { formState, formReducer } from '../reducers/formReducer';

export const FormContext = createContext();

export const FormProvider = ({ children }) => (
  <FormContext.Provider value={useReducer(formReducer, formState)}>{children}</FormContext.Provider>
);
export const useFormValue = () => useContext(FormContext);

export default FormContext;
