/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import { createTagList } from '../../utils/utils';
export const Poll = (data, isAws) => {
  const sponsor = isAws
    ? data.sponsordetailinfo_s && data.sponsordetailinfo_s[0]
    : data.sponsordetailinfo_s && JSON.parse(data.sponsordetailinfo_s)[0];
  return {
    fields: {
      Image: {
        value: {
          Crops: isAws
            ? data.celumimagesvariant_s &&
              data.celumimagesvariant_s.ImageCropUrl &&
              data.celumimagesvariant_s.ImageCropUrl.CropUrl
              ? data.celumimagesvariant_s.ImageCropUrl.CropUrl
              : data.imagecropurl_s
            : data.imagecropurl_s && JSON.parse(data.imagecropurl_s),

          AltText: isAws
            ? data.celumimagesvariant_s &&
              data.celumimagesvariant_s.ImageCropUrl &&
              data.celumimagesvariant_s.ImageCropUrl.AltText
            : data.alttext_s,
          isWebpAvailable: isAws
            ? data.celumimagesvariant_s &&
              data.celumimagesvariant_s.ImageCropUrl &&
              data.celumimagesvariant_s.ImageCropUrl.isWebpAvailable
            : data.iswebpavailablebool_b,
        },
      },
      Introduction: {
        value: data.introduction_t,
      },
      Question: {
        value: data.question_t,
      },
      EndTime: {
        value: data.endtime_tdt,
      },
      Options: {
        value: data.options_sm,
      },
      EndDate: {
        value: data.enddate_tdt,
      },
      IsLoginRequire: {
        value: data.isloginrequire_b,
      },
      ContentType: {
        value: data.contenttype_t,
      },
      ContentAccess: {
        value: data.contentaccess_t ? data.contentaccess_t : null,
      },
      CardType: {
        value: data.cardtype_t,
      },
      ContentAccess: {
        value: data.contentaccess_t ? data.contentaccess_t : null,
      },
      CTATextAfterVote: {
        value: data.ctatextaftervote_t ? data.ctatextaftervote_t : null,
      },
      CTATextBeforeVote: {
        value: data.ctatextbeforevote_t ? data.ctatextbeforevote_t : null,
      },
      Headline: {
        value: data.headline_t ? data.headline_t : null,
      },
      HidePollStats: {
        value: data.hidepollstats_b ? data.hidepollstats_b : null,
      },
      FinalResultMessage: {
        value: data.finalresultmessage_t,
      },
      Sponsorby: sponsor
        ? {
            fields: {
              IsTelco: {
                value: false,
              },
              PartnerIconDark: {
                value: {
                  Crops: {
                    img1x: sponsor.PartnerIconDark,
                  },
                },
              },
              PartnerName: {
                value: sponsor.PartnerName,
              },
              ShortDescription: {
                value: '',
              },
              CTAURL: {
                value: {
                  href: sponsor.CTAURL,
                },
              },
              IsActive: {
                value: true,
              },
              MCC_MNC: {
                value: sponsor.MCC_MNC,
              },
              PartnerIconLight: {
                value: {
                  Crops: {
                    img1x: sponsor.PartnerIconLight,
                  },
                },
              },
              PartnerText: {
                value: sponsor.PartnerText,
              },
            },
          }
        : null,
      __Published: {
        value: data.appearance_tdt || data.__published_tdt,
      },
      __PublishedBy: {
        value: data.__publishedby_t,
      },
      manutds: {
        value: {
          DestinationUrl: data.destinationurl_s,
          Tags: createTagList(data.taglist_sm, data.category_s),
          Author: isAws ? data.authorname_s : data.author_t,
          pollId: data.pollid_s,
          GigyaContent: isAws
            ? data.gigyacontent_t && data.gigyacontent_t
            : data.gigyacontent_t && JSON.parse(data.gigyacontent_t),
          Options: data.options_sm,
        },
      },
    },
    rendering: {
      dataSource: data.itemid_s,
    },
  };
};
