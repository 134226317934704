/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint-disable jsx-a11y/aria-role */
import React, { useState, useEffect } from 'react';
import { useSponsorValue } from '../../../context/sponsorContext';
import { fetchAllocatedMatchTicket } from '../../../actions/muscAction';
import { MuscProvider, useMuscValue } from '../../../context/muscContext';
import { redirectToSignIn } from '../../../utils/gigyaScreen';
import Loader from '../../Common/Loader';
import loadable from '@loadable/component';

const MatchAppsOverView = loadable(() => import('./MatchAppsOverView'));
const MemberSelection = loadable(() => import('./MemberSelection'));
const TicketConfirmation = loadable(() => import('./TicketConfirmation'));
const MatchAppThankYou = loadable(() => import('./MatchAppThankYou'));

const MuscTicketApplication = (props) => (
  <MuscProvider>
    <MuscTicket {...props}></MuscTicket>
  </MuscProvider>
);
const MuscTicket = (props) => {
  const [{ isGigyaAvilable, userLoginDetail }] = useSponsorValue();
  const [
    { matchTicket, memberSelection, matchStatus, confirmTicketStatus },
    dispatch,
  ] = useMuscValue();
  const [activeSection, setActiveSection] = useState({ active: 1 });

  useEffect(() => {
    if (isGigyaAvilable) {
      if (userLoginDetail && userLoginDetail.data && userLoginDetail.data.mufcNumber) {
        fetchAllocatedMatchTicket(dispatch, userLoginDetail);
      } else {
        redirectToSignIn();
      }
    }
  }, [isGigyaAvilable, userLoginDetail]);
  const setActivePage = (index) => {
    setActiveSection({ active: index });
  };

  return (
    <div className="main-viewer">
      {matchStatus !== 'fetched' && <Loader></Loader>}
      {matchTicket && activeSection.active === 1 && matchStatus === 'fetched' && (
        <MatchAppsOverView
          matchTicket={matchTicket}
          setActivePage={setActivePage}
        ></MatchAppsOverView>
      )}
      {memberSelection && activeSection.active === 2 && (
        <MemberSelection memberSelection={memberSelection} setActivePage={setActivePage} />
      )}
      {memberSelection && activeSection.active === 3 && (
        <TicketConfirmation
          memberSelection={memberSelection}
          setActivePage={setActivePage}
          userLoginDetail={userLoginDetail}
        />
      )}
      {memberSelection && activeSection.active === 4 && (
        <MatchAppThankYou memberSelection={memberSelection} setActivePage={setActivePage} />
      )}
      {confirmTicketStatus === 'fetching' && <Loader></Loader>}
    </div>
  );
};

export default MuscTicketApplication;
