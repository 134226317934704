/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { Fragment, useEffect } from 'react';
import { useModal } from '../../Common/Modal';
import MU from '../../../constants/muConstants';
import Loader from '../../Common/Loader';
import { redirectTo } from '../../../utils/utils';
import loadable from '@loadable/component';
import ErrorBoundary from '../../Common/ErrorBoundary';
const QuizModal = loadable(() => import('../../Modals/QuizModal/QuizModal'));

const QuizDetail = (props) => {
  const { showModal, closeModal, isModalVisible } = useModal();
  const { manutds } = props.fields;
  const language = MU.language ? MU.language : '';
  const itemId = manutds && manutds.value && manutds.value.Id;
  useEffect(() => {
    showModal(manutds && manutds.value && manutds.value.DestinationUrl);
  }, []);
  return (
    <Fragment>
      {isModalVisible && (
        <ErrorBoundary>
          <QuizModal
            closeModal={closeModal}
            itemid={`{${itemId}}`}
            fallback={<Loader />}
            cardData={props.fields}
            onClose={() => redirectTo('/', language)}
            container={props.container}
            isDestinationPage={true}
          />
        </ErrorBoundary>
      )}
    </Fragment>
  );
};
export default React.memo(QuizDetail);
