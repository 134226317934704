/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import { isExperienceEditorActive, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import {
  gridArray,
  SUPPORTED_NEWS_GRID_CURATED_CARD,
  THRESHOLD,
} from '../../../constants/globalConstants';
import { newsGridClassArray, supportedContentType } from '../../../utils/utils';
import Transform from '../../../data-transform';
import { NEWS_GRID } from '../../../constants/containerConstants';
import ErrorBoundary from '../../Common/ErrorBoundary';

const NewsGridCuration = (props) => {
  const maxCard = 10;
  const [gridClasses, setGridClasses] = useState(gridArray);
  const [gridrowClass, setGridrowClass] = useState('three-rows');
  let timeoutId;

  useEffect(() => {
    setGridNRows();
  }, [props.rendering]);

  useEffect(() => {
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const resizeListener = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setGridNRows();
    }, THRESHOLD);
  };

  const setGridNRows = () => {
    const gridElms = IsCurateFromSelectCards()
      ? props &&
        props.rendering &&
        props.rendering.fields &&
        props.rendering.fields.SelectCards.length
      : props.rendering &&
        props.rendering.placeholders &&
        props.rendering.placeholders['mufc-ph-newsgrid'] &&
        props.rendering.placeholders['mufc-ph-newsgrid'].length;

    let gridType = 'tengrid';
    let gridRow = 'three-rows';
    if (gridElms && gridElms > 0) {
      if (gridElms <= 2) {
        gridType = 'twogrid';
        gridRow = 'one-rows';
      } else if (gridElms > 2 && gridElms <= 6) {
        gridType = 'sixgrid';
        gridRow = 'two-rows';
      }
    }
    setGridClasses(newsGridClassArray(gridType));
    setGridrowClass(gridRow);
  };

  const IsCurateFromSelectCards = () => {
    if (
      props.fields &&
      props.fields.CurateFromSelectCardsField &&
      props.fields.CurateFromSelectCardsField.value === true
    ) {
      return true;
    }
    return false;
  };

  const renderExpEditorMsg = () => {
    if (isExperienceEditorActive()) {
      if (IsCurateFromSelectCards()) {
        return (
          <div>
            <b>
              Cards (max 10) will appear from SelectCards field
              <br />
            </b>
          </div>
        );
      } else {
        return (
          <div>
            <b>
              PlaceHolder For News Grid :Max and Min Componentes allowed are 10
              <br />
            </b>
          </div>
        );
      }
    }
  };

  const RenderCards = () => {
    if (
      !IsCurateFromSelectCards() &&
      props.rendering &&
      props.rendering.placeholders &&
      props.rendering.placeholders['mufc-ph-newsgrid']
    ) {
      let itemIndex = -1;
      return (
        <Placeholder
          rendering={props.rendering}
          name="mufc-ph-newsgrid"
          missingComponentComponent={(component) => {
            return (
              <div className={`${component.gridClass} ratio`}>
                <div className="mu-content">
                  <div className="mu-item__info">
                    <h3>
                      Missing Card
                      {component && component.rendering ? component.rendering.componentName : ''}
                    </h3>
                  </div>
                </div>
              </div>
            );
          }}
          renderEach={(component) => {
            itemIndex++;
            const contentType =
              component.props.fields &&
              component.props.fields.ContentType &&
              component.props.fields.ContentType.value;
            if (
              contentType &&
              SUPPORTED_NEWS_GRID_CURATED_CARD.includes(contentType.toLowerCase())
            ) {
              const clonedElementWithMoreProps = React.cloneElement(component, {
                gridClass: gridClasses[itemIndex],
                container: NEWS_GRID,
                nativeCard: true,
              });
              return itemIndex <= maxCard && clonedElementWithMoreProps;
            } else {
              return null;
            }
          }}
        />
      );
    } else if (
      props.rendering &&
      props.rendering.fields &&
      props.rendering.fields.SelectCards &&
      props.rendering.fields.SelectCards.length > 0
    ) {
      let data = supportedContentType(props.rendering.fields.SelectCards, maxCard, 'curated');
      return (
        data &&
        data.map((item, i) => (
          <Transform
            container={NEWS_GRID}
            key={i}
            data={item}
            source="curate"
            gridClass={gridClasses[i]}
            nativeCard={true}
          />
        ))
      );
    }
  };
  const signpostContainer = () => {
    return (
      <div
        className={`container-signpost ${
          props &&
          props.rendering &&
          props.rendering.fields &&
          props.rendering.fields.Description &&
          props.rendering.fields.Description.value
            ? 'description'
            : ''
        }`}
      >
        <div className="news-grid-signpost-left-container">
          {props &&
            props.rendering &&
            props.rendering.fields &&
            props.rendering.fields.Title &&
            props.rendering.fields.Title.value && (
              <h2 className="news-grid-title">
                {props.rendering.fields.Title.value.substring(0, 39)}
              </h2>
            )}
          {props &&
            props.rendering &&
            props.rendering.fields &&
            props.rendering.fields.Description &&
            props.rendering.fields.Description.value && (
              <span className="news-grid-description">
                {props.rendering.fields.Description.value.substring(0, 79)}
              </span>
            )}
        </div>
        {props &&
          props.rendering &&
          props.rendering.fields &&
          props.rendering.fields.CTATitle &&
          props.rendering.fields.CTATitle.value && (
            <div className="news-grid-signpost-right-container">
              <a href={props.rendering.fields.CTAUrl.value.hrefMuCustom} role="button">
                <span>{props.rendering.fields.CTATitle.value}</span>
              </a>
            </div>
          )}
      </div>
    );
  };

  return (
    <ErrorBoundary>
      <div className="home-row">
        {renderExpEditorMsg()}
        <div className="news-grid curated">
          <div className="container main">
            {((props &&
              props.rendering &&
              props.rendering.fields &&
              props.rendering.fields.Title &&
              props.rendering.fields.Title.value) ||
              (props &&
                props.rendering &&
                props.rendering.fields &&
                props.rendering.fields.Description &&
                props.rendering.fields.Description.value) ||
              (props &&
                props.rendering &&
                props.rendering.fields &&
                props.rendering.fields.CTATitle &&
                props.rendering.fields.CTATitle.value)) &&
              signpostContainer()}
            <div className={gridrowClass}>{RenderCards()}</div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default NewsGridCuration;
