/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import * as track from '../../utils/analytics';
import { createPlayerUrl, isSmallDevice } from '../../utils/utils';
import { THRESHOLD } from '../../constants/globalConstants';

/**
 *
 * @param {dictionary data} t
 * @param {lineupPlayer of lineup} lineupPlayer
 * @param {callback from child component to parent to active matchday tag} switchTab
 *
 */

const Lineup = ({ t, lineupPlayer, switchTab }) => {
  const [touchDevice, setTouchDevice] = useState(false);
  const [activeTab, setActiveTab] = useState({ tabName: 'matchBlog' });
  let timeoutId;
  useEffect(() => {
    setTouchDevice(isSmallDevice());
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  /**
   *
   * @param {teamDetail with there subsitutes playername/time and more..} team
   * @param {loop index to make repeated li with unique key} index
   * @param {home/away team} teamName
   * @param {isSubstitutes} isSubstitutes
   */
  const drawTeam = (team, index, teamName, isSubstitutes) => {
    return (
      <li key={`${teamName}${index}`}>
        <div className="lineups__items">
          <React.Fragment>
            {teamName && lineupPlayer[teamName] && lineupPlayer[teamName].FID !== '1' && (
              <span className="player_photo icon-lineup_avtar">
                <img
                  src={createPlayerUrl(team.Player, 'image')}
                  alt=""
                  onError={(e) => onErrorImg(e)}
                  onLoad={(e) => onLoad(e)}
                  className="no-img"
                />
              </span>
            )}
            {teamName && lineupPlayer[teamName] && lineupPlayer[teamName].FID === '1' && (
              <a href={createPlayerUrl(team.Player, 'href')}>
                <span className="player_photo icon-lineup_avtar">
                  <img
                    src={createPlayerUrl(team.Player, 'image')}
                    alt=""
                    onError={(e) => onErrorImg(e)}
                    onLoad={(e) => onLoad(e)}
                  />
                  {screenReaderDom(team)}
                </span>
              </a>
            )}
            {playerDetail(team, isSubstitutes)}
          </React.Fragment>
        </div>
        {teamName &&
          lineupPlayer[teamName] &&
          lineupPlayer[teamName].FID !== '1' &&
          screenReaderDom(team)}
      </li>
    );
  };

  /**
   *
   * @param {team, that have details of subsitute with name and time...} team
   * @param {isSubstitutes} isSubstitutes
   */
  const playerDetail = (team, isSubstitutes) => {
    return (
      <React.Fragment>
        {team.time && (
          <span className="player_shuffle" aria-hidden="true" tabIndex="-1">
            <i className={isSubstitutes ? 'icon-shuffle icon-sub' : 'icon-shuffle icon-start'}></i>
            {team.time}
          </span>
        )}
        <div
          aria-hidden="true"
          tabIndex="-1"
          className={
            team && team.subPlayer && team.subPlayer.JerseyNo
              ? 'player_details'
              : 'player_details subPlayerNotAvalibale'
          }
        >
          <div className="sub_on_player">
            <span className="player_name">
              <span className="shirt_number"> {team.JerseyNumber}</span>
              {team.Player.KnownName ? team.Player.KnownName : team.Player.LastName}
            </span>
          </div>
          {team.subPlayer && team.subPlayer.JerseyNo && (
            <div className="sub_off_player">
              <span className="player_name subsitutes">
                <span className="shirt_number"> {team.subPlayer.JerseyNo}</span>
                {team.subPlayer.KnownName ? team.subPlayer.KnownName : team.subPlayer.LastName}
              </span>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };

  /**
   *
   * @param {tracking of matchday page tab} tabName
   */
  const trackButton = (tabName) => {
    const data = track.data('button');
    track.analytics({ ...data, button_name: tabName });
  };

  /**
   *
   * @param {make active of tab. tabname may be matchblog/lineup} tabName
   */
  const showTabInfo = (tabName) => {
    switchTab(tabName);
    setActiveTab({ tabName: tabName });
    trackButton(tabName === 'matchBlog' ? 'MATCH BLOG' : 'LINE UPS');
  };

  /**
   * Lineup tab dom
   */
  const lineupTab = () => {
    return (
      <ul className="matchday_tab" role="tablist" aria-label="Tabs">
        <li
          className={`matchday_tab__blog ${activeTab.tabName === 'matchBlog' ? 'active' : ''}`}
          aria-selected={activeTab.tabName === 'matchBlog' ? true : false}
          aria-controls="matchdayBlog-tab"
          role="tab"
          id="matchdayBlog"
          onClick={(e) => showTabInfo('matchBlog', e)}
        >
          MATCH BLOG
        </li>
        <li
          className={`matchday_tab__lineup ${activeTab.tabName === 'lineup' ? 'active' : ''}`}
          aria-selected={activeTab.tabName === 'lineup' ? true : false}
          aria-controls="lineup-tab"
          id="lineup"
          tabIndex="-1"
          role="tab"
          onClick={(e) => showTabInfo('lineup', e)}
        >
          LINE UPS
        </li>
      </ul>
    );
  };

  /**
   *
   * @param {accessability dom creating as per team} team
   */
  const screenReaderDom = (team) => {
    return (
      <React.Fragment>
        <span className="screenreader">
          {`${team.Player.KnownName ? team.Player.KnownName : team.Player.LastName}  ${t(
            'Shirtnumber'
          )} ${team.JerseyNumber} ${
            team.time ? ` Substituted off at ${team.time} ${t('minute')}` : ''
          }`}
        </span>
        {team.subPlayer && team.subPlayer.JerseyNo && (
          <span className="screenreader">
            {`${team.subPlayer.KnownName ? team.subPlayer.KnownName : team.subPlayer.LastName} ${t(
              'Shirtnumber'
            )} ${team.subPlayer.JerseyNo} Substituted on at ${team.time}  ${t('minute')}`}
          </span>
        )}
      </React.Fragment>
    );
  };

  /**
   *
   * @param {return class as per team type} teamType
   */
  const getClassName = (teamType) => {
    if (teamType === 'home_team') {
      return lineupPlayer && lineupPlayer.homeTeamName && lineupPlayer.homeTeamName.FID === '1'
        ? 'home_team is_manutd'
        : 'home_team';
    } else {
      return lineupPlayer && lineupPlayer.awayTeamName && lineupPlayer.awayTeamName.FID === '1'
        ? 'away_team is_manutd'
        : 'away_team';
    }
  };

  /**
   *
   * {window resizing to check the width of device and set it in state}
   */
  const resizeListener = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setTouchDevice(isSmallDevice());
    }, THRESHOLD);
  };

  const onErrorImg = (e) => {
    !e.target.className.includes('no-img') && e.target.classList.add('no-img');
  };

  const onLoad = (e) => {
    e.target.className.includes('no-img') && e.target.classList.remove('no-img');
  };

  return (
    <React.Fragment>
      <div className="matchday_tab__wrapper">{lineupTab()}</div>
      <div
        className="lineups_wrapper"
        role="region"
        aria-labelledby="Linups"
        data-tabname={activeTab.tabName}
        data-type={touchDevice}
        style={{
          display: activeTab.tabName === 'matchBlog' && touchDevice ? 'none' : 'block',
        }}
      >
        {lineupPlayer && lineupPlayer.isDataAvaliable && (
          <h2
            className={`lineup_heading ${lineupPlayer.showLineup && 'checkAlternetClass'}`}
            id="Linups"
          >
            Line ups
          </h2>
        )}
        {lineupPlayer && lineupPlayer.showLineup && (
          <React.Fragment>
            <div className="lineup_teams__wrapper" aria-describedby="Linups">
              {/*Player listing*/}
              <div className="lineup_teams">
                <div className={getClassName('home_team')}>
                  <h4 className="team_name_txt" aria-hidden="true">
                    {lineupPlayer.homeTeamName && lineupPlayer.homeTeamName.ShortClubName}
                  </h4>
                  <div className="screenreader">
                    Home Team {lineupPlayer.homeTeamName && lineupPlayer.homeTeamName.ShortClubName}
                  </div>
                  <ul>
                    {lineupPlayer.HomeTeam &&
                      lineupPlayer.HomeTeam.startPlayers &&
                      lineupPlayer.HomeTeam.startPlayers.length > 0 &&
                      lineupPlayer.HomeTeam.startPlayers.map((team, index) =>
                        drawTeam(team, index, 'homeTeamName')
                      )}
                  </ul>
                </div>
                <div className={getClassName('away_team')}>
                  <h4 className="team_name_txt" aria-hidden="true">
                    {lineupPlayer.awayTeamName && lineupPlayer.awayTeamName.ShortClubName}
                  </h4>
                  <div className="screenreader">
                    Away Team {lineupPlayer.awayTeamName && lineupPlayer.awayTeamName.ShortClubName}
                  </div>
                  <ul>
                    {lineupPlayer.AwayTeam &&
                      lineupPlayer.AwayTeam.startPlayers &&
                      lineupPlayer.AwayTeam.startPlayers.length > 0 &&
                      lineupPlayer.AwayTeam.startPlayers.map((team, index) =>
                        drawTeam(team, index, 'awayTeamName')
                      )}
                  </ul>
                </div>
              </div>
              {/*SubPlayer listing*/}
              {((lineupPlayer.HomeTeam && lineupPlayer.HomeTeam.Subs.length > 0) ||
                (lineupPlayer.AwayTeam && lineupPlayer.AwayTeam.Subs.length > 0)) && (
                <div className="lineup_subsitutes">
                  <h3 className="subsitutes">{t('Substitutes')}</h3>
                  <div className={getClassName('home_team')}>
                    <div className="screenreader">
                      Home Team
                      {lineupPlayer.homeTeamName && lineupPlayer.homeTeamName.ShortClubName}
                    </div>
                    <ul>
                      {lineupPlayer.HomeTeam &&
                        lineupPlayer.HomeTeam.Subs &&
                        lineupPlayer.HomeTeam.Subs.length > 0 &&
                        lineupPlayer.HomeTeam.Subs.map((team, index) =>
                          drawTeam(team, index, 'homeTeamName', true)
                        )}
                    </ul>
                  </div>

                  <div className={getClassName('away_team')}>
                    <div className="screenreader">
                      Away Team
                      {lineupPlayer.awayTeamName && lineupPlayer.awayTeamName.ShortClubName}
                    </div>
                    <ul>
                      {lineupPlayer.AwayTeam &&
                        lineupPlayer.AwayTeam.Subs &&
                        lineupPlayer.AwayTeam.Subs.length > 0 &&
                        lineupPlayer.AwayTeam.Subs.map((team, index) =>
                          drawTeam(team, index, 'awayTeamName', true)
                        )}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </React.Fragment>
        )}
        {lineupPlayer && !lineupPlayer.showLineup && lineupPlayer.isDataAvaliable && (
          <div className="lineup_awating">
            <h2 className="awating">{t('AwaitingLiveMatchData')}</h2>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Lineup);
