/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_MUTV_LIST,
  SET_MUTV_LIST,
  ERROR_MUTV_LIST,
} from '../constants/actions/actionConstant';
import { getFilters } from '../components/Mutv/mutvutil';

export const mutvListingState = {
  status: null,
  cardData: null,
  startListIndex: 0,
  showLoadMore: false,
  carouselDetails: null,
  filters: [],
  filterObj: null,
  seriesType: null,
  itemsNumber: 24,
  itemId: null,
};

/**
 * Reducer for mutv episode carousel listing
 */
export const mutvListingReducer = (
  state = mutvListingState,
  { type, response, actionType, activeFilter, itemid } = {}
) => {
  switch (type) {
    case FETCH_MUTV_LIST:
      return {
        ...state,
        status: 'fetching',
        showLoadMore: false,
        ...fetchData(actionType),
      };
    case SET_MUTV_LIST: {
      return {
        ...state,
        status: 'fetched',
        ...setData(response && response.data, state, actionType, activeFilter, itemid),
      };
    }
    case ERROR_MUTV_LIST: {
      return {
        ...state,
        status: 'error',
        showLoadMore: false,
      };
    }
    default:
      return state;
  }
};

const fetchData = (type) => {
  return type === 'default' ? { cardData: null } : {};
};

const setData = (response, state, actionType, activeFilter, itemid) => {
  if (response) {
    const UpNextResponse = response.UpNextResponse && response.UpNextResponse.grouped;
    const GetCarouselResponse =
      response.GetCarouselResponse &&
      response.GetCarouselResponse.response &&
      response.GetCarouselResponse.response.docs.length > 0 &&
      response.GetCarouselResponse.response.docs[0];
    let cardData = [];
    let start = 0;
    let numFound = 0;
    let showLoadMore = false;
    const sortOrder =
      GetCarouselResponse &&
      GetCarouselResponse.carouselsortorder_s &&
      GetCarouselResponse.carouselsortorder_s.toLowerCase();
    const seriesType =
      GetCarouselResponse.seriestype_s && GetCarouselResponse.seriestype_s.toLowerCase();
    let filters, filterObj;

    //set filters data
    if (state.itemId !== itemid) {
      const FacetResponse =
        response.FacetResponse &&
        response.FacetResponse.facet_counts &&
        response.FacetResponse.facet_counts.facet_fields;
      const filtersData = getFilters(seriesType, FacetResponse, sortOrder);
      filters = filtersData.filters;
      filterObj = filtersData.filterObj;
    } else {
      filters = state.filters;
      filterObj = state.filterObj;
    }

    // set Listing Data
    if (seriesType && seriesType === 'none' && UpNextResponse && UpNextResponse._language) {
      let groups = UpNextResponse._language.groups;
      if (groups && groups.length > 0 && groups[0].doclist) {
        let docList = groups[0].doclist;
        cardData = docList.docs ? docList.docs : [];
        start = docList.start;
        numFound = docList.numFound;
      }
    } else if (
      seriesType &&
      seriesType === 'nonseasonal' &&
      UpNextResponse &&
      UpNextResponse.seriesnumberunique_s
    ) {
      let groups = UpNextResponse.seriesnumberunique_s.groups;
      groups &&
        groups.map((item) => {
          if (item.groupValue === (activeFilter ? activeFilter : filters[0])) {
            cardData = item.doclist.docs;
            start = item.doclist.start;
            numFound = item.doclist.numFound;
          }
        });
    } else if (
      seriesType &&
      seriesType === 'seasonal' &&
      UpNextResponse &&
      UpNextResponse.seasonnumberunique_s
    ) {
      let groups = UpNextResponse.seasonnumberunique_s.groups;
      groups &&
        groups.map((item) => {
          if (item.groupValue === (activeFilter ? activeFilter : filters[0])) {
            cardData = item.doclist.docs;
            start = item.doclist.start;
            numFound = item.doclist.numFound;
          }
        });
    }

    cardData = actionType !== 'default' ? [...state.cardData, ...cardData] : cardData;
    showLoadMore = numFound - start > state.itemsNumber ? true : false;
    let ImageDetail =
      GetCarouselResponse &&
      GetCarouselResponse.celumimagesvariant_s &&
      GetCarouselResponse.celumimagesvariant_s.ImageDetails;
    let ImageDetails =
      ImageDetail &&
      ImageDetail.find(
        (item) => item && item.CropUrl && item.Name && item.Name.toLowerCase() === 'bannerimage'
      );

    return {
      cardData: cardData,
      startListIndex: start,
      showLoadMore: showLoadMore,
      carouselDetails: {
        aspectRatio: GetCarouselResponse.aspectratio_t,
        heading: GetCarouselResponse.heading_t,
        ImageDetails: ImageDetails,
        description_t: GetCarouselResponse.description_t,
        contenttype_t: GetCarouselResponse.contenttype_t,
        sponsordetailinfo_s: GetCarouselResponse.sponsordetailinfo_s,
        __publishedby_t: GetCarouselResponse.__publishedby_t,
        itemid_s: GetCarouselResponse.itemid_s,
        taglist_sm: GetCarouselResponse.taglist_sm,
      },
      filters: filters,
      filterObj: filterObj,
      seriesType: seriesType,
      itemId: itemid,
    };
  }
  return {};
};
