/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';

const SearchPagination = (props) => {
  const { pager, setPage } = props;

  const updateSearch = (e, type, val) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13) {
      if (type == 'prev') {
        setPage(val);
      } else if (type == 'index') {
        setPage(val);
      } else if (type == 'next') {
        setPage(val);
      }
    }
  };

  return (
    <div className="paging">
      <div className="pager">
        {pager && pager.pages && pager.pages.length > 0 && (
          <ul className="pagination">
            {/* Handling due to old ui branding css (start)   */}
            {typeof window !== 'undefined' &&
              (window.innerWidth > 767 || (window.innerWidth < 767 && pager.currentPage == 1)) && (
                <li style={{ display: 'none' }}>
                  <a className="active" />
                </li>
              )}
            {/* End */}
            {pager && pager.currentPage && pager.currentPage > 1 && (
              <li className={`${pager.currentPage === 1 ? 'disabled' : ''}`}>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setPage(pager.currentPage - 1);
                  }}
                  onKeyPress={(e) => updateSearch(e, 'prev', pager.currentPage - 1)}
                  tabIndex="0"
                  role="button"
                  aria-label="Previous page"
                  href="#"
                >
                  <i className="prev-small">{props.t('back')}</i>
                  <i className="prev-large">{props.t('prev')}</i>
                  <span className="icon-prev-page-arrow"></span>
                </a>
              </li>
            )}
            {pager &&
              pager.pages &&
              pager.pages.length > 1 &&
              pager.pages.map((page, i) => (
                <li key={`pager${i}`}>
                  <a
                    href="#"
                    className={`${pager.currentPage === page ? 'active' : ''}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setPage(page);
                    }}
                    onKeyPress={(e) => updateSearch(e, 'index', page)}
                  >
                    <span aria-hidden="true">{page}</span>
                    <span className="screenreader">{`${props.t('page')}${page}`}</span>
                  </a>
                </li>
              ))}
            {pager && pager.currentPage < pager.totalPages && (
              <li className={`${pager.currentPage === pager.totalPages ? 'disabled' : ''}`}>
                <a
                  onClick={() => setPage(pager.currentPage + 1)}
                  onKeyPress={(e) => updateSearch(e, 'next', pager.currentPage + 1)}
                  tabIndex="0"
                  role="button"
                  aria-label="Next page"
                >
                  <i className="next-small">{props.t('loadmore')}</i>
                  <i className="next-large">{props.t('Next')}</i>
                  <span className="icon-next-page-arrow"></span>
                </a>
              </li>
            )}
            {/* Handling due to old ui branding css (start)   */}
            {typeof window !== 'undefined' &&
              (window.innerWidth > 767 ||
                (window.innerWidth < 767 && pager.currentPage == pager.totalPages)) && (
                <li style={{ display: 'none' }}>
                  <a className="active" />
                </li>
              )}
            {/* End */}
          </ul>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(SearchPagination);
