/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
const SkipToContent = () => {
  const skipFocus = (skipToId) => {
    if (skipToId) {
      let scrolOffset =
        document.getElementById(skipToId) && document.getElementById(skipToId).offsetTop;
      window.scrollTo(0, scrolOffset);
      document.getElementById(skipToId).tabIndex = 0;
      setTimeout(() => {
        document.getElementById(skipToId).focus();
        document.getElementById(skipToId).removeAttribute('tabindex');
      }, 500);
    }
  };
  return (
    <React.Fragment>
      <a
        href="javascript:void(0)"
        className="skip"
        tabIndex="0"
        onClick={() => skipFocus('navigation')}
      >
        Skip to Main Navigation
      </a>
      <a
        href="javascript:void(0)"
        className="skip"
        tabIndex="0"
        onClick={() => skipFocus('content')}
      >
        Skip to content
      </a>
    </React.Fragment>
  );
};
export default SkipToContent;
