/**
 *  © 2022-2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import { getDrawerImage } from '../../../utils/competitionUtils';
import ImgCrop from '../../Common/ImgCrop';
import { createDestinationUrl } from '../../../utils/utils';
import xss from 'xss';
const CompetitionClose = (props) => {
  const { responseContent, entryConfirm, manutds, gridClass, sourceFromDetail, t } = props;

  const drawerData =
    responseContent &&
    responseContent.data &&
    responseContent.data.GetItemResponse &&
    responseContent.data.GetItemResponse.response &&
    responseContent.data.GetItemResponse.response.docs[0] &&
    responseContent.data.GetItemResponse.response.docs[0];

  const drawerImageData =
    drawerData && drawerData.competitionform_s && drawerData.competitionform_s;
  const viewEntryDetailsCTA =
    drawerData && drawerData.viewentrydetailscta_t && drawerData.viewentrydetailscta_t;
  const closedMessageDescription =
    drawerData && drawerData.closedmessagedescription_t && drawerData.closedmessagedescription_t;
  const closedMessageTitle =
    drawerData && drawerData.closedmessagetitle_t && drawerData.closedmessagetitle_t;
  const closeCtaTitle = drawerData && drawerData.ctatitle_t && drawerData.ctatitle_t;
  const closeCtaUrl = drawerData && drawerData.webctaurl_t && drawerData.webctaurl_t;

  const drawerImageContent = drawerImageData && getDrawerImage(drawerImageData);
  const drawerCloseIcon =
    drawerImageContent &&
    drawerImageContent.Icon &&
    drawerImageContent.Icon.CropUrl &&
    drawerImageContent.Icon.CropUrl;
  const drawerCloseIconImage = drawerCloseIcon && getDrawerImage(drawerCloseIcon);

  return (
    <div className="competition-closed">
      {xss(drawerCloseIconImage) && (
        <span className="close-logo">
          <span className="img-holder">
            <ImgCrop
              className="imgcrop"
              imgCrop={drawerCloseIconImage}
              singleSrc={false}
              gridClass={gridClass}
              alt={
                drawerImageContent &&
                drawerImageContent.DrawerImage &&
                drawerImageContent.DrawerImage.AltText &&
                drawerImageContent.DrawerImage &&
                drawerImageContent.DrawerImage.AltText
              }
              title={
                drawerImageContent &&
                drawerImageContent.DrawerImage &&
                drawerImageContent.DrawerImage.Name &&
                drawerImageContent.DrawerImage.Name
              }
              isWebpAvailable={
                drawerImageContent &&
                drawerImageContent.DrawerImage &&
                drawerImageContent.DrawerImage.isWebpAvailable &&
                drawerImageContent.DrawerImage.isWebpAvailable
              }
            />
          </span>
        </span>
      )}
      <div className="modal-text-gif">
        {closedMessageTitle && <h3>{closedMessageTitle}</h3>}
        {closedMessageDescription && <p>{closedMessageDescription}</p>}
        <span className="modal-btn">
          <>
            {closeCtaTitle && closeCtaUrl && (
              <a href={xss(closeCtaUrl)} role="button">
                <button className="red-btn-skew viewOtherComp">{closeCtaTitle}</button>
              </a>
            )}
            {entryConfirm &&
              !sourceFromDetail &&
              viewEntryDetailsCTA &&
              manutds &&
              manutds.value &&
              manutds.value.DestinationUrl && (
                <a href={createDestinationUrl(manutds.value.DestinationUrl)}>
                  <button className="white-btn-skew viewEntry">{viewEntryDetailsCTA}</button>
                </a>
              )}
          </>
        </span>
      </div>
    </div>
  );
};

export default withTranslation()(CompetitionClose);
