/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import { SIGN_IN_PAGE_KEY, SIGN_UP_PAGE_KEY } from '../../constants/ampConstants';
import { transformPrimarySecondarySponsor, isTeamViewer } from '../../utils/utils';
import MU from '../../constants/muConstants';

const AMPFooter = ({ t, fields }) => {
  const {
    CopyRightText,
    NavigationLinks,
    FooterSponsorsPrimary,
    PrimarySponsors,
    SecondarySponsors,
    SocialLinkButton,
    SocialMediaLinks,
    FooterSponsorsSecondary,
  } = fields;

  const getFooterNavLinks = () => {
    let navlinks = '';
    try {
      if (NavigationLinks && NavigationLinks.length > 0) {
        navlinks = NavigationLinks.map((item, i) => {
          const { Name, TargetURL } = item.fields || {};
          return (
            <li key={`NavigationLink${i}`}>
              <a
                href={TargetURL && TargetURL.value && TargetURL.value.hrefMuCustom}
                target={(TargetURL && TargetURL.value && TargetURL.value.target) || '_self'}
              >
                {Name && Name.value}
              </a>
            </li>
          );
        });
      }
    } catch (e) {
      console.log('error in getFooterNavLinks', e);
      navlinks = '';
    }
    return navlinks;
  };

  const getSocialMediaLinks = () => {
    let socialMediaLinks = '';
    try {
      {
        //to be verified on mt
        if (SocialMediaLinks && SocialMediaLinks.length > 0) {
          socialMediaLinks = SocialMediaLinks.map((item, i) => {
            const { link, Title, IconClass } = item.fields;
            const socialItemIcon = IconClass && IconClass.value;
            const iconurl = socialItemIcon
              ? `https://${MU && MU.manutdDomain}/amp/icons/${socialItemIcon.replace(
                  'icon-',
                  ''
                )}.png`
              : '';
            return (
              <li key={`SocialMediaLinks${i}`}>
                <a href={link && link.value && link.value.hrefMuCustom}>
                  {iconurl && iconurl.length && (
                    <amp-img
                      src={iconurl}
                      height="44"
                      width="44"
                      class="social-logos"
                      alt={Title && Title.value}
                    ></amp-img>
                  )}
                  <span className="screenreader">{`Follow us on ${
                    Title && Title.value
                  } External Website`}</span>
                </a>
              </li>
            );
          });
        }
      }
    } catch (e) {
      console.log('error in getSocialMediaLinks', e);
      socialMediaLinks = '';
    }
    return socialMediaLinks;
  };

  const getSocialLinkButtons = () => {
    let socialLinkButtons = '';
    const signInText = t(SIGN_IN_PAGE_KEY) && t(SIGN_IN_PAGE_KEY).toLowerCase();
    try {
      if (SocialLinkButton && SocialLinkButton.length > 0) {
        socialLinkButtons = SocialLinkButton.map((item, i) => {
          const { Name, TargetURL } = item.fields || {};
          return (
            <div
              key={`SocialLinkButton${i}`}
              className={
                Name && Name.value
                  ? Name.value.toLowerCase() === signInText
                    ? 'btn-skew black'
                    : Name.value === t(SIGN_UP_PAGE_KEY)
                    ? 'btn-skew red'
                    : ''
                  : ''
              }
            >
              <a href={TargetURL && TargetURL.value && TargetURL.value.hrefMuCustom}>
                <span>{Name && Name.value}</span>
              </a>
            </div>
          );
        });
      }
    } catch (e) {
      socialLinkButtons = '';
      console.log('getSocialLinkButtons', e);
    }
    return socialLinkButtons;
  };

  const primarySecondarySponsor = (sponsorItem) => {
    return (
      sponsorItem &&
      sponsorItem.length > 0 &&
      sponsorItem.map((obj, key) => {
        if (obj) {
          let { PartnerIconDark, partnerName, CTAURL, isActive: IsActive, CTATarget } = obj;
          if (IsActive) {
            return (
              <li key={key}>
                <a href={CTAURL && CTAURL} target={(CTATarget && CTATarget) || '_self'}>
                  {PartnerIconDark && PartnerIconDark.length > 0 && (
                    <amp-img
                      src={PartnerIconDark}
                      height="35"
                      width={isTeamViewer(partnerName) ? '90' : '70'}
                      alt={partnerName && partnerName}
                      noloading
                    />
                  )}
                  {
                    CTAURL &&
                      CTAURL.value &&
                      CTAURL.value.linktype &&
                      CTAURL.value.linktype === 'external' && (
                        <span className="screenreader">External Website</span>
                      ) //to be verified
                  }
                </a>
              </li>
            );
          }
        }
      })
    );
  };

  return (
    <footer className="footer" data-module="footer-elm" data-container="footer">
      <div className="sponsor">
        <h2>{t('Sponsors')}</h2>
        <div className="sponsor__primary">
          <ul>
            {PrimarySponsors && PrimarySponsors.length > 0
              ? primarySecondarySponsor(transformPrimarySecondarySponsor(PrimarySponsors))
              : primarySecondarySponsor(FooterSponsorsPrimary)}
          </ul>
        </div>

        <div className="sponsor__secondary">
          <ul>
            {SecondarySponsors && SecondarySponsors.length > 0
              ? primarySecondarySponsor(transformPrimarySecondarySponsor(SecondarySponsors))
              : primarySecondarySponsor(FooterSponsorsSecondary)}
          </ul>
        </div>
      </div>
      <div className="footer__social-links">
        <div className="social-icons">
          <div className="social-button" id="footerloginRegisterLink">
            {getSocialLinkButtons()}
          </div>
          <ul>{getSocialMediaLinks()}</ul>
        </div>
      </div>
      <div className="footer__menu">
        <div className="footer__menu__links">
          <ul>{getFooterNavLinks()}</ul>
        </div>
        <div className="footer__menu__copyright">
          <ul>
            <li>{CopyRightText && CopyRightText.value}</li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default withTranslation()(AMPFooter);
