/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useRef, useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import StatsBar from '../../Common/StatsBar';
import { getStatsBarData } from '../../../utils/utils';

const Social = (props) => {
  const { fields, gridClass, iscollection, showStatsbar } = props;
  const tweetContainerRef = useRef(null);
  const [showGridFlag, setShowGridFlag] = useState(null);
  const [themeBg, setThemeBg] = useState(null);
  const [mediaVisibility, setMediaVisibility] = useState(null);

  useEffect(() => {
    let interval;
    if (iscollection) {
      setThemeBg('dark');
      setMediaVisibility('visible');
      setShowGridFlag(false);
    } else {
      setThemeBg('light');
    }

    if (!window.twttr) {
      interval = setInterval(() => {
        if (window.twttr) {
          twitterReady();
          clearInterval(interval);
        }
      }, 500);
    } else {
      twitterReady();
    }
    setTimeout(() => {
      clearInterval(interval);
    }, 15000);
    return () => clearInterval(interval);
  }, []);

  const twitterReady = () => {
    const tweetUrl = fields.SocialUrl.value;
    const tweetId = tweetUrl.slice(tweetUrl.lastIndexOf('/') + 1, tweetUrl.length);
    const isShowGrid = window.location.href.search(/(liveevents|matches)/i) !== -1 ? false : true;
    setShowGridFlag(isShowGrid);
    setMediaVisibility(isShowGrid ? 'hidden' : 'visible');
    window.twttr.ready(function (twttr) {
      twttr.widgets.createTweet(tweetId, tweetContainerRef.current, {
        conversation: 'none', // or all
        cards: isShowGrid ? 'hidden' : 'visible', // hidden or visible
        linkColor: '#cc0000', // default is blue
        theme: iscollection ? 'dark' : 'light', // light or dark
        align: 'center',
      });
    });
  };

  return (
    <data-social-card>
      <div className={showGridFlag ? gridClass : ''} data-module="child-spotlight" tabIndex="0">
        <div className="mu-content">
          <article className="mu-item social-card child">
            <div data-share-url={fields.manutds.value.DestinationUrl} data-module="social-share">
              <div ref={tweetContainerRef} className="social-twtr-card"></div>
              {showStatsbar && <StatsBar item={getStatsBarData(fields)} />}
            </div>
          </article>
        </div>
      </div>
    </data-social-card>
  );
};

Social.defaultProps = {
  iscollection: false,
  showStatsbar: true,
};

export default Social;
