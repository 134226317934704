/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import Basic from '../Basic';
const BasicBlock = (props) => {
  const { fields, params } = props;
  const { BasicBlock2, BasicBlock1 } = fields;
  const theme = (params && params.Theme) || 'White';
  const position = (params && params.Position) || 'Left';
  return (
    <div
      className={`tmp-basic-block tmp-basic-clear ${theme ? theme : ''} ${
        position ? position : ''
      }  no-media`}
    >
      <div className="block-main tmp-basic-clear">
        {BasicBlock1 && <Basic isBlock={true} {...BasicBlock1} />}
        {BasicBlock2 && <Basic isBlock={true} {...BasicBlock2} />}
      </div>
    </div>
  );
};
export default withTranslation()(React.memo(BasicBlock));
