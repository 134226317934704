/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { isEmpty } from 'lodash';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';

const AMPNavigation = ({ allNav }) => {
  return (
    <ul className="sidebar">
      <li>
        <a href={allNav && allNav.TargetURL} target={allNav && allNav.TargetURLTarget}>
          {allNav && allNav.Name}
        </a>
      </li>
      {allNav &&
        allNav.Childrens &&
        allNav.Childrens.map((navItem, i) => {
          const { IsHidden, ShortName, Name, TargetURL, TargetURLTarget, IsExternalTarget } =
            navItem || {};

          return (
            !IsHidden && (
              <li key={`Childrens${i}`}>
                <a
                  href={TargetURL ? (IsExternalTarget ? TargetURL : TargetURL.toLowerCase()) : '#'}
                  target={TargetURLTarget || '_self'}
                >
                  {ShortName || Name}
                </a>
              </li>
            )
          );
        })}
      {!isExperienceEditorActive() &&
        allNav &&
        allNav.MoreMenuChildrens &&
        allNav.MoreMenuChildrens.map((navItem, i) => {
          const { IsHidden, ShortName, Name, TargetURL, TargetURLTarget, IsExternalTarget } =
            navItem || {};

          return (
            !IsHidden &&
            (!isEmpty(ShortName) || !isEmpty(Name) || !isEmpty(TargetURL)) && (
              <li className="more-menu__heading" key={`MoreMenuChildrens${i}`}>
                <a
                  href={IsExternalTarget ? TargetURL : TargetURL && TargetURL.toLowerCase()}
                  target={TargetURLTarget || '_self'}
                >
                  {ShortName || Name}
                </a>
              </li>
            )
          );
        })}
    </ul>
  );
};

export default AMPNavigation;
