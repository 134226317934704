/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useEffect } from 'react';
import StatsListing from './StatsListing';
import { StatsTransform, filterDataArray } from '../../data-transform/components/StatsTransform';
const Stats = (props) => {
  const [statsItem, setStatsItem] = useState(null);
  const { fields, id, uid, isAws, item, isMatchcenterCard } =
    props && props.rendering ? props.rendering : props;
  if (fields) {
    fields.itemid_s = id ? id : uid ? uid : '';
  }
  useEffect(() => {
    const dataItem =
      fields && !isAws ? filterDataArray(StatsTransform(fields)) : filterDataArray(item);
    dataItem && setStatsItem(dataItem);
  }, [item]);
  const gridClass = props.isCarousel ? 'grid-3 ratio child' : 'grid-12 child';
  const carousalClass = props.isCarousel ? 'mu-content' : '';
  return (
    statsItem && (
      <div>
        <div data-module="match-stats" className="blogstats">
          <StatsListing item={statsItem} gridClass={gridClass} carousalClass={carousalClass} />
        </div>
      </div>
    )
  );
};

Stats.defaultProps = {
  isCarousel: false,
};

export default Stats;
