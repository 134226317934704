/**
 *  © 2022-2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_COMINGUPDATA,
  SET_COMINGUPDATA,
  ERROR_COMINGUPDATA,
} from '../constants/actions/actionConstant';

export const comingupState = {
  status: null,
  comingUpResponseData: null,
};

export const comingupCarouselReducer = (state = comingupState, { type, response } = {}) => {
  switch (type) {
    case FETCH_COMINGUPDATA:
      return { ...state, status: 'fetching' };
    case SET_COMINGUPDATA: {
      return { ...state, status: 'fetched', comingUpResponseData: response.data };
    }
    case ERROR_COMINGUPDATA:
      return { ...state, status: 'error', comingUpResponseData: null };
    default:
      return state;
  }
};
