/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import { PlayerGridProvider, usePlayerGridValue } from '../../context/playerGridContext';
import MU from '../../constants/muConstants';
import { fetchPlayerGrid } from '../../actions/playerGridAction';
import { DFP } from '../Common/Dfp';
import Loader from '../Common/Loader';
import PlayerGridFilter from './PlayerGridFilter';
import PlayerGridGroup from './PlayerGridGroup';
import * as track from '../../utils/analytics';

const PlayerGrid = (props) => (
  <PlayerGridProvider>
    <PlayerGridContainer {...props} />
  </PlayerGridProvider>
);

const PlayerGridContainer = (props) => {
  const [{ status, filters, gridData, gridDataKeys }, dispatch] = usePlayerGridValue();
  const setLastSegment = () => {
    if (typeof window !== 'undefined') {
      const value = window.location.href.substr(0, window.location.href.lastIndexOf('?'));
      if (value) {
        return value.substr(value.lastIndexOf('/') + 1).toLowerCase();
      } else {
        return window.location.href.substr(window.location.href.lastIndexOf('/') + 1).toLowerCase();
      }
    }
  };
  const [lastSegment] = useState(setLastSegment());
  useEffect(() => {
    fetchData(MU.datafilter);
  }, []);

  const fetchData = (filter) => {
    fetchPlayerGrid(dispatch, filter).then(() => {
      setTimeout(() => {
        track.cardImpression();
      }, 500);
    });
  };

  return (
    <React.Fragment>
      {status === 'fetching' && <Loader className="loader-container" />}
      {status === 'fetched' && (
        <div className="team-grid__result">
          <PlayerGridFilter filters={filters} lastSegment={lastSegment} language={MU.language} />
          <div className="container minheight">
            {gridDataKeys &&
              gridDataKeys.map((playerNode, i) => {
                return (
                  <section key={`section${i}`} className="team-grid__details">
                    {gridData &&
                      gridData[playerNode] &&
                      Object.values(gridData[playerNode].grouped).map((playerHeading, i) => (
                        <PlayerGridGroup
                          key={`playerGridGroup${i}`}
                          playerHeading={playerHeading}
                        />
                      ))}
                  </section>
                );
              })}
            {gridData && (
              <div className="grid-12 ad-block dashboard" aria-hidden="true" tabIndex="-1">
                <DFP
                  slot="/11820346/Hercules_ROS_970x250//750x200//300x250"
                  browserSize={[1024, 768, 320]}
                  size={[
                    [970, 250],
                    [750, 200],
                    [300, 250],
                  ]}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default PlayerGrid;
