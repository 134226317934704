/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useEffect } from 'react';
import Profile from './Profile';
import { withTranslation } from 'react-i18next';
import PlayerStats from '../PlayerStats';
import * as track from '../../utils/analytics';
import { createDestinationUrl } from '../../utils/utils';
import { PLAYER_PROFILE } from '../../constants/containerConstants';
import { usePlayerDetailValue } from '../../context/playerDetailContext';
const ProfileTabs = ({ t, fields, itemId, setBgclass }) => {
  const [
    {
      hideMatchStats,
      isMatchLive,
      isPlayerPlayingLive,
      PlayerStatsGroupList,
      seasonstatsjson_t,
      hideseasonstats_b,
    },
  ] = usePlayerDetailValue();
  const { manutds, __Published, ContentType } = fields;
  const DestinationUrl =
    manutds &&
    manutds.value &&
    manutds.value.DestinationUrl &&
    createDestinationUrl(manutds.value.DestinationUrl);
  const [activeTab, setActiveTab] = useState(isMatchLive && isPlayerPlayingLive ? 2 : 1);
  const [firstClick, setFirstClick] = useState(null);
  useEffect(() => {
    setActiveTab(isMatchLive && isPlayerPlayingLive ? 2 : 1);
  }, [isMatchLive, isPlayerPlayingLive]);
  const isSetTab = (tabNum) => {
    return activeTab === tabNum;
  };
  const trackCard = () => {
    const initialData = track.data('card');
    track.analytics({
      ...initialData,
      card_name: 'playerprofile',
      container_type: PLAYER_PROFILE,
      item_id: itemId,
      content_type: ContentType && ContentType.value,
      tag: manutds && manutds.value && manutds.value.Tags,
      created_datetime: __Published && __Published.value,
      destination_url: DestinationUrl,
    });
  };
  const trackButton = (tabNum) => {
    const initialData = track.data('button');
    track.analytics({
      ...initialData,
      button_name: tabNum === 1 ? t('Profile') : t('Stats'),
      card_name: 'NA',
      tag: 'NA',
    });
  };
  const profileTabs = (e, tabNum) => {
    e.preventDefault();
    setActiveTab(tabNum);
    firstClick === null && tabNum === 2 && setFirstClick(true);
    trackButton(tabNum);
    trackCard();
    tabNum === 2 ? setBgclass('bgwhite') : setBgclass('');
  };
  return (
    <React.Fragment>
      <div className="touch__swipe">
        <div className="touch__swipe-wrapper">
          <span className="screenreader">{t('FiltersList')}</span>
          <ul
            className={`touch__swipe-items
            ${
              (!hideMatchStats && PlayerStatsGroupList && PlayerStatsGroupList.length > 0) ||
              (!hideseasonstats_b && seasonstatsjson_t && seasonstatsjson_t.length > 0)
                ? 'active'
                : ''
            }`}
            role="tablist"
          >
            <li
              className={`touch__swipe-item ${isSetTab(1) ? 'active' : ''}`}
              role="tab"
              aria-selected={`${isSetTab(1) ? 'true' : 'false'}`}
            >
              <a href="#" onClick={(e) => profileTabs(e, 1)} data-tab="#profile-tab">
                {t('Profile')}
              </a>
            </li>
            <li
              className={`touch__swipe-item ${isSetTab(2) ? 'active' : ''}`}
              role="tab"
              aria-selected={`${isSetTab(2) ? 'true' : 'false'}`}
            >
              <a href="#" onClick={(e) => profileTabs(e, 2)} data-tab="#stats-tab">
                {t('Stats')}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={`profile-tab-contentholder ${isSetTab(2) ? 'stats-active' : ''}`}>
        <section id="profile-tab" className={`profile-tab-content ${isSetTab(1) ? 'active' : ''}`}>
          <Profile fields={fields} itemId={itemId} />
        </section>
        <section id="stats-tab" className={`profile-tab-content ${isSetTab(2) ? 'active' : ''}`}>
          <PlayerStats itemId={itemId} fields={fields}></PlayerStats>
        </section>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(ProfileTabs);
