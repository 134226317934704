/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import ImageSlider from '../Common/ImageSlider';
import MatchHighlight from '../Common/MatchHighlight';
import { MatchCenterProvider, useMatchCenterValue } from '../../context/matchCenterContext';
import { MATCHDAY_LISTING } from '../../constants/containerConstants';
import {
  fetchMatchCenter,
  inViewPortAction,
  updateListing,
  fetchSpotlight,
} from '../../actions/matchCenterAction';
import Spotlight from './Spotlight';
import { getParameterByName, elmIsInViewport, getMatchIdFromUrl } from '../../utils/utils';
import Listing from '../Common/Listing';
import Lineup from './Lineup';
import Loader from '../Common/Loader';
import LiveNotification from '../Common/LiveNotification';
import { THRESHOLD } from '../../constants/globalConstants';
import ErrorBoundary from '../Common/ErrorBoundary';
const MatchCenter = (props) => (
  <React.Fragment>
    <MatchCenterProvider>
      <MatchesCenterContainer {...props}></MatchesCenterContainer>
    </MatchCenterProvider>
  </React.Fragment>
);
const MatchesCenterContainer = (props) => {
  const [
    {
      cronTimeInterval,
      showloadMore,
      rowsCount,
      serviceAction,
      timeStampValue,
      LineupResponse,
      ListingResponse,
      HighlightMatchStatsResponse,
      ImageResponse,
      matchId,
      lastCard,
      notificationCount,
      reTry,
      loader,
      SpotlightResponse,
      TimerResponse,
      contentType,
    },
    dispatch,
  ] = useMatchCenterValue();
  let timeoutId;
  const [activeTab, setActiveTab] = useState({ tabName: 'matchBlog' });
  const [IntId, setIntId] = useState(null);
  const domElm = useRef(null);
  const listingContainer = useRef(null);
  const matchdayRight = useRef(null);
  const matchdayWrapper = useRef(null);
  const matchdayLeft = useRef(null);
  const matchdayContainer = useRef(null);
  useEffect(() => {
    let id = getParameterByName('matchId');
    if (typeof id === 'undefined' || id == null) {
      id = getMatchIdFromUrl();
    }
    id && fetchApi('default', 'all', id);
    getViewPort();
    document.addEventListener('scroll', onscrollListner, {
      passive: true,
    });
    window.addEventListener('resize', onscrollListner);
    window.addEventListener('scroll', stickySidebar);
    return () => {
      clearInterval(IntId);
      window.removeEventListener('scroll', onscrollListner);
      window.removeEventListener('resize', onscrollListner);
      window.removeEventListener('scroll', stickySidebar);
    };
  }, []);

  useEffect(() => {
    clearInterval(IntId);
    contentType && contentType !== 'result' && enableCronJob();
    contentType && contentType === 'live' && fetchSpotlight(dispatch);
    return () => clearInterval(IntId);
  }, [contentType]);

  const stickySidebar = () => {
    let el = matchdayRight && matchdayRight.current;
    if (el) {
      let heading = document.querySelector('.lineup_heading');
      let footer = document.querySelector('.footer');
      let left = matchdayLeft && matchdayLeft.current;
      let wrapper = matchdayWrapper && matchdayWrapper.current;
      let checkAlternetScreen = heading && heading.classList.contains('checkAlternetClass');
      let matchday = matchdayContainer && matchdayContainer.current;
      if (window.innerWidth > 767 && checkAlternetScreen && footer && left && wrapper) {
        let height = el.offsetHeight;
        let scroll =
          (window.pageYOffset || document.documentElement.scrollTop) + window.innerHeight;
        var footerOffset = footer.offsetTop;
        let leftPanel = left.offsetHeight + wrapper.offsetHeight;
        height < leftPanel &&
          footerOffset < scroll &&
          !matchday.classList.contains('footer-in') &&
          matchday.classList.add('footer-in');
        height < leftPanel &&
          height < scroll &&
          !el.classList.contains('sticky') &&
          el.classList.add('sticky');
        footerOffset > scroll &&
          matchday.classList.contains('footer-in') &&
          matchday.classList.remove('footer-in');
        height > scroll && el.classList.contains('sticky') && el.classList.remove('sticky');
      }
    }
    return null;
  };

  const onscrollListner = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      getViewPort();
    }, THRESHOLD);
  };
  const enableCronJob = () => {
    clearInterval(IntId);
    setIntId(
      setInterval(() => {
        fetchApi('cronJob', 'all');
        contentType && contentType === 'live' && fetchSpotlight(dispatch);
      }, cronTimeInterval)
    );
  };
  const fetchApi = (
    actionType = serviceAction,
    stampVal = timeStampValue,
    id = matchId,
    count = rowsCount
  ) => {
    if (id && count) {
      // match id and pagination should be there
      fetchMatchCenter(dispatch, actionType, stampVal, id, count);
    }
  };
  const getViewPort = () => {
    if (domElm.current) {
      let isInView = elmIsInViewport(domElm);
      isInView = window.pageYOffset < 151 ? true : isInView;
      inViewPortAction(dispatch, isInView);
    }
  };

  /**
   *
   * @param {tabname may be matchblog/lineup to active} tabName
   */
  const feedShowHide = (tabName) => {
    setActiveTab({ tabName: tabName });
  };
  const loadMoreRecords = () => {
    let stampVal = lastCard ? 'b_' + lastCard : 'all';
    fetchApi('loadMore', stampVal);
    let matchday = matchdayContainer && matchdayContainer.current;
    matchday && matchday.classList.contains('footer-in') && matchday.classList.remove('footer-in');
  };
  const updateLiveFeed = () => {
    updateListing(dispatch);
    scrollToUp();
  };
  const scrollToUp = () => {
    //listingContainer && listingContainer.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    const yOffset = -85;
    const e = document.getElementById('listingContainer');
    const y = e && e.getBoundingClientRect().top + window.pageYOffset + yOffset;
    y && window.scrollTo({ top: y, behavior: 'smooth' });
  };

  return (
    <React.Fragment>
      <div className="match-day" data-module="match-day" ref={matchdayContainer}>
        {ImageResponse && ImageResponse.length > 0 && (
          <div className="match-day__left" style={{ display: 'block' }} ref={matchdayLeft}>
            <div className="hero mu-item">
              <ImageSlider sliderData={ImageResponse} animateTime={10000} />
            </div>
          </div>
        )}
        <div className="match-day__right" ref={matchdayRight}>
          {SpotlightResponse && (
            <div className="match-day__spotlight__wrapper">
              <ErrorBoundary>
                <Spotlight
                  spotItem={SpotlightResponse}
                  timerItem={TimerResponse}
                  contentType={contentType}
                />
              </ErrorBoundary>
            </div>
          )}

          {LineupResponse && (
            <ErrorBoundary>
              <Lineup lineupPlayer={LineupResponse} switchTab={feedShowHide} />
            </ErrorBoundary>
          )}
        </div>

        <div
          className="match-day__wrapper"
          style={{ display: activeTab.tabName === 'matchBlog' ? 'block' : 'none' }}
          ref={matchdayWrapper}
        >
          <div className="match-day__container">
            <div className="detect-view-port" ref={domElm}>
              {HighlightMatchStatsResponse && (
                <ErrorBoundary>
                  <MatchHighlight highlights={HighlightMatchStatsResponse} />
                </ErrorBoundary>
              )}
            </div>
            <div id="listingContainer" ref={listingContainer}>
              {ListingResponse && (
                <ErrorBoundary>
                  <Listing
                    listing={ListingResponse}
                    gridClassName={'grid-8'}
                    isMatchcenterCard={true}
                    classItem="news-list__item"
                    container={MATCHDAY_LISTING}
                  ></Listing>
                </ErrorBoundary>
              )}
            </div>
            <div className="row" style={{ textAlign: 'center' }}>
              {showloadMore && !reTry && (
                <button
                  type="button"
                  id="loadMore"
                  className="white-btn-skew-v2"
                  onClick={() => loadMoreRecords()}
                >
                  {props.t('loadmore')}
                </button>
              )}
              {reTry && (
                <div>
                  <div className="error-whoops__container">
                    <div className="grid-12 error-whoops__inner loadmore">
                      <h1>{props.t('Whoops')}</h1>
                      <p>{props.t('thereissomethingworngwithinternet')}</p>
                      <button
                        type="button"
                        id="loadMore"
                        className="red-btn-skew"
                        onClick={() => loadMoreRecords()}
                      >
                        <span>{props.t('Retry')}</span>
                        <i className="icon-follow-live"></i>
                      </button>
                      <button className="white-btn-skew" onClick={() => scrollToUp()}>
                        <span>{props.t('Latest')}</span>
                        <i className="tag-red-arrow"></i>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {notificationCount > 0 && (
          <LiveNotification
            count={notificationCount}
            updateLiveFeed={updateLiveFeed}
          ></LiveNotification>
        )}
      </div>
      {loader && (
        <Loader className={`loader-container1`} style={{ paddingBottom: '30px' }}></Loader>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(MatchCenter);
