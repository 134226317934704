/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';
import { CAROUSEL } from '../../constants/containerConstants';
import Transform from '../../data-transform';
import MU from '../../constants/muConstants';
import { withTranslation } from 'react-i18next';
import { checkDevice, getRandomId, getActiveModalCount } from '../../utils/utils';
import {
  afterChangeAccessbility,
  beforeChangeAccessbility,
  NextArrow,
  onInitAccessibility,
  PrevArrow,
  slideShowCount,
} from '../../utils/carouselUtils';
import {
  SUPPORTED_PLAYER_PROFILE_CAROUSEL_CARDTYPE,
  THRESHOLD,
} from '../../constants/globalConstants';

const PlayerProfileCarousel = (props) => {
  const { playerCarousel, PlayerTag } = props.data;
  const [slicker, setSlicker] = useState({});
  const [isTouchDevice, setIsTouchDevice] = useState(true);
  const [screenReaderText, setScreenReaderText] = useState('');
  const [uniqueId, setUniqueId] = useState(getRandomId());
  const refElement = useRef(null);
  let timeoutId;
  useEffect(() => {
    setIsTouchDevice(checkDevice());
  }, []);
  useEffect(() => {
    const touchDevice = checkDevice();
    if (!touchDevice) {
      window.addEventListener('resize', resizeListener);
    }
    return () => {
      !touchDevice && window.removeEventListener('resize', resizeListener);
    };
  }, []);
  const resizeListener = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      const modalCount = getActiveModalCount();
      setIsTouchDevice(checkDevice());
      modalCount === 0 && setUniqueId(getRandomId());
    }, THRESHOLD);
  };
  const slideCount = () => {
    if (
      playerCarousel &&
      playerCarousel.response &&
      playerCarousel.response.docs &&
      playerCarousel.response.docs.length > 0
    ) {
      return playerCarousel.response.docs.length;
    } else {
      return 0;
    }
  };
  const nextPrevArrow = (oldIndex, newIndex, slicker, bypass) => {
    setTimeout(() => {
      if (oldIndex < newIndex || bypass) {
        slicker && slicker.prevArrow && slicker.prevArrow.classList.remove('clicked');
        slicker && slicker.nextArrow && slicker.nextArrow.classList.add('clicked');
      }
      if (oldIndex > newIndex) {
        slicker && slicker.prevArrow && slicker.prevArrow.classList.add('clicked');
        slicker && slicker.nextArrow && slicker.nextArrow.classList.remove('clicked');
      }
    }, 100);
  };
  const settings = () => {
    return {
      className: 'carousal-list',
      lazyLoad: 'progressive',
      variableWidth: true,
      infinite: false,
      speed: 400,
      dots: true,
      slidesToScroll: slideShowCount(),
      swipe: true,
      accessibility: false,
      slidesToShow: slideShowCount(),
      draggable: false,
      swipeToSlide: true,
      touchMove: true,
      mobileFirst: true,
      touchThreshold: 20,
      initialSlide: 0,
      rtl: false,
      arabic: MU.language === 'ar' ? true : false,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow slideShow={slideShowCount()} />,
      appendDots: (dots) => {
        if (dots && dots.length) {
          let isActive = true;
          let list = dots.map((val, index) => {
            isActive = isActive ? val.props.className !== 'slick-active' : false;
            return (
              <li key={index} className={`${val.props.className} ${isActive ? 'active' : ''}`}>
                <i></i>
              </li>
            );
          });
          return (
            <div className="slick-dots">
              <ul tabIndex="-1" aria-hidden="true">
                {list}
              </ul>
            </div>
          );
        }
      },
      onLazyLoad: (e) => {
        if (e.includes(slideCount() - 1)) {
          setTimeout(() => {
            if (refElement) {
              const slider = refElement && refElement.current;
              let list = slider && slider.querySelector('.slick-list');
              let slideTrack = list && list.querySelector('.slick-track');
              let slides = slideTrack && slideTrack.querySelectorAll('.slick-slide');
              let nextArrow = slider && slider.querySelector('.slider-next.slick-arrow');
              let prevArrow = slider && slider.querySelector('.slider-prev.slick-arrow');
              onInitAccessibility(slideTrack, slides, 'playerprofile');
              setSlicker({
                slider: slider,
                list: list,
                track: slideTrack,
                slides: slides,
                nextArrow: nextArrow,
                prevArrow: prevArrow,
              });
            }
          }, 500);
        }
      },
      beforeChange: (oldIndex, newIndex) => {
        setScreenReaderValue(newIndex);
        nextPrevArrow(oldIndex, newIndex, slicker);
        beforeChangeAccessbility(slicker, 'playerprofile');
      },
      afterChange: () => {
        afterChangeAccessbility(slicker);
      },
    };
  };
  const setScreenReaderValue = (index) => {
    const a = index;
    const b = slideShowCount();
    const c = slideCount();
    if (a + b <= c) {
      setScreenReaderText(`Displayed ${a + 1} of ${a + b} out of ${c}`);
    }
    setTimeout(() => {
      setScreenReaderText('');
    }, 300);
  };
  const renderSlides = (cardDetails, i) => {
    if (
      cardDetails &&
      cardDetails.contenttype_t &&
      SUPPORTED_PLAYER_PROFILE_CAROUSEL_CARDTYPE.includes(cardDetails.contenttype_t.toLowerCase())
    ) {
      return (
        <Transform
          container={CAROUSEL}
          key={i}
          data={cardDetails}
          source="solr"
          gridClass="grid-3"
        />
      );
    }
  };

  const searchUrl = (i) => {
    let url = `/search?q=${i}`;
    if (MU.language && url) {
      url = `/${MU.language}${url}`;
    }
    return url;
  };

  return (
    <React.Fragment>
      {playerCarousel &&
        playerCarousel.response &&
        playerCarousel.response.docs &&
        playerCarousel.response.docs.length && (
          <div
            className={`container ${isTouchDevice ? 'custom-container' : ''} ${
              slideCount() <= slideShowCount() ? 'adjust' : ''
            }`}
            key={uniqueId}
          >
            <div className="carousal-width" ref={refElement}>
              <div className="carousal-head">
                <div className="carousal_title_link">
                  <h3 className="carousal__title">
                    <span>{props.t('Latest')}</span>
                    {playerCarousel &&
                      playerCarousel.response &&
                      playerCarousel.response.docs &&
                      playerCarousel.response.docs.length > 4 && (
                        <a
                          className="carousal-destination"
                          href={searchUrl(PlayerTag && PlayerTag.value)}
                        >
                          {props.t('ViewMore')}
                        </a>
                      )}
                  </h3>
                </div>
              </div>
              {!isTouchDevice && (
                <Slider {...settings()}>
                  {playerCarousel &&
                    playerCarousel.response &&
                    playerCarousel.response.docs &&
                    playerCarousel.response.docs.length > 0 &&
                    playerCarousel.response.docs.map((cardDetails, i) =>
                      renderSlides(cardDetails, i)
                    )}
                </Slider>
              )}
              {isTouchDevice && (
                <div className="carousal-wrapper">
                  <div className={`carousal-list custom-mobile-slider`}>
                    {playerCarousel &&
                      playerCarousel.response &&
                      playerCarousel.response.docs &&
                      playerCarousel.response.docs.length > 0 &&
                      playerCarousel.response.docs.map((cardDetails, i) => (
                        <div key={i} className="mobile-listitem" role="listitem">
                          {renderSlides(cardDetails, i)}
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
            {!isTouchDevice && (
              <div className="screenreader" aria-live="assertive">
                {screenReaderText}
              </div>
            )}
          </div>
        )}
    </React.Fragment>
  );
};

export default withTranslation()(PlayerProfileCarousel);
