/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
const MatchGoals = ({ t, item, statsType, goalType }) => {
  const buildMatchGoalData = (item) => {
    let graphData = {};
    let statsItems = item && item.stats;
    if (statsItems && statsItems.length > 0) {
      for (var outer = 0; outer < statsItems.length; outer++) {
        var outerObj = statsItems[outer];
        if (item.groupName === 'stats_Saves') {
          if (outerObj && outerObj.statsKey === 'stats_Savestopleftcorner') {
            graphData.topLeftCornerKey = outerObj.statsKey;
            graphData.topLeftCornerStatsValue = outerObj.statsValue;
          } else if (outerObj && outerObj.statsKey === 'stats_SavesHighCentre') {
            graphData.highCentreKey = outerObj.statsKey;
            graphData.highCentreStatsValue = outerObj.statsValue;
          } else if (outerObj && outerObj.statsKey === 'stats_SavesTopRightCorner') {
            graphData.topRightCornerKey = outerObj.statsKey;
            graphData.topRightCornerStatsValue = outerObj.statsValue;
          } else if (outerObj && outerObj.statsKey === 'stats_SavesBottomLeftCorner') {
            graphData.bottomLeftCornerKey = outerObj.statsKey;
            graphData.bottomLeftCornerStatsValue = outerObj.statsValue;
          } else if (outerObj && outerObj.statsKey === 'stats_SavesLowCentre') {
            graphData.lowCentreKey = outerObj.statsKey;
            graphData.lowCentreStatsValue = outerObj.statsValue;
          } else if (outerObj && outerObj.statsKey === 'stats_SavesBottomRightCorner') {
            graphData.bottomRightCornerKey = outerObj.statsKey;
            graphData.bottomRightCornerStatsValue = outerObj.statsValue;
          } else if (outerObj && outerObj.statsKey === 'stats_SavesInsideTheBox') {
            graphData.goalsfromInsideBoxKey = outerObj.statsKey;
            graphData.goalsfromInsideBoxStatsValue = outerObj.statsValue;
          } else if (outerObj && outerObj.statsKey === 'stats_SavesOutsideTheBox') {
            graphData.goalsfromOutsideBoxKey = outerObj.statsKey;
            graphData.goalsfromOutsideBoxStatsValue = outerObj.statsValue;
          }
        } else {
          if (outerObj && outerObj.statsKey == 'stats_TopLeftCorner') {
            graphData.topLeftCornerKey = outerObj.statsKey;
            graphData.topLeftCornerStatsValue = outerObj.statsValue;
          } else if (outerObj && outerObj.statsKey == 'stats_HighCentre') {
            graphData.highCentreKey = outerObj.statsKey;
            graphData.highCentreStatsValue = outerObj.statsValue;
          } else if (outerObj && outerObj.statsKey == 'stats_TopRightCorner') {
            graphData.topRightCornerKey = outerObj.statsKey;
            graphData.topRightCornerStatsValue = outerObj.statsValue;
          } else if (outerObj && outerObj.statsKey == 'stats_BottomLeftCorner') {
            graphData.bottomLeftCornerKey = outerObj.statsKey;
            graphData.bottomLeftCornerStatsValue = outerObj.statsValue;
          } else if (outerObj && outerObj.statsKey == 'stats_LowCentre') {
            graphData.lowCentreKey = outerObj.statsKey;
            graphData.lowCentreStatsValue = outerObj.statsValue;
          } else if (outerObj && outerObj.statsKey == 'stats_BottomRightCorner') {
            graphData.bottomRightCornerKey = outerObj.statsKey;
            graphData.bottomRightCornerStatsValue = outerObj.statsValue;
          } else if (outerObj && outerObj.statsKey == 'stats_GoalsfromInsideBox') {
            graphData.goalsfromInsideBoxKey = outerObj.statsKey;
            graphData.goalsfromInsideBoxStatsValue = outerObj.statsValue;
          } else if (outerObj && outerObj.statsKey == 'stats_GoalsfromOutsideBox') {
            graphData.goalsfromOutsideBoxKey = outerObj.statsKey;
            graphData.goalsfromOutsideBoxStatsValue = outerObj.statsValue;
          }
        }
      }
    }
    return graphData;
  };
  const [graphData, setGraphData] = useState(null);
  useEffect(() => {
    setGraphData(buildMatchGoalData(item));
  }, [item]);
  return (
    graphData && (
      <div className="chart-wrapper">
        {goalType === 'goal' && <h3>{t('stats_Goals')}</h3>}
        {goalType === 'save' && <h3>{t('stats_Saves')}</h3>}
        <div className="match-stats-goals">
          <div className="stats-wrapper">
            <div className="field-wrapper">
              <div className="frame">
                <div className="frame-box">
                  {graphData.topLeftCornerStatsValue > 0 && (
                    <span className="score">
                      <span className="screenreader">{t('stats_Savestopleftcorner')}</span>
                      <span>{graphData.topLeftCornerStatsValue}</span>
                    </span>
                  )}
                </div>
                <div className="frame-box">
                  {graphData.highCentreStatsValue > 0 && (
                    <span className="score">
                      <span className="screenreader">{t('stats_HighCentre')}</span>
                      <span>{graphData.highCentreStatsValue}</span>
                    </span>
                  )}
                </div>
                <div className="frame-box">
                  {graphData.topRightCornerStatsValue > 0 && (
                    <span className="score">
                      <span className="screenreader">{t('stats_SavesTopRightCorner')}</span>
                      <span>{graphData.topRightCornerStatsValue}</span>
                    </span>
                  )}
                </div>
                <div className="frame-box">
                  {graphData.bottomLeftCornerStatsValue > 0 && (
                    <span className="score">
                      <span className="screenreader">{t('stats_BottomLeftCorner')}</span>
                      <span>{graphData.bottomLeftCornerStatsValue}</span>
                    </span>
                  )}
                </div>
                <div className="frame-box">
                  {graphData.lowCentreStatsValue > 0 && (
                    <span className="score">
                      <span className="screenreader">{t('stats_LowCentre')}</span>
                      <span>{graphData.lowCentreStatsValue}</span>
                    </span>
                  )}
                </div>
                <div className="frame-box">
                  {graphData.bottomRightCornerStatsValue > 0 && (
                    <span className="score">
                      <span className="screenreader">{t('stats_SavesBottomRightCorner')}</span>
                      <span>{graphData.bottomRightCornerStatsValue}</span>
                    </span>
                  )}
                </div>
              </div>
              <div className="field-outer" aria-hidden="true">
                <span className="inside-score">{graphData.goalsfromInsideBoxStatsValue}</span>
                <span className="outside-score">{graphData.goalsfromOutsideBoxStatsValue}</span>
              </div>
            </div>
          </div>
          {goalType === 'goal' && (
            <ul className="pass-notations">
              <li>
                <span aria-hidden="true" className="pass-point"></span>
                <span aria-hidden="true">{t('stats_GoalsfromInsideBox')}</span>
                <span className="screenreader">
                  {`${t('stats_GoalsfromInsideBox')} ${graphData.goalsfromInsideBoxStatsValue}`}
                </span>
              </li>
              <li>
                <span aria-hidden="true" className="pass-point"></span>
                <span aria-hidden="true">{t('stats_GoalsfromOutsideBox')}</span>
                <span className="screenreader">{`${t('stats_GoalsfromOutsideBox')} ${
                  graphData.goalsfromOutsideBoxStatsValue
                }`}</span>
              </li>
            </ul>
          )}

          {goalType === 'save' && (
            <ul className="pass-notations">
              <li>
                <span aria-hidden="true" className="pass-point"></span>
                <span aria-hidden="true">{t('stats_SavesMadefromInsideBox')}</span>
                <span className="screenreader">{`${t('stats_SavesMadefromInsideBox')} ${
                  graphData.goalsfromInsideBoxStatsValue
                }`}</span>
              </li>
              <li>
                <span aria-hidden="true" className="pass-point"></span>
                <span aria-hidden="true">{t('stats_SavesMadefromOutsideBox')}</span>
                <span className="screenreader">{`${t('stats_SavesMadefromOutsideBox')} ${
                  graphData.goalsfromOutsideBoxStatsValue
                }`}</span>
              </li>
            </ul>
          )}
        </div>
      </div>
    )
  );
};
export default withTranslation()(MatchGoals);
