/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { isEmpty } from 'lodash';
import { buildImgUrl } from '../../../utils/utils';
import { getAMPVideoIframeURL } from '../../../utils/articleUtils';

const AMPVideo = ({ image, altText, mediaId, caption }) => {
  const imgUrl = buildImgUrl(image);
  return (
    <div className="mu-item video">
      <amp-iframe
        title={caption}
        width="360"
        height="203"
        sandbox="allow-scripts allow-same-origin allow-popups"
        layout="responsive"
        frameborder="0"
        allowfullscreen
        allow="encrypted-media"
        src={getAMPVideoIframeURL(imgUrl, mediaId)}
      >
        {imgUrl && imgUrl.length > 0 && (
          <amp-img layout="fill" src={imgUrl} alt={altText} placeholder></amp-img>
        )}
      </amp-iframe>
      {!isEmpty(caption) && (
        <div className="mu-item__info">
          <h2 className="mu-item__title">{caption}</h2>
        </div>
      )}
    </div>
  );
};

AMPVideo.defaultProps = {
  image: '',
  altText: '',
  mediaId: '',
  caption: '',
};

export default AMPVideo;
