/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import { createTagList, transformUpsellData } from '../../utils/utils';
export const Image = (data, isAws) => {
  const sponsor = isAws
    ? data.sponsordetailinfo_s && data.sponsordetailinfo_s[0]
    : data.sponsordetailinfo_s && JSON.parse(data.sponsordetailinfo_s)[0];

  return {
    fields: {
      'Image File': {
        value: {
          Crops: isAws
            ? data.celumimagesvariant_s &&
              data.celumimagesvariant_s.ImageCropUrl &&
              data.celumimagesvariant_s.ImageCropUrl.CropUrl
              ? data.celumimagesvariant_s.ImageCropUrl.CropUrl
              : data.imagecropurl_s
            : data.imagecropurl_s && JSON.parse(data.imagecropurl_s),

          AltText: isAws
            ? data.celumimagesvariant_s &&
              data.celumimagesvariant_s.ImageCropUrl &&
              data.celumimagesvariant_s.ImageCropUrl.AltText
            : data.alttext_s,
          isWebpAvailable: isAws
            ? data.celumimagesvariant_s &&
              data.celumimagesvariant_s.ImageCropUrl &&
              data.celumimagesvariant_s.ImageCropUrl.isWebpAvailable
              ? data.celumimagesvariant_s.ImageCropUrl.isWebpAvailable
              : data.iswebpavailablebool_b
            : data.iswebpavailablebool_b,
        },
      },
      Variant: {
        value: data.variant_t && data.variant_t.toLowerCase(),
      },
      Teaser: {
        value: data.teaser_t || data.blogdescription_t || data.shortheadline_t,
      },
      ContentType: {
        value: data.contenttype_t,
      },
      CardType: {
        value: data.cardtype_t,
      },
      UpsellMapping:
        !isAws && data.upsell_s && typeof data.upsell_s === 'string'
          ? transformUpsellData(JSON.parse(data.upsell_s))
          : data.upsell_s && transformUpsellData(data.upsell_s),
      Sponsorby: sponsor
        ? {
            fields: {
              PartnerIconDark: {
                value: {
                  Crops: {
                    img1x: sponsor.PartnerIconDark,
                  },
                },
              },
              PartnerName: {
                value: sponsor.PartnerName,
              },

              CTAURL: {
                value: {
                  href: sponsor.CTAURL,
                },
              },

              MCC_MNC: {
                value: sponsor.MCC_MNC,
              },
              PartnerIconLight: {
                value: {
                  Crops: {
                    img1x: sponsor.PartnerIconLight,
                  },
                },
              },
              PartnerText: {
                value: sponsor.PartnerText,
              },
            },
          }
        : null,
      __Published: {
        value: data.appearance_tdt || data.__published_tdt,
      },
      __PublishedBy: {
        value: data.__publishedby_t,
      },
      manutds: {
        value: {
          DestinationUrl: data.destinationurl_s,
          Author: isAws ? data.authorname_s : data.author_t,
          Tags: createTagList(data.taglist_sm, data.category_s),
        },
      },
    },
    rendering: {
      dataSource: data.itemid_s,
    },
  };
};
