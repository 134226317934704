/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_PLAYER_STATS,
  FETCH_SEASON_STATS,
  SET_PLAYER_STATS,
  SET_SEASON_STATS,
  ERROR_PLAYER_STATS,
  ERROR_SEASON_STATS,
} from '../constants/actions/actionConstant';
import { PLAYER_LIVE_STATS, PLAYER_SEASON_STATS } from '../constants/endpoints';
import api from '../utils/muApi';
import MU from '../constants/muConstants';
export const fetchPlayerLiveStats = (dispatch) => {
  const playerProfileTagName = MU && MU.playerProfileTagName ? MU.playerProfileTagName : null;
  if (playerProfileTagName) {
    const route = `${PLAYER_LIVE_STATS}${playerProfileTagName}`;
    dispatch(fetchingPlayerLiveStats());
    return api
      .get({ route })
      .then((response) => {
        dispatch(setPlayerLiveStats(response));
      })
      .catch((err) => {
        dispatch(errorPlayerLiveStats(err));
      });
  }
};

export const fetchSeasonStats = (dispatch) => {
  const playerProfileTagName = MU && MU.playerProfileTagName ? MU.playerProfileTagName : null;
  const route = `${PLAYER_SEASON_STATS}${playerProfileTagName}`;
  if (playerProfileTagName) {
    dispatch(fetchingSeasonStats());
    return api
      .get({ route })
      .then((response) => {
        dispatch(setSeasonStats(response));
      })
      .catch((err) => {
        dispatch(errorSeasonStats(err));
      });
  }
};

export const fetchingPlayerLiveStats = () => ({ type: FETCH_PLAYER_STATS });
export const setPlayerLiveStats = (response) => ({ type: SET_PLAYER_STATS, payload: response });
export const errorPlayerLiveStats = (response) => ({ type: ERROR_PLAYER_STATS, payload: response });

export const fetchingSeasonStats = () => ({ type: FETCH_SEASON_STATS });
export const setSeasonStats = (response) => ({ type: SET_SEASON_STATS, payload: response });
export const errorSeasonStats = (response) => ({ type: ERROR_SEASON_STATS, payload: response });
