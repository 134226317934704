/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { mapCuratedCard } from './DataMapping';
import Transform from '../../data-transform';
import { MEGA_NAV_ } from '../../constants/containerConstants';
import { tabbable } from '../../utils/tabbable';
import AutoSearch from '../Common/AutoSearch';
import ErrorBoundary from '../Common/ErrorBoundary';
import { checkDevice } from '../../utils/utils';
let timeoutId1;
let timeoutId2;

const Search = ({ searchText, searchCards, onMenuHover }) => {
  const [stl, setStyle] = useState({});
  const [styleHedSearchPannel, setStyleHedSearchPannel] = useState({
    display: 'block',
    visibility: 'hidden',
  });
  const updateHeaderStyle = (flag) => {
    flag ? setStyle({ display: 'block' }) : setStyle({ display: 'none' });
  };

  const closeSearchPannel = (e) => {
    try {
      onMenuHover(e, false, 'search');
      setTimeout(() => {
        setStyleHedSearchPannel({ display: 'none' });
        document.getElementById('inp').value = '';
        document.getElementById('search-menu-suggestions').style.display = 'none';
        document.getElementById('headerSearchIcon').focus();
      }, 400);
      e.stopPropagation();
    } catch (e) {}
  };
  const updateOnMouseEnter = (e) => {
    onMenuHover(e, true, 'search');
    clearTimeout(timeoutId2);
    timeoutId1 = setTimeout(() => {
      setStyleHedSearchPannel({ display: 'block' });
      setStyle({ display: 'block' });
    }, 400);
  };
  const updateOnMouseLeave = (e) => {
    onMenuHover(e, false, 'search');
    clearTimeout(timeoutId1);
    timeoutId2 = setTimeout(() => {
      setStyleHedSearchPannel({ display: 'none' });
      setStyle({ display: 'none' });
    }, 400);
  };

  /**
   * responsible for rendering curated card inside search panel
   */
  const renderCards = () => {
    let cardObj = { CardsInformation: searchCards };
    const data = mapCuratedCard(cardObj);
    const navCards = { isMagaNavCard: true };
    return (
      data &&
      data.length > 0 &&
      data.map((obj, key) => {
        return (
          <Transform
            key={key}
            data={obj}
            source="solr"
            gridClass="grid-4"
            navCards={navCards}
            container={`${MEGA_NAV_}${searchText && searchText.value}`}
            isRelative={obj.sponsordetailinfo_s ? true : false} //no isAutomated check required as all serach cards made from mapcurated function so only used sponsor info detail to set isRealtive
          />
        );
      })
    );
  };

  const onEnterShowSearch = (e) => {
    let code = e.keyCode || e.which;
    if (code === 13 || e.type === 'click') {
      onMenuHover(e, true, 'search');
      setStyleHedSearchPannel({ display: 'block' });
      setStyle({ display: 'block' });
    }
  };
  const setSubmenuClass = () => {
    return styleHedSearchPannel &&
      styleHedSearchPannel.display === 'block' &&
      !styleHedSearchPannel.visibility
      ? 'active'
      : '';
  };
  const handleSearchAccessibility = (e) => {
    let code = e.keyCode || e.which;
    let rootNode = e.target && e.target.closest('.home-search__panel');
    let tabbableArr = rootNode && tabbable(rootNode);
    let activeElm = document.activeElement;
    let currentIndex = tabbableArr && activeElm && tabbableArr.indexOf(activeElm);
    if (code === 9 && !e.shiftKey) {
      if (tabbableArr && currentIndex === tabbableArr.length - 1) {
        setStyleHedSearchPannel({ display: 'none' });
        onMenuHover(e, false);
      }
    }
  };
  const onSearchClick = (e) => {
    e.stopPropagation();
    const target = document.getElementById('headerSearchIcon');
    if (
      checkDevice() ||
      (target && target.classList && target.classList.contains('non-keyboard-outline'))
    ) {
      updateOnMouseEnter(e);
    }
  };
  /**
   * responsible for rendering search Dom only
   */
  const renderSearch = () => {
    return (
      <React.Fragment>
        <li
          className={`home-search ${setSubmenuClass()}`}
          onMouseEnter={(e) => updateOnMouseEnter(e)}
          onMouseLeave={(e) => updateOnMouseLeave(e)}
          onClick={(e) => onSearchClick(e)}
        >
          <div
            className="links__icon-holder"
            id="headerSearchIcon"
            tabIndex="0"
            role="button"
            aria-label={searchText && searchText.value}
            onKeyDown={(e) => onEnterShowSearch(e)}
          >
            <i className="icon-home-search"></i>
            <span>{searchText && searchText.value}</span>
          </div>
          <div
            className="home-search__panel home-header__menu-list"
            onKeyDown={(e) => handleSearchAccessibility(e)}
            style={styleHedSearchPannel}
            onMouseLeave={(e) => updateOnMouseLeave(e)}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="grid-11">
              <ErrorBoundary>
                <AutoSearch
                  updateHeaderStyle={updateHeaderStyle}
                  searchButton={true}
                  placeholder={`WhatAreYouLookingFor`}
                ></AutoSearch>
              </ErrorBoundary>
              <div className="home-header__cards" style={stl}>
                {searchCards &&
                  (searchCards.Card1Title ||
                    searchCards.Card1Image ||
                    searchCards.Card2Title ||
                    searchCards.Card2Image ||
                    searchCards.Card3Title ||
                    searchCards.Card3Image ||
                    searchCards.card1Video ||
                    searchCards.card2Video ||
                    searchCards.card3Video) && (
                    <div
                      role="heading"
                      aria-level="2"
                      className="home-header__cards--heading"
                      tabIndex="0"
                    >
                      {searchCards.CardHeading}
                    </div>
                  )}
                {renderCards()}
              </div>
              <div
                className="nav-close search"
                tabIndex="0"
                role="button"
                aria-label="close search"
                onClick={(e) => closeSearchPannel(e)}
                onKeyDown={(e) => e && (e.keyCode === 13 || e.which === 13) && closeSearchPannel()}
              >
                <span>
                  <i className="icon-cross"></i>
                </span>
              </div>
            </div>
          </div>
        </li>
      </React.Fragment>
    );
  };

  return renderSearch();
};

export default withTranslation()(React.memo(Search));
