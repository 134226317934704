/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { createContext, useContext, useReducer } from 'react';
import { myListState, mutvMyListReducer } from '../reducers/mutvMyListReducer';

export const MutvListContext = createContext();

export const MutvListProvider = ({ children }) => (
  <MutvListContext.Provider value={useReducer(mutvMyListReducer, myListState)}>
    {children}
  </MutvListContext.Provider>
);
export const useMutvListingValue = () => useContext(MutvListContext);

export default MutvListContext;
