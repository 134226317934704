/**
 *  © 2022 -2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useSponsorValue } from '../../../context/sponsorContext';
import { getContentAccessLocal } from '../../../utils/utils';
import LazyLoad from 'react-lazyload';
import ImgCrop from '../../Common/ImgCrop';
import { ACCOUNT_SUBSCRIPTION, REGISTERED } from '../../../constants/globalConstants';
import { useModal } from '../../Common/Modal';
import ErrorBoundary from '../../Common/ErrorBoundary';
import Loader from '../../Common/Loader';
import loadable from '@loadable/component';
import { data, analytics } from '../../../utils/analytics';
import { COMINGUP_EVENTS, COMINGUPCAROUSAL } from '../../../constants/containerConstants';
import MU from '../../../constants/muConstants';
const RegistrationModal = loadable(() =>
  import('../../Modals/RegistrationModal/RegistrationModal')
);

const CardEvents = (props) => {
  const { item, getDateSchudle, getTimeSchudle, t } = props;

  const {
    itemid_s,
    __published_tdt,
    authorname_s,
    contentaccess_t,
    contenttype_t,
    taglist_sm,
    ctatitleregistereduser_t,
    ctatitlesubscribeduser_t,
    ctatitle_t,
    ctaurlstring_s,
    iconcomingup_s,
    celumimagesvariant_s,
    alttext_s,
    matchdate_tdt,
    title,
    headline_t,
    description_t,
    label_t,
    _name,
    broadcasterdetails_s,
    additionaltextcupdraw_t,
    iswebpavailablebool_b,
    hideeventtime_b,
  } = item;

  const triggerAnalytics = () => {
    const initialData = data('card');
    analytics({
      ...initialData,
      card_name: title + ': ' + headline_t + ': ' + description_t,
      container_type: COMINGUPCAROUSAL,
      content_type: COMINGUP_EVENTS,
      button_name: getCTA(),
      item_id: itemid_s,
      item_name: _name,
      tag: taglist_sm,
      created_datetime: __published_tdt !== undefined ? __published_tdt : 'N/A',
      author: authorname_s,
      destination_url: ctaurlstring_s,
    });
  };

  const [{ isUserLogin, isUserSubscribe }] = useSponsorValue();
  const { closeModal } = useModal();
  const contentAccessState = getContentAccessLocal(contentaccess_t, contenttype_t, taglist_sm);
  const {
    showModal: cbrShowModal,
    closeModal: cbrCloseModal,
    isModalVisible: cbrModalVisible,
  } = useModal();

  const [imageCrops, setImageCrops] = useState({});

  useEffect(() => {
    setImageCrops({
      value: {
        Crops:
          celumimagesvariant_s &&
          celumimagesvariant_s.ImageCropUrl &&
          celumimagesvariant_s.ImageCropUrl.CropUrl
            ? celumimagesvariant_s.ImageCropUrl.CropUrl
            : iconcomingup_s && iconcomingup_s,
        AltText:
          celumimagesvariant_s &&
          celumimagesvariant_s.ImageCropUrl &&
          celumimagesvariant_s.ImageCropUrl.AltText
            ? celumimagesvariant_s.ImageCropUrl.AltText
            : alttext_s,
        isWebpAvailable:
          celumimagesvariant_s &&
          celumimagesvariant_s.ImageCropUrl &&
          celumimagesvariant_s.ImageCropUrl.isWebpAvailable
            ? celumimagesvariant_s.ImageCropUrl.isWebpAvailable
            : iswebpavailablebool_b && iswebpavailablebool_b,
      },
    });
  }, [item]);

  const getCTA = () => {
    try {
      if (!isUserLogin && contentAccessState === REGISTERED) {
        return ctatitleregistereduser_t.toUpperCase();
      } else if (!isUserSubscribe && contentAccessState === ACCOUNT_SUBSCRIPTION) {
        return ctatitlesubscribeduser_t.toUpperCase();
      } else {
        return ctatitle_t.toUpperCase();
      }
    } catch (e) {
      return ctatitle_t.toUpperCase();
    }
  };

  const getCtaButton = () => {
    if (!isUserLogin && contentAccessState === REGISTERED) {
      return (
        ctatitleregistereduser_t && (
          <div className="cardBtn">{ctatitleregistereduser_t.toUpperCase()}</div>
        )
      );
    } else if (!isUserSubscribe && contentAccessState === ACCOUNT_SUBSCRIPTION) {
      return (
        ctatitlesubscribeduser_t && (
          <div className="cardBtn">{ctatitlesubscribeduser_t.toUpperCase()}</div>
        )
      );
    } else {
      return ctatitle_t && <div className="cardBtn">{ctatitle_t.toUpperCase()}</div>;
    }
  };
  const getModalCtaAction = (e, showCbr) => {
    triggerAnalytics();
    if (showCbr) {
      e.preventDefault();
      cbrShowModal();
    } else {
      return "location.href='#'";
    }
    closeModal();
  };

  const modalShow = () => {
    if (
      (!isUserLogin && contentAccessState === REGISTERED) ||
      (!isUserSubscribe && contentAccessState === ACCOUNT_SUBSCRIPTION)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const successCallback = ({ showContent, isSubscribedContent }) => {
    if (showContent) {
      if (!MU.isIDMEnabled) window.location.href = ctaurlstring_s;
      else {
        return ['successCallbck', isSubscribedContent, 'destinationUrl', ctaurlstring_s];
      }
    }
  };

  const checkMarqueeAnim = () => {
    const txt01 = label_t ? label_t.length : 0;
    const txt02 =
      broadcasterdetails_s && broadcasterdetails_s.BroadcasterName
        ? ' on '.length + broadcasterdetails_s.BroadcasterName.length
        : 0;
    const txtLength = txt01 + txt02;
    return txtLength > 21 ? 'subTxt marqueeAnim' : 'subTxt';
  };

  return (
    <>
      <a
        className="card-content"
        tabIndex="-1"
        aria-hidden="true"
        style={{ outline: 'none' }}
        href={!modalShow() && ctaurlstring_s ? ctaurlstring_s : 'javascript:void(0)'}
        onClick={(e) => getModalCtaAction(e, modalShow())}
      >
        <div className="card-data card-events">
          <span className="screenreader">Event</span>
          <div className="leftBox">
            <div className="data_info" aria-hidden="true">
              <div className="info_ico">
                <div className="img-holder">
                  {imageCrops && imageCrops.value && imageCrops.value.Crops && (
                    <LazyLoad once={true} offset={100}>
                      <ImgCrop
                        imgCrop={imageCrops.value.Crops}
                        alt={imageCrops.value.AltText}
                        title={imageCrops.value.AltText}
                        isWebpAvailable={imageCrops.value.isWebpAvailable}
                        singleSrc={false}
                        gridClass="grid-12"
                      ></ImgCrop>
                    </LazyLoad>
                  )}
                </div>
              </div>
              <div className="info_Txt">
                {matchdate_tdt && <p className="dateSchudle">{getDateSchudle(matchdate_tdt)}</p>}
                {title && <p>{title}</p>}
              </div>
            </div>
            <div className="txtBox">
              {headline_t && <p className="text-truncate">{headline_t}</p>}
              {description_t && <p className="text-truncate">{description_t}</p>}
            </div>
          </div>
          <div className="rightBox">
            {!hideeventtime_b && matchdate_tdt ? (
              <p className="timeSchudle">{getTimeSchudle(matchdate_tdt)}</p>
            ) : (
              <p className="timeSchudle">&nbsp;</p>
            )}
            {label_t ? (
              <div className={checkMarqueeAnim()}>
                <p>
                  <span className="txtStatus">{label_t.toUpperCase()}</span>
                  {broadcasterdetails_s && broadcasterdetails_s.BroadcasterName && (
                    <span> on {broadcasterdetails_s.BroadcasterName}</span>
                  )}
                </p>
              </div>
            ) : (
              additionaltextcupdraw_t && (
                <div
                  className={additionaltextcupdraw_t.length > 21 ? 'subTxt marqueeAnim' : 'subTxt'}
                >
                  <p>
                    <span>{additionaltextcupdraw_t}</span>
                  </p>
                </div>
              )
            )}
            {getCtaButton()}
          </div>
        </div>
      </a>
      {cbrModalVisible && (
        <ErrorBoundary>
          <RegistrationModal
            closeModal={cbrCloseModal}
            successCallback={successCallback}
            fallback={<Loader />}
            previousActiveElement={document.activeElement}
            accessType={contentAccessState}
          />
        </ErrorBoundary>
      )}
    </>
  );
};

export default withTranslation()(CardEvents);
