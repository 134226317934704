/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import MU from '../../constants/muConstants';
const AppOnly = ({ data }) => {
  return (
    <div>
      <div className="app-only">
        <div className="app-only__gradient"></div>
        <div className="app-only__wrapper">
          <h1
            dangerouslySetInnerHTML={{
              __html: data && data.title,
            }}
          ></h1>
          <p>{data && data.description}</p>
          <div className="app-only__img">
            <div className="app-only__bg">
              <img className="img-large" src="/Assets/images/bg/mobile.png" alt="" />
              <img className="img-small" src="/Assets/images/bg/mobile-small.png" alt="" />
            </div>
            <div className="download__links">
              <section>
                <div className="mu-logo">
                  <span>
                    <img
                      className="google-play"
                      src="/Assets/images/bg/logo.png"
                      alt="manchester united official logo"
                    />
                  </span>
                </div>
                <div className="google-play icons">
                  <a href={data && data.googlePlayLink} target={data && data.googlePlayLinkTarget}>
                    {MU && MU.language === 'zh' ? (
                      <img src="/Assets/images/bg/android-button@2.png" />
                    ) : (
                      <img
                        src="/Assets/images/bg/google-play.png"
                        alt="download from google play store"
                      />
                    )}
                  </a>
                </div>
                <div className="app-store icons">
                  <a href={data && data.appStoreLink} target={data && data.appStoreLinkTarget}>
                    {MU && MU.language === 'zh' ? (
                      <img src="/Assets/images/bg/iOS-appstore-badge.png" />
                    ) : (
                      <img src="/Assets/images/bg/ios.png" alt="download from apple store" />
                    )}
                  </a>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(AppOnly);
