/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_SHOW_LIST,
  SET_SHOW_LIST,
  ERROR_SHOW_LIST,
  SET_MUTV_VIDEO_DATA,
} from '../constants/actions/actionConstant';
import { GET_CAROUSEL, GET_PODCAST_EPISODE } from '../constants/endpoints';
import api from '../utils/muApi';

/**
 * Action for mutv Shows carousel listing
 */
export const fetchShowListing = (
  dispatch,
  itemid,
  startIndex,
  itemsNumber,
  activeFilter,
  actionType,
  seriesType,
  tabSelected
) => {
  let isShowScreen = true;
  const route = `${GET_CAROUSEL}${encodeURI(itemid)}${
    seriesType && activeFilter
      ? seriesType === 'none'
        ? `~sid:~sort:${activeFilter}`
        : `~sid:${activeFilter}`
      : ''
  }/${startIndex}/${itemsNumber} `;
  dispatch(fetchShowList(actionType, activeFilter, isShowScreen, null, tabSelected));
  return api
    .get({ route })
    .then((response) => {
      dispatch(setShowList(response, actionType, activeFilter, isShowScreen, null, tabSelected));
    })
    .catch((err) => {
      dispatch(error(err));
    });
};

export const fetchShowList = (
  actionType,
  activeFilter,
  isShowScreen,
  defaultFilter,
  tabSelected,
  heroLayoutData
) => ({
  type: FETCH_SHOW_LIST,
  actionType,
  activeFilter,
  isShowScreen,
  defaultFilter,
  tabSelected,
  heroLayoutData,
});

export const setShowList = (
  response,
  actionType,
  activeFilter,
  isShowScreen,
  defaultFilter,
  tabSelected,
  heroLayoutData
) => ({
  type: SET_SHOW_LIST,
  response,
  actionType,
  activeFilter,
  isShowScreen,
  defaultFilter,
  tabSelected,
  heroLayoutData,
});

export const error = (response) => ({ type: ERROR_SHOW_LIST, response });

export const setVideoplayData = (dispatch, videoData) => {
  return dispatch({
    type: SET_MUTV_VIDEO_DATA,
    videoData,
  });
};

export const fetchVideoEpisode = (
  dispatch,
  startIndex,
  itemsNumber,
  EpisodeDetail,
  activeFilter,
  actionType,
  defaultFilter,
  tabSelected,
  heroLayoutData
) => {
  let isShowScreen = false;
  const route = `${GET_PODCAST_EPISODE}${activeFilter ? activeFilter : defaultFilter}~series:${
    EpisodeDetail.Series
  }~epNo:${EpisodeDetail.Episode}/${startIndex}/${itemsNumber}`;
  dispatch(
    fetchShowList(
      actionType,
      activeFilter,
      isShowScreen,
      defaultFilter,
      tabSelected,
      heroLayoutData
    )
  );
  return api
    .get({ route })
    .then((response) => {
      dispatch(
        setShowList(
          response,
          actionType,
          activeFilter,
          isShowScreen,
          defaultFilter,
          tabSelected,
          heroLayoutData
        )
      );
    })
    .catch((err) => {
      dispatch(error(err));
    });
};
