/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { createContext, useContext, useReducer } from 'react';
import { playerDetailReducer, playerDetailState } from '../reducers/playerDetailReducer';

export const PlayerDetailContext = createContext();

export const PlayerDetailProvider = ({ children }) => (
  <PlayerDetailContext.Provider value={useReducer(playerDetailReducer, playerDetailState)}>
    {children}
  </PlayerDetailContext.Provider>
);
export const usePlayerDetailValue = () => useContext(PlayerDetailContext);

export default PlayerDetailContext;
