/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { withTranslation } from 'react-i18next';
import MU from '../../constants/muConstants';
import { updateNtag } from '../../utils/utils';
import { getMatchDate } from '../../utils/momentDate';
import { showEditProfile } from '../../utils/gigyaScreen';
import { countryNameArr } from '../../constants/globalConstants';

const UserIntro = ({ t, gigyaData, favPlayer }) => {
  const messages = ['passionate', 'loyal', 'Vocal', 'devoted', 'dedicated'];
  const firstName = gigyaData && gigyaData.profile && gigyaData.profile.firstName;
  const lastName = gigyaData && gigyaData.profile && gigyaData.profile.lastName;
  const country = gigyaData && gigyaData.profile && gigyaData.profile.country;
  const age = gigyaData && gigyaData.profile && gigyaData.profile.age;
  const createdDate = gigyaData && gigyaData.created;
  const photoUrl = gigyaData && gigyaData.profile && gigyaData.profile.photoURL;

  const getBioMessage = () => {
    let msg = messages[Math.floor(Math.random() * messages.length)];
    return t(msg);
  };
  const updateProfileBioDic = (age, createdDate, str) => {
    const createdData = getMatchDate(createdDate, 'DD MMM YYYY');
    const arrString = [age, createdData];
    return updateNtag(arrString, 0, str);
  };

  const showFavPlayerDisc = (firstName, apostS, str) => {
    str = str && str.replace('.', '');
    return (
      <React.Fragment>
        {favPlayer && favPlayer.fullname_t && (
          <React.Fragment>
            <span className="first-capitalize">
              {firstName}
              <small className="lang-specific">{apostS}</small>
            </span>
            {updateNtag(['', ''], 0, str)}
            <span className="first-capitalize">{favPlayer.fullname_t}.</span>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  const updateProfileBioGraphic = (firstName, lastName, str, bioMessage, country) => {
    try {
      if (MU && MU.language === 'en') {
        if (countryNameArr.indexOf(country.toLowerCase()) !== -1) {
          country = 'the ' + country;
        }
      }
      let arrString = [firstName, lastName, bioMessage, country];
      return updateNtag(arrString, 0, str);
    } catch (e) {
      console.error('error in myunited into text n_tag');
    }
  };

  return (
    <React.Fragment>
      <div className="row">
        <div className="profile__description">
          <span>
            {updateProfileBioGraphic(
              firstName,
              lastName,
              t('isoneofourmost'),
              getBioMessage(),
              country
            )}
          </span>
          <span> {updateProfileBioDic(age, createdDate, t('yearoldsMyUniteddebutcameon'))}</span>
          <span> {showFavPlayerDisc(firstName, t('s'), t('favoriteplayeris'))}</span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(React.memo(UserIntro));
