/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import SignIn from '../Common/Login/SignIn';
import SignUp from '../Common/Login/SignUp';
import * as track from '../../utils/analytics';
import { RegisterKey, SignInKey } from '../../constants/globalConstants';
import { useSponsorValue } from '../../context/sponsorContext';
import { errorHandler } from '../../utils/gigyaScreen';
import { userLoginStatus } from '../../actions/sponsorAction';
import { getLanguage } from '../../utils/utils';

const TertiaryNav = ({ tertiaryNav, t, ishumberger }) => {
  const [{ userLoginDetail, isUserLogin }, dispatch] = useSponsorValue();
  const userName = userLoginDetail && userLoginDetail.profile && userLoginDetail.profile.firstName;
  const userPhoto = userLoginDetail && userLoginDetail.profile && userLoginDetail.profile.photoURL;

  const getLoginDetail = () => {
    if (window.gigya) {
      var evalResponse = function (response) {
        if (response.errorCode == 0) {
          userLoginStatus(dispatch, response);
        } else {
          errorHandler(response);
        }
      };
      window.gigya && window.gigya.accounts.getAccountInfo({ callback: evalResponse });
    }
  };

  const getNameValues = (nav) => {
    if (
      nav &&
      nav.fields &&
      nav.fields.manutds &&
      nav.fields.manutds.value &&
      nav.fields.manutds.value.__Name
    ) {
      return nav.fields.manutds.value.__Name;
    }
  };

  const trackLink = (e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: 'Profile Picture of User',
        destination_url: e && e.target && e.target.parentElement && e.target.parentElement.href,
      },
      e
    );
  };
  const renderTertinaryNav = () => {
    return (
      tertiaryNav &&
      tertiaryNav.map((nav, i) => {
        if (SignInKey === getNameValues(nav)) {
          return (
            <SignIn
              key={i}
              itemName={nav.fields && nav.fields.Name && nav.fields.Name.value}
              ishumberger={ishumberger}
              getLoginDetail={getLoginDetail}
            ></SignIn>
          );
        }
        if (RegisterKey === getNameValues(nav)) {
          return (
            <SignUp
              key={i}
              itemName={nav.fields && nav.fields.Name && nav.fields.Name.value}
              ishumberger={ishumberger}
              getLoginDetail={getLoginDetail}
            ></SignUp>
          );
        }
      })
    );
  };

  return (
    <div className="home-header__buttons nav-profile">
      {!isUserLogin ? (
        <div className="social-button" id="navloginRegisterLink">
          {renderTertinaryNav()}
        </div>
      ) : (
        <div className="header-profile nav-profile__wrapper" id="user_image2">
          <a
            href={`/${getLanguage()}/myunited`}
            aria-label="Profile Picture of User"
            onClick={(e) => trackLink(e)}
            aria-hidden="true"
            tabIndex="-1"
          >
            <div
              className="home-header__profile-icon icon-avatar-profile nav-profile__icon"
              style={
                userPhoto && {
                  backgroundImage: `url(${userPhoto})`,
                  backgroundPosition: '0 0',
                  backgroundSize: 'cover',
                }
              }
            >
              <img
                src="//:0"
                className="visually-hidden "
                aria-hidden="true"
                id="headerprofileimg2"
                alt="Profile pic"
                title="Profile pic"
              />
            </div>
          </a>
          <div className="nav-profile__name">
            <span className="loggedin">{`${t('Hi')} ${userName}`}</span>
            <a href={`/${getLanguage()}/myunited`} className="login-user-profile">
              {t('MyProfile')}
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(React.memo(TertiaryNav));
