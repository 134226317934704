/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import PlayerGridCard from './PlayerGridCard';

const PlayerGridGroup = ({ playerHeading, t }) => {
  return (
    <React.Fragment>
      <div className="team-grid__item">
        <div className="grid-12">
          {playerHeading &&
            playerHeading.value &&
            playerHeading.value.doclist &&
            playerHeading.value.doclist.docs.length > 0 && (
              <h2 className="team-grid__heading"> {t(`${playerHeading.key}`)} </h2>
            )}
        </div>
        <ul>
          {playerHeading &&
            playerHeading.value &&
            playerHeading.value.doclist &&
            playerHeading.value.doclist.docs.length > 0 &&
            playerHeading.value.doclist.docs.map((playerObj) => {
              if (
                playerObj.destinationurl_s &&
                playerObj.destinationurl_s !== 'undefined' &&
                playerObj.destinationurl_s !== ''
              ) {
                return <PlayerGridCard playerObj={playerObj} />;
              }
            })}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(PlayerGridGroup);
