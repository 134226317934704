/**
 *  © 2022-2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { data, analytics } from '../../../utils/analytics';
import { COMINGUPCAROUSAL, ECOM_PAGE } from '../../../constants/containerConstants';
import LazyLoad from 'react-lazyload';
import ImgCrop from '../../Common/ImgCrop';

const CardEcommerce = (props) => {
  const { item, getDateSchudle, getTimeSchudle } = props;
  const {
    itemid_s,
    contenttype_t,
    taglist_sm,
    __published_tdt,
    authorname_s,
    ctaurlstring_s,
    variant_t,
    celumimagesvariant_s,
    iconcomingup_s,
    matchdate_tdt,
    title,
    ctatitle_t,
    headline_t,
    description_t,
    label_t,
    _name,
    broadcasterdetails_s,
    iswebpavailablebool_b,
    alttext_s,
    hideeventtime_b,
  } = item;

  const triggerAnalytics = () => {
    const initialData = data('card');
    analytics({
      ...initialData,
      card_name: title + ': ' + headline_t + ': ' + description_t,
      container_type: COMINGUPCAROUSAL,
      item_id: itemid_s,
      content_type: ECOM_PAGE,
      button_name: ctatitle_t,
      item_name: _name,
      tag: taglist_sm,
      created_datetime: __published_tdt !== undefined ? __published_tdt : 'N/A',
      author: authorname_s,
      destination_url: ctaurlstring_s,
    });
  };

  function cardClickHandler() {
    triggerAnalytics();
  }

  const [imageCrops, setImageCrops] = useState({});

  useEffect(() => {
    setImageCrops({
      value: {
        Crops:
          celumimagesvariant_s &&
          celumimagesvariant_s.ImageCropUrl &&
          celumimagesvariant_s.ImageCropUrl.CropUrl
            ? celumimagesvariant_s.ImageCropUrl.CropUrl
            : iconcomingup_s && iconcomingup_s,
        AltText:
          celumimagesvariant_s &&
          celumimagesvariant_s.ImageCropUrl &&
          celumimagesvariant_s.ImageCropUrl.AltText
            ? celumimagesvariant_s.ImageCropUrl.AltText
            : alttext_s,
        isWebpAvailable:
          celumimagesvariant_s &&
          celumimagesvariant_s.ImageCropUrl &&
          celumimagesvariant_s.ImageCropUrl.isWebpAvailable
            ? celumimagesvariant_s.ImageCropUrl.isWebpAvailable
            : iswebpavailablebool_b && iswebpavailablebool_b,
      },
    });
  }, [item]);

  const checkMarqueeAnim = () => {
    const txt01 = label_t ? label_t.length : 0;
    const txt02 =
      broadcasterdetails_s && broadcasterdetails_s.BroadcasterName
        ? ' on '.length + broadcasterdetails_s.BroadcasterName.length
        : 0;
    const txtLength = txt01 + txt02;
    return txtLength > 16 ? 'subTxt marqueeAnim' : 'subTxt';
  };

  return (
    <a
      className="card-content"
      tabIndex="-1"
      aria-hidden="true"
      style={{ outline: 'none' }}
      href={ctaurlstring_s ? ctaurlstring_s : 'javascript:void(0)'}
      onClick={(e) => cardClickHandler()}
    >
      {variant_t === 'Kit' ? (
        <div className="card-data card-ecommerce kitTheme">
          <span className="screenreader">Ecommerce</span>
          {imageCrops && imageCrops.value && imageCrops.value.Crops && (
            <div className="leftBox">
              <div className="img-holder">
                <LazyLoad once={true} offset={100}>
                  <ImgCrop
                    imgCrop={imageCrops.value.Crops}
                    alt={imageCrops.value.AltText}
                    title={imageCrops.value.AltText}
                    isWebpAvailable={imageCrops.value.isWebpAvailable}
                    singleSrc={false}
                    gridClass="grid-12"
                  ></ImgCrop>
                </LazyLoad>
              </div>
            </div>
          )}

          <div className={`rightBox ${!imageCrops && 'kitMainBox'}`}>
            {matchdate_tdt && (
              <p className="dateSchudle">
                {getDateSchudle(matchdate_tdt)} - {getTimeSchudle(matchdate_tdt)}
              </p>
            )}
            {title && (
              <div className={title.length > 29 ? 'subTxt marqueeAnim' : 'subTxt'}>
                <p className="titletxt">{title}</p>
              </div>
            )}
            {ctatitle_t && <div className="cardBtn">{ctatitle_t}</div>}
          </div>
        </div>
      ) : (
        <div
          className={
            variant_t === 'Black'
              ? `card-data card-ecommerce blackTheme`
              : `card-data card-ecommerce defaultTheme`
          }
        >
          <span className="screenreader">Ecommerce</span>
          <div className="leftBox">
            <div className="data_info" aria-hidden="true">
              <div className="info_ico">
                <div className="img-holder">
                  {imageCrops && imageCrops.value && imageCrops.value.Crops && (
                    <LazyLoad once={true} offset={100}>
                      <ImgCrop
                        imgCrop={imageCrops.value.Crops}
                        alt={imageCrops.value.AltText}
                        title={imageCrops.value.AltText}
                        isWebpAvailable={imageCrops.value.isWebpAvailable}
                        singleSrc={false}
                        gridClass="grid-12"
                      ></ImgCrop>
                    </LazyLoad>
                  )}
                </div>
              </div>
              <div className="info_Txt">
                {matchdate_tdt && <p className="dateSchudle">{getDateSchudle(matchdate_tdt)}</p>}
                {title && <p>{title}</p>}
              </div>
            </div>
            <div className="txtBox">
              {headline_t && <p className="text-truncate">{headline_t}</p>}
              {description_t && <p className="text-truncate">{description_t}</p>}
            </div>
          </div>
          <div className="rightBox">
            {!hideeventtime_b && matchdate_tdt ? (
              <p className="timeSchudle">{getTimeSchudle(matchdate_tdt)}</p>
            ) : (
              <p className="timeSchudle">&nbsp;</p>
            )}
            <div className={checkMarqueeAnim()}>
              <p>
                {label_t && <span className="txtStatus">{label_t.toUpperCase()}</span>}
                {broadcasterdetails_s && broadcasterdetails_s.BroadcasterName && (
                  <span> on {broadcasterdetails_s.BroadcasterName}</span>
                )}
              </p>
            </div>
            {ctatitle_t && <div className="cardBtn">{ctatitle_t.toUpperCase()}</div>}
          </div>
        </div>
      )}
    </a>
  );
};

export default withTranslation()(CardEcommerce);
