/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

export const leagueTableConstants = {
  position_t: 'Position',
  gamesplayed_t: 'GamesPlayed',
  wins_t: 'Wins',
  draws_t: 'Draws',
  lost_t: 'Lost',
  goalsfor_t: 'GoalsFor',
  goalsagainst_t: 'GoalsAgainst',
  goalsdifference_t: 'Goalsdifference',
  teampoints_t: 'Teampoints',
  '0-col-draw': 'FirstMatchDraw',
  '1-col-draw': 'SecondMatchDraw',
  '2-col-draw': 'ThirdMatchDraw',
  '3-col-draw': 'FourthMatchDraw',
  '4-col-draw': 'FifthMatchDraw',
  '0-col-won': 'FirstMatchWin',
  '1-col-won': 'SecondMatchWin',
  '2-col-won': 'ThirdMatchWin',
  '3-col-won': 'FourthMatchWin',
  '4-col-won': 'FifthMatchWin',
  '0-col-lost': 'FirstMatchlost',
  '1-col-lost': 'SecondMatchlost',
  '2-col-lost': 'ThirdMatchlost',
  '3-col-lost': 'FourthMatchlost',
  '4-col-lost': 'FifthMatchlost',
};
