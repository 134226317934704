/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import MU from '../../constants/muConstants';
import LanguageItems from './LanguageItems';
const LanguageSwitcher = ({ Language, t }) => {
  const [isListOpen, setIsListOpen] = useState(false);
  const openList = (e) => {
    let code = e.keyCode || e.which;
    if (code === 13 || e.type === 'click') {
      setIsListOpen(true);
    } else if (isListOpen && code === 40) {
      e.preventDefault();
      if (e.target && e.target.parentElement) {
        const focusEl = e.target.parentElement.querySelector('li');
        focusEl && focusEl.focus();
      }
    }
    if (code === 27) {
      setIsListOpen(false);
      const elm = document.querySelector('.home-dropdown .accessible-dropdown');
      elm && elm.focus();
    }
  };

  const openListKeyDown = (e) => {
    let code = e.keyCode || e.which;
    if (code === 40) {
      e.preventDefault();
    }
  };

  const renderLanguage = () => {
    return (
      <React.Fragment>
        <div
          className={`home-dropdown ${isListOpen ? 'hover' : ''}`}
          data-container="language-switcher"
        >
          <span
            style={{ background: 'none' }}
            className="accessible-dropdown"
            aria-label={`Language dropdown ${MU && MU.language && MU.language.toUpperCase()}`}
            aria-expanded={isListOpen ? 'true' : 'false'}
            onKeyUp={(e) => openList(e)}
            onKeyDown={(e) => openListKeyDown(e)}
            tabIndex="0"
            aria-haspopup="listbox"
            role="listbox"
          >
            <span role="option" aria-hidden="true" tabIndex="-1"></span>
            <span>{MU && MU.language ? `${MU.language} ` : 'EN '} </span>
            <span className="dropdown-arrow"></span>
            <span className="icon-globe"></span>
          </span>
          <ul id="listbox1" role="listbox" tabIndex="-1" className="language-dropdown">
            {Language &&
              Language.length > 0 &&
              Language.filter(
                (val) => val.fields && val.fields.Iso && val.fields.Iso.value !== MU.language
              ).map(
                (item, index) =>
                  item.fields &&
                  item.fields.Iso &&
                  item.fields.Iso.value && (
                    <LanguageItems
                      key={index}
                      languageItem={item}
                      totalLength={Language.length - 1}
                      activeIndex={index}
                      setIsListOpen={setIsListOpen}
                    />
                  )
              )}
          </ul>
        </div>
      </React.Fragment>
    );
  };

  return renderLanguage();
};

export default withTranslation()(React.memo(LanguageSwitcher));
