/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import Accordion from '../Accordion';
import ImgCrop from '../../Common/ImgCrop';
import LazyLoad from 'react-lazyload';
import { withTranslation } from 'react-i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { getItemId } from '../../../utils/utils';
const HeroAccordion = (props) => {
  const { t, fields } = props;
  const imageCrops = fields.Image && fields.Image.value && fields.Image.value.Crops;
  const imageAltText = fields.Image && fields.Image.value && fields.Image.value.AltText;
  const isWebpAvailable = fields.Image && fields.Image.value && fields.Image.value.isWebpAvailable;
  const AccordionYes = fields.AccordionYes && fields.AccordionYes.value;
  const itemId = getItemId(props);
  const showGradient = () => {
    if (
      AccordionYes ||
      (fields.Headline && fields.Headline.value) ||
      (fields.PrimaryCTATitle &&
        fields.PrimaryCTATitle.value &&
        fields.PrimaryCTAURL &&
        fields.PrimaryCTAURL.value &&
        fields.PrimaryCTAURL.value.href) ||
      (fields.SecondaryCTATitle &&
        fields.SecondaryCTATitle.value &&
        fields.SecondaryCTAURL &&
        fields.SecondaryCTAURL.value &&
        fields.SecondaryCTAURL.value.href)
    ) {
      return true;
    }
    return false;
  };
  return (
    <div className={`hero__accordian ${AccordionYes ? 'accord-space' : ''}`}>
      <div className="home-hero card-xl">
        <div className="img-holder">
          <LazyLoad once={true} offset={100}>
            <ImgCrop
              imgCrop={imageCrops}
              alt={imageAltText}
              isWebpAvailable={isWebpAvailable}
              title={imageAltText}
              titleAvailable={showGradient()}
            ></ImgCrop>
          </LazyLoad>
        </div>
        <div className="hero__accordian__intro">
          <div className="contact-us__block">
            {fields.Headline && fields.Headline.value && (
              <h1 className="home-hero__title">
                <span id={itemId}>
                  <Text field={fields.Headline} />
                </span>
              </h1>
            )}
            {fields.CTATitle &&
              fields.CTATitle.value &&
              fields.CTAURL &&
              fields.CTAURL.value &&
              fields.CTAURL.value.href && (
                <div className="red-btn-skew">
                  <a href={fields.CTAURL.value.href} role="button">
                    <span>{fields.CTATitle.value}</span>
                    <i className="icon-follow-live"></i>
                  </a>
                </div>
              )}
          </div>
        </div>
      </div>
      <div className="container">
        <Accordion isHeroAccordion={true} {...props} />
      </div>
    </div>
  );
};
export default withTranslation()(HeroAccordion);
