/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { getPublisherType3, jsonStringify } from '../../utils/structureData';
const NewsArticleData = (props) => {
  const { newsArticleStructureData } = props.structureData;
  const data = newsArticleStructureData && {
    '@context': newsArticleStructureData.context,
    '@type': newsArticleStructureData.type,
    headline: newsArticleStructureData.headline,
    description: newsArticleStructureData.description,
    datePublished: newsArticleStructureData.datePublished,
    dateModified: newsArticleStructureData.dateModified,
    publisher: getPublisherType3(newsArticleStructureData.publisher),
    mainEntityOfPage: {
      '@type':
        newsArticleStructureData.mainEntityOfPage && newsArticleStructureData.mainEntityOfPage.type,
      '@id':
        newsArticleStructureData.mainEntityOfPage && newsArticleStructureData.mainEntityOfPage.id,
    },
    image: {
      '@type': newsArticleStructureData.image && newsArticleStructureData.image.type,
      url: newsArticleStructureData.image && newsArticleStructureData.image.url,
    },
    author: {
      '@type': newsArticleStructureData.author && newsArticleStructureData.author.type,
      name: newsArticleStructureData.author && newsArticleStructureData.author.name,
    },
  };
  return newsArticleStructureData ? (
    <Helmet>
      <script data-schema="NewsArticle" type="application/ld+json">
        {`${jsonStringify(data)}`}
      </script>
    </Helmet>
  ) : null;
};
export default NewsArticleData;
