/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { Video } from '../../data-transform/components/Video';
import { localeConfig } from '../../utils/cardDateFormat';
import ImgCrop from '../Common/ImgCrop';
import { useModal } from '../Common/Modal';
import * as track from '../../utils/analytics';
import { SEARCH } from '../../constants/containerConstants';
import loadable from '@loadable/component';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import ErrorBoundary from '../Common/ErrorBoundary';
import {
  createDestinationUrl,
  createTagList,
  isMutvVideo,
  validateCbr,
  getLoggedInStatus,
} from '../../utils/utils';
import { useSponsorValue } from '../../context/sponsorContext';
import { ANONYMOUS, REGISTERED } from '../../constants/globalConstants';
import Loader from '../Common/Loader';
import AccessBadge from '../Common/AccessBadge';
const RegistrationModal = loadable(() => import('../Modals/RegistrationModal/RegistrationModal'));
const VideoModal = loadable(() => import('../Modals/VideoModal/VideoModal'));
const VideosSearch = ({ results, resultsCount, t, language }) => {
  const [{ isUserLogin, isLoginChecked, isUserSubscribe, isUserSkyId }] = useSponsorValue();
  const { showModal, closeModal, isModalVisible } = useModal();
  const {
    showModal: cbrShowModal,
    closeModal: cbrCloseModal,
    isModalVisible: cbrModalVisible,
  } = useModal();
  const [fields, setFields] = useState({});
  const [itemId, setItemId] = useState(null);
  const contentAccessValue = useRef({});

  const getAnalyticsData = (fields, itemId, result, contentStatus) => {
    const initialData = track.data('card');
    return {
      ...initialData,
      card_name: fields.ShortHeadline && fields.ShortHeadline.value,
      container_type: SEARCH,
      item_id: itemId,
      content_type: fields.ContentType && fields.ContentType.value,
      content_status: contentStatus,
      subscription_status: getLoggedInStatus({
        isLoginChecked,
        isUserLogin,
        isUserSubscribe,
        isUserSkyId,
      }),
      tag: fields.manutds && fields.manutds.value && fields.manutds.value.Tags,
      created_datetime: fields.__Published && fields.__Published.value,
      author: result.authornameinfo_s && result.authornameinfo_s, //fields.AuthorName && fields.AuthorName.value,
      destination_url:
        fields.manutds && fields.manutds.value && fields.manutds.value.DestinationUrl,
    };
  };

  const trackCard = (fields, itemId, e, result, contentStatus) => {
    track.analytics(getAnalyticsData(fields, itemId, result, contentStatus), e);
  };

  const formatCardDate = (time) => {
    if (time) {
      return localeConfig(time, language, t);
    }
  };

  const linkURL = (result) => {
    const tagList = result.taglist_sm;
    return tagList && isMutvVideo(createTagList(tagList, result.category_s))
      ? createDestinationUrl(result.destinationurl_s)
      : '#';
  };

  const openSearchModal = (result, e) => {
    e.preventDefault();
    const destPage = linkURL(result);
    if (destPage && destPage !== '#') {
      window.location.href = destPage;
    } else {
      const showCbr = validateCbr({
        isUserLogin,
        isUserSubscribe,
        contentAccess: result.contentaccess_t,
        contentType: result.contenttype_t,
        manutds: result.taglist_sm,
        isAws: true,
      });
      const videoData = Video(result, true);
      setFields(videoData.fields);
      setItemId(videoData.rendering && videoData.rendering.dataSource);
      trackCard(
        videoData.fields,
        videoData.rendering && videoData.rendering.dataSource,
        e,
        result,
        showCbr && showCbr.accessCheck
      );
      if (showCbr && showCbr.value) {
        contentAccessValue.current = { value: showCbr.accessCheck, videoData, result };
        cbrShowModal();
      } else {
        showModal();
      }
    }
  };

  const renderHtml = (result, index) => {
    result.appearance_tdt = result.__published_tdt;
    if (result.contenttype_t.toLowerCase() === 'blogentry' && result.isvideoasset_b == true) {
      result.contenttype_t = 'video';
      result.teaser_t = result.blogdescription_t;
      result.shortheadline_t = result.blogheading_t;
    }
    return (
      <React.Fragment>
        {((result.contenttype_t && result.contenttype_t.toLowerCase() === 'video') ||
          (result.contenttype_t.toLowerCase() === 'blogentry' &&
            result.isvideoasset_b == true)) && (
          <div>
            <div className="search__items">
              <div
                className="search__items__detail video"
                onClick={(e) => openSearchModal(result, e)}
              >
                <div className="details">
                  <div className="detail__left">
                    <div className="img-holder">
                      <ImgCrop
                        imgCrop={
                          result.celumimagesvariant_s &&
                          result.celumimagesvariant_s.ImageCropUrl.CropUrl
                        }
                        alt={result.alttext_s}
                        title={result.alttext_s}
                      />
                    </div>
                    <span>
                      <i className="icon-play" aria-hidden="true"></i>
                    </span>
                  </div>
                  <div className="detail__right">
                    <AccessBadge
                      contentAcessLocal={result.contentaccess_t}
                      contentTypeLocal={result.contenttype_t}
                      tagListLocal={result.taglist_sm}
                      isSearchPage={true}
                    />
                    <h2>
                      <span className="title">
                        <span tabIndex="0" role="link">
                          {result.shortheadline_t}
                          <span className="screenreader">{t('Video')}</span>
                        </span>
                      </span>
                    </h2>
                    <h2 className="mobile-view">
                      <span>{result.shortheadline_t}</span>
                    </h2>
                    <p className="teasure">
                      {result.appearance_tdt && (
                        <RichText
                          tag="strong"
                          field={{ value: formatCardDate(result.appearance_tdt) }}
                          editable={false}
                        />
                      )}
                      {result.appearance_tdt && <span>{' - '}</span>}
                      {result.teaser_t}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  const successCallback = ({ showContent }) => {
    if (showContent) {
      showModal();
    }
  };

  const renderCBSModal = () => {
    const refValue = contentAccessValue.current;
    return (
      <ErrorBoundary>
        <RegistrationModal
          successCallback={successCallback}
          closeModal={cbrCloseModal}
          fallback={<Loader />}
          previousActiveElement={document.activeElement}
          accessType={contentAccessValue.current.value}
          analyticsObj={getAnalyticsData(
            refValue.videoData && refValue.videoData.fields,
            refValue.videoData &&
              refValue.videoData.rendering &&
              refValue.videoData.rendering.dataSource,
            refValue.result,
            refValue.value
          )}
        />
      </ErrorBoundary>
    );
  };

  return (
    <React.Fragment>
      {resultsCount > 0 && (
        <div className="grid-12">
          {results &&
            results.map((result, index) => <div key={index}>{renderHtml(result, index)}</div>)}
          {cbrModalVisible && renderCBSModal()}
          {isModalVisible && (
            <ErrorBoundary>
              <VideoModal
                cardName={fields.Teaser && fields.Teaser.value}
                author={fields.AuthorName && fields.AuthorName.value}
                closeModal={closeModal}
                itemid={itemId}
                data={fields}
                contentAccessState={contentAccessValue.current.value}
              />
            </ErrorBoundary>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(VideosSearch);
