/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { PlayerProfileCommon } from './PlayerProfileCommon';
import { PlayerDetailProvider } from '../../context/playerDetailContext';
import { getItemId } from '../../utils/utils';
const PlayerProfileDetail = (props) => (
  <PlayerDetailProvider>
    <PlayerProfileContainer {...props} />
  </PlayerDetailProvider>
);
const PlayerProfileContainer = (props) => {
  const { fields } = props;
  const itemId = getItemId(props);
  return fields && PlayerProfileCommon(fields, itemId);
};

export default PlayerProfileDetail;
