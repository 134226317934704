/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import LeftPanel from './LeftPanel';
import StatsBar from '../Common/StatsBar';
import SponsorPanel from './SponsorPanel';
import ImagePanel from './ImagePanel';
import { getStatsBarData } from '../../utils/utils';
import ErrorBoundary from '../Common/ErrorBoundary';

const HeadToHeadNumberDonut = ({ item, t, gridClass, carousalClass }) => {
  const isPlayer =
    item && item.opta_statentitytype_t && item.opta_statentitytype_t.toLowerCase() === 'player'
      ? true
      : false;
  const PlayerData = (keyObj, key) => {
    return (
      <li key={key}>
        <span aria-hidden="true" className="scoreA">
          {`${keyObj[0].Value}${
            keyObj[0].IsPercentage === 'true' || keyObj[0].IsPercentage === true ? '%' : ''
          }`}
        </span>
        <span aria-hidden="true" className="score-info">
          {keyObj[0].StatsName}
        </span>
        <span aria-hidden="true" className="scoreB">
          {`${keyObj[1].Value}${
            keyObj[1].IsPercentage === 'true' || keyObj[1].IsPercentage === true ? '%' : ''
          }`}
        </span>
        <div className="screenreader">
          {`${keyObj[0].Value} ${
            keyObj[0].IsPercentage === 'true' || keyObj[0].IsPercentage === true ? '%' : ''
          } ${keyObj[0].StatsName} ${t('by')} ${
            item.opta_statentities_t[0].KnownName
              ? item.opta_statentities_t[0].KnownName
              : item.opta_statentities_t[0].LastName
          } ${t('Shirtnumber')} ${item.opta_statentities_t[0].JerseyNumber}
                                       ${keyObj[1].Value} ${
            keyObj[1].IsPercentage === 'true' || keyObj[1].IsPercentage === true ? '%' : ''
          } ${keyObj[1].StatsName} ${t('by')} ${
            item.opta_statentities_t[1].KnownName
              ? item.opta_statentities_t[1].KnownName
              : item.opta_statentities_t[1].LastName
          } ${t('Shirtnumber')} ${item.opta_statentities_t[1].JerseyNumber}`}
        </div>
      </li>
    );
  };
  const TeamData = (keyObj, key) => {
    return (
      <li key={key}>
        <span aria-hidden="true" className="scoreA">
          {keyObj[0].Value}
        </span>
        {key > 0 && (
          <span aria-hidden="true" className="score-info">
            {`${keyObj[0].StatsName}${
              keyObj[0].IsPercentage === 'true' || keyObj[0].IsPercentage === true ? '%' : ''
            }`}
          </span>
        )}
        {key > 0 &&
          item.opta_statentities_t[0] &&
          item.opta_statentities_t[0].statValues[0].Value === 0 &&
          item.opta_statentities_t[1].statValues[0].Value === 0 && (
            <span aria-hidden="true" className="score-info">
              {keyObj[0].StatsName}
            </span>
          )}
        <span className="scoreB" aria-hidden="true">
          {`${keyObj[1].Value}${
            keyObj[1].IsPercentage === 'true' || keyObj[1].IsPercentage === true ? '%' : ''
          }`}
        </span>
        <div className="screenreader">
          {`${keyObj[0].Value} ${keyObj[0].StatsName} ${t('by')} ${
            item.opta_statentities_t[0].TeamName
          } ${keyObj[1].Value} ${keyObj[0].StatsName} ${t('by')} ${
            item.opta_statentities_t[1].TeamName
          }`}
        </div>
      </li>
    );
  };
  return (
    item && (
      <div className={gridClass}>
        <div className={carousalClass}>
          <div
            className={`stats-card head-to-head-number ${item.IsMUEntityClass} ${
              isPlayer ? 'player-section' : 'team-section'
            }`}
          >
            <SponsorPanel item={item} />
            <div className="stats-card__info">
              <LeftPanel item={item} />
              <div className="stats-card__info__right">
                <div className="chart-area">
                  <div className={`players ${isPlayer ? '' : 'piechart'}`}>
                    {isPlayer && item.opta_statentities_t && item.opta_statentities_t.length > 1 && (
                      <div className="screenreader">
                        {`${
                          item.opta_statentities_t[0].KnownName
                            ? item.opta_statentities_t[0].KnownName
                            : item.opta_statentities_t[0].LastName
                        } ${t('Shirtnumber')}
                                ${item.opta_statentities_t[0].JerseyNumber} ${t('VS')} ${
                          item.opta_statentities_t[1].KnownName
                            ? item.opta_statentities_t[1].KnownName
                            : item.opta_statentities_t[1].LastName
                        } ${t('Shirtnumber')} ${item.opta_statentities_t[1].JerseyNumber}`}
                      </div>
                    )}
                    <div className="players-vs-player" aria-hidden={isPlayer ? true : false}>
                      {!isPlayer &&
                        item.opta_statentities_t &&
                        item.opta_statentities_t.length > 1 && (
                          <div className="screenreader">
                            {`${item.opta_statentities_t[0].TeamName} ${t('VS')} ${
                              item.opta_statentities_t[1].TeamName
                            }`}
                          </div>
                        )}
                      <div className="player__host" aria-hidden={!isPlayer ? true : false}>
                        <div className="player-img player-image">
                          <ErrorBoundary>
                            <ImagePanel item={item.opta_statentities_t[0]} statsType={isPlayer} />
                          </ErrorBoundary>
                          {isPlayer &&
                            item.opta_statentities_t &&
                            item.opta_statentities_t.length > 1 && (
                              <React.Fragment>
                                <span className="notification">
                                  {item.opta_statentities_t[0].JerseyNumber}
                                </span>
                                <h3>
                                  {item.opta_statentities_t[0].KnownName
                                    ? item.opta_statentities_t[0].KnownName
                                    : item.opta_statentities_t[0].LastName}
                                </h3>
                              </React.Fragment>
                            )}
                        </div>
                      </div>
                      {isPlayer ? (
                        <div className="vs">
                          <div>
                            <span>{t('VS')}</span>
                          </div>
                        </div>
                      ) : (
                        <div className="piechart-area" aria-hidden="true">
                          <div className="piechart-inner">
                            <svg height="200" width="200" viewBox="10 -8 26 70">
                              <circle
                                className="donut-hole"
                                cx="21"
                                cy="21"
                                r="15.91549430918954"
                                fill="transparent"
                                stroke={item.homeColor}
                                strokeWidth="16"
                                strokeDasharray={item.dashArrHome}
                                strokeDashoffset={item.dashOffsetHome}
                              ></circle>
                              <circle
                                className="donut-hole"
                                cx="21"
                                cy="21"
                                r="15.91549430918954"
                                fill="transparent"
                                stroke={item.awayColor}
                                strokeWidth="16"
                                strokeDasharray={item.dashArrAway}
                                strokeDashoffset={item.dashOffsetAway}
                              ></circle>
                            </svg>
                          </div>
                        </div>
                      )}
                      <div className="player__opponent" aria-hidden="true">
                        <div className="player-img player-image">
                          <ErrorBoundary>
                            <ImagePanel item={item.opta_statentities_t[1]} statsType={isPlayer} />
                          </ErrorBoundary>
                          {isPlayer &&
                            item.opta_statentities_t &&
                            item.opta_statentities_t.length > 1 && (
                              <React.Fragment>
                                <span className="notification">
                                  {item.opta_statentities_t[1].JerseyNumber}
                                </span>
                                <h3>
                                  {item.opta_statentities_t[1].KnownName
                                    ? item.opta_statentities_t[1].KnownName
                                    : item.opta_statentities_t[1].LastName}
                                </h3>
                              </React.Fragment>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="players__match-details">
                      <ul>
                        {item.filterEntitiObj.map((keyObj, key) =>
                          isPlayer ? PlayerData(keyObj, key) : TeamData(keyObj, key)
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <StatsBar item={getStatsBarData(item)} type="statsCard"></StatsBar>
          </div>
        </div>
      </div>
    )
  );
};
export default withTranslation()(HeadToHeadNumberDonut);
