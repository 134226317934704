/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import { ArticleDetailContentStyle } from '../constants/articleDetailConstants';
import MU from '../constants/muConstants';
import { isEmpty } from 'lodash';

export const getContentType = (fieldData) => {
  try {
    return (
      fieldData &&
      fieldData.ContentType &&
      fieldData.ContentType.value &&
      fieldData.ContentType.value.toLowerCase()
    );
  } catch (e) {
    return '';
  }
};

export const getItemStyleByContent = (fieldData) => {
  let style = { className: 'home-article-detail__video-panel', gridClass: 'grid-7' };
  try {
    if (fieldData) {
      const contentType = getContentType(fieldData);
      if (contentType.length > 0) {
        style = ArticleDetailContentStyle[contentType && contentType.toLowerCase()];
        if (!style) {
          style = { className: 'home-article-detail__image-panel', gridClass: 'grid-7' };
        }
      }
    }
  } catch (e) {
    console.log('error in getItemStyleByContent', e);
    style = { className: '', gridClass: 'grid-7' };
  }
  return style;
};

export const skip_focus = (id) => {
  const contentContainer = document.querySelector(id);
  if (contentContainer) {
    window.scroll({ top: 0, behavior: 'smooth' });
    contentContainer.setAttribute('tabIndex', '0');
    setTimeout(() => {
      contentContainer.focus();
      contentContainer.removeAttribute('tabindex');
    }, 500);
  }
};

export const checkOrentation = () => {
  const screenorintation = window.matchMedia('(orientation: portrait)');
  if (screenorintation.matches) {
    return 'portrait';
  } else {
    return 'landscape';
  }
};

export const throttle = (fn, wait) => {
  let time = Date.now();
  return () => {
    if (time + wait - Date.now() < 0) {
      fn();
      time = Date.now();
    }
  };
};

export const getAMPVideoIframeURL = (imgUrl, MediaId) => {
  let iframeSrc = '';
  try {
    if (MU && MU.brightcovePlayerUrl && MU.brightcovePlayerId && MU.brightcoveAccountId) {
      iframeSrc = MU.brightcovePlayerUrl;
      iframeSrc = iframeSrc && iframeSrc.replace('{0}', MediaId);
      iframeSrc = iframeSrc && iframeSrc.replace('{1}', MU.brightcovePlayerId);
      iframeSrc = iframeSrc && iframeSrc.replace('{2}', MU.brightcoveAccountId);
      iframeSrc = iframeSrc && iframeSrc.replace('{3}', imgUrl);
    }
  } catch (e) {
    console.log('error in getAMPVideoIFrameURL', e);
    iframeSrc = '';
  }
  return iframeSrc;
};

export const replaceDoubleQuoteWithQTag = (text) => {
  let updatedText = text;
  try {
    if (!isEmpty(text)) {
      let count = 0;
      let startDoubleQuote = false,
        noReplace = false;

      for (let i = 0; i < text.length; i++) {
        if (text[i] == '"') {
          count++;
          if (count % 2 == 1) startDoubleQuote = true;
          else startDoubleQuote = false;

          if (i > 0 && text[i - 1] == '=') {
            noReplace = true;
            continue;
          }

          if (!startDoubleQuote && noReplace) {
            noReplace = false;
            continue;
          }

          if (startDoubleQuote && noReplace) continue;
          else if (startDoubleQuote && !noReplace)
            updatedText =
              updatedText.substring(0, i) +
              '“' +
              updatedText.substring(i + 1, updatedText.length + 1);
          else if (!startDoubleQuote && !noReplace)
            updatedText =
              updatedText.substring(0, i) +
              '”' +
              updatedText.substring(i + 1, updatedText.length + 1);
        }
      }
      updatedText = updatedText.replace('&ldquo;', '<q>&ldquo;').replace('&rdquo;', '&rdquo;</q>');
      updatedText = updatedText.replace('“', '<q>“').replace('”', '”</q>');
    }
  } catch (e) {
    console.log('Error in replaceDoubleQuoteWithQTag', e);
    updatedText = '';
  }
  return updatedText;
};

export const replaceDoubleQuoteWithBlockQuoteTag = (text) => {
  let updatedText = text;
  try {
    if (!isEmpty(text)) {
      let count = 0;
      let startDoubleQuote = false,
        noReplace = false;

      for (let i = 0; i < text.length; i++) {
        if (text[i] == '"') {
          count++;
          if (count % 2 == 1) startDoubleQuote = true;
          else startDoubleQuote = false;

          if (i > 0 && text[i - 1] == '=') {
            noReplace = true;
            continue;
          }

          if (!startDoubleQuote && noReplace) {
            noReplace = false;
            continue;
          }

          if (startDoubleQuote && noReplace) continue;
          else if (startDoubleQuote && !noReplace)
            updatedText =
              updatedText.substring(0, i) +
              '“' +
              updatedText.substring(i + 1, updatedText.length + 1);
          else if (!startDoubleQuote && !noReplace)
            updatedText =
              updatedText.substring(0, i) +
              '”' +
              updatedText.substring(i + 1, updatedText.length + 1);
        }
      }
      updatedText = updatedText
        .replace('&ldquo;', '<blockquote>&ldquo;')
        .replace('&rdquo;', '&rdquo;</blockquote>');
      updatedText = updatedText.replace('“', '<blockquote>“').replace('”', '”</blockquote>');
    }
  } catch (e) {
    console.log('Error in replaceDoubleQuoteWithBlockQuoteTag', e);
    updatedText = '';
  }
  return updatedText;
};

export const replaceTwitterWidgetWithAmpTwitterTag = (sourceText) => {
  let strStart = '<blockquote class="twitter-tweet"',
    strEnd = '</a></blockquote>';
  let startIndex, endIndex;
  let twitterWidget = '';
  let twitterWdgetId = '';
  let ampTtwitterWidget = '';
  try {
    sourceText = sourceText.replace(/\\"/g, '"');
    if (!isEmpty(sourceText) && sourceText.includes(strStart) && sourceText.includes(strEnd)) {
      twitterWdgetId = getTwitterWidgetId(sourceText);
      ampTtwitterWidget =
        '<amp-twitter width="375" height="472" layout="responsive" data-tweetid="' +
        twitterWdgetId +
        '"></amp-twitter>';

      startIndex = sourceText.indexOf(strStart, 0);
      endIndex = sourceText.indexOf(strEnd, startIndex) + strEnd.length;
      twitterWidget = sourceText.substring(startIndex, endIndex);
      let updatedSourceText = sourceText.replace(twitterWidget, ampTtwitterWidget);
      if (
        !isEmpty(updatedSourceText) &&
        updatedSourceText.includes(strStart) &&
        updatedSourceText.includes(strEnd)
      ) {
        updatedSourceText = replaceTwitterWidgetWithAmpTwitterTag(updatedSourceText);
      } else {
        return updatedSourceText;
      }
    } else {
      return sourceText;
    }
  } catch (ex) {
    console.log('Error replacing Twitter widget with Amp Twitter tag', ex);
    return '';
  }
};

export const getTwitterWidgetId = (sourceText) => {
  let strStart = '/status/',
    strEnd = '?ref_src';
  let startIndex, endIndex;
  try {
    if (!isEmpty(sourceText) && sourceText.includes(strStart) && sourceText.includes(strEnd)) {
      startIndex = sourceText.indexOf(strStart, 0) + strStart.length;
      endIndex = sourceText.indexOf(strEnd, startIndex);
      return sourceText.substring(startIndex, endIndex);
    }
  } catch (ex) {
    console.log('Error getting Twitter widget id', ex);
  }
  return '';
};

export const getSponsorDataFromFields = (sponsorObj) => {
  const {
    CTAURL,
    IsActive,
    MCC_MNC,
    PartnerIconDark,
    PartnerIconLight,
    PartnerName,
    PartnerText,
    CTATa,
  } = sponsorObj;
  let sponsorObjElm = {};
  try {
    sponsorObjElm.CTAURL =
      CTAURL && CTAURL.value && (CTAURL.value.hrefMuCustom || CTAURL.value.href);
    sponsorObjElm.CTATarget = CTAURL && CTAURL.value && CTAURL.value.target;
    sponsorObjElm.MCC_MNC = MCC_MNC && MCC_MNC.value;
    sponsorObjElm.PartnerIconDark =
      PartnerIconDark && PartnerIconDark.value && PartnerIconDark.value.Preview;
    sponsorObjElm.PartnerIconLight =
      PartnerIconLight && PartnerIconLight.value && PartnerIconLight.value.Preview;
    sponsorObjElm.partnerName = PartnerName && PartnerName.value;
    sponsorObjElm.partnerText = PartnerText && PartnerText.value;
    sponsorObjElm.IsActive = IsActive && IsActive.value;
  } catch (e) {
    console.log('error in getSponsor', e);
    sponsorObjElm = {};
  }
  return sponsorObjElm;
};
