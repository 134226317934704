/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import { usePlayerDetailValue } from '../../context/playerDetailContext';
const General = ({ t, item, statsType }) => {
  const [{ isPlayerPlayingLive, isMatchLive, isPlayerAppeared }, dispatch] = usePlayerDetailValue();
  const getGeneralIcons = (eventName) => {
    switch (eventName) {
      case 'stats_Appearances':
        return 'eventsmall-appear';
      case 'stats_Starts':
        return 'eventsmall-et-ko';
      case 'stats_SubstituteOff':
        return 'eventsmall-pen-substitution-off';
      case 'stats_SubstituteOn':
        return 'eventsmall-pen-substitution-on';
      case 'stats_TimePlayed':
        return 'eventsmall-match-played';
      case 'stats_MinutesPlayed':
        return 'eventsmall-match-played';
      case 'stats_RedCard':
        return 'eventsmall-redcard';
      case 'stats_YellowCards':
        return 'eventsmall-yellowcard';
      default:
        return 'eventsmall-appear';
    }
  };
  const getGeneralStatsData = (genObj) => {
    if (genObj.statsKey === 'stats_MinutesPlayed') {
      if (genObj.statsValue === 0 || genObj.statsValue === '0') {
        if ((isMatchLive && isPlayerPlayingLive) || (isMatchLive && isPlayerAppeared)) {
          return '-';
        } else return genObj.statsValue;
      } else return genObj.statsValue;
    } else return genObj.statsValue;
  };
  return (
    <div className="chart-wrapper">
      <h3>{t('stats_General')}</h3>
      <div className="goalkeeping general">
        <ul>
          {item &&
            item.stats &&
            item.stats.length > 0 &&
            item.stats.map((general, key) => (
              <li key={key}>
                <span className="list">
                  <i className={getGeneralIcons(general.statsKey)}></i>
                  <span aria-hidden="true">{t(general.statsKey)}</span>
                  {statsType === 'season' && (
                    <span className="screenreader">
                      {t(general.statsKey)} {general.statsValue}
                      {general.isPercentage === 'true' ? '%' : ''}
                    </span>
                  )}
                  {statsType === 'live' && (
                    <span className="screenreader">
                      {t(general.statsKey)}
                      <span>{getGeneralStatsData(general)}</span>
                    </span>
                  )}
                </span>
                {statsType === 'season' && (
                  <span aria-hidden="true">
                    {general.statsValue}
                    {general.isPercentage === 'true' ? '%' : ''}
                  </span>
                )}
                {statsType === 'live' && (
                  <span aria-hidden="true">{getGeneralStatsData(general)}</span>
                )}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
export default withTranslation()(General);
