/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { isEmpty } from 'lodash';
import { buildImgUrl, createDestinationUrl } from '../../../utils/utils';
import { AMPArticleDetailContentStyle, AMPApplicableCards } from '../../../constants/ampConstants';
import { getAMPVideoIframeURL } from '../../../utils/articleUtils';
import { withTranslation } from 'react-i18next';

const AMPCard = ({ fields, t }) => {
  const {
    manutds,
    ContentType,
    BackgroundImage,
    ThumbnailImage,
    Image,
    CoverImage,
    Title,
    ShortHeadline,
    Question,
    Teaser,
    CTATitle,
    CTAUrl,
    VideoFile,
    QuoteValue,
    Author,
    FirstName,
    LastName,
  } = fields;

  const imageObj = BackgroundImage || ThumbnailImage || Image || CoverImage || fields['Image File'];
  const title = ShortHeadline || Title || Question || Teaser;
  const contentType = ContentType && ContentType.value;

  const destinationURL =
    contentType === 'adcard'
      ? CTAUrl && CTAUrl.value && CTAUrl.value.hrefMuCustom
      : createDestinationUrl(manutds && manutds.value && manutds.value.DestinationUrl);

  const imgURL = buildImgUrl(
    imageObj && imageObj.value && imageObj.value.Crops && imageObj.value.Crops.medium
  );

  const getCommonCard = () => {
    return (
      <div
        className={`mu-item ${
          (AMPArticleDetailContentStyle &&
            AMPArticleDetailContentStyle[contentType] &&
            AMPArticleDetailContentStyle[contentType].className) ||
          ''
        }`}
      >
        <a href={destinationURL}>
          {contentType === 'video' ? (
            <amp-iframe
              title={title && title.value}
              width="360"
              height="203"
              sandbox="allow-scripts allow-same-origin allow-popups"
              layout="responsive"
              frameborder="0"
              allowfullscreen
              allow="encrypted-media"
              src={getAMPVideoIframeURL(
                imgURL,
                VideoFile && VideoFile.value && VideoFile.value.MediaId
              )}
            >
              {imgURL && imgURL.length > 0 && (
                <amp-img
                  layout="fill"
                  src={imgURL}
                  alt={imageObj && imageObj.value && imageObj.value.AltText}
                  placeholder
                ></amp-img>
              )}
            </amp-iframe>
          ) : (
            imgURL &&
            imgURL.length > 0 && (
              <amp-img
                src={imgURL}
                layout="responsive"
                height="246"
                width="328"
                alt={imageObj && imageObj.value && imageObj.value.AltText}
              />
            )
          )}
          <div className="mu-item__info">
            <h2 className="mu-item__title">
              {`${contentType === 'poll' ? 'Poll: ' : ''}`}
              {(title && title.value) || ''}
              {contentType === 'video' && <span className="screenreader">{contentType}</span>}
            </h2>
            {contentType === 'adcard' && (
              <span className="dfp-ads__cta">{CTATitle && CTATitle.value}</span>
            )}
          </div>
        </a>
      </div>
    );
  };

  const getPlayerProfileCard = () => {
    return (
      <div className="mu-item profile">
        <div className="mu-item__info">
          <span>{t('Profile')}</span>
          <a href={destinationURL}>
            <h2 className="player-name">
              {`${FirstName && FirstName.value} ${LastName && LastName.value}`}
            </h2>
          </a>
        </div>
      </div>
    );
  };

  const getQuoteCard = () => {
    return (
      <div className="mu-item quote">
        {imgURL && imgURL.length > 0 && (
          <amp-img
            src={imgURL}
            layout="responsive"
            height="246"
            width="328"
            alt={imageObj && imageObj.value && imageObj.value.AltText}
          />
        )}
        <div className="mu-item__info">
          {ShortHeadline && ShortHeadline.value && ShortHeadline.value.length > 0 && (
            <div className="mu-item__title">{ShortHeadline && ShortHeadline.value}</div>
          )}
          <blockquote>
            <p className="mu-quotes__title">{QuoteValue && QuoteValue.value}</p>
          </blockquote>
          <p className="mu-quotes__author">
            <cite>{Author && Author.value}</cite>
          </p>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {contentType !== 'playerprofile' &&
        contentType !== 'quote' &&
        AMPApplicableCards.indexOf(contentType) > -1 &&
        getCommonCard()}

      {contentType === 'playerprofile' && getPlayerProfileCard()}

      {contentType === 'quote' && getQuoteCard()}
    </React.Fragment>
  );
};

export default withTranslation()(AMPCard);
