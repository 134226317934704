/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
const LiveNotification = ({ t, count, updateLiveFeed }) => {
  return (
    <div className="matchday-notification sticky-notification" onClick={() => updateLiveFeed()}>
      <a href="#" onClick={(e) => e.preventDefault()}>
        {count === 1
          ? `${t('Thereis')} ${count} ${t('newitem')}`
          : `${t('Thereare')} ${count} ${t('newitems')}`}
        <i className="icon-arrow"></i>
      </a>
    </div>
  );
};

export default withTranslation()(LiveNotification);
