/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_VENU_TRAVEL_REPORT,
  SET_VENU_TRAVEL_REPORT,
  ERROR_VENU_TRAVEL_REPORT,
  FETCH_MU_TRAVEL_REPORT,
  SET_MU_TRAVEL_REPORT,
  ERROR_MU_TRAVEL_REPORT,
  FETCH_PERSIST_PROXY,
  SET_PERSIST_PROXY,
  ERROR_PERSIST_PROXY,
  SEND_EMAIL_USER_DATA,
} from '../constants/actions/actionConstant';

import MU from '../constants/muConstants';
import { PERSIST_REPORT_PROXY, SEARCH_REPORT_PROXY, SEND_EMAIL } from '../constants/endpoints';
import api from '../utils/muApi';

export const fetchVenuTravelReport = (dispatch, oId, matchId, Surname) => {
  const route = `${SEARCH_REPORT_PROXY}?type=VenueMasterTravelReport&oid=${oId}&MatchID=${matchId}&ReportType=TravelReport&Surname=${Surname}`;
  const baseURL = `${MU.awsEndPoint}/${MU.awsAPIVersion}`;
  const isHardcoredGeo = true;
  const header = { contenttype: 'application/x-www-form-urlencoded', Accept: '*/*' };
  dispatch({ type: FETCH_VENU_TRAVEL_REPORT });
  return api
    .get({ baseURL, route, isHardcoredGeo, header })
    .then((response) => {
      dispatch(setVenuTravelReport(response));
    })
    .catch((err) => {
      dispatch(errorVenuTravelReport(err));
    });
};

export const fetchMuTravelReport = (dispatch, oId, matchId, Surname) => {
  const route = `${SEARCH_REPORT_PROXY}?type=MuTravelReports&oid=${oId}&MatchID=${matchId}&ReportType=TravelReport&Surname=${Surname}`;
  const baseURL = `${MU.awsEndPoint}/${MU.awsAPIVersion}`;
  const isHardcoredGeo = true;
  const header = { contenttype: 'application/x-www-form-urlencoded', Accept: '*/*' };
  dispatch({ type: FETCH_MU_TRAVEL_REPORT });
  return api
    .get({ baseURL, route, isHardcoredGeo, header })
    .then((response) => {
      dispatch(setMuTravelReport(response));
    })
    .catch((err) => {
      dispatch(errorMuTravelReport(err));
    });
};

export const setVenuTravelReport = (response) => ({ type: SET_VENU_TRAVEL_REPORT, response });
export const errorVenuTravelReport = (response) => ({ type: ERROR_VENU_TRAVEL_REPORT, response });

export const setMuTravelReport = (response) => ({ type: SET_MU_TRAVEL_REPORT, response });
export const errorMuTravelReport = (response) => ({ type: ERROR_MU_TRAVEL_REPORT, response });

/**
 *
 */

export const fetchPersistProxy = (dispatch, mufcVal, postData) => {
  const route = `${PERSIST_REPORT_PROXY}`;
  const baseURL = `${MU.awsEndPoint}/${MU.awsAPIVersion}`;
  const isHardcoredGeo = true;
  const header = { 'Content-Type': 'application/x-www-form-urlencoded', Accept: '*/*' };
  const data = 'type=MuTravelReports&oid=' + mufcVal + '&data=' + JSON.stringify(postData);

  dispatch({ type: FETCH_PERSIST_PROXY });
  return api
    .post({ baseURL, route, isHardcoredGeo, header, data })
    .then((response) => {
      dispatch(setPersistProxy(response));
    })
    .catch((err) => {
      dispatch(errorPersistProxy(err));
    });
};

export const sendEmailUserData = (dispatch, emailData) => {
  const route = `${SEND_EMAIL}`;
  const baseURL = `${MU.assetUrlResource}`;
  const isHardcoredGeo = true;
  const header = { 'Content-Type': 'application/json' };
  const data = emailData;
  dispatch({ type: SEND_EMAIL_USER_DATA });
  return api
    .post({ baseURL, route, isHardcoredGeo, header, data })
    .then((response) => {
      console.log('Email success', response);
    })
    .catch((err) => {
      console.error('Email Fail', err);
    });
};

export const setPersistProxy = (response) => ({ type: SET_PERSIST_PROXY, response });
export const errorPersistProxy = (response) => ({ type: ERROR_PERSIST_PROXY, response });
