/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import { fetchVideoEpisode } from '../../../actions/showListingAction';
import { ShowListingProvider, useShowListingValue } from '../../../context/showListingContext';
import { useSponsorValue } from '../../../context/sponsorContext';
import MutvHeroPodcast from '../Cards/Hero/MutvHeroPodcast';
import MutvHeroVideo from '../Cards/Hero/MutvHeroVideo';
import ShowCarouselListingChild from '../Show/ShowCarouselListingChild';
import { heroDataMapping } from '../mutvutil';
import { PODCAST_EPISODE, VIDEO_EPISODE } from '../../../constants/containerConstants';
import * as track from '../../../utils/analytics';

const VideoEpisode = (props) => (
  <ShowListingProvider>
    <VideoEpisodeContainer {...props} />
  </ShowListingProvider>
);

const VideoEpisodeContainer = ({ fields, isPodcast }) => {
  const [
    { cardData, itemsNumber, heroDetails, initialLoad, isClipVideo, isMutvMovie },
    dispatch,
  ] = useShowListingValue();
  const [{ isLoginChecked, isUserLogin, isUserSubscribe, isUserSkyId }] = useSponsorValue();
  const [EpisodeDetail, setEpisodeDetail] = useState(null);
  const getScreenShow = () => {
    if (isPodcast) {
      return { podcast: true, video: false };
    }
    return { podcast: false, video: true };
  };
  const [screenShow, setScreenShow] = useState(getScreenShow());

  const callApi = (startIndex, activeFilter, actionType, tabSelected, heroLayoutData) => {
    EpisodeDetail &&
      fetchVideoEpisode(
        dispatch,
        startIndex,
        itemsNumber,
        EpisodeDetail,
        activeFilter,
        actionType,
        EpisodeDetail.defaultFilter,
        tabSelected,
        heroLayoutData
      ).then(() => {
        setTimeout(() => {
          if (actionType === 'default') {
            track.cardImpression();
          } else {
            track.cardLoadImpression();
          }
        }, 500);
      });
  };

  useEffect(() => {
    if (EpisodeDetail) {
      const heroLayoutData =
        (!isPodcast && fields.EpisodeDetail.value) || (isPodcast && fields.PodcastDetail.value);
      let tabSelected = 'episode';
      if (!isPodcast && !EpisodeDetail.IsFullEpisode) {
        tabSelected = 'clips';
      }
      callApi(0, null, 'default', tabSelected, heroLayoutData);
    }
  }, [EpisodeDetail]);

  useEffect(() => {
    if (isClipVideo && isPodcast) {
      setScreenShow({ podcast: false, video: true });
    }
  }, [isClipVideo]);

  if (
    !EpisodeDetail &&
    ((!isPodcast && fields && fields.EpisodeDetail && fields.EpisodeDetail.value) ||
      (isPodcast && fields && fields.PodcastDetail && fields.PodcastDetail.value))
  ) {
    const {
      Episode,
      EpisodeType,
      IsFullEpisode,
      ItemId,
      Season,
      Series,
      SeriesNumber,
      SeriesType,
    } = (!isPodcast && fields.EpisodeDetail.value) || (isPodcast && fields.PodcastDetail.value);
    const defaultFilter = SeriesType.toLowerCase() === 'nonseasonal' ? SeriesNumber : Season;
    setEpisodeDetail({
      Episode,
      EpisodeType,
      IsFullEpisode,
      ItemId,
      Season,
      Series,
      SeriesNumber,
      SeriesType,
      defaultFilter,
    });
  }
  // else if (!fields) {
  //   // return null;
  // }
  return (
    <React.Fragment>
      <div className="remove-gap"></div>
      {(!isPodcast ||
        (isPodcast && isClipVideo) ||
        (isPodcast && !isClipVideo && heroDetails && heroDetails.videofile_t)) && (
        <div
          className="mutv-herovideo__container"
          style={{ display: screenShow.video ? 'block' : 'none' }}
        >
          <MutvHeroVideo
            heroData={heroDetails ? heroDataMapping(heroDetails) : {}}
            isLoginChecked={isLoginChecked}
            isUserLogin={isUserLogin}
            maskRequired={true}
            isShowScreen={false}
            isPodcast={isPodcast}
            containerType={isPodcast ? PODCAST_EPISODE : VIDEO_EPISODE}
            isClipVideo={isClipVideo}
            initialLoad={initialLoad}
            setScreenShow={setScreenShow}
            screenShow={screenShow}
            isUserSubscribe={isUserSubscribe}
            isUserSkyId={isUserSkyId}
            isMutvMovie={isMutvMovie}
          />
        </div>
      )}
      {isPodcast && !isClipVideo && (
        <div
          className="mutv-heropodcast__container"
          style={{ display: screenShow.podcast ? 'block' : 'none' }}
        >
          <MutvHeroPodcast
            heroData={heroDetails ? heroDataMapping(heroDetails) : {}}
            isLoginChecked={isLoginChecked}
            isUserLogin={isUserLogin}
            maskRequired={true}
            containerType={PODCAST_EPISODE}
            initialLoad={initialLoad}
            setScreenShow={setScreenShow}
            screenShow={screenShow}
            isUserSubscribe={isUserSubscribe}
            isUserSkyId={isUserSkyId}
            isMutvMovie={isMutvMovie}
          />
        </div>
      )}
      <ShowCarouselListingChild
        defaultFilter={EpisodeDetail && EpisodeDetail.defaultFilter}
        cardData={cardData}
        itemsNumber={itemsNumber}
        callApi={callApi}
        containerType={isPodcast ? PODCAST_EPISODE : VIDEO_EPISODE}
      />
    </React.Fragment>
  );
};

export default VideoEpisode;
