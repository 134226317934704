/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { Fragment, useEffect } from 'react';
import Modal, { useModal } from '../../Common/Modal';
import MU from '../../../constants/muConstants';
import Loader from '../../Common/Loader';
import { redirectTo } from '../../../utils/utils';
import loadable from '@loadable/component';
import * as track from '../../../utils/analytics';
import { withTranslation } from 'react-i18next';
import ErrorBoundary from '../../Common/ErrorBoundary';
import ContentAccessModal from '../../Common/ContentAccessModal';
const Poll = loadable(() => import('../../Cards/Poll'));

const PollDetail = (props) => {
  const { showModal, closeModal, isModalVisible } = useModal();
  const { manutds } = props.fields;
  const {
    fields: cardData,
    modalFlag,
    closeModalPopUp,
    setAlreadyVotedHome,
    previousActiveElement,
  } = props;
  const language = MU.language ? MU.language : '';
  const itemId = manutds && manutds.value && manutds.value.Id;
  useEffect(() => {
    showModal(manutds && manutds.value && manutds.value.DestinationUrl);
  }, []);

  const getAnalyticsData = (cardData) => {
    const initialData = track.data('card');
    return {
      ...initialData,
      card_name: cardData && cardData.Question && cardData.Question.value,
      container_type: props.container,
      item_id: `{${itemId}}`,
      content_type: cardData && cardData.ContentType && cardData.ContentType.value,
      tag: cardData.manutds && cardData.manutds.value && cardData.manutds.value.Tags,
      created_datetime: cardData && cardData.__Published && cardData.__Published.value,
      author: cardData.manutds && cardData.manutds.value && cardData.manutds.value.Author,
      destination_url:
        cardData.manutds && cardData.manutds.value && cardData.manutds.value.DestinationUrl,
    };
  };

  const trackCard = (e, cardData) => {
    track.analytics(getAnalyticsData(cardData), e);
  };
  const modalClose = () => {
    if (modalFlag) {
      closeModalPopUp();
      closeModal();
    } else closeModal();
  };
  return (
    <div className="poll-non-desc">
      {isModalVisible && (
        <Modal
          containerClass="dialog poll-modal poll-non-desc"
          closeModal={modalClose}
          onClose={(e) => {
            trackCard(e, props.fields);
            !modalFlag && redirectTo('/', language);
          }}
          previousActiveElement={previousActiveElement && previousActiveElement}
        >
          <ContentAccessModal
            ContentAccess={cardData && cardData.ContentAccess && cardData.ContentAccess.value}
            ContentType={cardData && cardData.ContentType && cardData.ContentType.value}
            manutds={cardData && cardData.manutds}
            className={'poll'}
            insideModal={true}
            isDestinationPage={true}
            analyticsObj={getAnalyticsData(cardData)}
          />
          <ErrorBoundary>
            <Poll
              closeModal={closeModal}
              id={itemId}
              fallback={<Loader />}
              fields={props.fields}
              container={props.container}
              isDestinationPage={!modalFlag ? true : false}
              pollModalFlag={modalFlag}
              setAlreadyVotedHome={setAlreadyVotedHome}
            />
          </ErrorBoundary>
        </Modal>
      )}
    </div>
  );
};

PollDetail.defaulPorps = {
  modalFlag: false,
  previousActiveElement: null,
  closeModalPopUp: () => {},
  setAlreadyVotedHome: () => {},
};

export default withTranslation()(React.memo(PollDetail));
