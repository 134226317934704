/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import InlineVideo from '../../../Cards/Video/InlineVideo';
import ImgCrop from '../../../Common/ImgCrop';
import Sponsor from '../../../Common/Sponsor';
import EpisodeNote from './EpisodeNote';
import {
  bredcrumText,
  individualTag,
  setPlayerPosition,
  isContentLabelVisible,
} from '../../mutvutil';
import MutvHeroShimmer from './MutvHeroShimmer';
import { Podcast } from '../../../../data-transform/components/Podcast';
import { useModal } from '../../../Common/Modal';
import ErrorBoundary from '../../../Common/ErrorBoundary';
import Loader from '../../../Common/Loader';
import {
  getContentAccessLocal,
  createTagList,
  getLoggedInStatus,
  showCBS,
  getParameterByName,
  decodeXML,
} from '../../../../utils/utils';
import * as track from '../../../../utils/analytics';
import AccessBadge from '../../../Common/AccessBadge';
import { FREE_TO_VIEW, CONTENT_TYPE } from '../../../../constants/globalConstants';
import loadable from '@loadable/component';
import { useMutvListingValue } from '../../../../context/mutvMyListContext';
import { pauseOnNextPrev, playOnNextPre } from '../../../../utils/brightcove';
import MU from '../../../../constants/muConstants';
const RegistrationModal = loadable(() =>
  import('../../../Modals/RegistrationModal/RegistrationModal')
);

const MutvHeroPodcast = (props) => {
  const [
    { myListResponse, lastResponseTime, reportStatus, status },
    dispatch,
  ] = useMutvListingValue();
  const {
    heroData,
    isLoginChecked,
    isUserLogin,
    isUserSubscribe,
    isUserSkyId,
    containerType,
    initialLoad,
    maskRequired,
    setScreenShow,
    screenShow,
    t,
    isMutvMovie,
  } = props;
  const [playAudio, setPlayAudio] = useState(false);
  const [playerStatus, setPlayerStatus] = useState(null);
  const [showSwitching, setShowSwitching] = useState(false);
  const [savePlayerStatus, setSavePlayerStatus] = useState('playing');
  const [contentAccessState, setContentAccessState] = useState(null);
  const { showModal, closeModal, isModalVisible } = useModal();
  const {
    imagecropurl_s,
    headline_t,
    episodedescription_t,
    videorequirelogin_b,
    taglist_sm,
    contentaccess_t,
    labelexpirytime_tl,
    __published_tdt,
    appearance_tdt,
    contentlabel_s,
    series_s,
    itemid_s,
    videofile_t,
    contenttype_t,
    __publishedby_t,
    celumimagesvariant_s,
    sponsordetailinfo_s,
    videoFileTranscript,
    category_s,
  } = heroData;
  const typeRef = useRef(null);
  const containerRef = useRef(null);
  const ImageCropUrl = celumimagesvariant_s && celumimagesvariant_s.ImageCropUrl;
  useEffect(() => {
    if (videofile_t) {
      if (screenShow && screenShow.podcast) {
        setPlayerStatus({
          isPodcast: true,
          currentScreen: 'podcastEpisode',
          podcast: savePlayerStatus,
        });
      } else {
        setPlayerStatus({ isPodcast: true, currentScreen: 'podcastEpisode', podcast: 'pause' });
      }
    }
  }, [screenShow]);

  useEffect(() => {
    if (!contentAccessState && Object.keys(heroData).length > 0) {
      setContentAccessState(getContentAccessLocal(contentaccess_t, contenttype_t, taglist_sm));
    }
  }, [heroData]);

  const getStyle = (type) => {
    if (type === 'audio' && (contentAccessState === FREE_TO_VIEW || isLoginChecked)) {
      return { display: playAudio ? 'none' : 'block', height: 'inherit' };
    } else if (
      type === 'video' &&
      (!videorequirelogin_b || (videorequirelogin_b && isLoginChecked))
    ) {
      return { display: playAudio ? 'none' : 'block', height: 'inherit' };
    }
    return { opacity: '0.3', pointerEvents: 'none' };
  };

  const getAnalyticsData = (type) => {
    const initialData = track.data('button');
    return {
      ...initialData,
      card_name: headline_t,
      button_name: `${type} fullscreen`,
      container_type: containerType,
      subscription_status: getLoggedInStatus({
        isLoginChecked,
        isUserLogin,
        isUserSubscribe,
        isUserSkyId,
      }),
      item_id: itemid_s,
      content_type: contenttype_t,
      tag: taglist_sm && createTagList(taglist_sm, category_s),
      created_datetime: __published_tdt,
      content_status: contentAccessState,
      author: __publishedby_t,
      ...individualTag(taglist_sm),
    };
  };

  const trackCard = (e, type) => {
    track.analytics(getAnalyticsData(type), e);
  };

  const getClassName = () => {
    return maskRequired ? 'show-episode mask' : 'show-episode';
  };

  const triggerPlayAudio = (e, type) => {
    trackCard(e, type);
    e.preventDefault();
    if (type && type === 'audio') {
      typeRef.current = type;
      if (showCBS({ isUserLogin, isUserSubscribe, contentAccessState })) {
        showModal();
      } else {
        setPlayAudio(true);
        e.stopPropagation();
      }
    } else if (type && type === 'video') {
      typeRef.current = type;
      if (videorequirelogin_b && !isUserLogin) {
        showModal();
      } else {
        setScreenShow({ podcast: false, video: true });
        e.stopPropagation();
      }
    }
  };

  const renderBredcrum = (type) => {
    if (type) {
      return (
        <li>
          <span>{taglist_sm && bredcrumText(taglist_sm, type)}</span>
          <span className="dots"></span>
        </li>
      );
    } else {
      return null;
    }
  };

  const watchVideoClick = () => {
    if (videorequirelogin_b && !isUserLogin) {
      typeRef.current = 'video';
      showModal();
    } else {
      setScreenShow({ podcast: false, video: true });
    }
  };

  const retriveSuccessCallback = typeof window !== 'undefined' && getParameterByName('state', true);

  useEffect(() => {
    if (retriveSuccessCallback && isLoginChecked) {
      const successCallData = retriveSuccessCallback.split(',');
      if (successCallData && successCallData[0] === 'mutvHeroPodcast') {
        const isSubscribedContent = successCallData[1] && successCallData[1] === 'true';
        const userSubscribed = isUserSubscribe;
        if (!isSubscribedContent || (isSubscribedContent && userSubscribed)) {
          if (successCallData[2] && successCallData[2] === 'audio') {
            setPlayAudio(true);
          } else if (successCallData[2] && successCallData[2] === 'video') {
            setScreenShow({ podcast: false, video: true });
          }
          const url = window.location.href;
          const urlMain = url.split('?')[0] ?? url;
          window.history.replaceState({}, null, urlMain);
        }
      }
    }
  }, [retriveSuccessCallback, isLoginChecked]);

  const successCallback = ({ showContent, isSubscribedContent }) => {
    if (showContent) {
      if (!MU.isIDMEnabled) {
        if (typeRef.current && typeRef.current === 'audio') {
          setPlayAudio(true);
        } else if (typeRef.current && typeRef.current === 'video') {
          setScreenShow({ podcast: false, video: true });
        }
      } else return ['mutvHeroPodcast', isSubscribedContent, typeRef.current];
    }
  };

  const onTranscriptClick = (showTranscript) => {
    if (playAudio && containerRef && containerRef.current) {
      const videoAttr = containerRef.current.querySelector('video-js');
      if (videoAttr) {
        const videoAttrId = videoAttr.getAttribute('id');
        showTranscript ? pauseOnNextPrev(videoAttrId) : playOnNextPre(videoAttrId);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="mutv-hero-container">
        <div className={`mutv-hero ${getClassName()}`} item-id={itemid_s} ref={containerRef}>
          <div className="mu-content">
            {initialLoad && <MutvHeroShimmer type="podcast" isDetailPage={true} />}
            {!initialLoad && Object.keys(heroData).length > 0 && (
              <div className="mu-item podcast">
                <div className="img-holder">
                  {ImageCropUrl && ImageCropUrl.CropUrl && (
                    <LazyLoad once={true} offset={100}>
                      <ImgCrop
                        imgCrop={ImageCropUrl.CropUrl}
                        alt={ImageCropUrl.AltText}
                        isWebpAvailable={ImageCropUrl.isWebpAvailable}
                        gridClass="grid-12"
                        isUpdateImage={true}
                      ></ImgCrop>
                    </LazyLoad>
                  )}
                </div>
                <div style={{ display: playAudio ? 'none' : 'block' }}>
                  <Sponsor
                    contentType={contenttype_t}
                    sponsorInfo={sponsordetailinfo_s}
                    type={CONTENT_TYPE}
                    author={__publishedby_t}
                    createdDateTime={__published_tdt}
                    itemId={itemid_s}
                    tags={taglist_sm}
                    cardName={headline_t}
                    container={containerType}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {!initialLoad && (
          <div className="container">
            <div className="mu-item__info">
              {!isMutvMovie && <div className="mu-item__series">{decodeXML(series_s)}</div>}
              {headline_t && <h2 className="mu-item__title">{headline_t}</h2>}
              <div className="breadcrum">
                <ul>
                  <AccessBadge
                    contentAcessLocal={contentaccess_t}
                    requiredInList={true}
                    className="badge"
                    contentTypeLocal={contenttype_t}
                    tagListLocal={taglist_sm}
                  />
                  {isContentLabelVisible({
                    labelexpirytime_tl,
                    __published_tdt,
                    appearance_tdt,
                  }) &&
                    contentlabel_s && (
                      <li>
                        <span>{contentlabel_s}</span>
                        <span className="dots"></span>
                      </li>
                    )}
                  {!isMutvMovie && renderBredcrum('series')}
                  {!isMutvMovie && renderBredcrum('episode')}
                </ul>
              </div>
              <div
                className="hero-widgets podcast"
                style={{ display: playAudio ? 'none' : 'block' }}
              >
                {videofile_t && (
                  <div className="red-btn-skew btn" onClick={(e) => triggerPlayAudio(e, 'video')}>
                    <a style={getStyle('video')} href="#" role="button">
                      <span>
                        {videorequirelogin_b && !isUserLogin
                          ? t('RegisterToWatch_web')
                          : t('WatchVideo')}
                      </span>
                    </a>
                  </div>
                )}

                <div
                  className="white-btn-skew btn"
                  onClick={(e) => triggerPlayAudio(e, 'audio')}
                  tabIndex="-1"
                >
                  <a style={getStyle('audio')} href="#" role="button" tabIndex="0">
                    <span>{t('ListenNow')}</span>
                  </a>
                </div>
              </div>
              {playAudio && (
                <React.Fragment>
                  <InlineVideo
                    data={Podcast(heroData, true).fields}
                    itemid={itemid_s}
                    type={'audio'}
                    playerStatus={playerStatus}
                    setSavePlayerStatus={setSavePlayerStatus}
                    setShowSwitching={setShowSwitching}
                    isMutv={{
                      container_type: containerType,
                      subscription_status: getLoggedInStatus({
                        isLoginChecked,
                        isUserLogin,
                        isUserSubscribe,
                        isUserSkyId,
                      }),
                      isPodcast: true,
                      contentStatus: contentAccessState,
                      startTime: setPlayerPosition(myListResponse, itemid_s),
                      heroData: heroData,
                    }}
                    myListResponse={myListResponse}
                    lastResponseTime={lastResponseTime}
                    reportStatus={reportStatus}
                    status={status}
                    dispatch={dispatch}
                    contentAccessState={contentAccessState}
                    playerStop={setPlayAudio}
                  />
                  {playAudio && videofile_t && showSwitching && (
                    <button id="watchVideo" onClick={() => watchVideoClick()}>
                      <i className="icon-watchvideo"></i>
                      {videorequirelogin_b && !isUserLogin
                        ? t('RegisterToWatch_web')
                        : t('WatchVideo')}
                    </button>
                  )}
                  <div className="hero-widgets podcast"></div>
                </React.Fragment>
              )}
            </div>
          </div>
        )}
      </div>
      {!initialLoad && Object.keys(heroData).length > 0 && (
        <div className="container mutv-container">
          <EpisodeNote
            Description={episodedescription_t}
            showMyList={true}
            data={heroData}
            containerType={containerType}
            page={'podcatsDetail'}
            contentStatus={contentAccessState}
            isUserSubscribe={isUserSubscribe}
            isUserSkyId={isUserSkyId}
            videoFileTranscript={videoFileTranscript}
            onTranscriptClick={onTranscriptClick}
          />
        </div>
      )}
      {isModalVisible && (
        <ErrorBoundary>
          <RegistrationModal
            closeModal={closeModal}
            fallback={<Loader />}
            successCallback={successCallback}
            previousActiveElement={document.activeElement}
            accessType={contentAccessState}
            analyticsObj={getAnalyticsData(typeRef.current)}
          />
        </ErrorBoundary>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(MutvHeroPodcast);
