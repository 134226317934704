/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { createContext, useContext, useReducer } from 'react';
import { mutvListingState, mutvListingReducer } from '../reducers/mutvListingReducer';

export const MutvListingContext = createContext();

export const MutvListingProvider = ({ children }) => (
  <MutvListingContext.Provider value={useReducer(mutvListingReducer, mutvListingState)}>
    {children}
  </MutvListingContext.Provider>
);
export const useMutvListingValue = () => useContext(MutvListingContext);

export default MutvListingContext;
