/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import ImageFallback from './../Common/ImageFallback';
import api from '../../utils/muApi';
import { GET_CAROUSEL, GET_ITEM } from '../../constants/endpoints';
import { slideShowCount, featuredShowCount } from '../../utils/carouselUtils';
import { checkDevice, createDestinationUrl, getRandomId } from '../../utils/utils';
import { THRESHOLD } from '../../constants/globalConstants';
import {
  calculateSliderHeight,
  mutvslideShowCount,
  podcastTags,
  processData,
  getLocalData,
  checkIsFeatured,
} from './mutvutil';
import Shimmer from './Shimmer';
import loadable from '@loadable/component';
import * as track from '../../utils/analytics';
import EpgSliderCarousel from './EPG/EpgSliderCarousel';
import { useMutvListingValue } from '../../context/mutvMyListContext';
import {
  deletAndSort,
  defaultLocalDataJson,
  checkHistoryAvailable,
  createAndSaveToSession,
} from './mutvutil';

const MutvSliderCarousel = loadable(() => import('./MutvSliderCarousel'));
const MutvSlider = (props) => {
  const [state, setState] = useState({
    status: 'fetching',
    noCardFound: 'false',
    error: null,
    data: null,
  });
  const {
    data,
    isUserLogin,
    isGigyaAvilable,
    isLoginChecked,
    userLoginDetail,
    isUserSubscribe,
    isUserSkyId,
    isDiscover,
    DiscoverCarouselTitle,
    dispatch,
    isEpg,
    epgData,
    selectedDate,
    activeIndex,
    weekDate,
    oncardClick,
    isEpgImage,
    toggleState,
    intialToggleState,
    t,
  } = props;
  const {
    numberofrecordsonlandingscreen_t: numberOfCards,
    aspectratio_t: aspectRatio,
    istrending_b: isTrending,
    contenttype_t: contentType,
    heading_t: carouselHeading,
    carouselname_t: carouselName,
    description_t: description,
    showcardstype_t: featurecardType,
    viewallcopytext_t,
    celumimagesvariant_s,
  } = data;
  const isFeatureCard = featurecardType && checkIsFeatured(contentType, featurecardType);
  const updatedData = useRef({});
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [viewCarousel, setViewCarousel] = useState(
    contentType === 'continuelistening' ? false : true
  );

  const [viewAll, setViewAll] = useState(viewallcopytext_t);
  const [destURL, setdestURL] = useState(null);
  const [uniqueId, setUniqueId] = useState(getRandomId());
  const [{ myListResponse }] = useMutvListingValue();
  const slickElement = useRef(null);
  let timeoutId;
  const refCallBack = () => {
    return slickElement;
  };

  useEffect(() => {
    if (isLoginChecked && contentType === 'continuelistening') {
      getSyncApiData();
    }
  }, [myListResponse, isLoginChecked, userLoginDetail]);

  useEffect(() => {
    setIsTouchDevice(checkDevice());
    const touchDevice = checkDevice();
    if (!isEpg && contentType !== 'continuelistening') {
      getCards(props.data, props.cardData);
    }
    if (!touchDevice) {
      window.addEventListener('resize', resizeListener);
    }
    return () => {
      !touchDevice && window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const slideShowCounter = (aspectRatio, carouselContentType) => {
    if (isDiscover) {
      return slideShowCount();
    } else if (isFeatureCard) {
      return featuredShowCount(featurecardType);
    } else {
      if (aspectRatio) {
        return aspectRatio === '3x4' ? slideShowCount() : mutvslideShowCount();
      } else
        return carouselContentType === 'continuelistening'
          ? mutvslideShowCount()
          : slideShowCount();
    }
  };

  const slideCount = () => {
    return state && state.data && state.data.length > 0 ? state.data.length : 0;
  };

  const resizeListener = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setIsTouchDevice(checkDevice());
      setUniqueId(getRandomId());
    }, THRESHOLD);
  };

  const pushDocs = (docs, carouselType = null) => {
    //extra argument to handle the continue listening part
    setState({
      ...state,
      status: 'success',
      data:
        carouselType === 'continuelistening'
          ? docs && docs.slice(0, 11)
          : docs && docs.slice(0, numberOfCards), //max number of card in carousel is 12
      noCardFound: docs && docs.length > 0 ? docs.length : 0,
    });

    return docs;
  };

  const getCards = (data, cardData) => {
    let itemid = data.itemid_s;
    if (cardData) {
      //this section is for discover carousel as we already have the data for cards
      pushDocs(processData(cardData));
    } else {
      const route = `${GET_CAROUSEL}${encodeURI(itemid)}`;
      return api
        .get({ route })
        .then((data) => {
          let url = data.data.GetCarouselResponse.response.docs[0].destinationurl_s;
          setdestURL(createDestinationUrl(url));
          setViewAll(viewallcopytext_t);
          const grouped = data.data.UpNextResponse.grouped;
          pushDocs(processData(grouped));
          return null;
        })
        .catch((err) => {
          setViewAll(t('Refresh'));
          return null;
        });
    }
  };
  const getSyncApiData = () => {
    const localMylistData = getLocalData();
    if (isGigyaAvilable && isLoginChecked) {
      let totalData;
      if (isUserLogin) {
        const data = checkHistoryAvailable(myListResponse);
        totalData =
          data && deletAndSort(myListResponse.ContentHistoryResponse.ContentHistory, 'historyDT');
      } else {
        if (localMylistData) {
          const data = checkHistoryAvailable(localMylistData);
          totalData =
            data &&
            deletAndSort(localMylistData.ContentHistoryResponse.ContentHistory, 'historyDT');
        } else {
          totalData = defaultLocalDataJson();
        }
      }
      if (totalData) {
        const obj = {};
        totalData && totalData.length > 0 && totalData.forEach((item) => (obj[item.ID] = null));
        updatedData.current = obj;
        getContinueCards(totalData);
      }
    }
  };
  const getContinueCards = (historyObj) => {
    if (historyObj && historyObj.length > 0) {
      let count = 0;
      const historyObjLength = historyObj.length > 12 ? 12 : historyObj.length;
      historyObj.slice(0, historyObjLength).forEach((item, index) => {
        const IdFoundinSession =
          sessionStorage.getItem(item.ID) && JSON.parse(sessionStorage.getItem(item.ID));
        if (IdFoundinSession && IdFoundinSession.itemid_s === item.ID) {
          IdFoundinSession['totalDuration'] = item.totalDuration;
          IdFoundinSession['playPosition'] = item.playPosition;
          updatedData.current[item.ID] = IdFoundinSession;
          count++;
          if (count === historyObjLength) {
            setAllData(updatedData);
          }
        } else {
          const route = `${GET_ITEM}${item.ID}`;
          api
            .get({ route })
            .then((data) => {
              let responseData = createAndSaveToSession(data, 'contentHistory', item);
              updatedData.current[responseData.itemid_s] = responseData;
              count++;
              if (count === historyObjLength) {
                setAllData(updatedData);
              }
            })
            .catch((err) => {
              count++;
              if (count === historyObjLength) {
                setAllData(updatedData);
              }
            });
        }
      });
    }
  };
  const setAllData = (updatedData) => {
    if (updatedData) {
      setState({
        ...state,
        status: 'success',
        data:
          updatedData.current && Object.values(updatedData.current).filter((val) => val !== null),
      });
      setViewCarousel(true);
      setViewAll(t('ViewAll'));
      setdestURL(createDestinationUrl('mutv/mylist?viewAll=true'));
    }
  };

  const trackLink = (ctaTitle, linkUrl, e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: ctaTitle,
        destination_url: linkUrl,
      },
      e
    );
  };
  const shortHeadingLength = (heading) => {
    if (heading && heading.length > 30) {
      const shortHeading = heading.slice(0, 30);
      return shortHeading + '...';
    }
    return heading;
  };
  const carouselTitle = () => {
    if (!isDiscover) {
      if ((contentType === 'showcarousel' || contentType === 'episodecarousel') && carouselName) {
        return shortHeadingLength(carouselName);
      } else {
        return shortHeadingLength(carouselHeading);
      }
    } else {
      return shortHeadingLength(DiscoverCarouselTitle);
    }
  };
  const setClassByCount = () => {
    return `item-count-${slideCount()}`;
  };

  const teasureEllipsis = (description) => {
    return description && description.length > 120
      ? `${description.slice(0, 120)}...`
      : description;
  };
  const getLogoImg = (response) => {
    return (
      <ImageFallback
        src={response.CropUrl && response.CropUrl.img1x}
        onErrorClass="no-img"
        alt={response && response.AltText}
        relativePath={true}
      />
    );
  };
  return (
    <React.Fragment>
      {viewCarousel && !isEpg && (
        <div
          className={`mutv-main ${
            isFeatureCard
              ? `feature-component ${featurecardType ? featurecardType : ''} ${setClassByCount()}`
              : ''
          }`}
          key={uniqueId}
        >
          <div
            className={`carousal ${calculateSliderHeight(
              aspectRatio,
              contentType,
              isDiscover
            )}_wrapper`}
          >
            <div
              className={` container ${isTouchDevice ? 'custom-container' : ''} ${
                slideCount() <= slideShowCounter(aspectRatio, contentType) ? 'adjust' : ''
              }`}
            >
              <div className="carousal-width" ref={slickElement}>
                <div className="carousal-head">
                  {isFeatureCard &&
                    celumimagesvariant_s &&
                    celumimagesvariant_s.ImageDetails &&
                    celumimagesvariant_s.ImageDetails[0] &&
                    celumimagesvariant_s.ImageDetails[0].CropUrl && (
                      <React.Fragment>
                        <div className="feature-carousal-logo">
                          {getLogoImg(celumimagesvariant_s.ImageDetails[0])}
                        </div>
                      </React.Fragment>
                    )}
                  <div className="carousal_title_link">
                    <h2 className="carousal__title">
                      <span className="mutv_span">{carouselTitle()}</span>
                      {((contentType !== 'showofshows' && isDiscover === false) ||
                        viewAll === t('Refresh')) && (
                        <a
                          href={destURL}
                          rel="noreferrer"
                          className="carousal-destination caraousel__pointer"
                          tabIndex="0"
                          onClick={(e) => {
                            contentType !== 'continuelistening'
                              ? viewAll === t('Refresh') &&
                                getCards(props.data, props.cardData, true)
                              : contentType === 'continuelistening' &&
                                getContinueCards(userLoginDetail);
                            trackLink(viewAll, destURL, e);
                          }}
                        >
                          <span aria-hidden="true">
                            <span>{viewAll}</span>
                          </span>
                          <span className="screenreader">{props.t(viewAll)}</span>
                        </a>
                      )}
                    </h2>
                    {featurecardType && description && <p>{teasureEllipsis(description)}</p>}
                  </div>
                </div>
                <div className={`carousel-min-height`}>
                  {state.data && state.data.length > 0 && (
                    <MutvSliderCarousel
                      isTouchDevice={isTouchDevice}
                      data={state.data}
                      contentType={contentType}
                      aspectRatio={aspectRatio}
                      isTrending={isTrending}
                      isDiscover={isDiscover}
                      refCallBack={refCallBack}
                      isUserLogin={isUserLogin}
                      isGigyaAvilable={isGigyaAvilable}
                      userLoginDetail={userLoginDetail}
                      isLoginChecked={isLoginChecked}
                      dispatch={dispatch}
                      featurecardType={featurecardType}
                      isFeatureCard={isFeatureCard}
                      isUserSubscribe={isUserSubscribe}
                      isUserSkyId={isUserSkyId}
                    />
                  )}
                  {(!state.data || state.data.length === 0) && (
                    <div className={!isTouchDevice ? `custom-container` : ''}>
                      <div className={`shimmerBlock ${isTouchDevice ? '' : 'shimmer_nonTouch'}`}>
                        <div className={`carousal-list custom-mobile-slider`}>
                          <Shimmer
                            aspectRatio={aspectRatio}
                            carouselContentType={contentType}
                            isDiscover={isDiscover}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isEpg && (
        <div className="mutv-main">
          <div className={`carousal`}>
            <div className={` container ${isTouchDevice ? 'custom-container' : ''}`}>
              <div className="carousal-width" ref={slickElement}>
                <div className={`carousel-min-height`}>
                  <div className={`carousel-min-height-item`}>
                    {epgData && epgData.length > 0 && (
                      <EpgSliderCarousel
                        isTouchDevice={isTouchDevice}
                        data={epgData}
                        contentType={'podcast'}
                        aspectRatio={'3x4'}
                        refCallBack={refCallBack}
                        isEpg={true}
                        selectedDate={selectedDate}
                        activeIndex={activeIndex}
                        weekDate={weekDate}
                        oncardClick={oncardClick}
                        isEpgImage={isEpgImage}
                        toggleState={toggleState}
                        intialToggleState={intialToggleState}
                      />
                    )}
                  </div>
                  {(!epgData || epgData.length === 0) && (
                    <div className={!isTouchDevice ? `custom-container` : ''}>
                      <div className={`shimmerBlock ${isTouchDevice ? '' : 'shimmer_nonTouch'}`}>
                        <div className={`carousal-list custom-mobile-slider`}>
                          <Shimmer
                            aspectRatio={aspectRatio}
                            carouselContentType={contentType}
                            isDiscover={isDiscover}
                            isEpg={true}
                            noCard={epgData && epgData.length === 0}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

MutvSlider.defaultProps = {
  cardData: null,
  isDiscover: false,
  isUserLogin: null,
  isGigyaAvilable: null,
  userLoginDetail: null,
  isDiscover: false,
  dispatch: null,
  isLoginChecked: null,
  DiscoverCarouselTitle: null,
  data: {},
  isEpg: false,
};
export default withTranslation()(MutvSlider);
