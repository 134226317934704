/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_MATCH_TICKET,
  SET_MATCH_TICKET,
  ERROR_MATCH_TICKET,
  FETCH_MEMBER_SELECTION,
  SET_MEMBER_SELECTION,
  ERROR_MEMBER_SELECTION,
  FETCH_PREVIOUS_ORDER,
  SET_PREVIOUS_ORDER,
  ERROR_PREVIOUS_ORDER,
  FETCH_ORDER_DETAIL,
  SET_ORDER_DETAIL,
  ERROR_ORDER_DETAIL,
  FETCH_USER_DETAIL,
  SET_USER_DETAIL,
  ERROR_USER_DETAIL,
  SUBMIT_USER_REQUEST,
  GET_USER_REQUEST,
  ERROR_USER_REQUEST,
  SUBMIT_CONFIRM_TICKET,
  GET_CONFIRM_TICKET,
  ERROR_CONFIRM_TICKET,
  USER_CONFIRMATION,
} from '../constants/actions/actionConstant';

export const muscState = {
  matchStatus: null,
  matchTicket: null,
  memberStatus: null,
  memberSelection: null,
  prevOrderStatus: null,
  prevOrderDetail: null,
  orderDetailStatus: null,
  orderDetail: null,
  userListStatus: null,
  userList: null,
  newUserRequest: null,
  newUserRequestStatus: null,
  confirmTicketStatus: null,
  confirmTicket: null,
  userConfirmationData: null,
};

export const muscReducer = (state = muscState, { type, response } = {}) => {
  switch (type) {
    case FETCH_MATCH_TICKET: {
      return {
        ...state,
        matchStatus: 'fetching',
      };
    }
    case SET_MATCH_TICKET: {
      if (
        response &&
        response.data &&
        response.data.MatchDetailsResponse &&
        response.data.MatchDetailsResponse.Secretaryemail
      ) {
        return {
          ...state,
          matchStatus: 'fetched',
          matchTicket: response && response.data,
        };
      } else {
        window.location = window.location.origin;
        return { ...state, matchStatus: null, matchTicket: null };
      }
    }
    case ERROR_MATCH_TICKET: {
      return {
        ...state,
        matchStatus: 'error',
        matchTicket: null,
      };
    }
    /**
     * Member Selection
     */

    case FETCH_MEMBER_SELECTION: {
      return {
        ...state,
        memberStatus: 'fetching',
        memberSelection: null,
      };
    }
    case SET_MEMBER_SELECTION: {
      return {
        ...state,
        memberStatus: 'fetched',
        memberSelection: response && response.data,
      };
    }
    case ERROR_MEMBER_SELECTION: {
      return {
        ...state,
        memberStatus: 'error',
        memberSelection: null,
      };
    }

    case FETCH_PREVIOUS_ORDER: {
      return {
        ...state,
        prevOrderStatus: 'fetching',
      };
    }

    /**
     * Previous Order
     */
    case SET_PREVIOUS_ORDER: {
      return {
        ...state,
        prevOrderStatus: 'fetched',
        prevOrderDetail: response && response.data,
      };
    }
    case ERROR_PREVIOUS_ORDER: {
      return {
        ...state,
        prevOrderStatus: 'error',
        prevOrderDetail: null,
      };
    }

    /**
     * Order Details
     */
    case FETCH_ORDER_DETAIL: {
      return {
        ...state,
        orderDetailStatus: 'fetching',
      };
    }
    case SET_ORDER_DETAIL: {
      return {
        ...state,
        orderDetailStatus: 'fetched',
        orderDetail: response && response.data,
      };
    }
    case ERROR_ORDER_DETAIL: {
      return {
        ...state,
        orderDetailStatus: 'error',
        orderDetail: null,
      };
    }

    /**
     * User Detail
     */
    case FETCH_USER_DETAIL: {
      return {
        ...state,
        userListStatus: 'fetching',
      };
    }
    case SET_USER_DETAIL: {
      return {
        ...state,
        userListStatus: 'fetched',
        userList: response && response.data,
      };
    }
    case ERROR_USER_DETAIL: {
      return {
        ...state,
        userListStatus: 'error',
        userList: null,
      };
    }

    /**
     * User Request
     */
    case SUBMIT_USER_REQUEST: {
      return {
        ...state,
        newUserRequestStatus: 'fetching',
      };
    }
    case GET_USER_REQUEST: {
      return {
        ...state,
        newUserRequestStatus: 'fetched',
        newUserRequest: response && response.data,
      };
    }
    case ERROR_USER_REQUEST: {
      return {
        ...state,
        newUserRequestStatus: 'error',
        newUserRequest: null,
      };
    }

    /**
     * Confirm Ticket
     */
    case SUBMIT_CONFIRM_TICKET: {
      return {
        ...state,
        confirmTicketStatus: 'fetching',
      };
    }
    case GET_CONFIRM_TICKET: {
      return {
        ...state,
        confirmTicketStatus: 'fetched',
        confirmTicket: response && response.data,
      };
    }
    case ERROR_CONFIRM_TICKET: {
      return {
        ...state,
        confirmTicketStatus: 'error',
        confirmTicket: null,
      };
    }
    /**GET userlist confirmation */
    case USER_CONFIRMATION: {
      return {
        ...state,
        userConfirmationData: response,
      };
    }
    default:
      return state;
  }
};
