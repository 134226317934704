/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useEffect, useRef } from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { getLanguage, isLiveByPeriod } from '../../utils/utils';
import { localeConfig } from '../../utils/cardDateFormat';
import Share from './GigyaShare';
import Tags from './Tags';
import AccessBadge from './AccessBadge';
import UpSell from './UpSell';
/**
 * @param {dictionary}t
 * @param {data} item
 * @param {type of card eg: blogCard or default} type
 */
const StatsBar = ({
  t,
  item,
  type,
  classes,
  style,
  noTags,
  shareOnly,
  requiredIconOnly,
  tracking,
  openPanelCallback,
  analyticsData,
  tagListLocal,
  upSellData,
}) => {
  const sharemoreRef = useRef(null);
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    document.addEventListener('openShare', (e) => {
      setToggle(false);
    });
    return () => {
      document.removeEventListener('openShare', () => {});
    };
  }, []);

  const formatCardDate = (time, accessibility) => {
    let lang = getLanguage();
    if (time) {
      return localeConfig(time, lang, t, accessibility);
    }
  };
  const statsBarClass = type === 'blogCard' ? 'statsbar blog' : 'statsbar';

  const setClass = () => {
    return toggle ? 'social-share__footer isShowStatsBar' : 'social-share__footer';
  };

  const closeShare = (evt) => {
    evt.preventDefault();
    if (evt.target) {
      let targetElm = evt.target.parentElement.parentElement.parentElement;
      targetElm.classList.remove('isShowStatsBar');
      const closestShareIcon = targetElm.querySelector('.icon-share-card-lite');
      closestShareIcon && closestShareIcon.focus();
      const shareIconFocus = requiredIconOnly
        ? document.getElementById('iconShare')
        : sharemoreRef && sharemoreRef.current && sharemoreRef.current.querySelector('span');
      if (shareIconFocus) {
        shareIconFocus.classList && shareIconFocus.classList.add('non-keyboard-outline');
        shareIconFocus.focus();
      }
    }
    const event = document.createEvent('Event');
    event.initEvent('closeShare', false, true);
    document.dispatchEvent(event);
    setTimeout(() => {
      setToggle(!toggle);
    }, 1000);
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
      openPanel(e);
    }
  };

  const openPanel = (e) => {
    e.stopPropagation();
    const event = document.createEvent('Event');
    event.initEvent('openShare', false, true);
    document.dispatchEvent(event);
    setToggle(!toggle);
    tracking && openPanelCallback && openPanelCallback(e);
  };

  let cardDate = {
    value: formatCardDate(item.appearance_tdt),
  };
  let cardDateAccessibility = {
    value: formatCardDate(item.appearance_tdt, 'accessability'),
  };

  let getStatsBarTemplate = (cardDate, cardDateAccessibility) => {
    return (
      <span className="statsbar__time">
        {!shareOnly && (
          <span aria-hidden="true">
            <RichText tag="span" className="date" field={cardDate} editable={false} />
          </span>
        )}
        {!shareOnly && (
          <span className="screenreader">
            {`${t('publishedtime')} `}
            <span>
              <RichText tag="span" field={cardDateAccessibility} editable={false} />
            </span>
          </span>
        )}
      </span>
    );
  };

  return (
    <React.Fragment>
      {!requiredIconOnly ? (
        <div className={classes ? classes : statsBarClass} style={style}>
          <ul>
            {type === 'quiz' && (
              <li className="quiz-timeago">
                {getStatsBarTemplate(cardDate, cardDateAccessibility)}
              </li>
            )}
            {type === 'livestream' && (
              <li>
                <span className="icon-live-blink">{t('LiveNow')}</span>
                <span className="statsbar__split" aria-hidden="true"></span>
                <AccessBadge
                  contentAcessLocal={item.ContentAccess}
                  contentTypeLocal={item.ContentType}
                  tagListLocal={tagListLocal}
                  withAnchor={true}
                />
              </li>
            )}
            {type === 'statsCard' && (
              <li>
                {!isLiveByPeriod(item.matchperiod_t) &&
                  getStatsBarTemplate(cardDate, cardDateAccessibility)}
              </li>
            )}
            {type !== 'blogCard' &&
              type !== 'livestream' &&
              type !== 'statsCard' &&
              type !== 'quiz' && (
                <li>
                  {getStatsBarTemplate(cardDate, cardDateAccessibility)}
                  {type !== 'heroSlider' && (
                    <AccessBadge
                      contentAcessLocal={item.ContentAccess}
                      contentTypeLocal={item.ContentType}
                      tagListLocal={tagListLocal}
                      withAnchor={true}
                    />
                  )}
                  {!noTags && (
                    <Tags displayTag={item.displaytag_s} categoryTag={item.categorytag_s}></Tags>
                  )}
                </li>
              )}
            <li>
              {type === 'blogCard' &&
                (item.matchstage_t === '0' ||
                  item.matchstage_t === '11' ||
                  (item.eventtype_t && item.eventtype_t.toLowerCase() === 'comment')) &&
                getStatsBarTemplate(cardDate, cardDateAccessibility)}
            </li>
            {type !== 'blogCard' && type !== 'quiz' && (
              <React.Fragment>
                {upSellData && (
                  <li className="upsell-wrapper">
                    <UpSell data={upSellData} />
                  </li>
                )}
                <li onClick={(e) => openPanel(e)} ref={sharemoreRef}>
                  <span
                    className="icon-share-card-lite"
                    onKeyPress={(e) => handleKeypress(e)}
                    tabIndex="0"
                    aria-label={t('Share')}
                    role="link"
                  ></span>
                </li>
              </React.Fragment>
            )}
          </ul>
        </div>
      ) : (
        // <div className={classes ? classes : statsBarClass} style={style}>
        <span
          onClick={(e) => openPanel(e)}
          id="iconShare"
          tabIndex="0"
          aria-describedby="mutv-main-heading"
          className="bdr"
          aria-label={t('Share')}
          role="link"
          onKeyPress={(e) => handleKeypress(e)}
        >
          <i className="icon-share-transparent" aria-hidden="true"></i>
        </span>
        // </div>
      )}

      {toggle && type !== 'quiz' && (
        <div
          role="dialog"
          data-test="share-container"
          className={setClass()}
          onClick={(e) => e.stopPropagation()}
        >
          <Share item={item} focusFirstItem={true} tracking={tracking} {...analyticsData} />
          <span className="close" onClick={closeShare}>
            <a href="#!" role="button" aria-label={t('CloseMoreShareOptions')}>
              <i className="icon-close-black" />
            </a>
          </span>
        </div>
      )}
    </React.Fragment>
  );
};

StatsBar.defaultProps = {
  shareOnly: false, //for display only share icon in statsbar (like poll in collection)
  analyticsData: {},
  tracking: false,
  upSellData: false,
};

export default withTranslation()(StatsBar);
