/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { isSmallDevice } from '../../utils/utils';
import { THRESHOLD } from '../../constants/globalConstants';
const MatchHighlight = ({ t, highlights }) => {
  /**
   * @param {matchStats: stores all stats values}
   * @param {touchDevice: return true false for device check}
   * @param {slidermove: set left and right movement of slide }
   * @param {cardVisible: get how much card can be visible in one screen}
   * @param {carousalWidth: get carousal width as per the device screen size}
   */
  const [matchStats, setMatchStats] = useState();
  const [touchDevice, setTouchDevice] = useState(false);
  const [slidermove, setSlidermove] = useState(0);
  const [cardVisible, setCardVisible] = useState(0);
  const [carousalWidth, setCarousalWidth] = useState(0);
  const sliderRef = useRef(null);
  let timeoutId;

  useEffect(() => {
    if (highlights) {
      setTeamsDetail();
    }
    setTouchDevice(isSmallDevice);
    setCarousalWidth(sliderRef.current.getBoundingClientRect().width);
  }, [carousalWidth, highlights]);

  useEffect(() => {
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  /**
   * @define {setTeamsDetail set the team name or team club name as per condition }
   */
  const setTeamsDetail = () => {
    let statsData = {
      graphs: [],
      homeTeamName: '',
      awayTeamName: '',
      slideWidth: 169,
    };
    let matchHighlight = highlights.matchhighlightstatsjson_t;
    if (matchHighlight && matchHighlight.length >= 2) {
      statsData.homeTeamName = matchHighlight[0].Ishometeam
        ? matchHighlight[0].ShortClubName || matchHighlight[0].TeamName
        : matchHighlight[1].ShortClubName || matchHighlight[1].TeamName;

      statsData.awayTeamName = matchHighlight[0].Ishometeam
        ? matchHighlight[1].ShortClubName || matchHighlight[1].TeamName
        : matchHighlight[0].ShortClubName || matchHighlight[0].TeamName;

      groupStatsData(matchHighlight, statsData);
    } else {
      return console.error(
        'Invalid response in HighlightMatchStatsResponse.response.docs[0].matchhighlightstatsjson_t: expecting two nodes'
      );
    }
  };

  /**
   *
   * @param {holds Both Team data, used to add custom key for both the team} team
   * @param {holds all graphs data with additional keys which has teams details info to view on Dom} statsData
   */
  const groupStatsData = (teams, statsData) => {
    let graphsToHide = hideStatsGraph();
    const colorcode = ['#c70101', '#1a1a1a'];
    teams[0].statsValues.forEach((item, i) => {
      let homeStats = teams[0].statsValues[i];
      let awayStats = teams[1].statsValues[i];
      if (graphsToHide.indexOf(homeStats.StatsKey) === -1) {
        let graphObj = {
          homeTeamName: statsData.homeTeamName,
          awayTeamName: statsData.awayTeamName,
          statsName: homeStats.StatsName,
          statsKey: homeStats.StatsKey,
          homeStatsScore: parseFloat(homeStats.Value),
          awayStatsScore: parseFloat(awayStats.Value),
          isHomeMU: teams[0].IsMUEntity,
          isAwayMU: teams[1].IsMUEntity,
          homeColor: teams[0].IsMUEntity ? colorcode[0] : colorcode[1],
          awayColor: teams[1].IsMUEntity ? colorcode[0] : colorcode[1],
        };
        if (statsData.homeTeamName.length >= 15 || statsData.awayTeamName.length >= 15) {
          statsData.cardClassName = 'x-large';
          statsData.slideWidth = 249;
        } else {
          statsData.cardClassName = 'large';
          statsData.slideWidth = 199;
        }
        mapStatsKey(graphObj, statsData);
        statsData.graphs.push(graphObj);
      }
    });
    setMatchStats(statsData);
    setCardVisible(parseInt(carousalWidth / statsData.slideWidth));
  };

  /**
   *
   * @param {hold all graphs values, and used for manupaluting percentage value for teams.} graphObj
   * @param {used to setClass name and there width of card as per conditions} statsData
   */
  const mapStatsKey = (graphObj, statsData) => {
    if (
      graphObj.statsKey !== 'accurate_pass' &&
      graphObj.statsKey !== 'possession_percentage' &&
      statsData.cardClassName !== 'x-large' &&
      (graphObj.homeStatsScore.toString().length > 1 ||
        graphObj.awayStatsScore.toString().length > 1)
    ) {
      statsData.cardClassName = 'large';
      statsData.slideWidth = 199;
    }
    if (graphObj.statsKey === 'possession_percentage') {
      if (graphObj.homeStatsScore === 0 && graphObj.awayStatsScore === 0) {
        graphObj.homePossessionPer = 0;
        graphObj.awayPossessionPer = 0;
      } else {
        graphObj.homePossessionPer = Math.round(graphObj.homeStatsScore);
        graphObj.awayPossessionPer = Math.round(graphObj.awayStatsScore);
        graphObj.homePossessionPer.toString().length > 2 && (graphObj.passingPerClass = 'small');
        graphObj.awayPossessionPer.toString().length > 2 && (graphObj.passingPerClass = 'small');
      }
    }
    if (graphObj.statsKey === 'accurate_pass') {
      if (graphObj.homeStatsScore > graphObj.awayStatsScore) {
        graphObj.homePassessPer = '100px';
        let awayTeamCal = Math.round((graphObj.awayStatsScore * 100) / graphObj.homeStatsScore);
        graphObj.awayPassessPer = awayTeamCal + 'px';
      } else if (graphObj.awayStatsScore > graphObj.homeStatsScore) {
        graphObj.awayPassessPer = '100px';
        let homeTeamCal = Math.round((graphObj.homeStatsScore * 100) / graphObj.awayStatsScore);
        graphObj.homePassessPer = homeTeamCal + 'px';
      }
    }
  };

  /**
   * @define {slider movement logic for next slide}
   */
  const nextCard = () => {
    let totalWidth = matchStats.slideWidth * matchStats.graphs.length;
    let totalSlidedWidth = carousalWidth + Math.abs(slidermove) + matchStats.slideWidth;
    let lastSideToMove = totalSlidedWidth - totalWidth;
    if (cardVisible < matchStats.graphs.length) {
      totalWidth > totalSlidedWidth
        ? setSlidermove(slidermove - matchStats.slideWidth)
        : setSlidermove(slidermove - (matchStats.slideWidth - lastSideToMove));
      setCardVisible(cardVisible + 1);
    }
  };

  /**
   * @define {slider movement logic for previous slide}
   */
  const prevCard = () => {
    if (cardVisible <= matchStats.graphs.length) {
      Math.abs(slidermove) > matchStats.slideWidth
        ? setSlidermove(slidermove + matchStats.slideWidth)
        : setSlidermove(0);
      setCardVisible(cardVisible - 1);
    }
  };

  /**
   * @define {returned hide graphObject which has not be shown on Dom but avalible in response }
   */
  const hideStatsGraph = () => {
    let graphsToHide = [];
    if (highlights) {
      highlights.hideaerialduelswon_b === true && graphsToHide.push('aerial_won');
      highlights.hidepassescompleted_b === true && graphsToHide.push('accurate_pass');
      highlights.hidepossession_b === true && graphsToHide.push('possession_percentage');
      highlights.hideshotsontarget_b === true && graphsToHide.push('ontarget_scoring_att');
      highlights.hidetackleswon_b === true && graphsToHide.push('won_tackle');
      highlights.hidetotalpasses_b === true && graphsToHide.push('total_pass');
      highlights.hidetotalshots_b === true && graphsToHide.push('total_scoring_att');
    }
    return graphsToHide;
  };

  /**
   *
   * @param {return Dom base as per stats name} stats
   */
  const selectTemplate = (stats) => {
    switch (stats.statsKey) {
      case 'accurate_pass': {
        return (
          <div className="matchday-blogs__info passes-completed">
            <div className={stats.isHomeMU ? 'blogs_team home manu-team' : 'blogs_team home'}>
              <span className="passes-left">
                <span className="matchday-blogs__title">{stats.homeTeamName}</span>
                <span className="percentage-value" style={{ width: stats.homePassessPer }}></span>
              </span>
              <span className="score">{stats.homeStatsScore}</span>
            </div>
            <div className={stats.isAwayMU ? 'blogs_team away manu-team' : 'blogs_team away'}>
              <span className="passes-left">
                <span className="matchday-blogs__title">{stats.awayTeamName}</span>
                <span className="percentage-value" style={{ width: stats.awayPassessPer }}></span>
              </span>
              <span className="score">{stats.awayStatsScore}</span>
            </div>
          </div>
        );
      }
      case 'possession_percentage': {
        return (
          <div className="matchday-blogs__info possession">
            <div className="pie-chart">
              <svg width="68" height="70" className="donut" viewBox="3 37 32 37">
                {stats.homeStatsScore !== 0 && stats.awayStatsScore !== 0 ? (
                  <>
                    <circle
                      className="donut-ring"
                      cx="19"
                      cy="55"
                      r="15.8"
                      fill="transparent"
                      stroke={stats.homeColor}
                      strokeWidth="1.5"
                      strokeDasharray={`${stats.homePossessionPer} ${
                        100 - stats.homePossessionPer
                      }`}
                      strokeDashoffset="27"
                    ></circle>
                    <circle
                      className="donut-ring"
                      cx="19"
                      cy="55"
                      r="15.8"
                      fill="transparent"
                      stroke={stats.awayColor}
                      strokeWidth="1.5"
                      strokeDasharray={`${stats.awayPossessionPer} ${
                        100 - stats.awayPossessionPer
                      }`}
                      strokeDashoffset={127 - stats.homePossessionPer}
                    ></circle>
                  </>
                ) : (
                  <circle
                    className="donut-ring"
                    cx="19"
                    cy="55"
                    r="15.8"
                    fill="transparent"
                    stroke="#d0d0d0"
                    strokeWidth="1.5"
                    strokeDasharray="100 0"
                    strokeDashoffset="0"
                  ></circle>
                )}
                <g direction="ltr">
                  <text
                    className={`${
                      stats.passingPerClass === 'small'
                        ? 'passing-percentage small-font'
                        : 'passing-percentage'
                    }`}
                    textAnchor="middle"
                    x="50%"
                    y="50%"
                    dx="2"
                    dy="42"
                  >
                    {`${stats.isHomeMU ? stats.homePossessionPer : stats.awayPossessionPer}%`}
                  </text>
                </g>
              </svg>
            </div>
            <div className="blogs_team_wrapper">
              <div className={stats.isHomeMU ? 'blogs_team home manu-team' : 'blogs_team home'}>
                <p className="matchday-blogs__title">{stats.homeTeamName}</p>
              </div>
              <div className={stats.isAwayMU ? 'blogs_team away manu-team' : 'blogs_team away'}>
                <span className="screenreader">Vs</span>
                <p className="matchday-blogs__title">{stats.awayTeamName}</p>
              </div>
            </div>
          </div>
        );
      }
      default:
        return (
          <div className="matchday-blogs__info">
            <span className={`matchday-blogs__tag eventsmall-${getStatsIcon(stats)}`}></span>
            <div className={stats.isHomeMU ? 'blogs_team home manu-team' : 'blogs_team home'}>
              <p className="matchday-blogs__title">{stats.homeTeamName}</p>
              <p className="score">{stats.homeStatsScore}</p>
            </div>
            <div className={stats.isAwayMU ? 'blogs_team away manu-team' : 'blogs_team away'}>
              <p className="matchday-blogs__title">{stats.awayTeamName}</p>
              <p className="score">{stats.awayStatsScore}</p>
            </div>
          </div>
        );
    }
  };

  /**
   *
   * @param {returns icons name only as per there stats name} stats
   */
  const getStatsIcon = (stats) => {
    if (stats.statsKey === 'total_scoring_att') return 'total-shots';
    if (stats.statsKey === 'ontarget_scoring_att') return 'shots-target';
    if (stats.statsKey === 'total_pass') return 'total-passes';
    if (stats.statsKey === 'won_tackle') return 'tackle-won';
    if (stats.statsKey === 'aerial_won') return 'dual-won';
  };
  const resizeListener = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setTouchDevice(isSmallDevice);
    }, THRESHOLD);
  };

  /**
   * @define {returns all graph Dom after looping}
   */
  const renderStatsHighlights = () => {
    return (
      <React.Fragment>
        {matchStats.graphs.map((item, index) => (
          <li
            className={`matchday-blogs ${
              matchStats.cardClassName ? matchStats.cardClassName : ''
            } ${touchDevice ? 'highlight__swipe-item' : ''}`}
            key={index}
          >
            <p className="blogs-time">{item.statsName}</p>
            {selectTemplate(matchStats.graphs[index])}
          </li>
        ))}
      </React.Fragment>
    );
  };

  /**
   * @define { retuns Dom conditionally as per small and large device}
   */
  return (
    <div className="matchday-blog">
      <div className="matchday__left-Panel_blogs" ref={sliderRef}>
        {matchStats && matchStats.graphs && matchStats.graphs.length > 0 && (
          <React.Fragment>
            {!touchDevice && (
              <React.Fragment>
                <div className="matchday-buttons" role="region" aria-labelledby="matchhighlights">
                  <h2 id="matchhighlights">{t('matchhighlights')}</h2>
                  <span
                    className="icon-arrow-next highlights_button"
                    aria-hidden="true"
                    onClick={() => nextCard()}
                  ></span>
                  <span
                    className="icon-arrow-prev highlights_button"
                    aria-hidden="true"
                    onClick={() => prevCard()}
                  ></span>
                </div>
                <ul
                  className="matchday-blogs_slider desktop"
                  style={{
                    width: `${matchStats.slideWidth * matchStats.graphs.length}px`,
                    left: slidermove,
                  }}
                >
                  {renderStatsHighlights()}
                </ul>
              </React.Fragment>
            )}
            {touchDevice && (
              <React.Fragment>
                <div className="matchday-buttons" role="region" aria-labelledby="matchhighlights">
                  <h2 id="matchhighlights">{t('matchhighlights')}</h2>
                </div>
                <div className="highlight__swipe">
                  <div className="highlight__swipe-wrapper">
                    <ul className="highlight__swipe-items">{renderStatsHighlights()}</ul>
                  </div>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(MatchHighlight);
