/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useRef, useState } from 'react';
import MU from '../../constants/muConstants';
import { getRandomId, closestElm } from '../../utils/utils';
import * as track from '../../utils/analytics';
export const DFP = ({ slot, size, browserSize, tag, itemId }) => {
  const [oldSize, setOldSize] = useState(null);
  const setTargetTags = (googletag, tag) => {
    if (tag) {
      let tags = tag.value && tag.value.Tags;
      if (tags) {
        for (const [k, v] of Object.entries(tags)) {
          let key = k && k.toLowerCase();
          let value = v && v[0] && v[0].Name;
          if (key && value && googletag) {
            googletag.pubads().setTargeting(key, [value]);
          }
        }
      }
    }
  };
  const [id] = useState(getRandomId());
  const elRef = useRef(null);
  const [isSSR] = useState(typeof window == 'undefined');
  useEffect(() => {
    let resizeTimeout;
    const blurListener = () => {
      let elem = document.activeElement;
      let isDfp = closestElm(elem, '[data-dfp]');
      if (elem && elem.tagName === 'IFRAME' && isDfp) {
        let lineitemid = parseInt(isDfp.getAttribute('data-lineitemid'));
        let creativeid = parseInt(isDfp.getAttribute('data-creativeid'));
        if (lineitemid && creativeid) {
          let initialData = track.data('dfp');
          track.analytics(
            {
              ...initialData,
              ad_creative_ID: creativeid,
              ad_lineitemID: lineitemid,
            },
            elem
          );
        }
      }
    };
    const mousemoveListener = () => {
      if ((document.activeElement && document.activeElement.tagName) === 'IFRAME') {
        window.focus();
      }
    };
    const resizeListener = (checkAdScreenGrp) => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        let oldSizeGrp = checkAdScreenGrp(oldSize);
        let newSizeGrp = checkAdScreenGrp(window.innerWidth);
        if (oldSizeGrp !== newSizeGrp) {
          setOldSize(window.innerWidth);
          window.googletag &&
            window.googletag.pubads &&
            window.googletag.pubads().refresh(window.dfpAds);
        }
      }, 500);
    };
    if (elRef && elRef.current && window) {
      if (!isSSR && !window.dfpAds) {
        window.dfpAds = [];
        setOldSize(window.innerWidth);
        let checkAdScreenGrp = (screenSize) => {
          let group;
          if (screenSize >= 1280) {
            group = 1;
          } else if (screenSize > 1024 && screenSize < 1280) {
            group = 2;
          } else if (screenSize > 768 && screenSize <= 1024) {
            group = 3;
          } else if (screenSize > 568 && screenSize <= 768) {
            group = 4;
          } else if (screenSize >= 300 && screenSize <= 568) {
            group = 5;
          }
          return group;
        };
        window.addEventListener('blur', blurListener);
        window.addEventListener('mousemove', mousemoveListener);
        window.addEventListener('resize', () => resizeListener(checkAdScreenGrp));
      }
      let adSizesArr = [];
      window.googletag = window.googletag || { cmd: [] };
      const googletag = isSSR ? {} : window.googletag;
      googletag.cmd = googletag.cmd || [];
      googletag.cmd.push(() => {
        // Ad size mapping
        let sizeMapping = googletag.sizeMapping();
        if (size) {
          for (let i = 0; i < size.length; i++) {
            let adArr = size[i];
            if (size.length === 1) {
              adSizesArr = adArr;
            } else {
              adSizesArr.push(adArr);
            }
            let adWidth = adArr[0];
            //check browser size
            if (browserSize && browserSize.length > 0) {
              adWidth = browserSize[i];
            }
            let initialSizeArr = [adWidth, 0];
            sizeMapping.addSize(initialSizeArr, adArr);
          }
        }
        sizeMapping = sizeMapping.build();
        let slots = googletag.defineSlot(slot, size, id);
        if (!isSSR && sizeMapping && slot && slots) {
          slots.defineSizeMapping(sizeMapping);
          slots.addService(googletag.pubads());
          window.dfpAds.push(slots);
        }
        if (tag) {
          googletag
            .pubads()
            .setTargeting('Page-Name', MU.screenName)
            .setTargeting('content-id', itemId);
          setTargetTags(googletag, tag);
        } else {
          googletag
            .pubads()
            .setTargeting('Page-Name', MU.screenName)
            .setTargeting('Value', document.title);
        }
        googletag.pubads().enableSingleRequest();
        googletag.pubads().collapseEmptyDivs();
        googletag.enableServices();
      });
      googletag.cmd.push(() => {
        googletag.display(id);
      });
      if (!isSSR && window.googletag && googletag.pubadsReady) {
        googletag.pubads().addEventListener('slotRenderEnded', (event) => {
          try {
            const el = document.getElementById(event.slot.getSlotElementId());
            if (el) {
              let iframe = el.querySelector('iframe');
              el.setAttribute('data-lineItemId', event.lineItemId);
              el.setAttribute('data-creativeId', event.creativeId);
              el.setAttribute('data-dfp', true);
              if (iframe) {
                let span = closestElm(iframe, 'span');
                span && span.classList.add('mu-ad-container');
                iframe.setAttribute('tabindex', '-1');
                iframe.setAttribute('aria-hidden', 'true');
                let adWidth = iframe.offsetWidth;
                if (adWidth === 320) {
                  let adBlock = closestElm(iframe, '.ad-block');
                  adBlock && adBlock.classList.add('iphone-ad');
                }
              }
            }
          } catch (e) {
            console.warn('Error fetching dfp data.', e);
          }
        });
      }
    }
    return () => {
      if (!isSSR && window.dfpAds && window.dfpAds.length === 0) {
        window.removeEventListener('blur', blurListener);
        window.removeEventListener('mousemove', mousemoveListener);
        window.removeEventListener('resize', resizeListener);
      }
    };
  }, [elRef]);
  return <div ref={elRef} id={id}></div>;
};
