/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import { withTranslation } from 'react-i18next';
import ImgCrop from '../../Common/ImgCrop';
import loadable from '@loadable/component';
import { ACCOUNT_SUBSCRIPTION, FREE_TO_VIEW, REGISTERED } from '../../../constants/globalConstants';
import { TEMPLATE_PAGE } from '../../../constants/containerConstants';
import {
  getContentAccessLocal,
  showCBS,
  createTagArr,
  getLoggedInStatus,
  createDestinationUrl,
} from '../../../utils/utils';
import * as track from '../../../utils/analytics';
import { useSponsorValue } from '../../../context/sponsorContext';
import ErrorBoundary from '../../Common/ErrorBoundary';
import Loader from '../../Common/Loader';
import { useModal } from '../../Common/Modal';
import MU from '../../../constants/muConstants';
import BackgroundVideo from '../../Cards/Video/BackgroundVideo';

const RegistrationModal = loadable(() =>
  import('../../Modals/RegistrationModal/RegistrationModal')
);
const PricePackContainer = ({
  fields,
  rendering,
  t,
  heroCarousel,
  itemID,
  PricePack,
  currentActiveSlide,
}) => {
  const itemId = rendering && rendering.dataSource;
  const {
    BannerDescription,
    BannerImage,
    BannerTitle,
    DisplayPricePack,
    ContentType,
    CTATitle,
    CTATitleRegisteredUser,
    CTATitleSubscribedUser,
    CTAUrl,
    manutds,
  } = fields;
  const PricePackResponse =
    PricePack &&
    PricePack.PricePackResponse &&
    PricePack.PricePackResponse.response &&
    PricePack.PricePackResponse.response.docs;
  const [
    { isLoginChecked, isUserLogin, isUserSubscribe, isUserSkyId },
    dispatch,
  ] = useSponsorValue();
  const setContentAccessState = () => {
    if (fields) {
      const tags =
        (!fields.ContentAccess || !fields.ContentAccess.value) && createTagArr(fields.manutds);
      return getContentAccessLocal(
        fields.ContentAccess && fields.ContentAccess.value,
        fields.ContentType && fields.ContentType.value,
        tags
      );
    }
    return null;
  };
  const [contentAccessState] = useState(setContentAccessState());
  const { showModal, closeModal, isModalVisible } = useModal();
  const showContent = () => {
    if (contentAccessState === FREE_TO_VIEW || isLoginChecked) {
      return true;
    }
    return false;
  };
  const [playBGVideo, setPlayBGVideo] = useState(false);

  const autoPlayBGVideo = () => {
    setPlayBGVideo(true);
  };
  useEffect(() => {
    if (
      fields &&
      fields.HeroVideoBackground &&
      fields.HeroVideoBackground.value &&
      (fields.HeroVideoBackground.value.mediaId || fields.HeroVideoBackground.value.MediaId)
    ) {
      autoPlayBGVideo();
    }
  }, [heroCarousel]);
  const getCtaText = () => {
    if (!isUserLogin && contentAccessState === REGISTERED) {
      return CTATitleRegisteredUser && CTATitleRegisteredUser.value
        ? CTATitleRegisteredUser.value
        : t('RegisterToWatch_web');
    } else if (!isUserSubscribe && contentAccessState === ACCOUNT_SUBSCRIPTION) {
      return CTATitleSubscribedUser && CTATitleSubscribedUser.value
        ? CTATitleSubscribedUser.value
        : t('SubscribeToWatch_web');
    }
    return CTATitle && CTATitle.value ? CTATitle.value : t('FreeToWatch_web');
  };

  const renderCtaButton = () => {
    if (showContent()) {
      return (
        <div className="hero-widgets large-btn">
          <div className="red-btn-skew btn" onClick={(e) => onButtonClick(e)}>
            <a href="#" role="link" onClick={(e) => e.preventDefault()}>
              <span>{getCtaText()}</span>
            </a>
          </div>
        </div>
      );
    }
    return <div className="btn-shimmer large" />;
  };

  const onButtonClick = (e) => {
    e.preventDefault();
    trackCard(fields, e);
    if (showCBS({ isUserLogin, isUserSubscribe, contentAccessState })) {
      showModal();
    } else {
      window.location.href =
        CTAUrl && CTAUrl.value && CTAUrl.value.href && createDestinationUrl(CTAUrl.value.href);
    }
  };

  const successCallback = ({ showContent, isSubscribedContent }) => {
    if (showContent && CTAUrl && CTAUrl.value && CTAUrl.value.href) {
      if (!MU.isIDMEnabled) window.location.href = createDestinationUrl(CTAUrl.value.href);
      else {
        return ['successCallbck', isSubscribedContent, 'destinationUrl', CTAUrl.value.href];
      }
    }
  };

  const getAnalyticsData = (fields) => {
    const initialData = track.data('card');
    return {
      ...initialData,
      button_name: getCtaText(),
      card_name: BannerTitle && BannerTitle.value,
      container_type: TEMPLATE_PAGE,
      item_id: itemId,
      content_type: ContentType && ContentType.value,
      tag: manutds && manutds.value && manutds.value.Tags,
      created_datetime: fields.__Published && fields.__Published.value,
      author: fields.Author && fields.Author.value,
      destination_url: CTAUrl && CTAUrl.value && CTAUrl.value.href,
      subscription_status: getLoggedInStatus({
        isLoginChecked,
        isUserLogin,
        isUserSubscribe,
        isUserSkyId,
      }),
      content_status: contentAccessState,
    };
  };
  const trackCard = (fields, e) => {
    track.analytics(getAnalyticsData(fields), e);
  };

  const renderPricePack = () => {
    if (PricePackResponse && PricePackResponse.length > 0) {
      if (showContent()) {
        return (
          <React.Fragment>
            <div className="price-detail">
              {PricePackResponse.map((value, index) => {
                return (
                  value.price_t &&
                  value.validity_t && (
                    <React.Fragment>
                      <span className="price-month">
                        {value.price_t} <span> {value.validity_t} </span>
                        {index != PricePackResponse.length - 1 && <div className="line" />}
                      </span>
                      {index != PricePackResponse.length - 1 && <p className="screenreader" />}
                    </React.Fragment>
                  )
                );
              })}
            </div>
            <p className="subscription-required">{t('NoCommitmentsSubscriptionRequired')}</p>
          </React.Fragment>
        );
      }
      return (
        <div className="price-shimmer-wrapper">
          <div className="price-shimmer" />
          <div className="commitment-shimmer" />
        </div>
      );
    }
    return null;
  };

  const getBGMediaID = (data) => {
    if (
      data &&
      data.HeroVideoBackground &&
      data.HeroVideoBackground.value &&
      (data.HeroVideoBackground.value.mediaId || data.HeroVideoBackground.value.MediaId)
    ) {
      return data.HeroVideoBackground.value.mediaId
        ? data.HeroVideoBackground.value.mediaId
        : data.HeroVideoBackground.value.MediaId;
    }
    return '';
  };

  const videoData = () => {
    return {
      VideoID: {
        value: getBGMediaID(fields),
      },
      ContentType: { value: ContentType?.value },
      ShortHeadline: {
        value: fields && fields.BannerTitle && fields.BannerTitle.value && fields.BannerTitle.value,
      },
      manutds: { value: { Author: '', Tags: '' } },
      ContentAccess: {
        value:
          fields &&
          fields.ContentAccess &&
          fields.ContentAccess.value &&
          fields.ContentAccess.value,
      },
    };
  };

  const renderImgVideo = () => {
    try {
      return (
        <div className="img-holder">
          {BannerImage && BannerImage.value && BannerImage.value.Crops && (
            <LazyLoad once={true} offset={100}>
              <ImgCrop
                imgCrop={BannerImage.value.Crops}
                alt={BannerImage.AltText}
                isWebpAvailable={BannerImage.isWebpAvailable}
                gridClass="grid-12"
              />
            </LazyLoad>
          )}
          {heroCarousel &&
            playBGVideo &&
            itemID === currentActiveSlide &&
            fields &&
            fields.HeroVideoBackground &&
            (fields.HeroVideoBackground.value.mediaId ||
              fields.HeroVideoBackground.value.MediaId) && (
              <>
                <LazyLoad once={true} offset={100}>
                  <BackgroundVideo
                    data={videoData()}
                    isMutv={false}
                    isCloseIcon={false}
                    type="BGVideoAutoPlay"
                  />
                </LazyLoad>
              </>
            )}
        </div>
      );
    } catch (e) {
      console.log('Error in render ImgVideo', e);
    }
  };
  return (
    <React.Fragment>
      <div className="home-row">
        <div className="home-hero">
          <div
            className={`mutv-hero-tmpl price-pack-tmpl heroBGVideo`}
            data-impression={track.impression({
              card_name: BannerTitle && BannerTitle.value,
              content_type: ContentType && ContentType.value,
            })}
          >
            <div className="mu-content">
              <div className="mu-item">{renderImgVideo()}</div>
            </div>
          </div>
          <div className="price-contianer">
            <div className={`mu-item__info ${showContent() ? '' : 'info-shimmer'}`}>
              {BannerTitle && BannerTitle.value && (
                <h2 className="mu-item__title" id="mutv-main-heading">
                  {BannerTitle.value}
                </h2>
              )}
              {BannerDescription && BannerDescription.value && (
                <p className="mu-item__teasure">{BannerDescription.value}</p>
              )}
              {!isUserSubscribe &&
                contentAccessState === ACCOUNT_SUBSCRIPTION &&
                DisplayPricePack &&
                DisplayPricePack.value &&
                PricePackResponse &&
                renderPricePack()}
              {renderCtaButton()}
            </div>
          </div>
        </div>
      </div>
      {isModalVisible && (
        <ErrorBoundary>
          <RegistrationModal
            successCallback={successCallback}
            closeModal={closeModal}
            fallback={<Loader />}
            previousActiveElement={document.activeElement}
            accessType={contentAccessState}
            analyticsObj={getAnalyticsData(fields)}
          />
        </ErrorBoundary>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(PricePackContainer);
