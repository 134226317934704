/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const OneRow = (props) => (
  <div className="tmpls-container">
    <Placeholder
      name="mufc-ph-onerow"
      rendering={props.rendering}
      missingComponentComponent={(component) => {
        return null;
      }}
    />
  </div>
);

export default OneRow;
