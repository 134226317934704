/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState } from 'react';
import { buildImgUrl, getAbbreviationPeriod, getPeriodForAccessibility } from '../../utils/utils';
import { withTranslation } from 'react-i18next';
import { isLiveByPeriod, isResultsByPeriod, manuIds } from '../../utils/matchUtils';
import moment from 'moment';
import { data, analytics } from '../../utils/analytics';
import { MATCH_LISTING } from '../../constants/containerConstants';

const FixtureDetail = ({ t, matchDetail, type, postponed, id }) => {
  const [expandAction, setExpandAction] = useState(false);
  const expandMatchDetail = (e) => {
    if (
      document.activeElement &&
      document.activeElement.classList &&
      document.activeElement.classList.contains('non-keyboard-outline')
    ) {
      const focusEle = document.activeElement;
      setTimeout(() => {
        focusEle.classList &&
          !focusEle.classList.contains('non-keyboard-outline') &&
          focusEle.classList.add('non-keyboard-outline');
        focusEle.focus();
      }, 10);
    }
    setExpandAction(!expandAction);
    buildAnalyitcsData('#');
  };

  const buildAnalyitcsData = (destinationUrl) => {
    const {
      hometeam_t,
      awayteam_t,
      matchdate_tdt,
      itemid_s,
      contenttype_t,
      __published_tdt,
      taglist_sm,
    } = matchDetail;
    const initialData = data('card');
    analytics({
      ...initialData,
      card_name: hometeam_t + ' vs ' + awayteam_t + ' ' + matchdate_tdt,
      container_type: MATCH_LISTING,
      item_id: itemid_s,
      content_type: contenttype_t,
      tag: taglist_sm,
      created_datetime: __published_tdt,
      author: '',
      destination_url: destinationUrl,
    });
  };

  // matchDetail will have following properties
  const {
    resultdata_t,
    hometeamimage_s,
    awayteamimage_s,
    matchdate_tdt,
    venuename_t,
    ctatitle_t,
    ctaurl_t,
    showBroadcasterLogo,
    broadcasterdetails_s,
    ismatchdatetbc_b,
    matchdatetbc_t,
    isabandoned_b,
    hometeamshortname_t,
    awayteamshortname_t,
    hometeam_t,
    awayteam_t,
    awayGoals,
    homeGoals,
    hometeamid_t,
    awayteamid_t,
    homePenalty,
    awayPenalty,
  } = matchDetail;
  const homeTeamName = hometeamshortname_t ? hometeamshortname_t : hometeam_t;
  const awayTeamName = awayteamshortname_t ? awayteamshortname_t : awayteam_t;

  const { AwayResult, HomeResult, Period } = resultdata_t;
  const expandData = expandAction ? 'expanded' : '';
  const twoItemClass =
    (showBroadcasterLogo || (ismatchdatetbc_b && matchdatetbc_t)) && ctatitle_t && ctaurl_t
      ? 'fixture-channels two-item'
      : 'fixture-channels';
  const getScoreClass = (period, match) => {
    let scoreClass = '';
    if (period) {
      period = period.toLowerCase();
      if (isLiveByPeriod(period)) {
        scoreClass = 'live';
      } else if (period === 'fulltime' || period === 'full time') {
        scoreClass = 'result hover-dot';
      }
    }
    if (match) {
      if (match.hometeamid_t !== '1' && match.awayteamid_t !== '1') {
        scoreClass += ' non-mu';
      }
    }
    return scoreClass;
  };

  const fixtureClassName = `fixture-details ${expandData} ${getScoreClass(Period, matchDetail)}`;

  const manuBoldClass = (teamId) => {
    if (manuIds.indexOf(teamId) !== -1) {
      return 'manu-normal';
    }
    return '';
  };

  const getGoalType = (type) => {
    let goalType = '';
    if (type) {
      type = type.toLowerCase();
    }
    if (type === 'owng' || type === 'own') {
      goalType = `(${t('own')})`;
    } else if (type === 'penalty') {
      goalType = `(${t('pen')})`;
    }
    return goalType;
  };

  return (
    <div>
      <div className="Matches__fixture">
        <div
          className={fixtureClassName}
          tabIndex="0"
          aria-expanded={expandAction}
          onClick={(e) => expandMatchDetail(e)}
          onKeyDown={(e) => (e.keyCode === 13 || e.which === 13) && expandMatchDetail(e)}
        >
          <div className="accord-btn">
            <span>
              <i className="icon-down"></i>
            </span>
          </div>
          <ul>
            <li className="first-team">
              <span>
                {hometeamimage_s && (
                  <img src={buildImgUrl(hometeamimage_s.img1x)} alt="" title="" />
                )}
              </span>
              <span className={`team__name ${manuBoldClass(hometeamid_t)}`}>
                {homeTeamName}
                <span className="screenreader">{` ${t('versus')}`}</span>
              </span>
              {homeGoals &&
                homeGoals.length > 0 &&
                (isResultsByPeriod(Period) || isLiveByPeriod(Period)) && (
                  <ul className="first-team_goals sub-child">
                    {homeGoals.map((goal, i) => (
                      <li key={i}>
                        {goal && (
                          <span>
                            {!goal.card && <i aria-hidden="true" className="icon-goal-black"></i>}
                            {goal.card && goal.card.toLowerCase() === 'red' && (
                              <i aria-hidden="true" className="red-card"></i>
                            )}
                          </span>
                        )}
                        <span style={{ outline: 'none' }}>
                          <span className="screenreader">{t('goaldoneby')}</span>
                          {goal &&
                            goal.player &&
                            `${
                              goal.player.KnownName ? goal.player.KnownName : goal.player.LastName
                            } ${getGoalType(goal.type)} ${goal.time ? goal.time : ''}`}
                          <span className="screenreader">{t('minutesago')}</span>
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
            </li>

            <li className="second-team">
              <span>
                {awayteamimage_s && (
                  <img src={buildImgUrl(awayteamimage_s.img1x)} alt="" title="" />
                )}
              </span>
              <span className={`team__name ${manuBoldClass(awayteamid_t)}`}>{awayTeamName}</span>
              {awayGoals &&
                awayGoals.length > 0 &&
                (isResultsByPeriod(Period) || isLiveByPeriod(Period)) && (
                  <ul className="second-team_goals sub-child">
                    {awayGoals.map((goal, i) => (
                      <li key={i}>
                        {goal && (
                          <span>
                            {!goal.card && <i aria-hidden="true" className="icon-goal-black"></i>}
                            {goal.card && goal.card.toLowerCase() === 'red' && (
                              <i aria-hidden="true" className="red-card"></i>
                            )}
                          </span>
                        )}
                        <span style={{ outline: 'none' }}>
                          <span className="screenreader">{t('goaldoneby')}</span>
                          {goal &&
                            goal.player &&
                            `${
                              goal.player.KnownName ? goal.player.KnownName : goal.player.LastName
                            } ${getGoalType(goal.type)} ${goal.time ? goal.time : ''}`}
                          <span className="screenreader">{t('minutesago')}</span>
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
            </li>
            {!postponed && (
              <li className="fixture-score">
                <p>
                  {(Period.toLowerCase() === 'pre match' ||
                    Period.toLowerCase() === 'prematch') && (
                    <>
                      <span className="screenreader">{`${t('time')} `}</span>
                      {!isabandoned_b && (
                        <span className="match">{moment(matchdate_tdt).format('HH:mm')}</span>
                      )}
                      {isabandoned_b && (
                        <span>
                          {t('A')} - {t('A')}
                        </span>
                      )}
                    </>
                  )}
                  {Period.toLowerCase() !== 'pre match' && Period.toLowerCase() !== 'prematch' && (
                    <>
                      <span className="screenreader">{t('Score')}</span>
                      {!isabandoned_b && <span>{HomeResult.Score}</span>}
                      {isabandoned_b && <span>{t('A')}</span>}
                      <span> - </span>
                      {!isabandoned_b && <span>{AwayResult.Score}</span>}
                      {isabandoned_b && <span>{t('A')}</span>}
                    </>
                  )}
                </p>
                {(isLiveByPeriod(Period) || isResultsByPeriod(Period)) && (
                  <ul className="sub-child">
                    <li>
                      <span aria-hidden="true">{getAbbreviationPeriod(Period, t)}</span>
                      <span className="screenreader">{getPeriodForAccessibility(Period)}</span>
                    </li>
                  </ul>
                )}
              </li>
            )}
            {postponed && (
              <li className="fixture-score">
                <p>
                  <span>{t('VS')}</span>
                </p>
              </li>
            )}
          </ul>
          {(homePenalty || awayPenalty) && (
            <p className="team-venue penalty">
              {t('Pens')} {homePenalty ? homePenalty : 0}-{awayPenalty ? awayPenalty : 0}
            </p>
          )}
          <p className="team-venue">{venuename_t}</p>

          <div className={twoItemClass}>
            {showBroadcasterLogo && (
              <div className="fixture-sponsor">
                <span className="screenreader">{t('broadcaster')}</span>
                <img
                  src={buildImgUrl(broadcasterdetails_s.BroadcasterIconDark)}
                  alt={broadcasterdetails_s.BroadcasterName}
                />
              </div>
            )}

            {!postponed && !showBroadcasterLogo && ismatchdatetbc_b && matchdatetbc_t && (
              <div className="fixture-tbc-text">
                <span>{matchdatetbc_t}</span>
              </div>
            )}
            {!postponed && ctaurl_t && ctatitle_t && (
              <div className="fixture-link">
                <a
                  href={ctaurl_t}
                  onClick={(e) => (e.stopPropagation(), buildAnalyitcsData(ctaurl_t))}
                  aria-describedby={id}
                >
                  <span>{ctatitle_t}</span>
                  <i className="tag-red-arrow"></i>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(FixtureDetail);
