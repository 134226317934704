/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState } from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import Transform from '../../../data-transform';
import {
  inlineVideoData,
  showCBS,
  inlineImageData,
  getCtaUrlLink,
  checkMarketPotRule,
  checkResponseDocs,
  getLoggedInStatus,
} from '../../../utils/utils';
import * as track from '../../../utils/analytics';
import { useSponsorValue } from '../../../context/sponsorContext';
import { REGISTERED, FREE_TO_VIEW } from '../../../constants/globalConstants';
import MU from '../../../constants/muConstants';
import ErrorBoundary from '../../Common/ErrorBoundary';
import Loader from '../../Common/Loader';
import loadable from '@loadable/component';
import { useModal } from '../../Common/Modal';
const RegistrationModal = loadable(() =>
  import('../../Modals/RegistrationModal/RegistrationModal')
);
const Basic = (props) => {
  const { isBlock, fields, params } = props;
  const {
    Heading1,
    Heading2,
    Heading3,
    Heading4,
    Summary,
    Description,
    PrimaryCTATitle,
    SecondaryCTATitle,
    PrimaryCTAUrl,
    SecondaryCTAUrl,
    Video,
    Image,
    TertiaryLabel,
    ContentAccess,
    CTATitleRegisteredUser,
    CTATitleSubscribedUser,
    CTATitle,
    CTAUrl,
  } = fields;
  const [{ isLoginChecked, isUserLogin, isUserSubscribe, isUserSkyId }] = useSponsorValue();
  const { showModal, closeModal, isModalVisible } = useModal();
  const isContextual = params && params.IsContextual && params.IsContextual === '1';
  const getContentAccess = () => {
    if (isContextual) {
      let finalContentAccess =
        ContentAccess && ContentAccess.value ? ContentAccess.value : FREE_TO_VIEW;
      if (finalContentAccess && MU && checkResponseDocs(MU.MarketAccessRulesResponse)) {
        const marketAccessValue = checkMarketPotRule(
          finalContentAccess,
          MU.MarketAccessRulesResponse.response.docs[0]
        );
        finalContentAccess = marketAccessValue ? marketAccessValue : finalContentAccess;
      }
      return finalContentAccess;
    }
    return null;
  };
  const [contentAccessState] = useState(getContentAccess());
  const video = Video && Video.value;
  const image = Image && Image.value;
  const media = (video && video.MediaId) || (image && image.Crops);
  const theme = (params && params.Theme) || 'White';
  let position = (params && params.Position) || 'Left';
  position = position === 'Bottom' ? 'Left' : position;

  const getAnalyticsData = (data, isCBS) => {
    const initialData = track.data('link');
    if (isCBS) {
      initialData.content_status = contentAccessState;
      initialData.subscription_status = getLoggedInStatus({
        isLoginChecked,
        isUserLogin,
        isUserSubscribe,
        isUserSkyId,
      });
    }
    return {
      ...initialData,
      button_name: data.title,
      destination_url: data.url,
    };
  };

  const trackLink = (data, e, isCBS) => {
    track.analytics(getAnalyticsData(data, isCBS), e);
  };

  const getContextTitle = (openCBS) => {
    if (openCBS) {
      return contentAccessState === REGISTERED ? CTATitleRegisteredUser : CTATitleSubscribedUser;
    }
    return CTATitle;
  };

  const renderCTA = () => {
    const openCBS =
      contentAccessState && showCBS({ isUserLogin, isUserSubscribe, contentAccessState });
    if (openCBS || (!openCBS && CTATitle && CTATitle.value && CTAUrl && CTAUrl.value)) {
      const title = getContextTitle(openCBS);
      const href = openCBS ? '#' : getCtaUrlLink(CTAUrl.value);
      return (
        <a
          className="btn-red"
          href={href}
          role="button"
          onClick={(e) => {
            trackLink({ title: title.value, url: href }, e, true);
            if (openCBS) {
              e.preventDefault();
              showModal();
            }
          }}
        >
          <Text field={title} />
        </a>
      );
    }
    return null;
  };

  const content = () => {
    return (
      <div className="content tmp-basic-clear">
        {Heading1 && Heading1.value && (
          <h2>
            <Text field={Heading1} />
          </h2>
        )}
        {Heading2 && Heading2.value && (
          <h3>
            <Text field={Heading2} />
          </h3>
        )}
        {Heading3 && Heading3.value && (
          <h4>
            <Text field={Heading3} />
          </h4>
        )}
        {Heading4 && Heading4.value && (
          <h5>
            <Text field={Heading4} />
          </h5>
        )}
        {Summary && Summary.value && (
          <div className="summary">
            <Text field={Summary} />
          </div>
        )}
        {Description && Description.value && (
          <div className="desc">
            <RichText field={Description} />
          </div>
        )}
        <div className="ctawrap">
          {TertiaryLabel && TertiaryLabel.value ? (
            <div className="btn-success">{TertiaryLabel.value}</div>
          ) : (
            <React.Fragment>
              {isContextual && renderCTA()}
              {!isContextual &&
                PrimaryCTATitle &&
                PrimaryCTATitle.value &&
                PrimaryCTAUrl &&
                PrimaryCTAUrl.value && (
                  <a
                    className="btn-red"
                    target={PrimaryCTAUrl.value.target ? PrimaryCTAUrl.value.target : null}
                    href={getCtaUrlLink(PrimaryCTAUrl.value)}
                    role="button"
                    onClick={(e) =>
                      trackLink({ title: PrimaryCTATitle.value, url: PrimaryCTAUrl.value.href }, e)
                    }
                  >
                    <Text field={PrimaryCTATitle} />
                  </a>
                )}
              {SecondaryCTATitle &&
                SecondaryCTATitle.value &&
                SecondaryCTAUrl &&
                SecondaryCTAUrl.value && (
                  <a
                    className="btn-white"
                    target={SecondaryCTAUrl.value.target ? SecondaryCTAUrl.value.target : null}
                    href={getCtaUrlLink(SecondaryCTAUrl.value)}
                    role="button"
                    onClick={(e) =>
                      trackLink(
                        { title: SecondaryCTATitle.value, url: SecondaryCTAUrl.value.href },
                        e
                      )
                    }
                  >
                    <Text field={SecondaryCTATitle} />
                  </a>
                )}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  };

  const successCallback = ({ showContent, isSubscribedContent }) => {
    if (showContent && CTAUrl) {
      if (!MU.isIDMEnabled) window.location = getCtaUrlLink(CTAUrl.value);
      else {
        return [
          'successCallbck',
          isSubscribedContent,
          'destinationUrl',
          getCtaUrlLink(CTAUrl.value),
        ];
      }
    }
  };

  return (
    <React.Fragment>
      {isModalVisible && (
        <ErrorBoundary>
          <RegistrationModal
            successCallback={successCallback}
            closeModal={closeModal}
            fallback={<Loader />}
            previousActiveElement={document.activeElement}
            accessType={contentAccessState}
            analyticsObj={getAnalyticsData({ title: getContextTitle(true).value, url: '#' })}
          />
        </ErrorBoundary>
      )}
      <div
        className={`tmp-basic tmp-basic-clear ${theme ? theme : ''} ${position ? position : ''} ${
          media ? (isBlock ? 'no-media' : 'media') : 'no-media'
        }`}
      >
        <div className="main tmp-basic-clear">
          {!media && content()}
          {media && position === 'Right' && content()}
          {media && !isBlock && (
            <div className="imgVid">
              {video && video.MediaId ? (
                <React.Fragment>
                  <Transform
                    componentName="video"
                    gridClass="grid-6"
                    isInlineVideo={true}
                    tracking={false}
                    data={inlineVideoData({
                      crops: image.Crops,
                      video: video,
                    })}
                    source="aws"
                    showStatsbar={false}
                    isDestinationPage={true}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {image && (
                    <Transform
                      componentName="image"
                      gridClass="grid-6"
                      isInlineImage={true}
                      tracking={false}
                      data={inlineImageData({
                        crops: image.Crops,
                      })}
                      source="aws"
                      showStatsbar={false}
                      hideGradient={true}
                    />
                  )}
                </React.Fragment>
              )}
            </div>
          )}
          {media && position === 'Left' && content()}
        </div>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(React.memo(Basic));
