/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { createContext, useContext, useReducer } from 'react';
import { listingState, listingReducer } from '../reducers/listingReducer';

export const ListingContext = createContext();

export const ListingProvider = ({ children }) => (
  <ListingContext.Provider value={useReducer(listingReducer, listingState)}>
    {children}
  </ListingContext.Provider>
);
export const useListingValue = () => useContext(ListingContext);

export default ListingContext;
