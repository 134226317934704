/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { CompetitionLogo } from '../../Common/matchesCard/CompetitionLogo';
import {
  MatchData,
  HomeTeam,
  AwayTeam,
  Competition,
  GoalBy,
  Versus,
} from '../../../constants/globalConstants';
import { withTranslation } from 'react-i18next';
import { getMatchDate, getMatchTime } from '../../../utils/momentDate';
import * as track from '../../../utils/analytics';
import LazyLoad from 'react-lazyload';
const MatchCard = (props) => {
  const { t, item, type } = props;
  /**
   *
   * @param {ctaURl to track url of link } ctaURl
   * @param {ctaTitle to track title } ctaURl
   * @param {clicked event object} e
   */
  const trackLink = (ctaURl, ctaTitle, e) => {
    const initialData = track.data('link');
    track.analytics({ ...initialData, button_name: ctaTitle, destination_url: ctaURl }, e);
  };
  const validateData = () => {
    if (item && item.contenttype_t && item.contenttype_t.toLowerCase() === MatchData) {
      if (item.opta_cid_t && item.opta_sid_t && item.opta_mid_t) {
        return true;
      }
    }
    return false;
  };
  const getMatchInfo = () => {
    if (item.ispostponed_b) {
      return (
        <p className="match-item__info--teasure">
          <span>{t('MatchPostponed')}</span>
        </p>
      );
    } else if (item.isabandoned_b) {
      return (
        <p className="match-item__info--teasure">
          <span>{getMatchDate(item.matchdate_tdt, 'dddd DD MMMM YYYY')},</span>
          <span>{t('Matchabandoned')}</span>
        </p>
      );
    } else {
      return (
        <p className="match-item__info--teasure">
          <span>{getMatchDate(item.matchdate_tdt, 'dddd DD MMMM YYYY')},</span>
          <span>
            {getMatchTime(item.matchdate_tdt)}
            {item.venuename_t ? `, ${item.venuename_t}` : ''}
          </span>
        </p>
      );
    }
  };

  const getTeamName = (teamType) => {
    if (teamType === HomeTeam) {
      return item.hometeamshortname_t
        ? item.hometeamshortname_t
        : item.hometeam_t
        ? item.hometeam_t
        : '';
    } else if (teamType === AwayTeam) {
      return item.awayteamshortname_t
        ? item.awayteamshortname_t
        : item.awayteam_t
        ? item.awayteam_t
        : '';
    }
  };

  const getTeamScore = (resultData, teamType) => {
    if (teamType === HomeTeam && resultData && resultData.HomeResult) {
      return resultData.HomeResult.Score ? resultData.HomeResult.Score : 0;
    } else if (teamType === AwayTeam && resultData && resultData.AwayResult) {
      return resultData.AwayResult.Score ? resultData.AwayResult.Score : 0;
    }
  };

  const renderFixture = () => {
    return (
      <React.Fragment>
        <span className="screenreader">{`${getTeamName(HomeTeam)} ${Versus} ${getTeamName(
          AwayTeam
        )}`}</span>
        <div className="match-item__info--team gray-block">
          <span aria-hidden="true">{getMatchTime(item.matchdate_tdt)}</span>
        </div>
      </React.Fragment>
    );
  };

  const renderResult = (resultData, isLive) => {
    const homeTeamName = getTeamName(HomeTeam);
    const awayTeamName = getTeamName(AwayTeam);
    const homeTeamScore = getTeamScore(resultData, HomeTeam);
    const awayTeamScore = getTeamScore(resultData, AwayTeam);

    return (
      <React.Fragment>
        {isLive && (
          <div className="match-item__info--team red-block">
            <span aria-hidden="true">{homeTeamScore}</span>
            <span aria-hidden="true"> - </span>
            <span aria-hidden="true">{awayTeamScore}</span>
          </div>
        )}
        <span className="screenreader">{`${GoalBy} ${homeTeamName} ${homeTeamScore} ${Versus} ${GoalBy} ${awayTeamName} ${awayTeamScore}`}</span>
        {!isLive && (
          <div className="match-item__info--team black-block" aria-hidden="true">
            <span>{`${homeTeamScore} - ${awayTeamScore}`}</span>
          </div>
        )}
      </React.Fragment>
    );
  };

  const renderMatchInfo = () => {
    const resultData = parseJsonData(item && item.resultdata_t);
    if (
      resultData &&
      resultData.Period &&
      (resultData.Period.toLowerCase() === 'prematch' ||
        resultData.Period.toLowerCase() === 'pre match')
    ) {
      return renderFixture(resultData);
    } else {
      return renderResult(resultData);
    }
  };

  const renderLiveMatchInfo = () => {
    const resultData = parseJsonData(item && item.resultdata_t);
    return renderResult(resultData, true);
  };

  const getTeameNamesandVS = () => {
    return (
      <React.Fragment>
        <div className="match-item__info--team team-name">
          <span>{getTeamName(HomeTeam)}</span>
        </div>
        <div className="match-item__info--team team-vs">
          <span>{t('VS')}</span>
        </div>
        <div className="match-item__info--team team-name">
          <span>{getTeamName(AwayTeam)}</span>
        </div>
      </React.Fragment>
    );
  };
  const getMatchCta = () => {
    const resultData = parseJsonData(item && item.resultdata_t);
    let ctaTitle;
    let ctaURl;
    if (resultData && resultData.Period && type !== 'live') {
      if (
        resultData.Period.toLowerCase() === 'prematch' ||
        resultData.Period.toLowerCase() === 'pre match'
      ) {
        ctaTitle = item.ticketctatitle_t;
        ctaURl = item.ticketctaurl_t;
      } else if (
        resultData.Period.toLowerCase() === 'fulltime' ||
        resultData.Period.toLowerCase() === 'full time'
      ) {
        ctaTitle = item.matchdayresultctatitle_t;
        ctaURl = item.matchdayctaurl_t;
      }
    } else if (type === 'live') {
      ctaTitle = item.matchdaylivectatitle_t;
      ctaURl = item.matchdayctaurl_t;
    }
    return (
      ctaTitle &&
      ctaURl && (
        <a
          href={ctaURl}
          tabIndex="0"
          className={`${type === 'live' ? 'live' : ''} `}
          onClick={(e) => trackLink(ctaURl, ctaTitle, e)}
        >
          {ctaTitle}
          <i className="icon-cta-arrow"></i>
        </a>
      )
    );
  };
  const parseJsonData = (data) => {
    try {
      return data && JSON.parse(data);
    } catch (e) {
      console.error('Error in parsing data of match carousel');
    }
  };
  const getLogo = (teamType) => {
    if (item && item.celumimages_s) {
      let celumimages = parseJsonData(item.celumimages_s);
      return (
        <LazyLoad once={true} offset={100}>
          <CompetitionLogo celumimages={celumimages} teamType={teamType}></CompetitionLogo>
        </LazyLoad>
      );
    }
  };

  return (
    <React.Fragment>
      {validateData() && (
        <div data-type={type} className="matchcard">
          <div className="grid-3 ratio">
            <div className="mu-content">
              <span className="screenreader">{item.contenttype_t}</span>
              <article className="mu-item">
                <div className="match-item__league">{getLogo(Competition)}</div>
                <div className="tbc-text">
                  {item.ismatchdatetbc_b && !item.isresultdeclared_b ? item.matchdatetbc_t : ''}
                </div>
                {getMatchInfo()}
                <section className="match-item__info">
                  <div className="match-item__info--team team-left" aria-hidden="true">
                    {getLogo(HomeTeam)}
                  </div>
                  {type !== 'live' ? renderMatchInfo() : renderLiveMatchInfo()}
                  <div className="match-item__info--team team-right" aria-hidden="true">
                    {getLogo(AwayTeam)}
                  </div>
                </section>
                <section className="match-item__info teams" aria-hidden="true">
                  {getTeameNamesandVS()}
                </section>
                <div className="match-item__cta">{getMatchCta()}</div>
              </article>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(React.memo(MatchCard));
