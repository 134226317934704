/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_VENU_TRAVEL_REPORT,
  SET_VENU_TRAVEL_REPORT,
  ERROR_VENU_TRAVEL_REPORT,
  FETCH_MU_TRAVEL_REPORT,
  SET_MU_TRAVEL_REPORT,
  ERROR_MU_TRAVEL_REPORT,
  FETCH_PERSIST_PROXY,
  SET_PERSIST_PROXY,
  ERROR_PERSIST_PROXY,
  SEND_TRAVEL_USER_DATA,
} from '../constants/actions/actionConstant';

export const muTravelState = {
  venuTravelFormData: null,
  venuTravelFormStatus: null,

  muTravelFormData: null,
  muTravelFormStatus: null,

  persistProxyStatus: null,
  persistProxyData: null,
};

/**
 * @param {initial state} state
 * @param {Action type} type
 * @param {response} response data
 */
export const muTravelReducer = (state = muTravelState, { type, response } = {}) => {
  switch (type) {
    case FETCH_VENU_TRAVEL_REPORT: {
      return {
        ...state,
        venuTravelFormStatus: 'fetching',
        venuTravelFormData: null,
      };
    }
    case SET_VENU_TRAVEL_REPORT: {
      return {
        ...state,
        venuTravelFormStatus: 'fetched',
        venuTravelFormData: response.data,
      };
    }
    case ERROR_VENU_TRAVEL_REPORT: {
      return {
        ...state,
        venuTravelFormStatus: 'error',
        venuTravelFormData: null,
      };
    }

    case FETCH_MU_TRAVEL_REPORT: {
      return {
        ...state,
        muTravelFormStatus: 'fetching',
        muTravelFormData: null,
      };
    }
    case SET_MU_TRAVEL_REPORT: {
      return {
        ...state,
        muTravelFormStatus: 'fetched',
        muTravelFormData: response.data,
      };
    }
    case ERROR_MU_TRAVEL_REPORT: {
      return {
        ...state,
        muTravelFormStatus: 'error',
        muTravelFormData: null,
      };
    }

    /**--------------------- */

    case FETCH_PERSIST_PROXY: {
      return {
        ...state,
        persistProxyStatus: 'fetching',
        persistProxyData: null,
      };
    }
    case SET_PERSIST_PROXY: {
      return {
        ...state,
        persistProxyStatus: 'fetched',
        persistProxyData: response.data,
      };
    }
    case ERROR_PERSIST_PROXY: {
      return {
        ...state,
        persistProxyStatus: 'error',
        persistProxyData: null,
      };
    }

    default:
      return state;
  }
};
