/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Modal from '../Common/Modal';
import { withTranslation } from 'react-i18next';
import { continueOnOtherAction, playHereAction } from '../../actions/sponsorAction';
import { deviceManageApi, reducerDispatcher } from '../../utils/utils';
import { PLAY, STOP } from '../../constants/globalConstants';
const DeviceMangerModal = (props) => {
  const {
    dispatch,
    UID,
    deviceID,
    deviceSynclimitReached,
    deviceInfo,
    previousActiveElement,
    t,
    closeModal,
  } = props;
  const continueOnOther = () => {
    continueOnOtherAction(dispatch, true);
  };
  const playHere = () => {
    if (deviceInfo.deviceId != deviceID && deviceSynclimitReached) {
      const payloadData = {
        DeviceInfo: [
          {
            statusFlag: STOP,
            deviceId: deviceInfo.deviceId,
            deviceName: deviceInfo.deviceName,
          },
          {
            statusFlag: PLAY,
            deviceId: deviceID,
            deviceName: t('MUTVWebPlayer'),
          },
        ],
        UidGigya: UID,
      };
      deviceManageApi(payloadData)
        .then((resp) => {
          reducerDispatcher(resp, payloadData.DeviceInfo[0], dispatch);
          playHereAction(dispatch, true);
        })
        .catch((err) => console.log('error', err));
    }
  };

  const getCtaButton = () => {
    return (
      <React.Fragment>
        <div>
          <a
            href="#"
            role="button"
            class="red-btn-skew btn"
            aria-label={t('ContinueWatch')}
            onClick={(e) => {
              e.preventDefault();
              continueOnOther();
            }}
          >
            <span aria-hidden="true">{t('ContinueWatch')}</span>
          </a>
        </div>
        <div>
          <a
            href="#"
            role="button"
            aria-label={t('PlayHere')}
            class="white-btn-skew btn"
            onClick={(e) => {
              e.preventDefault();
              playHere();
            }}
          >
            <span aria-hidden="true">{t('PlayHere')}</span>
          </a>
        </div>
      </React.Fragment>
    );
  };
  return (
    <div className="customMobile">
      <Modal
        containerClass="dialog epg-modal device-manager-modal"
        customElm={true}
        showClose={false}
        closeModal={closeModal}
        previousActiveElement={previousActiveElement}
        ariaDescribedBy="dialogMesg"
      >
        <div id="modelCustomPop">
          <div className="watch-widget">
            <div className="watch-widget-inner">
              <h2 className="device-modal-heading" id="dialogMesg">
                {t('DeviceManageMent')}
              </h2>
              <p className="date-time">{t('AlreadyLoggedIN')}</p>
              <h3>{t('MUTVWebPlayer')}</h3>
              {getCtaButton()}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
DeviceMangerModal.defaultProps = {
  UID: false,
  deviceID: false,
  deviceSynclimitReached: false,
  deviceInfo: false,
  previousActiveElement: false,
};
export default withTranslation()(DeviceMangerModal);
