/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { SEARCH } from '../../constants/containerConstants';
import Transform from '../../data-transform';
import { setNewsFilter } from '../../utils/searchUtils';

const ImagesSearch = (props) => {
  const { results, resultsCount } = props;

  const renderHtml = (item) => {
    item.appearance_tdt = item.__published_tdt;
    item.variant_t = 'search-child';
    if (item.contenttype_t.toLowerCase() === 'blogentry' && item.isimageasset_b == true) {
      item.contenttype_t = 'image';
    }
    return (
      <li>
        {item.contenttype_t.toLowerCase() === 'image' && (
          <Transform container={SEARCH} data={item} source="aws" gridClass="grid-4" />
        )}
      </li>
    );
  };

  return (
    <React.Fragment>
      {resultsCount > 0 && (
        <div>
          <ul className="search__news images">
            {results &&
              results.map((item) => setNewsFilter(item.contenttype_t, item) && renderHtml(item))}
          </ul>
        </div>
      )}
    </React.Fragment>
  );
};

export default ImagesSearch;
