/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/**
 * Analytics Data Containers List
 */
export const ACCORDION = `accordion`;
export const ARTICLE_DETAIL = `article-detail`;
export const ARTICLE_DETAIL_PAGE = `article-detail-page`;
export const BASIC = `basic`;
export const CAROUSEL = `carousel`;
export const COLLECTION = `collection`;
export const COLLECTION_MODAL = `collection-modal`;
export const CONTACT_US = `contact-us`;
export const DOWNLOAD = `download`;
export const FOOTER = `footer`;
export const GALLERY_MODAL = `gallery-modal`;
export const HEADER = `header`;
export const HEADER_SPOTLIGHT = `header-spotlight`;
export const HERO_ACCORDION = `hero-accordion`;
export const HERO_ADCARD = `hero-adcard`;
export const HERO_ARTICLE = `hero-article`;
export const HERO_CAROUSEL = `hero-carousel`;
export const HERO_COLLECTION = `hero-collection`;
export const HERO_LIVE_EVENT = `hero-live-event`;
export const HERO_PRINT_PROD = `hero-print-prod`;
export const HERO_QUIZ = `hero-quiz`;
export const HERO_QUOTE = `hero-quote`;
export const HERO_VIDEO = `hero-video`;
export const HERO_PODCAST = `hero-podcast`;
export const HERO_POLL = `hero-poll`;
export const IMAGE_ASSET_URL_CAROUSEL = `image-asset-url-carousel`;
export const IMAGE_MODAL = `image-modal`;
export const LANGUAGE_SWITCHER = `language-switcher`;
export const LIVE_EVENT = `live-event`;
export const MATCH_CAROUSEL = `match-carousel`;
export const MATCHES = `matches`;
export const MATCHDAY_LISTING = `matchday-listing`;
export const MATCH_LISTING = `matchListing`;
export const MEGA_NAV_ = `mega-nav-`;
export const MYUNITED = `myunited`;
export const MYUNITED_SETTINGS = `myunited-settings`;
export const NEWS_GRID = `news-grid`;
export const NEWS_LISTING = `news-listing`;
export const NOTIFICATION_PROMPT = `Notification_Prompt`;
export const PLAYER_GRID = `player-grid`;
export const WOMEN_PLAYER = `women-player`;
export const PLAYER_PROFILE = `player-profile`;
export const PLAYER_STAFF = `player-staff`;
export const POLL_MODAL = `poll-modal`;
export const PRINT_PRODUCT_DETAIL = `print-product-detail`;
export const PROMOBOX = `promoBox`;
export const QUOTE_MODAL = `quote-modal`;
export const RECOMMENDED_SECTION = `recommended-section`;
export const SEARCH = `Search`;
export const SHOP_CAROUSEL = `shop-carousel`;
export const SIGN_POST = `signpost`;
export const SIGN_POST_BLOCK = `signpostblock`;
export const SPOTLIGHT = `spotlight`;
export const SUBNAV = `subnav`;
export const SUPER_HERO_IMAGE = `super-hero-image`;
export const SUPER_HERO_VIDEO = `super-hero-video`;
export const THEMED_CAROUSEL = `themed-carousel`;
export const TICKET_BLOCK_MODEL = `ticketblockmodel`;
export const VIDEO_LISTING = `video-listing`;
export const VIDEO_MODAL = `video-modal`;
export const HEADER_PRIMARY_SPONSOR = `header primary sponsor`;
export const QUIZ_MODAL = `quiz-modal`;
export const MYUNITED_WEB_PREDICTION = `myunitedWebPrediction`;
export const PREDICTION_WEB = 'PredictionWeb';
export const SHOW_CAROUSEL = `show-carousel`;
export const EPISODE_CAROUSEL = `episode-carousel`;
export const VIDEO_EPISODE = `video-episode`;
export const PODCAST_EPISODE = `audio-episode`;
export const MUTV_HERO = `mutv-hero`;
export const TEMPLATE_PAGE = `template-page`;
export const ECOM_PAGE = `ecommerce`;
export const COMINGUPCAROUSAL = `ComingUp-carousel`;
export const COMINGUP_EVENTS = `MUevents`;
export const COMINGUP_PLAYERBIRTHDAY = `Playerbirthday`;
export const COMINGUP_USERBIRTHDAY = `Fanbirthday`;
export const COMINGUP_FIXTURE = `Fixture`;
export const COMINGUP_RESULTS = `Result`;
export const Hero_Slider_Auto = `hero_slider_auto`;
export const External_Product_Carousel = `externalProductCarousel`;
