/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { isEmpty } from 'lodash';
import { buildImgUrl } from '../../../utils/utils';

const AMPImage = ({ caption, altText, image }) => {
  return (
    <div className="image-panel">
      <div className="image-card">
        {image && image.length > 0 && (
          <amp-img
            src={buildImgUrl(image)}
            layout="responsive"
            height="246"
            width="328"
            alt={altText}
          />
        )}
      </div>
      {!isEmpty(caption) && <div className="content-bar">{caption}</div>}
    </div>
  );
};

AMPImage.defaultProps = {
  image: '',
  altText: '',
  caption: '',
};

export default AMPImage;
