/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { DARK_THEME, MODULE, FILTER } from '../../constants/globalConstants';
import { createDestinationUrl } from '../../utils/utils';
import Sponsor from '../Common/Sponsor';
import * as track from '../../utils/analytics';
import Sticky from '../../utils/sticky';

const ListingFilter = ({ filters, listing, lastSegment, t, language }) => {
  const fltWidth = useRef(null);
  const [newWidth, setNewWidth] = useState(null);

  useEffect(() => {
    if (fltWidth && fltWidth.current) {
      setFilterWidth();
    }
  }, [fltWidth]);

  const setFilterInViewport = () => {
    if (typeof window !== 'undefined' && window.innerWidth <= 1024) {
      const elmnt = document.querySelector('.filter__nav');
      const activeElm = fltWidth.current.querySelector('li.active');
      setTimeout(() => {
        const offesetToScroll = activeElm.offsetLeft;
        elmnt.scrollLeft = offesetToScroll;
      }, 1500);
    }
  };
  const setFilterWidth = () => {
    let width = 30;
    for (let i = 0; i < filters.length; i++) {
      const element = fltWidth.current.childNodes[i];
      if (element) {
        const style = element.currentStyle || window.getComputedStyle(element);
        width = width + element.clientWidth + parseFloat(style.marginRight);
      }
    }
    setFilterInViewport();
    setNewWidth(width);
  };

  const moduleName = listing == 'news' ? 'newspage' : 'videopage';
  const getNavValue = listing == 'news' ? t('newsfilter') : t('videofilter');

  const activeFilter = (destinationurl_s) => {
    let listingType = listing;
    if (listing === 'video') {
      listingType = 'videos';
    }
    if (listingType === lastSegment && destinationurl_s === `${language}/${lastSegment}`) {
      return 'active';
    } else if (destinationurl_s === `${language}/${listingType}/${lastSegment}`) {
      return 'active';
    } else if (lastSegment === 'latest' && destinationurl_s === `${language}/${listingType}`) {
      return 'active';
    }
  };

  const trackLink = (data, e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: data.label_t,
        destination_url: data.destinationurl_s,
      },
      e
    );
  };

  const filterSponsorModuleName = () => {
    if (filters && filters.length) {
      let listingType = listing;
      if (listing === 'video') {
        listingType = 'videos';
      }
      let activeFilterlabel;
      filters.map((filter, key) => {
        if (activeFilter(filter.destinationurl_s)) {
          activeFilterlabel = filters[key] && filters[key].label_t;
          activeFilterlabel = listingType + '#' + activeFilterlabel;
        }
      });
      return activeFilterlabel;
    }
  };

  return (
    <Sticky>
      <div className="news-list__filter__bg newslisting-aria-label with-sponsor">
        <div className="container main">
          <div className="grid-xl news-list__filter Sponsor">
            <div className="news-list__filter__tab">
              <div className="filter__right-tab ">
                <Sponsor
                  type={FILTER}
                  moduleName={filterSponsorModuleName()}
                  theme={DARK_THEME}
                ></Sponsor>
              </div>
              <div className="filter__nav">
                <nav aria-label={getNavValue}>
                  <ul className="mainList" ref={fltWidth} style={{ width: newWidth }}>
                    {filters &&
                      filters.map((filter, index) => {
                        return (
                          filter.label_t && (
                            <li className={activeFilter(filter.destinationurl_s)}>
                              <a
                                href={createDestinationUrl(filter.destinationurl_s)}
                                className="link"
                                onClick={(e) => trackLink(filter, e)}
                              >
                                {filter.label_t}
                              </a>
                            </li>
                          )
                        );
                      })}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  );
};

export default withTranslation()(ListingFilter);
