/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import LazyLoad from 'react-lazyload';
import {
  getStatsBarData,
  getSponsorData,
  getRandomId,
  getItemId,
  isApp,
  isCollection,
  createTagArr,
  validateCbr,
  getLoggedInStatus,
  createDestinationUrl,
} from '../../../utils/utils';
import ImgCrop from '../../Common/ImgCrop';
import Sponsor from '../../Common/Sponsor';
import {
  ANONYMOUS,
  CONTENT_TYPE,
  REGISTERED,
  MUAppDeepLink,
} from '../../../constants/globalConstants';
import StatsBar from '../../Common/StatsBar';
import * as track from '../../../utils/analytics';
import { useModal } from '../../Common/Modal';
import Loader from '../../Common/Loader';
import loadable from '@loadable/component';
import ErrorBoundary from '../../Common/ErrorBoundary';
import AccessBadge from '../../Common/AccessBadge';
import { useSponsorValue } from '../../../context/sponsorContext';
import MU from '../../../constants/muConstants';
const QuizModal = loadable(() => import('../../Modals/QuizModal/QuizModal'));
const RegistrationModal = loadable(() =>
  import('../../Modals/RegistrationModal/RegistrationModal')
);

const Quiz = (props) => {
  const {
    fields,
    t,
    gridClass,
    isMatchcenterCard,
    container,
    showStatsbar,
    isArticleDetail,
  } = props;
  const {
    Title,
    Teaser,
    ContentType,
    BackgroundImage,
    Sponsorby,
    manutds,
    __Published,
    ContentAccess,
  } = fields;
  const [id] = useState(getRandomId());
  const itemId = getItemId(props);
  const sponsorInfo = Sponsorby && getSponsorData(Sponsorby.fields);
  const [{ isUserLogin, isLoginChecked, isUserSubscribe, isUserSkyId }] = useSponsorValue();
  const { showModal, closeModal, isModalVisible } = useModal();
  const {
    showModal: cbrShowModal,
    closeModal: cbrCloseModal,
    isModalVisible: cbrModalVisible,
  } = useModal();
  const destinationUrl = manutds && manutds.value && manutds.value.DestinationUrl;
  const [successScrollState, setSuccessScrollState] = useState({
    x: '',
    y: '',
  });
  const getAnalyticsData = (fields, contentStatus) => {
    const initialData = track.data('card');
    return {
      ...initialData,
      card_name: Title && Title.value,
      container_type: container,
      item_id: itemId,
      content_type: ContentType && ContentType.value,
      content_status: contentStatus,
      subscription_status: getLoggedInStatus({
        isLoginChecked,
        isUserLogin,
        isUserSubscribe,
        isUserSkyId,
      }),
      tag: manutds && manutds.value && manutds.value.Tags,
      created_datetime: __Published && __Published.value,
      author: manutds && manutds.value && manutds.value.Author,
      destination_url: destinationUrl,
    };
  };

  const trackCard = (fields, e, contentStatus) => {
    track.analytics(getAnalyticsData(fields, contentStatus), e);
  };

  const imageCrops = BackgroundImage && BackgroundImage.value && BackgroundImage.value.Crops;
  const imageAltText = BackgroundImage && BackgroundImage.value && BackgroundImage.value.AltText;
  const onCardClick = (e) => {
    setSuccessScrollState({ x: e.pageX, y: e.pageY });
    const showCbr = getCbrValue();
    trackCard(fields, e, showCbr && showCbr.accessCheck);
    if (isApp() && !isCollection()) {
      window.location.href = createDestinationUrl(destinationUrl) + MUAppDeepLink;
      return true;
    }
    if (showCbr && showCbr.value) {
      cbrShowModal(destinationUrl);
    } else {
      showModal(destinationUrl);
    }
  };
  const getCbrValue = () => {
    return validateCbr({
      isUserLogin,
      isUserSubscribe,
      contentAccess: ContentAccess && ContentAccess.value,
      contentType: ContentType && ContentType.value,
      manutds,
    });
  };
  const successCallback = ({ showContent, isSubscribedContent }) => {
    if (showContent) {
      if (!MU.isIDMEnabled) showModal(destinationUrl);
      else {
        return [
          'successScroll',
          isSubscribedContent,
          JSON.stringify(successScrollState.x),
          JSON.stringify(successScrollState.y),
        ];
      }
    }
  };

  const renderCBSModal = () => {
    const accessType = getCbrValue().accessCheck;
    return (
      <ErrorBoundary>
        <RegistrationModal
          successCallback={successCallback}
          closeModal={cbrCloseModal}
          fallback={<Loader />}
          previousActiveElement={document.activeElement}
          accessType={accessType}
          analyticsObj={getAnalyticsData(fields, accessType)}
        />
      </ErrorBoundary>
    );
  };

  return (
    <React.Fragment>
      {cbrModalVisible && renderCBSModal()}
      <div
        className={`${gridClass} ${isMatchcenterCard ? 'ratio-matchday' : 'ratio'} item`}
        onClick={(e) => onCardClick(e)}
        data-impression={track.impression({
          card_name: Title && Title.value,
          content_type: ContentType && ContentType.value,
        })}
      >
        <div className="mu-content">
          <article
            className={`mu-item quiz-card ${
              isArticleDetail && isApp() && isCollection() ? 'open-destinationpage' : 'open-quiz'
            }`}
          >
            <div className="img-holder">
              {imageCrops && (
                <LazyLoad once={true} offset={100}>
                  <ImgCrop
                    imgCrop={imageCrops}
                    alt={imageAltText}
                    title={imageAltText}
                    singleSrc={false}
                    isWebpAvailable={BackgroundImage.value.isWebpAvailable}
                    gridClass={gridClass}
                  ></ImgCrop>
                </LazyLoad>
              )}
            </div>
            <div className="top-badges">
              <AccessBadge
                contentAcessLocal={ContentAccess && ContentAccess.value}
                contentTypeLocal={ContentType && ContentType.value}
                tagListLocal={createTagArr(manutds)}
              />
            </div>
            <Sponsor
              contentType={ContentType && ContentType.value}
              varient=""
              sponsorInfo={sponsorInfo}
              grid={gridClass}
              type={CONTENT_TYPE}
              author={manutds && manutds.value && manutds.value.Author}
              cardName={Title && Title.value}
              container={container}
              createdDateTime={__Published && __Published.value}
              itemId={itemId}
              tags={manutds && manutds.value && manutds.value.Tags}
            ></Sponsor>
            <div className="mu-item__info">
              {Title && Title.value && (
                <React.Fragment>
                  <h2 id={id} className="mu-item__title" tabIndex="0">
                    <Text field={Title} />
                  </h2>
                  <span className="screenreader">
                    &nbsp;{ContentType && ContentType.value && t('quiz')}
                  </span>
                </React.Fragment>
              )}
              {Teaser && Teaser.value && (
                <p className="mu-item__teasure">
                  <Text field={Teaser} />
                </p>
              )}
              <div className="link-btn-white">
                <a href="#" onClick={(e) => e.preventDefault()} aria-describedby={id}>
                  <span>
                    {t('ViewQuiz')}
                    <i className="icon-cta-arrow"></i>
                  </span>
                </a>
              </div>
            </div>
            {showStatsbar && (
              <StatsBar
                item={getStatsBarData(fields)}
                tagListLocal={createTagArr(manutds)}
              ></StatsBar>
            )}
          </article>
        </div>
      </div>
      {isModalVisible && (
        <ErrorBoundary>
          <QuizModal
            closeModal={closeModal}
            itemid={itemId}
            fallback={<Loader />}
            cardData={fields}
            container={container}
          />
        </ErrorBoundary>
      )}
    </React.Fragment>
  );
};

Quiz.defaultProps = {
  showStatsbar: true,
  isArticleDetail: false,
};

export default withTranslation()(Quiz);
