/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { jsonStringify } from '../../utils/structureData';
const ProfileData = (props) => {
  const { personStructureData } = props.structureData;
  const data = personStructureData && {
    '@context': personStructureData.context,
    '@type': personStructureData.type,
    name: personStructureData.name,
    birthDate: personStructureData.birthDate,
    birthPlace: personStructureData.birthPlace,
    familyName: personStructureData.familyName,
    givenName: personStructureData.givenName,
    JobTitle: personStructureData.JobTitle,
    Nationality: personStructureData.Nationality,
    brand: {
      '@type': personStructureData.brand && personStructureData.brand.type,
      name: personStructureData.brand && personStructureData.brand.name,
    },
  };
  return personStructureData ? (
    <Helmet>
      <script data-schema="ProfileObject" type="application/ld+json">
        {`${jsonStringify(data)}`}
      </script>
    </Helmet>
  ) : null;
};
export default ProfileData;
