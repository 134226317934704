/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_MY_LIST_DATA,
  SET_MY_LIST_DATA,
  ERROR_MY_LIST_DATA,
  FETCH_MY_LIST_REPORT,
  SET_MY_LIST_REPORT,
  ERROR_MY_LIST_REPORT,
  DELETE_CONFIRMATION,
  LOCAL_DATA,
  SET_RELOAD,
} from '../constants/actions/actionConstant';
export const myListState = {
  status: null,
  reportStatus: null,
  myListResponse: null,
  myListReport: null,
  itemNumber: 12,
  lastResponseTime: null,
  showPopup: false,
  localDetails: null,
  reloadPage: false,
};

/**
 * @param {initial state} state
 * @param {Action type} type
 * @param {response} response data
 */
export const mutvMyListReducer = (state = myListState, { type, response } = {}) => {
  switch (type) {
    case FETCH_MY_LIST_DATA:
      return { ...state, status: 'fetching', myListResponse: null, reloadPage: false };
    case SET_MY_LIST_DATA: {
      return {
        ...state,
        status: 'fetched',
        myListResponse: response.data,
        lastResponseTime: new Date(),
        totalLength: response.data ? response.data.length : 0,
        reloadPage: false,
        reportStatus: null,
      };
    }

    case ERROR_MY_LIST_DATA:
      return { ...state, status: 'error', myListResponse: null, reloadPage: false };

    case FETCH_MY_LIST_REPORT:
      return { ...state, reportStatus: 'fetching', myListReport: null };

    case SET_MY_LIST_REPORT:
      return {
        ...state,
        reportStatus: 'fetched',
        myListReport: response.data,
      };

    case ERROR_MY_LIST_REPORT:
      return { ...state, reportStatus: 'error', myListReport: null };

    case DELETE_CONFIRMATION:
      return { ...state, showPopup: response };

    case LOCAL_DATA:
      return { ...state, localDetails: response };
    case SET_RELOAD:
      return { ...state, reloadPage: response };

    default:
      return state;
  }
};
