/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import Video from '../../Cards/Video';
import { HERO_VIDEO } from '../../../constants/containerConstants';

const HeroBannerVideo360 = (props) => {
  return (
    <div className="home-row">
      <div className="home-hero">
        <Video container={HERO_VIDEO} {...props} heroVarient="parent" gridClass="grid-12" />
      </div>
    </div>
  );
};

export default HeroBannerVideo360;
