/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import { createDestinationUrl } from '../../utils/utils';
import ImgCrop from '../Common/ImgCrop';
import * as track from '../../utils/analytics';
import { PLAYER_GRID } from '../../constants/containerConstants';

const PlayerGridCard = ({ playerObj, t }) => {
  const trackCard = (e) => {
    const initialData = track.data('card');
    track.analytics(
      {
        ...initialData,
        card_name: `${playerObj.firstname_t} ${playerObj.lastname_t}`,
        container_type: PLAYER_GRID,
        item_id: playerObj.itemid_s,
        content_type: playerObj.contenttype_t,
        tag: playerObj.taglist_sm,
        created_datetime: playerObj.__published_tdt,
        author: playerObj.authornameinfo_s,
      },
      e
    );
  };
  return (
    <React.Fragment>
      <li className="grid-3 teamgrid-ratio">
        <div className="mu-content" tabIndex="0" role="link">
          <a
            tabIndex="-1"
            href={createDestinationUrl(playerObj.destinationurl_s)}
            onClick={(e) => trackCard(e)}
            data-impression={track.impression({
              card_name: `${playerObj.firstname_t} ${playerObj.lastname_t}`,
              content_type: playerObj.contenttype_t,
            })}
          >
            <div className="mu-item team-card">
              <div className="img-holder" aria-hidden="true">
                <LazyLoad once={true} offset={100}>
                  <ImgCrop
                    className="adaptive"
                    imgCrop={playerObj.playergridcropurl_s && playerObj.playergridcropurl_s.img1x}
                    alt={
                      playerObj.celumimagesvariant_s &&
                      playerObj.celumimagesvariant_s.ImageDetails[0] &&
                      playerObj.celumimagesvariant_s.ImageDetails[0].AltText
                    }
                    title={
                      playerObj.celumimagesvariant_s &&
                      playerObj.celumimagesvariant_s.ImageDetails[0] &&
                      playerObj.celumimagesvariant_s.ImageDetails[0].AltText
                    }
                    isWebpAvailable={
                      playerObj.celumimagesvariant_s &&
                      playerObj.celumimagesvariant_s.ImageDetails[0] &&
                      playerObj.celumimagesvariant_s.ImageDetails[0].isWebpAvailable
                    }
                    singleSrc={true}
                  ></ImgCrop>
                </LazyLoad>
              </div>
              <div className="mu-item__info">
                <p className="mu-item__jersey-number" aria-hidden="true">
                  {playerObj.shirtnumber_t}
                </p>
                <span className="mu-item__firstname">{playerObj.firstname_t}</span>
                <span className="mu-item__lastname">{playerObj.lastname_t}</span>
                {playerObj.shirtnumber_t && (
                  <span className="screenreader">
                    {`${t('Shirtnumber')} ${playerObj.shirtnumber_t}`}
                  </span>
                )}
              </div>
            </div>
          </a>
        </div>
      </li>
    </React.Fragment>
  );
};

export default withTranslation()(PlayerGridCard);
