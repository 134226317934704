/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { getSelectedTeamFilter, getUpdatedUrl } from '../../utils/matchUtils';
import { createDestinationUrl } from '../../utils/utils';
import { analytics, data } from '../../utils/analytics';
import Sticky from '../../utils/sticky';
import MU from '../../constants/muConstants';
import LazyLoad from 'react-lazyload';
import ImgCrop from '../Common/ImgCrop';

const TeamFilter = ({
  t,
  isCurrentYear,
  selectedLeague,
  selectedYear,
  selectedFilter,
  teamTypeFilters,
  selectTeamTypeFilter,
  selectedSubFilter,
  showTable,
  checkCalendarStatus,
  getCalendarAction,
  props,
}) => {
  const [dropdownHidden, setDropdownHidden] = useState(false);
  const [selectedTeam] = useState(getSelectedTeamFilter());
  const Keyboard = { UP: 38, DOWN: 40, LEFT: 37, ENTER: 13, RIGHT: 39, ESCAPE: 27, TAB: 9 };
  const tabOnkeypress = (e, value, subFilterValue, actionType) => {
    const code = e.keyCode || e.which;
    if (code === 32 || code === 13) {
      selectTeamTypeFilter(value, subFilterValue, actionType);
    }
  };
  const { fields } = props || {};
  const { FixtureIcon } = fields || {};
  useEffect(() => {
    window.addEventListener('click', hideDropdown);
    return () => {
      window.removeEventListener('click', hideDropdown);
    };
  }, [dropdownHidden]);
  const hideDropdown = () => {
    if (dropdownHidden) {
      setDropdownHidden(false);
    }
  };

  const showDropDown = () => {
    if (selectedYear) {
      const year = selectedYear.split('-');
      return year[0] >= 2018;
    }
  };

  const selectedTeamName = () => {
    const name = teamTypeFilters[0].label_t;
    const team = teamTypeFilters.find(
      (teamTypeFilter) => selectedTeam == teamTypeFilter.pagefilter_sm[0].replace('/', '%2F')
    );
    return team ? team.label_t : name;
  };

  const dropDownClick = (buttonName, url) => {
    const anaylticsObj = data('button');
    anaylticsObj.card_name = 'NA';
    anaylticsObj.button_name = buttonName;
    anaylticsObj.tag = 'NA';
    analytics(anaylticsObj);
    window.location.href = createDestinationUrl(url);
  };
  const openDropDown = (e) => {
    e.stopPropagation();
    const code = e && (e.keyCode || e.which);
    const listItems = e.target && e.target.querySelectorAll('#exp_elem_list li');
    if (code === Keyboard.ENTER || e.type === 'click') {
      setDropdownHidden(!dropdownHidden);
    } else if (code === Keyboard.ESCAPE || code === Keyboard.TAB) {
      setDropdownHidden(false);
    } else if (code === Keyboard.DOWN) {
      listItems && listItems[0] && listItems[0].focus();
      e.preventDefault();
    }
  };
  const openMenu = (e) => {
    setDropdownHidden(!dropdownHidden);
    e.stopPropagation();
  };
  const focusListItem = (e, index, filterLabel, url) => {
    e.stopPropagation();
    let code = e && (e.keyCode || e.which);
    const listItems = document.querySelectorAll('#exp_elem_list li');
    if (code === Keyboard.DOWN) {
      e.preventDefault();
      listItems && listItems[index + 1] && listItems[index + 1].focus();
    } else if (code === Keyboard.UP) {
      e.preventDefault();
      listItems && listItems[index - 1] && listItems[index - 1].focus();
    } else if (code === Keyboard.ESCAPE || code === Keyboard.TAB) {
      setDropdownHidden(!dropdownHidden);
    } else if (code === Keyboard.ENTER) {
      dropDownClick(filterLabel, url);
    }
  };

  const emptyURL = '';

  const stickyCSS = { overflow: 'visible' };
  return (
    <Sticky style={stickyCSS}>
      <div className="team-dropdown" data-sticky-matches>
        <div className="container">
          <div className="wrapper">
            <div className="team-dropdown_left" style={{ outline: 'none' }}>
              <div className="team-list team-toggle1">
                {showDropDown() && (
                  <div
                    className="filter_dropdown"
                    tabIndex="0"
                    onClick={(e) => openMenu(e)}
                    onKeyDown={(e) => openDropDown(e)}
                    aria-label={`${t('SelectYourTeam')}  ${selectedTeamName()}`}
                    aria-haspopup="listbox"
                    role="listbox"
                    aria-expanded={dropdownHidden}
                    id="exp_elem"
                  >
                    <button
                      tabIndex="-1"
                      aria-haspopup="listbox"
                      aria-labelledby="exp_elem exp_button"
                      id="exp_button"
                    >
                      {selectedTeamName()}
                    </button>
                    <ul
                      id="exp_elem_list"
                      tabIndex="-1"
                      role="listbox"
                      aria-labelledby="exp_elem"
                      className={dropdownHidden ? '' : 'hidden'}
                    >
                      {teamTypeFilters &&
                        teamTypeFilters
                          .filter((teamTypeFilter) => selectedTeamName() != teamTypeFilter.label_t)
                          .map((teamTypeFilter, index) => (
                            <li
                              key={index}
                              id={`exp_elem_${index}`}
                              role="option"
                              tabIndex="0"
                              onClick={() =>
                                dropDownClick(
                                  teamTypeFilter.label_t,
                                  getUpdatedUrl(selectedYear)(teamTypeFilter.destinationurl_s)
                                )
                              }
                              onKeyDown={(e) =>
                                focusListItem(
                                  e,
                                  index,
                                  teamTypeFilter.label_t,
                                  getUpdatedUrl(selectedYear)(teamTypeFilter.destinationurl_s)
                                )
                              }
                            >
                              {teamTypeFilter.label_t}
                            </li>
                          ))}
                    </ul>
                  </div>
                )}
                {selectedSubFilter && (
                  <div className="team-filter">
                    <ul role="tablist" aria-label="Team Listing filter">
                      <li
                        onClick={() => selectTeamTypeFilter(selectedFilter, 'all', 'subfilter')}
                        className={selectedSubFilter == 'all' ? 'selected' : undefined}
                        role="tab"
                        aria-selected={selectedSubFilter.toLowerCase() == 'all' ? true : false}
                        tabIndex="0"
                        onKeyUp={(event) =>
                          tabOnkeypress(event, selectedFilter, 'all', 'subfilter')
                        }
                      >
                        <span>{t('All')}</span>
                      </li>
                      <li
                        onClick={() => selectTeamTypeFilter(selectedFilter, 'united', 'subfilter')}
                        className={selectedSubFilter == 'united' ? 'selected' : undefined}
                        role="tab"
                        tabIndex="0"
                        aria-selected={selectedSubFilter.toLowerCase() == 'united' ? true : false}
                        onKeyUp={(event) =>
                          tabOnkeypress(event, selectedFilter, 'united', 'subfilter')
                        }
                      >
                        <span>{t('United')}</span>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              <div className="team-tab">
                <ul className="tab-list">
                  {isCurrentYear() && (
                    <li
                      onClick={() => selectTeamTypeFilter('fixtures', selectedSubFilter)}
                      className={selectedFilter === 'fixtures' ? 'active' : undefined}
                    >
                      <a href="#" onClick={(e) => e.preventDefault()}>
                        {t('Fixtures')}
                      </a>
                    </li>
                  )}
                  <li
                    onClick={() => selectTeamTypeFilter('results', selectedSubFilter)}
                    className={selectedFilter === 'results' ? 'active' : undefined}
                  >
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      {t('Results')}
                    </a>
                  </li>
                  {showTable && (
                    <li
                      onClick={() => selectTeamTypeFilter('tables', selectedSubFilter)}
                      className={selectedFilter === 'tables' ? 'active' : undefined}
                    >
                      <a href="#" onClick={(e) => e.preventDefault()}>
                        {t('Tables')}
                      </a>
                    </li>
                  )}
                </ul>
              </div>
              {MU &&
                MU.displayFixtureCalendar &&
                selectedLeague &&
                teamTypeFilters &&
                checkCalendarStatus() && (
                  <div className="fixtures_calendar_Box_Mobile">
                    <div className="calendar-btn">
                      <a
                        href={emptyURL}
                        role="button"
                        aria-label={t('ADD')}
                        onClick={(e) => getCalendarAction(e)}
                      >
                        <div className="cta_main">
                          <div className="cta_container">
                            {FixtureIcon && FixtureIcon.value && FixtureIcon.value.Crops && (
                              <span className="calendar_plus-icon">
                                <LazyLoad>
                                  <ImgCrop
                                    imgCrop={FixtureIcon.value.Crops}
                                    isWebpAvailable={FixtureIcon.value.isWebpAvailable}
                                  ></ImgCrop>
                                </LazyLoad>
                              </span>
                            )}
                            <span className="cta_title">{t('ADD')}</span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  );
};

export default withTranslation()(TeamFilter);
