/**
 *  © 2022 -2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import { data, analytics } from '../../../utils/analytics';
import { COMINGUP_USERBIRTHDAY, COMINGUPCAROUSAL } from '../../../constants/containerConstants';

const CardUserBirthday = (props) => {
  const { commonResponse, item, t } = props;
  const { userPhoto, userFirstName, userAge, userCountry } = item;
  const userBdayMsg = commonResponse && commonResponse.userbirthdaymessage_t;
  const { weburl_t, userbirthdaytitle_t, webctatitle_t } = commonResponse;

  const initialData = data('card');
  const triggerAnalytics = () => {
    analytics({
      ...initialData,
      card_name: 'Fanbirthday',
      container_type: COMINGUPCAROUSAL,
      content_type: COMINGUP_USERBIRTHDAY,
      destination_url: commonResponse.weburl_t,
      button_name: commonResponse.webctatitle_t,
      item_name: 'Fanbirthday',
      created_datetime: 'N/A',
    });
  };

  function cardClickHandler() {
    triggerAnalytics();
  }

  return (
    <a
      className="card-content"
      tabIndex="-1"
      aria-hidden="true"
      style={{ outline: 'none' }}
      href={weburl_t ? weburl_t : 'javascript:void(0)'}
      onClick={(e) => cardClickHandler()}
    >
      <div className="card-data card-user_birthday">
        <div className="leftBox">
          <div className="data_info" aria-hidden="true">
            <div className="info_ico">
              {userPhoto && (
                <>
                  <div
                    className="avatar_profile"
                    style={
                      userPhoto && {
                        backgroundImage: `url(${userPhoto})`,
                        backgroundPosition: '0 0',
                        backgroundSize: 'cover',
                      }
                    }
                  >
                    <img
                      src="//:0"
                      className="visually-hidden "
                      aria-hidden="true"
                      id="headerprofileimg2"
                      alt="Profile pic"
                      title="Profile pic"
                    />
                  </div>
                </>
              )}
            </div>
            <div className="info_Txt">
              <p className="dateSchudle">{t('Today')}</p>
              {userbirthdaytitle_t && <p>{userbirthdaytitle_t}</p>}
            </div>
          </div>
          {userFirstName ? (
            <div className="msgBox">
              {userBdayMsg && (
                <p className="user_birthdayMsg">
                  {userBdayMsg.slice(0, userBdayMsg.indexOf('{First Name}'))} {userFirstName}{' '}
                  {userBdayMsg.slice(userBdayMsg.indexOf('from'))}
                </p>
              )}
            </div>
          ) : (
            <div className="msgBox">
              {userBdayMsg && (
                <p className="user_birthdayMsg">
                  {userBdayMsg.slice(0, userBdayMsg.indexOf('{First Name}'))}{' '}
                  {userBdayMsg.slice(userBdayMsg.indexOf('from'))}
                </p>
              )}
            </div>
          )}
        </div>
        <div className="rightBox">
          <>
            {!isNaN(userAge) && (
              <p className="timeSchudle">
                {userAge} {t('years Old')}
              </p>
            )}
            {userCountry && (
              <div className={userCountry.length > 21 ? 'subTxt marqueeAnim' : 'subTxt'}>
                <p>{userCountry}</p>
              </div>
            )}
            {webctatitle_t && <div className="cardBtn">{webctatitle_t.toUpperCase()}</div>}
          </>
        </div>
      </div>
    </a>
  );
};

export default withTranslation()(CardUserBirthday);
