/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';

export const airShipScript = (item) => {
  return (
    item.uaNotificationScript && (
      <script type="text/javascript">
        {`!function(n,t,c,e,u){
      function r(n){try{f=n(u)}catch(n){return h=n,void i(p,n)} i(s,f)} function i(n,t){
          for(var c = 0; c < n.length; c++) d(n[c],t);
      } function o(n,t) { return n && (f?d(n,f):s.push(n)),t && (h?d(t,h):p.push(t)),l} function a(n) { return o(!1,n)} function d(t,c){
          n.setTimeout(function(){t(c)},0)
      } var f,h,s=[],p=[],l={ then:o,catch:a,_setup:r};n[e]=l;var v=t.createElement("script");
      v.src =c,v.async=!0,v.id="_uasdk",v.rel=e,t.head.appendChild(v)
      }(window,document,'https://web-sdk.urbanairship.com/notify/v1/ua-sdk.min.js','UA',${JSON.stringify(
        configObj(item)
      )});
      `}
      </script>
    )
  );
};

const configObj = (item) => {
  return {
    vapidPublicKey: item.uaNotificationScript.vapidPublicKey,
    websitePushId: item.uaNotificationScript.websitePushId,
    appKey: item.uaNotificationScript.appKey,
    token: item.uaNotificationScript.token,
  };
};
