/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import MU from '../../constants/muConstants';
import { buildImgUrl, replaceSpace, disableScroll } from '../../utils/utils';
import { fetchFavplayer } from '../../actions/myUnitedAction';
import { useMyUnitedValue } from '../../context/myUnitedContext';
import { useSponsorValue } from '../../context/sponsorContext';
import { fnBrowserDetect } from '../../utils/utils';
import { setUserFavPlayer } from '../../utils/idmUtils';

const FavouritePlayer = ({
  t,
  allPlayer,
  favPlayer,
  gigyaData,
  favPlayerStatus,
  trackLink,
  trackButton,
  mensTeamPlayer,
  womensTeamPlayer,
  toggleFavPlayer,
  myUnitedStyleObj,
  sponsorDispatch,
}) => {
  const [playerState, setPlayerState] = useState(false);
  const [genderState, setGenderState] = useState(true);
  const [errorIndex, setErrorIndex] = useState(-1);
  const [{}, dispatch] = useMyUnitedValue();
  const [{ isGigyaAvilable }] = useSponsorValue();
  const [selectedGender, setSelectedGender] = useState('');
  const [favPlayerPopOverlay, setFavPlayerPopOverlay] = useState(null);
  const [categoryByTeam, setCategoryByTeam] = useState([]);
  const [playerListData, setPlayerListData] = useState('');
  const categoryRef = useRef(null);
  useEffect(() => {
    if (
      mensTeamPlayer &&
      mensTeamPlayer.FavouritePlayerFiltersResponse &&
      mensTeamPlayer.FavouritePlayerFiltersResponse.response &&
      mensTeamPlayer.FavouritePlayerFiltersResponse.response.docs &&
      mensTeamPlayer.FavouritePlayerFiltersResponse.response.docs.length > 0
    ) {
      const tempCategoryList = [];
      mensTeamPlayer.FavouritePlayerFiltersResponse.response.docs.map((elem) => {
        tempCategoryList.push(elem);
      });
      setCategoryByTeam(tempCategoryList);
    }
  }, [mensTeamPlayer]);
  const playerList = [
    'position_t:goalkeeper',
    'position_t:defender',
    'position_t:midfielder',
    'position_t:forward',
  ];

  const enableFavPlayer = toggleFavPlayer && toggleFavPlayer.EnableOrDisableFavPlayer === true;

  const setCheck = (player) => {
    if (enableFavPlayer && player && player.playertag_t && favPlayer && favPlayer.playertag_t) {
      return player.playertag_t === favPlayer.playertag_t ? 'icon-check-red-circle' : ''.trim();
    } else if (
      !enableFavPlayer &&
      player &&
      player.playertag_t &&
      favPlayer &&
      favPlayer.playertag_t
    ) {
      return player.playertag_t === favPlayer.playertag_t && 'active-check';
    }
  };
  const setFavouritePlayer = (playerTag, index) => {
    if (playerTag && typeof playerTag !== 'undefined') {
      if (MU && MU.isIDMEnabled && gigyaData) {
        setUserFavPlayer(gigyaData, playerTag).then((response) => {
          if (response && response.data && response.data.Status === 'success') {
            fetchFavplayer(dispatch, playerTag, 1, 15, sponsorDispatch);
            if (favPlayerStatus === 'error') {
              setErrorIndex(index);
            } else {
              setTimeout(() => {
                closePlayerList();
              }, 1500);
            }
          } else {
            setTimeout(() => {
              setErrorIndex(index);
            }, 1000);
          }
        });
      } else if (isGigyaAvilable) {
        window.gigya.accounts.setAccountInfo({
          data: { favoritePlayerID: playerTag },
          callback: function validateSetAccountInfo(response) {
            if (response && response.errorCode === 0) {
              fetchFavplayer(dispatch, playerTag, 1, 15, sponsorDispatch);
              if (favPlayerStatus === 'error') {
                setErrorIndex(index);
              } else {
                setTimeout(() => {
                  closePlayerList();
                }, 1500);
              }
            } else {
              setTimeout(() => {
                setErrorIndex(index);
              }, 1000);
            }
          },
        });
      }
    }
  };

  const setGender = (type) => {
    setSelectedGender(type);
    setGenderState(false);
    switch (type) {
      case 'First Team': {
        return setPlayerListData(mensTeamPlayer);
      }
      case 'Women Team': {
        return setPlayerListData(womensTeamPlayer);
      }
      default:
        return setPlayerListData(mensTeamPlayer);
    }
  };

  const setImage = (image) => {
    try {
      let findPos = image.lastIndexOf('/');
      let updateImgtxt = image.split('');
      updateImgtxt.splice(findPos + 1, 0, 'square_');
      return updateImgtxt.join('');
    } catch {
      return false;
    }
  };

  const imageSort = (player) => {
    let headshotImg = false;
    if (player && player.headshotimage_t) {
      headshotImg = setImage(player.headshotimage_t);
    } else {
      headshotImg = false;
    }
    if (headshotImg) {
      return <img alt="" src={`${MU.assetUrl}${headshotImg}`} />;
    } else if (player && player.playergridcropurl_s && player.playergridcropurl_s.img1x) {
      return <img alt="" src={`${MU.assetUrl}${player.playergridcropurl_s.img1x}`} />;
    } else {
      return <img alt="" src={`${MU && MU.assetUrlResource}/assets/images/bg/player_avtar.png`} />;
    }
  };
  useEffect(() => {
    if (fnBrowserDetect() == 'safari' && playerState && !genderState) {
      categoryRef.current.classList.add('teamList');
    } else if (fnBrowserDetect() == 'safari' && playerState && genderState) {
      categoryRef.current.classList.remove('teamList');
    }
  }, [genderState]);

  const renderPlayerList = () => {
    const categoryClass = selectedGender
      ? `categoryTeam selectedTeam_${selectedGender}`
      : 'categoryTeam selectedTeam_MEN';
    const teamListClass = fnBrowserDetect() !== 'safari' && genderState ? '' : 'teamList';
    return (
      <div
        className={
          enableFavPlayer
            ? `favourite-player-select ${
                fnBrowserDetect() !== 'safari' && enableFavPlayer ? teamListClass : ''
              } ${enableFavPlayer && favPlayerPopOverlay ? 'favPlayerPopUp' : ''}`
            : 'favourite-player-select'
        }
        ref={categoryRef}
      >
        <h6>{t('Favouriteplayer')} </h6>
        {!genderState && categoryByTeam.length > 0 && (
          <span onClick={() => setGenderState(true)} className="icon-back_icon"></span>
        )}

        <a
          className={enableFavPlayer ? 'icon-close_circle_stroke' : 'icon-close-black'}
          href="javascript:void(0)"
          tabIndex="0"
          aria-label="close players list"
          onClick={() => closePlayerList()}
        ></a>

        <div className={enableFavPlayer ? `player__list ${categoryClass}` : 'player__list'}>
          {enableFavPlayer && genderState && (
            <div className="categoryList">
              {categoryByTeam &&
                categoryByTeam.map((elem, index) => {
                  const teamName = elem.pagefilter_sm[0];
                  const sortedTeamName = teamName.slice(teamName.indexOf('/') + 1);
                  return (
                    <h4
                      key={index}
                      value={elem.label_t}
                      onClick={() => {
                        setGender(sortedTeamName);
                      }}
                    >
                      <a href="javascript:void(0)" tabIndex="0" aria-label={elem.label_t}>
                        {elem.label_t}
                        <span className="icon-arrow-nxt-category"></span>
                      </a>
                    </h4>
                  );
                })}
            </div>
          )}
          {enableFavPlayer ? (
            !genderState &&
            playerListData &&
            playerListData.PlayerGridResponse &&
            playerListData.PlayerGridResponse.grouped &&
            Object.values(playerListData.PlayerGridResponse.grouped).map((key, index) => {
              let eachCategory =
                playerListData.PlayerGridResponse.grouped[playerList[index]].doclist.docs;
              return (
                <>
                  {eachCategory.map((player, ind) => {
                    if (ind == 0) {
                      return (
                        <p className="listTitle" key={ind}>
                          {player.position_t.toUpperCase() + 'S'}
                        </p>
                      );
                    }
                  })}
                  <ul key={index}>
                    {eachCategory.map((player, ind) => {
                      if (player.isenablemyfavouriteplayer_b) {
                        return (
                          <>
                            <li key={ind}>
                              <div className="list_content">
                                <a
                                  href="javascript:void(0)"
                                  onClick={(e) => {
                                    setFavouritePlayer(player.playertag_t, index);
                                    trackButton('favourite-player-selected', player);
                                  }}
                                >
                                  <span>{imageSort(player)}</span>
                                  <span>
                                    {player.shirtnumber_t} {player.fullname_t}
                                  </span>
                                  <i className={`icon-plus_circle ${setCheck(player)}`}></i>
                                  <div className="fav-player-error showerror">
                                    {errorIndex === index && t('WeWereUnableToSaveYourChanges')}
                                  </div>
                                </a>
                              </div>
                            </li>
                          </>
                        );
                      }
                    })}
                  </ul>
                </>
              );
            })
          ) : (
            <ul>
              {allPlayer &&
                allPlayer.length > 0 &&
                allPlayer.map((player, index) => {
                  return (
                    <li key={index}>
                      <div className="list_content">
                        <a
                          href="javascript:void(0)"
                          onClick={(e) => {
                            setFavouritePlayer(player.playertag_t, index);
                            trackButton('favourite-player-selected', player);
                          }}
                        >
                          <span>{imageSort(player)}</span>
                          <span>{player.fullname_t}</span>
                          <i className={`icon-check ${setCheck(player)}`}></i>
                          <div className="fav-player-error showerror">
                            {errorIndex === index && t('WeWereUnableToSaveYourChanges')}
                          </div>
                        </a>
                      </div>
                    </li>
                  );
                })}
            </ul>
          )}
        </div>
      </div>
    );
  };
  const openPlayerList = (e, targeBtn) => {
    setPlayerState(true);
    if (categoryByTeam.length === 0) {
      setGenderState(false);
    }
    if (enableFavPlayer) {
      let screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        const overlayElement = document.getElementById('hover-overlay');
        overlayElement.classList.add('favPlayer_overlay');
        setFavPlayerPopOverlay(true);
      }
    }

    e && e.target && trackLink(e, targeBtn, e.target.href);
    setTimeout(function () {
      disableScroll(document.querySelector('.favourite-player-select .player__list'));
    }, 100);
  };

  const closePlayerList = () => {
    setPlayerState(false);
    setGenderState(true);
    setErrorIndex(-1);
    if (enableFavPlayer) {
      let screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        const overlayElement = document.getElementById('hover-overlay');
        overlayElement.classList.remove('favPlayer_overlay');
        setFavPlayerPopOverlay(false);
      }
    }
  };
  return (
    <React.Fragment>
      <React.Fragment>
        {favPlayer && favPlayer.playertag_t && (
          <div className="favourite-player">
            <div className="favourite-player_true">
              <div className="favourite-player__title">
                <p>{t('Favouriteplayer')} </p>
              </div>
              <div className="favourite-player-pic-details">
                <div
                  className="favourite-player__pic playerbgImg"
                  style={{
                    backgroundImage: `url(${buildImgUrl(
                      favPlayer && favPlayer.playercropurl_s && favPlayer.playercropurl_s.img1x
                    )})`,
                  }}
                >
                  {enableFavPlayer && <span className="star-icon"></span>}
                </div>
                <div className="favourite-player__details">
                  <span className="first-name playerFname">{favPlayer.firstname_t}</span>
                  <br />
                  <span className="last-name playerLname">{favPlayer.lastname_t}</span>
                  {enableFavPlayer ? (
                    favPlayer && favPlayer.ctatitle_t ? (
                      <div className="buy-shirt">
                        <a
                          href={favPlayer.ctaurlstring_s}
                          target="_blank"
                          onClick={(e) =>
                            trackLink(e, 'player_football-kits', favPlayer.ctaurlstring_s)
                          }
                        >
                          {favPlayer.ctatitle_t}
                        </a>
                      </div>
                    ) : (
                      favPlayer.country_t && (
                        <div className="country">
                          <span>
                            <i
                              className={`flag-icon flag flag-${replaceSpace(favPlayer.country_t)}`}
                            ></i>
                          </span>
                          <span className="playerCountry">{favPlayer.country_t}</span>
                        </div>
                      )
                    )
                  ) : (
                    favPlayer.country_t && (
                      <div className="country">
                        <span>
                          <i
                            className={`flag-icon flag flag-${replaceSpace(favPlayer.country_t)}`}
                          ></i>
                        </span>
                        <span className="playerCountry">{favPlayer.country_t}</span>
                      </div>
                    )
                  )}
                </div>
                <span className="action-button only-small">
                  <a href="javascript:void(0)" onClick={(e) => openPlayerList(e, 'Edit')}>
                    {t('Edit')}
                  </a>
                </span>
              </div>
            </div>
            <span className="action-button wide">
              <a href="javascript:void(0)" onClick={(e) => openPlayerList(e, 'Edit')}>
                {t('Edit')}
              </a>
            </span>
          </div>
        )}

        {gigyaData && !gigyaData?.data?.favoritePlayerID && !favPlayer && (
          <div className="favourite-player">
            <div className="favourite-player_false">
              <div className="favourite-player__title">
                <p>{t('Favouriteplayer')}</p>
              </div>
              <div className="favourite-player-pic-details">
                <div
                  className="favourite-player__pic"
                  onClick={(e) => openPlayerList(e, 'Add')}
                  style={{
                    backgroundImage: `url(${
                      MU && MU.assetUrlResource
                    }/${'assets/images/bg/player_avtar.png'})`,
                  }}
                >
                  <p className="add-player-icon"></p>
                </div>
                <div className="favourite-player__details">
                  <p>{t('Pickyourplayer')}</p>
                </div>
                <span className="action-button only-small">
                  <a href="javascript:void(0)" onClick={(e) => openPlayerList(e, 'Edit')}>
                    {t('Edit')}
                  </a>
                </span>
              </div>
            </div>
            <span className="action-button wide">
              <a href="javascript:void(0)" onClick={(e) => openPlayerList(e, 'Edit')}>
                {t('Edit')}
              </a>
            </span>
          </div>
        )}
      </React.Fragment>
      {playerState && renderPlayerList()}
    </React.Fragment>
  );
};

export default withTranslation()(React.memo(FavouritePlayer));
