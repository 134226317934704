/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { createContext, useContext, useReducer } from 'react';
import { liveEventState, liveEventReducer } from '../reducers/liveEventReducer';

export const LiveEventContext = createContext();

export const LiveEventProvider = ({ children }) => (
  <LiveEventContext.Provider value={useReducer(liveEventReducer, liveEventState)}>
    {children}
  </LiveEventContext.Provider>
);
export const useLiveEventValue = () => useContext(LiveEventContext);

export default LiveEventContext;
