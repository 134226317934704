/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import ImgCrop from '../../Common/ImgCrop';
import {
  calculateSliderHeight,
  durationUtil,
  individualTag,
  msToTime,
  heroDataMapping,
  seriesEpisodeInfoUtil,
  playVideoOrNot,
  carouselTypeCheck,
} from '../mutvutil';
import * as track from '../../../utils/analytics';
import {
  createDestinationUrl,
  createTagList,
  getContentAccessLocal,
  getLoggedInStatus,
  showCBS,
} from '../../../utils/utils';
import { errorHandler } from '../../../utils/gigyaScreen';
import Sponsor from '../../Common/Sponsor';
import { CONTENT_TYPE } from '../../../constants/globalConstants';
import { useModal } from '../../Common/Modal';
import Loader from '../../Common/Loader';
import ErrorBoundary from '../../Common/ErrorBoundary';
import { setVideoplayData } from '../../../actions/showListingAction';
import AccessBadge from '../../Common/AccessBadge';
import loadable from '@loadable/component';
import MU from '../../../constants/muConstants';
const RegistrationModal = loadable(() =>
  import('../../Modals/RegistrationModal/RegistrationModal')
);

const MutvCards = (props) => {
  const {
    isUserLogin,
    isGigyaAvilable,
    userLoginDetail,
    isLoginChecked,
    isUserSubscribe,
    isUserSkyId,
    isDiscover,
    trendingIndex,
    carouselContentType,
    isCarousal,
    navCards,
    dispatch,
    containerType,
    cardAspectRatio: aspectRatio,
    trending: trendIndex,
    gridClass,
    item,
    isContinueCard,
    showDeleteBtn,
    onDeleteItem,
    isDeleteChecked,
    mylistPage,
    isRelative,
    isFeatureCard,
    t,
    isMutvMovie,
  } = props && props;
  const cardDetails = heroDataMapping(item, carouselContentType, isContinueCard);
  const info =
    cardDetails.taglist_sm && seriesEpisodeInfoUtil(cardDetails.taglist_sm, carouselContentType);
  const [playIcon, setPlayIcon] = useState('');
  const { showModal, closeModal, isModalVisible } = useModal();

  const getContainerType = () => {
    if (containerType) {
      return containerType;
    } else if (isDiscover) {
      return 'discover-carousel';
    } else if (navCards) {
      return 'mutv';
    } else {
      const a = carouselContentType && carouselContentType.split('carousel');
      return props.trending
        ? 'trending-carousel'
        : `${
            a && a.length > 0
              ? `${a[0]}-carousel`
              : carouselContentType
              ? carouselContentType
              : 'mutv-carousel'
          }`;
    }
  };

  const teasureInfo = () => {
    try {
      return cardDetails.taglist_sm.indexOf('SeriesType/Seasonal') > -1 &&
        !isCarousal &&
        !info.includes(`${t('SeasonFilter')}`)
        ? `${info.slice(2, 4)}/${info.slice(5, 7)}${info.slice(7, info.length)}`
        : info;
    } catch (error) {
      return '';
    }
  };

  const getLoginDetail = () => {
    if (window.gigya) {
      var evalResponse = function (response) {
        if (response.errorCode == 0) {
          errorHandler(response);
        }
      };
      window.gigya && window.gigya.accounts.getAccountInfo({ callback: evalResponse });
    }
  };

  const getAnalyticsData = (item, contentStatus) => {
    const initialData = track.data('card');
    return {
      ...initialData,
      card_name: cardDetails.heading,
      container_type: getContainerType(),
      subscription_status: getLoggedInStatus({
        isLoginChecked,
        isUserLogin,
        isUserSubscribe,
        isUserSkyId,
      }),
      content_status: contentStatus,
      tag: item && item.taglist_sm && createTagList(item.taglist_sm, item.category_s),
      item_id: item && item.itemid_s,
      content_type: item && item.contenttype_t,
      created_datetime: item && (item.appearance_tdt || item.__published_tdt),
      author: item && item.authorname_s,
      destination_url: item && item.destinationurl_s,
      ...individualTag(item.taglist_sm),
    };
  };

  const trackCard = (item, e, contentStatus) => {
    track.analytics(getAnalyticsData(item, contentStatus), e);
  };

  const setDuration = (duration) => {
    let durationEdit;
    const showSecond = isContinueCard || carouselContentType === 'continuelistening' ? true : false;
    if (carouselContentType === 'continuelistening' || showSecond) {
      return durationUtil(msToTime(duration).split(':'), showSecond);
    } else {
      if (duration && duration.audio) {
        durationEdit = duration.audio.duration;
        return durationUtil(durationEdit.split(':'), showSecond);
      } else if (duration && duration.duration) {
        durationEdit = duration.duration;
        return durationUtil(durationEdit.split(':'), showSecond);
      }
    }
  };

  const infoCheckFunc = () => {
    if (props && props.cardAspectRatio) {
      return true;
    } else if (carouselContentType == 'continuelistening') {
      return true;
    } else return false;
  };

  const renderPlayIcon = () => {
    const contentType = ['podcast', 'video', 'showcarousel'];
    return (
      contentType.includes(cardDetails && cardDetails.contenttype_t.toLowerCase()) && (
        <div
          className="mu-item__play video_play_dir"
          aria-hidden="true"
          style={{ opacity: playIcon }}
        >
          <span className="icon-play"></span>
        </div>
      )
    );
  };

  const hoverClass = () => {
    if (
      isCarousal &&
      carouselContentType &&
      carouselTypeCheck.includes(carouselContentType.toLowerCase())
    ) {
      return 'hover-class';
    } else return '';
  };

  const gridClassFunc = () => {
    return navCards
      ? ` ${gridClass} ratio megaNav-mutv `
      : `${gridClass} mutv-card ${calculateSliderHeight(
          aspectRatio,
          carouselContentType,
          isDiscover
        )} ${isCarousal ? 'isCarousal' : ''} ${
          carouselContentType === 'continuelistening' ? 'continue-listening' : ''
        } ${hoverClass()}`;
  };
  const getCbrValue = () => {
    return getContentAccessLocal(item.contentaccess_t, item.contenttype_t, item.taglist_sm);
  };
  const onClickAction = (item, e) => {
    const contentAccessValue = getCbrValue();
    trackCard(item, e, contentAccessValue);
    if (item.contenttype_t === 'article') {
      if (showCBS({ isUserLogin, isUserSubscribe, contentAccessState: contentAccessValue })) {
        e.preventDefault();
        showModal();
      }
    } else if (props.inlinePlay) {
      setVideoplayData(dispatch, {
        heroData: item,
        itemId: item.itemid_s,
        isClipVideo: true,
      });
      window.scroll({
        top: 0,
        behavior: 'smooth',
      });
      e.preventDefault();
      return '#';
    }
  };

  const badgeCheck = () => {
    return aspectRatio === '3x4' ||
      carouselTypeCheck.includes(carouselContentType.toLowerCase()) ||
      !aspectRatio ||
      props.isDiscover === true
      ? false
      : true;
  };

  const sponsorCheck = () => {
    return (isCarousal && carouselTypeCheck.includes(carouselContentType.toLowerCase())) || navCards
      ? true
      : false;
  };

  const successCallback = ({ showContent, isSubscribedContent }) => {
    if (showContent && item.contenttype_t === 'article') {
      if (!MU.isIDMEnabled) window.location = createDestinationUrl(cardDetails.destinationurl_s);
      else {
        return [
          'successCallbck',
          isSubscribedContent,
          'destinationUrl',
          cardDetails.destinationurl_s,
        ];
      }
    }
  };
  const getPercentageValue = (cardDetails) => {
    if (cardDetails) {
      const percent = (cardDetails.remainingTime / cardDetails.duration) * 100;
      return percent > 1 ? `${percent}%` : `${1}%`;
    }
  };
  const getNumCheck = () => {
    if (trendIndex) {
      return trendingIndex > 9 ? 'info__trend double' : 'info__trend single';
    } else return '';
  };

  const accessBadgeFunction = (position) => {
    return (
      <div className={`${position === 'top' ? 'top-badges' : ''}`}>
        <AccessBadge
          contentAcessLocal={cardDetails.contentaccess_t}
          contentTypeLocal={cardDetails.contenttype_t}
          tagListLocal={cardDetails.taglist_sm}
          head={item.headline_t === 'Article Register H' && item.headline_t}
        />
      </div>
    );
  };

  const renderCBSModal = () => {
    const accessType = getCbrValue();
    return (
      <ErrorBoundary>
        <RegistrationModal
          successCallback={successCallback}
          closeModal={closeModal}
          fallback={<Loader />}
          previousActiveElement={document.activeElement}
          accessType={accessType}
          analyticsObj={getAnalyticsData(item, accessType)}
        />
      </ErrorBoundary>
    );
  };

  const webpAvail = (imageCrop) => {
    return navCards ? cardDetails.iswebpavailablebool_b : imageCrop.isWebpAvailable;
  };

  const renderImage = () => {
    const { ImageCropUrl, ImageDetails } = cardDetails.celumimagesvariant_s;
    let imageCrop = ImageCropUrl;
    if (!mylistPage && item.contenttype_t && item.contenttype_t.toLowerCase() === 'showcarousel') {
      const image =
        ImageDetails &&
        ImageDetails.find((item) => item.Name && item.Name.toLowerCase() === 'mutvlandingimage');
      if (image && image.CropUrl) {
        imageCrop = image;
      }
    }
    if (imageCrop && imageCrop.CropUrl) {
      return (
        <React.Fragment>
          <LazyLoad once={true} offset={100}>
            <ImgCrop
              imgCrop={imageCrop.CropUrl}
              alt={imageCrop.AltText}
              isUpdateImage={mylistPage ? true : false}
              isWebpAvailable={webpAvail(imageCrop)}
            >
              <div className="mu-item__gradient" aria-hidden="true" tabIndex="-1"></div>
              <div className="mu-item__video" tabIndex="-1" aria-hidden="true">
                <div className="mu-item__player"></div>
              </div>
            </ImgCrop>
          </LazyLoad>
          {!navCards && renderPlayIcon()}
          {trendIndex && aspectRatio !== '4x3' && (
            <div className="numeric-number">
              <span>{trendingIndex}</span>
            </div>
          )}
        </React.Fragment>
      );
    }
    return null;
  };

  return (
    <React.Fragment>
      {cardDetails && (
        <div
          className={gridClassFunc()}
          onClick={(e) => onClickAction(item, e)}
          data-impression={track.impression({
            card_name: cardDetails.heading,
            content_type: cardDetails.contenttype_t,
          })}
        >
          <div className="mu-content">
            <div className="mu-item video" item-id={item.itemid_s}>
              <a
                href={createDestinationUrl(cardDetails.destinationurl_s)}
                tabIndex="-1"
                aria-hidden="true"
              >
                <div className="img-holder">
                  {cardDetails.celumimagesvariant_s && renderImage()}
                </div>
              </a>
              {sponsorCheck() && (
                <Sponsor
                  varient={''}
                  itemId={item.itemid_s}
                  contentType={cardDetails.contenttype_t}
                  sponsorInfo={cardDetails.sponsordetailinfo_s}
                  grid={gridClass}
                  type={CONTENT_TYPE}
                  isRelative={isRelative}
                  container={getContainerType()}
                  cardName={cardDetails.heading}
                  createdDateTime={item.__published_tdt}
                  tags={cardDetails.taglist_sm}
                />
              )}
              {cardDetails.duration && (
                <div
                  className="mutv_time_tag"
                  aria-label={`Video Length ${setDuration(cardDetails.duration)}`}
                >
                  <span aria-hidden="true">{setDuration(cardDetails.duration)}</span>
                </div>
              )}
              {showDeleteBtn && (
                <div
                  className="delete-item-wrapper"
                  onClick={(e) => onDeleteItem(e, item.itemid_s)}
                >
                  <div
                    className={isDeleteChecked ? 'delete-item active' : 'delete-item'}
                    tabIndex="0"
                    aria-label={`Delete button ${cardDetails.heading}`}
                  >
                    <span className="icon-trash"></span>
                  </div>
                </div>
              )}
              <div className={navCards ? 'megaNav' : 'accessTop'}>{accessBadgeFunction('top')}</div>
              <div className={`mu-item__info ${getNumCheck()}`}>
                {renderPlayIcon()}
                <div className="accessBottom">
                  {((carouselContentType && carouselContentType === 'continuelistening') ||
                    isContinueCard) && (
                    <div
                      tabIndex="-1"
                      aria-label={`${t('Resume')} ${durationUtil(
                        msToTime(cardDetails.duration - cardDetails.remainingTime).split(':')
                      )} ${t('Left')}`}
                      className="continue-sec"
                    >
                      <span className="full" aria-hidden="true"></span>
                      <span
                        aria-hidden="true"
                        className="remain"
                        style={{
                          width: `${getPercentageValue(cardDetails)}`,
                        }}
                      ></span>
                      <p aria-hidden="true">{`${t('Resume')} ${durationUtil(
                        msToTime(cardDetails.duration - cardDetails.remainingTime).split(':')
                      )} ${t('Left')}`}</p>
                    </div>
                  )}
                  {!navCards && badgeCheck() && accessBadgeFunction('bottom')}
                </div>
                <a
                  href={createDestinationUrl(cardDetails.destinationurl_s)}
                  onFocus={() => {
                    setPlayIcon('1');
                  }}
                  onBlur={() => setPlayIcon('')}
                >
                  <h3
                    className="mu-item__title"
                    id="mutv_item_heading"
                    style={{ opacity: playIcon }}
                  >
                    {/* ​​​​{​​​​​​navCards && <div className="top-acc"/>}​​​​​​ */}
                    {cardDetails.heading}
                  </h3>
                </a>
                {info && infoCheckFunc() && !isMutvMovie && (
                  <p className="mu-item__teasure">{teasureInfo()}</p>
                )}
              </div>
              {isFeatureCard && (
                <div className="mu-item__info view-all-episode">
                  <div className="white-btn-skew btn">
                    <a href={createDestinationUrl(cardDetails.destinationurl_s)} role="link">
                      <span>{`${t('featurecomponentbutton')}`}</span>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {isModalVisible && renderCBSModal()}
    </React.Fragment>
  );
};
MutvCards.defaultProps = {
  isCarousal: false,
  isUserLogin: null,
  isGigyaAvilable: null,
  userLoginDetail: null,
  isDiscover: false,
  dispatch: null,
  gridClass: null,
  trending: false,
  navCards: false,
  trendingIndex: null,
  inlinePlay: false,
  isRelative: false,
  isFeatureCard: false,
  isMutvMovie: false,
};
export default withTranslation()(MutvCards);
