/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { getCssUrl } from '../utils/utils';
import MU from '../constants/muConstants';
import layout from '../constants/layoutConstants';
import SkipToContent from '../components/Common/SkipToContent';

const MasterClientLayout = ({ route }) => {
  const location = useLocation();
  const name = route && route.fields && route.fields.Name && route.fields.Name.value;
  const getLoction = () => {
    let { pathname } = location;
    pathname = pathname && pathname.toLowerCase();
    if (pathname.includes('matches/matchcenter')) {
      return getCssUrl(layout.MATCHCENTER_LAYOUT, MU.language);
    } else if (
      pathname.includes('matches') ||
      pathname.includes('fixtures') ||
      pathname.includes('league-table')
    ) {
      return getCssUrl(layout.MATCHES_LAYOUT, MU.language);
    } else {
      return getCssUrl(layout.LISTING_LAYOUT, MU.language);
    }
  };
  return (
    <React.Fragment>
      {(typeof window === 'undefined' || process.env.NODE_ENV === 'development') && (
        <Helmet>
          <link href={getLoction()} rel="stylesheet" />
        </Helmet>
      )}
      <div className={`page-wrap ${MU.reverseTheme} ${MU.gapClass}`}>
        <SkipToContent />
        <Placeholder
          name="mufc-ph-header"
          rendering={route}
          missingComponentComponent={() => {
            return null;
          }}
        />
        <div className="main-container" id="content">
          {name && <h1 className="screenreader">{name}</h1>}
          <Placeholder
            name="mufc-ph-body"
            rendering={route}
            missingComponentComponent={() => {
              return null;
            }}
          />
          <div>
            <Placeholder
              name="mufc-ph-footercontainer"
              rendering={route}
              missingComponentComponent={() => {
                return null;
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MasterClientLayout;
