/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useRef, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import Share from '../../Common/GigyaShare';
import { getLanguage } from '../../../utils/utils';
import * as track from '../../../utils/analytics';
import { SOCIAL_SHARE_MORE_BUTTON } from '../../../constants/globalConstants';

const SocialShareStrip = ({ t, item, fields, itemId }) => {
  const { AuthorName, Headline, ContentType, __Published, Video, manutds, ShortHeadline } = fields;
  /** with useState
   * @define {initial value}
   */
  const [socialShare, setSocialShare] = useState({
    open: '',
    open_fullwidth: '',
    full_bg: '',
    noborder: '',
  });
  const [enableShare, setEnableShare] = useState(true);
  const callBackShareClose = useRef();
  const shareStripRef = useRef();
  /**
   * @method {moreSocialLink function add the class for Dom Elements}
   */
  const moreSocialLink = (e) => {
    setEnableShare(false);
    e.preventDefault();
    trackButton();
    const isKeyboardUser =
      e && e.target && e.target.classList && e.target.classList.contains('non-keyboard-outline');
    setSocialShare({
      open: 'open',
      open_fullwidth: 'open_fullwidth',
      full_bg: 'full_bg',
      noborder: 'no-border',
      isKeyboardUser: isKeyboardUser,
    });
  };
  /**
   * @method {closeSocialLink function remove the class for Dom Elements}
   */
  const closeSocialLink = (evt) => {
    setEnableShare(true);
    evt.stopPropagation();
    evt.preventDefault();
    setSocialShare({
      open: 'close',
      open_fullwidth: '',
      full_bg: '',
      noborder: '',
    });
    callBackShareClose && callBackShareClose.current();
  };

  let country = getLanguage();
  const cssCountryChina = country && country.toLowerCase() === 'zh' ? 'lang-zh' : '';

  const trackButton = () => {
    const initialData = track.data('button');
    track.analytics({
      ...initialData,
      button_name: SOCIAL_SHARE_MORE_BUTTON,
      card_name: ShortHeadline && ShortHeadline.value,
      tag: manutds && manutds.value && manutds.value.Tags,
    });
  };

  useEffect(() => {
    if (shareStripRef && shareStripRef.current) {
      if (socialShare.open === 'open' && socialShare.isKeyboardUser) {
        const firstLi = shareStripRef.current.querySelector('.social-icons-li');
        if (firstLi) {
          firstLi.classList.add('non-keyboard-outline');
          firstLi.focus();
        }
      } else if (socialShare.open === 'close') {
        setTimeout(() => {
          const moreIcon = shareStripRef.current.querySelector('.more a');
          if (moreIcon) {
            moreIcon.classList.add('non-keyboard-outline');
            moreIcon.focus();
          }
        }, 0);
      }
    }
  }, [socialShare]);

  return (
    <div className={`home-content-panel__details-social__icons ${socialShare.open_fullwidth}`}>
      {enableShare && (
        <Share
          onRef={(callback) => {
            callBackShareClose.current = callback;
          }}
          item={item}
          t={t}
          tracking={true}
          Ooyala_id={Video && Video.value && Video.value.MediaId}
          author={
            AuthorName &&
            AuthorName.fields &&
            AuthorName.fields.AuthorName &&
            AuthorName.fields.AuthorName.value
          }
          card_name={Headline && Headline.value}
          content_type={ContentType && ContentType.value}
          created_datetime={__Published && __Published.value}
          item_id={itemId && itemId.toLowerCase()}
          tag={manutds && manutds.value && manutds.value.Tags}
          focusFirstItem={socialShare.open === 'open'}
        ></Share>
      )}
      <div className={`left ${cssCountryChina} ${socialShare.open}`} ref={shareStripRef}>
        {!enableShare && (
          <Share
            onRef={(callback) => {
              callBackShareClose.current = callback;
            }}
            item={item}
            t={t}
            tracking={true}
            Ooyala_id={Video && Video.value && Video.value.MediaId}
            author={
              AuthorName &&
              AuthorName.fields &&
              AuthorName.fields.AuthorName &&
              AuthorName.fields.AuthorName.value
            }
            card_name={Headline && Headline.value}
            content_type={ContentType && ContentType.value}
            created_datetime={__Published && __Published.value}
            item_id={itemId && itemId.toLowerCase()}
            tag={manutds && manutds.value && manutds.value.Tags}
            focusFirstItem={socialShare.open === 'open'}
          ></Share>
        )}
        <span className="more">
          <a
            href="#"
            aria-label="open more share options"
            onClick={(e) => moreSocialLink(e)}
            tabIndex="0"
          >
            <i className="icon-more-black "></i>
          </a>
        </span>
        <span className="close">
          <a
            href="#"
            aria-label="Close more share options"
            onClick={(e) => closeSocialLink(e)}
            tabIndex="0"
          >
            <i className="icon-close-black"></i>
          </a>
        </span>
      </div>
    </div>
  );
};

export default withTranslation()(SocialShareStrip);
