/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import { newsAndImgFilterValue } from './utils';

export const PagerService = (a, b, c) => {
  let service = {};
  service.GetPager = GetPager(a, b, c);
  return service;
};

export const GetPager = (totalItems, currentPage, pageSize) => {
  currentPage = currentPage || 1;
  pageSize = pageSize || 10;

  let totalPages = Math.ceil(totalItems / pageSize);
  let startPage, endPage, midPoint, pageCount;
  if (typeof window !== 'undefined' && window.innerWidth <= 568) {
    pageCount = 4; //6;
  } else if (typeof window !== 'undefined' && window.innerWidth > 568 && window.innerWidth <= 768) {
    pageCount = 6; //6;
  } else {
    pageCount = 10; //6;
  }

  midPoint = parseInt(pageCount / 2);
  if (totalPages <= 10) {
    startPage = 1;
    endPage = totalPages;
  } else {
    if (currentPage <= midPoint + 1) {
      startPage = 1;
      endPage = pageCount; //6;
    } else if (currentPage + 2 >= totalPages) {
      startPage = totalPages - pageCount - 1; // endpage 6-1 = 5
      endPage = totalPages;
    } else {
      startPage = currentPage - midPoint;
      endPage = currentPage + (midPoint - 1);
    }
  }
  let startIndex = (currentPage - 1) * pageSize;
  let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

  let pages = range(startPage, endPage + 1);
  return {
    totalItems: totalItems,
    currentPage: currentPage,
    pageSize: pageSize,
    totalPages: totalPages,
    startPage: startPage,
    endPage: endPage,
    startIndex: startIndex,
    endIndex: endIndex,
    pages: pages,
  };
};

export const range = (min, max) => [...Array(max - min).keys()].map((i) => i + min);

export const setNewsFilter = (contenttype_t, item) => {
  if (contenttype_t) {
    contenttype_t = contenttype_t.toLowerCase();
    if (
      item.hasOwnProperty('isimageasset_b') &&
      item.isimageasset_b &&
      contenttype_t == 'blogentry'
    ) {
      return contenttype_t;
    } else {
      if (newsAndImgFilterValue.indexOf(contenttype_t) != -1) {
        return contenttype_t;
      } else {
        return false;
      }
    }
  }
};

export const getSearchParameterByName = (name) => {
  try {
    let url = window.location.href;
    let splitUrl = url.split('?q=');
    if (splitUrl[1]) {
      splitUrl[1].replaceAll(splitUrl[1], '#', '');
      url = splitUrl[0] + '?q=' + splitUrl[1];
    }

    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  } catch (e) {
    console.log('error', e);
  }
};

export const replaceAllValue = (target, search, replacement) => {
  return target && target.replace(new RegExp(search, 'g'), replacement);
};

export const textTruncate = (str, numwords) => {
  try {
    let strOut = str.split(/\s+/).slice(0, numwords).join(' ');
    return strOut;
  } catch (e) {
    console.log('textTruncate str not found', e);
  }
};

export const updateNtag = (arrString, i, str) => {
  if (i < arrString.length && str) {
    str = str.replace(new RegExp('n_tag'), arrString[i]);
    i++;
    return updateNtag(arrString, i, str);
  } else {
    return str;
  }
};

export const getUrl = () => {
  return (
    typeof window !== 'undefined' &&
    window.location.protocol + '//' + window.location.host + window.location.pathname
  );
};

export const itemsPerPage = 15;
export const Filters = ['all', 'news', 'videos', 'images', 'podcasts', 'mutv'];
export const componentCards = ['menuitem', 'submenuitem'];
export const modalFilter = [
  'image',
  'gallery',
  'quote',
  'video',
  'blogentry',
  'collection',
  'quiz',
];
export const profileContenttype = ['playerprofile', 'coachprofile', 'managerprofile'];

export const contentFilter = (filterVal) => {
  if (filterVal) {
    if (filterVal == 'all') {
      return [
        'article',
        'video',
        'gallery',
        'quote',
        'image',
        'menuitem',
        'submenuitem',
        'blogentry',
        'quiz',
        'collection',
        'podcast',
      ];
    } else if (filterVal == 'news') {
      return ['article', 'gallery', 'quote', 'image', 'quiz', 'collection'];
    } else if (filterVal == 'image' || filterVal == 'images') {
      return ['article', 'gallery', 'quote', 'image', 'blogentry'];
    } else if (filterVal === 'video' || filterVal === 'videos') {
      return ['video', 'blogentry'];
    } else if (filterVal === 'podcast' || filterVal === 'podcasts') {
      return ['podcast'];
    } else if (filterVal === 'mutv') {
      return ['video'];
    }
  } else {
    return [
      'article',
      'printproduct',
      'video',
      'gallery',
      'quote',
      'image',
      'menuitem',
      'submenuitem',
      'quiz',
      'collection',
      'podcast',
    ];
  }
};
