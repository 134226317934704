/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import LazyLoad from 'react-lazyload';
import * as track from '../../../utils/analytics';
import { checkDevice } from '../../../utils/utils';
const FanaticsProduct = (props) => {
  const { fields, gridClass, container, rendering } = props;
  const isMeganav = props && props.data && props.data.ismegaNav ? props.data.ismegaNav : false;
  const linkUrl = fields && fields.CTAUrl && fields.CTAUrl.value && fields.CTAUrl.value.href;
  const linkTarget = fields && fields.CTAUrl && fields.CTAUrl.value && fields.CTAUrl.value.target;
  const ctaTitle = fields && fields.CTATitle && fields.CTATitle.value;
  const cropImage = fields.ImageLink && fields.ImageLink.value;
  const title = fields.Title && fields.Title.value;
  const itemId = rendering && rendering.dataSource;
  const Id = fields && fields.Id;
  const shopItemId = props && props.data && props.data.Id;
  /**
   *
   * @param {each item data to track link analytics} item
   */
  const trackLink = (ctaTitle, linkUrl, e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: ctaTitle,
        destination_url: linkUrl,
      },
      e
    );
  };

  const trackButton = (ctaTitle, linkUrl, e) => {
    e.stopPropagation();
    const initialData = track.data('button');
    track.analytics(
      {
        ...initialData,
        button_name: ctaTitle,
        destination_url: linkUrl,
      },
      e
    );
  };

  const trackCard = (ctaTitle, linkUrl, e) => {
    const initialData = track.data('card');
    track.analytics(
      {
        ...initialData,
        card_name: title,
        container_type: container,
        destination_url: linkUrl,
        item_id: itemId,
      },
      e
    );
  };

  return (
    <React.Fragment>
      {isMeganav && fields && (
        <div className={`${gridClass ? gridClass : 'grid-4'} ratio`}>
          <article className="kitbag-card">
            <div className="kitbag-card-item">
              <LazyLoad once={true} offset={100}>
                <figure>
                  <img className="img-responsive" src={cropImage} alt={title} title={title} />
                </figure>
              </LazyLoad>
            </div>
            <div className="kitbag-card__info">
              <p className="kitbag-card__detail" id={shopItemId}>
                {title}
              </p>
              <div className="white-btn-skew">
                <a
                  href={linkUrl}
                  target={linkTarget}
                  onClick={(e) => trackLink(ctaTitle, linkUrl, e)}
                  aria-describedby={shopItemId}
                >
                  <span> {ctaTitle}</span>
                  <i className="tag-red-arrow"></i>
                </a>
              </div>
            </div>
          </article>
        </div>
      )}
      {!isMeganav && fields && (
        <a
          href={linkUrl}
          onClick={(e) => trackCard(ctaTitle, linkUrl, e)}
          target="_blank"
          tabIndex="0"
        >
          {checkDevice() && <span className=" screenreader">{title}</span>}
          <div className={`${gridClass ? gridClass : 'grid-3'} ratio`}>
            <div className="mu-content">
              <article className="ecommerce-card">
                <div className="ecommerce-card-item">
                  <LazyLoad once={true} offset={100}>
                    <figure className="nobg">
                      <img className="img-responsive" src={cropImage} alt={title} />
                    </figure>
                  </LazyLoad>
                </div>
                <div className="ecommerce-card__info ecom-btn">
                  <div id={Id} className="ecommerce-card__title">
                    {title}
                  </div>
                  <div
                    aria-describedby={Id}
                    className="white-btn-skew"
                    onClick={(e) => trackButton(ctaTitle, linkUrl, e)}
                    role="link"
                  >
                    <span>
                      <span>{ctaTitle}</span>
                      <i className="tag-red-arrow"></i>
                    </span>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </a>
      )}
    </React.Fragment>
  );
};

export default React.memo(FanaticsProduct);
