/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/ar';
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/zh-cn';
moment.locale('en');

export const localeConfig = (time, locale, t, accessibility) => {
  let year = '';
  if (locale === 'ja' || locale === 'zh-cn' || locale === 'ko') {
    year = moment(time).locale(locale).format('LL').toString().slice(2, 5);
  } else {
    if (locale === 'es' || locale === 'fr' || locale === 'ar') {
      year = moment(time).year();
    } else {
      year = moment(time).year().toString().substr(-2);
    }
  }
  moment.relativeTimeThreshold('s', 0);
  moment.relativeTimeThreshold('m', 59);
  moment.relativeTimeThreshold('h', 23);
  moment.relativeTimeThreshold('d', 36500);
  if (!accessibility) {
    moment.updateLocale('en', {
      relativeTime: {
        future: 'in %s',
        past: '%s',
        s: `<span class='date'>1</span> ${filterVals(t, 'second', locale)}`,
        m: `<span class='date'>1</span> ${filterVals(t, 'minute', locale)}`,
        mm: `<span class='date'>%d</span>${filterVals(t, 'minute', locale)}`,
        h: `<span class='date'>1</span> ${filterVals(t, 'hour', locale)}`,
        hh: `<span class='date'>%d</span> ${filterVals(t, 'hour', locale)}`,
        d: `<span class='date'>1</span> ${filterVals(t, 'Day', locale)}`,
        dd: function (val) {
          if (val <= 7) {
            return `<span class='date'>${val}</span>${filterVals(t, 'Day', locale)}`;
          } else if (val > 7 && val < 29) {
            let weeks = Math.floor(val / 7);
            return `<span class='date'>${weeks}</span>${filterVals(t, 'week', locale)}`;
          } else if (val > 28) {
            moment.locale(locale);
            let month = moment.monthsShort()[moment(time).get('month')];
            if (locale === 'en' || locale === 'fr' || locale === 'es') {
              month = `${month.charAt(0).toUpperCase()}${month.slice(1)}`;
            }
            if (locale === 'ja' || locale === 'zh-cn') {
              return `${year}${month}`;
            } else if (locale === 'ko') {
              return `${year} ${month}`;
            } else if (locale === 'es') {
              return `${month} de ${year}`;
            } else if (locale === 'fr' || locale === 'ar') {
              return `${month} ${year}`;
            } else {
              return `${month} '${year}`;
            }
          }
        },
      },
    });
  } else {
    moment.updateLocale('en', {
      relativeTime: {
        future: 'in %s',
        past: '%s',
        s: `1 ${filterValsAcessibility(t, 'second', 'ago')}`,
        m: `1 ${filterValsAcessibility(t, 'minute', 'ago')}`,
        mm: `%d ${filterValsAcessibility(t, 'minute', 'ago')}`,
        h: `1 ${filterValsAcessibility(t, 'hour', 'ago')}`,
        hh: `%d ${filterValsAcessibility(t, 'hour', 'ago')}`,
        d: `1 ${filterValsAcessibility(t, 'Day', 'ago')}`,
        dd: function (val) {
          if (val <= 7) {
            return `${val}${filterValsAcessibility(t, 'Day', 'ago')}`;
          } else if (val > 7 && val < 29) {
            let weeks = Math.floor(val / 7);
            return `${weeks}${filterValsAcessibility(t, 'week', 'ago')}`;
          } else if (val > 28) {
            moment.locale(locale);
            let month = moment.monthsShort()[moment(time).get('month')];
            if (locale === 'en' || locale === 'fr' || locale === 'es') {
              month = `${month.charAt(0).toUpperCase()}${month.slice(1)}`;
            }
            if (locale === 'es') {
              return `${month} de ${year}`;
            } else if (locale === 'fr' || locale === 'ar') {
              return `${month} ${year}`;
            } else {
              return `${month} ${year}`;
            }
          }
        },
      },
    });
  }
  let newtime = moment(time).fromNow();
  return newtime;
};

const filterVals = (t, key, locale) => {
  let nKey = key.toLowerCase();
  locale = locale.toLowerCase();
  if (t(key) && locale !== 'en') {
    let val;
    if (locale === 'fr' || locale === 'es') {
      let value = t(key).toLowerCase();
      if (value === 'seconde' || value === 'semaine' || value === 'segundo' || value === 'semana') {
        val = value.slice(0, 3);
        return ` ${val}`;
      } else {
        val = value.slice(0, 1);
        return val;
      }
    } else if (locale === 'ja') {
      val = ` ${t(key).toLowerCase()} ${t('ago')}`;
      return val;
    } else {
      val = ` ${t(key).toLowerCase()}`;
      return val;
    }
  } else {
    if (nKey === 'hour') {
      return 'h';
    }
    if (nKey === 'minute') {
      return 'm';
    }
    if (nKey === 'day') {
      return 'd';
    }
    if (nKey === 'second') {
      return 's';
    }
    if (nKey === 'week') {
      return 'w';
    }
    if (nKey === 'year') {
      return 'y';
    }
    if (nKey === 'years') {
      return 'ys';
    }
  }
};

const filterValsAcessibility = (t, key, ago) => {
  if (t && t(key) && t(ago)) {
    let val = ` ${t(key).toLowerCase()} ${t(ago).toLowerCase()}`;
    return val;
  }
};
