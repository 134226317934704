/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

export const focusHighlighter = () => {
  var lastKey = new Date(),
    lastClick = new Date();
  document.addEventListener('focusin', (event) => {
    var lights = document.querySelector('.non-keyboard-outline');
    lights && lights.classList && lights.classList.remove('non-keyboard-outline');
    var wasByKeyboard = lastClick < lastKey;
    if (wasByKeyboard) {
      event.target.classList.add('non-keyboard-outline');
    }
  });
  window.addEventListener('click', (event) => {
    lastClick = new Date();
  });
  window.addEventListener('keydown', (event) => {
    lastKey = new Date();
  });
};
