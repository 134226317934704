/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import Sponsor from '../Common/Sponsor';
import { useSponsorValue } from '../../context/sponsorContext';
import { MODULE, DARK_THEME, LIGHT_THEME } from '../../constants/globalConstants';

const SponsorPanel = ({ item }) => {
  const [{ onlySponsor }] = useSponsorValue();
  const template_t = item.template_t && item.template_t.toLowerCase();

  const containerFlagCheck = () => {
    let seasonCheck;
    if (onlySponsor) {
      seasonCheck = onlySponsor.some((item) => item?.DisplayContainer?.toLowerCase() === 'true');
    }
    return seasonCheck;
  };

  const sponsorContainer = (item) => {
    if (
      (item && item.contenttype_t.toLowerCase() === 'seasonstats' && !containerFlagCheck()) ||
      (item && item.contenttype_t.toLowerCase() === 'matchdaystats' && !containerFlagCheck()) ||
      (item && item.contenttype_t.toLowerCase() === 'competitionstats' && !containerFlagCheck())
    ) {
      return (
        <div className="mu-item__sponsor__wrapper Scontainer">
          <Sponsor
            type={MODULE}
            moduleName={
              item.contenttype_t.toLowerCase() === 'seasonstats' ||
              item.contenttype_t.toLowerCase() === 'competitionstats'
                ? 'competitionstats'
                : 'matchstats'
            }
            theme={
              template_t === 'percentage breakdown' || template_t === 'single stat (2)'
                ? LIGHT_THEME
                : DARK_THEME
            }
          ></Sponsor>
        </div>
      );
    } else {
      return (
        <div className="mu-item__sponsor__wrapper">
          <Sponsor
            type={MODULE}
            moduleName={
              item.contenttype_t.toLowerCase() === 'seasonstats' ||
              item.contenttype_t.toLowerCase() === 'competitionstats'
                ? 'competitionstats'
                : 'matchstats'
            }
            theme={
              template_t === 'percentage breakdown' || template_t === 'single stat (2)'
                ? LIGHT_THEME
                : DARK_THEME
            }
          ></Sponsor>
        </div>
      );
    }
  };
  return <div className="mu-item__sponsor">{sponsorContainer(item)}</div>;
};

export default SponsorPanel;
