/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useRef, useEffect } from 'react';
import { selectImgCrop } from '../../utils/utils';
import xss from 'xss';
const ImgCrop = (props) => {
  const [imgUrl, setImgUrl] = useState(null);
  const figElm = useRef(null);
  const figElmDimensions = useRef(null);
  const figElmCrop = useRef(null);

  useEffect(() => {
    if (figElm && figElm.current) {
      const domRect = figElm.current.getBoundingClientRect();
      const domRectW = domRect.width;
      const domRectH = domRect.height;
      if (
        !figElmDimensions ||
        !figElmDimensions.current ||
        props.isUpdateImage ||
        (figElmDimensions.current &&
          figElmDimensions.current.width !== domRectW &&
          figElmDimensions.current.height !== domRectH) ||
        props.singleSrc
      ) {
        figElmDimensions.current = { width: domRectW, height: domRectH };
        const crop = selectImgCrop(
          domRectW,
          domRectH,
          props.imgCrop,
          props.singleSrc,
          props.isWebpAvailable,
          props.gridClass
        );
        if (crop && figElmCrop.current !== crop) {
          figElmCrop.current = crop;
          setImgUrl(crop);
        }
      }
    }
  });

  const onErrorImg = (e) => {
    if (imgUrl && imgUrl.includes('.webp')) {
      const crop = imgUrl.replace(/\.[^/.]+$/, '.jpg');
      crop && setImgUrl(crop);
    }
    props.onError(e);
  };

  return (
    <>
      {xss({ imgUrl }) && (
        <figure
          className={`${props.className} figure-adaptive ${props.adaptiveClass}`}
          ref={figElm}
          style={{
            backgroundImage: `url("${xss(imgUrl)}")`,
            opacity: 1,
            ...props.figureStyle,
          }}
        >
          <img
            src={xss(imgUrl)}
            className="img-responsive img-zoom visually-hidden no-img"
            alt={props.alt}
            title={props.title}
            onLoad={(e) => props.onLoad(e)}
            onError={(e) => onErrorImg(e)}
            tabIndex={props.tabIndex}
          />
          {props.titleAvailable && (
            <React.Fragment>
              <div className="mu-item__gradient--left" style={{ display: 'none' }}></div>
              <div className="mu-item__gradient" style={props.gradientStyle}></div>
            </React.Fragment>
          )}
        </figure>
      )}
    </>
  );
};
ImgCrop.defaultProps = {
  singleSrc: false,
  gradientStyle: null,
  className: 'figure-component',
  adaptiveClass: '',
  titleAvailable: true,
  figureStyle: {},
  alt: '',
  title: '',
  onLoad: () => {},
  onError: () => {},
  tabIndex: null,
  isWebpAvailable: false,
  gridClass: '',
  isUpdateImage: false,
};
export default ImgCrop;

/* Sample to call Img crop components with LazyLoad
import LazyLoad from "react-lazyload";
<LazyLoad once={true} offset={100}>
  <ImgCrop imgCrop={item.imagecropurl_s} isWebpAvailable={true} singleSrc={false}></ImgCrop>
</LazyLoad>
*/
