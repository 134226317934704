/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import MU from '../../constants/muConstants';
import { logoutComplete } from '../../utils/analytics';
import { removeUserIDCookie } from '../../utils/idmUtils';
export const getFavPlayerData = (data) => {
  if (
    data &&
    data.PlayerProfileResponse &&
    data.PlayerProfileResponse.response &&
    data.PlayerProfileResponse.response.docs &&
    data.PlayerProfileResponse.response.docs.length > 0
  ) {
    return data.PlayerProfileResponse.response.docs[0];
  } else {
    return null;
  }
};

export const getCarousalData = (data) => {
  if (
    data &&
    data.ContentResponse &&
    data.ContentResponse.response &&
    data.ContentResponse.response.docs &&
    data.ContentResponse.response.docs.length > 0
  ) {
    return data.ContentResponse.response.docs;
  } else {
    return [];
  }
};

export const onUserLogout = () => {
  window.gigya.accounts.logout({
    callback: function onLogout(response) {
      logoutComplete(response);
      if (response && response.errorCode === 0) {
        localStorage && localStorage.removeItem('deviceID');
        localStorage && localStorage.removeItem('apiSessionID');
        sessionStorage && sessionStorage.removeItem('keysObj');
        removeUserIDCookie();
        return window.location.replace(`${MU.assetUrlResource}/${MU.language}`);
      }
    },
  });
};
