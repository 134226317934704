/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import AMPHeroBanner from './AMPHeroBanner';
import AMPSocialHolder from './AMPSocialHolder';
import AMPDfp from './AMPDfp';
import AMPFont from './AMPFont';
import AMPCenterContent from './AMPCenterContent';
import AMPTextbox from './AMPTextbox';
import { replaceDoubleQuoteWithQTag } from '../../../utils/articleUtils';
import AMPCard from './AMPCard';
import { getTagObjects } from '../../../utils/utils';

const AMPArticleDetail = (props) => {
  const {
    Headline,
    Image,
    Video,
    Introduction,
    TextBox6,
    SelectCard6,
    RecommendationSectionTitle,
    AuthorName,
    __PublishedBy,
    ContentType,
    __Published,
    manutds,
  } = props.fields || {};

  let tagsForAnalytics = '';
  const tags = getTagObjects(manutds && manutds.value && manutds.value.Tags);
  if (tags && tags.length) {
    tags.map((item) => {
      tagsForAnalytics += tagsForAnalytics.length
        ? `,${item.Name}: ${item.Tag}`
        : `${item.Name}: ${item.Tag}`;
    });
  }

  return (
    <React.Fragment>
      <AMPFont />
      <div
        className="top-panel"
        data-article-detail={JSON.stringify({
          article_name: Headline && Headline.value,
          author:
            (AuthorName &&
              AuthorName.fields &&
              AuthorName.fields.AuthorName &&
              AuthorName.fields.AuthorName.value) ||
            (__PublishedBy && __PublishedBy.value),
          content_type: ContentType && ContentType.value,
          created_datetime: __Published && __Published.value,
          tag: tagsForAnalytics,
        })}
      >
        {/*---- Hero Banner Start ----*/}
        <AMPHeroBanner Video={Video} Image={Image} />
        {/*---- Hero Banner End ----*/}

        <h1>{Headline && Headline.value}</h1>

        {/*---- Content with social icons Start ----*/}
        <AMPSocialHolder
          fields={props.fields}
          sponsorClass="top"
          type="article"
          sponsorSpanClass="sponsorspan"
          context={props && props.context}
        />
        {/*---- Content with social icons End ----*/}
      </div>

      {/* - Content Heading Start ---*/}
      <div className="content-panel_text">
        <p
          className="heading"
          dangerouslySetInnerHTML={{
            __html: replaceDoubleQuoteWithQTag(Introduction && Introduction.value),
          }}
        />
      </div>
      {/* - Content Heading End ---*/}

      {/*--Center Section Starts here-*/}
      <AMPCenterContent fields={props.fields} isDFPScript={props.isDFPScript} />
      {/*--Center Section Ends here-*/}

      {/* - Content Panel Start ---*/}
      {TextBox6 && TextBox6.value && TextBox6.value.length > 0 && (
        <AMPTextbox value={TextBox6.value} />
      )}
      {/* - Content Panel End ---*/}

      {/* - Recommendation Section Starts here---*/}
      {RecommendationSectionTitle &&
        RecommendationSectionTitle.value &&
        RecommendationSectionTitle.value.length > 0 &&
        SelectCard6 &&
        SelectCard6.length > 0 && (
          <div className="content-panel_recommendations">
            <h2 className="player-title">{RecommendationSectionTitle.value}</h2>
            <ul>
              {SelectCard6.map((item, i) => {
                return (
                  <li key={i}>
                    <AMPCard fields={item && item.fields} />
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      {/* - Recommendation Section Ends here---*/}

      {/*--Bottom Author & Sponsor Section Starts here ---*/}
      <div className="social__holder-bottom">
        <AMPSocialHolder
          fields={props.fields}
          type="articlefooter"
          context={props && props.context}
        />
        {props.isDFPScript && (
          <AMPDfp
            fields={props.fields}
            width="320"
            height="50"
            slot="/11820346/Hercules_ROS_970x90//728x90//320x50"
            className="banner"
          />
        )}
      </div>
      {/*--Bottom Author & Sponsor Section Ends here ---*/}
    </React.Fragment>
  );
};

export default withTranslation()(AMPArticleDetail);
