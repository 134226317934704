/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import FixtureDetail from './FixtureDetail';
import { fixtureSubtitle, fixtureTitle } from '../../utils/momentDate';
import ErrorBoundary from '../Common/ErrorBoundary';

const FixtureListing = ({ listing, selectedFilter, t }) => {
  return (
    <Fragment>
      {Object.keys(listing).map((month, monthIndex) => (
        <ErrorBoundary>
          <div className="Matches__fixture" key={monthIndex}>
            <div className="Matches__time-title">
              <h2>{fixtureTitle(month)}</h2>
            </div>
            {Object.keys(listing[month]).map((day, dayIndex) => (
              <div key={dayIndex}>
                <div className="fixture-title" id={listing[month][day][0].opta_mid_t}>
                  <span>
                    <span>{fixtureSubtitle(listing[month][day][0].matchdate_tdt)}</span>
                    <span className="screenreader">
                      {selectedFilter == 'results' ? 'match result' : t('plannedmatch')}
                    </span>
                  </span>
                  <span>|</span>
                  <span>{listing[month][day][0].competitionname_t}</span>
                </div>
                {listing[month][day].map((match, index) => (
                  <FixtureDetail
                    key={index}
                    matchDetail={match}
                    type={selectedFilter}
                    id={listing[month][day][0].opta_mid_t}
                  />
                ))}
              </div>
            ))}
          </div>
        </ErrorBoundary>
      ))}
    </Fragment>
  );
};

export default withTranslation()(FixtureListing);
