/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { Fragment, useEffect, useRef } from 'react';
import { useModal } from '../../Common/Modal';
import MU from '../../../constants/muConstants';
import Loader from '../../Common/Loader';
import { redirectTo, deviceManageApi, deviceMangerData } from '../../../utils/utils';
import loadable from '@loadable/component';
import ErrorBoundary from '../../Common/ErrorBoundary';
import { STOP } from '../../../constants/globalConstants';
const VideoDetailModal = loadable(() => import('./VideoDetailModal'));

const VideoDetail = (props) => {
  const { showModal, closeModal, isModalVisible } = useModal();
  const { manutds, Teaser, AuthorName } = props.fields;
  const language = MU.language ? MU.language : '';
  const itemId = manutds && manutds.value && manutds.value.Id;
  const isMediaObj = useRef(null);
  useEffect(() => {
    showModal();
  }, []);

  //to handle device sync if user close the modal while accessing the subscribed media content
  const isMediaPlaying = (data) => {
    isMediaObj.current = data;
  };
  const onClose = () => {
    let playHereObj = isMediaObj && isMediaObj.current;
    if (playHereObj && playHereObj.status && playHereObj.userDetail) {
      const { userDetail } = playHereObj;
      const payloadData = deviceMangerData(
        STOP,
        userDetail.UID,
        userDetail.deviceID,
        userDetail.deviceName
      );
      deviceManageApi(payloadData)
        .then(() => {
          redirectTo('/', language);
        })
        .catch(() => {
          redirectTo('/', language);
        });
    } else {
      redirectTo('/', language);
    }
  };
  return (
    <Fragment>
      {isModalVisible && (
        <ErrorBoundary>
          <VideoDetailModal
            cardName={Teaser && Teaser.value}
            author={AuthorName && AuthorName.value}
            closeModal={closeModal}
            fallback={<Loader />}
            onClose={() => onClose()}
            isDestinationPage={true}
            itemid={itemId}
            videoData={props.fields}
            isMediaPlaying={isMediaPlaying}
          />
        </ErrorBoundary>
      )}
    </Fragment>
  );
};

export default VideoDetail;
