/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import loadable from '@loadable/component';
import React, { useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { SEARCH } from '../../constants/containerConstants';
import { localeConfig } from '../../utils/cardDateFormat';
import {
  componentCards,
  modalFilter,
  profileContenttype,
  setNewsFilter,
} from '../../utils/searchUtils';
import {
  createDestinationUrl,
  createSearchUrl,
  createTagList,
  isMutvVideo,
  validateCbr,
  validateObj,
  getLoggedInStatus,
} from '../../utils/utils';
import { DFP } from '../Common/Dfp';
import ImgCrop from '../Common/ImgCrop';
import { useModal } from '../Common/Modal';
import { Video } from '../../data-transform/components/Video';
import * as track from '../../utils/analytics';
import { Image } from '../../data-transform/components/Image';
import { Quotes } from '../../data-transform/components/Quotes';
import { Article } from '../../data-transform/components/Article';
import { Unitedcompetition } from '../../data-transform/components/Unitedcompetition';
import { Podcast } from '../../data-transform/components/Podcast';
import { Quiz } from '../../data-transform/components/Quiz';
import { Collection } from '../../data-transform/components/Collection';
import { ImageGallery } from '../../data-transform/components/ImageGallery';
import SearchPlayerProfile from './SearchPlayerProfile';
import { PlayerProfile } from '../../data-transform/components/PlayerProfile';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import ErrorBoundary from '../Common/ErrorBoundary';
import Loader from '../Common/Loader';
import { useSponsorValue } from '../../context/sponsorContext';
import MU from '../../constants/muConstants';
import { ANONYMOUS, CBR_CONTENTTYPES, REGISTERED } from '../../constants/globalConstants';
import AccessBadge from '../Common/AccessBadge';
const ImageModal = loadable(() => import('../Modals/ImageModal/ImageModal'));
const VideoModal = loadable(() => import('../Modals/VideoModal/VideoModal'));
const QuoteModal = loadable(() => import('../Modals/QuoteModal/QuoteModal'));
const QuizModal = loadable(() => import('../Modals/QuizModal/QuizModal'));
const ImageGalleryModal = loadable(() => import('../Modals/ImageGalleryModal/ImageGalleryModal'));
const CollectionModal = loadable(() => import('../Modals/CollectionModal/CollectionModal'));
const RegistrationModal = loadable(() => import('../Modals/RegistrationModal/RegistrationModal'));
const AllSearch = ({
  results,
  resultsCount,
  t,
  ImageSet,
  search,
  pager,
  language,
  playerProfileList,
}) => {
  const [{ isUserLogin, isLoginChecked, isUserSubscribe, isUserSkyId }] = useSponsorValue();
  const { showModal, closeModal, isModalVisible } = useModal();
  const {
    showModal: cbrShowModal,
    closeModal: cbrCloseModal,
    isModalVisible: cbrModalVisible,
  } = useModal();
  const [fields, setFields] = useState({});
  const [itemId, setItemId] = useState(null);
  const [Modal, setModal] = useState(null);
  const contentAccessValue = useRef({});
  let listIndex = -1;

  const getAnalyticsData = (fields, itemId, contentStatus) => {
    const initialData = track.data('card');
    return {
      ...initialData,
      card_name:
        (fields.ShortHeadline && fields.ShortHeadline.value) ||
        (fields.Headline && fields.Headline.value),
      container_type: SEARCH,
      item_id: itemId,
      content_type: fields.ContentType && fields.ContentType.value,
      content_status: contentStatus,
      subscription_status: getLoggedInStatus({
        isLoginChecked,
        isUserLogin,
        isUserSubscribe,
        isUserSkyId,
      }),
      tag: fields.manutds && fields.manutds.value && fields.manutds.value.Tags,
      created_datetime: fields.__Published && fields.__Published.value,
      author: fields.AuthorName && fields.AuthorName.value,
      destination_url:
        fields.manutds && fields.manutds.value && fields.manutds.value.DestinationUrl,
    };
  };

  const trackCard = (fields, itemId, e, contentStatus) => {
    track.analytics(getAnalyticsData(fields, itemId, contentStatus), e);
  };

  const openSearchModal = (type, result, e) => {
    if (result) {
      e.preventDefault();
      let data;
      if (type === 'image' || (type === 'blogentry' && result.isimageasset_b == true)) {
        data = Image(result, true);
        setModal(ImageModal);
      } else if (type === 'video') {
        let destPage = linkURL(result);
        if (destPage && destPage !== '#') {
          window.location.href = destPage;
        } else {
          data = Video(result, true);
          setModal(VideoModal);
        }
      } else if (type === 'quote') {
        data = Quotes(result, true);
        setModal(QuoteModal);
      } else if (type === 'quiz') {
        data = Quiz(result, true);
        setModal(QuizModal);
      } else if (type === 'gallery') {
        data = ImageGallery(result, true);
        setModal(ImageGalleryModal);
      } else if (type === 'collection') {
        data = Collection(result, true);
        setModal(CollectionModal);
      }
      if (data) {
        const showCbr =
          CBR_CONTENTTYPES.includes(type) &&
          validateCbr({
            isUserLogin,
            isUserSubscribe,
            contentAccess: result.contentaccess_t,
            contentType: result.contenttype_t,
            manutds: result.taglist_sm,
            isAws: true,
          });
        setFields(data.fields);
        setItemId(data.rendering && data.rendering.dataSource);
        trackCard(
          data.fields,
          data.rendering && data.rendering.dataSource,
          e,
          showCbr && showCbr.accessCheck
        );
        if (showCbr && showCbr.value) {
          contentAccessValue.current = {
            value: showCbr.accessCheck,
            contentType: result.contenttype_t,
            itemId: data.rendering && data.rendering.dataSource,
            item: data.fields,
          };
          cbrShowModal();
        } else {
          showModal();
        }
      }
    }
  };

  const setModalFilter = (contenttype_t) => {
    if (contenttype_t) {
      contenttype_t = contenttype_t.toLowerCase();
      if (modalFilter.indexOf(contenttype_t) != -1) {
        return true;
      } else {
        return false;
      }
    }
  };

  const buildSearchDestinationUrl = (destinationurl_s, contenttype) => {
    if (setModalFilter(contenttype.toLowerCase())) {
      return '#';
    } else {
      return createDestinationUrl(destinationurl_s);
    }
  };

  const formatCardDate = (time, accessibility) => {
    if (time) {
      return localeConfig(time, language, t, accessibility);
    }
  };

  const renderStatsBar = (result) => {
    return (
      <div className="search-statsbar">
        <span className="screenreader">
          {t('publishedtime')}
          <RichText
            tag="span"
            field={{ value: formatCardDate(result.appearance_tdt, 'accessability') }}
            editable={false}
          />
        </span>
        <RichText
          tag="span"
          aria-hidden="true"
          field={{ value: formatCardDate(result.appearance_tdt) }}
          editable={false}
        />
        {validateObj(result.displaytag_s && result.displaytag_s.Display) && (
          <a
            href={createSearchUrl(result.displaytag_s.AppliedTag)}
            onClick={(e) => e.stopPropagation()}
          >
            <span>{` | ${result.displaytag_s && result.displaytag_s.Display}`}</span>
          </a>
        )}
        {!validateObj(result.displaytag_s && result.displaytag_s.Display) &&
          result.categorytag_s &&
          result.categorytag_s.Category && (
            <a
              href={createSearchUrl(result.categorytag_s.AppliedTag)}
              onClick={(e) => e.stopPropagation()}
            >
              <span>{` | ${result.categorytag_s && result.categorytag_s.Category}`}</span>
            </a>
          )}
      </div>
    );
  };

  const renderImage = (result) => {
    return (
      <div className="img-holder">
        <ImgCrop
          imgCrop={
            result.celumimagesvariant_s &&
            result.celumimagesvariant_s.ImageCropUrl &&
            result.celumimagesvariant_s.ImageCropUrl.CropUrl
          }
          isWebpAvailable={
            result.celumimagesvariant_s &&
            result.celumimagesvariant_s.ImageCropUrl &&
            result.celumimagesvariant_s.ImageCropUrl.isWebpAvailable
          }
          alt={result.alttext_s}
          title={result.alttext_s}
        />
      </div>
    );
  };
  const onCardClick = (e, result) => {
    const contentType = result.contenttype_t && result.contenttype_t.toLowerCase();
    if (setModalFilter(contentType)) {
      openSearchModal(contentType, result, e);
    } else {
      const showCbr = validateCbr({
        isUserLogin,
        isUserSubscribe,
        contentAccess: result.contentaccess_t,
        contentType: result.contenttype_t,
        manutds: result.taglist_sm,
        isAws: true,
      });
      const fieldsData =
        contentType === 'podcast' ? Podcast(result, true).fields : Article(result, true).fields;
      trackCard(fieldsData, result.itemid_s, e, showCbr && showCbr.accessCheck);
      if (showCbr && showCbr.value) {
        contentAccessValue.current = {
          value: showCbr.accessCheck,
          contentType: result.contenttype_t,
          url: createDestinationUrl(result.destinationurl_s),
          itemId: result.itemid_s,
          item: fieldsData,
        };
        e.preventDefault();
        cbrShowModal();
      }
    }
  };
  const renderCardClick = (result, index) => {
    return (
      <React.Fragment>
        <a
          className="search-card-click"
          href={buildSearchDestinationUrl(result.destinationurl_s, result.contenttype_t)}
          onClick={(e) => onCardClick(e, result)}
        >
          {CBR_CONTENTTYPES.includes(result.contenttype_t.toLowerCase()) && (
            <AccessBadge
              contentAcessLocal={result.contentaccess_t}
              contentTypeLocal={result.contenttype_t}
              tagListLocal={result.taglist_sm}
              isSearchPage={true}
            />
          )}
          <h2>
            <span className="title">
              {result.contenttype_t.toLowerCase() === 'podcast'
                ? result.headline_t
                : result.shortheadline_t}
            </span>
          </h2>
          <p className="teasure">
            {result.contenttype_t.toLowerCase() === 'podcast'
              ? result.episodedescription_t
              : result.teaser_t}
          </p>
        </a>
        {result.appearance_tdt && renderStatsBar(result)}
      </React.Fragment>
    );
  };

  const renderDFP = (i) => {
    return (i + 1) % 4 === 0 ? (
      <div>
        <div className="grid-xl ad-block" aria-hidden="true" tabIndex="-1">
          <DFP
            slot="/11820346/Hercules_ROS_750x200//300x250"
            size={[
              [750, 200],
              [300, 250],
            ]}
            browserSize={[1280, 320]}
          />
        </div>
      </div>
    ) : null;
  };

  const renderPodcastVideoRightDetail = (result, index) => {
    if (result.contenttype_t.toLowerCase() === 'podcast') {
      return renderCardClick(result, index);
    }
    return (
      <React.Fragment>
        {CBR_CONTENTTYPES.includes(result.contenttype_t.toLowerCase()) && (
          <AccessBadge
            contentAcessLocal={result.contentaccess_t}
            contentTypeLocal={result.contenttype_t}
            tagListLocal={result.taglist_sm}
            isSearchPage={true}
          />
        )}
        <h2>
          <span className="title">
            <span tabIndex="0" role="link">
              {result.shortheadline_t}
              <span className="screenreader">{'video'}</span>
            </span>
          </span>
        </h2>
        <h2 className="mobile-view">
          <span>{result.shortheadline_t}</span>
        </h2>
        <p className="teasure">{result.teaser_t}</p>
        {result.appearance_tdt && renderStatsBar(result)}
      </React.Fragment>
    );
  };
  const linkURL = (result) => {
    const tagList = result.taglist_sm;
    return tagList && isMutvVideo(createTagList(tagList, result.category_s))
      ? createDestinationUrl(result.destinationurl_s)
      : '#';
  };

  const renderHtml = (result, index) => {
    if (result) {
      listIndex += 1;
      result.appearance_tdt = result.__published_tdt;
      result.variant_t = 'search-child';
      if (
        result.contenttype_t &&
        (result.contenttype_t.toLowerCase() === 'menuitem' ||
          result.contenttype_t.toLowerCase() === 'submenuitem')
      ) {
        result.shortheadline_t = result.pagetitle_t;
        result.teaser_t = result.pagedescription_t;
        result.appearance_tdt = result.__published_tdt
          ? result.__published_tdt
          : result.__smallcreateddate_tdt;
      } else if (result.contenttype_t && result.contenttype_t.toLowerCase() === 'quote') {
        result.teaser_t = result.quotevalue_t;
      } else if (result.contenttype_t && result.contenttype_t.toLowerCase() === 'blogentry') {
        result.teaser_t = result.blogdescription_t;
        result.shortheadline_t = result.blogheading_t;
        if (result.isvideoasset_b == true) {
          result.contenttype_t = 'video';
        }
      } else if (result.contenttype_t && result.contenttype_t.toLowerCase() === 'quiz') {
        result.shortheadline_t = result.title_t ? result.title_t : result.title;
      }
      return (
        <div className="search__results">
          {result.contenttype_t.toLowerCase() !== 'image' &&
            result.contenttype_t.toLowerCase() !== 'blogentry' &&
            setNewsFilter(result.contenttype_t, result) && (
              <div className="search__items">
                <div className="search__items__detail news-article">
                  {renderCardClick(result, index)}
                </div>
              </div>
            )}
          {/* Old implementation for playerprofile */}
          {/* {profileContenttype.includes(result.contenttype_t.toLowerCase()) && index > 0 && (
            <div className="search__items">
              <Transform container={SEARCH} data={result} source="aws" gridClass="grid-12" />
            </div>
          )} */}
          {setNewsFilter(result.contenttype_t, result) &&
            (result.contenttype_t.toLowerCase() === 'image' ||
              (result.contenttype_t.toLowerCase() === 'blogentry' &&
                result.isimageasset_b == true)) && (
              <div className="search__items">
                <div className="search__items__detail news-article">
                  <div className="details">
                    <div className="detail__left image-card">
                      {renderImage(result)}
                      <span>
                        <i className="icon-camera-white" aria-hidden="true"></i>
                      </span>
                    </div>
                    <div className="detail__right">{renderCardClick(result, index)}</div>
                  </div>
                </div>
              </div>
            )}
          {(result.contenttype_t.toLowerCase() === 'podcast' ||
            result.contenttype_t.toLowerCase() === 'video' ||
            (result.contenttype_t.toLowerCase() === 'blogentry' &&
              result.isvideoasset_b == true)) && (
            <div className="search__items">
              <div
                className={`search__items__detail video ${
                  result.contenttype_t.toLowerCase() === 'podcast' ? 'podcast' : ''
                }`}
                onClick={(e) =>
                  result.contenttype_t.toLowerCase() !== 'podcast' &&
                  openSearchModal(result.contenttype_t.toLowerCase(), result, e)
                }
              >
                <div className="details">
                  <div className="detail__left">
                    {renderImage(result)}
                    <span>
                      <i
                        className={`icon-${
                          result.contenttype_t.toLowerCase() === 'podcast' ? 'mic' : 'play'
                        }`}
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="detail__right">
                    {renderPodcastVideoRightDetail(result, index)}
                  </div>
                </div>
              </div>
            </div>
          )}
          {renderDFP(pager && pager.currentPage < 2 ? listIndex : index)}
        </div>
      );
    }
  };

  const renderImageSlider = () => {
    listIndex += 1;
    return (
      <div className="search__results-images grid-12">
        <h2>{`${t('Imagesfor')} ${search}`} </h2>
        <div className="search-swip-carousel" role="list">
          {ImageSet.map((image, index) => (
            <div className="swip-carousel-items" role="listitem" key={index}>
              <div className="img-holder" onClick={(e) => openSearchModal('image', image, e)}>
                <ImgCrop
                  imgCrop={image.imagecropurl_s && image.imagecropurl_s.large}
                  titleAvailable={false}
                  className={'img-zoom figure-component'}
                  tabIndex="0"
                  singleSrc={true}
                  isWebpAvailable={image.iswebpavilablebool_b}
                />
              </div>
            </div>
          ))}
        </div>
        {renderDFP(listIndex)}
      </div>
    );
  };

  const renderPlayerProfile = () => {
    return (
      <React.Fragment>
        {playerProfileList && playerProfileList.length > 0 && (
          <div>
            <h2 className="search_playerprofile_heading">{t('playerprofile')} </h2>
            {playerProfileList.map((result, i) => {
              listIndex += 1;
              return (
                <React.Fragment key={i}>
                  {profileContenttype.includes(result.contenttype_t.toLowerCase()) && (
                    <ErrorBoundary>
                      <SearchPlayerProfile
                        {...PlayerProfile(result, true)}
                        container={SEARCH}
                        gridClass="grid-12"
                      />
                    </ErrorBoundary>
                  )}
                  {renderDFP(listIndex)}
                </React.Fragment>
              );
            })}
          </div>
        )}
        {ImageSet && ImageSet.length > 0 && renderImageSlider()}
      </React.Fragment>
    );
  };

  const renderData = (data) => {
    return data.map((result, index) => renderHtml(result, index));
  };
  const renderCards = (contentData, flag) => {
    let len =
      contentData && contentData.length > 0 && contentData.length > 3
        ? 3
        : contentData && contentData.length;
    return len && flag
      ? flag === 'beforeProfile'
        ? renderData(contentData.slice(0, len)) //To render the first three cards before player's profile.
        : renderData(contentData.slice(len, contentData.length)) //To render the reamining cards after player's profile.
      : null;
  };
  const renderSortingCards = () => {
    if (pager && pager.currentPage < 2) {
      return (
        <React.Fragment>
          {renderCards(results, 'beforeProfile')}
          {renderPlayerProfile()}
          {renderCards(results, 'afterProfile')}
        </React.Fragment>
      );
    } else if (pager && pager.currentPage > 1 && results) {
      return renderData(results);
    }
  };

  const successCallback = ({ showContent, isSubscribedContent }) => {
    if (contentAccessValue.current && showContent) {
      if (!MU.isIDMEnabled) {
        if (contentAccessValue.current.contentType !== 'article') {
          showModal();
        } else if (
          contentAccessValue.current.contentType === 'article' &&
          contentAccessValue.current.url
        ) {
          window.location = contentAccessValue.current.url;
        }
      } else
        return [
          'successCallbck',
          isSubscribedContent,
          'destinationUrl',
          contentAccessValue.current.url,
        ];
    }
  };

  return (
    <React.Fragment>
      {resultsCount > 0 && (
        <section className="grid-12">
          {renderSortingCards()}
          {cbrModalVisible && (
            <ErrorBoundary>
              <RegistrationModal
                successCallback={successCallback}
                closeModal={cbrCloseModal}
                previousActiveElement={document.activeElement}
                fallback={<Loader />}
                accessType={contentAccessValue.current && contentAccessValue.current.value}
                analyticsObj={getAnalyticsData(
                  contentAccessValue.current.item,
                  contentAccessValue.current.itemId,
                  contentAccessValue.current.value
                )}
              />
            </ErrorBoundary>
          )}
          {isModalVisible && (
            <ErrorBoundary>
              <Modal
                cardName={fields.Teaser && fields.Teaser.value}
                author={fields.AuthorName && fields.AuthorName.value}
                closeModal={closeModal}
                itemid={itemId}
                data={fields}
                imageData={fields}
                quoteData={fields}
                cardData={fields}
                galleryData={fields}
                contentAccessState={
                  contentAccessValue &&
                  contentAccessValue.current &&
                  contentAccessValue.current.value
                }
              />
            </ErrorBoundary>
          )}
        </section>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(AllSearch);
