/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import MU from '../constants/muConstants';
import {
  VIDEO_STOP,
  VIDEO_AD_CLICK,
  VIDEO_AD_COMPLETE,
  VIDEO_AD_START,
  VIDEO_COMPLETE,
  VIDEO_FULLSCREEN,
  VIDEO_PAUSE,
  VIDEO_PLAY,
  VIDEO_PLAY_25,
  VIDEO_PLAY_50,
  VIDEO_PLAY_75,
} from '../constants/globalConstants';
import { videoEvents } from './analytics';
import { individualTag } from '../components/Mutv/mutvutil';
import { createTagArr, getContentAccessLocal, isIOSDevice } from './utils';
let playerObjects = [];

class Brightcove {
  brightCoveWebPlayer(elmId, itemid, componentType, videoData, options, callback, type, isMutv) {
    if (typeof document !== 'undefined' && document) {
      if (!type) {
        type = 'video';
      }
      if (this.playerObj) {
        this.playerObj = null;
      }
      const isLiveVideo = componentType === 'liveStream' || componentType === 'liveStreamDest';
      this.elmId = elmId;
      this.itemid = itemid;
      this.type = type;
      this.contentAccessFlag = setContentAccessState(this.videoData);
      this.accountId = MU.brightcoveAccountId;
      this.playerId = MU.brightcovePlayerId;
      this.isIOSDevice = !isLiveVideo && isIOSDevice() ? true : false;
      this.options = {
        ...this.getDefaultOptions(),
        ...options,
        ...this.bigPlayButtonOptions({ isIOSDevice: this.isIOSDevice }),
      };
      this.videoId = getVideoMediaId(videoData, type);
      this.options['data-video-id'] = 'ref:' + this.videoId; //'ref:6144054271001'; //"ref:6130761022001";//"ref:6129150490001";;
      this.playerObj = null;
      this.videoData = videoData;
      this.componentType = componentType;
      this.subscribeEvents = callback;
      this.isMutv = isMutv ? isMutv : false;
      this.stream_time = 0;
      this.addPlayerHtml();
    }
  }

  getDefaultOptions() {
    if (this.type === 'BGVideoAutoPlay') {
      return {
        'data-account': MU.brightcoveAccountId,
        'data-player': MU.brightcovePlayerId,
        language: MU.language,
        playsinline: true,
        controlBar: {
          volumePanel: false,
          pictureInPictureToggle: false,
          fullscreenToggle: false,
          timeDivider: false,
          progressControl: false,
          audioTrackButton: false,
          subsCapsButton: false,
          playbackRateMenuButton: false,
          currentTimeDisplay: false,
          durationDisplay: false,
        },
        html5: {
          hls: {
            smoothQualityChange: true,
          },
        },
        loop: true,
        muted: true,
        autoplay: true,
        bigPlayButton: false,
        title: false,
        description: false,
        debug: false,
      };
    } else {
      return {
        'data-account': MU.brightcoveAccountId,
        'data-player': MU.brightcovePlayerId,
        language: MU.language,
        playsinline: true,
        controlBar: {
          volumePanel: this.type === 'audio' ? false : {},
          pictureInPictureToggle: false,
          fullscreenToggle: this.type === 'audio' ? false : true,
          timeDivider: this.type === 'audio' ? false : true,
        },
        html5: {
          hls: {
            smoothQualityChange: true,
          },
        },
        autoplay: true,
        bigPlayButton: false,
        title: false,
        description: false,
        debug: false,
      };
    }
  }

  addPlayerHtml() {
    var playerHTML =
      '<video-js id="brightcove-webplayer-' +
      this.elmId +
      '" class="video-js" data-embed="default" data-bigPlayButton="' +
      this.isIOSDevice +
      '" controls style="width:100%;height:100%;opacity:0;"></video-js>';
    // Inject the player code into the DOM
    document.getElementById(this.elmId).innerHTML = playerHTML;
    if (
      this.componentType &&
      [
        'superHero',
        'modalVideo',
        'liveStream',
        'liveStreamDest',
        'collectionVideo',
        'quizComponent',
        'inlineVideo',
      ].includes(this.componentType)
    ) {
      const id = 'brightcove-player';
      if (!document.getElementById(id)) {
        var s = document.createElement('script');
        s.id = id;
        s.src = `//players.brightcove.net/${MU.brightcoveAccountId}/${MU.brightcovePlayerId}_default/index.min.js`;
        document.body.appendChild(s);
        s.onload = () => this.initWebPlayer();
        // s.onerror = () => this.initWebPlayer();
        s.onerror = () => s.parentNode.removeChild(s);
      } else {
        this.initWebPlayer();
      }
    }
  }

  initWebPlayer() {
    var _self = this;
    if (
      (_self.videoData && !_self.componentType === 'liveStreamDest') ||
      (_self.isIOSDevice && _self.type !== 'BGVideoAutoPlay')
    ) {
      _self.changeOpacity();
    }
    _self.playerObj =
      typeof window !== 'undefined' &&
      window &&
      window.bc('brightcove-webplayer-' + _self.elmId, _self.options);
    playerObjects.push(_self.playerObj);
    _self.playerObj.on('play', function () {
      (!_self.isIOSDevice || _self.type === 'BGVideoAutoPlay') && _self.changeOpacity();
    });
    _self.preventVrErrorMsg();
    _self.subscribeEvents(_self.playerObj);
    _self.playerObj.contentAccess = setContentAccessState(_self.videoData);
    if (_self.componentType !== 'liveStream' || _self.componentType !== 'liveStreamDest') {
      _self.defaultSubscribeEvents(_self.playerObj);
    }
    if (_self.options.autoplay) _self.playerObj.play();
  }

  defaultSubscribeEvents(playerObj) {
    let lastTriggerPos;
    let isAdPlaying = false;
    let isSeeked = false;
    var _self = this;
    let startTime = 0;
    let endTime = 0;
    let existingTotalTime = 0;
    let initialLoad = true;
    playerObj.on('fullscreenchange', function (evt) {
      _self.setOmnitureData(VIDEO_FULLSCREEN);
    });
    playerObj.on('seeked', function (evt) {
      console.log('seeked');
      isSeeked = true;
      let phPos = parseInt(playerObj.currentTime());
      existingTotalTime = _self.stream_time;
      endTime = phPos;
      startTime = phPos;
    });
    playerObj.on('ready', function (evt) {
      if (!isSeeked && _self.type === 'audio') {
        _self.setAudioControls(playerObj);
      }
    });
    playerObj.on('playing', function (evt) {
      if (_self.isMutv && _self.isMutv.startTime && initialLoad) {
        playerObj.currentTime(_self.isMutv.startTime);
        initialLoad = false;
      }
      if (isAdPlaying) {
        handleAudioCss(false, _self.type);
      }
      if (!isSeeked) {
        console.log('play');
        if (_self.type === 'audio') {
          _self.hideControlsForAudio();
        }
        _self.setOmnitureData(VIDEO_PLAY);
      } else {
        isSeeked = false;
      }
    });
    playerObj.on('pause', function (evt) {
      if (!playerObj.seeking() && !playerObj.ended()) {
        console.log('pause');
        _self.setOmnitureData(VIDEO_PAUSE);
      }
    });
    playerObj.on('ended', function (evt) {
      console.log('ended');
      _self.setOmnitureData(VIDEO_COMPLETE);
    });
    playerObj.on('ads-click', function (evt) {
      console.log('ads-click');
      _self.setOmnitureData(VIDEO_AD_CLICK, null, true);
    });
    playerObj.on('ads-pod-started', function (evt) {
      console.log('ads-pod-started');
      isAdPlaying = true;
      handleAudioCss(true, _self.type);
      _self.setOmnitureData(VIDEO_AD_START, null, true);
    });
    playerObj.on('ads-pod-ended', function (evt) {
      console.log('ads-pod-ended');
      isAdPlaying = false;
      handleAudioCss(false, _self.type);
      _self.setOmnitureData(VIDEO_AD_COMPLETE, null, true);
    });
    playerObj.on('ads-allpods-completed', function (evt) {
      console.log('ads-allpods-completed');
      isAdPlaying = false;
      handleAudioCss(false, _self.type);
    });
    playerObj.on('adserror', function (evt) {
      console.log('adserror');
      isAdPlaying = false;
      handleAudioCss(false, _self.type);
      _self.setOmnitureData(VIDEO_AD_COMPLETE, null, true);
    });
    playerObj.on('error', function (evt) {
      console.log('error');
      if (playerObj.error_ && playerObj.error_.type === 'CHROMECAST_PLAYER_LOAD_FAILURE') {
        playerObj.error(null);
        evt.preventDefault();
      }
      if (playerObj.error_ && playerObj.error_.code !== 'web-vr-not-supported') {
        playerObjects = playerObjects.filter(function (obj) {
          return obj.id_ !== playerObj.id_;
        });
      }
    });
    playerObj.on('timeupdate', function (evt) {
      const dur = parseInt(playerObj.duration());
      if (!isAdPlaying) {
        let phPos = parseInt(playerObj.currentTime());
        if (endTime + 1 == phPos) {
          endTime = phPos;
          _self.stream_time = existingTotalTime + (endTime - startTime);
        }
        if (phPos == parseInt(dur * 0.25) && phPos != lastTriggerPos) {
          lastTriggerPos = phPos;
          console.log('quarter', dur);
          _self.setOmnitureData(VIDEO_PLAY_25, dur);
        }
        if (phPos == parseInt(dur * 0.5) && phPos != lastTriggerPos) {
          lastTriggerPos = phPos;
          console.log('half', dur);
          _self.setOmnitureData(VIDEO_PLAY_50, dur);
        }
        if (phPos == parseInt(dur * 0.75) && phPos != lastTriggerPos) {
          lastTriggerPos = phPos;
          console.log('onethird', dur);
          _self.setOmnitureData(VIDEO_PLAY_75, dur);
        }
      }
    });
  }

  setAudioControls(playerObj) {
    this.hideControlsForAudio();

    // +++ Create divs for buttons +++
    let myPlayer = playerObj,
      jumpAmount = 30,
      controlBar,
      insertBeforeNode,
      newElementBB = typeof document !== 'undefined' && document && document.createElement('div'),
      newElementFB = typeof document !== 'undefined' && document && document.createElement('div'),
      newImageBB = typeof document !== 'undefined' && document && document.createElement('img'),
      newImageFB = typeof document !== 'undefined' && document && document.createElement('img'),
      newElementWatchVideo =
        typeof document !== 'undefined' && document && document.createElement('a');

    // +++ Assign IDs for later element manipulation +++
    newElementBB.id = 'backButton';
    newElementFB.id = 'forwardButton';
    // newElementWatchVideo.id = 'watchVideo';
    // newElementWatchVideo.innerHTML = '<i class="icon-watchvideo"></i>Watch Video';

    // +++ Assign properties to elements and assign to parents +++
    newImageBB.setAttribute(
      'src',
      '//learning-services-media.brightcove.com/doc-assets/player-development/samples/back-forward-buttons/back-button.png'
    );
    newElementBB.appendChild(newImageBB);
    newImageFB.setAttribute(
      'src',
      '//learning-services-media.brightcove.com/doc-assets/player-development/samples/back-forward-buttons/forward-button.png'
    );
    newElementFB.appendChild(newImageFB);

    // +++ Get controlbar and insert elements +++
    controlBar = myPlayer.$('.vjs-control-bar');

    // Get the element to insert buttons in front of in conrolbar
    insertBeforeNode = myPlayer.$('.vjs-play-control');

    controlBar.insertBefore(newElementWatchVideo, insertBeforeNode);
    // Insert the button div in proper location
    controlBar.insertBefore(newElementBB, insertBeforeNode);

    // Get the element to insert buttons in front of in conrolbar
    // insertBeforeNode = myPlayer.$(".vjs-volume-panel");

    // Insert the button div in proper location
    insertBeforeNode.insertAdjacentElement('afterend', newElementFB);

    // +++ Add event handlers to jump back or forward +++
    // Back button logic, don't jump to negative times
    newElementBB.addEventListener('click', function () {
      var newTime,
        rewindAmt = jumpAmount,
        videoTime = myPlayer.currentTime();
      if (videoTime >= rewindAmt) {
        newTime = videoTime - rewindAmt;
      } else {
        newTime = 0;
      }
      myPlayer.currentTime(newTime);
    });

    // Forward button logic, don't jump past the duration
    newElementFB.addEventListener('click', function () {
      var newTime,
        forwardAmt = jumpAmount,
        videoTime = myPlayer.currentTime(),
        videoDuration = myPlayer.duration();
      if (videoTime + forwardAmt <= videoDuration) {
        newTime = videoTime + forwardAmt;
      } else {
        newTime = videoDuration;
      }
      myPlayer.currentTime(newTime);
    });
  }

  hideControlsForAudio() {
    const podcastElement =
      typeof document !== 'undefined' && document && document.querySelector('.mu-item.podcast');
    const player = podcastElement && podcastElement.getElementsByClassName('mu-item__player');
    if (player && player.length) {
      player[0].classList.add('audio-player');
    }

    const videoContainer = podcastElement && podcastElement.getElementsByClassName('video-js');
    if (videoContainer && videoContainer.length > 0) {
      videoContainer[0].style.height = '190px';
    }

    // const videoplayer = document.getElementsByTagName('video');
    // if (videoplayer && videoplayer.length > 0) {
    //   videoplayer[0].style.display = 'none';
    // }
  }

  changeOpacity() {
    var _self = this;
    const element =
      typeof document !== 'undefined' &&
      document &&
      document.getElementById(`brightcove-webplayer-${_self.elmId}`);
    if (element) {
      element.style.opacity = 1;
      const videoElement = element.getElementsByTagName('video');
      if (videoElement && videoElement[0]) videoElement[0].style.opacity = 1;
    }
  }

  changeVideo(itemid, videoData) {
    this.itemid = itemid;
    this.videoData = videoData;
    this.videoId = getVideoMediaId(videoData, this.type);
    this.playerObj.contentAccess = setContentAccessState(videoData);
    var playerObj = this.playerObj;
    playerObj.catalog.getVideo('ref:' + this.videoId, function (error, video) {
      // Load the video object into the player
      if (playerObj.customEndscreenModal) {
        playerObj.customEndscreenModal.close();
      }
      playerObj.catalog.load(video);
    });
  }

  preventVrErrorMsg() {
    var playerObj = this.playerObj;
    playerObj.on('loadstart', function (evt) {
      if (playerObj.error_ && playerObj.error_.code === 'web-vr-not-supported') {
        playerObj.error(null);
        evt.preventDefault();
      }
    });
  }

  getChromeCastSession() {
    try {
      if (
        typeof window !== 'undefined' &&
        window &&
        window.cast &&
        window.cast.framework &&
        window.cast.framework.CastContext &&
        window.cast.framework.CastContext.getInstance &&
        window.cast.framework.CastContext.getInstance() &&
        window.cast.framework.CastContext.getInstance().getCurrentSession
      ) {
        return window.cast.framework.CastContext.getInstance().getCurrentSession();
      }
      return null;
    } catch (e) {
      console.log('error in getting cast currentsession');
      return null;
    }
  }

  bigPlayButtonOptions = ({ isIOSDevice }) => {
    if (isIOSDevice && this.type !== 'BGVideoAutoPlay') {
      return { autoplay: false, bigPlayButton: true };
    }
    return {};
  };

  closeVideo() {
    if (this.playerObj && !this.playerObj.isDisposed_) {
      this.setOmnitureData(VIDEO_STOP);
      this.playerObj.dispose();
      const castSession = this.getChromeCastSession();
      castSession && castSession.endSession(true);
    }
  }

  playVideo() {
    if (this.playerObj) {
      return this.playerObj.play();
    }
  }

  pauseVideo(shouldAdPause) {
    if (this.playerObj) {
      this.playerObj.pause();
      if (shouldAdPause && this.playerObj.ima3 && this.playerObj.ima3.adsManager) {
        this.playerObj.ima3.adsManager.pause();
      }
    }
  }

  getPlayerObject() {
    return this.playerObj;
  }

  setOmnitureData(eventType, dur, isAd, type) {
    let contentType = type === 'live' ? 'live-video' : 'video';
    if (['podcast', 'schedule'].includes(this.videoData.ContentType.value)) {
      contentType = this.videoData.ContentType.value;
    }
    if (isAd) {
      const obj = {
        video_name: this.videoData.ShortHeadline && this.videoData.ShortHeadline.value,
        item_id: this.itemid,
        ooyala_id: getVideoMediaId(this.videoData, this.type),
        content_type: contentType,
        ...getMutvAdditionalData(this.isMutv, this.videoData, this.type, true, this.stream_time),
      };
      videoEvents(eventType, obj, 'videoadData');
    } else {
      const obj = {
        page_name: typeof document !== 'undefined' && document && document.title,
        video_name: this.videoData.ShortHeadline && this.videoData.ShortHeadline.value,
        tag: this.videoData.manutds.value && this.videoData.manutds.value.Tags,
        item_id: this.itemid,
        ooyala_id: getVideoMediaId(this.videoData, this.type),
        created_datetime: this.videoData.__Published && this.videoData.__Published.value,
        author: this.videoData.manutds.value && this.videoData.manutds.value.Author,
        content_type: contentType,
        ...getMutvAdditionalData(this.isMutv, this.videoData, this.type, false, this.stream_time),
        ...isFullscreEvent(eventType),
      };
      if (type !== 'live') {
        // duration captured in seconds
        dur = dur ? dur : parseInt(this.playerObj.duration());
        obj.video_length = dur * 1000;
      }
      let collectionVideObj;
      if (this.componentType === 'collectionVideo') {
        collectionVideObj = {
          collectionDestUrl:
            this.videoData.manutds.value && this.videoData.manutds.value.DestinationUrl,
          containerType: 'collectionVideo',
          collectionTitle: obj.video_name,
        };
      }
      videoEvents(eventType, obj, 'videoData', collectionVideObj);
    }
  }

  liveEpgAnalytics(itemid, videoData) {
    this.setOmnitureData(VIDEO_STOP);
    this.itemid = itemid;
    this.videoData = videoData;
    this.setOmnitureData(VIDEO_PLAY);
  }
}

const additionalAnalytics = (obj) => {
  return obj ? obj : {};
};

const getMutvAdditionalData = (isMutv, data, type, isAd, stream_time) => {
  if (isMutv) {
    if (isAd) {
      const obj = {};
      if (type === 'audio' || (isMutv.isPodcast && type === 'video')) {
        obj.video_name = data.Headline && data.Headline.value;
        obj.card_name = data.Headline && data.Headline.value;
      } else {
        obj.card_name = data.ShortHeadline && data.ShortHeadline.value;
      }
      obj.media_type = type;
      return obj;
    }

    const obj = {
      card_name: data.ShortHeadline && data.ShortHeadline.value,
      media_type: type,
      destination_url: data.manutds.value && data.manutds.value.DestinationUrl,
      content_status: isMutv.contentStatus,
      container_type: isMutv.container_type,
      subscription_status: isMutv.subscription_status,
      stream_time: stream_time,
      ...individualTag(data.manutds.value && data.manutds.value.Tags, true),
      ...additionalAnalytics(isMutv.additionalAnalytics),
    };
    if (type === 'audio' || (isMutv.isPodcast && type === 'video')) {
      obj.video_name = data.Headline && data.Headline.value;
      obj.card_name = data.Headline && data.Headline.value;
    }
    return obj;
  }
  return {};
};
const isFullscreEvent = (eventType) => {
  if (eventType === VIDEO_FULLSCREEN) {
    return { button_name: 'FullScreen' };
  }
};

const getVideoMediaId = (videoData, type) => {
  if (type === 'audio') {
    if (videoData.AudioID) {
      return videoData.AudioID.value;
    } else if (videoData.AudioFile && videoData.AudioFile.value) {
      return videoData.AudioFile.value.MediaId;
    }
  }
  if (videoData.LiveVideoID) {
    return videoData.LiveVideoID.value;
  } else if (videoData.VideoID) {
    return videoData.VideoID.value;
  } else if (videoData.VideoFile && videoData.VideoFile.value) {
    return videoData.VideoFile.value.MediaId;
  }
  //return '6218923932001'
};

export const playOnNextPre = (pid) => {
  const player = playerObjects.find((player) => player.id_ === pid);
  try {
    if (player && player.paused()) {
      player.play();
      if (player.customEndscreenModal) {
        player.customEndscreenModal.close();
      }
    }
    if (player && player.ima3 && player.ima3.adPlayer) {
      player.ima3.adPlayer.play();
    }
  } catch (error) {
    console.log('error', error);
  }
};

export const pauseOnNextPrev = (pid) => {
  playerObjects.forEach((player) => {
    try {
      const playerId = player.id_;
      if (player && !player.paused() && !player.isFullscreen()) {
        player.pause();
      }
      if (player && playerId == pid) {
        player.pause();
      }
      if (player && player.ima3 && player.ima3.adPlayer) {
        player.ima3.adPlayer.pause();
      }
    } catch (error) {}
  });
};
const handleAudioCss = (addCss, type) => {
  if (type === 'audio') {
    const backButton =
      typeof document !== 'undefined' && document && document.getElementById('backButton');
    const forwardButton =
      typeof document !== 'undefined' && document && document.getElementById('forwardButton');

    if (backButton && forwardButton) {
      if (addCss) {
        backButton.style.display = 'none';
        forwardButton.style.display = 'none';
      } else {
        backButton.removeAttribute('style');
        forwardButton.removeAttribute('style');
      }
    }
  }
};

const setContentAccessState = (videoData) => {
  const ContentAccess = videoData && videoData.ContentAccess && videoData.ContentAccess.value;
  const ContentType = videoData && videoData.ContentType && videoData.ContentType.value;
  const manutds = videoData && videoData.manutds;
  const tags = !ContentAccess && createTagArr(manutds);
  return getContentAccessLocal(ContentAccess, ContentType, tags);
};
export default Brightcove;
