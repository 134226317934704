/**
 *  © 2023 -2024 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useEffect } from 'react';
import { Placeholder, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import ErrorBoundary from '../Common/ErrorBoundary';
import LazyLoad from 'react-lazyload';
import ImgCrop from '../Common/ImgCrop';
import { Hero_Slider_Auto, THEMED_CAROUSEL } from '../../constants/containerConstants';
import Stats from '../Stats';
import Transform from '../../data-transform';
import {
  HomePageHeroCarouselVarientDark,
  HomePageHeroCarouselVarientLight,
  SUPPORTED_STATS_CONTENT_TYPE,
} from '../../constants/globalConstants';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import moment from 'moment';
import HeroSliderAutomation from './HeroSliderAutomation';
import {
  checkDevice,
  handleTouchStart,
  swiperProgress,
  swiperTouchStart,
  swiperSetTransition,
  defaultSwiperAnimTime,
} from '../../utils/utils';

const HeroSliderAuto = (props) => {
  const [isExpEditor, setIsExpEditor] = useState(false);
  useEffect(() => {
    setIsExpEditor(isExperienceEditorActive());
  }, []);
  const [isTouchDevice, setIsTouchDevice] = useState(true);
  useEffect(() => {
    setIsTouchDevice(checkDevice());
  }, []);

  const [currentActiveSlide, setCurrentActiveSlide] = useState(0);

  const varientClass = () => {
    if (
      props.fields &&
      props.fields.Skin &&
      props.fields.Skin.value &&
      props.fields.Skin.value === HomePageHeroCarouselVarientLight
    ) {
      return `carousal-${HomePageHeroCarouselVarientLight.toLowerCase()}`;
    } else {
      return `carousal-${HomePageHeroCarouselVarientDark.toLowerCase()}`;
    }
  };
  const bgnCTA = () => {
    if (
      props.fields &&
      props.fields.Image &&
      props.fields.Image.value &&
      (!props.fields.CTATitle || !props.fields.CTATitle.value)
    ) {
      return 'bg-ncta';
    } else {
      return '';
    }
  };
  const isCurated = () => {
    if (isExpEditor && props.fields && (!props.fields.IsAuto || !props.fields.IsAuto.value)) {
      return 'curated';
    } else {
      return '';
    }
  };
  const renderExpEditior = () => {
    if (
      props.fields &&
      props.fields.CurateFromSelectCardsField &&
      props.fields.CurateFromSelectCardsField.value
    ) {
      return (
        <div className="carousal__info">
          <b>
            Slider AutomationMode : Cards will appear from SelectCards field <br />
          </b>
        </div>
      );
    } else {
      return <div className="carousal__info">Slider CurationMode : Add Slider Components</div>;
    }
  };

  const sliderArray = props?.rendering?.fields?.SelectCards;

  const isSwiperEnableAnimation = props?.fields?.EnableAnimation?.value;

  const sliderPlaySpeed = () => {
    if (isSwiperEnableAnimation && sliderArray && Array.isArray(sliderArray)) {
      let bgVdSlideAnim, bgImgSlideAnim;
      const bgVdSlideDuration =
        sliderArray[currentActiveSlide]?.fields?.MUTVHeroVideoBackground?.value?.duration ||
        sliderArray[currentActiveSlide]?.fields?.MUTVHeroVideoBackground?.value?.Duration;
      const bgImgSlideDuration = props?.fields?.ScrollDuration?.value;
      if (bgVdSlideDuration) {
        bgVdSlideAnim = moment.duration(bgVdSlideDuration).asMilliseconds();
      }
      if (bgImgSlideDuration) {
        const parsedDuration = parseInt(bgImgSlideDuration);
        if (!isNaN(parsedDuration)) {
          bgImgSlideAnim = parsedDuration * 1000; // Convert seconds to milliseconds
        }
      }
      return bgVdSlideAnim
        ? bgVdSlideAnim
        : bgImgSlideAnim
        ? bgImgSlideAnim
        : defaultSwiperAnimTime;
    }
  };

  const renderFromSelectCards = (item, i) => {
    if (
      item &&
      item.fields &&
      item.fields.ContentType &&
      item.fields.ContentType.value &&
      SUPPORTED_STATS_CONTENT_TYPE.includes(item.fields.ContentType.value.toLowerCase())
    ) {
      return <Stats fields={item.fields} id={item.id} isAws={false} isCarousel={true} />;
    } else {
      return (
        <Transform
          key={i}
          currentIndex={i}
          currentActiveSlide={currentActiveSlide}
          data={item}
          source="curate"
          container={Hero_Slider_Auto}
          gridClass="grid-12"
          isCarousel={true}
          nativeCard={true}
          isHeroSlider={true}
          CTATitle={props?.fields?.CTATitle?.value}
          CTATitleRegisteredUser={props?.fields?.CTATitleRegisteredUser?.value}
          CTATitleSubscribedUser={props?.fields?.CTATitleSubscribedUser?.value}
        />
      );
    }
  };

  const renderCarouselSlides = () => {
    if (
      props.fields &&
      props.fields.CurateFromSelectCardsField &&
      !props.fields.CurateFromSelectCardsField.value
    ) {
      return (
        <React.Fragment>
          <Placeholder
            name="mufc-ph-carouselslider"
            rendering={props.rendering}
            missingComponentComponent={() => {
              return null;
            }}
            render={(components) => {
              return (
                <Swiper
                  slidesPerView={1}
                  autoplay={{
                    enabled: isSwiperEnableAnimation,
                    delay: sliderPlaySpeed(),
                    disableOnInteraction: false,
                  }}
                  speed={1000}
                  watchSlidesProgress={true}
                  navigation={!isTouchDevice}
                  allowTouchMove={isTouchDevice ? true : false}
                  pagination={{ clickable: true }}
                  modules={[Navigation, Pagination, Autoplay]}
                  onActiveIndexChange={(swiper) => setCurrentActiveSlide(swiper?.activeIndex)}
                  onProgress={(swiper) => swiperProgress(swiper)}
                  onTouchStart={(swiper) => swiperTouchStart(swiper)}
                  onSetTransition={(swiper) => swiperSetTransition(swiper)}
                  onTouchMove={(Swiper) => handleTouchStart(Swiper)}
                >
                  <SwiperSlide>
                    <div className="slide-inner">
                      <div className="bg_vd__gradient"></div>
                      {components}
                    </div>
                  </SwiperSlide>
                </Swiper>
              );
            }}
            container={Hero_Slider_Auto}
            gridClass="grid-12"
            isCarousel={true}
            nativeCard={true}
          />
        </React.Fragment>
      );
    } else if (props?.fields?.CurateFromSelectCardsField?.value && sliderArray?.length > 0) {
      return (
        <React.Fragment>
          <ErrorBoundary>
            <div className="hero-carousal container">
              <div className="carousal">
                <Swiper
                  slidesPerView={1}
                  autoplay={{
                    enabled: isSwiperEnableAnimation,
                    delay: sliderPlaySpeed(),
                    disableOnInteraction: false,
                  }}
                  speed={1000}
                  watchSlidesProgress={true}
                  navigation={!isTouchDevice}
                  allowTouchMove={isTouchDevice ? true : false}
                  pagination={{ clickable: true }}
                  modules={[Navigation, Pagination, Autoplay]}
                  onActiveIndexChange={(swiper) => setCurrentActiveSlide(swiper?.activeIndex)}
                  onProgress={(swiper) => swiperProgress(swiper)}
                  onTouchStart={(swiper) => swiperTouchStart(swiper)}
                  onSetTransition={(swiper) => swiperSetTransition(swiper)}
                  onTouchMove={(Swiper) => handleTouchStart(Swiper)}
                >
                  {sliderArray.map((item, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <div className="slide-inner">
                          <div className="bg_vd__gradient"></div> {renderFromSelectCards(item, i)}{' '}
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </ErrorBoundary>
        </React.Fragment>
      );
    }
  };
  const renderCarousel = () => {
    if (
      props.fields &&
      props.fields.carousel &&
      props.fields.carousel.value &&
      props.fields.carousel.value.documentsFromSolr
    ) {
      return (
        <HeroSliderAutomation
          fields={props.fields}
          itemId={props.rendering.dataSource}
          // slidesCount={slidesCount}
        />
      );
    } else if (props.fields && props.fields.CurateFromSelectCardsField) {
      return (
        <div
          className={`heroSliderAuto heroBGVideo curated ${varientClass()} ${bgnCTA()} ${isCurated()}`}
        >
          {props.fields.Image && props.fields.Image.value && (
            <div className="mu-content">
              {props?.fields?.Image?.value?.Crops && (
                <div className="img-holder">
                  <LazyLoad once={true} offset={100}>
                    <ImgCrop
                      className={'adaptive carousal-cover'}
                      imgCrop={props?.fields?.Image?.value?.Crops}
                      alt={props?.fields?.Image?.value?.AltText}
                      title={props?.fields?.Image?.value?.AltText}
                    />
                  </LazyLoad>
                </div>
              )}
            </div>
          )}
          {isExpEditor && renderExpEditior()}
          {renderCarouselSlides()}
        </div>
      );
    }
  };
  return (
    <ErrorBoundary>
      <div className="home-row">{renderCarousel()}</div>
    </ErrorBoundary>
  );
};
export default withTranslation()(HeroSliderAuto);
