/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import SignUp from './SignUp';
import SignIn from './SignIn';
import * as track from '../../../utils/analytics';
import { errorHandler } from '../../../utils/gigyaScreen';
import { getLanguage } from '../../../utils/utils';
import { useSponsorValue } from '../../../context/sponsorContext';
import { userLoginStatus } from '../../../actions/sponsorAction';
const Login = ({ t, isMutv }) => {
  const [isListOpen, setIsListOpen] = useState(false);
  const openList = (e) => {
    let code = e.keyCode || e.which;
    if (code === 13 || e.type === 'click') {
      setIsListOpen(true);
    } else if (isListOpen && code === 40) {
      e.preventDefault();
      if (e.target && e.target.parentElement) {
        const focusEl = e.target.parentElement.querySelector('li');
        focusEl && focusEl.focus();
      }
    } else if (code === 27) {
      setIsListOpen(false);
      const elm = document.querySelector('.primary-header__wrapper .links__icon-holder');
      elm && elm.focus();
    }
  };
  const openListKeyDown = (e) => {
    let code = e.keyCode || e.which;
    if (code === 40) {
      e.preventDefault();
    }
  };

  const [{ isGigyaAvilable, userLoginDetail }, dispatch] = useSponsorValue();

  const getLoginDetail = () => {
    if (window.gigya) {
      var evalResponse = function (response) {
        if (response.errorCode == 0) {
          userLoginStatus(dispatch, response);
        } else {
          userLoginStatus(dispatch, false);
          errorHandler(response);
        }
      };
      window.gigya && window.gigya.accounts.getAccountInfo({ callback: evalResponse });
    }
  };

  const activeList = (e, btnName) => {
    const code = e.keyCode || e.which;
    const listItems = e.target && e.target.parentElement.querySelectorAll('li');
    if (code === 13) {
      e.target.querySelector('a').click();
      setIsListOpen(false);
    } else if (e && e.shiftKey && code === 9 && btnName === 'SignIn') {
      setIsListOpen(false);
    } else if (e && code === 9 && !e.shiftKey && btnName === 'SignUp') {
      setIsListOpen(false);
    } else if (code === 40) {
      e.preventDefault();
      if (listItems && listItems[0] && listItems[0].classList.contains('signIn_btn')) {
        listItems && listItems[1] && listItems[1].focus();
      }
    } else if (code === 38) {
      e.preventDefault();
      if (listItems && listItems[1] && listItems[1].classList.contains('signUp_btn')) {
        listItems && listItems[0] && listItems[0].focus();
      }
    } else if (code === 27) {
      setIsListOpen(false);
      const elm = document.querySelector('.primary-header__wrapper .links__icon-holder');
      elm && elm.focus();
    }
  };

  const trackLink = (e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: 'Profile Picture of User',
        destination_url: e && e.target && e.target.parentElement && e.target.parentElement.href,
      },
      e
    );
  };

  const userName = userLoginDetail && userLoginDetail.profile && userLoginDetail.profile.firstName;
  const userPhoto = userLoginDetail && userLoginDetail.profile && userLoginDetail.profile.photoURL;

  return (
    <React.Fragment>
      {!userLoginDetail && isGigyaAvilable && (
        <div className="home-prof" id="gigyalogin">
          <div
            className="links__icon-holder"
            tabIndex="0"
            onKeyDown={(e) => openListKeyDown(e)}
            aria-expanded={isListOpen ? 'true' : 'false'}
            aria-label={`${t('SignIn')} user ${t('SignIn')}`}
            onKeyUp={(e) => openList(e)}
            aria-controls="listbox2"
            aria-haspopup="listbox"
            role="listbox"
          >
            <span role="option" aria-hidden="true" tabIndex="-1"></span>
            <span className="user-signin">
              <i className="icon-user"></i>
              <span>{t('SignIn')}</span>
            </span>
          </div>
          <ul
            className="social-button"
            id="listbox2"
            role="listbox"
            tabIndex="-1"
            style={{ display: isListOpen && 'block' }}
          >
            <li
              className="signIn_btn"
              tabIndex="0"
              role="option"
              onKeyDown={(e) => activeList(e, 'SignIn')}
            >
              <SignIn
                setIsListOpen={setIsListOpen}
                getLoginDetail={getLoginDetail}
                isHeader="true"
                isMutv={isMutv}
              ></SignIn>
            </li>
            <li
              className="signUp_btn"
              tabIndex="0"
              role="option"
              onKeyDown={(e) => activeList(e, 'SignUp')}
            >
              <SignUp
                setIsListOpen={setIsListOpen}
                getLoginDetail={getLoginDetail}
                isHeader="true"
                isMutv={isMutv}
              ></SignUp>
            </li>
          </ul>
        </div>
      )}
      {userLoginDetail && isGigyaAvilable && (
        <div className="header-profile" id="user_image">
          <a
            href={`/${getLanguage()}/myunited`}
            onClick={(e) => trackLink(e)}
            aria-label={`${t('Hi')} ${userName}`}
            tabIndex="0"
          >
            <div
              className="home-header__profile-icon icon-avatar-profile"
              aria-hidden="true"
              tabIndex="-1"
              style={
                userPhoto && {
                  backgroundImage: `url(${userPhoto})`,
                  backgroundPosition: '0 0',
                  backgroundSize: 'cover',
                }
              }
            >
              <img
                src="//:0"
                className="visually-hidden "
                aria-hidden="true"
                id="headerprofileimg"
                alt="Profile pic "
                title="Profile pic "
              />
            </div>
            <span className="loggedin" tabIndex="-1" aria-hidden="true">
              {t('Hi')} {userName}
            </span>
          </a>
        </div>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(Login);
