/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_FAVPLAYER,
  SET_FAVPLAYER,
  ERROR_FAVPLAYER,
  FETCH_ALLPLAYER,
  SET_ALLPLAYER,
  SET_WOMENSPLAYER,
  ERROR_ALLPLAYER,
  FETCH_PREDICTION,
  SET_PREDICTION,
  ERROR_PREDICTION,
  FETCH_UNITEDCONFIGDATA,
  SET_UNITEDCONFIGDATA,
  ERROR_UNITEDCONFIGDATA,
  RESET_USER_FAV_PLAYER,
} from '../constants/actions/actionConstant';
import {
  PLAYER_GRID,
  WOMEN_PLAYER,
  FAV_PLAYOUR,
  MYUNITED_PREDICTION,
  MATCH_CENTER,
  UNITEDCONFIG,
} from '../constants/endpoints';
import api from '../utils/muApi';

export const fetchAllplayer = (dispatch) => {
  const route = PLAYER_GRID;
  dispatch(fetchingAllplayer());
  return api
    .get({ route })
    .then((response) => {
      dispatch(setAllplayer(response));
    })
    .catch((err) => {
      dispatch(error(err));
    });
};

export const fetchWomensPlayer = (dispatch) => {
  const route = WOMEN_PLAYER;
  return api
    .get({ route })
    .then((response) => {
      dispatch(setWomensPlayer(response));
    })
    .catch((err) => {
      dispatch(error(err));
    });
};

export const fetchingAllplayer = () => ({ type: FETCH_ALLPLAYER });
export const setAllplayer = (response) => ({ type: SET_ALLPLAYER, response });
export const setWomensPlayer = (response) => ({ type: SET_WOMENSPLAYER, response });
export const error = (response) => ({ type: ERROR_ALLPLAYER, response });
export const fetchFavplayer = (dispatch, playerTag, index, lastIndex, sponsorDispatch) => {
  const route = `${FAV_PLAYOUR}${playerTag}/${index}/${lastIndex}`;
  dispatch(fetchingFavplayer());
  return api
    .get({ route })
    .then((response) => {
      dispatch(setFavplayer(response));
      // if fav player is disabled => reset to blank in gigya (user login details)
      if (
        sponsorDispatch &&
        window &&
        window?.gigya &&
        !response?.data?.PlayerProfileResponse?.response?.numFound
      ) {
        window.gigya.accounts.setAccountInfo({
          data: { favoritePlayerID: '' },
          callback: function validateSetAccountInfo(response) {
            console.log(response);
            sponsorDispatch(resetUserFavplayer());
          },
        });
      }
    })
    .catch((err) => {
      dispatch(favError(err));
    });
};

export const fetchingFavplayer = () => ({ type: FETCH_FAVPLAYER });
export const resetUserFavplayer = () => ({ type: RESET_USER_FAV_PLAYER });
export const setFavplayer = (response) => ({ type: SET_FAVPLAYER, response });
export const favError = (response) => ({ type: ERROR_FAVPLAYER, response });

export const fetchOfficialAppData = (dispatch, userId, seasonId) => {
  const route = `${MYUNITED_PREDICTION}uid:${userId}~sid:${seasonId}`;

  dispatch(fetchingPrediction());
  return api
    .get({ route })
    .then((response) => {
      fetchMatchDayData(dispatch, response);
      return response;
    })
    .catch((err) => {
      dispatch(predictionError(err));
    });
};

export const fetchingPrediction = () => ({ type: FETCH_PREDICTION });
export const setPrediction = (response) => ({ type: SET_PREDICTION, response });
export const predictionError = (response) => ({ type: ERROR_PREDICTION, response });

export const fetchMatchDayData = (dispatch, offAppRes) => {
  const matchId =
    offAppRes.data &&
    offAppRes.data.PredictionInfo &&
    offAppRes.data.PredictionInfo.MatchPredictionArray &&
    offAppRes.data.PredictionInfo.MatchPredictionArray[0] &&
    offAppRes.data.PredictionInfo.MatchPredictionArray[0].MatchID;
  offAppRes.data.matchID = matchId;

  const route = `${MATCH_CENTER}${matchId}/0/0/all`;
  if (matchId) {
    return api
      .get({ route })
      .then((response) => {
        offAppRes.data.matchdata = response.data;
        dispatch(setPrediction(offAppRes));
      })
      .catch((err) => {
        dispatch(setPrediction(offAppRes));
      });
  } else {
    dispatch(setPrediction(offAppRes));
  }
};

export const fetchUnitedConfigData = (dispatch, status) => {
  try {
    let route = `${UNITEDCONFIG}`;
    dispatch(fetchingUnitedConfigData());
    return api
      .get({ route })
      .then((response) => {
        dispatch(setUnitedConfigData(response));
      })
      .catch((err) => {
        dispatch(unitedConfigError(err));
      });
  } catch (e) {
    console.error('Error in Screens Tags');
  }
};

export const fetchingUnitedConfigData = () => ({ type: FETCH_UNITEDCONFIGDATA });
export const setUnitedConfigData = (response) => ({ type: SET_UNITEDCONFIGDATA, response });
export const unitedConfigError = (response) => ({ type: ERROR_UNITEDCONFIGDATA, response });
