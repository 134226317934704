/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { createDestinationUrl } from '../../utils/utils';
import { MODULE, DARK_THEME } from '../../constants/globalConstants';
import Sponsor from '../Common/Sponsor';

const LeagueFilter = ({ leagueFilters, selectedYear, setShowTable, moduleName }) => {
  const filterRef = useRef(null);

  useEffect(() => {
    if (filterRef && filterRef.current) {
      setFilterInViewport();
    }
  }, [filterRef]);

  const setFilterInViewport = () => {
    if (typeof window !== 'undefined' && window.innerWidth <= 1024) {
      const elmnt = filterRef.current.parentElement;
      setTimeout(() => {
        const activeElm = filterRef.current.querySelector('li.active');
        const offesetToScroll = activeElm.offsetLeft;
        elmnt.scrollLeft = offesetToScroll;
      }, 1500);
    }
  };
  const activeLeagueFilter = (filter, index) => {
    const filterName = filter ? filter.label_t : null;
    const filterUrl = filter ? createDestinationUrl(filter.destinationurl_s) : null;
    let browserUrl = window.location.href && window.location.href.toLowerCase();
    if (browserUrl && browserUrl.indexOf('?') !== -1) {
      const splittedBrowserUrl = browserUrl.split('?');
      browserUrl = splittedBrowserUrl[0];
    }
    if (browserUrl && browserUrl.indexOf('/league-table') !== -1) {
      browserUrl = browserUrl.replace('/league-table', '');
    }
    if (filterName) {
      if (browserUrl.indexOf(selectedYear) == -1 && filterUrl !== browserUrl && index === 0) {
        setShowTable && setShowTable(filter.haschildren_b);
        return 'active';
      } else if (filterUrl === browserUrl) {
        setShowTable && setShowTable(filter.haschildren_b);
        return 'active';
      }
    }
  };

  return (
    <div className="container">
      <div className="matches__filter">
        <div className="filter__right-tab">
          <Sponsor type={MODULE} moduleName={moduleName} theme={DARK_THEME} />
        </div>
        <div className="matches__nav">
          <nav className="nav-slide" aria-label="league filter">
            <div className="slick-prev slick-arrow">
              <i className="icon-prev-2"></i>
            </div>
            <ul className="matches__mainList slide league_filters" ref={filterRef}>
              {leagueFilters &&
                leagueFilters.length > 0 &&
                leagueFilters.map((filter, index) => (
                  <li key={index} className={activeLeagueFilter(filter, index)}>
                    <a
                      href={createDestinationUrl(filter.destinationurl_s)}
                      data-track-type="link"
                      data-an-track="true"
                      aria-current={activeLeagueFilter(filter, index) === 'active' ? true : false}
                    >
                      {filter.label_t}
                    </a>
                  </li>
                ))}
            </ul>
            <div className="slick-next slick-arrow">
              <i className="icon-next-2"></i>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(LeagueFilter);
