/**
 *  © 2022-2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import ImgCrop from '../../Common/ImgCrop';
import * as track from '../../../utils/analytics';
import { getTagObjects, isApp } from '../../../utils/utils';
import { ARTICLE_DETAIL } from '../../../constants/containerConstants';
import CompetitionForm from '../../Forms/CompetitionForm';
import moment from 'moment';
import CompetitionClose from '../../Cards/Unitedcompetition/CompetitionClose';
import { GET_COMPETITION_ITEM } from '../../../constants/endpoints';
import { fetchAPIGetItem } from '../../../utils/competitionUtils';
import ErrorBoundary from '../../Common/ErrorBoundary';
import MU from '../../../constants/muConstants';

const CompetitionDetailForm = (props) => {
  const {
    fields,
    entryError,
    userId,
    entryConfirm,
    gridClass,
    sourceFromDetail,
    isModalForm,
    t,
  } = props;
  const {
    Headline,
    manutds,
    ShortHeadline,
    ContentType,
    Image,
    AuthorName,
    __Published,
    __PublishedBy,
    FormFields,
    FormImage,
  } = fields;
  const itemId = `{${manutds && manutds.value && manutds.value.Id}}`;
  const tags = getTagObjects(manutds && manutds.value && manutds.value.Tags);
  const [entryClose, setEntryClose] = useState(false);
  const [closeData, setCloseData] = useState();
  let tagsForAnalytics = '';
  if (tags && tags.length) {
    tags.map((item) => {
      tagsForAnalytics += tagsForAnalytics.length
        ? `,${item.Name}: ${item.Tag}`
        : `${item.Name}: ${item.Tag}`;
    });
  }

  // to checked competation is expire or not
  const checkExpiredCompetition = (data) => {
    if (data && data.EndDate && data.EndDate.value) {
      var expired = moment(data.EndDate.value).isBefore(moment());
      if (expired && data.EndDate.value !== '0001-01-01T00:00:00Z') {
        setEntryClose(true);
      }
    }
  };

  useEffect(() => {
    fields && checkExpiredCompetition(fields);
  });

  const getAnalyticsData = () => {
    const initialData = track.data('card');
    return {
      ...initialData,
      card_name: ShortHeadline && ShortHeadline.value,
      container_type: ARTICLE_DETAIL,
      item_id: itemId,
      content_type: ContentType && ContentType.value,
      tag: manutds && manutds.value && manutds.value.Tags,
      created_datetime: __Published && __Published.value,
      author: AuthorName && AuthorName.value,
      destination_url: manutds && manutds.value && manutds.value.DestinationUrl,
    };
  };
  useEffect(() => {
    if (entryClose) {
      const itemId = `{${manutds && manutds.value && manutds.value.Id}}`;
      const route = `${GET_COMPETITION_ITEM}${encodeURI(itemId && itemId.toUpperCase())}`;
      const getCampaignItemData = fetchAPIGetItem(route);
      getCampaignItemData
        .then((data) => {
          setCloseData(data);
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  }, [entryClose]);

  return (
    <React.Fragment>
      <div
        className="home-article-detail home-competition-detail home-competition-detail-form clearfix"
        data-article-detail={JSON.stringify({
          competition_name: Headline && Headline.value,
          author:
            (AuthorName &&
              AuthorName.fields &&
              AuthorName.fields.AuthorName &&
              AuthorName.fields.AuthorName.value) ||
            (__PublishedBy && __PublishedBy.value),
          content_type: ContentType && ContentType.value,
          created_datetime: __Published && __Published.value,
          tag: tagsForAnalytics,
          uid: userId,
        })}
      >
        {/* ---- Top Panel start ----*/}
        {
          <div
            className={`home-article-detail__top-panel ${
              entryError || entryClose ? 'top-panel-competition-close' : ''
            }`}
          >
            {FormImage && FormImage.value && FormImage.value.Crops ? (
              <div className="grid-12 ratio">
                <div className="mu-content">
                  <article className="mu-item xlarge">
                    <div className="img-holder">
                      <ImgCrop
                        imgCrop={FormImage && FormImage.value && FormImage.value.Crops}
                        alt={FormImage && FormImage.value && FormImage.value.AltText}
                        title={FormImage && FormImage.value && FormImage.value.AltText}
                        isWebpAvailable={
                          FormImage && FormImage.value && FormImage.value.isWebpAvailable
                        }
                        className={'adaptive'}
                      />
                    </div>
                  </article>
                </div>
              </div>
            ) : !entryError && !entryClose ? (
              <section>
                <div className={isApp() ? 'mu-logo-app' : 'mu-logo'}>
                  <span>
                    <img
                      className="google-play"
                      src={`${MU && MU.assetUrlResource}/Assets/images/bg/logo.png`}
                      alt="manchester united official logo"
                    />
                  </span>
                </div>
              </section>
            ) : (
              <div className="top-panel-competition-closed" style={{ height: '180px' }}></div>
            )}
          </div>
        }

        {!entryError && !entryClose && (
          <div
            className={
              FormImage && FormImage.value && FormImage.value.Crops
                ? 'home-article-detail__title__modal title-image'
                : 'home-article-detail__title__modal'
            }
            style={{
              backfaceVisibility: 'hidden',
              WebkitBackfaceVisibility: 'hidden',
            }}
          >
            {Headline && Headline.value}
          </div>
        )}

        {/* ------ Top Panel ends -------*/}

        {entryClose && closeData && (
          <div className="home-article-detail__content-panel">
            <div className="home-content-panel__details">
              <ErrorBoundary>
                <CompetitionClose
                  responseContent={closeData}
                  entryConfirm={entryConfirm}
                  manutds={manutds}
                  gridClass={gridClass}
                  sourceFromDetail={sourceFromDetail}
                />
              </ErrorBoundary>
            </div>
          </div>
        )}

        {(entryError || (FormFields && FormFields.length > 0)) && !entryClose && (
          <ErrorBoundary>
            <CompetitionForm {...props} />
          </ErrorBoundary>
        )}

        {/* - Competation Form End -----*/}

        {/* - Content Panel End -----*/}
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(CompetitionDetailForm);
