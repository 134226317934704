/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_PLAYER_STATS,
  FETCH_SEASON_STATS,
  SET_PLAYER_STATS,
  SET_SEASON_STATS,
  ERROR_PLAYER_STATS,
  ERROR_SEASON_STATS,
} from '../constants/actions/actionConstant';

export const playerDetailState = {
  status: 'fetching',
  PlayerStatsGroupList: null,
  appearances: null,
  cleanSheets: null,
  goals: null,
  isPlayerPlayingLive: null,
  isPlayerAppeared: null,
  hideMatchStats: null,
  playerPosition: null,
  playerName: null,
  isMatchLive: null,
  preloader: false,
  matchKOTime: null,
  awayTeam: null,
  homeTeam: null,
  fullname_t: null,
  hideseasonstats_b: null,
  manualoverrideseasonstats_b: null,
  optaplayerid_t: null,
  seasonstatsjson_t: null,
};

export const playerDetailReducer = (state = playerDetailState, { type, payload } = {}) => {
  switch (type) {
    case FETCH_PLAYER_STATS:
      return { ...state, status: 'fetching' };
    case SET_PLAYER_STATS:
      return { ...state, status: 'fetched', ...payload.data, preloader: true };
    case ERROR_PLAYER_STATS:
      return { ...state, status: 'error' };
    case FETCH_SEASON_STATS:
      return { ...state, status: 'fetching' };
    case SET_SEASON_STATS:
      return { ...state, status: 'fetched', ...setSeasonState(payload) };
    case ERROR_SEASON_STATS:
      return { ...state, status: 'error' };
  }
};

const setSeasonState = (payload) => {
  const { PlayerSeasonStatsResponse } = payload.data;
  let newState = {};
  if (
    PlayerSeasonStatsResponse &&
    PlayerSeasonStatsResponse.response &&
    PlayerSeasonStatsResponse.response.docs &&
    PlayerSeasonStatsResponse.response.docs[0] &&
    PlayerSeasonStatsResponse.response.docs.length > 0
  ) {
    const docs = PlayerSeasonStatsResponse.response.docs[0];
    newState.fullname_t = docs.fullname_t;
    newState.hideseasonstats_b = docs.hideseasonstats_b;
    newState.manualoverrideseasonstats_b = docs.manualoverrideseasonstats_b;
    newState.optaplayerid_t = docs.optaplayerid_t;
    newState.seasonstatsjson_t = docs.seasonstatsjson_t;
  }
  return newState;
};
