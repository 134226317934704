/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import { MatchesProvider, useMatchesValue } from '../../context/matchesContext';
import { fetchMatchFixture, fetchMatchResult } from '../../actions/matchesAction';
import MU from '../../constants/muConstants';
import Loader from '../Common/Loader';
import { withTranslation } from 'react-i18next';
import Error from '../Common/Error';
import FixtureListing from './FixtureListing';
import TimeFilter from './TimeFilter';
import { getSelectedYear } from '../../utils/matchUtils';
import { replaceAll } from '../../utils/utils';
import LeagueFilter from './LeagueFilter';
import TeamFilter from './TeamFilter';
import { noOfListingRecords } from '../../reducers/matchesReducer';
import PostponedListing from './PostponedListing';
import { useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import { analytics, data } from '../../utils/analytics';
import ErrorBoundary from '../Common/ErrorBoundary';
import { getSelectedTeamFilter } from '../../utils/matchUtils';
import { showCBS, createDestinationUrl } from '../../utils/utils';
import { useSponsorValue } from '../../context/sponsorContext';
import { useModal } from '../Common/Modal';
import loadable from '@loadable/component';
import { sortBy } from 'lodash';
const RegistrationModal = loadable(() => import('../Modals/RegistrationModal/RegistrationModal'));
let cronInterval;

const Matches = (props) => (
  <MatchesProvider>
    <MatchesContainer {...props} />
  </MatchesProvider>
);

const MatchesContainer = (props) => {
  const sYear = getSelectedYear();
  const location = useLocation();
  const calSelectedFilter = () => {
    if (location.search.includes('filter=results')) {
      return 'results';
    }
    return !MU.currentSeason || MU.currentSeason.includes(sYear) ? 'fixtures' : 'results';
  };
  const [selectedYear] = useState(sYear);
  const [selectedFilter, setSelectedFilter] = useState(calSelectedFilter());
  const [selectedFilterValue, setSelectedFilterValue] = useState(`${MU.matchFilter}~isMU:true`);
  const [selectedSubFilter, setSelectedSubFilter] = useState('united');
  const [showTable, setShowTable] = useState(true);
  const [selectedTeam] = useState(getSelectedTeamFilter());
  const { fields } = props || {};
  const { CalendarContentAccess } = fields || {};
  const [leagueFilters, setLeagueFilters] = useState([]);
  const [selectedLeague, setSelectedLeague] = useState(null);
  const [selectedSeasons, setSelectedSeasons] = useState(null);
  const [
    {
      listing,
      listingLoader,
      loadMoreListing,
      teamTypeFilters,
      filterGroups,
      status,
      resultData,
      currentIndex,
      postponedMatcheslisting,
      cronTiming,
      tables,
      isLoadMore,
    },
    dispatch,
  ] = useMatchesValue();

  useEffect(() => {
    fetchMatchData(0, 'default');
    return () => clearInterval(cronInterval);
  }, [selectedFilterValue, selectedFilter]);

  useEffect(() => {
    if (cronTiming === 15000) {
      clearInterval(cronInterval);
      setCronInterval();
    }
  }, [cronTiming]);

  const fetchMatchData = (pageIndex, actionType) => {
    clearInterval(cronInterval);
    if (!isCurrentYear() || selectedFilter === 'results') {
      fetchMatchResult(dispatch, selectedFilterValue, pageIndex, noOfListingRecords, actionType);
    } else {
      setCronInterval();
      fetchMatchFixture(dispatch, selectedFilterValue, pageIndex, noOfListingRecords, actionType);
    }
  };

  const setCronInterval = () => {
    cronInterval = setInterval(() => {
      fetchMatchFixture(dispatch, selectedFilterValue, 0, noOfListingRecords, 'cronJob');
    }, cronTiming);
  };

  const loadMore = () => {
    const pageIndex = currentIndex + noOfListingRecords;
    clearInterval(cronInterval);
    fetchMatchData(pageIndex, 'loadMore');
  };

  const isCurrentYear = () => {
    if (!MU.currentSeason || MU.currentSeason.includes(selectedYear)) {
      return true;
    }
    return false;
  };

  const updateFilterValue = (subFilterValue) => {
    if (subFilterValue && selectedFilterValue) {
      setSelectedSubFilter(subFilterValue);
      if (subFilterValue === 'all') {
        const updatedFilterValue = replaceAll(selectedFilterValue, '~isMU:true', '');
        setSelectedFilterValue(updatedFilterValue);
      } else if (subFilterValue === 'united') {
        if (selectedFilterValue.indexOf('~isMU:true') === -1) {
          setSelectedFilterValue(`${selectedFilterValue}~isMU:true`);
        }
      }
    }
  };

  const subFilterTracking = (type) => {
    let buttonName;
    if (type === 'all') {
      buttonName = 'ALL TEAMS';
    } else if (type === 'united') {
      buttonName = 'UNITED';
    }
    const anaylticsObj = data('button');
    anaylticsObj.card_name = 'NA';
    anaylticsObj.button_name = buttonName;
    anaylticsObj.tag = 'NA';
    analytics(anaylticsObj);
  };

  const selectTeamTypeFilter = (value, subFilterValue, actionType) => {
    if (value === 'fixtures' || value === 'results') {
      // //if (eventType == "keyEvent" && filterType == "teamsubFilter")
      //   $('.page-wrap').prepend( '<a class="search-blank-accessibility" href="javascript:void(0);"></a>' ).promise().done(function(){
      //   $('.search-blank-accessibility').focus();
      //   $('.search-blank-accessibility').remove();
      // });
      updateFilterValue(subFilterValue);
      setSelectedFilter(value);
    } else if (value === 'tables') {
      if (window.location.pathname && window.location.pathname.includes(selectedYear)) {
        window.location.href = window.location.origin + window.location.pathname + '/league-table';
      } else {
        window.location.href = window.location.origin + '/' + MU.language + '/matches/league-table';
      }
    }
    // tracking code
    if (actionType == 'subfilter') {
      subFilterTracking(subFilterValue);
    }
  };

  const contentAccessState = CalendarContentAccess && CalendarContentAccess.value;
  const {
    showModal: cbrShowModal,
    closeModal: cbrCloseModal,
    isModalVisible: cbrModalVisible,
  } = useModal();

  const checkSelectedTeam = () => {
    const team = teamTypeFilters.find(
      (elem) => selectedTeam === elem.pagefilter_sm[0].replace('/', '%2F')
    );
    return team;
  };

  const checkCalendarStatus = () => {
    if (selectedSeasons) {
      if (selectedSeasons.displayfixturecalendar_b) {
        if (selectedLeague) {
          if (selectedLeague.displayfixturecalendar_b) {
            const team = checkSelectedTeam();
            return team
              ? team.displayfixturecalendar_b
              : teamTypeFilters
              ? teamTypeFilters[0].displayfixturecalendar_b
              : false;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    }
  };

  const [{ isUserLogin, isUserSubscribe }] = useSponsorValue();

  const getCalendarAction = (e) => {
    e.preventDefault();
    if (showCBS({ isUserLogin, isUserSubscribe, contentAccessState })) {
      e.preventDefault();
      cbrShowModal();
    } else {
      const team = checkSelectedTeam();
      const fixtureCalendarUrl = team ? team.fixturecalendarurl_t : '';
      if (fixtureCalendarUrl) {
        window.location = createDestinationUrl(fixtureCalendarUrl);
      }
      //setShowPopUp(true);
    }
  };

  const successCallback = ({ showContent, isSubscribedContent }) => {
    if (showContent) {
      const team = checkSelectedTeam();
      const fixtureCalendarUrl = team ? team.fixturecalendarurl_t : '';
      if (fixtureCalendarUrl) {
        if (!MU.isIDMEnabled) window.location = createDestinationUrl(fixtureCalendarUrl);
        else {
          return ['successCallbck', isSubscribedContent, 'destinationUrl', fixtureCalendarUrl];
        }
      }
    }
  };

  useEffect(() => {
    if (filterGroups) {
      const timeFilterGrpId = getTimeFilterGrpId(filterGroups[0], selectedYear);
      const filters = timeFilterGrpId && getLeagueFilters(filterGroups, timeFilterGrpId);
      try {
        setLeagueFilters(sortBy(filters, '__sortorder_t'));
      } catch (e) {
        console.error('Error sorting filters : ', e);
      }
    }
  }, [filterGroups]);

  const getLeagueFilters = (filtersValue, timeGrpId) => {
    const filter =
      filtersValue && filtersValue.find((filterGroup) => filterGroup.groupValue === timeGrpId);
    return filter && filter.doclist.docs;
  };

  const getTimeFilterGrpId = (timeFilters, selectedYear) => {
    if (
      timeFilters &&
      timeFilters.doclist &&
      timeFilters.doclist.docs &&
      timeFilters.doclist.docs.length > 0
    ) {
      var timerdocs = timeFilters.doclist.docs;
      for (var i = 0; i < timerdocs.length; i++) {
        var timerDoc = timerdocs[i];
        if (timerDoc) {
          if (selectedYear && selectedYear !== 'default') {
            // get selected Year value
            if (selectedYear === timerDoc.label_t) {
              setSelectedSeasons(timerDoc);
              return timerDoc.rawitemid_s;
            }
          } else if (timerDoc.iscurrentyear_b) {
            setSelectedSeasons(timerDoc);
            return timerDoc.rawitemid_s;
          }
        }
      }
    }
  };

  useEffect(() => {
    if (leagueFilters && leagueFilters.length > 0) {
      leagueFilters.map((filter, index) => {
        const filterUrl = filter ? createDestinationUrl(filter.destinationurl_s) : null;
        let browserUrl = window.location.href && window.location.href.toLowerCase();
        if (browserUrl.indexOf(selectedYear) === -1 && filterUrl !== browserUrl && index === 0) {
          setSelectedLeague(filter);
        } else if (filterUrl === browserUrl) {
          setSelectedLeague(filter);
        }
      });
    }
  }, [leagueFilters]);

  return (
    <>
      <div data-module="matches">
        <div className="main">
          <div className="matches-container">
            {/* {FilterComopnent} */}
            {filterGroups && (
              <ErrorBoundary>
                <LeagueFilter
                  leagueFilters={leagueFilters}
                  selectedYear={selectedYear}
                  setShowTable={setShowTable}
                  moduleName="matchpage"
                />
              </ErrorBoundary>
            )}
            {teamTypeFilters && (
              <ErrorBoundary>
                <TeamFilter
                  isCurrentYear={isCurrentYear}
                  selectedFilter={selectedFilter}
                  selectedLeague={selectedLeague}
                  selectedYear={selectedYear}
                  teamTypeFilters={teamTypeFilters}
                  selectTeamTypeFilter={selectTeamTypeFilter}
                  selectedSubFilter={selectedSubFilter}
                  showTable={showTable}
                  checkCalendarStatus={checkCalendarStatus}
                  getCalendarAction={getCalendarAction}
                  props={props}
                />
              </ErrorBoundary>
            )}
            <div className="container">
              <div className="matches-wrapper">
                <div className="matches-component">
                  {/* {ListingComonents} */}
                  {listing && <FixtureListing listing={listing} selectedFilter={selectedFilter} />}
                  {postponedMatcheslisting && (
                    <PostponedListing
                      listing={postponedMatcheslisting}
                      selectedFilter={selectedFilter}
                    />
                  )}
                  {listingLoader && (
                    <Loader className={isLoadMore ? 'loader-fixture' : 'loader-container'} />
                  )}
                  <div className="btn-wrapper">
                    {!listingLoader && loadMoreListing && status !== 'error' && (
                      <div className="white-btn-skew" onClick={() => loadMore()}>
                        <a href="#" onClick={(e) => e.preventDefault()}>
                          <span>{props.t('loadmore')}</span>
                        </a>
                      </div>
                    )}
                  </div>
                  {status === 'error' && <Error onClick={() => console.log('clicked')} />}
                  {selectedFilter === 'results' && !listing && !listingLoader && (
                    <p className="matches-note none-note">
                      {props.t('Therearecurrentlynoresultsavailable')}
                    </p>
                  )}
                  {selectedFilter === 'fixtures' && !listing && !listingLoader && (
                    <p className="matches-note none-note">
                      {props.t('Therearecurrentlynofixturesavailabl')}
                    </p>
                  )}
                  {selectedFilter === 'fixtures' && listing && (
                    <p className="matches-note">
                      <i className="icon-information"></i>
                      {props.t('NoteAllfixturesaresubjecttochange')}
                    </p>
                  )}
                  {filterGroups && (
                    <ErrorBoundary>
                      <TimeFilter timeFilters={filterGroups[0]} selectedYear={selectedYear} />
                    </ErrorBoundary>
                  )}
                </div>
                <Sidebar
                  selectedLeague={selectedLeague}
                  resultData={resultData}
                  tablesData={tables}
                  teamTypeFilters={teamTypeFilters}
                  checkCalendarStatus={checkCalendarStatus}
                  getCalendarAction={getCalendarAction}
                  props={props}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {cbrModalVisible && (
        <ErrorBoundary>
          <RegistrationModal
            closeModal={cbrCloseModal}
            successCallback={successCallback}
            fallback={<Loader />}
            previousActiveElement={document.activeElement}
            accessType={contentAccessState}
          />
        </ErrorBoundary>
      )}
    </>
  );
};

export default withTranslation()(Matches);
