/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_MY_LIST_DATA,
  SET_MY_LIST_DATA,
  ERROR_MY_LIST_DATA,
  SET_MY_LIST_REPORT,
  ERROR_MY_LIST_REPORT,
  SHOW_MY_LIST,
  SET_SHOW_MY_LIST,
  ERROR_SHOW_MY_LIST,
  DELETE_CONFIRMATION,
  LOCAL_DATA,
  SET_RELOAD,
} from '../constants/actions/actionConstant';
import { GET_CONTINUE_CAROUSEL, POST_MYLIST } from '../constants/endpoints';

import api from '../utils/muApi';
import { MAX_ITEM } from '../constants/mutvConstants';
import { getPublishedDate } from '../utils/momentDate';
import { isTimeDiffAllowed, getDefaultData, deleteOldItems } from '../components/Mutv/mutvutil';
const isApiSecure = true;
export const fetchMyListData = (dispatch, userLoginDetail) => {
  const header = {
    deviceID: typeof window !== 'undefined' && window.localStorage.getItem('deviceID'),
  };
  if (userLoginDetail && userLoginDetail.UID) {
    const uid = userLoginDetail.UID;
    let dateISO = '2017-04-25T14:05:15.953Z';
    const route = `${GET_CONTINUE_CAROUSEL}${encodeURI(uid)}~lastContentDataSyncDT:${dateISO}`;
    dispatch(fetchingMyListData());
    return api
      .get({ route, header, isApiSecure, UID: uid })
      .then((response) => {
        if (
          response.data &&
          response.data.errorMessage &&
          response.data.errorMessage === "Bad Request :: User doesn't exists"
        ) {
          response.data.ContentHistoryResponse = {
            ContentHistory: [],
            UidGigya: userLoginDetail.UID,
          };
          response.data.ContentListResponse = {
            ContentList: [],
            UidGigya: userLoginDetail.UID,
          };
        }
        response.data = createDefaultData(response.data, uid);
        localStorage.setItem('serverListData', response && JSON.stringify(response.data));
        dispatch(setMyListData(response));
        return response;
      })
      .catch((err) => {
        dispatch(error(err));
        if (userLoginDetail && userLoginDetail.UID && err && err.details && err.details.response) {
          const errResponse = err.details.response;
          const statusCode =
            errResponse &&
            errResponse.data &&
            errResponse.data.error &&
            errResponse.data.error.httpStatus;
          if (statusCode === 406) {
            const response = JSON.parse(localStorage.getItem('serverListData'));
            dispatch(setMyListData({ data: response }));
          }
        }
        return err;
      });
  }
};

const createDefaultData = (data, UidGigya) => {
  if (!data.ContentHistoryResponse || !data.ContentHistoryResponse.ContentHistory) {
    data.ContentHistoryResponse = { ContentHistory: [], UidGigya };
  }
  if (!data.ContentListResponse || !data.ContentListResponse.ContentList) {
    data.ContentListResponse = {
      ContentList: [],
      UidGigya,
      TransId: data.ContentListResponse && data.ContentListResponse.TransId,
    };
  }
  return data;
};

const myListDuplicateCheck = (getResponseData, myListData, contentTypeCheck) => {
  let listArray;
  if (contentTypeCheck === 'contentList') {
    listArray =
      getResponseData &&
      getResponseData.ContentListResponse &&
      getResponseData.ContentListResponse.ContentList;
  } else if (contentTypeCheck === 'contentHistory') {
    listArray = getResponseData.ContentHistoryResponse.ContentHistory;
  }
  const output = [
    ...myListData,
    ...listArray.filter((el1) => !myListData.some((el2) => el2.ID === el1.ID)),
  ];
  if (contentTypeCheck === 'contentList') {
    getResponseData.ContentListResponse.ContentList = output;
  } else if (contentTypeCheck === 'contentHistory') {
    getResponseData.ContentHistoryResponse.ContentHistory = output;
  }
  return getResponseData;
};
export const removeDeletedItemsIncount = (getResponseData) => {
  let contentHistory = [];
  let deletedHistory = [];
  let contentList = [];
  let deletedList = [];
  if (
    getResponseData &&
    getResponseData.ContentListResponse &&
    getResponseData.ContentListResponse.ContentList &&
    getResponseData.ContentHistoryResponse &&
    getResponseData.ContentHistoryResponse.ContentHistory
  ) {
    getResponseData.ContentListResponse.ContentList.forEach((item) => {
      !item.deletedDT ? contentList.push(item) : deletedList.push(item);
    });
    getResponseData.ContentHistoryResponse.ContentHistory.forEach((item) => {
      !item.deletedDT ? contentHistory.push(item) : deletedHistory.push(item);
    });
    getResponseData.ContentListResponse.ContentList = contentList;
    getResponseData.ContentHistoryResponse.ContentHistory = contentHistory;
    return { deletedHistory, deletedList };
  } else {
    return { deletedHistory, deletedList };
  }
};

export const deleteOldRecord = (
  dispatch,
  lastResponseTime,
  myListData,
  userLoginDetail,
  getResponseData,
  type,
  actionType,
  componentName,
  setmyListButton,
  reportStatus
) => {
  if (getResponseData !== null) {
    postAPIPayload(myListData, getResponseData, type, actionType);
    const onlyTrueItem = getResponseData.ContentListResponse.ContentList.filter(
      (item) => !item.deletedDT
    );
    if (onlyTrueItem.length > MAX_ITEM) {
      getResponseData = deleteOldItems({ allData: getResponseData, isHistory: false });
    }

    if (isTimeDiffAllowed(lastResponseTime) && reportStatus !== 'fetched') {
      if (getResponseData) {
        try {
          const route = `${POST_MYLIST}`;
          const data = getResponseData;
          const UID = userLoginDetail.UID;
          api.post({ route, data, isApiSecure, UID }).then((response) => {
            if (response && response.data.message === 'success') {
              dispatch(setMyListReport(response));
              setmyListButton(!type);
            }
          });
        } catch (e) {
          console.error(e, 'error in post data');
        }
      }
    } else {
      fetchMyListData(dispatch, userLoginDetail).then((res) => {
        if (res && res.data) {
          const getResponseData = res.data;
          reportStatus = null;
          lastResponseTime = new Date();
          deleteOldRecord(
            dispatch,
            lastResponseTime,
            myListData,
            userLoginDetail,
            getResponseData,
            type,
            actionType,
            componentName,
            setmyListButton,
            reportStatus
          );
        }
      });
    }
  }
  dispatch(setConfiramtion(false));
};

const postAPIPayload = (myListData, getResponseData, type, actionType) => {
  let ContentList = [];
  if (getResponseData) {
    if (actionType === 'contentList') {
      if (!type) {
        myListData.forEach((mylist) => {
          if (mylist.deletedDT) {
            delete mylist.deletedDT;
            mylist.myList = true;
          }
          mylist.dataSyncDT = getResponseData.lastContentDataSyncDT; //sync update
        });
      } else if (type) {
        myListData.forEach((mylist) => {
          if (!mylist.deletedDT) {
            mylist.deletedDT = getPublishedDate();
            mylist.myList = false;
          }
          delete mylist.bgImageURL;
          delete mylist.contentURL;
          delete mylist.geoCode;
          delete mylist.language;
          delete mylist.title;
          delete mylist.totalDuration;
          mylist.dataSyncDT = getResponseData.lastContentDataSyncDT; //sync update
        });
      }
      if (
        getResponseData.ContentListResponse &&
        getResponseData.ContentListResponse.ContentList === undefined
      ) {
        ContentList.concat(myListData);
        getResponseData.ContentListResponse.ContentList = ContentList;
      } else {
        myListDuplicateCheck(getResponseData, myListData, actionType);
      }
    }
    delete getResponseData.lastContentDataSyncDT;
    delete getResponseData.FutureAWSRequestId;
    delete getResponseData.AwsServerUtcTime;
  }
};

const mergeLocalData = (responseData, localData, awsServerUtcTime) => {
  if ((responseData && responseData.length >= 0) || (localData && localData.length >= 0)) {
    updateSyncDateNPosition(responseData, localData, awsServerUtcTime);
    return [
      ...localData,
      ...responseData.filter((el1) => !localData.some((el2) => el2.ID === el1.ID)),
    ];
  } else return [];
};

const updateSyncDateNPosition = (responseData, localData, awsServerUtcTime) => {
  responseData &&
    localData &&
    responseData.forEach((el1) => {
      localData.forEach((el2) => {
        if (el2.playPosition && el2.ID === el1.ID) {
          if (el2.dataSyncDT < el1.dataSyncDT) {
            el2.playPosition = el1.playPosition;
          }
        }
        el2.dataSyncDT = awsServerUtcTime;
      });
    });
};

export const postMyListReport = (
  dispatch,
  lastResponseTime,
  myListData,
  userLoginDetail,
  getResponseData,
  type,
  actionType,
  reportStatus,
  componentName,
  setmyListButton
) => {
  lastResponseTime = new Date(lastResponseTime);
  const timeDiff = isTimeDiffAllowed(lastResponseTime);
  if (timeDiff && reportStatus !== 'fetched') {
    postListReportTimer(
      dispatch,
      myListData,
      getResponseData,
      type,
      actionType,
      componentName,
      setmyListButton,
      userLoginDetail
    );
  } else {
    return fetchMyListData(dispatch, userLoginDetail).then((resData) => {
      if (resData.data) {
        if (
          resData.data &&
          resData.data.errorMessage &&
          resData.data.errorMessage === "Bad Request :: User doesn't exists"
        ) {
          resData.data.ContentHistoryResponse = {
            ContentHistory: [],
            UidGigya: userLoginDetail.UID,
          };
          resData.data.ContentListResponse = {
            ContentList: [],
            UidGigya: userLoginDetail.UID,
          };
        }
        getResponseData = createDefaultData(resData.data, userLoginDetail.UID);
        postListReportTimer(
          dispatch,
          myListData,
          getResponseData,
          type,
          actionType,
          componentName,
          setmyListButton,
          userLoginDetail
        );
      } else if (userLoginDetail && userLoginDetail.UID && resData.details.response) {
        const errResponse = resData.details.response;
        const statusCode =
          errResponse &&
          errResponse.data &&
          errResponse.data.error &&
          errResponse.data.error.httpStatus;
        if (statusCode === 406) {
          setmyListButton(type);
        }
      }
    });
  }
};

const postListReportTimer = (
  dispatch,
  myListData,
  getResponseData,
  type,
  actionType,
  componentName,
  setmyListButton,
  userLoginDetail
) => {
  if (getResponseData && (getResponseData.ContentListResponse || getResponseData.ContentHistory)) {
    let totalCount = 0;
    const route = `${POST_MYLIST}`;
    const onlyTrueItem =
      getResponseData.ContentListResponse.ContentList &&
      getResponseData.ContentListResponse.ContentList.filter((item) => !item.deletedDT);
    if (!type) {
      let count = 0;
      if (onlyTrueItem && onlyTrueItem.length > 0) {
        // find unique array
        for (let i = 0; i < myListData.length; i++) {
          for (let j = 0; j < onlyTrueItem.length; j++) {
            if (myListData[i].ID === onlyTrueItem[j].ID) {
              count++;
            }
          }
        }
        totalCount = myListData.length - count + onlyTrueItem.length;
      } else {
        totalCount = myListData.length;
      }
    }
    if (totalCount > MAX_ITEM && !type) {
      dispatch(setConfiramtion(true));
    } else {
      postAPIPayload(myListData, getResponseData, type, actionType);
      const data = getResponseData;
      const UID = userLoginDetail.UID;
      return api
        .post({ route, data, isApiSecure, UID })
        .then((response) => {
          if (response && response.data.message === 'success') {
            dispatch(setMyListReport(response));
            if (componentName === 'widget') {
              setmyListButton(!type);
            } else if (componentName === 'mylist') {
              setmyListButton();
            }
          }
          return response;
        })
        .catch((err) => {
          console.log('error', err);
          dispatch(errorMyListReport(err));
        });
    }
  }
};

const checkHasLocalData = (localData) => {
  return localData &&
    localData.ContentListResponse &&
    localData.ContentListResponse.ContentList &&
    localData.ContentHistoryResponse &&
    localData.ContentHistoryResponse.ContentHistory &&
    (localData.ContentHistoryResponse.ContentHistory.length > 0 ||
      localData.ContentListResponse.ContentList.length > 0)
    ? true
    : false;
};

const sendDataToAPi = ({ dispatch, localData, userLoginDetail, getResponseData, route }) => {
  getResponseData.ContentListResponse.ContentList = mergeLocalData(
    getResponseData.ContentListResponse.ContentList,
    localData.ContentListResponse.ContentList,
    getResponseData.lastContentDataSyncDT
  );
  getResponseData.ContentHistoryResponse.ContentHistory = mergeLocalData(
    getResponseData.ContentHistoryResponse.ContentHistory,
    localData.ContentHistoryResponse.ContentHistory,
    getResponseData.lastContentDataSyncDT
  );
  const onlyTrueItem = getResponseData.ContentListResponse.ContentList.filter(
    (item) => !item.deletedDT
  );
  const onlyTrueItemHistory = getResponseData.ContentHistoryResponse.ContentHistory.filter(
    (item) => !item.deletedDT
  );
  if (onlyTrueItem.length > MAX_ITEM) {
    getResponseData = deleteOldItems({ allData: getResponseData, isHistory: false });
  }
  if (onlyTrueItemHistory.length > MAX_ITEM) {
    getResponseData = deleteOldItems({ allData: getResponseData, isHistory: true });
  }
  delete getResponseData.lastContentDataSyncDT;
  delete getResponseData.FutureAWSRequestId;
  delete getResponseData.AwsServerUtcTime;
  delete getResponseData.errorMessage;
  const data = getResponseData;
  try {
    const UID = userLoginDetail.UID;
    return api
      .post({ route, data, isApiSecure, UID })
      .then((response) => {
        dispatch(setMyListReport(response));
        setMyListData(data);
        dispatch(setReloadPage(true));
        localStorage.removeItem('localData');
      })
      .catch((err) => {
        dispatch(errorMyListReport(err));
        console.error('error');
      });
  } catch (e) {
    console.error(e, 'error in post data');
  }
};

export const postMyLocalData = ({ dispatch, localData, userLoginDetail, getResponseData }) => {
  if (checkHasLocalData(localData)) {
    const route = `${POST_MYLIST}`;
    sendDataToAPi({ dispatch, localData, userLoginDetail, getResponseData, route });
  }
};

export const fetchingMyListData = () => ({ type: FETCH_MY_LIST_DATA });
export const setMyListData = (response) => ({ type: SET_MY_LIST_DATA, response });
export const error = (response) => ({ type: ERROR_MY_LIST_DATA, response });

export const setMyListReport = (response) => ({ type: SET_MY_LIST_REPORT, response });
export const errorMyListReport = (response) => ({ type: ERROR_MY_LIST_REPORT, response });

export const setConfiramtion = (response) => ({ type: DELETE_CONFIRMATION, response });

export const setLocalData = (response) => ({ type: LOCAL_DATA, response });

export const showMyListData = () => ({ type: SHOW_MY_LIST });
export const setShowMyListData = (response) => ({ type: SET_SHOW_MY_LIST, response });
export const errorShowMyListData = (response) => ({ type: ERROR_SHOW_MY_LIST, response });

export const setReloadPage = (response) => ({ type: SET_RELOAD, response });
