/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
const Loader = ({ className, style, areaHidden, tabIndex }) => {
  const css = { ...{ zIndex: 9998 }, ...style };
  return (
    <div tabIndex={0} aria-label="wait loading process">
      <div
        className={className}
        style={css}
        aria-hidden={areaHidden && areaHidden}
        tabIndex={tabIndex && tabIndex}
      >
        <div className="mu-pageloader">
          <div className="loader-logo" />
          <div className="loader" />
        </div>
      </div>
    </div>
  );
};

Loader.defaultProps = {
  className: 'loader-container',
  style: {},
};

export default Loader;
