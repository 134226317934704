/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
const HomeContainer = (props) => {
  return (
    <div id="homePage">
      {isExperienceEditorActive() && (
        <div>
          <b>
            Section SuperHero Components <br />
          </b>
        </div>
      )}
      <div>
        <Placeholder name="mufc-ph-superherocontainer" rendering={props.rendering} />
      </div>
      {isExperienceEditorActive() && (
        <div>
          <b>
            Section Header Component <br />
          </b>
        </div>
      )}
      <Placeholder
        name="mufc-ph-headercontainer"
        rendering={props.rendering}
        missingComponentComponent={() => {
          return null;
        }}
      />
      {isExperienceEditorActive() && (
        <div>
          <b>
            PlaceHolder For HomePage Components
            <br />
          </b>
        </div>
      )}
      <div className="main-container" id="content">
        <h1 className="screenreader">Home Page</h1>
        <Placeholder
          name="mufc-ph-homecontainer"
          rendering={props.rendering}
          missingComponentComponent={() => {
            return null;
          }}
        />
        {isExperienceEditorActive() && (
          <div>
            <b>
              Section Footer Component
              <br />
            </b>
          </div>
        )}
        <Placeholder
          name="mufc-ph-footercontainer"
          rendering={props.rendering}
          missingComponentComponent={() => {
            return null;
          }}
        />
      </div>
    </div>
  );
};

export default HomeContainer;
