/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  getStatsBarData,
  getItemId,
  buildImgUrl,
  getRandomId,
  createDestinationUrl,
} from '../../utils/utils';
import StatsBar from '../Common/StatsBar';
import * as track from '../../utils/analytics';
import { formatDate } from '../../utils/momentDate';
const SearchPlayerProfile = (props) => {
  const { t, gridClass, container } = props;
  const {
    Appearances,
    Cleansheets,
    Goals,
    Position,
    ShirtNumber,
    LastName,
    FirstName,
    CardBackgroundImage,
    BirthPlace,
    BirthDate,
    AuthorName,
    ContentType,
    __Published,
    manutds,
    DebutDate,
    LatestMatchId,
  } = props.fields;
  const imageCrops =
    CardBackgroundImage && CardBackgroundImage.value && CardBackgroundImage.value.Crops;
  const itemId = getItemId(props);
  const DestinationUrl = manutds && manutds.value && manutds.value.DestinationUrl;
  const PlayerName = `${FirstName && FirstName.value} ${LastName && LastName.value || ''}`;
  const isGoalKeeper =
    Position && Position.value && Position.value.toLowerCase() === 'goalkeeper' ? true : false;
  const randomId = getRandomId();
  const trackCard = (fields, e) => {
    const initialData = track.data('card');
    track.analytics(
      {
        ...initialData,
        card_name: PlayerName,
        container_type: container,
        item_id: itemId,
        content_type: ContentType && ContentType.value,
        tag: manutds && manutds.value && manutds.value.Tags,
        created_datetime: __Published && __Published.value,
        author: AuthorName && AuthorName.value,
        destination_url: DestinationUrl,
      },
      e
    );
  };
  const validateItem = (obj) => {
    return obj.hasOwnProperty('value') &&
      obj.value !== null &&
      obj.value !== undefined &&
      obj.value.toString() !== '' &&
      obj.value > 0
      ? true
      : false;
  };
  return (
    <React.Fragment>
      <div
        className={gridClass}
        aria-label="player profile"
        onClick={(e) => trackCard(props, e)}
        data-impression={track.impression({
          card_name: PlayerName,
          content_type: ContentType && ContentType.value,
        })}
      >
        <div className="Search_PlayerProfile match-day profile child">
          <div className="player-outer-wrap">
            <div className="left_panel">
              <div className="player-img" aria-hidden="true">
                <img
                  className={'icon-avatar'}
                  src={buildImgUrl(imageCrops && imageCrops.thumb)}
                  alt=""
                  title=""
                />
              </div>
            </div>
            <div className="right_panel">
              <div className="profile__score">
                <span className="top_heading" id={randomId}>
                  {PlayerName}
                </span>
                <span className="screenreader">{t('playerprofile')}</span>
              </div>
              <div className="bio-wrap">
                <ul>
                  {ShirtNumber && validateItem(ShirtNumber) && (
                    <li>
                      <p>{t('Shirt')}</p>
                      <span className="card-val-1 card-val-2">{ShirtNumber.value}</span>
                    </li>
                  )}
                  {Appearances && validateItem(Appearances) && (
                    <li>
                      <p aria-hidden="true">{t('Apps')}</p>
                      <span className="screenreader">{t('Appearances')}</span>
                      <span className="card-val-1 card-val-2">{Appearances.value}</span>
                    </li>
                  )}
                  {!isGoalKeeper && Goals && validateItem(Goals) && (
                    <li>
                      <p>{t('Goals')} </p>
                      <span className="card-val-1 card-val-2">{Goals.value}</span>
                    </li>
                  )}
                  {isGoalKeeper && Cleansheets && validateItem(Cleansheets) && (
                    <li>
                      <p>{t('Cleansheets')}</p>
                      <span className="card-val-1 card-val-2">{Cleansheets.value}</span>
                    </li>
                  )}

                  {DebutDate && DebutDate.value && DebutDate.value !== '0001-01-01T00:00:00Z' && (
                    <li>
                      <p>{t('Debut')}</p>
                      <span className="card-val-1 card-val-2">
                        {formatDate(DebutDate.value, 'playerprofile-date')}
                      </span>
                    </li>
                  )}
                  <li>
                    <p>{t('Birthplace')}</p>
                    <span className="card-val-1 card-val-2 birthplace" aria-hidden="true">
                      {BirthPlace && BirthPlace.value ? BirthPlace.value : '-'}
                    </span>
                    <span className="screenreader">{BirthPlace && BirthPlace.value}</span>
                  </li>
                  {BirthDate && BirthDate.value && BirthDate.value !== '0001-01-01T00:00:00Z' && (
                    <li>
                      <p>{t('Birthdate')}</p>
                      <span className="card-val-1 card-val-2" aria-label={t('Dateofbirth')}>
                        {formatDate(BirthDate && BirthDate.value, 'playerprofile-date')}
                      </span>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="btn-wrapper">
              <div className="white-btn-skew">
                <a href={createDestinationUrl(DestinationUrl)} aria-describedby={randomId}>
                  <span>{t('viewprofile')}</span>
                  <i className="tag-red-arrow"></i>
                </a>
              </div>
            </div>
            <div className="match-day__info"></div>
          </div>
          <StatsBar
            classes="statsbar profile-Share-Item"
            item={getStatsBarData(props.fields)}
          ></StatsBar>
        </div>
      </div>
    </React.Fragment>
  );
};
export default withTranslation()(SearchPlayerProfile);