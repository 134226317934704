/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import * as track from '../../../utils/analytics';
const FilterDropdown = ({ changeFilter, filters, activeFilter, filterObj, seriesType, t }) => {
  const [dropdownHidden, setDropdownHidden] = useState(false);
  const [optionList, setOptionList] = useState(
    filters && filters.filter((element) => element !== activeFilter)
  );
  const Keyboard = { UP: 38, DOWN: 40, LEFT: 37, ENTER: 13, RIGHT: 39, ESCAPE: 27, TAB: 9 };
  useEffect(() => {
    window.addEventListener('click', hideDropdown);
    return () => {
      window.removeEventListener('click', hideDropdown);
    };
  }, [dropdownHidden]);

  useEffect(() => {
    setOptionList(filters && filters.filter((element) => element !== activeFilter));
  }, [activeFilter]);

  const hideDropdown = () => {
    if (dropdownHidden) {
      setDropdownHidden(false);
    }
  };

  const openDropDown = (e) => {
    e.stopPropagation();
    const code = e && (e.keyCode || e.which);
    const listItems = e.target && e.target.querySelectorAll('#exp_elem_list li');
    if (code === Keyboard.ENTER || e.type === 'click') {
      setDropdownHidden(!dropdownHidden);
    } else if (code === Keyboard.ESCAPE || code === Keyboard.TAB) {
      setDropdownHidden(false);
    } else if (code === Keyboard.DOWN) {
      listItems && listItems[0] && listItems[0].focus();
      e.preventDefault();
    }
  };

  const showFilterClick = (e, data, seasonLinkValue) => {
    changeFilter(data);
    trackCard(e, seasonLinkValue);
  };

  const trackCard = (e, seasonLink) => {
    const initialData = track.data('button');
    track.analytics(
      {
        ...initialData,
        button_name: seasonLink,
      },
      e
    );
  };

  const focusActiveEelement = (e) => {
    const filterDropdwonBar = e.target.parentElement.parentElement.parentElement;
    const options = filterDropdwonBar && filterDropdwonBar.querySelector('non-keyboard-outline');
    options &&
      options.classList &&
      options.classList.contains('non-keyboard-outline') &&
      options.classList.remove('non-keyboard-outline');
    setTimeout(() => {
      filterDropdwonBar && filterDropdwonBar.classList.add('non-keyboard-outline');
      filterDropdwonBar.focus();
    }, 100);
  };

  const focusListItem = (e, index, data) => {
    e.stopPropagation();
    const code = e && (e.keyCode || e.which);
    const listItems = document.querySelectorAll('#exp_elem_list li');
    if (code === Keyboard.DOWN) {
      e.preventDefault();
      listItems && listItems[index + 1] && listItems[index + 1].focus();
    } else if (code === Keyboard.UP) {
      e.preventDefault();
      listItems && listItems[index - 1] && listItems[index - 1].focus();
    } else if (code === Keyboard.ESCAPE || code === Keyboard.TAB) {
      dropdownHidden && setDropdownHidden(!dropdownHidden);
      focusActiveEelement(e);
    } else if (code === Keyboard.ENTER) {
      setDropdownHidden(!dropdownHidden);
      focusActiveEelement(e);
      changeFilter(data);
    }
  };

  const borderClip = () => {
    return filters.length === 1 ? 'only-button' : dropdownHidden ? '' : 'not-hover';
  };

  const filterFormat = (year) => {
    try {
      return seriesType && seriesType === 'seasonal' && year
        ? `${t('Season')} ${year.slice(2, 4)}/${year.slice(5, 7)}`
        : year;
    } catch (error) {
      return '';
    }
  };

  return (
    <React.Fragment>
      {filters && filters.length > 0 && (
        <div
          className={`season-filter_dropdown ${borderClip()}`}
          tabIndex="0"
          onClick={() => filters.length > 1 && setDropdownHidden(!dropdownHidden)}
          onKeyDown={(e) => filters.length > 1 && openDropDown(e)}
          aria-label={`Select Season ${filterFormat(filterObj[activeFilter])}`}
          aria-haspopup="listbox"
          role="listbox"
          aria-expanded={dropdownHidden}
          id="exp_elem"
        >
          <button
            aria-labelledby="exp_elem exp_button"
            aria-haspopup="listbox"
            id="exp_button"
            tabIndex="-1"
            aria-hidden="true"
            className={`${borderClip()}`}
          >
            {filterFormat(filterObj[activeFilter])}
            {filters.length > 1 && <i className="icon-mu-arrow_down"></i>}
          </button>
          <div id="exp_elem_list" aria-haspopup="listbox" aria-labelledby="exp_elem">
            <ul
              tabIndex={dropdownHidden ? 0 : -1}
              role="listbox"
              aria-labelledby="exp_elem"
              className={
                dropdownHidden ? 'filter-dropdown-wrapper' : 'hidden filter-dropdown-wrapper'
              }
            >
              {optionList.map((data, index) => (
                <li
                  key={index}
                  data-test={index}
                  id={`exp_elem_${index}`}
                  role="option"
                  tabIndex={!dropdownHidden ? 0 : -1}
                  onClick={(e) => {
                    showFilterClick(e, data, filterObj[data]);
                  }}
                  onKeyDown={(e) => {
                    focusListItem(e, index, data);
                  }}
                >
                  {filterFormat(filterObj[data])}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default withTranslation()(FilterDropdown);
