/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState, useRef } from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import Transform from '../../../data-transform';
import { inlineVideoData, inlineImageData, closestElm, getRandomId } from '../../../utils/utils';
import Table from '../Table';
import Download from '../Download';
let baseAccordion = [];
const initial = 'initial';
const dispalyContent = (fields, isContactUs, isTicketsAndEvents) => {
  baseAccordion = [];
  let dispalyShowcontent = false;
  if (
    (fields.Image1 && fields.Image1.value && fields.Image1.value.Crops) ||
    (fields.Video1 && fields.Video1.value && Object.keys(fields.Video1.value).length) ||
    (fields.Description1 && fields.Description1.value) ||
    (fields.Description && fields.Description.value) ||
    (fields['Download-Table1'] && fields['Download-Table1'].length)
  ) {
    dispalyShowcontent = true;
    baseAccordion.push(true);
  } else {
    baseAccordion.push(false);
  }
  if (
    (fields.Image2 && fields.Image2.value && fields.Image2.value.Crops) ||
    (fields.Video2 && fields.Video2.value && Object.keys(fields.Video2.value).length) ||
    (fields.Description2 && fields.Description2.value) ||
    (fields['Download-Table2'] && fields['Download-Table2'].length)
  ) {
    dispalyShowcontent = true;
    baseAccordion.push(true);
  } else {
    baseAccordion.push(false);
  }
  if (
    (fields.Image3 && fields.Image3.value && fields.Image3.value.Crops) ||
    (fields.Video3 && fields.Video3.value && Object.keys(fields.Video3.value).length) ||
    (fields.Description3 && fields.Description3.value) ||
    (fields['Download-Table3'] && fields['Download-Table3'].length)
  ) {
    if (!dispalyShowcontent) {
      dispalyShowcontent = true;
    }
    baseAccordion.push(true);
  } else {
    baseAccordion.push(false);
  }
  if (
    (fields.Image4 && fields.Image4.value && fields.Image4.value.Crops) ||
    (fields.Video4 && fields.Video4.value && Object.keys(fields.Video4.value).length) ||
    (fields.Description4 && fields.Description4.value) ||
    (fields['Download-Table4'] && fields['Download-Table4'].length)
  ) {
    if (!dispalyShowcontent) {
      dispalyShowcontent = true;
    }
    baseAccordion.push(true);
  } else {
    baseAccordion.push(false);
  }
  if (
    (fields.Image5 && fields.Image5.value && fields.Image5.value.Crops) ||
    (fields.Video5 && fields.Video5.value && Object.keys(fields.Video5.value).length) ||
    (fields.Description5 && fields.Description5.value) ||
    (fields['Download-Table5'] && fields['Download-Table5'].length)
  ) {
    if (!dispalyShowcontent) {
      dispalyShowcontent = true;
    }
    baseAccordion.push(true);
  } else {
    baseAccordion.push(false);
  }
  if (isContactUs || isTicketsAndEvents) {
    if (!dispalyShowcontent) {
      dispalyShowcontent = true;
    }
  }
  if (
    !dispalyShowcontent &&
    fields.PrimaryCTATitle &&
    fields.PrimaryCTATitle.value &&
    fields.PrimaryCTAURL &&
    fields.PrimaryCTAURL.value &&
    fields.SecondaryCTATitle &&
    fields.SecondaryCTATitle.value &&
    fields.SecondaryCTAURL &&
    fields.SecondaryCTAURL.value
  ) {
    dispalyShowcontent = true;
  }
  return dispalyShowcontent;
};
const isAccordionExpanded = (fields) => {
  let AcoordianExpanded = false;
  if (fields.AccordionExpanded.value) {
    AcoordianExpanded = fields.AccordionExpanded.value;
  } else {
    if (!fields.AccordionYes.value) {
      AcoordianExpanded = true;
    }
  }
  return AcoordianExpanded;
};
const Accordion = (props) => {
  const { t, fields, isHeroAccordion, isOldUi, isContactUs, isTicketsAndEvents } = props;
  const [activeState, setActiveState] = useState(false);
  const [setHeight, setHeightState] = useState('0px');
  const [scroll, setScroll] = useState(0);
  const bsAccordion = useRef(null);
  const content = useRef(null);
  const title = useRef(null);
  const bsWrapper = useRef(null);
  const [triggerState, setTriggerState] = useState(null);
  const isMarkedExpanded = isAccordionExpanded(fields);
  const AccordionYes = fields.AccordionYes && fields.AccordionYes.value;
  let timer;
  useEffect(() => {
    toggleAccordion(null, isMarkedExpanded, false);
    window.addEventListener('resize', resetHeight);
    return () => {
      window.removeEventListener('resize', resetHeight);
    };
  }, []);
  useEffect(() => {
    if (triggerState) {
      let timeout = 0;
      if (setHeight === initial) {
        const bsContentEl = bsAccordion && bsAccordion.current && bsAccordion.current;
        const height = bsContentEl && bsContentEl.scrollHeight;
        bsContentEl.style.maxHeight = isMarkedExpanded ? initial : height + 'px';
        timeout = 500;
      }
      setTimeout(() => {
        setActiveState(activeState === false ? true : false);
        changeTabIndex(activeState === false ? true : false);
        setTimeout(() => {}, 1000);
        props.onChange(false);
        setHeightState(
          activeState
            ? '0px'
            : `${bsAccordion && bsAccordion.current && bsAccordion.current.scrollHeight}px`
        );
      }, timeout);
    }
  }, [triggerState]);
  useEffect(() => {
    let toggleEl = title && title.current;
    if (toggleEl) {
      AccordionYes && toggleEl.addEventListener('click', triggerToggle);
    }
    return () => {
      toggleEl.removeEventListener('click', triggerToggle);
    };
  }, [title]);
  const triggerToggle = (e) => {
    AccordionYes && toggleAccordion(e, false, true);
  };
  const resetHeight = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      const accordionEl = bsAccordion && bsAccordion.current;
      const isExpanded = accordionEl && accordionEl.style.maxHeight;
      if (isExpanded !== '0px') {
        const contentEl = content && content.current;
        const newHeight = contentEl && contentEl.scrollHeight + 'px';
        accordionEl && (accordionEl.style.maxHeight = isMarkedExpanded ? initial : newHeight);
      }
    }, 100);
    return '';
  };
  const addRemoveTabIndex = (videos, anchors, activeState) => {
    const videosLen = videos && videos.length;
    const anchorsLen = anchors && anchors.length;
    const tabIndexValue = activeState ? '0' : '-1';
    if (videosLen) {
      videos.forEach((val) => {
        const tabIndexEl = val && closestElm(val, '[tabindex]');
        tabIndexEl && tabIndexEl.setAttribute('tabindex', tabIndexValue);
      });
    }
    if (anchorsLen) {
      anchors.forEach((val) => {
        val && val.setAttribute('tabindex', tabIndexValue);
      });
    }
  };
  const changeTabIndex = (activeState) => {
    const bs = bsAccordion && bsAccordion.current;
    const videos = bs && bs.querySelectorAll('.mu-item.video');
    const anchors = bs && bs.querySelectorAll('.actas a');
    if ((videos && videos.length) || (anchors && anchors.length)) {
      addRemoveTabIndex(videos, anchors, activeState);
    }
  };
  const toggleAccordion = (e, load, click) => {
    if (!load && !click) {
      changeTabIndex(false);
    }
    if (load || !AccordionYes) {
      setActiveState(true);
      props.onChange(true);
      setHeightState(initial);
    } else {
      if (click) {
        setTriggerState(getRandomId());
      }
    }
    const which = e && (e.keyCode || e.which);
    if (which === 13 || click) {
      if (!activeState) {
        content && content.current && content.current.focus();
      } else {
        title && title.current && title.current.focus();
      }
    }
    if (isOldUi) {
      const scrollPosition = document.documentElement && document.documentElement.scrollTop;
      if (!activeState) {
        setScroll(scrollPosition);
      } else {
        let elScroll = 0;
        if (!scroll) {
          let selector = bsWrapper && bsWrapper.current;
          if (isTicketsAndEvents) {
            selector = selector.closest('.container.ticketsEvents');
          }
          elScroll = selector && selector.offsetTop;
        }
        window.scroll({
          top: scroll ? scroll : elScroll,
          behavior: 'smooth',
        });
      }
    }
  };
  const newHandler = () => {
    return (
      <div className="bs_handler_wrapper">
        <div
          className="bs_handler"
          tabIndex={isHeroAccordion ? (AccordionYes ? '0' : null) : '0'}
          //onClick={(e) => AccordionYes && toggleAccordion(e, false, true)}
          //onKeyPress={(e) => AccordionYes && toggleAccordion(e, false, true)}
          ref={title}
          aria-expanded={AccordionYes ? (activeState ? 'true' : 'false') : null}
          aria-label={
            AccordionYes
              ? isHeroAccordion
                ? fields.AccordionText && fields.AccordionText.value
                : fields.Heading && fields.Heading.value
              : null
          }
          role={AccordionYes ? 'button' : null}
        >
          {!isHeroAccordion && fields.Heading && fields.Heading.value && (
            <h3 className="bs_title">
              <Text field={fields.Heading} />
            </h3>
          )}
          {AccordionYes && <i className="bs_icon" title={t('ClickHere')} />}
        </div>
      </div>
    );
  };
  const oldHandler = () => {
    return (
      <React.Fragment>
        {AccordionYes && (
          <div className="bs_handler_wrapper">
            <div
              className="bs_handler"
              tabIndex={isHeroAccordion ? (AccordionYes ? '0' : null) : '0'}
              //onClick={(e) => AccordionYes && toggleAccordion(e, false, true)}
              //onKeyPress={(e) => AccordionYes && toggleAccordion(e, false, true)}
              ref={title}
              aria-expanded={AccordionYes ? (activeState ? 'true' : 'false') : null}
              aria-label={AccordionYes ? fields.AccordionText && fields.AccordionText.value : null}
              role={AccordionYes ? 'button' : null}
            >
              {fields.AccordionText && fields.AccordionText.value && (
                <p className="bs_title">
                  <Text field={fields.AccordionText} />
                </p>
              )}
              {AccordionYes && <i className="bs_icon" title={t('ClickHere')} />}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };
  const headSection = () => {
    return (
      <React.Fragment>
        {isContactUs && fields && fields.Heading1 && fields.Heading1.value && (
          <h2 className="contact-us__title">
            <Text field={fields.Heading1} />
          </h2>
        )}
        {isContactUs && fields && fields.Summary && fields.Summary.value && (
          <div className="contact-us__block__summary">
            <p>
              <Text field={fields.Summary} />
            </p>
          </div>
        )}
        {fields && fields.Heading && fields.Heading.value && (
          <React.Fragment>
            {!isTicketsAndEvents && (
              <h3 className="">
                <Text field={fields.Heading} />
              </h3>
            )}
          </React.Fragment>
        )}
        {fields && fields.Description && fields.Description.value && (
          <div className="contact-us__block__description">
            <RichText field={fields.Description} />
          </div>
        )}
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <section
        className={`bs_accordion contact-us ${activeState ? 'active' : ''} ${
          isOldUi ? 'oldUi' : ''
        } ${isTicketsAndEvents ? 'ticketsEvents' : ''} ${!AccordionYes ? 'expended' : ''}`}
        data-container="accordion"
        ref={bsWrapper}
      >
        <div className="row">
          <div className="contact-us__block">
            {!isOldUi && newHandler()}
            {isOldUi && headSection()}
            <div
              ref={bsAccordion}
              style={{ maxHeight: `${isMarkedExpanded ? initial : setHeight}` }}
              className="bs_content"
            >
              <div
                className="contact-us__content"
                style={{
                  paddingBottom: '20px',
                  margin: isTicketsAndEvents ? '0 auto' : '14px 0 0',
                  paddingTop: isHeroAccordion ? (!AccordionYes ? '26px' : '50px') : null,
                }}
                tabIndex={activeState ? null : -1}
                aria-hidden={activeState ? null : 'true'}
                ref={content}
              >
                {isHeroAccordion && (
                  <React.Fragment>
                    <div className="hero__accordian--content">
                      {fields.Introduction && fields.Introduction.value && (
                        <p className="hero-intro">
                          <Text field={fields.Introduction} />
                        </p>
                      )}
                      {fields.Description && fields.Description.value && (
                        <p className="hero-desc">
                          <Text field={fields.Description} />
                        </p>
                      )}
                    </div>
                  </React.Fragment>
                )}
                {!isOldUi && fields.Description && fields.Description.value && (
                  <div className="contact-us__block__description">
                    <RichText field={fields.Description} />
                  </div>
                )}
                {dispalyContent(fields, isContactUs, isTicketsAndEvents) && (
                  <React.Fragment>
                    {props.children}
                    {baseAccordion.map(
                      (value, key) =>
                        value && (
                          <React.Fragment key={key}>
                            {fields[`Description${key + 1}`] &&
                              fields[`Description${key + 1}`].value && (
                                <div className="contact-us__block__description">
                                  <RichText field={fields[`Description${key + 1}`]} />
                                </div>
                              )}
                            {fields[`Video${key + 1}`] &&
                            fields[`Video${key + 1}`].value &&
                            Object.keys(fields[`Video${key + 1}`].value).length ? (
                              <div className="home-article-detail__video-panel">
                                <Transform
                                  componentName="video"
                                  gridClass="grid-7"
                                  isInlineVideo={true}
                                  tracking={false}
                                  caption={
                                    fields[`Caption${key + 1}`] && fields[`Caption${key + 1}`].value
                                  }
                                  data={inlineVideoData({
                                    crops:
                                      fields[`Image${key + 1}`] &&
                                      fields[`Image${key + 1}`].value &&
                                      fields[`Image${key + 1}`].value.Crops,
                                    video:
                                      fields[`Video${key + 1}`] && fields[`Video${key + 1}`].value,
                                  })}
                                  source="aws"
                                  playIconTabIndex={!activeState ? '-1' : '0'}
                                  hideGradient={true}
                                />
                              </div>
                            ) : (
                              fields[`Image${key + 1}`] &&
                              fields[`Image${key + 1}`].value &&
                              fields[`Image${key + 1}`].value.Crops && (
                                <div className="home-article-detail__image-panel">
                                  <Transform
                                    componentName="image"
                                    gridClass="grid-7"
                                    isInlineImage={true}
                                    tracking={false}
                                    caption={
                                      fields[`Caption${key + 1}`] &&
                                      fields[`Caption${key + 1}`].value
                                    }
                                    data={inlineImageData({
                                      crops:
                                        fields[`Image${key + 1}`] &&
                                        fields[`Image${key + 1}`].value &&
                                        fields[`Image${key + 1}`].value.Crops,
                                    })}
                                    source="aws"
                                    hideGradient={true}
                                  />
                                </div>
                              )
                            )}
                            {fields[`Download-Table${key + 1}`] &&
                            fields[`Download-Table${key + 1}`].length
                              ? fields[`Download-Table${key + 1}`].map((tableValue, tableKey) => {
                                  return (
                                    <React.Fragment key={tableKey}>
                                      {tableValue.fields && tableValue.fields.RTETable ? (
                                        <Table key={tableKey} {...tableValue} />
                                      ) : (
                                        <Download
                                          key={tableKey}
                                          {...tableValue}
                                          isAccordion={true}
                                          isAccordionExpended={activeState}
                                        />
                                      )}
                                    </React.Fragment>
                                  );
                                })
                              : null}
                          </React.Fragment>
                        )
                    )}
                    {((fields.PrimaryCTATitle &&
                      fields.PrimaryCTATitle.value &&
                      fields.PrimaryCTAURL &&
                      fields.PrimaryCTAURL.value &&
                      fields.PrimaryCTAURL.value.href) ||
                      (fields.SecondaryCTATitle &&
                        fields.SecondaryCTATitle.value &&
                        fields.SecondaryCTAURL &&
                        fields.SecondaryCTAURL.value &&
                        fields.SecondaryCTAURL.value.href)) && (
                      <div
                        className={`align-center actas ${isHeroAccordion ? 'tickets_ _cta' : ''}`}
                      >
                        {fields.PrimaryCTATitle.value &&
                          fields.PrimaryCTAURL.value &&
                          fields.PrimaryCTAURL.value.href && (
                            <div className="red-btn-skew">
                              <a
                                href={fields.PrimaryCTAURL.value.href}
                                rel="noopener noreferrer"
                                target="_blank"
                                role="button"
                              >
                                <span>{fields.PrimaryCTATitle.value}</span>
                                <i className="icon-follow-live"></i>
                              </a>
                            </div>
                          )}
                        {fields.SecondaryCTATitle.value &&
                          fields.SecondaryCTAURL.value &&
                          fields.SecondaryCTAURL.value.href && (
                            <div className="white-btn-skew">
                              <a
                                href={fields.SecondaryCTAURL.value.href}
                                rel="noopener noreferrer"
                                target="_blank"
                                role="button"
                              >
                                <span>{fields.SecondaryCTATitle.value}</span>
                                <i className="tag-red-arrow"></i>
                              </a>
                            </div>
                          )}
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
            {isOldUi && oldHandler()}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
Accordion.defaultProps = {
  isHeroAccordion: false,
  isOldUi: false,
  isContactUs: false,
  isTicketsAndEvents: false,
  onChange: () => {},
};
export default withTranslation()(React.memo(Accordion));
