/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import MU from '../../../constants/muConstants';
import LazyLoad from 'react-lazyload';
import ImgCrop from '../../Common/ImgCrop';
import * as track from '../../../utils/analytics';
const PromoBox = (props) => {
  const { Heading, Summary, Image, CTATitle, CTAUrl } = props.fields;
  const buildImgAssetUrl = function (imgUrl) {
    const baseurl = MU.assetUrl;
    return `${baseurl}${imgUrl}`;
  };
  const trackLink = (data, e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: CTATitle && CTATitle.value,
        destination_url: CTAUrl && CTAUrl.value && CTAUrl.value.href,
      },
      e
    );
  };
  return (
    <React.Fragment>
      <div className="container">
        <div className="grid-12 promo-box">
          <div className="box-content">
            <div className="img-holder promobox-image">
              {Image && Image.value && Image.value.Crops && (
                <LazyLoad once={true} offset={100}>
                  <ImgCrop
                    imgCrop={Image.value.Crops}
                    alt={Image.AltText}
                    title={Image.AltText}
                    adaptiveClass="adaptive"
                    isWebpAvailable={Image.value.isWebpAvailable}
                  ></ImgCrop>
                </LazyLoad>
              )}
            </div>
            <div className="mu-item__info">
              {Heading && Heading.value && (
                <h2 className="mu-item__title">
                  <Text field={Heading} />
                </h2>
              )}
              {Summary && Summary.value && (
                <p className="mu-item__teasure">
                  <Text field={Summary} />
                </p>
              )}
              {CTATitle && CTATitle.value && (
                <div className="red-btn-skew">
                  <a
                    href={CTAUrl && CTAUrl.value && CTAUrl.value.href}
                    role="button"
                    onClick={(e) => trackLink({}, e)}
                  >
                    <span>
                      <Text field={CTATitle} />
                    </span>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default PromoBox;
