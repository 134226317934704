/**
 *  © 2022-2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { Fragment, useState, useEffect } from 'react';
import LazyLoad from 'react-lazyload';
import ImgCrop from '../../Common/ImgCrop';
import Sponsor from '../../Common/Sponsor';
import { withTranslation } from 'react-i18next';
import StatsBar from '../../Common/StatsBar';
import { useModal } from '../../Common/Modal';
import ErrorBoundary from '../../Common/ErrorBoundary';
import AccessBadge from '../../Common/AccessBadge';
import Loader from '../../Common/Loader';
import moment from 'moment';
import * as track from '../../../utils/analytics';
import {
  ACCOUNT_SUBSCRIPTION,
  REGISTERED,
  MUAppDeepLink,
  CONTENT_TYPE,
} from '../../../constants/globalConstants';
import { GET_COMPETITIONS, GET_COMPETITION_ITEM } from '../../../constants/endpoints';
import {
  getSponsorData,
  getContentAccessLocal,
  showCBS,
  getStatsBarData,
  createDestinationUrl,
  createTagArr,
  validateCbr,
  getLoggedInStatus,
  isApp,
  isCollection,
} from '../../../utils/utils';
import { useSponsorValue } from '../../../context/sponsorContext';
import OneClickModal from '../../Modals/UnitedCompetitionModal/UnitedCompetitionModal';
import loadable from '@loadable/component';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss';
import { fetchAPIGetItem } from '../../../utils/competitionUtils';
import MU from '../../../constants/muConstants';
let competitionRslt, competitionUid;
const RegistrationModal = loadable(() =>
  import('../../Modals/RegistrationModal/RegistrationModal')
);

const Unitedcompetition = (props) => {
  const { fields, t, gridClass, isDestinationPage, container } = props;
  const {
    ContentType,
    Sponsorby,
    Image,
    FailMessage,
    Headline,
    PleaseTryAgainCTA,
    EnterCompetitionCTA,
    EntryConfirmedCTA,
    SuccessMessage,
    Teaser,
    manutds,
    ContentAccess,
    taglist_sm,
    DestinationType,
    CTATitleRegisteredUser,
    CTATitleSubscribedUser,
    __Published,
    AuthorName,
  } = fields;

  const [showPopUp, setShowPopUp] = useState(false);
  const [entryError, setEntryError] = useState(false);
  const [entryConfirm, setEntryConfirm] = useState(false);
  const [entryClose, setEntryClose] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userCompetition, setUserCompetition] = useState(null);
  const [drawerData, setDrawerData] = useState();
  const [userLoginInfo, setUserLoginInfo] = useState({});
  const [state, setState] = useState({
    status: null,
    showLoader: false,
    message: '',
    error: '',
  });

  useEffect(() => {
    fields && checkExpiredCompetition(fields);
  });

  const emptyURL = '';

  const renderSponsor = () => {
    return (
      <Sponsor
        contentType={ContentType && ContentType.value && ContentType.value}
        sponsorInfo={Sponsorby && getSponsorData(Sponsorby.fields)}
        grid={isDestinationPage ? 'grid-12' : gridClass}
        type={CONTENT_TYPE}
        author={
          Sponsorby &&
          Sponsorby.fields &&
          Sponsorby.manutds &&
          Sponsorby.manutds.value &&
          Sponsorby.manutds.value.Id
        }
        container={container}
        createdDateTime={
          Sponsorby &&
          Sponsorby.fields &&
          Sponsorby.fields.__Published &&
          Sponsorby.fields.__Published.value
        }
        tags={
          Sponsorby &&
          Sponsorby.fields &&
          Sponsorby.fields.manutds &&
          Sponsorby.fields.manutds.value &&
          Sponsorby.fields.manutds.value.Tags
        }
      ></Sponsor>
    );
  };

  const { showModal, closeModal, isModalVisible } = useModal();
  const [
    { isUserLogin, isLoginChecked, userLoginDetail, isUserSubscribe, isUserSkyId },
  ] = useSponsorValue();
  // const [contentAccessState] = useState(
  //   getContentAccessLocal(
  //     ContentAccess.value,
  //     ContentType && ContentType.value && ContentType.value
  //   )
  // );
  const contentAccessState = ContentAccess && ContentAccess.value && ContentAccess.value;
  const {
    showModal: cbrShowModal,
    closeModal: cbrCloseModal,
    isModalVisible: cbrModalVisible,
  } = useModal();

  const closeOneClickModal = () => {
    setShowPopUp(false);
  };

  // Error in get competation Response
  const competitionDataErrorCase = (err) => {
    fields && checkExpiredCompetition(fields);
    setState(() => ({
      ...state,
      showLoader: false,
      status: 'error',
      error: err,
      message: t && t('APIFailed') ? t('APIFailed') : '',
    }));
  };

  // Success in get competation Response
  const getUserCompetitionSuccess = (response) => {
    if (response && response.data) {
      setUserCompetition(response);
    } else {
      setUserCompetition(null);
    }
    setState(() => ({
      ...state,
      showLoader: false,
      status: 'fetched',
    }));
    fields && checkExpiredCompetition(fields);
  };

  // Check locally stored competation Response
  const matchLocallyStoredCompetition = (UID, competition_ID) => {
    const userCompetitionStore = localStorage.getItem('userCompetitions');
    if (
      typeof Storage !== undefined &&
      userCompetitionStore &&
      userCompetitionStore !== 'undefined'
    ) {
      const competitions = JSON.parse(userCompetitionStore);
      if (competitions) {
        competitions.forEach((users) => {
          if (users.userId && users.userId === UID) {
            users.competitions &&
              users.competitions.forEach((userCompetition) => {
                const compID =
                  userCompetition.competitionId && userCompetition.competitionId.charAt(0) === '{'
                    ? userCompetition.competitionId.slice(1, -1)
                    : userCompetition.competitionId;
                const responseID =
                  competition_ID && competition_ID.charAt(0) === '{'
                    ? competition_ID.slice(1, -1)
                    : competition_ID;
                if (compID === responseID) {
                  setEntryConfirm(true);
                }
              });
          }
        });
      }
    }
  };

  //Get sync competation Response
  const fetchCompetitionData = (UID) => {
    const route = `${GET_COMPETITIONS}?uid=${UID}`;
    if (!competitionRslt || (userLoginDetail && UID !== competitionUid)) {
      competitionUid = UID;
      competitionRslt = fetchAPIGetItem(route);
    }
    competitionRslt
      .then((data) => {
        if (
          data &&
          data.data &&
          data.data.errorMessage &&
          data.data.errorMessage === 'Campaign Failure : UID does not exist in MongoDB'
        ) {
          competitionDataErrorCase({});
        } else {
          getUserCompetitionSuccess(data);
        }
      })
      .catch((err) => {
        competitionDataErrorCase(err);
      })
      .finally(() => {
        matchLocallyStoredCompetition(UID, manutds && manutds.value && manutds.value.Id);
      });
  };

  // Once user is login call competation Response
  useEffect(() => {
    if (userLoginDetail) {
      const userID = userLoginDetail && userLoginDetail.UID;
      setUserId(userID);
      setState(() => ({
        ...state,
        showLoader: true,
        status: 'fetching',
      }));
      fetchCompetitionData(userID);
      setUserLoginInfo({
        FirstName:
          userLoginDetail &&
          userLoginDetail.profile &&
          userLoginDetail.profile.firstName &&
          userLoginDetail.profile.firstName,
        LastName:
          userLoginDetail &&
          userLoginDetail.profile &&
          userLoginDetail.profile.lastName &&
          userLoginDetail.profile.lastName,
        Email:
          userLoginDetail &&
          userLoginDetail.profile &&
          userLoginDetail.profile.email &&
          userLoginDetail.profile.email,
        MarketingConsent:
          userLoginDetail &&
          userLoginDetail.data &&
          userLoginDetail.data.marketingConsent &&
          userLoginDetail.data.marketingConsent,
      });
    }
  }, [userLoginDetail]);

  // Once get the user's competation Response then checked with ID
  useEffect(() => {
    if (userCompetition) {
      const userAPIData =
        userCompetition && userCompetition.data && userCompetition.data.CampaignGetSyncResponse;
      if (userAPIData) {
        userAPIData.forEach((elem) => {
          if (elem.UidGigya && elem.UidGigya === userId) {
            if (manutds && manutds.value && manutds.value.Id) {
              const compID =
                manutds.value.Id.charAt(0) === '{'
                  ? manutds.value.Id.slice(1, -1)
                  : manutds.value.Id;
              const elemID =
                elem.CampaignID && elem.CampaignID.charAt(0) === '{'
                  ? elem.CampaignID.slice(1, -1)
                  : elem.CampaignID;
              if (elemID === compID) {
                setEntryConfirm(true);
              }
            }
          }
        });
      }
    }
  }, [userCompetition]);

  // Fetch competation item Data
  const getCampaignItemData = () => {
    let itemid =
      manutds && manutds.value && manutds.value.Id && manutds.value.Id.charAt(0) === '{'
        ? manutds.value.Id
        : '{' + manutds.value.Id + '}';
    const route = `${GET_COMPETITION_ITEM}${encodeURI(itemid && itemid.toUpperCase())}`;
    return fetchAPIGetItem(route);
  };

  const getCbrValue = () => {
    return validateCbr({
      isUserLogin,
      isUserSubscribe,
      contentAccess: ContentAccess && ContentAccess.value,
      contentType: ContentType && ContentType.value,
      manutds,
    });
  };

  const getAnalyticsError = (competitionID, userId, Headline, loginStatus, err) => {
    const initialData = track.data('unitedcompetitionError');
    return {
      ...initialData,
      error_message: err,
      error_type: 'competitionError',
      item_id: competitionID,
      login_id: userId,
      subscription_status: loginStatus,
    };
  };

  // const trackCompetitionError = (competitionID, userId, Headline, loginStatus, err) => {
  //   track.analytics(getAnalyticsError(competitionID, userId, Headline, loginStatus, err));
  // };

  // Once hit competation CTA button
  const getCtaAction = (e) => {
    e.preventDefault();
    // App webview redirect
    if (isApp() && !isCollection()) {
      const compURL = manutds && manutds.value && manutds.value.DestinationUrl;
      if (compURL) {
        window.location.href = createDestinationUrl(compURL) + MUAppDeepLink;
      }
      return true;
    }
    // App webview redirect
    const showCbr = getCbrValue();
    trackCard(fields, e, showCbr && showCbr.accessCheck);
    if (showCBS({ isUserLogin, isUserSubscribe, contentAccessState })) {
      e.preventDefault();
      cbrShowModal();
    } else {
      setState(() => ({
        ...state,
        showLoader: true,
        status: 'fetching',
      }));
      const fetchedItemData = getCampaignItemData();
      fetchedItemData
        .then((data) => {
          setDrawerData(data);
          setState(() => ({
            ...state,
            showLoader: false,
            status: 'fetched',
          }));
          if (entryClose) {
            setShowPopUp(true);
            document.querySelector('body').classList.add('no-scroll');
          } else if (
            !entryClose &&
            DestinationType &&
            DestinationType.value &&
            DestinationType.value.toLowerCase() === 'drawer'
          ) {
            setShowPopUp(true);
            document.querySelector('body').classList.add('no-scroll');
          } else if (!entryClose && manutds && manutds.value && manutds.value.DestinationUrl) {
            window.location = createDestinationUrl(manutds.value.DestinationUrl);
          }
        })
        .catch((err) => {
          setEntryError(true);
          // trackCompetitionError(
          //   '',
          //   userId,
          //   Headline,
          //   getLoggedInStatus({
          //     isLoginChecked,
          //     isUserLogin,
          //     isUserSubscribe,
          //     isUserSkyId,
          //   }),
          //   err
          // );
          console.log('err', err);
          setState(() => ({
            ...state,
            showLoader: false,
            status: 'error',
            error: err,
            message: t && t('APIFailed') ? t('APIFailed') : '',
          }));
        });
    }
    closeModal();
  };

  // CTA button title
  const getCtaButton = () => {
    if (!isUserLogin && contentAccessState === REGISTERED) {
      return (
        CTATitleRegisteredUser &&
        CTATitleRegisteredUser.value && (
          <span className="red-btn-skew competition-entry-btn">
            {CTATitleRegisteredUser.value.toUpperCase()}
          </span>
        )
      );
    } else if (!isUserSubscribe && contentAccessState === ACCOUNT_SUBSCRIPTION) {
      return (
        CTATitleSubscribedUser &&
        CTATitleSubscribedUser.value && (
          <span className="red-btn-skew competition-entry-btn">
            {CTATitleSubscribedUser.value.toUpperCase()}
          </span>
        )
      );
    } else {
      return (
        EnterCompetitionCTA &&
        EnterCompetitionCTA.value && (
          <span className="red-btn-skew competition-entry-btn">
            {EnterCompetitionCTA.value.toUpperCase()}
          </span>
        )
      );
    }
  };
  const successCallback = ({ showContent, isSubscribedContent }) => {
    if (showContent) {
      if (!MU.isIDMEnabled) {
        const fetchedItemCallBk = getCampaignItemData();
        fetchedItemCallBk.then((data) => {
          setDrawerData(data);
          document.querySelector('body').classList.add('no-scroll');
          setShowPopUp(true);
        });
      } else {
        const destinationUrl = manutds && manutds.value && manutds.value.DestinationUrl;
        return ['successCallbck', isSubscribedContent, 'destinationUrl', destinationUrl];
      }
    }
  };

  // to checked competation is expire or not
  const checkExpiredCompetition = (data) => {
    if (data && data.EndDate && data.EndDate.value) {
      var expired = moment(data.EndDate.value).isBefore(moment());
      if (expired && data.EndDate.value !== '0001-01-01T00:00:00Z') {
        setEntryClose(true);
      }
    }
  };

  // Analytics
  const getAnalyticsData = (fields, contentStatus) => {
    const initialData = track.data('unitedcompetition');
    return {
      ...initialData,
      container_type: container,
      content_type: ContentType && ContentType.value,
      card_name: Headline && Headline.value,
      competition_name: Headline && Headline.value,
      item_id: manutds && manutds.value && manutds.value.Id,
      login_id: userId,
      subscription_status: getLoggedInStatus({
        isLoginChecked,
        isUserLogin,
        isUserSubscribe,
        isUserSkyId,
      }),
      tag: manutds && manutds.value && manutds.value.Tags,
      created_datetime: __Published && __Published.value,
      author: AuthorName && AuthorName.value,
      destination_url: manutds && manutds.value && manutds.value.DestinationUrl,
    };
  };

  const trackCard = (fields, e, contentStatus) => {
    track.analytics(getAnalyticsData(fields, contentStatus), e);
  };

  return (
    <Fragment>
      {isExperienceEditorActive() && (
        <div style={{ textAlign: 'left', height: '100px', width: '100%' }}>
          <b>
            United Competition Component
            <br />
          </b>
        </div>
      )}

      <div className={`competition-entry ratio ${gridClass}`}>
        <div
          className="competition-container"
          aria-hidden="true"
          style={{ outline: 'none' }}
          onClick={(e) => getCtaAction(e)}
        >
          <div className="mu-content clearfix">
            <article className="mu-item competition-entry">
              <div className="top-badges">
                <AccessBadge
                  contentAcessLocal={ContentAccess && ContentAccess.value}
                  contentTypeLocal={ContentType && ContentType.value && ContentType.value}
                  tagListLocal={taglist_sm && taglist_sm}
                />
              </div>
              {Sponsorby && renderSponsor()}
              <div className="img-holder">
                {Image && Image.value && Image.value.Crops && (
                  <LazyLoad once={true} offset={100}>
                    <ImgCrop
                      alt={Image.value.AltText}
                      title={Image.value.AltText}
                      imgCrop={Image.value.Crops}
                      isWebpAvailable={Image.value.isWebpAvailable}
                      singleSrc={false}
                      gridClass={gridClass}
                    />
                  </LazyLoad>
                )}
              </div>
              {
                <div className="mu-item__info">
                  {Headline && Headline.value && (
                    <h2 className="mu-item__title">{Headline.value}</h2>
                  )}
                  {!entryConfirm && !entryError && Teaser && Teaser.value && (
                    <p className="mu-item__subtitle">{Teaser.value}</p>
                  )}

                  {entryConfirm && SuccessMessage && SuccessMessage.value && (
                    <p className="mu-item__subtitle">{SuccessMessage.value}</p>
                  )}

                  {entryError && !entryConfirm && FailMessage && FailMessage.value && (
                    <p className="mu-item__subtitle">{FailMessage.value}</p>
                  )}

                  {!entryConfirm
                    ? ((PleaseTryAgainCTA && PleaseTryAgainCTA.value) ||
                        (EnterCompetitionCTA && EnterCompetitionCTA.value)) && (
                        <div className="entry-btn">
                          <a href={emptyURL} role="button" aria-label={EnterCompetitionCTA.value}>
                            <span className={entryError ? 'entry-error-btn' : 'entry-btn-wrapper'}>
                              {entryError ? PleaseTryAgainCTA.value : getCtaButton()}
                              <span className="icon-red-arrow"></span>
                              <span className="icon-cta-arrow-white"></span>
                            </span>
                          </a>
                        </div>
                      )
                    : entryConfirm &&
                      EntryConfirmedCTA &&
                      EntryConfirmedCTA.value && (
                        <div className="entry-btn">
                          <a href={emptyURL} role="button" aria-label={EntryConfirmedCTA.value}>
                            <span className="red-btn-skew">
                              <span className="icon-check-red-circle"></span>
                              <span className="icon-check-circle"></span>
                              <span>{EntryConfirmedCTA.value}</span>
                            </span>
                          </a>
                        </div>
                      )}
                </div>
              }
              {fields && (
                <StatsBar
                  item={getStatsBarData(fields)}
                  tagListLocal={createTagArr(manutds)}
                ></StatsBar>
              )}
            </article>
          </div>
        </div>
        {state.showLoader && <Loader className="loader-container" />}
      </div>
      {showPopUp && (
        <ErrorBoundary>
          <OneClickModal
            closeModal={closeOneClickModal}
            showClose={true}
            fallback={<Loader />}
            previousActiveElement={document.activeElement}
            accessType={contentAccessState}
            fields={fields}
            DrawerData={drawerData}
            gridClass={gridClass}
            showPopUp={showPopUp}
            setShowPopUp={setShowPopUp}
            entryConfirm={entryConfirm}
            setEntryConfirm={setEntryConfirm}
            entryClose={entryClose}
            setEntryError={setEntryError}
            userId={userId}
            userLoginInfo={userLoginInfo}
            sourceFromDetail={false}
          />
        </ErrorBoundary>
      )}
      {cbrModalVisible && (
        <ErrorBoundary>
          <RegistrationModal
            closeModal={cbrCloseModal}
            successCallback={successCallback}
            fallback={<Loader />}
            previousActiveElement={document.activeElement}
            accessType={contentAccessState}
          />
        </ErrorBoundary>
      )}
    </Fragment>
  );
};

export default withTranslation()(Unitedcompetition);
