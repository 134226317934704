/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import EpisodeCarouselListing from './EpisodeCarouselListing';
import ShowCarouselListing from './ShowCarouselListing';

const Show = ({ fields }) => {
  let itemId, isShowCarousel;
  if (fields && fields.ShowDetail && fields.ShowDetail.value && fields.ShowDetail.value) {
    itemId = fields.ShowDetail.value.ItemId;
    isShowCarousel = fields.ShowDetail.value.IsShowCarousel;
  }

  return (
    <React.Fragment>
      {itemId && isShowCarousel && <ShowCarouselListing itemid={itemId} />}
      {itemId && !isShowCarousel && <EpisodeCarouselListing itemid={itemId} />}
    </React.Fragment>
  );
};

export default Show;
