/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState } from 'react';
import { buildImgUrl } from '../../utils/utils';
import { replaceExtWithWebp } from '../../utils/webp';
/**
 *
 * @param {image src of image} src
 * @param {set this class name if image fails to load} onErrorClass
 * @param {image alt name} alt
 */
const ImageFallback = ({
  src,
  onErrorClass,
  alt,
  relativePath,
  title,
  onLoad,
  className,
  isWebpAvailable,
}) => {
  const [imageError, setImageError] = useState('');
  const [imgSrc, setImgSrc] = useState(replaceExtWithWebp(isWebpAvailable, src));
  const onErrorImg = () => {
    if (imgSrc && imgSrc.includes('.webp')) {
      const crop = imgSrc.replace(/\.[^/.]+$/, '.jpg');
      crop && setImgSrc(crop);
    } else {
      setImageError(onErrorClass);
    }
  };
  const getImg = (src) => {
    return src ? src : '//:0';
  };
  return (
    <img
      src={relativePath ? buildImgUrl(getImg(imgSrc)) : getImg(imgSrc)}
      alt={alt}
      title={title}
      className={`${imageError} ${className}`}
      onError={() => onErrorImg()}
      onLoad={() => onLoad()}
    />
  );
};

ImageFallback.defaultProps = {
  relativePath: true,
  onLoad: () => {},
  className: '',
  alt: '',
  title: '',
  isWebpAvailable: false,
  src: '',
};

export default ImageFallback;
