/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { checkDevice } from '../../../utils/utils';
const SubNavigation = (props) => {
  const { t, fields } = props;
  const [activeNode, setActiveNode] = useState([]);
  const navigationRef = useRef(0);
  useEffect(() => {
    let activeItemSet = [];
    let mapResult = [];
    let index = 1;
    let path = window.location.pathname.toLowerCase();
    let hasMappingFilter = false;
    SubnavigationChildrens &&
      SubnavigationChildrens.map((value, key) => {
        let navUrl = value.PageURL && value.PageURL.value && value.PageURL.value.href;
        navUrl = navUrl && navUrl.toLowerCase();
        navUrl = removeSpace(navUrl);

        if (navUrl) {
          let result = path === navUrl;
          mapResult.push(result);
          if (result) {
            hasMappingFilter = true;
          }
        }
        return null;
      });
    mapResult.map((value, key) => {
      let activeItem = null;
      if (index === 1 && !hasMappingFilter) {
        activeItem = 'active';
      }
      if (value) {
        activeItem = 'active';
      }
      index = index + 1;
      activeItemSet.push(activeItem);
      return null;
    });
    setActiveNode(activeItemSet);
  }, []);

  useEffect(() => {
    if (checkDevice() && navigationRef && navigationRef.current) {
      setFilterInViewport();
    }
  }, [navigationRef]);

  const setFilterInViewport = () => {
    const elmnt = navigationRef.current;
    setTimeout(() => {
      const activeElm = navigationRef.current.querySelector('li a.active');
      const offesetToScroll = activeElm.offsetLeft;
      elmnt.scrollLeft = offesetToScroll;
    }, 1000);
  };

  if (!fields) {
    return null;
  }
  const { SubnavigationChildrens } = fields;
  const removeSpace = (navUrl) => {
    return navUrl && navUrl.replace(/ /g, '%20');
  };
  return (
    <React.Fragment>
      {SubnavigationChildrens && SubnavigationChildrens.length && (
        <div className="tmpls-container" data-container="subnav">
          <div className="tickets">
            <div className="touch__swipe">
              <div className="touch__swipe-wrapper">
                <span className="screenreader">{t('FiltersList')}</span>
                <ul className="touch__swipe-items" ref={navigationRef}>
                  {SubnavigationChildrens.map(
                    (value, key) =>
                      value && (
                        <li className="touch__swipe-item" key={key}>
                          <a
                            aria-current={activeNode[key] === 'active' ? true : false}
                            href={value.PageURL && value.PageURL.value && value.PageURL.value.href}
                            className={activeNode[key]}
                          >
                            <i className={value.LabelIcon}></i>
                            {value.Label}
                          </a>
                        </li>
                      )
                  )}
                </ul>
              </div>
              <div className="touch__swipe-paddles">
                <button
                  className="touch__swipe-paddle touch__swipe-paddle-left"
                  aria-hidden="true"
                  disabled
                ></button>
                <button
                  className="touch__swipe-paddle touch__swipe-paddle-right"
                  aria-hidden="true"
                  disabled
                ></button>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default withTranslation()(SubNavigation);
