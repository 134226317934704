/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import { createTagList } from '../../utils/utils';
export const Quotes = (data, isAws) => {
  const sponsor = isAws
    ? data.sponsordetailinfo_s && data.sponsordetailinfo_s[0]
    : data.sponsordetailinfo_s && JSON.parse(data.sponsordetailinfo_s)[0];

  return {
    fields: {
      Author: {
        value: data.author_t,
      },
      Image: {
        value: {
          Crops: isAws
            ? data.celumimagesvariant_s &&
              data.celumimagesvariant_s.ImageCropUrl &&
              data.celumimagesvariant_s.ImageCropUrl.CropUrl
              ? data.celumimagesvariant_s.ImageCropUrl.CropUrl
              : data.imagecropurl_s
            : data.imagecropurl_s && JSON.parse(data.imagecropurl_s),
          AltText: isAws
            ? data.celumimagesvariant_s &&
              data.celumimagesvariant_s.ImageCropUrl &&
              data.celumimagesvariant_s.ImageCropUrl.AltText
            : data.alttext_s,
          isWebpAvailable: isAws
            ? data.celumimagesvariant_s &&
              data.celumimagesvariant_s.ImageCropUrl &&
              data.celumimagesvariant_s.ImageCropUrl.isWebpAvailable
            : data.iswebpavailablebool_b,
        },
      },
      QuoteValue: {
        value: data.quotevalue_t,
      },
      ShortHeadline: {
        value: data.shortheadline_t,
      },
      ContentType: {
        value: data.contenttype_t,
      },
      CardType: {
        value: data.cardtype_t,
      },
      Sponsorby: sponsor
        ? {
            fields: {
              PartnerIconDark: {
                value: {
                  Crops: {
                    img1x: sponsor.PartnerIconDark,
                  },
                },
              },
              PartnerName: {
                value: sponsor.PartnerName,
              },

              CTAURL: {
                value: {
                  href: sponsor.CTAURL,
                },
              },

              MCC_MNC: {
                value: sponsor.MCC_MNC,
              },
              PartnerIconLight: {
                value: {
                  Crops: {
                    img1x: sponsor.PartnerIconLight,
                  },
                },
              },
              PartnerText: {
                value: sponsor.PartnerText,
              },
            },
          }
        : null,
      __Published: {
        value: data.appearance_tdt || data.__published_tdt,
      },
      __PublishedBy: {
        value: data.__publishedby_t,
      },
      manutds: {
        value: {
          DestinationUrl: data.destinationurl_s,
          Author: data.author_t,
          Tags: createTagList(data.taglist_sm, data.category_s),
        },
      },
    },
    rendering: {
      dataSource: data.itemid_s,
    },
  };
};
