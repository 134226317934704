/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { isEmpty } from 'lodash';

const AMPDfp = ({ fields, width, height, slot, className }) => {
  const { manutds } = fields;
  const contentId = manutds && manutds.value && manutds.value.Id && manutds.value.Id.toLowerCase();
  const tagObj = { targeting: { 'Page-Name': 'Article-Detail', 'content-id': contentId } };

  if (manutds) {
    let tags = manutds.value && manutds.value.Tags;
    if (tags) {
      for (const [k, v] of Object.entries(tags)) {
        let key = k && k.toLowerCase();
        tagObj[key] = v.map((tagItem) => tagItem.Name);
      }
    }
  }

  return (
    <div className={`dfp-ad ${className}`}>
      <amp-ad
        width={width}
        height={height}
        aria-hidden="true"
        tabIndex="-1"
        type="doubleclick"
        data-slot={slot}
        json={!isEmpty(tagObj) ? JSON.stringify(tagObj) : ''}
      ></amp-ad>
    </div>
  );
};

export default AMPDfp;
