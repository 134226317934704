/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import { createTagList } from '../../utils/utils';
export const PlayerProfile = (data, isAws) => {
  const sponsor = isAws
    ? data.sponsordetailinfo_s && data.sponsordetailinfo_s[0]
    : data.sponsordetailinfo_s && JSON.parse(data.sponsordetailinfo_s)[0];
  return {
    fields: {
      LatestMatchId: {
        value: data.latestmatchid_t,
      },
      Appearances: {
        value: data.appearances_tl,
      },
      Cleansheets: {
        value: data.cleansheets_tl,
      },
      DebutOpposition: {
        value: data.debutopposition_t,
      },
      DebutDate: {
        value: data.debutdate_tdt,
      },
      Goals: {
        value: data.goals_tl,
      },
      Position: {
        value: data.position_t,
      },
      ShirtNumber: {
        value: data.shirtnumber_t,
      },
      LastName: {
        value: data.lastname_t,
      },
      FirstName: {
        value: data.firstname_t,
      },
      Country: {
        value: data.playercountry_s,
      },
      CardBackgroundImage: {
        value: {
          Crops: isAws
            ? data.celumimagesvariant_s &&
              data.celumimagesvariant_s.ImageCropUrl &&
              data.celumimagesvariant_s.ImageCropUrl.CropUrl
              ? data.celumimagesvariant_s.ImageCropUrl.CropUrl
              : data.imagecropurl_s
            : data.imagecropurl_s && JSON.parse(data.imagecropurl_s),
          isWebpAvailable: isAws
            ? data.celumimagesvariant_s &&
              data.celumimagesvariant_s.ImageCropUrl &&
              data.celumimagesvariant_s.ImageCropUrl.isWebpAvailable
              ? data.celumimagesvariant_s.ImageCropUrl.isWebpAvailable
              : data.iswebpavailablebool_b
            : data.iswebpavailablebool_b,
        },
      },
      BirthPlace: {
        value: data.birthplace_t,
      },
      BirthDate: {
        value: data.birthdate_tdt,
      },
      AuthorName: isAws ? data.authorname_s : data.author_t,
      ContentType: {
        value: data.contenttype_t,
      },
      CardType: {
        value: data.cardtype_t,
      },
      Sponsorby: sponsor
        ? {
            fields: {
              PartnerIconDark: {
                value: {
                  Crops: {
                    img1x: sponsor.PartnerIconDark,
                  },
                },
              },
              PartnerName: {
                value: sponsor.PartnerName,
              },

              CTAURL: {
                value: {
                  href: sponsor.CTAURL,
                },
              },

              MCC_MNC: {
                value: sponsor.MCC_MNC,
              },
              PartnerIconLight: {
                value: {
                  Crops: {
                    img1x: sponsor.PartnerIconLight,
                  },
                },
              },
              PartnerText: {
                value: sponsor.PartnerText,
              },
            },
          }
        : null,
      __Published: {
        value: data.appearance_tdt || data.__published_tdt,
      },
      __PublishedBy: {
        value: data.__publishedby_t,
      },
      manutds: {
        value: {
          DestinationUrl: data.destinationurl_s,
          Author: isAws ? data.authorname_s : data.author_t,
          Tags: createTagList(data.taglist_sm, data.category_s),
        },
      },
    },
    rendering: {
      dataSource: data.itemid_s,
    },
  };
};
