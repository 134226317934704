/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import AccessBadge from '../../Common/AccessBadge';
import * as track from '../../../utils/analytics';
import { useModal } from '../../Common/Modal';
import ErrorBoundary from '../../Common/ErrorBoundary';
import Loader from '../../Common/Loader';
import {
  getSchudleTime,
  createRecodedUrl,
  cardTrackData,
  setLiveText,
  checkHasAttachedVideo,
} from '../mutvutil';
import { getContentAccessLocal, showCBS } from '../../../utils/utils';
import { useSponsorValue } from '../../../context/sponsorContext';
import { GET_EPG_RECODEDVIDEO } from '../../../constants/endpoints';
import moment from 'moment';
import api from '../../../utils/muApi';
import { withTranslation } from 'react-i18next';
import EpgModal from './EpgModal';
import MU from '../../../constants/muConstants';
import loadable from '@loadable/component';
const RegistrationModal = loadable(() =>
  import('../../Modals/RegistrationModal/RegistrationModal')
);

const EpgCard = (props) => {
  const {
    item,
    activeIndex,
    weekDate,
    itemIndex,
    selectedDate,
    oncardClick,
    isEpgImage,
    t,
  } = props;
  const {
    schedulemetadata_t,
    scheduletype_t,
    contentaccess_t,
    contenttype_t,
    taglist_sm,
    recordedvideoitemid_t,
    startdatetime_tdt,
  } = item;
  const { showModal, closeModal, isModalVisible } = useModal();
  const [destination, setDestination] = useState(null);
  const [cssBtnClass, setCssBtnClass] = useState(false);
  const [{ isUserLogin, isLoginChecked, isUserSubscribe, isUserSkyId }] = useSponsorValue();
  const [contentAccessState] = useState(
    getContentAccessLocal(contentaccess_t, contenttype_t, taglist_sm)
  );
  const {
    showModal: cbrShowModal,
    closeModal: cbrCloseModal,
    isModalVisible: cbrModalVisible,
  } = useModal();
  useEffect(() => {
    addCssClass();
  }, []);
  const [successScrollState, setSuccessScrollState] = useState({
    x: '',
    y: '',
  });
  const epgCardClickAction = (e) => {
    setSuccessScrollState({ x: e.pageX, y: e.pageY });
    if (activeIndex === itemIndex && weekDate && weekDate[0] === selectedDate) {
      /*for current Active card only */
      if (showCBS({ isUserLogin, isUserSubscribe, contentAccessState })) {
        showModal();
      } else {
        window.scroll({ top: 0, behavior: 'smooth' });
        oncardClick(); //toShow HeroVideo above EPG
      }
      trackCard(e);
    } else {
      /* For all Epg cards except active card */
      trackCard(e);
      showModal();
    }
  };
  const getRecodedVideoDestination = () => {
    const itemId = checkHasAttachedVideo(schedulemetadata_t, scheduletype_t);
    const route = `${GET_EPG_RECODEDVIDEO}${itemId}`;
    if (itemId) {
      try {
        return api
          .get({ route })
          .then((response) => {
            const url = createRecodedUrl(response.data);
            const initialData = track.data('card');
            track.analytics(
              cardTrackData(
                initialData,
                item,
                url,
                isLoginChecked,
                isUserLogin,
                isUserSubscribe,
                isUserSkyId
              )
            );
            setDestination(createRecodedUrl(response.data));
          })
          .catch((err) => {
            console.log('error in fetching api', err);
          });
      } catch (e) {
        console.log('error in fetching api', e);
      }
    }
  };

  const trackModalButton = (btnName) => {
    const data = track.data('button');
    track.analytics({
      ...data,
      button_name: btnName,
    });
  };

  /*Calls when user click on EPG modal popup */
  const getModalCtaAction = (e, btnName, showCbr) => {
    if (showCbr) {
      e.preventDefault();
      cbrShowModal(); //show CBR modal
    }
    trackModalButton(btnName);
    closeModal(); //close custom Modal
  };
  const setActiveCard = () => {
    return activeIndex === itemIndex && weekDate && weekDate[0] === selectedDate ? true : false;
  };
  const checkFutureLive = () => {
    return item.scheduletype_t &&
      item.scheduletype_t.toLowerCase() === 'live' &&
      moment(item.enddatetime_tdt).isAfter(moment())
      ? true
      : false;
  };
  const addCssClass = () => {
    if (showCBS({ isUserLogin, isUserSubscribe, contentAccessState })) {
      setCssBtnClass(false); //To add class for spacing of button
    } else {
      setCssBtnClass(true);
    }
  };
  const trackCard = (e) => {
    const initialData = track.data('card');
    if (showCBS({ isUserLogin, isUserSubscribe, contentAccessState })) {
      track.analytics(
        cardTrackData(
          initialData,
          item,
          null,
          isLoginChecked,
          isUserLogin,
          isUserSubscribe,
          isUserSkyId
        ),
        e
      );
    } else if (checkHasAttachedVideo(schedulemetadata_t, scheduletype_t)) {
      getRecodedVideoDestination(); //get Destination url and then track
    } else {
      track.analytics(
        cardTrackData(
          initialData,
          item,
          null,
          isLoginChecked,
          isUserLogin,
          isUserSubscribe,
          isUserSkyId
        ),
        e
      );
    }
  };

  const renderWatchOrInfoButton = () => {
    if (showCBS({ isUserLogin, isUserSubscribe, contentAccessState })) {
      return null;
    } else {
      if ((item && item.recordedvideoitemid_t) || setActiveCard()) {
        if (setActiveCard()) {
          return (
            <div className="grey-btn">
              <a
                href="javascript:void(0)"
                className="content-access-btn"
                aria-describedby="headline-text"
              >
                <span>{t('Watch')}</span>
              </a>
            </div>
          );
        } else if (checkFutureLive()) {
          return (
            <div className="grey-btn">
              <a
                href="javascript:void(0)"
                className="content-access-btn"
                aria-describedby="headline-text"
              >
                <span>{t('MoreInfo')}</span>
              </a>
            </div>
          );
        } else {
          return (
            <div className="grey-btn">
              <a
                href="javascript:void(0)"
                className="content-access-btn"
                aria-describedby="headline-text"
              >
                <span>{t('Watch')}</span>
              </a>
            </div>
          );
        }
      } else {
        return (
          <div className="grey-btn">
            <a
              href="javascript:void(0)"
              className="content-access-btn"
              aria-describedby="headline-text"
            >
              <span>{t('MoreInfo')}</span>
            </a>
          </div>
        );
      }
    }
  };
  const successCallback = ({ showContent, isSubscribedContent }) => {
    if (showContent) {
      if (!MU.isIDMEnabled) {
        if (activeIndex === itemIndex && weekDate && weekDate[0] === selectedDate) {
          window.scroll({ top: 0, behavior: 'smooth' });
          oncardClick();
        } else if (
          destination &&
          (checkHasAttachedVideo(schedulemetadata_t, scheduletype_t) ||
            (scheduletype_t &&
              recordedvideoitemid_t &&
              scheduletype_t.toLowerCase() === 'live' &&
              moment(startdatetime_tdt).isBefore(moment())))
        ) {
          window.location.href = destination;
        } else {
          showModal();
        }
      } else {
        return [
          'EPGCard',
          isSubscribedContent,
          JSON.stringify(successScrollState.x),
          JSON.stringify(successScrollState.y),
        ];
      }
    }
  };

  return (
    <React.Fragment>
      {item && (
        <li
          className={setActiveCard() ? 'epg-box active' : 'epg-box'}
          item-id={item.itemid_s}
          item-access={item.contentaccess_t}
          item-starttime={item.startdatetime_tdt}
          item-endtime={item.enddatetime_tdt}
          item-scheduletype={item.scheduletype_t}
          ietm-recodeditem={item.recordedvideoitemid_t}
          key={itemIndex}
          onClick={(e) => epgCardClickAction(e)}
          data-impression={track.impression({
            card_name: item.schedulemetadata_t && item.schedulemetadata_t.Title,
            content_type: item.contenttype_t,
          })}
        >
          <div className={`${cssBtnClass ? 'epg-box__field button-space' : 'epg-box__field'}`}>
            <span className="time-text">
              {getSchudleTime(item.startdatetime_tdt)}
              {item.isYesterdayItem && ` - ${t('YDAY')}`}
            </span>
            {/* ||(item.scheduletype_t === 'Live' && moment(item.startdatetime_tdt).isAfter(moment()))) */}
            {setActiveCard() && (
              <div className="video-now-active">
                <span className="icon-video-mutv"></span>
                <span className="blink">
                  <i className="icon-live-blink"></i>
                </span>
                <span className="live-now">
                  {setLiveText(item.scheduletype_t) ? t('LiveNow') : t('OnNow')}
                </span>
              </div>
            )}
            <p id="headline-text">{item.schedulemetadata_t && item.schedulemetadata_t.Title}</p>
            <AccessBadge
              contentAcessLocal={item.contentaccess_t}
              contentTypeLocal={item.contenttype_t}
              tagListLocal={item.taglist_sm}
              withAnchor={true}
              isEPG={true}
              item={item}
            />
            {renderWatchOrInfoButton()}
          </div>
        </li>
      )}
      {isModalVisible && (
        <ErrorBoundary>
          <EpgModal
            closeModal={closeModal}
            item={item}
            getModalCtaAction={getModalCtaAction}
            isEpgImage={isEpgImage}
            destination={destination}
            fallback={<Loader />}
          ></EpgModal>
        </ErrorBoundary>
      )}
      {cbrModalVisible && (
        <ErrorBoundary>
          <RegistrationModal
            closeModal={cbrCloseModal}
            successCallback={successCallback}
            fallback={<Loader />}
            previousActiveElement={document.activeElement}
            accessType={contentAccessState}
            analyticsObj={cardTrackData(
              track.data('card'),
              item,
              null,
              isLoginChecked,
              isUserLogin,
              isUserSubscribe,
              isUserSkyId
            )}
          />
        </ErrorBoundary>
      )}
    </React.Fragment>
  );
};
export default withTranslation()(EpgCard);
