/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { getMatchDate, getMatchTime } from '../../../utils/momentDate';
import { withTranslation } from 'react-i18next';
const MatchInfo = ({ t, matchDate, cardSequence, venuename }) => {
  return (
    matchDate && (
      <p className="match-item__info--teasure">
        <span>{getMatchDate(matchDate, 'dddd DD MMMM YYYY')}</span>
        {(cardSequence === 2 || cardSequence === 3) && <span>{t('KickOff')} </span>}
        <span>
          {getMatchTime(matchDate)}
          {venuename ? `, ${venuename}` : ''}
        </span>
      </p>
    )
  );
};

export default withTranslation()(React.memo(MatchInfo));
