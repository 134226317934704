/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import * as track from '../../../utils/analytics';
import { useSponsorValue } from '../../../context/sponsorContext';
import { triggerLoginFlow } from '../../../utils/idmUtils';

const SignIn = ({ t, itemName, ishumberger, getLoginDetail, isHeader, isMutv }) => {
  const [{ isUserLogin }] = useSponsorValue();
  /**
   *
   * @param {clicked event object} e
   */
  const trackLink = (e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: !itemName ? t('LOGIN') : itemName,
        destination_url: 'javascript:void(0)',
      },
      e
    );
  };

  const showLoginModal = (e) => {
    e.preventDefault();
    trackLink(e);
    if (!isUserLogin) {
      triggerLoginFlow(getLoginDetail, isMutv);
    }
  };
  const updateAttrs = () => {
    if (isHeader) {
      return { tabIndex: '-1' };
    }
  };

  return (
    <div className={ishumberger ? 'red-btn-skew btn' : 'black-btn-skew btn'}>
      <a href="#" onClick={(e) => showLoginModal(e)} role="link" {...updateAttrs()}>
        {!itemName ? <span>{t('LOGIN')}</span> : <span>{itemName}</span>}
        <span className="screenreader">Open overlay</span>
      </a>
    </div>
  );
};

export default withTranslation()(SignIn);
