/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { getMatchDate } from '../../../utils/momentDate';
export const TimeFormatStrip = ({ eventDate }) => {
  const dateString = eventDate ? getMatchDate(eventDate, 'dddd DD MMMM YYYY HH:mm') : '';
  const arrDate = dateString && dateString.length > 0 ? dateString.split(' ') : [];
  return (
    <>
      <span className="home-content-panel__details-social__time-day">
        {arrDate && arrDate.length > 0 ? `${arrDate[0]}` : ''}
      </span>
      <span>{arrDate && arrDate.length >= 4 ? ` ${arrDate.slice(1, 4).join(' ')}` : ''}</span>
      <span>{arrDate && arrDate.length >= 5 ? ` ${arrDate[4]}` : ''}</span>
    </>
  );
};

export default TimeFormatStrip;
