/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import ImgCrop from '../../Common/ImgCrop';
import * as track from '../../../utils/analytics';
import { getItemId } from '../../../utils/utils';

const SignPost = (props) => {
  const { fields, t } = props;
  const { Title, CTATitle, CTAUrl, Image, Summary, Variant } = props.fields;
  const itemId = getItemId(props);
  const trackCard = (e) => {
    const initialData = track.data('card');
    track.analytics(
      {
        ...initialData,
        card_name: Title && Title.value,
        item_id: itemId,
        content_type: 'signpost',
        link_name: CTATitle && CTATitle.value,
        share_url: CTAUrl && CTAUrl.value && CTAUrl.value.hrefMuCustom,
        track_type: 'link',
        track_text: CTATitle && CTATitle.value,
      },
      e
    );
  };
  const isChild =
    Variant && Variant.value && Variant.value.toLowerCase() === 'child' ? 'child' : '';
  const primaryCTAUrl = CTAUrl && CTAUrl.value && CTAUrl.value.hrefMuCustom;
  const primaryCTAUrlTarget = CTAUrl && CTAUrl.value && CTAUrl.value.target;
  return (
    <section className={`tpl-sign-post skin-white ${isChild}`}>
      <a href={primaryCTAUrl} target={primaryCTAUrlTarget} onClick={(e) => trackCard(e)}>
        <div className="sign-post-tpl-common">
          <div className="sign-post-image img-holder">
            {Image && Image.value && Image.value.Crops && (
              <LazyLoad once={true} offset={100}>
                <ImgCrop
                  imgCrop={Image.value.Crops}
                  alt={Image.value.AltText}
                  title={Image.value.AltText}
                  isWebpAvailable={Image.value.isWebpAvailable}
                  singleSrc={false}
                  adaptiveClass="adaptive"
                ></ImgCrop>
              </LazyLoad>
            )}
            {isChild && Title && Title.value && (
              <h2>
                <Text field={Title} />
              </h2>
            )}
            <div className="clear-all-section"></div>
          </div>
          {!isChild && (
            <div className="sign-post-desc">
              {Title && Title.value && (
                <h2>
                  <Text field={Title} />
                </h2>
              )}
              {Summary && Summary.value && (
                <div className="sign-post-summary">
                  <RichText field={Summary} />
                </div>
              )}
              {CTATitle && CTATitle.value && (
                <div className="link-btn-white">
                  <span>
                    <Text field={CTATitle} />
                  </span>{' '}
                  <span className="screenreader">{`${props.t('about')} ${
                    Title && Title.value
                  }`}</span>
                  <i className="icon-cta-arrow"></i>
                </div>
              )}
            </div>
          )}
          {isChild && Summary && Summary.value && (
            <div className="sign-post-desc">
              <div className="sign-post-summary">
                <RichText field={Summary} />
              </div>

              {CTATitle && CTATitle.value && (
                <div className="link-btn-white">
                  <span>
                    <Text field={CTATitle} />
                  </span>{' '}
                  <span className="screenreader">{`${props.t('about')} ${
                    Title && Title.value
                  }`}</span>
                  <i className="icon-cta-arrow"></i>
                </div>
              )}
            </div>
          )}
        </div>
      </a>
    </section>
  );
};

export default withTranslation()(SignPost);
