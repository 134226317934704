/**
 *  © 2022-2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import UnitedForm from './UnitedForm';
import ImgCrop from '../../Common/ImgCrop';
import moment from 'moment';
import { createDestinationUrl, isApp, getLoggedInStatus } from '../../../utils/utils';
import { saveCompetitionResponse, fetchAPIGetItem } from '../../../utils/competitionUtils';
import ErrorBoundary from '../../Common/ErrorBoundary';
import xss from 'xss';
import {
  DataRequiredValidation,
  EmailValidation,
  MaxLengthValidation,
  MinLengthValidation,
  MultiSelectCheckBoxRequiredValidation,
  AlphaNumericValidation,
  DateValidation,
} from '../../../utils/unitedFormUtils';
import Loader from '../../Common/Loader';
import AdCard from '../../Cards/AdCard';
import { useSponsorValue } from '../../../context/sponsorContext';
import * as track from '../../../utils/analytics';
import CompetitionClose from '../../Cards/Unitedcompetition/CompetitionClose';
import { GET_COMPETITION_ITEM } from '../../../constants/endpoints';
import { monthArr } from '../../../constants/formConstants';
import { MUAppDeepLink } from '../../../constants/globalConstants';

const CompetitionForm = (props) => {
  const {
    userId,
    userLoginInfo,
    entryConfirm,
    setEntryConfirm,
    entryError,
    setEntryError,
    fields,
    userEnteredCompetition,
    retryFormDetails,
    setRetryFormDetails,
    formSuccessMsg,
    setFormSuccessMsg,
    entryClose,
    gridClass,
    sourceFromDetail,
    t,
  } = props;
  const {
    Headline,
    FormFields,
    EnterCompetitionCTA,
    ViewCompetitionDetailsCTA,
    EditEntryDetailsCTA,
    Cancel,
    EndDate,
    manutds,
    SuccessSelectCard,
    DetailedPageSuccessTitle,
    DetailedPageSuccessDescription,
    FailMessage,
    EntryFailed,
    Icon,
    Retry,
    EditScreenTitle,
    EditScreenTextBox,
    UpdateCompetitionEntry,
    PostSuccessMessage,
    PostSuccessTextBox,
    NocelebrationConfetti,
  } = fields;
  const [formDetails, setFormDetails] = useState({});
  const [formError, setFormError] = useState({});
  const [selectedData, setSelectedData] = useState({});
  const [selectedCreateDate, setSelectedCreateDate] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [userEnteredAPIData, setUserEnteredAPIData] = useState({});
  const [typeInputValue, setTypeInputValue] = useState();
  const [filledFormValues, setFilledFormValues] = useState({});
  const [closeData, setCloseData] = useState();

  const [
    { isUserLogin, isLoginChecked, userLoginDetail, isUserSubscribe, isUserSkyId },
  ] = useSponsorValue();
  const loginStatus = getLoggedInStatus({
    isLoginChecked,
    isUserLogin,
    isUserSubscribe,
    isUserSkyId,
  });

  const formEntryHandler = (type) => {
    if (isApp()) {
      // fucntion to integrate united competition in app
      if (window.MUhelp) {
        // Android code
        if (type === 'view') {
          window.MUhelp.compSuccessDetail(true);
          window.location.href = createDestinationUrl(manutds.value.DestinationUrl) + MUAppDeepLink;
        } else if (type === 'edit') {
          window.MUhelp.compSuccessDetail(false);
          setFormSuccessMsg(false);
        }
      } else if (
        // iOS code
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.postHelp
      ) {
        try {
          if (type === 'view') {
            window.webkit.messageHandlers.postHelp.postMessage('cbd');
            window.location.href =
              createDestinationUrl(manutds.value.DestinationUrl) + MUAppDeepLink;
          } else if (type === 'edit') {
            window.webkit.messageHandlers.postHelp.postMessage('cbe');
            setFormSuccessMsg(false);
          }
        } catch (err) {
          console.log('OnClickSuccessBtn iOS error');
        }
      } else {
        console.log('OnClickSuccessBtn error');
      }
    } else {
      // united competition in browsers
      if (type === 'view') {
        window.location.href = createDestinationUrl(manutds.value.DestinationUrl);
      } else if (type === 'edit') {
        setFormSuccessMsg(false);
      }
    }
  };
  useEffect(() => {
    if (userEnteredCompetition) {
      const userAPIData =
        userEnteredCompetition &&
        userEnteredCompetition.data &&
        userEnteredCompetition.data.CampaignGetSyncResponse;
      if (userAPIData) {
        userAPIData.forEach((elem) => {
          if (elem.UidGigya && elem.UidGigya === userId) {
            if (manutds && manutds.value && manutds.value.Id) {
              const compID =
                manutds.value.Id.charAt(0) === '{'
                  ? manutds.value.Id.slice(1, -1)
                  : manutds.value.Id;
              const elemID =
                elem.CampaignID && elem.CampaignID.charAt(0) === '{'
                  ? elem.CampaignID.slice(1, -1)
                  : elem.CampaignID;
              if (elemID === compID) {
                setUserEnteredAPIData(elem);
                const keys = Object.keys(elem);
                for (let i = 0; i < keys.length; i++) {
                  keys.forEach((key) => {
                    if (`FieldValue-${i}` === key) {
                      setFilledFormValues((filledFormValues) => {
                        return { ...filledFormValues, [key]: elem[key] };
                      });
                    }
                  });
                }
              }
            }
          }
        });
      }
    }
  }, [userEnteredCompetition]);

  const getAnalyticsError = (competitionID, userId, Headline, loginStatus, err) => {
    const initialData = track.data('unitedcompetitionError');
    return {
      ...initialData,
      error_message: err,
      error_type: 'competitionError',
      item_id: competitionID,
      login_id: userId,
      subscription_status: loginStatus,
    };
  };

  // const trackCompetitionError = (competitionID, userId, Headline, loginStatus, err) => {
  //   track.analytics(getAnalyticsError(competitionID, userId, Headline, loginStatus, err));
  // };

  const individualMapping = {
    DataRequiredMessage: DataRequiredValidation,
    MultiSelectCheckBoxRequiredMessage: MultiSelectCheckBoxRequiredValidation,
    MinNumberMessage: MinLengthValidation,
    MaxNumberMessage: MaxLengthValidation,
    Email: EmailValidation,
    AlphaNumericMessage: AlphaNumericValidation,
    DateValidMessage: DateValidation,
  };
  const validateFields = (value, item, keyArray) => {
    let error = false;
    for (let i = 0; i < keyArray.length; i++) {
      error = individualMapping[keyArray[i]] ? individualMapping[keyArray[i]](value, item) : false;
      if (error) {
        break;
      }
    }
    return error;
  };
  const getValidationOnSubmit = (e) => {
    try {
      let obj = {};
      let isValid = true;
      const target = e && e.target;
      const SelectField = target && target.querySelectorAll('select');
      const InputTextField = target && target.querySelectorAll('input[type=text]');
      const InputEmailField = target && target.querySelectorAll('input[type=email]');
      const InputRadioField = target && target.querySelectorAll('ul[type=radio]'); //('input[type=radio]');
      const TextAreaField = target && target.querySelectorAll('textarea');
      const InputCheckBoxField = target && target.querySelectorAll('input[type=checkbox]');
      const array = [
        ...InputTextField,
        ...InputEmailField,
        ...SelectField,
        ...TextAreaField,
        ...InputRadioField,
        ...InputCheckBoxField,
      ];
      array &&
        array.forEach((b) => {
          const validationObject = JSON.parse(b && b.getAttribute('data-validation-object'));
          const name = b && b.getAttribute('name');
          let keyArray = validationObject && Object.keys(validationObject);
          let value = b.value;
          if (b && b.type === 'radio') {
            const a = b && b.querySelector('input[data-radio-checked=true]');
            value = a && a.value ? a.value : false;
          }
          if (b && b.type === 'checkbox' && !b.classList.contains('multiSelect')) {
            value = b.checked;
          }
          if (b && b.type === 'checkbox' && b.classList.contains('multiSelect')) {
            const elements = document.getElementsByClassName('multiSelect');
            let names = [];
            for (let i = 0; i < elements.length; i++) {
              names.push(elements[i].checked);
            }
            value = names;
          }
          if (b && b.id === 'dateBox') {
            const selectDateName = b.name;
            if (selectDateName) {
              const selectField = selectDateName.split('_')[0];
              if (selectField) {
                if (selectedCreateDate[selectField]) {
                  value = selectedCreateDate[selectField];
                } else if (userEnteredAPIData[selectField]) {
                  value = userEnteredAPIData[selectField];
                }
              }
            }
          }
          let error = keyArray ? validateFields(value, validationObject, keyArray) : false;

          if (isValid && error) {
            isValid = false;
            if (b && b.type === 'radio') {
              b.querySelector('input[type=radio]') && b.querySelector('input[type=radio]').focus();
            } else {
              b && b.focus();
            }
          }
          if (b && b.id === 'dateBox') {
            const selectDateName = b.name;
            if (selectDateName) {
              const selectField = selectDateName.split('_')[0];
              obj = { ...obj, [selectField]: error ? error : false };
            }
          } else {
            obj = { ...obj, [name]: error ? error : false };
          }
        });
      setFormError({ ...formError, ...obj });
      return isValid;
    } catch (e) {
      console.log('Validation failed on submit');
    }
  };
  const submitHandler = (e) => {
    setShowLoader(true);
    e.preventDefault();
    if (getValidationOnSubmit(e)) {
      const compSaveID =
        manutds && manutds.value && manutds.value.Id && manutds.value.Id.charAt(0) === '{'
          ? manutds.value.Id.slice(1, -1)
          : manutds.value.Id;
      if (userId) {
        const allListData = { ...filledFormValues, ...formDetails, ...selectedCreateDate };
        setRetryFormDetails(allListData);
        saveCompetitionResponse(
          userId,
          userLoginInfo,
          compSaveID,
          EndDate,
          setEntryConfirm,
          setEntryError,
          undefined,
          setShowLoader,
          allListData,
          Headline,
          loginStatus,
          setFormSuccessMsg,
          true,
          NocelebrationConfetti
        );
      } else {
        setEntryError(true);
        //trackCompetitionError(compSaveID, userId, Headline, loginStatus, 'User ID not found.');
        setShowLoader(false);
      }
    } else {
      setShowLoader(false);
      console.log('Please Fill All Required Fields ');
    }
  };
  const retryHandler = (e) => {
    setShowLoader(true);
    e.preventDefault();
    const compSaveID =
      manutds && manutds.value && manutds.value.Id && manutds.value.Id.charAt(0) === '{'
        ? manutds.value.Id.slice(1, -1)
        : manutds.value.Id;
    if (userId) {
      saveCompetitionResponse(
        userId,
        userLoginInfo,
        compSaveID,
        EndDate,
        setEntryConfirm,
        setEntryError,
        undefined,
        setShowLoader,
        retryFormDetails,
        Headline,
        loginStatus,
        setFormSuccessMsg,
        true,
        NocelebrationConfetti
      );
    } else {
      setEntryError(true);
      //trackCompetitionError(compSaveID, userId, Headline, loginStatus, 'User ID not found.');
      setShowLoader(false);
    }
  };
  const getBack = (e) => {
    e.preventDefault();
    if (typeof window !== 'undefined') {
      window.location.href = xss(window.document.referrer);
    }
  };
  useEffect(() => {
    if (selectedData) {
      const dateTimeArray = Object.keys(selectedData);
      if (dateTimeArray) {
        dateTimeArray.map((elem, index) => {
          const getItem = elem.split('_')[0];
          const checkItem = elem.split('_')[1];
          if (checkItem !== undefined) {
            if (
              selectedData[`${getItem}_Date`] !== undefined &&
              selectedData[`${getItem}_Month`] !== undefined &&
              selectedData[`${getItem}_Year`] !== undefined
            ) {
              let createDate = '';
              const monthNum = moment().month(selectedData[`${getItem}_Month`]).format('M');
              createDate = `${selectedData[`${getItem}_Year`]}-${monthNum}-${
                selectedData[`${getItem}_Date`]
              }`;
              setSelectedCreateDate((selectedCreateDate) => {
                return { ...selectedCreateDate, [getItem]: createDate };
              });
            }
          }
        });
      }
    }
  }, [selectedData]);

  const onChangeFunction = (e, item, validation) => {
    let keyArray = Object.keys(validation);
    let value = e.target.value;
    setTypeInputValue({ [e.target.name]: value });
    if (e.target && e.target.type === 'checkbox' && !e.target.classList.contains('multiSelect')) {
      value = e.target.checked ? 'True' : 'False';
    }
    if (
      e.target &&
      e.target.name &&
      e.target.type === 'checkbox' &&
      e.target.classList.contains('multiSelect')
    ) {
      const elements = document.getElementsByClassName('multiSelect');
      let filteredElements = [];
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].name === e.target.name) {
          if (elements[i].checked) {
            if ([elements[i].id] && elements[i].value) {
              filteredElements.push({ [elements[i].id]: elements[i].value });
            }
          } else {
            if ([elements[i].id]) {
              filteredElements.push({ [elements[i].id]: '' });
            }
          }
        }
      }
      value = filteredElements;
    }
    if (e.target && e.target.id === 'dateBox') {
      setSelectedData({ ...selectedData, [e.target.name]: value });
    }
    if (e.target && e.target.id !== 'dateBox') {
      setFormDetails({ ...formDetails, [e.target.name]: value });
    }
    let error = item && validation ? validateFields(value, item, keyArray) : false;
    setFormError({ ...formError, [e.target.name]: error ? error : false });
  };
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    if (entryClose) {
      const itemId = `{${manutds && manutds.value && manutds.value.Id}}`;
      const route = `${GET_COMPETITION_ITEM}${encodeURI(itemId && itemId.toUpperCase())}`;
      const getCampaignItemData = fetchAPIGetItem(route);
      getCampaignItemData
        .then((data) => {
          setCloseData(data);
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  }, [entryClose]);

  useEffect(() => {
    if (Object.keys(filledFormValues).length !== 0 && FormFields && FormFields.length > 0) {
      FormFields.map((item, index) => {
        const dataType =
          item && item.fields && item.fields.FieldType && item.fields.FieldType.value;
        if (dataType && dataType === 'Datetime') {
          const dateValue = filledFormValues[`FieldValue-${index + 1}`];
          if (dateValue.includes('-')) {
            const enteredYear = dateValue.split('-')[0];
            const enteredMonth = dateValue.split('-')[1];
            const enteredDate = dateValue.split('-')[2];
            let MonthType;
            if (enteredMonth) {
              const MonthNum = Number(enteredMonth);
              if (!isNaN(MonthNum)) {
                MonthType = monthArr[MonthNum - 1].value;
              }
            }
            setSelectedData((selectedData) => {
              return {
                ...selectedData,
                [`FieldValue-${index + 1}_Year`]: enteredYear,
                [`FieldValue-${index + 1}_Month`]: MonthType,
                [`FieldValue-${index + 1}_Date`]: enteredDate,
              };
            });
          }
        }
      });
    }
  }, [FormFields, filledFormValues]);
  return (
    <>
      {closeData ? (
        <div className="home-article-detail__content-panel">
          <div className="home-content-panel__details">
            <ErrorBoundary>
              <CompetitionClose
                responseContent={closeData}
                entryConfirm={entryConfirm}
                manutds={manutds}
                gridClass={gridClass}
                sourceFromDetail={sourceFromDetail}
              />
            </ErrorBoundary>
          </div>
        </div>
      ) : !entryClose && !entryError ? (
        <>
          <div className="home-article-detail__content-panel">
            <div className="home-content-panel__details">
              <div className="home-content-panel__details-text">
                {entryConfirm && formSuccessMsg && (
                  <>
                    <div className="successMsg">
                      {DetailedPageSuccessTitle && DetailedPageSuccessTitle.value && (
                        <p className="mu-item__subtitle successHead">
                          {DetailedPageSuccessTitle.value}
                        </p>
                      )}
                      {DetailedPageSuccessDescription && DetailedPageSuccessDescription.value && (
                        <p className="mu-item__subtitle successDesc">
                          {DetailedPageSuccessDescription.value}
                        </p>
                      )}
                    </div>
                    {entryConfirm && formSuccessMsg && (
                      <>
                        <div className="success-paragraph">
                          {PostSuccessMessage && PostSuccessMessage.value && (
                            <span>{PostSuccessMessage.value}</span>
                          )}
                          {PostSuccessTextBox && PostSuccessTextBox.value && (
                            <span>{PostSuccessTextBox.value}</span>
                          )}
                        </div>
                        <div className="formBtnBox conformBoxBtn">
                          <div className="form_btn">
                            {ViewCompetitionDetailsCTA &&
                              ViewCompetitionDetailsCTA.value &&
                              manutds &&
                              manutds.value &&
                              manutds.value.DestinationUrl && (
                                <div className="red-btn-skew">
                                  <a href="#" onClick={(e) => formEntryHandler('view')}>
                                    <span>{ViewCompetitionDetailsCTA.value}</span>
                                  </a>
                                </div>
                              )}
                            {EditEntryDetailsCTA && EditEntryDetailsCTA.value && (
                              <div className="white-btn-skew">
                                <a href="#" onClick={(e) => formEntryHandler('edit')}>
                                  <span>{EditEntryDetailsCTA.value}</span>
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}

                {!formSuccessMsg && (
                  <>
                    <div className="paragraph">
                      {EditScreenTitle && EditScreenTitle.value && (
                        <span className="messageHeadBox">{EditScreenTitle.value}</span>
                      )}
                      {EditScreenTextBox && EditScreenTextBox.value && (
                        <span className="messageTxtBox">{EditScreenTextBox.value}</span>
                      )}
                    </div>
                    <div className="formpage-line-devider"></div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div id="competitionForm">
            {!formSuccessMsg && (
              <form action="" id="united_form" onSubmit={(e) => submitHandler(e)}>
                {FormFields &&
                  FormFields.length > 0 &&
                  FormFields.map((item, index) => (
                    <React.Fragment key={index}>
                      {item.fields &&
                        item.fields.HeadingCheck &&
                        item.fields.HeadingCheck.value && (
                          <div className="generic_heading">{item.fields.Title.value}</div>
                        )}
                      <ErrorBoundary>
                        <UnitedForm
                          data={item}
                          index={index}
                          fields={fields}
                          formError={formError}
                          formDetails={formDetails}
                          entryConfirm={entryConfirm}
                          userEnteredAPIData={userEnteredAPIData}
                          typeInputValue={typeInputValue}
                          formatedDate={selectedCreateDate}
                          setSelectedCreateDate={setSelectedCreateDate}
                          selectedData={selectedData}
                          setFormError={setFormError}
                          t={t}
                          onChangeFunction={onChangeFunction}
                        />
                      </ErrorBoundary>
                    </React.Fragment>
                  ))}

                <div className="formBtnBox">
                  <div className="form_btn">
                    {!entryConfirm && EnterCompetitionCTA && EnterCompetitionCTA.value && (
                      <input
                        type="submit"
                        aria-invalid="false"
                        aria-label={EnterCompetitionCTA.value}
                        className="red-btn-skew"
                        value={EnterCompetitionCTA.value}
                        data-track-submit="true"
                        onClick={scrollTop}
                      />
                    )}
                    {!isApp() && !entryConfirm && Cancel && Cancel.value && (
                      <div className="white-btn-skew">
                        <a href="#" onClick={(e) => getBack(e)}>
                          <span>{Cancel.value}</span>
                        </a>
                      </div>
                    )}
                    {entryConfirm && UpdateCompetitionEntry && UpdateCompetitionEntry.value && (
                      <input
                        type="submit"
                        aria-invalid="false"
                        aria-label={UpdateCompetitionEntry.value}
                        className="red-btn-skew"
                        value={UpdateCompetitionEntry.value}
                        data-track-submit="true"
                        onClick={scrollTop}
                      />
                    )}
                  </div>
                </div>
              </form>
            )}
          </div>
          {/* ----ad card panel start here---- */}
          {entryConfirm && formSuccessMsg && SuccessSelectCard && SuccessSelectCard.length > 0 && (
            <div className="home-article-detail__content-panel home-competition-detail__ad-card-panel">
              <div className="home-content-panel__details">
                {SuccessSelectCard.map((fields, i) => (
                  <AdCard key={i} {...fields} gridClass="grid-12" />
                ))}
              </div>
            </div>
            /* ----ad card panel start here---- */
          )}
        </>
      ) : (
        entryError && (
          <div className="home-article-detail__content-panel">
            <div className="home-content-panel__details">
              <div className="competition-closed">
                {Icon && Icon.value && Icon.value.Crops && (
                  <span className="close-logo">
                    <span className="img-holder">
                      <ImgCrop
                        className="imgcrop"
                        imgCrop={Icon.value.Crops}
                        singleSrc={false}
                        isWebpAvailable={Icon && Icon.value && Icon.value.isWebpAvailable}
                      />
                    </span>
                  </span>
                )}
                <div className="modal-text-gif">
                  {EntryFailed && EntryFailed.value && <h3>{EntryFailed.value}</h3>}
                  {FailMessage && FailMessage.value && <p>{FailMessage.value}</p>}
                  {Retry && Retry.value && (
                    <span className="modal-btn">
                      <a href="#" role="button">
                        <button
                          className="red-btn-skew viewOtherComp"
                          onClick={(e) => retryHandler(e)}
                        >
                          {Retry.value}
                        </button>
                      </a>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      )}

      {showLoader && <Loader className="loader-container" />}
    </>
  );
};

export default withTranslation()(CompetitionForm);
