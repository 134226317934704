import { newSearchUrl } from './squizSearchApi';
import {
  checkDevice,
  createBaseUrl,
  getLanguage,
  getLatestIOSDevice,
  getMobileOperatingSystem,
} from './utils';
import MU from '../constants/muConstants';

export const onSearchFormSubmit = (e, errorHandler, searchResultDisplayHandler, accessContent) => {
  //to call Squiz result page & add result in search_input class
  e.preventDefault();
  let inputElm = document.getElementById('search_input');
  if (inputElm && inputElm.value && inputElm.value.trim() !== '')
    return newSearchUrl(inputElm.value, errorHandler, searchResultDisplayHandler, accessContent);
};

export const renderNewSearchResult = (data) => {
  //add result data in search_input class
  let inputElm = document.querySelector('.search_result');
  if (inputElm) {
    inputElm.innerHTML = data;
  } else {
    console.error('Element with class "search_result" not found.');
  }
};

export const createSquizSearchUrl = (redirect) => {
  // on click search icon from header redirect to search page
  if (redirect) {
    return `${createBaseUrl()}/${getLanguage()}${redirect}`;
  } else return `${createBaseUrl()}/${getLanguage()}`;
};

export const replaceAllValueInQuery = (target, search, replacement) => {
  return target && target.replace(new RegExp(search, 'g'), replacement);
};

export const getPageUrl = () => {
  return (
    typeof window !== 'undefined' &&
    window.location.protocol + '//' + window.location.host + window.location.pathname
  );
};

export const getQueryParamValue = (url, query) => {
  if (!url) {
    return null;
  }
  try {
    const urlObject = new URL(url);
    // Use URLSearchParams to get the value of the specified parameter
    const params = new URLSearchParams(urlObject.href);
    const value = params.get(query);
    return value ? value : null;
  } catch (e) {
    return null;
  }
};

export const closeSearch = () => {
  //on closed search, reset values sessionStorage of searchStaticResp and redirect to sessionStorage of searchVisitedURL else redirect to home page
  typeof Storage !== 'undefined' && sessionStorage.removeItem('searchStaticResp');
  const redirectURL = typeof Storage !== 'undefined' && sessionStorage.getItem('searchVisitedURL');
  if (redirectURL) {
    typeof Storage !== 'undefined' && sessionStorage.removeItem('searchVisitedURL');
    window.location.href = redirectURL;
  } else window.location = `${window.location.origin}/${MU.language ? MU.language : 'en'}`;
};

export const keyAsPerOS = (t) => {
  //as per device add dictionary  key
  if (checkDevice()) {
    const getMobileOS = getMobileOperatingSystem();
    if (getMobileOS === 'iOS') {
      return t('HitentertosearchIOS');
    } else return t('HitentertosearchAndroid');
  } else if (getLatestIOSDevice()) {
    return t('HitentertosearchIOS');
  } else {
    return t('HitentertosearchorESCtoclose');
  }
};

export const isTouchScreens = () => {
  //to check touch screens devices
  if (checkDevice() || getLatestIOSDevice()) {
    return true;
  } else {
    return false;
  }
};

export const getPlaceholderText = (t) => {
  const innerWidth = typeof window !== 'undefined' && window?.innerWidth;
  if (innerWidth > 768) {
    return `${t('SearchplayersticketsFAQs').slice(0, 31)}...`;
  } else if (innerWidth > 700 && innerWidth <= 768) {
    return `${t('SearchplayersticketsFAQs').slice(0, 47)}...`;
  } else if (innerWidth > 600 && innerWidth <= 700) {
    return `${t('SearchplayersticketsFAQs').slice(0, 43)}...`;
  } else if (innerWidth > 500 && innerWidth <= 600) {
    return `${t('SearchplayersticketsFAQs').slice(0, 35)}...`;
  } else if (innerWidth > 380 && innerWidth <= 500) {
    return `${t('SearchplayersticketsFAQs').slice(0, 30)}...`;
  } else if (innerWidth <= 380) {
    return `${t('SearchplayersticketsFAQs').slice(0, 26)}...`;
  }
};
