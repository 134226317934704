/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_MATCHES,
  SET_MATCHES_FIXTURE,
  SET_MATCHES_RESULT,
  ERROR_MATCHES,
  FETCH_TABLES,
  SET_TABLES,
} from '../constants/actions/actionConstant';
import { MATCH_FIXTURE, MATCH_RESULT, TABLES } from '../constants/endpoints';
import api from '../utils/muApi';

export const fetchMatchFixture = (
  dispatch,
  matchFilter,
  currentIndex,
  noOfListingRecords,
  actionType
) => {
  const route = `${MATCH_FIXTURE}/${matchFilter}/${currentIndex}/${noOfListingRecords}`;
  dispatch(fetchingMatches(actionType));
  return api
    .get({ route })
    .then((response) => {
      dispatch(setMatchesFixture(response, actionType));
    })
    .catch((err) => {
      dispatch(error(err));
    });
};

export const fetchMatchResult = (
  dispatch,
  matchFilter,
  currentIndex,
  noOfListingRecords,
  actionType
) => {
  const route = `${MATCH_RESULT}/${matchFilter}/${currentIndex}/${noOfListingRecords}`;
  dispatch(fetchingMatches(actionType));
  return api
    .get({ route })
    .then((response) => {
      dispatch(setMatchesResult(response, actionType));
    })
    .catch((err) => {
      dispatch(error(err));
    });
};

export const fetchingMatches = (actionType) => ({ type: FETCH_MATCHES, actionType });
export const setMatchesFixture = (response, actionType) => ({
  type: SET_MATCHES_FIXTURE,
  response,
  actionType,
});
export const setMatchesResult = (response, actionType) => ({
  type: SET_MATCHES_RESULT,
  response,
  actionType,
});
export const error = (response) => ({ type: ERROR_MATCHES, response });

export const fetchLeagueTables = (dispatch, matchFilter) => {
  const route = `${TABLES}/${matchFilter}`;
  dispatch(fetchingTables());
  return api
    .get({ route })
    .then((response) => {
      dispatch(setTables(response));
    })
    .catch((err) => {
      dispatch(error(err));
    });
};
export const fetchingTables = () => ({ type: FETCH_TABLES });
export const setTables = (response) => ({
  type: SET_TABLES,
  response,
});
