/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useRef, useState } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import loadable from '@loadable/component';
import Slider from 'react-slick';
import MU from '../../../constants/muConstants';
import Transform from '../../../data-transform';
import * as track from '../../../utils/analytics';
import { checkDevice, getRandomId } from '../../../utils/utils';
import {
  afterChangeAccessbility,
  beforeChangeAccessbility,
  onInitAccessibility,
  NextArrow,
  PrevArrow,
  slideShowCount,
} from '../../../utils/carouselUtils';
import { IMAGE_ASSET_URL_CAROUSEL } from '../../../constants/containerConstants';
import moment from 'moment';
import ErrorBoundary from '../../Common/ErrorBoundary';
import { THRESHOLD } from '../../../constants/globalConstants';
const StructuredData = loadable(() => import('../../StructuredData'));
const areEqual = (prevProps, nextProps) => true;
const MatchCarousel = React.memo((props) => {
  const value = props.fields && props.fields.matchcarousel && props.fields.matchcarousel.value;
  let ResultsResponse, FixtureResponse, LiveResponse;
  if (value && value.documentsFromSolr) {
    ResultsResponse = value.documentsFromSolr.ResultsResponse;
    FixtureResponse = value.documentsFromSolr.FixtureResponse;
    LiveResponse = value.documentsFromSolr.LiveResponse;
  }
  const [isTouchDevice, setIsTouchDevice] = useState(true);
  const [slicker, setSlicker] = useState({});
  const [screenReaderText, setScreenReaderText] = useState('');
  const [uniqueId, setUniqueId] = useState(getRandomId());
  const slickElement = useRef(null);
  const carouselRef = useRef(null);
  const mobileRef = useRef(null);
  const slickInitSlide = useRef(null);
  const ContentType = { ContentType: { value: 'matchcarousel' } };
  const structuredData = { structuredata: value };
  const { pageEditing } = props.sitecoreContext;
  let timeoutId;
  useEffect(() => {
    setIsTouchDevice(checkDevice());
  }, []);
  useEffect(() => {
    if (slickElement && !isTouchDevice) {
      if (slickElement.current) {
        setTimeout(() => {
          slickElement.current.slickGoTo(firstSlide());
        }, 3000);
      }
    }
  }, [slickElement, isTouchDevice, uniqueId]);
  useEffect(() => {
    if (isTouchDevice) {
      const initialSlide = firstSlide();
      scrollToInTouchDevice(initialSlide);
    }
  }, [mobileRef]);
  useEffect(() => {
    const touchDevice = checkDevice();
    if (!touchDevice) {
      window.addEventListener('resize', resizeListener);
    }
    return () => {
      !touchDevice && window.removeEventListener('resize', resizeListener);
    };
  }, []);
  const resizeListener = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setIsTouchDevice(checkDevice());
      setUniqueId(getRandomId());
    }, THRESHOLD);
  };
  const slickNext = () => {
    const slkel = slickElement && slickElement.current;
    if (slkel) {
      slkel.slickNext();
    }
  };
  const slickPrev = () => {
    const slkel = slickElement && slickElement.current;
    if (slkel) {
      slkel.slickPrev();
    }
  };
  const checkTbcCard = () => {
    let isTbcCard = false;
    if (
      FixtureResponse &&
      FixtureResponse.response &&
      FixtureResponse.response.docs &&
      FixtureResponse.response.docs.length > 0
    ) {
      isTbcCard = FixtureResponse.response.docs.find((value) => {
        return value.ismatchdatetbc_b;
      });
    }
    return isTbcCard ? 'tbc-card' : '';
  };
  const initalize = () => {
    let count = 0;
    if (
      ResultsResponse &&
      ResultsResponse.response &&
      ResultsResponse.response.docs &&
      ResultsResponse.response.docs.length > 0
    ) {
      count = ResultsResponse.response.docs.reduce((counter, item) => {
        if (item.opta_cid_t && item.opta_sid_t && item.opta_mid_t) counter += 1;
        return counter;
      }, 0);
    }
    if (
      LiveResponse &&
      LiveResponse.response &&
      LiveResponse.response.docs &&
      LiveResponse.response.docs.length > 0 &&
      LiveResponse.response.docs[0].opta_cid_t &&
      LiveResponse.response.docs[0].opta_sid_t &&
      LiveResponse.response.docs[0].opta_mid_t
    ) {
      count++;
    } else if (
      FixtureResponse &&
      FixtureResponse.response &&
      FixtureResponse.response.docs &&
      FixtureResponse.response.docs.length > 0
    ) {
      FixtureResponse.response.docs.find((value) => {
        return value.opta_cid_t && value.opta_sid_t && value.opta_mid_t && count++;
      });
    }
    return count;
  };
  const scrollToInTouchDevice = (initialSlide) => {
    let calcMargin;
    if (typeof window !== 'undefined' && window.innerWidth >= 320 && window.innerWidth < 374) {
      calcMargin = initialSlide * 279 + initialSlide * 24 + 12 - 16;
    } else if (
      typeof window !== 'undefined' &&
      window.innerWidth >= 375 &&
      window.innerWidth <= 567
    ) {
      calcMargin = initialSlide * 279 + initialSlide * 24 + 12 - 43;
    } else {
      calcMargin = initialSlide * 279 + initialSlide * 24 + 12;
    }
    const element = carouselRef && carouselRef.current;
    const sliderContainer = element && element.querySelector('.carousal-list');
    if (sliderContainer && calcMargin) {
      setTimeout(() => {
        sliderContainer.scroll({
          left: calcMargin,
        });
      }, 1000);
    }
  };
  const firstSlide = () => {
    let initialSlide = 0;
    switch (initalize()) {
      case 1:
        initialSlide = 0;
        break;
      case 2:
        initialSlide = 0;
        break;
      case 3:
        initialSlide = 1;
        break;
      case 4:
        initialSlide = 2;
        break;
      case 5:
        initialSlide = 3;
        break;
      default:
        initialSlide = 0;
    }
    if (typeof window !== 'undefined' && window.innerWidth <= 568) {
      initialSlide += 1;
    }
    return initialSlide;
  };
  const nextPrevArrow = (oldIndex, newIndex, slicker, bypass) => {
    setTimeout(() => {
      if (oldIndex < newIndex || bypass) {
        slicker && slicker.prevArrow && slicker.prevArrow.classList.remove('clicked');
        slicker && slicker.nextArrow && slicker.nextArrow.classList.add('clicked');
      }
      if (oldIndex > newIndex) {
        slicker && slicker.prevArrow && slicker.prevArrow.classList.add('clicked');
        slicker && slicker.nextArrow && slicker.nextArrow.classList.remove('clicked');
      }
    }, 100);
  };
  const slideCount = () => {
    let count = 0;
    if (
      ResultsResponse &&
      ResultsResponse.response &&
      ResultsResponse.response.docs &&
      ResultsResponse.response.docs.length > 0
    ) {
      count += ResultsResponse.response.docs.length;
    }
    if (
      LiveResponse &&
      LiveResponse.response &&
      LiveResponse.response.docs &&
      LiveResponse.response.docs.length > 0
    ) {
      count += LiveResponse.response.docs.length;
    }
    if (
      FixtureResponse &&
      FixtureResponse.response &&
      FixtureResponse.response.docs &&
      FixtureResponse.response.docs.length > 0
    ) {
      count += FixtureResponse.response.docs.length;
    }
    return count;
  };
  const trackLink = (ctaTitle, linkUrl, e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: ctaTitle,
        destination_url: linkUrl,
      },
      e
    );
  };

  const settings = () => {
    return {
      className: `carousal-list ${checkTbcCard()} center`,
      lazyLoad: 'progressive',
      variableWidth: true,
      infinite: false,
      speed: 400,
      swipe: true,
      accessibility: false,
      slidesToShow: slideShowCount(),
      dots: true,
      slidesToScroll: slideShowCount(),
      draggable: false,
      swipeToSlide: true,
      touchMove: true,
      mobileFirst: true,
      touchThreshold: 20,
      initialSlide: firstSlide(),
      rtl: false,
      arabic: MU.language === 'ar' ? true : false,
      prevArrow: <PrevArrow pageEditing={pageEditing} slickPrev={slickPrev} />,
      nextArrow: (
        <NextArrow
          slideShow={slideShowCount()}
          clicked={firstSlide()}
          pageEditing={pageEditing}
          slickNext={slickNext}
        />
      ),
      appendDots: (dots) => {
        if (dots && dots.length) {
          let activeDots = dots.length;
          try {
            const slidesPerPage = slideShowCount();
            const totalSlides = slideCount();
            const currentSlide = slickInitSlide ? slickInitSlide.current : firstSlide();
            const slidesLeft =
              currentSlide + slidesPerPage > totalSlides ? 0 : currentSlide + slidesPerPage;
            activeDots = dots.length - Math.ceil((totalSlides - slidesLeft) / slidesPerPage);
          } catch (error) {
            console.log('Error in slick active dots.');
          }
          const list = dots.map((val, index) => {
            const active = activeDots;
            activeDots--;
            return (
              <li
                key={index}
                className={`${val.props.className} ${active && active > 0 ? 'active' : ''}`}
              >
                <i></i>
              </li>
            );
          });
          return (
            <div className="slick-dots">
              <ul tabIndex="-1" aria-hidden="true">
                {list}
              </ul>
            </div>
          );
        }
      },
      onLazyLoad: (e) => {
        if (e.includes(slideCount() - 1)) {
          setTimeout(() => {
            if (carouselRef) {
              let slider = carouselRef.current;
              let list = slider && slider.querySelector('.slick-list');
              let slideTrack = list && list.querySelector('.slick-track');
              let slides = slideTrack && slideTrack.querySelectorAll('.slick-slide');
              let nextArrow = slider && slider.querySelector('.slider-next.slick-arrow');
              let prevArrow = slider && slider.querySelector('.slider-prev.slick-arrow');
              onInitAccessibility(slideTrack, slides, 'match');
              setSlicker({
                slider: slider,
                list: list,
                track: slideTrack,
                slides: slides,
                nextArrow: nextArrow,
                prevArrow: prevArrow,
              });
            }
          }, 500);
        }
      },
      beforeChange: (oldIndex, newIndex) => {
        slickInitSlide.current = newIndex;
        setScreenReaderValue(newIndex);
        nextPrevArrow(oldIndex, newIndex, slicker);
        beforeChangeAccessbility(slicker, 'match');
      },
      afterChange: (currentSlide) => {
        afterChangeAccessbility(slicker);
      },
    };
  };
  const setScreenReaderValue = (index) => {
    const a = index;
    const b = slideShowCount();
    const c = slideCount();
    if (a + b <= c) {
      setScreenReaderText(`Displayed ${a + 1} of ${a + b} out of ${c}`);
    }
    setTimeout(() => {
      setScreenReaderText('');
    }, 300);
  };
  const getSortedResponse = (value) => {
    return value.sort((a, b) => {
      return moment.utc(a.matchdate_tdt).diff(moment.utc(b.matchdate_tdt));
    });
  };
  const renderDesktopCards = () => {
    return (
      <Slider {...settings()} ref={slickElement}>
        {ResultsResponse &&
          ResultsResponse.response &&
          ResultsResponse.response.docs &&
          ResultsResponse.response.docs.length > 0 &&
          getSortedResponse(ResultsResponse.response.docs).map((item, i) => (
            <Transform
              componentName="MatchCard"
              key={i}
              item={item}
              source="curate"
              type="results"
              container={IMAGE_ASSET_URL_CAROUSEL}
            />
          ))}
        {LiveResponse &&
          LiveResponse.response &&
          LiveResponse.response.docs &&
          LiveResponse.response.docs.length > 0 &&
          LiveResponse.response.docs.map((item, i) => (
            <Transform
              componentName="MatchCard"
              key={i}
              item={item}
              source="curate"
              type="live"
              container={IMAGE_ASSET_URL_CAROUSEL}
            />
          ))}
        {FixtureResponse &&
          FixtureResponse.response &&
          FixtureResponse.response.docs &&
          FixtureResponse.response.docs.length > 0 &&
          FixtureResponse.response.docs.map((item, i) => (
            <Transform
              componentName="MatchCard"
              key={i}
              item={item}
              source="curate"
              type="fixture"
              container={IMAGE_ASSET_URL_CAROUSEL}
            />
          ))}
      </Slider>
    );
  };
  const renderMobileCards = () => {
    return (
      <div className="carousal-wrapper">
        <div className={`carousal-list ${checkTbcCard()} custom-mobile-slider`} ref={mobileRef}>
          {ResultsResponse &&
            ResultsResponse.response &&
            ResultsResponse.response.docs &&
            ResultsResponse.response.docs.length > 0 &&
            getSortedResponse(ResultsResponse.response.docs).map((item, i) => (
              <div key={i} className="mobile-listitem" role="listitem">
                <Transform
                  componentName="MatchCard"
                  key={i}
                  item={item}
                  source="curate"
                  type="results"
                  container={IMAGE_ASSET_URL_CAROUSEL}
                />
              </div>
            ))}
          {LiveResponse &&
            LiveResponse.response &&
            LiveResponse.response.docs &&
            LiveResponse.response.docs.length > 0 &&
            LiveResponse.response.docs.map((item, i) => (
              <div key={i} className="mobile-listitem" role="listitem">
                <Transform
                  componentName="MatchCard"
                  key={i}
                  item={item}
                  source="curate"
                  type="live"
                  container={IMAGE_ASSET_URL_CAROUSEL}
                />
              </div>
            ))}
          {FixtureResponse &&
            FixtureResponse.response &&
            FixtureResponse.response.docs &&
            FixtureResponse.response.docs.length > 0 &&
            FixtureResponse.response.docs.map((item, i) => (
              <div key={i} className="mobile-listitem" role="listitem">
                <Transform
                  componentName="MatchCard"
                  key={i}
                  item={item}
                  source="curate"
                  type="fixture"
                  container={IMAGE_ASSET_URL_CAROUSEL}
                />
              </div>
            ))}
        </div>
      </div>
    );
  };
  return (
    <ErrorBoundary>
      {slideCount() && (
        <div className="home-row" key={uniqueId}>
          <div className="carousal match-carousal carousal-light">
            <div
              className={`container ${isTouchDevice ? 'custom-container' : ''} ${
                slideCount() <= slideShowCount() ? 'adjust' : ''
              }`}
            >
              <div className="carousal-width" ref={carouselRef}>
                <div className="carousal-head">
                  <div className="carousal_title_link light">
                    <h2 className="carousal__title--light">
                      <span>{value && value.heading}</span>
                      {value.CTATitle && value.CTAUrl && (
                        <a
                          href={value.CTAUrl}
                          className="carousal-destination"
                          onClick={(e) => trackLink(value.CTATitle, value.CTAUrl, e)}
                          tabIndex="0"
                        >
                          <span aria-hidden="true">{value.CTATitle}</span>
                          <span className="screenreader">{props.t('ClickToSeeAllMatches')}</span>
                        </a>
                      )}
                    </h2>
                  </div>
                </div>
                {!isTouchDevice && renderDesktopCards()}
                {isTouchDevice && renderMobileCards()}
              </div>
            </div>
          </div>
          {!isTouchDevice && (
            <div className="screenreader" aria-live="assertive">
              {screenReaderText}
            </div>
          )}
        </div>
      )}
      {(typeof window === 'undefined' || process.env.NODE_ENV === 'development') &&
        slideCount() && <StructuredData structuredData={structuredData} fields={ContentType} />}
    </ErrorBoundary>
  );
}, areEqual);
export default withSitecoreContext()(withTranslation()(MatchCarousel));
