/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { createContext, useContext, useReducer } from 'react';
import { showListingState, showListingReducer } from '../reducers/showListingReducer';

export const ShowListingContext = createContext();

export const ShowListingProvider = ({ children }) => (
  <ShowListingContext.Provider value={useReducer(showListingReducer, showListingState)}>
    {children}
  </ShowListingContext.Provider>
);
export const useShowListingValue = () => useContext(ShowListingContext);

export default ShowListingContext;
