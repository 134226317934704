/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useRef, useState } from 'react';
import { FREE_TO_VIEW } from '../../constants/globalConstants';
import MU from '../../constants/muConstants';
import { useSponsorValue } from '../../context/sponsorContext';
import {
  createTagArr,
  getContentAccessLocal,
  isApp,
  showCBS,
  getLoggedInStatus,
} from '../../utils/utils';
import ErrorBoundary from './ErrorBoundary';
import Loader from './Loader';
import { useModal } from './Modal';
import loadable from '@loadable/component';
const RegistrationModal = loadable(() => import('../Modals/RegistrationModal/RegistrationModal'));

export const ContentAccessModal = ({
  ContentAccess,
  ContentType,
  manutds,
  className,
  insideModal,
  isDestinationPage,
  successCallback,
  analyticsObj,
}) => {
  const { showModal, closeModal, isModalVisible } = useModal();
  const [{ isLoginChecked, isUserLogin, isUserSubscribe, isUserSkyId }] = useSponsorValue();
  const setContentAccessState = () => {
    const tags = !ContentAccess && createTagArr(manutds);
    return getContentAccessLocal(ContentAccess, ContentType, tags);
  };
  const [contentAccessState] = useState(setContentAccessState());
  const [showLoader, setShowLoader] = useState(contentAccessState !== FREE_TO_VIEW ? true : false);
  const element = useRef(null);
  useEffect(() => {
    if (MU && MU.isMWV) {
      removeLoaderandStyle();
    } else if (contentAccessState && contentAccessState !== FREE_TO_VIEW) {
      if (!element.current) {
        element.current = document.getElementsByTagName('BODY')[0];
      }
      if (contentAccessState && !isLoginChecked) {
        if (element.current) {
          if (!insideModal) {
            element.current.style.overflow = 'hidden';
            element.current.style.position = 'static';
          }
          const filter = element.current.getElementsByClassName(className);
          if (filter && filter.length > 0) {
            filter[0].style.filter = 'blur(6px)';
          }
        }
      } else if (contentAccessState && isUserLogin) {
        if (!showCBS({ isUserLogin, isUserSubscribe, contentAccessState })) {
          removeLoaderandStyle();
          !isApp() && closeModal();
        } else {
          !isApp() && showModal('', true);
        }
      } else if (contentAccessState && isLoginChecked) {
        if (showCBS({ isUserLogin, isUserSubscribe, contentAccessState })) {
          !isApp() && showModal('', true);
        } else {
          removeLoaderandStyle();
        }
      }
    }
  }, [isLoginChecked, isUserLogin]);

  const removeLoaderandStyle = () => {
    if (element.current) {
      !insideModal && element.current.removeAttribute('style');
      const filter = element.current.getElementsByClassName(className);
      if (filter && filter.length > 0) {
        filter[0].removeAttribute('style');
      }
      setShowLoader(false);
    }
  };

  const loaderStyle = { zIndex: 999999, background: 'rgba(0,0,0,0.8)' };

  const getAnalyticsObj = () => {
    const obj = {
      content_status: contentAccessState,
      subscription_status: getLoggedInStatus({
        isLoginChecked,
        isUserLogin,
        isUserSubscribe,
        isUserSkyId,
      }),
    };
    if (analyticsObj) {
      return { ...analyticsObj, ...obj };
    }
    return obj;
  };

  return (
    <React.Fragment>
      {showLoader &&
        (isApp() ? (
          <div className="loader-container1" style={loaderStyle} />
        ) : (
          <Loader className="loader-container1" style={loaderStyle} />
        ))}
      {isModalVisible && (
        <ErrorBoundary>
          <RegistrationModal
            isDestinationPage={isDestinationPage}
            showClose={false}
            successCallback={successCallback}
            closeModal={closeModal}
            fallback={null}
            previousActiveElement={document.activeElement}
            accessType={contentAccessState}
            analyticsObj={getAnalyticsObj()}
          />
        </ErrorBoundary>
      )}
    </React.Fragment>
  );
};

ContentAccessModal.defaultProps = {
  insideModal: false,
  isDestinationPage: false,
};
export default ContentAccessModal;
