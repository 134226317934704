/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import { redirectToSignIn } from '../../../utils/gigyaScreen';
import { useSponsorValue } from '../../../context/sponsorContext';
import { useMuscValue, MuscProvider } from '../../../context/muscContext';
import { fetchAllocatedMatchTicket, fetchUserDetail } from '../../../actions/muscAction';
import Loader from '../../Common/Loader';
import loadable from '@loadable/component';

const MemberManagmentOverview = loadable(() => import('./MemberManagmentOverview'));
const MemberManagmentDetail = loadable(() => import('./MemberManagmentDetail'));
const MemberThankYou = loadable(() => import('./MemberThankYou'));

const MuscMemberManagment = (props) => (
  <MuscProvider>
    <MemberManagement {...props}></MemberManagement>
  </MuscProvider>
);
const MemberManagement = (props) => {
  const [{ isGigyaAvilable, userLoginDetail }] = useSponsorValue();
  const [
    { matchTicket, matchStatus, userList, userListStatus, newUserRequestStatus },
    dispatch,
  ] = useMuscValue();
  const [activeDomElm, setActiveDomElm] = useState({ active: 1 });
  const matchDetailsResponse = matchTicket && matchTicket.MatchDetailsResponse;
  const ticketApplication =
    matchDetailsResponse &&
    matchDetailsResponse.Staticdata &&
    matchDetailsResponse.Staticdata.ticketapplication;

  useEffect(() => {
    if (isGigyaAvilable) {
      if (userLoginDetail && userLoginDetail.data && userLoginDetail.data.mufcNumber) {
        fetchAllocatedMatchTicket(dispatch, userLoginDetail);
        fetchUserDetail(dispatch, userLoginDetail);
      } else {
        redirectToSignIn();
      }
    }
  }, [isGigyaAvilable, userLoginDetail]);

  const setActivePage = (index) => {
    setActiveDomElm({ active: index });
  };

  return (
    <React.Fragment>
      {matchStatus === 'fetched' && userListStatus === 'fetched' ? (
        <section className="tickets tmpls-container secretary-container second">
          <div className="match_application-wrap">
            <section className="match_ticket_application">
              {activeDomElm && activeDomElm.active === 1 && (
                <MemberManagmentOverview
                  ticketApplication={ticketApplication}
                  matchDetailsResponse={matchDetailsResponse}
                  userList={userList}
                  setActivePage={setActivePage}
                />
              )}
              {activeDomElm && activeDomElm.active === 2 && (
                <MemberManagmentDetail
                  ticketApplication={ticketApplication}
                  matchDetailsResponse={matchDetailsResponse}
                  setActivePage={setActivePage}
                />
              )}
              {activeDomElm && activeDomElm.active === 3 && (
                <MemberThankYou
                  ticketApplication={ticketApplication}
                  matchDetailsResponse={matchDetailsResponse}
                  setActivePage={setActivePage}
                />
              )}
            </section>
          </div>
        </section>
      ) : (
        <Loader />
      )}
      {newUserRequestStatus === 'fetching' && <Loader></Loader>}
    </React.Fragment>
  );
};

export default MuscMemberManagment;
