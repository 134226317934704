/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect } from 'react';
import { fetchedCaptcha } from '../../actions/formAction';
const CapchaInit = ({ dispatch }) => {
  useEffect(() => {
    var v = document.createElement('script');
    v.src = 'https://www.google.com/recaptcha/api.js';
    document.body.appendChild(v);
    v.onload = () => fetchedCaptcha(dispatch);
  }, []);
  return null;
};

export default CapchaInit;
