/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { createContext, useContext, useReducer } from 'react';
import { sponsorReducer, sponsorState } from '../reducers/sponsorReducer';

export const SponsorContext = createContext();

export const SponsorProvider = ({ children }) => (
  <SponsorContext.Provider value={useReducer(sponsorReducer, sponsorState)}>
    {children}
  </SponsorContext.Provider>
);
export const useSponsorValue = () => useContext(SponsorContext);

export default SponsorContext;
