/**
 *  © 2024 -2025 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import MU from '../../constants/muConstants';
import * as track from '../../utils/analytics';
// import { checkDevice } from '../../utils/utils';
const Products = (props) => {
  const [imagehover, setImagehover] = useState(false);
  const [shopUrl, setShopUrl] = useState('');
  const [productImageUrl, setProductImageUrl] = useState('');
  const container = props?.container;
  const productData = props?.productList;
  const shopIdConfiguration = MU?.shopIDs?.ShopIDConfiguration;
  const ecomProductShopId = props?.rendering?.fields?.EComProduct1?.value?.EComProduct1?.ShopID;
  // const ecomImageBaseURL = MU?.ecomData?.ecomImageBaseURL;
  const productImage = productImageUrl && productImageUrl + props?.productList?.images[0]?.hash;
  const productHoverImage =
    productImageUrl && productImageUrl + props?.productList?.images[1]?.hash;
  const productName = productData?.attributes?.name?.values?.label;
  const productId = productData?.id;
  const productMaxRetailPrice = productData?.priceRange?.max?.recommendedRetailPrice;
  const productMinRetailPrice = productData?.priceRange?.min?.recommendedRetailPrice;
  const productMaxPrice = productMaxRetailPrice && productMaxRetailPrice / 100;
  const productMinPrice = productMinRetailPrice && productMinRetailPrice / 100;
  const ecomShopUrl = productId && shopUrl + productId;
  const itemId = props?.rendering?.dataSource;

  const handleProductPrice = () => {
    if (productMaxRetailPrice === productMinRetailPrice) {
      return '£' + productMaxPrice;
    } else if (productMaxRetailPrice !== productMinRetailPrice) {
      return '£' + productMinPrice + ' - £' + productMaxPrice;
    }
  };

  const trackCard = (ctaTitle, linkUrl, e) => {
    const initialData = track.data('card');
    track.analytics(
      {
        ...initialData,
        card_name: ctaTitle,
        container_type: container,
        destination_url: linkUrl,
        item_id: itemId,
      },
      e
    );
  };

  useEffect(() => {
    if (shopIdConfiguration && ecomProductShopId) {
      const shopIdData = shopIdConfiguration.find((item) => item.ShopID === ecomProductShopId);
      setProductImageUrl(shopIdData.ImageURL);
      setShopUrl(shopIdData.ShopURL);
    }
  }, [shopIdConfiguration]);

  return (
    <React.Fragment>
      <a
        href={ecomShopUrl}
        target="_blank"
        tabIndex="0"
        rel="noreferrer"
        onClick={(e) => trackCard(productName, ecomShopUrl, e)}
      >
        <div className="grid-3 ratio">
          <div className="mu-content">
            <article className="ecommerce-card">
              <div className="ecommerce-card-item">
                {productImage && (
                  <LazyLoad once={true} offset={100}>
                    <figure
                      className="nobg"
                      onMouseOver={() => setImagehover(true)}
                      onMouseOut={() => setImagehover(false)}
                    >
                      <img
                        className="img-responsive"
                        src={
                          imagehover
                            ? productHoverImage
                              ? productHoverImage
                              : productImage
                            : productImage
                        }
                        alt={productName}
                      />
                    </figure>
                  </LazyLoad>
                )}
              </div>
              {productMaxPrice && productMinPrice && (
                <div className="product-price-info">
                  <span className="price-tag">{handleProductPrice()}</span>
                </div>
              )}
              {productName && (
                <div className="ecommerce-card__info ecom-btn">
                  <div id={productId} className="ecommerce-card__title">
                    {productName}
                  </div>
                </div>
              )}
            </article>
          </div>
        </div>
      </a>
    </React.Fragment>
  );
};

export default React.memo(Products);
