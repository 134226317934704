/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import Transform from '../../data-transform';
import { SUPPORTED_LISTING_CARD } from '../../constants/globalConstants';
import { CustomComponentMapping } from '../CustomComponentMapping';

/**
 * @component {Listing}
 * @param {props:list of all item}
 */
const Listing = (props) => {
  return props.listing.map((item, i) => {
    let contentType = item && item.contenttype_t;
    if (SUPPORTED_LISTING_CARD.includes(contentType.toLowerCase())) {
      return (
        <div className={props.classItem}>
          <Transform
            key={i}
            data={item}
            source="aws"
            gridClass={props.gridClassName}
            isMatchcenterCard={props.isMatchcenterCard}
            container={props.container}
          />
        </div>
      );
    } else {
      const Customcomponent = CustomComponentMapping(
        item.contenttype_t && item.contenttype_t.toLowerCase()
      );
      return (
        Customcomponent && (
          <div className={props.classItem}>
            <Customcomponent
              item={item}
              key={i}
              gridClass={props.gridClassName}
              isMatchcenterCard={props.isMatchcenterCard}
              container={props.container}
            />
          </div>
        )
      );
    }
  });
};

export default Listing;
