/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_NEWS_VIDEO_LIST,
  SET_NEWS_VIDEO_LIST,
  ERROR_NEWS_VIDEO_LIST,
} from '../constants/actions/actionConstant';
import { SUPPORTED_NEWS_LISTING_CARD } from '../constants/globalConstants';
import { checkResponseDocs, createDestinationUrl } from '../utils/utils';

export const listingState = {
  status: null,
  listingObj: null,
  heroObj: null,
  filterObj: null,
  pageData: null,
  filterGroups: null,
  totalCards: null,
  startListIndex: 0,
  showLatest: false,
  filters: null,
  initialLoad: false,
  noMoreRecord: false,
  cardsEnd: false,
  showloadMore: false,
};

/**
 * Reducer for both news & video listing
 */
export const listingReducer = (state = listingState, { type, response, actionType } = {}) => {
  switch (type) {
    case FETCH_NEWS_VIDEO_LIST:
      return {
        ...state,
        status: 'fetching',
        listingLoader: true,
        showLatest: false,
      };
    case SET_NEWS_VIDEO_LIST: {
      if (
        !state.initialLoad &&
        !(
          response &&
          response.data &&
          response.data.ListingResponse &&
          checkResponseDocs(response.data.ListingResponse) &&
          response.data.ListingResponse.response.docs.length
        )
      ) {
        redirectErrorPage();
      }
      state.initialLoad = true;
      return {
        ...state,
        status: 'fetched',
        listingLoader: false,
        showLatest: true,
        ...filterListing(response, state, actionType),
      };
    }
    case ERROR_NEWS_VIDEO_LIST: {
      if (!state.initialLoad) {
        redirectErrorPage();
      }
      return {
        ...state,
        status: 'error',
        listingLoader: false,
        showLatest: true,
      };
    }
    default:
      return state;
  }
};

const redirectErrorPage = () => {
  window.location = createDestinationUrl('PageLoadFail');
};

const filterListing = (response, state, actionType) => {
  let newState = {};
  if (response && response.data) {
    if (actionType !== 'loadMore') {
      newState.heroObj = getHeroBanner(response.data);
      newState.filters = getFilterList(response.data);
    }
    newState = { ...newState, ...getCardList(response.data, state, actionType) };
  }
  return newState;
};

const getHeroBanner = (data) => {
  if (data && data.HeroResponse && checkResponseDocs(data.HeroResponse)) {
    if (
      typeof data.HeroResponse.response.docs == 'string' &&
      data.HeroResponse.response.docs.length > 0
    ) {
      return JSON.parse(data.HeroResponse.response.docs);
    } else {
      return data.HeroResponse.response.docs;
    }
  }
};

const getFilterList = (data) => {
  if (data && data.PageFilterResponse && checkResponseDocs(data.PageFilterResponse)) {
    return sortFilter(data);
  }
};

const getCardList = (data, state, actionType) => {
  let newState = {};
  if (data && data.ListingResponse && checkResponseDocs(data.ListingResponse)) {
    if (state.pageData && actionType === 'loadMore') {
      newState.pageData = [...state.pageData, ...removeNode(data.ListingResponse.response.docs)];
      newState.totalCards = data.ListingResponse.response.numFound;
      newState.startListIndex = data.ListingResponse.response.start;
    } else {
      newState.pageData = removeNode(data.ListingResponse.response.docs);
      newState.totalCards = data.ListingResponse.response.numFound;
      newState.startListIndex = data.ListingResponse.response.start;
    }
    if (newState.totalCards - newState.startListIndex > 30) {
      if (newState.startListIndex >= 90) {
        newState.noMoreRecord = false;
        newState.showloadMore = true;
        newState.cardsEnd = true;
      } else {
        newState.showloadMore = true;
      }
    } else {
      newState.showloadMore = false;
      newState.noMoreRecord = true;
    }
  }
  return newState;
};

const removeNode = (data) => {
  let newArray = [];
  newArray = data.filter((item) => {
    return SUPPORTED_NEWS_LISTING_CARD.includes(item.contenttype_t.toLowerCase());
  });
  return newArray;
};

const sortFilter = (data) => {
  if (data.PageFilterResponse.response) {
    if (data.PageFilterResponse.response.docs && data.PageFilterResponse.response.docs.length > 0) {
      let filterTempData = data.PageFilterResponse.response.docs;
      try {
        return filterTempData.sort((a, b) => {
          return parseInt(a.__sortorder_t) - parseInt(b.__sortorder_t);
        });
      } catch (e) {
        console.error('Invalid filters', e);
      }
    }
  }
};
