import React from 'react';
import Masonry from 'react-masonry-component';
import MU from '../../constants/muConstants';

const SubMenuMore = ({ isMoreMenu, children, ulClass }) => {
  const isRtl = MU && MU.language && MU.language === 'ar' ? false : true;
  if (isMoreMenu) {
    return (
      <Masonry
        className={ulClass} // default ''
        elementType={'ul'} // default 'div'
        style={{ width: '100%' }}
        options={{ isOriginLeft: isRtl }}
      >
        {children}
      </Masonry>
    );
  } else {
    return (
      <ul className={ulClass} style={{ width: '100%' }}>
        {children}
      </ul>
    );
  }
};

export default SubMenuMore;
