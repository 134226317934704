/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import ImgCrop from '../Common/ImgCrop';

const ImageSlider = ({ sliderData, animateTime }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    setInterval(() => {
      setCurrentSlide((currentSlide) => (currentSlide + 1) % sliderData.length);
    }, animateTime);
  }, [animateTime, sliderData.length]);

  const getSlideClass = (index) => (index === currentSlide ? 'slide showing' : 'slide');
  return (
    <React.Fragment>
      {sliderData && (
        <ul id="image__slides" style={{ width: '100%', height: '100%' }}>
          {sliderData.map((sliderItem, index) => (
            <li className={getSlideClass(index)} key={index}>
              <div className="img-holder">
                <ImgCrop
                  imgCrop={sliderItem.imagecropurl_s}
                  singleSrc={false}
                  alt=""
                  title=""
                  isWebpAvailable={sliderItem.iswebpavailablebool_b}
                ></ImgCrop>
              </div>
            </li>
          ))}
        </ul>
      )}
    </React.Fragment>
  );
};

export default ImageSlider;
