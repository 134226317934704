/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import { fetchPlayerLiveStats, fetchSeasonStats } from '../../actions/playerDetailAction';
import { LIGHT_THEME, CONTENT_TYPE } from '../../constants/globalConstants';
import { PLAYER_PROFILE } from '../../constants/containerConstants';
import { getSponsorData } from '../../utils/utils';
import Sponsor from '../Common/Sponsor';
import ProfileTabs from './ProfileTabs';
import PlayerArticle from './PlayerArticle';
import { getPlayerImg, maskButtonClass } from './PlayerProfileCommon';
import { usePlayerDetailValue } from '../../context/playerDetailContext';
const FirstTeam = ({ fields, itemId }) => {
  let timeoutId;
  const {
    ProfileImage,
    ShirtNumber,
    ContentType,
    Sponsorby,
    AuthorName,
    FirstName,
    LastName,
    __Published,
    manutds,
    PlayerTag,
    Variant,
    CTAUrl,
    IsEnablePlayerHub,
    CTAUrlPlayerHub,
  } = fields;
  const [bgClass, setBgclass] = useState('');
  const sponsorInfo = Sponsorby && getSponsorData(Sponsorby.fields);
  const [{ isMatchLive, isPlayerPlayingLive }, dispatch] = usePlayerDetailValue();
  const fetchPlayerStats = () => {
    fetchPlayerLiveStats(dispatch);
  };
  useEffect(() => {
    fetchPlayerStats();
    fetchSeasonStats(dispatch);
  }, []);
  useEffect(() => {
    timeoutId = setTimeout(
      () => {
        fetchPlayerStats();
      },
      isMatchLive && isPlayerPlayingLive ? 15000 : 60000
    );
    return () => clearTimeout(timeoutId);
  });
  return (
    fields && (
      <div
        className="player-detail"
        id="playerDetail"
        data-player-tag={PlayerTag && PlayerTag.value}
        data-optimizelycontentid={itemId && itemId}
      >
        <div
          className={`player-detail__wrapper ${bgClass} ${maskButtonClass(
            IsEnablePlayerHub,
            CTAUrlPlayerHub
          )}`}
        >
          <div className="bg-light-gray-mask"></div>
          <div className="player-detail__bg-black-mask">
            <img
              className={`player-avatar ${getPlayerImg('class', CTAUrl)}`}
              src={`${getPlayerImg('bgImg', ProfileImage)}`}
              alt={`${FirstName?.value + (LastName?.value ? '' + LastName.value : '')}`}
            />
            <div className="player-detail__bg-shirt-number" aria-hidden="true">
              {ShirtNumber && ShirtNumber.value}
            </div>
            <div className="player-detail__bg-gray-mask"></div>
          </div>
          <div className="container player-detail__main">
            <div className="row">
              <Sponsor
                contentType={ContentType && ContentType.value}
                varient={Variant && Variant.value}
                sponsorInfo={sponsorInfo}
                grid={'grid-12'}
                type={CONTENT_TYPE}
                author={AuthorName && AuthorName.value}
                cardName={ContentType && ContentType.value}
                container={PLAYER_PROFILE}
                createdDateTime={__Published && __Published.value}
                itemId={itemId}
                tags={manutds && manutds.value && manutds.value.Tags}
                theme={LIGHT_THEME}
              ></Sponsor>
              <PlayerArticle fields={fields} itemId={itemId} />
            </div>
            <div className="row">
              {<ProfileTabs fields={fields} itemId={itemId} setBgclass={setBgclass} />}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default FirstTeam;
