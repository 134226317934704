/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import LeftPanel from './LeftPanel';
import SponsorPanel from './SponsorPanel';
import ImagePanel from './ImagePanel';
import { getStatsBarData } from '../../utils/utils';
import StatsBar from '../Common/StatsBar';
import { addColorClass } from '../../data-transform/components/StatsTransform';
import ErrorBoundary from '../Common/ErrorBoundary';

const Top3Line = ({ item, t, gridClass, carousalClass }) => {
  const isPlayer =
    item && item.opta_statentitytype_t && item.opta_statentitytype_t.toLowerCase() === 'player'
      ? true
      : false;

  return (
    item && (
      <div className={`${gridClass} matchday-stats`}>
        <div className={carousalClass}>
          <div className={`stats-card top3line ${isPlayer ? 'player-section' : 'team-section'}`}>
            <SponsorPanel item={item} />
            <div className="stats-card__info">
              <LeftPanel item={item} />
              <div className="stats-card__info__right">
                <div className="chart-area">
                  {item.opta_statentities_t.map((top3Item, key) => (
                    <div className="players__notification" key={key}>
                      <div
                        className={`player-img ${isPlayer ? 'player-image' : 'team-image'}`}
                        aria-hidden="true"
                      >
                        <ErrorBoundary>
                          <ImagePanel item={top3Item} statsType={isPlayer} />
                        </ErrorBoundary>
                        <span className="notification">{top3Item.JerseyNumber}</span>
                      </div>
                      <div className="player-info" aria-hidden="true">
                        {isPlayer ? (
                          <h3>{top3Item.KnownName ? top3Item.KnownName : top3Item.LastName}</h3>
                        ) : (
                          <h3>{top3Item.AbbrevTeamName}</h3>
                        )}

                        <span className="resultdata">
                          {`${top3Item.statValues[0].Value}${
                            top3Item.statValues[0].IsPercentage == 'true' ||
                            top3Item.statValues[0].IsPercentage == true
                              ? '%'
                              : ''
                          }`}
                        </span>
                      </div>
                      <div
                        aria-hidden="true"
                        className={`line-chart line ${
                          top3Item.statValues[0].IsPercentage == 'false' ||
                          top3Item.statValues[0].IsPercentage == false
                            ? 'ispercentage-false'
                            : ''
                        }`}
                      >
                        {top3Item.statValues[0].IsPercentage == 'true' ||
                          (top3Item.statValues[0].IsPercentage == true && (
                            <span
                              style={{ width: `${top3Item.statValues[0].Value}%` }}
                              className={addColorClass(top3Item)}
                            ></span>
                          ))}
                      </div>
                      <div className="screenreader">
                        {isPlayer
                          ? `${top3Item.KnownName ? top3Item.KnownName : top3Item.LastName} ${t(
                              'Shirtnumber'
                            )}
                                ${top3Item.JerseyNumber} ${t('Goals')} ${
                              top3Item.statValues[0].Value
                            } ${
                              top3Item.statValues[0].IsPercentage == 'true' ||
                              top3Item.statValues[0].IsPercentage == true
                                ? '%'
                                : ''
                            }`
                          : `${top3Item.AbbrevTeamName} ${t('Goals')} ${
                              top3Item.statValues[0].Value
                            } ${
                              top3Item.statValues[0].IsPercentage == 'true' ||
                              top3Item.statValues[0].IsPercentage == true
                                ? '%'
                                : ''
                            }`}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <StatsBar item={getStatsBarData(item)} type="statsCard"></StatsBar>
          </div>
        </div>
      </div>
    )
  );
};
export default withTranslation()(Top3Line);
