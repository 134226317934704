/**
 *  © 2022-2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { createContext, useContext, useReducer } from 'react';
import { comingupState, comingupCarouselReducer } from '../reducers/comingupCarouselReducer';

export const ComingupContext = createContext();

export const ComingupProvider = ({ children }) => (
  <ComingupContext.Provider value={useReducer(comingupCarouselReducer, comingupState)}>
    {children}
  </ComingupContext.Provider>
);
export const useComingupValue = () => useContext(ComingupContext);

export default ComingupContext;
