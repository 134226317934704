/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_PLAYER_GRID,
  SET_PLAYER_GRID,
  ERROR_PLAYER_GRID,
} from '../constants/actions/actionConstant';
import { checkResponseDocs, createDestinationUrl } from '../utils/utils';

export const playerGridState = {
  status: null,
  filters: null,
  gridData: null,
  gridDataKeys: null,
};

/**
 * Reducer for PlayerGrid
 */
export const playerGridReducer = (state = playerGridState, { type, response, filter } = {}) => {
  switch (type) {
    case FETCH_PLAYER_GRID:
      return {
        ...state,
        status: 'fetching',
      };
    case SET_PLAYER_GRID: {
      if (response && response.data) {
        checkErrorPage(response.data, filter);
      }
      return {
        ...state,
        status: 'fetched',
        ...gridPageData(response, state),
      };
    }
    case ERROR_PLAYER_GRID: {
      redirectErrorPage();
      return {
        ...state,
        status: 'error',
      };
    }
    default:
      return state;
  }
};

const checkErrorPage = (data, filter) => {
  if (filter && filter.toLowerCase().indexOf('legends') >= 0) {
    if (!checkLegendsListCount(data)) {
      redirectErrorPage();
    }
  } else {
    if (
      !(
        data &&
        data.PlayerGridResponse &&
        data.PlayerGridResponse.grouped &&
        Object.keys(data.PlayerGridResponse.grouped).length &&
        checkGridListCount(data.PlayerGridResponse.grouped)
      )
    ) {
      redirectErrorPage();
    }
  }
};

const checkLegendsListCount = (data) => {
  let count = 0;
  if (
    data &&
    data.TopScorerResponse &&
    data.TopScorerResponse.response &&
    data.TopScorerResponse.response.numFound
  ) {
    count += data.TopScorerResponse.response.numFound;
  }
  if (
    data &&
    data.TopAppearancesResponse &&
    data.TopAppearancesResponse.response &&
    data.TopAppearancesResponse.response.numFound
  ) {
    count += data.TopAppearancesResponse.response.numFound;
  }
  if (
    data &&
    data.FormerManagerResponse &&
    data.FormerManagerResponse.response &&
    data.FormerManagerResponse.response.numFound
  ) {
    count += data.FormerManagerResponse.response.numFound;
  }
  return count;
};

const checkGridListCount = (data) => {
  let count = 0;
  Object.entries(data).forEach((value) => {
    count += value[1].doclist.numFound;
  });
  return count;
};

const redirectErrorPage = () => {
  window.location = createDestinationUrl('PageLoadFail');
};

const gridPageData = (response, state) => {
  let newState = {};
  if (response && response.data) {
    newState.filters = sortFilter(response.data);
    newState.gridData = gridData(response.data);
    newState.gridDataKeys = newState.gridData && Object.keys(newState.gridData);
  }
  return newState;
};

const sortFilter = (data) => {
  let playerInfoData = [];
  if (data && data.PageFilterResponse && checkResponseDocs(data.PageFilterResponse)) {
    if (
      data.PageFilterResponse.response &&
      data.PageFilterResponse.response.docs &&
      data.PageFilterResponse.response.docs.length > 0
    ) {
      data.PageFilterResponse.response.docs.forEach((item) => {
        playerInfoData.push({
          label_t: item.label_t,
          destinationurl_s: item.destinationurl_s,
          sortorder_t: parseInt(item.__sortorder_t),
        });
      });
      return playerInfoData.sort((a, b) => {
        return parseInt(a.sortorder_t) - parseInt(b.sortorder_t);
      });
    }
  }
};

const gridData = (data) => {
  if (data) {
    let playerData = [];
    let playerFilter = [];
    let groupObj = {
      LegendResponse: {},
    };
    groupObj.LegendResponse['grouped'] = [];
    Object.entries(data).forEach((value) => {
      if (value[0] == 'TopAppearancesResponse') {
        let legendValue = {
          doclist: value[1].response,
        };
        playerFilter[value[0]] = {
          key: 'Topappearances',
          value: legendValue,
        };
        groupObj.LegendResponse['grouped'][0] = playerFilter[value[0]];
        groupObj.LegendResponse['grouped'][0].name = 'Legends';
      } else if (value[0] == 'TopScorerResponse') {
        let legendValue = {
          doclist: value[1].response,
        };
        playerFilter[value[0]] = {
          key: 'TopScorers',
          value: legendValue,
        };
        groupObj.LegendResponse['grouped'][1] = playerFilter[value[0]];
      } else if (value[0] == 'FormerManagerResponse') {
        let legendValue = {
          doclist: value[1].response,
        };
        playerFilter[value[0]] = {
          key: 'FormerManagers',
          value: legendValue,
        };
        groupObj.LegendResponse['grouped'][2] = playerFilter[value[0]];
      } else if (value[0] == 'PlayerGridResponse') {
        playerData[value[0]] = JSON.stringify(value[1]);
        playerData[value[0]] = JSON.parse(playerData[value[0]]);
        if (playerData[value[0]].grouped) {
          Object.entries(playerData[value[0]].grouped).forEach((item) => {
            if (item[0] == 'position_t:goalkeeper') {
              playerData[value[0]].grouped[0] = {
                key: 'Goalkeepers',
                value: item[1],
              };
              delete playerData[value[0]].grouped['position_t:goalkeeper'];
            } else if (item[0] == 'position_t:defender') {
              playerData[value[0]].grouped[1] = {
                key: 'Defenders',
                value: item[1],
              };
              delete playerData[value[0]].grouped['position_t:defender'];
            } else if (item[0] == 'position_t:midfielder') {
              playerData[value[0]].grouped[2] = {
                key: 'Midfielders',
                value: item[1],
              };
              delete playerData[value[0]].grouped['position_t:midfielder'];
            } else if (item[0] == 'position_t:forward') {
              playerData[value[0]].grouped[3] = {
                key: 'Forwards',
                value: item[1],
              };
              delete playerData[value[0]].grouped['position_t:forward'];
            } else if (item[0] == 'position_t:Manager') {
              playerData[value[0]].grouped[0] = {
                key: 'Manager',
                value: item[1],
              };
              delete playerData[value[0]].grouped['position_t:Manager'];
            } else if (item[0] == 'position_t:FirstTeamCoach') {
              playerData[value[0]].grouped[1] = {
                key: 'FirstTeamStaff',
                value: item[1],
              };
              delete playerData[value[0]].grouped['position_t:FirstTeamCoach'];
            } else if (item[0] == 'position_t:ReservesCoach') {
              playerData[value[0]].grouped[2] = {
                key: 'ReservesStaff',
                value: item[1],
              };
              delete playerData[value[0]].grouped['position_t:ReservesCoach'];
            } else if (item[0] == 'position_t:AcademyCoach') {
              playerData[value[0]].grouped[3] = {
                key: 'AcademyStaff',
                value: item[1],
              };
              delete playerData[value[0]].grouped['position_t:AcademyCoach'];
            } else if (item[0] == 'position_t:Physio') {
              playerData[value[0]].grouped[4] = {
                key: 'Physio',
                value: item[1],
              };
              delete playerData[value[0]].grouped['position_t:Physio'];
            }
          });
        }
      }
    });
    if (groupObj) {
      Object.assign(playerData, groupObj);
    }
    return playerData;
  }
};
