/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import LeagueTableGrouping from './LeagueTableGrouping';

const LiveTableBlogCard = ({ item }) => {
  return (
    <div className="grid-12 matches live-table__blog">
      <LeagueTableGrouping tablesData={[item]} tableType="smallTable" />
    </div>
  );
};
export default LiveTableBlogCard;
