/**
 *  © 2022 -2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import { buildImgUrl, createDestinationUrl } from '../../../utils/utils';
import { HomeTeam, AwayTeam } from '../../../constants/globalConstants';
import { data, analytics } from '../../../utils/analytics';
import { COMINGUP_FIXTURE, COMINGUPCAROUSAL } from '../../../constants/containerConstants';

const CardFixture = (props) => {
  const { item, getDateSchudle, getTimeSchudle, commonResponse, t } = props;
  const {
    aliascompetitionname_t,
    competitionname_t,
    ispostponed_b,
    isabandoned_b,
    matchdate_tdt,
    hometeamshortname_t,
    hometeam_t,
    awayteamshortname_t,
    awayteam_t,
    itemid_s,
    contenttype_t,
    taglist_sm,
    __published_tdt,
    authorname_s,
    level_s,
    hometeamimage_s,
    awayteamimage_s,
    venuename_t,
    broadcasterdetails_s,
    _name,
  } = item;
  const {
    womenfixturectaurl_t,
    under23fixturectaurl_t,
    under18fixturectaurl_t,
    fixturectaurl_t,
    fixturectatitle_t,
    womenfixturectatitle_t,
    under23fixturectatitle_t,
    under18fixturectatitle_t,
  } = commonResponse;
  let competitionName = aliascompetitionname_t;
  competitionName = competitionName ? competitionName : competitionname_t && competitionname_t;
  const getMatchInfo = () => {
    return (
      <>
        {matchdate_tdt && <p className="dateSchudle">{getDateSchudle(matchdate_tdt)}</p>}
        {competitionName && (
          <div className="subTxt">
            <p className="ellipsisTxt">{competitionName}</p>
          </div>
        )}
      </>
    );
  };
  const getDateInfo = () => {
    if (isabandoned_b) {
      return <p className="timeSchudle abandoned-match">{t('Matchabandoned')}</p>;
    } else if (ispostponed_b) {
      return <p className="timeSchudle abandoned-match">{t('MatchPostponed')}</p>;
    } else {
      return (
        matchdate_tdt && (
          <p className="timeSchudle">
            {t('KickOff')} - {getTimeSchudle(matchdate_tdt)}
          </p>
        )
      );
    }
  };
  const getTeamName = (teamType) => {
    if (teamType === HomeTeam) {
      return hometeamshortname_t ? hometeamshortname_t : hometeam_t ? hometeam_t : '';
    } else if (teamType === AwayTeam) {
      return awayteamshortname_t ? awayteamshortname_t : awayteam_t ? awayteam_t : '';
    }
  };

  const initialData = data('card');
  const triggerAnalytics = () => {
    analytics({
      ...initialData,
      card_name: competitionname_t + ': ' + hometeam_t + ' vs ' + awayteam_t,
      container_type: COMINGUPCAROUSAL,
      content_type: COMINGUP_FIXTURE,
      button_name: returnFixtureURL(level_s),
      item_name: _name,
      item_id: itemid_s,
      tag: taglist_sm,
      created_datetime: __published_tdt !== undefined ? __published_tdt : 'N/A',
      author: authorname_s,
      destination_url: redirectToFixtureList(level_s),
    });
  };

  function returnFixtureURL(level_s) {
    switch (level_s) {
      case 'First Team':
        return fixturectatitle_t;
      case 'MU Women':
        return womenfixturectatitle_t;
      case 'Under-18s':
        return under18fixturectatitle_t;
      case 'Under-21s':
        return under23fixturectatitle_t;
      case 'Under-23s':
        return under23fixturectatitle_t;
      default:
        return fixturectatitle_t;
    }
  }

  function redirectToFixtureList(level_s) {
    switch (level_s) {
      case 'First Team':
        return createDestinationUrl(fixturectaurl_t);
      case 'MU Women':
        return createDestinationUrl(womenfixturectaurl_t);
      case 'Under-18s':
        return createDestinationUrl(under18fixturectaurl_t);
      case 'Under-21s':
        return createDestinationUrl(under23fixturectaurl_t);
      case 'Under-23s':
        return createDestinationUrl(under23fixturectaurl_t);
      default:
        return createDestinationUrl(fixturectaurl_t);
    }
  }
  function fixtureCtaUrl(level_s) {
    switch (level_s) {
      case 'First Team':
        return fixturectatitle_t && <div className="cardBtn">{fixturectatitle_t}</div>;
      case 'MU Women':
        return womenfixturectatitle_t && <div className="cardBtn">{womenfixturectatitle_t}</div>;
      case 'Under-18s':
        return (
          under18fixturectatitle_t && <div className="cardBtn">{under18fixturectatitle_t}</div>
        );
      case 'Under-21s':
        return (
          under23fixturectatitle_t && <div className="cardBtn">{under23fixturectatitle_t}</div>
        );
      case 'Under-23s':
        return (
          under23fixturectatitle_t && <div className="cardBtn">{under23fixturectatitle_t}</div>
        );
      default:
        return fixturectatitle_t && <div className="cardBtn">{fixturectatitle_t}</div>;
    }
  }

  function fixtureClickHandler() {
    triggerAnalytics();
  }

  return (
    <a
      className="card-content"
      tabIndex="-1"
      aria-hidden="true"
      style={{ outline: 'none' }}
      href={redirectToFixtureList(level_s)}
      onClick={(e) => fixtureClickHandler()}
    >
      <div className="card-data matchdata card-fixture">
        <span className="screenreader">{contenttype_t}</span>
        <div className="leftBox">
          {getMatchInfo()}
          <div className="HomeTeam_info team_info" aria-hidden="true">
            <div className="info_logo">
              {hometeamimage_s && <img src={buildImgUrl(hometeamimage_s.img1x)} alt="" title="" />}
            </div>
            <div className="info_Txt">{getTeamName(HomeTeam)}</div>
          </div>
          <div className="AwayTeam_info team_info" aria-hidden="true">
            <div className="info_logo">
              {awayteamimage_s && <img src={buildImgUrl(awayteamimage_s.img1x)} alt="" title="" />}
            </div>
            <div className="info_Txt">{getTeamName(AwayTeam)}</div>
          </div>
        </div>
        <div className="rightBox">
          {getDateInfo()}
          <div
            className={
              broadcasterdetails_s && broadcasterdetails_s.BroadcasterName
                ? broadcasterdetails_s.BroadcasterName.length > 13
                  ? 'subTxt marqueeAnim'
                  : 'subTxt'
                : venuename_t && venuename_t.length > 21
                ? 'subTxt marqueeAnim'
                : 'subTxt'
            }
          >
            {broadcasterdetails_s && broadcasterdetails_s.BroadcasterName ? (
              <p>
                <span className="txtStatus">{t('Live').toUpperCase()} </span>
                {`on ${broadcasterdetails_s.BroadcasterName}`}
              </p>
            ) : (
              venuename_t && <p>{venuename_t}</p>
            )}
          </div>
          {fixtureCtaUrl(level_s)}
        </div>
      </div>
    </a>
  );
};

export default withTranslation()(CardFixture);
