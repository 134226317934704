/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import MU from '../constants/muConstants';
import * as Utils from './utils';
import layoutConstants from '../constants/layoutConstants';
let notificationimpressionset = false;
let screenName = null;
export const analytics = (an, event) => {
  screenName = MU.screenName && MU.screenName.toLowerCase();
  let trackType = an.trackType;
  let eventName = '';
  const userConsent_off = Utils.getCookie('userConsentCookiePolicy') === 'off';
  if (!userConsent_off) {
    switch (trackType) {
      case 'card':
      case 'adcard':
      case 'question':
        eventName = 'w_card_click';
        trackAnalytics(eventName, an);
        break;
      case 'addSubscription':
        eventName = 'w_subscription_started';
        trackAnalytics(eventName, an);
        break;
      case 'manageSubscription':
        eventName = 'w_manage_my_subscription';
        trackAnalytics(eventName, an);
        break;
      case 'registrationBenefits':
        eventName = 'w_registration_benefits';
        trackAnalytics(eventName, an);
        break;
      case 'subscriptionBenefit':
        eventName = 'w_subscription_benefits';
        trackAnalytics(eventName, an);
        break;
      case 'collection':
        eventName = 'w_card_click';
        trackAnalytics(eventName, an, false, an);
        break;
      case 'mylist_on':
        eventName = 'a_mylist_click_on';
        trackAnalytics(eventName, an, false, an);
        break;
      case 'mylist_off':
        eventName = 'a_mylist_click_off';
        trackAnalytics(eventName, an, false, an);
        break;
      case 'rate':
        eventName = 'a_rate_click';
        trackAnalytics(eventName, an, false, an);
        break;
      case 'collectionExit':
        eventName = 'w_collection_exit';
        trackAnalytics(eventName, an, false, an);
        break;
      case 'spotlight':
        eventName = 'w_spotlight_click';
        trackAnalytics(eventName, an, false, an);
        break;
      case 'formView':
        eventName = 'w_form_start';
        trackAnalytics(eventName, an, 'formData');
        break;
      case 'formSubmit':
        eventName = 'w_form_submit';
        trackAnalytics(eventName, an, 'formData');
        break;
      case 'search':
        eventName = an.search_result > 0 ? 'w_successful_search' : 'w_unsuccessful_search';
        trackAnalytics(eventName, an, 'searchData');
        break;
      case 'gigyaSocialShare':
        eventName = 'w_share_initiate';
        if (typeof an.content_type !== 'undefined' && typeof an.content_type !== undefined) {
          if (an.content_type.toLowerCase() !== 'video') {
            delete an.Ooyala_id;
          }
        }
        trackAnalytics(eventName, an, 'shareData');
        break;
      case 'link':
        trackLink(an, event);
        break;
      case 'sponsor':
        //sponsor(an, event);
        trackLink(an, event);
        break;
      case 'sponsorFilter':
        sponsorFilter(an, event);
        break;
      case 'button':
        eventName = 'w_button_click';
        trackAnalytics(eventName, an);
        break;
      case 'video':
      case 'liveVideo':
      case 'collectionVideo':
        if (!!event) {
          if (event === 'fullscreen') {
            eventName = 'w_button_click';
          } else if (event === 'play') {
            eventName = 'w_video_play';
          } else if (event === 'pause') {
            eventName = 'w_video_pause';
          } else if (event === 'Complete') {
            eventName = 'w_video_complete';
          } else if (event === 'quarter') {
            eventName = 'w_video_play_25%';
          } else if (event === 'half') {
            eventName = 'w_video_play_50%';
          } else if (event === 'onethird') {
            eventName = 'w_video_play_75%';
          } else if (event === 'adStart') {
            eventName = 'w_video_ad_start';
          } else if (event === 'adOpened') {
            eventName = 'w_video_ad_click';
          } else if (event === 'adEnd') {
            eventName = 'w_video_ad_complete';
          }
          trackAnalytics(eventName, an, 'videoData');
        }
        break;
      case 'dfp':
      case 'hpto':
        eventName = 'w_ad_click';
        trackAnalytics(eventName, an, 'adData');
        break;
      case 'quizButton':
        eventName = 'w_button_click';
        trackAnalytics(eventName, an, false, an);
        break;
      case 'quizExit':
        eventName = 'w_quiz_exit';
        trackAnalytics(eventName, an, false, an);
        break;
      case 'unitedcompetition':
        eventName = 'w_card_click';
        trackAnalytics(eventName, an);
        const competitionEvent = 'w_competition_start ';
        trackAnalytics(competitionEvent, an);
        break;
      case 'unitedcompetitionSuccess':
        eventName = 'w_competition_entered';
        trackAnalytics(eventName, an);
        break;
      case 'unitedcompetitionError':
        eventName = 'w_error';
        trackAnalytics(eventName, an);
        break;
      default:
        return trackAnalytics(eventName, an);
    }
  }
};
export const data = (type) => {
  let o = {
    card_name: '',
    container_type: '',
    item_id: '',
    content_type: '',
    tag: '',
    created_datetime: '',
    author: '',
    destination_url: '',
  };
  switch (type) {
    case 'addSubscription':
    case 'manageSubscription':
      delete o.item_id;
      delete o.content_type;
      delete o.tag;
      delete o.author;
      delete o.created_datetime;
      break;
    case 'spotlight':
      o.spotlight_name = '';
      delete o.card_name;
      break;
    case 'adcard':
      o.w_item_name = '';
      o.w_theme = '';
      o.button_name = '';
      break;
    case 'question':
      o.content_type = 'question';
      break;
    case 'collection':
      o.collectionTitle = '';
      o.collectionDestUrl = '';
      break;
    case 'collectionExit':
      o.collectionTitle = '';
      o.collectionDestUrl = '';
      o.content_exit = '';
      o.content_items = '';
      o.content_items_viewed = '';
      break;
    case 'formView':
      o = {
        form_name: '',
      };
      break;
    case 'formSubmit':
      o = {
        form_name: '',
        button_name: '',
      };
      break;
    case 'search':
      o = {
        search_term: '',
        search_result: '',
      };
      break;
    case 'gigyaSocialShare':
      delete o.container_type;
      delete o.destination_url;
      o.destination_platform = '';
      o.Ooyala_id = '';
      break;
    case 'link':
      o = {
        button_name: '',
        destination_url: '',
      };
      break;
    case 'button':
      o = {
        button_name: '',
      };
      break;
    case 'video':
    case 'collectionVideo':
      delete o.card_name;
      delete o.destination_url;
      delete o.container_type;
      o.video_name = '';
      o.ooyala_id = '';
      o.video_length = '';
      o.content_type = 'video';
      break;
    case 'liveVideo':
      delete o.card_name;
      delete o.destination_url;
      delete o.container_type;
      o.video_name = '';
      o.ooyala_id = '';
      o.video_length = '';
      o.content_type = 'live-video';
      break;
    case 'dfp':
    case 'hpto':
      o = {
        ad_creative_ID: '',
        ad_lineitemID: '',
      };
      break;
    case 'quizButton':
      delete o.card_name;
      delete o.container_type;
      delete o.created_datetime;
      delete o.item_id;
      delete o.tag;
      o.content_type = 'quiz';
      o.collectionTitle = '';
      o.collectionDestUrl = '';
      o.button_name = '';
      break;
    case 'quizExit':
      o.collectionTitle = '';
      o.collectionDestUrl = '';
      o.content_items_viewed = '';
      o.content_exit = '';
      o.content_items = '';
      o.content_items_viewed = '';
      o.content_type = 'quiz';
      break;
    case 'sponsor':
    case 'sponsorFilter':
      o.button_name = '';
      break;
    case 'unitedcompetitionSuccess':
      delete o.author;
      delete o.card_name;
      delete o.container_type;
      delete o.content_type;
      delete o.created_datetime;
      delete o.destination_url;
      delete o.tag;
      o.notification_status = notificationState();
      break;
    case 'unitedcompetitionError':
      delete o.author;
      delete o.card_name;
      delete o.container_type;
      delete o.content_type;
      delete o.created_datetime;
      delete o.destination_url;
      delete o.tag;
      o.notification_status = notificationState();
      break;
    case 'unitedcompetition':
      o.notification_status = notificationState();
      break;
    case 'w_competition_start':
      o.notification_status = notificationState();
      break;
    default:
      o.trackType = type;
      //console.info(`Adobe >> ${type} >> Initial Track Data`, o);
      return o;
  }
  o.trackType = type;
  //console.info(`Adobe >> ${type} >> Initial Track Data`, o);
  return o;
};
const language = () => (MU.language ? MU.language : '');
const trackLink = (an, event) => {
  let eventName = undefined;
  let a = new RegExp('/' + window.location.host + '/');
  let attr =
    !!event &&
    ((event.currentTarget && event.currentTarget.href) || (event.target && event.target.href));
  const anchorIgnore =
    event && event.target && event.target.getAttribute('data-track-ignore-anchor');
  if (anchorIgnore && anchorIgnore === 'true') {
    eventName = 'w_internal_link_click';
    trackAnalytics(eventName, an);
  } else if (typeof attr !== typeof undefined && attr !== false && attr !== '') {
    let avoid = attr.toLowerCase().slice(0, 10);
    let hash = attr.slice(0, 1);
    let urltype = hash === '#' || avoid === 'javascript' || a.test(attr);
    if (urltype) {
      eventName = 'w_internal_link_click';
    } else {
      eventName = 'w_external_link_click';
    }
    trackAnalytics(eventName, an);
  }
};
export const sponsor = (an, event) => {
  let contentType = an && an.content_type && an.content_type.toLowerCase();
  if (contentType === 'adcard') {
    let dfpSponsor =
      event &&
      event.currentTarget &&
      event.currentTarget.parentNode &&
      event.currentTarget.parentNode.classList &&
      event.currentTarget.parentNode.classList.contains('dfp-ads__sponsor');
    !dfpSponsor && delete an.button_name;
  }
  trackLink(an, event);
};
export const sponsorFilter = (an, event) => {
  screenName = MU.screenName && MU.screenName.toLowerCase();
  if (screenName === 'video' || screenName === 'news') {
    let pageName = screenName === 'video' ? 'Video-Listing-' : 'News-Listing-';
    let filter = document.querySelector('.filter__nav li[data-filter-value].active');
    filter = filter && filter.textContent && filter.textContent.trim();
    filter && an && (an.card_name = pageName + filter + '-Filter');
    screenName && (an.container_type = (pageName + '-Filter').toLowerCase());
  } else if (screenName === 'players-and-staff') {
    let filter = document.querySelector('.team-grid__filter-tab ul li a.active');
    filter = filter && filter.textContent && filter.textContent.trim();
    filter && an && (an.card_name = 'Player-Listing-' + filter + '-Filter');
    screenName && (an.container_type = 'Player-Listing-Filter'.toLowerCase());
  }
  trackLink(an, event);
};
const hybridMCMIC = (an) => {
  let appVisitorCookie = Utils.getCookie('appVisitorID');
  let id = undefined;
  if (appVisitorCookie) {
    id = appVisitorCookie;
  } else {
    let url = decodeURIComponent(window.location.href).split('|');
    url.forEach(function (v, k) {
      let mcmid = v.slice(0, 6).toLowerCase();
      if (mcmid === 'mcmid=') {
        id = v.slice(6, v.length);
        return false;
      }
    });
  }
  return id;
};
const trackHybridPage = (an) => {
  let request = Utils.getParameterByName('request');
  let visitorId = hybridMCMIC();
  let appVisitorCookie = Utils.getCookie('appVisitorID');
  if (request === 'muapp') {
    if (visitorId) {
      if (
        (typeof appVisitorCookie == 'undefined' && typeof appVisitorCookie == undefined) ||
        appVisitorCookie !== visitorId
      ) {
        Utils.setCookie('appVisitorID', visitorId, 1);
        an.mid = visitorId;
      } else {
        an.mid = visitorId;
      }
    } else if (typeof appVisitorCookie !== 'undefined' && typeof appVisitorCookie !== undefined) {
      an.mid = appVisitorCookie;
    } else {
      an.mid = undefined;
    }
  }
  if ((!request || !visitorId) && !!appVisitorCookie) {
    an.mid = appVisitorCookie;
  }
};
const trackLoginID = (an) => {
  MU.userId && (an.login_id = MU.userId);
};
const tagsFilter = (an) => {
  if (an && (an.tag || an.video_tag)) {
    let tags = an.tag || an.video_tag;
    if (!(Object.keys(tags).length === 0)) {
      let tagsArr = [];
      if (typeof tags === 'object' && !tags.length) {
        Object.keys(tags).map((value, index) => {
          let tagVal = tags[value];
          let tagLen = tagVal && tagVal.length;
          if (tagLen > 1) {
            tagVal.map((val, ind) => {
              let tag = val && val.Tag;
              tag && tagsArr.push(tag);
              return '';
            });
          } else {
            let tag = tagVal && tagVal[0] && tagVal[0].Tag;
            tag && tagsArr.push(tag);
          }
          return '';
        });
      }
      if (tagsArr.length || tags) {
        an.tag = tagsArr.length ? tagsArr : tags;
        an.tag = an.tag
          .toString()
          .replace(/[[\]']/g, '')
          .replace(/"/g, '');
      }
    } else {
      an.tag = '';
    }
  }
};
const itemIdFilter = (an) => {
  if (an && an.item_id) {
    an.item_id = an.item_id.replace(/[{}]/g, '');
  }
};
const createFullURL = (an) => {
  if (an && an.destination_url) {
    an.destination_url = Utils.createDestinationUrl(an.destination_url);
  }
};
const undefinedCheck = (an) => {
  if (an) {
    Object.entries(an).forEach(([k, v]) => {
      if (v === 'undefined' || v === undefined || v === null || v === 'null' || v === '') {
        an[k] = '';
      }
    });
  }
};
const getMarketingID = (an) => {
  let request = Utils.getParameterByName('request');
  if (request !== 'muapp') {
    try {
      let mid = null; // Missing
      //let mid = _satellite.getVisitorId().getMarketingCloudVisitorID();
      if (mid && mid !== 'undefined' && mid !== null && mid !== 'null') {
        an.mid = mid;
      } else {
        an.mid = undefined;
      }
    } catch (e) {
      console.warn('Error in MarketingCloudVisitorID', e);
    }
  }
};
export const loginStart = (event, analyticsObj = {}) => {
  trackAnalytics('w_page_view_login_signup', {}, 'pageData', 'Login');
  let platform = Utils.getParameterByName('platform');
  let eventName = platform ? 'a_login_start' : 'w_login_start';
  let an = {
    //login_id: '',
    login_date: new Date().toISOString(),
    //gender: event.profile.gender, age: event.profile.age,
    login: eventName,
    //login_provider: '',
    platform: platform ? platform : 'web',
    ...analyticsObj,
  };
  getMarketingID(an);
  trackHybridPage(an);
  trackAnalytics(eventName, an, 'userdata');
};
export const loginComplete = (event) => {
  if (event) {
    let platform = Utils.getParameterByName('platform');
    let eventName = platform ? 'a_login_complete' : 'w_login_complete';
    let uid = event.UID;
    let an = {
      login_id: uid,
      login_date: new Date().toISOString(),
      //gender: event.profile.gender, age: event.profile.age,
      login: eventName,
      login_provider: event.loginProvider,
      platform: platform ? platform : 'web',
    };
    let cdpan = {
      gigya_id: uid,
      event: eventName,
    };
    getMarketingID(an);
    trackHybridPage(an);
    undefinedCheck(an);
    trackAnalytics(eventName, an, 'userdata');
    trackCDPLytics(eventName, cdpan, 'userdata');
  }
};
export const logoutComplete = (event) => {
  if (event) {
    let platform = Utils.getParameterByName('platform');
    let eventName = platform ? 'a_logout_complete' : 'w_logout_complete';
    let uid = event.UID;
    let an = {
      login_id: uid,
      logout_date: new Date().toISOString(),
      //gender: event.profile.gender, age: event.profile.age,
      logout: eventName,
      login_provider: event.connectedProviders,
      platform: platform ? platform : 'web',
    };
    let cdpan = {
      gigya_id: uid,
      event: eventName,
    };
    getMarketingID(an);
    trackHybridPage(an);
    undefinedCheck(an);
    trackAnalytics(eventName, an, 'userdata');
    trackCDPLytics(eventName, cdpan, 'userdata');
  }
};
export const registrationStart = (event, analyticsObj = {}) => {
  trackAnalytics('w_page_view_login_signup', {}, 'pageData', 'Sign Up');
  let platform = Utils.getParameterByName('platform');
  let eventName = platform ? 'a_registration_start' : 'w_registration_start';
  let an = {
    //login_id: '',
    registration_date: new Date().toISOString(),
    registration_status: eventName,
    //login_provider: '',
    platform: platform ? platform : 'web',
    ...analyticsObj,
  };
  getMarketingID(an);
  trackAnalytics(eventName, an, 'userdata');
};
const trackAnalytics = (eventName, an, ddoType, pageName) => {
  trackCommonAssets(eventName, an, ddoType, pageName);
  trackAdobe(eventName, an, ddoType, pageName);
};
export const registrationSubmit = (event) => {
  if (event) {
    let platform = Utils.getParameterByName('platform');
    let eventName = platform ? 'a_registration_submit' : 'w_registration_submit';
    let uid = event.response && event.response.UID;
    let an = {
      login_id: uid,
      registration_date: new Date().toISOString(),
      registration_status: eventName,
      login_provider: event.response && event.response.loginProvider,
      platform: platform ? platform : 'web',
    };
    let cdpan = {
      gigya_id: uid,
      event: eventName,
    };
    getMarketingID(an);
    trackHybridPage(an);
    undefinedCheck(an);
    trackAnalytics(eventName, an, 'userdata');
    trackCDPLytics(eventName, cdpan, 'userdata');
  }
};
export const regVerificationInit = (event) => {
  if (event) {
    let platform = Utils.getParameterByName('platform');
    let eventName = platform ? 'a_verification_initiate' : 'w_verification_initiate';
    let uid = event.response && event.response.UID;
    let loginProvider = event.response && event.response.loginProvider;
    let an = {
      login_id: uid,
      registration_date: new Date().toISOString(),
      //age: event.profile.age,
      registration_status: eventName,
      login_provider: loginProvider,
      platform: platform ? platform : 'web',
    };
    getMarketingID(an);
    trackHybridPage(an);
    undefinedCheck(an);
    trackAnalytics(eventName, an, 'userdata');
    if (
      loginProvider === 'googleplus' ||
      loginProvider === 'facebook' ||
      loginProvider === 'apple'
    ) {
      registrationComplete(event);
    }
  }
};
export const registrationComplete = (event) => {
  let platform = Utils.getParameterByName('platform');
  let eventName = platform ? 'a_registration_complete' : 'w_registration_complete';
  let uid = '';
  let loginProvider = '';
  if (event) {
    uid = event.response && event.response.UID;
    loginProvider = event.response && event.response.loginProvider;
  }
  let an = {
    login_id: uid,
    registration_date: new Date().toISOString(),
    //age: event.profile.age,
    login_provider: loginProvider,
    registration_status: eventName,
    platform: platform ? platform : 'web',
  };
  let cdpan = {
    gigya_id: uid,
    event: eventName,
  };
  getMarketingID(an);
  trackHybridPage(an);
  undefinedCheck(an);
  trackAnalytics(eventName, an, 'userdata');
  trackCDPLytics(eventName, cdpan, 'userdata');
};
export const updateProfile = (event) => {};
const trackCommonAssets = (eventName, an, ddoType, pageName) => {
  if (pageName) {
    if (pageName.collectionTitle) {
      an.page_name = pageName.collectionTitle;
    } else {
      an.page_name = typeof pageName === 'string' ? pageName : document.title;
    }
    delete an.collectionTitle;
  } else {
    an.page_name = document.title;
  }
  if (pageName && pageName.collectionDestUrl) {
    an.page_url = Utils.createDestinationUrl(pageName.collectionDestUrl);
    delete an.collectionDestUrl;
  } else {
    an.page_url = typeof window && window.location.href;
  }
  an.language = language();
  trackHybridPage(an);
  trackLoginID(an);
  tagsFilter(an);
  itemIdFilter(an);
  createFullURL(an);
  undefinedCheck(an);
};
const hybridPrefix = (an) => {
  let request = Utils.getParameterByName('request');
  let visitorId = hybridMCMIC();
  let appVisitorCookie = Utils.getCookie('appVisitorID');
  if ((request === 'muapp' && visitorId) || appVisitorCookie) {
    return true;
  } else {
    return false;
  }
};
export const impression = (an) => {
  if (an) {
    return `${an.content_type}|${an.card_name}`;
  } else {
    return '';
  }
};
const notificationPrompt = (impression) => {
  let contentType = impression && impression.split('|')[0];
  if (contentType === 'Notification_Prompt') {
    if (!notificationimpressionset) {
      notificationimpressionset = true;
    } else {
      impression = null;
    }
  }
  return impression;
};
const cardImpressionList = (loadType) => {
  let impressions = document.querySelectorAll('[data-impression]');
  let impressionsLen = impressions.length;
  let cardsList = '';
  if (impressionsLen) {
    Object.keys(impressions).map((value, index) => {
      let impression = impressions[index].getAttribute('data-impression');
      impression = impression && impression.trim();
      if (loadType) {
        impression = notificationPrompt(impression);
      } else {
        let isHeaderCard = !impressions[index].closest('header');
        impression = isHeaderCard ? notificationPrompt(impression) : '';
      }
      return impression && (cardsList += impression + '$');
    });
  }
  cardsList = cardsList.slice(0, -1);
  return cardsList;
};
const trackImpression = (eventName, ddoType, pageName, loadType) => {
  setTimeout(() => {
    let cardsList = cardImpressionList(loadType);
    if (cardsList) {
      let an = {
        cards_list: cardsList,
      };
      trackCommonAssets(eventName, an, ddoType, pageName);
      trackAdobe(eventName, an, ddoType, pageName);
    } else {
      console.log('Adobe >> Card Impression Tracking >> No Cards to track');
    }
  }, 2000);
};
export const cardImpression = () => {
  let eventName = 'w_card_impression';
  let ddoType = 'cardImpressionData';
  trackImpression(eventName, ddoType, false, false);
};
export const cardLoadImpression = () => {
  let eventName = 'w_card_load_impression';
  let ddoType = 'cardLoadImpressionData';
  trackImpression(eventName, ddoType, false, true);
};
const notificationState = () => {
  if (typeof Notification === 'undefined') {
    return 'Not Supported';
  }
  let permission = Notification.permission;
  if (permission === 'default') {
    return 'Not set';
  } else if (permission === 'denied') {
    return 'Opted Out';
  } else if (permission === 'granted') {
    return 'Opted in';
  }
};
const getSuperHeroTitle = (superhero) => {
  let title = superhero.querySelector('.super-hero__title');
  title = title && title.textContent;
  title = title && title.trim();
  title = title ? title : '';
  return title;
};
const trackSuperHero = (an) => {
  let superhero = document.querySelector('.superhero');
  let superherovideo = undefined;
  let superherobanner = undefined;
  if (superhero) {
    let video = superhero.querySelector('.mu-item.video');
    if (video) {
      superherovideo = video;
    } else {
      superherobanner = superhero;
    }
    if (superherovideo) {
      let title = getSuperHeroTitle(superherovideo);
      an.super_hero_title_image = title;
      an.super_hero_title_video = title;
    }
    if (superherobanner) {
      let title = getSuperHeroTitle(superherobanner);
      an.super_hero_title_image = title;
      an.super_hero_title_video = 'NA';
    }
    an.superhero = 'on';
  } else {
    an.superhero = 'off';
    an.super_hero_title_image = '';
    an.super_hero_title_video = '';
  }
  return an;
};
export const pageView = (collection, layoutId) => {
  screenName = MU.screenName && MU.screenName.toLowerCase();
  let an = {
    notification_status: notificationState(),
  };
  if (screenName === 'article' || screenName === 'printproduct') {
    // case for article detail page
    const articleAssets = [];
    const articleAdsElem = document.querySelectorAll('.wide-add-panel.ad-block');
    const articleVideosElem = document.querySelectorAll('.home-article-detail__video-panel');
    const totalImagesElem = document.querySelectorAll('.home-article-detail .mu-item .img-holder');
    const articleImages =
      totalImagesElem && articleVideosElem && totalImagesElem.length && articleVideosElem.length
        ? totalImagesElem.length - articleVideosElem.length
        : 0;
    articleImages ? articleAssets.push(`${articleImages} Images`) : articleAssets.push(`0 Images`);
    articleVideosElem && articleVideosElem.length
      ? articleAssets.push(`${articleVideosElem.length} Videos`)
      : articleAssets.push(`0 Videos`);
    articleAdsElem && articleAdsElem.length
      ? articleAssets.push(`${articleAdsElem.length} Advertisements`)
      : articleAssets.push(`0 Advertisements`);
    const articleDetailContainer = document.querySelector('.home-article-detail');
    const objPageViewData =
      articleDetailContainer && articleDetailContainer.getAttribute('data-article-detail');
    const objPageView =
      typeof objPageViewData === 'string' ? JSON.parse(objPageViewData) : objPageViewData;
    if (objPageView) {
      an.article_assets = articleAssets && articleAssets.length > 0 && articleAssets.join(' | ');
      an.author = objPageView && objPageView.author;
      an.content_type = objPageView && objPageView.content_type;
      an.created_datetime = objPageView && objPageView.created_datetime;
      an.article_name = objPageView && objPageView.article_name;
      an.tag = objPageView && objPageView.tag;
    }
  }
  if (screenName === 'unitedcompetition') {
    const compDetailContainer = document.querySelector('.home-article-detail');
    const objCompPageViewData =
      compDetailContainer && compDetailContainer.getAttribute('data-article-detail');
    const objCompPageView =
      typeof objCompPageViewData === 'string'
        ? JSON.parse(objCompPageViewData)
        : objCompPageViewData;

    if (objCompPageView) {
      an.author = objCompPageView && objCompPageView.author;
      an.content_type = objCompPageView && objCompPageView.content_type;
      an.created_datetime = objCompPageView && objCompPageView.created_datetime;
      an.competition_name = objCompPageView && objCompPageView.competition_name;
      an.tag = objCompPageView && objCompPageView.tag;
    }
  }
  if (screenName === 'home') {
    trackSuperHero(an);
  }
  let eventName = 'w_page_view';
  if (collection) {
    trackAnalytics(eventName, an, 'pageData', collection);
  } else {
    trackAnalytics(eventName, an, 'pageData');
  }
  if (
    layoutId === layoutConstants.HOME_LAYOUT ||
    layoutId === layoutConstants.TEMPLATE_LAYOUT ||
    layoutId === layoutConstants.NEWS_DETAIL_LAYOUT
  ) {
    cardImpression();
  }
};

const adobeEventName = (eventName) => {
  if (eventName) {
    let hybrid = hybridPrefix();
    let eventPrefix = eventName;
    let a_ = 'a_';
    if (hybrid) {
      eventPrefix = a_ + eventPrefix.slice(2, eventPrefix.length);
    }
    return eventPrefix;
  } else {
    return 'adobeEventNameUndefined';
  }
};
const trackAdobe = (eventName, anObject, ddoType, pageName) => {
  let an = {
    ...anObject,
  };
  delete an.trackType;
  let ddotype = ddoType ? ddoType : 'cardData';
  try {
    window.DDO[ddotype] = an;
    let adobePrefixEventName = adobeEventName(eventName);
    window._satellite.track(adobePrefixEventName);
  } catch (e) {
    console.warn('Adobe >> _satellite not define');
  }
  console.info(`Adobe >> ${eventName} >> ${ddotype} >>`, an);
};

const trackCDPLytics = (eventName, anObject, ddoType) => {
  try {
    if (window.jstag) {
      let an = {
        ...anObject,
      };
      let ddotype = ddoType ? ddoType : 'userData';
      window.jstag.send(an);
      console.info(`CDP Lytics >> ${eventName} >> ${ddotype} >>`, an);
    } else {
      console.warn('CDP Lytics >> jstag not define');
    }
  } catch (e) {
    console.warn('CDP Lytics >> error');
  }
};

export const videoEvents = (action, obj, ddoType, componentType) => {
  let eventName = action;
  if (obj) {
    if (ddoType === 'liveVideoData') {
      obj.content_type = 'live-video';
      ddoType = 'videoData';
    } else if (obj.content_type !== 'podcast' && obj.content_type !== 'schedule') {
      obj.content_type = 'video';
    }
  }
  if (componentType && componentType.containerType === 'collectionVideo') {
    trackAnalytics(eventName, obj, ddoType, componentType);
  } else {
    trackAnalytics(eventName, obj, ddoType);
  }
};
