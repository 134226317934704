/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useRef, useState } from 'react';
import ImageFallback from '../ImageFallback';

const EventCountDownTimer = (props) => {
  const [timer, setTime] = useState('-- DAY -- HR -- MIN -- SECOND');
  const timerFlag = useRef(null);
  const { EventDate, EventHeadline, Image } = props.fields;
  const EventDateValue = EventDate ? EventDate.value : null;
  useEffect(() => {
    getTimer();
  }, []);

  const getUtcDate = (EventDateValue) => {
    //to have proper date format as return value
    if (EventDateValue) {
      let yr, mt, dt, hs, ms, ss;
      yr = EventDateValue.substring(0, 4);
      mt = EventDateValue.substring(5, 7);
      dt = EventDateValue.substring(8, 10);
      hs = EventDateValue.substring(11, 13);
      ms = EventDateValue.substring(14, 16);
      ss = EventDateValue.substring(17, 19);
      //to check the date format of the date, null and undefined check for each variable
      if (yr && mt && dt && hs && ms && ss) {
        const utcDate = `${yr}-${mt}-${dt}T${hs}:${ms}:${ss}.000Z`; // ISO-8601 formatted date returned from server
        return utcDate;
      } else return null;
    } else return null;
  };

  const getTimer = () => {
    try {
      let utcDate = getUtcDate(EventDateValue);
      if (utcDate) {
        const countDownDate = new Date(utcDate).getTime();
        //To check date  proper format as required
        if (!isNaN(countDownDate)) {
          timerFlag.current = setInterval(() => {
            // Get today's date and time
            let now = new Date().getTime();
            // Find the distance between now and the count down date
            let distance = countDownDate - now;
            // Time calculations for days, hours, minutes and seconds
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);
            let ddy = 'DAYS';
            let hhs = 'HRS';
            let mms = 'MINS';
            let ses = 'SECONDS';
            if (days <= 1) {
              ddy = 'DAY';
            }
            if (hours <= 1) {
              hhs = 'HR';
            }
            if (minutes <= 1) {
              mms = 'MIN';
            }
            if (seconds <= 1) {
              ses = 'SECOND';
            }
            // Output the result to set the timer
            setTime(`${days} ${ddy} ${hours} ${hhs} ${minutes} ${mms} ${seconds} ${ses}`);
            // If the count down is over, write some text
            if (distance < 0) {
              clearInterval(timerFlag.current);
              setTime('00 DAY 00 HR 00 MIN 00 SECOND');
            }
          }, 1000);
        }
      }
    } catch (error) {
      console.log('Event timer error');
      setTime('-- DAY -- HR -- MIN -- SECOND');
    }
  };
  const image =
    Image.value && Image.value.Crops && Image.value.Crops.img1x ? Image.value.Crops.img1x : null;
  const altText = Image && Image.value && Image.value.AltText ? Image.value.AltText : null;
  const EventHeadlineValue = EventHeadline && EventHeadline.value ? EventHeadline.value : null;
  return (
    <section
      className="container event-tagtimer"
      style={{ paddingBottom: '30px', paddingTop: '30px' }}
    >
      <div className="col-md-4">
        <div className="sponser_image_tp" style={{ outline: 'none' }}>
          <ImageFallback
            src={image}
            onErrorClass={'no-img'}
            className={image && 'sponsor__light'}
            alt={altText && altText}
            title={altText && altText}
          />
        </div>
        <div className="outer_tagtimer">
          <div className="tagtimer" id="tagtimer">
            {timer && timer}
          </div>
          <div className="tagsponsor">{EventHeadlineValue && EventHeadlineValue}</div>
        </div>
      </div>
    </section>
  );
};
export default EventCountDownTimer;
