/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import {
  replaceDoubleQuoteWithQTag,
  replaceDoubleQuoteWithBlockQuoteTag,
  replaceTwitterWidgetWithAmpTwitterTag,
} from '../../../utils/articleUtils';

const AMPTextbox = ({ value }) => {
  const getTextboxContent = (value) => {
    if (value.includes('<p') || value.includes('<P')) {
      return replaceDoubleQuoteWithQTag(replaceTwitterWidgetWithAmpTwitterTag(value));
    } else {
      return replaceDoubleQuoteWithBlockQuoteTag(replaceTwitterWidgetWithAmpTwitterTag(value));
    }
  };
  return (
    <div
      className="content-panel_text"
      dangerouslySetInnerHTML={{ __html: getTextboxContent(value) }}
    />
  );
};

export default AMPTextbox;
