/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import ProfileData from './ProfileData';
import NewsArticleData from './NewsArticleData';
import VideoData from './VideoData';
import ImageData from './ImageData';
import MatchcenterData from './MatchcenterData';
import LiveEventsData from './LiveEventsData';
import MatchCarouselData from './MatchCarouselData';
import NewsArticleAmpData from './NewsArticleAmpData';
import { useLocation } from 'react-router-dom';
import { STRUCTURE_DATA_SUPPORTED_CONTENT_TYPE } from '../../constants/globalConstants';
const StructuredData = (props) => {
  const { structuredata } = props.structuredData;
  const { ContentType } = props.fields;
  const { isAmp } = props;
  const { pathname } = useLocation();
  const componetMapping = {
    playerprofile: ProfileData,
    managerprofile: ProfileData,
    coachprofile: ProfileData,
    video: VideoData,
    image: ImageData,
    article: NewsArticleData,
    matchcarousel: MatchCarouselData,
  };
  const getStructureTemplate = () => {
    if (isAmp) {
      return <NewsArticleAmpData structureData={structuredata} />;
    } else {
      const contentType = ContentType && ContentType.value && ContentType.value.toLowerCase();
      let StructureTemplate;
      if (contentType && STRUCTURE_DATA_SUPPORTED_CONTENT_TYPE.includes(contentType)) {
        StructureTemplate = componetMapping[contentType];
        return <StructureTemplate structureData={structuredata} />;
      } else if (
        pathname &&
        pathname.toLowerCase() &&
        pathname.toLowerCase().includes('matches/matchcenter')
      ) {
        return <MatchcenterData structureData={structuredata} />;
      } else if (
        pathname.includes('events/liveevents') ||
        pathname.includes('Events/LiveEvents/')
      ) {
        return <LiveEventsData structureData={structuredata} />;
      } else return null;
    }
  };
  return structuredata ? getStructureTemplate() : null;
};
export default StructuredData;
