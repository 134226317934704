/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import { fetchShowListing } from '../../../actions/showListingAction';
import { ShowListingProvider, useShowListingValue } from '../../../context/showListingContext';
import { useSponsorValue } from '../../../context/sponsorContext';
import MutvHeroVideo from '../Cards/Hero/MutvHeroVideo';
import ShowCarouselListingChild from './ShowCarouselListingChild';
import * as track from '../../../utils/analytics';
import { SHOW_CAROUSEL } from '../../../constants/containerConstants';
import { heroDataMapping, playVideoOrNot } from '../mutvutil';

const ShowCarouselListing = (props) => (
  <ShowListingProvider>
    <ShowCarouselContainer {...props} />
  </ShowListingProvider>
);

const ShowCarouselContainer = (props) => {
  const [
    { cardData, seriesType, itemsNumber, heroDetails, isClipVideo, initialLoad, isMutvMovie },
    dispatch,
  ] = useShowListingValue();
  const [{ isLoginChecked, isUserLogin, isUserSubscribe, isUserSkyId }] = useSponsorValue();
  const { itemid } = props;
  const [heroVideodata, setHeroVideodata] = useState(null);

  useEffect(() => {
    callApi(0, null, 'default', 'episode');
  }, []);

  useEffect(() => {
    if (isLoginChecked && cardData && cardData.episode) {
      const data = cardData.episode.find((value) =>
        playVideoOrNot(isUserLogin, value.contentaccess_t)
      );
      const destURL = data ? data.destinationurl_s : cardData.episode[0].destinationurl_s;
      setHeroVideodata(destURL);
    }
  }, [cardData, isLoginChecked, isUserLogin]);

  const callApi = (startIndex, activeFilter, actionType, tabSelected) => {
    fetchShowListing(
      dispatch,
      itemid,
      startIndex,
      itemsNumber,
      activeFilter,
      actionType,
      seriesType,
      tabSelected
    ).then(() => {
      setTimeout(() => {
        if (actionType === 'default') {
          track.cardImpression();
        } else {
          track.cardLoadImpression();
        }
      }, 500);
    });
  };

  return (
    <React.Fragment>
      <MutvHeroVideo
        heroData={heroDetails ? heroDataMapping(heroDetails) : {}}
        listingData={heroVideodata}
        isLoginChecked={isLoginChecked}
        isUserLogin={isUserLogin}
        maskRequired={true}
        isShowScreen={true}
        containerType={SHOW_CAROUSEL}
        isClipVideo={isClipVideo}
        initialLoad={initialLoad}
        isUserSubscribe={isUserSubscribe}
        isUserSkyId={isUserSkyId}
        isMutvMovie={isMutvMovie}
      />
      <ShowCarouselListingChild
        containerType={SHOW_CAROUSEL}
        cardData={cardData}
        itemsNumber={itemsNumber}
        callApi={callApi}
        isShowScreen={true}
      />
    </React.Fragment>
  );
};
export default ShowCarouselListing;
