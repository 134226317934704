import React, { useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { localeConfig } from '../../utils/cardDateFormat';
import ImgCrop from '../Common/ImgCrop';
import { useModal } from '../Common/Modal';
import * as track from '../../utils/analytics';
import { SEARCH } from '../../constants/containerConstants';
import loadable from '@loadable/component';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import ErrorBoundary from '../Common/ErrorBoundary';
import { Video } from '../../data-transform/components/Video';
import {
  createDestinationUrl,
  createTagList,
  validateCbr,
  getLoggedInStatus,
  isMutvVideo,
} from '../../utils/utils';
import { useSponsorValue } from '../../context/sponsorContext';
import Loader from '../Common/Loader';
import AccessBadge from '../Common/AccessBadge';
const VideoModal = loadable(() => import('../Modals/VideoModal/VideoModal'));
const RegistrationModal = loadable(() => import('../Modals/RegistrationModal/RegistrationModal'));
const MutvSearch = ({ results, resultsCount, t, language }) => {
  const [{ isUserLogin, isLoginChecked, isUserSubscribe, isUserSkyId }] = useSponsorValue();
  const {
    showModal: cbrShowModal,
    closeModal: cbrCloseModal,
    isModalVisible: cbrModalVisible,
  } = useModal();
  const { showModal, closeModal, isModalVisible } = useModal();
  const [fields, setFields] = useState({});
  const [itemId, setItemId] = useState(null);
  const contentAccessValue = useRef({});

  const getAnalyticsData = (result, contentStatus) => {
    const initialData = track.data('card');
    return {
      ...initialData,
      card_name: result.headline_t,
      container_type: SEARCH,
      item_id: result.itemid_s,
      content_type: result.contenttype_t,
      content_status: contentStatus,
      subscription_status: getLoggedInStatus({
        isLoginChecked,
        isUserLogin,
        isUserSubscribe,
        isUserSkyId,
      }),
      tag: result.taglist_sm && createTagList(result.taglist_sm, result.category_s),
      created_datetime: result.appearance_tdt,
      author: result.authorname_s,
      destination_url: result.destinationurl_s,
    };
  };

  const trackCard = (e, result, contentStatus) => {
    track.analytics(getAnalyticsData(result, contentStatus), e);
  };

  const linkURL = (result) => {
    const tagList = result.taglist_sm;
    return tagList && isMutvVideo(createTagList(tagList, result.category_s))
      ? createDestinationUrl(result.destinationurl_s)
      : '#';
  };

  const onCardClick = (e, result) => {
    const destPage = linkURL(result);
    const showCbr = validateCbr({
      isUserLogin,
      isUserSubscribe,
      contentAccess: result.contentaccess_t,
      contentType: result.contenttype_t,
      manutds: result.taglist_sm,
      isAws: true,
    });
    trackCard(e, result, showCbr && showCbr.accessCheck);
    if (destPage && destPage !== '#') {
      window.location.href = destPage;
    } else {
      e.preventDefault();
      const videoData = Video(result, true);
      setFields(videoData.fields);
      setItemId(videoData.rendering && videoData.rendering.dataSource);
      if (showCbr && showCbr.value) {
        contentAccessValue.current = {
          value: showCbr.accessCheck,
          url: createDestinationUrl(result.destinationurl_s),
          result,
        };
        cbrShowModal();
      } else {
        showModal();
      }
    }
  };

  const renderHtml = (result, index) => {
    result.appearance_tdt = result.__published_tdt;
    return (
      <React.Fragment>
        {result.contenttype_t && result.contenttype_t.toLowerCase() === 'video' && (
          <div>
            <div className="search__items">
              <div className="search__items__detail video">
                <div className="details">
                  <div className="detail__left">
                    <div className="img-holder">
                      <ImgCrop
                        imgCrop={
                          result.celumimagesvariant_s &&
                          result.celumimagesvariant_s.ImageCropUrl.CropUrl
                        }
                        alt={result.AltText}
                        title={result.AltText}
                      />
                    </div>
                    <span>
                      <i className="icon-play" aria-hidden="true"></i>
                    </span>
                  </div>
                  <div className="detail__right">
                    <a
                      className="search-card-click"
                      href={createDestinationUrl(result.destinationurl_s)}
                      onClick={(e) => onCardClick(e, result)}
                    >
                      <AccessBadge
                        contentAcessLocal={result.contentaccess_t}
                        contentTypeLocal={result.contenttype_t}
                        tagListLocal={result.taglist_sm}
                        isSearchPage={true}
                      />
                      <h2>
                        <span className="title">
                          <span>
                            {result.shortheadline_t}
                            <span className="screenreader">{t('Video')}</span>
                          </span>
                        </span>
                      </h2>
                      <h2 className="mobile-view">
                        <span>{result.shortheadline_t}</span>
                      </h2>
                      <p className="teasure">
                        {result.appearance_tdt && (
                          <RichText
                            tag="strong"
                            field={{ value: localeConfig(result.appearance_tdt, language, t) }}
                            editable={false}
                          />
                        )}
                        {result.appearance_tdt && <span>{' - '}</span>}
                        {result.teaser_t}
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  const successCallback = ({ showContent }) => {
    if (showContent && contentAccessValue.current) {
      showModal();
    }
  };

  return (
    <React.Fragment>
      {resultsCount > 0 && (
        <div className="grid-12">
          {results &&
            results.map((result, index) => <div key={index}>{renderHtml(result, index)}</div>)}
          {cbrModalVisible && (
            <ErrorBoundary>
              <RegistrationModal
                successCallback={successCallback}
                closeModal={cbrCloseModal}
                fallback={<Loader />}
                previousActiveElement={document.activeElement}
                accessType={contentAccessValue.current && contentAccessValue.current.value}
                analyticsObj={getAnalyticsData(
                  contentAccessValue.current.result,
                  contentAccessValue.current.value
                )}
              />
            </ErrorBoundary>
          )}
          {isModalVisible && (
            <ErrorBoundary>
              <VideoModal
                cardName={fields.Teaser && fields.Teaser.value}
                author={fields.AuthorName && fields.AuthorName.value}
                closeModal={closeModal}
                itemid={itemId}
                data={fields}
                contentAccessState={contentAccessValue.current.value}
              />
            </ErrorBoundary>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(MutvSearch);
