/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_MATCH_CENTER,
  SET_MATCH_CENTER,
  ERROR_MATCH_CENTER,
  FETCH_SPOTLIGHT,
  SET_SPOTLIGHT,
  ERROR_SPOTLIGHT,
  IS_IN_VIEW,
  UPDATE_FEED,
} from '../constants/actions/actionConstant';
import { getLineupFormation } from '../utils/matchUtils';
import { checkResponseDocs } from '../utils/utils';
import { findLastIndex, differenceWith, drop, uniqBy } from 'lodash';

export const matchCenterState = {
  status: null,
  cronTimeInterval: 30000,
  showloadMore: false,
  reTry: false,
  rowsCount: 30,
  serviceAction: 'default',
  timeStampValue: 'all',
  LineupResponse: null,
  ListingResponse: null,
  HighlightMatchStatsResponse: null,
  ImageResponse: null,
  matchId: null,
  lastCard: null,
  isInViewPort: true,
  notificationCount: 0,
  updatedRecordsList: [],
  loader: true,
  SpotlightResponse: null,
  TimerResponse: null,
  contentType: null,
};

export const matchCenterReducer = (state = matchCenterState, { type, payload } = {}) => {
  switch (type) {
    case FETCH_MATCH_CENTER:
      return {
        ...state,
        status: 'fetching',
        loader: payload.serviceAction !== 'cronJob',
        matchId: payload.matchId,
      };
    case SET_MATCH_CENTER: {
      let newstate = {
        ...state,
        status: 'fetched',
        loader: false,
        ...filterMatchdayData(payload, state),
      };
      return newstate;
    }
    case ERROR_MATCH_CENTER:
      return { ...state, status: 'error', ...errorHandling(state) };
    case FETCH_SPOTLIGHT:
      return { ...state, status: 'fetching' };
    case SET_SPOTLIGHT:
      return { ...state, status: 'fetched', ...updateSpotlightLive(payload.response.data) };
    case ERROR_SPOTLIGHT:
      return {
        ...state,
        status: 'error',
        SpotlightResponse: state.SpotlightResponse,
        TimerResponse: state.TimerResponse,
      };
    case IS_IN_VIEW:
      if (payload.isInViewPort !== state.isInViewPort) {
        return { ...state, ...updateOnScroll(payload.isInViewPort, state) };
      }
      return state;
    case UPDATE_FEED:
      return { ...state, ...updateFeedListing(state) };
    default:
      return state;
  }
};

const errorHandling = (state) => {
  let newState = {};
  newState.reTry = true;
  return newState;
};

/**
 * @param {update newState ImageResponse Object for Image slider if not then retun imageObj from spotLight response} data
 * @param {global object of newState which finally merged with state} newState
 */
const updateImageSlider = (data, state) => {
  let newState = {};
  if (data && checkResponseDocs(data.ImageResponse)) {
    newState.ImageResponse = data.ImageResponse.response.docs;
  } else if (data && checkResponseDocs(data.SpotlightResponse)) {
    newState.ImageResponse = [
      { imagecropurl_s: data.SpotlightResponse.response.docs[0].imagecropurl_s },
    ];
  } else {
    newState.ImageResponse = state.ImageResponse;
  }
  return newState;
};

/**
 * @param {update newState HighlightResponse Object for Matchhighlight} data
 * @param {global object of newState which finally merged with state} newState
 */
const updateMatchHighlight = (data, state) => {
  let newState = {};
  try {
    if (
      data &&
      data.HighlightMatchStatsResponse &&
      checkResponseDocs(data.HighlightMatchStatsResponse)
    ) {
      newState.HighlightMatchStatsResponse = data.HighlightMatchStatsResponse.response.docs[0];
    } else {
      newState.HighlightMatchStatsResponse = state.HighlightMatchStatsResponse;
    }
  } catch (e) {
    newState.HighlightMatchStatsResponse = state.HighlightMatchStatsResponse;
    console.log('Error in MatchHighlight Response >', e);
  }
  return newState;
};

/**
 * @param {update newState LineupResponse Object for Lineup RHS. It does all the calculation of linup and return final data} data
 * @param {global object of newState which finally merged with state} newState
 */
const updateLineup = (data, newState) => {
  try {
    data.lineup =
      data &&
      data.LineupResponse &&
      checkResponseDocs(data.LineupResponse) &&
      data.LineupResponse.response.docs[0];
    data.spotlight =
      data &&
      data.SpotlightResponse &&
      checkResponseDocs(data.SpotlightResponse) &&
      data.SpotlightResponse.response.docs[0];
    drawLinup(data, newState);
  } catch (e) {
    console.log('Error in Lineup Response >', e);
  }
};

/**
 * @param {responseData of lineup} responseData
 */

const drawLinup = (responseData, newState) => {
  const lineUpData = { isDataAvaliable: true, showLineup: false };
  if (
    responseData &&
    responseData.lineup &&
    responseData.lineup.lineupdata_t &&
    responseData.lineup.lineupdata_t.value &&
    responseData.lineup.lineupdata_t.value.length > 0
  ) {
    let data_t = responseData.lineup.lineupdata_t.value[0];
    let matchEvent = responseData.lineup.matchkeyevents_t;
    if (data_t && data_t.MatchDataList && data_t.MatchDataList.length > 0) {
      lineUpData.homeTeamName = data_t.MatchDataList[0].HomeTeam;
      lineUpData.awayTeamName = data_t.MatchDataList[0].AwayTeam;
      matchEvent &&
        buildTeam(data_t.MatchDataList[0], matchEvent, 'HomeTeam', lineUpData, newState);
      matchEvent &&
        buildTeam(data_t.MatchDataList[0], matchEvent, 'AwayTeam', lineUpData, newState);
    }
  }
  if (responseData && responseData.spotlight && responseData.spotlight.contenttype_t) {
    let contentType = responseData.spotlight.contenttype_t.toLowerCase();
    let isLineupAvailable = responseData.spotlight.islineupavailable_b
      ? responseData.spotlight.islineupavailable_b
      : false;
    let data_t =
      responseData.lineup &&
      responseData.lineup.lineupdata_t &&
      responseData.lineup.lineupdata_t.value[0];

    if ((contentType === 'fixture' || contentType === 'timer') && isLineupAvailable) {
      lineUpData.showLineup = true;
    } else if (contentType === 'live' || contentType === 'result') {
      data_t && data_t.MatchDataList && data_t.MatchDataList.length > 0
        ? (lineUpData.showLineup = true)
        : (lineUpData.isDataAvaliable = false);
    }
    newState.LineupResponse = lineUpData;
  } else {
    newState.LineupResponse = lineUpData;
  }
};

/**
 *
 * @param {both team playerlist data} players
 * @param {matchevent data for subsitute and time} matchEvent
 * @param {home or away team} teamType
 * @param {global data of lineup and update this date in state} lineUpData
 */
const buildTeam = (players, matchEvent, teamType, lineUpData, newState) => {
  let playersList = players[teamType].TeamPlayers;
  let formationused = players[teamType].FormationUsed;
  if (
    playersList &&
    playersList.length > 0 &&
    matchEvent &&
    matchEvent[teamType] &&
    matchEvent[teamType].Substitutions &&
    matchEvent[teamType].Substitutions.length > 0
  ) {
    let matchEventObj = matchEvent[teamType].Substitutions;
    for (let i = 0; i < playersList.length; i++) {
      let type = playersList[i].Status === 'Start' ? 'SubOffPlayer' : 'SubOnPlayer';
      let type1 = playersList[i].Status === 'Start' ? 'SubOnPlayer' : 'SubOffPlayer';
      matchEventObj.forEach((val) => {
        if (val[type] && val[type].FID && playersList[i].Player && playersList[i].Player.FID) {
          if (val[type].FID === playersList[i].Player.FID) {
            playersList[i].subPlayer = val[type1];
            playersList[i].time = val.Time ? val.Time : '';
          }
        }
      });
    }
  }
  lineupFormation(playersList, formationused, teamType, lineUpData, newState);
};

/**
 * @param {both team playerlist data} playersList
 * @param {lineup formation to show the players} formationused
 * @param {home/away team} teamType
 * @param {global data of lineup} lineUpData
 */
const lineupFormation = (playersList, formationused, teamType, lineUpData, newState) => {
  let startPlayers = [],
    subPlayers = [];
  let formationObj = getLineupFormation();
  let formatePattern =
    formationused && typeof formationused !== undefined && formationused !== 'undefined'
      ? formationObj[formationused]
      : formationObj[442];

  if (formatePattern && formatePattern.length > 0) {
    /*  filtering playerlist as per formation */
    formatePattern.forEach((val) => {
      for (let i = 0; i < playersList.length; i++) {
        if (
          playersList[i] &&
          playersList[i].Player &&
          playersList[i].Player.FormationPlace &&
          val === parseInt(playersList[i].Player.FormationPlace)
        ) {
          startPlayers.push(playersList[i]);
        }
      }
    });
    /*  spliting Subplayers from playerList */
    for (let j = 0; j < playersList.length; j++) {
      if (playersList[j].Status === 'Sub') {
        subPlayers.push(playersList[j]);
      }
    }
  }
  lineUpData[teamType] = { startPlayers };
  lineUpData[teamType].Subs = subPlayers;
  newState.LineupResponse = lineUpData;
};

const updateFeedListing = (state) => {
  let newState = {};
  newState.ListingResponse =
    state.updatedRecordsList.length > 0 ? state.updatedRecordsList : state.ListingResponse;
  newState.notificationCount = 0;
  newState.updatedRecordsList = [];
  return newState;
};

const updateOnScroll = (isInView, state) => {
  let newState = {};
  newState.isInViewPort = isInView;
  if (isInView && state.updatedRecordsList && state.updatedRecordsList.length > 0) {
    newState.ListingResponse = state.updatedRecordsList;
    newState.notificationCount = 0;
    newState.updatedRecordsList = [];
  }
  return newState;
};
/**
 * @param {update newState ListingResponse Object for matchday feeds} data
 * @param {global object of newState which finally merged with state} newState
 */
const updateListing = (payload, state) => {
  let data = payload.response.data;
  const { matchId, serviceAction, timeStampValue } = payload;
  let newState = {};
  const isInViewPort = state.isInViewPort;
  let listingRes =
    data && checkResponseDocs(data.ListingResponse) ? data.ListingResponse.response.docs : [];
  if (listingRes && listingRes.length > 0) {
    let timeStampKey = matchId + '_matchday';
    newState.lastCard = listingRes[listingRes.length - 1][timeStampKey];
    newState.showloadMore = listingRes.length < 30 ? false : true;
    newState.matchId = matchId;
    newState.serviceAction = serviceAction;
    newState.timeStampValue = timeStampValue;
    newState.reTry = false;
    if (serviceAction === 'default') {
      newState.ListingResponse = listingRes;
    } else if (serviceAction === 'loadMore') {
      newState.ListingResponse = uniqBy([...state.ListingResponse, ...listingRes], 'itemid_s');
    } else if (serviceAction === 'cronJob') {
      var updatedRecordsList = listingRes;
      var notifCount = 0;
      var matchedIndex = findLastIndex(state.ListingResponse, {
        itemid_s: listingRes[listingRes.length - 1].itemid_s,
      });
      if (matchedIndex !== -1) {
        notifCount = differenceWith(listingRes, state.ListingResponse, function (obj1, obj2) {
          return obj1.itemid_s === obj2.itemid_s;
        }).length;
        updatedRecordsList = uniqBy(
          [...listingRes, ...drop(state.ListingResponse, matchedIndex + 1)],
          'itemid_s'
        );
      }
      if (isInViewPort && updatedRecordsList.length > 0) {
        newState.lastCard = updatedRecordsList[updatedRecordsList.length - 1][timeStampKey];
        newState.ListingResponse = updatedRecordsList;
        newState.notificationCount = 0;
        newState.updatedRecordsList = [];
      } else if (!isInViewPort && notifCount > 0) {
        newState.updatedRecordsList = updatedRecordsList;
        newState.notificationCount = notifCount;
      }
    }
  }
  return newState;
};
const updateTimerSpotlight = (timerItem) => {
  let innerText = '';
  let injuryText = false;
  if (timerItem && timerItem.optacontent) {
    var { TimerMinutes, TimerSeconds, Period } = timerItem.optacontent;
    TimerMinutes = TimerSeconds > 0 ? parseInt(TimerMinutes) + 1 : TimerMinutes;
    if (TimerMinutes && Period) {
      Period = Period.toLowerCase();
      if (Period === 'halftime' || Period === 'half time') {
        injuryText = false;
        innerText = 'HT';
        timerItem.injuryTime = injuryText;
        timerItem.timerText = innerText;
        return;
      } else if (
        Period === 'fulltime' ||
        Period === 'fulltime90' ||
        Period === 'full time 90' ||
        Period === 'full time'
      ) {
        innerText = 'FT';
        injuryText = false;
        timerItem.injuryTime = injuryText;
        timerItem.timerText = innerText;
        return;
      } else if (Period === 'extrahalftime' || Period === 'extra half time') {
        injuryText = false;
        innerText = 'HT<span class="extra__time">ET</span>';
        timerItem.injuryTime = injuryText;
        timerItem.timerText = innerText;
        return;
      } else if (Period === 'extrafulltime' || Period === 'extra full time') {
        innerText = 'FT <span class="extra__time">AET</span>';
        injuryText = false;
        timerItem.injuryTime = injuryText;
        timerItem.timerText = innerText;
        return;
      } else if (Period === 'fulltimeaet' || Period === 'full time aet') {
        innerText = 'FT<span class="extra__time">AET</span>';
        injuryText = false;
        timerItem.injuryTime = injuryText;
        timerItem.timerText = innerText;
        return;
      } else if (Period === 'shootout') {
        innerText = 'FT<span class="extra__time">AET</span>';
        injuryText = false;
        timerItem.injuryTime = injuryText;
        timerItem.timerText = innerText;
        return;
      } else if (Period === 'extrahalftime') {
        innerText = 'HT<span class="extra__time">ET</span>';
        injuryText = false;
        timerItem.injuryTime = injuryText;
        timerItem.timerText = innerText;
        return;
      }
      if ((TimerMinutes > 45 && Period == 'firsthalf') || Period == 'first half') {
        innerText = '45';
        injuryText = TimerMinutes - 45;
        timerItem.injuryTime = injuryText;
        timerItem.timerText = innerText;
        return;
      } else if ((TimerMinutes > 90 && Period == 'secondhalf') || Period == 'second half') {
        innerText = '90';
        injuryText = TimerMinutes - 90;
        timerItem.injuryTime = injuryText;
        timerItem.timerText = innerText;
        return;
      } else if (
        (TimerMinutes > 105 && Period == 'extrafirsthalf') ||
        Period == 'extra first half'
      ) {
        innerText = '105';
        injuryText = TimerMinutes - 105;
        timerItem.injuryTime = injuryText;
        timerItem.timerText = innerText;
        return;
      } else if (
        (TimerMinutes > 120 && Period == 'extrasecondhalf') ||
        Period == 'extra second half'
      ) {
        innerText = '120';
        injuryText = TimerMinutes - 120;
        timerItem.injuryTime = injuryText;
        timerItem.timerText = innerText;
        return;
      } else if (
        Period == 'unknown' ||
        Period == 'un known' ||
        Period == 'prematch' ||
        Period == 'pre match'
      ) {
        innerText = TimerMinutes;
        injuryText = false;
        timerItem.injuryTime = injuryText;
        timerItem.timerText = innerText;
        return;
      } else if (TimerMinutes >= 0 && TimerMinutes < 10) {
        TimerMinutes = `0${TimerMinutes}`;
        innerText = TimerMinutes;
        injuryText = false;
        timerItem.injuryTime = injuryText;
        timerItem.timerText = innerText;
        return;
      } else {
        innerText = TimerMinutes;
        injuryText = false;
        timerItem.injuryTime = injuryText;
        timerItem.timerText = innerText;
        return;
      }
    } else {
      innerText = '00:00';
      injuryText = false;
      timerItem.injuryTime = injuryText;
      timerItem.timerText = innerText;
      return;
    }
  } else {
    innerText = '00:00';
    injuryText = false;
    timerItem.injuryTime = injuryText;
    timerItem.timerText = innerText;
    return;
  }
};
const updateSpotlightAll = (data) => {
  let newState = {};
  if (data && data.SpotlightResponse && checkResponseDocs(data.SpotlightResponse)) {
    if (data.SpotlightResponse.response.docs[0].contenttype_t !== 'live') {
      newState.SpotlightResponse = data.SpotlightResponse.response.docs[0];
      if (data && data.TimerResponse && checkResponseDocs(data.TimerResponse)) {
        data.TimerResponse.response.docs[0].injuryTime = false;
        data.TimerResponse.response.docs[0].timerText = '';
        newState.TimerResponse = data.TimerResponse.response.docs[0];
      }
    }
    newState.contentType = data.SpotlightResponse.response.docs[0].contenttype_t;
  }
  return newState;
};

export const updateSpotlightLive = (data) => {
  let newState = {};
  if (data && data.SpotlightResponse && checkResponseDocs(data.SpotlightResponse)) {
    if (data.SpotlightResponse.response.docs[0].contenttype_t === 'live') {
      newState.SpotlightResponse = data.SpotlightResponse.response.docs[0];
      if (data && data.TimerResponse && checkResponseDocs(data.TimerResponse)) {
        updateTimerSpotlight(data.TimerResponse.response.docs[0]);
        newState.TimerResponse = data.TimerResponse.response.docs[0];
      }
    }
    newState.contentType = data.SpotlightResponse.response.docs[0].contenttype_t;
  }
  return newState;
};

/**
 *
 * @param {all response data of matchday page returned and merged with state after manupulation of data as per the requirement} data
 */
const filterMatchdayData = (payload, state) => {
  if (payload && payload.response && payload.response.data) {
    let data = payload.response.data;
    let newState = {};
    newState = { ...newState, ...updateImageSlider(data, state) };
    newState = { ...newState, ...updateMatchHighlight(data, state) };
    updateLineup(data, newState);
    newState = { ...newState, ...updateListing(payload, state) };
    newState = { ...newState, ...updateSpotlightAll(data) };
    return newState;
  }
};
