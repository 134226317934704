/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { buildImgUrl } from '../../utils/utils';
import { withTranslation } from 'react-i18next';
import { formatDate } from '../../utils/momentDate';

const ResultCard = ({ t, resultData }) => {
  const {
    hometeamshortname_t,
    hometeam_t,
    awayteamshortname_t,
    awayteam_t,
    matchdate_tdt,
    competitionname_t,
    hometeamimage_s,
    resultdata_t,
    isabandoned_b,
    awayteamimage_s,
  } = resultData;
  const resultsHomeTeamName = hometeamshortname_t ? hometeamshortname_t : hometeam_t;
  const homeScoreObj = resultdata_t.HomeResult;

  const resultsAwayTeamName = awayteamshortname_t ? awayteamshortname_t : awayteam_t;
  const awayScoreObj = resultdata_t.AwayResult;
  return (
    <article className="mu-item matches-card child">
      <div className="card-wrapper">
        <p className="info__league">
          <span>{formatDate(matchdate_tdt, 'matches-page')}</span>
          <span>{competitionname_t}</span>
          <span className="horizan-line"></span>
        </p>
        <div className="info__matches">
          <ul>
            <li>
              <span className="team__logo">
                {hometeamimage_s && (
                  <img
                    src={buildImgUrl(hometeamimage_s.img1x)}
                    alt=""
                    title=""
                    className="img-responsive"
                  />
                )}
                {!hometeamimage_s && <img src="//:0" alt="" title="" className="img-responsive" />}
              </span>
              <span className="team__name">
                <span className="screenreader">
                  {homeScoreObj.Score} {t('goaldoneby')}
                </span>
                {resultsHomeTeamName}
              </span>
            </li>
            <li className="match-score" aria-hidden="true">
              {!isabandoned_b && (
                <>
                  <span>{homeScoreObj.Score}</span>
                  <span> - </span>
                  <span>{awayScoreObj.Score}</span>
                </>
              )}
              {isabandoned_b && (
                <>
                  <span>{t('A')}</span>
                  <span> - </span>
                  <span>{t('A')}</span>
                </>
              )}
              <div className="match-score_counter">
                <span></span>
              </div>
            </li>
            <li>
              <span className="team__logo">
                {awayteamimage_s && (
                  <img
                    src={buildImgUrl(awayteamimage_s.img1x)}
                    alt=""
                    title=""
                    className="img-responsive"
                  />
                )}
                {!awayteamimage_s && <img src="//:0" alt="" title="" className="img-responsive" />}
              </span>
              <span className="team__name">
                <span className="screenreader">
                  {awayScoreObj.Score} {t('goaldoneby')}
                </span>
                {resultsAwayTeamName}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </article>
  );
};

export default withTranslation()(ResultCard);
