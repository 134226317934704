/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import LeftPanel from './LeftPanel';
import StatsBar from '../Common/StatsBar';
import SponsorPanel from './SponsorPanel';
import ImagePanel from './ImagePanel';
import { getStatsBarData } from '../../utils/utils';
import ErrorBoundary from '../Common/ErrorBoundary';
const SingleStats = ({ item, t, gridClass, carousalClass }) => {
  const isPlayer =
    item && item.opta_statentitytype_t && item.opta_statentitytype_t.toLowerCase() === 'player'
      ? true
      : false;
  return (
    <div className={`${gridClass} matchday-stats`}>
      <div className={carousalClass}>
        <div
          className={`stats-card brekdown-percentage single-stats ${item.IsMUEntityClass} ${
            isPlayer ? 'player-section' : 'team-section'
          }`}
        >
          {item.percntVal > 0 && (
            <div
              className={`brekdown-percentage__value ${
                item.percntVal == 100 || item.percntVal === '100' ? 'is-completed' : ''
              }`}
              style={{ width: `${item.percntVal}%` }}
            ></div>
          )}
          <div className="brekdown-percentage__info">
            <SponsorPanel item={item} />
            <div className="stats-card__info">
              <LeftPanel item={item} />
              <div className="stats-card__info__right">
                <div className="chart-area">
                  <div className="brekdown-percentage__details">
                    <div className="brekdown-percentage__details__left two-img">
                      <div className="players__notification left-img">
                        <div
                          className={`player-img ${isPlayer ? 'player-image' : 'team-image'}`}
                          aria-hidden={isPlayer ? true : false}
                        >
                          <ErrorBoundary>
                            <ImagePanel item={item.opta_statentities_t[0]} statsType={isPlayer} />
                          </ErrorBoundary>
                          {isPlayer ? (
                            <span className="notification">
                              {item.opta_statentities_t[0].JerseyNumber}
                            </span>
                          ) : (
                            <span className="two-img-score">
                              {`${item.homeVal}${
                                item.IsPercentage === true || item.IsPercentage === 'true'
                                  ? '%'
                                  : ''
                              }`}
                            </span>
                          )}
                        </div>
                        {isPlayer && (
                          <React.Fragment>
                            <h3 aria-hidden="true">
                              {item.opta_statentities_t[0].KnownName
                                ? item.opta_statentities_t[0].KnownName
                                : item.opta_statentities_t[0].LastName}
                            </h3>
                            <span className="two-img-score" aria-hidden="true">
                              {`${item.homeVal}${
                                item.IsPercentage === true || item.IsPercentage === 'true'
                                  ? '%'
                                  : ''
                              }`}
                            </span>
                          </React.Fragment>
                        )}
                        <span className="screenreader">
                          {isPlayer
                            ? `${item.homeVal} ${
                                item.IsPercentage === true || item.IsPercentage === 'true'
                                  ? '%'
                                  : ''
                              } ${item.cardheading_t} ${t('by')} ${
                                item.opta_statentities_t[0].KnownName
                                  ? item.opta_statentities_t[0].KnownName
                                  : item.opta_statentities_t[0].LastName
                              } ${t('Shirtnumber')} ${item.opta_statentities_t[0].JerseyNumber}`
                            : `${item.homeVal} ${
                                item.IsPercentage === true || item.IsPercentage === 'true'
                                  ? '%'
                                  : ''
                              } ${item.cardheading_t} ${t('by')} ${
                                item.opta_statentities_t[0].TeamName
                              }`}
                        </span>
                      </div>
                      <div className="players__notification right-img">
                        <div
                          className={`player-img ${isPlayer ? 'player-image' : 'team-image'}`}
                          aria-hidden={isPlayer ? true : false}
                        >
                          <ErrorBoundary>
                            <ImagePanel item={item.opta_statentities_t[1]} statsType={isPlayer} />
                          </ErrorBoundary>
                          {isPlayer ? (
                            <span className="notification">
                              {item.opta_statentities_t[1].JerseyNumber}
                            </span>
                          ) : (
                            <span className="two-img-score">
                              {`${item.awayVal}${
                                item.IsPercentage === true || item.IsPercentage === 'true'
                                  ? '%'
                                  : ''
                              }`}
                            </span>
                          )}
                        </div>
                        {isPlayer && (
                          <React.Fragment>
                            <h3 aria-hidden="true">
                              {item.opta_statentities_t[1].KnownName
                                ? item.opta_statentities_t[1].KnownName
                                : item.opta_statentities_t[1].LastName}
                            </h3>
                            <span className="two-img-score" aria-hidden="true">
                              {`${item.awayVal}${
                                item.IsPercentage === true || item.IsPercentage === 'true'
                                  ? '%'
                                  : ''
                              }`}
                            </span>
                          </React.Fragment>
                        )}
                        <span className="screenreader">
                          &nbsp;
                          {isPlayer
                            ? `${item.awayVal} ${
                                item.IsPercentage === true || item.IsPercentage === 'true'
                                  ? '%'
                                  : ''
                              } ${item.cardheading_t} ${t('by')} ${
                                item.opta_statentities_t[1].KnownName
                                  ? item.opta_statentities_t[1].KnownName
                                  : item.opta_statentities_t[1].LastName
                              } ${t('Shirtnumber')} ${item.opta_statentities_t[1].JerseyNumber}`
                            : `${item.awayVal} ${
                                item.IsPercentage === true || item.IsPercentage === 'true'
                                  ? '%'
                                  : ''
                              } ${item.cardheading_t} ${t('by')} ${
                                item.opta_statentities_t[1].TeamName
                              }`}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <StatsBar item={getStatsBarData(item)} type="statsCard" />
        </div>
      </div>
    </div>
  );
};
export default withTranslation()(SingleStats);
