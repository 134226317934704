/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_MATCH_CENTER,
  SET_MATCH_CENTER,
  ERROR_MATCH_CENTER,
  FETCH_SPOTLIGHT,
  SET_SPOTLIGHT,
  ERROR_SPOTLIGHT,
  IS_IN_VIEW,
  UPDATE_FEED,
} from '../constants/actions/actionConstant';
import { MATCH_CENTER, SPOTLIGHT } from '../constants/endpoints';
import api from '../utils/muApi';
export const fetchMatchCenter = (dispatch, serviceAction, timeStampValue, matchId, rowsCount) => {
  const route = `${MATCH_CENTER}${matchId}/0/${rowsCount}/${timeStampValue}`;
  if (route) {
    dispatch(fetchingMatchCenter(matchId, serviceAction, timeStampValue));
    return api
      .get({ route })
      .then((response) => {
        dispatch(setMatchCenter(response, matchId, serviceAction, timeStampValue));
      })
      .catch((err) => {
        dispatch(error(err));
      });
  }
};

export const fetchingMatchCenter = (matchId, serviceAction, timeStampValue) => ({
  type: FETCH_MATCH_CENTER,
  payload: { matchId, serviceAction, timeStampValue },
});
export const setMatchCenter = (response, matchId, serviceAction, timeStampValue) => ({
  type: SET_MATCH_CENTER,
  payload: { response, serviceAction, timeStampValue, matchId },
});
export const error = (response) => ({ type: ERROR_MATCH_CENTER, payload: response });

export const fetchSpotlight = (dispatch) => {
  const route = `${SPOTLIGHT}`;
  dispatch(fetchingSpotlight());
  return api
    .get({ route })
    .then((response) => {
      dispatch(setSpotlight(response));
    })
    .catch((err) => {
      dispatch(errorSpotlight(err));
    });
};

export const fetchingSpotlight = () => ({ type: FETCH_SPOTLIGHT });
export const setSpotlight = (response) => ({ type: SET_SPOTLIGHT, payload: { response } });
export const errorSpotlight = (response) => ({ type: ERROR_SPOTLIGHT, payload: response });

export const inViewPortAction = (dispatch, isInViewPort) => {
  dispatch(updateViewPortAction(isInViewPort));
};
export const updateListing = (dispatch) => {
  dispatch(updateFeedListing());
};
export const updateViewPortAction = (isInViewPort) => ({
  type: IS_IN_VIEW,
  payload: { isInViewPort },
});
export const updateFeedListing = () => ({ type: UPDATE_FEED });
