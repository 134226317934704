/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint no-unused-vars: "off" */
import axios from 'axios';
import { ENCRYPT_KEY } from '../constants/endpoints';
import { DEFAULT_AUTH_TOKEN, SEPRATOR } from '../constants/globalConstants';
import MU from '../constants/muConstants';
import { getCookie, setCookie } from './utils';
import { sha256 } from 'js-sha256';
let geoCookie, jsencrypt, deviceID;
const setGeoCookie = () => {
  geoCookie = getCookie('geo');
};
const call = ({ method, route, data, params, baseURL, header, isApiSecure, UID, retry }) => {
  if (!baseURL) baseURL = `${MU.awsEndPoint}/${MU.awsAPIVersion}/${MU.language}/`;
  if (isApiSecure) {
    let authorization;
    if (!deviceID) {
      deviceID = localStorage.getItem('deviceID');
    }
    let keysObj =
      sessionStorage.getItem('keysObj') && JSON.parse(sessionStorage.getItem('keysObj'));
    if (keysObj) {
      authorization = encryptionFunction(keysObj, UID, deviceID); //call the encryption function
      header = header ? { ...header, authorization } : { authorization };
      return callApi({ method, route, data, params, baseURL, header, isApiSecure, UID, retry });
    } else {
      return fetchKeys().then((keys) => {
        authorization = encryptionFunction(keys, UID, deviceID); //call the encryption function;
        setStorage(keys, 'keys');
        header = header ? { ...header, authorization } : { authorization };
        return callApi({ method, route, data, params, baseURL, header, isApiSecure, UID, retry });
      });
    }
  } else {
    return callApi({ method, route, data, baseURL, params, header });
  }
};
const callApi = ({ method, route, data, params, baseURL, header, isApiSecure, UID, retry }) => {
  let headers = {
    'Accept-Encoding': 'gzip',
    Accept: 'application/json',
    'x-api-key': MU.awsapiKeyWeb,
  };
  if (header) {
    headers = { ...headers, ...header };
  }
  const operation = axios.request({
    baseURL,
    url: route,
    method,
    data,
    params,
    headers,
    // paramsSerializer: params => qs.stringify(params, {arrayFormat: 'brackets'}),
  });
  return new Promise((resolve, reject) => {
    operation
      .then((resp) => {
        if (resp.status >= 200 && resp.status < 300) {
          // Response was good! Let's parse it and resolve
          if (!resp.data) {
            return resolve();
          } else if (resp.data && isApiSecure) {
            let authheader = resp && resp.headers && resp.headers.authheader;
            let apiSessionID = localStorage.getItem('apiSessionID');
            !apiSessionID && authheader && setStorage(authheader, 'apiSession');
            return resolve({ data: resp.data });
          } else return resolve({ data: resp.data });
        }
        // Response was bad, let's throw an error
        const error = new Error('MU API Error!');
        error.details = resp.data;
        return reject(error);
      })
      .catch((err) => {
        const error = new Error('Error while fetching from MU API');
        error.details = err;
        let apiError =
          error.details &&
          error.details.response &&
          error.details.response.data &&
          error.details.response.data.error;
        if (
          isApiSecure &&
          apiError &&
          apiError.httpStatus === 401 &&
          (!retry || (retry && retry.e401 < 3))
        ) {
          let retryObject = retryObj('e401', retry);
          localStorage.removeItem('apiSessionID');
          //as sessionId expires after seven days
          return call({
            method,
            route,
            data,
            params,
            baseURL,
            header,
            isApiSecure,
            UID,
            retry: retryObject,
          })
            .then((data) => {
              return resolve(data);
            })
            .catch(() => {
              return reject(error);
            });
        } else if (
          isApiSecure &&
          apiError &&
          apiError.httpStatus === 400 &&
          (!retry || (retry && retry.e400 < 3))
        ) {
          let retryObject = retryObj('e400', retry);
          sessionStorage.removeItem('keysObj');
          //as publickeys expires after 30 or 60 days
          return call({
            method,
            route,
            data,
            params,
            baseURL,
            header,
            isApiSecure,
            UID,
            retry: retryObject,
          })
            .then((data) => {
              return resolve(data);
            })
            .catch(() => {
              return reject(error);
            });
        } else {
          return reject(error);
        }
      });
  });
};
const post = ({ route, baseURL, params, data, header, isHardcoredGeo, isApiSecure, UID }) => {
  let geoValue = 'all';
  if (!baseURL) baseURL = `${MU.awsEndPoint}/${MU.awsAPIVersion}/${MU.language}/`;
  if (!isHardcoredGeo) {
    if (geoCookie) {
      geoValue = geoCookie;
      baseURL = `${baseURL}${geoValue}/`;
      return call({
        method: 'POST',
        route,
        baseURL,
        params,
        data,
        header,
        isApiSecure,
        UID,
      });
    } else if (typeof MU.countryCode !== 'undefined' && MU.countryCode !== '') {
      geoValue = MU.countryCode.toLowerCase();
      baseURL = `${baseURL}${geoValue}/`;
      setCookie('geo', geoValue);
      return call({
        method: 'POST',
        route,
        baseURL,
        params,
        data,
        header,
        isApiSecure,
        UID,
      });
    } else {
      baseURL = `${baseURL}in/`;
      return call({
        method: 'POST',
        route,
        baseURL,
        params,
        data,
        header,
        isApiSecure,
        UID,
      });
    }
  } else {
    return call({
      method: 'POST',
      route,
      baseURL,
      params,
      data,
      header,
      isApiSecure,
      UID,
    });
  }
};

const get = ({ route, baseURL, params, header, isHardcoredGeo, isApiSecure, UID }) => {
  let geoValue = 'all';
  if (!baseURL) baseURL = `${MU.awsEndPoint}/${MU.awsAPIVersion}/${MU.language}/`;
  if (!isHardcoredGeo) {
    if (geoCookie) {
      geoValue = geoCookie;
      baseURL = `${baseURL}${geoValue}/`;
      return call({ method: 'GET', route, baseURL, params, header, isApiSecure, UID });
    } else if (typeof MU.countryCode !== 'undefined' && MU.countryCode !== '') {
      geoValue = MU.countryCode.toLowerCase();
      baseURL = `${baseURL}${geoValue}/`;
      setCookie('geo', geoValue);
      return call({ method: 'GET', route, baseURL, params, header, isApiSecure, UID });
    } else {
      baseURL = `${baseURL}in/`;
      return call({ method: 'GET', route, baseURL, params, header, isApiSecure, UID });
    }
  } else {
    return call({ method: 'GET', route, baseURL, params, header, isApiSecure, UID });
  }
};

const patch = ({ route, baseURL, params, data, header }) => {
  return call({ method: 'PATCH', route, baseURL, params, data, header });
};

const _delete = ({ route, baseURL, params, data }) => {
  return call({ method: 'DELETE', route, baseURL, params, data });
};

export default {
  call,
  get,
  post,
  patch,
  delete: _delete,
  setGeoCookie,
};

const fetchKeys = () => {
  const route = `${ENCRYPT_KEY}`;
  return get({ route })
    .then((response) => {
      let data = response && response.data;
      let keysObj = {
        publicKey: data.publicKey,
        saltKey: data.saltKey,
      };
      setStorage(keysObj, 'keys');
      return keysObj;
    })
    .catch((err) => {
      console.log('Error', err);
    });
};

const encryptionFunction = (encryptKeys, UID, deviceID) => {
  if (!jsencrypt) {
    jsencrypt = new window.JSEncrypt();
  }
  if (jsencrypt) {
    jsencrypt.setPublicKey(encryptKeys.publicKey);
    let sessionID =
      localStorage.getItem('apiSessionID') && JSON.parse(localStorage.getItem('apiSessionID')); //get sessionID from localstorage
    if (encryptKeys) {
      if (sessionID) {
        //hashing and encrypt the using the sessionID
        return jsencrypt.encrypt(
          `${sha256(
            `${encryptKeys.saltKey}${SEPRATOR}${sessionID}${SEPRATOR}${deviceID}${SEPRATOR}${UID}`
          )}${SEPRATOR}${deviceID}${SEPRATOR}${UID}`
        );
      } else {
        //encrypt Using the default token
        return jsencrypt.encrypt(`${DEFAULT_AUTH_TOKEN}${SEPRATOR}${deviceID}${SEPRATOR}${UID}`);
      }
    }
  } else {
    return '';
  }
};

const setStorage = (storageData, type) => {
  try {
    if (typeof Storage !== 'undefined' && !sessionStorage.getItem('keys') && type == 'keys') {
      sessionStorage.setItem('keysObj', JSON.stringify(storageData));
    } else if (
      typeof Storage !== 'undefined' &&
      type === 'apiSession' &&
      !localStorage.getItem('apiSessionID')
    ) {
      localStorage.setItem('apiSessionID', JSON.stringify(storageData));
    } else {
      console.info('Storage not supported');
    }
  } catch (e) {
    console.log('Error in Storage Sponsor', e);
  }
};

const retryObj = (errCode, errObj) => {
  let obj;
  if (!errObj) {
    obj = { e401: 0, e400: 0 };
  } else {
    obj = { ...errObj };
  }
  obj[errCode] = obj[errCode] + 1;
  return obj;
};
