/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { getMatchDate, checkisExpiredDate } from '../../../utils/momentDate';
import { buildImgUrl } from '../../../utils/utils';
import { fetchCarParking, sendCarParkingReport } from '../../../actions/formAction';
import { FormProvider, useFormValue } from '../../../context/formContext';
import { useSponsorValue } from '../../../context/sponsorContext';
import { redirectToSignIn } from '../../../utils/gigyaScreen';
import {
  getUrlId,
  getCarParkingData,
  getCurrentDate,
  setName,
  refreshPage,
} from '../../../utils/FormUtils';
import Loader from '../../Common/Loader';
import { initialValueCarParking } from '../../../constants/formConstants';
import MU from '../../../constants/muConstants';

const FormData = (props) => (
  <React.Fragment>
    <FormProvider>
      <CompetitionAndPolls {...props}></CompetitionAndPolls>
    </FormProvider>
  </React.Fragment>
);

const CompetitionAndPolls = (props) => {
  const [
    { carparkingData, carparkingStatus, persistReportData, persistReportStatus },
    dispatch,
  ] = useFormValue();
  const [{ isGigyaAvilable, userLoginDetail, isUserLogin }] = useSponsorValue();
  const {
    competitionName,
    TermsText,
    SuccessMessage,
    ErrorMessage,
    NoTicketMessage,
    StartDate,
    EndDate,
    ThankYouDetail,
    ButtonText,
    ButtonLink,
    ThankYouText,
    Image,
    MatchId,
    AwayTeamName,
    AllInclusiveList,
  } =
    props &&
    props.fields &&
    props.fields.carparkingFormObject &&
    props.fields.carparkingFormObject.value;
  const [message, setMessage] = useState(initialValueCarParking);
  const [errorMsg, setErrorMsg] = useState({});
  const [formShow, setFormShow] = useState({ showform: 'step1' });
  const matchIdNew = AwayTeamName + '_' + MatchId;
  const termText = { value: `${TermsText}` };
  const thankYouText = { value: `${ThankYouText}` };
  const formArr = [];
  const endDate = getMatchDate(EndDate, 'DD-MMM-YYYY');
  useEffect(() => {
    isGigyaAvilable && userLoggedin() && redirectToSignIn();
  }, [isGigyaAvilable, isUserLogin]);

  useEffect(() => {
    carparkingStatus === 'fetched' && carparkingData && getCarParkingResponse(carparkingData);
  }, [carparkingStatus, carparkingData]);

  useEffect(() => {
    (persistReportStatus === 'fetched' || persistReportStatus === 'error') &&
      persistReportData &&
      showStatus();
  }, [persistReportStatus, persistReportData]);

  const userLoggedin = () => {
    return MU.isLoginRequire && !isUserLogin;
  };

  const getImagePath = () => {
    if (Image && typeof Image == 'string') {
      const imageSet = JSON.parse(Image);
      if (imageSet && imageSet.img1x) {
        return buildImgUrl(imageSet.img1x);
      }
    }
  };

  const getCarParkingResponse = (response) => {
    const objectCount = response && response.objectsCount;
    const searchResult = response && response.results && response.results.length;
    let duplicateInfo = false;
    if (objectCount && objectCount > 0 && searchResult > 0) {
      duplicateInfo = true;
    }
    if (duplicateInfo) {
      setMessage({ ...message, duplicate: true });
    } else {
      setMessage({ ...message, duplicate: false });
      sendReport();
    }
  };

  const showStatus = () => {
    if (persistReportStatus === 'fetched') {
      setFormShow({ showform: 'step2' });
      setMessage({ ...message, thankYou: true });
    } else {
      setMessage({ ...message, saveError: true });
    }
  };

  const sendReport = () => {
    const formData = getCarParkingData();
    if (userLoginDetail) {
      const parkingData = { CarParking: {} };
      parkingData.CarParking[matchIdNew] = {
        id: getUrlId(),
        Forename: userLoginDetail.profile && userLoginDetail.profile.firstName,
        Email: userLoginDetail.profile && userLoginDetail.profile.email,
        Surname: userLoginDetail.profile && userLoginDetail.profile.lastName,
        TimeAndDate: getCurrentDate(),
        MatchID: matchIdNew,
        ...formData,
      };
      sendCarParkingReport(dispatch, userLoginDetail, parkingData);
    }
  };

  const validateForm = () => {
    const formData = getCarParkingData();
    const formErrors = {};
    let formIsValid = true;
    if (formData) {
      formArr &&
        formArr.length > 0 &&
        formArr.forEach((item, index) => {
          let [key] = Object.keys(item);
          if (!formData[key] && item.isRequired) {
            formErrors[key] = formArr[index][key];
            formIsValid = false;
          } else {
            formErrors[key] = '';
          }
        });
    }
    setErrorMsg(formErrors);
    return formIsValid;
  };
  const onChangeFunction = (key, required, msg) => {
    let formErrors = '';
    const formData = getCarParkingData();
    if (!formData[key] && required) {
      formErrors = msg;
    } else {
      formErrors = '';
    }
    setErrorMsg({ ...errorMsg, [key]: formErrors });
  };

  const submitCarParking = (e) => {
    e.preventDefault();
    if (validateForm() && userLoginDetail && MatchId) {
      fetchCarParking(dispatch, userLoginDetail, matchIdNew);
    }
  };
  const renderForm = () => {
    return (
      AllInclusiveList &&
      AllInclusiveList.length > 0 &&
      AllInclusiveList.map((item, index) => {
        let key = setName(item && item.Name);
        formArr.push({ [key]: item.DataRequiredMessage, isRequired: item.True });
        return (
          <React.Fragment key={index}>
            {item.HeadingCheck && <div className="generic_heading">{item.Title}</div>}
            <div className="grid-12">
              {item &&
                (item.AnswerIsTextCheckBox ||
                  item.AlphabetCheckBox ||
                  item.RegexCheckBox ||
                  item.AlphaNumericCheckBox) && (
                  <React.Fragment>
                    <input
                      type="text"
                      className={`inputStyle generic_input generic_firstname`}
                      aria-label={item && item.Title}
                      placeholder={item && item.Title}
                      name={key}
                      onChange={() => onChangeFunction(key, item.True, item.DataRequiredMessage)}
                    />
                    <label htmlFor={setName(item.Name)} role="alert" className="genericErrormsg">
                      {errorMsg && errorMsg[key]}
                    </label>
                  </React.Fragment>
                )}
            </div>
          </React.Fragment>
        );
      })
    );
  };

  return (
    <React.Fragment>
      {checkisExpiredDate(EndDate) ? (
        <React.Fragment>
          <div style={{ opacity: userLoggedin() ? 0 : 1 }}>
            <section className="car__parking">
              <div className="matches-league">
                <span className="team-ico">
                  <img src={getImagePath()} alt="" />
                </span>
                <p className="parking__ballet">{competitionName}</p>
                <p className="parking__ballet closing">{`Closing Date ${endDate}`}</p>
                {formShow && formShow.showform === 'step1' && (
                  <div className="travel__container">
                    <form
                      className="car__parking__grid grid-8"
                      action=""
                      id="car_parking"
                      onSubmit={(e) => submitCarParking(e)}
                    >
                      <fieldset>{renderForm()}</fieldset>
                      <div style={{ clear: 'both' }}></div>
                      <div className="carparkingSubmit">
                        <input
                          type="submit"
                          aria-label="SEND FORM"
                          className="generic_skewBtn gen_submitBtn"
                          value="ENTER COMPETITION  →"
                        />
                      </div>
                      <div style={{ clear: 'both' }}></div>
                    </form>
                    <div style={{ clear: 'both' }}></div>
                    <div className="description">
                      {/* <p className="success-save">{SuccessMessage}</p>
                    <p className="no-ticket">{NoTicketMessage}</p> */}
                      {message && message.saveError && (
                        <p className="error-save" role="alert">
                          {ErrorMessage}
                        </p>
                      )}
                      {message && message.duplicate && (
                        <p className="duplicate-error-msg" role="alert">
                          {NoTicketMessage}
                        </p>
                      )}
                    </div>
                    <div style={{ clear: 'both' }}></div>
                    <RichText className="car_termclick" field={termText} />
                  </div>
                )}
                {formShow && formShow.showform === 'step2' && (
                  <div className="step__three clearfix">
                    <RichText className="description" field={thankYouText}></RichText>
                    <div style={{ clear: 'both' }}></div>
                    <div className="black-btn-skew">
                      <a href={ButtonLink} role="button" onClick={() => refreshPage()}>
                        <span>{ButtonText}</span>
                        <i className="icon-follow-live"></i>
                      </a>
                    </div>
                    <div className="description">{ThankYouDetail}</div>
                  </div>
                )}
              </div>
            </section>
            {carparkingStatus === 'fetching' && <Loader></Loader>}
          </div>
        </React.Fragment>
      ) : (
        <div style={{ opacity: userLoggedin() ? 0 : 1 }}>
          <section className="car__parking">Expired</section>
        </div>
      )}
      {userLoggedin() && <Loader />}
    </React.Fragment>
  );
};

export default withTranslation()(React.memo(FormData));
