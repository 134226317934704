/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import { SAVE_POLL } from '../constants/endpoints';
import api from './muApi';
import MU from '../constants/muConstants';

export const getPoleSessionId = () => {
  if (typeof Storage !== 'undefined' && localStorage.getItem('poleSessionId') === null) {
    localStorage.setItem('poleSessionId', generatePoleSessionId());
  }
  return localStorage.getItem('poleSessionId');
};

export const generatePoleSessionId = () => {
  let d = new Date().getTime();
  const id = 'xxxxxxxx-xxxx-yxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return id;
};

export const persistUserPollSelection = (voteIndex, cardData, userId) => {
  let userFound = false,
    pollFound = false;
  const userPollsStore = localStorage.getItem('userPolls');
  if (typeof Storage !== 'undefined' && (!userPollsStore || userPollsStore === 'undefined')) {
    let polls = [];
    polls.push(generateUserPoleObject(voteIndex, cardData));
    localStorage.setItem('userPolls', JSON.stringify([{ userId: userId, polls }]));
  } else {
    const userPoleObj =
      userPollsStore && userPollsStore !== 'undefined' && typeof userPollsStore === 'string'
        ? JSON.parse(userPollsStore)
        : userPollsStore;
    userPoleObj.forEach((userPollData) => {
      if (userPollData.userId == userId) {
        userFound = true;
        userPollData.polls.forEach(function (poll, index) {
          if (
            cardData &&
            cardData.manutds &&
            cardData.manutds.value &&
            poll.pollId == cardData.manutds.value.pollId
          ) {
            pollFound = true;
            userPollData.polls[index] = generateUserPoleObject(voteIndex, cardData);
          }
        });
        if (!pollFound) {
          userPollData.polls.push(generateUserPoleObject(voteIndex, cardData));
        }
      }
    });
    if (!userFound) {
      let polls = [];
      polls.push(generateUserPoleObject(voteIndex, cardData));
      userPoleObj.push({ userId: userId, polls });
    }
    localStorage.setItem('userPolls', JSON.stringify(userPoleObj));
  }
};

export const generateUserPoleObject = (index, cardData) => {
  const pollid =
    cardData && cardData.manutds && cardData.manutds.value && cardData.manutds.value.pollId;
  let results =
    cardData &&
    cardData.manutds &&
    cardData.manutds.value &&
    cardData.manutds.value.GigyaContent &&
    cardData.manutds.value.GigyaContent.data &&
    cardData.manutds.value.GigyaContent.data.results;
  if (!results) {
    const key = 'data.' + pollid + '.votingOption';
    results = [];
    const options =
      cardData &&
      cardData.manutds &&
      cardData.manutds.value &&
      cardData.manutds.value.Options &&
      cardData.manutds.value.Options.length;
    let i = 0;
    while (i !== options) {
      const obj = {};
      obj[key] = i;
      obj['count(*)'] = 0;
      results.push(obj);
      i++;
    }
  }
  let indexFound = false;
  let totalCount = 0;
  results.forEach((option) => {
    totalCount += option['count(*)'];
    if (parseInt(option['data.' + pollid + '.votingOption']) == index) {
      option['count(*)'] += 1;
      indexFound = true;
    }
  });
  if (!indexFound) {
    const pollKey = 'data.' + pollid + '.votingOption';
    const result = {};
    result[pollKey] = index.toString();
    result['count(*)'] = 1;
    results.push(result);
  }
  totalCount++;
  return {
    pollId: pollid,
    results: results,
    votingOption: index,
    totalCount: totalCount,
  };
};

export const savePollResponse = (pollid_s, pollObj) => {
  api
    .post({
      route: SAVE_POLL,
      baseURL: null,
      params: null,
      data: getRequestBody(pollid_s, pollObj),
    })
    .then(() => {})
    .catch((err) => {
      console.log('err', err);
      retryPollSubmission(pollid_s, pollObj);
    });
};

const retryPollSubmission = (pollid_s, pollObj) => {
  setTimeout(() => {
    api
      .post({
        route: SAVE_POLL,
        baseURL: null,
        params: null,
        data: getRequestBody(pollid_s, pollObj),
      })
      .then(() => {})
      .catch((err) => {
        console.log('err', err);
      });
  }, 5000);
};

const getRequestBody = (pollid_s, pollObj) => {
  const requestBody = {};
  requestBody.pollID = pollid_s;
  requestBody.UUID = pollObj && pollObj[pollid_s] ? pollObj[pollid_s].uID : '';
  requestBody.votingClient = 'web';
  requestBody.votingDate = new Date().toISOString().split('.')[0];
  requestBody.votingOption = pollObj && pollObj[pollid_s] ? pollObj[pollid_s].votingOption : '';
  requestBody.region = MU && MU.CountryCode ? MU.CountryCode.toLowerCase() : undefined;
  return requestBody;
};

export const calculatePercentage = (index, cardData, voteIndex, alreadyVoted, pollClosed) => {
  try {
    if ((voteIndex !== null && voteIndex !== undefined) || alreadyVoted || pollClosed) {
      let votesData =
        cardData &&
        cardData.manutds &&
        cardData.manutds.value &&
        cardData.manutds.value.GigyaContent &&
        cardData.manutds.value.GigyaContent.data;
      if (!votesData) {
        votesData = {};
        const userPollsStore = localStorage.getItem('userPolls');
        const userPoleObj =
          userPollsStore && userPollsStore !== 'undefined' && typeof userPollsStore === 'string'
            ? JSON.parse(userPollsStore)
            : userPollsStore;
        if (userPoleObj) {
          const pollid =
            cardData && cardData.manutds && cardData.manutds.value && cardData.manutds.value.pollId;
          let poll;
          const polls = userPoleObj && userPoleObj.length && userPoleObj[0] && userPoleObj[0].polls;
          polls.forEach((val, key) => {
            if (pollid === val.pollId) {
              poll = key;
              return false;
            }
          });
          votesData.results =
            userPoleObj &&
            userPoleObj.length &&
            userPoleObj[0] &&
            userPoleObj[0].polls &&
            userPoleObj[0].polls[poll] &&
            userPoleObj[0].polls[poll].results;
        }
      }
      let totalCount = 0;
      let voteOptionExists = false;
      let votePercentage, voteOptionIndex;
      if (votesData && votesData.results) {
        votesData.results.forEach((option) => {
          totalCount += option['count(*)'];
        });
        for (let count = 0; count < votesData.results.length; count++) {
          voteOptionIndex = getVoteOptionIndex(votesData.results[count]);
          if (voteOptionIndex == index) {
            votePercentage = (votesData.results[count]['count(*)'] / totalCount) * 100;
            voteOptionExists = true;
            return Math.round(votePercentage) + '%';
          }
        }
      }
      if (!votesData || !votesData.results || !votesData.results.length || !voteOptionExists) {
        // result array empty or voting option missing
        if (index === voteIndex) {
          votePercentage = (1 / totalCount) * 100;
          return Math.round(votePercentage) + '%';
        }
        return '0%';
      }
    }
  } catch (e) {
    console.log('fail gigyacontent.data');
    return '0%';
  }
};

export const getVoteOptionIndex = (voteOptionObj) => {
  let index;
  Object.keys(voteOptionObj).forEach((key) => {
    if (key.indexOf('votingOption') !== -1) {
      index = parseInt(voteOptionObj[key]);
    }
  });
  return index;
};

export const pollClasses = (closed, hideStats, noteVoted) => {
  let classes = '';
  if (!closed) {
    classes += ' active';
  }
  if (hideStats) {
    classes += ' hide-stats';
  }
  if (noteVoted) {
    classes += ' not-voted';
  }
  return classes;
};
