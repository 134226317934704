/**
 *  © 2022-2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/**
 * Callback after braze sdk is loaded
 */
import MU from '../constants/muConstants';
import { checkTimeDifference, fnBrowserDetect, getCookie } from './utils';
const braze = typeof window !== 'undefined' ? require('@braze/web-sdk') : null;

const userAgent = typeof window !== 'undefined' && window?.navigator?.userAgent;

const customSupported = () => {
  return (
    /^((?!chrome|android).)*safari/i.test(userAgent) &&
    !userAgent.match(/iPad/i) &&
    !userAgent.match(/iPhone/i)
  );
};

const notificationPermission = (notification) => {
  if (userAgent && notification?.customWebNotification) {
    const browserSet = notification?.customWebNotification?.allowedBrowsers;
    const browserObj = fnBrowserDetect();
    const checkBrowserSet = browserObj && browserSet?.includes(browserObj);
    if (checkBrowserSet || customSupported()) {
      return true;
    } else return false;
  } else return false;
};

export const isCookiesSelected = () => {
  const userConsent_on = getCookie('userConsentCookiePolicy') === 'on';
  const userConsent_off = getCookie('userConsentCookiePolicy') === 'off';
  const infoAgreeData = localStorage.getItem('infoAgree');
  const infoConsent = infoAgreeData && JSON.parse(infoAgreeData)?.value === 'yes';
  if (userConsent_on || userConsent_off || infoConsent) return true;
  else return false;
};

export const initBraze = () => {
  if (typeof window !== 'undefined' && braze && MU?.brazeData?.brazeKey && MU?.brazeData?.baseUrl) {
    const userConsent_on = getCookie('userConsentCookiePolicy') === 'on';
    const userConsent_off = getCookie('userConsentCookiePolicy') === 'off';
    const infoAgreeData = localStorage.getItem('infoAgree');
    const infoConsent = infoAgreeData && JSON.parse(infoAgreeData)?.value === 'yes';
    const isNoCookies = userConsent_on || infoConsent ? true : userConsent_off && false;
    braze.initialize(MU.brazeData.brazeKey, {
      baseUrl: MU.brazeData.baseUrl,
      enableLogging: true,
      allowUserSuppliedJavascript: true,
      inAppMessageZIndex: 9999,
      noCookies: !isNoCookies,
    });
  }
};

export const initBrazePush = (notification) => {
  if (notificationPermission(notification) && 'Notification' in window) {
    const permission = Notification?.permission;
    if (permission === 'allowed' || permission === 'granted') {
      initBraze();
      // rehister for push
      braze.requestPushPermission();
      console.log('Braze-isPushBlocked', braze.isPushBlocked());

      console.log('Braze-isPushSupported', braze.isPushSupported());

      console.log('Braze-isPushPermissionGranted', braze.isPushPermissionGranted());

      MU.language && braze.getUser().setCustomUserAttribute('web_language', MU.language);

      MU.countryCode && braze.getUser().setCustomUserAttribute('web_last_location', MU.countryCode);
    }
  }
};

export const callBraze = (userData, notification) => {
  initBraze();

  // optionally show all in-app messages
  braze.automaticallyShowInAppMessages();

  // rehister for push

  if (notificationPermission(notification) && 'Notification' in window) {
    const permission = Notification?.permission;
    if (permission === 'allowed' || permission === 'granted') {
      braze.requestPushPermission();
      console.log('Braze-isPushBlocked', braze.isPushBlocked());

      console.log('Braze-isPushSupported', braze.isPushSupported());

      console.log('Braze-isPushPermissionGranted', braze.isPushPermissionGranted());

      braze.getUser().setCustomUserAttribute('web_language', MU.language);

      braze.getUser().setCustomUserAttribute('web_last_location', MU.countryCode);
    }
  }

  if (userData && userData.UID) {
    braze.changeUser(userData.UID);
  }

  // optionally set the current user's external ID before starting a new session
  if (
    userData &&
    userData.registeredTimestamp &&
    checkTimeDifference(userData.registeredTimestamp)
  ) {
    userData && setUserDataForBraze(braze, userData);
  }

  // `openSession` should be called last - after `changeUser` and `automaticallyShowInAppMessages`
  braze.openSession();
};

export const setUserDataForBraze = (braze, userData) => {
  if (typeof window !== 'undefined' && userData) {
    if (userData && userData.profile) {
      const { firstName, lastName, email } = userData.profile;

      if (firstName) {
        braze.getUser().setFirstName(firstName);
      }

      if (lastName) {
        braze.getUser().setLastName(lastName);
      }

      if (email) {
        braze.getUser().setEmail(email);
      }
    }
  }
};
