/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import { calculatePercentage } from '../../utils/playerProfileStats';
const Defending = ({ t, item, statsType }) => {
  const buildDefendingData = (defItem) => {
    if (defItem && defItem.stats && defItem.stats.length > 0) {
      var statsItems = defItem.stats;
      for (var i = 0; i < statsItems.length; i++) {
        var outerObj = statsItems[i];
        // for tacles
        if (outerObj && outerObj.statsKey == 'stats_TacklesWon') {
          for (var inner = 0; inner < statsItems.length; inner++) {
            var innerObj = statsItems[inner];
            if (innerObj && innerObj.statsKey === 'stats_TotalTackles') {
              outerObj.totalValue = innerObj.statsValue;
              outerObj.totalIspercentage = innerObj.isPercentage;
              statsItems.splice(inner, 1);
              break;
            }
          }
        } else if (outerObj && outerObj.statsKey == 'stats_AerialDuelsWon') {
          for (var inner = 0; inner < statsItems.length; inner++) {
            var innerObj = statsItems[inner];
            if (innerObj && innerObj.statsKey === 'stats_AerialDuels') {
              outerObj.totalValue = innerObj.statsValue;
              outerObj.totalIspercentage = innerObj.isPercentage;
              statsItems.splice(inner, 1);
              break;
            }
          }
        } else if (outerObj && outerObj.statsKey == 'stats_GroundDuelswon') {
          for (var inner = 0; inner < statsItems.length; inner++) {
            var innerObj = statsItems[inner];
            if (innerObj && innerObj.statsKey === 'stats_GroundDuels') {
              outerObj.totalValue = innerObj.statsValue;
              outerObj.totalIspercentage = innerObj.isPercentage;
              statsItems.splice(inner, 1);
              break;
            }
          }
        } else if (outerObj && outerObj.statsKey == 'stats_SuccessfulFiftyFifty') {
          for (var inner = 0; inner < statsItems.length; inner++) {
            var innerObj = statsItems[inner];
            if (innerObj && innerObj.statsKey === 'stats_FiftyFifty') {
              outerObj.totalValue = innerObj.statsValue;
              outerObj.totalIspercentage = innerObj.isPercentage;
              statsItems.splice(inner, 1);
              break;
            }
          }
        } else if (outerObj && outerObj.statsKey == 'stats_InterceptionWon') {
          for (var inner = 0; inner < statsItems.length; inner++) {
            var innerObj = statsItems[inner];
            if (innerObj && innerObj.statsKey === 'stats_Interceptions') {
              outerObj.totalValue = innerObj.statsValue;
              outerObj.totalIspercentage = innerObj.isPercentage;
              statsItems.splice(inner, 1);
              break;
            }
          }
        } else if (outerObj && outerObj.statsKey == 'stats_Duelswon') {
          for (var inner = 0; inner < statsItems.length; inner++) {
            var innerObj = statsItems[inner];
            if (innerObj && innerObj.statsKey === 'stats_Duels') {
              outerObj.totalValue = innerObj.statsValue;
              outerObj.totalIspercentage = innerObj.isPercentage;
              statsItems.splice(inner, 1);
              break;
            }
          }
        }
      }
      return statsItems;
    }
  };

  return (
    <div className="chart-wrapper">
      <h3>{t('stats_Defending')}</h3>
      <div className="player-line-chart">
        <ul>
          {item &&
            item.stats &&
            item.stats.length > 0 &&
            buildDefendingData(item).map((defending, key) => (
              <li key={key}>
                <div className="player-line-chart__wrapper">
                  {defending.statsValue > 0 && (
                    <span
                      className="percentage-value"
                      style={{
                        width: `${calculatePercentage(
                          defending.statsValue,
                          defending.totalValue
                        )}%`,
                      }}
                    ></span>
                  )}
                  <span className="result-name" aria-hidden="true">
                    {t(defending.statsKey)}
                  </span>
                  {defending.totalValue != 0 &&
                    defending.totalValue != '' &&
                    defending.totalValue != undefined && (
                      <span className="result-value" aria-hidden="true">
                        <span>
                          {defending.statsValue}
                          <span className="total-value">/{defending.totalValue}</span>
                        </span>
                      </span>
                    )}
                  {(!defending.totalValue || defending.totalValue === '0') && (
                    <span className="result-value" aria-hidden="true">
                      {' '}
                      <span>0</span>
                    </span>
                  )}
                  <div className="screenreader">
                    {`${t(defending.statsKey)} ${
                      defending.totalValue ? defending.statsValue : 0
                    } out of ${defending.totalValue ? defending.totalValue : 0}`}
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
export default withTranslation()(Defending);
