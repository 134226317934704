/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

export const chartColor = ['#ae0000', '#f8e71c', '#0f8723', '#bc9d4c'];
export const chartIndex = [0, 1];
export const parseIntVal = (val) => {
  if (val != undefined) return parseInt(val);
  else return 0;
};
export const chartPercentageValue = (item, graphData, firstVal, secondVal, thirdVal, fourthVal) => {
  firstVal = parseIntVal(firstVal);
  secondVal = parseIntVal(secondVal);
  thirdVal = parseIntVal(thirdVal);
  fourthVal = parseIntVal(fourthVal);
  let newState = {};
  let firstChartTotal = firstVal + secondVal;
  let secondChartTotal = thirdVal + fourthVal;
  if (item.groupName == 'stats_Saves') {
    newState.saveMadeInsidePerValue =
      firstChartTotal != 0 ? Math.round((firstVal / firstChartTotal) * 100) : 0;
    newState.saveMadeOutsidePerValue =
      firstChartTotal != 0 ? Math.round((secondVal / firstChartTotal) * 100) : 0;
    newState.saveMadeCaughtPerValue =
      secondChartTotal != 0 ? Math.round((thirdVal / secondChartTotal) * 100) : 0;
    newState.saveMadeParriedPerValue =
      secondChartTotal != 0 ? Math.round((fourthVal / secondChartTotal) * 100) : 0;
  } else if (item.groupName == 'stats_Goals') {
    newState.goalsInsideBoxPerValue =
      firstChartTotal != 0 ? Math.round((firstVal / firstChartTotal) * 100) : 0;
    newState.goalsOutsideBoxPerValue =
      firstChartTotal != 0 ? Math.round((secondVal / firstChartTotal) * 100) : 0;
    newState.rightFootGoalsPerValue =
      secondChartTotal != 0 ? Math.round((thirdVal / secondChartTotal) * 100) : 0;
    newState.leftFootGoalsPerValue =
      secondChartTotal != 0 ? Math.round((fourthVal / secondChartTotal) * 100) : 0;
  } else if (item.groupName == 'stats_Passing') {
    newState.successfulPassesOppPerValue =
      firstChartTotal != 0 ? Math.round((firstVal / firstChartTotal) * 100) : 0;
    newState.unsuccessfulPassesOppPerValue =
      firstChartTotal != 0 ? Math.round((secondVal / firstChartTotal) * 100) : 0;
    newState.successfulPassesOwnPerValue =
      secondChartTotal != 0 ? Math.round((thirdVal / secondChartTotal) * 100) : 0;
    newState.unsuccessfulPassesOwnPerValue =
      secondChartTotal != 0 ? Math.round((fourthVal / secondChartTotal) * 100) : 0;
  }
  return newState;
};
export const popluteCircle = (
  graphData,
  chartValue1,
  chartValue2,
  primaryColor,
  secondryColor,
  index
) => {
  let cdashArrayValue1,
    cdashArrayValue2,
    c1offsetValue,
    c2dashArrayValue1,
    c2dashArrayValue2,
    c2offsetValue,
    defaultOffsetValue = 30,
    chartGap = 3;
  if (chartValue1 == 100) {
    graphData.circleData[index].push(
      { CdashArrayValue1: 100, CdashArrayValue2: 0, offsetValue: '', fillcolor: primaryColor },
      { CdashArrayValue1: 0, CdashArrayValue2: 0, offsetValue: '', fillcolor: '' }
    );
  } else if (chartValue2 == 100) {
    graphData.circleData[index].push(
      { CdashArrayValue1: 0, CdashArrayValue2: 0, offsetValue: '', fillcolor: '' },
      { CdashArrayValue1: 100, CdashArrayValue2: 0, offsetValue: '', fillcolor: secondryColor }
    );
  } else if (chartValue1 == 0 && chartValue2 == 0) {
    graphData.noValue[index] = true;
  } else if (parseInt(chartValue1) <= chartGap * 2) {
    //setting offset for default position
    defaultOffsetValue = -17;
    cdashArrayValue1 = parseInt(chartValue1);
    cdashArrayValue2 = 100 - cdashArrayValue1;
    c1offsetValue = defaultOffsetValue + chartGap * 2;
    c2dashArrayValue1 = parseInt(chartValue2) - parseInt(chartGap * 2);
    c2dashArrayValue2 = 100 - c2dashArrayValue1;
    c2offsetValue = c1offsetValue + c2dashArrayValue1 + chartGap;
    graphData.circleData[index].push(
      {
        CdashArrayValue1: cdashArrayValue1,
        CdashArrayValue2: cdashArrayValue2,
        offsetValue: c1offsetValue,
        fillcolor: primaryColor,
      },
      {
        CdashArrayValue1: c2dashArrayValue1,
        CdashArrayValue2: c2dashArrayValue2,
        offsetValue: c2offsetValue,
        fillcolor: secondryColor,
      }
    );
  } else if (parseInt(chartValue2) <= chartGap * 2) {
    //setting offset for default position
    defaultOffsetValue = 33;
    cdashArrayValue1 = parseInt(chartValue1) - parseInt(chartGap * 2);
    cdashArrayValue2 = 100 - cdashArrayValue1;
    c1offsetValue = defaultOffsetValue + chartGap;
    c2dashArrayValue1 = parseInt(chartValue2);
    c2dashArrayValue2 = 100 - c2dashArrayValue1;
    c2offsetValue = c1offsetValue + c2dashArrayValue1 + chartGap;
    graphData.circleData[index].push(
      {
        CdashArrayValue1: cdashArrayValue1,
        CdashArrayValue2: cdashArrayValue2,
        offsetValue: c1offsetValue,
        fillcolor: primaryColor,
      },
      {
        CdashArrayValue1: c2dashArrayValue1,
        CdashArrayValue2: c2dashArrayValue2,
        offsetValue: c2offsetValue,
        fillcolor: secondryColor,
      }
    );
  } else if (chartValue1 != 100 && chartValue2 != 100) {
    //setting offset for default position
    try {
      if (chartValue1 <= 40 && chartValue2 <= 93) {
        defaultOffsetValue = -13;
      } else if (chartValue1 <= 93 && chartValue2 <= 40) {
        defaultOffsetValue = 30;
      } else if (chartValue1 >= 40 && chartValue2 <= 60) {
        defaultOffsetValue = -3;
      }
    } catch (e) {
      console.log('Incorrect offset value');
    }
    cdashArrayValue1 = parseInt(chartValue1) - parseInt(chartGap);
    cdashArrayValue2 = 100 - cdashArrayValue1;
    c1offsetValue = defaultOffsetValue + chartGap;
    c2dashArrayValue1 = parseInt(chartValue2) - parseInt(chartGap);
    c2dashArrayValue2 = 100 - c2dashArrayValue1;
    c2offsetValue = c1offsetValue + c2dashArrayValue1 + chartGap;
    graphData.circleData[index].push(
      {
        CdashArrayValue1: cdashArrayValue1,
        CdashArrayValue2: cdashArrayValue2,
        offsetValue: c1offsetValue,
        fillcolor: primaryColor,
      },
      {
        CdashArrayValue1: c2dashArrayValue1,
        CdashArrayValue2: c2dashArrayValue2,
        offsetValue: c2offsetValue,
        fillcolor: secondryColor,
      }
    );
  }
  return graphData;
};
export const calculatePercentage = (current, total, isRound) => {
  if (total == 0 || total == null || total == '' || total == undefined) return 0;
  if (current) current = parseInt(current);
  if (total) total = parseInt(total);
  var percentage = (current / total) * 100;
  if (isRound) return Math.round(percentage);
  else return percentage;
};
