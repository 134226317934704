/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  chartPercentageValue,
  popluteCircle,
  chartColor,
  chartIndex,
} from '../../utils/playerProfileStats';
const Passing = ({ t, item, statsType }) => {
  const buildPassingData = (item) => {
    let graphData = {};
    graphData.noValue = [false, false];
    graphData.circleData = [[], []];
    let seasonStatsItems = item && item.stats;
    if (seasonStatsItems && seasonStatsItems.length > 0) {
      for (let i = 0; i < seasonStatsItems.length; i++) {
        let statsItem = seasonStatsItems[i];
        statsItem.statsValue = statsItem.statsValue == '' ? 0 : statsItem.statsValue;
        if (statsType === 'season') {
          if (statsItem && statsItem.statsKey == 'stats_SuccessfulPassesOppositionHalf') {
            graphData.successfulPassesOppKey = statsItem.statsKey;
            graphData.successfulPassesOppValue = statsItem.statsValue;
          } else if (statsItem && statsItem.statsKey == 'stats_UnsuccessfulPassesOppositionHalf') {
            graphData.unsuccessfulPassesOppKey = statsItem.statsKey;
            graphData.unsuccessfulPassesOppValue = statsItem.statsValue;
          } else if (statsItem && statsItem.statsKey == 'stats_SuccessfulPassesownhalf') {
            graphData.successfulPassesOwnKey = statsItem.statsKey;
            graphData.successfulPassesOwnValue = statsItem.statsValue;
          } else if (statsItem && statsItem.statsKey == 'stats_UnsuccessfulPassesOwnHalf') {
            graphData.unsuccessfulPassesOwnKey = statsItem.statsKey;
            graphData.unsuccessfulPassesOwnValue = statsItem.statsValue;
          } else if (statsItem && statsItem.statsKey == 'stats_PassingAccuracy') {
            graphData.passingAccuracyKey = statsItem.statsKey;
            graphData.isPercentage = statsItem.isPercentage;
            if (statsItem.statsValue) {
              try {
                graphData.passingAccuracyValue = Math.round(statsItem.statsValue);
              } catch (e) {
                console.error('incorrect paasing accuracy');
              }
            } else {
              graphData.passingAccuracyValue = 0;
            }
          }
        } else if (statsType === 'live') {
          if (statsItem && statsItem.statsKey == 'stats_Successfulpasses') {
            graphData.successfulPassesOppKey = statsItem.statsKey;
            graphData.successfulPassesOppValue = statsItem.statsValue;
          } else if (statsItem && statsItem.statsKey == 'stats_UnsuccessfulPasses') {
            graphData.unsuccessfulPassesOppKey = statsItem.statsKey;
            graphData.unsuccessfulPassesOppValue = statsItem.statsValue;
          } else if (statsItem && statsItem.statsKey == 'stats_Successfulfinalthirdpasses') {
            graphData.successfulPassesOwnKey = statsItem.statsKey;
            graphData.successfulPassesOwnValue = statsItem.statsValue;
          } else if (statsItem && statsItem.statsKey == 'stats_UnsuccessfulFinalThirdPasses') {
            graphData.unsuccessfulPassesOwnKey = statsItem.statsKey;
            graphData.unsuccessfulPassesOwnValue = statsItem.statsValue;
          } else if (statsItem && statsItem.statsKey == 'stats_TotalPasses') {
            graphData.passingAccuracyKey = statsItem.statsKey;
            graphData.isPercentage = statsItem.isPercentage;
            if (statsItem.statsValue) {
              try {
                graphData.passingAccuracyValue = Math.round(statsItem.statsValue);
              } catch (e) {
                console.error('incorrect paasing accuracy');
              }
            } else {
              graphData.passingAccuracyValue = 0;
            }
          }
        }
      }
    }
    //Calculating Percentage Value from service Values
    graphData = {
      ...graphData,
      ...chartPercentageValue(
        item,
        graphData,
        graphData.successfulPassesOppValue,
        graphData.unsuccessfulPassesOppValue,
        graphData.successfulPassesOwnValue,
        graphData.unsuccessfulPassesOwnValue
      ),
    };
    //Populating First Chart
    if (
      graphData.successfulPassesOppValue != undefined &&
      graphData.unsuccessfulPassesOppValue != undefined
    ) {
      graphData = {
        ...graphData,
        ...popluteCircle(
          graphData,
          graphData.successfulPassesOppPerValue,
          graphData.unsuccessfulPassesOppPerValue,
          chartColor[0],
          chartColor[1],
          chartIndex[0]
        ),
      };
    }
    //Populating Second Chart
    if (
      graphData.successfulPassesOwnValue != undefined &&
      graphData.unsuccessfulPassesOwnValue != undefined
    ) {
      graphData = {
        ...graphData,
        ...popluteCircle(
          graphData,
          graphData.successfulPassesOwnPerValue,
          graphData.unsuccessfulPassesOwnPerValue,
          chartColor[2],
          chartColor[3],
          chartIndex[1]
        ),
      };
    }
    return graphData;
  };
  const [graphData, setGraphData] = useState(null);
  useEffect(() => {
    setGraphData(buildPassingData(item));
  }, [item]);
  return (
    graphData && (
      <div className="chart-wrapper no-width">
        <h3>{t('stats_Passing')}</h3>
        <div className="season-stats-passing">
          <div className="stats-passing-score">
            <span aria-hidden="true">
              {graphData.passingAccuracyValue}
              {graphData.isPercentage && <span>%</span>}
            </span>
            {statsType === 'season' && (
              <React.Fragment>
                <span aria-hidden="true">{t('stats_PassingAccuracy')}</span>
                <span className="screenreader">
                  {`${t('stats_PassingAccuracy')} ${graphData.passingAccuracyValue}%`}
                </span>
              </React.Fragment>
            )}
            {statsType === 'live' && (
              <React.Fragment>
                <span aria-hidden="true">{t('stats_TotalPasses')}</span>
                <span className="screenreader">
                  {`${t('stats_TotalPasses')} ${graphData.passingAccuracyValue}`}
                </span>
              </React.Fragment>
            )}
            {statsType === 'season' && (
              <span className="screenreader">
                <span>{`${t('AccuracyOppoHalf')} ${graphData.successfulPassesOppPerValue}%`}</span>
                <span>{`${t('AccuracyOwnHalf')} ${graphData.successfulPassesOwnPerValue}%`}</span>
              </span>
            )}
            {statsType === 'live' && (
              <span className="screenreader">
                <span>
                  {`${t('stats_AccuratePassPercentage')} ${graphData.successfulPassesOppPerValue}`}
                </span>
                <span>
                  {`${t('stats_AccurateFinalThirdPassPercentage')} ${
                    graphData.successfulPassesOwnPerValue
                  }`}
                </span>
              </span>
            )}
          </div>
          <ul className="stats-passing-charts" aria-hidden="true">
            <li>
              <svg width="124" height="106" viewBox="2 0 42 37">
                {!graphData.noValue[0] && (
                  <circle
                    className="donut-ring"
                    cx="23"
                    cy="18"
                    r="15.8"
                    fill="transparent"
                    stroke={graphData.circleData[0][0].fillcolor}
                    strokeWidth="1.5"
                    strokeDasharray={`${graphData.circleData[0][0].CdashArrayValue1} ${graphData.circleData[0][0].CdashArrayValue2}`}
                    strokeDashoffset={graphData.circleData[0][0].offsetValue}
                  ></circle>
                )}
                {graphData.successfulPassesOppValue > 0 && (
                  <text x="34" y="36">
                    {graphData.successfulPassesOppValue}
                  </text>
                )}
                {!graphData.noValue[0] && (
                  <circle
                    className="donut-ring"
                    cx="23"
                    cy="18"
                    r="15.8"
                    fill="transparent"
                    stroke={graphData.circleData[0][1].fillcolor}
                    strokeWidth="1.5"
                    strokeDasharray={`${graphData.circleData[0][1].CdashArrayValue1} ${graphData.circleData[0][1].CdashArrayValue2}`}
                    strokeDashoffset={graphData.circleData[0][1].offsetValue}
                  ></circle>
                )}
                {graphData.unsuccessfulPassesOppValue > 0 && (
                  <text x="2" y="4">
                    {graphData.unsuccessfulPassesOppValue}
                  </text>
                )}
                <g direction="ltr">
                  <text
                    className="passing-percentage"
                    textAnchor="middle"
                    x="50%"
                    y="50%"
                    dx="3"
                    dy="4"
                  >
                    {graphData.successfulPassesOppPerValue}%
                  </text>
                </g>
                {graphData.noValue[0] && (
                  <circle
                    className="donut-ring"
                    cx="23"
                    cy="18"
                    r="15.8"
                    fill="transparent"
                    stroke="#7b7b7b"
                    strokeWidth="1"
                    strokeDasharray="100 0"
                    strokeDashoffset="33"
                  ></circle>
                )}
              </svg>
            </li>
            <li>
              <svg width="124" height="106" viewBox="2 0 42 37">
                {!graphData.noValue[1] && (
                  <circle
                    className="donut-ring"
                    cx="23"
                    cy="18"
                    r="15.8"
                    fill="transparent"
                    stroke={graphData.circleData[1][0].fillcolor}
                    strokeWidth="1.5"
                    strokeDasharray={`${graphData.circleData[1][0].CdashArrayValue1} ${graphData.circleData[1][0].CdashArrayValue2}`}
                    strokeDashoffset={graphData.circleData[1][0].offsetValue}
                  ></circle>
                )}
                {graphData.successfulPassesOwnValue > 0 && (
                  <text x="34" y="36">
                    {graphData.successfulPassesOwnValue}
                  </text>
                )}
                {!graphData.noValue[1] && (
                  <circle
                    className="donut-ring"
                    cx="23"
                    cy="18"
                    r="15.8"
                    fill="transparent"
                    stroke={graphData.circleData[1][1].fillcolor}
                    strokeWidth="1.5"
                    strokeDasharray={`${graphData.circleData[1][1].CdashArrayValue1} ${graphData.circleData[1][1].CdashArrayValue2}`}
                    strokeDashoffset={graphData.circleData[1][1].offsetValue}
                  ></circle>
                )}
                {graphData.unsuccessfulPassesOwnValue && (
                  <text x="2" y="4">
                    {graphData.unsuccessfulPassesOwnValue}
                  </text>
                )}
                <g direction="ltr">
                  <text
                    className="passing-percentage"
                    textAnchor="middle"
                    x="50%"
                    y="50%"
                    dx="3"
                    dy="4"
                  >
                    {graphData.successfulPassesOwnPerValue}%
                  </text>
                </g>
                {graphData.noValue[1] && (
                  <circle
                    className="donut-ring"
                    cx="23"
                    cy="18"
                    r="15.8"
                    fill="transparent"
                    stroke="#7b7b7b"
                    strokeWidth="1"
                    strokeDasharray="100 0"
                    strokeDashoffset="33"
                  ></circle>
                )}
              </svg>
            </li>
          </ul>
          {statsType === 'season' && (
            <ul className="pass-notations">
              <li>
                <span aria-hidden="true" className="pass-point"></span>
                <span aria-hidden="true">{t('stats_SuccessfulPassesOppositionHalf')}</span>
                <span className="screenreader">
                  {`${t('stats_SuccessfulPassesOppositionHalf')} ${
                    graphData.successfulPassesOppValue
                  }`}
                </span>
              </li>
              <li>
                <span aria-hidden="true" className="pass-point"></span>
                <span aria-hidden="true">{t('stats_UnsuccessfulPassesOppositionHalf')}</span>
                <span className="screenreader">
                  {`${t('stats_UnsuccessfulPassesOppositionHalf')} ${
                    graphData.unsuccessfulPassesOppValue
                  }`}
                </span>
              </li>
              <li>
                <span aria-hidden="true" className="pass-point"></span>
                <span aria-hidden="true">{t('stats_SuccessfulPassesOwnHalf')}</span>
                <span className="screenreader">
                  {`${t('stats_SuccessfulPassesOwnHalf')} ${graphData.successfulPassesOwnValue}`}
                </span>
              </li>
              <li>
                <span aria-hidden="true" className="pass-point"></span>
                <span aria-hidden="true">{t('stats_UnsuccessfulPassesOwnHalf')}</span>
                <span className="screenreader">
                  {`${t('stats_UnsuccessfulPassesOwnHalf')} ${
                    graphData.unsuccessfulPassesOwnValue
                  }`}
                </span>
              </li>
            </ul>
          )}
          {statsType === 'live' && (
            <ul className="pass-notations">
              <li>
                <span aria-hidden="true" className="pass-point"></span>
                <span aria-hidden="true">{t('stats_Successfulpasses')}</span>
                <span className="screenreader">{`${t('stats_Successfulpasses')} ${
                  graphData.successfulPassesOppValue
                }`}</span>
              </li>
              <li>
                <span aria-hidden="true" className="pass-point"></span>
                <span aria-hidden="true">{t('stats_UnsuccessfulPasses')}</span>
                <span className="screenreader">{`${t('stats_UnsuccessfulPasses')} ${
                  graphData.unsuccessfulPassesOppValue
                }`}</span>
              </li>
              <li>
                <span aria-hidden="true" className="pass-point"></span>
                <span aria-hidden="true">{t('stats_Successfulfinalthirdpasses')}</span>
                <span className="screenreader">{`${t('stats_Successfulfinalthirdpasses')} ${
                  graphData.successfulPassesOwnValue
                }`}</span>
              </li>
              <li>
                <span aria-hidden="true" className="pass-point"></span>
                <span aria-hidden="true">{t('stats_UnsuccessfulFinalThirdPasses')}</span>
                <span className="screenreader">
                  {`${t('stats_UnsuccessfulFinalThirdPasses')} ${
                    graphData.unsuccessfulPassesOwnValue
                  }`}
                </span>
              </li>
            </ul>
          )}
        </div>
      </div>
    )
  );
};
export default withTranslation()(Passing);
