/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
export const PrevArrow = (props) => {
  const { onClick, currentSlide, className, btnName } = props;
  return (
    <React.Fragment>
      <button
        onClick={(e) => {
          onClick && onClick();
          arrowEvents(e, 'prev');
        }}
        onKeyPress={(e) => {
          arrowEvents(e, 'prev');
        }}
        onKeyUp={(e) => {
          arrowEvents(e, 'prev');
        }}
        aria-label={btnName ? btnName : 'previous button'}
        className={`slider-prev ${className}`}
        style={{ background: 'none', border: 'none', color: `rgb(42, 42, 42)` }}
        aria-disabled={currentSlide === 0}
      >
        <i className="icon-arrow-prev"></i>
      </button>
    </React.Fragment>
  );
};

export const NextArrow = (props) => {
  const { onClick, currentSlide, slideCount, slideShow, className, btnName } = props;
  return (
    <React.Fragment>
      <button
        onClick={(e) => {
          onClick && onClick();
          arrowEvents(e, 'next');
        }}
        onKeyPress={(e) => {
          arrowEvents(e, 'next');
        }}
        onKeyUp={(e) => {
          arrowEvents(e, 'next');
        }}
        aria-label={btnName ? btnName : 'next button'}
        className={`slider-next ${className}`}
        style={{ background: 'none', border: 'none', color: `rgb(42, 42, 42)` }}
        aria-disabled={currentSlide + slideShow === slideCount}
      >
        <i className="icon-arrow-next"></i>
      </button>
    </React.Fragment>
  );
};

export const onInitAccessibility = (slideTrack, slides, nextArrow, prevArrow) => {
  if (slideTrack) {
    slideTrack.setAttribute('role', 'list');
    let slickCurrent = slideTrack.querySelector('.slick-current');
    if (slickCurrent) {
      slickCurrent.setAttribute('aria-hidden', 'false');
    }
  }
  if (slides) {
    slides.forEach((item) => {
      item.setAttribute('role', 'listitem');
      item.removeAttribute('tabindex');
    });
  }
  let ariaTrue = slideTrack && slideTrack.querySelectorAll('div[aria-hidden="true"]');
  if (ariaTrue) {
    commonAccessibility(ariaTrue);
  }
};

export const afterChangeAccessbility = (slick) => {
  if (slick.track) {
    nextPrevType ? slick.track.classList.remove('nofocus') : slick.track.classList.add('nofocus');
    let slickCurrent = slick.track.querySelector('.slick-current');
    if (slickCurrent) {
      slickCurrent.setAttribute('aria-hidden', 'false');
    }
  }
  if (nextPrevFlag === 'next') {
    let slickTrack = slick.track && slick.track.querySelectorAll('[aria-hidden="false"]');
    const focusAdd = slickTrack && slickTrack[slickTrack.length - 1];
    if (focusAdd) {
      let anchorActive = focusAdd.querySelector('.mu-item__info a');
      if (anchorActive) {
        anchorActive.setAttribute('tabindex', '0');
        anchorActive.focus();
      }
    }
  } else if (nextPrevFlag === 'prev') {
    let slickTrack = slick.track && slick.track.querySelectorAll('[aria-hidden="false"]');
    const focusAdd = slickTrack && slickTrack[0];
    if (focusAdd) {
      let anchorActive = focusAdd.querySelector('.mu-item__info a');
      if (anchorActive) {
        anchorActive.setAttribute('tabindex', '0');
        anchorActive.focus();
      }
    }
  }

  let ariaTrue = slick.track && slick.track.querySelectorAll('div[aria-hidden="true"]');
  if (ariaTrue) {
    commonAccessibility(ariaTrue);
  }
  setTimeout(function () {
    if (slick && slick.slides) {
      slick.slides.forEach((item) => {
        item.removeAttribute('tabindex');
      });
    }
  }, 1000);
};

export const beforeChangeAccessbility = (slick) => {
  if (slick) {
    let ariaTrue = slick.track && slick.track.querySelectorAll('div[aria-hidden="true"]');
    if (ariaTrue) {
      ariaTrue.forEach((item) => {
        let anchor = item.querySelector('.mu-item__info a');
        if (anchor) {
          anchor.setAttribute('tabindex', '0');
        }
      });
    }
  }
};

const commonAccessibility = (ariaTrue) => {
  ariaTrue.forEach((item) => {
    let tabIndex = item.querySelectorAll("[tabindex='0']");
    if (tabIndex) {
      tabIndex.forEach((item) => {
        item.setAttribute('tabindex', '-1');
      });
    }
    let anchor = item.querySelectorAll('.mu-item__info a');
    if (anchor) {
      anchor.forEach((item) => {
        item.setAttribute('tabindex', '-1');
      });
    }
  });
};

let nextPrevFlag, nextPrevType;
const arrowEvents = (e, value) => {
  nextPrevFlag = value;
  nextPrevType = e.which === 13 ? true : false;
};
