/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect } from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';
import { getCssUrl } from '../utils/utils';
import MU from '../constants/muConstants';
import layout from '../constants/layoutConstants';
import SkipToContent from '../components/Common/SkipToContent';
import { fetchMyListData, postMyLocalData } from '../actions/mutvMyListAction';
import { MutvListProvider, useMutvListingValue } from '../context/mutvMyListContext';
import { useSponsorValue } from '../context/sponsorContext';

const MutvLayout = ({ route, context }) => {
  const name = route && route.fields && route.fields.Name && route.fields.Name.value;
  const pagename = route && route.name;
  const getLoction = () => {
    return getCssUrl(layout.MUTV_LAYOUT, MU.language);
  };
  const { pageEditing } = context;

  return (
    <React.Fragment>
      <Helmet>
        {pageEditing && (
          <link href={getCssUrl(layout.EDITOR_LAYOUT, MU.language)} rel="stylesheet" />
        )}
      </Helmet>
      {(typeof window === 'undefined' || process.env.NODE_ENV === 'development') && (
        <Helmet>
          <link href={getLoction()} rel="stylesheet" />
        </Helmet>
      )}
      <div
        className={`page-wrap ${MU.reverseTheme} ${MU.gapClass} ${pageEditing ? 'expeditor' : ''}`}
      >
        <SkipToContent />
        <Placeholder
          missingComponentComponent={() => {
            return null;
          }}
          name="mufc-ph-header"
          rendering={route}
          isMutv={true}
        />
        <div className="main-container" id="content">
          {name ? (
            <h1 className="screenreader">{name}</h1>
          ) : (
            <h1 className="screenreader">{pagename}</h1>
          )}
          <MutvListProvider>
            <MutvListContainer route={route} />
          </MutvListProvider>
          <div>
            <Placeholder
              missingComponentComponent={() => {
                return null;
              }}
              name="mufc-ph-footercontainer"
              rendering={route}
              isMutv={true}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MutvLayout;

const MutvListContainer = (props) => {
  const [{ userLoginDetail }] = useSponsorValue();
  const [{}, dispatch] = useMutvListingValue();
  useEffect(() => {
    if (userLoginDetail && userLoginDetail.UID) {
      try {
        fetchMyListData(dispatch, userLoginDetail).then((response) => {
          const localData =
            localStorage.getItem('localData') && JSON.parse(localStorage.getItem('localData'));
          localData &&
            response &&
            response.data &&
            postMyLocalData({
              dispatch,
              localData,
              userLoginDetail,
              getResponseData: response.data,
            });
        });
      } catch (e) {
        console.log('error', e);
      }
    }
  }, [userLoginDetail]);

  return (
    <React.Fragment>
      <Placeholder name="mutv-ph-body" rendering={props.route} />
    </React.Fragment>
  );
};
