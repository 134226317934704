/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';

const SearchFilter = (props) => {
  const { filterKeyData, lastSegment, updateTemplate } = props;
  const updateTemplateOnkeypress = (e, tab, value) => {
    let code = e.keyCode || e.which;
    if (code === 32 || code === 13) {
      updateTemplate(tab, value);
    }
  };
  const addId = (valKey) => {
    valKey = valKey && valKey.split('/').pop();
    return 'searchFilter' + valKey.toLowerCase();
  };
  const getActive = (lastSegment, key) => {
    try {
      const value = key && key.substr(key.lastIndexOf('/') + 1);
      return value && lastSegment === value.toLowerCase() ? 'active' : '';
    } catch (e) {
      console.log('error in getting active tab');
    }
  };
  return (
    <div className="search__tabs__wrapper grid-12">
      {filterKeyData && filterKeyData.length > 0 && (
        <nav aria-label="search filter">
          <div className="search__tabs">
            <ul>
              {filterKeyData.map((filterKey, i) => (
                <li
                  key={`filter${i}`}
                  className={getActive(lastSegment, filterKey.destinationurl_s)}
                  onClick={() => {
                    updateTemplate(filterKey.destinationurl_s, filterKey.value);
                  }}
                  id={addId(filterKey.destinationurl_s)}
                  onKeyUp={(e) =>
                    updateTemplateOnkeypress(e, filterKey.destinationurl_s, filterKey.value)
                  }
                >
                  <span
                    tabIndex="0"
                    role="link"
                    aria-current={
                      getActive(lastSegment, filterKey.destinationurl_s) === 'active' ? true : false
                    }
                  >
                    {filterKey.key}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </nav>
      )}
    </div>
  );
};

export default withTranslation()(SearchFilter);
