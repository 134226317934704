/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import * as track from '../../../utils/analytics';
const Download = (props) => {
  const { t, fields, isAccordionExpended, isAccordion } = props;
  const [state, setState] = useState(null);
  /* Up to five download boxes can be in one download component */
  const downloads = [1, 2, 3, 4, 5];
  useEffect(() => {
    let data = [];
    Object.keys(downloads).map((value, key) => {
      data.push({
        AdditionalText: fields[`AdditionalText${key + 1}`],
        DownloadLink: fields[`DownloadLink${key + 1}`],
        DownloadText: fields[`DownloadText${key + 1}`],
        DownloadTitle: fields[`DownloadTitle${key + 1}`],
      });
      return '';
    });
    setState(data);
  }, []);
  const getSkin = () => {
    let skinColor = fields && fields.Skin && fields.Skin.value;
    return skinColor === 'dark' ? 'tpl-download skin-grey' : 'tpl-download skin-white';
  };
  const trackLink = (data, e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        ...data,
      },
      e
    );
  };
  return (
    <React.Fragment>
      <div className="clear-all-section"></div>
      <section className={getSkin()}>
        <div className="tpl-download__wrapper grid-6">
          {fields && fields.DownloadHeading && (
            <h2 className="tpl-download__title">
              <Text field={fields.DownloadHeading} />
            </h2>
          )}
          {state &&
            state.map(
              (value, key) =>
                value &&
                value.DownloadLink &&
                value.DownloadLink.value &&
                value.DownloadLink.value.DocumentURL && (
                  <a
                    key={key}
                    href={value.DownloadLink.value.DocumentURL}
                    className="tpl-download__block"
                    target="_blank"
                    rel="noreferrer"
                    tabIndex={isAccordion ? (isAccordionExpended ? null : -1) : 0}
                    aria-hidden={isAccordion ? (isAccordionExpended ? null : true) : null}
                    aria-label={`${value.DownloadTitle && value.DownloadTitle.value} ${
                      value.AdditionalText && value.AdditionalText.value
                    } ${value.DownloadText && value.DownloadText.value} File ${
                      value.DownloadLink.value && value.DownloadLink.value.FileSize
                    } MB`}
                    onClick={(e) =>
                      trackLink(
                        {
                          button_name: value.DownloadTitle && value.DownloadTitle.value,
                          destination_url: value.DownloadLink.value.DocumentURL,
                        },
                        e
                      )
                    }
                  >
                    <div className="tpl-download__icon" aria-hidden="true" tabIndex="-1">
                      <i className="icon-download-file"></i>
                      <label>
                        {value.DownloadLink.value && value.DownloadLink.value.FileSize} {`MB`}
                      </label>
                    </div>
                    <div className="tpl-download__content" aria-hidden="true" tabIndex="-1">
                      <p className="content-heading">
                        <Text field={value.DownloadTitle} />
                      </p>
                      <p>
                        <Text field={value.AdditionalText} />
                      </p>
                      <span className="tpl-download__link">
                        <Text field={value.DownloadText} />
                        <i className="icon-cta-arrow-next"></i>
                      </span>
                    </div>
                  </a>
                )
            )}
        </div>
      </section>
    </React.Fragment>
  );
};
Download.defaultProps = {
  isAccordion: false,
};
export default withTranslation()(Download);
