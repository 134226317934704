/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { getLiveEventsBlogData, jsonStringify } from '../../utils/structureData';
const LiveEventsData = (props) => {
  const { liveBlogPostingStructureData } = props.structureData;
  return (
    <Helmet>
      {liveBlogPostingStructureData && (
        <script type="application/ld+json">{`${jsonStringify(
          getLiveEventsBlogData(liveBlogPostingStructureData)
        )}`}</script>
      )}
    </Helmet>
  );
};
export default LiveEventsData;
