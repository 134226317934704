/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
const Error = ({ onClick, scrollUp, t }) => {
  return (
    <div className="error-whoops__container">
      <div className="grid-12 error-whoops__inner loadmore">
        <h1>{t('Whoops')}</h1>
        <p>{t('thereissomethingworngwithinternet')}</p>
        <div className="red-btn-skew">
          <a role="button" onClick={onClick}>
            <span>{t('Retry')}</span>
            <i className="icon-follow-live"></i>
          </a>
        </div>
        <div className="white-btn-skew">
          <a role="button" onClick={scrollUp}>
            <span>{t('Latest')}</span>
            <i className="tag-red-arrow"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Error);
