/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useRef, useState } from 'react';
import { checkResponseDocs } from '../../../utils/utils';
import MutvHeroContainer from './MutvHeroContainer';
import moment from 'moment';
import api from '../../../utils/muApi';
import { GET_MUTV_HEROCONTAINER } from '../../../constants/endpoints';
import Loader from '../../Common/Loader';
import { useSponsorValue } from '../../../context/sponsorContext';
import { withTranslation } from 'react-i18next';
import { setMutvHeroVideoStatus } from '../../../actions/sponsorAction';
import LazyLoad from 'react-lazyload';
import ImgCrop from '../../Common/ImgCrop';

const THRESHOLD_TIME = 900000;
const areEqual = (prevProps, nextProps) => true;
// eslint-disable-next-line react/display-name
const HeroContainer = React.memo(({ fields, t }) => {
  const timer = useRef(0);
  const timer1 = useRef(0);
  const [showLoader, setShowLoader] = useState(false);
  const [{ showHero, isMutvHeroVideoPlaying }, dispatch] = useSponsorValue();
  const getThresholdTime = (givenTime) => {
    const time = moment(givenTime).diff(moment(), 'seconds');
    const timerTime = time && time > 0 ? time * 1000 : 0;
    return timerTime + 5000;
  };
  const timerFunction = (givenTime) => {
    clearTimeout(timer1.current);
    timer1.current = setTimeout(() => {
      callApi('nextCard');
    }, getThresholdTime(givenTime));
  };
  const currentLiveEpg = (item) => {
    const startTime = moment(item.startdatetime_tdt);
    const endTime = moment(item.enddatetime_tdt);
    return moment().isBetween(startTime, endTime);
  };
  const getData = (response) => {
    const MuTvHeroContainerResponse =
      response &&
      checkResponseDocs(response.MuTvHeroContainerResponse) &&
      response.MuTvHeroContainerResponse.response.docs[0];
    if (MuTvHeroContainerResponse && MuTvHeroContainerResponse.isenablemutvcontainer_b) {
      let heroDetails,
        EPGResponse,
        futureEPGData,
        heroDetailsArray,
        currentEpgLiveStreamResponse,
        showLiveEpgStream,
        heroDetailSortedArray;
      const uniqueItemIds = new Set();
      const containerType =
        MuTvHeroContainerResponse.containertype_t &&
        MuTvHeroContainerResponse.containertype_t.toLowerCase();
      if (!(containerType === 'vod' || containerType === 'epg')) {
        return false;
      }
      const AudioStreamResponse =
        checkResponseDocs(response.AudioStreamResponse) &&
        response.AudioStreamResponse.response.docs[0];
      const EpgLiveStreamResponse =
        checkResponseDocs(response.EpgLiveStreamResponse) &&
        response.EpgLiveStreamResponse.response.docs;
      const PricePackResponse =
        fields &&
        fields.PricePack &&
        checkResponseDocs(fields.PricePack.PricePackResponse) &&
        fields.PricePack.PricePackResponse.response.docs;
      const Settings = fields && fields.Settings && fields.Settings;

      if (
        containerType === 'vod' &&
        (checkResponseDocs(response.VodResponse) ||
          checkResponseDocs(response.EpgLiveStreamPreviewResponse) ||
          checkResponseDocs(response.EpgLiveStreamResponse))
      ) {
        const previewResponseArray = [];
        const epgLiveStreamResponseArray = [];

        if (checkResponseDocs(response.EpgLiveStreamPreviewResponse)) {
          const previewResponse = response?.EpgLiveStreamPreviewResponse?.response?.docs;
          previewResponseArray.push(
            ...previewResponse.filter((item) =>
              moment().isSameOrBefore(moment(item?.startdatetime_tdt))
            )
          );
        }

        if (checkResponseDocs(response.EpgLiveStreamResponse)) {
          currentEpgLiveStreamResponse = EpgLiveStreamResponse.find((item) => currentLiveEpg(item));
          showLiveEpgStream =
            currentEpgLiveStreamResponse && currentEpgLiveStreamResponse.enablevideomutv_b;

          if (showLiveEpgStream) {
            epgLiveStreamResponseArray.push(currentEpgLiveStreamResponse);
          }
        }

        const vodResponseArray = checkResponseDocs(response.VodResponse)
          ? response.VodResponse.response.docs
          : [];
        heroDetailsArray = [
          // filter out duplicates based on itemid_s
          ...epgLiveStreamResponseArray
            .map((item) => {
              if (!uniqueItemIds.has(item.itemid_s)) {
                uniqueItemIds.add(item.itemid_s);
                return { ...item, type: 'epg' };
              }
              return null;
            })
            .filter(Boolean), // Filter out null values from the map
          ...previewResponseArray
            .map((item) => {
              if (!uniqueItemIds.has(item.itemid_s)) {
                uniqueItemIds.add(item.itemid_s);
                return { ...item, type: 'preview' };
              }
              return null;
            })
            .filter(Boolean),
          ...vodResponseArray
            .map((item) => {
              if (!uniqueItemIds.has(item.itemid_s)) {
                uniqueItemIds.add(item.itemid_s);
                return { ...item, type: 'vod' };
              }
              return null;
            })
            .filter(Boolean),
        ];
        heroDetailSortedArray = heroDetailsArray && heroDetailsArray.slice(0, 6);
        heroDetails = heroDetailsArray[0];
      } else if (containerType === 'epg' && checkResponseDocs(response.EpgScheduleLiveResponse)) {
        EPGResponse = response.EpgScheduleLiveResponse.response.docs;
        heroDetails = response.EpgScheduleLiveResponse.response.docs.find((item) =>
          currentLiveEpg(item)
        );
      }
      if (EPGResponse) {
        let datetime_tdt = 'startdatetime_tdt';
        if (containerType === 'vod' && showLiveEpgStream) {
          datetime_tdt = 'enddatetime_tdt';
        }
        futureEPGData = EPGResponse.find((item) => moment(item[datetime_tdt]).isAfter(moment()));
        futureEPGData && timerFunction(futureEPGData[datetime_tdt]);
      }
      return heroDetails
        ? {
            MuTvHeroContainerResponse,
            heroDetails,
            heroDetailSortedArray,
            AudioStreamResponse,
            PricePackResponse,
            currentEpgLiveStreamResponse,
            showLiveEpgStream,
            Settings,
          }
        : false;
    }
    return false;
  };

  const [state, setState] = useState(
    fields &&
      fields.MUTVHeroContainer &&
      fields.MUTVHeroContainer.value &&
      fields.MUTVHeroContainer.value.Response &&
      getData(fields.MUTVHeroContainer.value.Response)
  );

  useEffect(() => {
    clearInterval(timer.current);
    timer.current = setInterval(() => {
      callApi();
    }, THRESHOLD_TIME);
  }, []);

  const callApi = (type) => {
    if (type === 'close') {
      setShowLoader(true);
    }
    const route = GET_MUTV_HEROCONTAINER;
    api
      .get({ route })
      .then((data) => {
        const apiData = data && data.data && getData(data.data);
        setState(apiData);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        console.log('error in fetching MUTVHeroContainer api');
      });
  };

  const videoEndedBanner = (imageCrops) => {
    return (
      <div className="mutv-hero free-trail mask">
        <div className="mu-content">
          <div className="mu-item video">
            <div className="img-holder">
              {imageCrops && (imageCrops.CropUrl || imageCrops.CropURL) && (
                <LazyLoad once={true} offset={100}>
                  <ImgCrop
                    imgCrop={imageCrops.CropUrl || imageCrops.CropURL}
                    alt={imageCrops.AltText}
                    isWebpAvailable={imageCrops.isWebpAvailable}
                    gridClass="grid-12"
                    isUpdateImage={true}
                  />
                </LazyLoad>
              )}
              <div className="livestream-ended">
                <h2 className="mu-item__title">{t('mutvvideoendweb')}</h2>
              </div>
              <button
                className="close-btn"
                onClick={() => {
                  setMutvHeroVideoStatus(dispatch, { value: false });
                  callApi('close');
                }}
              >
                <i className="icon-cross" data-closemodal="close"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getMutvHeroContainer = () => {
    if (state && showHero && state.heroDetails) {
      return (
        <React.Fragment>
          {showLoader && <Loader style={{ zIndex: 9999999 }} />}
          <MutvHeroContainer callApi={callApi} data={state} maskRequired={true} />
        </React.Fragment>
      );
    } else if (
      (!state || !state.heroDetails) &&
      showHero &&
      isMutvHeroVideoPlaying &&
      isMutvHeroVideoPlaying.value
    ) {
      return videoEndedBanner(isMutvHeroVideoPlaying.img);
    }
    return null;
  };

  return getMutvHeroContainer();
}, areEqual);

export default withTranslation()(HeroContainer);
