/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { getMatchTime } from '../../../utils/momentDate';
import { withTranslation } from 'react-i18next';
import { HomeTeam, AwayTeam } from '../../../constants/globalConstants';
import { CompetitionLogo } from './CompetitionLogo';
const MatchInfo = ({ t, celumimages, matchDate, cardSequence, liveMatch, resultdata }) => {
  /**
   *
   * @param {home/away team type on basis of that we return home and away score } teamType
   */
  const getTeamScore = () => {
    if (resultdata && resultdata.HomeResult && resultdata.AwayResult) {
      return `${resultdata.HomeResult.Score ? resultdata.HomeResult.Score : 0} - ${
        resultdata.AwayResult.Score ? resultdata.AwayResult.Score : 0
      }`;
    }
  };

  return (
    <section className="match-item__info" aria-hidden="true">
      <div className="match-item__info--team">
        <CompetitionLogo celumimages={celumimages} teamType={HomeTeam}></CompetitionLogo>
      </div>
      {liveMatch && cardSequence === 1 ? (
        <div className="match-item__info--team live">{t('Live')}</div>
      ) : cardSequence === 2 || cardSequence === 3 ? (
        <div className="match-item__info--team result">
          <span>{matchDate && getMatchTime(matchDate)}</span>
        </div>
      ) : cardSequence === 1 ? (
        <div className="match-item__info--team">{getTeamScore()}</div>
      ) : (
        <div className="match-item__info--team no-data"> </div>
      )}
      <div className="match-item__info--team">
        <CompetitionLogo celumimages={celumimages} teamType={AwayTeam}></CompetitionLogo>
      </div>
    </section>
  );
};

export default withTranslation()(React.memo(MatchInfo));
