/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import { fetchMutvListing } from '../../../actions/mutvListingAction';
import { MutvListingProvider, useMutvListingValue } from '../../../context/mutvListingContext';
import CarouselListing from './CarouselListing';
import * as track from '../../../utils/analytics';
import { EPISODE_CAROUSEL } from '../../../constants/containerConstants';
import FilterDropdown from './FilterDropdown';
import LazyLoad from 'react-lazyload';
import ImgCrop from '../../Common/ImgCrop';
import { CONTENT_TYPE } from '../../../constants/globalConstants';
import Sponsor from '../../Common/Sponsor';

const EpisodeCarouselListing = (props) => (
  <MutvListingProvider>
    <EpisodeListingContainer {...props} />
  </MutvListingProvider>
);

const EpisodeListingContainer = (props) => {
  const [
    {
      status,
      cardData,
      startListIndex,
      showLoadMore,
      seriesType,
      filterObj,
      carouselDetails,
      filters,
      itemsNumber,
      isMutvMovie,
    },
    dispatch,
  ] = useMutvListingValue();

  const { itemid } = props;
  const [activeFilter, setActiveFilter] = useState(null);

  useEffect(() => {
    callApi(0, activeFilter, 'default');
  }, []);

  useEffect(() => {
    !activeFilter && filters && filters.length > 0 && setActiveFilter(filters[0]);
  }, [filters]);

  const callApi = (startIndex, activeFilter, actionType) => {
    fetchMutvListing(
      dispatch,
      itemid,
      startIndex,
      itemsNumber,
      activeFilter,
      actionType,
      seriesType
    ).then(() => {
      setTimeout(() => {
        if (actionType === 'default') {
          track.cardImpression();
        } else {
          track.cardLoadImpression();
        }
      }, 500);
    });
  };

  const loadMore = () => {
    callApi(startListIndex + itemsNumber, activeFilter, 'loadMore');
  };

  const changeFilter = (data) => {
    if (data !== activeFilter) {
      setActiveFilter(data);
      callApi(0, data, 'default');
    }
  };
  const filterDropDown = () => {
    return (
      <React.Fragment>
        <FilterDropdown
          changeFilter={changeFilter}
          filters={filters}
          filterObj={filterObj}
          activeFilter={activeFilter}
          seriesType={seriesType}
        />
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      {carouselDetails && carouselDetails.ImageDetails && (
        <div className="mutv-hero show-episode series-screen mask banner-height">
          <div className="mu-content">
            <div className="mu-item video">
              <div className="img-holder">
                <LazyLoad once={true} offset={100}>
                  <ImgCrop
                    imgCrop={carouselDetails.ImageDetails.CropUrl}
                    alt={carouselDetails.ImageDetails.AltText}
                    isWebpAvailable={carouselDetails.ImageDetails.isWebpAvailable}
                    gridClass="grid-12"
                  ></ImgCrop>
                </LazyLoad>
              </div>
              <div>
                <Sponsor
                  contentType={carouselDetails.contenttype_t}
                  sponsorInfo={carouselDetails.sponsordetailinfo_s}
                  type={CONTENT_TYPE}
                  author={carouselDetails.__publishedby_t}
                  createdDateTime={carouselDetails.__published_tdt}
                  itemId={carouselDetails.itemid_s}
                  tags={carouselDetails.taglist_sm}
                  cardName={carouselDetails.heading}
                  container={EPISODE_CAROUSEL}
                />
              </div>
              <div className="container">
                <div className="mu-item__info">
                  {carouselDetails.heading && (
                    <h2 className="mu-item__title">{carouselDetails.heading}</h2>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="episode-carousel_listing">
        <div className="container mutv-container filters">
          <div className="mutv-shows__filter">
            {carouselDetails && carouselDetails.ImageDetails && (
              <React.Fragment>
                <div className="mutv-shows__filter__wrapper desktop-view">
                  {carouselDetails.description_t && (
                    <div className="carsouselDescription">{carouselDetails.description_t}</div>
                  )}
                  {filters && filters.length > 0 && filterDropDown()}
                </div>
                <div className="mutv-shows__filter__wrapper mobile-view">
                  {carouselDetails.description_t && (
                    <div className="carsouselDescription">{carouselDetails.description_t}</div>
                  )}
                </div>
                <div className="mutv-shows__filter__wrapper mobile-view">
                  {filters && filters.length > 0 && filterDropDown()}
                </div>
              </React.Fragment>
            )}

            {carouselDetails && !carouselDetails.ImageDetails && (
              <div className="mutv-shows__filter__wrapper">
                {carouselDetails.heading && <h3>{carouselDetails.heading}</h3>}
                {filters && filters.length > 0 && filterDropDown()}
              </div>
            )}
          </div>
          <CarouselListing
            containerType={EPISODE_CAROUSEL}
            aspectRatio={carouselDetails && carouselDetails.aspectRatio}
            status={status}
            cardData={cardData}
            listView={false}
            showLoadMore={showLoadMore}
            loadMore={loadMore}
            isMutvMovie={isMutvMovie}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default EpisodeCarouselListing;
