/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { getMatchDate } from '../../utils/momentDate';
export const TimeFormatStrip = ({ eventDate }) => {
  return (
    eventDate && (
      <span className="home-content-panel__details-social__time-day">
        {getMatchDate(eventDate.eventdate_tdt, 'dddd DD MMM YYYY HH:mm')}
      </span>
    )
  );
};

export default TimeFormatStrip;
