/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  HomeTeam,
  AwayTeam,
  SecondLeg,
  Aggregate,
  GoalBy,
  Versus,
} from '../../../constants/globalConstants';
export const TeamsName = ({ t, cardSequence, liveMatch, data }) => {
  /**
   *
   * @param {home/away team type} teamType
   * return team short name and screenreader text
   */
  const getTeamName = (teamType) => {
    return (
      <React.Fragment>
        {cardSequence === 1 && !liveMatch && <span className="screenreader">{GoalBy}</span>}
        {data && teamType === HomeTeam && data.hometeamshortname_t}
        {data && teamType === AwayTeam && data.awayteamshortname_t}
        {cardSequence === 1 && !liveMatch && (
          <span className="screenreader">{getTeamScore(teamType)} </span>
        )}
      </React.Fragment>
    );
  };
  /**
   *
   * @param @param {home/away team type on basis of that return score of teams} teamType
   *
   */
  const getTeamScore = (teamType) => {
    if (data && data.resultdata_t) {
      if (teamType === HomeTeam && data.resultdata_t.HomeResult) {
        return data.resultdata_t.HomeResult.Score ? data.resultdata_t.HomeResult.Score : 0;
      } else if (teamType === AwayTeam && data.resultdata_t.AwayResult) {
        return data.resultdata_t.AwayResult.Score ? data.resultdata_t.AwayResult.Score : 0;
      }
    }
  };
  /**
   * return Dom of aggrigate score.
   */
  const getAggScore = () => {
    if (data && data.resultdata_t && cardSequence === 1 && !liveMatch) {
      if (data.resultdata_t.MatchType && data.resultdata_t.ResultType) {
        if (
          data.resultdata_t.MatchType.toLowerCase() === SecondLeg ||
          data.resultdata_t.ResultType.toLowerCase() === Aggregate
        ) {
          if (data.resultdata_t.HomeResult && data.resultdata_t.AwayResult) {
            return (
              <p className="match-item__info--agg">
                (<span className="screenreader">{Aggregate}</span>
                <span aria-hidden="true">{t('Agg')}</span>:
                {`${data.resultdata_t.HomeResult.AggScore}-${data.resultdata_t.AwayResult.AggScore}`}
                )
              </p>
            );
          }
        }
      }
    }
  };

  return (
    <section className="match-item__info-details">
      <div className="match-item__info--team-name">{getTeamName(HomeTeam)}</div>
      <div className="match-item__info--team-vs">
        <span aria-hidden="true">{t('VS')}</span>
        <span className="screenreader">{Versus}</span>
      </div>

      <div className="match-item__info--team-name">{getTeamName(AwayTeam)}</div>
      {getAggScore()}
    </section>
  );
};

export default withTranslation()(React.memo(TeamsName));
