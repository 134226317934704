/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import PrimarySponsors from './PrimarySponsors';

const PowerdBySponsor = ({ sponsorBy, headerIT }) => {
  const contentSponsorBy = sponsorBy && [sponsorBy];
  const moduleSponsorBy = headerIT;
  /**
   * render Powerdby sponsor by using contentlevel/module level data
   */
  const RenderSponsorBy = () => {
    if (contentSponsorBy && contentSponsorBy.length > 0) {
      return (
        contentSponsorBy[0] &&
        contentSponsorBy[0].fields && (
          <PrimarySponsors contentSponsorBy={contentSponsorBy} isPowerdby={true}></PrimarySponsors>
        )
      );
    } else if (moduleSponsorBy && moduleSponsorBy.length > 0) {
      moduleSponsorBy.length = 1;
      return (
        <PrimarySponsors moduleSponsorBy={moduleSponsorBy} isPowerdby={true}></PrimarySponsors>
      );
    } else {
      return null;
    }
  };

  return RenderSponsorBy();
};

export default React.memo(PowerdBySponsor);
