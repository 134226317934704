/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import LeftPanel from './LeftPanel';
import StatsBar from '../Common/StatsBar';
import SponsorPanel from './SponsorPanel';
import ImagePanel from './ImagePanel';
import { getStatsBarData } from '../../utils/utils';
import ErrorBoundary from '../Common/ErrorBoundary';

const HeadToHead4 = ({ item, t, gridClass, carousalClass }) => {
  const isPlayer =
    item && item.opta_statentitytype_t && item.opta_statentitytype_t.toLowerCase() === 'player'
      ? true
      : false;

  const renderBar = (entityObj) => {
    return entityObj.map((value, key) =>
      value && value[0] && value[1] ? (
        <li key={key}>
          {value[0].percentVal > 0 && (
            <div
              className={`percentage-value ${value[0].percentVal === 100 ? 'is-completed' : ''}`}
              style={{ width: `${value[0].percentVal}%` }}
            ></div>
          )}
          <span className="scoreA" aria-hidden="true">
            {value[0].Value}
            {value[0].IsPercentage === 'true' || value[0].IsPercentage === true ? '%' : ''}
          </span>
          <span className="score-info" aria-hidden="true">
            {value[0].StatsName}
          </span>
          <span className="scoreB" aria-hidden="true">
            {value[1].Value}
            {value[1].IsPercentage === 'true' || value[1].IsPercentage === true ? '%' : ''}
          </span>
          {isPlayer && item.opta_statentities_t && item.opta_statentities_t.length > 1 && (
            <div className="screenreader">
              {`${value[0].Value} ${
                value[0].IsPercentage === 'true' || value[0].IsPercentage === true ? '%' : ''
              } ${value[0].StatsName} ${t('by')} ${
                item.opta_statentities_t[0].KnownName
                  ? item.opta_statentities_t[0].KnownName
                  : item.opta_statentities_t[0].LastName
              }`}
              {`${value[1].Value} ${
                value[1].IsPercentage === 'true' || value[1].IsPercentage === true ? '%' : ''
              } ${value[1].StatsName} ${t('by')} ${
                item.opta_statentities_t[1].KnownName
                  ? item.opta_statentities_t[1].KnownName
                  : item.opta_statentities_t[1].LastName
              }`}
            </div>
          )}
          {!isPlayer && item.opta_statentities_t && item.opta_statentities_t.length > 1 && (
            <div className="screenreader">
              {`${value[0].Value} ${
                value[0].IsPercentage === 'true' || value[0].IsPercentage === true ? '%' : ''
              } ${value[0].StatsName} ${t('by')} ${item.opta_statentities_t[0].TeamName}`}
              {`${value[1].Value} ${
                value[1].IsPercentage === 'true' || value[1].IsPercentage === true ? '%' : ''
              } ${value[1].StatsName} ${t('by')} ${item.opta_statentities_t[1].TeamName}`}
            </div>
          )}
        </li>
      ) : null
    );
  };

  return (
    item && (
      <div className={gridClass}>
        <div className={carousalClass}>
          <div
            className={`stats-card head-to-head ${item.IsMUEntityClass} ${
              isPlayer ? 'player-section' : 'team-section'
            }`}
          >
            <SponsorPanel item={item}></SponsorPanel>
            <div className="stats-card__info">
              <LeftPanel item={item} />
              <div className="stats-card__info__right">
                <div className="chart-area">
                  <div className="players">
                    {isPlayer &&
                      item.opta_statentities_t &&
                      item.opta_statentities_t.length > 1 && (
                        <div className="screenreader">
                          {`${
                            item.opta_statentities_t[0].KnownName
                              ? item.opta_statentities_t[0].KnownName
                              : item.opta_statentities_t[0].LastName
                          } ${t('Shirtnumber')} ${item.opta_statentities_t[0].JerseyNumber} ${t(
                            'VS'
                          )} ${
                            item.opta_statentities_t[1].KnownName
                              ? item.opta_statentities_t[1].KnownName
                              : item.opta_statentities_t[1].LastName
                          } ${t('Shirtnumber')} ${item.opta_statentities_t[1].JerseyNumber}`}
                        </div>
                      )}
                    {!isPlayer &&
                      item.opta_statentities_t &&
                      item.opta_statentities_t.length > 1 && (
                        <div className="screenreader">
                          {`${item.opta_statentities_t[0].TeamName} ${t('VS')} ${
                            item.opta_statentities_t[1].TeamName
                          }`}
                        </div>
                      )}
                    <div
                      className={`players-vs-player ${isPlayer ? 'player-only' : 'team-only'}`}
                      aria-hidden="true"
                    >
                      <div className={`player__host ${isPlayer ? '' : 'team-images'}`}>
                        <div
                          className={`player-img ${isPlayer ? 'player-image' : 'team-image'}`}
                          aria-hidden={isPlayer ? 'true' : 'false'}
                        >
                          {item.opta_statentities_t[0] && (
                            <ErrorBoundary>
                              <ImagePanel item={item.opta_statentities_t[0]} statsType={isPlayer} />
                            </ErrorBoundary>
                          )}
                          {isPlayer &&
                            item.opta_statentities_t &&
                            item.opta_statentities_t.length > 1 && (
                              <React.Fragment>
                                <span className="notification">
                                  {item.opta_statentities_t[0].JerseyNumber}
                                </span>
                                <h3>
                                  {item.opta_statentities_t[0].KnownName
                                    ? item.opta_statentities_t[0].KnownName
                                    : item.opta_statentities_t[0].LastName}
                                </h3>
                              </React.Fragment>
                            )}
                        </div>
                      </div>
                      <div className="vs">
                        {isPlayer &&
                          item.opta_statentities_t &&
                          item.opta_statentities_t.length > 1 && (
                            <h3>
                              {item.opta_statentities_t[0].KnownName
                                ? item.opta_statentities_t[0].KnownName
                                : item.opta_statentities_t[0].LastName}
                            </h3>
                          )}

                        <div>
                          <span>{t('VS')}</span>
                        </div>

                        {isPlayer &&
                          item.opta_statentities_t &&
                          item.opta_statentities_t.length > 1 && (
                            <h3>
                              {item.opta_statentities_t[1].KnownName
                                ? item.opta_statentities_t[1].KnownName
                                : item.opta_statentities_t[1].LastName}
                            </h3>
                          )}
                      </div>
                      <div className="player__opponent">
                        <div className={`player-img ${isPlayer ? 'player-image' : 'team-image'}`}>
                          {item.opta_statentities_t[1] && (
                            <ErrorBoundary>
                              <ImagePanel item={item.opta_statentities_t[1]} statsType={isPlayer} />
                            </ErrorBoundary>
                          )}
                          {isPlayer &&
                            item.opta_statentities_t &&
                            item.opta_statentities_t.length > 1 && (
                              <React.Fragment>
                                <span className="notification">
                                  {item.opta_statentities_t[1].JerseyNumber}
                                </span>
                                <h3>
                                  {item.opta_statentities_t[1].KnownName
                                    ? item.opta_statentities_t[1].KnownName
                                    : item.opta_statentities_t[1].LastName}
                                </h3>
                              </React.Fragment>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="players__match-details">
                      <ul>{renderBar(item.filterEntitiObj)}</ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <StatsBar item={getStatsBarData(item)} type="statsCard"></StatsBar>
          </div>
        </div>
      </div>
    )
  );
};
export default withTranslation()(HeadToHead4);
