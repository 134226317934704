/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useSponsorValue } from '../../context/sponsorContext';
import { buildImgUrl, isSmallDevice } from '../../utils/utils';
import * as track from '../../utils/analytics';
import {
  FILTER,
  CONTENT_TYPE,
  MODULE,
  DARK_THEME,
  LIGHT_THEME,
  BLOG_EVENT,
} from '../../constants/globalConstants';

/**
 *
 * @param {dictionary}t
 * @param {content type of item} contentType
 * @param {child/parent} varient
 * @param {sponsor details} sponsorInfo
 * @param {column grid 'grid-6,grid-4 ...'}grid
 * @param {sponsor type: module,content,filter} type
 * @param {moduleName: 'videopage,newspage...'}moduleName
 * @param {theme: 'dark,light' for light sponsor or dark sponsor}theme
 */
const Sponsor = ({
  t,
  contentType,
  varient,
  sponsorInfo,
  grid,
  type,
  moduleName,
  eventName,
  theme,
  author,
  cardName,
  container,
  createdDateTime,
  itemId,
  tags,
  classes,
  style,
  isRelative,
  masterClass,
  isAdCard,
  hidePartnerText,
}) => {
  const [{ sponsorData }] = useSponsorValue();
  const [deviceCheck, setDeviceCheck] = useState(false);
  const [sponsor, setSponsor] = useState();
  useEffect(() => {
    setDeviceCheck(isSmallDevice());
    setSponsor(initSponsor());
  }, [sponsorData]);

  /**
   * @define {track the sponsor based on type }
   */
  const trackSponsor = (sponsor, e) => {
    e.stopPropagation();
    let initialData = {};
    if (type === 'module' || type === 'contenttype') {
      initialData = track.data('sponsor');
    } else if (type === 'filter') {
      initialData = track.data('sponsorFilter');
    }
    track.analytics(
      {
        ...initialData,
        author: author,
        button_name: sponsor.PartnerName,
        card_name: cardName,
        container_type: container,
        content_type: contentType,
        created_datetime: createdDateTime,
        destination_url: sponsor.CTAURL,
        item_id: itemId,
        tag: tags,
      },
      e
    );
    e.stopPropagation();
  };

  /**
   * @define {returned sponsor object for all contenttype }
   * @param {type of content ex: video,article} contenttype
   * @param {if sponsor avalible for content level then returen there own sponsor otherwise get from global sponsor} contentlevel
   */

  const getSponsor = (contenttype, contentlevel) => {
    if (contentlevel && contentlevel.length > 0) {
      return contentlevel[0];
    } else {
      return getGlobalSponsor('contenttype', contenttype, sponsorData);
    }
  };

  /**
   * @define {returned sponsor object for all module/contenttype/filter which is set on global level }
   * @param {values: 'module'/'contenttype'/'filter'} type
   * @param {contenttype name/ module name} subtype
   * @param {sponsor response} sponsorObj
   */
  const getGlobalSponsor = (type, subtype, sponsorObj) => {
    if (sponsorObj && sponsorObj[type]) {
      let allContentObj = sponsorObj[type];
      let sponsorObjElm;
      subtype = subtype ? subtype.toLowerCase() : '';
      for (let contenttype in allContentObj) {
        if (allContentObj.hasOwnProperty(contenttype) && subtype === contenttype.toLowerCase()) {
          sponsorObjElm = allContentObj[contenttype];
          break;
        }
      }
      if (sponsorObjElm) {
        return sponsorObjElm.length > 0 ? sponsorObjElm[0] : sponsorObjElm;
      }
    }
  };

  /**
   * @define {returned DOM as per there theme type}
   * @param {value: dark,light |for dark image/light image} themeType
   */
  const renderSponsorTheme = (themeType) => {
    return (
      <span>
        {themeType === DARK_THEME ? (
          <img
            src={isRelative ? sponsor.PartnerIconDark : buildImgUrl(sponsor.PartnerIconDark)}
            alt={sponsor.PartnerName}
            className="dark-sponsor-child"
          />
        ) : (
          <img
            src={isRelative ? sponsor.PartnerIconLight : buildImgUrl(sponsor.PartnerIconLight)}
            alt={sponsor.PartnerName}
            className="light-sponsor-child"
          />
        )}
        <span className="screenreader">{t('Externalwebsite')}</span>
      </span>
    );
  };

  /**
   * @define {returned themed DOM as per there grid value and device}
   */
  const renderContentSponsor = () => {
    if (deviceCheck) {
      if (window.innerWidth <= 567) {
        if (grid && (grid.indexOf('micro-card') > -1 || grid.indexOf('novarient') > -1)) {
          if (
            grid.indexOf('micro-card') > -1 &&
            contentType &&
            (contentType.toLowerCase() === 'video' ||
              contentType.toLowerCase() === 'livestream' ||
              contentType.toLowerCase() === 'video360degree')
          ) {
            return getImage('light');
          }
          return getImage('dark');
        } else {
          return getImage('light');
        }
      } else if (window.innerWidth <= 767) {
        if ((grid && grid.indexOf('micro-card') > -1) || (grid && grid.indexOf('novarient') > -1)) {
          return getImage('dark');
        } else if (varient === 'child') {
          return getImage('dark');
        } else {
          return getImage('light');
        }
      }
    } else if (!deviceCheck && grid) {
      if (
        grid.indexOf('grid-6') > -1 ||
        grid.indexOf('grid-8') > -1 ||
        grid.indexOf('grid-12') > -1 ||
        grid.indexOf('novarient') > -1 ||
        grid.indexOf('grid-6') < 0 ||
        grid.indexOf('grid-8') < 0 ||
        grid.indexOf('grid-12') < 0
      ) {
        if (varient && grid.indexOf('grid-3') < 0) {
          if (varient && varient.toLowerCase() === 'child') {
            if (contentType && contentType.toLowerCase() !== 'video') {
              return renderSponsorTheme(DARK_THEME);
            } else {
              return renderSponsorTheme(LIGHT_THEME);
            }
          }
          if (varient && varient.toLowerCase() !== 'child') {
            return renderSponsorTheme(LIGHT_THEME);
          }
        } else {
          return renderSponsorTheme(LIGHT_THEME);
        }
      }
    } else {
      return renderSponsorTheme(LIGHT_THEME);
    }
  };

  const getImage = (theme) => {
    if (theme === DARK_THEME) {
      return (
        <span>
          <img
            src={isRelative ? sponsor.PartnerIconDark : buildImgUrl(sponsor.PartnerIconDark)}
            alt={sponsor.PartnerName}
          />
          <span className="screenreader">{t('Externalwebsite')}</span>
        </span>
      );
    } else {
      return (
        <span>
          <img
            src={isRelative ? sponsor.PartnerIconLight : buildImgUrl(sponsor.PartnerIconLight)}
            alt={sponsor.PartnerName}
          />
          <span className="screenreader">{t('Externalwebsite')}</span>
        </span>
      );
    }
  };

  /**
   * @define {inital call for sponsor. calls inner function based on CONTENT_TYPE,MODULE,FILTER}
   */
  const initSponsor = () => {
    if (type && type === CONTENT_TYPE) {
      return getSponsor(contentType, sponsorInfo);
    } else if (type && type === BLOG_EVENT) {
      return getGlobalSponsor(type, eventName, sponsorData);
    } else if ((type && type === MODULE && moduleName) || (type && type === FILTER && moduleName)) {
      return getGlobalSponsor(type, moduleName, sponsorData);
    }
    return;
  };

  return (
    <React.Fragment>
      {(sponsor && type === CONTENT_TYPE) || (sponsor && type === BLOG_EVENT) ? (
        isAdCard ? (
          <div className="dfp-ads__sponsor">
            <a
              href={sponsor.CTAURL}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => trackSponsor(sponsor, e)}
            >
              {type === CONTENT_TYPE && renderContentSponsor()}
              {type === BLOG_EVENT && renderSponsorTheme(theme)}
            </a>
          </div>
        ) : (
          <div className={`${masterClass} ${classes}`} style={style}>
            <div className="mu-item__sponsor__wrapper">
              <a
                href={sponsor.CTAURL}
                target="_blank"
                rel="noreferrer"
                onClick={(e) => trackSponsor(sponsor, e)}
              >
                <span aria-hidden="true">{sponsor.PartnerText}</span>
                <span className="screenreader">{sponsor.PartnerText}</span>
                {type === CONTENT_TYPE && renderContentSponsor()}
                {type === BLOG_EVENT && renderSponsorTheme(theme)}
              </a>
            </div>
          </div>
        )
      ) : (
        sponsor && (
          <a
            href={sponsor.CTAURL}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => trackSponsor(sponsor, e)}
          >
            {!hidePartnerText && (
              <React.Fragment>
                <span aria-hidden="true">{sponsor.PartnerText}</span>
                <span className="screenreader">{sponsor.PartnerText}</span>
              </React.Fragment>
            )}
            {theme === DARK_THEME
              ? renderSponsorTheme(DARK_THEME)
              : renderSponsorTheme(LIGHT_THEME)}
          </a>
        )
      )}
    </React.Fragment>
  );
};

Sponsor.defaultProps = {
  classes: '',
  style: {},
  isRelative: false,
  masterClass: 'mu-item__sponsor',
  isAdCard: false,
  hidePartnerText: false,
};

export default withTranslation()(Sponsor);
