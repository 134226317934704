/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import Transform from '../../data-transform';
import { checkGridClass } from '../../utils/utils';
import { DFP } from '../Common/Dfp';
import { THRESHOLD } from '../../constants/globalConstants';
import { NEWS_LISTING, VIDEO_LISTING } from '../../constants/containerConstants';

const ListingPage = ({ contentPage, startListIndex, itemsPerPage, enableDFP, listing }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  let timeoutId;

  useEffect(() => {
    resizeListener();
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const resizeListener = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setWindowWidth(window.innerWidth);
    }, THRESHOLD);
  };

  const getListingClass = (index) => {
    if (index === startListIndex + itemsPerPage - 1) {
      return 'lastnewslistingcard';
    } else {
      return '';
    }
  };

  const containerType = (listing) => {
    if (listing === 'news') {
      return NEWS_LISTING;
    } else if (listing === 'video') {
      return VIDEO_LISTING;
    } else {
      return '';
    }
  };

  return (
    <React.Fragment>
      <div className="news-list__block lisitingComponent">
        {contentPage &&
          contentPage.length > 0 &&
          contentPage.map((item, index) => {
            return (
              <div className={`news-list__item ${getListingClass(index)}`} key={index}>
                <Transform
                  container={containerType(listing)}
                  data={item}
                  source="aws"
                  gridClass={checkGridClass(index, windowWidth)}
                />
                {/* DFP */}
                {enableDFP && (index + 1) / 10 === 1 && (
                  <React.Fragment>
                    <div className="grid-xl ad-block dashboard" aria-hidden="true" tabIndex="-1">
                      <DFP
                        slot="/11820346/Hercules_ROS_970x250//750x200//300x250"
                        browserSize={[1024, 768, 320]}
                        size={[
                          [970, 250],
                          [750, 200],
                          [300, 250],
                        ]}
                      />
                    </div>
                    <div style={{ width: '100%', float: 'left', clear: 'both' }}></div>
                  </React.Fragment>
                )}
                {enableDFP &&
                  (index + 1) / 10 > 1 &&
                  (index + 1) % 10 === 0 &&
                  (index + 1) % 3 !== 0 && (
                    <div>
                      <div className="grid-xl ad-block billboard" aria-hidden="true" tabIndex="-1">
                        <DFP
                          slot="/11820346/Hercules_ROS_970x90//728x90//320x50"
                          browserSize={[1024, 768, 320]}
                          size={[
                            [970, 90],
                            [728, 90],
                            [320, 50],
                          ]}
                        />
                      </div>
                    </div>
                  )}
              </div>
            );
          })}
      </div>
    </React.Fragment>
  );
};

export default ListingPage;
