/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_FAVPLAYER,
  SET_FAVPLAYER,
  ERROR_FAVPLAYER,
  FETCH_ALLPLAYER,
  SET_ALLPLAYER,
  SET_WOMENSPLAYER,
  ERROR_ALLPLAYER,
  FETCH_PREDICTION,
  SET_PREDICTION,
  ERROR_PREDICTION,
  FETCH_UNITEDCONFIGDATA,
  SET_UNITEDCONFIGDATA,
  ERROR_UNITEDCONFIGDATA,
} from '../constants/actions/actionConstant';
export const myUnitedState = {
  status: null,
  allPlayerData: null,
  responseData: null,
  womensPlayer: null,
  favPlayerStatus: null,
  predictionStatus: null,
  predictionData: null,
  matchdayStatus: null,
  matchdayData: null,
  matchId: null,
  unitedNowConfigData: null,
  unitedNowConfigStatus: null,
};

/**
 * @param {initial state} state
 * @param {Action type} type
 * @param {response} response data
 */
export const myUnitedReducer = (state = myUnitedState, { type, response } = {}) => {
  switch (type) {
    case FETCH_ALLPLAYER:
      return { ...state, status: 'fetching' };
    case SET_ALLPLAYER: {
      let playerData;
      if (response && response.data) {
        playerData = formatePlayerData(response.data);
      }
      return {
        ...state,
        status: 'fetched',
        responseData: response.data,
        allPlayerData: playerData,
      };
    }
    case SET_WOMENSPLAYER: {
      return {
        ...state,
        status: 'fetched',
        womensPlayer: response.data,
      };
    }
    case ERROR_ALLPLAYER:
      return { ...state, status: 'error', responseData: null, allPlayerData: null };
    case FETCH_FAVPLAYER:
      return {
        ...state,
        favPlayerStatus: 'fetching',
        favPlayerData: null,
      };
    case SET_FAVPLAYER: {
      return {
        ...state,
        favPlayerStatus: 'fetched',
        favPlayerData: response.data,
      };
    }
    case ERROR_FAVPLAYER:
      return { ...state, favPlayerStatus: 'error', favPlayerData: null };

    case FETCH_PREDICTION:
      return {
        ...state,
        predictionStatus: 'fetching',
        predictionData: null,
      };
    case SET_PREDICTION: {
      let predData;
      if (response && response.data) {
        predData = formateMatchdayData(response.data);
      }
      return {
        ...state,
        predictionStatus: 'fetched',
        predictionData: predData,
      };
    }
    case ERROR_PREDICTION:
      return { ...state, predictionStatus: 'error', predictionData: null };
    case FETCH_UNITEDCONFIGDATA:
      return { ...state, unitedNowConfigStatus: 'fetching' };
    case SET_UNITEDCONFIGDATA: {
      return { ...state, unitedNowConfigStatus: 'fetched', unitedNowConfigData: response.data };
    }
    case ERROR_UNITEDCONFIGDATA:
      return { ...state, unitedNowConfigStatus: 'error', unitedNowConfigData: null };
    default:
      return state;
  }
};

const formatePlayerData = (data) => {
  const playerList = [
    'position_t:goalkeeper',
    'position_t:defender',
    'position_t:midfielder',
    'position_t:forward',
  ];

  if (data) {
    const key = Object.keys(data.PlayerGridResponse && data.PlayerGridResponse.grouped);
    const plyrDataArr = [];
    if (key && key.length > 0) {
      key.forEach((objkey, i) => {
        let groups = data.PlayerGridResponse.grouped[playerList[i]].doclist.docs;
        if (groups && groups.length > 0) {
          groups.forEach((group) => {
            group.isenablemyfavouriteplayer_b && plyrDataArr.push(group);
          });
        }
      });
      return plyrDataArr;
    }
  }
};

const formateMatchdayData = (data) => {
  let defaultData = {};
  if (data) {
    const { PredictionInfo, UserInfo, matchID, matchdata } = data;
    defaultData.PredictionInfo = PredictionInfo;
    defaultData.UserInfo = UserInfo;
    defaultData.matchID = matchID;

    if (
      matchdata &&
      matchdata.SpotlightResponse &&
      matchdata.SpotlightResponse.response &&
      matchdata.SpotlightResponse.response.docs &&
      matchdata.SpotlightResponse.response.docs.length
    ) {
      defaultData.SpotlightResponse = matchdata.SpotlightResponse.response.docs;
    }
    if (
      matchdata &&
      matchdata.TimerResponse &&
      matchdata.TimerResponse.response &&
      matchdata.TimerResponse.response.docs &&
      matchdata.TimerResponse.response.docs.length
    ) {
      defaultData.TimerResponse = matchdata.TimerResponse.response.docs;
    }
  }
  return defaultData;
};
