/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

export const roundMap = {
  EN_FA: {
    'Round 1': 'First Round',
    'Quarter-Finals': 'Quarter-final',
    'Semi-Finals': 'Semi-final',
    'Round 3': 'Third Round',
    'Round 2': 'Second Round',
    Final: 'Final',
    'Round 5': 'Fifth Round',
    'Round 4': 'Fourth Round',
  },
  EU_EL: {
    'Round of 32': 'Round of 32',
    'Round 1': 'Group Stage',
    'Quarter-Finals': 'Quarter-final',
    'Semi-Finals': 'Semi-final',
    'Qualifier Round': 'Qualifier Round',
    'Round of 16': 'Round of 16',
    Final: 'Final',
  },
  EN_PL: {
    'Round of 32': 'Round of 32',
    'Play-Offs': 'Play-Offs',
    'Round 6': 'Round 6',
    'Qualifier Round': 'Qualifier Round',
    'Round of 16': 'Round of 16',
    'Round 3': 'Round 3',
    'Round 2': 'Round 2',
    'Round 5': 'Round 5',
    '3rd and 4th Place': '3rd and 4th Place',
    'Round 4': 'Round 4',
    'Round 1': 'Round 1',
    'Quarter-Finals': 'Quarter-Finals',
    'Semi-Finals': 'Semi-Finals',
    Final: 'Final',
  },
  EU_CL: {
    'Round of 32': 'Round of 32',
    'Round 1': 'Group Stage',
    'Quarter-Finals': 'Quarter-final',
    'Semi-Finals': 'Semi-final',
    'Qualifier Round': 'Qualifier Round',
    'Round of 16': 'Round of 16',
    Final: 'Final',
  },
  EN_LC: {
    'Round 1': 'Round One',
    'Quarter-Finals': 'Round Five',
    'Semi-Finals': 'Semi-final',
    'Round 3': 'Round Three',
    'Round 2': 'Round Two',
    Final: 'Final',
    'Round 4': 'Round Four',
  },
};
export const i18n = {
  common: { publish: 'published time', leaguetable: 'league tables' },
  spotlight: {
    kickoff: 'Kick Off',
    '1st Leg': '1st Leg',
    '2nd Leg': '2nd Leg',
    'agg text': 'Agg:',
    'pen text': 'pens',
    win: 'wins',
    'aggregate text': 'on aggregate',
    'awaygoal text': 'on away goals',
    'penaltyshoot text': 'Penalty Shootout',
    penalty: 'pen',
    accessibility: {
      spotlightCard: 'Spotlight Card',
      goaldoneby: 'Goal done by',
      cardrecivedby: 'Card Received by',
      activitydoneby: 'Activity done by',
      timeago: 'Minutes Ago',
      vs: 'Versus',
      timeRemaining: 'Time Remaining',
      hrs: 'Hours',
      mins: 'Minutes',
      timeElapsed: 'Time Elapsed',
      outOf: 'Out Of',
      and: 'and',
      aggscoreof: 'Aggregated score of',
      penaltyshots: 'Penalty shots',
    },
  },
  myUnited: {
    settings: {
      pageTitle: 'SETTINGS',
      notifications: 'NOTIFICATIONS',
      topStories: 'Top Stories',
      breakingNews: 'Breaking News',
      generalSettings: 'general settings',
      videoAndSound: 'VIDEO & SOUND EFFECTS',
      autoPlayVideo: 'Autoplay Video',
      cheerSoundEffects: 'Cheer Sound Effects',
      myProfile: 'My Profile',
      policy: 'policy statements',
      terms: 'terms of use',
      faqs: 'faqs',
      contactus: 'contact us',
      privacy: 'Privacy and cookies',
      accessibility: 'Accessibility',
      help: 'help',
      editProfile: 'Edit Profile',
      changePassword: 'Change Password',
      changeEmail: 'Change Email Address',
      logout: 'Logout',
      socialConnect: 'LINKED ACCOUNTS',
      facebook: 'Facebook',
      googlePlus: 'Google+',
      wechat: 'WeChat',
      weibo: 'Sina Weibo',
      blankMesg: 'Please fill the email id',
      emailMatch: 'Email addresses do not match',
      sameEmailMesg: 'Old email and new email id are not same',
      Pleasecompletetheinformationrequired: 'Please complete the information required',
    },
  },
  stats: {
    vs: 'vs',
    percentage: '%',
    accessibility: {
      cardtitle: 'Match stats cards',
      by: 'By',
      Publishedtime: 'Published time',
      jerseyno: 'jersey no',
      Minutesofmatch: 'Minutes of match',
      Versus: 'Versus',
      from: 'from',
      againstopponent: 'against opponent',
    },
  },
  news: {
    newsListing: { filterNav: 'news filter', region: 'Region' },
    videoListing: { filterNav: 'video filter', region: 'Region' },
  },
};
export const searchI18n = {
  suggestResultCount: 'results are available, use up and down arrow keys to navigate',
};
