/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { Fragment } from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { Helmet } from 'react-helmet';
import { getCssUrl } from '../../../utils/utils';
import MU from '../../../constants/muConstants';
import layoutConstants from '../../../constants/layoutConstants';
const ModelContainer = (props) => {
  return (
    <Fragment>
      {(typeof window === 'undefined' || process.env.NODE_ENV === 'development') && (
        <Helmet>
          <link
            href={getCssUrl(layoutConstants.NON_DESTINATION_LAYOUT, MU.language)}
            rel="stylesheet"
          />
        </Helmet>
      )}
      <Placeholder
        name="mufc-ph-modelcontainer"
        rendering={props.rendering}
        missingComponentComponent={() => {
          return null;
        }}
      />
    </Fragment>
  );
};

export default ModelContainer;
