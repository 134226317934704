/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import LiveTableBlogCard from './LeagueTable/LiveTableBlogCard';
import BlogCard from './MatchCenter/BlogCard';
import LineupCard from './MatchCenter/LineupCard';
import Stats from './Stats';

const components = new Map();
components.set('blogentry', BlogCard);
components.set('lineup', LineupCard);
components.set('livetable', LiveTableBlogCard);
components.set('matchdaystats', Stats);
components.set('competitionstats', Stats);
components.set('seasonstats', Stats);
export function CustomComponentMapping(componentName) {
  return components.get(componentName);
}
