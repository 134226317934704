/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { withTranslation } from 'react-i18next';
import Transform from '../../data-transform';
import MU from '../../constants/muConstants';
import { isTouchDevice } from '../../utils/utils';
import {
  PrevArrow,
  NextArrow,
  afterChangeAccessbility,
  beforeChangeAccessbility,
  onInitAccessibility,
} from './Setting';
const StoryForYou = ({ t, sliderData, containerClassName }) => {
  const [touchDevice, setTouchDevice] = useState(false);
  const [slicker, setSlicker] = useState({});
  useEffect(() => {
    setTouchDevice(isTouchDevice);
  }, []);

  const renderSliderCards = () => {
    const settings = {
      className: containerClassName,
      accessibility: false,
      slidesToShow: slideShowCount(),
      infinite: false,
      draggable: false,
      dots: false,
      initialSlide: MU && MU.language === 'ar' ? slideShowCount() - 1 : 0,
      slidesToScroll: 1,
      rtl: MU && MU.language === 'ar' ? true : false,
      prevArrow: <PrevArrow btnName={t('PreviousSlide')} />,
      nextArrow: <NextArrow btnName={t('NextSlide')} />,
      onInit: () => {
        setTimeout(() => {
          let slider = document.querySelector('.card-wrapper.playerSlick.slick-initialized');
          let list = slider && slider.querySelector('.slick-list');
          let slideTrack = list && list.querySelector('.slick-track');
          let slides = slideTrack && slideTrack.querySelectorAll('.slick-slide');
          let nextArrow = slider && slider.querySelector('.slider-next.slick-arrow');
          let prevArrow = slider && slider.querySelector('.slider-prev.slick-arrow');
          onInitAccessibility(slideTrack, slides, nextArrow, prevArrow);
          setSlicker({
            slider: slider,
            list: list,
            track: slideTrack,
            slides: slides,
            nextArrow: nextArrow,
            prevArrow: prevArrow,
          });
        }, 1000);
      },
      beforeChange: () => {
        beforeChangeAccessbility(slicker);
      },
      afterChange: () => {
        afterChangeAccessbility(slicker);
      },
    };
    return (
      <Slider {...settings}>
        {sliderData &&
          sliderData.length > 0 &&
          sliderData.map((item, i) => (
            <Transform key={i} data={item} source="aws" gridClass="grid-3 child" />
          ))}
      </Slider>
    );
  };

  const renderDefaultCards = () => {
    return (
      <div className={containerClassName}>
        {sliderData &&
          sliderData.length > 0 &&
          sliderData.map((item, i) => (
            <Transform key={i} data={item} source="aws" gridClass="grid-3 child" />
          ))}
      </div>
    );
  };

  const slideShowCount = () => {
    let slideCount = 2;
    if (typeof window !== 'undefined' && window.innerWidth >= 568 && window.innerWidth <= 1023) {
      slideCount = 4;
    } else if (typeof window !== 'undefined' && window.innerWidth >= 1024) {
      slideCount = 5;
    }
    return slideCount;
  };

  return (
    <React.Fragment>{touchDevice ? renderDefaultCards() : renderSliderCards()}</React.Fragment>
  );
};

export default withTranslation()(React.memo(StoryForYou));
