/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import LazyLoad from 'react-lazyload';
import ImgCrop from '../../Common/ImgCrop';

const HeroImage = (props) => {
  const { HeroTitle, Image, Description, Summary } = props.fields;
  const isTitle = HeroTitle && HeroTitle.value ? true : false;
  return (
    <div className="card-xl tmpls-hero">
      {Image && Image.value && Image.value.Crops && (
        <div className="img-holder">
          <LazyLoad once={true} offset={100}>
            <ImgCrop
              imgCrop={Image.value.Crops}
              alt={Image.value.AltText}
              title={Image.value.AltText}
              isWebpAvailable={Image.value.isWebpAvailable}
              singleSrc={false}
              adaptiveClass="adaptive"
              titleAvailable={isTitle}
            ></ImgCrop>
          </LazyLoad>
        </div>
      )}

      {HeroTitle && HeroTitle.value && (
        <div className="tpl-basic tmpls-hero-heading">
          <div className="basic-tpl-block">
            <RichText tag="h2" className="htmpls-hero__title" field={HeroTitle} />
          </div>
        </div>
      )}

      {((Summary && Summary.value) || (Description && Description.value)) && (
        <div className="tpl-basic">
          <div className="basic-tpl-block">
            <div className="tmpls-hero__intro">
              {Summary && Summary.value && <RichText tag="p" className="intro" field={Summary} />}
              {Description && Description.value && <RichText tag="p" field={Description} />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeroImage;
