/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { getRandomId } from '../../../utils/utils';
import {
  setInputTextData,
  InputText,
  TextareaField,
  InputPassword,
  InputDatePicker,
  renderDate,
  renderYear,
  InputEmail,
  InputRadio,
  InputCheckbox,
  getValue,
  setName,
} from '../../../utils/FormUtils';
import { monthArr } from '../../../constants/formConstants';
const fileTypeSupport = (n) => {
  const supportArr = [];
  n.forEach((item, index) => {
    supportArr.push(item.fields.name.value);
  });
  return supportArr;
};

const fileApplication = (n) => {
  const fileArr = [];
  n.forEach((item, index) => {
    fileArr.push(item.fields.value.value);
  });
  return fileArr;
};
export const GenericConditional = ({
  data,
  index,
  fields,
  t,
  onChangeFunction,
  formError,
  initial,
  formDetails,
  isUserLogin,
  fileName,
}) => {
  const newData = data && data.fields;
  const item = setInputTextData(newData);
  if (newData) {
    if (
      newData.AnswerIsTextCheckBox &&
      newData.AnswerIsTextCheckBox.value &&
      newData.Name &&
      newData.Name.value
    ) {
      if (newData.AlphaNumericCheckBox && newData.AlphaNumericCheckBox.value) {
        let validation = {};
        if (item.True) {
          validation.DataRequiredMessage = item.DataRequiredMessage;
        }
        validation = {
          ...validation,
          AlphaNumericMessage: item.AlphaNumericMessage,
          MinNumberMessage: item.MinNumberMessage,
          MaxNumberMessage: item.MaxNumberMessage,
          MinNumber: item.MinNumber,
          MaxNumber: item.MaxNumber,
        };
        return (
          <div className="formBox">
            <div className="formContainer textBox">
              {item?.Title && <label className="headTxt">{item.Title}</label>}
              {item && (
                <InputText
                  item={item}
                  className="generic_firstname"
                  onChangeFunction={onChangeFunction}
                  requireLabel={''}
                  formError={formError}
                  initial={initial}
                  validation={validation}
                />
              )}
            </div>
          </div>
        );
      } else if (newData.AlphabetCheckBox && newData.AlphabetCheckBox.value) {
        const className = 'generic_firstname';
        let validation = {};
        if (item.True) {
          validation.DataRequiredMessage = item.DataRequiredMessage;
        }
        validation = {
          ...validation,
          MinNumberMessage: item.MinNumberMessage,
          MaxNumberMessage: item.MaxNumberMessage,
          MinNumber: item.MinNumber,
          MaxNumber: item.MaxNumber,
        };
        return (
          <div className="formBox">
            <div className="formContainer textBox">
              {item?.Title && <label className="headTxt">{item.Title}</label>}
              {item && (
                <InputText
                  item={item}
                  className={className}
                  formError={formError}
                  initial={initial}
                  onChangeFunction={onChangeFunction}
                  requireLabel={''}
                  validation={validation}
                />
              )}
            </div>
          </div>
        );
      } else if (newData.RegexCheckBox && newData.RegexCheckBox.value) {
        const className = 'generic_firstname';
        let validation = {};
        if (item.True) {
          validation.DataRequiredMessage = item.DataRequiredMessage;
        }
        validation = {
          ...validation,
          MinNumberMessage: item.MinNumberMessage,
          MaxNumberMessage: item.MaxNumberMessage,
          MinNumber: item.MinNumber,
          MaxNumber: item.MaxNumber,
        };
        return (
          <div className="formBox">
            <div className="formContainer textBox">
              {item?.Title && <label className="headTxt">{item.Title}</label>}
              {item && (
                <InputText
                  item={item}
                  className={className}
                  formError={formError}
                  initial={initial}
                  onChangeFunction={onChangeFunction}
                  requireLabel={''}
                  validation={validation}
                />
              )}
            </div>
          </div>
        );
      } else {
        const className = 'generic_firstname';
        let validation = {};
        if (item.True) {
          validation.DataRequiredMessage = item.DataRequiredMessage;
        }
        validation = {
          ...validation,
          MinNumberMessage: item.MinNumberMessage,
          MaxNumberMessage: item.MaxNumberMessage,
          MinNumber: item.MinNumber,
          MaxNumber: item.MaxNumber,
        };
        return (
          <div className="formBox">
            <div className="formContainer textBox">
              {item?.Title && <label className="headTxt">{item.Title}</label>}
              {item && (
                <InputText
                  item={item}
                  className={className}
                  formError={formError}
                  initial={initial}
                  onChangeFunction={onChangeFunction}
                  requireLabel={''}
                  validation={validation}
                />
              )}
            </div>
          </div>
        );
      }
    } else if (newData.AnswerIsTextCheckBox && newData.AnswerIsTextCheckBox.value) {
      if (newData.AlphaNumericCheckBox && newData.AlphaNumericCheckBox.value) {
        const className = '';
        let validation = {};
        if (item.True) {
          validation.DataRequiredMessage = item.DataRequiredMessage;
        }
        validation = {
          ...validation,
          AlphaNumericMessage: item.AlphaNumericMessage,
          MinNumberMessage: item.MinNumberMessage,
          MaxNumberMessage: item.MaxNumberMessage,
          MinNumber: item.MinNumber,
          MaxNumber: item.MaxNumber,
        };
        return (
          <div className="formBox">
            <div className="formContainer textBox">
              {item?.Title && <label className="headTxt">{item.Title}</label>}
              {item && (
                <InputText
                  item={item}
                  className={className}
                  formError={formError}
                  initial={initial}
                  onChangeFunction={onChangeFunction}
                  requireLabel={''}
                  validation={validation}
                />
              )}
            </div>
          </div>
        );
      } else if (newData.AlphabetCheckBox && newData.AlphabetCheckBox.value) {
        const className = 'generic_firstname';
        let validation = {};
        if (item.True) {
          validation.DataRequiredMessage = item.DataRequiredMessage;
        }
        validation = {
          ...validation,
          MinNumberMessage: item.MinNumberMessage,
          MaxNumberMessage: item.MaxNumberMessage,
          MinNumber: item.MinNumber,
          MaxNumber: item.MaxNumber,
        };
        return (
          <div className="formBox">
            <div className="formContainer textBox">
              {item?.Title && <label className="headTxt">{item.Title}</label>}
              {item && (
                <InputText
                  item={item}
                  className={className}
                  formError={formError}
                  initial={initial}
                  onChangeFunction={onChangeFunction}
                  requireLabel={''}
                  validation={validation}
                />
              )}
            </div>
          </div>
        );
      } else if (newData.RegexCheckBox && newData.RegexCheckBox.value) {
        const className = 'generic_firstname';
        let validation = {};
        if (item.True) {
          validation.DataRequiredMessage = item.DataRequiredMessage;
        }
        validation = {
          ...validation,
          MinNumberMessage: item.MinNumberMessage,
          MaxNumberMessage: item.MaxNumberMessage,
          MinNumber: item.MinNumber,
          MaxNumber: item.MaxNumber,
        };
        return (
          <div className="formBox">
            <div className="formContainer textBox">
              {item?.Title && <label className="headTxt">{item.Title}</label>}
              {item && (
                <InputText
                  item={item}
                  className={className}
                  formError={formError}
                  initial={initial}
                  onChangeFunction={onChangeFunction}
                  requireLabel={''}
                  validation={validation}
                />
              )}
            </div>
          </div>
        );
      } else if (newData.IsTextArea && newData.IsTextArea.value) {
        let validation = {};
        if (item.True) {
          validation.DataRequiredMessage = item.DataRequiredMessage;
        }
        validation = {
          ...validation,
          MinNumberMessage: item.MinNumberMessage,
          MaxNumberMessage: item.MaxNumberMessage,
          MinNumber: item.MinNumber,
          MaxNumber: item.MaxNumber,
        };
        return (
          <div className="formBox">
            <div className="formContainer textBox">
              {item?.Title && <label className="headTxt">{item.Title}</label>}
              {item && (
                <TextareaField
                  item={item}
                  formError={formError}
                  initial={initial}
                  onChangeFunction={onChangeFunction}
                  requireLabel={''}
                  validation={validation}
                />
              )}
            </div>
          </div>
        );
      } else {
        const className = '';
        let validation = {};
        if (item.True) {
          validation.DataRequiredMessage = item.DataRequiredMessage;
        }
        validation = {
          ...validation,
          MinNumberMessage: item.MinNumberMessage,
          MaxNumberMessage: item.MaxNumberMessage,
          MinNumber: item.MinNumber,
          MaxNumber: item.MaxNumber,
        };
        return (
          <div className="formBox">
            <div className="formContainer textBox">
              {item?.Title && <label className="headTxt">{item.Title}</label>}
              {item && (
                <InputText
                  item={item}
                  className={className}
                  onChangeFunction={onChangeFunction}
                  formError={formError}
                  initial={initial}
                  requireLabel={''}
                  validation={validation}
                />
              )}
            </div>
          </div>
        );
      }
    } else if (newData.AnswerIsNumberCheckBox && newData.AnswerIsNumberCheckBox.value) {
      let validation = {};
      if (item.True) {
        validation.DataRequiredMessage = item.DataRequiredMessage;
      }
      validation = {
        ...validation,
        NumericValidationMessage: item.NumericValidationMessage,
        MinNumberMessage: item.MinNumberMessage,
        MaxNumberMessage: item.MaxNumberMessage,
        MinNumber: item.MinNumber,
        MaxNumber: item.MaxNumber,
      };
      return (
        <div className="formBox">
          <div className="formContainer textBox">
            {item?.Title && <label className="headTxt">{item.Title}</label>}
            {item && (
              <InputText
                item={item}
                validation={validation}
                initial={initial}
                onChangeFunction={onChangeFunction}
                formError={formError}
                requireLabel={''}
              />
            )}
          </div>
        </div>
      );
    } else if (newData.AnswerIsSelectionCheckBox && newData.AnswerIsSelectionCheckBox.value) {
      let validation = {};
      if (item.True) {
        validation.DataRequiredMessage = item.DataRequiredMessage;
      }
      validation = {
        ...validation,
        MinNumberMessage: item.MinNumberMessage,
        MaxNumberMessage: item.MaxNumberMessage,
        MinNumber: item.MinNumber,
        MaxNumber: item.MaxNumber,
      };
      return (
        <div className="formBox">
          <div className="formContainer textBox">
            {item?.Title && <label className="headTxt">{item.Title}</label>}
            <TextareaField
              item={item}
              validation={validation}
              onChangeFunction={onChangeFunction}
              initial={initial}
              formError={formError}
              requireLabel={''}
            />
          </div>
        </div>
      );
    } else if (newData.AnswerIsEmailCheckBox && newData.AnswerIsEmailCheckBox.value) {
      let className;
      let validation = {};
      if (item.True) {
        validation.DataRequiredMessage = item.DataRequiredMessage;
      }
      validation = {
        ...validation,
        Email: item.DataRequiredMessage,
        MinNumberMessage: item.MinNumberMessage,
        MaxNumberMessage: item.MaxNumberMessage,
        MinNumber: item.MinNumber,
        MaxNumber: item.MaxNumber,
      };
      if (newData.PrimaryEmailCheck && newData.PrimaryEmailCheck.value) {
        className = 'GenericFormemail';
      } else {
        className = 'GenericFormemailSecondary';
      }
      return (
        <div className="formBox">
          <div className="formContainer textBox">
            {item?.Title && <label className="headTxt">{item.Title}</label>}
            {item && (
              <InputEmail
                item={item}
                className={className}
                formError={formError}
                initial={initial}
                onChangeFunction={onChangeFunction}
                requireLabel={''}
                isUserLogin={isUserLogin}
                validation={validation}
              />
            )}
          </div>
        </div>
      );
    } else if (newData.AnswerIsPasswordCheckBox && newData.AnswerIsPasswordCheckBox.value) {
      const validation = {
        ...validation,
        MinNumberMessage: item.MinNumberMessage,
        MaxNumberMessage: item.MaxNumberMessage,
        MinNumber: item.MinNumber,
        MaxNumber: item.MaxNumber,
      };
      return (
        <div className="formBox">
          <div className="formContainer textBox">
            {item?.Title && <label className="headTxt">{item.Title}</label>}
            {item && (
              <InputPassword
                validation={validation}
                initial={initial}
                item={item}
                onChangeFunction={onChangeFunction}
                formError={formError}
                requireLabel={''}
              />
            )}
          </div>
        </div>
      );
    } else if (newData.AnswerIsDateCheckBox && newData.AnswerIsDateCheckBox.value) {
      const validation = item.True ? { DataRequiredMessage: item.DataRequiredMessage } : null;
      return (
        <React.Fragment>
          <div className="formBox">
            <div className="formContainer datePickerBox">
              {item?.Title && <label className="headTxt">{item.Title}</label>}
              {item && (
                <InputDatePicker
                  item={item}
                  initial={initial}
                  validation={validation}
                  onChangeFunction={onChangeFunction}
                  formError={formError}
                  requireLabel={''}
                  formDetails={formDetails}
                />
              )}
            </div>
          </div>
        </React.Fragment>
      );
    } else if (newData.AnswerIsDateOfBirthCheck && newData.AnswerIsDateOfBirthCheck.value) {
      const DOBDateName = setName(item.Name) + '_Date';
      const DOBMonthName = setName(item.Name) + '_Month';
      const DOBYearName = setName(item.Name) + '_Year';
      const validationMonth = item.True ? { DataRequiredMessage: item.MonthErrorMessage } : {};
      const validationDate = item.True ? { DataRequiredMessage: item.DateErrorMessage } : {};
      const validationYear = item.True ? { DataRequiredMessage: item.YearErrorMessage } : {};
      let showDateError = !initial && getValue(formError, DOBDateName);
      let showMonthError = !initial && getValue(formError, DOBMonthName);
      let showYearError = !initial && getValue(formError, DOBYearName);
      return (
        <div className="formBox ">
          <div className="formContainer selectBox">
            {item?.Title && <label className="headTxt">{item.Title}</label>}
            <div className="selectDate select_date">
              <select
                className="formElem inputSelectBox"
                aria-invalid={showDateError ? true : false}
                aria-label={t('Date')}
                data-date=""
                aria-required={item.True}
                name={DOBDateName}
                onChange={(e) => onChangeFunction(e, item, validationDate)}
                data-validation-object={JSON.stringify(validationDate)}
              >
                {renderDate(t, true)}
              </select>
              {showDateError && (
                <span role="alert" className="formError">
                  {showDateError}
                </span>
              )}
            </div>

            <div className="selectDate select_month">
              <select
                className="formElem inputSelectBox"
                aria-invalid={showMonthError ? true : false}
                aria-label={t('Month')}
                data-month=""
                aria-required={item.True}
                name={DOBMonthName}
                onChange={(e) => onChangeFunction(e, item, validationMonth)}
                data-validation-object={JSON.stringify(validationMonth)}
              >
                <option value="" selected disabled="disabled" aria-label={t('Month')} key="0">
                  {t('Month')}
                </option>
                {monthArr &&
                  monthArr.length > 0 &&
                  monthArr.map((item, index) => (
                    <option value={t(item.value)} key={index}>
                      {t(item.name)}
                    </option>
                  ))}
              </select>
              {showMonthError && (
                <span role="alert" className="formError">
                  {showMonthError}
                </span>
              )}
            </div>
            <div className="selectDate select_year">
              <select
                className="formElem inputSelectBox"
                aria-invalid={showYearError ? true : false}
                aria-label={t('Year')}
                data-year=""
                aria-required={item.True}
                name={DOBYearName}
                onChange={(e) => onChangeFunction(e, item, validationYear)}
                data-validation-object={JSON.stringify(validationYear)}
              >
                {renderYear(t, true)}
              </select>
              {showYearError && (
                <span role="alert" className="formError">
                  {showYearError}
                </span>
              )}
            </div>
          </div>
        </div>
      );
    } else if (newData.UploadButtonCheck && newData.UploadButtonCheck.value) {
      if (
        fields.AttachmentCheckBox &&
        fields.AttachmentCheckBox.value &&
        fields.AttachmentFileTypes &&
        fields.AttachmentFileTypes.length > 0
      ) {
        const validation = item.True ? { DataRequiredMessage: item.DataRequiredMessage } : {};
        const textClassName = 'uploadFile' + index;
        const fileSupportAnchorMessage = fileTypeSupport(fields && fields.AttachmentFileTypes);
        const fileApplicationName = fileApplication(fields && fields.AttachmentFileTypes);
        const showError = !initial && getValue(formError, textClassName);
        const uploadFileName = fileName
          ? fileName
          : newData?.Title?.value + ': ' + fileSupportAnchorMessage.join('');
        return (
          <div className="formBox">
            <div className="formContainer uploadBox">
              <span className="fileName">{uploadFileName}</span>
              <div className="browseBtn">
                <input
                  type="file"
                  aria-label={item.Title}
                  aria-invalid={showError ? true : false}
                  aria-required={item.True}
                  name={textClassName}
                  id="fileUpload"
                  accept={[...fileApplicationName]}
                  onChange={(e) => onChangeFunction(e, item, validation)}
                  data-validation-object={JSON.stringify(validation)}
                />
                <input type="button" className="uploadButton" value="Browse" />
              </div>
              {/* <p className="generic_filesupported">{[...fileSupportAnchorMessage]}</p> */}

              {showError && (
                <span role="alert" className="formError">
                  {showError}
                </span>
              )}
            </div>
          </div>
        );
      } else {
        return null;
      }
    } else if (
      newData.AnswerIsCheckBox_TypeFirstCheck &&
      newData.AnswerIsCheckBox_TypeFirstCheck.value
    ) {
      const validation = item.True && { DataRequiredMessage: item.DataRequiredMessage };
      return (
        <React.Fragment>
          {data && data.fields.Title && data.fields.Title.value ? (
            <>
              <legend className="generic_heading">{data.fields.Title.value}</legend>
              {checkBoxFirstList(data, item, onChangeFunction, validation, formError, initial)}
            </>
          ) : (
            checkBoxFirstList(data, item, onChangeFunction, validation, formError, initial)
          )}
        </React.Fragment>
      );
    } else if (
      newData.AnswerIsCheckBox_TypeSecondCheck &&
      newData.AnswerIsCheckBox_TypeSecondCheck.value
    ) {
      const validation = item.True && { DataRequiredMessage: item.DataRequiredMessage };
      return (
        <React.Fragment>
          {data && data.fields.Title && data.fields.Title.value ? (
            <fieldset>
              <legend className="generic_heading">{data.fields.Title.value}</legend>
              {checkBoxSecondlist(data, item, onChangeFunction, validation, formError, initial)}
            </fieldset>
          ) : (
            checkBoxSecondlist(data, item, onChangeFunction, validation, formError, initial)
          )}
        </React.Fragment>
      );
    } else if (newData.AnswerIsDropdownCheckBox && newData.AnswerIsDropdownCheckBox.value) {
      if (
        newData.AnswerIsDropdownCheckBox &&
        newData.ListData &&
        newData.ListData.DropDownList &&
        newData.ListData.DropDownList.length > 0
      ) {
        const validation = item.True ? { DataRequiredMessage: item.DataRequiredMessage } : {};
        let showError = !initial && getValue(formError, setName(item && item.Name));
        return (
          <div className="formBox">
            <div className="formContainer selectBox">
              {item?.Title && <label className="headTxt">{item.Title}</label>}
              <div className="elemContainer">
                <select
                  className={`formElem inputSelectBox ${showError && 'is_error'}`}
                  s
                  onChange={(e) => onChangeFunction(e, item, validation)}
                  data-validation-object={JSON.stringify(validation)}
                  aria-label={newData && newData.Title && newData.Title.value}
                  aria-invalid={showError ? true : false}
                  aria-required={item.True}
                  name={setName(
                    newData &&
                      newData.manutds &&
                      newData.manutds.value &&
                      newData.manutds.value.__Name
                  )}
                >
                  <option value="" selected disabled>
                    {newData && newData.Title && newData.Title.value}
                  </option>
                  {data &&
                    data.fields &&
                    data.fields.ListData &&
                    data.fields.ListData.DropDownList &&
                    data.fields.ListData.DropDownList.length > 0 &&
                    data.fields.ListData.DropDownList.map((item, index) => (
                      <option value={item.Value} key={index}>
                        {item.Name}
                      </option>
                    ))}
                </select>
                {showError && (
                  <>
                    <span className="red-cross-icon"></span>
                    <span role="alert" className="formError">
                      {showError}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    } else if (newData.AnswerIsRadioCheckBox && newData.AnswerIsRadioCheckBox.value) {
      if (
        newData.AnswerIsRadioCheckBox &&
        newData.ListData &&
        newData.ListData.RadioList &&
        newData.ListData.RadioList.length > 0
      ) {
        const validation = item.True ? { DataRequiredMessage: item.DataRequiredMessage } : {};
        const nameRadio =
          data &&
          data.fields &&
          data.fields.manutds &&
          data.fields.manutds.value &&
          data.fields.manutds.value.__Name;
        const showError = !initial && getValue(formError, setName(nameRadio));
        return (
          <div className="formBox">
            <div className="formContainer radioBox">
              {item?.Title && <label className="headTxt">{item.Title}</label>}
              {newData?.RadioButtonTitle?.value && (
                <label className="secondaryTxt">{newData.RadioButtonTitle.value}</label>
              )}
              <ul
                type="radio"
                data-validation-object={JSON.stringify(validation)}
                name={setName(nameRadio)}
              >
                {data &&
                  data.fields &&
                  data.fields.ListData &&
                  data.fields.ListData.RadioList &&
                  data.fields.ListData.RadioList.length > 0 &&
                  data.fields.ListData.RadioList.map((i, key) => {
                    let radioId = `f-option${key}${getRandomId()}`;
                    return (
                      <li key={key}>
                        <InputRadio
                          item={item}
                          radioId={radioId}
                          allData={data}
                          onChangeFunction={onChangeFunction}
                          requireLabel={''}
                          validation={validation}
                          formError={formError}
                          initial={initial}
                          dataVal={i}
                          formDetails={formDetails}
                          nameRadio={nameRadio}
                          showError={showError}
                        />
                        <label htmlFor={radioId}>{data.fields.ListData.RadioList[key].Value}</label>
                        <div className="check"></div>
                      </li>
                    );
                  })}
              </ul>
              {showError && (
                <span role="alert" className="formError">
                  {showError}
                </span>
              )}
            </div>
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const checkBoxFirstList = (data, mainItem, onChangeFunction, validation, formError, initial) => {
  return (
    data &&
    data.fields &&
    data.fields.ListData &&
    data.fields.ListData.checkBoxFirstList &&
    data.fields.ListData.checkBoxFirstList.length > 0 &&
    data.fields.ListData.checkBoxFirstList.map((item, index) => (
      <div className="formBox inputCheckBox" key={index}>
        <div className="formContainer checkBox">
          {item?.Title && <label className="headTxt">{item.Title}</label>}
          <InputCheckbox
            item={item}
            data={data}
            index={index}
            onChangeFunction={onChangeFunction}
            requireLabel={''}
            validation={validation}
            formError={formError}
            initial={initial}
            mainItem={mainItem}
          />
        </div>
      </div>
    ))
  );
};

const checkBoxSecondlist = (data, mainItem, onChangeFunction, validation, formError, initial) => {
  return (
    <div className="formBox ">
      {data &&
        data.fields &&
        data.fields.ListData &&
        data.fields.ListData.checkBoxSecondList &&
        data.fields.ListData.checkBoxSecondList.length > 0 &&
        data.fields.ListData.checkBoxSecondList.map((item, index) => (
          <div
            className={
              index % 2 === 0
                ? 'grid-6 generic-checkbox_con grid6_genLeft'
                : 'grid-6 generic-checkbox_con grid6_genRight'
            }
            key={index}
          >
            <InputCheckbox
              item={item}
              data={data}
              index={index}
              onChangeFunction={onChangeFunction}
              requireLabel={''}
              validation={validation}
              formError={formError}
              initial={initial}
              mainItem={mainItem}
            />
          </div>
        ))}
    </div>
  );
};
