/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { formatDate } from '../../utils/momentDate';
import { useSponsorValue } from '../../context/sponsorContext';
import MU from '../../constants/muConstants';
import { setUserJerseyNumber } from '../../utils/idmUtils';
const UserInfo = ({ t, gigyaData, updateJersyNo, trackLink, jersey }) => {
  const preappendZero = (number) => {
    if (number && String(number) && String(number).length === 1) {
      return `0${number}`;
    } else {
      return number;
    }
  };
  const age = gigyaData && gigyaData.profile && gigyaData.profile.age;
  const birthday = preappendZero(gigyaData && gigyaData.profile && gigyaData.profile.birthDay);
  const birthMonth = preappendZero(gigyaData && gigyaData.profile && gigyaData.profile.birthMonth);
  const birthYear = gigyaData && gigyaData.profile && gigyaData.profile.birthYear;
  const signedInDate = gigyaData && gigyaData.created;
  const [showEditBtn, setshowEditBtn] = useState(true);
  const [loaderState, setLoaderState] = useState(false);
  const [textVal, setTextVal] = useState(jersey);
  const [errorMsg, setErrorMsg] = useState('');
  const [{ isGigyaAvilable }] = useSponsorValue();
  useEffect(() => {
    setTextVal(jersey);
  }, [jersey]);

  const setJersey = (evt) => {
    let val = evt && evt.target && evt.target.value;
    if (val) {
      updateJersyNo(val);
      setTextVal(val);
    } else {
      updateJersyNo('');
    }
  };
  const edit = (e, btnName, targetUrl) => {
    setshowEditBtn(false);
    setErrorMsg('');
    targetUrl = e.target && e.target.href;
    trackLink(e, btnName, targetUrl);
    setTimeout(() => {
      let inputElm = document.getElementById('united-int');
      inputElm && inputElm.focus();
    }, 100);
  };

  const save = (e, btnName, targetUrl) => {
    setshowEditBtn(true);
    targetUrl = e.target && e.target.href;
    let jersyNum = textVal ? textVal.toString() : '00';
    trackLink(e, btnName, targetUrl);
    if (MU && MU.isIDMEnabled && gigyaData) {
      setLoaderState(true);
      setUserJerseyNumber(gigyaData, jersyNum).then((response) => {
        if (response && response.data && response.data.Status === 'success') {
          setLoaderState(false);
        } else {
          setTimeout(() => {
            setLoaderState(false);
            setJersey(
              gigyaData && gigyaData.data && gigyaData.data.jerseyNo
                ? gigyaData.data.jerseyNo
                : '00'
            );
            setErrorMsg(t('WeWereUnableToSaveYourChanges'));
          }, 1000);
        }
      });
    } else if (isGigyaAvilable) {
      setLoaderState(true);
      window.gigya.accounts.setAccountInfo({
        data: { jerseyNo: jersyNum },
        callback: function setAccountInfoCallback(response) {
          if (response && response.errorCode === 0) {
            setLoaderState(false);
          } else {
            setTimeout(() => {
              setLoaderState(false);
              setJersey(
                gigyaData && gigyaData.data && gigyaData.data.jerseyNo
                  ? gigyaData.data.jerseyNo
                  : '00'
              );
              setErrorMsg(t('WeWereUnableToSaveYourChanges'));
            }, 1000);
          }
        },
      });
    }
  };
  const validateInput = (e) => {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <React.Fragment>
      <div className="profile__details united-left row margin-bottom">
        <ul className="profile__info">
          <li className="profile__info__left">
            <div className="profile-dob">
              <p className="headling">{t('Dateofbirth')}</p>
              <p>
                {birthday &&
                  birthMonth &&
                  birthYear &&
                  formatDate(`${birthYear}-${birthMonth}-${birthday}`, 'myunited')}
              </p>
            </div>
            <div className="profile-age">
              <p className="headling">{t('Age')}</p>
              <p>{age}</p>
            </div>
          </li>
          <li className="profile__info__right">
            <div className="profile-signed-date">
              <p className="headling">{t('SignedDate')}</p>
              <p>{formatDate(signedInDate, 'myunited')}</p>
            </div>
            <div className="profile-tshirt-no">
              <p className="headling">
                <span>{t('Shirt')}</span>
                {showEditBtn ? (
                  <span className="action-button">
                    <a href="javascript:void(0)" onClick={(e) => edit(e, 'Edit')}>
                      {t('Edit')}
                    </a>
                  </span>
                ) : (
                  <span className="action-button-save">
                    <a href="javascript:void(0)" onClick={(e) => save(e, 'Save')}>
                      {t('save')}
                    </a>
                  </span>
                )}
              </p>
              {showEditBtn && <p>{jersey ? jersey : '00'}</p>}
              {loaderState && (
                <div className="loader-wrapper-jersy">
                  <div className="loader small-loader"></div>
                </div>
              )}
              {!showEditBtn && (
                <React.Fragment>
                  <input
                    id="united-int"
                    value={textVal}
                    onChange={(e) => setTextVal(setJersey(e))}
                    type="text"
                    min="00"
                    max="99"
                    step="1"
                    pattern="[0-9]*"
                    maxLength="2"
                    onKeyPress={(e) => validateInput(e)}
                  />
                </React.Fragment>
              )}
              <div className="showerror-scenario">{errorMsg}</div>
            </div>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(React.memo(UserInfo));
