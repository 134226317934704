/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { createTagSearchUrl } from '../../utils/utils';
const Tags = (props) => {
  let tags =
    props && props.displayTag && props.displayTag.Category
      ? props.displayTag.Category
      : props && props.categoryTag && props.categoryTag.Category
      ? props.categoryTag.Category
      : null;

  return (
    <React.Fragment>
      {tags && (
        <React.Fragment>
          <span className="statsbar__split" aria-hidden="true"></span>
          <a
            href={createTagSearchUrl(tags)}
            onClick={(e) => e.stopPropagation()}
            className="tag-hide"
          >
            <span className="statsbar__tag">{tags}</span>
          </a>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default Tags;
