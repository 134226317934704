/**
 *  © 2022 -2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import Modal from '../../Common/Modal';
import ImgCrop from '../../Common/ImgCrop';
import Loader from '../../Common/Loader';
import { saveCompetitionResponse, getDrawerImage } from '../../../utils/competitionUtils';
import MU from '../../../constants/muConstants';
import { createDestinationUrl, getLoggedInStatus } from '../../../utils/utils';
import { useSponsorValue } from '../../../context/sponsorContext';
import CompetitionClose from '../../Cards/Unitedcompetition/CompetitionClose';
import xss from 'xss';

const OneClickModal = (props) => {
  const {
    t,
    closeModal,
    showClose,
    previousActiveElement,
    fields,
    gridClass,
    setShowPopUp,
    entryConfirm,
    setEntryConfirm,
    entryClose,
    setEntryError,
    userId,
    userLoginInfo,
    DrawerData,
    sourceFromDetail,
  } = props;
  const { manutds, CompetitionType, EndDate, NocelebrationConfetti } = fields;
  const [showLoader, setShowLoader] = useState(false);
  const isObjectEmpty = (objectName) => {
    return objectName && Object.keys(objectName).length === 0 && objectName.constructor === Object;
  };
  const [
    { isUserLogin, isLoginChecked, userLoginDetail, isUserSubscribe, isUserSkyId },
  ] = useSponsorValue();
  const loginStatus = getLoggedInStatus({
    isLoginChecked,
    isUserLogin,
    isUserSubscribe,
    isUserSkyId,
  });
  const drawerData =
    DrawerData &&
    DrawerData.data &&
    DrawerData.data.GetItemResponse &&
    DrawerData.data.GetItemResponse.response &&
    DrawerData.data.GetItemResponse.response.docs[0] &&
    DrawerData.data.GetItemResponse.response.docs[0];

  const drawerImageData =
    drawerData && drawerData.competitionform_s && drawerData.competitionform_s;
  const Headline = drawerData && drawerData.headline_t && drawerData.headline_t;
  const SuccessTitle = drawerData && drawerData.successtitle_t && drawerData.successtitle_t;
  const SuccessDescription =
    drawerData && drawerData.successdescription_t && drawerData.successdescription_t;
  const PreEntryConfirmation =
    drawerData && drawerData.preentryconfirmation_t && drawerData.preentryconfirmation_t;
  const EnterCompetitionCTA =
    drawerData && drawerData.entercompetitioncta_t && drawerData.entercompetitioncta_t;
  const ViewCompetitionDetailsCTA =
    drawerData && drawerData.viewcompetitiondetailscta_t && drawerData.viewcompetitiondetailscta_t;
  const viewEntryDetailsCTA =
    drawerData && drawerData.viewentrydetailscta_t && drawerData.viewentrydetailscta_t;
  const EditEntryDetailsCTA =
    drawerData && drawerData.editentrydetailscta_t && drawerData.editentrydetailscta_t;
  const SuccessGif = drawerData && drawerData.successgif_t && drawerData.successgif_t;
  const CompetitionFormDestinatioUrl =
    drawerData &&
    drawerData.competitionformdestinatiourl_s &&
    drawerData.competitionformdestinatiourl_s;

  const getCtaAction = () => {
    if (
      CompetitionType &&
      CompetitionType.value &&
      CompetitionType.value.toLowerCase() === 'long'
    ) {
      CompetitionFormDestinatioUrl &&
        (window.location = xss(createDestinationUrl(CompetitionFormDestinatioUrl)));
    } else {
      setShowLoader(true);
      const compSaveID =
        manutds && manutds.value && manutds.value.Id && manutds.value.Id.charAt(0) === '{'
          ? manutds.value.Id.slice(1, -1)
          : manutds.value.Id;
      saveCompetitionResponse(
        userId,
        userLoginInfo,
        compSaveID,
        EndDate,
        setEntryConfirm,
        setEntryError,
        setShowPopUp,
        setShowLoader,
        undefined,
        Headline,
        loginStatus,
        undefined,
        false,
        NocelebrationConfetti
      );
    }
  };

  const drawerImageContent = drawerImageData && getDrawerImage(drawerImageData);
  const draweImage =
    drawerImageContent &&
    drawerImageContent.DrawerImage &&
    drawerImageContent.DrawerImage.CropUrl &&
    drawerImageContent.DrawerImage.CropUrl;
  const drawerImageProfile = draweImage && getDrawerImage(draweImage);

  return (
    <div className="mainContent">
      <Modal
        containerClass="dialog competition-modal compModalUni"
        closeModal={closeModal}
        onClose={(e) => {
          document.querySelector('body').classList.remove('no-scroll');
        }}
        showClose={showClose}
        animate={true}
        previousActiveElement={previousActiveElement}
        ariaDescribedBy="dialogMesg"
      >
        {!entryClose ? (
          <div className="imgBox">
            {!entryConfirm ? (
              <span className="img-holder">
                {xss(drawerImageProfile) && (
                  <ImgCrop
                    className="imgcrop"
                    imgCrop={drawerImageProfile}
                    singleSrc={false}
                    gridClass={gridClass}
                    alt={
                      drawerImageContent &&
                      drawerImageContent.DrawerImage &&
                      drawerImageContent.DrawerImage.AltText &&
                      drawerImageContent.DrawerImage &&
                      drawerImageContent.DrawerImage.AltText
                    }
                    title={
                      drawerImageContent &&
                      drawerImageContent.DrawerImage &&
                      drawerImageContent.DrawerImage.Name &&
                      drawerImageContent.DrawerImage.Name
                    }
                  />
                )}
              </span>
            ) : (
              SuccessGif &&
              !isObjectEmpty(SuccessGif) && (
                <img
                  id="gif"
                  className="imgcrop"
                  src={`${MU && MU.assetUrlResource}/${xss(SuccessGif)}`}
                  alt="no img"
                />
              )
            )}
            <div className={!entryConfirm ? 'modal-text' : 'modal-text-gif'}>
              {(Headline || SuccessTitle) && <h3>{!entryConfirm ? Headline : SuccessTitle}</h3>}
              {(PreEntryConfirmation || SuccessDescription) && (
                <p>{!entryConfirm ? PreEntryConfirmation : SuccessDescription}</p>
              )}
              <span className="modal-btn">
                {!entryConfirm
                  ? EnterCompetitionCTA && (
                      <div className="compBtn">
                        <button className="red-btn-skew enterComp" onClick={getCtaAction}>
                          {EnterCompetitionCTA}
                        </button>
                      </div>
                    )
                  : viewEntryDetailsCTA &&
                    manutds &&
                    manutds.value &&
                    manutds.value.DestinationUrl && (
                      <a href={createDestinationUrl(manutds.value.DestinationUrl)}>
                        <button className="red-btn-skew viewEntry">{viewEntryDetailsCTA}</button>
                      </a>
                    )}
                {!entryConfirm
                  ? ViewCompetitionDetailsCTA &&
                    manutds &&
                    manutds.value &&
                    manutds.value.DestinationUrl && (
                      <a href={createDestinationUrl(manutds.value.DestinationUrl)}>
                        <button className="white-btn-skew viewComp">
                          {ViewCompetitionDetailsCTA}
                        </button>
                      </a>
                    )
                  : CompetitionType &&
                    CompetitionType.value &&
                    CompetitionType.value.toLowerCase() === 'long' &&
                    EditEntryDetailsCTA && (
                      <div className="compBtn">
                        <button onClick={getCtaAction} className="white-btn-skew editEntry">
                          {EditEntryDetailsCTA}
                        </button>
                      </div>
                    )}
              </span>
            </div>
          </div>
        ) : (
          <CompetitionClose
            responseContent={DrawerData}
            entryConfirm={entryConfirm}
            manutds={manutds}
            gridClass={gridClass}
            sourceFromDetail={sourceFromDetail}
          />
        )}
        {showLoader && <Loader className="loader-container" />}
      </Modal>
    </div>
  );
};

export default withTranslation()(OneClickModal);
