/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import { ARTICLE_DETAIL } from '../../../constants/containerConstants';
import Transform from '../../../data-transform';

const Recommendation = ({ t, fields }) => {
  let checkHeadline, checkShortHeadline, checkImage;
  const { SelectCard6, RecommendationSectionTitle } = fields;
  return (
    <div className="home-article-detail__tags">
      <div className="home-article-detail__grid-container grid-10">
        <h2 className="player-title">
          {RecommendationSectionTitle && RecommendationSectionTitle.value}
        </h2>
        <ul className="home-article-detail__grid-list">
          {SelectCard6.map((item, index) => {
            checkHeadline = item && item.fields.Headline;
            checkShortHeadline = item && item.fields.ShortHeadline;
            checkImage = item && item.fields.Image;
            if((checkHeadline && checkHeadline.value !== '') || (checkShortHeadline && checkShortHeadline.value !== '') || (checkImage && Object.keys(checkImage.value).length !== 0)){
            return (
              <li key={index}>
                <div className="home-article-detail__article grid- 12">
                  <Transform
                    key={index}
                    data={item}
                    container={ARTICLE_DETAIL}
                    source="curate"
                    gridClass="grid-7"
                    showStatsbar={false}
                    isInlineVideo={true}
                    isArticleDetail={true}
                    noVariant={true}
                  />
                </div>
              </li>
            );
          }
          else{
            return null;
          }
          })}
        </ul>
      </div>
    </div>
  );
};

export default withTranslation()(Recommendation);