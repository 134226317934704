/**
 *  © 2022-2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { Fragment, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { isEmpty, isEqual } from 'lodash';
import ImgCrop from '../../Common/ImgCrop';
import Socialholder from '../ArticleDetail/SocialHolder';
import Recommendation from '../ArticleDetail/Recommendation';
import CenterContent from '../ArticleDetail/CenterContent';
import RelatedKeywords from '../ArticleDetail/RelatedKeywords';
import ArticleNext from '../ArticleDetail/ArticleNext';
import * as track from '../../../utils/analytics';
import { skip_focus, checkOrentation, throttle } from '../../../utils/articleUtils';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import Transform from '../../../data-transform';
import {
  inlineVideoData,
  getTagObjects,
  isApp,
  getDFPAdFields,
  isCollection,
  createDestinationUrl,
  getParameterByName,
} from '../../../utils/utils';
import loadable from '@loadable/component';
import MU from '../../../constants/muConstants';
import AMPArticleDetail from '../AMPArticleDetail';
import DFPAd from '../../DFPAd';
import ContentAccessModal from '../../Common/ContentAccessModal';
import { ARTICLE_DETAIL } from '../../../constants/containerConstants';
import { useSponsorValue } from '../../../context/sponsorContext';
import { useModal } from '../../Common/Modal';
import { showCBS, getLoggedInStatus } from '../../../utils/utils';
import { ACCOUNT_SUBSCRIPTION, REGISTERED } from '../../../constants/globalConstants';
import { GET_COMPETITIONS, GET_COMPETITION_ITEM } from '../../../constants/endpoints';
import ErrorBoundary from '../../Common/ErrorBoundary';
import OneClickModal from '../../Modals/UnitedCompetitionModal/UnitedCompetitionModal';
import Loader from '../../Common/Loader';
import moment from 'moment';
import { fetchAPIGetItem, saveCompetitionResponse } from '../../../utils/competitionUtils';
import CompetitionDetailForm from '../CompetitionDetailForm';
import AdCard from '../../Cards/AdCard';
import Modal from '../../Common/Modal';
import { FREE_TO_VIEW, MUAppDeepLink } from '../../../constants/globalConstants';
const RegistrationModal = loadable(() =>
  import('../../Modals/RegistrationModal/RegistrationModal')
);
const HybridArticleCSS = loadable(() => import('../ArticleDetail/HybridArticleCSS.js'));

const CompetitionDetail = (props) => {
  const { fields, gridClass, t, isDFPScript } = props;
  const {
    Headline,
    Introduction,
    SelectCard6,
    RecommendationSectionTitle,
    manutds,
    NextArticle,
    ShortHeadline,
    ContentType,
    Image,
    Video,
    AuthorName,
    __Published,
    __PublishedBy,
    TextBox6,
    ContentAccess,
    FailMessage,
    PleaseTryAgainCTA,
    EnterCompetitionCTA,
    EntryConfirmedCTA,
    SuccessMessage,
    SuccessSelectCard,
    PreEntryConfirmation,
    DetailedPageSuccessTitle,
    DetailedPageSuccessDescription,
    CompetitionType,
    CTATitleRegisteredUser,
    CTATitleSubscribedUser,
    EndDate,
    DetailImage,
    NocelebrationConfetti,
  } = fields;
  const appImagePortrait =
    DetailImage && DetailImage.value && DetailImage.value.Crops && DetailImage.value.Crops.portrait;
  const appImageLarge =
    DetailImage && DetailImage.value && DetailImage.value.Crops && DetailImage.value.Crops.xlarge;
  const [hybridReadMoreScrolledClass, setHybridReadMoreScrolledClass] = useState('');
  const [hybridImagePath, setHybridImagePath] = useState(appImagePortrait);
  const [showPopUp, setShowPopUp] = useState(false);
  const [entryError, setEntryError] = useState(false);
  const [entryConfirm, setEntryConfirm] = useState(false);
  const [entryClose, setEntryClose] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userCompetition, setUserCompetition] = useState(null);
  const [drawerData, setDrawerData] = useState();
  let competitionRslt, competitionUid;
  const [showLoader, setShowLoader] = useState(false);
  const [userLoginInfo, setUserLoginInfo] = useState({});
  const [retryFormDetails, setRetryFormDetails] = useState();
  const [formSuccessMsg, setFormSuccessMsg] = useState(false);
  const [showFormDrawer, setShowFormDrawer] = useState(false);

  useEffect(() => {
    if (isApp()) {
      if (appImagePortrait && appImageLarge) {
        try {
          const selector = document.getElementsByClassName('hybridArticleImg');
          const grid = selector && selector[0] && selector[0].offsetWidth;
          const screenOrientation = checkOrentation();
          /*ipad pro*/
          if (grid >= 1024 && grid < 1366 && screenOrientation === 'portrait') {
            // ipad pro portrait
            setHybridImagePath(appImagePortrait);
          } else if (grid >= 1024 && screenOrientation === 'landscape') {
            // ipad landscape
            setHybridImagePath(appImageLarge);
          }
        } catch (er) {
          setHybridImagePath('');
          console.log(er);
        }
      }
      window.addEventListener(
        'scroll',
        throttle(hybridScroll, 500, {
          leading: true,
          trailing: true,
        })
      );
    }
    return () => {
      if (isApp()) {
        window.removeEventListener(
          'scroll',
          throttle(hybridScroll, 500, {
            leading: true,
            trailing: true,
          })
        );
      }
    };
  }, []);

  const hybridScroll = () => {
    let noScroll = true;
    const position = window.pageYOffset;
    if (noScroll) {
      if (position > 1) {
        noScroll = false;
        setHybridReadMoreScrolledClass('scrolled');
      }
    }
  };

  const itemId = `{${manutds && manutds.value && manutds.value.Id}}`;

  const tags = getTagObjects(manutds && manutds.value && manutds.value.Tags);

  const trackLink = (e) => {
    const initialData = track.data('link');
    if (initialData && typeof initialData.destination_url !== 'undefined') {
      delete initialData.destination_url;
    }
    track.analytics(
      {
        ...initialData,
        button_name: t('ScrollToTop'),
      },
      e
    );
    e.preventDefault();
  };

  let tagsForAnalytics = '';
  if (tags && tags.length) {
    tags.map((item) => {
      tagsForAnalytics += tagsForAnalytics.length
        ? `,${item.Name}: ${item.Tag}`
        : `${item.Name}: ${item.Tag}`;
    });
  }

  if (MU && MU.isAmpPageUrl) {
    return <AMPArticleDetail {...props}></AMPArticleDetail>;
  }
  const { showModal, closeModal, isModalVisible } = useModal();
  const [
    { isUserLogin, isLoginChecked, userLoginDetail, isUserSubscribe, isUserSkyId },
  ] = useSponsorValue();
  const loginStatus = getLoggedInStatus({
    isLoginChecked,
    isUserLogin,
    isUserSubscribe,
    isUserSkyId,
  });

  const contentAccessState = ContentAccess && ContentAccess.value && ContentAccess.value;
  const {
    showModal: cbrShowModal,
    closeModal: cbrCloseModal,
    isModalVisible: cbrModalVisible,
  } = useModal();

  const closeOneClickModal = () => {
    setShowPopUp(false);
  };

  const closeFormClickModal = () => {
    setShowFormDrawer(false);
    setShowLoader(false);
  };

  // Error in get competation Response
  const competitionDataErrorCase = (err) => {
    fields && checkExpiredCompetition(fields);
    setShowLoader(false);
  };

  // Success in get competation Response
  const getUserCompetitionSuccess = (response) => {
    if (response && response.data) {
      setUserCompetition(response);
    } else {
      setUserCompetition(null);
    }
    setShowLoader(false);
    fields && checkExpiredCompetition(fields);
  };

  // Check locally stored competation Response
  const matchLocallyStoredCompetition = (UID, competition_ID) => {
    const userCompetitionStore = localStorage.getItem('userCompetitions');
    if (
      typeof Storage !== undefined &&
      userCompetitionStore &&
      userCompetitionStore !== 'undefined'
    ) {
      const competitions = JSON.parse(userCompetitionStore);
      if (competitions) {
        competitions.forEach((users) => {
          if (users.userId && users.userId === UID) {
            users.competitions &&
              users.competitions.forEach((userCompetition) => {
                const compID =
                  userCompetition.competitionId && userCompetition.competitionId.charAt(0) === '{'
                    ? userCompetition.competitionId.slice(1, -1)
                    : userCompetition.competitionId;
                const responseID =
                  competition_ID && competition_ID.charAt(0) === '{'
                    ? competition_ID.slice(1, -1)
                    : competition_ID;
                if (compID === responseID) {
                  setEntryConfirm(true);
                }
              });
          }
        });
      }
    }
  };

  const getAnalyticsError = (competitionID, userId, Headline, loginStatus, err) => {
    const initialData = track.data('unitedcompetitionError');
    return {
      ...initialData,
      error_message: err,
      error_type: 'competitionError',
      item_id: competitionID,
      login_id: userId,
      subscription_status: loginStatus,
    };
  };

  // const trackCompetitionError = (competitionID, userId, Headline, loginStatus, err) => {
  //   track.analytics(getAnalyticsError(competitionID, userId, Headline, loginStatus, err));
  // };

  //Get sync competation Response
  const fetchCompetitionData = (UID) => {
    const route = `${GET_COMPETITIONS}?uid=${UID}`;
    if (!competitionRslt || (userLoginDetail && UID !== competitionUid)) {
      competitionUid = UID;
      competitionRslt = fetchAPIGetItem(route);
    }
    competitionRslt
      .then((data) => {
        if (
          data &&
          data.data &&
          data.data.errorMessage &&
          data.data.errorMessage === 'Campaign Failure : UID does not exist in MongoDB'
        ) {
          competitionDataErrorCase({});
        } else {
          getUserCompetitionSuccess(data);
        }
      })
      .catch((err) => {
        competitionDataErrorCase(err);
      })
      .finally(() => {
        matchLocallyStoredCompetition(UID, manutds && manutds.value && manutds.value.Id);
      });
  };

  // Once user is login call competation Response
  useEffect(() => {
    if (userLoginDetail) {
      const userID = userLoginDetail && userLoginDetail.UID;
      setUserId(userID);
      setShowLoader(true);
      fetchCompetitionData(userID);
      setUserLoginInfo({
        FirstName:
          userLoginDetail &&
          userLoginDetail.profile &&
          userLoginDetail.profile.firstName &&
          userLoginDetail.profile.firstName,
        LastName:
          userLoginDetail &&
          userLoginDetail.profile &&
          userLoginDetail.profile.lastName &&
          userLoginDetail.profile.lastName,
        Email:
          userLoginDetail &&
          userLoginDetail.profile &&
          userLoginDetail.profile.email &&
          userLoginDetail.profile.email,
        MarketingConsent:
          userLoginDetail &&
          userLoginDetail.data &&
          userLoginDetail.data.marketingConsent &&
          userLoginDetail.data.marketingConsent,
      });
    }
  }, [userLoginDetail]);

  //Once get the user's competation Response then checked with ID
  useEffect(() => {
    if (userCompetition) {
      const userAPIData =
        userCompetition && userCompetition.data && userCompetition.data.CampaignGetSyncResponse;
      if (userAPIData) {
        userAPIData.forEach((elem) => {
          if (elem.UidGigya && elem.UidGigya === userId) {
            if (manutds && manutds.value && manutds.value.Id) {
              const compID =
                manutds.value.Id.charAt(0) === '{'
                  ? manutds.value.Id.slice(1, -1)
                  : manutds.value.Id;
              const elemID =
                elem.CampaignID && elem.CampaignID.charAt(0) === '{'
                  ? elem.CampaignID.slice(1, -1)
                  : elem.CampaignID;
              if (elemID === compID) {
                setEntryConfirm(true);
              }
            }
          }
        });
      }
    }
  }, [userCompetition]);

  // Once hit competation CTA button
  const getCtaAction = (e) => {
    e.preventDefault();
    // App webview redirect
    if (isApp() && !isCollection() && !isUserLogin) {
      const compURL = manutds && manutds.value && manutds.value.DestinationUrl;
      window.location.href = createDestinationUrl(compURL) + MUAppDeepLink;
      return true;
    }
    // App webview redirect
    if (showCBS({ isUserLogin, isUserSubscribe, contentAccessState })) {
      e.preventDefault();
      cbrShowModal();
    } else {
      setShowLoader(true);
      const route = `${GET_COMPETITION_ITEM}${encodeURI(itemId && itemId.toUpperCase())}`;
      const getCampaignItemData = fetchAPIGetItem(route);
      getCampaignItemData
        .then((data) => {
          setDrawerData(data);
          if (entryClose) {
            setShowLoader(false);
            setShowPopUp(true);
            document.querySelector('body').classList.add('no-scroll');
          } else if (
            !entryClose &&
            CompetitionType &&
            CompetitionType.value &&
            CompetitionType.value.toLowerCase() === 'long'
          ) {
            if (isApp()) {
              if (manutds && manutds.value && manutds.value.FormDestinationUrl) {
                setShowLoader(false);
                const compURL = manutds.value.FormDestinationUrl;
                window.location.href = createDestinationUrl(compURL) + MUAppDeepLink;
                return true;
              }
            } else {
              setShowFormDrawer(true);
              document.querySelector('body').classList.add('no-scroll');
            }
          } else if (!entryClose && !entryConfirm) {
            setShowPopUp(false);
            const compSaveID =
              manutds && manutds.value && manutds.value.Id && manutds.value.Id.charAt(0) === '{'
                ? manutds.value.Id.slice(1, -1)
                : manutds.value.Id;
            saveCompetitionResponse(
              userId,
              userLoginInfo,
              compSaveID,
              EndDate,
              setEntryConfirm,
              setEntryError,
              setShowPopUp,
              setShowLoader,
              undefined,
              Headline,
              loginStatus,
              undefined,
              false,
              NocelebrationConfetti
            );
          } else if (entryConfirm) {
            setShowLoader(false);
            setShowPopUp(true);
            document.querySelector('body').classList.add('no-scroll');
          }
        })
        .catch((err) => {
          setEntryError(true);
          //trackCompetitionError('', userId, Headline, loginStatus, err);
          console.log('err', err);
          setShowLoader(false);
        });
    }
    closeModal();
  };

  // CTA button title
  const getCtaButton = () => {
    if (!isUserLogin && contentAccessState === REGISTERED) {
      return (
        CTATitleRegisteredUser &&
        CTATitleRegisteredUser.value && (
          <span className="red-btn-skew competition-entry-btn">
            {CTATitleRegisteredUser.value.toUpperCase()}
          </span>
        )
      );
    } else if (!isUserSubscribe && contentAccessState === ACCOUNT_SUBSCRIPTION) {
      return (
        CTATitleSubscribedUser &&
        CTATitleSubscribedUser.value && (
          <span className="red-btn-skew competition-entry-btn">
            {CTATitleSubscribedUser.value.toUpperCase()}
          </span>
        )
      );
    } else {
      return (
        EnterCompetitionCTA &&
        EnterCompetitionCTA.value && (
          <span className="red-btn-skew competition-entry-btn">
            {EnterCompetitionCTA.value.toUpperCase()}
          </span>
        )
      );
    }
  };

  const retriveSuccessCallback = typeof window !== 'undefined' && getParameterByName('state', true);

  useEffect(() => {
    if (retriveSuccessCallback && isLoginChecked) {
      const successCallData = retriveSuccessCallback.split(',');
      if (successCallData && successCallData[0] === 'competitionDetail') {
        const isSubscribedContent = successCallData[1] && successCallData[1] === 'true';
        const userSubscribed = isUserSubscribe;
        if (!isSubscribedContent || (isSubscribedContent && userSubscribed)) {
          setShowPopUp(false);
          const url = window.location.href;
          const urlMain = url.split('?')[0] ?? url;
          window.history.replaceState({}, null, urlMain);
        }
      }
    }
  }, [retriveSuccessCallback, isLoginChecked]);

  const successCallback = ({ showContent, isSubscribedContent }) => {
    if (showContent) {
      if (!MU.isIDMEnabled) {
        setShowPopUp(false);
      } else return ['competitionDetail', isSubscribedContent];
    }
  };

  // to checked competation is expire or not
  const checkExpiredCompetition = (data) => {
    if (data && data.EndDate && data.EndDate.value) {
      var expired = moment(data.EndDate.value).isBefore(moment());
      if (expired && data.EndDate.value !== '0001-01-01T00:00:00Z') {
        setEntryClose(true);
      }
    }
  };

  const getAnalyticsData = () => {
    const initialData = track.data('card');
    return {
      ...initialData,
      card_name: ShortHeadline && ShortHeadline.value,
      container_type: ARTICLE_DETAIL,
      item_id: itemId,
      content_type: ContentType && ContentType.value,
      tag: manutds && manutds.value && manutds.value.Tags,
      created_datetime: __Published && __Published.value,
      author: AuthorName && AuthorName.value,
      destination_url: manutds && manutds.value && manutds.value.DestinationUrl,
    };
  };
  useEffect(() => {
    if (
      entryError &&
      showFormDrawer &&
      CompetitionType &&
      CompetitionType.value &&
      CompetitionType.value.toLowerCase() === 'long'
    ) {
      document.querySelector('body').classList.add('no-scroll');
    }
  }, [entryError]);

  return showFormDrawer ? (
    <Modal
      containerClass="dialog competition-modal competition-form-modal"
      closeModal={closeFormClickModal}
      onClose={(e) => {
        document.querySelector('body').classList.remove('no-scroll');
      }}
      showClose={true}
      animate={true}
      ariaDescribedBy="dialogMesg"
    >
      <ErrorBoundary>
        <CompetitionDetailForm
          {...props}
          userId={userId}
          userLoginInfo={userLoginInfo}
          entryConfirm={entryConfirm}
          setEntryConfirm={setEntryConfirm}
          entryError={entryError}
          setEntryError={setEntryError}
          userEnteredCompetition={userCompetition}
          retryFormDetails={retryFormDetails}
          setRetryFormDetails={setRetryFormDetails}
          formSuccessMsg={formSuccessMsg}
          setFormSuccessMsg={setFormSuccessMsg}
          entryClose={entryClose}
          gridClass={gridClass}
        />
      </ErrorBoundary>
    </Modal>
  ) : MU &&
    MU.isFormDestination &&
    MU.isFormDestination === true &&
    CompetitionType &&
    CompetitionType.value &&
    CompetitionType.value.toLowerCase() === 'long' ? (
    <ErrorBoundary>
      <CompetitionDetailForm
        {...props}
        userId={userId}
        userLoginInfo={userLoginInfo}
        entryConfirm={entryConfirm}
        setEntryConfirm={setEntryConfirm}
        entryError={entryError}
        setEntryError={setEntryError}
        userEnteredCompetition={userCompetition}
        retryFormDetails={retryFormDetails}
        setRetryFormDetails={setRetryFormDetails}
        formSuccessMsg={formSuccessMsg}
        setFormSuccessMsg={setFormSuccessMsg}
        entryClose={entryClose}
        gridClass={gridClass}
      />
    </ErrorBoundary>
  ) : (
    <React.Fragment>
      {(typeof window === 'undefined' || process.env.NODE_ENV === 'development') && isApp() && (
        <HybridArticleCSS />
      )}
      <div
        className={`home-article-detail home-competition-detail ${
          isApp() ? 'hybrid-article' : ''
        } clearfix`}
        data-article-detail={JSON.stringify({
          competition_name: Headline && Headline.value,
          author:
            (AuthorName &&
              AuthorName.fields &&
              AuthorName.fields.AuthorName &&
              AuthorName.fields.AuthorName.value) ||
            (__PublishedBy && __PublishedBy.value),
          content_type: ContentType && ContentType.value,
          created_datetime: __Published && __Published.value,
          tag: tagsForAnalytics,
          uid: userLoginInfo,
        })}
      >
        {/* ---- Top Panel start ----*/}
        {isEmpty(Video && Video.value) && (
          <div className="home-article-detail__top-panel">
            <div className="grid-12 ratio">
              <div className="mu-content">
                <article className="mu-item xlarge">
                  {DetailImage &&
                  DetailImage.value &&
                  DetailImage.value.Crops &&
                  isApp() &&
                  !isCollection() &&
                  hybridImagePath &&
                  hybridImagePath.length > 0 ? (
                    <div className="img-holder nobg">
                      <ImgCrop
                        imgCrop={hybridImagePath}
                        alt={DetailImage.value.AltText}
                        title={DetailImage.value.AltText}
                        isWebpAvailable={DetailImage.value.isWebpAvailable}
                        adaptiveClass="hybridArticleImg"
                        figureStyle={{
                          backfaceVisibility: 'hidden',
                          WebkitBackfaceVisibility: 'hidden',
                        }}
                        singleSrc={true}
                      />
                    </div>
                  ) : (
                    <div className="img-holder">
                      <ImgCrop
                        imgCrop={DetailImage && DetailImage.value && DetailImage.value.Crops}
                        alt={DetailImage && DetailImage.value && DetailImage.value.AltText}
                        title={DetailImage && DetailImage.value && DetailImage.value.AltText}
                        isWebpAvailable={
                          DetailImage && DetailImage.value && DetailImage.value.isWebpAvailable
                        }
                        className={'adaptive'}
                      />
                    </div>
                  )}
                  <div className="home-article-detail__info">
                    <div className="competitionTopContainer">
                      <h1
                        data-impression={track.impression({
                          card_name: ShortHeadline && ShortHeadline.value,
                          content_type: ContentType && ContentType.value,
                        })}
                        className="home-article-detail__title"
                        id="hybridTitle"
                        style={{
                          backfaceVisibility: 'hidden',
                          WebkitBackfaceVisibility: 'hidden',
                        }}
                      >
                        {Headline && Headline.value}
                      </h1>
                      {!entryConfirm &&
                        !entryError &&
                        PreEntryConfirmation &&
                        PreEntryConfirmation.value && (
                          <p className="mu-item__subtitle preEntry">{PreEntryConfirmation.value}</p>
                        )}
                      {entryConfirm && SuccessMessage && SuccessMessage.value && (
                        <p className="mu-item__subtitle successMsg">{SuccessMessage.value}</p>
                      )}

                      {entryError && !entryConfirm && FailMessage && FailMessage.value && (
                        <p className="mu-item__subtitle failMsg">{FailMessage.value}</p>
                      )}

                      {!entryConfirm
                        ? ((PleaseTryAgainCTA && PleaseTryAgainCTA.value) ||
                            (EnterCompetitionCTA && EnterCompetitionCTA.value)) && (
                            <div className="entry-btn">
                              <a
                                href="#"
                                role="button"
                                onClick={(e) => getCtaAction(e)}
                                aria-label={EnterCompetitionCTA.value}
                              >
                                <span
                                  className={entryError ? 'entry-error-btn' : 'entry-btn-wrapper'}
                                >
                                  {entryError ? PleaseTryAgainCTA.value : getCtaButton()}
                                  <span className="icon-red-arrow"></span>
                                  <span className="icon-cta-arrow-white"></span>
                                </span>
                              </a>
                            </div>
                          )
                        : entryConfirm &&
                          EntryConfirmedCTA &&
                          EntryConfirmedCTA.value && (
                            <div className="entry-btn">
                              <a
                                href="#"
                                role="button"
                                onClick={(e) => getCtaAction(e)}
                                aria-label={EntryConfirmedCTA.value}
                              >
                                <span className="red-btn-skew">
                                  <span className="icon-check-red-circle"></span>
                                  <span className="icon-check-circle"></span>
                                  <span>{EntryConfirmedCTA.value}</span>
                                </span>
                              </a>
                            </div>
                          )}
                    </div>
                  </div>
                  {isApp() && (
                    <Fragment>
                      <div
                        data-track-ignore-anchor={false}
                        className="downArrow downBounce non-keyboard-outline"
                        tabIndex="0"
                        aria-label={t('ScrollToTop')}
                        onClick={(e) => {
                          trackLink(e);
                        }}
                      ></div>
                      <div id="hybridReadMore" className={hybridReadMoreScrolledClass}>
                        {t('ScrollToTop')}
                      </div>
                    </Fragment>
                  )}
                </article>
              </div>
            </div>
          </div>
        )}

        {!isEmpty(Video && Video.value) && (
          <div className="home-article-detail__video-panel">
            <Transform
              componentName="video"
              gridClass="grid-7"
              isInlineVideo={true}
              tracking={false}
              data={inlineVideoData({
                crops:
                  fields.DetailImage && fields.DetailImage.value && fields.DetailImage.value.Crops,
                video: Video && Video.value,
              })}
              source="aws"
              showStatsbar={false}
              isArticleDetail={true}
              noVariant={true}
              isEmbedVideo={true}
            />
          </div>
        )}
        {/* ------ Top Panel ends -------*/}

        {/*---- Content with social icons Start ----*/}
        <div className="home-article-detail__content-panel no-margin">
          <div className="home-content-panel__sponsor">
            <ul className="sponsor-tag"></ul>
          </div>
          <div className="home-content-panel__details">
            {!isEmpty(Video && Video.value) && (
              <h2 className="home-content-panel__video-heading">{Headline && Headline.value}</h2>
            )}
            <Socialholder gridClass="grid-7" fields={fields} itemId={itemId}></Socialholder>
            {entryConfirm && (
              <div className="successMsg">
                {DetailedPageSuccessTitle && DetailedPageSuccessTitle.value && (
                  <p className="mu-item__subtitle successHead">{DetailedPageSuccessTitle.value}</p>
                )}
                {DetailedPageSuccessDescription && DetailedPageSuccessDescription.value && (
                  <p className="mu-item__subtitle successDesc">
                    {DetailedPageSuccessDescription.value}
                  </p>
                )}
              </div>
            )}
            <div className="home-content-panel__details-text">
              <p className="heading">
                <Text field={Introduction} />
              </p>
            </div>
          </div>
        </div>
        {/*---- Content with social icons End ----*/}

        {/*--Center Section Starts here-*/}
        <div className="home-competition-detail__content-card-panel">
          <CenterContent fields={fields} />
        </div>
        {/*--Center Section Ends here-*/}

        {/* - Content Panel Start ---*/}
        {TextBox6 && TextBox6.value && TextBox6.value.length > 0 && (
          <div className="home-article-detail__content-panel home-competition-detail__content-card-panel">
            <div className="home-content-panel__details">
              <div className="home-content-panel__details-text">
                <div className="paragraph">
                  <RichText field={TextBox6} />
                </div>
              </div>
            </div>
          </div>
        )}
        {/* - Content Panel End -----*/}

        {/* ----ad card panel start here---- */}
        {entryConfirm && SuccessSelectCard && SuccessSelectCard.length > 0 && (
          <div className="home-article-detail__content-panel home-competition-detail__ad-card-panel">
            <div className="home-content-panel__details">
              {SuccessSelectCard.map((fields, i) => (
                <AdCard key={i} {...fields} gridClass="grid-12" />
              ))}
            </div>
          </div>
          /* ----ad card panel start here---- */
        )}

        {/*--Recommendation Section Starts here-*/}
        {SelectCard6 &&
          SelectCard6.length > 0 &&
          RecommendationSectionTitle &&
          RecommendationSectionTitle.value &&
          RecommendationSectionTitle.value.length > 0 && <Recommendation fields={fields} />}
        {/*--Recommendation Section Ends here---*/}

        {/*-- Author & Sponsor Section Starts here ---*/}
        <div className="home-article-detail__content-panel no-margin">
          <div className="home-content-panel__details">
            <div className="home-content-panel__details-social bottom-panel-social">
              <Socialholder
                gridClass="grid-7"
                fields={fields}
                itemId={itemId}
                type="articlefooter"
              ></Socialholder>
              <div className="back-to-top-btn" role="button" tabIndex="0">
                <span
                  onClick={() => {
                    skip_focus('#content');
                  }}
                >
                  <span>{t('BacktoTop')}</span>
                  <i className="icon-arrow-up-black"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        {/*-- Author & Sponsor Section Ends here */}

        {/*-- Related keywords section start ---*/}
        {tags && tags.length > 0 && <RelatedKeywords tags={tags}></RelatedKeywords>}
        {/*--- Related keywords section end ---*/}
        {/*----- next section starts here  ----*/}

        {NextArticle &&
          NextArticle.Id &&
          !isEqual(
            NextArticle.Id.toLowerCase(),
            manutds && manutds.value && manutds.value.Id && manutds.value.Id.toLowerCase()
          ) && <ArticleNext nextArticle={NextArticle}></ArticleNext>}
        {/*  next section ends here */}
        {!isApp() && isDFPScript && (
          <DFPAd
            rendering={{ dataSource: manutds && manutds.value && manutds.value.Id }}
            fields={getDFPAdFields(
              '[1024, 768, 320]',
              '[970, 90]',
              '[728, 90]',
              '[320, 50]',
              '/11820346/Hercules_ROS_970x90//728x90//320x50',
              manutds
            )}
          />
        )}
      </div>
      {showPopUp && (
        <ErrorBoundary>
          <OneClickModal
            closeModal={closeOneClickModal}
            showClose={true}
            fallback={<Loader />}
            accessType={contentAccessState}
            fields={fields}
            DrawerData={drawerData}
            gridClass={gridClass}
            showPopUp={showPopUp}
            setShowPopUp={setShowPopUp}
            entryConfirm={entryConfirm}
            setEntryConfirm={setEntryConfirm}
            entryClose={entryClose}
            setEntryError={setEntryError}
            userId={userId}
            sourceFromDetail={true}
          />
        </ErrorBoundary>
      )}

      {cbrModalVisible && (
        <ErrorBoundary>
          <RegistrationModal
            closeModal={cbrCloseModal}
            successCallback={successCallback}
            fallback={<Loader />}
            previousActiveElement={document.activeElement}
            accessType={contentAccessState}
          />
        </ErrorBoundary>
      )}
      {/* For testing purpose isApp code added */}
      <ContentAccessModal
        ContentAccess={
          isApp() ? FREE_TO_VIEW : ContentAccess && ContentAccess.value && ContentAccess.value
        }
        ContentType={ContentType && ContentType.value}
        manutds={manutds}
        className={`home-article-detail ${isApp() ? 'hybrid-article' : ''} clearfix`}
        isDestinationPage={true}
        insideModal={false}
        successCallback={successCallback}
        analyticsObj={getAnalyticsData()}
      />
      {showLoader && (
        <Loader className="loader-container" style={{ background: 'rgba(0,0,0,0.8)' }} />
      )}
    </React.Fragment>
  );
};

export default withTranslation()(CompetitionDetail);
