/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

export const dialog = (
  setState,
  manageSettings,
  overlayEl,
  dialogEl,
  focusableList,
  firstFocusable,
  lastFocusable,
  focusedElBeforeOpen
) => {
  const dialog = dialogEl && dialogEl.current;
  if (dialog) {
    focusableList.current = dialog.querySelectorAll(
      'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), p, h2, [tabindex="0"]'
    );
    focusableList.current = Array.prototype.slice.call(focusableList.current);
    firstFocusable.current = focusableList.current[0];
    firstFocusable.current && firstFocusable.current.focus();
    lastFocusable.current = focusableList.current[focusableList.current.length - 1];
    dialogOpen(
      false,
      setState,
      manageSettings,
      overlayEl,
      dialogEl,
      focusableList,
      firstFocusable,
      lastFocusable,
      focusedElBeforeOpen
    );
    return '';
  }
};
const dialogOpen = (
  type,
  setState,
  manageSettings,
  overlayEl,
  dialogEl,
  focusableList,
  firstFocusable,
  lastFocusable,
  focusedElBeforeOpen
) => {
  let time = 0;
  if (type) {
    manageSettings();
    time = 500;
  }
  setTimeout(() => {
    const overlay = overlayEl && overlayEl.current;
    const dialog = dialogEl && dialogEl.current;
    focusedElBeforeOpen.current = document.activeElement;
    if (type) {
      setState((state) => ({
        ...state,
        showManageSetting: true,
      }));
    }
    if (dialog && overlay && !type) {
      dialog.addEventListener('keydown', (e) => {
        dialogHandleKeyDown(
          e,
          setState,
          focusedElBeforeOpen,
          focusableList,
          firstFocusable,
          lastFocusable
        );
      });
      overlay.addEventListener('click', () => {
        dialogClose(false, setState, focusedElBeforeOpen);
      });
    }
    firstFocusable.current && firstFocusable.current.focus();
  }, time);
};
const dialogClose = (type, setState, focusedElBeforeOpen) => {
  if (type) {
    setState((state) => ({
      ...state,
      showManageSetting: false,
      showConsent: false,
    }));
  }
  if (focusedElBeforeOpen.current) {
    setTimeout(() => {
      focusedElBeforeOpen.current && focusedElBeforeOpen.current.focus();
    }, 500);
  }
};
export const openCloseCookieDialog = (
  openDialogSel,
  closeDialogSel,
  setState,
  manageSettings,
  overlayEl,
  dialogEl,
  focusableList,
  firstFocusable,
  lastFocusable,
  focusedElBeforeOpen
) => {
  const openDialogEls = document.querySelectorAll(openDialogSel);
  if (openDialogEls.length) {
    for (let i = 0; i < openDialogEls.length; i++) {
      openDialogEls[i].addEventListener('click', () => {
        dialogOpen(
          true,
          setState,
          manageSettings,
          overlayEl,
          dialogEl,
          focusableList,
          firstFocusable,
          lastFocusable,
          focusedElBeforeOpen
        );
      });
    }
  }
  const closeDialogEls = document.querySelectorAll(closeDialogSel);
  if (closeDialogEls.length) {
    for (let i = 0; i < closeDialogEls.length; i++) {
      closeDialogEls[i].addEventListener('click', () => {
        dialogClose(true, setState, focusedElBeforeOpen);
      });
    }
  }
};
const dialogHandleKeyDown = (
  e,
  setState,
  focusedElBeforeOpen,
  focusableList,
  firstFocusable,
  lastFocusable
) => {
  const KEY_TAB = 9;
  const KEY_ESC = 27;
  const handleBackwardTab = () => {
    if (document.activeElement === firstFocusable.current) {
      e.preventDefault();
      lastFocusable.current && lastFocusable.current.focus();
      document.activeElement.classList.add('non-keyboard-outline');
    }
  };
  const handleForwardTab = () => {
    if (document.activeElement === lastFocusable.current) {
      e.preventDefault();
      firstFocusable.current && firstFocusable.current.focus();
      document.activeElement.classList.add('non-keyboard-outline');
    }
  };
  switch (e.keyCode) {
    case KEY_TAB:
      if (focusableList.current.length === 1) {
        e.preventDefault();
        break;
      }
      if (e.shiftKey) {
        handleBackwardTab();
      } else {
        handleForwardTab();
      }
      break;
    case KEY_ESC:
      dialogClose(false, setState, focusedElBeforeOpen);
      break;
    default:
      break;
  }
};
