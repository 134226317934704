/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect } from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { getCssUrl, isApp, buildImgUrl } from '../utils/utils';
import MU from '../constants/muConstants';
import layout from '../constants/layoutConstants';
import SkipToContent from '../components/Common/SkipToContent';
import { isEqual } from 'lodash';
import { ScreenNames, APP_ONLY } from '../constants/globalConstants';
import AppOnly from '../components/Common/AppOnly';

const NewsDetailLayout = ({ route, context }) => {
  const contentType =
    route &&
    route.fields &&
    route.fields.ContentType &&
    route.fields.ContentType.value &&
    route.fields.ContentType.value.toLowerCase();
  const isProfileType =
    contentType &&
    (contentType === 'playerprofile' ||
      contentType === 'managerprofile' ||
      contentType === 'coachprofile')
      ? true
      : false;
  const location = useLocation();
  const getLoction = () => {
    let { pathname } = location;
    pathname = pathname && pathname.toLowerCase();
    if (pathname.includes('/search')) {
      return getCssUrl(layout.SEARCH_LAYOUT, MU.language);
    } else if (pathname.includes('news/detail/') || pathname.includes('printproduct/detail/')) {
      if (!isApp()) {
        return getCssUrl(layout.ARTICLE_DETAIL, MU.language);
      } else {
        return getCssUrl(layout.HYBRID_ARTICLE, MU.language);
      }
    } else if (pathname.includes('players-and-staff/detail/') || isProfileType) {
      return getCssUrl(layout.PLAYER_PROFILE_LAYOUT, MU.language);
    } else if (pathname.includes('myunited')) {
      return getCssUrl(layout.MYUNITED_LAYOUT, MU.language);
    } else if (pathname.includes('events/liveevents') || pathname.includes('Events/LiveEvents/')) {
      return getCssUrl(layout.NON_MATCHDAY_LAYOUT, MU.language);
    } else {
      if (!isApp()) {
        return getCssUrl(layout.ARTICLE_DETAIL, MU.language);
      } else {
        return getCssUrl(layout.HYBRID_ARTICLE, MU.language);
      }
    }
  };
  const expeditor = isExperienceEditorActive() ? 'expeditor' : '';
  const fileName = getLoction();
  const portraitImage =
    route &&
    route.fields &&
    route.fields.Image &&
    route.fields.Image.value &&
    route.fields.Image.value.Crops &&
    route.fields.Image.value.Crops.portrait;

  const imgpathSmall = portraitImage ? `https:${buildImgUrl(portraitImage)}` : '';
  const name = route && route.fields && route.fields.Name && route.fields.Name.value;
  const headerFields =
    route &&
    route.placeholders &&
    route.placeholders['mufc-ph-header'] &&
    route.placeholders['mufc-ph-header'][0] &&
    route.placeholders['mufc-ph-header'][0].fields;

  const imageValuesPreviewSmall =
    headerFields && headerFields.Logo && headerFields.Logo.value && headerFields.Logo.value.Preview;

  const imageValuesPreview =
    headerFields &&
    headerFields.ScrollingLogoImage &&
    headerFields.ScrollingLogoImage.value &&
    headerFields.ScrollingLogoImage.value.Preview;

  const isDFPScript =
    route && route.fields && route.fields.DFPScript && route.fields.DFPScript.value;

  return (
    <React.Fragment>
      <Helmet>
        <style type="text/css">{`.dfp-ads__sponsor img { max-height: 32px !important; }`}</style>
        <style>
          {`.icon-mu-logo-cms,.icon-mu-logo-cms-small.mobile{background-image:url(${imageValuesPreview})}`}
        </style>
        <style>{`.icon-mu-logo-cms-small{background-image:url(${imageValuesPreviewSmall})}`}</style>
        <meta name="format-detection" content="telephone=no" />
        {isExperienceEditorActive() && (
          <link href={getCssUrl(layout.EDITOR_LAYOUT, MU.language)} rel="stylesheet" />
        )}
        {isApp() && imgpathSmall && imgpathSmall.length > 0 && (
          <link href={imgpathSmall} rel="preload" as="image" />
        )}
        {(typeof window === 'undefined' || process.env.NODE_ENV === 'development') &&
          (isApp() ? (
            <link rel="stylesheet" type="text/css" href={fileName} />
          ) : (
            <link href={fileName} rel="stylesheet" />
          ))}
      </Helmet>
      {!isApp() &&
      isEqual(
        route &&
          route.fields &&
          route.fields.Media &&
          route.fields.Media.value &&
          route.fields.Media.value.toLowerCase(),
        APP_ONLY
      ) ? (
        <AppOnly data={context && context.AppOnly} />
      ) : (
        <div
          className={`page-wrap ${expeditor} ${MU.reverseTheme} ${MU.gapClass}`}
          style={{ backgroundColor: '#2b2b2b' }}
        >
          {!isApp() && <SkipToContent />}
          {!isApp() && (
            <Placeholder
              missingComponentComponent={() => {
                return null;
              }}
              name="mufc-ph-header"
              rendering={route}
            />
          )}
          <section>
            <article>
              <div className="main-container" id="content">
                {name &&
                  !(
                    isEqual(name.toLowerCase(), ScreenNames.OLD_TRAFFORD.toLowerCase()) ||
                    isEqual(
                      name.toLowerCase(),
                      ScreenNames.TICKETS_AND_HOSPITALITY.toLowerCase()
                    ) ||
                    isEqual(name.toLowerCase(), ScreenNames.TERMS_CONDITIONS.toLowerCase()) ||
                    isEqual(name.toLowerCase(), ScreenNames.PRIVACY_POLICY.toLowerCase()) ||
                    isEqual(name.toLowerCase(), ScreenNames.COOKIE_POLICY.toLowerCase())
                  ) && <h1 className="screenreader">{name}</h1>}
                <Placeholder name="mufc-ph-body" rendering={route} isDFPScript={isDFPScript} />
                {isExperienceEditorActive() && (
                  <div>
                    <b>
                      Section Footer Component
                      <br />
                    </b>
                  </div>
                )}
                <div>
                  {!isApp() && (
                    <Placeholder
                      missingComponentComponent={() => {
                        return null;
                      }}
                      name="mufc-ph-footercontainer"
                      rendering={route}
                    />
                  )}
                </div>
              </div>
            </article>
          </section>
        </div>
      )}
    </React.Fragment>
  );
};

export default NewsDetailLayout;
