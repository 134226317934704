/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

export const VIDEO_LISTING = `all/web/list/video/`;
export const VIDEO_RECOMMENDATION = `/all/web/list/videorec/`;
export const NEWS_LISTING = `all/web/list/news/`;
export const MATCH_CENTER = `/all/web/list/matchday/`;
export const MATCH_STATS = `/all/web/list/alternatescreenweb/`;
export const NON_MATCHDAY = `/all/web/list/liveevent/`;
export const SEARCH_RESULT = `/all/web/search/`;
export const SEARCH_SUGGESTION = `/all/web/suggest/all/all`;
export const COLLECTION = `/all/web/list/collection/`;
export const GET_COMPETITIONS = '/all/web/campaigngetsync';
export const GET_COMPETITION_ITEM = `all/web/list/getitem/`;
export const SAVE_COMPETITION = '/all/web/campaignPostSync';
export const GET_COMINGUP_CAROUSEL = 'all/web/list/getcomingupcarousel/Screens%2F';
export const PLAYER_GRID = `/all/web/list/playergrid/`;
export const WOMEN_PLAYER = `/all/web/list/playergrid/Team%20Level%2FWomen%20Team`;
export const FAV_PLAYOUR = `/all/web/list/playerprofile/`;
export const CHANGE_EMAIL = `/all/all/web/changeemail`;
export const SPOTLIGHT = `/all/web/list/spotlight`;
export const IMAGE_GALLERY = `/all/web/list/getitem/`;
export const POLL = `/all/web/getpollresult`;
export const SAVE_POLL = `/all/web/pollsubmitv2`;
export const POLL_DETAIL = `/all/web/list/getitem/`;
export const POLL_VOTE = `ERROR_VIDEOS`;
export const PLAYER_LIVE_STATS = `/all/web/playerlivestats/`;
export const PLAYER_SEASON_STATS = `/all/web/list/playerSeasonStats/`;
export const QUIZ = `/all/web/list/quiz/`;
export const MYUNITED_PREDICTION = `/all/app/getpredictionstats/`;
export const SPONSOR = `all/web/webconfig/config`;
export const LIVESTREAM = `/all/web/list/livestream/`;
export const LIVESTREAMVIAEPG = `/all/web/list/livestream`;
export const UNITEDCONFIG = `/all/web/list/getunitednowconfigurations`;
export const SET_USER_PROFILE = `/all/app/setuserprofile`;
/**
 * Not Required GEO for these endpoints
 */
export const MATCHES = `/all/web/list/matches`;
export const MATCH_RESULT = `/all/web/list/matchresult`;
export const MATCH_FIXTURE = `/all/web/list/matchfixture`;
export const TABLES = `/all/web/list/table`;
export const SEND_EMAIL = '/api/sitecore/SendEmail/TravelFormUserData';

/**
 * Musc Pages
 */
export const TICKET_ALLOCATION = `en/in/all/web/matchticketproxy/AllocationTicket/mufcNumber/`;
export const GET_PREVIOUS_ORDER = `/en/in/all/web/matchticketproxy/GetPreviousOrder/mufcNumber/`;
export const GET_ORDER_DETAIL = `/en/in/all/web/matchticketproxy/GetOrderDetails/ordernumber/`;
export const GET_USER_LIST = `/en/in/all/web/matchticketproxy/GetListOfUsers/mufcNumber/`;
export const ADD_USER_REQUEST = `/en/in/all/web/matchticketproxy/EditUserDetails/`;
export const TICKET_DETAILCONFIRMATION = `/en/in/all/web/matchticketproxy/DetailsConfirmation`;

export const GIGYAENDPOINT = {
  ACTIVATE: `/en/all/all/web/activate/all/`,
  PARENTCONSENTDETAILS: `/en/all/all/web/parentconsentdetails`,
  SETPARENTCONSENT: `/en/all/all/web/setparentconsent`,
  SETEMAILVERIFICATION: `/en/all/all/web/setemailverification`,
  VERIFYONFORGOTPWD: `/en/in/all/web/setaccountverification`,
  PREFERENCECENTRE: `/en/in/all/web/preferencecentre`,
};

export const SEARCH_REPORT_PROXY = `/en/all/all/web/searchreportproxy`;
export const PERSIST_REPORT_PROXY = `/en/all/all/web/persistreportproxy`;

export const SEND_EMAIL_TO_USER = `/api/sitecore/GenericMail/SendEmailToUser`;
export const SEND_FAN_FEEDBACK = `/api/sitecore/FansRepresentatives/SendFanFeedBack`;

/**
 * MUTV endpoints
 */
export const GET_CAROUSEL = `all/web/list/getcarousel/itemid:`;
export const GET_CONTINUE_CAROUSEL = 'all/web/contentgetsync/uid:';
export const GET_SINGLE_CARD_CONTINUE_CAROUSEL = `all/web/list/getpodcastepisode/sid:`;
export const POST_MYLIST = `all/web/contentPostSync`;
export const GET_ITEM = `all/web/list/getitem/`;
export const GET_PODCAST_EPISODE = `all/web/list/getpodcastepisode/sid:`;
export const GET_UNITED_BENEFITS = `/all/web/list/unitedbenefits/myunited`;
export const GET_MUTV_HEROCONTAINER = '/all/web/list/getmutvherocontainer';
export const GET_EPG = 'all/app/list/getepgschedule/weekly';
export const GET_EPG_RECODEDVIDEO = '/all/web/list/getitem/';
export const GET_UNITED_BENEFITS_MUTV = `/all/web/list/unitedbenefits/mutv`;
export const SUBSCRIBE_ENCRYPTION = `/all/web/webpayments`;
//ENCRYPT endpoints
export const ENCRYPT_KEY = 'all/web/getpublickey';

//Device Management
export const DEVICE_SYNC = `all/web/devicesync`;
