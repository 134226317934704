/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';

const ComingSoon = (props) => (
  <section className="coming-bg">
    <section className="row">
      <div className="coming-txt">
        <img
          src="/assets/dam-images/under-construction-icon-green.png"
          className="img-responsive"
          alt="under construction"
        />
      </div>
    </section>
  </section>
);

export default ComingSoon;
