/**
 *  © 2022-2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import Loader from '../Common/Loader';
import { getParameterByName } from '../../utils/utils';
import { createBaseUrl } from '../../utils/idmUtils';

const SVG = () => {
  const error = getParameterByName('error', true);
  if (error && error === 'login_required') {
    window.location = createBaseUrl();
  }
  return (
    <>
      {isExperienceEditorActive() && (
        <div>
          <b>
            SVG Components <br />
          </b>
        </div>
      )}
      <div
        className="svg_container"
        style={{
          background: '#1A1A1A',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh',
        }}
      >
        <div className="svg_content">
          <Loader className="svg_loader-container" />
          {/* <p>Confirming Details</p> */}
        </div>
      </div>
    </>
  );
};

export default SVG;
