/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import MU from '../../../constants/muConstants';

const AMPFont = () => {
  const loadAMPFonts = () => {
    let ampFonts = <></>;
    try {
      if (MU && MU.language === 'zh') {
        ampFonts = (
          <React.Fragment>
            <amp-font
              layout="nodisplay"
              font-family="NotoSansSC-Bold"
              timeout="2000"
              on-error-remove-class="comicamp-loading"
              on-error-add-class="comicamp-missing"
              on-load-remove-class="comicamp-loading"
              on-load-add-class="comicamp-loaded"
            ></amp-font>
            <amp-font
              layout="nodisplay"
              font-family="NotoSansSC-Regular"
              timeout="2000"
              on-error-remove-class="comicamp-loading"
              on-error-add-class="comicamp-missing"
              on-load-remove-class="comicamp-loading"
              on-load-add-class="comicamp-loaded"
            ></amp-font>
            <amp-font
              layout="nodisplay"
              font-family="NotoSansSC-Thin"
              timeout="2000"
              on-error-remove-class="comicamp-loading"
              on-error-add-class="comicamp-missing"
              on-load-remove-class="comicamp-loading"
              on-load-add-class="comicamp-loaded"
            ></amp-font>
          </React.Fragment>
        );
      } else if (MU && MU.language === 'ja') {
        ampFonts = (
          <React.Fragment>
            <amp-font
              layout="nodisplay"
              font-family="NotoSansJP-Bold"
              timeout="2000"
              on-error-remove-class="comicamp-loading"
              on-error-add-class="comicamp-missing"
              on-load-remove-class="comicamp-loading"
              on-load-add-class="comicamp-loaded"
            ></amp-font>
            <amp-font
              layout="nodisplay"
              font-family="NotoSansJP-Regular"
              timeout="2000"
              on-error-remove-class="comicamp-loading"
              on-error-add-class="comicamp-missing"
              on-load-remove-class="comicamp-loading"
              on-load-add-class="comicamp-loaded"
            ></amp-font>
            <amp-font
              layout="nodisplay"
              font-family="NotoSansJP-Thin"
              timeout="2000"
              on-error-remove-class="comicamp-loading"
              on-error-add-class="comicamp-missing"
              on-load-remove-class="comicamp-loading"
              on-load-add-class="comicamp-loaded"
            ></amp-font>
          </React.Fragment>
        );
      } else if (MU && MU.language === 'ko') {
        ampFonts = (
          <React.Fragment>
            <amp-font
              layout="nodisplay"
              font-family="KoPubDotum-Bold"
              timeout="2000"
              on-error-remove-class="comicamp-loading"
              on-error-add-class="comicamp-missing"
              on-load-remove-class="comicamp-loading"
              on-load-add-class="comicamp-loaded"
            ></amp-font>
            <amp-font
              layout="nodisplay"
              font-family="KoPubDotum-Medium"
              timeout="2000"
              on-error-remove-class="comicamp-loading"
              on-error-add-class="comicamp-missing"
              on-load-remove-class="comicamp-loading"
              on-load-add-class="comicamp-loaded"
            ></amp-font>
            <amp-font
              layout="nodisplay"
              font-family="KoPubDotum-Light"
              timeout="2000"
              on-error-remove-class="comicamp-loading"
              on-error-add-class="comicamp-missing"
              on-load-remove-class="comicamp-loading"
              on-load-add-class="comicamp-loaded"
            ></amp-font>
          </React.Fragment>
        );
      } else if (MU && MU.language === 'ar') {
        ampFonts = (
          <React.Fragment>
            <amp-font
              layout="nodisplay"
              font-family="ChangaBold"
              timeout="2000"
              on-error-remove-class="comicamp-loading"
              on-error-add-class="comicamp-missing"
              on-load-remove-class="comicamp-loading"
              on-load-add-class="comicamp-loaded"
            ></amp-font>
            <amp-font
              layout="nodisplay"
              font-family="ChangaRegular"
              timeout="2000"
              on-error-remove-class="comicamp-loading"
              on-error-add-class="comicamp-missing"
              on-load-remove-class="comicamp-loading"
              on-load-add-class="comicamp-loaded"
            ></amp-font>
            <amp-font
              layout="nodisplay"
              font-family="ChangaLight"
              timeout="2000"
              on-error-remove-class="comicamp-loading"
              on-error-add-class="comicamp-missing"
              on-load-remove-class="comicamp-loading"
              on-load-add-class="comicamp-loaded"
            ></amp-font>
          </React.Fragment>
        );
      }
    } catch (e) {
      console.log('error in loadAMPFonts', e);
      ampFonts = <React.Fragment></React.Fragment>;
    }
    return ampFonts;
  };
  return loadAMPFonts();
};

export default AMPFont;
