/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import DatePicker from 'react-date-picker';

export const setName = (itemName) => {
  if (itemName && itemName.includes(' ')) {
    itemName = itemName.replace(/ /g, '');
  }
  if (itemName && itemName.includes('-')) {
    itemName = itemName.replace(/-/g, '');
  }
  return itemName;
};

export const getValue = (formObject, name) => {
  let a;
  if (formObject && name && formObject[name]) {
    a = formObject[name];
  }
  return a ? a : false;
};

export const InputText = ({
  item,
  className,
  onChangeFunction,
  requireLabel,
  formError,
  initial,
  validation,
}) => {
  let showError = !initial && getValue(formError, setName(item && item.Name));
  return (
    <React.Fragment>
      <div className="elemContainer">
        <input
          type="text"
          className={`formElem inputText ${className ? className : ''} ${showError && 'is_error'}`}
          aria-label={item && item.Title}
          aria-invalid={showError ? true : false}
          aria-required={item.True}
          // placeholder={item && item.Title}
          name={setName(item && item.Name)}
          data-validation-object={JSON.stringify(validation)}
          onChange={(e) => onChangeFunction(e, item, validation)}
        />
        {requireLabel
          ? showError && (
              <label htmlFor={setName(item.Name)} role="alert" className="genericErrormsg">
                {showError}
              </label>
            )
          : showError && (
              <>
                <span className="red-cross-icon"></span>
                <span role="alert" className="formError">
                  {showError}
                </span>
              </>
            )}
      </div>
    </React.Fragment>
  );
};

export const TextareaField = ({
  item,
  onChangeFunction,
  requireLabel,
  validation,
  formError,
  initial,
}) => {
  let showError = !initial && getValue(formError, setName(item && item.Name));
  return (
    <React.Fragment>
      <textarea
        className={`formElem inputText inputTextArea ${showError && 'is_error'}`}
        aria-label={item && item.Title}
        aria-invalid={showError ? true : false}
        aria-required={item.True}
        placeholder={item && item.Title}
        name={setName(item && item.Name)}
        data-validation-object={JSON.stringify(validation)}
        onChange={(e) => onChangeFunction(e, item, validation)}
      ></textarea>
      {requireLabel
        ? showError && (
            <label htmlFor={setName(item.Name)} role="alert" className="genericErrormsg">
              {showError}
            </label>
          )
        : showError && (
            <>
              <span className="red-cross-icon"></span>
              <span role="alert" className="formError">
                {showError}
              </span>
            </>
          )}
    </React.Fragment>
  );
};

export const InputEmail = ({
  item,
  className,
  onChangeFunction,
  requireLabel,
  formError,
  initial,
  validation,
}) => {
  let showError = !initial && getValue(formError, setName(item && item.Name));
  return (
    <React.Fragment>
      <div className="elemContainer">
        <input
          type="email"
          className={`formElem inputText emailBox ${className ? className : ''} ${
            showError && 'is_error'
          }`}
          aria-label={item && item.Title}
          aria-invalid={showError ? true : false}
          aria-required={item.True}
          //placeholder={item && item.Title}
          name={setName(item && item.Name)}
          data-validation-object={JSON.stringify(validation)}
          onChange={(e) => onChangeFunction(e, item, validation)}
        />
        {requireLabel
          ? showError && (
              <label htmlFor={setName(item.Name)} role="alert" className="genericErrormsg">
                {showError}
              </label>
            )
          : showError && (
              <>
                <span className="red-cross-icon"></span>
                <span role="alert" className="formError">
                  {showError}
                </span>
              </>
            )}
      </div>
    </React.Fragment>
  );
};

export const InputPassword = ({
  item,
  initial,
  formError,
  className,
  onChangeFunction,
  requireLabel,
  validation,
}) => {
  let showError = !initial && getValue(formError, setName(item && item.Name));
  return (
    <React.Fragment>
      <div className="elemContainer">
        <input
          type="password"
          className={`formElem inputText passBox ${className ? className : ''} ${
            showError && 'is_error'
          }`}
          aria-label={item && item.Title}
          aria-invalid={showError ? true : false}
          aria-required={item.True}
          //placeholder={item && item.Title}
          name={setName(item && item.Name)}
          data-validation-object={JSON.stringify(validation)}
          onChange={(e) => onChangeFunction(e, item, validation)}
        />
        {requireLabel
          ? showError && (
              <label htmlFor={setName(item.Name)} role="alert" className="genericErrormsg">
                {showError}
              </label>
            )
          : showError && (
              <>
                <span className="red-cross-icon"></span>
                <span role="alert" className="formError">
                  {showError}
                </span>
              </>
            )}
      </div>
    </React.Fragment>
  );
};
export const InputCheckbox = ({
  item,
  onChangeFunction,
  validation,
  initial,
  formError,
  mainItem,
}) => {
  let showError = !initial && getValue(formError, setName(item && item.Name));
  return (
    <React.Fragment>
      <input
        type="checkbox"
        className={`formElem inputCheckBox`}
        aria-required={mainItem.True}
        aria-invalid={showError ? true : false}
        id={item && item.Name}
        aria-label={item && item.Value}
        value={item && item.Value}
        name={setName(item && item.Name)}
        data-validation-object={JSON.stringify(validation)}
        onChange={(e) => onChangeFunction(e, mainItem, validation)}
      />
      <label htmlFor={item.Name}>
        <span>{item.Name}</span>
      </label>
      {showError && (
        <span role="alert" className="formError">
          {showError}
        </span>
      )}
    </React.Fragment>
  );
};

export const InputRadio = ({
  item,
  radioId,
  onChangeFunction,
  validation,
  dataVal,
  formDetails,
  nameRadio,
  showError,
}) => {
  let value = getValue(formDetails, setName(nameRadio));
  return (
    <input
      type="radio"
      className={`formElem inputRadio`}
      aria-invalid={showError ? true : false}
      id={radioId}
      aria-label={dataVal && dataVal.Value}
      aria-required={item.True}
      value={dataVal && dataVal.Value}
      name={setName(nameRadio)}
      onChange={(e) => onChangeFunction(e, item, validation)}
      data-validation-object={JSON.stringify(validation)}
      data-radio-checked={dataVal && dataVal.Value === value}
    />
  );
};
export const InputDatePicker = ({
  item,
  className,
  onChangeFunction,
  requireLabel,
  validation,
  initial,
  formError,
  formDetails,
}) => {
  let DatePickerTitle = item.Title + '(DD.MM.YYYY)    ';
  let showError = !initial && getValue(formError, setName(item && item.Name));
  let value = getValue(formDetails, setName(item && item.Name));
  const target =
    typeof window !== 'undefined' &&
    document.querySelector(`div[name=${setName(item && item.Name)}]`);
  if (target) {
    datePickerAccessibility(target, 'onLoad', value, item.True, showError);
  }
  return (
    <React.Fragment>
      <div
        className="genericform-datepicker"
        type="genericform-datepicker"
        name={setName(item && item.Name)}
        data-validation-object={JSON.stringify(validation)}
        onKeyDown={(e) => {
          const keyValue = e.keyCode || e.which;
          if (keyValue && keyValue == 27 && target) {
            datePickerAccessibility(target, 'onChange', 'escape');
          } else if (keyValue && keyValue == 13 && target) {
            datePickerAccessibility(target, 'onChange', 'enter');
          } else if (keyValue && !(keyValue == 9 || (keyValue == 9 && e.shiftKey))) {
            e.preventDefault();
          }
        }}
      >
        <DatePicker
          className={`inputStyle generic_input ${className ? className : ''}`}
          name={setName(item && item.Name)}
          dayPlaceholder={`${DatePickerTitle}  `}
          yearPlaceholder={''}
          monthPlaceholder={''}
          onChange={(e) =>
            onChangeFunction(e, setName(item && item.Name), validation, 'DatePicker')
          }
          value={value}
          calendarIcon={<span className="icon-caldendar"></span>}
          format={'dd.MM.yyyy'}
          type="text"
          clearAriaLabel="Close"
          calendarAriaLabel="Calendar"
          prevAriaLabel="Previous Month"
          nextAriaLabel="Next Month"
          prev2Label={null}
          next2Label={null}
          showNeighboringMonth={false}
          {...datePickerProps(value)}
        />
      </div>
      {requireLabel
        ? showError && (
            <label htmlFor={setName(item.Name)} role="alert" className="genericErrormsg">
              {showError}
            </label>
          )
        : showError && (
            <span role="alert" className="genericErrormsg">
              {showError}
            </span>
          )}
    </React.Fragment>
  );
};

export const datePickerProps = (value) => {
  if (!value) {
    return { clearIcon: null };
  }
};

export const datePickerAccessibility = (target, type, value, isRequired, isInvalid) => {
  if (type && type == 'onChange' && value) {
    const a = target.querySelector('.react-date-picker__calendar-button.react-date-picker__button');
    const b = target.querySelector('.react-date-picker__calendar--open');
    const c = target.querySelector('.react-date-picker__calendar--closed');
    if (a && b && value == 'escape') {
      a.click();
    } else if (a && c && value == 'enter') {
      a.click();
    }
  } else if (type && type == 'onLoad') {
    const a = target.querySelectorAll('.react-date-picker__inputGroup__divider');
    const b = target.querySelector('input[type=date]');
    if (b) {
      b.setAttribute('aria-required', isRequired);
      b.setAttribute('aria-invalid', isInvalid ? true : false);
    }
    if (!value) {
      a &&
        a.forEach((b) => {
          b.style.display = 'none';
        });
      const b = target.querySelector('.react-date-picker__inputGroup__month');
      const c = target.querySelector('.react-date-picker__inputGroup__year');
      const d = target.querySelector(
        '.react-date-picker__calendar-button.react-date-picker__button'
      );
      b && b.setAttribute('tabindex', '-1');
      c && c.setAttribute('tabindex', '-1');
      d && d.setAttribute('tabindex', '-1');
    } else {
      a &&
        a.forEach((b) => {
          b.removeAttribute('style');
        });
    }
  }
};

export const setInputTextData = (newData) => {
  return {
    Title: newData && newData.Title && newData.Title.value,
    Name: newData && newData.manutds && newData.manutds.value && newData.manutds.value.__Name,
    True: newData && newData.Required && newData.Required.value,
    DataRequiredMessage:
      newData && newData.DataRequiredMessage && newData.DataRequiredMessage.value,
    AlphaNumericCheckBox:
      newData && newData.AlphaNumericCheckBox && newData.AlphaNumericCheckBox.value,
    MinNumber: newData && newData.MinNumber && newData.MinNumber.value,
    AlphaNumericMessage:
      newData && newData.AlphaNumericMessage && newData.AlphaNumericMessage.value,
    MinNumberMessage:
      newData && newData.MinNumberErrorMessage && newData.MinNumberErrorMessage.value,
    MaxNumber: newData && newData.MaxNumber && newData.MaxNumber.value,
    MaxNumberMessage:
      newData && newData.MaxNumberErrorMessage && newData.MaxNumberErrorMessage.value,
    NumericValidationMessage:
      newData && newData.NumericValidationMessage && newData.NumericValidationMessage.value,
    DateErrorMessage: newData && newData.DateErrorMessage && newData.DateErrorMessage.value,
    MonthErrorMessage: newData && newData.MonthErrorMessage && newData.MonthErrorMessage.value,
    YearErrorMessage: newData && newData.YearErrorMessage && newData.YearErrorMessage.value,
  };
};

export const monthArr = [
  { name: 'Jan', value: 'Jan' },
  { name: 'Feb', value: 'Feb' },
  { name: 'Mar', value: 'Mar' },
  { name: 'Apr', value: 'Apr' },
  { name: 'May', value: 'May' },
  { name: 'Jun', value: 'Jun' },
  { name: 'Jul', value: 'Jul' },
  { name: 'Aug', value: 'Aug' },
  { name: 'Sep', value: 'Sep' },
  { name: 'Oct', value: 'Oct' },
  { name: 'Nov', value: 'Nov' },
  { name: 'Dec', value: 'Dec' },
];

export const renderDate = (t, isMultiLang) => {
  const dateArr = [];
  if (isMultiLang) {
    dateArr.push(
      <option value="" selected disabled="disabled" aria-label={t('Date')} key="0">
        {t('Date')}
      </option>
    );
  } else {
    dateArr.push(
      <option value="" selected disabled="disabled" aria-label="date" key="0">
        Date
      </option>
    );
  }
  for (let i = 1; i <= 31; i++) {
    dateArr.push(
      <option value={i} aria-label={i} key={i}>
        {i}
      </option>
    );
  }
  return dateArr;
};

export const renderYear = (t, isMultiLang) => {
  const d = new Date();
  const year = d.getFullYear();
  const yearArr = [];
  if (isMultiLang) {
    yearArr.push(
      <option value="" selected disabled="disabled" aria-label={t('Year')} key="101">
        {t('Year')}
      </option>
    );
  } else {
    yearArr.push(
      <option value="" selected disabled="disabled" aria-label="year" key="101">
        Year
      </option>
    );
  }
  if (year) {
    for (let i = 0; i <= 100; i++) {
      yearArr.push(
        <option value={year - i} aria-label={year - i} key={i}>
          {year - i}
        </option>
      );
    }
  }

  return yearArr;
};

export const formSerialize = (formId) => {
  let formArr = document.querySelector(formId);
  if (formArr) {
    let data = new FormData(formArr);
    let formData = {};
    for (let [key, value] of data) {
      formData[key] = value;
    }
    return formData;
  }
  return {};
};

export const validateEmail = (elementValue) => {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(elementValue);
};

export const getCurrentDate = () => {
  const currentdate = new Date();
  return `${currentdate.getDate()}-${
    currentdate.getMonth() + 1
  }-${currentdate.getFullYear()} ${currentdate.getHours()}:${currentdate.getMinutes()}:${currentdate.getSeconds()}`;
};
export const getDate = (date) => {
  let splitDate = date && date.split('-');
  if (splitDate && splitDate.length > 0) {
    return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
  } else {
    return date;
  }
};

export const getEmailData = (emailObj) => {
  return {
    First: emailObj.Forename,
    Surname: emailObj.Surname,
    Email: emailObj.Email,
    Postcode: emailObj.Postcode,
    Nationality: emailObj.Nationality,
    DOB: `${emailObj.DOB} 00:00:00`,
    PassportNumber: emailObj.PassportNumber,
    TravellingBy: emailObj.TravellingBy,
    ArrivalPoint: emailObj.ArrivalPoint,
    TravellingFrom: emailObj.TravellingFrom,
    CityTown: emailObj.CityTown,
    DepartureDate: emailObj.DepartureDate,
    Hotel: emailObj.Hotel,
    FlightNumber: emailObj.FlightNumber,
  };
};

export const getLoaderHtml = () => {
  return (
    <div className="loader-container tmpl-loader">
      <div className="mu-pageloader">
        <div className="loader-logo"></div>
        <div className="loader"></div>
      </div>
    </div>
  );
};
export const numericPattern = new RegExp(/^[0-9\b]+$/);
export const alphaNumericPattern = new RegExp(/^[a-z0-9]+$/i);

export const DataRequiredValidation = (value, item) => {
  return !value && item ? item.DataRequiredMessage : false;
};
export const NumericValidation = (value, item) => {
  return value && !numericPattern.test(value) ? item.NumericValidationMessage : false;
};
export const MinLengthValidation = (value, item) => {
  return value && item && item.MinNumber && value.length < item.MinNumber
    ? item.MinNumberMessage
    : false;
};
export const MaxLengthValidation = (value, item) => {
  return value && item && item.MaxNumber && value.length > item.MaxNumber
    ? item.MaxNumberMessage
    : false;
};
export const EmailValidation = (value, item) => {
  return value && !validateEmail(value) ? item.DataRequiredMessage : false;
};
export const AlphaNumericValidation = (value, item) => {
  return value && !alphaNumericPattern.test(value) ? item.AlphaNumericMessage : false;
};

export const getUrlId = () => {
  let url = window && window.location && window.location.href;
  let splitUrl = url && url.split('id=');
  return splitUrl && splitUrl.length > 1 && splitUrl[1];
};

export const getCarParkingData = () => {
  return formSerialize('#car_parking');
};

export const refreshPage = () => {
  typeof window !== 'undefined' && window.location.reload();
};
