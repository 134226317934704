/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useRef, useState } from 'react';
import { DARK_THEME, FILTER } from '../../constants/globalConstants';
import { createDestinationUrl } from '../../utils/utils';
import Sponsor from '../Common/Sponsor';
import * as track from '../../utils/analytics';
import Sticky from '../../utils/sticky';

const PlayerGridFilter = ({ filters, lastSegment, language }) => {
  const fltWidth = useRef(null);
  const [newWidth, setNewWidth] = useState(null);

  useEffect(() => {
    if (fltWidth && filters && typeof window !== 'undefined' && window.innerWidth < 767) {
      setFilterWidth();
    }
  }, []);

  const setFilterWidth = () => {
    let width = 10;
    for (let i = 0; i < filters.length; i++) {
      const element = fltWidth.current && fltWidth.current.childNodes[i];
      if (element) {
        const style = element.currentStyle || window.getComputedStyle(element);
        width = width + element.clientWidth + parseFloat(style.marginRight) + 25;
      }
    }
    setNewWidth(width);
  };

  const activeFilter = (destinationurl_s) => {
    if (destinationurl_s === `${language}/players-and-staff/${lastSegment}`) {
      return 'active';
    } else {
      return '';
    }
  };

  const trackLink = (label, linkUrl, e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: label,
        destination_url: linkUrl,
      },
      e
    );
  };

  const moduleName = `players-and-staff#${lastSegment}`;

  return (
    <Sticky>
      <section className="team-grid">
        <div className="filter__right-tab" style={{ display: 'none' }}></div>
        <div className="team-grid__filter">
          <div className="container">
            <div className="grid-12">
              <div className="filter__right-tab">
                <Sponsor type={FILTER} moduleName={moduleName} theme={DARK_THEME} />
              </div>
              <div className="team-grid__filter-tab">
                <nav aria-label="Team Grid">
                  <ul ref={fltWidth} style={{ width: newWidth }}>
                    {filters &&
                      filters.map((filtername) => {
                        return (
                          filtername.label_t && (
                            <li>
                              <a
                                href={createDestinationUrl(filtername.destinationurl_s)}
                                className={activeFilter(filtername.destinationurl_s)}
                                onClick={(e) =>
                                  trackLink(
                                    filtername.label_t,
                                    createDestinationUrl(filtername.destinationurl_s),
                                    e
                                  )
                                }
                              >
                                {filtername.label_t}
                              </a>
                            </li>
                          )
                        );
                      })}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Sticky>
  );
};

export default PlayerGridFilter;
