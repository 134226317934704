/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import Transform from '../../data-transform';
import { mapCuratedCard } from './DataMapping';
import NavSpotlight from '../Common/matchesCard/NavSpotlight';
import { MEGA_NAV_ } from '../../constants/containerConstants';
import ErrorBoundary from '../Common/ErrorBoundary';
import { useSponsorValue } from '../../context/sponsorContext';
import MutvCards from '../Mutv/Cards/MutvCards';
const MutvCardWrapper = (props) => {
  const [
    { isGigyaAvilable, isUserLogin, userLoginDetail, isUserSubscribe, isUserSkyId },
    dispatch,
  ] = useSponsorValue();
  const { data, index, isAutomated } = props;
  let isRelative;
  if (isAutomated) {
    const celumimagesvariant_s = { ImageCropUrl: { CropUrl: data.imagecropurl_s } };
    data['celumimagesvariant_s'] = celumimagesvariant_s;
    isRelative = data.sponsordetailinfo_s && !isAutomated;
  } else if (!isAutomated) {
    try {
      data.sponsordetailinfo_s =
        typeof data.sponsordetailinfo_s === 'string'
          ? JSON.parse(data.sponsordetailinfo_s)
          : data.sponsordetailinfo_s;
      isRelative = data.sponsordetailinfo_s && !isAutomated;
    } catch (e) {
      console.error('Error in getting the sponso detail info');
    }
  }

  return (
    <MutvCards
      data={data}
      key={index}
      item={data}
      isUserLogin={isUserLogin}
      isGigyaAvilable={isGigyaAvilable}
      userLoginDetail={userLoginDetail}
      isUserSkyId={isUserSkyId}
      dispatch={dispatch}
      gridClass="grid-4"
      navCards={true}
      isRelative={isRelative}
      isUserSubscribe={isUserSubscribe}
    />
  );
};

const ContextualCards = ({ cards, navconstant }) => {
  const supportedAutometedCard = [
    'video',
    'article',
    'collection',
    'printproduct',
    'quiz',
    'unitedcompetition',
  ];
  const [dateTbc, setDateTbc] = useState(false);
  const curatedCard = () => {
    let isMyunited = false;
    if (cards && navconstant) {
      isMyunited = cards.Id === navconstant.MyUnitedItemId ? true : false;
    }
    const navCards = { isMyunited: isMyunited, isMagaNavCard: true };
    const data = mapCuratedCard(cards);
    return (
      data &&
      data.length > 0 &&
      data.map((obj, key) => {
        navCards.target = obj && obj.target;
        const isRelative = obj.sponsordetailinfo_s && !cards.IsAutomated;
        return obj.contenttype_t === 'video' && !obj.isNormalVideo_b ? (
          <MutvCardWrapper
            key={key}
            data={obj}
            gridClass="grid-4"
            navCards={navCards}
            isAutomated={false}
          />
        ) : (
          <Transform
            key={key}
            data={obj}
            source="solr"
            gridClass="grid-4"
            navCards={navCards}
            container={`${MEGA_NAV_}${cards && cards.ItemName}`}
            isRelative={isRelative}
          />
        );
      })
    );
  };
  /**
   * Responsible for render automated cards
   */
  const automatedCard = () => {
    if (navconstant && cards) {
      if (cards.NewsContextualCards && navconstant.newsItemId === cards.Id) {
        return renderAutomatedCards(cards.NewsContextualCards, 'news');
      }
      if (cards.VideosContextualCards && navconstant.VideosItemId === cards.Id) {
        return renderAutomatedCards(cards.VideosContextualCards, 'video');
      }
      if (cards.MatchesContextualCards && navconstant.MatchesItemId === cards.Id) {
        return (
          <ErrorBoundary>
            <NavSpotlight
              matchData={cards.MatchesContextualCards}
              liveMatchData={cards.LiveCardContextualCards}
              isDateTbc={isDateTbc}
            ></NavSpotlight>
          </ErrorBoundary>
        );
      }
      if (cards.PlayersContextualCards && navconstant.PlayersItemId === cards.Id) {
        return renderAutomatedCards(cards.PlayersContextualCards, 'playerprofile');
      }
      if (
        cards.ShopContextualCard &&
        (navconstant.ShopItemIdROW === cards.Id || navconstant.ShopItemIdUS === cards.Id)
      ) {
        return renderAutomatedCards(cards.ShopContextualCard, 'kitbag');
      }
      if (cards.MUTVContextualCards) {
        return renderAutomatedCards(cards.MUTVContextualCards, 'mutv');
      }
    }
  };
  /**
   *
   * @param {boolean to call callback to add classname} isTbc
   */
  const isDateTbc = (isTbc) => {
    isTbc && setDateTbc(true);
  };

  /**
   *
   * @param {data of each contextual cards} cardsData
   * @param {type of card ex: video/playerprofile...} cardType
   */
  const renderAutomatedCards = (cardsData, cardType) => {
    if (cardsData && cardsData.length) {
      const navCards = { isMagaNavCard: true };
      cardsData.length = cardsData.length > 3 ? 3 : cardsData.length;
      let data = [];
      if (cardType === 'news') {
        data = redersupportedCards(cardsData);
      } else if (
        cardType === 'video' ||
        cardType === 'playerprofile' ||
        cardType === 'kitbag' ||
        cardType === 'mutv'
      ) {
        data = cardsData;
      }
      return (
        data &&
        data.map((obj, key) => {
          if (cardType === 'mutv') {
            obj.ismegaNav = true;
            return (
              <MutvCardWrapper
                key={key}
                data={obj}
                gridClass="grid-4"
                navCards={navCards}
                isAutomated={true}
              />
            );
          } else {
            if (cardType === 'kitbag') {
              obj.contenttype_t = 'shop';
              obj.ismegaNav = true;
            }
            return (
              <Transform
                key={key}
                data={obj}
                source="aws"
                gridClass="grid-4"
                navCards={navCards}
                container={`${MEGA_NAV_}${cards && cards.ItemName}`}
                isRelative={data.sponsordetailinfo_s && !cards.IsAutomated}
              />
            );
          }
        })
      );
    }
  };
  /**
   *
   * @param {all card data. responsible for return only supported contenttype } card
   */
  const redersupportedCards = (card) => {
    return card.filter(
      (val) => val && val.contenttype_t && supportedAutometedCard.includes(val.contenttype_t)
    );
  };

  return (
    <div
      className={`home-header__cards ${dateTbc ? 'tbc-card' : ''}`}
      data-container={cards && cards.Name ? `mega-nav-${cards.Name}` : 'mega-nav'}
    >
      <div role="heading" aria-level="2" className="home-header__cards--heading" tabIndex="0">
        {cards.CardHeading}
      </div>
      {cards && cards.IsAutomated ? automatedCard() : curatedCard()}
    </div>
  );
};

export default withTranslation()(React.memo(ContextualCards));
