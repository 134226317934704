/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import ErrorBoundary from '../../Common/ErrorBoundary';
import PricePackContainer from './PricePackContainer';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import moment from 'moment';
import {
  checkDevice,
  handleTouchStart,
  swiperProgress,
  swiperTouchStart,
  swiperSetTransition,
  defaultSwiperAnimTime,
} from '../../../utils/utils';
const PricePackBanner = (props) => {
  const { fields, t, rendering } = props;
  const [currentActiveSlide, setCurrentActiveSlide] = useState(0);
  const [isTouchDevice, setIsTouchDevice] = useState(true);
  useEffect(() => {
    setIsTouchDevice(checkDevice());
  }, []);

  const additionalHeroPricePacksArray = props?.fields?.AdditionalHeroPricePacks || [];
  const pricePackBannerFieldsArray = [
    fields,
    ...additionalHeroPricePacksArray.map((item) => item.fields),
  ];
  const PricePack = fields?.PricePack;

  const isSwiperEnableAnimation = fields?.EnableAnimation?.value;

  const sliderPlaySpeed = () => {
    if (
      isSwiperEnableAnimation &&
      pricePackBannerFieldsArray &&
      Array.isArray(pricePackBannerFieldsArray)
    ) {
      let bgVdSlideAnim, bgImgSlideAnim;
      const bgVdSlideDuration =
        pricePackBannerFieldsArray[currentActiveSlide]?.HeroVideoBackground?.value?.duration ||
        pricePackBannerFieldsArray[currentActiveSlide]?.HeroVideoBackground?.value?.Duration;
      const bgImgSlideDuration = fields?.ScrollDuration?.value;
      if (bgVdSlideDuration) {
        bgVdSlideAnim = moment.duration(bgVdSlideDuration).asMilliseconds();
      }
      if (bgImgSlideDuration) {
        const parsedDuration = parseInt(bgImgSlideDuration);
        if (!isNaN(parsedDuration)) {
          bgImgSlideAnim = parsedDuration * 1000; // Convert seconds to milliseconds
        }
      }
      return bgVdSlideAnim
        ? bgVdSlideAnim
        : bgImgSlideAnim
        ? bgImgSlideAnim
        : defaultSwiperAnimTime;
    }
  };

  const renderCarouselSlides = () => {
    try {
      if (props && props.fields && props.fields.ContentType) {
        const contentType = props.fields.ContentType.value.toLowerCase();
        if (contentType === 'pricepackbanner') {
          if (pricePackBannerFieldsArray && Array.isArray(pricePackBannerFieldsArray)) {
            if (pricePackBannerFieldsArray.length > 0) {
              return (
                <ErrorBoundary>
                  <div className="container">
                    <div className="carousal">
                      <Swiper
                        slidesPerView={1}
                        autoplay={{
                          enabled: isSwiperEnableAnimation,
                          delay: sliderPlaySpeed(),
                          disableOnInteraction: false,
                        }}
                        speed={1000}
                        watchSlidesProgress={true}
                        navigation={!isTouchDevice}
                        allowTouchMove={isTouchDevice ? true : false}
                        pagination={{ clickable: true }}
                        modules={[Navigation, Pagination, Autoplay]}
                        onActiveIndexChange={(swiper) => setCurrentActiveSlide(swiper?.activeIndex)}
                        onProgress={(swiper) => swiperProgress(swiper)}
                        onTouchStart={(swiper) => swiperTouchStart(swiper)}
                        onSetTransition={(swiper) => swiperSetTransition(swiper)}
                        onTouchMove={(Swiper) => handleTouchStart(Swiper)}
                      >
                        {pricePackBannerFieldsArray.map((item, index) => {
                          return (
                            <SwiperSlide key={index}>
                              <div className="slide-inner">
                                <div className="bg_vd__gradient"></div>
                                <PricePackContainer
                                  key={index}
                                  itemID={index}
                                  currentActiveSlide={currentActiveSlide}
                                  fields={item}
                                  rendering={rendering}
                                  heroCarousel={true}
                                  PricePack={PricePack}
                                  t={t}
                                />
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                  </div>
                </ErrorBoundary>
              );
            }
          }
        }
      }
    } catch (e) {
      console.log('Error in fetching pricepack banner', e);
    }
    // If none of the conditions are met, render MutvHeroContent directly
    return <PricePackContainer fields={fields} rendering={rendering} PricePack={PricePack} t={t} />;
  };

  return (
    <React.Fragment>
      <>
        <ErrorBoundary>
          <div className="hero_contentBox">
            {additionalHeroPricePacksArray && renderCarouselSlides()}
          </div>
        </ErrorBoundary>
      </>
    </React.Fragment>
  );
};

export default withTranslation()(PricePackBanner);
