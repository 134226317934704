/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import Slider from 'react-slick';
import { THEMED_CAROUSEL } from '../../constants/containerConstants';
import {
  CONTENT_TYPE,
  HomePageHeroCarouselVarientDark,
  HomePageHeroCarouselVarientLight,
  SUPPORTED_STATS_CONTENT_TYPE,
} from '../../constants/globalConstants';
import Transform from '../../data-transform';
import { checkDevice } from '../../utils/utils';
import ImgCrop from '../Common/ImgCrop';
import Sponsor from '../Common/Sponsor';
import Stats from '../Stats';
import * as track from './../../utils/analytics';
import ErrorBoundary from '../Common/ErrorBoundary';
import { slideShowCount } from '../../utils/carouselUtils';
const ThemedCarouselAutomation = (props) => {
  const [isTouchDevice, setIsTouchDevice] = useState(true);
  const ctaUrl = null;
  useEffect(() => {
    setIsTouchDevice(checkDevice());
  }, []);
  const value =
    props.fields && props.fields.carouselResponse && props.fields.carouselResponse.value;
  const cardData =
    props.fields &&
    props.fields.carousel &&
    props.fields.carousel.value &&
    props.fields.carousel.value.documentsFromSolr &&
    props.fields.carousel.value.documentsFromSolr.response &&
    props.fields.carousel.value.documentsFromSolr.response.docs;
  const trackLink = (ctaTitle, linkUrl, e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: ctaTitle,
        destination_url: linkUrl,
      },
      e
    );
  };
  const varientClass = () => {
    if (value && value.Skin && value.Skin === HomePageHeroCarouselVarientLight) {
      return `carousal-${HomePageHeroCarouselVarientLight.toLowerCase()}`;
    } else {
      return `carousal-${HomePageHeroCarouselVarientDark.toLowerCase()}`;
    }
  };
  const bgnCTA = () => {
    if (value && value.Image && !value.CTATitle && !ctaUrl) {
      return 'bg-ncta';
    } else {
      return '';
    }
  };
  const isCurated = () => {
    if (isExperienceEditorActive() && value && !value.IsAuto) {
      return 'curated';
    } else {
      return '';
    }
  };
  const firstcardCss = () => {
    if (value && value.Image && value.CTATitle && ctaUrl) {
      return 'first-slide';
    } else {
      return '';
    }
  };
  const ctaClass = () => {
    if (!ctaUrl) {
      return '';
    } else {
      return 'bg-cta';
    }
  };
  const renderExpEditior = () => {
    if (value && value.IsAuto) {
      return (
        <div className="carousal__info">
          <b>
            Slider Auto Mode
            <br />
          </b>
        </div>
      );
    }
  };
  const mobileHeading = () => {
    // if (value && value.Image) {
    return 'bg-image';
    // } else {
    //   return '';
    // }
  };
  const carousalDestination = (value, props, className) => {
    return (
      <React.Fragment>
        {value && value.IsMore && (
          <a
            href={value.RedirectUrl && value.RedirectUrl}
            onClick={(e) => trackLink(value.DisplayMoreTitle, value.RedirectUrl, e)}
            className={`carousal-destination ${className ? className : ''}`}
            tabIndex="0"
          >
            <span aria-hidden="true">{value.DisplayMoreTitle && value.DisplayMoreTitle}</span>
            <span className="screenreader">{props.t('ClickToSeeMoreContent')}</span>
          </a>
        )}
      </React.Fragment>
    );
  };
  const renderTitle = () => {
    if (ctaUrl && value && value.CTATitle) {
      return (
        <div className="infowrapper">
          <div className="carousal-width-new">
            <div className="carousal-head">
              <div className="carousal__info">
                <div className="carousal_title_link">
                  <h2 className="carousal__title">
                    <span>{value.Title && value.Title}</span>
                    {carousalDestination(value, props, 'desktopvisible')}
                    <div className="carousal-destination">
                      <div className="link-btn-white">
                        <a
                          href={ctaUrl}
                          tabIndex="0"
                          onClick={(e) => trackLink(value.CTATitle, ctaUrl, e)}
                        >
                          <span>{value.CTATitle}</span>
                          <i className="icon-follow-live"></i>
                          <span className="screenreader">{props.t('ClickToSeeMoreContent')}</span>
                        </a>
                      </div>
                    </div>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="carousal-width-new">
          <div className="carousal-head">
            <div className="carousal__info">
              <div className="carousal_title_link">
                <h2 className="carousal__title">
                  {value && value.Title && value.Title}
                  {carousalDestination(value, props, 'desktopvisible')}
                </h2>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  const renderSponsor = () => {
    return value.Sponsorby && Object.keys(value.Sponsorby).length > 0 ? (
      <Sponsor
        contentType="Hero Carousel"
        varient=""
        sponsorInfo={value.Sponsorby && [value.Sponsorby]}
        grid="grid-3"
        type={CONTENT_TYPE}
        container={THEMED_CAROUSEL}
        itemId={props.itemId && props.itemId}
        isRelative={true}
      />
    ) : null;
  };
  const lastCardLink = () => {
    if (value && value.SelectedTagName) {
      const displayKeyValue = value.SelectedTagName.split(':');
      if (displayKeyValue && displayKeyValue.length > 0 && displayKeyValue.length == 2) {
        if (displayKeyValue[0] && displayKeyValue[1]) {
          let text = displayKeyValue[0].trim();
          let link = displayKeyValue[1].trim();
          return (
            <div className="link-btn-white" aria-label="morecard information">
              <a href={`/search?q=${link}`} role="button" className="link">
                <span>{text}</span>
                <i className="icon-cta-arrow"></i>
              </a>
            </div>
          );
        }
      }
    }
  };
  const renderLastCard = () => {
    return (
      <div>
        <div className="grid-3 ratio item">
          <div className="mu-content">
            <article className="mu-item last-card">
              {renderSponsor()}
              <div className="mu-item__info">
                <h2 className="mu-item__title">
                  {props.t('SeeMoreOn')}
                  <span className="screenreader">More</span>
                </h2>
                {lastCardLink()}
              </div>
            </article>
          </div>
        </div>
      </div>
    );
  };

  const renderCarouselSlides = () => {
    return (
      <React.Fragment>
        {!isTouchDevice && isCurated() !== 'curated' && (
          <Slider {...props.settings}>
            {cardData.map((item, i) => renderFromSolr(item, i))}
            {renderLastCard()}
          </Slider>
        )}
        {(isTouchDevice || isCurated() === 'curated') && (
          <div className="carousal-wrapper">
            <div className={`carousal-list custom-mobile-slider`}>
              {cardData.map((item, i) => (
                <div key={i} className="mobile-listitem" role="listitem">
                  {renderFromSolr(item, i)}
                </div>
              ))}
              <div className="mobile-listitem" role="listitem">
                {renderLastCard()}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  const renderFromSolr = (item, i) => {
    if (
      item &&
      item.contenttype_t &&
      SUPPORTED_STATS_CONTENT_TYPE.includes(item.contenttype_t.toLowerCase())
    ) {
      return <Stats item={item} isAws={true} isCarousel={true} />;
    } else {
      return (
        <Transform
          container={THEMED_CAROUSEL}
          key={i}
          data={item}
          source="solr"
          gridClass="grid-3"
          isCarousel={true}
          nativeCard={true}
        />
      );
    }
  };

  return (
    <ErrorBoundary>
      <div
        className={`carousal ${varientClass()} hero-carousal__sponsor background-content ${bgnCTA()} ${isCurated()}`}
      >
        {value && value.Image && (
          <div className={firstcardCss()} tabIndex="-1">
            <div className={`carousal-cover__wrapper ${ctaClass()}`}>
              <LazyLoad once={true} offset={100}>
                <ImgCrop
                  className={'adaptive carousal-cover'}
                  imgCrop={JSON.parse(value.Image)}
                  alt={value.AltText}
                  title={value.AltText}
                  isWebpAvailable={value.isWebpAvailable}
                  onErrorClass="no-img"
                />
              </LazyLoad>
              {renderSponsor()}
            </div>
          </div>
        )}
        {isExperienceEditorActive() && renderExpEditior()}
        <div
          className={`container ${isTouchDevice ? 'custom-container' : ''} ${
            props.slidesCount() <= slideShowCount() ? 'adjust' : ''
          }`}
        >
          {renderTitle()}
          <div className="carousal-width">
            <div className="carousal_title_link kitbag mobile">
              {value && value.Title && (
                <h2 className={`carousal__skin-title ${mobileHeading()}`}>
                  <span>{value.Title}</span> {carousalDestination(value, props)}
                </h2>
              )}
              {!(value && value.Title) && (
                <h2 className={`carousal__skin-title ${mobileHeading()}`}>
                  {carousalDestination(value, props)}
                </h2>
              )}
            </div>

            {value && value.IsAuto && cardData && cardData.length > 0 && renderCarouselSlides()}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
export default withTranslation()(ThemedCarouselAutomation);
