/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  chartPercentageValue,
  popluteCircle,
  chartColor,
  chartIndex,
} from '../../utils/playerProfileStats';
const SeasonsGoal = ({ t, item, statsType }) => {
  const buildSeasonGoalData = (item) => {
    let graphData = {};
    graphData.noValue = [false, false];
    graphData.circleData = [[], []];
    let seasonStatsItems = item && item.stats;
    if (seasonStatsItems && seasonStatsItems.length > 0) {
      for (let i = 0; i < seasonStatsItems.length; i++) {
        let statsItem = seasonStatsItems[i];
        statsItem.statsValue = statsItem.statsValue == '' ? 0 : statsItem.statsValue;
        if (statsItem && statsItem.statsKey == 'stats_GoalsfromInsideBox') {
          graphData.goalsInsideBoxKey = statsItem.statsKey;
          graphData.goalsInsideBoxValue = statsItem.statsValue;
        } else if (statsItem && statsItem.statsKey == 'stats_GoalsfromOutsideBox') {
          graphData.goalsOutsideBoxKey = statsItem.statsKey;
          graphData.goalsOutsideBoxValue = statsItem.statsValue;
        } else if (statsItem && statsItem.statsKey == 'stats_LeftFootgoals') {
          graphData.leftFootGoalsKey = statsItem.statsKey;
          graphData.leftFootGoalsValue = statsItem.statsValue;
        } else if (statsItem && statsItem.statsKey == 'stats_RightFootGoals') {
          graphData.rightFootGoalsKey = statsItem.statsKey;
          graphData.rightFootGoalsValue = statsItem.statsValue;
        } else if (statsItem && statsItem.statsKey == 'stats_Goals') {
          graphData.totalGoalsKey = statsItem.statsKey;
          graphData.totalGoalsValue = statsItem.statsValue;
        }
      }
    }
    //Calculating Percentage Value from service Values
    graphData = {
      ...graphData,
      ...chartPercentageValue(
        item,
        graphData,
        graphData.goalsInsideBoxValue,
        graphData.goalsOutsideBoxValue,
        graphData.rightFootGoalsValue,
        graphData.leftFootGoalsValue
      ),
    };
    //Populating First Chart
    if (graphData.goalsInsideBoxValue != undefined && graphData.goalsOutsideBoxValue != undefined) {
      graphData = {
        ...graphData,
        ...popluteCircle(
          graphData,
          graphData.goalsInsideBoxPerValue,
          graphData.goalsOutsideBoxPerValue,
          chartColor[0],
          chartColor[1],
          chartIndex[0]
        ),
      };
    }
    //Populating Second Chart
    if (graphData.rightFootGoalsValue != undefined && graphData.leftFootGoalsValue != undefined) {
      graphData = {
        ...graphData,
        ...popluteCircle(
          graphData,
          graphData.rightFootGoalsPerValue,
          graphData.leftFootGoalsPerValue,
          chartColor[2],
          chartColor[3],
          chartIndex[1]
        ),
      };
    }
    return graphData;
  };
  const [graphData, setGraphData] = useState(null);
  useEffect(() => {
    setGraphData(buildSeasonGoalData(item));
  }, [item]);
  return (
    graphData && (
      <div className="chart-wrapper no-width">
        <h3>{t('stats_Goals')}</h3>
        <div className="season-stats-goals">
          <div className="stats-wrapper">
            <div className="stats-goal-score">
              <span aria-hidden="true">{graphData.totalGoalsValue}</span>
              <span>
                {t('stats_Goals')}
                <span className="screenreader">{graphData.totalGoalsValue}</span>
              </span>
            </div>
            <ul className="goals-passing-charts" aria-hidden="true">
              <li>
                <svg width="124" height="106" viewBox="2 0 42 37">
                  {!graphData.noValue[0] && (
                    <circle
                      className="donut-ring"
                      cx="23"
                      cy="18"
                      r="15.8"
                      fill="transparent"
                      stroke={graphData.circleData[0][0].fillcolor}
                      strokeWidth="1.5"
                      strokeDasharray={`${graphData.circleData[0][0].CdashArrayValue1} ${graphData.circleData[0][0].CdashArrayValue2}`}
                      strokeDashoffset={graphData.circleData[0][0].offsetValue}
                    ></circle>
                  )}
                  {graphData.goalsInsideBoxValue > 0 && (
                    <text x="34" y="36">
                      {graphData.goalsInsideBoxValue}
                    </text>
                  )}
                  {!graphData.noValue[0] && (
                    <circle
                      className="donut-ring"
                      cx="23"
                      cy="18"
                      r="15.8"
                      fill="transparent"
                      stroke={graphData.circleData[0][1].fillcolor}
                      strokeWidth="1.5"
                      strokeDasharray={`${graphData.circleData[0][1].CdashArrayValue1} ${graphData.circleData[0][1].CdashArrayValue2}`}
                      strokeDashoffset={graphData.circleData[0][1].offsetValue}
                    ></circle>
                  )}
                  {graphData.goalsOutsideBoxValue > 0 && (
                    <text x="2" y="4">
                      {graphData.goalsOutsideBoxValue}
                    </text>
                  )}
                  {graphData.noValue[0] && (
                    <circle
                      className="donut-ring"
                      cx="23"
                      cy="18"
                      r="15.8"
                      fill="transparent"
                      stroke="#7b7b7b"
                      strokeWidth="1"
                      strokeDasharray="100 0"
                      strokeDashoffset="33"
                    ></circle>
                  )}
                </svg>
              </li>
              <li>
                <svg width="124" height="106" viewBox="2 0 42 37">
                  {!graphData.noValue[1] && (
                    <circle
                      className="donut-ring"
                      cx="23"
                      cy="18"
                      r="15.8"
                      fill="transparent"
                      stroke={graphData.circleData[1][0].fillcolor}
                      strokeWidth="1.5"
                      strokeDasharray={`${graphData.circleData[1][0].CdashArrayValue1} ${graphData.circleData[1][0].CdashArrayValue2}`}
                      strokeDashoffset={graphData.circleData[1][0].offsetValue}
                    ></circle>
                  )}
                  {graphData.rightFootGoalsValue > 0 && (
                    <text x="34" y="36">
                      {graphData.rightFootGoalsValue}
                    </text>
                  )}
                  {!graphData.noValue[1] && (
                    <circle
                      className="donut-ring"
                      cx="23"
                      cy="18"
                      r="15.8"
                      fill="transparent"
                      stroke={graphData.circleData[1][1].fillcolor}
                      strokeWidth="1.5"
                      strokeDasharray={`${graphData.circleData[1][1].CdashArrayValue1} ${graphData.circleData[1][1].CdashArrayValue2}`}
                      strokeDashoffset={graphData.circleData[1][1].offsetValue}
                    ></circle>
                  )}
                  {graphData.leftFootGoalsValue > 0 && (
                    <text x="2" y="4">
                      {graphData.leftFootGoalsValue}
                    </text>
                  )}
                  {graphData.noValue[1] && (
                    <circle
                      className="donut-ring"
                      cx="23"
                      cy="18"
                      r="15.8"
                      fill="transparent"
                      stroke="#7b7b7b"
                      strokeWidth="1"
                      strokeDasharray="100 0"
                      strokeDashoffset="33"
                    ></circle>
                  )}
                </svg>
              </li>
            </ul>
          </div>
          <ul className="pass-notations">
            <li>
              <span className="pass-point"></span>
              <span>{t('stats_GoalsfromInsideBox')}</span>
              <span className="screenreader">{graphData.goalsInsideBoxValue}</span>
            </li>
            <li>
              <span className="pass-point"></span>
              <span>{t('stats_GoalsfromOutsideBox')}</span>
              <span className="screenreader">{graphData.goalsOutsideBoxValue}</span>
            </li>
            <li>
              <span className="pass-point"></span>
              <span>{t('stats_RightFootGoals')}</span>
              <span className="screenreader">{graphData.rightFootGoalsValue}</span>
            </li>
            <li>
              <span className="pass-point"></span>
              <span>{t('stats_LeftFootgoals')}</span>
              <span className="screenreader">{graphData.leftFootGoalsValue}</span>
            </li>
          </ul>
        </div>
      </div>
    )
  );
};
export default withTranslation()(SeasonsGoal);
