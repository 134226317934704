/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useEffect, useRef } from 'react';
import { isExperienceEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import MuLogo from './MuLogo';
import { withTranslation } from 'react-i18next';
import Navigation from './Navigation';
import SecondaryNavigation from './SecondaryNavigation';
import Search from './Search';
import PowerdBySponsor from './PowerdBySponsor';
import PrimarySponsors from './PrimarySponsors';
import LanguageSwitcher from './LanguageSwitcher';
import LanguageDropDown from './LanguageDropDown';
import Login from '../Common/Login/Login';
import TertiaryNav from './TertiaryNav';
import HumbergerLinks from './HumbergerLinks';
import MU from '../../constants/muConstants';
import AMPHeader from '../AMPHeader';
import ErrorBoundary from '../Common/ErrorBoundary';
import { createSquizSearchUrl, isTouchScreens } from '../../utils/squizSearchUtils';
let timeoutId1;
let timeoutId2;
let timerSearchOnHover;
const Header = (props) => {
  const { t } = props;
  const {
    Logo,
    AllNav,
    secondaryLevelNavigationConfiguration,
    SearchText,
    SponsorBy,
    Sponsors,
    Language,
    MoreMenu,
    manutds,
    HeaderIT,
    HeaderSponsors,
    SearchCards,
    TertiaryNavigationLinks,
    HamburgerNavigationLinks,
    MyUnitedSettingItemChildrens,
    IsMunich,
  } = props.fields;
  const { pageEditing, route } = props.sitecoreContext;
  const squizData = MU?.squizData;
  const tooltipEnable = squizData?.tooltipEnable;
  const [humbergerClass, setHumberger] = useState({ class: '', close: '', humbergerState: false });
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [showContextualCard, setShowContextualCard] = useState(false);
  const [showSearchToolTip, setShowSearchToolTip] = useState(false);
  const [menuHover, setmenuHover] = useState({ display: 'none', opacity: 0 });
  const [styleHedSearchPannel, setStyleHedSearchPannel] = useState({
    display: 'block',
    visibility: 'hidden',
  });
  const megaHeader = useRef(null);
  const primaryHeader = useRef(null);
  const mainHeader = useRef(null);
  const setContextualCard = useRef(null);
  const isSquizEnable = squizData?.squizGlobalWebsiteEnable;
  const isSearchToolTipVisited =
    typeof Storage !== 'undefined' && localStorage?.getItem('isSearchToolTipVisited') === 'true';
  const isTouchScreenVisitedWeb =
    typeof Storage !== 'undefined' && localStorage?.getItem('isTouchScreenVisitedWeb') === 'true';

  let lastScrollTop = 0;

  const addSticky = (mainHeader, megaHeader, scrollDirection, container, headerHeight) => {
    mainHeader.current.classList.add('sticky');
    megaHeader.current.classList.add('short');
    container.style.marginTop = headerHeight + 'px';
    if (scrollDirection) {
      mainHeader.current.classList.remove('up');
      megaHeader.current.classList.remove('up');
    } else {
      mainHeader.current.classList.add('up');
      megaHeader.current.classList.add('up');
    }
  };
  const onEnterShowSearch = () => {
    if (typeof window !== 'undefined') {
      const visitedURL = window?.location?.href;
      tooltipEnable &&
        typeof Storage !== 'undefined' &&
        localStorage.setItem('isSearchToolTipVisited', true);
      typeof Storage !== 'undefined' && sessionStorage.setItem('searchVisitedURL', visitedURL);
      const redirect = squizData?.squizMuPageUrl;
      window.location.href = createSquizSearchUrl(redirect);
    }
  };
  const setMoreHeight = () => {
    const moreSubMenu = document.querySelector('.home-header__menu-list.more-menu.sub-nav');
    try {
      if (window.innerWidth && window.innerWidth >= 1200) {
        const moreSubTop = moreSubMenu && moreSubMenu.getBoundingClientRect().top;
        if (moreSubTop) {
          moreSubMenu.style.maxHeight = `calc( 100vh - ${moreSubTop}px)`;
        } else {
          moreSubMenu.removeAttribute('style');
        }
      } else {
        moreSubMenu && moreSubMenu.removeAttribute('style');
      }
    } catch (error) {
      moreSubMenu && moreSubMenu.removeAttribute('style');
    }
  };
  const removeSticky = (
    mainHeader,
    megaHeader,
    scrollDirection,
    container,
    headerHeight,
    pageYOffset
  ) => {
    const superheroDfp = document.querySelector('.superhero-dfp');
    const superheroDfpHeight = superheroDfp ? superheroDfp.offsetHeight : 0;
    mainHeader.current.classList.remove('sticky');
    megaHeader.current.classList.remove('short');
    if (pageYOffset <= headerHeight + superheroDfpHeight) {
      container.style.marginTop = null;
    }
    if (scrollDirection) {
    } else {
      mainHeader.current.classList.remove('up');
      megaHeader.current.classList.remove('up');
    }
  };
  const stickyHeader = () => {
    if (
      megaHeader &&
      megaHeader.current &&
      primaryHeader &&
      primaryHeader.current &&
      mainHeader &&
      mainHeader.current
    ) {
      let st = window.pageYOffset || document.documentElement.scrollTop;
      let scrollDirection = st > lastScrollTop;
      lastScrollTop = st <= 0 ? 0 : st;
      let headerHeight = primaryHeader.current.offsetHeight;
      let sticky = headerHeight;
      let superhero = document.querySelector('.superhero');
      let superherobanner = document.querySelector('[data-module=superherobanner]');
      let superheroDfp = document.querySelector('.superhero-dfp');
      let container = document.querySelector('.main-container');
      if (superhero || superherobanner || superheroDfp) {
        sticky = megaHeader.current.offsetTop;
      }
      if (superhero || superherobanner || superheroDfp) {
        if (window.pageYOffset > sticky + headerHeight) {
          addSticky(mainHeader, megaHeader, scrollDirection, container, headerHeight);
        }
        if (window.pageYOffset < sticky) {
          removeSticky(
            mainHeader,
            megaHeader,
            scrollDirection,
            container,
            headerHeight,
            window.pageYOffset
          );
        }
      } else {
        if (window.pageYOffset >= headerHeight) {
          addSticky(mainHeader, megaHeader, scrollDirection, container, headerHeight);
        }
        if (window.pageYOffset === 0) {
          removeSticky(
            mainHeader,
            megaHeader,
            scrollDirection,
            container,
            headerHeight,
            window.pageYOffset
          );
        }
      }
    }
  };
  const setActiveClass = () => {
    return styleHedSearchPannel &&
      styleHedSearchPannel.display === 'block' &&
      !styleHedSearchPannel.visibility
      ? 'active'
      : '';
  };
  const updateOnMouseEnter = (e) => {
    if (!isTouchScreens()) {
      timeoutId1 = setTimeout(() => {
        setStyleHedSearchPannel({ display: 'block' });
      }, 200);
      document.getElementById('newSearchBox').classList.add('active_hover');
      timerSearchOnHover = setTimeout(() => {
        document.getElementById('newSearchBox').classList.remove('active_hover');
      }, 3000);
    }
  };
  const updateOnMouseLeave = (e) => {
    clearTimeout(timerSearchOnHover);
    timeoutId2 = setTimeout(() => {
      setStyleHedSearchPannel({ display: 'none' });
    }, 200);
  };
  useEffect(() => {
    if (isSquizEnable && tooltipEnable && !isTouchScreens()) {
      if (!isSearchToolTipVisited) setShowSearchToolTip(true);
      else setShowSearchToolTip(false);
    }
  }, [isSquizEnable, tooltipEnable, isSearchToolTipVisited]);
  useEffect(() => {
    if (isSquizEnable && tooltipEnable && isTouchScreens() && !isTouchScreenVisitedWeb) {
      setShowSearchToolTip(true);
      setStyleHedSearchPannel({ display: 'block' });
      document.getElementById('newSearchBox').classList.add('active_hover', 'mobileView');
      setTimeout(() => {
        setStyleHedSearchPannel({ display: 'none' });
        typeof Storage !== 'undefined' && localStorage.setItem('isTouchScreenVisitedWeb', true);
      }, 3000);
    }
  }, [isSquizEnable, tooltipEnable, isTouchScreenVisitedWeb]);

  useEffect(() => {
    const expEditor = isExperienceEditorActive() || pageEditing;
    !expEditor && window.addEventListener('scroll', stickyHeader);
    return () => {
      !expEditor && window.removeEventListener('scroll', stickyHeader);
    };
  }, []);
  /**
   * @desc {return al naigation Constants after parse in json}
   */
  const navConstant = () => {
    return manutds && manutds.value && manutds.value.NavigationConstants
      ? JSON.parse(manutds.value.NavigationConstants)
      : {};
  };
  /**
   * @def { responsoble top open and close humbergerMenu}
   * @param {to prevent page refresh. } e
   */
  const hamburgerMenu = (e) => {
    const toggleValue = !humbergerClass.humbergerState;
    e.preventDefault();
    toggleValue
      ? document.getElementById('siteCorePage').classList.add('body-sticky')
      : document.getElementById('siteCorePage').classList.remove('body-sticky');
    if (toggleValue) {
      // Code to scroll DFP HPTO Add
      try {
        const htmlOffset = document.querySelector('html').scrollTop;
        const headerTopValue = document.querySelector('header').offsetTop;
        const scrolledValue = headerTopValue - htmlOffset;
        if (scrolledValue > 0) {
          document.querySelector('html').scroll({
            top: headerTopValue,
            behavior: 'smooth',
          });
        }
      } catch (e) {
        console.log(e);
      }
    }
    setHumberger(
      toggleValue
        ? { class: 'hamburger-menu', close: 'active', humbergerState: true }
        : { class: '', close: '', humbergerState: false }
    );
    setMoreHeight();
  };
  /**
   *  @param {callback function to open submenu in humberger menu } toggleValue
   */
  const onSubMenuClick = (toggleValue) => {
    setShowSubmenu(toggleValue);
  };
  const activeSearchText = () => {
    let sugElm = document.getElementById('search-menu-suggestions');
    let inputElm = document.getElementById('inp');
    if (inputElm) {
      inputElm.value = '';
      setTimeout(() => {
        inputElm.focus();
      }, 0);
    }
    if (sugElm) sugElm.style.display = 'none';
  };
  const onMenuHover = (e, state, search) => {
    if (!setContextualCard || !setContextualCard.current) {
      setContextualCard.current = true;
      setShowContextualCard(true);
    }
    let targetElm;
    if (e && e.target) {
      targetElm =
        e.target.closest('li') && e.target.closest('li').querySelector('.home-header__menu-list');
    }
    if (state) {
      clearTimeout(timeoutId2);
      timeoutId1 = setTimeout(() => {
        setmenuHover({ display: 'block', opacity: 0.6 });
        if (search) {
          activeSearchText();
        }
        if (targetElm) {
          disableScroll(targetElm);
        }
      }, 400);
    } else {
      clearTimeout(timeoutId1);
      timeoutId2 = setTimeout(() => {
        setmenuHover({ display: 'none', opacity: 0 });
        if (targetElm) {
          enableScroll(targetElm);
        }
      }, 400);
    }
    setMoreHeight();
  };
  function preventDefault(e, d) {
    this.scrollTop -= e.wheelDeltaY;
    e.preventDefault();
  }
  /**
   *
   * @param {current elment} elm
   */
  const disableScroll = (elm) => {
    elm.addEventListener('mousewheel', preventDefault);
  };
  /**
   *
   * @param {current elment} elm
   */
  const enableScroll = (elm) => {
    elm.removeEventListener('mousewheel', preventDefault);
  };
  const setSubmenuClass = () => (showSubmenu ? 'clicked-sub-menu-active' : '');
  const getMunichClass = () => IsMunich && IsMunich.value && 'reversetheme';

  if (MU && MU.isAmpPageUrl) {
    return <AMPHeader {...props}></AMPHeader>;
  }
  return (
    <React.Fragment>
      {!pageEditing && <div id="hover-overlay" style={menuHover}></div>}
      <header
        id="mega-header"
        className={`home-header scrolled-header1 ${getMunichClass()} ${humbergerClass.class}`}
        ref={megaHeader}
      >
        <div className="mainHeader" ref={mainHeader}>
          <section className="primary-header" ref={primaryHeader}>
            <div className="primary-header__left">
              <div className="primary-header__wrapper">
                {Language && Language.length > 0 && (
                  <LanguageSwitcher Language={Language}></LanguageSwitcher>
                )}
                <Login isMutv={props.isMutv}></Login>
              </div>
            </div>
            <div className="primary-header-sponsors" data-container="header">
              <ul>
                {(Sponsors || HeaderSponsors) && (
                  <PrimarySponsors
                    sponsors={Sponsors}
                    headerSponsors={HeaderSponsors}
                    isPowerdby={false}
                  ></PrimarySponsors>
                )}
              </ul>
            </div>
          </section>
          <section className="home-header__bottom" id="navigation">
            <div className="fluid-container">
              {Logo && <MuLogo logo={Logo && Logo}></MuLogo>}
              <a
                role="button"
                className={`hamburger home-header__bottom-toggle ${humbergerClass.close}`}
                href="/#"
                aria-label={humbergerClass.close ? 'close menu' : 'menu'}
                onClick={(e) => hamburgerMenu(e)}
              >
                <div className="hamburger-btn">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </a>
              <nav
                className={`first-level-nav ${setSubmenuClass()}`}
                id="scrollmenu"
                role="navigation"
              >
                <div className="nav-content">
                  {TertiaryNavigationLinks && TertiaryNavigationLinks.length > 0 && (
                    <ErrorBoundary>
                      <TertiaryNav
                        tertiaryNav={TertiaryNavigationLinks}
                        ishumberger={true}
                        navconstant={navConstant()}
                      ></TertiaryNav>
                    </ErrorBoundary>
                  )}
                </div>
                {/* 1. nav-content  will came here */}
                {AllNav && (
                  <ErrorBoundary>
                    <Navigation
                      AllNav={AllNav}
                      moreMenu={MoreMenu}
                      more={AllNav.MoreMenuChildrens}
                      myUnitedSettingItemChildrens={MyUnitedSettingItemChildrens}
                      navconstant={navConstant()}
                      onSubMenuClick={onSubMenuClick}
                      onMenuHover={onMenuHover}
                      layoutId={route && route.layoutId}
                      showContextualCard={showContextualCard}
                    ></Navigation>
                  </ErrorBoundary>
                )}
                {/* 2. tertiary-nav  will came here*/}
                {HamburgerNavigationLinks && HamburgerNavigationLinks.length > 0 && (
                  <ul className="nav-small-menu">
                    <ErrorBoundary>
                      <HumbergerLinks
                        humbergerLinks={HamburgerNavigationLinks}
                        navconstant={navConstant()}
                      ></HumbergerLinks>
                    </ErrorBoundary>
                  </ul>
                )}
                {Language && <LanguageDropDown allLanguage={Language}></LanguageDropDown>}
              </nav>
              {secondaryLevelNavigationConfiguration &&
                secondaryLevelNavigationConfiguration.length > 0 && (
                  <SecondaryNavigation
                    shortNavItem={secondaryLevelNavigationConfiguration}
                  ></SecondaryNavigation>
                )}
              <div className="secondary-header__links">
                <ul>
                  {isSquizEnable
                    ? SearchText && (
                        <>
                          <li
                            className={`links__icon-holder squiz_search ${setActiveClass()}`}
                            id="headerSearchIcon"
                            tabIndex="0"
                            role="button"
                            aria-label={SearchText.value}
                            onClick={(e) => onEnterShowSearch(e)}
                            onMouseEnter={(e) => updateOnMouseEnter(e)}
                            onMouseLeave={(e) => updateOnMouseLeave(e)}
                          >
                            <div id="newSearchBox">
                              <span>{SearchText.value}</span>
                              <div className={`search_ico ${showSearchToolTip ? 'pulseAnim' : ''}`}>
                                <i className="icon-home-search"></i>
                              </div>
                              {showSearchToolTip && (
                                <div className={`newSearch_hover`}>
                                  <img
                                    alt=""
                                    src={`${
                                      MU && MU.assetUrlResource
                                    }/assets/images/gif/search_tooltip.gif`}
                                  />
                                  <p>{t('Tryournewandimprovedsearch!')}</p>
                                </div>
                              )}
                            </div>
                          </li>
                        </>
                      )
                    : SearchText && (
                        <Search
                          searchText={SearchText}
                          searchCards={SearchCards}
                          onMenuHover={onMenuHover}
                        />
                      )}
                  {(SponsorBy || HeaderIT) && (
                    <PowerdBySponsor sponsorBy={SponsorBy} headerIT={HeaderIT}></PowerdBySponsor>
                  )}
                </ul>
              </div>
            </div>
          </section>
        </div>
      </header>
    </React.Fragment>
  );
};

export default withSitecoreContext()(withTranslation()(Header));
