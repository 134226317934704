/**
 *  © 2022 -2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import CardFixture from './Cards/CardFixture';
import CardDefault from './Cards/CardDefault';
import CardEvents from './Cards/CardEvents';
import CardPlayerBirthday from './Cards/CardPlayerBirthday';
import CardResult from './Cards/CardResult';
import CardEcommerce from './Cards/CardEcommerce';
import CardUserBirthday from './Cards/CardUserBirthday';
import CardLive from './Cards/CardLive';
import { isLiveByPeriod } from '../../utils/matchUtils';

const ComingUpCard = (props) => {
  const { item, type, t } = props;

  const getTimeSchudle = (matchdate_tdt) => {
    return matchdate_tdt && moment(matchdate_tdt).format('HH:mm');
  };
  const getDateSchudle = (matchdate_tdt) => {
    if (matchdate_tdt) {
      const newDate = moment(matchdate_tdt).format('DD-MM-YY');
      if (moment().format('DD-MM-YY') === newDate) {
        return `${t('Today')}`;
      } else if (moment().add(1, 'days').format('DD-MM-YY') === newDate) {
        return `${t('Tomorrow')}`;
      } else {
        return `${moment(matchdate_tdt).format('ddd')} ${moment(matchdate_tdt).format(
          'DD'
        )} ${moment(matchdate_tdt).format('MMM')}`;
      }
    } else {
      return '';
    }
  };
  const components = {
    iloveunited: CardEvents,
    muevent: CardEvents,
    cupdraw: CardEvents,
    kitlaunchcomingup: CardEcommerce,
    playerprofile: CardPlayerBirthday,
    managerprofile: CardPlayerBirthday,
    userBday: CardUserBirthday,
  };

  const getCardType = (item, type) => {
    let SpecifiCard;
    if (item.contenttype_t === 'matchdata' && isLiveByPeriod(item.resultdata_t.Period)) {
      SpecifiCard = CardLive;
    } else if (item.contenttype_t === 'matchdata' && item.isresultdeclared_b) {
      SpecifiCard = CardResult;
    } else if (item.contenttype_t === 'matchdata' && !item.isresultdeclared_b) {
      SpecifiCard = CardFixture;
    } else {
      SpecifiCard = components[item.contenttype_t];
      if (components[item.contenttype_t] === undefined) {
        SpecifiCard = CardDefault;
      }
    }
    return (
      <SpecifiCard
        item={item}
        getDateSchudle={getDateSchudle}
        getTimeSchudle={getTimeSchudle}
        {...props}
      />
    );
  };

  return (
    <React.Fragment>
      {item && (
        <div className="card-container">
          {item.contenttype_t ? (
            getCardType(item, type)
          ) : (
            <CardDefault
              item={item}
              getDateSchudle={getDateSchudle}
              getTimeSchudle={getTimeSchudle}
              {...props}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(ComingUpCard);
