/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import MU from '../../../constants/muConstants';
import Loader from '../../Common/Loader';
import { redirectTo } from '../../../utils/utils';
import loadable from '@loadable/component';
import ErrorBoundary from '../../Common/ErrorBoundary';
const LiveStreamModal = loadable(() => import('../../Modals/VideoModal/LiveStreamModal'));

const Video360DegreeDetail = (props) => {
  const [showModal, setShowModal] = useState(false);
  const { manutds } = props.fields;
  const language = MU.language ? MU.language : '';
  const itemId = manutds && manutds.value && manutds.value.Id;
  useEffect(() => {
    setShowModal(true);
  }, []);
  return (
    <div className="modal-page live-stream">
      <div className="video-non-des live-card">
        {showModal && (
          <ErrorBoundary>
            <LiveStreamModal
              itemid={`{${itemId}}`}
              fallback={<Loader />}
              showModal={showModal}
              videoData={props.fields}
              onModalClose={() => redirectTo('/', language)}
              is360Video={true}
              isDestinationPage={true}
            />
          </ErrorBoundary>
        )}
      </div>
    </div>
  );
};

export default Video360DegreeDetail;
