/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { Fragment, useEffect, useState } from 'react';
import { isExperienceEditorActive, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
const SignPostHeroContainer = (props) => {
  const [isExpEditor, setIsExpEditor] = useState(false);
  const { pageEditing } = props.sitecoreContext;
  useEffect(() => {
    setIsExpEditor(isExperienceEditorActive());
  }, []);

  return (
    <Fragment>
      {(isExpEditor || pageEditing) && (
        <div>
          <b>
            Sign Post
            <br />
          </b>
        </div>
      )}
      <div className={props.params && props.params.class ? props.params.class : 'grid-12'}>
        <Placeholder
          name="mufc-ph-signpost"
          rendering={props.rendering}
          missingComponentComponent={() => {
            return null;
          }}
        />
      </div>
    </Fragment>
  );
};

export default withSitecoreContext()(SignPostHeroContainer);
