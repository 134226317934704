/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import Menu from './Menu';
import MU from '../../constants/muConstants';
import { ActiveClass } from '../../constants/globalConstants';
import * as track from '../../utils/analytics';

const Navigation = ({
  AllNav,
  more,
  moreMenu,
  navconstant,
  myUnitedSettingItemChildrens,
  onSubMenuClick,
  onMenuHover,
  layoutId,
  showContextualCard,
}) => {
  const [activeMenuIndex, setActiveMenuIndex] = useState(null);
  /**
   * responsible to render more menu parent link
   */
  const renderMoreMenu = (length) => {
    const item = {
      Name: moreMenu && moreMenu.moremenuname,
      ShortName: moreMenu && moreMenu.moremenushortname,
      TargetURL: '/',
      Childrens: more && more.length > 0 ? more : [],
      TargetURLTarget: '_blank',
      IsHidden: moreMenu && moreMenu.isHidden,
      IsMoreMenu: true,
    };
    return (
      item &&
      !item.IsHidden && (
        <Menu
          item={item}
          onSubMenuClick={onSubMenuClick}
          onMenuHover={onMenuHover}
          setActiveMenuIndex={setActiveMenuIndex}
          activeMenuIndex={activeMenuIndex}
          itemIndex={length}
          moreUniqueClass="more__menu"
          moreAlternateHero={AllNav && AllNav.MoreMenuContextualCard}
          showContextualCard={showContextualCard}
        ></Menu>
      )
    );
  };

  /**
   *
   * @param { item is home link data to track link analytics} item
   * @param {clicked event object} e
   */
  const trackLink = (item, e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: item && item.Name,
        destination_url: item && item.TargetURL,
      },
      e
    );
  };

  const isHomeActive = () => {
    if (AllNav.Id && MU.contextItemId && navconstant.SiteStartItemId && MU.contextItemId) {
      return AllNav.Id.toLowerCase() == MU.contextItemId.toLowerCase() ||
        navconstant.SiteStartItemId.toLowerCase() == MU.contextItemId.toLowerCase()
        ? ActiveClass
        : '';
    }
  };
  return (
    <ul className="nav-menu" role="menubar">
      {AllNav && !AllNav.IsHidden && (
        <li className={`list-item-home nav-item ${isHomeActive()}`}>
          <a
            href={AllNav.TargetURL && AllNav.TargetURL.toLowerCase()}
            target={AllNav.TargetURLTarget ? AllNav.TargetURLTarget : ''}
            onClick={(e) => trackLink(AllNav, e)}
          >
            <span className="mobile">{AllNav.ShortName}</span>
          </a>
        </li>
      )}

      {AllNav &&
        !AllNav.IsHidden &&
        AllNav.Childrens &&
        AllNav.Childrens.length > 0 &&
        !AllNav.Childrens.IsHidden &&
        AllNav.Childrens.map((item, index) => (
          <Menu
            key={index}
            itemIndex={index}
            item={item}
            totalLength={AllNav.Childrens.length}
            navconstant={navconstant}
            myUnitedSettingItemChildrens={myUnitedSettingItemChildrens}
            onSubMenuClick={onSubMenuClick}
            onMenuHover={onMenuHover}
            setActiveMenuIndex={setActiveMenuIndex}
            activeMenuIndex={activeMenuIndex}
            layoutId={layoutId}
            showContextualCard={showContextualCard}
          ></Menu>
        ))}
      {renderMoreMenu(AllNav && AllNav.Childrens && AllNav.Childrens.length)}
    </ul>
  );
};

export default withTranslation()(Navigation);
