/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import { createTagList, createDestinationUrl, getLoggedInStatus } from '../../utils/utils';
import { FREE_TO_VIEW, REGISTERED } from '../../constants/globalConstants';
import moment from 'moment';
import MU from '../../constants/muConstants';
import { getPublishedDate } from '../../utils/momentDate';
import { REMAINING_TIME, MAX_ITEM } from '../../constants/mutvConstants';
import i18n from 'i18next';

//for having array for shimmering purpose
export const arr = [1, 2, 3, 4, 5, 6, 7];
export const clipArr = ['clips', 'teaser', 'promo'];

const clipLeadZeroes = (data) => {
  return data.replace(/^0+(\d)|(\d)0+$/gm, '$1$2');
};
//to milisecond to proper Time format
export const msToTime = (duration) => {
  let seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;
  return hours + ':' + minutes + ':' + seconds;
};

export const cardContenttype = ['podcast', 'video', 'article', 'showcarousel', 'schedule'];
export const carouselTypeCheck = ['showofshows', 'showsofshows', 'showsofshow'];
export const calculateSliderHeight = (aspectRatio, contentType, Discover) => {
  if (Discover) {
    return 'three_ratio_four';
  } else if (contentType == 'continuelistening') {
    return 'four_ratio_three';
  } else if (carouselTypeCheck.includes(contentType.toLowerCase())) {
    return 'three_ratio_four';
  } else if (aspectRatio) {
    if (aspectRatio == '1x1') {
      return 'one_ratio_one';
    } else if (aspectRatio == '3x4') {
      return 'three_ratio_four';
    } else if (aspectRatio == '4x3') {
      return 'four_ratio_three';
    }
  } else {
    return 'three_ratio_four';
  }
};

export const rederHeadlineUtil = (item) => {
  if (item.contenttype_t === 'video') {
    return item.shortheadline_t;
  } else if (item.contenttype_t === 'podcast' || item.contenttype_t === 'article') {
    return item.headline_t;
  } else if (item.contenttype_t === 'showcarousel') {
    return item.heading_t;
  }
};
export const bredcrumText = (info, type) => {
  if (type === 'series' && info) {
    if (info.indexOf('SeriesType/NonSeasonal') > -1) {
      const nonSeasonalText = info.find(
        (item) => item && item.toLowerCase().includes('series number/')
      );
      if (nonSeasonalText) {
        return nonSeasonalText.slice(14);
      }
      return null;
    } else if (info.indexOf('SeriesType/Seasonal') > -1) {
      const seasonalText = info.find((item) => item && item.toLowerCase().includes('season/'));
      if (seasonalText) {
        const year = seasonalText.slice(9);
        const firstYear = year && year.slice(0, -2);
        const lastYear = year && year.slice(2);
        return `Season ${firstYear}/${lastYear}`;
      }
      return null;
    }
    return null;
  } else if (type === 'episode' && info) {
    const episodeText = info.find((item) => item && item.toLowerCase().includes('episode/'));
    if (episodeText) {
      return episodeText.slice(8);
    }
    return null;
  }
  return null;
};
export const seriesEpisodeInfoUtil = (info, contentType) => {
  let episode, series, season;
  let summary = '';
  if (contentType !== 'showsofshows') {
    if (info.indexOf('SeriesType/NonSeasonal') > -1) {
      let flag =
        info &&
        info.map((item) => {
          if (item && item.toLowerCase().includes('episode/')) {
            episode = item.slice(8);
            return episode;
          } else if (item && item.toLowerCase().includes('series number/')) {
            series = item.slice(14);
            return series;
          }
          return null;
        });
      //for handling the empty or undefined series, episode and season bug
      flag = flag.filter((ele) => {
        return ele;
      });

      if (flag && flag.length > 0) {
        if (flag.length < 2 && flag.length !== 0) {
          if (flag[0].toLowerCase().includes('episode')) {
            return (summary = summary.concat(`${i18n.t('Series')}-- · ${episode}`));
          } else if (flag[0].toLowerCase().includes('series')) {
            return (summary = summary.concat(`${series} · ${i18n.t('Episode')}--`));
          }
        } else return (summary = summary.concat(`${series} · ${episode}`));
      } else return (summary = summary.concat(`${i18n.t('Series')}-- · ${i18n.t('Episode')}--`));
    } else if (info.indexOf('SeriesType/Seasonal') > -1) {
      let flag =
        info &&
        info.map((item) => {
          if (item && item.toLowerCase().includes('episode/')) {
            episode = item.slice(8);
            return episode;
          } else if (item && item.toLowerCase().includes('season/')) {
            season = item.slice(7);
            season = `${season.substring(0, 4) + '-' + season.substring(4, season.length)}`;
            return season;
          }
        });
      //for handling the empty or undefined series, episode and season bug
      flag = flag.filter((ele) => {
        return ele;
      });
      if (flag && flag.length > 0) {
        if (flag.length < 2 && flag.length !== 0) {
          if (flag[0].toLowerCase().includes('episode')) {
            return (summary = summary.concat(`${i18n.t('SeasonFilter')}-- · ${episode}`));
          } else if (flag[0]) {
            return (summary = summary.concat(`${season} · ${i18n.t('Episode')}--`));
          }
        } else return (summary = summary.concat(`${season} · ${episode}`));
      } else
        return (summary = summary.concat(`${i18n.t('SeasonFilter')}-- · ${i18n.t('Episode')}--`));
      summary = summary.concat(`${season} · ${episode}`);
      return summary;
    }
  } else return null;
};

export const durationUtil = (duration, isContinueCard) => {
  let time = '';
  let fomratedTime = [];
  if (duration.length == 3) {
    duration.map((item, index) => {
      if (index === 0) {
        if (item != '00') {
          fomratedTime.push(clipLeadZeroes(`${item}${i18n.t('h')}`));
        }
      } else if (index === 1) {
        if (item != '00') {
          fomratedTime.push(clipLeadZeroes(`${item}${i18n.t('m')}`));
        }
      } else if (index == 2) {
        if (!isContinueCard) {
          if (duration[0] == '00' && duration[1] == '00') {
            fomratedTime.push(clipLeadZeroes(`${item}${i18n.t('sec')}`));
          }
        } else {
          if (item !== '00') {
            fomratedTime.push(clipLeadZeroes(`${item}${i18n.t('sec')}`));
          }
        }
      }
    });
    fomratedTime.map((item) => {
      time = time.concat(`${item} `);
    });
    return time;
  } else if (duration.length === 2) {
    duration.map((item, index) => {
      if (index === 0) {
        if (item != '00') {
          fomratedTime.push(clipLeadZeroes(`${item}${i18n.t('m')}`));
        }
      } else if (index === 1) {
        if (!isContinueCard) {
          if (duration[0] == '00') {
            fomratedTime.push(clipLeadZeroes(`${item}${i18n.t('sec')}`));
          }
        } else {
          if (item !== '00') {
            fomratedTime.push(clipLeadZeroes(`${item}${i18n.t('sec')}`));
          }
        }
      }
    });
    fomratedTime.map((item) => {
      time = time.concat(`${item} `);
    });
    return time;
  } else if (duration.length === 1) {
    return clipLeadZeroes(duration[0]);
  } else return `--${i18n.t('h')}--${i18n.t('m')}`;
};

//Card data parser function
export const processData = (data) => {
  const grouped = data;
  let seasons = [];
  let lang = [];
  let series = [];
  if (grouped && grouped.seasonnumberunique_s) {
    let groups = grouped.seasonnumberunique_s.groups;
    groups.map((item) => {
      item.doclist.docs.map((docItem) => {
        seasons.push(docItem);
      });
    });
    return seasons;
  } else if (grouped && grouped._language) {
    let groups = grouped._language.groups;
    groups.map((item) => {
      item.doclist.docs.map((docItem) => {
        lang.push(docItem);
      });
    });
    return lang;
  } else if (grouped && grouped.seriesnumberunique_s) {
    let groups = grouped.seriesnumberunique_s.groups;
    groups.map((item) => {
      item.doclist.docs.map((docItem) => {
        series.push(docItem);
      });
    });
    return series;
  } else {
    return grouped;
  }
};

export const mutvslideShowCount = () => {
  let slideCount = 4;
  if (typeof window !== 'undefined' && window.innerWidth <= 567) {
    slideCount = 2;
  } else if (
    typeof window !== 'undefined' &&
    window.innerWidth >= 568 &&
    window.innerWidth <= 767
  ) {
    slideCount = 2;
  } else if (
    typeof window !== 'undefined' &&
    window.innerWidth >= 768 &&
    window.innerWidth <= 1022
  ) {
    slideCount = 3;
  } else if (
    typeof window !== 'undefined' &&
    window.innerWidth >= 1023 &&
    window.innerWidth <= 1279
  ) {
    slideCount = 4;
  } else if (
    typeof window !== 'undefined' &&
    window.innerWidth >= 1280 &&
    window.innerWidth < 1700
  ) {
    slideCount = 4;
  } else {
    slideCount = 5;
  }
  return slideCount;
};

export const podcastTags = (item) => {
  let sid, epId, series;
  item.tags.forEach((data) => {
    if (data && data.toLowerCase().includes('season/')) {
      sid = data.slice(7);
    } else if (data && data.toLowerCase().includes('episode/')) {
      epId = data.slice(8);
    } else if (data && data.toLowerCase().includes('series/')) {
      series = data.slice(7);
    }
  });
  return {
    sid: sid,
    epId: epId,
    series: series,
  };
};

export const individualTag = (e, isTagList) => {
  try {
    let tags;
    if (isTagList) {
      tags = e;
    } else {
      tags = createTagList(e);
    }

    if (tags && !(Object.keys(tags).length === 0)) {
      let tagsArr = {};
      if (typeof tags === 'object' && !tags.length) {
        Object.keys(tags).map((value, index) => {
          let newArr = [];
          let tagVal = tags[value];
          let tagLen = tagVal && tagVal.length;
          if (tagLen > 1) {
            tagVal.map((val, ind) => {
              let tag = val && val.Name;
              tag && newArr.push(tag);
              return '';
            });
          } else {
            let tag = tagVal && tagVal[0] && tagVal[0].Name;
            tag && newArr.push(tag);
          }
          if (newArr) {
            tagsArr[value] =
              newArr && newArr.length > 0
                ? newArr
                    .toString()
                    .replace(/[[\]']/g, '')
                    .replace(/"/g, '')
                    .replace(/,/g, '|')
                : '';
          }
          return '';
        });
      }
      return tagsArr;
    }
  } catch (e) {
    return 'Error in creating the tag list';
  }
};

export const heroDataMapping = (data, carouselContentType, isContinueCard) => {
  try {
    let duration, remainingTime;
    if (carouselContentType === 'continuelistening' || isContinueCard) {
      duration = data.totalDuration;
      remainingTime = data.playPosition;
    }
    switch (data.contenttype_t && data.contenttype_t.toLowerCase()) {
      case 'showcarousel':
        return { ...data, heading: data.heading_t, description: data.description_t };
      case 'podcast': {
        const podcastmetadatadetails =
          data.podcastmetadatadetails_s && typeof data.podcastmetadatadetails_s === 'string'
            ? JSON.parse(data.podcastmetadatadetails_s)
            : data.podcastmetadatadetails_s;
        return {
          ...data,
          heading: data.headline_t,
          description: data.episodedescription_t,
          duration: duration ? duration : podcastmetadatadetails,
          videoFileTranscript: { value: data.videofiletranscript_t },
          remainingTime,
        };
      }
      case 'video':
        return {
          ...data,
          heading: data.shortheadline_t,
          description: data.teaser_t,
          duration: duration ? duration : data.celummetadatadetails_s,
          videoFileTranscript: { value: data.videofiletranscript_t },
          remainingTime,
        };
      case 'article':
        return {
          ...data,
          heading: data.headline_t,
        };
      default:
        return data;
    }
  } catch (e) {
    console.log('error in mapping');
    return {};
  }
};

export const reverseDataMapping = (data) => {
  if (data) {
    return {
      contenttype_t: data.ContentType,
      imagecropurl_s: data.ImageCropUrl ? JSON.parse(data.ImageCropUrl) : '',
      celumimagesvariant_s: data.CelumImagesVariant ? JSON.parse(data.CelumImagesVariant) : '',
      shortheadline_t: data.ShortHeadline,
      teaser_t: data.Teaser,
      episodedescription_t: data.EpisodeDescription,
      headline_t: data.Headline,
      audiofile_t: data.AudioFile,
      taglist_sm: data.TagList ? JSON.parse(data.TagList) : '',
      appearance_tdt: data.AppearanceDate,
      authorname_s: data.AuthorName,
      __published_tdt: data.PublishedDate,
      __publishedby_t: data.PublishedBy,
      itemid_s: data.ItemId,
      contentaccess_t: data.ContentAccess,
      contentlabel_s: data.ContentLabel,
      series_s: data.Series,
      labelexpirytime_tl: data.LabelExpiryTime,
      destinationurl_s: data.DestinationUrl,
      mediaid_s: data.MediaId,
      videofiletranscript_t: data.VideoFileTranscript,
      celummetadatadetails_s: data.CelumMetadataDetails
        ? JSON.parse(data.CelumMetadataDetails)
        : {},
      videofile_t: data.VideoFile,
      sponsordetailinfo_s: [
        {
          MCC_MNC: '',
          PartnerIconLight: data.SponsorDetailInfo && data.SponsorDetailInfo.PartnerIconLight,
          PartnerIconDark: data.SponsorDetailInfo && data.SponsorDetailInfo.PartnerIconDark,
          PartnerText: data.SponsorDetailInfo && data.SponsorDetailInfo.PartnerText,
          CTAURL: data.SponsorDetailInfo && data.SponsorDetailInfo.CTAURL,
          PartnerName: data.SponsorDetailInfo && data.SponsorDetailInfo.PartnerName,
        },
      ],
    };
  }
};

export const getFilters = (seriesType, FacetResponse, sortOrder) => {
  let filters = [];
  let filterObj = {};

  if (seriesType && seriesType === 'none') {
    filters = ['asc', 'desc'];
    filterObj = { asc: 'Oldest', desc: 'Newest' };
    filters = sortOrder === 'desc' ? filters.reverse() : filters;
  } else if (seriesType && seriesType === 'nonseasonal' && FacetResponse) {
    const facet = FacetResponse.seriesnumbercombined_s;
    facet &&
      facet.map((item) => {
        if (typeof item === 'string') {
          let obj = item.split(':');
          filters.push(obj[0]);
          filterObj = { ...filterObj, [obj[0]]: obj[1] };
        }
      });
    filters = filters && getSortedFilter(filters, sortOrder, 'series');
  } else if (seriesType && seriesType === 'seasonal' && FacetResponse) {
    const facet = FacetResponse.seasonstringcombined_s;
    facet &&
      facet.map((item) => {
        if (typeof item === 'string') {
          let obj = item.split(':');
          filters.push(obj[0]);
          filterObj = { ...filterObj, [obj[0]]: obj[1] };
        }
      });
    filters = filters && getSortedFilter(filters, sortOrder, 'season');
  }
  return { filters: filters, filterObj: filterObj };
};

export const getSortedFilter = (filters, sortOrder, type) => {
  if (type === 'series') {
    if (sortOrder && sortOrder === 'desc') {
      filters.sort((a, b) => {
        let ad = a.split(' ');
        let bd = b.split(' ');
        if (parseInt(ad[1]) > parseInt(bd[1])) {
          return -1;
        } else if (parseInt(ad[1]) < parseInt(bd[1])) {
          return 1;
        } else {
          return ad[0].localeCompare(bd[0]);
        }
      });
    } else {
      filters.sort((a, b) => {
        let ad = a.split(' ');
        let bd = b.split(' ');
        if (parseInt(ad[1]) < parseInt(bd[1])) {
          return -1;
        } else if (parseInt(ad[1]) > parseInt(bd[1])) {
          return 1;
        } else {
          return ad[0].localeCompare(bd[0]);
        }
      });
    }
    return filters;
  } else if (type === 'season') {
    if (sortOrder === 'desc') {
      filters.sort((a, b) => {
        return parseInt(b) - parseInt(a);
      });
    } else {
      filters.sort((a, b) => {
        return parseInt(a) - parseInt(b);
      });
    }
    return filters;
  }
};

export const playVideoOrNot = (isUserLogin, contentaccess) => {
  if (
    (isUserLogin && (!contentaccess || [FREE_TO_VIEW, REGISTERED].includes(contentaccess))) ||
    (!isUserLogin && (!contentaccess || [FREE_TO_VIEW].includes(contentaccess)))
  ) {
    return true;
  }
  return false;
};

export const responseObj = () => {
  return {};
};

export const getSchudleTime = (startdatetime_tdt) => {
  return startdatetime_tdt && moment(startdatetime_tdt).format('HH:mm');
};
export const getSchudleDate = (startdatetime_tdt) => {
  return startdatetime_tdt && moment(startdatetime_tdt).format('DD MMM');
};
export const createRecodedUrl = (data) => {
  if (
    data &&
    data.GetItemResponse &&
    data.GetItemResponse.response &&
    data.GetItemResponse.response.docs &&
    data.GetItemResponse.response.docs.length > 0 &&
    data.GetItemResponse.response.docs[0] &&
    data.GetItemResponse.response.docs[0].destinationurl_s
  ) {
    console.log(
      'data.GetItemResponse.response.docs[0].destinationurl_s',
      data.GetItemResponse.response.docs[0].destinationurl_s
    );
    return createDestinationUrl(data.GetItemResponse.response.docs[0].destinationurl_s);
  }
  return null;
};
export const checkFutureLive = (item) => {
  return item.scheduletype_t === 'Live' && moment(item.enddatetime_tdt).isAfter(moment())
    ? true
    : false;
};
export const getLiveSetremId = (apidata) => {
  if (
    apidata &&
    apidata.AudioStreamResponse &&
    apidata.AudioStreamResponse.response &&
    apidata.AudioStreamResponse.response.docs &&
    apidata.AudioStreamResponse.response.docs.length > 0 &&
    apidata.AudioStreamResponse.response.docs[0] &&
    apidata.AudioStreamResponse.response.docs[0].videostreamid_t
  ) {
    return apidata.AudioStreamResponse.response.docs[0].videostreamid_t;
  }
  return null;
};
export const createHeroData = (data, activeIndex) => {
  const heroData = [];
  if (data && data.length > 0) {
    heroData.push({
      startdatetime_tdt: data[activeIndex].startdatetime_tdt,
      itemid_s: data[activeIndex].itemid_s,
      title: data[activeIndex].schedulemetadata_t && data[activeIndex].schedulemetadata_t.Title,
      __published_tdt: data[activeIndex].__published_tdt,
      contentaccess_t: data[activeIndex].contentaccess_t,
      contenttype_t: data[activeIndex].contenttype_t,
      scheduletype_t: data[activeIndex].scheduletype_t,
      schedulemetadata_t: data[activeIndex].schedulemetadata_t,
      taglist_sm: data[activeIndex].taglist_sm,
      description:
        data[activeIndex].schedulemetadata_t && data[activeIndex].schedulemetadata_t.Description,
    });
  }
  if (data && data.length > activeIndex + 1) {
    heroData.push({
      startdatetime_tdt: data[activeIndex + 1].startdatetime_tdt,
      itemid_s: data[activeIndex + 1].itemid_s,
      title:
        data[activeIndex + 1].schedulemetadata_t && data[activeIndex + 1].schedulemetadata_t.Title,
      __published_tdt: data[activeIndex + 1].__published_tdt,
      contentaccess_t: data[activeIndex + 1].contentaccess_t,
      contenttype_t: data[activeIndex + 1].contenttype_t,
      scheduletype_t: data[activeIndex + 1].scheduletype_t,
      taglist_sm: data[activeIndex + 1].taglist_sm,
      description:
        data[activeIndex + 1].schedulemetadata_t &&
        data[activeIndex + 1].schedulemetadata_t.Description,
    });
  }
  return heroData;
};
export const isContentLabelVisible = ({ labelexpirytime_tl, __published_tdt, appearance_tdt }) => {
  try {
    if ((__published_tdt || appearance_tdt) && labelexpirytime_tl > 0) {
      const currentDate = moment();
      const publishedDate = moment(__published_tdt ? __published_tdt : appearance_tdt);
      let expiryDate =
        publishedDate && labelexpirytime_tl > 0
          ? publishedDate.add(labelexpirytime_tl, 'hours')
          : publishedDate;
      if (currentDate && expiryDate && expiryDate.isAfter(currentDate)) {
        // publishedDate + Expiry time <= current date time
        return true;
      } else if (labelexpirytime_tl === -1) {
        // content which will never expire
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (e) {
    console.log('error in isContentLabelVisible', e);
    return false;
  }
};

export const cardTrackData = (
  initialData,
  item,
  destination,
  isLoginChecked,
  isUserLogin,
  isUserSubscribe,
  isUserSkyId
) => {
  const data = {
    ...initialData,
    program_name: item.schedulemetadata_t && item.schedulemetadata_t.Title,
    program_starttime: item.startdatetime_tdt,
    program_endtime: item.enddatetime_tdt,
    item_id: item.itemid_s,
    content_type: item.contenttype_t,
    container_type: `epg-${item.scheduletype_t}`,
    content_status: item.contentaccess_t,
    tag: item.taglist_sm,
    created_datetime: item.__published_tdt,
    card_name: item.schedulemetadata_t && item.schedulemetadata_t.Title,
    subscription_status: getLoggedInStatus({
      isLoginChecked,
      isUserLogin,
      isUserSubscribe,
      isUserSkyId,
    }),
  };
  if (destination) {
    data.destination_url = destination;
  }
  return data;
};

export const deletAndSort = (obj, sortByName) => {
  return (
    obj &&
    obj
      .filter((item) => !item.deletedDT)
      .sort((a, b) => {
        return new Date(b[sortByName]) - new Date(a[sortByName]);
      })
  );
};
export const defaultLocalDataJson = () => {
  const defaultData = getDefaultData();
  const defaultJson = JSON.stringify(defaultData);
  localStorage.setItem('localData', defaultJson);
  return defaultData;
};

export const getDefaultData = (UidGigya) => {
  return {
    ContentHistoryResponse: { ContentHistory: [], UidGigya },
    ContentListResponse: { ContentList: [], UidGigya },
  };
};

export const createAndSaveToSession = (data, tabSelected, item) => {
  let responseData =
    data &&
    data.data &&
    data.data.GetItemResponse &&
    data.data.GetItemResponse.response &&
    data.data.GetItemResponse.response.docs.length > 0 &&
    data.data.GetItemResponse.response.docs[0];
  if (tabSelected === 'contentHistory' && responseData) {
    responseData['totalDuration'] = item.totalDuration;
    responseData['playPosition'] = item.playPosition;
  }
  sessionStorage.setItem(responseData.itemid_s, JSON.stringify(responseData)); //Setting in session
  return responseData && responseData;
};

export const checkHistoryAvailable = (obj) => {
  return obj && obj.ContentHistoryResponse && obj.ContentHistoryResponse.ContentHistory
    ? true
    : false;
};

export const createPostApiData = (idsToDeletes, item) => {
  const arrayToBeDeleted = [];
  if (idsToDeletes.includes(item.ID)) {
    const deleteObject = {};
    deleteObject.ID = item.ID;
    deleteObject.dataSyncDT = item.dataSyncDT;
    deleteObject.deletedDT = getPublishedDate();
    deleteObject.mediaID = item.mediaID;
    deleteObject.myList = false;
    deleteObject.myListDT = item.myListDT;
    deleteObject.parentID = item.parentID;
    deleteObject.publishDT = item.publishDT;
    deleteObject.tags = item.tags;
    deleteObject.typeID = item.typeID;

    arrayToBeDeleted.push(deleteObject); //push each data
  }
  return arrayToBeDeleted;
};
export const getNode = (obj, selected) => {
  return selected === 'contentList'
    ? obj && obj.ContentListResponse && obj.ContentListResponse.ContentList
    : obj && obj.ContentHistoryResponse && obj.ContentHistoryResponse.ContentHistory;
};
export const checkeDeletedDt = (obj) => {
  const historylength = obj.filter((item) => !item.hasOwnProperty('deletedDT'));
  return historylength && historylength.length > 0 ? true : false;
};
export const getLocalData = () => {
  const data = localStorage.getItem('localData');
  return data && JSON.parse(data);
};
export const getAllHistory = (obj) => {
  return obj && obj.ContentHistoryResponse && obj.ContentHistoryResponse.ContentHistory
    ? obj.ContentHistoryResponse.ContentHistory
    : null;
};
export const isLocalDataAvailable = () => {
  let localWatchData =
    localStorage.getItem('localData') && JSON.parse(localStorage.getItem('localData'));
  if (!localWatchData) {
    const defaultJson = JSON.stringify({
      ContentHistoryResponse: { ContentHistory: [] },
      ContentListResponse: { ContentList: [] },
    });
    localStorage.setItem('localData', defaultJson);
    return true;
  }
  return true;
};

export const createWatchData = (heroData, dur, playPos) => {
  const {
    contenttype_t,
    heading,
    taglist_sm,
    __published_tdt,
    itemid_s,
    destinationurl_s,
    imagecropurl_s,
    _parent,
    mediaid_s,
  } = heroData;

  const data = {
    bgImageURL: imagecropurl_s && imagecropurl_s.thumb,
    ID: itemid_s,
    contentURL: destinationurl_s,
    dataSyncDT: getPublishedDate(),
    geoCode: MU && MU.countryCode,
    historyDT: getPublishedDate(),
    language: MU.language,
    mediaID: mediaid_s,
    parentID: `{${_parent}}`,
    playPosition: playPos,
    publishDT: getPublishedDate(__published_tdt),
    tags: taglist_sm,
    title: heading,
    totalDuration: dur,
    typeID: contenttype_t,
  };
  if (dur <= playPos + REMAINING_TIME) {
    data.deletedDT = getPublishedDate();
  }
  return data;
};

export const setLiveText = (scheduletype_t) => {
  return scheduletype_t && scheduletype_t.toLowerCase() === 'live' ? true : false;
};

export const checkHasAttachedVideo = (schedulemetadata_t, scheduletype_t) => {
  return (
    scheduletype_t &&
    scheduletype_t.toLowerCase() === 'vod' &&
    schedulemetadata_t &&
    schedulemetadata_t.hasOwnProperty('VideoFile') &&
    schedulemetadata_t.itemid_s
  );
};

export const setPlayerPosition = (myListResponse, itemid_s) => {
  let history;
  if (
    itemid_s &&
    myListResponse &&
    myListResponse.ContentHistoryResponse &&
    myListResponse.ContentHistoryResponse.ContentHistory &&
    myListResponse.ContentHistoryResponse.ContentHistory.length > 0
  ) {
    history = myListResponse.ContentHistoryResponse.ContentHistory;
  } else {
    const localdata = itemid_s && getLocalData();
    history =
      localdata &&
      localdata.ContentHistoryResponse &&
      localdata.ContentHistoryResponse.ContentHistory &&
      localdata.ContentHistoryResponse.ContentHistory.length > 0 &&
      localdata.ContentHistoryResponse.ContentHistory;
  }

  const playertime = history && history.find((item) => item.ID === itemid_s);
  if (playertime && playertime.playPosition && playertime.totalDuration) {
    const resumePos =
      (playertime.totalDuration - playertime.playPosition) / 1000 > 5
        ? playertime.playPosition / 1000
        : 0;
    return resumePos;
  }
  return 0;
};

export const getAwsServerTime = () => {
  try {
    const localData = localStorage.getItem('serverListData');
    const parseData = localData && JSON.parse(localData);
    if (parseData && parseData.lastContentDataSyncDT) {
      return parseData.lastContentDataSyncDT;
    }
  } catch (e) {
    console.log('error', e);
  }
};

export const isTimeDiffAllowed = (lastResponseTime) => {
  let currentTime = new Date();
  let timeDiff =
    lastResponseTime && currentTime && (currentTime.getTime() - lastResponseTime.getTime()) / 1000;
  if (lastResponseTime) {
    return timeDiff < 10 ? true : false;
  } else {
    return false;
  }
};
export const deleteOldItems = ({ allData, isHistory }) => {
  const contentResponse = isHistory ? 'ContentHistoryResponse' : 'ContentListResponse';
  const contentType = isHistory ? 'ContentHistory' : 'ContentList';
  const contentDT = isHistory ? 'historyDT' : 'myListDT';
  if (allData && allData[contentResponse] && allData[contentResponse][contentType]) {
    let contentItem = [];
    let deletedItem = [];
    const data = sortData({ allData, contentResponse, contentType, contentDT });
    allData[contentResponse][contentType] = data;
    allData[contentResponse][contentType].forEach((item) => {
      !item.deletedDT ? contentItem.push(item) : deletedItem.push(item);
    });
    const itemToRemove =
      MAX_ITEM > contentItem.length ? MAX_ITEM - contentItem.length : contentItem.length - MAX_ITEM;
    contentItem = contentItem.slice(0, -itemToRemove);
    allData[contentResponse][contentType] = [...contentItem, ...deletedItem];
    return allData;
  }
};
export const getTrueItems = (allData) => {
  return allData.filter((item) => !item.deletedDT);
};

export const sortData = ({ allData, contentResponse, contentType, contentDT }) => {
  return allData[contentResponse][contentType].sort((a, b) => {
    return new Date(b[contentDT]) - new Date(a[contentDT]);
  });
};

export const checkIsFeatured = (carouselContentType, featurecardType) => {
  return (
    carouselContentType &&
    featurecardType &&
    carouselTypeCheck.includes(carouselContentType.toLowerCase())
  );
};

//Video orientaion setter for mutv
export const setInlineVideoContainerUtil = (orientObj) => {
  const vD = orientObj && orientObj.vD;
  const container = orientObj && orientObj.container;
  try {
    const wW = window && window.innerWidth;
    const wH = window && window.innerHeight;
    if (container && wW < 568 && vD) {
      const videoContainer = container;
      let cW = videoContainer && videoContainer.clientWidth;
      let cH = videoContainer && videoContainer.clientHeight;
      if (vD && cW && cH) {
        const splittedVal = vD.split('x');
        let vH = +splittedVal[1];
        let vW = +splittedVal[0];
        const videoProperties = {};
        var ratio = vH / vW;
        const mainHeader = document.querySelector('.mainHeader');
        if (vW > vH) {
          //landscape videos
          vH = (vH / vW) * cW;
          videoProperties['height'] = vH;
          videoProperties['width'] = vW < cW ? wW : vW;
        } else if (vW < vH) {
          //potrait videos
          vH = (vH / vW) * cW;
          if (vH >= cH && mainHeader && mainHeader.offsetHeight) {
            videoProperties['height'] = wH - mainHeader.offsetHeight;
          } else {
            videoProperties['height'] = vH;
          }
          videoProperties['width'] = vW < cW ? wW : vW;
        } else if (ratio == 1) {
          //square videos
          vW = wW;
          vH = vW;
          videoProperties['width'] = vW;
          videoProperties['height'] = vH;
        }
        let videoStyleObj = {};
        if (videoProperties && videoProperties.width && videoProperties.height) {
          videoStyleObj = {
            maxWidth: `${videoProperties.width}px`,
            height: `${videoProperties.height}px`,
            opacity: 1,
            minHeight: 'auto',
            transition: '.4s all ease',
          };
        }
        return videoStyleObj;
      }
    } else {
      return {};
    }
  } catch (e) {
    return {};
  }
};

export const getOrientation = (data, containerType) => {
  try {
    if (containerType === 'audio-episode') {
      if (data.duration && typeof data.duration === 'string') {
        const meta = JSON.parse(data.duration);
        return meta.duration.video;
      } else if (data.duration && typeof data.duration === 'object' && data.duration.video) {
        return data.duration.video.dimension;
      }
    } else if (containerType === 'epg-carousel') {
      const MetaData =
        data &&
        data.schedulemetadata_t &&
        data.schedulemetadata_t.VideoFile &&
        data.schedulemetadata_t.VideoFile.Metadata;
      const dimensions =
        MetaData &&
        MetaData.find(
          (element) => element && element.Key && element.Key.toLowerCase() === 'dimensions'
        );
      return dimensions.Value;
    } else {
      if (data.celummetadatadetails_s && typeof data.celummetadatadetails_s === 'string') {
        const meta = JSON.parse(data.celummetadatadetails_s);
        return meta.dimension;
      } else if (data.celummetadatadetails_s && typeof data.celummetadatadetails_s === 'object') {
        return data.celummetadatadetails_s.dimension;
      }
      //default size for all videos where didn't get any orientation detail
      return '16x9';
    }
  } catch (error) {
    // for any case we didn't get the video orientatin detail making it default ratio handled for live stream also
    return '16x9';
  }
};
