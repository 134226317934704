/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

export const monthArr = [
  { name: 'Jan', value: 'Jan' },
  { name: 'Feb', value: 'Feb' },
  { name: 'Mar', value: 'Mar' },
  { name: 'Apr', value: 'Apr' },
  { name: 'May', value: 'May' },
  { name: 'Jun', value: 'Jun' },
  { name: 'Jul', value: 'Jul' },
  { name: 'Aug', value: 'Aug' },
  { name: 'Sep', value: 'Sep' },
  { name: 'Oct', value: 'Oct' },
  { name: 'Nov', value: 'Nov' },
  { name: 'Dec', value: 'Dec' },
];

export const countryArr = [
  {
    name: 'Afghanistan',
    value: 'Afghanistan',
  },
  {
    name: 'Albania',
    value: 'Albania',
  },
  {
    name: 'Algeria',
    value: 'Algeria',
  },
  {
    name: 'American Somoa',
    value: 'American Somoa',
  },
  {
    name: 'Andorra',
    value: 'Andorra',
  },
  {
    name: 'Angola',
    value: 'Angola',
  },
  {
    name: 'Anguilla',
    value: 'Anguilla',
  },
  {
    name: 'Antarctica',
    value: 'Antarctica',
  },
  {
    name: 'Antigua and Barbuda',
    value: 'Antigua and Barbuda',
  },
  {
    name: 'Argentina',
    value: 'Argentina',
  },
  {
    name: 'Armenia',
    value: 'Armenia',
  },
  {
    name: 'Aruba',
    value: 'Aruba',
  },
  {
    name: 'Australia',
    value: 'Australia',
  },
  {
    name: 'Austria',
    value: 'Austria',
  },
  {
    name: 'Azerbaijan',
    value: 'Azerbaijan',
  },
  {
    name: 'Bahamas',
    value: 'Bahamas',
  },
  {
    name: 'Bahrain',
    value: 'Bahrain',
  },
  {
    name: 'Bangladesh',
    value: 'Bangladesh',
  },
  {
    name: 'Barbados',
    value: 'Barbados',
  },
  {
    name: 'Belarus',
    value: 'Belarus',
  },
  {
    name: 'Belgium',
    value: 'Belgium',
  },
  {
    name: 'Belize',
    value: 'Belize',
  },
  {
    name: 'Benin',
    value: 'Benin',
  },
  {
    name: 'Bermuda',
    value: 'Bermuda',
  },
  {
    name: 'Bhutan',
    value: 'Bhutan',
  },
  {
    name: 'Bolivia',
    value: 'Bolivia',
  },
  {
    name: 'Bosnia and Herzegovina',
    value: 'Bosnia and Herzegovina',
  },
  {
    name: 'Botswana',
    value: 'Botswana',
  },
  {
    name: 'Bouvet Island',
    value: 'Bouvet Island',
  },
  {
    name: 'Brazil',
    value: 'Brazil',
  },
  {
    name: 'British Indian Ocean Territory',
    value: 'British Indian Ocean Territory',
  },
  {
    name: 'Brunei Darussalam',
    value: 'Brunei Darussalam',
  },
  {
    name: 'Bulgaria',
    value: 'Bulgaria',
  },
  {
    name: 'Burkina Faso',
    value: 'Burkina Faso',
  },
  {
    name: 'Burma',
    value: 'Burma',
  },
  {
    name: 'Burundi',
    value: 'Burundi',
  },
  {
    name: 'Cambodia',
    value: 'Cambodia',
  },
  {
    name: 'Cameroon',
    value: 'Cameroon',
  },
  {
    name: 'Canada',
    value: 'Canada',
  },
  {
    name: 'Cape Verde',
    value: 'Cape Verde',
  },
  {
    name: 'Cayman Islands',
    value: 'Cayman Islands',
  },
  {
    name: 'Central African Republic',
    value: 'Central African Republic',
  },
  {
    name: 'Chad',
    value: 'Chad',
  },
  {
    name: 'Channel Islands',
    value: 'Channel Islands',
  },
  {
    name: 'Chile',
    value: 'Chile',
  },
  {
    name: 'China',
    value: 'China',
  },
  {
    name: 'Chinese Taipei',
    value: 'Chinese Taipei',
  },
  {
    name: 'Christmas Island',
    value: 'Christmas Island',
  },
  {
    name: 'Cocos Keeling Islands',
    value: 'Cocos Keeling Islands',
  },
  {
    name: 'Colombia',
    value: 'Colombia',
  },
  {
    name: 'Comoros',
    value: 'Comoros',
  },
  {
    name: 'Congo',
    value: 'Congo',
  },
  {
    name: 'Cook Islands',
    value: 'Cook Islands',
  },
  {
    name: 'Costa Rica',
    value: 'Costa Rica',
  },
  {
    name: 'Croatia',
    value: 'Croatia',
  },
  {
    name: 'Cuba',
    value: 'Cuba',
  },
  {
    name: 'Cyprus',
    value: 'Cyprus',
  },
  {
    name: 'Czech Republic',
    value: 'Czech Republic',
  },
  {
    name: 'Czechoslovakia Former',
    value: 'Czechoslovakia Former',
  },
  {
    name: 'Denmark',
    value: 'Denmark',
  },
  {
    name: 'Djibouti',
    value: 'Djibouti',
  },
  {
    name: 'Dominica',
    value: 'Dominica',
  },
  {
    name: 'Dominican Republic',
    value: 'Dominican Republic',
  },
  {
    name: 'Dubai',
    value: 'Dubai',
  },
  {
    name: 'East Timor',
    value: 'East Timor',
  },
  {
    name: 'Ecuador',
    value: 'Ecuador',
  },
  {
    name: 'Egypt',
    value: 'Egypt',
  },
  {
    name: 'El Salvador',
    value: 'El Salvador',
  },
  {
    name: 'England',
    value: 'England',
  },
  {
    name: 'Equatorial Guinea',
    value: 'Equatorial Guinea',
  },
  {
    name: 'Eritrea',
    value: 'Eritrea',
  },
  {
    name: 'Estonia',
    value: 'Estonia',
  },
  {
    name: 'Ethiopia',
    value: 'Ethiopia',
  },
  {
    name: 'Falkland Islands Malvinas',
    value: 'Falkland Islands Malvinas',
  },
  {
    name: 'Faroe Islands',
    value: 'Faroe Islands',
  },
  {
    name: 'Fiji',
    value: 'Fiji',
  },
  {
    name: 'Finland',
    value: 'Finland',
  },
  {
    name: 'France',
    value: 'France',
  },
  {
    name: 'French Guiana',
    value: 'French Guiana',
  },
  {
    name: 'French Polynesia',
    value: 'French Polynesia',
  },
  {
    name: 'French Southern Territories',
    value: 'French Southern Territories',
  },
  {
    name: 'Gabon',
    value: 'Gabon',
  },
  {
    name: 'Gambia',
    value: 'Gambia',
  },
  {
    name: 'Georgia',
    value: 'Georgia',
  },
  {
    name: 'Germany',
    value: 'Germany',
  },
  {
    name: 'Ghana',
    value: 'Ghana',
  },
  {
    name: 'Gibraltar',
    value: 'Gibraltar',
  },
  {
    name: 'Greece',
    value: 'Greece',
  },
  {
    name: 'Greenland',
    value: 'Greenland',
  },
  {
    name: 'Grenada',
    value: 'Grenada',
  },
  {
    name: 'Guadeloupe',
    value: 'Guadeloupe',
  },
  {
    name: 'Guam',
    value: 'Guam',
  },
  {
    name: 'Guatamala',
    value: 'Guatamala',
  },
  {
    name: 'Guinea',
    value: 'Guinea',
  },
  {
    name: 'Guinea Bissau',
    value: 'Guinea Bissau',
  },
  {
    name: 'Guyana',
    value: 'Guyana',
  },
  {
    name: 'Haiti',
    value: 'Haiti',
  },
  {
    name: 'Heard and McDonald Islands',
    value: 'Heard and McDonald Islands',
  },
  {
    name: 'Honduras',
    value: 'Honduras',
  },
  {
    name: 'Hong Kong ',
    value: 'Hong Kong ',
  },
  {
    name: 'Hungary',
    value: 'Hungary',
  },
  {
    name: 'Iceland',
    value: 'Iceland',
  },
  {
    name: 'India',
    value: 'India',
  },
  {
    name: 'Indonesia',
    value: 'Indonesia',
  },
  {
    name: 'Iran',
    value: 'Iran',
  },
  {
    name: 'Iraq',
    value: 'Iraq',
  },
  {
    name: 'Ireland',
    value: 'Ireland',
  },
  {
    name: 'Israel',
    value: 'Israel',
  },
  {
    name: 'Italy',
    value: 'Italy',
  },
  {
    name: 'Ivory Coast',
    value: 'Ivory Coast',
  },
  {
    name: 'Jamaica',
    value: 'Jamaica',
  },
  {
    name: 'Japan',
    value: 'Japan',
  },
  {
    name: 'Jordan',
    value: 'Jordan',
  },
  {
    name: 'Kazakhstan',
    value: 'Kazakhstan',
  },
  {
    name: 'Kenya',
    value: 'Kenya',
  },
  {
    name: 'Kiribati',
    value: 'Kiribati',
  },
  {
    name: 'Korea, North (PDR of Korea)',
    value: 'Korea, North (PDR of Korea)',
  },
  {
    name: 'Korea, South',
    value: 'Korea, South',
  },
  {
    name: 'Kuwait',
    value: 'Kuwait',
  },
  {
    name: 'Kyrgzstan',
    value: 'Kyrgzstan',
  },
  {
    name: 'Laos',
    value: 'Laos',
  },
  {
    name: 'Latvia',
    value: 'Latvia',
  },
  {
    name: 'Lebanon',
    value: 'Lebanon',
  },
  {
    name: 'Lesotho',
    value: 'Lesotho',
  },
  {
    name: 'Liberia',
    value: 'Liberia',
  },
  {
    name: 'Libya',
    value: 'Libya',
  },
  {
    name: 'Liechtenstein',
    value: 'Liechtenstein',
  },
  {
    name: 'Lithuania',
    value: 'Lithuania',
  },
  {
    name: 'Luxembourg',
    value: 'Luxembourg',
  },
  {
    name: 'Macau',
    value: 'Macau',
  },
  {
    name: 'Macedonia',
    value: 'Macedonia',
  },
  {
    name: 'Madagascar',
    value: 'Madagascar',
  },
  {
    name: 'Madeira',
    value: 'Madeira',
  },
  {
    name: 'Malawi ',
    value: 'Malawi ',
  },
  {
    name: 'Malaysia',
    value: 'Malaysia',
  },
  {
    name: 'Maldives',
    value: 'Maldives',
  },
  {
    name: 'Mali',
    value: 'Mali',
  },
  {
    name: 'Malta',
    value: 'Malta',
  },
  {
    name: 'Marshall Islands',
    value: 'Marshall Islands',
  },
  {
    name: 'Martinique',
    value: 'Martinique',
  },
  {
    name: 'Mauritania',
    value: 'Mauritania',
  },
  {
    name: 'Mauritius',
    value: 'Mauritius',
  },
  {
    name: 'Mayotte',
    value: 'Mayotte',
  },
  {
    name: 'Mexico',
    value: 'Mexico',
  },
  {
    name: 'Micronesia',
    value: 'Micronesia',
  },
  {
    name: 'Moldova',
    value: 'Moldova',
  },
  {
    name: 'Monaco',
    value: 'Monaco',
  },
  {
    name: 'Mongolia',
    value: 'Mongolia',
  },
  {
    name: 'Montenegro',
    value: 'Montenegro',
  },
  {
    name: 'Montserrat',
    value: 'Montserrat',
  },
  {
    name: 'Morocco',
    value: 'Morocco',
  },
  {
    name: 'Mozambique',
    value: 'Mozambique',
  },
  {
    name: 'Myanmar',
    value: 'Myanmar',
  },
  {
    name: 'Namibia',
    value: 'Namibia',
  },
  {
    name: 'Nauru',
    value: 'Nauru',
  },
  {
    name: 'Nepal',
    value: 'Nepal',
  },
  {
    name: 'Netherlands',
    value: 'Netherlands',
  },
  {
    name: 'Netherlands Antilles',
    value: 'Netherlands Antilles',
  },
  {
    name: 'New Caledonia',
    value: 'New Caledonia',
  },
  {
    name: 'New Zealand',
    value: 'New Zealand',
  },
  {
    name: 'Nicaragua',
    value: 'Nicaragua',
  },
  {
    name: 'Niger',
    value: 'Niger',
  },
  {
    name: 'Nigeria',
    value: 'Nigeria',
  },
  {
    name: 'Niue',
    value: 'Niue',
  },
  {
    name: 'Norfolk Island',
    value: 'Norfolk Island',
  },
  {
    name: 'Northern Ireland',
    value: 'Northern Ireland',
  },
  {
    name: 'Northern Mariana Islands',
    value: 'Northern Mariana Islands',
  },
  {
    name: 'Norway',
    value: 'Norway',
  },
  {
    name: 'Oman',
    value: 'Oman',
  },
  {
    name: 'Pakistan',
    value: 'Pakistan',
  },
  {
    name: 'Palau',
    value: 'Palau',
  },
  {
    name: 'Panama',
    value: 'Panama',
  },
  {
    name: 'Papua New Guinea',
    value: 'Papua New Guinea',
  },
  {
    name: 'Paraguay',
    value: 'Paraguay',
  },
  {
    name: 'Peru',
    value: 'Peru',
  },
  {
    name: 'Philippines',
    value: 'Philippines',
  },
  {
    name: 'Pitcairn',
    value: 'Pitcairn',
  },
  {
    name: 'Poland',
    value: 'Poland',
  },
  {
    name: 'Portugal',
    value: 'Portugal',
  },
  {
    name: 'Puerto Rico',
    value: 'Puerto Rico',
  },
  {
    name: 'Qatar',
    value: 'Qatar',
  },
  {
    name: 'Republic of Ireland',
    value: 'Republic of Ireland',
  },
  {
    name: 'Republic of Kosovo',
    value: 'Republic of Kosovo',
  },
  {
    name: 'Reunion',
    value: 'Reunion',
  },
  {
    name: 'Romania',
    value: 'Romania',
  },
  {
    name: 'Russia',
    value: 'Russia',
  },
  {
    name: 'Russian Federation',
    value: 'Russian Federation',
  },
  {
    name: 'Rwanda',
    value: 'Rwanda',
  },
  {
    name: 'S Georgia and S Sandwich',
    value: 'S Georgia and S Sandwich',
  },
  {
    name: 'Saint Kitts and Nevis',
    value: 'Saint Kitts and Nevis',
  },
  {
    name: 'Saint Lucia',
    value: 'Saint Lucia',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    value: 'Saint Vincent and the Grenadines',
  },
  {
    name: 'Samoa',
    value: 'Samoa',
  },
  {
    name: 'San Marino',
    value: 'San Marino',
  },
  {
    name: 'San Salvador',
    value: 'San Salvador',
  },
  {
    name: 'Sao Tome and Principe',
    value: 'Sao Tome and Principe',
  },
  {
    name: 'Saudi Arabia',
    value: 'Saudi Arabia',
  },
  {
    name: 'Scotland',
    value: 'Scotland',
  },
  {
    name: 'Senegal',
    value: 'Senegal',
  },
  {
    name: 'Serbia',
    value: 'Serbia',
  },
  {
    name: 'Seychelles',
    value: 'Seychelles',
  },
  {
    name: 'Sierra Leone',
    value: 'Sierra Leone',
  },
  {
    name: 'Singapore',
    value: 'Singapore',
  },
  {
    name: 'Slovak Republic',
    value: 'Slovak Republic',
  },
  {
    name: 'Slovenia',
    value: 'Slovenia',
  },
  {
    name: 'Solomon Islands',
    value: 'Solomon Islands',
  },
  {
    name: 'Somalia',
    value: 'Somalia',
  },
  {
    name: 'South Africa',
    value: 'South Africa',
  },
  {
    name: 'Spain',
    value: 'Spain',
  },
  {
    name: 'Sri Lanka',
    value: 'Sri Lanka',
  },
  {
    name: 'St Helena',
    value: 'St Helena',
  },
  {
    name: 'St Lucia',
    value: 'St Lucia',
  },
  {
    name: 'St Pierre and Miquelon',
    value: 'St Pierre and Miquelon',
  },
  {
    name: 'Sudan',
    value: 'Sudan',
  },
  {
    name: 'Suriname',
    value: 'Suriname',
  },
  {
    name: 'Svalbard and Jan Mayen Islands',
    value: 'Svalbard and Jan Mayen Islands',
  },
  {
    name: 'Swaziland',
    value: 'Swaziland',
  },
  {
    name: 'Sweden',
    value: 'Sweden',
  },
  {
    name: 'Switzerland',
    value: 'Switzerland',
  },
  {
    name: 'Syria',
    value: 'Syria',
  },
  {
    name: 'Taiwan',
    value: 'Taiwan',
  },
  {
    name: 'Tajikistan',
    value: 'Tajikistan',
  },
  {
    name: 'Tanzania',
    value: 'Tanzania',
  },
  {
    name: 'Thailand',
    value: 'Thailand',
  },
  {
    name: 'Togo',
    value: 'Togo',
  },
  {
    name: 'Tokelau',
    value: 'Tokelau',
  },
  {
    name: 'Tonga',
    value: 'Tonga',
  },
  {
    name: 'Trinidad & Tobago',
    value: 'Trinidad & Tobago',
  },
  {
    name: 'Tunisia',
    value: 'Tunisia',
  },
  {
    name: 'Turkey',
    value: 'Turkey',
  },
  {
    name: 'Turkmenistan',
    value: 'Turkmenistan',
  },
  {
    name: 'Turks and Caicos Islands',
    value: 'Turks and Caicos Islands',
  },
  {
    name: 'Tuvalu',
    value: 'Tuvalu',
  },
  {
    name: 'Uganda',
    value: 'Uganda',
  },
  {
    name: 'Ukraine',
    value: 'Ukraine',
  },
  {
    name: 'United Arab Emirates',
    value: 'United Arab Emirates',
  },
  {
    name: 'United Kingdom',
    value: 'United Kingdom',
  },
  {
    name: 'Uruguay',
    value: 'Uruguay',
  },
  {
    name: 'US Minor Outlying Islands',
    value: 'US Minor Outlying Islands',
  },
  {
    name: 'USA',
    value: 'USA',
  },
  {
    name: 'Uzbekistan',
    value: 'Uzbekistan',
  },
  {
    name: 'Vanuatu',
    value: 'Vanuatu',
  },
  {
    name: 'Vatican City State Holy See',
    value: 'Vatican City State Holy See',
  },
  {
    name: 'Venezuela',
    value: 'Venezuela',
  },
  {
    name: 'Vietnam',
    value: 'Vietnam',
  },
  {
    name: 'Virgin Islands British',
    value: 'Virgin Islands British',
  },
  {
    name: 'Virgin Islands US',
    value: 'Virgin Islands US',
  },
  {
    name: 'Wales',
    value: 'Wales',
  },
  {
    name: 'Wallis and Futana Islands',
    value: 'Wallis and Futana Islands',
  },
  {
    name: 'Western Sahara',
    value: 'Western Sahara',
  },
  {
    name: 'Yemen',
    value: 'Yemen',
  },
  {
    name: 'Zaire',
    value: 'Zaire',
  },
  {
    name: 'Zambia',
    value: 'Zambia',
  },
  {
    name: 'Zimbabwe',
    value: 'Zimbabwe',
  },
];

export const initialValue = {
  isConvicted: false,
  isWrongInfo: false,
  isReEnter: false,
  isconnectivity: false,
};
export const errorMessageGroup = {
  membership: 'Membership number is required',
  surname: 'Surname is required',
  convictedOffences: 'Please select any radio button',
  convictedOffencesYes:
    'You are unable to proceed as you have been convicted of a relevant offence. Call 0161 868 8000 to discuss.',
  firstName: 'First name is required',
  lastName: 'Last Name is required',
  postcode: 'Postcode is required',
  nationality: 'This field is required',
  dobDate: 'This field is required',
  dobMonth: 'This field is required',
  dobYear: 'This field is required',
  email: 'Please enter a valid email address',
  passport: 'Passport Number is required',
  travellingBy: 'Travel By field is required',
  newAir: 'Flight Number is required',
  travellingFrom: 'Travelling From is required',
  travellingOther: '',
  flightNumber: 'Flight Number is required',
  departureDate: 'Departure Date is required',
  returnDate: 'Return Date is required',
  arrivalPoint: 'Arrival Point is required',
  otherPoint: 'Arrival Point is required',
  city: 'City is required',
  hotel: 'Hotel is required',
};

export const travelInitialStateStep1 = [
  { name: 'membership', isRequired: true, type: 'text' },
  { name: 'surname', isRequired: true, type: 'text' },
  { name: 'convictedOffences', isRequired: true, type: 'radio' },
];

export const travelInitialStateStep2 = [
  { name: 'firstName', isRequired: true, type: 'text' },
  { name: 'lastName', isRequired: false, type: 'text' },
  { name: 'postcode', isRequired: false, type: 'text' },
  { name: 'nationality', isRequired: true, type: 'text' },
  { name: 'dobDate', isRequired: false, type: 'text' },
  { name: 'dobMonth', isRequired: false, type: 'text' },
  { name: 'dobYear', isRequired: false, type: 'text' },
  { name: 'email', isRequired: true, type: 'email' },
  { name: 'passport', isRequired: true, type: 'text' },
  { name: 'travellingBy', isRequired: true, type: 'text' },
  { name: 'travellingFrom', isRequired: true, type: 'text' },
  { name: 'departureDate', isRequired: true, type: 'text' },
  { name: 'returnDate', isRequired: true, type: 'text' },
  { name: 'arrivalPoint', isRequired: true, type: 'text' },
  { name: 'otherPoint', isRequired: false, type: 'text' },
  { name: 'city', isRequired: false, type: 'text' },
  { name: 'hotel', isRequired: false, type: 'text' },
];
/*car parking initialvalue* */
export const initialValueCarParking = { saveError: false, duplicate: false, thankYou: false };
