/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import React, { Fragment, useEffect, useState } from 'react';
import {
  getSponsorData,
  getItemId,
  getStatsBarData,
  createTagArr,
  validateCbr,
  isCollection as iscollectionModal,
  createDestinationUrl,
  isApp,
  getLoggedInStatus,
} from '../../../utils/utils';
import { CONTENT_TYPE, MUAppDeepLink, Keyboard } from '../../../constants/globalConstants';
import StatsBar from '../../Common/StatsBar';
import Sponsor from '../../Common/Sponsor';
import ImgCrop from '../../Common/ImgCrop';
import { withTranslation } from 'react-i18next';
import Loader from '../../Common/Loader';
import api from '../../../utils/muApi';
import { POLL_DETAIL, POLL } from '../../../constants/endpoints';
import moment from 'moment';
import { Poll as PollTransform } from '../../../data-transform/components/Poll';
import * as track from '../../../utils/analytics';
import {
  getPoleSessionId,
  persistUserPollSelection,
  savePollResponse,
  calculatePercentage,
  pollClasses,
} from '../../../utils/pollUtils';
import { useSponsorValue } from '../../../context/sponsorContext';
import AccessBadge from '../../Common/AccessBadge';
import { useModal } from '../../Common/Modal';
import ErrorBoundary from '../../Common/ErrorBoundary';
import PollDetail from '../../DestinationsPages/PollDetail';
import loadable from '@loadable/component';
import MU from '../../../constants/muConstants';
let pollRslt, pollUid;
const RegistrationModal = loadable(() =>
  import('../../Modals/RegistrationModal/RegistrationModal')
);
const Poll = (props) => {
  const {
    fields,
    t,
    gridClass,
    iscollection,
    container,
    source,
    isArticleDetail,
    isDestinationPage,
    showStatsbar,
    pollModalFlag,
    nativeCard,
    setAlreadyVotedHome,
  } = props;
  let itemid = '';
  const [state, setState] = useState({
    status: 'fetching',
    showLoader: true,
    message: '',
    error: '',
  });
  const [pollClosed, setPollClosed] = useState(false);
  const [alreadyVoted, setAlreadyVoted] = useState(false);
  let voteIndex = null;
  const { showModal, closeModal, isModalVisible } = useModal();
  const [cardData, setCardData] = useState(fields);
  const [userId, setUserId] = useState(null);
  const [userPolls, setUserPolls] = useState(null);
  const [pollItemId, setPollItemId] = useState(pollItemId);
  const [isMicroCard, setIsMicroCard] = useState(false);
  const [ctaHeading, setCtaHeading] = useState();
  const [
    { userLoginDetail, isUserLogin, isLoginChecked, isUserSubscribe, isUserSkyId },
    dispatch,
  ] = useSponsorValue();
  const {
    showModal: cbrShowModal,
    closeModal: cbrCloseModal,
    isModalVisible: isCbrModalVisible,
  } = useModal();
  const destinationUrl =
    fields.manutds && fields.manutds.value && fields.manutds.value.DestinationUrl;
  const isHidePollStats =
    fields && fields.HidePollStats && fields.HidePollStats.value && fields.HidePollStats.value;
  const { pageState } = props.sitecoreContext;

  useEffect(() => {
    cardData && checkExpiredPoll(cardData);
  });

  const [successScrollState, setSuccessScrollState] = useState({
    x: '',
    y: '',
  });

  useEffect(() => {
    if (isLoginChecked) {
      const showCbr = getCbrValue();
      if (pollClosed || (showCbr && !showCbr.value)) {
        if (userLoginDetail) {
          alreadyVoted && setAlreadyVoted(false);
          userPolls && setUserPolls(null);
          pollingData(null);
        } else {
          pollingData(userPolls);
        }
      } else if (!pollModalFlag && showCbr && showCbr.value) {
        setState(() => ({
          ...state,
          showLoader: false,
          status: 'success',
        }));
      }
    }
    return () => {
      voteIndex = null;
      itemid = null;
    };
  }, [isLoginChecked, userLoginDetail]);

  useEffect(() => {
    if (pollModalFlag) {
      setAlreadyVotedHome(alreadyVoted);
    } else if (nativeCard) {
      const CTATextBeforeVote =
        cardData && cardData.CTATextBeforeVote && cardData.CTATextBeforeVote.value;
      const CTATextAfterVote =
        cardData && cardData.CTATextAfterVote && cardData.CTATextAfterVote.value;
      return alreadyVoted ? setCtaHeading(CTATextAfterVote) : setCtaHeading(CTATextBeforeVote);
    }
  }, [alreadyVoted]);

  useEffect(() => {
    gridClass && gridClass.toLowerCase() === 'micro-card' && setIsMicroCard(gridClass);
  }, [gridClass]);

  const fetchPollData = () => {
    const route = `${POLL_DETAIL}${encodeURI(itemid)}`;
    return api
      .get({ route })
      .then((data) => {
        if (data.GetItemResponse.response && data.GetItemResponse.response.docs.length > 0) {
          const pollData = data.GetItemResponse.response.docs[0];
          setState(() => ({
            ...state,
            showLoader: false,
            status: 'success',
          }));
          const fieldData = PollTransform(pollData, source === 'aws');
          setCardData(fieldData && fieldData.fields);
          checkExpiredPoll(fieldData && fieldData.fields);
          getUserId(fieldData && fieldData.fields, userPolls);
        }
      })
      .catch((err) => {
        console.log('err', err);
        setState(() => ({
          ...state,
          showLoader: false,
          status: 'error',
          error: err,
          message: t && t('APIFailed') ? t('APIFailed') : '',
        }));
      });
  };

  const checkExpiredPoll = (data) => {
    if (data && data.EndDate && data.EndDate.value) {
      var expired = moment(data.EndDate.value).isBefore(moment());
      if (expired && data.EndDate.value !== '0001-01-01T00:00:00Z') {
        setPollClosed(true);
        !nativeCard && !pollModalFlag && setAlreadyVoted(false);
      }
    }
  };
  const getUserId = (pollData, userPollData) => {
    let id = '';
    if (isLoginChecked) {
      if (isUserLogin && userLoginDetail && userLoginDetail.UID) {
        id = userLoginDetail.UID;
      } else {
        id = getPoleSessionId();
      }
    } else {
      id = getPoleSessionId();
    }
    setUserId(id);
    checkVote(pollData, id, userPollData);
  };

  const checkVote = (pollData, uid, userPollData) => {
    if (!userPollData) {
      getUserPolls(
        pollData,
        uid,
        pollData && pollData.manutds && pollData.manutds.value && pollData.manutds.value.pollId
      );
    } else {
      checkIfAlreadyVoted(
        userPollData,
        pollData && pollData.manutds && pollData.manutds.value && pollData.manutds.value.pollId
      );
    }
  };

  const getUserPolls = (pollData, uid, pollId) => {
    !nativeCard && removePreviousVote(pollId);
    const route = `${POLL}?uid=${uid}`;
    if (!pollRslt || (userLoginDetail && uid !== pollUid)) {
      pollUid = uid;
      pollRslt = api.get({ route });
    }
    pollRslt
      .then((data) => {
        if (
          data &&
          data.data &&
          data.data.errorMessage &&
          data.data.errorMessage === 'Poll Failure : UID does not exist in MongoDB'
        ) {
          pollResultErrorCase(pollData, {});
        } else {
          getUserPollsSuccess(data, pollData);
        }
      })
      .catch((err) => {
        pollResultErrorCase(pollData, err);
      })
      .finally(() => {
        matchLocallyStoredPolls(uid, pollId);
      });
  };

  const pollResultErrorCase = (pollData, err) => {
    console.log('Get Poll Data Service Failed !!');
    checkExpiredPoll(pollData);
    setState(() => ({
      ...state,
      showLoader: false,
      status: 'error',
      error: err,
      message: t && t('APIFailed') ? t('APIFailed') : '',
    }));
  };

  const matchLocallyStoredPolls = (uid, pollId) => {
    const userPollsStore = localStorage.getItem('userPolls');
    if (typeof Storage !== undefined && userPollsStore && userPollsStore != 'undefined') {
      const polls = JSON.parse(userPollsStore);
      polls.forEach((users) => {
        if (users.userId == uid) {
          users.polls.forEach((userPoll) => {
            if (userPoll.pollId == pollId) {
              setAlreadyVoted(true);
              const elem = document.querySelector(`#${pollId}`);
              const option = elem && elem.querySelector(`.opt${parseInt(userPoll.votingOption)}`);
              option && option.classList.add('selected');
            }
          });
        }
      });
    }
  };

  const getUserPollsSuccess = (response, pollData) => {
    if (response && response.data) {
      setUserPolls(response);
      checkIfAlreadyVoted(
        response,
        pollData && pollData.manutds && pollData.manutds.value && pollData.manutds.value.pollId
      );
    } else {
      setUserPolls(null);
      setAlreadyVoted(false);
      setState(() => ({
        ...state,
        showLoader: false,
        status: 'success',
      }));
    }
    checkExpiredPoll(pollData);
  };

  const checkIfAlreadyVoted = (allUserPolls, pollId) => {
    if (allUserPolls && allUserPolls.data && allUserPolls.data.data) {
      Object.keys(allUserPolls.data.data).forEach((poll) => {
        if (poll == pollId) {
          setAlreadyVoted(true);
          const elem = document.querySelector(`#${pollId}`);
          const option =
            elem &&
            elem.querySelector(`.opt${parseInt(allUserPolls.data.data[poll].votingOption)}`);
          option && option.classList.add('selected');
        }
      });
    }
    setState(() => ({
      ...state,
      showLoader: false,
    }));
  };

  const vote = (index) => {
    const pollObj = {};
    setAlreadyVoted(true);
    voteIndex = index;
    pollRslt = null;
    const elem = document.querySelector(
      `#${cardData && cardData.manutds && cardData.manutds.value && cardData.manutds.value.pollId}`
    );
    const option = elem && elem.querySelector(`.opt${index}`);
    option && option.classList.add('selected');
    ariaLabel(cardData.manutds.value.Options[index], showNumber(index, true));
    isVoted(cardData.manutds.value.pollId, index);
    persistUserPollSelection(index, cardData, userId);
    pollObj[
      cardData && cardData.manutds && cardData.manutds.value && cardData.manutds.value.pollId
    ] = {
      pollID:
        cardData && cardData.manutds && cardData.manutds.value && cardData.manutds.value.pollId,
      pollTitle: cardData && cardData.Question && cardData.Question.value,
      pollEndTime: cardData && cardData.EndDate && cardData.EndDate.value,
      pollStartTime: cardData && cardData.__Published && cardData.__Published.value,
      webID: '',
      email: '',
      deviceID: '',
      uID: userId,
      votingOption: index.toString(),
    };
    savePollResponse(
      cardData && cardData.manutds && cardData.manutds.value && cardData.manutds.value.pollId,
      pollObj
    );
  };

  const getCbrValue = () => {
    return validateCbr({
      isUserLogin,
      isUserSubscribe,
      contentAccess: cardData.ContentAccess && cardData.ContentAccess.value,
      contentType: cardData.ContentType && cardData.ContentType.value,
      manutds: cardData.manutds,
    });
  };

  const getAnalyticsData = (fields, contentStatus) => {
    const initialData = track.data('card');
    return {
      ...initialData,
      card_name: cardData && cardData.Question && cardData.Question.value,
      container_type: container,
      item_id: pollItemId,
      content_status: contentStatus,
      subscription_status: getLoggedInStatus({
        isLoginChecked,
        isUserLogin,
        isUserSubscribe,
        isUserSkyId,
      }),
      content_type: cardData && cardData.ContentType && cardData.ContentType.value,
      tag: cardData.manutds && cardData.manutds.value && cardData.manutds.value.Tags,
      created_datetime: cardData && cardData.__Published && cardData.__Published.value,
      author: cardData.manutds && cardData.manutds.value && cardData.manutds.value.Author,
      destination_url:
        cardData.manutds && cardData.manutds.value && cardData.manutds.value.DestinationUrl,
    };
  };

  const trackCard = (fields, e, contentStatus) => {
    track.analytics(getAnalyticsData(fields, contentStatus), e);
  };

  const pollingData = (userPollData) => {
    if (props.itemId && props.itemId !== '') {
      itemid = props.itemId.charAt(0) == '{' ? props.itemId : '{' + props.itemId + '}';
      fetchPollData();
    } else {
      itemid = getItemId(props);
      !pollModalFlag &&
        setState(() => ({
          ...state,
          showLoader: false,
          status: 'success',
        }));
      getUserId(cardData, userPollData);
    }
    setPollItemId(itemid);
  };

  const removePreviousVote = (pollId) => {
    const pollWrapper = document.querySelector(`#${pollId}`);
    const liTag = pollWrapper && pollWrapper.getElementsByTagName('li');
    if (liTag) {
      for (let index = 0; index < liTag.length; index++) {
        const votedPollBar = liTag[index] && liTag[index].querySelector('i');
        const option =
          cardData &&
          cardData.manutds &&
          cardData.manutds.value &&
          cardData.manutds.value.Options[index];
        votedPollBar &&
          votedPollBar.classList.contains('selected') &&
          votedPollBar.classList.remove('selected');
        ariaLabel(option, showNumber(index, true));
        isVoted(cardData.manutds.value.pollId, index);
      }
    }
  };

  const showNumber = (index, isWidth) => {
    if (hidePollStats() && !pollClosed) {
      return isWidth ? null : '';
    } else {
      return calculatePercentage(index, cardData, voteIndex, alreadyVoted, pollClosed);
    }
  };

  const onCardClick = (e) => {
    setSuccessScrollState({ x: e.pageX, y: e.pageY });
    const showCbr = getCbrValue();
    if (isHybridCheck() && showCbr && showCbr.value) {
      window.location.href = createDestinationUrl(destinationUrl) + MUAppDeepLink;
      return true;
    } else {
      trackCard(fields, e, showCbr && showCbr.accessCheck);
      if (showCbr && showCbr.value) {
        cbrShowModal(destinationUrl);
      } else if (nativeCard) {
        showModal();
      }
    }
  };

  const isHybridCheck = () => {
    return isApp() && isArticleDetail && !iscollectionModal() ? true : false;
  };

  const onPollClick = (e, index) => {
    const showCbr = getCbrValue();
    if (isHybridCheck() && showCbr && showCbr.value) {
      e.preventDefault();
      return true;
    } else {
      if (showCbr && showCbr.value) {
        e.preventDefault();
      } else {
        vote(index);
      }
    }
  };

  const successCallback = ({ showContent, isSubscribedContent }) => {
    if (showContent) {
      if (!MU.isIDMEnabled) showModal();
      else {
        return [
          'successScroll',
          isSubscribedContent,
          JSON.stringify(successScrollState.x),
          JSON.stringify(successScrollState.y),
        ];
      }
    }
  };

  const renderCBSModal = () => {
    const accessType = getCbrValue().accessCheck;
    return (
      <ErrorBoundary>
        <RegistrationModal
          closeModal={cbrCloseModal}
          successCallback={nativeCard && successCallback}
          fallback={<Loader />}
          previousActiveElement={document.activeElement}
          accessType={accessType}
          analyticsObj={getAnalyticsData(fields, accessType)}
        />
      </ErrorBoundary>
    );
  };

  const activeList = (e, activeIndex) => {
    const code = e.keyCode || e.which;
    const listItems =
      e.target &&
      e.target.parentElement &&
      e.target.parentElement.parentElement &&
      e.target.parentElement.parentElement.querySelectorAll('li');
    try {
      if (code === Keyboard.ENTER || code === Keyboard.SPACEBAR) {
        e.preventDefault();
        !voteDisabled() && e.target.querySelector('input').click();
      } else if (code === Keyboard.UP) {
        e.preventDefault();
        const focusEl = activeIndex - 1;
        if (listItems && listItems[focusEl]) {
          listItems[focusEl + 1].setAttribute('tabIndex', '-1');
          listItems[focusEl].tabIndex = '0';
          listItems[focusEl].focus();
        } else {
          listItems[0].tabIndex = '-1';
          listItems[listItems.length - 1].tabIndex = '0';
          listItems[listItems.length - 1].focus();
        }
      } else if (code === Keyboard.DOWN) {
        e.preventDefault();
        const focusEl = activeIndex + 1;
        if (listItems && listItems[focusEl]) {
          listItems[focusEl - 1].tabIndex = '-1';
          listItems[focusEl].tabIndex = '0';
          listItems[focusEl].focus();
        } else {
          listItems[listItems.length - 1].tabIndex = '-1';
          listItems[0].tabIndex = '0';
          listItems[0].focus();
        }
      } else if (code === Keyboard.TAB && listItems && alreadyVoted) {
        //To make the focus back to selected someone abruptly goes out from poll options list
        for (let index = 0; index < listItems.length; index++) {
          const votedPollBar = listItems[index] && listItems[index].querySelector('i');
          const isSelected =
            votedPollBar && votedPollBar.classList && votedPollBar.classList.contains('selected');
          setTimeout(() => {
            listItems[index].setAttribute('tabIndex', isSelected ? '0' : '-1');
          }, 100);
        }
      }
    } catch (e) {
      console.log('Error', e);
    }
  };

  const renderSponsor = () => {
    return (
      <Sponsor
        contentType={cardData && cardData.ContentType && cardData.ContentType.value}
        varient=""
        sponsorInfo={cardData && cardData.Sponsorby && getSponsorData(cardData.Sponsorby.fields)}
        grid={isDestinationPage ? 'grid-12' : gridClass}
        type={CONTENT_TYPE}
        author={
          cardData && cardData.manutds && cardData.manutds.value && cardData.manutds.value.pollId
        }
        cardName={cardData && cardData.Question && cardData.Question.value}
        container={container}
        createdDateTime={cardData && cardData.__Published && cardData.__Published.value}
        itemId={pollItemId}
        tags={cardData && cardData.manutds && cardData.manutds.value && cardData.manutds.value.Tags}
      ></Sponsor>
    );
  };

  const ariaLabel = (option, votePercent) => {
    try {
      if (pollClosed) {
        return `${option} ${votePercent}`;
      } else {
        return alreadyVoted
          ? hidePollStats()
            ? `${option}`
            : `${option} ${votePercent} `
          : option;
      }
    } catch (e) {
      return option;
    }
  };

  const isVoted = (pollId, index) => {
    //Function for  aria checked status update
    if (typeof window !== 'undefined') {
      const pollWrapper = document.querySelector(`#${pollId}`);
      const liTag = pollWrapper && pollWrapper.getElementsByTagName('li');
      const votedPollBar = liTag && liTag[index] && liTag[index].querySelector('i');
      const isSelected =
        votedPollBar && votedPollBar.classList && votedPollBar.classList.contains('selected');
      return isSelected;
    } else {
      return false;
    }
  };

  const voteDisabled = () => {
    return alreadyVoted || pollClosed || state.showLoader;
  };

  const hidePollStats = () => {
    return pageState === 'preview' ? false : isHidePollStats;
  };

  return (
    <Fragment>
      {isCbrModalVisible && !isDestinationPage && renderCBSModal()}
      <div
        className={`${isMicroCard ? '' : 'poll'} ${
          pollModalFlag || isDestinationPage || source === 'aws' ? 'grid-12' : gridClass
        } ${nativeCard ? 'ratio' : 'clearfix'}`}
        onClick={(e) => {
          onCardClick(e);
        }}
        disabled={!isLoginChecked ? true : false}
        data-impression={track.impression({
          card_name: cardData && cardData.Question && cardData.Question.value,
          content_type: cardData && cardData.ContentType && cardData.ContentType.value,
        })}
      >
        <div className="mu-content clearfix">
          <article className="mu-item poll">
            <div className="img-holder poll__img">
              {cardData && cardData.Image && cardData.Image.value && cardData.Image.value.Crops && (
                <ImgCrop
                  alt={cardData.Image.value.AltText}
                  title={cardData.Image.value.AltText}
                  imgCrop={cardData.Image.value.Crops}
                  isWebpAvailable={cardData.Image.value.isWebpAvailable}
                  singleSrc={false}
                  gridClass={gridClass}
                />
              )}
              {!nativeCard && renderSponsor()}
              <div
                className="poll__wrapper"
                id={
                  cardData &&
                  cardData.manutds &&
                  cardData.manutds.value &&
                  cardData.manutds.value.pollId
                }
              >
                {!nativeCard && (
                  <form>
                    <fieldset>
                      <legend className="poll__question">
                        {cardData && cardData.Question && cardData.Question.value}
                      </legend>
                      <span className="screenreader">{t('poll')}</span>
                      <h1 className="poll__question">
                        {cardData && cardData.Question && cardData.Question.value}
                      </h1>
                      <div
                        className={`poll__options ${pollClasses(
                          pollClosed,
                          hidePollStats(),
                          !alreadyVoted
                        )}`}
                      >
                        <ul role="radiogroup" tabIndex="-1">
                          {cardData &&
                            cardData.manutds &&
                            cardData.manutds.value &&
                            cardData.manutds.value.Options &&
                            cardData.manutds.value.Options.map((option, index) => (
                              <li
                                key={index}
                                tabIndex={
                                  isVoted(cardData.manutds.value.pollId, index)
                                    ? '0'
                                    : index === 0 && !alreadyVoted
                                    ? '0'
                                    : '-1 '
                                }
                                role="radio"
                                aria-label={ariaLabel(option, showNumber(index, true))}
                                aria-disabled={voteDisabled() ? true : false}
                                aria-checked={isVoted(cardData.manutds.value.pollId, index)}
                                onKeyDown={(e) => activeList(e, index)}
                                onClick={(e) => onPollClick(e, index)}
                                style={{ pointerEvents: voteDisabled() ? 'none' : 'auto' }}
                              >
                                <input
                                  aria-hidden="true"
                                  tabIndex="-1"
                                  type="radio"
                                  name="polloptions "
                                  value=" "
                                  //onClick={(e) => onPollClick(e, index) }
                                  className="polloption"
                                  disabled={voteDisabled() ? true : false}
                                  id={`${
                                    cardData &&
                                    cardData.manutds &&
                                    cardData.manutds.value &&
                                    cardData.manutds.value.pollId
                                  }${Math.random()}`}
                                />
                                <label
                                  htmlFor={`${
                                    cardData &&
                                    cardData.manutds &&
                                    cardData.manutds.value &&
                                    cardData.manutds.value.pollId
                                  }${index}`}
                                  tabIndex="-1"
                                  aria-hidden="true"
                                >
                                  <span className="valigntxt">{option}</span>
                                  <span className="poll__percentage">
                                    {showNumber(index, false)}
                                  </span>
                                </label>
                                <i
                                  style={{ width: showNumber(index, true) }}
                                  className={`opt${index}`}
                                ></i>
                                {hidePollStats() && <span className="icon-selected-circle"></span>}
                              </li>
                            ))}
                        </ul>
                        <p>
                          {alreadyVoted && (
                            <span>
                              {cardData &&
                                cardData.FinalResultMessage &&
                                cardData.FinalResultMessage.value}
                            </span>
                          )}
                          {pollClosed && <span>{t('thepollhasended')} </span>}
                        </p>
                        {state.showLoader && <Loader className="loader-container" />}
                      </div>
                    </fieldset>
                  </form>
                )}
              </div>
            </div>
            {nativeCard && renderSponsor()}
            {nativeCard && (
              <div className="mu-item__info">
                <h2 className="mu-item__title">
                  {cardData && cardData.Headline && cardData.Headline.value}
                </h2>
                {isMicroCard && (
                  <div className="link-btn-white">
                    <a href="#" aria-label={ctaHeading} onClick={(e) => e.preventDefault()}>
                      <span>
                        {ctaHeading}
                        <i className="icon-cta-arrow"></i>
                      </span>
                    </a>
                  </div>
                )}
                {!isMicroCard && (
                  <div>
                    <a
                      href="#"
                      className="white-btn-skew"
                      role="button"
                      aria-label={ctaHeading}
                      onClick={(e) => e.preventDefault()}
                      disabled={!isLoginChecked ? true : false}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          e.preventDefault();
                          onCardClick(e);
                        }
                      }}
                    >
                      <span className="cta-button-title">
                        {ctaHeading}
                        <i className="tag-white-arrow"></i>
                      </span>
                    </a>
                  </div>
                )}
              </div>
            )}
            {!isDestinationPage && (
              <div className="top-badges">
                <AccessBadge
                  contentAcessLocal={cardData.ContentAccess && cardData.ContentAccess.value}
                  contentTypeLocal={cardData.ContentType && cardData.ContentType.value}
                  tagListLocal={createTagArr(cardData.manutds)}
                />
              </div>
            )}
            {showStatsbar && !pollModalFlag && (
              <StatsBar item={getStatsBarData(cardData)} shareOnly={iscollection}></StatsBar>
            )}
          </article>
        </div>
      </div>
      <div></div>
      {isModalVisible && (
        <ErrorBoundary>
          <PollDetail
            fields={cardData}
            modalFlag={true}
            closeModalPopUp={closeModal}
            setAlreadyVotedHome={setAlreadyVoted}
            previousActiveElement={document.activeElement}
          />
        </ErrorBoundary>
      )}
    </Fragment>
  );
};

Poll.defaultProps = {
  iscollection: false,
  itemId: null,
  isMatchcenterCard: true,
  isDestinationPage: false,
  showStatsbar: true,
  nativeCard: false,
  gridClass: null,
  pollModalFlag: false,
  setAlreadyVotedHome: () => {},
};

export default withSitecoreContext()(withTranslation()(Poll));
