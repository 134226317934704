/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useEffect } from 'react';
import { buildImgUrl } from '../../utils/utils';
import { getMatchDate } from '../../utils/momentDate';
import { withTranslation } from 'react-i18next';
import {
  supportedLeagues,
  accessibilitySpotlight,
  updateGoal,
  calculateTimeLeft,
} from '../../utils/matchUtils';
import { DARK_THEME, LIGHT_THEME, MODULE } from '../../constants/globalConstants';
import Sponsor from '../Common/Sponsor';
import { i18n, roundMap } from '../../constants/i18nConstants';
import { SPOTLIGHT } from '../../constants/containerConstants';

const Spotlight = ({ t, spotItem, timerItem, contentType }) => {
  const [thunder, setThunder] = useState(false);
  const [spotlightAccessConst] = useState(accessibilitySpotlight());
  let timeoutId;

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(spotItem));
  /**
   * @useEffect {update timer on every 1 second }
   */
  useEffect(() => {
    if (contentType && contentType === 'timer') {
      timeoutId = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(spotItem));
      }, 1000);
    }
    return () => clearTimeout(timeoutId);
  });
  /**
   * @method {getOptaValue return opta content stored in spotItem}
   */
  const getOptaValue = () => {
    return spotItem &&
      spotItem.optacontent &&
      spotItem.optacontent.value &&
      spotItem.optacontent.value.length > 0
      ? spotItem.optacontent.value[0]
      : false;
  };

  const getMatchDataList = (optaValue) => {
    return (
      optaValue &&
      optaValue.MatchDataList &&
      optaValue.MatchDataList.length > 0 &&
      optaValue.MatchDataList[0]
    );
  };
  /**
   * @method {getRoundAndLeg return match league type}
   */
  const getRoundAndLeg = () => {
    let optaValue = getOptaValue();
    if (optaValue) {
      let matchDetail = getMatchDataList(optaValue);
      if (matchDetail) {
        let cCode = optaValue.CompetitionCode;
        let matchLegType = matchDetail.MatchType;
        if (
          cCode &&
          supportedLeagues.indexOf(cCode) != -1 &&
          matchDetail &&
          matchDetail.RoundType
        ) {
          let roundType = matchDetail.RoundType;
          if (roundType && roundType.toLowerCase() == 'round') {
            let changedRound = roundMap[cCode][roundType + ' ' + matchDetail.RoundNumber];
            return changedRound ? changedRound : '';
          }
          return roundType ? roundType : '';
        } else {
          if (matchLegType && matchLegType == '1st Leg') {
            return i18n.spotlight['1st Leg'];
          } else if (
            matchLegType &&
            (matchLegType == '2nd Leg' ||
              matchLegType == '2nd Leg Away Goal' ||
              matchLegType == '2nd Leg Cup Short')
          ) {
            return i18n.spotlight['2nd Leg'];
          } else {
            return '';
          }
        }
      }
    }
    return '';
  };
  /**
   * @method {getMatchAndRoundType return match round type}
   */
  const getMatchAndRoundType = (type) => {
    let optaValue = getOptaValue();
    let matchDetail = getMatchDataList(optaValue);
    return optaValue && matchDetail ? matchDetail[type] : false;
  };
  const winnerScore = function (matchDataList) {
    if (matchDataList) {
      let hs = matchDataList.SecondLegHomeTeamAggregateScore,
        as = matchDataList.SecondLegAwayTeamAggregateScore;
      return hs + '-' + as;
    }
  };
  const winnerTeamName = (matchDataList) => {
    if (matchDataList) {
      let wid = matchDataList.MatchWinner;
      if (matchDataList.HomeTeam && wid == matchDataList.HomeTeam.FID) {
        return matchDataList.HomeTeam.ShortClubName;
      } else if (matchDataList.AwayTeam && wid == matchDataList.AwayTeam.FID) {
        return matchDataList.AwayTeam.ShortClubName;
      }
    }
  };
  /**
   * @method {resultLine2Leg return winner name & score}
   */
  const resultLine2Leg = function () {
    let optaValue = getOptaValue();
    if (optaValue) {
      let matchDataList = getMatchDataList(optaValue);
      if (matchDataList) {
        let resultType = matchDataList.ResultType,
          matchType = matchDataList.MatchType;
        resultType = resultType ? resultType.toLowerCase() : resultType;
        matchType = matchType ? matchType.toLowerCase() : matchType;
        if (
          matchType &&
          (matchType == '2nd leg' ||
            matchType == '2nd leg away goal' ||
            matchType == '2nd leg cup short')
        ) {
          if (resultType && resultType == 'aggregate') {
            return (
              winnerTeamName(matchDataList) +
              ' ' +
              i18n.spotlight['win'] +
              ' ' +
              winnerScore(matchDataList) +
              ' ' +
              i18n.spotlight['aggregate text']
            );
          } else if (resultType && (resultType == 'awaygoals' || resultType == 'away goals')) {
            return (
              winnerTeamName(matchDataList) +
              ' ' +
              i18n.spotlight['win'] +
              ' ' +
              i18n.spotlight['awaygoal text']
            );
          } else if (
            resultType &&
            (resultType == 'penalty shootout' || resultType == 'penaltyshootout')
          ) {
            return (
              winnerTeamName(matchDataList) +
              ' ' +
              i18n.spotlight['win'] +
              ' ' +
              winnerScore(matchDataList) +
              ' ' +
              i18n.spotlight['penaltyshootout text']
            );
          } else if (
            (matchDataList.AwayTeam &&
              matchDataList.AwayTeam.PenaltyShots &&
              matchDataList.AwayTeam.PenaltyShots.length) ||
            (matchDataList.HomeTeam &&
              matchDataList.HomeTeam.PenaltyShots &&
              matchDataList.HomeTeam.PenaltyShots.length)
          ) {
            return i18n.spotlight['penaltyshoot text'];
          }
        }
      }
    }
  };
  /**
   * @method {calcAggScore return aggregate score}
   */
  const calcAggScore = () => {
    let optaValue = getOptaValue();
    if (optaValue) {
      let matchDataList = getMatchDataList(optaValue);
      if (matchDataList) {
        try {
          let hs = matchDataList.SecondLegHomeTeamAggregateScore,
            as = matchDataList.SecondLegAwayTeamAggregateScore,
            matchType = matchDataList.MatchType;
          if (
            (matchType === '2nd Leg' ||
              matchType === '2nd Leg Away Goal' ||
              matchType === '2nd Leg Cup Short') &&
            (typeof hs !== 'undefined' || typeof as !== undefined)
          ) {
            return (
              <div className="agg-score-top">({`${i18n.spotlight['agg text']} ${hs} - ${as}`})</div>
            );
          }
        } catch (e) {
          console.log('error in cal Agg Score', e);
        }
      }
    }
  };
  /**
   * @method {penScore return penalty shots score}
   */
  const penScore = () => {
    let optaValue = getOptaValue();
    if (optaValue) {
      let matchDataList = getMatchDataList(optaValue);
      if (matchDataList) {
        try {
          let home_pen = matchDataList.HomeTeam.PenaltyScore,
            away_pen = matchDataList.AwayTeam.PenaltyScore,
            home_pen_shots = matchDataList.HomeTeam.PenaltyShots,
            away_pen_shots = matchDataList.AwayTeam.PenaltyShots;
          if (
            (home_pen_shots.length > 0 || away_pen_shots.length > 0) &&
            (typeof home_pen !== 'undefined' || typeof away_pen !== 'undefined')
          ) {
            return i18n.spotlight['pen text'] + ' (' + home_pen + ' - ' + away_pen + ')';
          } else {
            return false;
          }
        } catch (e) {
          return false;
        }
      } else return false;
    } else return false;
  };
  /**
   * @method {getPenaltyShots return penalty shots icon}
   * @param {team:HomeTeam, team:AwayTeam}
   */
  const getPenaltyShots = (team) => {
    let optaValue = getOptaValue();
    if (optaValue) {
      let matchDataList = getMatchDataList(optaValue);
      if (matchDataList) {
        return (
          <div className="penalty__details">
            <div className="row">
              {matchDataList[team] &&
                matchDataList[team].PenaltyShots &&
                matchDataList[team].PenaltyShots.length > 0 &&
                matchDataList[team].PenaltyShots.map((obj, key) => (
                  <span key={key}>
                    <i className={obj.Outcome === 'Scored' ? 'icon-pen-pass' : 'icon-pen-fail'}></i>
                  </span>
                ))}
            </div>
          </div>
        );
      }
    }
  };
  /**
   * @method {penscoreScreenReader render pen score for accessibility}
   */
  const penscoreScreenReader = () => {
    let optaValue = getOptaValue();
    if (optaValue) {
      let matchDataList = getMatchDataList(optaValue);
      if (
        matchDataList &&
        matchDataList.HomeTeam &&
        matchDataList.HomeTeam.PenaltyShots &&
        matchDataList.HomeTeam.PenaltyShots.length > 0 &&
        matchDataList.AwayTeam &&
        matchDataList.AwayTeam.PenaltyShots &&
        matchDataList.AwayTeam.PenaltyShots.length > 0
      ) {
        return (
          <span className="screenreader">
            {`${matchDataList.HomeTeam.PenaltyScore} ${spotlightAccessConst.outOf} ${
              matchDataList.HomeTeam.PenaltyShots.length
            } ${spotlightAccessConst.penaltyshots} ${t('by')} ${matchDataList.HomeTeam.TeamName} ${
              matchDataList.AwayTeam.PenaltyScore
            } ${spotlightAccessConst.outOf} ${matchDataList.AwayTeam.PenaltyShots.length} ${
              spotlightAccessConst.penaltyshots
            } ${t('by')} ${matchDataList.AwayTeam.TeamName}`}
          </span>
        );
      }
    }
  };
  /**
   * @method {getScore return live & result score}
   *   @param {team:HomeTeam, team:AwayTeam}
   *   @param {team id teamId:FID}
   */

  const getScore = (team, teamId) => {
    let optaValue = getOptaValue();
    if (optaValue) {
      let matchDataList = getMatchDataList(optaValue);
      if (matchDataList) {
        if (contentType === 'result') return matchDataList[team].Score;
        else if (contentType === 'live' && timerItem && timerItem.optacontent) {
          return teamId == 1
            ? timerItem.optacontent.GoalByManUtd
            : timerItem.optacontent.GoalByOpponent;
        }
      }
    }
  };
  /**
   * @method {getTeamName return team name}
   * @param {teamObj:HomeTeam, teamObj:AwayTeam}
   */
  const getTeamName = (teamObj) => {
    try {
      if (teamObj && teamObj.ShortClubName) {
        return teamObj.ShortClubName;
      }
      return teamObj.TeamName;
    } catch (e) {
      console.log('error in checkTeamNames Obj', e);
    }
  };
  const getTeamVsTeam = () => {
    if (contentType === 'timer' || contentType === 'fixture') {
      return <div>{t('VS')}</div>;
    }
  };
  const getHypenAndScore = (score, team, teamName) => {
    if (contentType === 'result' || contentType === 'live') {
      return (
        <div>
          <span aria-hidden="true" className="inplay-goals">
            {score}
            {team === 'HomeTeam' && <i className="hypen">-</i>}
          </span>
          <span className="screenreader">{`${t('Goals')} ${t('by')} ${teamName} ${score}`}</span>
        </div>
      );
    }
  };
  /**
   * @method {displayLogoAndScore used to display logo,name & score of the team}
   * @param {team:HomeTeam, team:AwayTeam}
   */
  const displayLogoAndScore = (team) => {
    let optaValue = getOptaValue();
    if (optaValue) {
      let matchDataList = getMatchDataList(optaValue);
      if (matchDataList) {
        let score = getScore(team, matchDataList[team].FID);
        let teamName = getTeamName(matchDataList[team]);
        return (
          <React.Fragment>
            <div className="team-logo">
              <div className="match-goal">{getHypenAndScore(score, team, teamName)}</div>
              <img
                src={
                  matchDataList &&
                  matchDataList[team].TeamCrestImage &&
                  matchDataList[team].TeamCrestImage.img1x &&
                  buildImgUrl(matchDataList[team].TeamCrestImage.img1x)
                }
                alt={teamName}
              />
            </div>
            <h6 aria-hidden="true">{teamName}</h6>
          </React.Fragment>
        );
      }
    }
  };
  /**
   * @method {renderShortPeriod used to display short period name}
   */
  const renderShortPeriod = () => {
    let p =
      timerItem &&
      timerItem.optacontent &&
      timerItem.optacontent.Period &&
      timerItem.optacontent.Period.toLowerCase();
    if (
      (p && p === 'fulltime') ||
      p === 'full time' ||
      p === 'fulltime90' ||
      p === 'full time 90'
    ) {
      return (
        <span aria-label="full time">
          <span aria-hidden="true">{t('FT')}</span>
        </span>
      );
    } else if (
      (p && p === 'fulltimeaet') ||
      p === 'full time aet' ||
      p === 'extrafulltime' ||
      p === 'extra full time' ||
      p === 'shootout'
    ) {
      return (
        <span>
          {t('FT')} <span className="extra__time">{t('AET')}</span>
        </span>
      );
    } else if (p && p === 'extrahalftime') {
      return (
        <span>
          {t('HT')} <span className="extra__time">ET</span>
        </span>
      );
    }
  };
  /**
   * @method {getPlayerName return player name}
   */
  const getPlayerName = (obj) => {
    return obj && obj.KnownName ? obj.KnownName : obj.LastName;
  };
  /**
   * @method {filterGoalData return goal filter data}
   * @param {team:HomeTeam, team:AwayTeam}
   */
  const filterGoalData = (team) => {
    let optaValue = getOptaValue();
    if (optaValue) {
      let matchDataList = getMatchDataList(optaValue);
      if (matchDataList) {
        let goalArr = matchDataList && updateGoal(matchDataList[team].GoalDetails);
        let teamName = getTeamName(matchDataList[team]);
        let bookingArr = matchDataList[team].Bookings;
        return (
          <React.Fragment>
            {contentType != 'timer' && contentType != 'fixture' && (
              <div className="spot-holder team-details">
                {goalArr && goalArr.length > 0 && (
                  <p className="screenreader">
                    {`${spotlightAccessConst.activitydoneby} ${teamName}`}
                  </p>
                )}
                <ul className="player-goal-details">
                  {goalArr &&
                    goalArr.length > 0 &&
                    goalArr.map((value, k) => value && value.player?(
                      <li className="inplay__detail" key={k}>
                        <span aria-hidden="true">{getPlayerName(value.player)}</span>
                        <span aria-hidden="true"> {value.time}</span>
                        {value.type && value.type.toLowerCase() === 'penalty' && (
                          <span aria-hidden="true"> ({i18n.spotlight['penalty']})</span>
                        )}
                        {value.type && value.type.toLowerCase() === 'own' && (
                          <span aria-hidden="true"> (og)</span>
                        )}
                        <i aria-hidden="true" className="icon-goal-shape"></i>
                        <span className="screenreader">
                          {`${t('Goals')} ${t('by')} ${getPlayerName(value.player)} ${value.time}
                          ${spotlightAccessConst.timeago}`}
                        </span>
                      </li>
                    ):'')}
                  {bookingArr &&
                    bookingArr.length > 0 &&
                    bookingArr.map(
                      (value, k) =>
                        value && value.Card &&
                        value.Card.toLowerCase() === 'red' && value.Player ?(
                          <li className="inplay__detail" key={k}>
                            <span aria-hidden="true">{getPlayerName(value.Player)}</span>
                            <span aria-hidden="true"> {value.Time}</span>
                            <i
                              aria-hidden="true"
                              className={
                                value.Card === 'yellow'
                                  ? 'yellow-card'
                                  : value.Card.toLowerCase() === 'red'
                                  ? 'red-card'
                                  : ''
                              }
                            ></i>
                            <span className="screenreader">
                              {`${value.Card} ${spotlightAccessConst.cardrecivedby} ${getPlayerName(
                                value.Player
                              )} ${value.Time} ${spotlightAccessConst.timeago}`}
                            </span>
                          </li>
                        ):''
                    )}
                </ul>
              </div>
            )}
          </React.Fragment>
        );
      }
    }
  };
  /**
   * @method {accessAgg return goal filter data}
   */
  const accessAgg = () => {
    let optaValue = getOptaValue();
    if (optaValue) {
      let matchDataList = getMatchDataList(optaValue);
      if (matchDataList) {
        try {
          let hs = matchDataList.SecondLegHomeTeamAggregateScore,
            as = matchDataList.SecondLegAwayTeamAggregateScore;
          if (hs && as) {
            return (
              <p className="screenreader">
                {`${hs} ${spotlightAccessConst.aggscoreof} ${getTeamName(matchDataList.HomeTeam)} ${
                  spotlightAccessConst.and
                } ${as} ${spotlightAccessConst.aggscoreof} ${getTeamName(matchDataList.AwayTeam)}`}
              </p>
            );
          }
        } catch (e) {}
      }
    }
  };
  /**
   * @method {getSponsorTheam sponsor theam}
   */
  const getSponsorTheam = () => {
    return timerItem && timerItem.injuryTime ? DARK_THEME : LIGHT_THEME;
  };
  /**
   * @method {updateTimerSpotlight used to update live score on the spotlight}
   */
  const getKickOff = () => {
    if (contentType && (contentType === 'fixture' || contentType === 'timer')) {
      return (
        <React.Fragment>
          {spotItem.isabandoned_b && <span>{t('Matchabandoned')}</span>}
          {spotItem.matchdate_tdt && !spotItem.isabandoned_b && !spotItem.ispostponed_b && (
            <span>
              {t('KickOff')} {getMatchDate(spotItem.matchdate_tdt, 'HH:mm')}
            </span>
          )}
        </React.Fragment>
      );
    }
  };
  return (
    spotItem &&
    getOptaValue() &&
    getMatchDataList(getOptaValue()) && (
      <React.StrictMode>
        {contentType && contentType === 'result' && (
          <span className="screenreader">{t('Matchinformationresult')}</span>
        )}
        {contentType && contentType != 'result' && (
          <span className="screenreader">{t('MatchInformation')}</span>
        )}
        <div className="match-day__spotlight">
          <div className="show-alternet-screen" onClick={() => setThunder(!thunder)}>
            <i className="icon-thunder"></i>
          </div>
          <div
            className="match-item__competion-leguename matchday-desktop"
            role="region"
            aria-labelledby="matchday-spotlight-info"
          >
            {contentType && contentType === 'fixture' && (
              <div className="tbc_wrapper">
                {spotItem.ismatchdatetbc_b && (
                  <div className="tbc_content">{spotItem.matchdatetbc_t}</div>
                )}
              </div>
            )}
            <p id="matchday-spotlight-info" className="match-item__competion__name">
              <span>{getOptaValue().CompetitionName + (getRoundAndLeg() ? ', ' : '')}</span>
              {getMatchAndRoundType('RoundType') && getMatchAndRoundType('MatchType') && (
                <span>{getRoundAndLeg()}</span>
              )}
            </p>
            {getMatchAndRoundType('MatchType') && (
              <p className="match-item__competion__name">{resultLine2Leg()}</p>
            )}
            {spotItem.ispostponed_b && (
              <p className="match-item__competion__teasure">{t('Datetobeconfirmed')}</p>
            )}
            {spotItem.matchdate_tdt && !spotItem.ispostponed_b && (
              <p className="match-item__competion__teasure">
                {getMatchDate(spotItem.matchdate_tdt, 'dddd D MMMM yyyy')}
              </p>
            )}
            <p className="match-item__competion__teasure">{getKickOff()}</p>
          </div>
          {contentType != 'fixture' && (
            <div
              className={`match-item__timer ${
                contentType === 'live' && timerItem && timerItem.injuryTime ? 'injury-time' : ''
              }`}
              data-container="spotlight"
            >
              <div className="match-item__logo">
                {contentType == 'timer' && (
                  <Sponsor
                    type={MODULE}
                    moduleName={'countdownclock'}
                    theme={LIGHT_THEME}
                    container={SPOTLIGHT}
                    contentType={contentType}
                    cardName={`Match Clock`}
                    createdDateTime={spotItem && spotItem.__published_tdt}
                    itemId={spotItem && spotItem.itemid_s}
                    tags={spotItem && spotItem.taglist_sm}
                  ></Sponsor>
                )}
                {contentType != 'timer' && (
                  <Sponsor
                    type={MODULE}
                    moduleName={'matchclock'}
                    theme={getSponsorTheam()}
                    container={SPOTLIGHT}
                    contentType={contentType}
                    cardName={`Match Clock`}
                    createdDateTime={spotItem && spotItem.__published_tdt}
                    itemId={spotItem && spotItem.itemid_s}
                    tags={spotItem && spotItem.taglist_sm}
                  ></Sponsor>
                )}
              </div>
              {contentType === 'timer' && timeLeft.hours !== 'zero' && (
                <p className="match-item__spot-timer state__timer" id="update-timer">
                  <span aria-hidden="true">{timeLeft.hours}</span>
                  <span className="countdown-seprator" aria-hidden="true">
                    :
                  </span>
                  <span aria-hidden="true">{timeLeft.minutes}</span>
                  <span className="countdown-seprator" aria-hidden="true">
                    :
                  </span>
                  <span aria-hidden="true">{timeLeft.seconds}</span>
                  <span className="screenreader">
                    {`${spotlightAccessConst.timeRemaining} ${timeLeft.hours} ${spotlightAccessConst.hrsTime} ${timeLeft.minutes} ${timeLeft.seconds} ${spotlightAccessConst.minsTime}`}
                  </span>
                </p>
              )}
              {contentType === 'timer' && timeLeft.hours === 'zero' && (
                <p className="match-item__spot-timer" id="update-timer">
                  <span aria-hidden="true">00:00:00</span>
                </p>
              )}
              {contentType != 'timer' && contentType != 'fixture' && (
                <div className="spot-holder">
                  {contentType != 'result' && (
                    <p className="match-item__spot-timer" id="spotlight__timer">
                      <span
                        className="match-mins"
                        aria-hidden="true"
                        dangerouslySetInnerHTML={{ __html: timerItem && timerItem.timerText }}
                      ></span>
                      {timerItem && timerItem.injuryTime && (
                        <span className="injury-time__min" aria-hidden="true">
                          + {timerItem.injuryTime}
                        </span>
                      )}
                      {timerItem && !timerItem.injuryTime && (
                        <span className="screenreader">
                          {spotlightAccessConst.timeElapsed}
                          <span className="match-mins" aria-hidden="true">
                            {timerItem.timerText}
                          </span>
                          {spotlightAccessConst.minsTime}
                        </span>
                      )}
                      {timerItem && timerItem.injuryTime && (
                        <span className="screenreader">
                          {spotlightAccessConst.timeElapsed}
                          <span className="match-mins" aria-hidden="true">
                            {timerItem.timerText}
                          </span>
                          {`${timerItem && timerItem.injuryTime} ${spotlightAccessConst.minsTime}`}
                        </span>
                      )}
                    </p>
                  )}
                  {contentType === 'result' && timerItem && (
                    <p className="match-item__spot-timer abc">{renderShortPeriod()}</p>
                  )}
                </div>
              )}
            </div>
          )}
          <div className="match-item__wrapper">
            <div className="match-item">
              {(contentType === 'live' || contentType === 'result') && (
                <React.Fragment>
                  {penScore() !== false && (
                    <div className="match-penalty">
                      <div className="match-penalty__team-a">{getPenaltyShots('HomeTeam')}</div>
                      <div className="match-penalty__score">
                        <span aria-hidden="true">
                          {penScore() !== false && penScore().toUpperCase()}
                        </span>
                        <div aria-hidden="true" className="icon-penalty"></div>
                        {penscoreScreenReader()}
                      </div>
                      <div className="match-penalty__team-b">{getPenaltyShots('AwayTeam')}</div>
                    </div>
                  )}
                </React.Fragment>
              )}
              <section className="match-item__info">
                <div
                  className={`match-item__info__team team__a ${
                    contentType === 'fixture' || contentType === 'timer' ? 'pre_match' : ''
                  }`}
                >
                  {displayLogoAndScore('HomeTeam')}
                  {filterGoalData('HomeTeam')}
                </div>
                <div className={`match-item__info__team match-goal not-started ${contentType}`}>
                  <div className="match-vs" aria-hidden="true">
                    {getTeamVsTeam()}
                    {calcAggScore()}
                  </div>
                  <span className="screenreader">
                    {`${spotItem.optacontent.value[0].MatchDataList[0].HomeTeam.TeamName} ${spotlightAccessConst.vs} ${spotItem.optacontent.value[0].MatchDataList[0].AwayTeam.TeamName}`}
                  </span>
                </div>
                <div
                  className={`match-item__info__team team__b  ${
                    contentType === 'fixture' || contentType === 'timer' ? 'pre_match' : ''
                  }`}
                >
                  {displayLogoAndScore('AwayTeam')}
                  {filterGoalData('AwayTeam')}
                </div>
                {accessAgg()}
              </section>
            </div>
          </div>
        </div>
      </React.StrictMode>
    )
  );
};
export default withTranslation()(Spotlight);
