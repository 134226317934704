/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

export const STYLE_AMP_BOILERPLATE = `body {
    -webkit-animation: -amp-start 8s steps(1, end) 0s 1 normal both;
    -moz-animation: -amp-start 8s steps(1, end) 0s 1 normal both;
    -ms-animation: -amp-start 8s steps(1, end) 0s 1 normal both;
    animation: -amp-start 8s steps(1, end) 0s 1 normal both;
  }

  @-webkit-keyframes -amp-start {
      from {
          visibility: hidden;
      }

      to {
          visibility: visible;
      }
  }

  @-moz-keyframes -amp-start {
      from {
          visibility: hidden;
      }

      to {
          visibility: visible;
      }
  }

  @-ms-keyframes -amp-start {
      from {
          visibility: hidden;
      }

      to {
          visibility: visible;
      }
  }

  @-o-keyframes -amp-start {
      from {
          visibility: hidden;
      }

      to {
          visibility: visible;
      }
  }

  @keyframes -amp-start {
      from {
          visibility: hidden;
      }

      to {
          visibility: visible;
      }
  }`;

export const STYLE_AMP_BOILERPLATE_NO_SCRIPT = `<style amp-boilerplate>
  body {
      -webkit-animation: none;
      -moz-animation: none;
      -ms-animation: none;
      animation: none;
  }
</style>`;

export const EN_STYLE = `
@font-face {
  font-family: 'BebasNeue';
  font-style: normal;
  font-weight: 400;
  src: local('BebasNeue'), url(/amp/fonts/bebasneue_bold-webfont.ttf) format('truetype');
}

.dfp-ad.square {
  margin-left: calc(50% - 150px);
}

.dfp-ad.banner {
  margin-left: calc(50% - 160px);
}

.screenreader {
  position: absolute;
  left: -10000px;
  top: auto;
  top: initial;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  color: #1a1a1a;
}

.mu-logo {
  margin: 10px 0 10px 16px;
}

header .top-container {
  height: 66px;
  background-image: radial-gradient(circle at 50% 55%, #cc1a1a, #c70101), linear-gradient(#c70101, #c70101);
}

header .bottom-container {
  height: 45px;
  background: black;
}

  header .bottom-container .hamburger {
      color: #fff;
      height: 20px;
      width: 20px;
      float: left;
      position: relative;
      display: block;
      left: 16px;
      top: 13px;
  }

      header .bottom-container .hamburger span {
          background: white;
          display: block;
          height: 2px;
          border-radius: 1px;
          position: relative;
      }

.hamburger span:nth-child(2),
.hamburger span:nth-child(3) {
  margin-top: 6px;
}

.home-header__sponsor {
  margin: 20px 16px;
  width: auto;
  display: block;
  float: right;
}

  .home-header__sponsor a {
      float: right;
      height: 25px;
      border-radius: 4px;
      background-color: #000;
  }

      .home-header__sponsor a .sponsor-txt {
          letter-spacing: .4px;
          color: #fff;
          font-size: 10px;
          font-size: .625rem;
          margin-left: 15px;
          float: left;
          line-height: 13px;
          margin-top: 6px;
      }

  .home-header__sponsor amp-img {
      margin: 8px 10px;
      width: 58px;
  }

amp-sidebar {
  background-color: #ffffff;
  width: 200px;
}

ul.sidebar {
  margin: 0;
  padding: 0 16px 150px;
}

  ul.sidebar li {
      list-style: none;
      border-bottom: 1px solid #e5e5e5;
      padding: 0 12px;
  }

      ul.sidebar li a {
          color: #1a1a1a;
          /*font-family: SourceSansProRegular, Helvetica, sans-serif;*/
          font-size: 1rem;
          font-weight: 600;
          text-decoration: none;
          display: inline-block;
          height: 45px;
          line-height: 45px;
      }

.close-sidebar {
  line-height: 22px;
  height: 22px;
  width: 22px;
  border: 1px solid;
  text-align: center;
  color: #fff;
  background: black;
  border-radius: 50%;
  font-size: 12px;
  margin: 10px;
  position: relative;
  right: 0;
}

.bottom-sponsor {
  display: inline-block;
  float: right;
  margin-right: 6px;
}

  .bottom-sponsor ul {
      list-style: none;
      padding: 0px;
      margin: 0px;
      margin-top: 4px;
  }

  .bottom-sponsor li {
      display: inline-block;
      padding: 5px 6px;
  }

  .bottom-sponsor amp-img img, .sponsor__primary amp-img img, .sponsor__secondary amp-img img {
      object-fit: contain;
  }
/*Footer Start*/

footer.footer {
  float: left;
  background: white;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

footer ul {
  padding: 0px;
}

footer .sponsor ul li {
  list-style: none;
  display: inline-block;
}

footer ul li a {
  color: #1a1a1a;
  text-decoration: none;
}

footer .sponsor h2 {
  font-size: .875rem;
  line-height: 16px;
  letter-spacing: .4px;
  font-family: Montserrat, sans-serif;
  text-align: left;
  margin-left: 16px;
  margin-bottom: 24px;
  text-transform: uppercase;
}

footer .sponsor__primary ul li a,
footer .sponsor__secondary ul li a {
  color: #1a1a1a;
  text-decoration: none;
  padding: 10px 16px;
  border-bottom: 1px solid transparent;
  display: inline-block;
  /*margin: 0 8px 8px 0;     */
}

.footer__social-links {
  float: left;
  background: #2a2a2a;
  width: 100%;
  min-height: 132px;
  text-align: center;
  position: relative;
  clear: both;
}

  .footer__social-links .social-icons {
      padding-top: 24px;
      padding-bottom: 24px;
  }

      .footer__social-links .social-icons ul {
          float: none;
          padding: 0 75px;
      }

          .footer__social-links .social-icons ul li {
              margin-right: 8px;
              margin-bottom: 8px;
              display: inline-block;
              /*padding: 0px;*/
          }

.footer__menu {
  float: left;
  background: #1a1a1a;
  width: 100%;
  border-top: 1px solid rgba(217, 217, 217, .3);
  padding-top: 7px;
  margin: 0px;
  padding: 0px;
}

.footer__menu__links {
  padding: 0px 16px;
}

.footer__menu .footer__menu__links ul {
  margin-bottom: 20px;
  width: 100%;
  margin: 0px 0px 20px;
  padding: 0px;
}

  .footer__menu .footer__menu__links ul li {
      width: 100%;
      border-bottom: 1px solid rgba(229, 229, 229, .2);
      text-align: left;
      padding: 15px 0;
      line-height: normal;
      list-style-type: none;
  }

      .footer__menu .footer__menu__links ul li:last-child {
          border: none;
      }

      .footer__menu .footer__menu__links ul li a {
          color: #fff;
          font-size: 14px;
          font-size: .875rem;
      }

.footer__menu .footer__menu__copyright ul li {
  color: #a3a3a3;
  font-size: 14px;
  font-size: .875rem;
  line-height: 19px;
  padding: 0 16px;
  margin-bottom: 20px;
  list-style: none;
}

.sponsor {
  text-align: center;
  padding: 24px 0;
}

.social-button {
  margin-bottom: 24px;
}
/*Footer End*/
/*Main Container*/

.content-panel_text {
  padding-left: 16px;
  padding-right: 16px;
  /*font-size:20px;*/
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 30px;
  margin-top: 24px;
  clear: both;
}

  .content-panel_text a {
      color: inherit;
      text-decoration: none;
      border-bottom: 1px solid #9c9c9c;
  }

  .content-panel_text blockquote {
      margin: 0px;
      display: inline;
  }

blockquote, q {
  quotes: none;
}

.content-bar {
  font-size: .875rem;
  line-height: normal;
  margin: 16px;
  padding-bottom: 16px;
}

.top-panel h1 {
  font-family: 'BebasNeue', serif, sans-serif;
  font-size: 36px;
  margin: 16px 16px 0px;
  font-weight: normal;
  line-height: 1;
}

.heading {
  font-size: 24px;
  line-height: 34px;
  letter-spacing: -.2px;
}

.social__holder {
  font-size: 14px;
  margin: 14px 16px 0px;
}

span.social__time {
  float: right;
}
/*Article card start*/

.mu-item,
.image-panel {
  margin: 24px 16px 0px;
  background: #ececec;
}

  .mu-item.profile {
      background: transparent;
  }

.mu-item__info {
  padding: 0px 16px 16px;
}

.mu-item__title {
  position: relative;
  margin-top: 36px;
  font-family: 'BebasNeue', serif, sans-serif;
  font-size: 24px;
  margin-bottom: 0px;
}

  .mu-item__title:before,
  .mu-item__logo-title:before {
      border-top: 2px solid #c81617;
      position: absolute;
      width: 61px;
      content: '';
      top: -16px;
  }

.mu-item__teasure {
  font-size: 1.25rem;
  font-weight: 300;
}
/*Main container End*/
/*Buttons*/

.btn-skew {
  display: inline-block;
  white-space: nowrap;
  transform: skew(-10deg);
  margin: 0 auto;
  vertical-align: middle;
  border: 1px solid rgba(255, 255, 255, .15);
}

  .btn-skew a {
      padding: 14px 26px;
      float: left;
      text-decoration: none;
      color: white;
      font-size: .875rem;
      font-family: 'Source Sans Pro', Helvetica, sans-serif;
      font-style: italic;
      letter-spacing: .6px;
      transform: skew(10deg);
      text-transform: uppercase;
  }

  .btn-skew.red {
      background: #c70101;
      margin-left: 8px;
  }
/*Buttons End*/
/*Quote Card*/

.quote blockquote {
  margin: 0px;
}

.quote a {
  text-decoration: none;
}

.quote .mu-item__title {
  font-size: 1.125rem;
  line-height: 26px;
  font-family: 'Source Sans Pro', sans-serif;
  margin-top: 16px;
}

.mu-quotes__title {
  font-size: 1.25rem;
  line-height: 26px;
  margin-top: 3px;
}

.mu-item a,
.mu-item__title {
  color: #1a1a1a;
}

.mu-item.print-prod .mu-item__logo-title {
  font-size: 12px;
  font-size: .75rem;
  line-height: .9;
  font-family: Montserrat, Helvetica, sans-serif;
  text-transform: uppercase;
  margin-bottom: 12px;
  position: relative;
  margin-top: 36px;
}

.mu-item.print-prod a,
.mu-item.dfp-ads-card a {
  text-decoration: none;
}

.mu-item.print-prod .mu-item__title {
  margin-top: 0px;
  text-decoration: underline;
}

.mu-item.dfp-ads-card .dfp-ads__cta {
  margin-top: 0px;
  text-decoration: underline;
  font-style: italic;
  text-transform: uppercase;
  font-size: .875rem;
  font-style: italic;
  text-transform: uppercase;
  letter-spacing: .6px;
  font-weight: 600;
}

.mu-item.print-prod .mu-item__title:before,
.mu-item.dfp-ads-card .mu-item__title:before, .mu-item.quote .mu-item__title:before {
  display: none;
}

.mu-item.profile a {
  font-family: BebasNeue;
}

.mu-item.profile span {
  font-family: BebasNeue;
  font-size: 24px;
  margin-right: 2px;
}

  .mu-item.profile span:after {
      content: ':';
  }

.mu-item.profile h2.player-name {
  display: inline;
}

.mu-item.dfp-ads-card .mu-item__title {
  font-family: Montserrat;
  text-transform: uppercase;
  font-size: 1.25rem;
  line-height: 24px;
  margin-top: 10px;
  margin-bottom: 12px;
}
/*Table */

.mu-table {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 20px;
  padding-top: 20px;
  max-width: 670px;
  float: none;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  overflow: auto;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 auto;
  width: 100%;
  float: left;
  float: none;
}

  table tr {
      border-bottom: 1px solid rgba(0, 0, 0, .1);
  }

  table td {
      padding: 5px 0;
      font-size: 1rem;
      float: none;
      position: relative;
      text-align: left;
      line-height: 1.35;
      display: inline-block;
      word-break: break-word;
      padding: 20px 0;
      display: table-cell;
      white-space: nowrap;
      padding-left: 10px;
  }

amp-twitter {
  margin: 0px;
}

.dfp-ad {
  margin-top: 24px;
}

.mu-item.profile .mu-item__info {
  padding: 0px;
}

#banner {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

  #banner > #banner-logo {
      padding: 8px;
  }

  #banner > #banner-text {
      flex: 1;
      font-size: 14px;
      padding: 0 8px;
  }

body {
  background-color: #2b2b2b;
}

.main-container {
  background-color: white;
  padding-bottom: 24px;
}

  .main-container:after {
      display: table;
      content: '';
      clear: both;
  }

amp-social-share.custom-style {
  background-color: #008080;
  background-image: url('https://raw.githubusercontent.com/google/material-design-icons/master/social/1x_web/ic_share_white_48dp.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

amp-social-share.rounded {
  border-radius: 50%;
  background-size: 60%;
  margin-left: 3px;
}

  amp-social-share.rounded:first-child {
      margin-left: 0px;
  }

amp-user-notification > div {
  font: 12px/1.5 Helvetica, Arial, sans-serif;
  color: #fff;
  background: #000;
  padding: 1rem;
}

  amp-user-notification > div a {
      color: #fff;
  }

amp-user-notification button {
  margin-top: 10px;
  height: 25px;
}

.mu-item__sponsor {
  background: #f8f8f8;
  float: left;
  max-height: 40px;
  margin: 16px 16px 30px;
}

  .mu-item__sponsor a {
      display: inline-block;
      padding: 6px 8px;
      float: left;
      text-decoration: none;
  }

      .mu-item__sponsor a span:first-child {
          color: #858585;
          margin-right: 16px;
          padding-top: 6px;
          padding-bottom: 6px;
          text-transform: uppercase;
          font-size: .75rem;
          float: left;
      }

.social__holder-bottom {
  margin-top: 32px;
  float: left;
  width: 100%;
}

.sponsor__primary amp-img img {
  object-fit: contain;
}

.sponsor__secondary amp-img img {
  object-fit: contain;
}

.content-panel_recommendations ul {
  list-style: none;
  padding: 0px;
}

.content-panel_recommendations .player-title {
  font-family: 'BebasNeue', serif, sans-serif;
  font-size: 40px;
  margin: 24px 16px 0px;
}

.bottom-sponsor amp-img img {
  object-fit: contain;
}

.mu-item__sponsor.top amp-img img {
  object-fit: contain;
}

.mu-item__sponsor.top .sponsorspan {
  display: inline-block;
  margin-top: 2px;
  vertical-align: middle;
  margin-bottom: 2px;
}

/*Cookie Policy Consent Starts here*/
.cookie-policy-message {
  float: left;
  background: #1a1a1a;
  padding: 20px 10px;
  position: fixed;
  z-index: 9999;
  color: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  -moz-border-radius: 16px 16px 0 0;
  border-radius: 16px 16px 0 0;
  -webkit-border-radius: 16px 16px 0 0;
  -webkit-mask-image: -webkit-radial-gradient(white,#000);
}

.cookie-message__content .dismiss-button {
  text-align: right;
}

.cookie-policy-message.non-eu {
  padding: 22px;
}

.cookie-policy-message h2 {
  margin: 5px 0;
}

.cookie-policy-message p {
  font-size: 14px;
  line-height: 20px;
  margin: 9px 0;
}

.cookie-policy-message.non-eu p {
  font-size: 14px;
  text-align: left;
}

.cookie-policy-message button.black-btn-skew span,
.cookie-policy-message button.red-btn-skew span {
  line-height: 30px;
}

.cookie-policy-message .cookie-buttons {
  border-top: 1px solid rgba(223, 223, 223, 0.1);
  margin-top: 19px;
  padding-top: 17px;
  text-align: center;
}

.cookie-policy-message.non-eu .cookie-buttons {
  border-top: none;
  margin-top: 8px;
}

.cookie-policy-message .cookie-buttons .black-btn-skew {
  border: 1px solid #ddd;
}

.cookie-policy-message .cookie-buttons .btn {
  width: 135px;
}

.cookie-message__content {
  text-align: center;
}

  .cookie-message__content span {
      font-size: 16px;
      font-weight: bold;
  }

.cookie-setting__content p {
  margin: 3px auto 0;
}

.cookie-setting__content h2 + p {
  margin: 8px 0 20px;
}

.cookie-policy-message .cookie-buttons .black-btn-skew {
  border: 1px solid #ddd;
}

.cookie-policy-message .black-btn-skew {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #1a1a1a;
  transition: background 1s ease;
  -webkit-transition: background 1s ease;
  -moz-transition: background 1s ease;
  -o-transition: background 1s ease;
}

.cookie-policy-message .black-btn-skew,
.cookie-policy-message .link-btn-black,
.cookie-policy-message .link-btn-white,
.cookie-policy-message .red-btn-skew,
.cookie-policy-message .transparent-btn-skew,
.cookie-policy-message .white-btn-skew {
  display: inline-block;
  white-space: nowrap;
  height: 50px;
  margin: 0 auto;
  border-radius: 25px;
  cursor: pointer;
  padding: 0;
  text-transform: uppercase;
  margin: 0 5px;
}

  .cookie-policy-message .black-btn-skew span,
  .cookie-policy-message .link-btn-black span,
  .cookie-policy-message .link-btn-white span,
  .cookie-policy-message .red-btn-skew span,
  .cookie-policy-message .transparent-btn-skew span,
  .cookie-policy-message .white-btn-skew span {
      color: #1a1a1a;
      font-size: 14px;
      font-size: 0.875rem;
      /* float: left; */
      line-height: 50px;
      padding: 0 32px;
      text-align: center;
      padding: 0;
  }

  .cookie-policy-message .black-btn-skew i,
  .cookie-policy-message .link-btn-black i,
  .cookie-policy-message .link-btn-white i,
  .cookie-policy-message .red-btn-skew i,
  .cookie-policy-message .transparent-btn-skew i,
  .cookie-policy-message .white-btn-skew i {
      display: none;
      margin: 3px 0 0 8px;
      float: right;
  }

.cookie-policy-message .red-btn-skew {
  border: 1px solid rgba(199, 1, 1, 0.2);
  background: #c70101;
  transition: background 1s ease;
  -webkit-transition: background 1s ease;
  -moz-transition: background 1s ease;
  -o-transition: background 1s ease;
}

  .cookie-policy-message .red-btn-skew span {
      color: #fff;
  }

  .cookie-policy-message .red-btn-skew:hover {
      background: #9f0000;
      transition: background 0s;
      -webkit-transition: background 0s;
      -moz-transition: background 0s;
      -o-transition: background 0s;
  }

.cookie-policy-message .black-btn-skew {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #1a1a1a;
  transition: background 1s ease;
  -webkit-transition: background 1s ease;
  -moz-transition: background 1s ease;
  -o-transition: background 1s ease;
}

  .cookie-policy-message .black-btn-skew span {
      color: #fff;
      text-align: center;
  }

  .cookie-policy-message .black-btn-skew:hover {
      background: #474747;
      transition: background 0s;
      -webkit-transition: background 0s;
      -moz-transition: background 0s;
      -o-transition: background 0s;
  }

.cookie-policy-message .white-btn-skew {
  background: #fff;
  transition: background 1s ease;
  -webkit-transition: background 1s ease;
  -moz-transition: background 1s ease;
  -o-transition: background 1s ease;
  border: 1px solid rgba(73, 74, 80, 0.2);
}

  .cookie-policy-message .white-btn-skew:hover {
      transition: unset;
      -webkit-transition: unset;
      -moz-transition: unset;
      -o-transition: unset;
      background-color: rgba(184, 183, 185, 0.15);
  }

.cookie-policy-message .transparent-btn-skew {
  background-color: none;
}

  .cookie-policy-message .transparent-btn-skew a {
      padding: 0;
  }

  .cookie-policy-message .transparent-btn-skew span {
      color: #1a1a1a;
      font-size: 14px;
      font-size: 0.875rem;
      letter-spacing: 0.6px;
      text-transform: uppercase;
  }

.fixedTap {
  position: fixed;
  bottom: 50px;
  right: 10px;
  color: #fff;
  height: 40px;
  display: none;
}
.widget{
  position: absolute;
  /*right: 50px;
  bottom: 20px;*/
  transform: translate(-19%, 0);
  transition: all 500ms ease-out;
  width: 200px;
  border: 0;
  background-color: #c70101;
  -webkit-box-shadow: -1px 4px 8px -4px rgba(0,0,0,0.7);
  -moz-box-shadow: -1px 4px 8px -4px rgba(0,0,0,0.7);
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.fixedTap .img-btn {
  margin: 7px 5px 0 10px;
  background: url(/../amp/icons/settings-icon.png) no-repeat;
  width: 30px;
  height: 32px;
  display: inline-block;
}

.fixedTap .clickbtn {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 14px;
  font-family: 'Source Sans Pro';
  vertical-align: top;
  margin-top: 10px;
  display: inline-block;
  border: 0;
}

.clickbtn:focus {
  border: 0;
  outline: none;
}

.fixedTap:hover .widget, .fixedTap:active .widget {
  transform: translate(0,0);
  float: none;
  margin-left: -152px;
}


.tint-overlay {
  width: 100vw;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
  /*height: 100vh;*/
  height: 3200px;
}

.cookie-message__content a {
  color: #fff;
}

body.amp-geo-group-eea #post-consent-ui .fixedTap {
  display: block;
}
/*Cookie Policy Consent Ends here*/
`;

export const ZH_STYLE = `
:root {
  --color-error: #B00020;
}

@font-face {
  font-family: 'NotoSansSC-Bold';
  font-style: normal;
  font-weight: 400;
  src: local('NotoSansSC-Bold'), url(/amp/fonts/NotoSansCJKsc/NotoSansSC-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'NotoSansSC-Thin';
  font-style: normal;
  font-weight: 400;
  src: local('NotoSansSC-Thin'), url(/amp/fonts/NotoSansCJKsc/NotoSansSC-Thin.otf) format('truetype');
}

@font-face {
  font-family: 'NotoSansSC-Regular';
  font-style: normal;
  font-weight: 400;
  src: local('NotoSansSC-Regular'), url/amp/fonts/NotoSansCJKsc/NotoSansSC-Regular.otf) format('truetype');
}
/* .unavailable-font-loaded .unavailable-font {
               font-family: 'Source Sans Pro', sans-serif;
              }
              .comicamp-loaded .comicamp {
                font-family: 'Comic AMP';
              }
              .comicamp2-loaded .comicamp2 {
                font-family: 'Comic AMP 2';
              }*/
.comicamp-loading .comicamp,
.comicamp2-loading .comicamp2,
.unavailable-font-loading .unavailable-font {
  color: var(--color-primary);
}

.comicamp-missing .comicamp,
.comicamp2-missing .comicamp2,
.unavailable-font-missing .unavailable-font {
  color: var(--color-error);
}

body {
  background-color: #2b2b2b;
}

.main-container {
  background-color: white;
  padding-bottom: 24px;
}

.dfp-ad.square {
  margin-left: calc(50% - 150px);
}

.dfp-ad.banner {
  margin-left: calc(50% - 160px);
}

.screenreader {
  position: absolute;
  left: -10000px;
  top: auto;
  top: initial;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

body {
  /*font-family: 'Source Sans Pro', sans-serif;*/
  font-family: 'NotoSansSC-Thin', sans-serif;
  color: #1a1a1a;
}

.mu-logo {
  margin: 10px 0 10px 16px;
}

header .top-container {
  height: 66px;
  background-image: radial-gradient(circle at 50% 55%, #cc1a1a, #c70101), linear-gradient(#c70101, #c70101);
}

header .bottom-container {
  height: 45px;
  background: black;
}

  header .bottom-container .hamburger {
      color: #fff;
      height: 20px;
      width: 20px;
      float: left;
      position: relative;
      display: block;
      left: 16px;
      top: 13px;
  }

      header .bottom-container .hamburger span {
          background: white;
          display: block;
          height: 2px;
          border-radius: 1px;
          position: relative;
      }

.hamburger span:nth-child(2),
.hamburger span:nth-child(3) {
  margin-top: 6px;
}

.home-header__sponsor {
  margin: 20px 16px;
  width: auto;
  display: block;
  float: right;
}

  .home-header__sponsor a {
      float: right;
      height: 25px;
      border-radius: 4px;
      background-color: #000;
  }

      .home-header__sponsor a .sponsor-txt {
          letter-spacing: .4px;
          color: #fff;
          font-size: 10px;
          font-size: .625rem;
          margin-left: 15px;
          float: left;
          line-height: 13px;
          margin-top: 6px;
      }

  .home-header__sponsor amp-img {
      margin: 8px 10px;
      width: 58px;
  }

amp-sidebar {
  background-color: #ffffff;
  width: 200px;
}

ul.sidebar {
  margin: 0;
  padding: 0 16px 150px;
}

  ul.sidebar li {
      list-style: none;
      border-bottom: 1px solid #e5e5e5;
      padding: 0 12px;
  }

      ul.sidebar li a {
          color: #1a1a1a;
          /*font-family: SourceSansProRegular, Helvetica, sans-serif;*/
          font-size: 1rem;
          font-weight: 600;
          text-decoration: none;
          display: inline-block;
          height: 45px;
          line-height: 45px;
      }

.close-sidebar {
  line-height: 22px;
  height: 22px;
  width: 22px;
  border: 1px solid;
  text-align: center;
  color: #fff;
  background: black;
  border-radius: 50%;
  font-size: 12px;
  margin: 10px;
  position: relative;
  right: 0;
}

.bottom-sponsor {
  display: inline-block;
  float: right;
  margin-right: 6px;
}

  .bottom-sponsor ul {
      list-style: none;
      padding: 0px;
      margin: 0px;
      margin-top: 4px;
  }

  .bottom-sponsor li {
      display: inline-block;
      padding: 5px 6px;
  }

  .bottom-sponsor amp-img img, .sponsor__primary amp-img img, .sponsor__secondary amp-img img {
      object-fit: contain;
  }
/*Footer Start*/

footer.footer {
  float: left;
  background: white;
  font-family: 'NotoSansSC-Regular', sans-serif;
  font-size: 14px;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

footer ul {
  padding: 0px
}

footer .sponsor ul li {
  list-style: none;
  display: inline-block;
}

footer ul li a {
  color: #1a1a1a;
  text-decoration: none;
}

footer .sponsor h2 {
  /*font-size: .875rem;*/
  font-size: 14px;
  line-height: 16px;
  letter-spacing: .4px;
  font-family: 'NotoSansSC-Bold', sans-serif;
  text-align: left;
  margin-left: 16px;
  margin-bottom: 24px;
  text-transform: uppercase;
}

footer .sponsor__primary ul li a,
footer .sponsor__secondary ul li a {
  color: #1a1a1a;
  text-decoration: none;
  padding: 10px 16px;
  border-bottom: 1px solid transparent;
  display: inline-block;
  /*margin: 0 8px 8px 0;     */
}

.footer__social-links {
  float: left;
  background: #2a2a2a;
  width: 100%;
  min-height: 132px;
  text-align: center;
  position: relative;
  clear: both;
}

  .footer__social-links .social-icons {
      padding-top: 24px;
      padding-bottom: 24px;
  }

      .footer__social-links .social-icons ul {
          float: none;
          padding: 0 75px;
      }

          .footer__social-links .social-icons ul li {
              margin-right: 8px;
              margin-bottom: 8px;
              display: inline-block;
              /*padding: 0px;*/
          }

.footer__menu {
  float: left;
  background: #1a1a1a;
  width: 100%;
  border-top: 1px solid rgba(217, 217, 217, .3);
  padding-top: 7px;
  margin: 0px;
  padding: 0px;
}

.footer__menu__links {
  padding: 0px 16px;
}

.footer__menu .footer__menu__links ul {
  margin-bottom: 20px;
  width: 100%;
  margin: 0px 0px 20px;
  padding: 0px;
}

  .footer__menu .footer__menu__links ul li {
      width: 100%;
      border-bottom: 1px solid rgba(229, 229, 229, .2);
      text-align: left;
      padding: 15px 0;
      line-height: normal;
  }

      .footer__menu .footer__menu__links ul li:last-child {
          border: none;
      }

      .footer__menu .footer__menu__links ul li a {
          color: #fff;
          font-size: 14px;
          font-size: .875rem;
      }

.footer__menu .footer__menu__copyright ul li {
  color: #a3a3a3;
  font-size: 14px;
  font-size: .875rem;
  line-height: 19px;
  padding: 0 16px;
  margin-bottom: 20px;
  list-style: none;
}

.sponsor {
  text-align: center;
  padding: 24px 0;
}

.social-button {
  margin-bottom: 24px;
}
/*Footer End*/
/*Main Container*/

.content-panel_text {
  padding-left: 16px;
  padding-right: 16px;
  font-size: 15px;
  /*font-size: 1.25rem;*/
  font-weight: 300;
  line-height: 30px;
  margin-top: 24px;
  clear: both;
}

  .content-panel_text a {
      color: inherit;
      text-decoration: none;
      border-bottom: 1px solid #9c9c9c;
      clear: both;
  }

.content-bar {
  font-size: 14px;
  line-height: normal;
  margin: 16px;
  padding-bottom: 16px;
}

.top-panel h1 {
  font-family: 'NotoSansSC-Bold', serif, sans-serif;
  font-size: 27px;
  line-height: 33.12px;
  margin: 16px 16px 0px;
}

.heading {
  font-size: 18px;
  line-height: 34px;
  letter-spacing: -.2px;
}

.social__holder {
  font-size: 14px;
  font-family: 'NotoSansSC-Regular', serif, sans-serif;
  margin: 14px 16px 0px;
}

span.social__time {
  float: right;
}
/*Article card start*/

.mu-item,
.image-panel {
  margin: 24px 16px 0px;
  background: #ececec;
}

  .mu-item.profile {
      background: transparent;
  }

.mu-item__info {
  padding: 0px 16px 16px;
}

.mu-item__title {
  position: relative;
  margin-top: 36px;
  font-family: 'NotoSansSC-Bold', serif, sans-serif;
  font-size: 18px;
  margin-bottom: 0px;
}

  .mu-item__title:before,
  .mu-item__logo-title:before {
      border-top: 2px solid #c81617;
      position: absolute;
      width: 61px;
      content: '';
      top: -16px;
  }

.mu-item__teasure {
  font-size: 1.25rem;
  font-weight: 300;
}
/*Main container End*/
/*Buttons*/

.btn-skew {
  display: inline-block;
  white-space: nowrap;
  transform: skew(-10deg);
  margin: 0 auto;
  vertical-align: middle;
  border: 1px solid rgba(255, 255, 255, .15);
}

  .btn-skew a {
      padding: 14px 26px;
      float: left;
      text-decoration: none;
      color: white;
      font-size: .875rem;
      font-family: 'NotoSansSC-Bold', serif, sans-serif;
      text-align: center;
      float: left;
      font-style: italic;
      letter-spacing: .6px;
      transform: skew(10deg);
      text-transform: uppercase;
  }

  .btn-skew.red {
      background: #c70101;
      margin-left: 8px;
  }
/*Buttons End*/
/*Quote Card*/

.quote blockquote {
  margin: 0px;
}

.quote a {
  text-decoration: none;
}

.quote .mu-item__title {
  font-size: 14px;
  line-height: 26px;
  font-family: 'Source Sans Pro', sans-serif;
  margin-top: 16px;
}

.mu-quotes__title {
  font-size: 15px;
  line-height: 26px;
  margin-top: 3px;
}

.mu-quotes__author {
  font-size: 12px;
}

.mu-item a,
.mu-item__title {
  color: #1a1a1a;
}

.mu-item.print-prod .mu-item__logo-title {
  font-size: 12px;
  font-size: .75rem;
  line-height: .9;
  font-family: 'NotoSansSC-bold', sans-serif;
  text-transform: uppercase;
  margin-bottom: 12px;
  position: relative;
  margin-top: 36px;
}

.mu-item.print-prod a,
.mu-item.dfp-ads-card a {
  text-decoration: none;
}

.mu-item.print-prod .mu-item__title {
  margin-top: 0px;
  text-decoration: underline;
}

.mu-item.dfp-ads-card .dfp-ads__cta {
  margin-top: 0px;
  text-decoration: underline;
  font-style: italic;
  text-transform: uppercase;
  font-size: 14px;
  font-style: italic;
  text-transform: uppercase;
  letter-spacing: .6px;
  font-weight: 600;
}

.mu-item.print-prod .mu-item__title:before,
.mu-item.dfp-ads-card .mu-item__title:before,
.mu-item.quote .mu-item__title:before {
  display: none;
}

.mu-item.profile a {
  font-family: 'NotoSansSC-Bold';
}

.mu-item.profile span {
  font-family: 'NotoSansSC-Bold';
  font-size: 18px;
  margin-right: 2px;
}

  .mu-item.profile span:after {
      content: ':';
  }

.mu-item.profile h2.player-name {
  display: inline;
  font-size: 18px;
}

.mu-item.dfp-ads-card .mu-item__title {
  font-family: 'NotoSansSC-bold', sans-serif;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 24px;
  margin-top: 10px;
  margin-bottom: 12px;
}
/*Table */

.mu-table {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 20px;
  padding-top: 20px;
  max-width: 670px;
  float: none;
  margin-left: auto;
  margin-right: auto;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 auto;
  width: 100%;
  float: left;
  float: none;
}

  table tr {
      border-bottom: 1px solid rgba(0, 0, 0, .1);
  }

  table td {
      padding: 5px 0;
      font-size: 12px;
      float: none;
      position: relative;
      text-align: left;
      line-height: 1.35;
      display: inline-block;
      word-break: break-word;
      padding: 20px 0;
      display: table-cell;
  }

amp-twitter {
  margin: 0;
}

.dfp-ad {
  margin-top: 24px;
}

.mu-item.profile .mu-item__info {
  padding: 0px;
}

.mu-item__sponsor {
  background: #f8f8f8;
  float: left;
  max-height: 40px;
  margin: 16px 16px 30px;
}

  .mu-item__sponsor a {
      display: inline-block;
      padding: 6px 8px;
      float: left;
      text-decoration: none;
  }

      .mu-item__sponsor a span:first-child {
          color: #858585;
          margin-right: 16px;
          padding-top: 6px;
          padding-bottom: 6px;
          text-transform: uppercase;
          font-size: .75rem;
          float: left;
      }

.social__holder-bottom {
  margin-top: 32px;
  float: left;
  width: 100%;
}
/*.content-panel_text {
                  margin-top: 24px;
              }*/

amp-social-share.custom-style {
  background-color: #008080;
  background-image: url('https://raw.githubusercontent.com/google/material-design-icons/master/social/1x_web/ic_share_white_48dp.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

amp-social-share.rounded {
  border-radius: 50%;
  background-size: 60%;
  margin-left: 3px;
}

  amp-social-share.rounded:first-child {
      margin-left: 0px;
  }

.main-container:after {
  display: table;
  content: '';
  clear: both;
}
/*Recommendations start*/

.content-panel_recommendations ul {
  list-style: none;
  padding-inline-start: 0px;
}

.content-panel_recommendations .player-title {
  font-family: 'NotoSansSC-Bold', serif, sans-serif;
  font-size: 30px;
  margin: 24px 16px 0px;
}

amp-user-notification > div {
  font: 12px/1.5 Helvetica, Arial, sans-serif;
  color: #fff;
  background: #000;
  padding: 1rem;
}

  amp-user-notification > div a {
      color: #fff;
  }

amp-user-notification button {
  margin-top: 10px;
  height: 25px;
}
/*Cookie Policy Consent Starts here*/
.cookie-policy-message {
  float: left;
  background: #1a1a1a;
  padding: 20px 10px;
  position: fixed;
  z-index: 9999;
  color: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  -moz-border-radius: 16px 16px 0 0;
  border-radius: 16px 16px 0 0;
  -webkit-border-radius: 16px 16px 0 0;
  -webkit-mask-image: -webkit-radial-gradient(white,#000);
}

.cookie-message__content .dismiss-button {
  text-align: right;
}

.cookie-policy-message.non-eu {
  padding: 22px;
}

.cookie-policy-message h2 {
  margin: 5px 0;
}

.cookie-policy-message p {
  font-size: 14px;
  line-height: 20px;
  margin: 9px 0;
}

.cookie-policy-message.non-eu p {
  font-size: 14px;
  text-align: left;
}

.cookie-policy-message button.black-btn-skew span,
.cookie-policy-message button.red-btn-skew span {
  line-height: 30px;
}

.cookie-policy-message .cookie-buttons {
  border-top: 1px solid rgba(223, 223, 223, 0.1);
  margin-top: 19px;
  padding-top: 17px;
  text-align: center;
}

.cookie-policy-message.non-eu .cookie-buttons {
  border-top: none;
  margin-top: 8px;
}

.cookie-policy-message .cookie-buttons .black-btn-skew {
  border: 1px solid #ddd;
}

.cookie-policy-message .cookie-buttons .btn {
  width: 135px;
}

.cookie-message__content {
  text-align: center;
}

  .cookie-message__content span {
      font-size: 16px;
      font-weight: bold;
  }

.cookie-setting__content p {
  margin: 3px auto 0;
}

.cookie-setting__content h2 + p {
  margin: 8px 0 20px;
}

.cookie-policy-message .cookie-buttons .black-btn-skew {
  border: 1px solid #ddd;
}

.cookie-policy-message .black-btn-skew {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #1a1a1a;
  transition: background 1s ease;
  -webkit-transition: background 1s ease;
  -moz-transition: background 1s ease;
  -o-transition: background 1s ease;
}

.cookie-policy-message .black-btn-skew,
.cookie-policy-message .link-btn-black,
.cookie-policy-message .link-btn-white,
.cookie-policy-message .red-btn-skew,
.cookie-policy-message .transparent-btn-skew,
.cookie-policy-message .white-btn-skew {
  display: inline-block;
  white-space: nowrap;
  height: 50px;
  margin: 0 auto;
  border-radius: 25px;
  cursor: pointer;
  padding: 0;
  text-transform: uppercase;
  margin: 0 5px;
}

  .cookie-policy-message .black-btn-skew span,
  .cookie-policy-message .link-btn-black span,
  .cookie-policy-message .link-btn-white span,
  .cookie-policy-message .red-btn-skew span,
  .cookie-policy-message .transparent-btn-skew span,
  .cookie-policy-message .white-btn-skew span {
      color: #1a1a1a;
      font-size: 14px;
      font-size: 0.875rem;
      /* float: left; */
      line-height: 50px;
      padding: 0 32px;
      text-align: center;
      padding: 0;
  }

  .cookie-policy-message .black-btn-skew i,
  .cookie-policy-message .link-btn-black i,
  .cookie-policy-message .link-btn-white i,
  .cookie-policy-message .red-btn-skew i,
  .cookie-policy-message .transparent-btn-skew i,
  .cookie-policy-message .white-btn-skew i {
      display: none;
      margin: 3px 0 0 8px;
      float: right;
  }

.cookie-policy-message .red-btn-skew {
  border: 1px solid rgba(199, 1, 1, 0.2);
  background: #c70101;
  transition: background 1s ease;
  -webkit-transition: background 1s ease;
  -moz-transition: background 1s ease;
  -o-transition: background 1s ease;
}

  .cookie-policy-message .red-btn-skew span {
      color: #fff;
  }

  .cookie-policy-message .red-btn-skew:hover {
      background: #9f0000;
      transition: background 0s;
      -webkit-transition: background 0s;
      -moz-transition: background 0s;
      -o-transition: background 0s;
  }

.cookie-policy-message .black-btn-skew {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #1a1a1a;
  transition: background 1s ease;
  -webkit-transition: background 1s ease;
  -moz-transition: background 1s ease;
  -o-transition: background 1s ease;
}

  .cookie-policy-message .black-btn-skew span {
      color: #fff;
      text-align: center;
  }

  .cookie-policy-message .black-btn-skew:hover {
      background: #474747;
      transition: background 0s;
      -webkit-transition: background 0s;
      -moz-transition: background 0s;
      -o-transition: background 0s;
  }

.cookie-policy-message .white-btn-skew {
  background: #fff;
  transition: background 1s ease;
  -webkit-transition: background 1s ease;
  -moz-transition: background 1s ease;
  -o-transition: background 1s ease;
  border: 1px solid rgba(73, 74, 80, 0.2);
}

  .cookie-policy-message .white-btn-skew:hover {
      transition: unset;
      -webkit-transition: unset;
      -moz-transition: unset;
      -o-transition: unset;
      background-color: rgba(184, 183, 185, 0.15);
  }

.cookie-policy-message .transparent-btn-skew {
  background-color: none;
}

  .cookie-policy-message .transparent-btn-skew a {
      padding: 0;
  }

  .cookie-policy-message .transparent-btn-skew span {
      color: #1a1a1a;
      font-size: 14px;
      font-size: 0.875rem;
      letter-spacing: 0.6px;
      text-transform: uppercase;
  }

.fixedTap {
  position: fixed;
  bottom: 50px;
  right: 10px;
  color: #fff;
  height: 40px;
  display: none;
}
.widget{
  position: absolute;
  /*right: 50px;
  bottom: 20px;*/
  transform: translate(-19%, 0);
  transition: all 500ms ease-out;
  width: 200px;
  border: 0;
  background-color: #c70101;
  -webkit-box-shadow: -1px 4px 8px -4px rgba(0,0,0,0.7);
  -moz-box-shadow: -1px 4px 8px -4px rgba(0,0,0,0.7);
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.fixedTap .img-btn {
  margin: 7px 5px 0 10px;
  background: url(/../amp/icons/settings-icon.png) no-repeat;
  width: 30px;
  height: 32px;
  display: inline-block;
}

.fixedTap .clickbtn {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 14px;
  font-family: 'Source Sans Pro';
  vertical-align: top;
  margin-top: 10px;
  display: inline-block;
  border: 0;
}

.clickbtn:focus {
  border: 0;
  outline: none;
}

.fixedTap:hover .widget, .fixedTap:active .widget {
  transform: translate(0,0);
  float: none;
  margin-left: -152px;
}


.tint-overlay {
  width: 100vw;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
  /*height: 100vh;*/
  height: 3200px;
}

.cookie-message__content a {
  color: #fff;
}

body.amp-geo-group-eea #post-consent-ui .fixedTap {
  display: block;
}
/*Cookie Policy Consent Ends here*/
`;

export const JA_STYLE = `
:root {
  --color-error: #B00020;
}

@font-face {
  font-family: 'NotoSansJP-Bold';
  font-style: normal;
  font-weight: 400;
  src: local('NotoSansJP-Bold'), url(/amp/fonts/NotoSansCJKjp/NotoSansJP-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'NotoSansJP-Thin';
  font-style: normal;
  font-weight: 400;
  src: local('NotoSansJP-Thin'), url(/amp/fonts/NotoSansCJKjp/NotoSansJP-Thin.otf) format('truetype');
}

@font-face {
  font-family: 'NotoSansJP-Regular';
  font-style: normal;
  font-weight: 400;
  src: local('NotoSansJP-Regular'), url(/amp/fonts/NotoSansCJKjp/NotoSansJP-Regular.otf) format('truetype');
}
/* .unavailable-font-loaded .unavailable-font {
           font-family: 'Source Sans Pro', sans-serif;
          }
          .comicamp-loaded .comicamp {
            font-family: 'Comic AMP';
          }
          .comicamp2-loaded .comicamp2 {
            font-family: 'Comic AMP 2';
          }*/

.comicamp-loading .comicamp,
.comicamp2-loading .comicamp2,
.unavailable-font-loading .unavailable-font {
  color: var(--color-primary);
}

.comicamp-missing .comicamp,
.comicamp2-missing .comicamp2,
.unavailable-font-missing .unavailable-font {
  color: var(--color-error);
}

body {
  background-color: #2b2b2b;
}

.main-container {
  background-color: white;
  padding-bottom: 24px;
}

.dfp-ad.square {
  margin-left: calc(50% - 150px);
}

.dfp-ad.banner {
  margin-left: calc(50% - 160px);
}

.screenreader {
  position: absolute;
  left: -10000px;
  top: auto;
  top: initial;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

body {
  /*font-family: 'Source Sans Pro', sans-serif;*/
  font-family: 'NotoSansJP-Thin', sans-serif;
  color: #1a1a1a;
}

.mu-logo {
  margin: 10px 0 10px 16px;
}

header .top-container {
  height: 66px;
  background-image: radial-gradient(circle at 50% 55%, #cc1a1a, #c70101), linear-gradient(#c70101, #c70101);
}

header .bottom-container {
  height: 45px;
  background: black;
}

  header .bottom-container .hamburger {
      color: #fff;
      height: 20px;
      width: 20px;
      float: left;
      position: relative;
      display: block;
      left: 16px;
      top: 13px;
  }

      header .bottom-container .hamburger span {
          background: white;
          display: block;
          height: 2px;
          border-radius: 1px;
          position: relative;
      }

.hamburger span:nth-child(2),
.hamburger span:nth-child(3) {
  margin-top: 6px;
}

.home-header__sponsor {
  margin: 20px 16px;
  width: auto;
  display: block;
  float: right;
}

  .home-header__sponsor a {
      float: right;
      height: 25px;
      border-radius: 4px;
      background-color: #000;
  }

      .home-header__sponsor a .sponsor-txt {
          letter-spacing: .4px;
          color: #fff;
          font-size: 12px;
          /*font-size: .625rem;*/
          margin-left: 15px;
          float: left;
          line-height: 13px;
          margin-top: 6px;
      }

  .home-header__sponsor amp-img {
      margin: 8px 10px;
      width: 58px;
  }

amp-sidebar {
  background-color: #ffffff;
  width: 200px;
}

ul.sidebar {
  margin: 0;
  padding: 0 16px 150px;
}

  ul.sidebar li {
      list-style: none;
      border-bottom: 1px solid #e5e5e5;
      padding: 0 12px;
  }

      ul.sidebar li a {
          color: #1a1a1a;
          /*font-family: SourceSansProRegular, Helvetica, sans-serif;*/
          font-size: 1rem;
          font-weight: 600;
          text-decoration: none;
          display: inline-block;
          height: 45px;
          line-height: 45px;
      }

.close-sidebar {
  line-height: 22px;
  height: 22px;
  width: 22px;
  border: 1px solid;
  text-align: center;
  color: #fff;
  background: black;
  border-radius: 50%;
  font-size: 12px;
  margin: 10px;
  position: relative;
  right: 0;
}

.bottom-sponsor {
  display: inline-block;
  float: right;
  margin-right: 6px;
}

  .bottom-sponsor ul {
      list-style: none;
      padding: 0px;
      margin: 0px;
      margin-top: 4px;
  }

  .bottom-sponsor li {
      display: inline-block;
      padding: 5px 6px;
  }

  .bottom-sponsor amp-img img, .sponsor__primary amp-img img, .sponsor__secondary amp-img img {
      object-fit: contain;
  }
/*Footer Start*/

footer.footer {
  float: left;
  background: white;
  font-family: 'NotoSansJP-Regular', sans-serif;
  font-size: 14px;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

footer ul {
  padding: 0px
}

footer .sponsor ul li {
  list-style: none;
  display: inline-block;
}

footer ul li a {
  color: #1a1a1a;
  text-decoration: none;
}

footer .sponsor h2 {
  /*font-size: .875rem;*/
  font-size: 14px;
  line-height: 16px;
  letter-spacing: .4px;
  font-family: 'NotoSansJP-Bold', sans-serif;
  text-align: left;
  margin-left: 16px;
  margin-bottom: 24px;
  text-transform: uppercase;
}

footer .sponsor__primary ul li a,
footer .sponsor__secondary ul li a {
  color: #1a1a1a;
  text-decoration: none;
  padding: 10px 16px;
  border-bottom: 1px solid transparent;
  display: inline-block;
  /*margin: 0 8px 8px 0;     */
}

.footer__social-links {
  float: left;
  background: #2a2a2a;
  width: 100%;
  min-height: 132px;
  text-align: center;
  position: relative;
  clear: both;
}

  .footer__social-links .social-icons {
      padding-top: 24px;
      padding-bottom: 24px;
  }

      .footer__social-links .social-icons ul {
          float: none;
          padding: 0 75px;
      }

          .footer__social-links .social-icons ul li {
              margin-right: 8px;
              margin-bottom: 8px;
              display: inline-block;
              /*padding: 0px;*/
          }

.footer__menu {
  float: left;
  background: #1a1a1a;
  width: 100%;
  border-top: 1px solid rgba(217, 217, 217, .3);
  padding-top: 7px;
  margin: 0px;
  padding: 0px;
}

.footer__menu__links {
  padding: 0px 16px;
}

.footer__menu .footer__menu__links ul {
  margin-bottom: 20px;
  width: 100%;
  margin: 0px 0px 20px;
  padding: 0px;
}

  .footer__menu .footer__menu__links ul li {
      width: 100%;
      border-bottom: 1px solid rgba(229, 229, 229, .2);
      text-align: left;
      padding: 15px 0;
      line-height: normal;
  }

      .footer__menu .footer__menu__links ul li:last-child {
          border: none;
      }

      .footer__menu .footer__menu__links ul li a {
          color: #fff;
          font-size: 14px;
          /*font-size: .875rem;*/
      }

.footer__menu .footer__menu__copyright ul li {
  color: #a3a3a3;
  font-size: 14px;
  /*font-size: .875rem;*/
  line-height: 19px;
  padding: 0 16px;
  margin-bottom: 20px;
  list-style: none;
}

.sponsor {
  text-align: center;
  padding: 24px 0;
}

.social-button {
  margin-bottom: 24px;
}
/*Footer End*/
/*Main Container*/

.content-panel_text {
  padding-left: 16px;
  padding-right: 16px;
  font-size: 17px;
  /*font-size: 1.25rem;*/
  font-weight: 300;
  line-height: 30px;
  margin-top: 24px;
  clear: both;
}

  .content-panel_text a {
      color: inherit;
      text-decoration: none;
      border-bottom: 1px solid #9c9c9c;
      clear: both;
  }

.content-bar {
  font-size: 14px;
  line-height: normal;
  margin: 16px;
  padding-bottom: 16px;
}

.top-panel h1 {
  font-family: 'NotoSansJP-Bold', serif, sans-serif;
  font-size: 30px;
  line-height: 33.12px;
  margin: 16px 16px 0px;
}

.heading {
  font-size: 21px;
  line-height: 34px;
  letter-spacing: -.2px;
}

.social__holder {
  font-size: 14px;
  font-family: 'NotoSansJP-Regular', serif, sans-serif;
  margin: 14px 16px 0px;
}

span.social__time {
  float: right;
}
/*Article card start*/

.mu-item,
.image-panel {
  margin: 24px 16px 0px;
  background: #ececec;
}

  .mu-item.profile {
      background: transparent;
  }

.mu-item__info {
  padding: 0px 16px 16px;
}

.mu-item__title {
  position: relative;
  margin-top: 36px;
  font-family: 'NotoSansJP-Bold', serif, sans-serif;
  font-size: 21px;
  margin-bottom: 0px;
}

  .mu-item__title:before,
  .mu-item__logo-title:before {
      border-top: 2px solid #c81617;
      position: absolute;
      width: 61px;
      content: '';
      top: -16px;
  }

.mu-item__teasure {
  font-size: 1.25rem;
  font-weight: 300;
}
/*Main container End*/
/*Buttons*/

.btn-skew {
  display: inline-block;
  white-space: nowrap;
  transform: skew(-10deg);
  margin: 0 auto;
  vertical-align: middle;
  border: 1px solid rgba(255, 255, 255, .15);
}

  .btn-skew a {
      padding: 14px 26px;
      float: left;
      text-decoration: none;
      color: white;
      font-size: 14px;
      font-family: 'NotoSansJP-Bold', serif, sans-serif;
      text-align: center;
      float: left;
      font-style: italic;
      letter-spacing: .6px;
      transform: skew(10deg);
      text-transform: uppercase;
  }

  .btn-skew.red {
      background: #c70101;
      margin-left: 8px;
  }
/*Buttons End*/
/*Quote Card*/

.quote blockquote {
  margin: 0px;
}

.quote a {
  text-decoration: none;
}

.quote .mu-item__title {
  font-size: 16px;
  line-height: 26px;
  font-family: 'NotoSansJP-Regular', sans-serif;
  margin-top: 16px;
}

.mu-quotes__title {
  font-size: 17px;
  line-height: 26px;
  font-family: 'NotoSansJP-Regular', sans-serif;
  margin-top: 3px;
}

.mu-quotes__author {
  font-size: 14px;
}

.mu-item a,
.mu-item__title {
  color: #1a1a1a;
}

.mu-item.print-prod .mu-item__logo-title {
  font-size: 14px;
  font-size: .75rem;
  line-height: .9;
  font-family: 'NotoSansJP-Bold', sans-serif;
  text-transform: uppercase;
  margin-bottom: 12px;
  position: relative;
  margin-top: 36px;
}

.mu-item.print-prod a,
.mu-item.dfp-ads-card a {
  text-decoration: none;
}

.mu-item.print-prod .mu-item__title {
  margin-top: 0px;
  text-decoration: underline;
}

.mu-item.dfp-ads-card .dfp-ads__cta {
  margin-top: 0px;
  text-decoration: underline;
  font-style: italic;
  text-transform: uppercase;
  font-size: 11px;
  font-style: italic;
  text-transform: uppercase;
  letter-spacing: .6px;
  font-weight: 600;
}

.mu-item.print-prod .mu-item__title:before,
.mu-item.dfp-ads-card .mu-item__title:before,
.mu-item.quote .mu-item__title:before {
  display: none;
}

.mu-item.profile a {
  font-family: 'NotoSansJP-Bold';
}

.mu-item.profile span {
  font-family: 'NotoSansJP-Bold';
  font-size: 21px;
  margin-right: 2px;
}

  .mu-item.profile span:after {
      content: ':';
  }

.mu-item.profile h2.player-name {
  display: inline;
  font-size: 21px;
}

.mu-item.dfp-ads-card .mu-item__title {
  font-family: 'NotoSansJP-Bold', sans-serif;
  text-transform: uppercase;
  font-size: 17px;
  line-height: 24px;
  margin-top: 10px;
  margin-bottom: 12px;
}
/*Table */

.mu-table {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 20px;
  padding-top: 20px;
  max-width: 670px;
  float: none;
  margin-left: auto;
  margin-right: auto;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 auto;
  width: 100%;
  float: left;
  float: none;
}

  table tr {
      border-bottom: 1px solid rgba(0, 0, 0, .1);
  }

  table td {
      padding: 5px 0;
      font-size: 14px;
      float: none;
      position: relative;
      text-align: left;
      line-height: 1.35;
      display: inline-block;
      word-break: break-word;
      padding: 20px 0;
      display: table-cell;
  }

amp-twitter {
  margin: 0;
}

.dfp-ad {
  margin-top: 24px;
}

.mu-item.profile .mu-item__info {
  padding: 0px;
}

.mu-item__sponsor {
  background: #f8f8f8;
  float: left;
  max-height: 40px;
  margin: 16px 16px 30px;
}

  .mu-item__sponsor a {
      display: inline-block;
      padding: 6px 8px;
      float: left;
      text-decoration: none;
  }

      .mu-item__sponsor a span:first-child {
          color: #858585;
          margin-right: 16px;
          padding-top: 6px;
          padding-bottom: 6px;
          text-transform: uppercase;
          font-size: 12px;
          float: left;
      }

.social__holder-bottom {
  margin-top: 32px;
  float: left;
  width: 100%;
}
/*.content-panel_text {
              margin-top: 24px;
          }*/

amp-social-share.custom-style {
  background-color: #008080;
  background-image: url('https://raw.githubusercontent.com/google/material-design-icons/master/social/1x_web/ic_share_white_48dp.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

amp-social-share.rounded {
  border-radius: 50%;
  background-size: 60%;
  margin-left: 3px;
}

  amp-social-share.rounded:first-child {
      margin-left: 0px;
  }

.main-container:after {
  display: table;
  content: '';
  clear: both;
}
/*Recommendations start*/

.content-panel_recommendations ul {
  list-style: none;
  padding-inline-start: 0px;
}

.content-panel_recommendations .player-title {
  font-family: 'NotoSansJP-Bold', serif, sans-serif;
  font-size: 34px;
  margin: 24px 16px 0px;
}

amp-user-notification > div {
  font: 12px/1.5 Helvetica, Arial, sans-serif;
  color: #fff;
  background: #000;
  padding: 1rem;
}

  amp-user-notification > div a {
      color: #fff;
  }

amp-user-notification button {
  margin-top: 10px;
  height: 25px;
}
/*Cookie Policy Consent Starts here*/
.cookie-policy-message {
  float: left;
  background: #1a1a1a;
  padding: 20px 10px;
  position: fixed;
  z-index: 9999;
  color: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  -moz-border-radius: 16px 16px 0 0;
  border-radius: 16px 16px 0 0;
  -webkit-border-radius: 16px 16px 0 0;
  -webkit-mask-image: -webkit-radial-gradient(white,#000);
}

.cookie-message__content .dismiss-button {
  text-align: right;
}

.cookie-policy-message.non-eu {
  padding: 22px;
}

.cookie-policy-message h2 {
  margin: 5px 0;
}

.cookie-policy-message p {
  font-size: 14px;
  line-height: 20px;
  margin: 9px 0;
}

.cookie-policy-message.non-eu p {
  font-size: 14px;
  text-align: left;
}

.cookie-policy-message button.black-btn-skew span,
.cookie-policy-message button.red-btn-skew span {
  line-height: 30px;
}

.cookie-policy-message .cookie-buttons {
  border-top: 1px solid rgba(223, 223, 223, 0.1);
  margin-top: 19px;
  padding-top: 17px;
  text-align: center;
}

.cookie-policy-message.non-eu .cookie-buttons {
  border-top: none;
  margin-top: 8px;
}

.cookie-policy-message .cookie-buttons .black-btn-skew {
  border: 1px solid #ddd;
}

.cookie-policy-message .cookie-buttons .btn {
  width: 135px;
}

.cookie-message__content {
  text-align: center;
}

  .cookie-message__content span {
      font-size: 16px;
      font-weight: bold;
  }

.cookie-setting__content p {
  margin: 3px auto 0;
}

.cookie-setting__content h2 + p {
  margin: 8px 0 20px;
}

.cookie-policy-message .cookie-buttons .black-btn-skew {
  border: 1px solid #ddd;
}

.cookie-policy-message .black-btn-skew {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #1a1a1a;
  transition: background 1s ease;
  -webkit-transition: background 1s ease;
  -moz-transition: background 1s ease;
  -o-transition: background 1s ease;
}

.cookie-policy-message .black-btn-skew,
.cookie-policy-message .link-btn-black,
.cookie-policy-message .link-btn-white,
.cookie-policy-message .red-btn-skew,
.cookie-policy-message .transparent-btn-skew,
.cookie-policy-message .white-btn-skew {
  display: inline-block;
  white-space: nowrap;
  height: 50px;
  margin: 0 auto;
  border-radius: 25px;
  cursor: pointer;
  padding: 0;
  text-transform: uppercase;
  margin: 0 5px;
}

  .cookie-policy-message .black-btn-skew span,
  .cookie-policy-message .link-btn-black span,
  .cookie-policy-message .link-btn-white span,
  .cookie-policy-message .red-btn-skew span,
  .cookie-policy-message .transparent-btn-skew span,
  .cookie-policy-message .white-btn-skew span {
      color: #1a1a1a;
      font-size: 14px;
      font-size: 0.875rem;
      /* float: left; */
      line-height: 50px;
      padding: 0 32px;
      text-align: center;
      padding: 0;
  }

  .cookie-policy-message .black-btn-skew i,
  .cookie-policy-message .link-btn-black i,
  .cookie-policy-message .link-btn-white i,
  .cookie-policy-message .red-btn-skew i,
  .cookie-policy-message .transparent-btn-skew i,
  .cookie-policy-message .white-btn-skew i {
      display: none;
      margin: 3px 0 0 8px;
      float: right;
  }

.cookie-policy-message .red-btn-skew {
  border: 1px solid rgba(199, 1, 1, 0.2);
  background: #c70101;
  transition: background 1s ease;
  -webkit-transition: background 1s ease;
  -moz-transition: background 1s ease;
  -o-transition: background 1s ease;
}

  .cookie-policy-message .red-btn-skew span {
      color: #fff;
  }

  .cookie-policy-message .red-btn-skew:hover {
      background: #9f0000;
      transition: background 0s;
      -webkit-transition: background 0s;
      -moz-transition: background 0s;
      -o-transition: background 0s;
  }

.cookie-policy-message .black-btn-skew {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #1a1a1a;
  transition: background 1s ease;
  -webkit-transition: background 1s ease;
  -moz-transition: background 1s ease;
  -o-transition: background 1s ease;
}

  .cookie-policy-message .black-btn-skew span {
      color: #fff;
      text-align: center;
  }

  .cookie-policy-message .black-btn-skew:hover {
      background: #474747;
      transition: background 0s;
      -webkit-transition: background 0s;
      -moz-transition: background 0s;
      -o-transition: background 0s;
  }

.cookie-policy-message .white-btn-skew {
  background: #fff;
  transition: background 1s ease;
  -webkit-transition: background 1s ease;
  -moz-transition: background 1s ease;
  -o-transition: background 1s ease;
  border: 1px solid rgba(73, 74, 80, 0.2);
}

  .cookie-policy-message .white-btn-skew:hover {
      transition: unset;
      -webkit-transition: unset;
      -moz-transition: unset;
      -o-transition: unset;
      background-color: rgba(184, 183, 185, 0.15);
  }

.cookie-policy-message .transparent-btn-skew {
  background-color: none;
}

  .cookie-policy-message .transparent-btn-skew a {
      padding: 0;
  }

  .cookie-policy-message .transparent-btn-skew span {
      color: #1a1a1a;
      font-size: 14px;
      font-size: 0.875rem;
      letter-spacing: 0.6px;
      text-transform: uppercase;
  }

.fixedTap {
  position: fixed;
  bottom: 50px;
  right: 10px;
  color: #fff;
  height: 40px;
  display: none;
}
.widget{
  position: absolute;
  /*right: 50px;
  bottom: 20px;*/
  transform: translate(-19%, 0);
  transition: all 500ms ease-out;
  width: 200px;
  border: 0;
  background-color: #c70101;
  -webkit-box-shadow: -1px 4px 8px -4px rgba(0,0,0,0.7);
  -moz-box-shadow: -1px 4px 8px -4px rgba(0,0,0,0.7);
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.fixedTap .img-btn {
  margin: 7px 5px 0 10px;
  background: url(/../amp/icons/settings-icon.png) no-repeat;
  width: 30px;
  height: 32px;
  display: inline-block;
}

.fixedTap .clickbtn {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 14px;
  font-family: 'Source Sans Pro';
  vertical-align: top;
  margin-top: 10px;
  display: inline-block;
  border: 0;
}

.clickbtn:focus {
  border: 0;
  outline: none;
}

.fixedTap:hover .widget, .fixedTap:active .widget {
  transform: translate(0,0);
  float: none;
  margin-left: -152px;
}


.tint-overlay {
  width: 100vw;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
  /*height: 100vh;*/
  height: 3200px;
}

.cookie-message__content a {
  color: #fff;
}

body.amp-geo-group-eea #post-consent-ui .fixedTap {
  display: block;
}
/*Cookie Policy Consent Ends here*/
`;

export const KO_STYLE = `
:root {
  --color-error: #B00020;
}

@font-face {
  font-family: 'KoPubDotum-Bold';
  font-style: normal;
  font-weight: 400;
  src: local('KoPubDotum-Bold'), url(/amp/fonts/KoPubDotum/KoPubDotum-Bold.woff) format('truetype');
}

@font-face {
  font-family: 'KoPubDotum-Light';
  font-style: normal;
  font-weight: 400;
  src: local('KoPubDotum-Light'), url(/amp/fonts/KoPubDotum/KoPubDotum-Light.woff) format('truetype');
}

@font-face {
  font-family: 'KoPubDotum-Medium';
  font-style: normal;
  font-weight: 400;
  src: local('KoPubDotum-Medium'), url(/amp/fonts/KoPubDotum/KoPubDotum-Medium.woff) format('truetype');
}

.comicamp-loading .comicamp,
.comicamp2-loading .comicamp2,
.unavailable-font-loading .unavailable-font {
  color: var(--color-primary);
}

.comicamp-missing .comicamp,
.comicamp2-missing .comicamp2,
.unavailable-font-missing .unavailable-font {
  color: var(--color-error);
}

body {
  background-color: #2b2b2b;
}

.main-container {
  background-color: white;
  padding-bottom: 24px;
}

.dfp-ad.square {
  margin-left: calc(50% - 150px);
}

.dfp-ad.banner {
  margin-left: calc(50% - 160px);
}

.screenreader {
  position: absolute;
  left: -10000px;
  top: auto;
  top: initial;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

body {
  /*font-family: 'Source Sans Pro', sans-serif;*/
  font-family: 'KoPubDotum-Light', sans-serif;
  color: #1a1a1a;
}

.mu-logo {
  margin: 10px 0 10px 16px;
}

header .top-container {
  height: 66px;
  background-image: radial-gradient(circle at 50% 55%, #cc1a1a, #c70101), linear-gradient(#c70101, #c70101);
}

header .bottom-container {
  height: 45px;
  background: black;
}

  header .bottom-container .hamburger {
      color: #fff;
      height: 20px;
      width: 20px;
      float: left;
      position: relative;
      display: block;
      left: 16px;
      top: 13px;
  }

      header .bottom-container .hamburger span {
          background: white;
          display: block;
          height: 2px;
          border-radius: 1px;
          position: relative;
      }

.hamburger span:nth-child(2),
.hamburger span:nth-child(3) {
  margin-top: 6px;
}

.home-header__sponsor {
  margin: 20px 16px;
  width: auto;
  display: block;
  float: right;
}

  .home-header__sponsor a {
      float: right;
      height: 25px;
      border-radius: 4px;
      background-color: #000;
  }

      .home-header__sponsor a .sponsor-txt {
          letter-spacing: .4px;
          color: #fff;
          font-size: 10px;
          font-size: .625rem;
          margin-left: 15px;
          float: left;
          line-height: 13px;
          margin-top: 6px;
      }

  .home-header__sponsor amp-img {
      margin: 8px 10px;
      width: 58px;
  }

amp-sidebar {
  background-color: #ffffff;
  width: 200px;
}

ul.sidebar {
  margin: 0;
  padding: 0 16px 150px;
}

  ul.sidebar li {
      list-style: none;
      border-bottom: 1px solid #e5e5e5;
      padding: 0 12px;
  }

      ul.sidebar li a {
          color: #1a1a1a;
          /*font-family: SourceSansProRegular, Helvetica, sans-serif;*/
          font-size: 1rem;
          font-weight: 600;
          text-decoration: none;
          display: inline-block;
          height: 45px;
          line-height: 45px;
      }

.close-sidebar {
  line-height: 22px;
  height: 22px;
  width: 22px;
  border: 1px solid;
  text-align: center;
  color: #fff;
  background: black;
  border-radius: 50%;
  font-size: 12px;
  margin: 10px;
  position: relative;
  right: 0;
}

.bottom-sponsor {
  display: inline-block;
  float: right;
  margin-right: 6px;
}

  .bottom-sponsor ul {
      list-style: none;
      padding: 0px;
      margin: 0px;
      margin-top: 4px;
  }

  .bottom-sponsor li {
      display: inline-block;
      padding: 5px 6px;
  }

  .bottom-sponsor amp-img img, .sponsor__primary amp-img img, .sponsor__secondary amp-img img {
      object-fit: contain;
  }
/*Footer Start*/

footer.footer {
  float: left;
  background: white;
  font-family: 'KoPubDotum-Medium', sans-serif;
  font-size: 14px;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

footer ul {
  padding: 0px
}

footer .sponsor ul li {
  list-style: none;
  display: inline-block;
}

footer ul li a {
  color: #1a1a1a;
  text-decoration: none;
}

footer .sponsor h2 {
  /*font-size: .875rem;*/
  font-size: 14px;
  line-height: 16px;
  letter-spacing: .4px;
  font-family: 'KoPubDotum-Bold', sans-serif;
  text-align: left;
  margin-left: 16px;
  margin-bottom: 24px;
  text-transform: uppercase;
}

footer .sponsor__primary ul li a,
footer .sponsor__secondary ul li a {
  color: #1a1a1a;
  text-decoration: none;
  padding: 10px 16px;
  border-bottom: 1px solid transparent;
  display: inline-block;
  /*margin: 0 8px 8px 0;     */
}

.footer__social-links {
  float: left;
  background: #2a2a2a;
  width: 100%;
  min-height: 132px;
  text-align: center;
  position: relative;
  clear: both;
}

  .footer__social-links .social-icons {
      padding-top: 24px;
      padding-bottom: 24px;
  }

      .footer__social-links .social-icons ul {
          float: none;
          padding: 0 75px;
      }

          .footer__social-links .social-icons ul li {
              margin-right: 8px;
              margin-bottom: 8px;
              display: inline-block;
              /*padding: 0px;*/
          }

.footer__menu {
  float: left;
  background: #1a1a1a;
  width: 100%;
  border-top: 1px solid rgba(217, 217, 217, .3);
  padding-top: 7px;
  margin: 0px;
  padding: 0px;
}

.footer__menu__links {
  padding: 0px 16px;
}

.footer__menu .footer__menu__links ul {
  margin-bottom: 20px;
  width: 100%;
  margin: 0px 0px 20px;
  padding: 0px;
}

  .footer__menu .footer__menu__links ul li {
      width: 100%;
      border-bottom: 1px solid rgba(229, 229, 229, .2);
      text-align: left;
      padding: 15px 0;
      line-height: normal;
  }

      .footer__menu .footer__menu__links ul li:last-child {
          border: none;
      }

      .footer__menu .footer__menu__links ul li a {
          color: #fff;
          font-size: 14px;
          font-size: .875rem;
      }

.footer__menu .footer__menu__copyright ul li {
  color: #a3a3a3;
  font-size: 14px;
  font-size: .875rem;
  line-height: 19px;
  padding: 0 16px;
  margin-bottom: 20px;
  list-style: none;
}

.sponsor {
  text-align: center;
  padding: 24px 0;
}

.social-button {
  margin-bottom: 24px;
}
/*Footer End*/
/*Main Container*/

.content-panel_text {
  padding-left: 16px;
  padding-right: 16px;
  font-size: 15px;
  /*font-size: 1.25rem;*/
  font-weight: 300;
  line-height: 30px;
  margin-top: 24px;
  clear: both;
}

  .content-panel_text a {
      color: inherit;
      text-decoration: none;
      border-bottom: 1px solid #9c9c9c;
      clear: both;
  }

.content-bar {
  font-size: 14px;
  line-height: normal;
  margin: 16px;
  padding-bottom: 16px;
}

.top-panel h1 {
  font-family: 'KoPubDotum-Bold', serif, sans-serif;
  font-size: 27px;
  line-height: 33.12px;
  margin: 16px 16px 0px;
}

.heading {
  font-size: 18px;
  line-height: 34px;
  letter-spacing: -.2px;
}

.social__holder {
  font-size: 14px;
  font-family: 'KoPubDotum-Medium', serif, sans-serif;
  margin: 14px 16px 0px;
}

span.social__time {
  float: right;
}
/*Article card start*/

.mu-item,
.image-panel {
  margin: 24px 16px 0px;
  background: #ececec;
}

  .mu-item.profile {
      background: transparent;
  }

.mu-item__info {
  padding: 0px 16px 16px;
}

.mu-item__title {
  position: relative;
  margin-top: 36px;
  font-family: 'KoPubDotum-Bold', serif, sans-serif;
  font-size: 18px;
  margin-bottom: 0px;
}

  .mu-item__title:before,
  .mu-item__logo-title:before {
      border-top: 2px solid #c81617;
      position: absolute;
      width: 61px;
      content: '';
      top: -16px;
  }

.mu-item__teasure {
  font-size: 1.25rem;
  font-weight: 300;
}
/*Main container End*/
/*Buttons*/

.btn-skew {
  display: inline-block;
  white-space: nowrap;
  transform: skew(-10deg);
  margin: 0 auto;
  vertical-align: middle;
  border: 1px solid rgba(255, 255, 255, .15);
}

  .btn-skew a {
      padding: 14px 26px;
      float: left;
      text-decoration: none;
      color: white;
      font-size: .875rem;
      font-family: 'KoPubDotum-Bold', serif, sans-serif;
      text-align: center;
      float: left;
      font-style: italic;
      letter-spacing: .6px;
      transform: skew(10deg);
      text-transform: uppercase;
  }

  .btn-skew.red {
      background: #c70101;
      margin-left: 8px;
  }
/*Buttons End*/
/*Quote Card*/

.quote blockquote {
  margin: 0px;
}

.quote a {
  text-decoration: none;
}

.quote .mu-item__title {
  font-size: 14px;
  line-height: 26px;
  font-family: 'Source Sans Pro', sans-serif;
  margin-top: 16px;
}

.mu-quotes__title {
  font-size: 15px;
  line-height: 26px;
  margin-top: 3px;
}

.mu-quotes__author {
  font-size: 12px;
}

.mu-item a,
.mu-item__title {
  color: #1a1a1a;
}

.mu-item.print-prod .mu-item__logo-title {
  font-size: 12px;
  font-size: .75rem;
  line-height: .9;
  font-family: 'KoPubDotum-Bold', sans-serif;
  text-transform: uppercase;
  margin-bottom: 12px;
  position: relative;
  margin-top: 36px;
}

.mu-item.print-prod a,
.mu-item.dfp-ads-card a {
  text-decoration: none;
}

.mu-item.print-prod .mu-item__title {
  margin-top: 0px;
  text-decoration: underline;
}

.mu-item.dfp-ads-card .dfp-ads__cta {
  margin-top: 0px;
  text-decoration: underline;
  font-style: italic;
  text-transform: uppercase;
  font-size: 14px;
  font-style: italic;
  text-transform: uppercase;
  letter-spacing: .6px;
  font-weight: 600;
}

.mu-item.print-prod .mu-item__title:before,
.mu-item.dfp-ads-card .mu-item__title:before,
.mu-item.quote .mu-item__title:before {
  display: none;
}

.mu-item.profile a {
  font-family: 'KoPubDotum-Bold';
}

.mu-item.profile span {
  font-family: 'KoPubDotum-Bold';
  font-size: 18px;
  margin-right: 2px;
}

  .mu-item.profile span:after {
      content: ':';
  }

.mu-item.profile h2.player-name {
  display: inline;
  font-size: 18px;
}

.mu-item.dfp-ads-card .mu-item__title {
  font-family: 'KoPubDotum-Bold', sans-serif;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 24px;
  margin-top: 10px;
  margin-bottom: 12px;
}
/*Table */

.mu-table {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 20px;
  padding-top: 20px;
  max-width: 670px;
  float: none;
  margin-left: auto;
  margin-right: auto;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 auto;
  width: 100%;
  float: left;
  float: none;
}

  table tr {
      border-bottom: 1px solid rgba(0, 0, 0, .1);
  }

  table td {
      padding: 5px 0;
      font-size: 12px;
      float: none;
      position: relative;
      text-align: left;
      line-height: 1.35;
      display: inline-block;
      word-break: break-word;
      padding: 20px 0;
      display: table-cell;
  }

amp-twitter {
  margin: 0;
}

.dfp-ad {
  margin-top: 24px;
}

.mu-item.profile .mu-item__info {
  padding: 0px;
}

.mu-item__sponsor {
  background: #f8f8f8;
  float: left;
  max-height: 40px;
  margin: 16px 16px 30px;
}

  .mu-item__sponsor a {
      display: inline-block;
      padding: 6px 8px;
      float: left;
      text-decoration: none;
  }

      .mu-item__sponsor a span:first-child {
          color: #858585;
          margin-right: 16px;
          padding-top: 6px;
          padding-bottom: 6px;
          text-transform: uppercase;
          font-size: .75rem;
          float: left;
      }

.social__holder-bottom {
  margin-top: 32px;
  float: left;
  width: 100%;
}
/*.content-panel_text {
                  margin-top: 24px;
              }*/

amp-social-share.custom-style {
  background-color: #008080;
  background-image: url('https://raw.githubusercontent.com/google/material-design-icons/master/social/1x_web/ic_share_white_48dp.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

amp-social-share.rounded {
  border-radius: 50%;
  background-size: 60%;
  margin-left: 3px;
}

  amp-social-share.rounded:first-child {
      margin-left: 0px;
  }

.main-container:after {
  display: table;
  content: '';
  clear: both;
}
/*Recommendations start*/

.content-panel_recommendations ul {
  list-style: none;
  padding-inline-start: 0px;
}

.content-panel_recommendations .player-title {
  font-family: 'KoPubDotum-Bold', serif, sans-serif;
  font-size: 30px;
  margin: 24px 16px 0px;
}

amp-user-notification > div {
  font: 12px/1.5 Helvetica, Arial, sans-serif;
  color: #fff;
  background: #000;
  padding: 1rem;
}

  amp-user-notification > div a {
      color: #fff;
  }

amp-user-notification button {
  margin-top: 10px;
  height: 25px;
}
/*Cookie Policy Consent Starts here*/
.cookie-policy-message {
  float: left;
  background: #1a1a1a;
  padding: 20px 10px;
  position: fixed;
  z-index: 9999;
  color: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  -moz-border-radius: 16px 16px 0 0;
  border-radius: 16px 16px 0 0;
  -webkit-border-radius: 16px 16px 0 0;
  -webkit-mask-image: -webkit-radial-gradient(white,#000);
}

.cookie-message__content .dismiss-button {
  text-align: right;
}

.cookie-policy-message.non-eu {
  padding: 22px;
}

.cookie-policy-message h2 {
  margin: 5px 0;
}

.cookie-policy-message p {
  font-size: 14px;
  line-height: 20px;
  margin: 9px 0;
}

.cookie-policy-message.non-eu p {
  font-size: 14px;
  text-align: left;
}

.cookie-policy-message button.black-btn-skew span,
.cookie-policy-message button.red-btn-skew span {
  line-height: 30px;
}

.cookie-policy-message .cookie-buttons {
  border-top: 1px solid rgba(223, 223, 223, 0.1);
  margin-top: 19px;
  padding-top: 17px;
  text-align: center;
}

.cookie-policy-message.non-eu .cookie-buttons {
  border-top: none;
  margin-top: 8px;
}

.cookie-policy-message .cookie-buttons .black-btn-skew {
  border: 1px solid #ddd;
}

.cookie-policy-message .cookie-buttons .btn {
  width: 135px;
}

.cookie-message__content {
  text-align: center;
}

  .cookie-message__content span {
      font-size: 16px;
      font-weight: bold;
  }

.cookie-setting__content p {
  margin: 3px auto 0;
}

.cookie-setting__content h2 + p {
  margin: 8px 0 20px;
}

.cookie-policy-message .cookie-buttons .black-btn-skew {
  border: 1px solid #ddd;
}

.cookie-policy-message .black-btn-skew {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #1a1a1a;
  transition: background 1s ease;
  -webkit-transition: background 1s ease;
  -moz-transition: background 1s ease;
  -o-transition: background 1s ease;
}

.cookie-policy-message .black-btn-skew,
.cookie-policy-message .link-btn-black,
.cookie-policy-message .link-btn-white,
.cookie-policy-message .red-btn-skew,
.cookie-policy-message .transparent-btn-skew,
.cookie-policy-message .white-btn-skew {
  display: inline-block;
  white-space: nowrap;
  height: 50px;
  margin: 0 auto;
  border-radius: 25px;
  cursor: pointer;
  padding: 0;
  text-transform: uppercase;
  margin: 0 5px;
}

  .cookie-policy-message .black-btn-skew span,
  .cookie-policy-message .link-btn-black span,
  .cookie-policy-message .link-btn-white span,
  .cookie-policy-message .red-btn-skew span,
  .cookie-policy-message .transparent-btn-skew span,
  .cookie-policy-message .white-btn-skew span {
      color: #1a1a1a;
      font-size: 14px;
      font-size: 0.875rem;
      /* float: left; */
      line-height: 50px;
      padding: 0 32px;
      text-align: center;
      padding: 0;
  }

  .cookie-policy-message .black-btn-skew i,
  .cookie-policy-message .link-btn-black i,
  .cookie-policy-message .link-btn-white i,
  .cookie-policy-message .red-btn-skew i,
  .cookie-policy-message .transparent-btn-skew i,
  .cookie-policy-message .white-btn-skew i {
      display: none;
      margin: 3px 0 0 8px;
      float: right;
  }

.cookie-policy-message .red-btn-skew {
  border: 1px solid rgba(199, 1, 1, 0.2);
  background: #c70101;
  transition: background 1s ease;
  -webkit-transition: background 1s ease;
  -moz-transition: background 1s ease;
  -o-transition: background 1s ease;
}

  .cookie-policy-message .red-btn-skew span {
      color: #fff;
  }

  .cookie-policy-message .red-btn-skew:hover {
      background: #9f0000;
      transition: background 0s;
      -webkit-transition: background 0s;
      -moz-transition: background 0s;
      -o-transition: background 0s;
  }

.cookie-policy-message .black-btn-skew {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #1a1a1a;
  transition: background 1s ease;
  -webkit-transition: background 1s ease;
  -moz-transition: background 1s ease;
  -o-transition: background 1s ease;
}

  .cookie-policy-message .black-btn-skew span {
      color: #fff;
      text-align: center;
  }

  .cookie-policy-message .black-btn-skew:hover {
      background: #474747;
      transition: background 0s;
      -webkit-transition: background 0s;
      -moz-transition: background 0s;
      -o-transition: background 0s;
  }

.cookie-policy-message .white-btn-skew {
  background: #fff;
  transition: background 1s ease;
  -webkit-transition: background 1s ease;
  -moz-transition: background 1s ease;
  -o-transition: background 1s ease;
  border: 1px solid rgba(73, 74, 80, 0.2);
}

  .cookie-policy-message .white-btn-skew:hover {
      transition: unset;
      -webkit-transition: unset;
      -moz-transition: unset;
      -o-transition: unset;
      background-color: rgba(184, 183, 185, 0.15);
  }

.cookie-policy-message .transparent-btn-skew {
  background-color: none;
}

  .cookie-policy-message .transparent-btn-skew a {
      padding: 0;
  }

  .cookie-policy-message .transparent-btn-skew span {
      color: #1a1a1a;
      font-size: 14px;
      font-size: 0.875rem;
      letter-spacing: 0.6px;
      text-transform: uppercase;
  }

.fixedTap {
  position: fixed;
  bottom: 50px;
  right: 10px;
  color: #fff;
  height: 40px;
  display: none;
}
.widget{
  position: absolute;
  /*right: 50px;
  bottom: 20px;*/
  transform: translate(-19%, 0);
  transition: all 500ms ease-out;
  width: 200px;
  border: 0;
  background-color: #c70101;
  -webkit-box-shadow: -1px 4px 8px -4px rgba(0,0,0,0.7);
  -moz-box-shadow: -1px 4px 8px -4px rgba(0,0,0,0.7);
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.fixedTap .img-btn {
  margin: 7px 5px 0 10px;
  background: url(/../amp/icons/settings-icon.png) no-repeat;
  width: 30px;
  height: 32px;
  display: inline-block;
}

.fixedTap .clickbtn {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 14px;
  font-family: 'Source Sans Pro';
  vertical-align: top;
  margin-top: 10px;
  display: inline-block;
  border: 0;
}

.clickbtn:focus {
  border: 0;
  outline: none;
}

.fixedTap:hover .widget, .fixedTap:active .widget {
  transform: translate(0,0);
  float: none;
  margin-left: -152px;
}


.tint-overlay {
  width: 100vw;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
  /*height: 100vh;*/
  height: 3200px;
}

.cookie-message__content a {
  color: #fff;
}

body.amp-geo-group-eea #post-consent-ui .fixedTap {
  display: block;
}
/*Cookie Policy Consent Ends here*/
`;

export const AR_STYLE = `
:root {
  --color-error: #B00020;
}

@font-face {
  font-family: 'ChangaBold';
  font-style: normal;
  font-weight: 400;
  src: local('ChangaBold'), url(/amp/fonts/Changa/ChangaBold.woff) format('truetype');
}

@font-face {
  font-family: 'ChangaLight';
  font-style: normal;
  font-weight: 400;
  src: local('ChangaLight'), url(/amp/fonts/Changa/ChangaLight.woff) format('truetype');
}

@font-face {
  font-family: 'ChangaRegular';
  font-style: normal;
  font-weight: 400;
  src: local('ChangaRegular'), url(/amp/fonts/Changa/ChangaRegular.woff) format('truetype');
}

.comicamp-loading .comicamp,
.comicamp2-loading .comicamp2,
.unavailable-font-loading .unavailable-font {
  color: var(--color-primary);
}

.comicamp-missing .comicamp,
.comicamp2-missing .comicamp2,
.unavailable-font-missing .unavailable-font {
  color: var(--color-error);
}

body {
  background-color: #2b2b2b;
  direction: rtl;
}

.main-container {
  background-color: white;
  padding-bottom: 24px;
}

.dfp-ad.square {
  margin-right: calc(50% - 150px);
}

.dfp-ad.banner {
  margin-right: calc(50% - 160px);
}

.screenreader {
  position: absolute;
  right: -10000px;
  top: auto;
  top: initial;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

body {
  /* font-family: 'Source Sans Pro', sans-serif;*/
  font-family: 'ChangaLight', sans-serif;
  color: #1a1a1a;
}

.mu-logo {
  float: right;
  margin: 10px 16px 10px 16px;
}

header .top-container {
  height: 66px;
  background-image: radial-gradient(circle at 50% 55%, #cc1a1a, #c70101), linear-gradient(#c70101, #c70101);
}

header .bottom-container {
  height: 45px;
  background: black;
  float: right;
  width: 100%;
}

  header .bottom-container .hamburger {
      color: #fff;
      height: 20px;
      width: 20px;
      float: right;
      position: relative;
      display: block;
      right: 16px;
      top: 13px;
  }

      header .bottom-container .hamburger span {
          background: white;
          display: block;
          height: 2px;
          border-radius: 1px;
          position: relative;
      }

.hamburger span:nth-child(2),
.hamburger span:nth-child(3) {
  margin-top: 6px;
}

.home-header__sponsor {
  margin: 20px 16px;
  width: auto;
  display: block;
  float: left;
}

  .home-header__sponsor a {
      float: right;
      height: 25px;
      border-radius: 4px;
      background-color: #000;
  }

      .home-header__sponsor a .sponsor-txt {
          letter-spacing: .4px;
          color: #fff;
          font-size: 10px;
          font-size: .625rem;
          margin-right: 15px;
          float: right;
          line-height: 13px;
          margin-top: 6px;
      }

  .home-header__sponsor amp-img {
      margin: 8px 10px;
      width: 58px;
  }

amp-sidebar {
  background-color: #ffffff;
  width: 200px;
}

ul.sidebar {
  margin: 0;
  padding: 0 16px 150px;
}

  ul.sidebar li {
      list-style: none;
      border-bottom: 1px solid #e5e5e5;
      padding: 0 12px;
  }

      ul.sidebar li a {
          color: #1a1a1a;
          /*font-family: SourceSansProRegular, Helvetica, sans-serif;*/
          font-size: 1rem;
          font-weight: 600;
          text-decoration: none;
          display: inline-block;
          height: 45px;
          line-height: 45px;
      }

.close-sidebar {
  line-height: 22px;
  height: 22px;
  width: 22px;
  border: 1px solid;
  text-align: center;
  color: #fff;
  background: black;
  border-radius: 50%;
  font-size: 12px;
  margin: 10px;
  position: relative;
  right: 0;
}

.bottom-sponsor {
  display: inline-block;
  float: left;
  margin-left: 6px;
}

  .bottom-sponsor ul {
      list-style: none;
      padding: 0px;
      margin: 0px;
      margin-top: 4px;
  }

  .bottom-sponsor li {
      display: inline-block;
      padding: 5px 6px;
  }

  .bottom-sponsor amp-img img, .sponsor__primary amp-img img, .sponsor__secondary amp-img img {
      object-fit: contain;
  }
/*Footer Start*/

footer.footer {
  float: left;
  background: white;
  font-family: 'ChangaRegular', sans-serif;
  font-size: 14px;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  /*margin-top: 24px;*/
}

footer ul {
  padding: 0px
}

footer .sponsor ul li {
  list-style: none;
  display: inline-block;
}

footer ul li a {
  color: #1a1a1a;
  text-decoration: none;
}

footer .sponsor h2 {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: .4px;
  font-family: 'ChangaBold', sans-serif;
  /* font-weight: 400px;*/
  /*font-family: Montserrat, sans-serif;*/
  text-align: right;
  margin-right: 16px;
  margin-bottom: 24px;
  text-transform: uppercase;
}

footer .sponsor__primary ul li a,
footer .sponsor__secondary ul li a {
  color: #1a1a1a;
  text-decoration: none;
  padding: 10px 16px;
  border-bottom: 1px solid transparent;
  display: inline-block;
  /*margin: 0 8px 8px 0;     */
}

.footer__social-links {
  float: left;
  background: #2a2a2a;
  width: 100%;
  min-height: 132px;
  text-align: center;
  position: relative;
  clear: both;
}

  .footer__social-links .social-icons {
      padding-top: 24px;
      padding-bottom: 24px;
  }

      .footer__social-links .social-icons ul {
          float: none;
          padding: 0 75px;
      }

          .footer__social-links .social-icons ul li {
              margin-right: 8px;
              margin-bottom: 8px;
              display: inline-block;
              /*padding: 0px;*/
          }

.footer__menu {
  float: left;
  background: #1a1a1a;
  width: 100%;
  border-top: 1px solid rgba(217, 217, 217, .3);
  padding-top: 7px;
  margin: 0px;
  padding: 0px;
}

.footer__menu__links {
  padding: 0px 16px;
}

.footer__menu .footer__menu__links ul {
  margin-bottom: 20px;
  width: 100%;
  margin: 0px 0px 20px;
  padding: 0px;
}

  .footer__menu .footer__menu__links ul li {
      width: 100%;
      border-bottom: 1px solid rgba(229, 229, 229, .2);
      text-align: right;
      padding: 15px 0;
      line-height: normal;
  }

      .footer__menu .footer__menu__links ul li:last-child {
          border: none;
      }

      .footer__menu .footer__menu__links ul li a {
          color: #fff;
          font-size: 14px;
          font-size: .875rem;
      }

.footer__menu .footer__menu__copyright ul li {
  color: #a3a3a3;
  font-size: 14px;
  font-size: .875rem;
  line-height: 19px;
  padding: 0 16px;
  margin-bottom: 20px;
  list-style: none;
}

.sponsor {
  text-align: center;
  padding: 24px 0;
}

.social-button {
  margin-bottom: 24px;
}
/*Footer End*/
/*Main Container*/

.content-panel_text {
  padding-left: 16px;
  padding-right: 16px;
  /*font-size:20px;*/
  font-size: 14px;
  font-size: .875rem;
  /*font-weight: 300;*/
  line-height: 30px;
  margin-top: 24px;
  clear: both;
}

  .content-panel_text a {
      color: inherit;
      text-decoration: none;
      border-bottom: 1px solid #9c9c9c;
      clear: both;
  }

.content-bar {
  font-size: 14px;
  line-height: normal;
  margin: 16px;
  /*padding-bottom: 16px;*/
  float: right;
}

.top-panel h1 {
  font-family: 'ChangaBold', sans-serif;
  /*font-weight: 700;*/
  font-size: 26px;
  line-height: 1.7;
  margin: 16px 16px 0px;
}

.heading {
  letter-spacing: -.2px;
  font-size: 17px;
  font-size: 1.0625rem;
  line-height: 34px;
}

.social__holder {
  font-size: 14px;
  font-family: 'ChangaRegular', serif, sans-serif;
  margin: 14px 16px 0px;
}

span.social__time {
  float: left;
}
/*Article card start*/

.mu-item,
.image-panel {
  margin: 24px 16px 0px;
  background: #ececec;
}

  .mu-item.profile {
      background: transparent;
  }

.mu-item__info {
  padding: 0px 16px 16px;
  float: right;
}

.mu-item__title {
  position: relative;
  margin-top: 36px;
  font-family: 'ChangaBold', sans-serif;
  /*font-weight: 700;*/
  font-size: 17px;
  line-height: 34px;
  margin-bottom: 0px;
}

  .mu-item__title:before,
  .mu-item__logo-title:before {
      border-top: 2px solid #c81617;
      position: absolute;
      width: 61px;
      content: '';
      top: -16px;
  }

.mu-item__teasure {
  font-size: 1.25rem;
  font-weight: 300;
}
/*Main container End*/
/*Buttons*/

.btn-skew {
  display: inline-block;
  white-space: nowrap;
  transform: skew(-10deg);
  margin: 0 auto;
  vertical-align: middle;
  border: 1px solid rgba(255, 255, 255, .15);
}

  .btn-skew a {
      padding: 14px 26px;
      float: left;
      text-decoration: none;
      color: white;
      font-size: .875rem;
      font-family: 'Changa', sans-serif;
      text-align: center;
      float: left;
      font-style: italic;
      letter-spacing: .6px;
      transform: skew(10deg);
      text-transform: uppercase;
  }

  .btn-skew.red {
      background: #c70101;
      margin-right: 8px;
  }
/*Buttons End*/
/*Quote Card*/

.quote blockquote {
  margin: 0px;
}

.quote a {
  text-decoration: none;
}

.quote .mu-item__title {
  font-size: 14px;
  line-height: 26px;
  font-family: 'ChangaRegular', sans-serif;
  margin-top: 16px;
}

.mu-quotes__title {
  font-size: 14px;
  line-height: 26px;
  margin-top: 3px;
}

p.mu-quotes__author {
  font-size: 13px;
  line-height: 24px;
}

.mu-item a,
.mu-item__title {
  color: #1a1a1a;
  float: right;
  clear: both;
  width: 100%;
  text-decoration: underline;
}

.mu-item.print-prod .mu-item__logo-title {
  font-size: 12px;
  font-size: .75rem;
  line-height: .9;
  font-family: 'ChangaBold', sans-serif;
  text-transform: uppercase;
  margin-bottom: 12px;
  position: relative;
  margin-top: 36px;
  float: right;
}

.mu-item.print-prod a,
.mu-item.dfp-ads-card a {
  text-decoration: none;
}

.mu-item.print-prod .mu-item__title {
  margin-top: 0px;
  text-decoration: underline;
}

.mu-item.dfp-ads-card .dfp-ads__cta {
  margin-top: 0px;
  text-decoration: underline;
  font-style: italic;
  text-transform: uppercase;
  font-size: 12px;
  font-style: italic;
  text-transform: uppercase;
  letter-spacing: .6px;
  font-weight: 600;
}

.mu-item.print-prod .mu-item__title:before,
.mu-item.dfp-ads-card .mu-item__title:before,
.mu-item.quote .mu-item__title:before {
  display: none;
}

.mu-item.profile a {
  font-family: 'Changa', sans-serif;
  font-weight: 600;
  float: none;
}

.mu-item.profile span {
  font-family: 'Changa', sans-serif;
  font-weight: 600;
  font-size: 17px;
  font-size: 1.0625rem;
  margin-left: 2px;
  line-height: 34px;
  float: right;
}

  .mu-item.profile span:after {
      content: ':';
  }

.mu-item.profile h2.player-name {
  display: inline;
  font-size: 17px;
  font-size: 1.0625rem;
  line-height: 34px;
}

.mu-item.dfp-ads-card .mu-item__title {
  /*font-family: Montserrat;*/
  font-family: 'ChangaBold', sans-serif;
  /*font-weight:700;*/
  text-transform: uppercase;
  font-size: 14;
  line-height: 24px;
  margin-top: 10px;
  margin-bottom: 12px;
}
/*Table */

.mu-table {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 20px;
  padding-top: 20px;
  max-width: 670px;
  float: none;
  margin-left: auto;
  margin-right: auto;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 auto;
  width: 100%;
  float: left;
  float: none;
}

  table tr {
      border-bottom: 1px solid rgba(0, 0, 0, .1);
  }

  table td {
      padding: 5px 0;
      font-size: 12px;
      float: none;
      position: relative;
      text-align: right;
      line-height: 1.35;
      display: inline-block;
      word-break: break-word;
      padding: 20px 0;
      display: table-cell;
  }

amp-twitter {
  margin: 0;
}

.dfp-ad {
  margin-top: 24px;
}

.mu-item.profile .mu-item__info {
  padding: 0px;
}

.mu-item__sponsor {
  background: #f8f8f8;
  float: right;
  max-height: 40px;
  margin: 16px 16px 30px;
}

  .mu-item__sponsor a {
      display: inline-block;
      padding: 6px 8px;
      float: left;
      text-decoration: none;
  }

      .mu-item__sponsor a span:first-child {
          color: #858585;
          margin-left: 16px;
          padding-top: 6px;
          padding-bottom: 6px;
          text-transform: uppercase;
          font-size: .75rem;
          float: right;
          line-height: 12px;
      }

.social__holder-bottom {
  margin-top: 32px;
  float: left;
  width: 100%;
}
/*.content-panel_text {
          margin-top: 24px;
      }*/

amp-social-share.custom-style {
  background-color: #008080;
  background-image: url('https://raw.githubusercontent.com/google/material-design-icons/master/social/1x_web/ic_share_white_48dp.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

amp-social-share.rounded {
  border-radius: 50%;
  background-size: 60%;
  margin-left: 3px;
}

  amp-social-share.rounded:first-child {
      margin-left: 0px;
  }

.main-container:after {
  display: table;
  content: '';
  clear: both;
}
/*Recommendations start*/

.content-panel_recommendations ul {
  list-style: none;
  padding-inline-start: 0px;
}

.content-panel_recommendations .player-title {
  font-family: 'BebasNeue', serif, sans-serif;
  font-size: 28px;
  margin: 24px 16px 0px;
}
/*.image-panel {
      float: right;
      margin: 24px 16px 16px;
      }*/

.mu-item::before,
.mu-item::after,
.image-panel::before,
.image-panel::after {
  content: "";
  clear: both;
  display: table;
}

amp-iframe,
amp-img {
  direction: none;
  width: 100%;
  height: 100%;
  float: left;
}

amp-twitter {
  direction: none;
  width: 100%;
  height: 100%;
  float: none;
}

.social__holder-bottom .mu-item__sponsor span:last-child {
  margin-top: 6px;
  float: left;
}

amp-user-notification > div {
  font: 12px/1.5 Helvetica, Arial, sans-serif;
  color: #fff;
  background: #000;
  padding: 1rem;
}

  amp-user-notification > div a {
      color: #fff;
  }

amp-user-notification button {
  margin-top: 10px;
  height: 25px;
}
/*Cookie Policy Consent Starts here*/
.cookie-policy-message {
  float: left;
  background: #1a1a1a;
  padding: 20px 10px;
  position: fixed;
  z-index: 9999;
  color: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  -moz-border-radius: 16px 16px 0 0;
  border-radius: 16px 16px 0 0;
  -webkit-border-radius: 16px 16px 0 0;
  -webkit-mask-image: -webkit-radial-gradient(white,#000);
}

.cookie-message__content .dismiss-button {
  text-align: right;
}

.cookie-policy-message.non-eu {
  padding: 22px;
}

.cookie-policy-message h2 {
  margin: 5px 0;
}

.cookie-policy-message p {
  font-size: 14px;
  line-height: 20px;
  margin: 9px 0;
}

.cookie-policy-message.non-eu p {
  font-size: 14px;
  text-align: left;
}

.cookie-policy-message button.black-btn-skew span,
.cookie-policy-message button.red-btn-skew span {
  line-height: 30px;
}

.cookie-policy-message .cookie-buttons {
  border-top: 1px solid rgba(223, 223, 223, 0.1);
  margin-top: 19px;
  padding-top: 17px;
  text-align: center;
}

.cookie-policy-message.non-eu .cookie-buttons {
  border-top: none;
  margin-top: 8px;
}

.cookie-policy-message .cookie-buttons .black-btn-skew {
  border: 1px solid #ddd;
}

.cookie-policy-message .cookie-buttons .btn {
  width: 135px;
}

.cookie-message__content {
  text-align: center;
}

  .cookie-message__content span {
      font-size: 16px;
      font-weight: bold;
  }

.cookie-setting__content p {
  margin: 3px auto 0;
}

.cookie-setting__content h2 + p {
  margin: 8px 0 20px;
}

.cookie-policy-message .cookie-buttons .black-btn-skew {
  border: 1px solid #ddd;
}

.cookie-policy-message .black-btn-skew {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #1a1a1a;
  transition: background 1s ease;
  -webkit-transition: background 1s ease;
  -moz-transition: background 1s ease;
  -o-transition: background 1s ease;
}

.cookie-policy-message .black-btn-skew,
.cookie-policy-message .link-btn-black,
.cookie-policy-message .link-btn-white,
.cookie-policy-message .red-btn-skew,
.cookie-policy-message .transparent-btn-skew,
.cookie-policy-message .white-btn-skew {
  display: inline-block;
  white-space: nowrap;
  height: 50px;
  margin: 0 auto;
  border-radius: 25px;
  cursor: pointer;
  padding: 0;
  text-transform: uppercase;
  margin: 0 5px;
}

  .cookie-policy-message .black-btn-skew span,
  .cookie-policy-message .link-btn-black span,
  .cookie-policy-message .link-btn-white span,
  .cookie-policy-message .red-btn-skew span,
  .cookie-policy-message .transparent-btn-skew span,
  .cookie-policy-message .white-btn-skew span {
      color: #1a1a1a;
      font-size: 14px;
      font-size: 0.875rem;
      /* float: left; */
      line-height: 50px;
      padding: 0 32px;
      text-align: center;
      padding: 0;
  }

  .cookie-policy-message .black-btn-skew i,
  .cookie-policy-message .link-btn-black i,
  .cookie-policy-message .link-btn-white i,
  .cookie-policy-message .red-btn-skew i,
  .cookie-policy-message .transparent-btn-skew i,
  .cookie-policy-message .white-btn-skew i {
      display: none;
      margin: 3px 0 0 8px;
      float: right;
  }

.cookie-policy-message .red-btn-skew {
  border: 1px solid rgba(199, 1, 1, 0.2);
  background: #c70101;
  transition: background 1s ease;
  -webkit-transition: background 1s ease;
  -moz-transition: background 1s ease;
  -o-transition: background 1s ease;
}

  .cookie-policy-message .red-btn-skew span {
      color: #fff;
  }

  .cookie-policy-message .red-btn-skew:hover {
      background: #9f0000;
      transition: background 0s;
      -webkit-transition: background 0s;
      -moz-transition: background 0s;
      -o-transition: background 0s;
  }

.cookie-policy-message .black-btn-skew {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #1a1a1a;
  transition: background 1s ease;
  -webkit-transition: background 1s ease;
  -moz-transition: background 1s ease;
  -o-transition: background 1s ease;
}

  .cookie-policy-message .black-btn-skew span {
      color: #fff;
      text-align: center;
  }

  .cookie-policy-message .black-btn-skew:hover {
      background: #474747;
      transition: background 0s;
      -webkit-transition: background 0s;
      -moz-transition: background 0s;
      -o-transition: background 0s;
  }

.cookie-policy-message .white-btn-skew {
  background: #fff;
  transition: background 1s ease;
  -webkit-transition: background 1s ease;
  -moz-transition: background 1s ease;
  -o-transition: background 1s ease;
  border: 1px solid rgba(73, 74, 80, 0.2);
}

  .cookie-policy-message .white-btn-skew:hover {
      transition: unset;
      -webkit-transition: unset;
      -moz-transition: unset;
      -o-transition: unset;
      background-color: rgba(184, 183, 185, 0.15);
  }

.cookie-policy-message .transparent-btn-skew {
  background-color: none;
}

  .cookie-policy-message .transparent-btn-skew a {
      padding: 0;
  }

  .cookie-policy-message .transparent-btn-skew span {
      color: #1a1a1a;
      font-size: 14px;
      font-size: 0.875rem;
      letter-spacing: 0.6px;
      text-transform: uppercase;
  }

.fixedTap {
  position: fixed;
  bottom: 50px;
  left: 11px;
  color: #fff;
  height: 40px;
  width: 40px;
  display: none;
}

.widget {
  position: absolute;
  transform: translate(-3%, 0);
  transition: all 500ms ease-out;
  width: 200px;
  border: 0;
  background-color: #c70101;
  -webkit-box-shadow: -1px 4px 8px -4px rgba(0,0,0,0.7);
  -moz-box-shadow: -1px 4px 8px -4px rgba(0,0,0,0.7);
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.fixedTap .img-btn {
  margin: 8px 5px 0 12px;
  background: url(/../amp/icons/settings-icon.png) no-repeat;
  width: 30px;
  height: 32px;
  display: inline-block;
  float: right;
}

.fixedTap .clickbtn {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 14px;
  font-family: 'Source Sans Pro';
  vertical-align: top;
  margin-top: 10px;
  display: inline-block;
  float: right;
  border: 0;
}

.clickbtn:focus {
  border: 0;
  outline: none;
}

.fixedTap:hover .widget, .fixedTap:active .widget {
  transform: translate(0,0);
  float: none;
  margin-right: -130px;
}


.tint-overlay {
  width: 100vw;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
  /*height: 100vh;*/
  height: 3200px;
}

.cookie-message__content a {
  color: #fff;
}

body.amp-geo-group-eea #post-consent-ui .fixedTap {
  display: block;
}
/*Cookie Policy Consent Ends here*/
`;

export const SIGN_IN_PAGE_KEY = 'LOGIN';

export const SIGN_UP_PAGE_KEY = 'SignUp';

export const AMPArticleDetailContentStyle = {
  video: { className: 'video' },
  poll: { className: 'article-card' },
  gallery: { className: 'image-gallery' },
  quiz: { className: 'quiz-card' },
  image: { className: 'image-card' },
  adcard: { className: 'dfp-ads-card' },
  article: { className: 'article-card' },
};

export const AMPApplicableCards = [
  'video',
  'poll',
  'gallery',
  'quiz',
  'image',
  'adcard',
  'article',
];
