/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react';
import { useSponsorValue } from '../../../context/sponsorContext';
import moment from 'moment';
import * as track from '../../../utils/analytics';
import MutvSlider from '../MutvSlider';
import InlineVideo from '../../Cards/Video/InlineVideo';
import api from '../../../utils/muApi';
import { GET_EPG } from '../../../constants/endpoints';
import {
  getLiveSetremId,
  createHeroData,
  setInlineVideoContainerUtil,
  getOrientation,
} from '../mutvutil';
import HeroNotes from './HeroNotes';
import {
  getContentAccessLocal,
  getRandomId,
  createTagList,
  getLoggedInStatus,
  showCBS,
  onCloseMedia,
  createDestinationUrl,
  getParameterByName,
} from '../../../utils/utils';
import { useModal } from '../../Common/Modal';
import ErrorBoundary from '../../Common/ErrorBoundary';
import Loader from '../../Common/Loader';
import { withTranslation } from 'react-i18next';
import loadable from '@loadable/component';
import MU from '../../../constants/muConstants';
import { ACCOUNT_SUBSCRIPTION, THRESHOLD } from '../../../constants/globalConstants';
const RegistrationModal = loadable(() =>
  import('../../Modals/RegistrationModal/RegistrationModal')
);
const EPG = (props) => {
  const initialState = {
    epgData: null,
    weekDays: [],
    weekDate: [],
    selectedDate: null,
  };
  const { t, fields } = props;
  const [state, setState] = useState(initialState);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [toggleState, setToggleState] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);
  const [apidata, setApiData] = useState(null);
  const [toggleAnimate, setToggleAnimate] = useState(true);
  const [newWidth, setNewWidth] = useState(null);
  const [heroDetail, setHeroDetail] = useState([]);
  const [videoStyle, setVideoStyle] = useState({});
  const [showSwitching, setShowSwitching] = useState(false);
  const [liveRandomId, setLiveRandomId] = useState(null);
  const [epgStatus] = useState(
    props.fields &&
      props.fields.EPGDetail &&
      props.fields.EPGDetail.value &&
      props.fields.EPGDetail.value.IsEnableEpg
  );

  const [isEpgImage] = useState(props.params && props.params.IsEpgImage === 'false' ? false : true);
  const [intialToggleState, setIntialToggleState] = useState(false);
  const { showModal, closeModal, isModalVisible } = useModal();
  const daysWidth = useRef(null);
  const timeRef = useRef(0);
  const prevRef = useRef(null);
  const contentAccess = useRef(null);
  const isMediaObj = useRef(null);
  const containerRef = useRef(null);
  let timeoutId;
  const [
    { isGigyaAvilable, userLoginDetail, isUserLogin, isLoginChecked, isUserSubscribe, isUserSkyId },
    dispatch,
  ] = useSponsorValue();

  useEffect(() => {
    epgStatus && callApi();
  }, []);

  useEffect(() => {
    epgStatus && apidata && weekDays();
  }, [apidata]);

  useEffect(() => {
    !newWidth && daysWidth && daysWidth.current && setTabWidth();
  }, [state]);

  useEffect(() => {
    const currentData = getCurrentPlayingData();
    if (currentData && prevRef.current && currentData.itemid_s !== prevRef.current.itemid_s) {
      const {
        contentaccess_t,
        contenttype_t,
        taglist_sm,
        scheduletype_t,
        itemid_s,
        startdatetime_tdt,
        enddatetime_tdt,
      } = currentData;
      if (playVideo) {
        contentAccess.current = getContentAccessLocal(contentaccess_t, contenttype_t, taglist_sm);
        if (showCBS({ isUserLogin, isUserSubscribe, contentAccessState: contentAccess.current })) {
          showModal();
          dispatch({
            type: 'SET_MUTV_HERO_DATA',
            showHero: true,
          });
          setPlayVideo(false);
          setShowSwitching(false);
        } else {
          setLiveRandomId(getRandomId());
        }
      }
      prevRef.current = {
        itemid_s,
        contenttype_t,
        startdatetime_tdt,
        enddatetime_tdt,
        scheduletype_t,
      };
    }
    createHeroDetails(); //change next program detail in Hero details
  }, [activeIndex]);

  useEffect(() => {
    if (contentAccess.current === ACCOUNT_SUBSCRIPTION && !playVideo) {
      isMediaObj && isMediaObj.current && onCloseMedia(isMediaObj.current);
    }
    playVideo && setInlineVideoContainer();
  }, [playVideo]);

  useEffect(() => {
    window.addEventListener('resize', resizeListener);
    return () => window.addEventListener('resize', resizeListener);
  }, []);

  useEffect(() => {
    if (toggleState && state && state.epgData && !intialToggleState) {
      setIntialToggleState(true);
    }
  }, [toggleState, state]);

  const weekDays = () => {
    let weekDay = {};
    const weekDate = [];
    const totalData = {};
    for (let i = 0; i <= 6; i++) {
      const nextDays = moment().add(i, 'days').format('DD-MM-YY');
      weekDate.push(nextDays);
      if (i !== 0) {
        weekDay[nextDays] = `${moment().add(i, 'days').format('ddd')} ${moment()
          .add(i, 'days')
          .format('D')}`;
      } else {
        weekDay[nextDays] = `${t('Today')}`;
      }
    }
    getFilteredData(weekDate, totalData);
    setState({
      ...state,
      epgData: totalData,
      weekDate: weekDate,
      weekDays: weekDay,
      selectedDate: weekDate[0],
      liveSetremId: getLiveSetremId(apidata),
    });
    getActiveCard(totalData[weekDate[0]], 'setRef');
  };

  const showDuplicateRecord = (yDay, tDay, j) => {
    if (
      yDay === moment(j.startdatetime_tdt).format('DD-MM-YY') &&
      tDay === moment(j.enddatetime_tdt).format('DD-MM-YY')
    ) {
      return true;
    }
    return false;
  };

  const getFilteredData = (weekDate, totalData) => {
    if (
      apidata &&
      apidata.EpgScheduleWeeklyResponse &&
      apidata.EpgScheduleWeeklyResponse.response &&
      apidata.EpgScheduleWeeklyResponse.response.docs
    ) {
      const docs = apidata.EpgScheduleWeeklyResponse.response.docs;
      const yDay = moment().subtract(1, 'day').format('DD-MM-YY');
      const tDay = moment().format('DD-MM-YY');
      weekDate &&
        weekDate.forEach((i, index) => {
          totalData[i] = [];
          docs.forEach((j) => {
            if (index === 0 && showDuplicateRecord(yDay, tDay, j)) {
              j.isYesterdayItem = true;
              totalData[i].push(j);
            }
            if (i === moment(j.startdatetime_tdt).format('DD-MM-YY')) {
              totalData[i].push(j);
            }
          });
        });
    }
  };

  const renderRelativesCards = (e, daysname) => {
    e && e.preventDefault();
    setState({
      ...state,
      selectedDate: daysname,
    });
    setTimeout(() => {
      track.cardImpression();
    }, 500);
  };

  const getActiveCard = (data, type) => {
    if (data) {
      const arrayIndex = data.findIndex(
        (item) =>
          moment().isBetween(item.startdatetime_tdt, item.enddatetime_tdt) ||
          moment().isSame(item.startdatetime_tdt)
      );
      if (arrayIndex !== -1) {
        if (type === 'setRef') {
          /* set initial data for hero only*/
          prevRef.current = {
            itemid_s: data[arrayIndex].itemid_s,
            contenttype_t: data[arrayIndex].contenttype_t,
            startdatetime_tdt: data[arrayIndex].startdatetime_tdt,
            enddatetime_tdt: data[arrayIndex].enddatetime_tdt,
            scheduletype_t: data[arrayIndex].scheduletype_t,
          };
        }
        setActiveIndex(arrayIndex);
        activeNextCard(data, arrayIndex);
      }
    }
  };

  const toggleEpgContainer = (e) => {
    epgCarouselToggle();
    !toggleState && track.cardImpression(); //card Impression tracking when it's Open
    trackButton(); //Buttopn track EPG
    setToggleState(!toggleState);
  };
  const trackButton = () => {
    const data = track.data('button');
    track.analytics({
      ...data,
      button_name: 'EPG Scheduler',
      status: !toggleState ? 'open' : 'close',
    });
  };

  const activeNextCard = (data, currentIndex) => {
    clearInterval(timeRef.current);
    if (data && data.length > currentIndex + 1) {
      timeRef.current = setInterval(() => {
        getActiveCard(data);
      }, getTimeDiffBetweenProgram(data, currentIndex));
    } else if (data && data.length === currentIndex + 1) {
      const newTime = moment(data[currentIndex].enddatetime_tdt).diff(moment(), 'seconds');
      timeRef.current = setInterval(() => {
        typeof window !== 'undefined' && window.location.reload();
      }, newTime * 1000);
    }
  };

  const getTimeDiffBetweenProgram = (data, currentIndex) => {
    if (data && data.length > currentIndex + 1) {
      const nextstartTime = data[currentIndex + 1].startdatetime_tdt;
      const time = moment(nextstartTime).diff(moment(), 'seconds');
      const newTime = time && time > 0 && time * 1000;
      return newTime ? newTime : 1000;
    }
  };

  const oncardClick = () => {
    dispatch({ type: 'SET_MUTV_HERO_DATA', showHero: false });
    setPlayVideo(true);
    createHeroDetails();
  };

  const callApi = () => {
    const route = GET_EPG;
    api
      .get({ route })
      .then((response) => {
        response && setApiData(response.data);
        //setApiData(res);
      })
      .catch((err) => {
        console.log('error in fetching api', err);
      });
  };

  const setTabWidth = () => {
    let width = 70;
    for (let i = 0; i < 7; i++) {
      const element = daysWidth.current.childNodes[i];
      if (element) {
        const style = element.currentStyle || window.getComputedStyle(element);
        width = width + element.clientWidth + parseFloat(style.marginRight);
      }
    }
    setNewWidth(width);
  };

  const createHeroDetails = () => {
    if (state.epgData && activeIndex !== -1) {
      const data = createHeroData(state.epgData[state.weekDate[0]], activeIndex);
      data && setHeroDetail(data);
    }
  };
  useEffect(() => {
    if (heroDetail && heroDetail.length > 0) {
      contentAccess.current = getContentAccessLocal(
        heroDetail[0].contentaccess_t,
        heroDetail[0].contenttype_t,
        heroDetail[0].taglist_sm
      );
    }
  }, [heroDetail]);

  const getCurrentPlayingData = () => {
    return (
      activeIndex >= 0 &&
      state.epgData &&
      state.weekDate &&
      state.epgData[state.weekDate[0]] &&
      state.epgData[state.weekDate[0]][activeIndex]
    );
  };
  const videoData = () => {
    //'refDemo6131593193001'
    return {
      VideoID: {
        value: state.liveSetremId,
      },
      ContentType: { value: heroDetail[0].contenttype_t },
      __Published: { value: heroDetail[0].__published_tdt },
      manutds: {
        value: {
          Author: '',
          Tags: createTagList(heroDetail[0].taglist_sm, heroDetail[0].category_s),
        },
      },
      ShortHeadline: { value: heroDetail[0].title },
      ContentAccess: { value: contentAccess.current },
    };
  };
  const epgAnalytics = () => {
    return {
      program_starttime: heroDetail[0].startdatetime_tdt,
      program_endtime: heroDetail[0].enddatetime_tdt,
      content_status: heroDetail[0].contentaccess_t,
      container_type: `epg-${heroDetail[0].scheduletype_t}`,
    };
  };
  const closeEPG = () => {
    dispatch({
      type: 'SET_MUTV_HERO_DATA',
      showHero: true,
    });
    playVideo && setPlayVideo(false);
    setShowSwitching(false);
  };
  const retriveSuccessCallback = typeof window !== 'undefined' && getParameterByName('state', true);

  useEffect(() => {
    if (retriveSuccessCallback && isLoginChecked) {
      const successCallData = retriveSuccessCallback.split(',');
      if (successCallData && successCallData[0] === 'EPGCard') {
        const isSubscribedContent = successCallData[1] && successCallData[1] === 'true';
        const userSubscribed = isUserSubscribe;
        if (!isSubscribedContent || (isSubscribedContent && userSubscribed)) {
          toggleEpgContainer();
          window.scrollTo(Number(successCallData[2]), Number(successCallData[3]) - 300);
          const url = window.location.href;
          const urlMain = url.split('?')[0] ?? url;
          window.history.replaceState({}, null, urlMain);
        }
      }
    }
  }, [retriveSuccessCallback, isLoginChecked]);

  const successCallback = ({ showContent }) => {
    if (showContent) {
      dispatch({ type: 'SET_MUTV_HERO_DATA', showHero: false });
      setPlayVideo(true);
    }
  };
  const getContentAccessState = () => {
    const currentData = getCurrentPlayingData();
    if (currentData) {
      const { contentaccess_t, contenttype_t, taglist_sm } = currentData;
      return getContentAccessLocal(contentaccess_t, contenttype_t, taglist_sm);
    }
  };

  const epgCarouselToggle = () => {
    if (!toggleState) {
      setToggleAnimate(false);
    } else {
      setTimeout(() => setToggleAnimate(true), 400);
    }
  };

  const isMediaPlaying = (data) => {
    isMediaObj.current = data;
  };

  const setInlineVideoContainer = () => {
    try {
      let containerType = 'epg-carousel';
      if (containerRef.current) {
        const orientObj = {
          vD: '16x9',
          container: containerRef.current,
        };
        orientObj && orientObj.vD && setVideoStyle(setInlineVideoContainerUtil(orientObj));
      }
    } catch (e) {
      console.log('Error', e);
      return null;
    }
  };

  const resizeListener = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setInlineVideoContainer();
    }, THRESHOLD);
  };

  return (
    <React.Fragment>
      <div></div>
      {epgStatus && (
        <div className="home-row">
          {/* hero card */}
          {playVideo && state.liveSetremId && (
            <React.Fragment>
              <div
                className="mutv-hero free-trail epg-hero"
                ref={containerRef}
                style={playVideo ? videoStyle : { transition: 'all .4s ease' }}
              >
                <div className="mu-content">
                  <div className="mu-item video">
                    <div className="img-holder">
                      {/*  refDemo6131593193001 */}
                      <React.Fragment>
                        {playVideo && (
                          <InlineVideo
                            data={videoData()}
                            setShowSwitching={setShowSwitching}
                            liveRandomId={liveRandomId}
                            isMutv={{
                              subscription_status: getLoggedInStatus({
                                isLoginChecked,
                                isUserLogin,
                                isUserSubscribe,
                                isUserSkyId,
                              }),
                              isPodcast: false,
                              additionalAnalytics: epgAnalytics(),
                              isEpg: true,
                            }}
                            contentAccessState={contentAccess.current}
                            playerStop={closeEPG}
                            isCloseIcon={true}
                            isMediaPlaying={isMediaPlaying}
                          />
                        )}
                      </React.Fragment>
                    </div>
                    {showSwitching && playVideo && (
                      <div className="hero-close-btn">
                        <button
                          className="close-btn"
                          onClick={() => {
                            dispatch({
                              type: 'SET_MUTV_HERO_DATA',
                              showHero: true,
                            });
                            playVideo && setPlayVideo(false);
                            setShowSwitching(false);
                          }}
                        >
                          <i className="icon-cross" data-closemodal="close" />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {heroDetail && <HeroNotes heroDetail={heroDetail}></HeroNotes>}
            </React.Fragment>
          )}
          {/* hero card end*/}

          <div className="container epg-carousel-outer">
            <div className={toggleState ? 'epg-carousel-wrapper open' : 'epg-carousel-wrapper'}>
              <p
                className="schudler"
                tabIndex="0"
                role="button"
                aria-label={`Schedule ${toggleState ? 'expanded' : 'collapsed'}`}
                onClick={() => toggleEpgContainer()}
                onKeyDown={(e) => (e.keyCode === 13 || e.which === 13) && toggleEpgContainer(e)}
              >
                <span className="icon-calender-mutv"></span>
                <span className="middle-text">{t('Schedule')}</span>
                <span className="icon-chevron-up-mutv"></span>
              </p>
              <div className="mylist-btn">
                <a href={createDestinationUrl(`${MU.language}/mutv/mylist`)}>
                  <i>+</i>
                  <span>{t('Mylist')}</span>
                </a>
              </div>
              <div className="schedule-section">
                {state && state.epgData && (
                  <div className="mutv-week">
                    <nav aria-label="">
                      <ul ref={daysWidth} style={{ width: newWidth }}>
                        {state &&
                          Object.keys(state.weekDays).map((daysname, index) => {
                            return (
                              <li key={index}>
                                <button
                                  tabIndex={toggleState ? '0' : '-1'}
                                  aria-hidden={toggleState ? 'false' : 'true'}
                                  href="#"
                                  role="link"
                                  className={state.selectedDate === daysname ? 'active' : ''}
                                  aria-current={state.selectedDate === daysname ? true : false}
                                  onClick={(e) => {
                                    renderRelativesCards(e, daysname);
                                  }}
                                >
                                  {state.weekDays[daysname]}
                                </button>
                              </li>
                            );
                          })}
                      </ul>
                    </nav>
                  </div>
                )}
                <div
                  className="program-section"
                  id="program-animation-widget"
                  style={{ display: toggleAnimate ? 'none' : 'block' }}
                  aria-hidden={toggleAnimate ? 'true' : 'false'}
                >
                  <MutvSlider
                    epgData={state && state.epgData && state.epgData[state.selectedDate]}
                    isUserLogin={isUserLogin}
                    isGigyaAvilable={isGigyaAvilable}
                    userLoginDetail={userLoginDetail}
                    isLoginChecked={isLoginChecked}
                    dispatch={dispatch}
                    isDiscover={false}
                    isEpg={true}
                    selectedDate={state.selectedDate}
                    activeIndex={activeIndex}
                    weekDate={state && state.weekDate}
                    oncardClick={oncardClick}
                    isEpgImage={isEpgImage}
                    toggleState={toggleState}
                    intialToggleState={intialToggleState}
                  />
                </div>
              </div>
              <div className="line-wrapper">
                <div className="line"></div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isModalVisible && (
        <ErrorBoundary>
          <RegistrationModal
            closeModal={closeModal}
            fallback={<Loader />}
            successCallback={successCallback}
            previousActiveElement={document.activeElement}
            accessType={getContentAccessState()}
          />
        </ErrorBoundary>
      )}
    </React.Fragment>
  );
};
export default withTranslation()(EPG);
