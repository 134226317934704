/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import ImgCrop from '../../Common/ImgCrop';
import {
  createDestinationUrl,
  isApp,
  isCollection,
  getContentAccessLocal,
  showCBS,
  getLoggedInStatus,
} from '../../../utils/utils';
import { withTranslation } from 'react-i18next';
import loadable from '@loadable/component';
import { useModal } from '../../Common/Modal';
import { useSponsorValue } from '../../../context/sponsorContext';
import ErrorBoundary from '../../Common/ErrorBoundary';
import Loader from '../../Common/Loader';
import { REGISTERED } from '../../../constants/globalConstants';
import * as track from '../../../utils/analytics';
import { ARTICLE_DETAIL } from '../../../constants/containerConstants';
import MU from '../../../constants/muConstants';
const RegistrationModal = loadable(() =>
  import('../../Modals/RegistrationModal/RegistrationModal')
);

const ArticleNext = ({ t, nextArticle }) => {
  const { showModal, closeModal, isModalVisible } = useModal();
  const destinationUrl = createDestinationUrl(nextArticle && nextArticle.DestinationUrl);
  const [{ isUserLogin, isLoginChecked, isUserSubscribe, isUserSkyId }] = useSponsorValue();
  const contentAccessState = getContentAccessLocal(
    nextArticle && nextArticle.ContentAccess,
    'article',
    nextArticle && nextArticle.Tags
  );

  const getDestinationValue = () => {
    return destinationUrl && isApp() && isCollection()
      ? destinationUrl.replace('?request=muapp&collection=true', '')
      : destinationUrl;
  };

  const onCardClick = (e) => {
    if (!isApp() && showCBS({ isUserLogin, isUserSubscribe, contentAccessState })) {
      e.preventDefault();
      showModal();
    }
  };

  const successCallback = ({ showContent, isSubscribedContent }) => {
    if (showContent) {
      if (!MU.isIDMEnabled) window.location.href = getDestinationValue();
      else {
        return ['successCallbck', isSubscribedContent, 'destinationUrl', getDestinationValue()];
      }
    }
  };

  const getCtaText = () => {
    const isRegistered = contentAccessState === REGISTERED;
    if (isLoginChecked && showCBS({ isUserLogin, isUserSubscribe, contentAccessState })) {
      return (
        <React.Fragment>
          <span className={isRegistered ? 'icon-register-icon' : 'icon-subscribe-watch'} />
          <span>{isRegistered ? t('RegisterToRead') : t('SubscribeToRead')}</span>
        </React.Fragment>
      );
    }
    return <span>{t('ReadArticle')}</span>;
  };

  const getAnalyticsData = () => {
    const initialData = track.data('card');
    return {
      ...initialData,
      card_name: nextArticle && nextArticle.Headline,
      container_type: ARTICLE_DETAIL,
      item_id: nextArticle && nextArticle.Id,
      destination_url: nextArticle && nextArticle.DestinationUrl,
      content_status: contentAccessState,
      subscription_status: getLoggedInStatus({
        isLoginChecked,
        isUserLogin,
        isUserSubscribe,
        isUserSkyId,
      }),
    };
  };

  return (
    <React.Fragment>
      {isModalVisible && (
        <ErrorBoundary>
          <RegistrationModal
            successCallback={successCallback}
            closeModal={closeModal}
            fallback={<Loader />}
            accessType={contentAccessState}
            previousActiveElement={document.activeElement}
            analyticsObj={getAnalyticsData()}
          />
        </ErrorBoundary>
      )}
      <div className="home-article-detail__article-panel ">
        <div className="grid-9 large">
          <a
            className="next-article"
            href={getDestinationValue()}
            onClick={(e) => onCardClick(e)}
            target={isApp() && isCollection() ? '_blank' : ''}
          >
            <div className="img-holder">
              <ImgCrop
                imgCrop={
                  nextArticle && nextArticle.Image && typeof nextArticle.Image === 'string'
                    ? JSON.parse(nextArticle.Image)
                    : nextArticle && nextArticle.Image
                }
                alt={nextArticle && nextArticle.AltText}
                title={nextArticle && nextArticle.AltText}
                className={'adaptive'}
              />
            </div>
            <div className="content-holder">
              <p className="heading_h2">{t('NextArticle')}</p>
              <p className="home-article-detail__title heading_h3">
                {nextArticle && nextArticle.Headline}
              </p>
              <span>
                {getCtaText()}
                <i className="tag-red-arrow"></i>
              </span>
            </div>
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(ArticleNext);
