/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import AppOnly from '../Common/AppOnly';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const SocialDetail = (props) => {
  const appData = props.sitecoreContext && props.sitecoreContext.AppOnly;
  const getSocialDetail = () => {
    try {
      return <AppOnly data={appData} />;
    } catch (e) {
      console.log('Exception occured in SocialDetail Component', e);
      return null;
    }
  };
  return appData ? getSocialDetail() : null;
};

export default withSitecoreContext()(SocialDetail);
