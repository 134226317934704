/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import LeagueTableGrouping from '../LeagueTable/LeagueTableGrouping';
import ResultCard from './ResultCard';
import { DFP } from '../Common/Dfp';
import MU from '../../constants/muConstants';
import LazyLoad from 'react-lazyload';
import ImgCrop from '../Common/ImgCrop';

const Sidebar = ({
  selectedLeague,
  resultData,
  tablesData,
  teamTypeFilters,
  checkCalendarStatus,
  getCalendarAction,
  props,
}) => {
  const [showSidebar] = useState(!(typeof window !== 'undefined' && window.innerWidth < 768));
  const [showAd, setShowAd] = useState(false);
  const { fields, t } = props || {};
  const { FixtureDescription, FixtureTitle, FixtureIcon, FixtureCTATitle } = fields || {};

  useEffect(() => {
    // Show ad only on client side otherwise behaves abnormally due to react hydration
    setShowAd(true);
  }, []);

  return (
    <>
      <div className="matches-sidebar">
        {MU &&
          MU.displayFixtureCalendar &&
          selectedLeague &&
          teamTypeFilters &&
          checkCalendarStatus() &&
          ((FixtureTitle && FixtureTitle.value) ||
            (FixtureDescription && FixtureDescription.value) ||
            (FixtureCTATitle && FixtureCTATitle.value)) && (
            <div className="fixtures_calendar_Box">
              {FixtureTitle && FixtureTitle.value && <h1>{FixtureTitle.value}</h1>}
              {FixtureDescription && FixtureDescription.value && <p>{FixtureDescription.value}</p>}
              {FixtureCTATitle && FixtureCTATitle.value && (
                <div className="calendar-btn">
                  <a
                    //href={emptyURL}
                    role="button"
                    aria-label={FixtureCTATitle && FixtureCTATitle.value && FixtureCTATitle.value}
                    onClick={(e) => getCalendarAction(e)}
                  >
                    <div className="red-btn-skew">
                      <div className="cta_container">
                        {FixtureIcon && FixtureIcon.value && FixtureIcon.value.Crops && (
                          <span className="calendar_plus-icon">
                            <LazyLoad>
                              <ImgCrop
                                imgCrop={FixtureIcon.value.Crops}
                                isWebpAvailable={FixtureIcon.value.isWebpAvailable}
                              ></ImgCrop>
                            </LazyLoad>
                          </span>
                        )}
                        {FixtureCTATitle && FixtureCTATitle.value && (
                          <span className="cta_title">{FixtureCTATitle.value}</span>
                        )}
                      </div>
                    </div>
                  </a>
                </div>
              )}
            </div>
          )}
        <div className="matches-row">
          <div className="grid-12 ad-block" aria-hidden="true">
            {showAd && <DFP slot="/11820346/Hercules_ROS_300x250" size={[[300, 250]]} />}
          </div>
        </div>

        {resultData && showSidebar && (
          <div className="matches-row card-shadow">
            <div className="grid-4 ">
              <ResultCard resultData={resultData} />
            </div>
          </div>
        )}
        {tablesData && (
          <div className="matches-row">
            <div className="grid-4 matches">
              <LeagueTableGrouping tablesData={tablesData} tableType="smallTable" />
            </div>
          </div>
        )}

        {showSidebar && (
          <div className="matches-row">
            <div className="grid-12 ad-block" aria-hidden="true">
              {showAd && <DFP slot="/11820346/Hercules_ROS_300x250" size={[[300, 250]]} />}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Sidebar;
