/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { SEARCH } from '../../constants/containerConstants';
import Transform from '../../data-transform';
import { setNewsFilter } from '../../utils/searchUtils';

const NewsSearch = ({ results, resultsCount }) => {
  const supportedCards = ['article', 'externalarticle', 'gallery', 'quiz', 'collection'];

  const renderHtml = (item, index) => {
    item.appearance_tdt = item.__published_tdt;
    item.variant_t = 'search-child';

    return (
      <li>
        {supportedCards.includes(item.contenttype_t.toLowerCase()) && (
          <Transform container={SEARCH} data={item} source="aws" gridClass="grid-4" />
        )}
      </li>
    );
  };
  return (
    <React.Fragment>
      {resultsCount > 0 && (
        <div>
          <ul className="search__news">
            {results &&
              results.map(
                (item, index) => setNewsFilter(item.contenttype_t, item) && renderHtml(item, index)
              )}
          </ul>
        </div>
      )}
    </React.Fragment>
  );
};

export default NewsSearch;
