/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_CARPARKING,
  SET_CARPARKING,
  ERROR_CARPARKING,
  FETCH_PERSIST_REPORT,
  SET_PERSIST_REPORT,
  ERROR_PERSIST_REPORT,
  GENERIC_FETCH_SEARCH_REPORT,
  SET_GENERIC_SEARCH_REPORT,
  ERROR_GENERIC_SEARCH_REPORT,
  FETCHED_CAPTCHA,
  GENERIC_FETCH_PERSISTS,
  GENERIC_SET_PERSISTS,
  GENERIC_ERROR_PERSISTS,
  FETCH_EMAIL_TO_USER,
  SET_EMAIL_TO_USER,
  ERROR_EMAIL_TO_USER,
  FETCH_FAN_FEEDBACK,
  SET_FAN_FEEDBACK,
  ERROR_FAN_FEEDBACK,
} from '../constants/actions/actionConstant';

export const formState = {
  carparkingData: null,
  carparkingStatus: null,
  persistReportData: null,
  persistReportStatus: null,
  searchReportData: null,
  genericPersistReportData: null,
  isCaptchaAvilable: null,
  emailResponse: null,
  feedbackData: null,
  status: null,
  loader: false,
};

/**
 * @param {initial state} state
 * @param {Action type} type
 * @param {response} response data
 */
export const formReducer = (state = formState, { type, response } = {}) => {
  switch (type) {
    case FETCH_CARPARKING:
      return {
        ...state,
        carparkingStatus: 'fetching',
        carparkingData: null,
      };
    case SET_CARPARKING: {
      return {
        ...state,
        carparkingStatus: 'fetched',
        carparkingData: response.data,
      };
    }
    case ERROR_CARPARKING:
      return { ...state, carparkingStatus: 'error', carparkingData: null };

    case FETCH_PERSIST_REPORT:
      return {
        ...state,
        persistReportStatus: 'fetching',
        persistReportData: null,
      };
    case SET_PERSIST_REPORT: {
      return {
        ...state,
        persistReportStatus: 'fetched',
        persistReportData: response.data,
      };
    }
    case ERROR_PERSIST_REPORT: {
      return { ...state, persistReportStatus: 'error', persistReportData: null };
    }

    case GENERIC_FETCH_SEARCH_REPORT:
      return {
        ...state,
        status: 'fetching',
        searchReportData: null,
        loader: true,
      };
    case SET_GENERIC_SEARCH_REPORT:
      return {
        ...state,
        status: 'fetched',
        searchReportData: response.data,
        loader: false,
      };
    case ERROR_GENERIC_SEARCH_REPORT:
      return { ...state, status: 'error', searchReportData: null, loader: false };
    case GENERIC_FETCH_PERSISTS:
      return { ...state, status: 'fetching', genericPersistReportData: null, loader: true };
    case GENERIC_SET_PERSISTS:
      return {
        ...state,
        status: 'fetched',
        genericPersistReportData: response.data,
        loader: false,
      };
    case GENERIC_ERROR_PERSISTS:
      return { ...state, status: 'error', genericPersistReportData: null, loader: false };
    case FETCH_EMAIL_TO_USER:
      //PBL-1870 As part of Temporary Fix loader initialised false, once fixed make it true.
      return { ...state, status: 'fetching', emailResponse: null, loader: false };
    case SET_EMAIL_TO_USER:
      return {
        ...state,
        status: 'fetched',
        emailResponse: response.data,
        loader: false,
      };
    case ERROR_EMAIL_TO_USER:
      return { ...state, status: 'error', emailResponse: null, loader: false };
    case FETCH_FAN_FEEDBACK:
      return { ...state, status: 'fetching', feedbackData: null, loader: true };
    case SET_FAN_FEEDBACK:
      return {
        ...state,
        status: 'fetched',
        feedbackData: response.data,
        loader: false,
      };
    case ERROR_FAN_FEEDBACK:
      return { ...state, status: 'error', feedbackData: null, loader: false };
    case FETCHED_CAPTCHA:
      return { ...state, isCaptchaAvilable: true };
    default:
      return state;
  }
};
