/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useRef } from 'react';
import { ACCOUNT_SUBSCRIPTION, REGISTERED, FREE_TO_VIEW } from '../../constants/globalConstants';
import { useSponsorValue } from '../../context/sponsorContext';
import { createBaseUrl, checkResponseDocs, checkMarketPotRule } from '../../utils/utils';
import MU from '../../constants/muConstants';
import { useModal } from './Modal';
import loadable from '@loadable/component';
import Loader from './Loader';
import ErrorBoundary from './ErrorBoundary';
const RegistrationModal = loadable(() => import('../Modals/RegistrationModal/RegistrationModal'));
const UpSell = ({ data }) => {
  const [{ isLoginChecked, isUserLogin, isUserSubscribe }] = useSponsorValue();
  const { showModal, closeModal, isModalVisible } = useModal();
  const accessValueRef = useRef(null);
  const getTarget = (url) => {
    if (url && url !== '#') {
      const baseUrl = createBaseUrl();
      return baseUrl && url.includes(baseUrl) ? '' : '_blank';
    }
    return '';
  };

  const getCbrValue = (showCbr) => {
    if (showCbr === REGISTERED) {
      return 'icon-register-watch';
    } else if (showCbr === ACCOUNT_SUBSCRIPTION) {
      return 'icon-subscribe-watch';
    } else {
      return '';
    }
  };

  const showCTA = ({ showCbr, title, CTAUrl }) => {
    return (
      <span className="upsell-cta">
        <div className="white-btn-skew">
          <a
            href={CTAUrl}
            target={getTarget(CTAUrl)}
            onClick={(e) => {
              if (showCbr) {
                e.preventDefault();
                showModal();
              }
            }}
          >
            <i className={getCbrValue(showCbr)}></i>
            {title && title.value && <span>{title.value}</span>}
          </a>
        </div>
      </span>
    );
  };
  const showUpSellOrNot = (GlobalOverride, Geo) => {
    if (GlobalOverride && GlobalOverride.value) {
      return true;
    } else if (MU && MU.countryCode && Geo && Geo.length > 0) {
      return Geo.includes(MU.countryCode);
    }
    return false;
  };

  const getUpSellData = (array) => {
    if (array && array.length > 0) {
      return array.find((obj) => showUpSellOrNot(obj.fields.GlobalOverride, obj.fields.Geo));
    }
    return false;
  };
  const renderUpSell = () => {
    if (data && data.length > 0) {
      const upSellData = getUpSellData(data);
      if (upSellData) {
        const {
          CTATitle,
          CTATitleRegisteredUser,
          CTATitleSubscribedUser,
          CTAUrl,
          ContentAccess,
        } = upSellData.fields;
        let finalContentAccess =
          ContentAccess && ContentAccess.value ? ContentAccess.value : FREE_TO_VIEW;
        if (finalContentAccess && checkResponseDocs(MU.MarketAccessRulesResponse)) {
          const marketAccessValue = checkMarketPotRule(
            finalContentAccess,
            MU.MarketAccessRulesResponse.response.docs[0]
          );
          finalContentAccess = marketAccessValue ? marketAccessValue : finalContentAccess;
        }
        const url = CTAUrl && CTAUrl.value && CTAUrl.value.href ? CTAUrl.value.href : '#';
        accessValueRef.current = { value: finalContentAccess, url };
        if (finalContentAccess === FREE_TO_VIEW) {
          return showCTA({ showCbr: false, title: CTATitle, CTAUrl: url });
        } else if ([ACCOUNT_SUBSCRIPTION, REGISTERED].includes(finalContentAccess)) {
          if (isLoginChecked) {
            if (finalContentAccess === REGISTERED && !isUserLogin) {
              return showCTA({ showCbr: REGISTERED, title: CTATitleRegisteredUser, CTAUrl: '#' });
            } else if (finalContentAccess === ACCOUNT_SUBSCRIPTION && !isUserSubscribe) {
              return showCTA({
                showCbr: ACCOUNT_SUBSCRIPTION,
                title: CTATitleSubscribedUser,
                CTAUrl: '#',
              });
            }
            return showCTA({ showCbr: false, title: CTATitle, CTAUrl: url });
          }
          return null;
        }
      }
      return null;
    }
    return null;
  };

  const successCallback = ({ showContent, isSubscribedContent }) => {
    if (showContent && accessValueRef.current) {
      if (!MU.isIDMEnabled)
        window.open(accessValueRef.current.url, getTarget(accessValueRef.current.url));
      else
        return [
          'successCallbck',
          isSubscribedContent,
          'openDestinationUrl',
          accessValueRef.current.url,
          getTarget(accessValueRef.current.url),
        ];
    }
  };
  return (
    <React.Fragment>
      {isModalVisible && (
        <ErrorBoundary>
          <RegistrationModal
            successCallback={successCallback}
            closeModal={closeModal}
            fallback={<Loader />}
            previousActiveElement={document.activeElement}
            accessType={accessValueRef.current && accessValueRef.current.value}
          />
        </ErrorBoundary>
      )}
      {renderUpSell()}
    </React.Fragment>
  );
};

export default UpSell;
