/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { createContext, useContext, useReducer } from 'react';
import { matchesReducer, matchesState } from '../reducers/matchesReducer';

export const MatchesContext = createContext();

export const MatchesProvider = ({ children }) => (
  <MatchesContext.Provider value={useReducer(matchesReducer, matchesState)}>
    {children}
  </MatchesContext.Provider>
);
export const useMatchesValue = () => useContext(MatchesContext);

export default MatchesContext;
