/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import StatsBar from '../../../Common/StatsBar';
import {
  createTagList,
  getStatsBarData,
  getLoggedInStatus,
  showCBS,
  checkResponseDocs,
} from '../../../../utils/utils';
import { useSponsorValue } from '../../../../context/sponsorContext';
import { useMutvListingValue } from '../../../../context/mutvMyListContext';
import { individualTag, getLocalData, getTrueItems } from '../../mutvutil';
import MU from '../../../../constants/muConstants';
import {
  postMyListReport,
  setConfiramtion,
  setLocalData,
} from '../../../../actions/mutvMyListAction';
import * as track from '../../../../utils/analytics';
import MutvPopup from '../MutvPopup';
import { getPublishedDate } from '../../../../utils/momentDate';
import { MAX_ITEM } from '../../../../constants/mutvConstants';
import { useModal } from '../../../Common/Modal';
import Loader from '../../../Common/Loader';
import ErrorBoundary from '../../../Common/ErrorBoundary';
import loadable from '@loadable/component';
import { FREE_TO_VIEW } from '../../../../constants/globalConstants';
const RegistrationModal = loadable(() =>
  import('../../../Modals/RegistrationModal/RegistrationModal')
);
const Widgets = (props) => {
  const { data, containerType, showMyList, t, contentStatus } = props;
  const {
    contenttype_t,
    heading,
    taglist_sm,
    __published_tdt,
    __publishedby_t,
    itemid_s,
    destinationurl_s,
    imagecropurl_s,
    _parent,
    mediaid_s,
    category_s,
  } = data;
  const [
    { isLoginChecked, isUserLogin, userLoginDetail, isUserSubscribe, isUserSkyId },
  ] = useSponsorValue();
  const [
    { myListResponse, lastResponseTime, reportStatus, showPopup, status },
    dispatch,
  ] = useMutvListingValue();
  const [rated, setRated] = useState(false);
  const [myListButton, setmyListButton] = useState(false);
  const { showModal, closeModal, isModalVisible } = useModal();

  useEffect(() => {
    if (isLoginChecked) {
      if (userLoginDetail) {
        if (
          myListResponse &&
          myListResponse.ContentListResponse &&
          myListResponse.ContentListResponse.ContentList
        ) {
          const listItem = myListResponse.ContentListResponse.ContentList.find(
            (item) =>
              item.ID === itemid_s &&
              !item.deletedDT &&
              (reportStatus === null || reportStatus === 'fetched' || reportStatus !== 'error')
          );
          listItem && setmyListButton(true);
        }
      } else {
        let locallyStoredData = [];
        if (localStorage.getItem('localData') !== null) {
          locallyStoredData = JSON.parse(localStorage.getItem('localData'));
          if (
            locallyStoredData &&
            locallyStoredData.ContentListResponse &&
            locallyStoredData.ContentListResponse.ContentList
          ) {
            const listItem = locallyStoredData.ContentListResponse.ContentList.find(
              (item) => item.ID === itemid_s && !item.deletedDT
            );
            listItem && setmyListButton(true);
          }
        }
      }
    }
  }, [myListResponse, userLoginDetail, isLoginChecked]);

  useEffect(() => {
    if (isLoginChecked && itemid_s) {
      setRateData();
    }
  }, [isLoginChecked, itemid_s, userLoginDetail]);

  const setRateData = () => {
    const rateData = localStorage.getItem('rate') && JSON.parse(localStorage.getItem('rate'));
    const anonymousData = rateData && rateData.anonymous ? rateData.anonymous : [];
    const userID = userLoginDetail && userLoginDetail.UID;
    if (rateData && userID) {
      const userData = rateData[userID] ? rateData[userID] : [];
      anonymousData && anonymousData.length > 0 && userData.push(...anonymousData);
      userData.includes(itemid_s) && setRated(true);
      const pushData = { ...rateData, [userID]: [...new Set(userData)], anonymous: [] };
      localStorage.setItem('rate', JSON.stringify(pushData));
    } else if (anonymousData && anonymousData.length > 0) {
      anonymousData.includes(itemid_s) ? setRated(true) : setRated(false);
    }
  };

  const createPayload = () => {
    return [
      {
        bgImageURL: imagecropurl_s && imagecropurl_s.thumb,
        ID: itemid_s,
        contentURL: destinationurl_s,
        geoCode: MU.countryCode,
        language: MU.language,
        mediaID: mediaid_s,
        myList: true,
        myListDT: getPublishedDate(),
        parentID: `{${_parent}}`,
        publishDT: getPublishedDate(__published_tdt),
        tags: taglist_sm,
        title: heading,
        totalDuration: 0,
        typeID: contenttype_t,
        dataSyncDT:
          myListResponse && myListResponse.lastContentDataSyncDT
            ? myListResponse.lastContentDataSyncDT
            : getPublishedDate(),
      },
    ];
  };
  let myList_objupdated = createPayload();

  const rateOnClick = (e) => {
    trackCard(e, 'rate', !rated);
    const rateData = localStorage.getItem('rate') && JSON.parse(localStorage.getItem('rate'));
    const userID = userLoginDetail && userLoginDetail.UID;
    let userData = [];
    if (rateData && userID) {
      userData = rateData[userID];
    } else if (rateData && rateData.anonymous) {
      userData = rateData.anonymous;
    }
    if (!rated) {
      userData.push(itemid_s);
    } else {
      userData = userData.filter((ele) => ele !== itemid_s);
    }
    setRated(!rated);
    const pushData = { ...rateData, [userID ? userID : 'anonymous']: userData };
    localStorage.setItem('rate', JSON.stringify(pushData));
    iconTabFocus();
  };

  const myListContent = () => {
    const isMylistAccessible =
      MU &&
      checkResponseDocs(MU.ContentAccessRulesResponse) &&
      MU.ContentAccessRulesResponse.response.docs.find(
        (value) =>
          value && value.selectcontenttype_t && value.selectcontenttype_t.toLowerCase() === 'mylist'
      );
    return isMylistAccessible && isMylistAccessible.contentaccess_t
      ? isMylistAccessible.contentaccess_t
      : FREE_TO_VIEW;
  };

  const myListOnClick = (e) => {
    const myListContentAccess = myListContent();
    if (
      !myListButton &&
      showCBS({ isUserLogin, isUserSubscribe, contentAccessState: myListContentAccess })
    ) {
      e.preventDefault();
      showModal();
    } else {
      trackCard(e, 'my_list', !myListButton);
      addRemoveMyList();
    }
  };

  const successCallback = ({ showContent }) => {
    if (showContent) {
      trackCard(false, 'my_list', !myListButton);
      addRemoveMyList();
    }
  };

  const iconTabFocus = () => {
    if (
      document.activeElement &&
      document.activeElement.classList &&
      document.activeElement.classList.contains('non-keyboard-outline')
    ) {
      const focusEle = document.activeElement;
      setTimeout(() => {
        focusEle.classList &&
          !focusEle.classList.contains('non-keyboard-outline') &&
          focusEle.classList.add('non-keyboard-outline');
        focusEle.focus();
      }, 10);
    }
  };

  const addRemoveMyList = () => {
    const myList_obj = createPayload();
    if (userLoginDetail && userLoginDetail !== null) {
      postMyListReport(
        dispatch,
        lastResponseTime,
        myList_obj,
        userLoginDetail,
        myListResponse,
        myListButton,
        'contentList',
        reportStatus,
        'widget',
        setmyListButton
      );
    } else {
      const mylist = myList_obj[0];
      if (!myListButton) {
        mylist.myList = true;
        mylist.myListDT = getPublishedDate();
      } else {
        mylist.deletedDT = getPublishedDate();
        mylist.myList = false;
        delete mylist.bgImageURL;
        delete mylist.contentURL;
        delete mylist.geoCode;
        delete mylist.language;
        delete mylist.title;
        delete mylist.totalDuration;
      }
      saveInLocalStorage(myList_obj);
    }
    iconTabFocus();
  };

  const localDuplicateCheck = (myListResponseData, myList_obj) => {
    return [
      ...myList_obj,
      ...myListResponseData.filter((el1) => !myList_obj.some((el2) => el2.ID === el1.ID)),
    ];
  };

  const saveInLocalStorage = (myList_obj) => {
    const localMylistData = getLocalData();
    let responseObj = {
      ContentListResponse: {
        ContentList: [],
      },
      ContentHistoryResponse: {
        ContentHistory:
          localMylistData &&
          localMylistData.ContentHistoryResponse &&
          localMylistData.ContentHistoryResponse.ContentHistory
            ? localMylistData.ContentHistoryResponse.ContentHistory
            : [],
      },
    };
    let myListResponseData = [];
    if (localMylistData) {
      myListResponseData =
        localMylistData.ContentListResponse && localMylistData.ContentListResponse.ContentList;
      const output = myListResponseData && localDuplicateCheck(myListResponseData, myList_obj);
      const trueItems = output && getTrueItems(output);
      if (trueItems && trueItems.length > MAX_ITEM) {
        responseObj.ContentListResponse.ContentList = output;
        dispatch(setLocalData(responseObj));
        dispatch(setConfiramtion(true));
      } else {
        responseObj.ContentListResponse.ContentList = output;
        localStorage.setItem('localData', JSON.stringify(responseObj));
        setmyListButton(!myListButton);
      }
    } else {
      myListResponseData = [...myList_obj];
      responseObj.ContentListResponse.ContentList = myListResponseData;
      localStorage.setItem('localData', JSON.stringify(responseObj));
      setmyListButton(!myListButton);
    }
  };

  const getAnalyticsData = (linkValue, listedValue) => {
    let initialDetails;
    if (linkValue === 'my_list') {
      initialDetails = track.data(listedValue ? 'mylist_on' : 'mylist_off');
    } else if (linkValue === 'rate') {
      initialDetails = track.data('rate');
    }
    let trackObject = {
      ...initialDetails,
      card_name: heading,
      container_type: containerType,
      subscription_status: getLoggedInStatus({
        isLoginChecked,
        isUserLogin,
        isUserSubscribe,
        isUserSkyId,
      }),
      item_id: itemid_s,
      content_type: contenttype_t,
      tag: taglist_sm && createTagList(taglist_sm, category_s),
      created_datetime: __published_tdt,
      content_status: contentStatus,
      author: __publishedby_t,
      ...individualTag(taglist_sm),
    };
    if (linkValue == 'rate') {
      trackObject.button_name = listedValue ? 'Rate' : 'Unrate';
    }
    return trackObject;
  };

  const trackCard = (e, linkValue, listedValue) => {
    track.analytics(getAnalyticsData(linkValue, listedValue), e);
  };

  const openPanelCallback = (e) => {
    const initialData = track.data('button');
    let trackObject = {
      ...initialData,
      button_name: 'Share',
      card_name: heading,
      container_type: containerType,
      subscription_status: getLoggedInStatus({
        isLoginChecked,
        isUserLogin,
        isUserSubscribe,
        isUserSkyId,
      }),
      item_id: itemid_s,
      content_type: contenttype_t,
      tag: taglist_sm && createTagList(taglist_sm, category_s),
      created_datetime: __published_tdt,
      content_status: contentStatus,
      author: __publishedby_t,
      ...individualTag(taglist_sm),
    };
    track.analytics(trackObject, e);
  };

  const shareAnalyticsData = () => {
    return {
      item_id: itemid_s,
      created_datetime: __published_tdt,
      content_type: contenttype_t,
      card_name: heading,
    };
  };
  const getStyle = (isMyList) => {
    if (isLoginChecked && (!isMyList || (isMyList && (status === null || status !== 'fetching')))) {
      return {};
    }
    return { opacity: '0.3', pointerEvents: 'none' };
  };
  return (
    <React.Fragment>
      <div className="widgets">
        <ul className="widgets-items">
          {showMyList && (
            <li className="item" style={getStyle(true)}>
              <span
                tabIndex={isLoginChecked ? '0' : '-1'}
                role="button"
                aria-describedby="mutv-main-heading"
                aria-label={myListButton ? t('Added') : t('Mylist')}
                className={myListButton ? 'active bdr' : 'bdr'}
                onClick={(e) => myListOnClick(e)}
                onKeyDown={(e) => (e.keyCode || e.which) === 13 && myListOnClick(e)}
              >
                <i className="icon-white-plus"></i>
              </span>
              <label aria-hidden="true">{myListButton ? t('Added') : t('Mylist')} </label>
            </li>
          )}
          <li className="item" style={getStyle()}>
            <span
              tabIndex={isLoginChecked ? '0' : '-1'}
              role="button"
              aria-describedby="mutv-main-heading"
              aria-label={rated ? t('Rated') : t('Rate')}
              className={rated ? 'active bdr' : 'bdr'}
              onClick={(e) => rateOnClick(e)}
              onKeyDown={(e) => (e.keyCode || e.which) === 13 && rateOnClick(e)}
            >
              <i className="icon-mood-rated"></i>
            </span>
            <label aria-hidden="true">{rated ? t('Rated') : t('Rate')}</label>
          </li>
          <li className="item sharepanel">
            {data && (
              <StatsBar
                item={getStatsBarData(data)}
                classes="statsbar"
                requiredIconOnly={true}
                tracking={true}
                openPanelCallback={openPanelCallback}
                analyticsData={shareAnalyticsData()}
              ></StatsBar>
            )}
            <label aria-hidden="true">{t('Share')}</label>
          </li>
        </ul>
      </div>
      {showPopup && (
        <MutvPopup
          lastResponseTime={lastResponseTime}
          myList_obj={myList_objupdated}
          userLoginDetail={userLoginDetail}
          myListButton={myListButton}
          contentList="contentList"
          widget="widget"
          setmyListButton={setmyListButton}
          showPopup={showPopup}
        ></MutvPopup>
      )}
      {isModalVisible && (
        <ErrorBoundary>
          <RegistrationModal
            closeModal={closeModal}
            fallback={<Loader />}
            previousActiveElement={document.activeElement}
            accessType={myListContent()}
            successCallback={successCallback}
            analyticsObj={getAnalyticsData('my_list', !myListButton)}
          />
        </ErrorBoundary>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(Widgets);
