/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { Fragment, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { buildImgUrl, getStatsBarData } from '../../utils/utils';
import StatsBar from '../Common/StatsBar';
import Sponsor from '../Common/Sponsor';
import Transform from '../../data-transform';
import { LIGHT_THEME, DARK_THEME, BLOG_EVENT } from '../../constants/globalConstants';
import { DFP } from '../Common/Dfp';
import { LIVE_EVENT } from '../../constants/containerConstants';
import { fnBrowserDetect } from '../../utils/utils';
/**
 * @param {dictionary}t
 * @param {Blog data of item} blogData
 */
const BlogCard = ({ t, item, gridClass, isMatchcenterCard, container }) => {
  const [avatarErrorSrc, setAvatarErrorSrc] = useState('');
  const onErrorImg = () => {
    setAvatarErrorSrc(`../../assets/images/bg/avatar.png`);
  };
  /**
   * @method {isGoal: return true/false}
   * @param {eventType}
   */

  const isGoal = function (eventType) {
    if (eventType) {
      eventType = eventType.toLowerCase();
      if (
        eventType === 'mugoal' ||
        eventType === 'ogoal' ||
        eventType === 'ft' ||
        eventType === 'ht' ||
        eventType === 'kickoff' ||
        eventType === 'openaltyshootoutgoal' ||
        eventType === 'mupenaltyshootoutgoal'
      ) {
        return true;
      }
    }
    return false;
  };
  /**
   * @method {buildGoalHeading: return val}
   * @param {item}
   */
  const buildGoalHeading = function (item) {
    let windowWidth = window.innerWidth;
    let val = '';
    if (item && item.eventtype_t && isGoal(item.eventtype_t)) {
      // event text e.g goal,red card,yellow card
      if (windowWidth >= 568) {
        if (item.eventtext_t) val += item.eventtext_t;
        // if home team is mu
        if (item.ishometeammu_b) {
          if (item.teamnameabbr_t) val += ` ${item.teamnameabbr_t}`;
          if (item.goalbymanunited_tl != null) val += ` ${item.goalbymanunited_tl}`;
          if (item.opponentteamabbr_t) val += ` ${item.opponentteamabbr_t}`;
          if (item.goalbyopposition_tl != null) val += ` ${item.goalbyopposition_tl}`;
        } else {
          if (item.opponentteamabbr_t) val += ` ${item.opponentteamabbr_t}`;
          if (item.goalbyopposition_tl != null) val += ` ${item.goalbyopposition_tl}`;
          if (item.teamnameabbr_t) val += ` ${item.teamnameabbr_t}`;
          if (item.goalbymanunited_tl != null) val += ` ${item.goalbymanunited_tl}`;
        }
      } else {
        // if home team is mu
        if (item.ishometeammu_b) {
          if (item.teamnameabbr_t) val += item.teamnameabbr_t;
          if (item.goalbymanunited_tl != null) val += ` ${item.goalbymanunited_tl}`;
          if (item.opponentteamabbr_t) val += ` ${item.opponentteamabbr_t}`;
          if (item.goalbyopposition_tl != null) val += ` ${item.goalbyopposition_tl}`;
        } else {
          if (item.opponentteamabbr_t) val += item.opponentteamabbr_t;
          if (item.goalbyopposition_tl != null) val += ` ${item.goalbyopposition_tl}`;
          if (item.teamnameabbr_t) val += ` ${item.teamnameabbr_t}`;
          if (item.goalbymanunited_tl != null) val += ` ${item.goalbymanunited_tl}`;
        }
      }
    }
    return val;
  };
  let fullwidth = '';
  const isCommentCard = (item) => {
    if (
      (item.eventtext_t === 'Comment' || item.eventtext_t === 'comment') &&
      (item.playerimageurl_t === undefined ||
        item.playerimageurl_t === 'undefined' ||
        item.playerimageurl_t === '')
    ) {
      fullwidth = 'full-width';
      return false;
    }
    return true;
  };
  const cardColor = (eventType) => {
    if (eventType) {
      eventType = eventType.toLowerCase();
      if (eventType === 'mugoal') {
        return 'red-blog';
      } else if (eventType === 'mupenaltyshootoutgoal') {
        return 'red-blog';
      } else if (eventType === 'ogoal') {
        return 'dark-blog';
      } else if (eventType === 'openaltyshootoutgoal') {
        return 'dark-blog';
      } else if (eventType === 'ft') {
        return 'black-blog';
      } else if (eventType === 'ht') {
        return 'black-blog';
      } else if (eventType === 'kickoff') {
        return 'black-blog kickoff';
      }
    }
  };
  const getGoalHedingClass = (eventType) => {
    if (eventType) {
      eventType = eventType.toLowerCase();
      if (
        eventType === 'mugoal' ||
        eventType === 'ogoal' ||
        eventType === 'kickoff' ||
        eventType === 'openaltyshootoutgoal' ||
        eventType === 'mupenaltyshootoutgoal'
      ) {
        return 'access';
      } else {
        return 'noaccess';
      }
    }
    return false;
  };
  /**
   * @method {getScoreTime: return time (90 MINS)}
   * @param {time, matchStage}
   */
  const getScoreTime = function (time, matchStage) {
    if (matchStage === '1') {
      if (time && time.indexOf(':') !== -1) {
        let splittedTime = time.split(':');
        let minutes = splittedTime[0];
        let seconds = splittedTime[1];
        minutes = +minutes;
        seconds = +seconds;
        if (seconds > 0) {
          minutes = +minutes + 1;
        }
        if (minutes > 45) {
          let subs = minutes - 45;
          return `45 MINS + ${subs}`;
        } else {
          if (minutes >= 0) {
            if (minutes === 1 || minutes === 0) {
              return `0${minutes} MIN`;
            } else if (minutes > 1 && minutes < 10) {
              return `0${minutes} MINS`;
            } else {
              return `${minutes} MINS`;
            }
          }
        }
      }
    } else if (matchStage === '2') {
      return 'HT';
    } else if (matchStage === '3') {
      if (time && time.indexOf(':') !== -1) {
        let splittedTime = time.split(':');
        let minutes = splittedTime[0];
        let seconds = splittedTime[1];
        if (seconds > 0) {
          minutes = +minutes + 1;
        }
        if (minutes > 90) {
          let subs = minutes - 90;
          return `90 MINS + ${subs}`;
        } else {
          if (minutes >= 0) {
            if (minutes === 1 || minutes === 0) {
              return `0${minutes} MIN`;
            } else if (minutes > 1 && minutes < 10) {
              return `0${minutes} MINS`;
            } else {
              return `${minutes} MINS`;
            }
          }
        }
      }
    } else if (matchStage === '4') {
      return 'FT';
    } else if (matchStage === '5') {
      if (time && time.indexOf(':') !== -1) {
        let splittedTime = time.split(':');
        let minutes = splittedTime[0];
        let seconds = splittedTime[1];
        if (seconds > 0) {
          minutes = +minutes + 1;
        }
        if (minutes > 105) {
          let subs = minutes - 105;
          return `105 MINS + ${subs}`;
        } else {
          if (minutes >= 0) {
            if (minutes === 1 || minutes === 0) {
              return `0${minutes} MIN`;
            } else if (minutes > 1 && minutes < 10) {
              return `0${minutes} MINS`;
            } else {
              return `${minutes} MINS`;
            }
          }
        }
      }
    } else if (matchStage === '6') {
      return 'HT (ET)';
    } else if (matchStage === '7') {
      if (time && time.indexOf(':') !== -1) {
        let splittedTime = time.split(':');
        let minutes = splittedTime[0];
        let seconds = splittedTime[1];
        if (seconds > 0) {
          minutes = +minutes + 1;
        }
        if (minutes > 120) {
          let subs = minutes - 120;
          return '120 MINS + ' + subs;
        } else {
          if (minutes >= 0) {
            if (minutes === 1 || minutes === 0) {
              return `0${minutes} MIN`;
            } else if (minutes > 1 && minutes < 10) {
              return `0${minutes} MINS`;
            } else {
              return `${minutes} MINS`;
            }
          }
        }
      }
    } else if (matchStage === '8') {
      return 'FT (AET)';
    } else {
      if (time && time.indexOf(':') !== -1) {
        let splittedTime = time.split(':');
        let minutes = splittedTime[0];
        let seconds = splittedTime[1];
        if (minutes) {
          minutes = +minutes;
        }
        if (seconds > 0) {
          minutes = minutes + 1;
        }
        if (minutes >= 0) {
          if (minutes === 1 || minutes === 0) {
            return `0${minutes} MIN`;
          } else if (minutes > 1 && minutes < 10) {
            return `0${minutes} MINS`;
          } else {
            return `${minutes} MINS`;
          }
        }
      }
    }
  };

  const eventIcon = function (eventType, small, dark) {
    let eventClass = 'event-';
    if (small) {
      eventClass = 'eventsmall-';
    }
    if (eventType) {
      eventType = eventType.toLowerCase();
      switch (eventType) {
        case 'kickoff':
          eventClass += 'kick-off';
          break;
        case 'update':
          eventClass += 'update';
          break;
        case 'injurytime':
          eventClass += 'injurytime';
          break;
        case 'muyellowcard':
        case 'oyellowcard':
          eventClass += 'yellowcard';
          break;
        case 'muredcard':
        case 'oredcard':
          eventClass += 'redcard';
          break;
        case 'mugoal':
          if (dark) {
            eventClass += 'goal-dark';
          } else {
            eventClass += 'goal';
          }
          break;
        case 'mupenaltyshootoutgoal':
          if (dark) {
            eventClass += 'goal-dark';
          } else {
            eventClass += 'goal';
          }
          break;
        case 'ogoal':
          if (dark) {
            eventClass += 'goal-dark';
          } else {
            eventClass += 'goal';
          }
          break;
        case 'openaltyshootoutgoal':
          if (dark) {
            eventClass += 'goal-dark';
          } else {
            eventClass += 'goal';
          }
          break;
        case 'mupenaltygiven':
        case 'openaltygiven':
          eventClass += 'pen-given';
          break;
        case 'mupenaltymissed':
        case 'openaltymissed':
          eventClass += 'pen-missed';
          break;
        case 'substitution':
          eventClass += 'pen-substitution';
          break;
        case 'corner':
          eventClass += 'pen-corner';
          break;
        case 'muattemptmissed':
        case 'oattemptmissed':
          eventClass += 'attempt-missed';
          break;
        case 'muattemptblocked':
        case 'oattemptblocked':
          eventClass += 'attempt-blocked';
          break;
        case 'muatttemptsaved':
        case 'oattemptsaved':
          eventClass += 'attempt-saved';
          break;
        case 'rivalswatch':
          eventClass += 'rivals-watch';
          break;
        case 'foul':
          eventClass += 'foul';
          break;
        case 'freekickwon':
          eventClass += 'freekick-won';
          break;
        case 'offside':
          eventClass += 'offside';
          break;
        case 'mugreatpass':
        case 'ogreatpass':
          eventClass += 'great-pass';
          break;
        case 'mugreatskill':
        case 'ogreatskill':
          eventClass += 'great-skill';
          break;
        case 'ht':
          eventClass += 'half-time';
          break;
        case 'ft':
          eventClass += 'full-time';
          break;
        case 'et-ko':
          eventClass += 'et-ko';
          break;
        case 'et-ht':
          eventClass += 'et-ht';
          break;
        case 'et-ft':
          eventClass += 'et-ft';
          break;
        case 'penaltyshootout':
          eventClass += 'penalty-shootout';
          break;
        default:
          eventClass = '';
      }
    } else {
      eventClass = '';
    }
    return eventClass;
  };
  const blogInfo = `matchday-blogs__info ${item.eventtext_t}`;
  const goalClass = `${getGoalHedingClass(item.eventtype_t)}`;

  const goalHeadClass = `kickoff ${getGoalHedingClass(item.eventtype_t)}`;
  const blogCardClass = `matchday-blog-card ${cardColor(item.eventtype_t)}`;
  const eventIconClass = `matchday-blogs__tag ${eventIcon(item.eventtype_t)}`;
  /**
   * @method {updateSponsorTagEvent: return LIGHT_THEME/DARK_THEME}
   * @param {eventType}
   */
  const updateSponsorTagEvent = (eventType) => {
    if (eventType) {
      eventType = eventType.toLowerCase();
      if (
        eventType === 'ogoal' ||
        eventType === 'mugoal' ||
        eventType === 'openaltyshootoutgoal' ||
        eventType === 'mupenaltyshootoutgoal' ||
        eventType === 'ft' ||
        eventType === 'kickoff' ||
        eventType === 'ht'
      ) {
        return LIGHT_THEME;
      } else {
        return DARK_THEME;
      }
    }
  };

  const renderFeedCard = (type) => {
    const data = { ...item };
    data.contenttype_t = type;
    return (
      <Transform
        data={data}
        source="aws"
        gridClass={gridClass}
        isMatchcenterCard={isMatchcenterCard}
        container={container}
        isBlogCard={true}
      />
    );
  };

  return (
    <>
      <span className="screenreader">{t('blog')}</span>
      {item.isimageasset_b && renderFeedCard('image')}
      {item.isvideoasset_b && renderFeedCard('video')}
      {!item.isvideoasset_b && !item.isimageasset_b && (
        <div className={blogCardClass} id={item.rawitemid_s}>
          <Sponsor
            type={BLOG_EVENT}
            eventName={item.eventtype_t}
            theme={updateSponsorTagEvent(item.eventtype_t)}
            container={LIVE_EVENT}
            varient={item.variant_t}
            author={item.personname_t}
            contentType={item.contenttype_t}
            createdDateTime={item.__published_tdt}
            cardName={item.blogheading_t}
            itemId={item.itemid_s}
            tags={item.taglist_sm}
            grid={'novarient'}
          ></Sponsor>
          <div className={blogInfo}>
            {isCommentCard(item) && (
              <div className="matchday-blogs__left-info">
                {item.matchstage_t !== '0' && item.matchstage_t !== '11' && (
                  <p className={fnBrowserDetect() !== 'safari'?"matchday-blogs__post-time":'matchday-blogs__post-time-light'}>
                    <span className="screenreader">{`${t('lastactivityin')} `}</span>
                    {getScoreTime(item.matchtimer_t, item.matchstage_t)}
                  </p>
                )}
                <div className="matchday-blogs__title-data">
                  {item.eventtype_t && item.eventtype_t.toLowerCase() !== 'update' && (
                    <span className={item.eventtext_t}>{item.eventtext_t}</span>
                  )}
                  {item.eventtype_t && item.eventtype_t.toLowerCase() !== 'update' && (
                    <span className={eventIconClass}></span>
                  )}
                  <div className="player__headshot__guard">
                    <img
                      className="player__headshot avatar"
                      src={
                        avatarErrorSrc !== '' ? avatarErrorSrc : buildImgUrl(item.playerimageurl_t)
                      }
                      alt=""
                      onError={() => onErrorImg()}
                      title="Profile pic"
                      aria-hidden="true"
                    />
                  </div>
                  {item.eventtype_t &&
                    (item.eventtype_t.toLowerCase() === 'mugoal' ||
                      item.eventtype_t.toLowerCase() === 'mupenaltyshootoutgoal') && (
                      <span className="matchday-blog-card__MUGoal">{item.eventtext_t}</span>
                    )}

                  {((item.eventtype_t && item.eventtype_t.toLowerCase() === 'ogoal') ||
                    item.eventtype_t.toLowerCase() === 'openaltyshootoutgoal') && (
                    <img
                      src="/assets/dam-images/giggs-profile-image.png"
                      className="blog-icon"
                      alt=""
                      title=""
                    />
                  )}

                  {item.eventtype_t &&
                    (item.eventtype_t.toLowerCase() === 'ogoal' ||
                      item.eventtype_t.toLowerCase() === 'openaltyshootoutgoal') && (
                      <span className="matchday-blog-card__MUGoal">{item.eventtext_t}</span>
                    )}

                  {item.personname_t &&
                    item.eventtype_t &&
                    item.eventtype_t.toLowerCase() === 'comment' && (
                      <React.Fragment>
                        <span className="screenreader authorname">
                          {'author ' + item.personname_t}
                        </span>
                        <span aria-hidden="true" className="authorname">
                          {item.personname_t}
                        </span>
                      </React.Fragment>
                    )}

                  {item.eventtype_t && item.eventtype_t.toLowerCase() === 'ft' && (
                    <span className="match_time">Full time</span>
                  )}
                  {item.eventtype_t && item.eventtype_t.toLowerCase() === 'ht' && (
                    <span className="match_time">Half time</span>
                  )}
                  {item.eventtype_t && item.eventtype_t.toLowerCase() === 'kickoff' && (
                    <span className="match_time">KickOff</span>
                  )}
                </div>
              </div>
            )}
            <div className={`matchday-blogs__right-info ${fullwidth}`}>
              <div className="blog-card">
                {item.eventtype_t && item.eventtype_t.toLowerCase() === 'ht' && (
                  <p className="match-time access">Half Time</p>
                )}
                {item.eventtype_t && item.eventtype_t.toLowerCase() === 'ft' && (
                  <p className="match-time access">Full Time</p>
                )}
                {item.eventtype_t &&
                  item.eventtype_t.toLowerCase() !== 'kickoff' &&
                  item.eventtype_t.toLowerCase() !== 'ht' &&
                  item.eventtype_t.toLowerCase() !== 'ft' &&
                  item.eventtype_t.toLowerCase() !== 'mupenaltyshootoutgoal' &&
                  item.eventtype_t.toLowerCase() !== 'openaltyshootoutgoal' && (
                    <p className={goalClass}>{buildGoalHeading(item)}</p>
                  )}
                {item.eventtype_t && item.eventtype_t.toLowerCase() === 'kickoff' && (
                  <p className={goalHeadClass}>{t('KickOff')}</p>
                )}
                <div className={fnBrowserDetect() !== 'safari'?'blogs__title':'blogs__title_light'}>{item.blogheading_t}</div>

                {item.blogdescription_t && (
                  <p className="blogs__teasure">
                    {item.blogdescription_t.split('\n').map((item, i) => (
                      <Fragment key={i}>
                        {item}
                        <br />
                      </Fragment>
                    ))}
                  </p>
                )}
              </div>
            </div>
          </div>
          <StatsBar item={getStatsBarData(item, true)} type="blogCard" noTags={true} />
        </div>
      )}
      {(((item.matchstage_t === '1' ||
        item.matchstage_t === '3' ||
        item.matchstage_t === '5' ||
        item.matchstage_t === '7') &&
        item.eventtype_t.toLowerCase() === 'kickoff') ||
        item.eventtype_t.toLowerCase() === 'ft' ||
        item.eventtype_t.toLowerCase() === 'et-ft' ||
        item.eventtype_t.toLowerCase() === 'ht' ||
        item.eventtype_t.toLowerCase() === 'et-ht' ||
        item.eventtype_t.toLowerCase() === 'et-ko') && (
        <div className="grid-xl ad-block" aria-hidden="true">
          <DFP
            slot="/11820346/Hercules_ROS_750x200//300x250"
            size={[
              [750, 200],
              [300, 250],
            ]}
          />
        </div>
      )}
    </>
  );
};

export default withTranslation()(BlogCard);
