/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import { createTagList } from '../../utils/utils';
export const AdCard = (data, isAws) => {
  const hrefValue = (strValue) => {
    try {
      let data = strValue && strValue.split(new String('url='))[1];
      let str = data && data.split('anchor')[0];
      if (str) {
        str = str.replace('" />', '');
        str = str.replace('"', '');
      }
      return str ? str : '';
    } catch (error) {
      console.log('AD Link Error');
      return '';
    }
  };
  const sponsor = isAws
    ? data.sponsordetailinfo_s && data.sponsordetailinfo_s[0]
    : data.sponsordetailinfo_s && JSON.parse(data.sponsordetailinfo_s)[0];
  return {
    fields: {
      Image: {
        value: {
          Crops: isAws
            ? data.celumimagesvariant_s &&
              data.celumimagesvariant_s.ImageCropUrl &&
              data.celumimagesvariant_s.ImageCropUrl.CropUrl
              ? data.celumimagesvariant_s.ImageCropUrl.CropUrl
              : data.imagecropurl_s
            : data.imagecropurl_s && JSON.parse(data.imagecropurl_s),

          AltText: isAws
            ? data.celumimagesvariant_s &&
              data.celumimagesvariant_s.ImageCropUrl &&
              data.celumimagesvariant_s.ImageCropUrl.AltText
            : data.alttext_s,

          isWebpAvailable: isAws
            ? data.celumimagesvariant_s &&
              data.celumimagesvariant_s.ImageCropUrl &&
              data.celumimagesvariant_s.ImageCropUrl.isWebpAvailable
            : data.iswebpavailablebool_b,
        },
      },
      ShortHeadline: {
        value: data.shortheadline_t,
      },
      Teaser: {
        value: data.teaser_t,
      },
      Theme: {
        value: data.theme_s,
      },
      CTATitle: {
        value: data.ctatitle_t,
      },
      CTATitleSubscribedUser: {
        value: data.ctatitlesubscribeduser_t,
      },
      CTATitleRegisteredUser: {
        value: data.ctatitleregistereduser_t,
      },
      ContentAccess: {
        value: data.contentaccess_t ? data.contentaccess_t : null,
      },
      CTAUrl: {
        value: {
          href: isAws ? data.ctaurlstring_s : hrefValue(data.ctaurl_t),
          target: '_blank',
        },
      },
      Variant: {
        value: data.variant_t && data.variant_t.toLowerCase(),
      },
      ContentType: {
        value: data.contenttype_t,
      },
      CardType: {
        value: data.cardtype_t,
      },
      Sponsorby: sponsor
        ? {
            fields: {
              PartnerIconDark: {
                value: {
                  Crops: {
                    img1x: sponsor.PartnerIconDark,
                  },
                },
              },
              PartnerName: {
                value: sponsor.PartnerName,
              },

              CTAURL: {
                value: {
                  href: sponsor.CTAURL,
                },
              },

              MCC_MNC: {
                value: sponsor.MCC_MNC,
              },
              PartnerIconLight: {
                value: {
                  Crops: {
                    img1x: sponsor.PartnerIconLight,
                  },
                },
              },
              PartnerText: {
                value: sponsor.PartnerText,
              },
            },
          }
        : null,
      __Published: {
        value: data.appearance_tdt || data.__published_tdt,
      },
      manutds: {
        value: {
          DestinationUrl: data.destinationurl_s,
          __Name: data._name,
          Tags: createTagList(data.taglist_sm, data.category_s),
        },
      },
    },
    rendering: {
      dataSource: data.itemid_s,
    },
  };
};
