/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_MATCH_TICKET,
  SET_MATCH_TICKET,
  ERROR_MATCH_TICKET,
  FETCH_MEMBER_SELECTION,
  SET_MEMBER_SELECTION,
  ERROR_MEMBER_SELECTION,
  FETCH_PREVIOUS_ORDER,
  SET_PREVIOUS_ORDER,
  ERROR_PREVIOUS_ORDER,
  FETCH_ORDER_DETAIL,
  SET_ORDER_DETAIL,
  FETCH_USER_DETAIL,
  SET_USER_DETAIL,
  ERROR_USER_DETAIL,
  SUBMIT_USER_REQUEST,
  GET_USER_REQUEST,
  ERROR_USER_REQUEST,
  SUBMIT_CONFIRM_TICKET,
  GET_CONFIRM_TICKET,
  ERROR_CONFIRM_TICKET,
  USER_CONFIRMATION,
  ERROR_ORDER_DETAIL,
} from '../constants/actions/actionConstant';
import {
  TICKET_ALLOCATION,
  GET_PREVIOUS_ORDER,
  GET_ORDER_DETAIL,
  GET_USER_LIST,
  ADD_USER_REQUEST,
  TICKET_DETAILCONFIRMATION,
} from '../constants/endpoints';
import api from '../utils/muApi';
import MU from '../constants/muConstants';

export const fetchAllocatedMatchTicket = (dispatch, gigyaResponse) => {
  const route = `${TICKET_ALLOCATION}${gigyaResponse.data.mufcNumber}`;
  const baseURL = `${MU.awsEndPoint}/${MU.awsAPIVersion}`;
  const isHardcoredGeo = true;
  const header = {
    Authorization:
      gigyaResponse.UID +
      'xa#po#cop3f' +
      gigyaResponse.UIDSignature +
      'xa#po#cop3f' +
      gigyaResponse.signatureTimestamp,
  };
  dispatch(fetchingMatchTicket());
  return api
    .get({ route, baseURL, isHardcoredGeo, header })
    .then((response) => {
      dispatch(setMatchTicket(response));
    })
    .catch((err) => {
      dispatch(error(err));
    });
};
export const fetchingMatchTicket = () => ({ type: FETCH_MATCH_TICKET });
export const setMatchTicket = (response) => ({ type: SET_MATCH_TICKET, response });
export const error = (response) => ({ type: ERROR_MATCH_TICKET, response });

export const fetchMemberSelection = (dispatch, gigyaResponse, match) => {
  const route = `${TICKET_ALLOCATION}${gigyaResponse.data.mufcNumber}&OptaMatchId=${match.OptaMatchId} `;
  const baseURL = `${MU.awsEndPoint}/${MU.awsAPIVersion}`;
  const isHardcoredGeo = true;
  const header = {
    Authorization:
      gigyaResponse.UID +
      'xa#po#cop3f' +
      gigyaResponse.UIDSignature +
      'xa#po#cop3f' +
      gigyaResponse.signatureTimestamp,
  };
  dispatch(fetchingMemberSelection());
  return api
    .get({ route, baseURL, isHardcoredGeo, header })
    .then((response) => {
      dispatch(setMemberSelection(response));
    })
    .catch((err) => {
      dispatch(setMemberSelectionError(err));
    });
};

export const fetchingMemberSelection = () => ({ type: FETCH_MEMBER_SELECTION });
export const setMemberSelection = (response) => ({ type: SET_MEMBER_SELECTION, response });
export const setMemberSelectionError = (response) => ({ type: ERROR_MEMBER_SELECTION, response });

/**
 * FetchPreviousOrder
 * @param {action dispatch} dispatch
 * @param {login user detail} gigyaResponse
 */
export const fetchPreviousOrder = (dispatch, gigyaResponse) => {
  fetchAllocatedMatchTicket(dispatch, gigyaResponse);
  const route = `${GET_PREVIOUS_ORDER}${gigyaResponse.data.mufcNumber}`;
  const baseURL = `${MU.awsEndPoint}/${MU.awsAPIVersion}`;
  const isHardcoredGeo = true;
  const header = {
    Authorization:
      gigyaResponse.UID +
      'xa#po#cop3f' +
      gigyaResponse.UIDSignature +
      'xa#po#cop3f' +
      gigyaResponse.signatureTimestamp,
  };
  dispatch(fetchingPreviousOrder());
  return api
    .get({ route, baseURL, isHardcoredGeo, header })
    .then((response) => {
      dispatch(setPreviousOrder(response));
    })
    .catch((err) => {
      dispatch(setPreviousOrderError(err));
    });
};

export const fetchingPreviousOrder = () => ({ type: FETCH_PREVIOUS_ORDER });
export const setPreviousOrder = (response) => ({ type: SET_PREVIOUS_ORDER, response });
export const setPreviousOrderError = (response) => ({ type: ERROR_PREVIOUS_ORDER, response });

/**
 * FetchOrderDetail
 * @param {action dispatch} dispatch
 * @param {login user detail} gigyaResponse
 */
export const fetchOrderDetail = (dispatch, gigyaResponse, orderNO) => {
  const route = `${GET_ORDER_DETAIL}${orderNO}`;
  const baseURL = `${MU.awsEndPoint}/${MU.awsAPIVersion}`;
  const isHardcoredGeo = true;
  const header = {
    Authorization:
      gigyaResponse.UID +
      'xa#po#cop3f' +
      gigyaResponse.UIDSignature +
      'xa#po#cop3f' +
      gigyaResponse.signatureTimestamp,
  };
  dispatch(fetchingOrderDetail());
  return api
    .get({ route, baseURL, isHardcoredGeo, header })
    .then((response) => {
      dispatch(setOrderDetail(response));
    })
    .catch((err) => {
      dispatch(setOrderDetailError(err));
    });
};

export const fetchingOrderDetail = () => ({ type: FETCH_ORDER_DETAIL });
export const setOrderDetail = (response) => ({ type: SET_ORDER_DETAIL, response });
export const setOrderDetailError = (response) => ({ type: ERROR_ORDER_DETAIL, response });

/**
 * FetchUserDetail
 * @param {action dispatch} dispatch
 * @param {login user detail} gigyaResponse
 */
export const fetchUserDetail = (dispatch, gigyaResponse) => {
  const route = `${GET_USER_LIST}${gigyaResponse.data.mufcNumber}`;
  const baseURL = `${MU.awsEndPoint}/${MU.awsAPIVersion}`;
  const isHardcoredGeo = true;
  const header = {
    Authorization:
      gigyaResponse.UID +
      'xa#po#cop3f' +
      gigyaResponse.UIDSignature +
      'xa#po#cop3f' +
      gigyaResponse.signatureTimestamp,
  };
  dispatch(fetchingUserList());
  return api
    .get({ route, baseURL, isHardcoredGeo, header })
    .then((response) => {
      dispatch(setUserList(response));
    })
    .catch((err) => {
      dispatch(setUserListError(err));
    });
};

export const fetchingUserList = () => ({ type: FETCH_USER_DETAIL });
export const setUserList = (response) => ({ type: SET_USER_DETAIL, response });
export const setUserListError = (response) => ({ type: ERROR_USER_DETAIL, response });

/**
 * AddUserRequest
 * @param {action dispatch} dispatch
 * @param {login user detail} gigyaResponse
 */
export const addUserRequest = (dispatch, gigyaResponse, data) => {
  const route = `${ADD_USER_REQUEST}`;
  const baseURL = `${MU.awsEndPoint}/${MU.awsAPIVersion}`;
  const isHardcoredGeo = true;
  const header = {
    Authorization:
      gigyaResponse.UID +
      'xa#po#cop3f' +
      gigyaResponse.UIDSignature +
      'xa#po#cop3f' +
      gigyaResponse.signatureTimestamp,
  };
  dispatch(submitUserRequest());
  return api
    .post({ route, baseURL, isHardcoredGeo, header, data })
    .then((response) => {
      dispatch(getUserRequest(response));
    })
    .catch((err) => {
      dispatch(setUserListError(err));
    });
};

export const submitUserRequest = () => ({ type: SUBMIT_USER_REQUEST });
export const getUserRequest = (response) => ({ type: GET_USER_REQUEST, response });
export const setUserRequestError = (response) => ({ type: ERROR_USER_REQUEST, response });

/**
 * Ticket Confirmation
 * @param {action dispatch} dispatch
 * @param {login user detail} gigyaResponse
 */
export const ticketConfirmation = (dispatch, gigyaResponse, data) => {
  const route = `${TICKET_DETAILCONFIRMATION}`;
  const baseURL = `${MU.awsEndPoint}/${MU.awsAPIVersion}`;
  const isHardcoredGeo = true;
  const header = {
    Authorization:
      gigyaResponse.UID +
      'xa#po#cop3f' +
      gigyaResponse.UIDSignature +
      'xa#po#cop3f' +
      gigyaResponse.signatureTimestamp,
  };
  dispatch(submitConfirmTicket());
  return api
    .post({ route, baseURL, isHardcoredGeo, header, data })
    .then((response) => {
      dispatch(getConfirmTicket(response));
    })
    .catch((err) => {
      dispatch(setConfirmTicketError(err));
    });
};

export const submitConfirmTicket = () => ({ type: SUBMIT_CONFIRM_TICKET });
export const getConfirmTicket = (response) => ({ type: GET_CONFIRM_TICKET, response });
export const setConfirmTicketError = (response) => ({ type: ERROR_CONFIRM_TICKET, response });

/**
 * Set Confirmation User
 * @param {action dispatch} dispatch
 * @param {login user detail} gigyaResponse
 */
export const setUserConfirmation = (dispatch, data) => {
  dispatch(setConfiramtion(data));
};
export const setConfiramtion = (response) => ({ type: USER_CONFIRMATION, response });
