/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_PLAYER_GRID,
  SET_PLAYER_GRID,
  ERROR_PLAYER_GRID,
} from '../constants/actions/actionConstant';
import { PLAYER_GRID } from '../constants/endpoints';
import { WOMEN_PLAYER } from '../constants/endpoints';
import api from '../utils/muApi';
import { updateFilterSymbols } from '../utils/utils';

/**
 * Action for PlayerGrid
 */
export const fetchPlayerGrid = (dispatch, filter) => {
  const route = `${PLAYER_GRID}${updateFilterSymbols(filter)}`;
  dispatch(fetchGrid(filter));
  return api
    .get({ route })
    .then((response) => {
      dispatch(setGrid(response, filter));
    })
    .catch((err) => {
      dispatch(error(err));
    });
};

export const fetchGrid = (filter) => ({ type: FETCH_PLAYER_GRID, filter });

export const setGrid = (response, filter) => ({
  type: SET_PLAYER_GRID,
  response,
  filter,
});

export const error = (response) => ({ type: ERROR_PLAYER_GRID, response });
