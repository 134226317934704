/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import StatsBar from '../../Common/StatsBar';
import LazyLoad from 'react-lazyload';
import ImgCrop from '../../Common/ImgCrop';
import { getStatsBarData, getItemId, createDestinationUrl } from '../../../utils/utils';
import * as track from '../../../utils/analytics';
import { HERO_LIVE_EVENT } from '../../../constants/containerConstants';
const LiveBlogEvent = (props) => {
  const { t, fields, gridClass, isMatchcenterCard, container } = props;

  if (fields.manutds && fields.manutds.value && fields.manutds.value.DestinationUrl) {
    let destinationURL = fields.manutds.value.DestinationUrl;
    destinationURL = destinationURL && destinationURL.split('#');
    destinationURL = destinationURL && destinationURL[0];
    fields.manutds.value.DestinationUrl = destinationURL;
  }

  const imageCrops =
    fields.EventHeroImageUrl &&
    fields.EventHeroImageUrl.value &&
    fields.EventHeroImageUrl.value.Crops;
  const imageAltText =
    fields.EventHeroImageUrl &&
    fields.EventHeroImageUrl.value &&
    fields.EventHeroImageUrl.value.AltText;
  const eventURL = createDestinationUrl(
    fields.manutds && fields.manutds.value && fields.manutds.value.DestinationUrl
  );
  const itemId = getItemId(props);
  const trackCard = (fields, e) => {
    const initialData = track.data('card');
    track.analytics(
      {
        ...initialData,
        card_name: fields.EventHeading && fields.EventHeading.value,
        container_type: container,
        item_id: itemId,
        content_type: fields.ContentType && fields.ContentType.value,
        tag: fields.manutds && fields.manutds.value && fields.manutds.value.Tags,
        created_datetime: fields.__Published && fields.__Published.value,
        author: fields.AuthorName && fields.AuthorName.value,
        destination_url: eventURL,
      },
      e
    );
  };
  return (
    <div className={`${gridClass} ${isMatchcenterCard ? 'ratio-matchday' : 'ratio'} item`}>
      <div className="mu-content">
        <div
          className="mu-item"
          onClick={(e) => trackCard(fields, e)}
          data-impression={track.impression({
            card_name: fields.EventHeading && fields.EventHeading.value,
            content_type: fields.ContentType && fields.ContentType.value,
          })}
        >
          <a
            href={eventURL}
            tabIndex="-1"
            aria-hidden={container === HERO_LIVE_EVENT ? 'true' : null}
            title={fields.EventHeading && fields.EventHeading.value}
          >
            <div className="img-holder">
              {imageCrops && (
                <LazyLoad once={true} offset={100}>
                  <ImgCrop
                    imgCrop={imageCrops}
                    alt={imageAltText}
                    title={imageAltText}
                    isWebpAvailable={
                      fields.EventHeroImageUrl &&
                      fields.EventHeroImageUrl.value &&
                      fields.EventHeroImageUrl.value.isWebpAvailable
                    }
                    gridClass={gridClass}
                  ></ImgCrop>
                </LazyLoad>
              )}
            </div>
          </a>
          <div className="mu-item__info">
            <a href={eventURL}>
              <h1 className="mu-item__title">{fields.EventHeading && fields.EventHeading.value}</h1>
            </a>
            <p className="mu-item__teasure ">
              {fields.EventDescription && fields.EventDescription.value}
            </p>
          </div>
          <StatsBar item={getStatsBarData(fields)}></StatsBar>
        </div>
      </div>
    </div>
  );
};
export default withTranslation()(LiveBlogEvent);
