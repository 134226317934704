/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useRef } from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
const Table = (props) => {
  const tableContainer = useRef(null);
  const RTETable = props && props.fields && props.fields.RTETable;
  useEffect(() => {
    let el = tableContainer && tableContainer.current;
    if (el) {
      let trList = el.querySelectorAll('tr');
      if (trList && trList.length) {
        let trHead = trList[0];
        trHead && trHead.classList.add('header-row');
        let headTdList = trHead.querySelectorAll('td');
        let headTdTextArr = [];
        headTdList &&
          headTdList.length &&
          headTdList.forEach((value) => {
            let text = (value && value.innerText) || '';
            text = text && text.trim() + ':';
            headTdTextArr.push(text);
          });
        if (headTdTextArr && headTdTextArr.length) {
          trList.forEach((value, key) => {
            let tdList = value && value.querySelectorAll('td');
            if (tdList && tdList.length) {
              tdList.forEach((td, index) => {
                td && td.setAttribute('data-label', headTdTextArr[index]);
              });
            }
          });
        }
      }
    }
  }, [tableContainer]);
  return (
    <React.Fragment>
      {RTETable && RTETable.value && (
        <section ref={tableContainer} className="tpl-table skin-white">
          <div className="table-tpl-inner">
            <RichText field={RTETable} editable={true} />
          </div>
        </section>
      )}
    </React.Fragment>
  );
};
export default Table;
