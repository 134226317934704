/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { createBaseUrl } from '../../utils/utils';
import AutoSearch from '../Common/AutoSearch';
import ErrorBoundary from '../Common/ErrorBoundary';
import xss from 'xss';

const SearchBox = (props) => {
  const { searchText, onSearch } = props;
  const [mouseLeave, setMouseLeave] = useState(false);
  const [searchValue, setSearchValue] = useState(searchText);
  const [initial, setInitial] = useState(true);
  const getValueOnChange = (e) => {
    setSearchValue(e.target.value);
    setInitial(false);
  };
  useEffect(() => {
    setSearchValue(searchText);
  }, [searchText]);

  const getBack = (e) => {
    e.preventDefault();
    if (typeof window !== 'undefined') {
      window.location.href = xss(window.document.referrer);
    }
  };

  const callSearch = (e) => {
    if (e && e.trim() !== '' && !initial) {
      setInitial(true);
      onSearch(e);
    }
  };

  const checkIfEnterKeyPressed = (e, type) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13) {
      if (type === 'ok') {
        searchValue && searchValue.length > 0 && callSearch(searchValue);
      } else if (type === 'cancel') {
        setSearchValue('');
      }
    }
  };

  return (
    <div
      className="typeahead-container"
      onMouseLeave={() => setMouseLeave(true)}
      onMouseEnter={() => setMouseLeave(false)}
    >
      <a
        href="#"
        className="icon-search-arrow"
        role="button"
        aria-label="back"
        onClick={(e) => getBack(e)}
      />
      <ErrorBoundary>
        <AutoSearch
          mouseLeave={mouseLeave}
          inputId={`searchInputId`}
          getValueOnChange={getValueOnChange}
          isSearchPage={true}
          searchText={searchValue}
          onSearch={callSearch}
          initial={initial}
          placeholder="Search"
        ></AutoSearch>
      </ErrorBoundary>
      {!initial || searchValue === '' ? (
        <img
          tabIndex="0"
          src={`${createBaseUrl()}/assets/images/icons/search-results@2x.png`}
          alt="search"
          role="button"
          onClick={() => searchValue && searchValue.length > 0 && callSearch(searchValue)}
          onKeyPress={(e) => checkIfEnterKeyPressed(e, 'ok')}
        />
      ) : (
        <img
          tabIndex="0"
          src={`${createBaseUrl()}/assets/images/icons/close-results@2x.png`}
          alt="search cancel icon"
          role="button"
          aria-label="Clear search text"
          onClick={() => {
            setSearchValue('');
            setInitial(true);
          }}
          onKeyPress={(e) => checkIfEnterKeyPressed(e, 'cancel')}
        />
      )}
    </div>
  );
};

export default withTranslation()(SearchBox);
