/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

 import React, { useState, useEffect, useRef } from 'react';
 import { CopyToClipboard } from 'react-copy-to-clipboard';
 import { withTranslation } from 'react-i18next';
 import { buildImgUrl, getLanguage, checkCountryCode, isTouchDevice } from '../../utils/utils';
 import MU from '../../constants/muConstants';
 import * as track from '../../utils/analytics';
 import { useSponsorValue } from '../../context/sponsorContext';
 import {
   FacebookShareButton,
   FacebookIcon,
   TwitterShareButton,
   TwitterIcon,
   WeiboShareButton,
   WeiboIcon,
   WhatsappShareButton,
   WhatsappIcon,
 } from 'next-share';
 
 const Share = ({
   t,
   item,
   onRef,
   tracking,
   Ooyala_id,
   author,
   card_name,
   content_type,
   created_datetime,
   item_id,
   tag,
   focusFirstItem,
 }) => {
   const [{ isGigyaAvilable }, dispatch] = useSponsorValue();
   const [copyState, setcopyState] = useState(false);
   const [FBParams, setFBParams] = useState(null);
   const [TWParams, setTWParams] = useState(null);
   const [SWParams, setSWParams] = useState(null);
   const [WAParams, setWAParams] = useState(null);
   const language = getLanguage();
   const gigyaRef = useRef(null);
   useEffect(() => {
     window.gigya &&
       initGigyaShare(
         buildImgUrl(item && item.imagecropurl_s && item.imagecropurl_s.img1x),
         item.teaser_t,
         createDestinationUrl(item.destinationurl_s),
         item.shortheadline_t
       );
   }, [isGigyaAvilable]);
   useEffect(() => {
     onRef &&
       onRef(() => {
         setcopyState(false);
       });
   }, []);
   useEffect(() => {
     if (window.gigya && gigyaRef && focusFirstItem) {
       const element = gigyaRef.current;
       const list = element && element.querySelector('li');
       list && list.focus();
     }
   }, [gigyaRef, isGigyaAvilable, focusFirstItem]);
   const initGigyaShare = (cardimage, description, sharUrl, title) => {
     let image = {
       type: 'image',
       src: cardimage,
       href: createDestinationUrl(item.destinationurl_s),
     };
     let ua = new window.gigya.socialize.UserAction();
 
     ua.setTitle(title);
     ua.setLinkBack(sharUrl);
     ua.setDescription(description);
     ua.addMediaItem(image);
 
     if (language !== 'zh-cn') {
       setFBParams({
         provider: 'facebook',
         platform: 'Facebook',
         url: sharUrl,
         userAction: ua,
         cid: 'Facebook share',
         shortURLs: 'never',
       });
       setTWParams({
         provider: 'twitter',
         platform: 'Twitter',
         url: sharUrl,
         userAction: ua,
         cid: 'Twitter Shares',
         shortURLs: 'never',
       });
     }
     setSWParams({
       provider: 'sina',
       platform: 'Sina',
       url: sharUrl,
       userAction: ua,
       cid: 'Sina Weibo Shares',
       shortURLs: 'never',
     });
     setWAParams({
       provider: 'whatsapp',
       platform: 'Whatsapp',
       url: sharUrl,
       userAction: ua,
       cid: 'Whatsapp Shares',
       shortURLs: 'never',
     });
   };
 
   const openShareModal = (e, socialParams) => {
     tracking && trackShare(e, socialParams && socialParams.platform);
   };
 
   const copyUrlToClipboard = (e) => {
     setcopyState(true);
     tracking && trackShare(e, 'Copy');
   };
 
   const sendMail = (e) => {
     let mailto = '',
       subject = item.shortheadline_t || item.pagetitle_t,
       url = createDestinationUrl(item.destinationurl_s);
     subject = !subject ? ' ' : subject;
     window.location = `mailto:${mailto}?subject=${subject}&body=${url}`;
     tracking && trackShare(e, 'Email');
   };
 
   const createDestinationUrl = (url) => {
     if (url) {
       if (url.indexOf('http') !== -1 || url.indexOf('www') !== -1) {
         return url;
       } else {
         const protocol = typeof window !== 'undefined' ? window.location.protocol + '//' : '';
         const baseUrl = MU.assetUrlResource.replace('//', protocol);
         return `${baseUrl}/${url}`;
       }
     }
   };
   const setClass = (isTouch) => {
     return isTouch ? 'whatsapp social-icons-li showIcon' : 'whatsapp social-icons-li';
   };
 
   const trackShare = (e, platform = '') => {
     const initialData = track.data('gigyaSocialShare');
     track.analytics(
       {
         ...initialData,
         Ooyala_id,
         author,
         card_name,
         content_type: Ooyala_id ? 'video' : content_type,
         created_datetime,
         destination_platform: platform,
         item_id,
         tag,
       },
       e
     );
     if (platform) {
       e.stopPropagation();
     }
   };
 
   const renderWhatsAppIcon = () => {
     const isTouch = isTouchDevice();
     return (
       <li
         className={setClass(isTouch)}
         onClick={(e) => openShareModal(e, WAParams)}
         onKeyDown={(e) => {
           (e.keyCode === 13 || e.which === 13) && openShareModal(e, WAParams);
         }}
         tabIndex={0}
         aria-hidden={focusFirstItem || isTouch ? 'false' : 'true'}
       >
         <span role="link" className="screenreader">
           {t('ShareWithWhatsapp')}
         </span>
         <WhatsappShareButton
           url={createDestinationUrl(item.destinationurl_s)}
           title={item.shortheadline_t}
           separator=":: "
         >
           <WhatsappIcon size={32} round />
         </WhatsappShareButton>
       </li>
     );
   };
 
   return (
     <ul ref={gigyaRef}>
       {isGigyaAvilable && (
         <React.Fragment>
           {checkCountryCode() && (
             <React.Fragment>
               <li
                 className="twitter social-icons-li"
                 onClick={(e) => openShareModal(e, TWParams)}
                 onKeyDown={(e) => {
                   (e.keyCode === 13 || e.which === 13) && openShareModal(e, TWParams);
                 }}
                 tabIndex={0}
               >
                 <span role="link" className="screenreader">
                   {' '}
                   {t('ShareWithTwitter')}
                 </span>
                 <TwitterShareButton
                   url={createDestinationUrl(item.destinationurl_s)}
                   title={item.shortheadline_t}
                 >
                   <TwitterIcon size={32} round />
                 </TwitterShareButton>
               </li>
               <li
                 className="facebook social-icons-li"
                 onClick={(e) => openShareModal(e, FBParams)}
                 onKeyDown={(e) => {
                   (e.keyCode === 13 || e.which === 13) && openShareModal(e, FBParams);
                 }}
                 tabIndex={0}
               >
                 <span role="link" className="screenreader">
                   {t('ShareWithFacebook')}
                 </span>
                 <FacebookShareButton
                   url={createDestinationUrl(item.destinationurl_s)}
                   quote={item.shortheadline_t}
                 >
                   <FacebookIcon size={32} round />
                 </FacebookShareButton>
               </li>
             </React.Fragment>
           )}
           {renderWhatsAppIcon()}
           <li
             className="egg social-icons-li"
             onClick={(e) => openShareModal(e, SWParams)}
             onKeyDown={(e) => {
               (e.keyCode === 13 || e.which === 13) && openShareModal(e, SWParams);
             }}
             tabIndex={0}
             aria-hidden={!focusFirstItem ? 'true' : 'false'}
           >
             <span role="link" className="screenreader">
               {t('ShareWithSinaWeibo')}
             </span>
             <WeiboShareButton
               url={createDestinationUrl(item.destinationurl_s)}
               title={item.shortheadline_t}
             >
               <WeiboIcon size={32} round />
             </WeiboShareButton>
           </li>
         </React.Fragment>
       )}
       <li
         className="email control-event"
         onClick={(e) => sendMail(e)}
         onKeyDown={(e) => {
           (e.keyCode === 13 || e.which === 13) && sendMail(e);
         }}
         tabIndex={0}
         aria-hidden={!focusFirstItem ? 'true' : 'false'}
       >
         <span role="link" className="screenreader">
           {t('ShareWithEmail')}
         </span>
         <i className="icon-mail" />
       </li>
       <li className="copy-link-btn" onClick={(e) => copyUrlToClipboard(e)}>
         <CopyToClipboard text={createDestinationUrl(item.destinationurl_s)}>
           <button className="copy-link">
             <span className="icon">
               <i className="icon-hyperlink" />
             </span>
             <span className="copy-text">{copyState ? t('copied') : t('CopyLink')}</span>
           </button>
         </CopyToClipboard>
       </li>
     </ul>
   );
 };
 
 Share.defaultProps = {
   tracking: false,
   Ooyala_id: '',
   author: '',
   card_name: '',
   content_type: '',
   created_datetime: '',
   item_id: '',
   tag: '',
   focusFirstItem: false,
 };
 
 export default withTranslation()(Share);
