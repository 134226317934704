/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import { ListingProvider, useListingValue } from '../../context/listingContext';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { fetchListing } from '../../actions/listingAction';
import ListingFilter from './ListingFilter';
import Loader from '../Common/Loader';
import { createTagSearchUrl, updateTag, updateFilterSymbols } from '../../utils/utils';
import MU from '../../constants/muConstants';
import HeroContainer from './HeroContainer';
import ListingPage from './ListingPage';
import * as track from '../../utils/analytics';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

const Listing = (props) => (
  <ListingProvider>
    <ListingContainer {...props} />
  </ListingProvider>
);

const itemsPerPage = 30;

const ListingContainer = (props) => {
  const [
    {
      status,
      startListIndex,
      heroObj,
      pageData,
      filters,
      showLatest,
      showloadMore,
      cardsEnd,
      noMoreRecord,
    },
    dispatch,
  ] = useListingValue();
  const setLastSegment = () => {
    if (typeof window !== 'undefined') {
      const value = window.location.href.substr(0, window.location.href.lastIndexOf('?'));
      if (value) {
        return value.substr(value.lastIndexOf('/') + 1).toLowerCase();
      } else {
        return window.location.href.substr(window.location.href.lastIndexOf('/') + 1).toLowerCase();
      }
    }
  };
  const [lastSegment] = useState(setLastSegment());

  const filterName = () => {
    if (lastSegment === 'news' || lastSegment === 'videos') {
      return 'all';
    } else {
      return updateFilterSymbols(MU.datafilter);
    }
  };

  const location = useLocation();
  const [enableDFP] = useState(true);
  const [filterValue] = useState(typeof window !== 'undefined' && filterName());
  const [listing, setListing] = useState(false);
  const [heroClassWrapper, setHeroClassWrapper] = useState('');
  const [filterCall, setFilterCall] = useState(false);

  useEffect(() => {
    try {
      if (!filterCall && heroObj && heroObj.length > 0) {
        createFiltersAndHeroBanner(heroObj);
      }
    } catch (e) {
      console.error('Error filtering data', e);
    }
  }, [pageData, status]);

  useEffect(() => {
    const pathname = location && location.pathname && location.pathname.toLowerCase();
    if (pathname.includes('/news')) {
      setListing('news');
      fetchData('news', startListIndex, filterValue, 'default');
    } else if (pathname.includes('/videos')) {
      setListing('video');
      fetchData('video', startListIndex, filterValue, 'default');
    }
  }, []);

  const fetchData = (listingType, index, filter, actionType) => {
    fetchListing(dispatch, index, itemsPerPage, actionType, listingType, filter).then(() => {
      setTimeout(() => {
        if (actionType === 'default') {
          track.cardImpression();
        } else {
          track.cardLoadImpression();
        }
      }, 500);
    });
  };

  const createFiltersAndHeroBanner = (mainObj) => {
    if (mainObj) {
      let variant;
      if (mainObj) {
        if (mainObj && mainObj.length > 0) {
          variant = mainObj[0].variant_t;
          if (variant) {
            variant = variant.toLowerCase();
          }
          let heroClassWrap = variant == 'child' ? 'container' : '';
          setHeroClassWrapper(heroClassWrap);
        }
      }

      if (listing === 'video') {
        setHeroClassWrapper('hero__container');
      } else if (variant != 'child' && listing != 'video') {
        //   $scope.heroClass.grid = $scope.heroClass.grid + ' xlarge';
      }
    }
  };

  const setHeroContainer = () => {
    if (listing === 'video') {
      return 'hero__container';
    }
    return '';
  };

  const setHeroClassName = `${setHeroContainer()} ${heroClassWrapper}  newslisting-hero-label`;

  const loadMore = () => {
    setFilterCall(true);
    const nextStartIndex = startListIndex + itemsPerPage;
    fetchData(listing, nextStartIndex, filterValue, 'loadMore');
    // write scroll functionality
  };

  const showLatestNews = () => {
    let mainCon, headerHeight;
    if (document.getElementsByClassName('hero__container')[0]) {
      mainCon = document.getElementsByClassName('hero__container')[0].clientHeight;
    } else {
      mainCon = 0;
    }
    if (document.getElementsByTagName('HEADER')[0]) {
      headerHeight = document.getElementsByTagName('HEADER')[0].clientHeight;
    } else {
      headerHeight = 0;
    }
    window.scroll({
      top: mainCon + headerHeight + 10,
      behavior: 'smooth',
    });
    setTimeout(() => {
      document.getElementsByClassName('link')[0].focus();
    }, 1000);
  };

  const checkIfEnterKeyPressed = (event, type) => {
    let keyCode = event.which || event.keyCode;
    if (keyCode === 13) {
      if (type == 'retry') {
        fetchData(listing, startListIndex, filterValue, 'loadMore');
      } else if (type == 'loadMore') {
        loadMore();
      } else if (type == 'latest') {
        showLatestNews();
      }
    }
  };

  const getStrWithAnchorTag = (str) => {
    let searchurl = createTagSearchUrl();
    let startTag = '<strong><a href="' + searchurl + '">';
    let endTag = '</strong></a>';
    let arrString = [{ startTag: startTag, endTag: endTag }];
    return updateTag(arrString, 0, str);
  };

  return (
    <React.Fragment>
      {/* HeroBanner */}
      <div>
        {heroObj && heroObj.length > 0 && (
          <HeroContainer
            hero={heroObj}
            setHeroClassName={setHeroClassName}
            filters={filters}
            listing={listing}
          />
        )}
        {/* Filter */}
        {filters && filters.length > 0 && (
          <ListingFilter
            filters={filters}
            listing={listing}
            lastSegment={lastSegment}
            language={MU.language}
          />
        )}
        {/* Listing */}
        <div className="news-list__bg newslistBgAccessibility">
          <div className="container  main">
            {pageData && pageData.length > 0 && (
              <ListingPage
                contentPage={pageData}
                startListIndex={startListIndex}
                itemsPerPage={itemsPerPage}
                enableDFP={enableDFP}
                listing={listing}
              />
            )}
            {status === 'error' && (
              <div>
                <div className="error-whoops__container">
                  <div className="grid-12 error-whoops__inner loadmore">
                    <h1>{props.t('Whoops')}</h1>
                    <p>{props.t('thereissomethingworngwithinternet')}</p>
                    <div className="red-btn-skew">
                      <a
                        role="button"
                        onKeyPress={(e) => checkIfEnterKeyPressed(e, 'retry')}
                        onClick={() => fetchData(listing, startListIndex, filterValue, 'loadMore')}
                      >
                        <span>{props.t('Retry')}</span>
                        <i className="icon-follow-live"></i>
                      </a>
                    </div>
                    <div className="white-btn-skew">
                      <a
                        role="button"
                        onClick={() => showLatestNews()}
                        onKeyPress={(e) => checkIfEnterKeyPressed(e, 'latest')}
                      >
                        <span>{props.t('Latest')}</span>
                        <i className="tag-red-arrow"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {status !== 'error' && (
              <div
                className="row newslisting-aria-label"
                style={{ textAlign: 'center', position: 'relative' }}
              >
                {showloadMore && status !== 'fetching' && (
                  <button
                    tabIndex="0"
                    id="loadMore"
                    className="white-btn-skew-v2"
                    aria-label={
                      listing === 'video'
                        ? props.t('watcholdervideos')
                        : props.t('Showolderstories')
                    }
                    onClick={() => loadMore()}
                    onKeyPress={(e) => checkIfEnterKeyPressed(e, 'loadMore')}
                    onKeyUp={(e) => checkIfEnterKeyPressed(e, 'loadMore')}
                  >
                    <span className="see-older-stories">
                      {listing === 'news' && props.t('Showolderstories')}
                      {listing === 'video' && props.t('watcholdervideos')}
                    </span>
                  </button>
                )}
                {pageData && pageData.length > 0 && status !== 'fetching' && showLatest && (
                  <button
                    tabIndex="0"
                    className="white-btn-skew-v2"
                    aria-label={props.t('Latest')}
                    onClick={() => showLatestNews()}
                    onKeyPress={(e) => checkIfEnterKeyPressed(e, 'latest')}
                  >
                    <span>{props.t('Latest')}</span>
                  </button>
                )}
                {status === 'fetching' && (
                  <Loader className={`loader-container1`} style={{ paddingBottom: '30px' }} />
                )}
                {noMoreRecord && (
                  <span className="last-card__msg nomore-last-msg">
                    {props.t('Therearenomorestoriestodisplay')}
                  </span>
                )}
                {cardsEnd && (
                  <RichText
                    className="last-card__msg"
                    tabIndex="0"
                    tag="span"
                    field={{
                      value: getStrWithAnchorTag(props.t('cantfindwhatyourelookingforMayb')),
                    }}
                    editable={false}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Listing);
