/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import { getMatchDate } from '../../utils/momentDate';
import { buildImgUrl } from '../../utils/utils';
import ImageFallback from '../Common/ImageFallback';
import LazyLoad from 'react-lazyload';
import ImgCrop from '../Common/ImgCrop';
import { MODULE, LIGHT_THEME } from '../../constants/globalConstants';
import Sponsor from '../Common/Sponsor';
import * as track from '../../utils/analytics';
import { MYUNITED_WEB_PREDICTION, PREDICTION_WEB } from '../../constants/containerConstants';

const UnitedPrediction = ({ myUnitedPrediction, t, item }) => {
  let HighestDailyStreak = 0;
  let TotalMatchAppearances = 0;
  let TotalPredictions = 0;
  let cardheading = 0;
  let PointWon = 0;
  let homeTeamScore = 0;
  let awayTeamScore = 0;
  let matchPrediction = item && item.PredictionInfo && item.PredictionInfo.MatchPredictionArray;
  let seasonInfo = item && item.UserInfo && item.UserInfo.SeasonInfo;
  let unitedImageCropUrl,
    liveScoreTimerData,
    seasonId,
    CompetitionName,
    contenttype_t,
    venue,
    matchDateTime,
    AwayTeam,
    awayTeamName,
    awayTeamImage,
    HomeTeam,
    homeTeamName,
    homeTeamImage,
    LineUpScore,
    CorrectScore,
    ManOfTheMatch,
    matchID,
    FirstScore;

  const trackCard = (e) => {
    const initialData = track.data('card');
    track.analytics(
      {
        ...initialData,
        card_name: cardheading,
        track_text: PREDICTION_WEB,
        content_type: MYUNITED_WEB_PREDICTION,
        track_type: 'link',
      },
      e
    );
  };
  const getLiveScore = (teamId, liveData) => {
    if (liveData && liveData.optacontent) {
      return teamId == 1 ? liveData.optacontent.GoalByManUtd : liveData.optacontent.GoalByOpponent;
    }
  };

  if (typeof myUnitedPrediction !== 'undefined') {
    seasonId = myUnitedPrediction.SeasonId;
    unitedImageCropUrl =
      myUnitedPrediction &&
      myUnitedPrediction.CoverImage &&
      JSON.parse(myUnitedPrediction.CoverImage);
  }

  if (seasonInfo) {
    for (var i = 0; i < seasonInfo.length; i++) {
      if (seasonInfo[i].SeasonID === seasonId.toString()) {
        HighestDailyStreak = seasonInfo[i].HighestDailyStreak
          ? seasonInfo[i].HighestDailyStreak
          : 0;
        TotalMatchAppearances = seasonInfo[i].TotalMatchAppearances
          ? seasonInfo[i].TotalMatchAppearances
          : 0;
        TotalPredictions = seasonInfo[i].PredictionsScoreTotal
          ? seasonInfo[i].PredictionsScoreTotal
          : 0;
      }
    }
  }
  cardheading = myUnitedPrediction && myUnitedPrediction.NoPredictionTitle;
  if (matchPrediction && matchPrediction.length > 0) {
    matchID = item.matchID === '' || item.matchID === undefined ? false : true;
    if (
      matchPrediction[0].LineUpPrediction ||
      matchPrediction[0].CorrectScorePrediction ||
      matchPrediction[0].ManOfTheMatchPrediction ||
      matchPrediction[0].FirstScorerPrediction
    ) {
      cardheading = myUnitedPrediction && myUnitedPrediction.LastPredictionTitle;
      LineUpScore =
        matchPrediction[0].LineUpPrediction &&
        matchPrediction[0].LineUpPrediction.TotalPredictionScore
          ? matchPrediction[0].LineUpPrediction.TotalPredictionScore
          : 0;

      CorrectScore =
        matchPrediction[0].CorrectScorePrediction &&
        matchPrediction[0].CorrectScorePrediction.PredictionScore
          ? matchPrediction[0].CorrectScorePrediction.PredictionScore
          : 0;

      ManOfTheMatch =
        matchPrediction[0].ManOfTheMatchPrediction &&
        matchPrediction[0].ManOfTheMatchPrediction.PredictionScore
          ? matchPrediction[0].ManOfTheMatchPrediction.PredictionScore
          : 0;

      FirstScore =
        matchPrediction[0].FirstScorerPrediction &&
        matchPrediction[0].FirstScorerPrediction.PredictionScore
          ? matchPrediction[0].FirstScorerPrediction.PredictionScore
          : 0;

      PointWon = LineUpScore + CorrectScore + ManOfTheMatch + FirstScore;
    }
  }

  if (item && item.SpotlightResponse) {
    let spotlightData = item.SpotlightResponse[0];
    contenttype_t = spotlightData.contenttype_t;
    let spotlightOptacontent = spotlightData.optacontent;
    CompetitionName = spotlightOptacontent && spotlightOptacontent.value[0].CompetitionName;
    venue = spotlightOptacontent && spotlightOptacontent.value[0].MatchDataList[0].Venue.Venue;
    matchDateTime = spotlightData.matchdate_tdt;

    let awayTeam = spotlightOptacontent && spotlightOptacontent.value[0].MatchDataList[0].AwayTeam;
    AwayTeam = awayTeam;
    awayTeamName = awayTeam.ShortClubName;
    awayTeamImage = awayTeam.TeamCrestImage.img1x;

    let homeTeam = spotlightOptacontent && spotlightOptacontent.value[0].MatchDataList[0].HomeTeam;
    HomeTeam = homeTeam;
    homeTeamName = homeTeam.ShortClubName;
    homeTeamImage = homeTeam.TeamCrestImage.img1x;

    if (spotlightData.contenttype_t === 'live') {
      if (item && item.TimerResponse) {
        liveScoreTimerData = item.TimerResponse[0];
      } else {
        awayTeamScore = 0;
        homeTeamScore = 0;
      }
    } else {
      awayTeamScore = awayTeam.Score;
      homeTeamScore = homeTeam.Score;
    }
  }

  return (
    <React.Fragment>
      {myUnitedPrediction && !myUnitedPrediction.HideWebPrediction && (
        <div className="profile__app-usage">
          <h2>{myUnitedPrediction.Heading}</h2>
          <div className="box-container">
            <div className="box red grid-4">
              <div className="box-wrapper">
                <div className="circle">
                  <span>{TotalPredictions}</span>
                </div>
                <p className="box-title">{myUnitedPrediction.MyUnitedTitle}</p>
                <p className="box-teaser">{myUnitedPrediction.MyUnitedTeaser}</p>
              </div>
            </div>
            <div className="box grey grid-4">
              <div className="box-wrapper">
                <div className="circle">
                  <span>{HighestDailyStreak}</span>
                </div>
                <p className="box-title">{myUnitedPrediction.StreakTitle}</p>
                <p className="box-teaser">{myUnitedPrediction.StreakTeaser}</p>
              </div>
            </div>
            <div className="box grey grid-4">
              <div className="box-wrapper">
                <div className="circle">
                  <span>{TotalMatchAppearances}</span>
                </div>
                <p className="box-title">{myUnitedPrediction.AppearancesTitle}</p>
                <p className="box-teaser">{myUnitedPrediction.AppearancesTeaser}</p>
              </div>
            </div>
          </div>
          <div className="grid-6 child ratio">
            <div className="mu-content">
              <div className="mu-item article-card">
                <div className="img-holder">
                  <LazyLoad once={true} offset={100}>
                    <ImgCrop imgCrop={unitedImageCropUrl} alt="" singleSrc={false}></ImgCrop>
                  </LazyLoad>
                </div>
                <div className="mu-item__sponsor" aria-label="sponser information">
                  <div className="mu-item__sponsor__wrapper">
                    <Sponsor type={MODULE} moduleName="predictionhomescreen" theme={LIGHT_THEME} />
                  </div>
                </div>
                {(!matchPrediction || !matchID) && (
                  <div className="mu-item__info without-app">
                    <h2 className="mu-item__title">{myUnitedPrediction.NoPredictionTitle}</h2>
                    <p className="mu-item__teasure">{myUnitedPrediction.LastPredictionTeaser}</p>
                    <div className="square">
                      <p aria-hidden="true">0</p>
                      <span aria-hidden="true">{myUnitedPrediction.PointsWon}</span>
                      <span className="screenreader">{`0 ${myUnitedPrediction.PointsWon}`}</span>
                    </div>
                    {myUnitedPrediction.CTATitle && myUnitedPrediction.CTAUrl && (
                      <div className="red-btn-skew">
                        <a
                          href={myUnitedPrediction.CTAUrl}
                          id="findOutMore"
                          target="_blank"
                          onClick={() => trackCard()}
                        >
                          <span>{myUnitedPrediction.CTATitle}</span>
                        </a>
                      </div>
                    )}
                  </div>
                )}
                {matchPrediction && matchID && (
                  <div className="mu-item__info with-app" style={{ display: 'block' }}>
                    <h2 className="mu-item__title">{myUnitedPrediction.LastPredictionTitle}</h2>
                    <p className="mu-item__teasure">
                      <span>{getMatchDate(matchDateTime, 'dddd D MMMM yyyy')}</span>
                      {`${CompetitionName}, ${t('kickoff')} ${getMatchDate(
                        matchDateTime,
                        'HH:mm'
                      )}, ${venue}`}
                    </p>
                    <div className="info__matches">
                      <ul>
                        <li>
                          <span className="team__logo" aria-hidden="true">
                            <ImageFallback
                              src={buildImgUrl(homeTeamImage)}
                              onErrorClass="no-img"
                              alt={homeTeamName}
                              relativePath={false}
                            />
                          </span>
                          {contenttype_t &&
                            (contenttype_t.toLowerCase() === 'result' ||
                              contenttype_t.toLowerCase() === 'live') && (
                              <span className="screenreader">
                                {`${homeTeamScore} ${t('goaldoneby')} ${homeTeamName}`}
                              </span>
                            )}
                          {contenttype_t &&
                            (contenttype_t.toLowerCase() === 'fixture' ||
                              contenttype_t.toLowerCase()) === 'timer' && (
                              <span className="screenreader">
                                {`${homeTeamName} ${t('versus')}`}
                              </span>
                            )}
                        </li>
                        {contenttype_t && contenttype_t.toLowerCase() === 'result' && (
                          <li className="match-score" aria-hidden="true">
                            <span>{homeTeamScore}</span>
                            <span> - </span>
                            <span>{awayTeamScore}</span>
                          </li>
                        )}
                        {contenttype_t && contenttype_t.toLowerCase() === 'live' && (
                          <li className="match-score" aria-hidden="true">
                            <span>{getLiveScore(HomeTeam.FID, liveScoreTimerData)}</span>
                            <span> - </span>
                            <span>{getLiveScore(AwayTeam.FID, liveScoreTimerData)}</span>
                          </li>
                        )}
                        {contenttype_t &&
                          (contenttype_t.toLowerCase() === 'fixture' ||
                            contenttype_t.toLowerCase() === 'timer') && (
                            <li className="match-score" aria-hidden="true">
                              <div className="versus-wrapper">
                                <span>{t('VS')}</span>
                              </div>
                            </li>
                          )}
                        <li>
                          <span className="team__logo" aria-hidden="true">
                            <ImageFallback
                              src={buildImgUrl(awayTeamImage)}
                              onErrorClass="no-img"
                              alt={awayTeamName}
                              relativePath={false}
                            />
                          </span>
                          {contenttype_t &&
                            (contenttype_t.toLowerCase() === 'result' ||
                              contenttype_t.toLowerCase() === 'live') && (
                              <span className="screenreader">
                                {`${awayTeamScore} ${t('goaldoneby')} ${awayTeamName}`}
                              </span>
                            )}
                        </li>
                      </ul>
                    </div>
                    <div className="square">
                      <p aria-hidden="true">{PointWon}</p>
                      <span aria-hidden="true">{myUnitedPrediction.PointsWon}</span>
                      <span className="screenreader">
                        {`${PointWon} ${myUnitedPrediction.PointsWon}`}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(UnitedPrediction);
