/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import { isArray, uniqBy } from 'lodash';
import MU from '../constants/muConstants';
import { sortBy } from 'lodash';
import { i18n } from '../constants/i18nConstants';

export const manuIds = ['1', '6752', '6826', '14501', '14712', '11839'];
export const calculatePenalty = (penaltyArr, homeTeamId, awayTemId) => {
  if (manuIds.indexOf(homeTeamId) !== -1 || manuIds.indexOf(awayTemId) !== -1) {
    var penalty = 0;
    for (var i = 0; i < penaltyArr.length; i++) {
      var penaltyObj = penaltyArr[i];
      if (penaltyObj && penaltyObj.Outcome === 'Scored') {
        penalty += 1;
      }
    }
    return penalty;
  }
  return penaltyArr ? penaltyArr.length : 0;
};

export const isLiveByPeriod = (period) => {
  if (period) {
    period = period.toLowerCase();
    if (
      period !== 'fulltime' &&
      period !== 'full time' &&
      period !== 'pre match' &&
      period !== 'prematch' &&
      period !== 'postponed' &&
      period !== 'abandoned'
    ) {
      return true;
    }
  }
  return false;
};

export const isMUTeam = (teamId) => {
  if (teamId) teamId = teamId.toString();
  if (manuIds.indexOf(teamId) !== -1) {
    return true;
  }
  return false;
};

export const isLivePrePeriod = (matchTime) => {
  const matchTimeDateObj = new Date(matchTime);
  const currentDate = new Date();
  const timeDiff = Math.abs(matchTimeDateObj - currentDate) / 36e5;
  if (timeDiff <= 3) return true;
  else return false;
};

export const isResultsByPeriod = (period) => {
  if (period) {
    period = period.toLowerCase();
    if (period === 'full time' || period === 'fulltime') {
      return true;
    }
  }
  return false;
};
export const matchMinutes = (min, period) => {
  period = period.toLowerCase();
  if (min && period) {
    if ((min > 45 && period === 'firsthalf') || period === 'first half') {
      return `45+${min - 45}`;
    } else if ((min > 90 && period === 'secondhalf') || period === 'second half') {
      return `90+${min - 90}`;
    } else if ((min > 105 && period === 'extrafirsthalf') || period === 'extra first half') {
      return `105+${min - 105}`;
    } else if ((min > 120 && period === 'extrasecondhalf') || period === 'extra second half') {
      return `120+${min - 120}`;
    } else if (
      period === 'unknown' ||
      period === 'un known' ||
      period === 'prematch' ||
      period === 'pre match'
    ) {
      return min;
    }
    //counter++;
    if (min >= 0 && min < 10) {
      min = `0${min}`;
      if (min === '00' || min === '01') {
        return min;
      }
    }
    return min;
  }
};

export const customizer = (objValue, srcValue) => {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
};

export const getSelectedYear = () => {
  const year =
    MU.matchFilter.indexOf('sid:') > -1 &&
    MU.matchFilter.substr(MU.matchFilter.indexOf('sid:') + 4, 4);
  return year && !isNaN(year) ? `${year}-${parseInt(year.substr(2, 2)) + 1}` : '';
};

export const getSelectedTeamFilter = () => {
  let filterValueGlobal = '';
  try {
    const filterValue = MU.matchFilter;
    if (filterValue) {
      const splittedVal = filterValue.split('~');
      if (splittedVal && splittedVal.length > 0) {
        for (var k = 0; k < splittedVal.length; k++) {
          const splitted = splittedVal[k];
          if (splitted && splitted.indexOf('team:') !== -1) {
            const splittedFilterValueGlobal = splitted.split(':');
            filterValueGlobal = splittedFilterValueGlobal[1];
          }
        }
      }
    }
  } catch (e) {
    console.error('Error parsing sitecore filter value');
  }
  return filterValueGlobal;
};

export const updateDestionationUrl = (url, year) => {
  if (url) {
    let splittedUrl = url.split('/');
    splittedUrl[splittedUrl.length - 2] = year;
    return splittedUrl.join('/');
  }
};

export const getUpdatedUrl = (yearfromFilter) => {
  return (url) => {
    if (yearfromFilter === MU.currentSeason) {
      return url;
    } else {
      return updateDestionationUrl(url, yearfromFilter);
    }
  };
};

/* Start match center constant & utils functions */
export const supportedLeagues = ['EU_CL', 'EN_LC', 'EN_PL', 'EU_EL', 'EN_FA'];
export const matchType = ['1st Leg', '2nd Leg', '2nd Leg Away Goal', '2nd Leg Cup Short'];

export const accessibilitySpotlight = () => {
  let spotLightConstants = {};
  spotLightConstants.spotlightCard = i18n.spotlight.accessibility.spotlightCard;
  spotLightConstants.goaldoneby = i18n.spotlight.accessibility.goaldoneby;
  spotLightConstants.penaltyshots = i18n.spotlight.accessibility.penaltyshots;
  spotLightConstants.activitydoneby = i18n.spotlight.accessibility.activitydoneby;
  spotLightConstants.timeago = i18n.spotlight.accessibility.timeago;
  spotLightConstants.cardrecivedby = i18n.spotlight.accessibility.cardrecivedby;
  spotLightConstants.vs = i18n.spotlight.accessibility.vs;
  spotLightConstants.timeRemaining = i18n.spotlight.accessibility.timeRemaining;
  spotLightConstants.hrsTime = i18n.spotlight.accessibility.hrs;
  spotLightConstants.minsTime = i18n.spotlight.accessibility.mins;
  spotLightConstants.timeElapsed = i18n.spotlight.accessibility.timeElapsed;
  spotLightConstants.outOf = i18n.spotlight.accessibility.outOf;
  spotLightConstants.and = i18n.spotlight.accessibility.and;
  spotLightConstants.aggscoreof = i18n.spotlight.accessibility.aggscoreof;
  return spotLightConstants;
};

export const updateGoal = (goalData) => {
  var goalArr = [];
  if (goalData) {
    try {
      goalData = sortBy(goalData, [
        function (o) {
          return parseInt(o.Time);
        },
      ]);
    } catch (e) {
      console.error('Error sorting goals in matches');
    }
    for (var i = 0; i < goalData.length; i++) {
      if (goalData[i] && goalData[i].Player) {
        goalArr[i] = new Object();
        let goaltime = '';
        let goalId = `${goalData[i].Player.ID}_i_${goalData[i].Type}`;
        for (var j = 0; j < goalData.length; j++) {
          if (
            goalData[j] &&
            goalData[j].Player &&
            goalId === `${goalData[j].Player.ID}_i_${goalData[j].Type}`
          ) {
            if (goaltime) {
              goaltime = goaltime + ', ' + matchMinutes(goalData[j].Time, goalData[j].Period);
            } else {
              goaltime = matchMinutes(goalData[j].Time, goalData[j].Period);
            }
            goalArr[i].goalId = goalId;
            goalArr[i].player = goalData[i].Player;
            goalArr[i].time = goaltime;
            goalArr[i].type = goalData[i].Type;
            goalArr[i].card = goalData[i].Card;
          }
        }
      }
    }
  }
  return uniqBy(goalArr, 'goalId');
};

export const getLineupFormation = () => {
  return {
    442: [1, 2, 5, 6, 3, 7, 4, 8, 11, 10, 9],
    41212: [1, 2, 5, 6, 3, 4, 7, 11, 8, 10, 9],
    433: [1, 2, 5, 6, 3, 7, 4, 8, 10, 9, 11],
    451: [1, 2, 5, 6, 3, 4, 10, 8, 7, 11, 9],
    4411: [1, 2, 5, 6, 3, 7, 4, 8, 11, 10, 9],
    4141: [1, 2, 5, 6, 3, 4, 7, 8, 10, 11, 9],
    4231: [1, 2, 5, 6, 3, 8, 4, 7, 10, 11, 9],
    4321: [1, 2, 5, 6, 3, 8, 4, 7, 10, 11, 9],
    532: [1, 2, 6, 5, 4, 3, 7, 8, 11, 10, 9],
    541: [1, 2, 6, 5, 4, 3, 7, 8, 10, 11, 9],
    352: [1, 6, 5, 4, 7, 11, 8, 2, 3, 10, 9],
    343: [1, 6, 5, 4, 2, 7, 8, 3, 10, 9, 11],
    4222: [1, 2, 5, 6, 3, 4, 8, 7, 11, 10, 9],
    3511: [1, 6, 5, 4, 7, 11, 8, 2, 3, 10, 9],
    3421: [1, 6, 5, 4, 2, 7, 8, 3, 10, 11, 9],
    3412: [1, 6, 5, 4, 2, 7, 8, 3, 9, 10, 11],
    3142: [1, 5, 4, 6, 8, 2, 7, 11, 3, 9, 10],
    '343d': [1, 6, 5, 4, 8, 2, 3, 7, 10, 9, 11],
    4132: [1, 2, 5, 6, 3, 4, 7, 8, 11, 9, 10],
    4240: [1, 2, 5, 6, 3, 4, 8, 7, 9, 10, 11],
    4312: [1, 2, 5, 6, 3, 7, 4, 11, 8, 9, 10],
    3241: [1, 6, 5, 4, 2, 3, 10, 7, 8, 11, 9],
    3331: [1, 6, 5, 4, 2, 8, 3, 10, 7, 11, 9],
  };
};

/**
 * @method {calculateTimeLeft return remaining time to live match}
 * */
export const calculateTimeLeft = (spotItem) => {
  let dt = spotItem && spotItem.matchdate_tdt ? spotItem.matchdate_tdt : 0;
  let end = new Date(dt);
  let now = new Date();
  let difference = end - now;
  let timeLeft = {};
  if (dt && difference > 0) {
    timeLeft = {
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
    timeLeft.hours = timeLeft.hours < 10 ? '0' + timeLeft.hours : timeLeft.hours;
    timeLeft.minutes = timeLeft.minutes < 10 ? '0' + timeLeft.minutes : timeLeft.minutes;
    timeLeft.seconds = timeLeft.seconds < 10 ? '0' + timeLeft.seconds : timeLeft.seconds;
  } else {
    timeLeft = {
      hours: 'zero',
      minutes: '00',
      seconds: '00',
    };
  }
  return timeLeft;
};
