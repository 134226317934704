/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import ImageFallback from '../Common/ImageFallback';
import { withTranslation } from 'react-i18next';
import * as track from '../../utils/analytics';
import { PLAYER_PROFILE } from '../../constants/containerConstants';
import { createDestinationUrl } from '../../utils/utils';
const NextPrevious = ({ t, fields, itemId }) => {
  const { NextPlayer, manutds, __Published, ContentType } = fields;
  const { PreviousPlayer: prevPlayer, NextPlayer: nextPlayer } = NextPlayer;
  const DestinationUrl =
    manutds &&
    manutds.value &&
    manutds.value.DestinationUrl &&
    createDestinationUrl(manutds.value.DestinationUrl);
  const trackCard = () => {
    const initialData = track.data('card');
    track.analytics({
      ...initialData,
      card_name: 'playerprofile',
      container_type: PLAYER_PROFILE,
      item_id: itemId,
      content_type: ContentType && ContentType.value,
      tag: manutds && manutds.value && manutds.value.Tags,
      created_datetime: __Published && __Published.value,
      destination_url: DestinationUrl,
    });
  };
  const getPlayerImg = (imgObj, AltText, Title) => {
    imgObj = imgObj && JSON.parse(imgObj);
    return (
      <li className="next-profile-image">
        <ImageFallback
          src={imgObj && imgObj.thumb}
          onErrorClass="no-img"
          alt={AltText}
          title={Title}
        />
      </li>
    );
  };
  const getPlayerTshirtNo = (lastname_t, shirtnumber_t, flag) => {
    let playerDictKey = flag === 'prev' ? 'PreviousPlayer' : flag === 'next' ? 'NextPlayer' : '';
    return (
      <li className="next-profile-name" data-shirt-number={shirtnumber_t}>
        <span className="screenreader">{t(playerDictKey)}</span>
        {shirtnumber_t ? (
          <span className="screenreader">
            {t('Shirtnumber')} {shirtnumber_t} {lastname_t}
          </span>
        ) : (
          <span className="screenreader">{lastname_t}</span>
        )}
        <span aria-hidden="true">
          {flag === 'prev' ? lastname_t : shirtnumber_t}{' '}
          {flag === 'prev' ? shirtnumber_t : lastname_t}
        </span>
      </li>
    );
  };
  const renderNextPrev = (playerObj, classes, flag) => {
    const {
      lastname_t,
      destinationurl_s,
      shirtnumber_t,
      playergridcropurl_s,
      AltText,
      Title,
      _name,
    } = playerObj;
    return (
      <a
        href={createDestinationUrl(destinationurl_s)}
        className={classes}
        onClick={() => trackCard()}
      >
        <ul>
          {flag === 'prev' && getPlayerImg(playergridcropurl_s, AltText || _name, Title || _name)}
          {getPlayerTshirtNo(lastname_t, shirtnumber_t, flag)}
          {flag === 'next' && getPlayerImg(playergridcropurl_s, AltText || _name, Title || _name)}
        </ul>
      </a>
    );
  };

  return (
    <React.Fragment>
      {prevPlayer && renderNextPrev(prevPlayer, 'player-detail__prev-controller', 'prev')}
      {nextPlayer && renderNextPrev(nextPlayer, 'player-detail__next-controller', 'next')}
    </React.Fragment>
  );
};

export default withTranslation()(NextPrevious);
