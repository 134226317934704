/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import Quiz from '../../Cards/Quiz';
import { HERO_QUIZ } from '../../../constants/containerConstants';

const HeroBannerQuiz = (props) => {
  return (
    <div className="home-row">
      <div className="home-hero">
        <Quiz container={HERO_QUIZ} {...props} gridClass="grid-12" />
      </div>
    </div>
  );
};

export default HeroBannerQuiz;
