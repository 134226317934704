/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import {
  deleteOldRecord,
  setConfiramtion,
  removeDeletedItemsIncount,
  setLocalData,
} from '../../../actions/mutvMyListAction';
import { useMutvListingValue } from '../../../context/mutvMyListContext';
import { MAX_ITEM } from '../../../constants/mutvConstants';
import { withTranslation } from 'react-i18next';
import { deleteOldItems } from '../mutvutil';
const MutvPopup = ({
  lastResponseTime,
  myList_obj,
  userLoginDetail,
  myListButton,
  contentList,
  widget,
  setmyListButton,
  t,
}) => {
  const [{ localDetails, myListResponse, reportStatus }, dispatch] = useMutvListingValue();
  const dataRef = useRef(null);
  const postRef = useRef(null);
  useEffect(() => {
    if (lastResponseTime) {
      dataRef.current = { lastResponseTime };
    }
  }, [lastResponseTime]);

  useEffect(() => {
    if (reportStatus) {
      postRef.current = { reportStatus };
    }
  }, [reportStatus]);

  const modalRootElement = document.getElementById('modal-popUp');

  const deleteLocallySaved = (e, localDetails) => {
    e.preventDefault();
    e.stopPropagation();
    const localData = deleteOldItems({ allData: localDetails, isHistory: false });
    localStorage.setItem('localData', JSON.stringify(localData));
    dispatch(setLocalData(null));
    dispatch(setConfiramtion(false));
    setmyListButton(!myListButton);
  };

  const removeOldRecords = (e) => {
    if (localDetails !== null) {
      deleteLocallySaved(e, localDetails);
    } else if (localDetails === null && myListResponse) {
      deleteOldRecord(
        dispatch,
        lastResponseTime,
        myList_obj,
        userLoginDetail,
        myListResponse,
        myListButton,
        contentList,
        widget,
        setmyListButton,
        postRef && postRef.current && postRef.current.reportStatus
      );
    } else {
      dispatch(setConfiramtion(false));
    }
  };

  const closeModal = () => {
    dispatch(setConfiramtion(false));
    dispatch(setLocalData(null));
  };
  if (!modalRootElement) return null;
  return ReactDOM.createPortal(
    <div id="modelPop">
      <div id="modelCustomPop" className="custompopup__wrapper">
        <div className="custompopup__innerwrapper">
          <img className="mu-crest" alt="MU" src="/assets/images/bg/logo.png" />
          <h2 className="custompopup__title">{t('YourMyListIsFull')}</h2>
          <p>{t('ThisWillDeleteYourOldestList')}</p>
          <div className="custompopup_actions">
            <div className="red-btn-skew">
              <a id="deleteOld" onClick={(e) => removeOldRecords(e)} href="#">
                <span>{t('RemoveOldItems')}</span>
              </a>
            </div>
            <div className="white-btn-skew">
              <a id="exitOverlay" onClick={() => closeModal()} href="#">
                <span>{t('No-thanks')}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>,
    modalRootElement
  );
};

export default withTranslation()(MutvPopup);
