/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

const Transcript = ({ t, toggleTranscript, transcriptOnkeypress, videoFileTranscript }) => {
  return (
    <div className="transcript-bg" role="dialog">
      <div className="top-bar">
        <span
          className="back-btn accessibilityCloseModal"
          tabIndex="0"
          role="button"
          title="back"
          onClick={(e) => toggleTranscript(e)}
          onKeyUp={(e) => transcriptOnkeypress(e)}
        >
          <i className="icon-chevron-left"></i>
        </span>
        <h2 className="transcript-txt">{t('Transcript')}</h2>
      </div>
      <RichText id="transcriptId" tag="p" field={videoFileTranscript} editable={false} />
    </div>
  );
};

export default withTranslation()(Transcript);
