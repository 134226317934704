/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import AdCard from '../../Cards/AdCard';
import { HERO_ADCARD } from '../../../constants/containerConstants';

const HeroBannerAdCard = (props) => {
  return (
    <div className="home-row">
      <div className="home-hero">
        <AdCard container={HERO_ADCARD} {...props} gridClass="grid-12" />
      </div>
    </div>
  );
};

export default HeroBannerAdCard;
