/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import VideoEpisode from '../VideoEpisode/index';

const PodcastEpisode = (props) => <VideoEpisode {...props} isPodcast={true} />;

export default PodcastEpisode;
