/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

export const ArticleDetailContentRange = [1, 2, 3, 4, 5];

export const ArticleDetailContentStyle = {
  video: { className: 'home-article-detail__video-panel', gridClass: 'grid-7' },
  playerprofile: {
    className: 'home-article-detail__article grid-12 player-profile',
    gridClass: 'grid-7 ratio',
  },
  quote: { className: 'home-article-detail__bottom-panel home-quotes', gridClass: 'grid-12' },
  poll: { className: 'home-article-detail__video-panel', gridClass: 'grid-7' },
  collection: { className: 'home-article-detail__article collection-wrapper', gridClass: 'grid-7' },
  gallery: { className: 'home-article-detail__article img-gallery', gridClass: 'grid-7' },
  quiz: { className: 'home-article-detail__article quiz-wrapper', gridClass: 'grid-7' },
  image: { className: 'home-article-detail__image-panel', gridClass: 'grid-7' },
  adcard: { className: 'home-article-detail__image-panel', gridClass: 'grid-7' },
  social: {
    className: 'home-article-detail__image-panel article-social',
    gridClass: 'grid-7 ratio',
  },
  article: { className: 'home-article-detail__article grid- 12', gridClass: 'grid-7' },
};
