/**
 *  © 2022 -2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';

const CardDefault = (props) => {
  const { item, getDateSchudle, getTimeSchudle, t } = props;
  const { matchdate_tdt, contenttype_t } = item;
  return (
    <div className="card-data card-default">
      <div className="leftBox">
        <div className="info_Txt">
          {matchdate_tdt && <p className="dateSchudle">{getDateSchudle(matchdate_tdt)}</p>}

          {contenttype_t && <p>{contenttype_t}</p>}
          <p>Card Defalut</p>
        </div>
      </div>
      <div className="rightBox">
        {matchdate_tdt && <p className="timeSchudle">{getTimeSchudle(matchdate_tdt)}</p>}
      </div>
    </div>
  );
};

export default withTranslation()(CardDefault);
