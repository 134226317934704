/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import {
  checkNewsGridSolarResponse,
  newsGridClassArray,
  supportedContentType,
} from '../../utils/utils';
import { gridArray, THRESHOLD } from '../../constants/globalConstants';
import Transform from '../../data-transform';
import { NEWS_GRID } from '../../constants/containerConstants';
import ErrorBoundary from '../Common/ErrorBoundary';

export const NewsGridAutomation = (props, gridType) => {
  const [gridClasses, setGridClasses] = useState(gridArray);
  let timeoutId;
  useEffect(() => {
    setGridClasses(newsGridClassArray(gridType));
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const resizeListener = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setGridClasses(newsGridClassArray(gridType));
    }, THRESHOLD);
  };

  const getMaxCard = () => {
    let maxCard = 10;
    if (gridType === 'twogrid') {
      maxCard = 2;
    } else if (gridType === 'sixgrid') {
      maxCard = 6;
    } else if (gridType === 'tengrid') {
      maxCard = 10;
    }
    return maxCard;
  };

  if (checkNewsGridSolarResponse(props)) {
    let maxCard = getMaxCard();
    let data = supportedContentType(
      props.rendering.fields.documentsFromSolr.value.response.docs,
      maxCard,
      'automated'
    );
    return (
      <ErrorBoundary>
        {data &&
          data.map((item, i) => (
            <Transform
              container={NEWS_GRID}
              key={i}
              data={item}
              source="solr"
              gridClass={gridClasses[i]}
              nativeCard={true}
            />
          ))}
      </ErrorBoundary>
    );
  }
};

export default NewsGridAutomation;
