/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import FixtureDetail from './FixtureDetail';
import ErrorBoundary from '../Common/ErrorBoundary';

const PostponedListing = ({ listing, selectedFilter, t }) => {
  return (
    <div className="Matches__fixture">
      <div className="Matches__time-title">
        <h2>{t('ToBeConfirmed')}</h2>
      </div>
      {listing.map((match, matchIndex) => (
        <ErrorBoundary>
          <div key={matchIndex}>
            <div className="fixture-title" id={match.opta_mid_t}>
              <span>{t('tbc')}</span>
              <span>|</span>
              <span>{match.competitionname_t}</span>
            </div>
            <FixtureDetail matchDetail={match} type={selectedFilter} postponed={true} />
          </div>
        </ErrorBoundary>
      ))}
    </div>
  );
};

export default withTranslation()(PostponedListing);
