/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_SPONSOR,
  SET_SPONSOR,
  ERROR_SPONSOR,
  FETCHED_GIGYA,
  SET_USER_STATUS,
  SET_SPONSOR_ON_RESIZE,
  SET_HELP_RESULTS,
  FETCH_DEVICE_MANAGE,
  SET_DEVICE_MANAGE,
  ERROR_DEVICE_MANAGE,
  CONTINUE_ON_OTHER,
  PLAY_HERE,
  SET_MUTV_HERO_VIDEO_STATUS,
} from '../constants/actions/actionConstant';
import { SPONSOR } from '../constants/endpoints';
import api from '../utils/muApi';

export const fetchSponsor = (dispatch) => {
  const route = SPONSOR;
  const sponsorObj = typeof Storage !== 'undefined' && sessionStorage.getItem('sponsorObj');
  if (sponsorObj) {
    try {
      dispatch(setSponsor({ data: JSON.parse(sponsorObj) }));
    } catch (e) {
      console.log('Error in Storage Sponsor', e);
    }
  } else {
    dispatch(fetchingSponsor());
    return api
      .get({ route })
      .then((response) => {
        dispatch(setSponsor(response));
      })
      .catch((err) => {
        dispatch(error(err));
      });
  }
};
export const fetchSponsorOnResize = (dispatch) => {
  dispatch({ type: SET_SPONSOR_ON_RESIZE });
};

export const fetchedGigya = (dispatch) => {
  dispatch({ type: FETCHED_GIGYA });
};

export const userLoginStatus = (dispatch, response) => {
  dispatch(setUserLoginStatus(response));
};

export const setHelpResults = (dispatch, response) => {
  dispatch({ type: SET_HELP_RESULTS, response });
};
//device management
export const setDeviceManage = (dispatch, response) => {
  dispatch({ type: SET_DEVICE_MANAGE, response });
};
export const continueOnOtherAction = (dispatch, response) => {
  dispatch({ type: CONTINUE_ON_OTHER, response });
  return '';
};
export const playHereAction = (dispatch, response) => {
  dispatch({ type: PLAY_HERE, response });
};

export const setMutvHeroVideoStatus = (dispatch, isMutvHeroVideoPlaying) => {
  dispatch({ type: SET_MUTV_HERO_VIDEO_STATUS, isMutvHeroVideoPlaying });
};

export const fetchingSponsor = () => ({ type: FETCH_SPONSOR });
export const setSponsor = (response) => ({ type: SET_SPONSOR, response });
export const error = (response) => ({ type: ERROR_SPONSOR, response });
export const setUserLoginStatus = (response) => ({ type: SET_USER_STATUS, response });
