/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_MUTV_LIST,
  SET_MUTV_LIST,
  ERROR_MUTV_LIST,
} from '../constants/actions/actionConstant';
import { GET_CAROUSEL } from '../constants/endpoints';
import api from '../utils/muApi';

/**
 * Action for mutv episode carousel listing
 */
export const fetchMutvListing = (
  dispatch,
  itemid,
  startIndex,
  itemsNumber,
  sortBy,
  actionType,
  seriesType
) => {
  let activeFilter = sortBy;
  const route = `${GET_CAROUSEL}${encodeURI(itemid)}${
    seriesType && sortBy ? (seriesType === 'none' ? `~sid:~sort:${sortBy}` : `~sid:${sortBy}`) : ''
  }/${startIndex}/${itemsNumber} `;
  dispatch(fetchMutvList(actionType, activeFilter, itemid));
  return api
    .get({ route })
    .then((response) => {
      dispatch(setMutvList(response, actionType, activeFilter, itemid));
    })
    .catch((err) => {
      dispatch(error(err));
    });
};

export const fetchMutvList = (actionType, activeFilter, itemid) => ({
  type: FETCH_MUTV_LIST,
  actionType,
  activeFilter,
  itemid,
});

export const setMutvList = (response, actionType, activeFilter, itemid) => ({
  type: SET_MUTV_LIST,
  response,
  actionType,
  activeFilter,
  itemid,
});

export const error = (response) => ({ type: ERROR_MUTV_LIST, response });
