/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import DFPAd from '../../DFPAd';

const DFPHPTOBillboard = (props) => <DFPAd {...props} panelClassName={'superhero-dfp'} />;

export default DFPHPTOBillboard;
