/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_LIVE_EVENT,
  SET_LIVE_EVENT,
  ERROR_LIVE_EVENT,
  UPDATE_LIVE_FEED,
  IS_IN_VIEW_PORT,
} from '../constants/actions/actionConstant';
import { checkResponseDocs } from '../utils/utils';
import { findLastIndex, differenceWith, drop, uniqBy } from 'lodash';

export const liveEventState = {
  status: null,
  cronTimeInterval: 30000,
  rowsCount: 30,
  timestamp: 'all',
  displayLoadMore: false,
  reTry: false,
  updatedEventList: [],
  HighlightResponse: null,
  LiveEventResponse: null,
  HeroItem: null,
  lastCardTime: null,
  pageLoad: false,
  notificationCount: 0,
  isInViewPort: true,
  action: 'default',
};

export const liveEventReducer = (
  state = liveEventState,
  { type, response, timestamp, isInViewPort, action } = {}
) => {
  switch (type) {
    case FETCH_LIVE_EVENT:
      return {
        ...state,
        status: 'fetching',
        pageLoad: action === 'loadmore',
        loader: action !== 'cronJob',
      };
    case SET_LIVE_EVENT: {
      return {
        ...state,
        status: 'fetched',
        pageLoad: false,
        loader: false,
        ...filterLiveEventData(response, state, timestamp),
      };
    }
    case ERROR_LIVE_EVENT:
      return { ...state, status: 'error', ...errorHandling(state) };
    case UPDATE_LIVE_FEED:
      return { ...state, ...updateFeedListing(state) };
    case IS_IN_VIEW_PORT:
      if (isInViewPort !== state.isInViewPort) {
        return { ...state, ...updateOnScroll(isInViewPort, state) };
      }
    default:
      return state;
  }
};
const updateOnScroll = (isInView, state) => {
  let newState = {};
  newState.isInViewPort = isInView;
  if (isInView && state.updatedEventList && state.updatedEventList.length > 0) {
    newState.LiveEventResponse = state.updatedEventList;
    newState.notificationCount = 0;
    newState.updatedEventList = [];
  }
  return newState;
};

const updateFeedListing = (state) => {
  let newState = {};
  newState.LiveEventResponse =
    state.updatedEventList.length > 0 ? state.updatedEventList : state.LiveEventResponse;
  newState.notificationCount = 0;
  newState.updatedEventList = [];
  newState = { ...state, ...newState };
  return newState;
};

const errorHandling = (state) => {
  let newState = {};
  newState.reTry = true;
  newState.pageLoad = false;
  newState.loader = false;
  newState = { ...state, ...newState };
  return newState;
};

const getHeroBanner = (data) => {
  let newState = {};
  if (data && data.LiveEventResponse && checkResponseDocs(data.LiveEventResponse)) {
    const heroItem = data.LiveEventResponse.response.docs.find(
      (item) => item.contenttype_t === 'eventmaster'
    );
    if (heroItem) {
      newState.HeroItem = heroItem;
    }
  }
  return newState;
};

/**
 *
 * @param {return api response after updating the values according to component requirement} response
 */
const filterLiveEventData = (response, state, timestamp) => {
  if (response && response.data) {
    let newState = {};
    newState = { ...newState, ...getHeroBanner(response.data) };
    newState = { ...newState, ...updateHighlights(response.data) };
    newState = { ...newState, ...updateListing(response.data, state, timestamp) };
    return newState;
  }
};

/**
 *
 * @param {response api data} data
 * @param {global state which we will return after updating the highlight response} newState
 */

const updateHighlights = (data) => {
  let newState = {};
  if (data && data.HighlightResponse && checkResponseDocs(data.HighlightResponse)) {
    let responseList = data.HighlightResponse.response.docs;
    newState.HighlightResponse = uniqBy(responseList, 'itemid_s');
  }
  return newState;
};

/**
 *
 * @param {response api data} data
 * @param {global state which we will return after updating the Listing response} newState
 */
const updateListing = (data, state, timestamp) => {
  let newState = {};
  if (data && data.LiveEventResponse && checkResponseDocs(data.LiveEventResponse)) {
    let responseList = data.LiveEventResponse.response.docs;
    newState.reTry = false;
    if (state.LiveEventResponse === null) {
      newState.updatedEventList = responseList;
      newState.LiveEventResponse = uniqBy(responseList, 'itemid_s');
      newState.lastCardTime = responseList[responseList.length - 1].appearance_tdt;
      newState.displayLoadMore = responseList.length < 30 ? false : true;
    } else if (timestamp === 'all') {
      var matchedIndex = findLastIndex(state.LiveEventResponse, {
        itemid_s: responseList[responseList.length - 1].itemid_s,
      });
      if (matchedIndex !== -1) {
        newState.notificationCount = differenceWith(
          responseList,
          state.LiveEventResponse,
          function (obj1, obj2) {
            return obj1.itemid_s == obj2.itemid_s;
          }
        ).length;
        newState.updatedEventList = uniqBy(
          [...responseList, ...drop(state.LiveEventResponse, matchedIndex + 1)],
          'itemid_s'
        );
      }
      if (newState.notificationCount > 0 && state.isInViewPort) {
        newState.LiveEventResponse = newState.updatedEventList;
        newState.lastCardTime =
          newState.LiveEventResponse[state.LiveEventResponse.length - 1].appearance_tdt;
        newState.notificationCount = 0;
      }
    } else {
      newState.LiveEventResponse = uniqBy(
        [...state.LiveEventResponse, ...responseList],
        'itemid_s'
      );
      newState.lastCardTime = responseList[responseList.length - 1].appearance_tdt;
      newState.displayLoadMore = responseList.length < 30 ? false : true;
    }
  } else if (timestamp === 'loadmore') {
    newState.displayLoadMore = false;
  }
  return newState;
};
