/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState, useRef } from 'react';
import { insertAdobeScript } from '../../../lib/AdobeAnalyticsPlugin';
import { dialog } from './CookiePolicyAccessibility';
import { useSponsorValue } from '../../../context/sponsorContext';
import { getCookie, isApp } from '../../../utils/utils';
import { callBraze, initBrazePush } from '../../../utils/brazeUtil';
const CookieInformative = (props) => {
  const {
    ampHeadlineNonConsent,
    ampDescriptionNonConsent,
    ampButtonTextNonConsent,
    frequency,
    layoutId,
    context,
  } = props;
  const [state, setState] = useState({
    showCookiePolicy: false,
  });
  const [{ isUserLogin, userLoginDetail }, dispatch] = useSponsorValue();
  const aboutCookieMesgEl = useRef(null);
  const dialogEl = useRef(null);
  const focusedElBeforeOpen = useRef(null);
  const firstFocusable = useRef(null);
  const lastFocusable = useRef(null);
  const focusableList = useRef(null);
  const overlayEl = useRef(null);
  useEffect(() => {
    const userConsent_off = getCookie('userConsentCookiePolicy') === 'off';
    if (!userConsent_off) {
      insertAdobeScript(layoutId);
    }
    initInformativePopUp();
  }, []);
  const enableLegalConsent = () => {
    window.googletag && window.googletag.pubads && window.googletag.pubads().setCookieOptions(0);
    if (typeof window.Kameleoon != 'undefined' && typeof window.Kameleoon != undefined) {
      window.Kameleoon && window.Kameleoon.API.Core.enableLegalConsent();
    }
    return '';
  };
  const getWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  };
  const closeInformative = () => {
    const now = new Date();
    const exdays = frequency ? frequency : null;
    const days = exdays;
    setState((state) => ({
      ...state,
      showCookiePolicy: false,
    }));
    const item = {
      value: 'yes',
      expiry: now.getTime() + days * 24 * 60 * 60 * 1000,
    };
    localStorage.setItem('infoAgree', JSON.stringify(item));
    if (!isApp()) {
      const notification = context?.Notification;
      if (isUserLogin && userLoginDetail) {
        callBraze(userLoginDetail, notification);
      } else {
        initBrazePush(notification);
      }
    }
  };
  const initInformativePopUp = () => {
    const infoConsent = getWithExpiry('infoAgree');
    if (!infoConsent) {
      setState((state) => ({
        ...state,
        showCookiePolicy: true,
      }));
      enableLegalConsent();
      setTimeout(() => {
        dialog(
          setState,
          undefined,
          overlayEl,
          dialogEl,
          focusableList,
          firstFocusable,
          lastFocusable,
          focusedElBeforeOpen
        );
        aboutCookieMesgEl && aboutCookieMesgEl.current && aboutCookieMesgEl.current.focus();
      }, 500);
    }
  };
  const handleAccessibility = (e, type) => {
    const value = e ? e.keyCode || e.which : null;
    if (type === 'OKbutton' && value && value === 9 && !e.shiftKey && dialogEl) {
      e.preventDefault();
      setTimeout(() => {
        const a =
          dialogEl.current &&
          dialogEl.current.querySelector('#infoMesg.cookie-message__content_infoMesg_class');
        a && a.focus();
      }, 100);
    } else if (type === 'H2heading' && value && value === 9 && e.shiftKey) {
      e.preventDefault();
      setTimeout(() => {
        const a =
          dialogEl.current && dialogEl.current.querySelector('.cookie-buttons .black-btn-skew.btn');
        a && a.focus();
      }, 100);
    }
  };
  return (
    <React.Fragment>
      {state.showCookiePolicy && (
        <div
          className="cookie-informative-message"
          role="dialog"
          aria-labelledby="infoMesg"
          style={{ display: 'block' }}
          ref={dialogEl}
        >
          <div className="cookie-message__content">
            {ampHeadlineNonConsent && (
              <h2
                id="infoMesg"
                tabIndex="0"
                ref={aboutCookieMesgEl}
                className="cookie-message__content_infoMesg_class"
                onKeyDown={(e) => handleAccessibility(e, 'H2heading')}
              >
                {ampHeadlineNonConsent}
              </h2>
            )}
            {ampDescriptionNonConsent && (
              <p dangerouslySetInnerHTML={{ __html: ampDescriptionNonConsent }}></p>
            )}
          </div>
          <div className="cookie-buttons">
            {ampButtonTextNonConsent && (
              <button
                className="black-btn-skew btn"
                id="accept-btn"
                onClick={closeInformative}
                onKeyDown={(e) => handleAccessibility(e, 'OKbutton')}
              >
                <span>{ampButtonTextNonConsent}</span>
              </button>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default React.memo(CookieInformative);
