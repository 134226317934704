/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { createSearchUrl } from '../../utils/utils';
import { SEARCH_SUGGESTION } from '../../constants/endpoints';
import { MIN_CHARACTER, RISTRICTED_KEYS, SEARCH_THRESHOLD } from '../../constants/globalConstants';
import { searchI18n } from '../../constants/i18nConstants';
import api from '../../utils/muApi';
import xss from 'xss';
const AutoSearch = (props) => {
  const {
    t,
    updateHeaderStyle,
    searchButton,
    placeholder,
    ariaLabel,
    mouseLeave,
    inputId,
    getValueOnChange,
    isSearchPage,
    searchText,
    initial,
    onSearch,
  } = props;
  const [result, setResult] = useState([]);
  const [ariaDis, setAriaDis] = useState('true');
  const [sugStyle, setSugStyle] = useState({});
  const [selected, setSelected] = useState(-1);
  const [sugItemLen, setSugItemLen] = useState(`0 ${searchI18n.suggestResultCount}`);
  const timer = useRef(0);
  useEffect(() => {
    mouseLeave && resetResults();
  }, [mouseLeave]);

  const resetResults = () => {
    setSugStyle({ display: 'none' });
    setResult([]);
    setSelected(-1);
  };
  const changeValOnArrow = (keycode, e) => {
    let keyVal;
    // down key
    if (keycode === 40) {
      if (selected < result.length - 1) keyVal = selected + 1;
      else if (selected === result.length - 1) keyVal = 0;
    } else {
      // up key
      if (selected > 0) keyVal = selected - 1;
      else if (selected === 0) keyVal = result.length - 1;
    }
    setSelected(keyVal);
    if (keyVal > -1 && e && e.target && e.target.value && result && result[keyVal])
      e.target.value = result[keyVal].suggestText;
  };
  const searchUrl = (value) => {
    if (isSearchPage) {
      resetResults();
      onSearch(value);
    } else {
      window.location.href = xss(createSearchUrl(value));
    }
  };
  const searchOnClick = (e) => {
    e && e.preventDefault();
    let inputElm = document.getElementById('inp');
    if (inputElm && inputElm.value && inputElm.value.trim() !== '') searchUrl(inputElm.value);
  };
  const renderSuggestedResult = (data) => {
    let docs =
      data &&
      data.SuggestResponse &&
      data.SuggestResponse.response &&
      data.SuggestResponse.response.docs &&
      data.SuggestResponse.response.docs.length > 0 &&
      data.SuggestResponse.response.docs;
    docs && setResult(docs);
    docs && setSugStyle({ display: 'block' });
    docs && updateHeaderStyle && updateHeaderStyle(false);
    docs && setSugItemLen(`${docs.length} ${searchI18n.suggestResultCount}`);
    setTimeout(() => {
      setSugItemLen('');
    }, 300);
    let spellCheck = data && data.SuggestResponse && data.SuggestResponse.spellcheck;
    let correctlySpelled = spellCheck && spellCheck.correctlySpelled;
    if (correctlySpelled === false) {
      try {
        let collationValue =
          spellCheck.collations && spellCheck.collations.length > 1 && spellCheck.collations[1];
        if (collationValue) fetchSuggestedResult(collationValue);
      } catch (e) {
        console.error('Error getting collation', e);
      }
    }
  };
  const fetchSuggestedResult = (value) => {
    resetResults();
    const route = `${SEARCH_SUGGESTION}/?q=${value}`;
    api
      .get({ route })
      .then((response) => {
        response && response.data && renderSuggestedResult(response.data);
      })
      .catch((error) => {
        setSugStyle({ display: 'none' });
        setResult([]);
        updateHeaderStyle && updateHeaderStyle(true);
      });
  };
  const onKeyUp = (e) => {
    let keycode = e.which || e.keyCode;
    if (RISTRICTED_KEYS.indexOf(keycode) != -1) {
      return false;
    }
    let value = e.target.value;
    if (keycode === 38 || keycode === 40) {
      changeValOnArrow(keycode, e);
      return false;
    }
    if (keycode === 13) {
      if (value !== 'undefined' && value !== '') searchUrl(value);
      return false;
    }
    if (value && value.length >= MIN_CHARACTER) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        fetchSuggestedResult(value);
        updateHeaderStyle && updateHeaderStyle(true);
        setAriaDis('false');
      }, SEARCH_THRESHOLD);
    } else if (value.length < MIN_CHARACTER) {
      updateHeaderStyle && updateHeaderStyle(true);
      resetResults();
      value.length === 0 ? setAriaDis('true') : setAriaDis('false');
    }
  };

  const onChange = (e) => {
    if (isSearchPage) {
      getValueOnChange(e);
    }
  };
  const populateSuggestions = () => {
    return result.map((res, key) => (
      <div
        key={key}
        className={`sm-suggestion options ${selected === key ? 'selected' : ''}`}
        onClick={() => searchUrl(res.suggestText)}
      >
        {res.suggestText}
      </div>
    ));
  };
  return (
    <React.Fragment>
      <div className="text-area">
        <input
          type="text"
          id={`${inputId}`}
          autoComplete="off"
          aria-label={t(ariaLabel)}
          placeholder={t(placeholder)}
          onKeyUp={(e) => onKeyUp(e)}
          onChange={(e) => onChange(e)}
          value={isSearchPage ? (initial ? searchText : null) : null}
        />
      </div>
      {searchButton && (
        <div className="sp-buttons">
          <div className="white-btn-skew">
            <a href="#" onClick={(e) => searchOnClick(e)} aria-disabled={ariaDis} role="button">
              <span>{t('Search')}</span>
            </a>
          </div>
        </div>
      )}
      <div
        className="search-menu"
        id={isSearchPage ? 'search-page-menu-suggestions' : 'search-menu-suggestions'}
        style={sugStyle}
      >
        {result && result.length > 0 && (
          <div className="sm-suggestion suggested-row">{t('Suggested')}...</div>
        )}
        {populateSuggestions()}
      </div>
      <div className="screenreader" aria-live="polite" aria-atomic="true">
        <div className="suggested-items-count">{sugItemLen}</div>
      </div>
    </React.Fragment>
  );
};
AutoSearch.defaultProps = {
  updateHeaderStyle: false,
  searchButton: false,
  ariaLabel: 'Search Edit box',
  mouseLeave: false,
  inputId: 'inp',
  getValueOnChange: () => {},
};
export default withTranslation()(AutoSearch);
