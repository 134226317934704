/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import FirstTeam from './FirstTeam';
import Academy from './Academy';
import Reserves from './Reserves';
import Legends from './Legends';
export const PlayerProfileCommon = (fields, itemId) => {
  const { manutds } = fields;
  let ProfileTemplate;
  const playerProfileMapping = {
    'first team': FirstTeam,
    'women team': Academy,
    academy: Academy,
    reserves: Reserves,
    legends: Legends,
  };
  const { 'Team Level': teamLevel } = manutds && manutds.value && manutds.value.Tags;
  const profileKey =
    teamLevel && teamLevel.length > 0 && teamLevel[0].Name && teamLevel[0].Name.toLowerCase();
  ProfileTemplate = profileKey && playerProfileMapping[profileKey];
  return ProfileTemplate ? <ProfileTemplate fields={fields} itemId={itemId} /> : null;
};
export const getPlayerImg = (temp, ProfileImage) => {
  if (temp === 'class')
    return ProfileImage && ProfileImage.value && ProfileImage.value.hrefMuCustom
      ? ''
      : 'no-shirt-cta';
  else if (temp === 'bgImg')
    return ProfileImage && ProfileImage.value && ProfileImage.value.Preview
      ? ProfileImage.value.Preview
      : null;
};

export const maskButtonClass = (IsEnablePlayerHub, CTAUrlPlayerHub) => {
  if (
    IsEnablePlayerHub &&
    IsEnablePlayerHub.value &&
    CTAUrlPlayerHub &&
    CTAUrlPlayerHub.value &&
    CTAUrlPlayerHub.value.href
  ) {
    return 'mask-with-player-icon';
  }
  return '';
};
