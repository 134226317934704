/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import MU from '../../constants/muConstants';
import LanguageItems from './LanguageItems';
const LanguageDropDown = ({ allLanguage }) => {
  const [dropdownState, setdropdownstate] = useState(false);
  /**
   * Show dropdown based on boolean value
   */
  const showDropDown = () => {
    const toggleValue = !dropdownState;
    setdropdownstate(toggleValue);
    let scrolledElm = document.getElementById('scrollmenu');
    if (scrolledElm) {
      setTimeout(() => {
        scrolledElm.scroll({ top: 1000, behavior: 'smooth' });
      }, 100);
    }
  };
  /**
   * return selected language name
   */
  const setSelectedLanguage = () => {
    let lang = MU && MU.language ? MU.language.toLowerCase() : 'en';
    let languageText = {
      en: 'English',
      zh: '中文',
      ja: '日本語',
      ko: '한국어',
      ar: 'العربية',
      es: 'Español',
      fr: 'Français',
    };
    return lang ? languageText[lang] : 'English';
  };
  const focusToHome = (e) => {
    let code = e && (e.keyCode || e.which);
    if (typeof window !== 'undefined' && window.outerWidth < 1200) {
      const isexpanded = document.querySelector('.language__dropdown a[aria-expanded="false"]');
      const firstChild = document.querySelector('.first-level-nav .nav-menu li a');
      if (e.shiftKey && code === 9) {
        e.preventDefault();
        const elm = document.querySelector('.first-level-nav .nav-menu');
        const lastChild = elm && elm.lastElementChild && elm.lastElementChild.querySelector('a');
        const smallLastChild =
          e.target &&
          e.target.parentElement &&
          e.target.parentElement.previousSibling &&
          e.target.parentElement.previousSibling.querySelectorAll('ul.nav-small-menu li a');
        if (smallLastChild) {
          const nodelist = [...smallLastChild];
          nodelist && nodelist[nodelist.length] && nodelist[nodelist.length - 1].focus();
        } else if (lastChild) {
          lastChild.focus();
        }
      } else if (code === 9) {
        if (firstChild && isexpanded) {
          e.preventDefault();
          firstChild.focus();
        }
      }
    }
  };

  return (
    <div className="language__dropdown">
      <a
        href="javascript:void(0)"
        className="expanded-false"
        aria-expanded={dropdownState ? 'true' : 'false'}
        onClick={() => showDropDown()}
        onKeyPress={(e) => focusToHome(e)}
      >
        <span className="selected__language">{setSelectedLanguage()}</span>
        <span className="icon-drop-down">
          <span className="screenreader">{` Expand or Collapse Website language`}</span>
        </span>
      </a>
      <ul className={`language__slector ${dropdownState && 'show'}`} role="listbox">
        {allLanguage &&
          allLanguage.length > 0 &&
          allLanguage
            .filter((val) => val.fields && val.fields.Iso && val.fields.Iso.value !== MU.language)
            .map(
              (item, i) =>
                item.fields &&
                item.fields.Iso &&
                item.fields.Iso.value && (
                  <LanguageItems
                    key={i}
                    languageItem={item}
                    isHumbergerLang={true}
                    humbergerLangIndex={i}
                    totalLength={allLanguage.length - 1}
                  />
                )
            )}
      </ul>
    </div>
  );
};

export default withTranslation()(React.memo(LanguageDropDown));
