/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useRef } from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import LazyLoad from 'react-lazyload';
import { withTranslation } from 'react-i18next';
import ImgCrop from '../../Common/ImgCrop';
import { getMatchDate } from '../../../utils/momentDate';
import * as track from '../../../utils/analytics';
import Accordion from '../Accordion';
import { getRandomId } from '../../../utils/utils';
const TicketsAndEvents = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const tabList = useRef(null);
  const { HeroImage, Heading, TicketBlockMatchData, TicketBlockSideNavItems } = props.fields;
  const homeTeam =
    TicketBlockMatchData && TicketBlockMatchData.HomeTeamShortName
      ? TicketBlockMatchData.HomeTeamShortName
      : TicketBlockMatchData.HomeTeamName;
  const awayTeam =
    TicketBlockMatchData && TicketBlockMatchData.AwayTeamShortName
      ? TicketBlockMatchData.AwayTeamShortName
      : TicketBlockMatchData.AwayTeamName;
  const randomId = getRandomId();
  const trackLink = (btnName, e) => {
    const data = track.data('link');
    track.analytics(
      {
        ...data,
        button_name: btnName,
      },
      e
    );
  };
  const selectedTab = (e, indexNum, btnName) => {
    e.preventDefault();
    setActiveTab(indexNum);
    trackLink(btnName, e);
    if (tabList.current) {
      const tabElement = tabList.current;
      const linkElemnt = e.target.closest('li');
      if (linkElemnt) {
        tabElement.scrollLeft = linkElemnt.offsetLeft;
      }
    }
  };
  return (
    <>
      {HeroImage && HeroImage.value && HeroImage.value.Crops && (
        <div className="card-large">
          <div className="img-holder">
            <LazyLoad once={true} offset={100}>
              <ImgCrop
                singleSrc={false}
                imgCrop={HeroImage.value.Crops}
                alt={HeroImage.AltText}
                title={HeroImage.AltText}
                isWebpAvailable={HeroImage.value.isWebpAvailable}
                adaptiveClass="adaptive"
              ></ImgCrop>
            </LazyLoad>
            {Heading && Heading.value && (
              <h2 className="hero-heading">
                <Text field={Heading} />
              </h2>
            )}
          </div>
        </div>
      )}
      <div className="container ticketsEvents" data-container="ticketblockmodel">
        <section className="tickets__content">
          <div className="tickets__block" data-speed="250">
            <div className="tickets__team--crestimage" aria-hidden="true">
              <img src={TicketBlockMatchData.HomeTeamCropUrl} alt="" width="66" height="66" />
              <img src={TicketBlockMatchData.AwayTeamCropUrl} alt="" width="66" height="66" />
            </div>
            {homeTeam && awayTeam && (
              <h2 className="tickets__team--name">{`${homeTeam} Vs ${awayTeam}`}</h2>
            )}
            {TicketBlockMatchData.IsMatchDateTBC && !TicketBlockMatchData.IsResultDeclared && (
              <div className="tbc-text">{TicketBlockMatchData.MatchDateTBC}</div>
            )}
            <p className="tickets__team--summary">
              {TicketBlockMatchData.CompetitionName
                ? `${TicketBlockMatchData.CompetitionName}${
                    TicketBlockMatchData.Venue ? `, ${TicketBlockMatchData.Venue}` : ''
                  }`
                : `${TicketBlockMatchData.Venue ? TicketBlockMatchData.Venue : ''}`}
            </p>
            {TicketBlockMatchData.MatchDate &&
              homeTeam &&
              awayTeam &&
              TicketBlockMatchData.CompetitionName && (
                <p className="tickets__team-summary--time">
                  <span>{getMatchDate(TicketBlockMatchData.MatchDate, 'dddd D MMMM yyyy')}</span>
                  <span>{` ${getMatchDate(TicketBlockMatchData.MatchDate, 'HH:mm')}`}</span>
                </p>
              )}
            <Accordion {...props} isTicketsAndEvents={true} isOldUi={true}>
              <div className="accordian__content" style={{ display: 'block' }}>
                <div className="focus-holder screenreader" tabIndex="0">
                  {props.t('MoreInfo')}
                </div>
                <div data-module="navTabs">
                  <div className="tickets__side--nav">
                    <ul role="tablist">
                      {TicketBlockSideNavItems &&
                        TicketBlockSideNavItems.length > 0 &&
                        TicketBlockSideNavItems.map((childItem, index) => (
                          <li role="tab" aria-expanded="true" key={index}>
                            <a
                              style={{ cursor: 'pointer' }}
                              href="#"
                              id={`${randomId}-tab-${index}`}
                              className={index === activeTab ? 'active' : ''}
                              onClick={(e) => selectedTab(e, index, childItem.NavTitle)}
                              aria-label={childItem.NavTitle}
                            >
                              <span aria-hidden="true">{childItem.NavTitle}</span>
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className="grid-12 tab-content">
                    <div className="tickets__side--nav touch__swipe">
                      <div className="touch__swipe--container">
                        <ul className="touch__swipe-items" role="tablist" ref={tabList}>
                          {TicketBlockSideNavItems &&
                            TicketBlockSideNavItems.length > 0 &&
                            TicketBlockSideNavItems.map((childItem, index) => (
                              <li
                                key={index}
                                className="touch__swipe-item"
                                role="tab"
                                aria-expanded="true"
                              >
                                <a
                                  href="#"
                                  onClick={(e) => selectedTab(e, index, childItem.NavTitle)}
                                  className={index === activeTab ? 'active' : ''}
                                  aria-label={childItem.NavTitle}
                                >
                                  <span aria-hidden="true">{childItem.NavTitle}</span>
                                </a>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                    {TicketBlockSideNavItems &&
                      TicketBlockSideNavItems.length > 0 &&
                      TicketBlockSideNavItems.map((childItem, index) => (
                        <div
                          className={index === activeTab ? 'tab-pane active' : 'tab-pane'}
                          id={`${randomId}-tab_${index}`}
                          role="tabpanel"
                          key={index}
                        >
                          <RichText field={{ value: childItem.Description }} />
                          <div className="align-center tickets__cta">
                            {childItem.PrimaryCTAURL !== null &&
                              childItem.PrimaryCTATitle !== null && (
                                <div className="red-btn-skew">
                                  <a
                                    href={childItem.PrimaryCTAURL.Url}
                                    rel="noreferrer"
                                    target="_blank"
                                    role="button"
                                  >
                                    <span>{childItem.PrimaryCTATitle}</span>
                                    <i className="icon-follow-live"></i>
                                  </a>
                                </div>
                              )}
                            {childItem.SecondaryCTATitle !== null &&
                              childItem.SecondaryCTAURL !== null && (
                                <div className="white-btn-skew">
                                  <a
                                    href={childItem.SecondaryCTAURL.Url}
                                    rel="noreferrer"
                                    target="_blank"
                                    role="button"
                                  >
                                    <span>{childItem.SecondaryCTATitle}</span>
                                    <i className="tag-red-arrow"></i>
                                  </a>
                                </div>
                              )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </Accordion>
          </div>
        </section>
      </div>
    </>
  );
};
export default withTranslation()(TicketsAndEvents);
