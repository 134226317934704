/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import ContextualCards from './ContextualCards';
import { SignedIn, SignedOut, Both } from '../../constants/globalConstants';
import { getfunction } from '../../utils/gigyaScreen';
import * as track from '../../utils/analytics';
import MoreMenu from './MoreMenu';
import MU from '../../constants/muConstants';
import { tabbable } from '../../utils/tabbable';
import { useSponsorValue } from '../../context/sponsorContext';
import ErrorBoundary from '../Common/ErrorBoundary';
import SubMenuMore from './SubMenuMore';

const SubMenu = ({
  subMenu,
  navconstant,
  toggleMoreMenuAnchor,
  onMenuHover,
  myUnitedSettingItemChildrens,
  isSetMoreMenuActive,
  setActiveMenuIndex,
  menuIndex,
  activeMenuIndex,
  moreAlternateHero,
  showContextualCard,
}) => {
  const [showMoreMenuActive, setShowMoreMenuActive] = useState(false);
  const [{ isUserLogin }] = useSponsorValue();
  const wrapClass = {
    ulClass:
      subMenu && subMenu.IsMoreMenu ? 'sub-nav-group' : 'home-header__sub-data sub-nav-group', //grid-3-column
    liClass: subMenu && subMenu.IsMoreMenu ? 'more-menu__heading' : `no-child  nav-item`,
    mainClass:
      subMenu && subMenu.IsMoreMenu
        ? 'home-header__menu-list more-menu sub-nav'
        : `home-header__menu-list sub-nav ${
            subMenu.Id === navconstant.MyUnitedItemId ? 'my-united' : ''
          }`,
  };
  let loginclass = '';
  /**
   *
   * checks either given contextId/contextParentId is equal to Id of parentMenu/ChildMenu. Except more more has there own cecks
   */
  useEffect(() => {
    if (subMenu.Id == MU.contextItemId || subMenu.Id == MU.contextItemParentId) {
      isSetMoreMenuActive(true);
    } else if (
      subMenu.Childrens &&
      subMenu.Childrens.length > 0 &&
      subMenu.Childrens.find(
        (val) => val.Id == MU.contextItemId || val.Id == MU.contextItemParentId
      )
    ) {
      isSetMoreMenuActive(true);
    }
  }, []);
  let heroImage = false;
  /**
   *
   * @param {each item data to track link analytics} item
   * @param {clicked event object} e
   */
  const trackLink = (item, e, unitedlink) => {
    const initialData = track.data('link');
    if (item) {
      track.analytics(
        {
          ...initialData,
          button_name: item && item.ShortName,
          destination_url: item && item.TargetURL,
        },
        e
      );
    } else if (unitedlink) {
      track.analytics(
        {
          ...initialData,
          button_name: unitedlink && unitedlink.Text,
          destination_url:
            unitedlink &&
            unitedlink.link &&
            unitedlink.link.value &&
            unitedlink.link.value.hrefMuCustom,
        },
        e
      );
    }
  };

  /**
   *
   * @param {responsible for adding classname login/logout in myunited panel} subChild
   */
  const setMyUnitedLoginClass = (subChild) => {
    if (subMenu && subMenu.Childrens && navconstant && navconstant.MyUnitedItemId === subMenu.Id) {
      if (subChild && subChild.ShowIn && subChild.ShowIn.toLowerCase() === SignedIn) {
        loginclass = 'login';
      } else if (subChild && subChild.ShowIn && subChild.ShowIn.toLowerCase() === SignedOut) {
        loginclass = 'logout';
      } else if (subChild && subChild.ShowIn && subChild.ShowIn.toLowerCase() === Both) {
        loginclass = '';
      }
    }
    return loginclass;
  };
  const onMoreMenuClick = (toggleValue) => {
    setShowMoreMenuActive(toggleValue);
  };
  /**
   * Render myunited extra menu which is not avalible in Myunited submenu(adding login link..)
   */
  const renderMyunitedExtraSubmenu = () => {
    if (subMenu && navconstant) {
      if (subMenu.Id === navconstant.MyUnitedItemId) {
        if (myUnitedSettingItemChildrens && myUnitedSettingItemChildrens.length > 0) {
          return (
            <li className="my-united__login-link">
              {mapMyunitedExtaraMenu(myUnitedSettingItemChildrens)}
            </li>
          );
        }
      }
    }
  };
  /**
   *
   * @param {getting showIn value inside data and depending upon it set classname } data
   */
  const getLoginChildClass = (data) => {
    let loginChildClass = data.showIn;
    if (data && data.showIn && data.showIn.toLowerCase() === SignedIn) {
      loginChildClass = 'login';
    } else if (data && data.showIn && data.showIn.toLowerCase() === SignedOut) {
      loginChildClass = 'logout';
    } else if (data && data.showIn && data.showIn.toLowerCase() === Both) {
      loginChildClass = '';
    }
    return loginChildClass;
  };

  /**
   *
   * @param {all data} data
   * @param {classname depend upon SIgnIn value which is coming from layout} classname
   * @param {boolans to check has link 'javascript:void(0)' in URL } javaScriptLink
   */
  const getSignedOutDom = (data, classname, javaScriptLink, index) => {
    if (javaScriptLink) {
      let functionName =
        data &&
        data.link &&
        data.link.value &&
        data.link.value.hrefMuCustom &&
        data.link.value.hrefMuCustom.replace('javascript:', '');
      return (
        <p className={`home-header__login-link ${classname}`} key={index}>
          <a
            href="javascript:void(0)"
            onClick={(e) => callFunctionByNameOrHref(functionName, null, e, data)}
          >
            <span>
              {data && data.Text}
              <strong>{data && data.title}</strong>
            </span>
          </a>
        </p>
      );
    } else {
      let myunited = navconstant && navconstant.MyUnitedLoginHereItemId === data.id;
      return (
        <p className={`home-header__login-link ${classname}`} key={index}>
          <a
            href={data && data.link && data.link.value && data.link.value.hrefMuCustom}
            id={myunited && 'myUnitedLink'}
            target={
              data && data.link && data.link.value && data.link.value.Target
                ? data.link.value.Target
                : ''
            }
            onClick={(e) => trackLink(null, e, data)}
          >
            <span>
              {data && data.Text}
              <strong>{data && data.title}</strong>
            </span>
          </a>
        </p>
      );
    }
  };

  /**
   *
   * @param {all data} data
   * @param {classname depend upon SIgnIn value which is coming from layout} classname
   * @param {boolans to check has link 'javascript:void(0)' in URL } javaScriptLink
   */
  const getSignedInDom = (data, classname, javaScriptLink, index) => {
    if (javaScriptLink) {
      let functionName =
        data &&
        data.link &&
        data.link.value &&
        data.link.value.hrefMuCustom &&
        data.link.value.hrefMuCustom.replace('javascript:', '');
      return (
        <div className={`white-btn-skew ${classname}`} key={index}>
          <a
            href="javascript:void(0)"
            onClick={(e) => callFunctionByNameOrHref(functionName, null, e, data)}
          >
            <span>
              {data && data.Text}
              <i className="tag-red-arrow"></i>
            </span>
          </a>
        </div>
      );
    } else {
      return (
        <div className={`white-btn-skew ${classname}`} key={index}>
          <a
            href={data && data.link && data.link.value && data.link.value.hrefMuCustom}
            target={
              data && data.link && data.link.value && data.link.value.Target
                ? data.link.value.Target
                : ''
            }
            onClick={(e) => trackLink(null, e, data)}
          >
            <span>
              {data && data.Text}
              <i className="tag-red-arrow"></i>
            </span>
          </a>
        </div>
      );
    }
  };
  /**
   *
   * @param {return boolean value after checking link has text 'javascript:void(0)' in URL } data
   */
  const isJavascriptLink = (data) => {
    return (
      data &&
      data.link &&
      data.link.value &&
      data.link.value.hrefMuCustom &&
      data.link.value.hrefMuCustom.includes('javascript:')
    );
  };

  /**
   *
   * @param {rendering additional menus} additionalChild
   */
  const mapMyunitedExtaraMenu = (additionalChild) => {
    return additionalChild.map((data, index) => {
      if (data && !data.ishidden && data.showIn) {
        let loginChildClass = getLoginChildClass(data);
        let javaScriptLink = isJavascriptLink(data);

        if (data.showIn.toLowerCase() === SignedOut || data.showIn.toLowerCase() === Both) {
          return getSignedOutDom(data, loginChildClass, javaScriptLink, index);
        }
        if (data.showIn.toLowerCase() === SignedIn || data.showIn.toLowerCase() === Both) {
          return getSignedInDom(data, loginChildClass, javaScriptLink, index);
        }
      }
    });
  };

  /**
   * return boolean after checking hero image is avalible in any submenu of MORE menu or not
   */
  const isHeroImageAvalible = () => {
    if (subMenu && subMenu.Childrens && subMenu.Childrens.length > 0 && !heroImage) {
      for (let i = 0; i < subMenu.Childrens.length; i++) {
        if (subMenu.Childrens[i].CardImage) {
          heroImage = true;
        }
      }
    }
    return heroImage;
  };
  /**
   *
   * @param {it contains myunited function name which came in href with javascript:funname} functionName
   * @param {children data to track} subChild
   * @param {clicked event object} e
   */
  const callFunctionByNameOrHref = (functionName, subChild, e, data) => {
    if (functionName) {
      getfunction(functionName);
    }
    trackLink(subChild, e, data);
  };

  /**
   *
   * @param {children data to track to make anchor link} subChild
   * returns anchor with function(myunited function) name or hrefLink
   */
  const getAnchorLinks = (subChild, i, totalLength) => {
    if (subChild && subChild.TargetURL) {
      let ismyUnitedJavascriptLink = subChild.TargetURL.includes('javascript:');
      if (ismyUnitedJavascriptLink) {
        let functionName = subChild.TargetURL.replace('javascript:', '');
        return (
          <a
            href="javascript:void(0)"
            onClick={(e) => callFunctionByNameOrHref(functionName, subChild, e)}
          >
            {subChild.Name}
          </a>
        );
      } else {
        return (
          <a
            href={subChild.TargetURL && subChild.TargetURL.toLowerCase()}
            target={subChild.TargetURLTarget ? subChild.TargetURLTarget : ''}
            onClick={(e) => trackLink(subChild, e)}
          >
            {subChild.Name}
          </a>
        );
      }
    }
  };

  const handleAccessibility = (e) => {
    if (typeof window !== 'undefined' && window.outerWidth < 1200) {
      setFocusToBack(e); //mobile Accessibility
    } else {
      let rootNode = e.target.closest('.home-header__menu-list.sub-nav');
      let tabbableArr = rootNode && tabbable(rootNode);
      let activeElm = document.activeElement;
      let currentIndex = tabbableArr && activeElm && tabbableArr.indexOf(activeElm);
      let code = e && (e.keyCode || e.which);
      const Keyboard = { UP: 38, DOWN: 40, ESCAPE: 27, TAB: 9 };

      if (code === Keyboard.UP) {
        e.preventDefault();
        if (currentIndex > 0) {
          tabbableArr && tabbableArr[currentIndex - 1] && tabbableArr[currentIndex - 1].focus();
        } else if (currentIndex === 0) {
          const activeElm = document.activeElement;
          const activeMenu = activeElm && activeElm.closest('.parent.nav-item');
          activeMenu && activeMenu.querySelector('a') && activeMenu.querySelector('a').focus();
        }
      } else if (code === Keyboard.DOWN) {
        e.preventDefault();
        if (currentIndex !== tabbableArr.length - 1) {
          tabbableArr && tabbableArr[currentIndex + 1] && tabbableArr[currentIndex + 1].focus();
        } else {
          if (rootNode && rootNode.parentElement) {
            rootNode.parentElement.nextSibling &&
              rootNode.parentElement.nextSibling.querySelector('a').focus();
            setActiveMenuIndex(menuIndex + 1);
            onMenuHover(e, false);
          }
        }
      }
      if (code === Keyboard.ESCAPE) {
        setActiveMenuIndex(null);
        onMenuHover(e, false);
        const activeElm = document.activeElement;
        const activeMenu = activeElm && activeElm.closest('.parent.nav-item');
        activeMenu && activeMenu.querySelector('a') && activeMenu.querySelector('a').focus();
      }
      if (code === Keyboard.TAB) {
        if (currentIndex === tabbableArr.length - 1) {
          setActiveMenuIndex(menuIndex + 1);
          onMenuHover(e, false);
        }
      }
    }
  };
  const setFocusToBack = (e) => {
    if (e && e.target && e.target.closest('.home-header__sub-data')) {
      const items = [
        ...e.target.closest('.home-header__sub-data').querySelectorAll('li:not(.login)'),
      ];
      const activeElm = document.activeElement && document.activeElement.closest('li');
      const activeIndex =
        items && items.length && activeElm && items.indexOf(document.activeElement.closest('li'));
      if (activeIndex === items.length - 1) {
        e.preventDefault();
        items[0].querySelector('a').focus();
      }
    }
  };
  const updateAttributes = () => {
    if (typeof window !== 'undefined' && window.outerWidth > 1200) {
      return {
        'aria-hidden': activeMenuIndex !== menuIndex ? 'true' : 'false',
      };
    }
  };
  const renderInnerItems = () => {
    return (
      <div className="grid-11">
        <SubMenuMore ulClass={wrapClass.ulClass} isMoreMenu={subMenu.IsMoreMenu}>
          {subMenu &&
            subMenu.Childrens &&
            subMenu.Childrens.length > 0 &&
            subMenu.Childrens.map((subChild, i) => (
              <React.Fragment key={i}>
                {/* This condition is to show main menu(ex: News) in submenu so that user can also click it in humbergermenu} */}
                {i === 0 && !subMenu.IsMoreMenu && (
                  <li className={`${wrapClass.liClass}`}>
                    <a
                      href={subMenu.TargetURL && subMenu.TargetURL.toLowerCase()}
                      target={subMenu.TargetURLTarget ? subMenu.TargetURLTarget : ''}
                      onClick={(e) => trackLink(subMenu, e)}
                    >
                      {subMenu.ShortName}
                    </a>
                  </li>
                )}

                {/* Condition to show all sub menus(ex: Latest,women...) in submenu */}
                {!subMenu.IsMoreMenu && subChild.ShortName && (
                  <li className={`${wrapClass.liClass} ${setMyUnitedLoginClass(subChild)}`}>
                    {getAnchorLinks(subChild, i, subMenu.Childrens.length)}
                  </li>
                )}

                {/* below condition is only for More Menu submeus rendering */}
                {subMenu.IsMoreMenu && subChild && subChild.Name && (
                  <ErrorBoundary>
                    <MoreMenu
                      subChild={subChild}
                      onMoreMenuClick={onMoreMenuClick}
                      toggleMoreMenuAnchor={toggleMoreMenuAnchor}
                      ishero={isHeroImageAvalible()}
                      isSetMoreMenuActive={isSetMoreMenuActive}
                      moreAlternateHero={moreAlternateHero}
                    ></MoreMenu>
                  </ErrorBoundary>
                )}
              </React.Fragment>
            ))}
          {/* rendering extra myunited menus*/}
          {renderMyunitedExtraSubmenu()}
        </SubMenuMore>
        {subMenu && !subMenu.IsMoreMenu && showContextualCard && (
          <ContextualCards cards={subMenu} navconstant={navconstant}></ContextualCards>
        )}
      </div>
    );
  };
  return (
    <React.Fragment>
      <div
        className={`${wrapClass.mainClass} 
        ${showMoreMenuActive ? 'more-sub-menu-active' : ''} 
        ${isUserLogin ? 'user-login' : ''}`}
        onMouseEnter={(e) => onMenuHover(e, true)}
        onMouseLeave={(e) => onMenuHover(e, false)}
        onKeyDown={(e) => handleAccessibility(e)}
        role="group"
        id={subMenu.Id}
        aria-labelledby={`accessible-megamenu-${subMenu.Id}`}
        {...updateAttributes()}
        aria-expanded={activeMenuIndex === menuIndex ? 'true' : 'false'}
      >
        {subMenu && subMenu.IsMoreMenu ? (
          <div className="moreMenuWrapper">{renderInnerItems()}</div>
        ) : (
          renderInnerItems()
        )}
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(React.memo(SubMenu));
