/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { MyUnitedProvider, useMyUnitedValue } from '../../context/myUnitedContext';
import {
  fetchAllplayer,
  fetchWomensPlayer,
  fetchFavplayer,
  fetchOfficialAppData,
} from '../../actions/myUnitedAction';
import MU from '../../constants/muConstants';
import { getParameterByName, isTouchDevice, showSubscriptionCard } from '../../utils/utils';
import * as track from '../../utils/analytics';
import FavouritePlayer from './FavouritePlayer';
import Loader from '../Common/Loader';
import JerseyDetails from './JerseyDetails';
import UserInfo from './UserInfo';
import UserIntro from './UserIntro';
import { DFP } from '../Common/Dfp';
import UserDetails from './UserDetails';
import UserMenu from './UserMenu';
import UnitedPrediction from './UnitedPrediction';
import StoryForYou from './StoryForYou';
import SubscribeInfo from './SubscribeInfo';
import { redirectToSignIn } from '../../utils/gigyaScreen';
import { useSponsorValue } from '../../context/sponsorContext';
import { fetchUnitedConfigData } from '../../actions/myUnitedAction';
import { checkLocalCodeMissing, redirectToIDMLogin, createBaseUrl } from '../../utils/idmUtils';

const MyUnited = (props) => (
  <React.Fragment>
    <MyUnitedProvider>
      <MyUnitedContainer {...props}></MyUnitedContainer>
    </MyUnitedProvider>
  </React.Fragment>
);

const MyUnitedContainer = (props) => {
  const [
    {
      allPlayerData,
      responseData,
      womensPlayer,
      favPlayerData,
      favPlayerStatus,
      predictionData,
      unitedNowConfigStatus,
      unitedNowConfigData,
    },
    dispatch,
  ] = useMyUnitedValue();
  const { t, fields } = props;
  const [fanStatusObj, setfanStatusObj] = useState({});
  const [touchDevice, setTouchDevice] = useState(false);
  const [gigyaData, setgigyaData] = useState(null);
  const [jerseyNo, setJerseyNo] = useState(false);
  const [defaultSegmentRules, setDefaultSegmentRules] = useState(false);
  const [
    { isGigyaAvilable, userLoginDetail, isUserSubscribe },
    sponsorDispatch,
  ] = useSponsorValue();
  const ctaTitle = fields && fields.CTATitle && fields.CTATitle.value;
  const ctaUrl = fields && fields.CTAUrl && fields.CTAUrl.value && fields.CTAUrl.value.href;
  const EnableSegmentationRules =
    fields &&
    fields.EnableSegmentationRules &&
    fields.EnableSegmentationRules.EnableSegmentationRules &&
    fields.EnableSegmentationRules.EnableSegmentationRules;
  const MyUnitedManageMySubscription = fields && fields.MyUnitedManageMySubscription;
  const mufcNumber = gigyaData && gigyaData.data && gigyaData.data.mufcNumber;
  const MyUnitedFavPlayer = fields && fields.MyUnitedFavPlayer;
  const shirtImage = fields && fields.MyUnitedImage && fields.MyUnitedImage.value;
  const [state, setState] = useState({
    status: null,
    showLoader: false,
    message: '',
    error: '',
  });
  const [displayMembershipId, setDisplayMembershipId] = useState();
  useEffect(() => {
    if (unitedNowConfigStatus === 'fetching') {
      setState(() => ({
        ...state,
        showLoader: true,
        status: 'fetching',
      }));
    } else if (unitedNowConfigStatus === 'fetched') {
      setState(() => ({
        ...state,
        showLoader: false,
        status: 'fetched',
      }));

      if (unitedNowConfigData) {
        try {
          const fanStatusResponse =
            unitedNowConfigData &&
            unitedNowConfigData.FanStatusSegmentRulesResponse &&
            unitedNowConfigData.FanStatusSegmentRulesResponse.grouped &&
            unitedNowConfigData.FanStatusSegmentRulesResponse.grouped.segmentgroupname_s &&
            unitedNowConfigData.FanStatusSegmentRulesResponse.grouped.segmentgroupname_s.groups;
          let fanStatusNonDefaultSegmentsArray;
          let fanStatusDefaultSegmentsArray;
          if (fanStatusResponse && fanStatusResponse.length > 0) {
            const fanStatusNonDefaultResponse = fanStatusResponse.find(
              (o1) => o1.groupValue === 'nondefault'
            );
            if (fanStatusNonDefaultResponse) {
              fanStatusNonDefaultSegmentsArray =
                fanStatusNonDefaultResponse &&
                fanStatusNonDefaultResponse.doclist &&
                fanStatusNonDefaultResponse.doclist.docs;
            }
            const fanStatusDefaultResponse = fanStatusResponse.find(
              (o1) => o1.groupValue === 'default'
            );
            if (fanStatusDefaultResponse) {
              fanStatusDefaultSegmentsArray =
                fanStatusDefaultResponse &&
                fanStatusDefaultResponse.doclist &&
                fanStatusDefaultResponse.doclist.docs;
            }
          }

          const localLyticsSegmentsArray =
            localStorage.getItem('lytics_segments') &&
            JSON.parse(localStorage.getItem('lytics_segments'));
          if (
            !localLyticsSegmentsArray ||
            (localLyticsSegmentsArray && localLyticsSegmentsArray.length === 0) ||
            !fanStatusNonDefaultSegmentsArray ||
            (fanStatusNonDefaultSegmentsArray && fanStatusNonDefaultSegmentsArray.length === 0)
          ) {
            if (fanStatusDefaultSegmentsArray && fanStatusDefaultSegmentsArray.length > 0) {
              const defaultResObj = fanStatusDefaultSegmentsArray[0];
              defaultResObj && setfanStatusObj(defaultResObj);
            } else {
              setDefaultSegmentRules(true);
            }
          } else if (
            fanStatusNonDefaultSegmentsArray &&
            fanStatusNonDefaultSegmentsArray.length > 0 &&
            localLyticsSegmentsArray &&
            localLyticsSegmentsArray.length > 0
          ) {
            const nonDefaultResObj = fanStatusNonDefaultSegmentsArray.find((o1) =>
              localLyticsSegmentsArray.some((o2) => o1.slug_t === o2)
            );
            if (nonDefaultResObj) {
              setfanStatusObj(nonDefaultResObj);
              nonDefaultResObj &&
                nonDefaultResObj.displaymembershipnumber_b &&
                setDisplayMembershipId(nonDefaultResObj.displaymembershipnumber_b);
            } else if (fanStatusDefaultSegmentsArray && fanStatusDefaultSegmentsArray.length > 0) {
              const defaultResObj = fanStatusDefaultSegmentsArray[0];
              defaultResObj && setfanStatusObj(defaultResObj);
              defaultResObj &&
                defaultResObj.displaymembershipnumber_b &&
                setDisplayMembershipId(defaultResObj.displaymembershipnumber_b);
            } else {
              setDefaultSegmentRules(true);
            }
          }
        } catch (e) {
          console.error('Error in MyUnited segmentation');
        }
      }
    } else if (unitedNowConfigStatus === 'error') {
      setDefaultSegmentRules(true);
      setState(() => ({
        ...state,
        showLoader: false,
        status: 'error',
        message: t && t('APIFailed') ? t('APIFailed') : '',
      }));
    }
  }, [unitedNowConfigData, unitedNowConfigStatus]);

  useEffect(() => {
    if (MU.isIDMEnabled) {
      const code = getParameterByName('code', true);
      const error = getParameterByName('error', true);
      if (error && error === 'login_required') {
        window.location = createBaseUrl();
      } else if (checkLocalCodeMissing() && !code) {
        redirectToIDMLogin();
      }
    } else {
      redirectToSignIn();
    }
    setTouchDevice(isTouchDevice);
    if (isGigyaAvilable) {
      fetchAllplayer(dispatch);
      fetchWomensPlayer(dispatch);
    }
  }, [isGigyaAvilable]);

  useEffect(() => {
    if (isGigyaAvilable && userLoginDetail) {
      fetchUnitedConfigData(dispatch);
      renderProfileData();
    }
  }, [isGigyaAvilable, userLoginDetail]);

  const getDecoderUrl = (url) => {
    try {
      return url ? decodeURIComponent(url) : '';
    } catch (e) {}
  };
  const trackLink = (e, btnName, targetUrl) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: btnName,
        destination_url: targetUrl,
      },
      e
    );
  };

  const trackButton = (btnName, favPlayer) => {
    const initialData = track.data('button');
    track.analytics({
      ...initialData,
      button_name: btnName,
      player_data: favPlayer,
    });
  };

  const updateJersyNo = (jerseyNo) => {
    if (jerseyNo) {
      setJerseyNo(jerseyNo);
    } else {
      setJerseyNo('');
    }
  };

  const renderProfileData = () => {
    setgigyaData(userLoginDetail);
    setJerseyNo(
      userLoginDetail.data && userLoginDetail.data.jerseyNo ? userLoginDetail.data.jerseyNo : '00'
    );
    if (userLoginDetail && userLoginDetail.data && userLoginDetail.data.favoritePlayerID) {
      fetchFavplayer(dispatch, userLoginDetail.data.favoritePlayerID, 1, 15, sponsorDispatch);
    }
    OfficialAppData(userLoginDetail);
  };

  const OfficialAppData = (response) => {
    const seasonID =
      props &&
      props.fields &&
      props.fields.MyUnitedPrediction &&
      props.fields.MyUnitedPrediction.SeasonId;
    const gigyaUid = response.UID;
    fetchOfficialAppData(dispatch, gigyaUid, seasonID);
  };

  const getFavPlayerData = (data) => {
    if (
      data &&
      data.PlayerProfileResponse &&
      data.PlayerProfileResponse.response &&
      data.PlayerProfileResponse.response.docs &&
      data.PlayerProfileResponse.response.docs.length > 0
    ) {
      return data.PlayerProfileResponse.response.docs[0];
    } else {
      return null;
    }
  };

  const getCarousalData = (data) => {
    if (
      data &&
      data.ContentResponse &&
      data.ContentResponse.response &&
      data.ContentResponse.response.docs &&
      data.ContentResponse.response.docs.length > 0
    ) {
      return data.ContentResponse.response.docs;
    } else {
      return [];
    }
  };
  const getPrimaryBtnBackgroundColorCode = (color) => {
    if (color && color.toLowerCase() === 'red') {
      return '#c70101';
    } else if (color && color.toLowerCase() === 'black') {
      return '#1a1a1a';
    } else {
      return 'Transparent';
    }
  };

  const colorHexValue =
    !EnableSegmentationRules || defaultSegmentRules
      ? 'c70101'
      : fanStatusObj && fanStatusObj.hexvalue_t && fanStatusObj.hexvalue_t;
  const gradientHexValue =
    !EnableSegmentationRules || defaultSegmentRules
      ? 'c70101'
      : fanStatusObj && fanStatusObj.gradienthexvalue_t && fanStatusObj.gradienthexvalue_t;
  let myUnitedStyleObj = {
    backgroundColor: `linear-gradient(180deg, ${'#' + colorHexValue} 0%, ${
      '#' + gradientHexValue
    } 100%, #636363 100%,#636363 100%)`,
    textLabel: fanStatusObj && fanStatusObj.textlabel_t && fanStatusObj.textlabel_t,
    badgeIcon: fanStatusObj && fanStatusObj.imagecropurl_s && fanStatusObj.imagecropurl_s,
    primaryCta:
      !EnableSegmentationRules || defaultSegmentRules
        ? mufcNumber
          ? `${t('MatchTickets')}`
          : `${t('BuyOfficialMembership')}`
        : fanStatusObj &&
          fanStatusObj.primaryupsellctatitle_t &&
          fanStatusObj.primaryupsellctatitle_t,
    secondaryCta:
      !EnableSegmentationRules || defaultSegmentRules
        ? ctaTitle
        : fanStatusObj &&
          fanStatusObj.secondaryupsellctatitle_t &&
          fanStatusObj.secondaryupsellctatitle_t,
    primaryBtnBackgrounColor:
      !EnableSegmentationRules || defaultSegmentRules
        ? '#1a1a1a'
        : fanStatusObj &&
          fanStatusObj.ctacolor_t &&
          getPrimaryBtnBackgroundColorCode(fanStatusObj.ctacolor_t),
    primaryCtaUrl:
      !EnableSegmentationRules || defaultSegmentRules
        ? mufcNumber
          ? MU && MU.eticketingMyUnited
          : MU && MU.buyOfficialMembership
        : fanStatusObj && fanStatusObj.primaryupsellctaurl_s && fanStatusObj.primaryupsellctaurl_s,
    secondaryCtaUrl:
      !EnableSegmentationRules || defaultSegmentRules
        ? ctaUrl
        : fanStatusObj &&
          fanStatusObj.secondaryupsellctaurl_s &&
          fanStatusObj.secondaryupsellctaurl_s,
  };
  return (
    <div data-container="myunited">
      {gigyaData && gigyaData.data ? (
        <React.Fragment>
          {state.showLoader && <Loader />}
          <div className="united-profile clearfix">
            <div className="contentBox">
              <div className="united-profile__top-panel clearfix">
                <div className="profile">
                  <div
                    style={{
                      background: `${myUnitedStyleObj.backgroundColor}`,
                    }}
                    className="profile__wrapper"
                  >
                    <div className="container">
                      <div className="profile__details-left mobile-view">
                        <UserDetails
                          gigyaData={gigyaData}
                          myUnitedStyleObj={myUnitedStyleObj}
                          EnableSegmentationRules={EnableSegmentationRules}
                          displayMembershipId={displayMembershipId}
                          defaultSegmentRules={defaultSegmentRules}
                        ></UserDetails>
                        <div className="profile__details-left wide">
                          <UserMenu></UserMenu>
                        </div>
                      </div>
                      <div className="profile__details-left narrow">
                        <UserMenu></UserMenu>
                      </div>
                      <div className="wide">
                        <FavouritePlayer
                          allPlayer={allPlayerData}
                          mensTeamPlayer={responseData}
                          womensTeamPlayer={womensPlayer}
                          favPlayer={favPlayerData && getFavPlayerData(favPlayerData)}
                          favPlayerStatus={favPlayerStatus}
                          trackLink={trackLink}
                          trackButton={trackButton}
                          gigyaData={gigyaData}
                          toggleFavPlayer={MyUnitedFavPlayer}
                          myUnitedStyleObj={myUnitedStyleObj}
                          sponsorDispatch={sponsorDispatch}
                        ></FavouritePlayer>
                      </div>
                      <div className="shirt-mobile-view">
                        <JerseyDetails
                          gigyaData={gigyaData}
                          jerseyNo={jerseyNo}
                          myUnitedStyleObj={myUnitedStyleObj}
                          EnableSegmentationRules={EnableSegmentationRules}
                          defaultSegmentRules={defaultSegmentRules}
                          shirtImage={shirtImage}
                        ></JerseyDetails>
                      </div>
                      <React.Fragment>
                        <div className="btn-container">
                          {EnableSegmentationRules ? (
                            <div>
                              {defaultSegmentRules ? (
                                <>
                                  {mufcNumber ? (
                                    <span className="member__id">{mufcNumber}</span>
                                  ) : (
                                    ''
                                  )}
                                </>
                              ) : (
                                <>
                                  {displayMembershipId && mufcNumber && (
                                    <span className="member__id">{mufcNumber}</span>
                                  )}
                                </>
                              )}
                            </div>
                          ) : mufcNumber ? (
                            <div>
                              {mufcNumber && <span className="member__id">{mufcNumber}</span>}
                            </div>
                          ) : (
                            ''
                          )}
                          {myUnitedStyleObj && myUnitedStyleObj.primaryCta && (
                            <div
                              className={`black-btn-skew ${
                                myUnitedStyleObj &&
                                myUnitedStyleObj.primaryBtnBackgrounColor &&
                                myUnitedStyleObj.primaryBtnBackgrounColor === 'Transparent'
                                  ? 'btn-border'
                                  : ''
                              }`}
                              style={{
                                backgroundColor: `${myUnitedStyleObj.primaryBtnBackgrounColor}`,
                              }}
                            >
                              <a
                                href={getDecoderUrl(myUnitedStyleObj.primaryCtaUrl)}
                                role="button"
                                target="_blank"
                                onClick={(e) =>
                                  trackLink(
                                    e,
                                    'membership',
                                    myUnitedStyleObj && myUnitedStyleObj.primaryCtaUrl
                                  )
                                }
                              >
                                <span>{myUnitedStyleObj.primaryCta.substring(0, 23)}</span>
                              </a>
                            </div>
                          )}
                          {myUnitedStyleObj && myUnitedStyleObj.secondaryCta && (
                            <div className="white-btn-skew">
                              <a
                                href={getDecoderUrl(myUnitedStyleObj.secondaryCtaUrl)}
                                role="button"
                                tabIndex="0"
                                target="_blank"
                                onClick={(e) =>
                                  trackLink(
                                    e,
                                    'football-kits',
                                    myUnitedStyleObj && myUnitedStyleObj.secondaryCtaUrl
                                  )
                                }
                              >
                                <span>{myUnitedStyleObj.secondaryCta.substring(0, 23)}</span>
                              </a>
                            </div>
                          )}
                        </div>
                      </React.Fragment>
                    </div>
                    <div className="wide">
                      <JerseyDetails
                        gigyaData={gigyaData}
                        jerseyNo={jerseyNo}
                        myUnitedStyleObj={myUnitedStyleObj}
                        EnableSegmentationRules={EnableSegmentationRules}
                        defaultSegmentRules={defaultSegmentRules}
                        shirtImage={shirtImage}
                      ></JerseyDetails>
                    </div>
                  </div>
                </div>
              </div>
              <div className="united-profile__details">
                <div className="profile__wrapper">
                  <div className="contentBox">
                    <div className="profile__details united-left">
                      <UserIntro
                        gigyaData={gigyaData}
                        favPlayer={favPlayerData && getFavPlayerData(favPlayerData)}
                      ></UserIntro>
                      <div className="favourite-player only-small">
                        <FavouritePlayer
                          allPlayer={allPlayerData}
                          mensTeamPlayer={responseData}
                          womensTeamPlayer={womensPlayer}
                          favPlayer={favPlayerData && getFavPlayerData(favPlayerData)}
                          favPlayerStatus={favPlayerStatus}
                          trackLink={trackLink}
                          trackButton={trackButton}
                          gigyaData={gigyaData}
                          toggleFavPlayer={MyUnitedFavPlayer}
                          myUnitedStyleObj={myUnitedStyleObj}
                          sponsorDispatch={sponsorDispatch}
                        ></FavouritePlayer>
                      </div>
                      {MU.language !== 'zh' && (
                        <UnitedPrediction
                          myUnitedPrediction={
                            props && props.fields && props.fields.MyUnitedPrediction
                          }
                          item={predictionData}
                        />
                      )}
                      {MyUnitedManageMySubscription &&
                        MyUnitedManageMySubscription.EnableSubscriptionCards &&
                        MyUnitedManageMySubscription.CardDetails &&
                        showSubscriptionCard({ isUserSubscribe, userLoginDetail }) && (
                          <SubscribeInfo
                            subscribeDetail={MyUnitedManageMySubscription}
                          ></SubscribeInfo>
                        )}
                    </div>
                    {favPlayerData && (
                      <div className="united-row">
                        <div
                          className={`favourite-list ${
                            touchDevice ? 'hide-view' : 'show-slick-loaded'
                          }`}
                        >
                          <div className="favourite-list__buttons">
                            <h2>{t('StoriesForYou')}</h2>
                          </div>
                          <div className="favourite-list__card">
                            <StoryForYou
                              sliderData={favPlayerData && getCarousalData(favPlayerData)}
                              containerClassName={`card-wrapper playerSlick ${
                                touchDevice ? 'mobile-view' : 'show-slick-loaded'
                              }`}
                            ></StoryForYou>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row margin-bottom">
                      <UserInfo
                        gigyaData={gigyaData}
                        updateJersyNo={updateJersyNo}
                        trackLink={trackLink}
                        jersey={jerseyNo}
                        defaultSegmentRules={defaultSegmentRules}
                      ></UserInfo>
                    </div>
                  </div>
                </div>
                <div className="advertisment ad-bg">
                  <div className="container">
                    <div className="advertisment__block">
                      <div className="grid-12 ad-block">
                        <DFP
                          slot="/11820346/Hercules_ROS_970x250//750x200//300x250"
                          size={[
                            [970, 250],
                            [750, 200],
                            [300, 250],
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <Loader></Loader>
      )}
    </div>
  );
};

export default withTranslation()(React.memo(MyUnited));
