/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import moment from 'moment';
import React from 'react';
import {
  dateformat,
  defaultDescription,
  getHomeAddress,
  getDataObject,
  OLD_TRAFFORD,
  parseJsonData,
  PostalAddress,
  Stadium,
  EventScheduled,
  EventCancelled,
  EventPostponed,
  Organization,
  Thing,
  getImageUrl,
  jsonStringify,
} from '../../utils/structureData';
import { getCelumImages, buildImgUrl } from '../../utils/utils';
import MU from '../../constants/muConstants';
import serializeJavascript from 'serialize-javascript';

const MatchCarouselData = (props) => {
  const { structureData } = props;

  const pageTitleConfiguration = () => {
    if (structureData && structureData.PageTitleConfiguration) {
      return structureData.PageTitleConfiguration;
    } else {
      return '';
    }
  };
  const getAbsoluteUrl = (url) => {
    if (url.indexOf('http') !== -1 || url.indexOf('https') !== -1 || url.indexOf('www') !== -1) {
      return url;
    } else {
      return `https://${MU && MU.manutdDomain}${url}`;
    }
  };

  const individualData = (item, type) => {
    try {
      let url, venueName, homeTeamImage, awayTeamImage, parseImage, description, dataObj;
      let eventStatus = EventScheduled;
      if (item.sitemapurl_s) {
        let siteMap = parseJsonData(item.sitemapurl_s);
        url = siteMap && siteMap.canonical ? `${getAbsoluteUrl(siteMap.canonical)}` : '';
      }
      const matchDate = moment.parseZone(item.matchdate_tdt).format(dateformat);
      const matchEndDate = moment.parseZone(item.matchdate_tdt).add(2, 'hours').format(dateformat);
      let headline = `${item.hometeam_t} Vs ${item.awayteam_t} - ${
        item.competitionname_t
      } ${moment
        .utc(moment(item.matchdate_tdt))
        .format('DD MMMM yyyy')}${pageTitleConfiguration()}`;
      if (item.hometeam_t && item.awayteam_t && item.competitionname_t && matchDate) {
        description = `Follow live match coverage and reaction as ${item.hometeam_t} play ${
          item.awayteam_t
        } in the ${item.competitionname_t} on ${moment
          .utc(moment(item.matchdate_tdt))
          .format('DD MMMM yyyy')} at ${moment
          .utc(moment(item.matchdate_tdt))
          .format('HH: mm')} UTC`;
      } else {
        description = defaultDescription;
      }
      if (item.isabandoned_b) {
        eventStatus = EventCancelled;
      }
      if (item.ispostponed_b) {
        eventStatus = EventPostponed;
      }
      let isMUHomeTeam = false;
      let isOldTrafford = false;
      if (item.hometeamid_t && item.hometeamid_t === '1') {
        isMUHomeTeam = true;
        if (item.venuename_t && item.venuename_t.toLowerCase() === 'old trafford') {
          isOldTrafford = true;
        }
      }
      if (isOldTrafford) {
        venueName = OLD_TRAFFORD;
      }
      if (item.venuename_t) {
        venueName = item.venuename_t;
      } else if (!isMUHomeTeam) {
        venueName = Stadium;
      }
      let image = [];
      if (item.celumimages_s) {
        let celumimage = parseJsonData(item.celumimages_s);
        parseImage = celumimage && getCelumImages(celumimage);
        homeTeamImage = parseImage && parseImage.homeCrest && getImageUrl(parseImage.homeCrest);
        awayTeamImage = parseImage && parseImage.awayCrest && getImageUrl(parseImage.awayCrest);
        let imageValues = parseImage && parseImage.defaultImage && parseImage.defaultImage.CropUrl;
        if (imageValues) {
          for (let imgKey in imageValues) {
            image.push(buildImgUrl(imageValues[imgKey]));
          }
        }
      }
      let address = getHomeAddress(item.venuecity_t);
      let addressAwayTeam = { '@type': PostalAddress, addressLocality: item.venuecity_t };
      let performer = [];
      performer[0] = { '@type': Organization, name: item.awayteam_t };
      performer[1] = { '@type': Organization, name: item.hometeam_t };
      let dataJson = {
        name: headline,
        url: url,
        description: description,
        startDate: matchDate,
        endDate: matchEndDate,
        eventStatus: eventStatus,
        location: {
          '@type': 'Place',
          name: venueName,
          address: isMUHomeTeam && isOldTrafford ? address : addressAwayTeam,
        },
        image: image,
        awayTeam: {
          '@type': Thing,
          name: item.awayteam_t,
          image: awayTeamImage,
        },
        homeTeam: {
          '@type': Thing,
          name: item.hometeam_t,
          image: homeTeamImage,
        },
        performer: performer,
      };
      if (type === 'fixture') {
        let period;
        if (item.resultdata_t) {
          let resultdataJson = parseJsonData(item.resultdata_t);
          if (resultdataJson !== null) {
            period = resultdataJson['Period'];
          }
        }
        if (isMUHomeTeam && period && period.toLowerCase() === 'prematch') {
          let offers = {
            type: 'Offer',
            description: item.ticketctatitle_t,
            url: item.ticketctaurl_t,
          };
          dataObj = Object.assign(getDataObject(dataJson), { offers: offers });
        } else {
          dataObj = getDataObject(dataJson);
        }
      } else {
        dataObj = getDataObject(dataJson);
      }
      return dataObj;
    } catch (e) {
      console.log('error in creating matches carousel structured data');
    }
  };

  const getStructureData = (value) => {
    try {
      let sportData = [];
      if (
        value &&
        value.ResultsResponse &&
        value.ResultsResponse.response &&
        value.ResultsResponse.response.docs &&
        value.ResultsResponse.response.docs.length > 0
      ) {
        value.ResultsResponse.response.docs.forEach((item) => {
          sportData.push(individualData(item, 'result'));
        });
      }
      if (
        value &&
        value.LiveResponse &&
        value.LiveResponse.response &&
        value.LiveResponse.response.docs &&
        value.LiveResponse.response.docs.length > 0
      ) {
        value.LiveResponse.response.docs.forEach((item) => {
          sportData.push(individualData(item, 'live'));
        });
      }
      if (
        value &&
        value.FixtureResponse &&
        value.FixtureResponse.response &&
        value.FixtureResponse.response.docs &&
        value.FixtureResponse.response.docs.length > 0
      ) {
        value.FixtureResponse.response.docs.forEach((item) => {
          sportData.push(individualData(item, 'fixture'));
        });
      }
      return sportData;
    } catch (e) {
      console.log('error in creating matches carousel structured data');
    }
  };

  return structureData && structureData.documentsFromSolr ? (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: `${serializeJavascript(getStructureData(structureData.documentsFromSolr)).replace(
          /\\"/g,
          ''
        )}`,
      }}
    ></script>
  ) : null;
};
export default MatchCarouselData;