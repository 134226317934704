/**
 *  © 2022 -2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import Unitedcompetition from '../../Cards/Unitedcompetition';
const HeroBannerCompetition = (props) => {
  return (
    <>
      <div className="home-row">
        <div className="home-hero">
          <Unitedcompetition {...props} gridClass="grid-12" />
        </div>
      </div>
    </>
  );
};

export default HeroBannerCompetition;
