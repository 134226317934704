/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useMutvListingValue } from '../../../context/mutvMyListContext';
import api from '../../../utils/muApi';
import { GET_ITEM } from '../../../constants/endpoints';
import Tablisting from './Tablisting';
import { useSponsorValue } from '../../../context/sponsorContext';
import Loader from '../../Common/Loader';
import { postMyListReport } from '../../../actions/mutvMyListAction';
import { useLocation } from 'react-router-dom';
import * as track from '../../../utils/analytics';
import {
  deletAndSort,
  defaultLocalDataJson,
  createAndSaveToSession,
  getNode,
  getLocalData,
} from '../mutvutil';
import { getPublishedDate } from '../../../utils/momentDate';
const MyList = (props) => {
  const [
    { myListResponse, itemNumber, lastResponseTime, reportStatus, status, reloadPage },
    dispatch,
  ] = useMutvListingValue();
  const location = useLocation();
  let { search } = location;
  const [{ isLoginChecked, isGigyaAvilable, isUserLogin, userLoginDetail }] = useSponsorValue();

  const [tabSelected, setTabSelected] = useState(
    search !== '?viewAll=true' ? 'contentList' : 'contentHistory'
  );
  const initialState = {
    contentList: {
      data: [],
      listLoader: false,
      startIndex: 0,
      status: null,
    },
    contentHistory: {
      data: [],
      listLoader: false,
      startIndex: 0,
      status: null,
    },
  };
  const [state, setState] = useState(initialState);
  const [idsToDeletes, setIdsToDeletes] = useState([]);
  const [showDeleteBtn, setShowDeleteBtn] = useState(false);
  const [undoDeleteState, setUndoDeleteState] = useState(true);
  const [onLoadStatus, setOnloadStatus] = useState(true);
  const [totalData, setTotalData] = useState();
  const [noDataFound, setNoDataFound] = useState(false);
  const updatedData = useRef({});
  const [tempDeleteArr, setTempDeleteArr] = useState([]);

  useEffect(() => {
    if (isGigyaAvilable && isLoginChecked) {
      const localMylistData = getLocalData();
      if (onLoadStatus || reloadPage) {
        let apiData;
        let activeTab = tabSelected;
        if (isUserLogin && myListResponse) {
          apiData = myListResponse && getSortedData(myListResponse); //myListResponse;
        } else if (!isUserLogin && !userLoginDetail && localMylistData) {
          apiData = getSortedData(localMylistData);
        } else if (!isUserLogin && !userLoginDetail && !localMylistData) {
          apiData = defaultLocalDataJson();
        }
        /*To handle if user have empty continue list and hits the URL directly in new tab*/
        if (
          search === '?viewAll=true' &&
          apiData &&
          apiData.ContentHistoryResponse &&
          apiData.ContentHistoryResponse.ContentHistory &&
          apiData.ContentHistoryResponse.ContentHistory.length == 0
        ) {
          activeTab = 'contentList';
          setTabSelected(activeTab);
        }
        apiData && setTotalData(apiData);
        apiData && getMyLists(state[activeTab].startIndex, activeTab, apiData);
      }
    }
  }, [myListResponse, isLoginChecked, reloadPage]);

  const getSortedData = (data) => {
    if (data) {
      return {
        ContentListResponse: {
          ContentList:
            data.ContentListResponse &&
            data.ContentListResponse.ContentList &&
            createIdToMap(
              data.ContentListResponse && data.ContentListResponse.ContentList,
              'myListDT',
              'contentList'
            ),
        },
        ContentHistoryResponse: {
          ContentHistory:
            data.ContentHistoryResponse &&
            data.ContentHistoryResponse.ContentHistory &&
            createIdToMap(
              data.ContentHistoryResponse && data.ContentHistoryResponse.ContentHistory,
              'historyDT',
              'contentHistory'
            ),
        },
      };
    }
  };
  const createIdToMap = (obj, sortByName, selected) => {
    const sortedData = deletAndSort(obj, sortByName);
    const obj1 = {};
    sortedData &&
      sortedData.forEach((item) => {
        obj1[item.ID] = null;
      });
    updatedData.current[selected] = obj1;
    return sortedData;
  };

  const getMyLists = (initial, tabSelected, totalData) => {
    if (tabSelected === 'contentList') {
      totalData &&
        totalData.ContentListResponse &&
        totalData.ContentListResponse.ContentList &&
        renderData(totalData.ContentListResponse.ContentList, initial, tabSelected);
    } else if (tabSelected === 'contentHistory') {
      totalData &&
        totalData.ContentHistoryResponse &&
        totalData.ContentHistoryResponse.ContentHistory &&
        renderData(totalData.ContentHistoryResponse.ContentHistory, initial, tabSelected);
    }
  };

  const renderData = (obj, initial, tabSelected) => {
    let count = 0;
    let getSliceToVal = getSliceTo(obj, initial);
    if (obj && obj.length > 0) {
      obj.slice(initial, initial + getSliceToVal).forEach((item, index) => {
        /**Checking from session otherwise call api */
        const IdFoundinSession =
          sessionStorage.getItem(item.ID) && JSON.parse(sessionStorage.getItem(item.ID));
        if (IdFoundinSession) {
          if (IdFoundinSession.itemid_s === item.ID) {
            if (tabSelected === 'contentHistory') {
              IdFoundinSession['totalDuration'] = item.totalDuration;
              IdFoundinSession['playPosition'] = item.playPosition;
            }
            count++;
            updatedData.current[tabSelected][item.ID] = IdFoundinSession;
            if (count === obj.slice(initial, initial + getSliceToVal).length) {
              setAllData(obj, initial, updatedData, tabSelected);
            }
          }
        } else {
          const route = `${GET_ITEM}${item.ID}`;
          api
            .get({ route })
            .then((data) => {
              count++;
              let responseData = createAndSaveToSession(data, tabSelected, item);
              updatedData.current[tabSelected][responseData.itemid_s] = responseData;
              if (count === obj.slice(initial, initial + getSliceToVal).length) {
                setAllData(obj, initial, updatedData, tabSelected);
              }
            })
            .catch((err) => {
              count++;
              return null;
            });
        }
      });
    }
  };

  const setAllData = (obj, initial, updatedData, tabSelected) => {
    const loadmore = obj.length - initial > itemNumber ? true : false;
    setState(() => ({
      ...state,
      [tabSelected]: {
        ...state[tabSelected],
        data:
          tabSelected === 'contentList'
            ? Object.values(updatedData.current[tabSelected]).filter(
                (val) => val !== null && !tempDeleteArr.includes(val.itemid_s)
              )
            : Object.values(updatedData.current[tabSelected]).filter((val) => val !== null),
        status: 'fetched',
        listLoader: loadmore,
        startIndex: initial,
      },
    }));
    setOnloadStatus(false); //Not to reLoad again
  };
  const loadMore = (isAutoScroll) => {
    if (undoDeleteState || isAutoScroll) {
      setState({
        ...state,
        [tabSelected]: {
          ...state[tabSelected],
          status: 'fetching',
          listLoader: false,
        },
      });

      setTimeout(() => {
        getMyLists(state[tabSelected].startIndex + itemNumber, tabSelected, totalData);
      }, 1000);
    }
  };
  const getSliceTo = (obj, initial) => {
    return obj && obj.length > 0 && obj.length - (initial + itemNumber) >= 0
      ? itemNumber
      : obj.length % itemNumber;
  };
  const trackAnalytic = (obj) => {
    const data = track.data('button');
    track.analytics({ ...data, ...obj });
  };
  const tabChange = (e, selectedItem, tabFilter) => {
    if (tabSelected !== selectedItem) {
      getStickyClass() ? window.scroll({ top: 10 }) : window.scroll({ top: 0 });
      //setTimeout(() => {
      setTabSelected(selectedItem);
      if (Object.keys(updatedData.current[selectedItem]).length > 0) {
        getMyLists(state[selectedItem].startIndex, selectedItem, totalData);
      }
      //}, 100);

      trackAnalytic({ button_name: tabFilter[selectedItem], container_type: 'Mylist' });
    }
    revetToDefaultBtnAction(); //restore all action button
  };
  const getStickyClass = () => {
    let elm = document.getElementsByClassName('custom-sticky');
    return elm && elm[0] && elm[0].classList && elm[0].classList.contains('sticky') ? true : false;
  };

  /**
   * Delete operations functions**
   */
  const onDeleteItem = (e, itemid) => {
    e.preventDefault();
    e.stopPropagation();

    if (itemid && undoDeleteState) {
      /*undoDeleteState:- should not click if it in undodelete mode */
      if (!idsToDeletes.includes(itemid)) {
        setIdsToDeletes([...idsToDeletes, itemid]);
      } else {
        idsToDeletes.splice(idsToDeletes.indexOf(itemid), 1);
        setIdsToDeletes([...idsToDeletes]);
      }
    }
  };

  const editAndDone = () => {
    setShowDeleteBtn(true);
    trackAnalytic({
      button_name: !showDeleteBtn ? 'Edit' : 'Done',
      container_type: 'Mylist',
      deleted_count: idsToDeletes && !undoDeleteState ? idsToDeletes.length : 0,
    });
    if (!showDeleteBtn === false && undoDeleteState === true) {
      revetToDefaultBtnAction(); //not delete
    } else if (!showDeleteBtn === false && undoDeleteState === false) {
      deletePermenently();
    }
  };
  /**
   * Delete and Undo Delete operation
   */
  const deleteAndUndoDelete = () => {
    if (idsToDeletes && idsToDeletes.length > 0) {
      setUndoDeleteState(!undoDeleteState);
      if (undoDeleteState) {
        setState(() => ({
          ...state,
          [tabSelected]: {
            ...state[tabSelected],
            data: Object.values(updatedData.current[tabSelected]).filter(
              (val) => val !== null && !idsToDeletes.includes(val.itemid_s)
            ),
          },
        }));
        setTempDeleteArr(idsToDeletes);
      } else {
        //revert delete
        setTempDeleteArr([]); //reverted to blank to loop over in state(not to draw deleted item)
        setState(() => ({
          ...state,
          [tabSelected]: {
            ...state[tabSelected],
            data: Object.values(updatedData.current[tabSelected]).filter((val) => val !== null),
          },
        }));
      }
    }
  };

  const deletePermenently = () => {
    if (idsToDeletes && idsToDeletes.length > 0) {
      if (isUserLogin && totalData) {
        const obj =
          tabSelected === 'contentList'
            ? totalData.ContentListResponse.ContentList
            : totalData.ContentHistoryResponse.ContentHistory;
        deleteFromAPI(obj);
      } else if (!isUserLogin && !userLoginDetail) {
        deleteFromLocalStorage();
      }
    }
  };

  const deleteFromAPI = (obj) => {
    const totalObj = JSON.parse(JSON.stringify(obj));
    const arrayToBeDeleted =
      totalObj &&
      totalObj.filter((item) => {
        if (idsToDeletes.includes(item.ID)) {
          item.deletedDT = getPublishedDate();
          item.myList = false;
          return item;
        }
      });
    postMyListReport(
      dispatch,
      lastResponseTime,
      arrayToBeDeleted,
      userLoginDetail,
      myListResponse,
      true,
      tabSelected,
      reportStatus,
      'mylist',
      successCallback
    );
  };

  const successCallback = () => {
    revetToDefaultBtnAction();
    updateStateValues();
    checkForceLoadMore(); //Forcefully load more if data is present
  };

  const deleteFromLocalStorage = () => {
    const localMylistData = getLocalData();
    const obj = getNode(localMylistData, tabSelected);
    obj &&
      obj.forEach((item) => {
        if (idsToDeletes.includes(item.ID)) {
          item.deletedDT = getPublishedDate();
          item.myList = false;
          delete item.bgImageURL;
          delete item.contentURL;
          delete item.geoCode;
          delete item.language;
          delete item.title;
          delete item.totalDuration;
        }
      });

    if (tabSelected === 'contentList') {
      localMylistData.ContentListResponse.ContentList = obj;
    } else {
      localMylistData.ContentHistoryResponse.ContentHistory = obj;
    }
    localStorage.setItem('localData', JSON.stringify(localMylistData));
    revetToDefaultBtnAction(); //set in default btn state
    updateStateValues(); //change state value after deletion
  };

  const updateStateValues = () => {
    idsToDeletes &&
      idsToDeletes.forEach((id) => {
        updatedData.current && delete updatedData.current[tabSelected][id];
      });
    checkForceLoadMore(); //Forcefully load more if data is present

    if (
      updatedData.current[tabSelected] &&
      Object.keys(updatedData.current[tabSelected]).length === 0
    ) {
      setNoDataFound(true); //showNoDataFound
    }
  };

  const checkForceLoadMore = () => {
    if (
      state[tabSelected] &&
      state[tabSelected].data.length === 0 &&
      Object.keys(updatedData.current[tabSelected]).length > 0
    ) {
      loadMore(true);
    }
  };

  const revetToDefaultBtnAction = () => {
    setShowDeleteBtn(false); //change to edit button
    setUndoDeleteState(true); //change to deleteselected
    setIdsToDeletes([]); //revert all selected item to delete
  };
  return (
    <React.Fragment>
      <Tablisting
        allData={state}
        tabSelected={tabSelected}
        tabChange={tabChange}
        loadMore={loadMore}
        noDataFound={noDataFound}
        totalData={totalData}
        onDeleteItem={onDeleteItem} //required in cards for click on card deletebutton
        idsToDeletes={idsToDeletes} //required in cards to show active
        deleteAndUndoDelete={deleteAndUndoDelete} //calback for delete or undo delete
        editAndDone={editAndDone} //calback for edit or Done
        undoDeleteState={undoDeleteState} // To change the text undodelete/Delete
        showDeleteBtn={showDeleteBtn} //to show Edit/Done button
      ></Tablisting>

      {(!isLoginChecked ||
        reportStatus === 'fetching' ||
        status === 'fetching' ||
        (Object.keys(updatedData.current).length < 0 && status !== 'error')) && <Loader></Loader>}
    </React.Fragment>
  );
};

export default MyList;
