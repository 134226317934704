/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { createContext, useContext, useReducer } from 'react';
import { muTravelState, muTravelReducer } from '../reducers/muTravelReducer';

export const MuTravelContext = createContext();

export const MuTravelProvider = ({ children }) => (
  <MuTravelContext.Provider value={useReducer(muTravelReducer, muTravelState)}>
    {children}
  </MuTravelContext.Provider>
);
export const useMuTravelValue = () => useContext(MuTravelContext);

export default MuTravelContext;
