/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import * as track from '../../utils/analytics';
import Accordion from '../Common/Accordion';
import { MyUnitedProvider, useMyUnitedValue } from '../../context/myUnitedContext';
import { fetchAllplayer, fetchWomensPlayer, fetchFavplayer } from '../../actions/myUnitedAction';
import FavouritePlayer from '../MyUnited/FavouritePlayer';
import {
  submitPreferenceSettings,
  showEditProfile,
  showChangePassword,
  redirectToSignIn,
} from '../../utils/gigyaScreen';
import Loader from '../Common/Loader';
import ChangeEmail from './ChangeEmail';
import { useSponsorValue } from '../../context/sponsorContext';
import {
  manageSubscription,
  addSubscription,
  isSubscribedViaCrossPlatform,
} from '../../utils/gigyaScreen';
import { getParameterByName, showSubscriptionCard } from '../../utils/utils';
import MU from '../../constants/muConstants';
import {
  checkLocalCodeMissing,
  createChangePasswordUrl,
  createEditProfileUrl,
  doUserLogout,
  redirectToIDMLogin,
  createChangeEmaildUrl,
} from '../../utils/idmUtils';
const MyUnitedSettings = (props) => (
  <React.Fragment>
    <MyUnitedProvider>
      <MyUnitedSettingsContainer {...props}></MyUnitedSettingsContainer>
    </MyUnitedProvider>
  </React.Fragment>
);

const MyUnitedSettingsContainer = (props) => {
  const { t, fields } = props;
  const [accordianitem, setAccordianitem] = useState([]);
  const [ProfileAccordian, setProfileAccordian] = useState([]);
  const [gigyaData, setgigyaData] = useState(null);
  const [emailModal, setEmailModal] = useState(false);
  const [accordianState, seAccordianState] = useState(null);
  const [
    { isGigyaAvilable, userLoginDetail, isUserSubscribe },
    sponsorDispatch,
  ] = useSponsorValue();
  useEffect(() => {
    if (MU.isIDMEnabled) {
      const code = getParameterByName('code', true);
      if (checkLocalCodeMissing() && !code) {
        redirectToIDMLogin();
      }
    } else redirectToSignIn();
    if (isGigyaAvilable) {
      fetchAllplayer(dispatch);
      fetchWomensPlayer(dispatch);
      userLoginDetail && renderProfileData();
    }
  }, [isGigyaAvilable, userLoginDetail]);

  const MyUnitedFavPlayer = fields && fields.MyUnitedFavPlayer;

  const [
    { allPlayerData, responseData, womensPlayer, favPlayerData, favPlayerStatus, predictionData },
    dispatch,
  ] = useMyUnitedValue();

  const trackLink = (e, btnName, targetUrl) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: btnName,
        destination_url: targetUrl,
      },
      e
    );
  };

  const trackButton = (btnName, favPlayer) => {
    const initialData = track.data('button');
    track.analytics({
      ...initialData,
      button_name: btnName,
      player_data: favPlayer,
    });
  };

  const getHelpAccordianData = () => {
    const AccordianData = [];
    if (fields && fields.Faqs && fields.Faqs.value) {
      fields.Faqs.value.title = t('FAQs');
      fields.Faqs.value.ariatext = 'view-faqs';
      fields.Faqs.value.href =
        fields.Faqs.value.linktype === 'internal'
          ? fields.Faqs.value.hrefMuCustom
          : fields.Faqs.value.href;
      fields.Faqs.value.target = fields.Faqs.value.target ? fields.Faqs.value.target : '_self';
      AccordianData.push(fields.Faqs.value);
    }
    if (fields && fields.ClubContacts && fields.ClubContacts.value) {
      fields.ClubContacts.value.title = t('ContactUs');
      fields.ClubContacts.value.ariatext = 'view-contactus';
      fields.ClubContacts.value.href =
        fields.ClubContacts.value.linktype === 'internal'
          ? fields.ClubContacts.value.hrefMuCustom
          : fields.ClubContacts.value.href;
      fields.ClubContacts.value.target = fields.ClubContacts.value.target
        ? fields.ClubContacts.value.target
        : '_self';
      AccordianData.push(fields.ClubContacts.value);
    }
    if (fields && fields.Accessibility && fields.Accessibility.value) {
      fields.Accessibility.value.title = t('Accessibility');
      fields.Accessibility.value.ariatext = 'view-accessibility';
      fields.Accessibility.value.href =
        fields.Accessibility.value.linktype === 'internal'
          ? fields.Accessibility.value.hrefMuCustom
          : fields.Accessibility.value.href;
      fields.Accessibility.value.target = fields.Accessibility.value.target
        ? fields.Accessibility.value.target
        : '_self';
      AccordianData.push(fields.Accessibility.value);
    }
    if (fields && fields.TermsOfUse && fields.TermsOfUse.value) {
      fields.TermsOfUse.value.title = t('TermsofUse');
      fields.TermsOfUse.value.ariatext = 'view-terms';
      fields.TermsOfUse.value.href =
        fields.TermsOfUse.value.linktype === 'internal'
          ? fields.TermsOfUse.value.hrefMuCustom
          : fields.TermsOfUse.value.href;
      fields.TermsOfUse.value.target = fields.TermsOfUse.value.target
        ? fields.TermsOfUse.value.target
        : '_self';
      AccordianData.push(fields.TermsOfUse.value);
    }
    if (fields && fields.CookiePolicy && fields.CookiePolicy.value) {
      fields.CookiePolicy.value.title = t('PrivacyCookies');
      fields.CookiePolicy.value.ariatext = 'view-privacy';
      fields.CookiePolicy.value.href =
        fields.CookiePolicy.value.linktype === 'internal'
          ? fields.CookiePolicy.value.hrefMuCustom
          : fields.CookiePolicy.value.href;
      fields.CookiePolicy.value.target = fields.CookiePolicy.value.target
        ? fields.CookiePolicy.value.target
        : '_self';
      AccordianData.push(fields.CookiePolicy.value);
    }
    setAccordianitem(AccordianData);
  };

  const getFavPlayerData = (data) => {
    if (
      data &&
      data.PlayerProfileResponse &&
      data.PlayerProfileResponse.response &&
      data.PlayerProfileResponse.response.docs &&
      data.PlayerProfileResponse.response.docs.length > 0
    ) {
      return data.PlayerProfileResponse.response.docs[0];
    } else {
      return null;
    }
  };

  const getProfileAccordian = (response) => {
    let data = {
      showEditProfile: {},
      changePassword: {},
      changeEmailaddress: {},
      submitPreferenceSettings: {},
      subscription: {},
      logout: {},
    };
    const ProfileAccordian = [];
    data.showEditProfile.title = t('EditProfile');
    data.showEditProfile.clikFun = 'showEditProfile';
    data.showEditProfile.ariatext = 'view-editProfile';
    ProfileAccordian.push(data.showEditProfile);
    if (response && response.loginProvider === 'site') {
      data.changePassword.title = t('ChangePassword');
      data.changePassword.clikFun = 'showChangePassword';
      data.changePassword.ariatext = 'view-changePassword';
      ProfileAccordian.push(data.changePassword);

      data.changeEmailaddress.title = t('ChangeEmailaddress');
      data.changeEmailaddress.ariatext = 'view-changeEmail';
      data.changeEmailaddress.clikFun = 'changeEmailaddress';
      ProfileAccordian.push(data.changeEmailaddress);
    }
    data.submitPreferenceSettings.title = t('PreferenceCentre');
    data.submitPreferenceSettings.ariatext = 'view-PreferenceCentre';
    data.submitPreferenceSettings.clikFun = 'submitPreferenceSettings';
    ProfileAccordian.push(data.submitPreferenceSettings);

    if (showSubscriptionCard({ isUserSubscribe, userLoginDetail })) {
      if (isUserSubscribe) {
        data.subscription.title = t('ManageMutvSubscription');
        data.subscription.ariatext = 'view-ManageMutvSubscription';
      } else {
        data.subscription.title = t('SubscribeToMutvNow');
        data.subscription.ariatext = 'view-SubscribeToMutvNow';
      }
      data.subscription.clikFun = 'subscription';
      ProfileAccordian.push(data.subscription);
    }
    data.logout.title = t('logout');
    data.logout.ariatext = 'view-editProfile';
    data.logout.clikFun = 'logout';
    ProfileAccordian.push(data.logout);
    setProfileAccordian(ProfileAccordian);
  };

  const renderProfileData = () => {
    setgigyaData(userLoginDetail);
    getProfileAccordian(userLoginDetail);
    getHelpAccordianData();
    if (userLoginDetail && userLoginDetail.data && userLoginDetail.data.favoritePlayerID) {
      fetchFavplayer(dispatch, userLoginDetail.data.favoritePlayerID, 1, 15, sponsorDispatch);
    }
  };

  const openEmailModal = () => {
    setEmailModal(true);
    focousModal();
    document.querySelector('body').classList.add('scroll-remove', 'scrollHideAll');
  };
  const closeEmailModal = () => {
    setEmailModal(false);
    if (document.querySelector('body').classList) {
      document.querySelector('body').classList.remove('scroll-remove', 'scrollHideAll');
    }
  };

  const focousModal = () => {
    setTimeout(function () {
      let changeEmailElm = document.getElementById('model-change-email');
      if (changeEmailElm && window.getComputedStyle(changeEmailElm).display === 'block') {
        let closeIcon = changeEmailElm.querySelector('.email-modal-close');
        if (closeIcon) {
          closeIcon.focus();
          closeIcon.classList.add(
            'accessablity-closebtn',
            'accessibilityCloseModal',
            'non-keyboard-outline'
          );
        }
      }
    }, 2000);
  };

  const onSubscriptionClick = (e, ariatext) => {
    if (isUserSubscribe) {
      const ManageSubscription =
        fields && fields.MyUnitedSettings && fields.MyUnitedSettings.ManageSubscription;
      const subscriptionDetails = isSubscribedViaCrossPlatform({
        userLoginDetail,
        ManageSubscription,
      });
      if (subscriptionDetails) {
        trackLink(e, ariatext, subscriptionDetails.destinationURL);
        subscriptionDetails.destinationURL &&
          window.open(subscriptionDetails.destinationURL, '_blank');
      } else {
        trackLink(e, ariatext, MU && MU.manageSubscriptionUrl);
        manageSubscription();
      }
    } else if (userLoginDetail && userLoginDetail.UID) {
      trackLink(e, ariatext, MU && MU.addSubscriptionUrl);
      addSubscription({ param: userLoginDetail.UID, type: 'uid' });
    }
  };

  const userLogout = (e) => {
    e.preventDefault();
    if (isGigyaAvilable) {
      trackLink(e, 'view-logout');
      doUserLogout();
    }
  };

  const editProfile = () => {
    if (typeof window !== 'undefined' && MU.isIDMEnabled) {
      window.location = createEditProfileUrl();
    } else {
      showEditProfile();
    }
  };

  const changePassword = () => {
    if (typeof window !== 'undefined' && MU.isIDMEnabled) {
      window.location = createChangePasswordUrl();
    } else {
      showChangePassword();
    }
  };
  const changeEmail = () => {
    if (typeof window !== 'undefined' && MU.isIDMEnabled) {
      window.location = createChangeEmaildUrl();
    } else {
      openEmailModal();
    }
  };
  const mapFunctionByName = (e, item, target) => {
    console.log(item);
    switch (item.clikFun) {
      case 'showEditProfile':
        editProfile();
        break;
      case 'showChangePassword':
        changePassword();
        break;
      case 'changeEmailaddress':
        changeEmail();
        break;
      case 'submitPreferenceSettings':
        submitPreferenceSettings();
        break;
      case 'subscription':
        onSubscriptionClick(e, item.ariatext);
        break;
      case 'logout':
        userLogout(e);
        break;
      default:
        console.log('no function found');
        break;
    }
    item.clikFun !== 'subscription' && trackLink(e, item.ariatext, target);
  };

  const getTabIndex = (index) => {
    if (accordianState && accordianState.length > 0) {
      if (
        accordianState[index] &&
        accordianState[index].index === index.toString() &&
        accordianState[index].isOpen
      ) {
        return {
          tabIndex: 0,
          'aria-hidden': false,
        };
      } else {
        return {
          tabIndex: -1,
          'aria-hidden': true,
        };
      }
    } else {
      return {
        tabIndex: -1,
        'aria-hidden': true,
      };
    }
  };

  return (
    <div>
      {gigyaData && gigyaData.data ? (
        <div className="container">
          <div className="grid-8">
            <h2 className="united__title" aria-hidden="true">
              {t('Settings')}
            </h2>
            <div className="united__settings">
              <div className="accordion" data-module="accordion-menu">
                <label className="united__sub-title">{t('GeneralSettings')}</label>
              </div>
              <section id="united-accordian">
                <Accordion
                  title={t('Help')}
                  trackButton="view-help"
                  headingClass="united__row accord"
                  titleclass="accord-title"
                  detailclass="united-detail"
                  index="0"
                  seAccordianState={seAccordianState}
                  accordianState={accordianState}
                >
                  {accordianitem &&
                    accordianitem.length > 0 &&
                    accordianitem.map((item, index) => (
                      <div className="united__inner-row" key={index}>
                        <a
                          href={item.href}
                          target={item.target}
                          className="accord-title full-width"
                          {...getTabIndex(0)}
                          onClick={(e) => {
                            trackLink(e, item.ariatext, item.href);
                          }}
                        >
                          <span className="hover-underline">{item.title}</span>
                          <span className="icon-next-page-arrow"></span>
                        </a>
                      </div>
                    ))}
                </Accordion>
                <Accordion
                  trackButton="view-myProfile"
                  headingClass="united__row accord"
                  titleclass="accord-title"
                  detailclass="united-detail"
                  title={t('MyProfile')}
                  index="1"
                  seAccordianState={seAccordianState}
                  accordianState={accordianState}
                  defaultOpen={true}
                >
                  {ProfileAccordian &&
                    ProfileAccordian.length > 0 &&
                    ProfileAccordian.map((item, index) => (
                      <div className="united__inner-row" key={index}>
                        {item.clikFun === 'changeEmailaddress' && (
                          <div id="model-change-email">
                            {emailModal && (
                              <ChangeEmail
                                gigyaData={gigyaData}
                                closeEmailModal={closeEmailModal}
                              ></ChangeEmail>
                            )}
                          </div>
                        )}
                        <a
                          href="javascript:void(0)"
                          className="profile-text accord-title"
                          {...getTabIndex(1)}
                          onClick={(e) => {
                            mapFunctionByName(e, item, 'javascript:void(0)');
                          }}
                        >
                          <span className="hover-underline">{item.title}</span>
                          <span className="icon-next-page-arrow"></span>
                        </a>
                      </div>
                    ))}
                </Accordion>
              </section>
            </div>
          </div>
          <div className="grid-4 united-profile setting-left-section">
            <div className="profile__details-right">
              <FavouritePlayer
                allPlayer={allPlayerData}
                mensTeamPlayer={responseData}
                womensTeamPlayer={womensPlayer}
                favPlayer={favPlayerData && getFavPlayerData(favPlayerData)}
                favPlayerStatus={favPlayerStatus}
                trackLink={trackLink}
                trackButton={trackButton}
                gigyaData={gigyaData}
                toggleFavPlayer={MyUnitedFavPlayer}
              ></FavouritePlayer>
            </div>
          </div>
        </div>
      ) : (
        <Loader></Loader>
      )}
    </div>
  );
};

export default withTranslation()(React.memo(MyUnitedSettings));
