/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
const SecondaryNavigation = ({ shortNavItem }) => {
  /**
   * Render secondry navigation for which is visible below 1200px width
   */
  const renderSecondNav = () => {
    return (
      <nav className="second-level-nav">
        <ul className="nav-menu">
          {shortNavItem &&
            shortNavItem.map(
              (item, index) =>
                item &&
                item.Shortname && (
                  <li key={index}>
                    <a href={item.Destination} target={item.Target ? item.Target : ''}>
                      {item.Shortname}
                    </a>
                  </li>
                )
            )}
        </ul>
      </nav>
    );
  };

  return renderSecondNav();
};

export default withTranslation()(React.memo(SecondaryNavigation));
