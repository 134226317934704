/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import api from '../../utils/muApi';
import { fetchMyListData, setMyListReport } from '../../actions/mutvMyListAction';
import {
  isLocalDataAvailable,
  createWatchData,
  getLocalData,
  getAllHistory,
  getAwsServerTime,
  getTrueItems,
  deleteOldItems,
} from './mutvutil';
import { getPublishedDate } from '../../utils/momentDate';
import { isTimeDiffAllowed } from './mutvutil';
import { REMAINING_TIME, MAX_ITEM } from '../../constants/mutvConstants';
import { POST_MYLIST } from '../../constants/endpoints';

export const postDataToAPI = ({
  dur,
  playPos,
  heroData,
  myListResponse,
  userLoginDetail,
  lastResponseTime,
  isLoginChecked,
  reportStatus,
  dispatch,
  status,
}) => {
  if (isLoginChecked && userLoginDetail) {
    const { UID } = userLoginDetail;
    if (isTimeDiffAllowed(lastResponseTime) && reportStatus !== 'fetched') {
      createPostData(myListResponse, heroData, playPos, dur, true, UID, dispatch);
      createLocalData(dur, playPos, heroData); //Update localstorage as well
    } else {
      if (status !== 'fetching') {
        fetchMyListData(dispatch, userLoginDetail).then((res) => {
          if (res && res.data) {
            const myListResponse = res.data;
            createPostData(myListResponse, heroData, playPos, dur, true, UID, dispatch);
            createLocalData(dur, playPos, heroData); //Update localstorage as well
          }
        });
      }
    }
  } else if (isLoginChecked && !userLoginDetail) {
    if (isLocalDataAvailable() && playPos !== 0) {
      createLocalData(dur, playPos, heroData);
    }
  }
};

const createPostData = (myListResponse, heroData, playPos, dur, isApiSecure, UID, dispatch) => {
  const historyObj = myListResponse && getAllHistory(myListResponse);
  if (historyObj && heroData) {
    const IdFound = historyObj && historyObj.find((item) => item.ID === heroData.itemid_s);
    let data = IdFound
      ? updateRecord(IdFound, dur, playPos, myListResponse, heroData)
      : insertNewRecord(heroData, dur, playPos, myListResponse, true);
    const route = `${POST_MYLIST}`;
    if (data) {
      try {
        api
          .post({ route, data, isApiSecure, UID })
          .then((response) => {
            if (response && response.data.message === 'success') {
              dispatch(setMyListReport(response));
            }
          })
          .catch((e) => {
            console.log('error', e);
          });
      } catch (e) {
        console.error(e, 'error in post data');
      }
    }
  }
};
const updateRecord = (IdFound, dur, playPos, allData, heroData) => {
  const historyObj = getAllHistory(allData);
  const data = historyObj && createUpdateRecord(historyObj, IdFound, dur, playPos, heroData);
  if (data) {
    allData.ContentHistoryResponse.ContentHistory = data;
    const trueItems = getTrueItems(allData.ContentHistoryResponse.ContentHistory);
    delete allData.lastContentDataSyncDT;
    delete allData.FutureAWSRequestId;
    delete allData.AwsServerUtcTime;
    if (trueItems && trueItems.length <= MAX_ITEM) {
      return allData;
    } else {
      return deleteOldItems({ allData, isHistory: true });
    }
  }
};
const createUpdateRecord = (historyObj, IdFound, dur, playPos, heroData) => {
  const serverTime = getAwsServerTime();
  return (
    historyObj &&
    IdFound &&
    historyObj.map((item) => {
      if (item.ID === IdFound.ID) {
        if (dur <= playPos + REMAINING_TIME) {
          item.deletedDT = getPublishedDate();
          item.historyDT = getPublishedDate();
          delete item.bgImageURL;
          delete item.contentURL;
          delete item.geoCode;
          delete item.language;
          delete item.playPosition;
          delete item.title;
          delete item.totalDuration;
        } else {
          item = createWatchData(heroData, dur, playPos);
          delete item.deletedDT;
        }
        item.dataSyncDT = serverTime ? serverTime : getPublishedDate();
      }
      return item;
    })
  );
};
const insertNewRecord = (heroData, dur, playPos, allData) => {
  const newData = createWatchData(heroData, dur, playPos);
  if (newData) {
    const serverTime = getAwsServerTime();
    if (serverTime) {
      newData.dataSyncDT = serverTime ? serverTime : getPublishedDate(); //update sync time
    }
    allData.ContentHistoryResponse.ContentHistory = [
      newData,
      ...allData.ContentHistoryResponse.ContentHistory,
    ];
    const trueItems = getTrueItems(allData.ContentHistoryResponse.ContentHistory);
    delete allData.lastContentDataSyncDT;
    delete allData.FutureAWSRequestId;
    delete allData.AwsServerUtcTime;
    if (trueItems && trueItems.length <= MAX_ITEM) {
      return allData;
    } else {
      return deleteOldItems({ allData, isHistory: true });
    }
  }
};

/*
    For LocalStorage 
  */
export const createLocalData = (dur, playPos, heroData) => {
  if (isLocalDataAvailable()) {
    const localWatchData = getLocalData();
    if (localWatchData && heroData) {
      const historyObj = getAllHistory(localWatchData);
      const IdFound = historyObj && historyObj.find((item) => item.ID === heroData.itemid_s);
      IdFound
        ? updateLocalRecord(IdFound, dur, playPos, localWatchData, heroData)
        : insertLocalNewRecord(heroData, dur, playPos, localWatchData);
    }
  }
};
export const updateLocalRecord = (IdFound, dur, playPos, localWatchData, heroData) => {
  const historyObj = getAllHistory(localWatchData);
  const data = historyObj && createUpdateRecord(historyObj, IdFound, dur, playPos, heroData);
  if (data) {
    const trueItems = getTrueItems(localWatchData.ContentHistoryResponse.ContentHistory);
    if (trueItems && trueItems.length <= MAX_ITEM) {
      localWatchData.ContentHistoryResponse.ContentHistory = data;
      localStorage.setItem('localData', JSON.stringify(localWatchData));
    } else {
      const allData = deleteOldItems({
        allData: localWatchData,
        setInlocal: true,
        isHistory: true,
      });
      localStorage.setItem('localData', JSON.stringify(allData));
    }
  }
};
export const insertLocalNewRecord = (heroData, dur, playPos, localWatchData) => {
  const newData = createWatchData(heroData, dur, playPos);
  if (newData) {
    localWatchData.ContentHistoryResponse.ContentHistory = [
      newData,
      ...localWatchData.ContentHistoryResponse.ContentHistory,
    ];
    const trueItems = getTrueItems(localWatchData.ContentHistoryResponse.ContentHistory);
    if (trueItems && trueItems.length <= MAX_ITEM) {
      localStorage.setItem('localData', JSON.stringify(localWatchData));
    } else {
      const allData = deleteOldItems({
        allData: localWatchData,
        setInlocal: true,
        isHistory: true,
      });
      localStorage.setItem('localData', JSON.stringify(allData));
    }
  }
};
