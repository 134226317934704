/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import { createTagList } from '../../utils/utils';
export const Social = (data, isAws) => {
  return {
    fields: {
      SocialUrl: {
        value: data.socialurl_t,
      },
      ContentType: {
        value: data.contenttype_t,
      },
      CardType: {
        value: data.cardtype_t,
      },
      Sponsorby: null,
      __Published: {
        value: data.appearance_tdt || data.__published_tdt,
      },
      __PublishedBy: {
        value: data.__publishedby_t,
      },
      manutds: {
        value: {
          DestinationUrl: data.destinationurl_s,
          Tags: createTagList(data.taglist_sm, data.category_s),
        },
      },
    },
    rendering: {
      dataSource: data.itemid_s,
    },
  };
};
