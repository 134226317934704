/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { Fragment } from 'react';
import { getStatsBarData, getSponsorData, isApp } from '../../../utils/utils';
import SocialShareStrip from './SocialShareStrip';
import TimeFormatStrip from './TimeFormatStrip';
import { withTranslation } from 'react-i18next';
import Sponsor from '../../Common/Sponsor';
import { CONTENT_TYPE, MODULE, DARK_THEME } from '../../../constants/globalConstants';
import { ARTICLE_DETAIL } from '../../../constants/containerConstants';
import ImageFallback from '../../Common/ImageFallback';

const Socialholder = ({ t, fields, gridClass, itemId, type }) => {
  const {
    AuthorName,
    __PublishedBy,
    __Published,
    Sponsorby,
    ContentType,
    Variant,
    Headline,
    manutds,
  } = fields;

  return (
    <React.Fragment>
      <div className="home-content-panel__details-social__holder">
        <div className="home-content-panel__details-social__author">
          {((AuthorName && AuthorName.fields) || (__PublishedBy && __PublishedBy.value)) && (
            <Fragment>
              <div className="article-author-avatar">
                {AuthorName &&
                  AuthorName.fields &&
                  AuthorName.fields.Image &&
                  AuthorName.fields.Image.value &&
                  AuthorName.fields.Image.value.Crops &&
                  AuthorName.fields.Image.value.Crops.thumb && (
                    <ImageFallback
                      src={AuthorName.fields.Image.value.Crops.thumb}
                      onErrorClass={'no-img'}
                      className={AuthorName.fields.Image.value.Crops.thumb && 'img-loaded'}
                      alt={AuthorName.fields.Image.value.AltText}
                      title={AuthorName.fields.Image.value.AltText}
                      relativePath={true}
                    />
                  )}
              </div>
              <div className="article-author-title">
                <div>{t('by')}</div>
                <div>
                  {(AuthorName &&
                    AuthorName.fields &&
                    AuthorName.fields.AuthorName &&
                    AuthorName.fields.AuthorName.value) ||
                    (manutds && manutds.value && manutds.value.Author)}
                </div>
              </div>
            </Fragment>
          )}
        </div>
        <span className="home-content-panel__details-social__time">
          <TimeFormatStrip eventDate={__Published && __Published.value} />
        </span>
      </div>
      <div className="home-content-panel__details-social__main-holder">
        <SocialShareStrip
          item={getStatsBarData(fields)}
          fields={fields}
          itemId={itemId}
        ></SocialShareStrip>

        {type !== 'articlefooter' && (
          <Sponsor
            contentType={ContentType && ContentType.value}
            varient={'child'}
            sponsorInfo={Sponsorby && getSponsorData(Sponsorby && Sponsorby.fields)}
            grid={`${gridClass} novarient`}
            type={CONTENT_TYPE}
            author={
              AuthorName &&
              AuthorName.fields &&
              AuthorName.fields.AuthorName &&
              AuthorName.fields.AuthorName.value
            }
            cardName={Headline && Headline.value}
            container={ARTICLE_DETAIL}
            createdDateTime={__Published && __Published.value}
            itemId={itemId}
            tags={manutds && manutds.value && manutds.value.Tags}
          ></Sponsor>
        )}
        {type === 'articlefooter' && (
          <div className="mu-item__sponsor" aria-label="sponser information">
            <div className="mu-item__sponsor__wrapper">
              <Sponsor
                type={MODULE}
                contentType={ContentType && ContentType.value}
                moduleName={'articlefooter'}
                theme={DARK_THEME}
                author={
                  AuthorName &&
                  AuthorName.fields &&
                  AuthorName.fields.AuthorName &&
                  AuthorName.fields.AuthorName.value
                }
                cardName={Headline && Headline.value}
                container={ARTICLE_DETAIL}
                createdDateTime={__Published && __Published.value}
                itemId={itemId}
                tags={manutds && manutds.value && manutds.value.Tags}
              ></Sponsor>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
Socialholder.defaultProps = {
  type: '',
};

export default withTranslation()(Socialholder);
