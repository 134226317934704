/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useRef, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import Share from '../Common/GigyaShare';
import TimeFormatStrip from './TimeFormatStrip';
const SocialShareStrip = ({ t, item }) => {
  /** with useState
   * @define {initial value}
   */
  const [socialShare, setSocialShare] = useState({
    open: '',
    open_fullwidth: '',
    full_bg: '',
    noborder: '',
  });
  const callBackShareClose = useRef();
  const sharemoreRef = useRef(null);
  /**
   * @method {moreSocialLink function add the class for Dom Elements}
   */

  useEffect(() => {
    if (sharemoreRef && sharemoreRef.current) {
      if (socialShare.open === 'open' && socialShare.isKeyboardUser) {
        const firstLi = sharemoreRef.current.querySelector('.social-icons-li');
        if (firstLi) {
          firstLi.classList.add('non-keyboard-outline');
          firstLi.focus();
        }
      } else if (socialShare.open === 'close') {
        setTimeout(() => {
          const moreIcon = sharemoreRef.current.querySelector('.more a');
          if (moreIcon) {
            moreIcon.classList.add('non-keyboard-outline');
            moreIcon.focus();
          }
        }, 0);
      }
    }
  }, [socialShare]);

  const moreSocialLink = (e) => {
    e.preventDefault();
    const isKeyboardUser =
      e && e.target && e.target.classList && e.target.classList.contains('non-keyboard-outline');
    setSocialShare({
      open: 'open',
      open_fullwidth: 'open_fullwidth',
      full_bg: 'full_bg',
      noborder: 'no-border',
      isKeyboardUser: isKeyboardUser,
    });
  };
  /**
   * @method {closeSocialLink function remove the class for Dom Elements}
   */
  const closeSocialLink = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setSocialShare({
      open: 'close',
      open_fullwidth: '',
      full_bg: '',
      noborder: '',
    });
    callBackShareClose && callBackShareClose.current();
  };

  if (item && item.destinationurl_s) {
    let destinationURL = item.destinationurl_s;
    destinationURL = destinationURL && destinationURL.split('#');
    destinationURL = destinationURL && destinationURL[0];
    item.destinationurl_s = destinationURL;
  }

  return (
    <div className={`home-content-panel__details ${socialShare.noborder}`}>
      <div className={`home-content-panel__details-social__holder ${socialShare.full_bg}`}>
        <span className="home-content-panel__details-social__time">
          <TimeFormatStrip eventDate={item} />
        </span>
        <div className={`home-content-panel__details-social__icons ${socialShare.open_fullwidth}`}>
          <div className={`left ${socialShare.open}`} ref={sharemoreRef}>
            <Share
              onRef={(callback) => {
                callBackShareClose.current = callback;
              }}
              item={item}
              t={t}
              focusFirstItem={socialShare.open === 'open'}
            ></Share>
            <span className="more">
              <a href="#" aria-label="open more share options" onClick={(e) => moreSocialLink(e)}>
                <i className="icon-more-black "></i>
              </a>
            </span>
            <span className="close">
              <a href="#" aria-label="Close more share options" onClick={(e) => closeSocialLink(e)}>
                <i className="icon-close-black"></i>
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(SocialShareStrip);
