/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import LazyLoad from 'react-lazyload';
import {
  getStatsBarData,
  getSponsorData,
  createDestinationUrl,
  getItemId,
  isApp,
  isCollection,
  createTagArr,
  validateCbr,
  getLoggedInStatus,
} from '../../../utils/utils';
import ImgCrop from '../../Common/ImgCrop';
import Sponsor from '../../Common/Sponsor';
import {
  CONTENT_TYPE,
  REGISTERED,
  ANONYMOUS,
  MUAppDeepLink,
} from '../../../constants/globalConstants';
import StatsBar from '../../Common/StatsBar';
import * as track from '../../../utils/analytics';
import { withTranslation } from 'react-i18next';
import { showLogin } from '../../../utils/gigyaScreen';
import AccessBadge from '../../Common/AccessBadge';
import ErrorBoundary from '../../Common/ErrorBoundary';
import Loader from '../../Common/Loader';
import { useSponsorValue } from '../../../context/sponsorContext';
import { useModal } from '../../Common/Modal';
import loadable from '@loadable/component';
import MU from '../../../constants/muConstants';
const RegistrationModal = loadable(() =>
  import('../../Modals/RegistrationModal/RegistrationModal')
);

const Article = (props) => {
  const {
    fields,
    t,
    gridClass,
    isMatchcenterCard,
    heroVarient,
    navCards,
    container,
    showStatsbar,
    noVariant,
    isArticleDetail,
    isRelative,
  } = props;
  const {
    ShortHeadline,
    Teaser,
    Variant,
    ContentType,
    Image,
    Sponsorby,
    manutds,
    __Published,
    AuthorName,
    ContentAccess,
  } = props.fields;
  const itemId = getItemId(props);
  const { showModal, closeModal, isModalVisible } = useModal();
  const [{ isUserLogin, isLoginChecked, isUserSubscribe, isUserSkyId }] = useSponsorValue();
  let javaScriptLink =
    manutds && manutds.value && manutds.value.DestinationUrl.includes('javascript:');
  const sponsorInfo = Sponsorby && getSponsorData(Sponsorby.fields);
  const getVariant = () => {
    if (isArticleDetail && noVariant) {
      return '';
    } else if (Variant && Variant.value) {
      const a = Variant.value.toLowerCase();
      if (a === 'child' && gridClass && gridClass.includes('mini-card')) {
        return '';
      }
      return a;
    }
    return '';
  };

  const getAnalyticsData = (fields, contentStatus) => {
    const initialData = track.data('card');
    return {
      ...initialData,
      card_name: ShortHeadline && ShortHeadline.value,
      container_type: container,
      item_id: itemId,
      content_type: ContentType && ContentType.value,
      content_status: contentStatus,
      subscription_status: getLoggedInStatus({
        isLoginChecked,
        isUserLogin,
        isUserSubscribe,
        isUserSkyId,
      }),
      tag: manutds && manutds.value && manutds.value.Tags,
      created_datetime: __Published && __Published.value,
      author: AuthorName && AuthorName.value,
      destination_url: manutds && manutds.value && manutds.value.DestinationUrl,
    };
  };

  const trackCard = (fields, e, contentStatus) => {
    track.analytics(getAnalyticsData(fields, contentStatus), e);
  };

  /**
   * Article Card can also cames is MegaNav so we have handle it here..
   */
  const myunitedCardHeading = () => {
    return !javaScriptLink ? headingWithUrl() : headingWithoutUrl();
  };
  const myunitedCard = () => {
    return !javaScriptLink ? cardWithUrl() : cardWithoutUrl();
  };
  const headingWithUrl = () => {
    return (
      <a
        href={createDestinationUrl(manutds.value.DestinationUrl)}
        target={navCards.target ? navCards.target : ''}
      >
        <div role="heading" aria-level="2" className="mu-item__title">
          <span aria-hidden={true}>{ShortHeadline && ShortHeadline.value}</span>
          <span className="screenreader">{`${ShortHeadline.value} ${ContentType.value}`}</span>
        </div>
      </a>
    );
  };
  const headingWithoutUrl = () => {
    return (
      <React.Fragment>
        <a href="javascript:void(0)" onClick={() => showLogin()}>
          <div role="heading" aria-level="2" className="mu-item__title">
            <span aria-hidden={true}>{ShortHeadline && ShortHeadline.value}</span>
            <span className="screenreader">{`${ShortHeadline.value} ${ContentType.value}`}</span>
          </div>
        </a>
      </React.Fragment>
    );
  };

  const sponsorFunction = () => {
    return (
      <Sponsor
        contentType={ContentType && ContentType.value}
        varient={Variant && Variant.value && Variant.value.toLowerCase()}
        sponsorInfo={sponsorInfo}
        grid={gridClass}
        type={CONTENT_TYPE}
        author={AuthorName && AuthorName.value}
        cardName={ShortHeadline && ShortHeadline.value}
        container={container}
        isRelative={isRelative}
        createdDateTime={__Published && __Published.value}
        itemId={itemId}
        tags={manutds && manutds.value && manutds.value.Tags}
      ></Sponsor>
    );
  };

  const cardWithoutUrl = () => {
    return (
      <a href="javascript:void(0)" onClick={() => showLogin()}>
        <div className="img-holder">
          {Image && Image.value && Image.value.Crops && (
            <LazyLoad once={true} offset={100}>
              <ImgCrop
                imgCrop={Image.value.Crops}
                alt={Image.value.AltText}
                title={Image.value.AltText}
                isWebpAvailable={Image.value.isWebpAvailable}
                singleSrc={false}
                gridClass={gridClass}
              ></ImgCrop>
            </LazyLoad>
          )}
        </div>
      </a>
    );
  };
  const cardWithUrl = () => {
    return (
      <a
        href={createDestinationUrl(manutds.value.DestinationUrl)}
        target={navCards.target ? navCards.target : ''}
        tabIndex="-1"
      >
        <div className="img-holder">
          {Image && Image.value && Image.value.Crops && (
            <LazyLoad once={true} offset={100}>
              <ImgCrop
                imgCrop={Image.value.Crops}
                alt={Image.value.AltText}
                title={Image.value.AltText}
                isWebpAvailable={Image.value.isWebpAvailable}
                singleSrc={false}
                gridClass={gridClass}
              ></ImgCrop>
            </LazyLoad>
          )}
        </div>
      </a>
    );
  };

  const onCardClick = (e) => {
    const showCbr = getCbrValue();
    trackCard(fields, e, showCbr && showCbr.accessCheck);
    if (!isApp() && showCbr && showCbr.value) {
      e.preventDefault();
      showModal();
    }
  };
  const getCbrValue = () => {
    return validateCbr({
      isUserLogin,
      isUserSubscribe,
      contentAccess: ContentAccess && ContentAccess.value,
      contentType: ContentType && ContentType.value,
      manutds,
    });
  };

  const successCallback = ({ showContent, isSubscribedContent }) => {
    if (showContent) {
      const destinationUrl = manutds.value.DestinationUrl;
      if (!MU.isIDMEnabled) window.location = createDestinationUrl(destinationUrl);
      else {
        return ['successCallbck', isSubscribedContent, 'destinationUrl', destinationUrl];
      }
    }
  };
  const acessbadgesFunction = () => {
    return (
      <div className="top-badges">
        <AccessBadge
          contentAcessLocal={ContentAccess && ContentAccess.value}
          contentTypeLocal={ContentType && ContentType.value}
          tagListLocal={createTagArr(manutds)}
        />
      </div>
    );
  };

  const renderCBSModal = () => {
    const accessType = getCbrValue().accessCheck;
    return (
      <ErrorBoundary>
        <RegistrationModal
          successCallback={successCallback}
          closeModal={closeModal}
          fallback={<Loader />}
          previousActiveElement={document.activeElement}
          accessType={accessType}
          analyticsObj={getAnalyticsData(fields, accessType)}
        />
      </ErrorBoundary>
    );
  };

  return (
    <React.Fragment>
      {isModalVisible && renderCBSModal()}
      <div
        className={`${gridClass} ${isMatchcenterCard ? 'ratio-matchday' : 'ratio'} ${
          heroVarient ? heroVarient : getVariant()
        }`}
        onClick={(e) => onCardClick(e)}
        data-impression={track.impression({
          card_name: ShortHeadline && ShortHeadline.value,
          content_type: ContentType && ContentType.value,
        })}
      >
        {!navCards && (
          <div className="mu-content">
            <div className="mu-item article-card article">
              <a
                href={createDestinationUrl(manutds.value.DestinationUrl)}
                tabIndex="-1"
                aria-hidden="true"
                target={isApp() && isCollection() ? '_blank' : ''}
              >
                <div className="img-holder">
                  {Image && Image.value && Image.value.Crops && (
                    <LazyLoad once={true} offset={100}>
                      <ImgCrop
                        imgCrop={Image.value.Crops}
                        alt={Image.value.AltText}
                        title={Image.value.AltText}
                        isWebpAvailable={Image.value.isWebpAvailable}
                        singleSrc={false}
                        gridClass={gridClass}
                      ></ImgCrop>
                    </LazyLoad>
                  )}
                </div>
              </a>
              {acessbadgesFunction()}
              {sponsorFunction()}
              <div className="mu-item__info">
                <a href={createDestinationUrl(manutds.value.DestinationUrl)}>
                  {ShortHeadline && (
                    <React.Fragment>
                      <h2 className="mu-item__title">
                        <Text field={ShortHeadline} />
                      </h2>
                      <span className="screenreader">
                        &nbsp;{ContentType && ContentType.value && t('Article')}
                      </span>
                    </React.Fragment>
                  )}
                </a>
                {Teaser && (
                  <p className="mu-item__teasure">
                    <Text field={Teaser} />
                  </p>
                )}
              </div>
              {showStatsbar && (
                <StatsBar
                  item={getStatsBarData(fields)}
                  tagListLocal={createTagArr(manutds)}
                ></StatsBar>
              )}
            </div>
          </div>
        )}
        {navCards && navCards.isMagaNavCard && (
          <div className="mu-content">
            <div className="mu-item article-meganavCurated">
              {acessbadgesFunction()}
              {sponsorFunction()}
              {navCards && navCards.isMyunited ? myunitedCard() : cardWithUrl()}
              <div className="mu-item__info">
                {navCards && navCards.isMyunited ? myunitedCardHeading() : headingWithUrl()}
                {navCards && navCards.isMyunited && (
                  <p className="mu-item__teasure test">{Teaser && Teaser.value}</p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

Article.defaultProps = {
  showStatsbar: true,
  isRelative: false,
};

export default withTranslation()(Article);
