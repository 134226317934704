/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import { getMatchDate } from '../../../utils/momentDate';
const LeagueMatch = ({ matchData }) => {
  const matchdate = matchData && matchData.MatchDate;
  const setCrestImage = (teameType) => {
    if (teameType === 'home') {
      return matchData && matchData.homeTeamCrestImage;
    } else {
      return matchData && matchData.awayTeamCrestImage;
    }
  };

  const renderLeagueInfo = () => {
    console.log('matchData', matchData);
    return (
      <React.Fragment>
        {matchData && (
          <div className="matches-league">
            <span className="team-ico">
              <img data-share-src src={setCrestImage('home')} alt="" title="" />
            </span>
            <div className="team-league-description" data-test="@localTime">
              <h2>
                {matchData && matchData.homeTeam} VS {matchData && matchData.awayTeam}
              </h2>
              <p>
                {matchData && matchData.competitionName}, {matchData && matchData.venueName},
                {getMatchDate(matchdate, 'dddd,  MMMM DD, YYYY HH:mm')} BST
              </p>
            </div>
            <span className="team-ico">
              <img data-share-src src={setCrestImage('away')} alt="" title="" />
            </span>
          </div>
        )}
      </React.Fragment>
    );
  };
  return (
    <div>
      <section className="travel__report">
        <input type="hidden" id="travel__matchId" defaultValue="@matchId" />
        {renderLeagueInfo()}
      </section>
    </div>
  );
};

export default withTranslation()(React.memo(LeagueMatch));
