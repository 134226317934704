/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
const FullWidthContainer = (props) => (
  <React.Fragment>
    {isExperienceEditorActive() && (
      <div>
        <b>
          Section Full Width Container
          <br />
        </b>
      </div>
    )}
    <div>
      <Placeholder
        name="mufc-ph-fullwidthcontainer"
        rendering={props.rendering}
        missingComponentComponent={() => {
          return null;
        }}
      />
    </div>
  </React.Fragment>
);

export default FullWidthContainer;
