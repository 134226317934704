/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import Attacking from './Attacking';
import Defending from './Defending';
import General from './General';
import Goalkeeping from './Goalkeeping';
import MatchGoals from './MatchGoals';
import Passing from './Passing';
import Saves from './Saves';
import SeasonsGoal from './SeasonsGoal';
import Shooting from './Shooting';
const ProfileStatsListing = ({ statsType, statsData }) => {
  const getComponents = (item) => {
    if (item.groupName === 'stats_Attacking')
      return <Attacking item={item} statsType={statsType} />;
    else if (item.groupName === 'stats_Defending')
      return <Defending item={item} statsType={statsType} />;
    else if (item.groupName === 'stats_General')
      return <General item={item} statsType={statsType} />;
    else if (item.groupName === 'stats_Goalkeeping')
      return <Goalkeeping item={item} statsType={statsType} />;
    else if (item.groupName === 'stats_Shooting')
      return <Shooting item={item} statsType={statsType} />;
    else if (item.groupName === 'stats_Goals' && statsType === 'season')
      return <SeasonsGoal item={item} statsType={statsType} />;
    else if (item.groupName === 'stats_Goals' && statsType === 'live')
      return <MatchGoals item={item} statsType={statsType} goalType={`goal`} />;
    else if (item.groupName === 'stats_Passing')
      return <Passing item={item} statsType={statsType} />;
    else if (item.groupName === 'stats_Saves' && statsType === 'season')
      return <Saves item={item} statsType={statsType} />;
    else if (item.groupName === 'stats_Saves' && statsType === 'live')
      return <MatchGoals item={item} statsType={statsType} goalType={`save`} />;
  };
  const getStatsTemplate = (statsData) => {
    return statsData.map((item, key) => {
      return (
        <div key={key} className="main-wrapper">
          <div className="grid-4">{getComponents(item)}</div>
        </div>
      );
    });
  };
  return (
    <div className="playerstats-charts">
      {statsData && statsData.length > 0 && getStatsTemplate(statsData)}
    </div>
  );
};
export default ProfileStatsListing;
