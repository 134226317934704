/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import Helmet from 'react-helmet';
import MU from '../../constants/muConstants';
const OgTag = ({ item, isAMP }) => {
  const getCanonicalLink = () => {
    return item.canonical && <link rel="canonical" href={item.canonical} />;
  };

  const getAMPLink = () => {
    return item.amphtml && <link rel="amphtml" href={item.amphtml} />;
  };

  const getPageTitle = () => {
    return item.pageTitle && <title>{item.pageTitle}</title>;
  };

  const getOGForNonAMP = () => {
    return (
      <Helmet>
        <meta name="theme-color" content={item.themeColor || '#c70101'} />
        {item.twitter_card && <meta name="twitter:card" content={item.twitter_card} />}
        {item.og_type && <meta name="og:type" content={item.og_type} />}
        {item.twitter_site && <meta name="twitter:site" content={item.twitter_site} />}
        {item.twitter_app_id_iphone && (
          <meta name="twitter:app:id:iphone" content={item.twitter_app_id_iphone} />
        )}
        {item.twitter_App_Id_GooglePlay && (
          <meta name="twitter:app:id:googleplay" content={item.twitter_App_Id_GooglePlay} />
        )}
        {item.twitter_App_Name_Iphone && (
          <meta name="twitter:app:name:iphone" content={item.twitter_App_Name_Iphone} />
        )}
        {item.twitter_App_Name_GooglePlay && (
          <meta name="twitter:app:name:googleplay" content={item.twitter_App_Name_GooglePlay} />
        )}
        {item.twitter__player_width && (
          <meta name="twitter:player:width" content={item.twitter__player_width} />
        )}
        {item.twitter__player_height && (
          <meta name="twitter:player:height" content={item.twitter__player_height} />
        )}
        {item.twitter_player && <meta name="twitter:player" content={item.twitter_player} />}
        {item.twittersite && <meta name="twitter:site" content={item.twittersite} />}
        {item.app_Id && <meta name="fb:app_id" content={item.app_Id} />}
        {item.og_image_height && <meta name="og:image:height" content={item.og_image_height} />}
        {item.og_image_width && <meta name="og:image:width" content={item.og_image_width} />}
        {<meta property="og:url" content={item.og_url} />}
        {item.twitter_url && <meta property="twitter:url" content={item.twitter_url} />}
        {item.apple_itunes_app && <meta name="apple-itunes-app" content={item.apple_itunes_app} />}
        {item.og_image && <meta property="og:image" content={item.og_image} />}
        {item.twitter_image && <meta name="twitter:image" content={item.twitter_image} />}
        {item.og_title && <meta property="og:title" content={item.og_title} />}
        {item.twitter_title && <meta name="twitter:title" content={item.twitter_title} />}
        {item.og_description && <meta property="og:description" content={item.og_description} />}
        {item.twitter_description && (
          <meta name="twitter:description" content={item.twitter_description} />
        )}
        {item.media && <meta name="media" content={item.media} />}
        {item.keyWords && <meta name="keywords" content={item.keyWords} />}
        {item.description && <meta name="description" content={item.description} />}
        {getCanonicalLink()}
        {getAMPLink()}
        {getPageTitle()}
      </Helmet>
    );
  };

  const getOGForAMP = () => {
    let ampHeads;
    const defaultAMPHead = (
      <Helmet>
        {getCanonicalLink()}
        {getPageTitle()}
      </Helmet>
    );
    try {
      if (MU && MU.language && item.og_url) {
        ampHeads = (
          <Helmet>
            {hasLanguage('en') && (
              <link
                rel="alternate"
                hrefLang="en"
                href={item.og_url.replace(`/${MU.language}/`, '/en/')}
              />
            )}
            {hasLanguage('zh') && (
              <link
                rel="alternate"
                hrefLang="zh"
                href={item.og_url.replace(`/${MU.language}/`, '/zh/')}
              />
            )}
            {hasLanguage('ja') && (
              <link
                rel="alternate"
                hrefLang="ja"
                href={item.og_url.replace(`/${MU.language}/`, '/ja/')}
              />
            )}
            {hasLanguage('ko') && (
              <link
                rel="alternate"
                hrefLang="ko"
                href={item.og_url.replace(`/${MU.language}/`, '/ko/')}
              />
            )}
            {hasLanguage('ar') && (
              <link
                rel="alternate"
                hrefLang="ar"
                href={item.og_url.replace(`/${MU.language}/`, '/ar/')}
              />
            )}
            {hasLanguage('es') && (
              <link
                rel="alternate"
                hrefLang="es"
                href={item.og_url.replace(`/${MU.language}/`, '/es/')}
              />
            )}
            {hasLanguage('fr') && (
              <link
                rel="alternate"
                hrefLang="fr"
                href={item.og_url.replace(`/${MU.language}/`, '/fr/')}
              />
            )}
            {getCanonicalLink()}
            {getAMPLink()}
            {getPageTitle()}
          </Helmet>
        );
      } else {
        ampHeads = defaultAMPHead;
      }
    } catch (e) {
      ampHeads = defaultAMPHead;
    }
    return ampHeads;
  };

  const hasLanguage = (language) => {
    let languageExists = false;
    try {
      if (item && item.languageVersionsAvailable && item.languageVersionsAvailable.length > 0) {
        languageExists = item.languageVersionsAvailable.indexOf(language) > -1 ? true : false;
      }
    } catch (e) {
      languageExists = false;
    }
    return languageExists;
  };

  return item && (MU && MU.isAmpPageUrl ? getOGForAMP() : getOGForNonAMP());
};

export default React.memo(OgTag);
