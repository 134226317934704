/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/**
 * Video constants
 */
export const SHOW_VIDEOS = `FETCH_VIDEOS`;
export const SET_VIDEOS = `SET_VIDEOS`;
export const ERROR_VIDEOS = `ERROR_VIDEOS`;

/**
 * News & Video constants
 */
export const FETCH_NEWS_VIDEO_LIST = `FETCH_NEWS_VIDEO_LIST`;
export const SET_NEWS_VIDEO_LIST = `SET_NEWS_VIDEO_LIST`;
export const ERROR_NEWS_VIDEO_LIST = `ERROR_NEWS_VIDEO_LIST`;

/**
 * Sponsor constants
 */
export const FETCH_SPONSOR = `FETCH_SPONSOR`;
export const SET_SPONSOR = `SET_SPONSOR`;
export const ERROR_SPONSOR = `ERROR_SPONSOR`;
export const SET_SPONSOR_ON_RESIZE = `SET_SPONSOR_ON_RESIZE`;

/**
 * Matches constants
 */
export const FETCH_MATCHES = `FETCH_MATCHES`;
export const SET_MATCHES_FIXTURE = `SET_MATCHES_FIXTURE`;
export const SET_MATCHES_RESULT = `SET_MATCHES_RESULT`;
export const ERROR_MATCHES = `ERROR_MATCHES`;
export const FETCH_TABLES = `FETCH_TABLES`;
export const SET_TABLES = `SET_TABLES`;

/**
 * Match Center constants
 */
export const FETCH_MATCH_CENTER = `FETCH_MATCH_CENTER`;
export const SET_MATCH_CENTER = `SET_MATCH_CENTER`;
export const ERROR_MATCH_CENTER = `ERROR_MATCH_CENTER`;
export const FETCH_SPOTLIGHT = 'FETCH_SPOTLIGHT';
export const SET_SPOTLIGHT = 'SET_SPOTLIGHT';
export const ERROR_SPOTLIGHT = 'ERROR_SPOTLIGHT';
export const IS_IN_VIEW = 'IS_IN_VIEW';
export const UPDATE_FEED = 'UPDATE_FEED';

/**
 * Live-Event constants
 */
export const FETCH_LIVE_EVENT = `FETCH_LIVE_EVENT`;
export const SET_LIVE_EVENT = `SET_LIVE_EVENT`;
export const ERROR_LIVE_EVENT = `ERROR_LIVE_EVENT`;
export const UPDATE_LIVE_FEED = `UPDATE_Live_FEED`;
export const IS_IN_VIEW_PORT = `IS_IN_VIEW_PORT`;

/**
 * MyUnited constants
 */
export const FETCH_ALLPLAYER = `FETCH_ALLPLAYER`;
export const SET_ALLPLAYER = `SET_ALLPLAYER`;
export const SET_WOMENSPLAYER = `SET_WOMENSPLAYER`;
export const ERROR_ALLPLAYER = `ERROR_ALLPLAYER`;

export const FETCH_FAVPLAYER = `FETCH_FAVPLAYER`;
export const SET_FAVPLAYER = `SET_FAVPLAYER`;
export const ERROR_FAVPLAYER = `ERROR_FAVPLAYER`;

/**
 * Coming up Carousel constants
 */
export const FETCH_COMINGUPDATA = `FETCH_COMINGUPDATA`;
export const SET_COMINGUPDATA = `SET_COMINGUPDATA`;
export const ERROR_COMINGUPDATA = `ERROR_COMINGUPDATA`;

/**
 * United Config constants
 */
export const FETCH_UNITEDCONFIGDATA = `FETCH_UNITEDCONFIGDATA`;
export const SET_UNITEDCONFIGDATA = `SET_UNITEDCONFIGDATA`;
export const ERROR_UNITEDCONFIGDATA = `ERROR_UNITEDCONFIGDATA`;

/*
 *   Player Details Constant
 */
export const FETCH_PLAYER_STATS = 'FETCH_PLAYER_STATS';
export const FETCH_SEASON_STATS = 'FETCH_SEASON_STATS';
export const SET_PLAYER_STATS = 'SET_PLAYER_STATS';
export const SET_SEASON_STATS = 'SET_SEASON_STATS';
export const ERROR_PLAYER_STATS = 'ERROR_PLAYER_STATS';
export const ERROR_SEASON_STATS = 'ERROR_SEASON_STATS';

/**
 * Player Grid constants
 */
export const FETCH_PLAYER_GRID = `FETCH_PLAYER_GRID`;
export const SET_PLAYER_GRID = `SET_PLAYER_GRID`;
export const ERROR_PLAYER_GRID = `ERROR_PLAYER_GRID`;

/**
 * Search constants
 */
export const FETCH_SEARCH_RESULT = `FETCH_SEARCH_RESULT`;
export const SET_SEARCH_RESULT = `SET_SEARCH_RESULT`;
export const ERROR_SEARCH_RESULT = `ERROR_SEARCH_RESULT`;

export const FETCH_PREDICTION = `FETCH_PREDICTION`;
export const SET_PREDICTION = `SET_PREDICTION`;
export const ERROR_PREDICTION = `ERROR_PREDICTION`;

/**
 * Gigya constants
 */
export const FETCHED_GIGYA = `FETCHED_GIGYA`;
export const SET_GIGYA = `SET_GIGYA`;
export const ERROR_GIGYA = `ERROR_GIGYA`;
export const SET_USER_STATUS = `SET_USER_STATUS`;
export const SET_HELP_RESULTS = `SET_HELP_RESULTS`;
export const RESET_USER_FAV_PLAYER = `RESET_USER_FAV_PLAYER`;

/**
 * Musc constants
 */
export const FETCH_MATCH_TICKET = `FETCH_MATCH_TICKET`;
export const SET_MATCH_TICKET = `SET_MATCH_TICKET`;
export const ERROR_MATCH_TICKET = `ERROR_MATCH_TICKET`;

export const FETCH_MEMBER_SELECTION = `FETCH_MEMBER_SELECTION`;
export const SET_MEMBER_SELECTION = `SET_MEMBER_SELECTION`;
export const ERROR_MEMBER_SELECTION = `ERROR_MEMBER_SELECTION`;

export const FETCH_PREVIOUS_ORDER = `FETCH_PREVIOUS_ORDER`;
export const SET_PREVIOUS_ORDER = `SET_PREVIOUS_ORDER`;
export const ERROR_PREVIOUS_ORDER = `ERROR_PREVIOUS_ORDER`;

export const FETCH_ORDER_DETAIL = `FETCH_ORDER_DETAIL`;
export const SET_ORDER_DETAIL = `SET_ORDER_DETAIL`;
export const ERROR_ORDER_DETAIL = `ERROR_ORDER_DETAIL`;

export const FETCH_USER_DETAIL = `FETCH_USER_DETAIL`;
export const SET_USER_DETAIL = `SET_USER_DETAIL`;
export const ERROR_USER_DETAIL = `ERROR_USER_DETAIL`;

export const SUBMIT_USER_REQUEST = `SUBMIT_USER_REQUEST`;
export const GET_USER_REQUEST = `GET_USER_REQUEST`;
export const ERROR_USER_REQUEST = `ERROR_USER_REQUEST`;

export const SUBMIT_CONFIRM_TICKET = `CONFIRM_TICKET`;
export const GET_CONFIRM_TICKET = `GET_CONFIRM_TICKET`;
export const ERROR_CONFIRM_TICKET = `ERROR_CONFIRM_TICKET`;

export const USER_CONFIRMATION = 'USER_CONFIRMATION';

/* Car Parking constants
 */
export const FETCH_CARPARKING = `FETCH_CARPARKING`;
export const SET_CARPARKING = `SET_CARPARKING`;
export const ERROR_CARPARKING = `ERROR_CARPARKING`;

export const FETCH_PERSIST_REPORT = `FETCH_PERSIST_REPORT`;
export const SET_PERSIST_REPORT = `SET_PERSIST_REPORT`;
export const ERROR_PERSIST_REPORT = `ERROR_PERSIST_REPORT`;

export const GENERIC_FETCH_SEARCH_REPORT = `GENERIC_FETCH_SEARCH_REPORT`;
export const SET_GENERIC_SEARCH_REPORT = `SET_GENERIC_SEARCH_REPORT`;
export const ERROR_GENERIC_SEARCH_REPORT = `ERROR_GENERIC_SEARCH_REPORT`;

export const FETCH_VENU_TRAVEL_REPORT = `FETCH_VENU_TRAVEL_REPORT`;
export const SET_VENU_TRAVEL_REPORT = `SET_VENU_TRAVEL_REPORT`;
export const ERROR_VENU_TRAVEL_REPORT = `ERROR_VENU_TRAVEL_REPORT`;

export const FETCH_MU_TRAVEL_REPORT = `FETCH_MU_TRAVEL_REPORT`;
export const SET_MU_TRAVEL_REPORT = `SET_MU_TRAVEL_REPORT`;
export const ERROR_MU_TRAVEL_REPORT = `ERROR_MU_TRAVEL_REPORT`;

export const FETCH_PERSIST_PROXY = `FETCH_PERSIST_PROXY`;
export const SET_PERSIST_PROXY = `SET_PERSIST_PROXY`;
export const ERROR_PERSIST_PROXY = `ERROR_PERSIST_PROXY`;

export const SEND_EMAIL_USER_DATA = `SEND_EMAIL_USER_DATA`;
export const SET_EMAIL_USER_DATA = `SET_EMAIL_USER_DATA`;
export const ERROR_EMAIL_USER_DATA = `ERROR_EMAIL_USER_DATA`;

export const GENERIC_FETCH_PERSISTS = `GENERIC_FETCH_PERSISTS`;
export const GENERIC_SET_PERSISTS = `GENERIC_SET_PERSISTS`;
export const GENERIC_ERROR_PERSISTS = `GENERIC_ERROR_PERSISTS`;

export const FETCH_EMAIL_TO_USER = `FETCH_EMAIL_TO_USER`;
export const SET_EMAIL_TO_USER = `SET_EMAIL_TO_USER`;
export const ERROR_EMAIL_TO_USER = `ERROR_EMAIL_TO_USER`;

export const FETCH_FAN_FEEDBACK = `FETCH_FAN_FEEDBACK`;
export const SET_FAN_FEEDBACK = `SET_FAN_FEEDBACK`;
export const ERROR_FAN_FEEDBACK = `ERROR_FAN_FEEDBACK`;

export const FETCHED_CAPTCHA = `FETCHED_CAPTCHA`;

/**
 * Mutv constants
 */
export const FETCH_MUTV_LIST = `FETCH_MUTV_LIST`;
export const SET_MUTV_LIST = `SET_MUTV_LIST`;
export const ERROR_MUTV_LIST = `ERROR_MUTV_LIST`;

/*Show All constant */
export const FETCH_SHOW_LIST = `FETCH_SHOW_LIST`;
export const SET_SHOW_LIST = `SET_SHOW_LIST`;
export const ERROR_SHOW_LIST = `ERROR_SHOW_LIST`;

export const SET_MUTV_VIDEO_DATA = `SET_MUTV_VIDEO_DATA`;

/*MyList constant */
export const FETCH_MY_LIST_DATA = `FETCH_MY_LIST_DATA`;
export const SET_MY_LIST_DATA = `SET_MY_LIST_DATA`;
export const ERROR_MY_LIST_DATA = `ERROR_MY_LIST_DATA`;

export const FETCH_MY_LIST_REPORT = `FETCH_MY_LIST_REPORT`;
export const SET_MY_LIST_REPORT = `SET_MY_LIST_REPORT`;
export const ERROR_MY_LIST_REPORT = `ERROR_MY_LIST_REPORT`;

export const SHOW_MY_LIST = `SHOW_MY_LIST`;
export const SET_SHOW_MY_LIST = `SET_SHOW_MY_LIST`;
export const ERROR_SHOW_MY_LIST = `ERROR_SHOW_MY_LIST`;
export const DELETE_CONFIRMATION = `DELETE_CONFIRMATION`;
export const LOCAL_DATA = `LOCAL_DATA`;
export const SET_RELOAD = `SET_RELOAD`;

/**
 * Device Mangaement Constants
 */
export const SET_DEVICE_MANAGE = `SET_DEVICE_MANAGE`;
export const CONTINUE_ON_OTHER = 'CONTINUE_ON_OTHER';
export const PLAY_HERE = 'PLAY_HERE';

export const SET_MUTV_HERO_VIDEO_STATUS = `SET_MUTV_HERO_VIDEO_STATUS`;
