/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { getPublisherType1, jsonStringify } from '../../utils/structureData';
const VideoData = (props) => {
  const { videoStructureData } = props.structureData;
  const data = videoStructureData && {
    '@context': videoStructureData.context,
    '@type': videoStructureData.type,
    name: videoStructureData.name,
    description: videoStructureData.description,
    thumbnailUrl: videoStructureData.thumbnailUrl,
    uploadDate: videoStructureData.uploadDate,
    duration: videoStructureData.duration,
    contentUrl: videoStructureData.contentUrl,
    publisher: getPublisherType1(videoStructureData.publisher),
  };
  return videoStructureData ? (
    <Helmet>
      <script data-schema="VideoObject" type="application/ld+json">
        {`${jsonStringify(data)}`}
      </script>
    </Helmet>
  ) : null;
};
export default VideoData;
