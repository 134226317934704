/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import LeftPanel from './LeftPanel';
import StatsBar from '../Common/StatsBar';
import SponsorPanel from './SponsorPanel';
import ImagePanel from './ImagePanel';
import { getStatsBarData } from '../../utils/utils';
import ErrorBoundary from '../Common/ErrorBoundary';
const HeadToHeadPercentage = ({ item, t, gridClass, carousalClass }) => {
  const isPlayer =
    item && item.opta_statentitytype_t && item.opta_statentitytype_t.toLowerCase() === 'player'
      ? true
      : false;
  return (
    <div className={`${gridClass} ${isPlayer ? 'player-section' : ''}`}>
      <div className={carousalClass}>
        <div
          className={`stats-card head-to-head-percentage head-to-head-number ${
            item.IsMUEntityClass
          } ${isPlayer ? 'player-section' : 'team-section'}`}
        >
          <SponsorPanel item={item} />
          <div className="stats-card__info">
            <LeftPanel item={item} />
            <div className="stats-card__info__right">
              <div className="chart-area">
                <div className="players">
                  <div className="screenreader">
                    {isPlayer && item.opta_statentities_t && item.opta_statentities_t.length > 1
                      ? `${
                          item.opta_statentities_t[0].KnownName
                            ? item.opta_statentities_t[0].KnownName
                            : item.opta_statentities_t[0].LastName
                        } ${t('Shirtnumber')} ${item.opta_statentities_t[0].JerseyNumber} ${t(
                          'VS'
                        )} ${
                          item.opta_statentities_t[1].KnownName
                            ? item.opta_statentities_t[1].KnownName
                            : item.opta_statentities_t[1].LastName
                        } ${t('Shirtnumber')} ${item.opta_statentities_t[1].JerseyNumber}`
                      : `${item.opta_statentities_t[0].TeamName} ${t('VS')} ${
                          item.opta_statentities_t[1].TeamName
                        }`}
                  </div>

                  <div
                    className={`players-vs-player  ${
                      isPlayer ? 'player-only' : 'team-image team-only'
                    }`}
                    aria-hidden="true"
                  >
                    <div className="player__host">
                      <div className={`player-img ${isPlayer ? 'player-image' : 'team-image'}`}>
                        <ErrorBoundary>
                          <ImagePanel item={item.opta_statentities_t[0]} statsType={isPlayer} />
                        </ErrorBoundary>
                        {isPlayer && (
                          <React.Fragment>
                            <span className="notification">
                              {item.opta_statentities_t[0].JerseyNumber}
                            </span>
                            <h3>
                              {item.opta_statentities_t[0].KnownName
                                ? item.opta_statentities_t[0].KnownName
                                : item.opta_statentities_t[0].LastName}
                            </h3>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                    <div className="vs">
                      <h3></h3>
                      <div>
                        <span>{t('VS')}</span>
                      </div>
                      <h3></h3>
                    </div>
                    <div className="player__opponent">
                      <div
                        className={`player-img ${isPlayer ? 'player-image' : 'team-image'}`}
                        aria-hidden={isPlayer ? true : false}
                      >
                        <ErrorBoundary>
                          <ImagePanel item={item.opta_statentities_t[1]} statsType={isPlayer} />
                        </ErrorBoundary>
                        <span className="notification">
                          {item.opta_statentities_t[1].JerseyNumber}
                        </span>
                        <h3>
                          {item.opta_statentities_t[1].KnownName
                            ? item.opta_statentities_t[1].KnownName
                            : item.opta_statentities_t[1].LastName}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="players__match-details">
                    <ul>
                      {item.filterEntitiObj.map((keyObj, key) => (
                        <li key={key}>
                          <span className="scoreA" aria-hidden="true">
                            {`${keyObj[0].Value}${
                              keyObj[0].IsPercentage === 'true' || keyObj[0].IsPercentage === true
                                ? '%'
                                : ''
                            }`}
                          </span>
                          <span className="score-info" aria-hidden="true">
                            {keyObj[0].StatsName}
                          </span>
                          <span className="scoreB" aria-hidden="true">
                            {`${keyObj[1].Value}${
                              keyObj[1].IsPercentage === 'true' || keyObj[1].IsPercentage === true
                                ? '%'
                                : ''
                            }`}
                          </span>
                          <div className="screenreader">
                            {isPlayer
                              ? `${keyObj[0].Value} ${
                                  keyObj[0].IsPercentage === 'true' ||
                                  keyObj[0].IsPercentage === true
                                    ? '%'
                                    : ''
                                } ${keyObj[0].StatsName} ${t('by')} ${
                                  item.opta_statentities_t[0].KnownName
                                    ? item.opta_statentities_t[0].KnownName
                                    : item.opta_statentities_t[0].LastName
                                }
                                ${keyObj[1].Value} ${
                                  keyObj[1].IsPercentage === 'true' ||
                                  keyObj[1].IsPercentage === true
                                    ? '%'
                                    : ''
                                } ${keyObj[1].StatsName} ${t('by')} ${
                                  item.opta_statentities_t[1].KnownName
                                    ? item.opta_statentities_t[1].KnownName
                                    : item.opta_statentities_t[1].LastName
                                } `
                              : ` ${keyObj[0].Value} ${
                                  keyObj[0].IsPercentage == 'true' || keyObj[0].IsPercentage == true
                                    ? '%'
                                    : ''
                                } ${keyObj[0].StatsName} ${t('by')} ${
                                  item.opta_statentities_t[0].TeamName
                                }
                              ${keyObj[1].Value} ${
                                  keyObj[1].IsPercentage == 'true' || keyObj[1].IsPercentage == true
                                    ? '%'
                                    : ''
                                } ${keyObj[1].StatsName} ${t('by')} ${
                                  item.opta_statentities_t[1].TeamName
                                } `}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <StatsBar item={getStatsBarData(item)} type="statsCard"></StatsBar>
        </div>
      </div>
    </div>
  );
};
export default withTranslation()(HeadToHeadPercentage);
