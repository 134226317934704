/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { getSchudleTime, setLiveText } from '../mutvutil';
import { withTranslation } from 'react-i18next';
const HeroNotes = (props) => {
  const { heroDetail, t } = props;
  const [readMore, setReadMore] = useState(false);
  const [isLongDescription, setLongDescription] = useState(false);

  useEffect(() => {
    if (heroDetail[0] && heroDetail[0].description && heroDetail[0].description.length > 0) {
      const screenWidth = window.innerWidth;
      if (screenWidth && screenWidth < 768 && heroDetail[0].description.length > 250) {
        setLongDescription(heroDetail[0].description.slice(0, 250));
      } else if (heroDetail[0].description.length > 450) {
        setLongDescription(heroDetail[0].description.slice(0, 450));
      } else {
        setLongDescription(false);
      }
    } else {
      setLongDescription(false);
    }
  }, []);
  return (
    <div className="container epg-carousel-outer">
      {heroDetail && heroDetail[0] && (
        <div className="epg-hero episode-detail">
          <div className="episode-detail__left">
            <div className="current-watching">
              <span className="icon-video-mutv"></span>
              {/* <span className="blink">
                <i className="icon-live-blink"></i>
              </span> */}
              <span className="blink">
                {setLiveText(heroDetail[0].scheduletype_t) ? (
                  <i className="icon-live-blink"></i>
                ) : (
                  <i className="ellipse"></i>
                )}
              </span>
              <span className="current-watching-text">
                {t('NowWatching')} - {getSchudleTime(heroDetail[0].startdatetime_tdt)}
              </span>
            </div>
            <h2 className="episode-title">{heroDetail[0].title}</h2>
            {!readMore ? (
              <p className="episode-description">
                {isLongDescription ? isLongDescription : heroDetail[0].description}
                {isLongDescription && <a onClick={() => setReadMore(!readMore)}>{t('Viewmore')}</a>}
              </p>
            ) : (
              <p className={'episode-description'}>
                {heroDetail[0].description}
                {isLongDescription && <a onClick={() => setReadMore(!readMore)}>{t('ViewLess')}</a>}
              </p>
            )}
          </div>

          {heroDetail[1] && (
            <div className="episode-detail__right">
              <div className="next-watching">
                {t('OnNext')} - {getSchudleTime(heroDetail[1].startdatetime_tdt)}
              </div>
              <div className="next-title">{heroDetail[1].title}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(HeroNotes);
