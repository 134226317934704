/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useRef, useState } from 'react';
import { cardContenttype, cardDataExtractorUtil, isClipVideo } from '../mutvutil';
import MutvCards from '../Cards/MutvCards';
import Loader from '../../Common/Loader';
import { useSponsorValue } from '../../../context/sponsorContext';

const CarouselListing = (props) => {
  const {
    cardData,
    listView,
    status,
    showLoadMore,
    loadMore,
    inlinePlay,
    dispatch,
    initialLoad,
    containerType,
    isContinueCard,
    showDeleteBtn,
    onDeleteItem,
    idsToDeletes,
    mylistPage,
    isMutvMovie,
  } = props;
  const [
    { isLoginChecked, userLoginDetail, isUserLogin, isUserSubscribe, isUserSkyId },
  ] = useSponsorValue();
  const [page, setPage] = useState(0);
  const infiniteScroll = useRef(null);

  useEffect(() => {
    var options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (infiniteScroll.current) {
      observer.observe(infiniteScroll.current);
    }
    return () => {
      infiniteScroll.current && observer.unobserve(infiniteScroll.current);
    };
  }, []);

  useEffect(() => {
    if (page && showLoadMore && status !== 'fetching') {
      loadMore();
    }
  }, [page]);

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting) {
      setPage((page) => page + 1);
    }
  };

  const renderCards = (aspectRatio, index, item) => {
    if (item && cardContenttype.includes(item.contenttype_t && item.contenttype_t.toLowerCase())) {
      return (
        <div className="filterCards">
          {item && (
            <MutvCards
              containerType={containerType}
              carouselContentType={item.contenttype_t}
              cardAspectRatio={aspectRatio ? aspectRatio : '4x3'}
              gridClass="grid-3"
              key={index}
              item={item}
              inlinePlay={inlinePlay ? true : false}
              isUserLogin={isUserLogin}
              isLoginChecked={isLoginChecked}
              userLoginDetail={userLoginDetail}
              dispatch={dispatch}
              isUserSubscribe={isUserSubscribe}
              isUserSkyId={isUserSkyId}
              isContinueCard={isContinueCard}
              showDeleteBtn={showDeleteBtn}
              onDeleteItem={onDeleteItem}
              isDeleteChecked={idsToDeletes && idsToDeletes.find((val) => val === item.itemid_s)}
              mylistPage={mylistPage}
              isMutvMovie={isMutvMovie}
            />
          )}
        </div>
      );
    } else {
      return null;
    }
  };
  return (
    <React.Fragment>
      <div className={`mutv-shows__filter-data ${listView ? 'row-pattern' : ''}`} tabIndex="none">
        <div className="mutv-shows__items" tabIndex="none">
          {cardData &&
            cardData.length > 0 &&
            cardData.map((item, index) => {
              return renderCards(props.aspectRatio, index, item);
            })}
          {status === 'fetching' && (
            <div
              className="loader-carouselListing__wrapper"
              style={initialLoad ? { paddingTop: '70px' } : null}
            >
              <Loader className="loader-carouselListing" />
            </div>
          )}
        </div>
      </div>
      <div
        className="infinite_Scroll"
        style={{ display: 'inline-block', position: 'relative' }}
        ref={infiniteScroll}
      >
        {showLoadMore && status !== 'fetching' && <button style={{ visibility: 'hidden' }} />}
      </div>
    </React.Fragment>
  );
};
CarouselListing.defaultProps = {
  inlinePlay: false,
};
export default CarouselListing;
