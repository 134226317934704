/**
 *  © 2022-2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import moment from 'moment';

export const InputText = ({
  index,
  item,
  onChangeFunction,
  formError,
  validation,
  formEnteredValue,
  userInputValue,
}) => {
  let showError = getValue(formError, `FieldValue-${index + 1}`);
  return (
    <React.Fragment>
      <div className="elemContainer">
        <input
          type="text"
          className={`formElem inputText ${showError && 'is_error'}`}
          aria-label={item && item.Title && item.Title}
          aria-invalid={showError ? true : false}
          aria-required={item.True}
          name={`FieldValue-${index + 1}`}
          data-validation-object={JSON.stringify(validation)}
          onChange={(e) => onChangeFunction(e, item, validation)}
          value={formEnteredValue ? formEnteredValue : ''}
        />
        {showError && (
          <>
            <span className="red-cross-icon"></span>
            <span role="alert" className="formError">
              {showError}
            </span>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export const TextareaField = ({
  index,
  item,
  fields,
  onChangeFunction,
  formError,
  validation,
  formEnteredValue,
}) => {
  let showError = getValue(formError, `FieldValue-${index + 1}`);
  return (
    <React.Fragment>
      <textarea
        type="text"
        className={`formElem inputText inputTextArea ${showError && 'is_error'}`}
        aria-label={item && item.Title && item.Title}
        aria-invalid={showError ? true : false}
        aria-required={item.True}
        placeholder={fields && fields.HelpText && fields.HelpText.value && fields.HelpText.value}
        name={`FieldValue-${index + 1}`}
        data-validation-object={JSON.stringify(validation)}
        onChange={(e) => onChangeFunction(e, item, validation)}
        value={formEnteredValue ? formEnteredValue : ''}
      />
      {showError && (
        <span role="alert" className="formError">
          {showError}
        </span>
      )}
    </React.Fragment>
  );
};

export const SelectBox = ({
  index,
  item,
  fields,
  onChangeFunction,
  formError,
  validation,
  formEnteredValue,
}) => {
  let showError = getValue(formError, `FieldValue-${index + 1}`);
  let options =
    fields &&
    fields.manutds &&
    fields.manutds.value &&
    fields.manutds.value.Options &&
    fields.manutds.value.Options;
  let additionalSelectOptions =
    fields && fields.AdditionalSelectOptions && fields.AdditionalSelectOptions;
  let selectoptions = options && arrayToObj(options);
  const Options =
    additionalSelectOptions && additionalSelectOptions.length > 0
      ? additionalSelectOptions
      : selectoptions && selectoptions.length > 0
      ? selectoptions
      : null;
  return (
    <React.Fragment>
      <div className="elemContainer">
        <select
          className={`formElem inputSelectBox ${showError && 'is_error'}`}
          aria-label={item && item.Title && item.Title}
          aria-invalid={showError ? true : false}
          aria-required={item.True}
          name={`FieldValue-${index + 1}`}
          onChange={(e) => onChangeFunction(e, item, validation)}
          data-validation-object={JSON.stringify(validation)}
          value={formEnteredValue ? formEnteredValue : ''}
        >
          <option
            value=""
            selected
            disabled="disabled"
            aria-label={fields && fields.SelectTextTitle && fields.SelectTextTitle.value}
            key="0"
          >
            {fields && fields.SelectTextTitle && fields.SelectTextTitle.value}
          </option>
          {Options &&
            Options.length > 0 &&
            Options.map((elem, key) => {
              return (
                <option
                  value={
                    elem &&
                    elem.fields &&
                    elem.fields.name &&
                    elem.fields.name.value &&
                    elem.fields.name.value
                  }
                  key={key}
                >
                  {elem &&
                    elem.fields &&
                    elem.fields.name &&
                    elem.fields.name.value &&
                    elem.fields.name.value}
                </option>
              );
            })}
        </select>
        {showError && (
          <>
            <span className="red-cross-icon"></span>
            <span role="alert" className="formError">
              {showError}
            </span>
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export const InputRadio = ({
  index,
  item,
  radioId,
  fields,
  onChangeFunction,
  validation,
  dataVal,
  formDetails,
  showError,
  formEnteredValue,
}) => {
  let value = getValue(formDetails, `FieldValue-${index + 1}`);
  return (
    <React.Fragment>
      <input
        type="radio"
        id={radioId}
        className={`formElem inputRadio`}
        aria-invalid={showError ? true : false}
        aria-label={dataVal && dataVal.fields && dataVal.fields.name && dataVal.fields.name.value}
        value={dataVal && dataVal.fields && dataVal.fields.name && dataVal.fields.name.value}
        name={`FieldValue-${index + 1}`}
        onChange={(e) => onChangeFunction(e, item, validation)}
        data-validation-object={JSON.stringify(validation)}
        data-radio-checked={
          dataVal &&
          dataVal.fields &&
          dataVal.fields.name &&
          dataVal.fields.name.value === formEnteredValue
        }
        checked={
          dataVal &&
          dataVal.fields &&
          dataVal.fields.name &&
          dataVal.fields.name.value === formEnteredValue
        }
      />
    </React.Fragment>
  );
};

export const InputCheckbox = ({
  index,
  item,
  onChangeFunction,
  validation,
  formError,
  mainItem,
  fields,
  isMultiSelect,
  elem,
  entryConfirm,
  boxIndex,
  formEnteredValue,
}) => {
  let showError = getValue(formError, `FieldValue-${index + 1}`);
  let isChecked = false;
  if (isMultiSelect) {
    if (formEnteredValue) {
      if (Object.values(formEnteredValue[boxIndex])[0] === '') {
        isChecked = false;
      } else {
        isChecked = true;
      }
    }
  } else {
    if (formEnteredValue) {
      isChecked = formEnteredValue === 'True' || !formEnteredValue === 'False';
    }
  }

  return (
    <React.Fragment>
      <input
        type="checkbox"
        className={`formElem inputCheckBox ${isMultiSelect && 'multiSelect'}`}
        aria-invalid={showError ? true : false}
        aria-label={item && item.Title && item.Title}
        name={`FieldValue-${index + 1}`}
        data-validation-object={JSON.stringify(validation)}
        onChange={(e) => onChangeFunction(e, item, validation)}
        checked={isChecked}
        id={
          isMultiSelect
            ? elem &&
              elem.fields &&
              elem.fields.name &&
              elem.fields.name.value &&
              elem.fields.name.value
            : fields &&
              fields.QuestionHeadline &&
              fields.QuestionHeadline.value &&
              fields.QuestionHeadline.value
        }
        value={
          elem
            ? elem &&
              elem.fields &&
              elem.fields.name &&
              elem.fields.name.value &&
              elem.fields.name.value
            : fields &&
              fields.QuestionHeadline &&
              fields.QuestionHeadline.value &&
              fields.QuestionHeadline.value
        }
      />
      {isMultiSelect
        ? elem && (
            <label
              htmlFor={
                elem &&
                elem.fields &&
                elem.fields.name &&
                elem.fields.name.value &&
                elem.fields.name.value
              }
            >
              <span>
                {elem &&
                  elem.fields &&
                  elem.fields.name &&
                  elem.fields.name.value &&
                  elem.fields.name.value}
              </span>
            </label>
          )
        : fields &&
          fields.QuestionHeadline &&
          fields.QuestionHeadline.value && (
            <label htmlFor={fields.QuestionHeadline.value}>
              <span>{fields.QuestionHeadline.value}</span>
            </label>
          )}
      {!isMultiSelect && showError && (
        <span role="alert" className="formError">
          {showError}
        </span>
      )}
    </React.Fragment>
  );
};

export const InputEmail = ({
  item,
  className,
  onChangeFunction,
  requireLabel,
  formError,
  validation,
}) => {
  //let showError = getValue(formError, setName(item && item.Name));
  return (
    <React.Fragment>
      <input
        type="email"
        className={`formElem inputText emailBox ${className ? className : ''}`}
        aria-label={item && item.Title}
        // aria-invalid={showError ? true : false}
        // aria-required={item.True}
        placeholder={item && item.Title}
        // name={setName(item && item.Name)}
        // data-validation-object={JSON.stringify(validation)}
        // onChange={(e) => onChangeFunction(e, item, validation)}
      />
      {/* {requireLabel
        ? showError && (
            <label htmlFor={setName(item.Name)} role="alert" className="genericErrormsg">
              {showError}
            </label>
          )
        : showError && (
            <span role="alert" className="genericErrormsg">
              {showError}
            </span>
          )} */}
    </React.Fragment>
  );
};

export const renderDate = (t, isMultiLang) => {
  const dateArr = [];
  if (isMultiLang) {
    dateArr.push(
      <option value="" selected disabled="disabled" aria-label={t('Date')} key="0">
        {t('Date')}
      </option>
    );
  } else {
    dateArr.push(
      <option value="" selected disabled="disabled" aria-label="date" key="0">
        Date
      </option>
    );
  }
  for (let i = 1; i <= 31; i++) {
    dateArr.push(
      <option value={i} aria-label={i} key={i}>
        {i}
      </option>
    );
  }
  return dateArr;
};
export const renderYear = (t, isMultiLang, data, fields) => {
  const maxDate = fields.MaxValue.value;
  const minDate = fields.MinValue.value;
  const filteredMaxDate = maxDate.indexOf('-');
  const filteredMinDate = minDate.indexOf('-');
  const filteredMaxYear = Number(maxDate.slice(0, filteredMaxDate));
  const filteredMinYear = Number(minDate.slice(0, filteredMinDate));
  const calculatedYear = filteredMaxYear - filteredMinYear;
  const d = new Date();
  const year = filteredMaxYear;
  const yearArr = [];
  if (isMultiLang) {
    yearArr.push(
      <option value="" selected disabled="disabled" aria-label={t('Year')} key="101">
        {t('Year')}
      </option>
    );
  } else {
    yearArr.push(
      <option value="" selected disabled="disabled" aria-label="year" key="101">
        Year
      </option>
    );
  }
  if (year) {
    for (let i = 0; i <= calculatedYear; i++) {
      yearArr.push(
        <option value={year - i} aria-label={year - i} key={i}>
          {year - i}
        </option>
      );
    }
  }

  return yearArr;
};

export const setInputTextData = (data) => {
  return {
    Title: data && data.QuestionHeadline && data.QuestionHeadline.value,
    Name: data && data.QuestionHeadline && data.QuestionHeadline.value,
    True: data && data.Required && data.Required.value,
    DataRequiredMessage: data && data.DataRequiredMessage && data.DataRequiredMessage.value,
    AlphaNumericCheckBox: data && data.AlphaNumericCheckBox && data.AlphaNumericCheckBox.value,
    MinNumber: data && data.MinLength && data.MinLength.value,
    AlphaNumericMessage: data && data.AlphaNumericMessage && data.AlphaNumericMessage.value,
    MinNumberMessage: data && data.MinNumberMessage && data.MinNumberMessage.value,
    MaxNumber: data && data.MaxLength && data.MaxLength.value,
    MaxNumberMessage: data && data.MaxNumberMessage && data.MaxNumberMessage.value,
    NumericValidationMessage:
      data && data.NumericValidationMessage && data.NumericValidationMessage.value,
    DateErrorMessage: data && data.DateErrorMessage && data.DateErrorMessage.value,
    MonthErrorMessage: data && data.MonthErrorMessage && data.MonthErrorMessage.value,
    YearErrorMessage: data && data.YearErrorMessage && data.YearErrorMessage.value,
    Regex: data && data.Regex && data.Regex.value,
  };
};
export const numericPattern = new RegExp(/^[0-9\b]+$/);
export const alphaNumericPattern = new RegExp(/^[a-z0-9]+$/i);
export const DataRequiredValidation = (value, item) => {
  return !value && item ? item.DataRequiredMessage : false;
};
export const validateEmail = (elementValue) => {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(elementValue);
};
export const MultiSelectCheckBoxRequiredValidation = (value, item) => {
  return value && !value.includes(true) ? item.MultiSelectCheckBoxRequiredMessage : false;
};
export const MinLengthValidation = (value, item) => {
  return value && item && item.MinNumber && value.length < Number(item.MinNumber)
    ? item.DataRequiredMessage
    : false;
};
export const MaxLengthValidation = (value, item) => {
  return value && item && item.MaxNumber && value.length > Number(item.MaxNumber)
    ? item.DataRequiredMessage
    : false;
};
export const EmailValidation = (value, item) => {
  return value && !validateEmail(value) ? item.DataRequiredMessage : false;
};
export const AlphaNumericValidation = (value, item) => {
  //const regExParsed = item && item.Regex && item.Regex.slice(1, -1);
  const regexPattern = item && item.Regex && new RegExp(item && item.Regex);
  return value && regexPattern && !regexPattern.test(value) ? item.DataRequiredMessage : false;
};
export const DateValidation = (value, item) => {
  return value && !moment(value, 'YYYY-M-D', true).isValid() ? item.DataRequiredMessage : false;
};
// export const setName = (itemName) => {
//   if (itemName && itemName.includes(' ')) {
//     itemName = itemName.replace(/ /g, '');
//   }
//   if (itemName && itemName.includes('-')) {
//     itemName = itemName.replace(/-/g, '');
//   }
//   return itemName;
// };
export const getValue = (formObject, name) => {
  let a;
  if (formObject && name && formObject[name]) {
    a = formObject[name];
  }
  return a ? a : false;
};
export const arrayToObj = (nameArr) => {
  const options = [];
  for (let i = 0; i < nameArr.length; i++) {
    options.push({
      fields: { name: { value: nameArr[i] } },
    });
  }
  return options;
};
