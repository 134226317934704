/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { createContext, useContext, useReducer } from 'react';
import { muscState, muscReducer } from '../reducers/muscReducer';

export const MuscContext = createContext();

export const MuscProvider = ({ children }) => (
  <MuscContext.Provider value={useReducer(muscReducer, muscState)}>{children}</MuscContext.Provider>
);
export const useMuscValue = () => useContext(MuscContext);

export default MuscContext;
