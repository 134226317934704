/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { getCelumImages, buildImgUrl } from '../../../utils/utils';
import { HomeTeam, AwayTeam, Competition } from '../../../constants/globalConstants';
export const CompetitionLogo = ({ celumimages, teamType }) => {
  /**
   * setting crest logo of comptition using helper function getCelumImages which takes celumimages as an argument and return object
   */
  const getCompetitionLogo = () => {
    let teamlogo = getCelumImages(celumimages);

    if (teamType === HomeTeam && teamlogo) {
      return (
        <img
          src={
            teamlogo &&
            teamlogo.homeCrest &&
            teamlogo.homeCrest.CropUrl &&
            buildImgUrl(teamlogo.homeCrest.CropUrl.img1x)
          }
          alt={teamlogo && teamlogo.homeCrest && teamlogo.homeCrest.AltText}
        />
      );
    } else if (teamType === AwayTeam && teamlogo) {
      return (
        <img
          src={
            teamlogo &&
            teamlogo.awayCrest &&
            teamlogo.awayCrest.CropUrl &&
            buildImgUrl(teamlogo.awayCrest.CropUrl.img1x)
          }
          alt={teamlogo && teamlogo.awayCrest && teamlogo.awayCrest.AltText}
        />
      );
    } else if (teamType === Competition && teamlogo) {
      return (
        <img
          src={
            teamlogo &&
            teamlogo.competitionCrest &&
            teamlogo.competitionCrest.CropUrl &&
            buildImgUrl(teamlogo.competitionCrest.CropUrl.img1x)
          }
          alt={teamlogo && teamlogo.competitionCrest && teamlogo.competitionCrest.AltText}
        />
      );
    } else {
      return null;
    }
  };
  return getCompetitionLogo();
};

export default React.memo(CompetitionLogo);
