/**
 *  © 2022 -2025 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import ImgCrop from '../Common/ImgCrop';

const StaticResponse = (props) => {
  const staticConciergeDataLocal =
    typeof Storage !== 'undefined' && JSON.parse(sessionStorage.getItem('searchStaticResp'));
  return (
    <>
      {staticConciergeDataLocal?.sections?.length > 0 &&
        staticConciergeDataLocal?.sections?.splice(0, 4)?.map((value, ind) => {
          return (
            <div key={ind} className="boxes">
              {value?.name && <h4 title={value.name}>{value.name}</h4>}
              <div className="content">
                {value?.items?.length > 0 &&
                  value?.items?.splice(0, 3)?.map((item, key) => {
                    const itemUrl = item?.url || '#';
                    const itemTitle = item?.title || '';
                    const categoryName = value?.name || '';
                    return (
                      <div key={key} className="content_box">
                        <a
                          className={`box${ind}${key}`}
                          href={item?.url ? item?.url : '#'}
                          onClick={(e) =>
                            props.handleStaticCardRidirect(itemTitle, itemUrl, categoryName, e)
                          }
                        >
                          <div className="content_box_img">
                            <div className="img-holder">
                              {item?.image && (
                                <ImgCrop
                                  imgCrop={item?.image}
                                  alt={''}
                                  title={item.title}
                                ></ImgCrop>
                              )}
                            </div>
                          </div>

                          {item?.title ? (
                            <p title={item.title}>{item.title}</p>
                          ) : (
                            item?.name && <p title={item.name}>{item.name}</p>
                          )}
                          <span className="icon-squizSearch_ico content_box_ico"></span>
                        </a>
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
    </>
  );
};
export default withTranslation()(StaticResponse);
