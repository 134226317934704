/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { Fragment, useEffect, useState } from 'react';
import { isExperienceEditorActive, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
const SignPostGridContainer = (props) => {
  const [isExpEditor, setIsExpEditor] = useState(false);
  const { pageEditing } = props.sitecoreContext;
  useEffect(() => {
    setIsExpEditor(isExperienceEditorActive());
  }, []);

  return (
    <Fragment>
      {(isExpEditor || pageEditing) && (
        <div>
          <b>
            Section Sign Post Container
            <br />
          </b>
        </div>
      )}
      <div className="container main">
        <Placeholder
          name="mufc-ph-gridcontainer"
          rendering={props.rendering}
          missingComponentComponent={() => {
            return null;
          }}
        />
      </div>
    </Fragment>
  );
};

export default withSitecoreContext()(SignPostGridContainer);
