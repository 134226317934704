/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { renderStatsView } from '../../data-transform/components/StatsTransform';
import BreakdownPercentage from './BreakdownPercentage';
import Top3Line from './Top3Line';
import HeadToHead4 from './HeadToHead4';
import HeadToHeadNumber from './HeadToHeadNumber';
import HeadToHeadNumberDonut from './HeadToHeadNumberDonut';
import HeadToHeadPercentage from './HeadToHeadPercentage';
import BreakdownNumber from './BreakdownNumber';
import SingleStats from './SingleStats';
import PlayerVteam from './PlayerVteam';
const StatsListing = ({ item, gridClass, carousalClass }) => {
  const getTemplate = (item) => {
    let StatsTemplate;
    let template_t = item.template_t && item.template_t.toLowerCase();
    if (template_t == 'percentage breakdown') {
      StatsTemplate = BreakdownPercentage;
    } else if (template_t == 'top 3' || template_t == 'single stat (3)') {
      StatsTemplate = Top3Line;
    } else if (template_t == 'head to head 4') {
      StatsTemplate = HeadToHead4;
    } else if (template_t == 'head to head number') {
      if (item.layout_t.toLowerCase() == 'bar' || item.layout_t.toLowerCase() == 'simple bar') {
        StatsTemplate = HeadToHeadNumber;
      } else if (item.layout_t.toLowerCase() == 'donut') {
        StatsTemplate = HeadToHeadNumberDonut;
      }
    } else if (template_t == 'head to head percentage') {
      StatsTemplate = HeadToHeadPercentage;
    } else if (template_t == 'number breakdown') {
      StatsTemplate = BreakdownNumber;
    } else if (template_t == 'single stat (2)' || template_t == 'single stat') {
      StatsTemplate = SingleStats;
    } else if (template_t == 'player v team') {
      StatsTemplate = PlayerVteam;
    }
    return (
      StatsTemplate && (
        <StatsTemplate
          item={renderStatsView(item)}
          gridClass={gridClass}
          carousalClass={carousalClass}
        />
      )
    );
  };
  return (
    item && (
      <div>
        <div>{getTemplate(item)}</div>
      </div>
    )
  );
};
export default StatsListing;
