/**
 *  © 2022 -2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { data, analytics } from '../../../utils/analytics';
import { COMINGUP_PLAYERBIRTHDAY, COMINGUPCAROUSAL } from '../../../constants/containerConstants';
import { replaceExtWithWebp } from '../../../utils/webp';
import { createDestinationUrl, buildImgUrl } from '../../../utils/utils';

const CardPlayerBirthday = (props) => {
  const { item, getDateSchudle, commonResponse, t } = props;
  const {
    birthdate_tdt,
    firstname_t,
    _name,
    itemid_s,
    taglist_sm,
    __published_tdt,
    authorname_s,
    destinationurl_s,
    playergridcropurl_s,
    matchdate_tdt,
    country_t,
    lastname_t,
    iswebpavailablebool_b,
  } = item;
  let playerBdayMsg = commonResponse && commonResponse.playerbirthdaymessage_t;
  const playerBdayYears = moment().diff(birthdate_tdt, 'years');
  const bDay = moment(matchdate_tdt).format('YYYY-MM-DD');
  const toDay = moment().format('YYYY-MM-DD');
  let playerBdayAge;
  if (moment(bDay).isAfter(toDay)) {
    playerBdayAge = playerBdayYears + 1;
  } else {
    playerBdayAge = playerBdayYears;
  }

  const checkBdayMsg = () => {
    if (playerBdayMsg) {
      if (playerBdayMsg.includes('{Player Name}')) {
        let fullName;
        if (lastname_t) {
          fullName = `${firstname_t} ${lastname_t}`;
        } else {
          fullName = firstname_t;
        }
        playerBdayMsg = playerBdayMsg.replace('{Player Name}', fullName);
      }
      if (playerBdayMsg.includes('{age}')) {
        playerBdayMsg = playerBdayMsg.replace('{age}', playerBdayAge);
      }
    }
    return playerBdayMsg;
  };

  const initialData = data('card');
  const triggerAnalytics = () => {
    analytics({
      ...initialData,
      card_name: COMINGUP_PLAYERBIRTHDAY,
      container_type: COMINGUPCAROUSAL,
      content_type: COMINGUP_PLAYERBIRTHDAY,
      item_id: itemid_s,
      item_name: _name,
      button_name: commonResponse && commonResponse.playerctatitleprebirthday_t,
      tag: taglist_sm,
      created_datetime: __published_tdt !== undefined ? __published_tdt : 'N/A',
      author: authorname_s,
      destination_url: createDestinationUrl(destinationurl_s),
    });
  };

  function cardClickHandler() {
    triggerAnalytics();
  }

  return (
    <a
      className="card-content"
      tabIndex="-1"
      aria-hidden="true"
      style={{ outline: 'none' }}
      href={destinationurl_s ? createDestinationUrl(destinationurl_s) : 'javascript:void(0)'}
      onClick={(e) => cardClickHandler()}
    >
      <div className="card-data card-player_birthday">
        <span className="screenreader">Player Birthday</span>
        <div className="leftBox">
          <div className="data_info" aria-hidden="true">
            <div className="info_ico">
              <div className="img-holder">
                {playergridcropurl_s && (
                  <>
                    <div
                      className="avatar_profile"
                      style={
                        playergridcropurl_s &&
                        playergridcropurl_s.thumb && {
                          backgroundImage: iswebpavailablebool_b
                            ? `url(${buildImgUrl(
                                replaceExtWithWebp(iswebpavailablebool_b, playergridcropurl_s.thumb)
                              )})`
                            : `url(${buildImgUrl(playergridcropurl_s.thumb)})`,
                          backgroundPosition: '0 0',
                          backgroundSize: 'cover',
                        }
                      }
                    >
                      <img
                        src="//:0"
                        className="visually-hidden "
                        aria-hidden="true"
                        id="headerprofileimg2"
                        alt="Profile pic"
                        title="Profile pic"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="info_Txt">
              {matchdate_tdt && <p className="dateSchudle">{getDateSchudle(matchdate_tdt)}</p>}
              {commonResponse && commonResponse.playerbirthdaytitle_t && (
                <p>{commonResponse.playerbirthdaytitle_t}</p>
              )}
            </div>
          </div>
          {firstname_t && (
            <div className="msgBox">
              <p className="user_birthdayMsg">{checkBdayMsg()}</p>
            </div>
          )}
        </div>
        <div className="rightBox">
          <>
            {playerBdayYears && (
              <p className="timeSchudle">
                {playerBdayYears} {t('years Old')}
              </p>
            )}
            {country_t && (
              <div className={country_t.length > 21 ? 'subTxt marqueeAnim' : 'subTxt'}>
                <p>{country_t}</p>
              </div>
            )}
            {commonResponse && commonResponse.playerctatitleprebirthday_t && (
              <div className="cardBtn">
                {commonResponse.playerctatitleprebirthday_t.toUpperCase()}
              </div>
            )}
          </>
        </div>
      </div>
    </a>
  );
};

export default withTranslation()(CardPlayerBirthday);
