/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useRef } from 'react';
import MU from '../constants/muConstants';
const Sticky = (props) => {
  let lastScrollPosition = 0;
  const container = useRef(null);
  const sticky = (el, header, primaryHeader, secondaryHeader, nextSibling, resize, isUp, hero) => {
    let st = window.pageYOffset || document.documentElement.scrollTop;
    let scrollDirection = st > lastScrollPosition;
    lastScrollPosition = st <= 0 ? 0 : st;
    let sticky = container && container.current;
    let elHeight = el && el.offsetHeight ? el.offsetHeight : sticky.offsetHeight;
    let headerHeight = header ? header.offsetHeight : 0;
    let primaryHeaderHeight = primaryHeader.offsetHeight;
    let secondaryHeaderHeight = secondaryHeader.offsetHeight;
    let heroHeight = hero ? hero.offsetHeight : 0;
    if (window.pageYOffset >= primaryHeaderHeight + heroHeight) {
      sticky.classList.add('sticky');
      sticky.style.top = secondaryHeaderHeight + 'px';
      if (nextSibling) {
        nextSibling.style.marginTop = elHeight + 'px';
      }
      if (scrollDirection) {
        sticky.style.top = secondaryHeaderHeight + 'px';
      } else {
        sticky.style.top = resize && !isUp ? secondaryHeaderHeight + 'px' : headerHeight + 'px';
      }
    }
    if (window.pageYOffset <= 0 + heroHeight) {
      sticky.classList.remove('sticky');
      sticky.style.top = null;
      if (nextSibling) {
        nextSibling.style.marginTop = null;
      }
    }
  };
  useEffect(() => {
    let el =
      container && container.current && container.current.children && container.current.children[0];
    let nextSibling = container && container.current && container.current.nextElementSibling;
    if (el) {
      let header = document.querySelector('.mainHeader');
      let primaryHeader = header && header.querySelector('.primary-header');
      let secondaryHeader = header && header.querySelector('.home-header__bottom');
      let screenName = MU && MU.screenName && MU.screenName.toLowerCase();
      let hero = null;
      if (screenName === 'news' || screenName === 'videos') {
        hero = document.querySelector('.hero-item.news-list-hero-item');
      }
      if (screenName === 'matches') {
        hero = document.querySelector('.matches__filter');
      }
      window.addEventListener(
        'scroll',
        function () {
          sticky(el, header, primaryHeader, secondaryHeader, nextSibling, false, null, hero);
        },
        false
      );
      window.addEventListener(
        'resize',
        function () {
          let timerid;
          timerid && clearTimeout(timerid);
          timerid = setTimeout(() => {
            let isUp = header && header.classList && header.classList.contains('up');
            sticky(el, header, primaryHeader, secondaryHeader, nextSibling, true, isUp, hero);
          }, 100);
        },
        false
      );
      return () => {
        window.removeEventListener(
          'scroll',
          function () {
            sticky(el, header, primaryHeader, secondaryHeader, nextSibling, false, null, hero);
          },
          false
        );
        window.removeEventListener(
          'resize',
          function () {
            sticky(el, header, primaryHeader, secondaryHeader, nextSibling, true, null, hero);
          },
          false
        );
      };
    }
  }, []);
  return (
    <div className={`custom-sticky`} style={props.style} ref={container}>
      {props.children}
    </div>
  );
};
Sticky.defaultProps = {
  style: {},
};
export default React.memo(Sticky);
