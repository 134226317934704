/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { NewsGridAutomation } from '../../Home/NewsGridAutomation';

const NewsGrid6 = (props) => {
  const renderExpEditorMsg = () => {
    if (isExperienceEditorActive()) {
      return (
        <div>
          <b>PlaceHolder For NewsGrid :TwoRow container</b>
        </div>
      );
    }
  };

  const signpostContainer = () => {
    return (
      <div
        className={`container-signpost ${
          props &&
          props.rendering &&
          props.rendering.fields &&
          props.rendering.fields.Description &&
          props.rendering.fields.Description.value
            ? 'description'
            : ''
        }`}
      >
        <div className="news-grid-signpost-left-container">
          {props &&
            props.rendering &&
            props.rendering.fields &&
            props.rendering.fields.Title &&
            props.rendering.fields.Title.value && (
              <h2 className="news-grid-title">
                {props.rendering.fields.Title.value.substring(0, 39)}
              </h2>
            )}

          {props &&
            props.rendering &&
            props.rendering.fields &&
            props.rendering.fields.Description &&
            props.rendering.fields.Description.value && (
              <span className="news-grid-description">
                {props.rendering.fields.Description.value.substring(0, 79)}
              </span>
            )}
        </div>
        {props &&
          props.rendering &&
          props.rendering.fields &&
          props.rendering.fields.CTATitle &&
          props.rendering.fields.CTATitle.value && (
            <div className="news-grid-signpost-right-container">
              <a href={props.rendering.fields.CTAUrl.value.hrefMuCustom} role="button">
                <span>{props.rendering.fields.CTATitle.value}</span>
              </a>
            </div>
          )}
      </div>
    );
  };
  return (
    <div className="home-row">
      {renderExpEditorMsg()}
      <div className="news-grid" data-container="news-grid">
        <div className="container main">
          {((props &&
            props.rendering &&
            props.rendering.fields &&
            props.rendering.fields.Title &&
            props.rendering.fields.Title.value) ||
            (props &&
              props.rendering &&
              props.rendering.fields &&
              props.rendering.fields.Description &&
              props.rendering.fields.Description.value) ||
            (props &&
              props.rendering &&
              props.rendering.fields &&
              props.rendering.fields.CTATitle &&
              props.rendering.fields.CTATitle.value)) &&
            signpostContainer()}
          <div className="two-rows">{NewsGridAutomation(props, 'sixgrid')}</div>
        </div>
      </div>
    </div>
  );
};

export default NewsGrid6;
