/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

class MU {
  constructor() {
    // Prod fallback
    if (process.env.NODE_ENV !== 'development') {
      this.assetUrl = '//assets.manutd.com/AssetPicker/images';
      this.assetUrlJpeg = '//assets.manutd.com/AssetPicker/images';
      this.assetUrlResource = '//www.manutd.com';
      if (!this.awsapiKeyWeb) this.awsapiKeyWeb = 'RySFXMCM0K1cmM7CyQb4v5iREoFooHXV9CyeKK3o';
      this.awsEndPoint = 'https://cdnapi.manutd.com/api';
      this.awsAPIVersion = 'v1';
      if (!this.language) this.language = 'en';
      if (!this.countryCode) this.countryCode = 'IN';
    }
  }
  setMU(data) {
    if (data) {
      for (const property in data) {
        this[property] = data[property];
      }
    }
  }
}

export default new MU();

export const muMock = {
  assetUrl: '//assets-dev.manutd.com/AssetPicker/images',
  assetUrlJpeg: '//assets-dev.manutd.com/AssetPicker/images',
  assetUrlResource: '//cms-dev.manutd.com',
  awsapiKeyWeb: '2uazll0Txh4X5It8yA4yk4dpFxNieR6zakbWC0Vm',
  awsGigyaAPIKey: '2uazll0Txh4X5It8yA4yk4dpFxNieR6zakbWC0Vm',
  eticketingUrl: 'https://www.eticketing.co.uk/muticketsandmembership/default.aspx',
  matchFilter: '',
  searchFilter:
    '{"all":"Screens/Search","news":"Screens/News,Screens/Search,Team Level/Academy,Team Level/First Team,Team Level/Reserves","videos":"Screens/Search,Screens/Video,Team Level/Academy,Team Level/First Team,Team Level/Reserves","images":"Screens/Search,Screens/Image"}',
  destinationUrls:
    '{"all":"/en/Search/All","images":"/en/Search/Images","news":"/en/Search/News","videos":"/en/Search/Videos"}',
  preferenceCenterURL: 'https://cloud.email.manutd.com/fanpreferences?lang=gb-GB',
  awsEndPoint: 'https://cdnapi-dev.manutd.com/api',
  awsAPIVersion: 'v5',
  isLoginRequire: false,
  countryCode: '',
  language: 'en',
  isUserLoggedIn: 'glt_3_kM1XsKFqGLYAgYA7fQl8-xBMztMt9gP8kLANUkcT2uNama7_MYEXWYajjolAxhDn',
  brightcoveAccountId: '6058004158001',
  brightcovePlayerId: 'gtu6zrxj',
  gigyaUserPollType: 'UserPolls_4',
  buildRevision: 3063,
  screenName: 'matchcenter',
  reverseTheme: '',
  gapClass: '',
  gigyaAPiKey: '3_kM1XsKFqGLYAgYA7fQl8-xBMztMt9gP8kLANUkcT2uNama7_MYEXWYajjolAxhDn',
  queryIsApp: false,
  appDeepLink: '',
  queryIsCollection: false,
};
