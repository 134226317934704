/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LeftPanel from './LeftPanel';
import StatsBar from '../Common/StatsBar';
import SponsorPanel from './SponsorPanel';
import ImagePanel from './ImagePanel';
import { getStatsBarData } from '../../utils/utils';
import ErrorBoundary from '../Common/ErrorBoundary';
const PlayerVteam = ({ item, t, gridClass, carousalClass }) => {
  const isPlayer =
    item && item.opta_statentitytype_t && item.opta_statentitytype_t.toLowerCase() === 'player'
      ? true
      : false;
  const [showSmall, setShowSmall] = useState(false);
  useEffect(() => {
    if (carousalClass === 'mu-content') {
      let charCount = 0;
      let liLength = 0;
      if (item && item.opta_statentities_t[0]) {
        if (item.opta_statentities_t[0].statValues) {
          liLength = item.opta_statentities_t[0].statValues.length;
        }
        if (item.opta_statentities_t[0].KnownName) {
          charCount = item.opta_statentities_t[0].KnownName.length;
        } else if (item.opta_statentities_t[0].LastName) {
          charCount = item.opta_statentities_t[0].LastName.length;
        }
      }
      if (liLength > 2 && charCount > 8) {
        setShowSmall(true);
      }
    }
  }, []);

  return (
    <div className={`${gridClass} matchday-stats`}>
      <div className={carousalClass}>
        <div
          className={`stats-card brekdown-number playerVteam ${
            isPlayer ? 'player-section' : 'team-section'
          }`}
        >
          <div className="brekdown-number__info">
            <SponsorPanel item={item} />
            <div className="stats-card__info">
              <LeftPanel item={item} />
              <div className="stats-card__info__right">
                <div className="chart-area">
                  <div className="brekdown-number__details player-vs-team">
                    <div className="brekdown-number__details__left">
                      <div className="screenreader">
                        {item.opta_statentities_t[0] &&
                          `${
                            item.opta_statentities_t[0].KnownName
                              ? item.opta_statentities_t[0].KnownName
                              : item.opta_statentities_t[0].LastName
                          } ${t('Shirtnumber')} ${item.opta_statentities_t[0].JerseyNumber} 
                        ${t('against')} ${item.opta_statentities_t[1].TeamName}`}
                      </div>
                      <div className="players__notification player-section" aria-hidden="true">
                        <div className="player-img team-image">
                          <ErrorBoundary>
                            <ImagePanel item={item.opta_statentities_t[0]} statsType={isPlayer} />
                          </ErrorBoundary>
                          {isPlayer && (
                            <span className="notification">
                              {item.opta_statentities_t[0] &&
                                item.opta_statentities_t[0].JerseyNumber}
                            </span>
                          )}
                        </div>
                        {isPlayer &&
                          item.opta_statentities_t &&
                          item.opta_statentities_t.length > 1 && (
                            <h3
                              className={showSmall ? 'smalltext' : ''}
                              style={showSmall ? { fontSize: '20px' } : null}
                            >
                              {item.opta_statentities_t[0] && item.opta_statentities_t[0].KnownName
                                ? item.opta_statentities_t[0].KnownName
                                : item.opta_statentities_t[0].LastName}
                            </h3>
                          )}
                      </div>
                      <div className="vs" aria-hidden="true">
                        <div>
                          <span>{t('VS')}</span>
                        </div>
                      </div>
                      <div className="players__notification team-section" aria-hidden="true">
                        <div className="player-img player-image">
                          <ErrorBoundary>
                            <ImagePanel item={item.opta_statentities_t[1]} statsType={isPlayer} />
                          </ErrorBoundary>
                        </div>
                        {!isPlayer &&
                          item.opta_statentities_t &&
                          item.opta_statentities_t.length > 1 && (
                            <h3
                              className={showSmall ? 'smalltext' : ''}
                              style={showSmall ? { fontSize: '20px' } : null}
                            >
                              {item.opta_statentities_t[0] && item.opta_statentities_t[0].KnownName
                                ? item.opta_statentities_t[0].KnownName
                                : item.opta_statentities_t[0].LastName}
                            </h3>
                          )}
                      </div>
                    </div>
                    <div className="brekdown-number__details__right">
                      <ul>
                        {item.opta_statentities_t[0] &&
                          item.opta_statentities_t[0].statValues.map((playerVteamItem, key) => (
                            <li
                              key={key}
                              className="brekdown-number__info"
                              data-ng-repeat="playerVteamItem in item.item.opta_statentities_t[0].statValues"
                            >
                              <span aria-hidden="true">{playerVteamItem.StatsName}</span>
                              <span aria-hidden="true">{`${playerVteamItem.Value}${
                                playerVteamItem.IsPercentage === 'true' ||
                                playerVteamItem.IsPercentage === true
                                  ? '%'
                                  : ''
                              }`}</span>

                              <div className="screenreader">
                                {isPlayer &&
                                item.opta_statentities_t &&
                                item.opta_statentities_t.length > 1
                                  ? `${playerVteamItem.StatsName} ${t('by')} ${
                                      item.opta_statentities_t[0].KnownName
                                        ? item.opta_statentities_t[0].KnownName
                                        : item.opta_statentities_t[0].LastName
                                    } ${playerVteamItem.Value} ${
                                      playerVteamItem.IsPercentage === 'true' ||
                                      playerVteamItem.IsPercentage === true
                                        ? '%'
                                        : ''
                                    }`
                                  : `${playerVteamItem.StatsName} ${t('by')} ${
                                      item.opta_statentities_t[0].TeamName
                                    } ${playerVteamItem.Value} 
                                ${
                                  playerVteamItem.IsPercentage === 'true' ||
                                  playerVteamItem.IsPercentage === true
                                    ? '%'
                                    : ''
                                }`}
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <StatsBar item={getStatsBarData(item)} type="statsCard" />
        </div>
      </div>
    </div>
  );
};
export default withTranslation()(PlayerVteam);
