/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useShowListingValue } from '../../../context/showListingContext';
import FilterDropdown from './FilterDropdown';
import CarouselListing from './CarouselListing';
import * as track from '../../../utils/analytics';
import { heroDataMapping, individualTag } from '../mutvutil';
import { createTagList, getLoggedInStatus } from '../../../utils/utils';
import { useSponsorValue } from '../../../context/sponsorContext';
import { ANONYMOUS, FREE_TO_VIEW, REGISTERED } from '../../../constants/globalConstants';

const ShowCarouselListingChild = (props) => {
  const [
    {
      status,
      cardData,
      startListIndex,
      showLoadMore,
      filterObj,
      carouselDetails,
      filters,
      itemsNumber,
      initialLoad,
      heroDetails,
      seriesType,
      isMutvMovie,
    },
    dispatch,
  ] = useShowListingValue();
  const [{ isLoginChecked, isUserLogin, isUserSubscribe, isUserSkyId }] = useSponsorValue();
  const { callApi, defaultFilter, containerType, isShowScreen, t } = props;
  const [activeFilter, setActiveFilter] = useState(null);
  const [tabSelected, setTabSelected] = useState(null);
  const tabFilter = { episode: t('Episode'), clips: t('Clips'), mlt: t('MoreLikeThis') };
  const fltWidth = useRef(null);
  const [newWidth, setNewWidth] = useState(null);
  const [activeText, setActiveText] = useState(null);

  const trackCard = (e, episodeLink) => {
    const initialData = track.data('button');
    const data = heroDetails && heroDataMapping(heroDetails);
    track.analytics(
      {
        ...initialData,
        button_name: episodeLink,
        card_name: data.heading,
        container_type: containerType,
        destination_url: data.destinationurl_s,
        subscription_status: getLoggedInStatus({
          isLoginChecked,
          isUserLogin,
          isUserSubscribe,
          isUserSkyId,
        }),
        item_id: data.itemid_s,
        content_type: data.contenttype_t,
        tag: data.taglist_sm && createTagList(data.taglist_sm, data.category_s),
        // content_status: data.contentaccess_t ? data.contentaccess_t : FREE_TO_VIEW,
        ...individualTag(data.taglist_sm),
      },
      e
    );
  };

  useEffect(() => {
    !activeFilter &&
      filters &&
      filters.length > 0 &&
      setActiveFilter(defaultFilter ? defaultFilter : filters[0]);
    cardData && cardData.defaultActiveTab && setTabSelected(cardData.defaultActiveTab);
  }, [filters]);

  useEffect(() => {
    if (fltWidth && fltWidth.current) {
      setFilterWidth();
    }
  }, [fltWidth, cardData]);

  const setFilterWidth = () => {
    let width = 30;
    for (let i = 0; i < 3; i++) {
      const element = fltWidth.current.childNodes[i];
      if (element) {
        const style = element.currentStyle || window.getComputedStyle(element);
        width = width + element.clientWidth + parseFloat(style.marginRight);
      }
    }
    setNewWidth(width);
  };

  const loadMore = () => {
    tabSelected &&
      callApi(
        startListIndex[tabSelected] + itemsNumber,
        getActionFilter(),
        'loadMore',
        tabSelected
      );
  };

  const getActionFilter = () => {
    return !isShowScreen && tabSelected !== 'episode' ? null : activeFilter;
  };

  const changeFilter = (data) => {
    if (data !== activeFilter) {
      setActiveFilter(data);
      callApi(0, data, 'default', tabSelected);
    }
  };
  const getItemCount = () => {
    if (tabSelected && tabSelected === 'episode') {
      const numFound = cardData != null && cardData.episodeNumFound;
      return `${numFound ? numFound : '0'} ${numFound > 1 ? t('Episodes') : t('Episode')} ${t(
        'Available'
      )} `;
    }
  };

  const onClickTab = (e, tabSelected, filterItem) => {
    trackCard(e, tabFilter[filterItem]);
    if (tabSelected !== filterItem) {
      setTabSelected(filterItem);
    }
    if (filterItem === 'clip') {
      setActiveText(`${tabFilter[filterItem]} ${t('selected')}`);
    } else if (filterItem === 'mlt') {
      setActiveText(`${tabFilter[filterItem]} ${t('selected')}`);
    } else {
      setActiveText(`${tabFilter[filterItem]} ${t('selected')}`);
    }
  };
  const getStyle = (filterItem) => {
    if (filterItem === (tabSelected ? tabSelected : cardData.defaultActiveTab)) {
      return 'block';
    }
    return 'none';
  };
  return (
    <div className="container mutv-container filters">
      {!initialLoad && (
        <div className="mutv-shows__filter">
          <div className="mutv-shows__filter__nav">
            <nav aria-label="Episode filter">
              <ul id="tabfilter" ref={fltWidth} style={{ width: newWidth }}>
                {tabFilter &&
                  Object.keys(tabFilter).map(
                    (filterItem, index) =>
                      ((cardData && cardData[filterItem] && cardData[filterItem].length > 0) ||
                        filterItem === 'episode') && (
                        <li
                          key={index}
                          className={tabSelected === filterItem ? 'active' : ''}
                          onClick={(e) => onClickTab(e, tabSelected, filterItem)}
                        >
                          <a
                            href="#"
                            aria-label={tabSelected === filterItem ? activeText : ''}
                            onClick={(e) => e.preventDefault()}
                            className="link"
                          >
                            {tabFilter[filterItem]}
                          </a>
                        </li>
                      )
                  )}
              </ul>
            </nav>
          </div>
          {tabSelected === 'episode' && !isMutvMovie && (
            <div className="mutv-shows__filter__wrapper">
              {filters && filters.length > 0 && (
                <FilterDropdown
                  changeFilter={changeFilter}
                  filters={filters}
                  filterObj={filterObj}
                  activeFilter={activeFilter}
                  seriesType={seriesType}
                />
              )}
              <div className="season-search__result">{getItemCount()}</div>
            </div>
          )}
        </div>
      )}
      {tabFilter &&
        Object.keys(tabFilter).map(
          (filterItem, index) =>
            ((cardData && cardData[filterItem] && cardData[filterItem].length > 0) ||
              filterItem === 'episode') && (
              <div
                key={index}
                style={{
                  display: getStyle(filterItem),
                }}
              >
                <CarouselListing
                  containerType={containerType}
                  aspectRatio={carouselDetails && carouselDetails.aspectRatio}
                  status={status}
                  cardData={cardData && cardData[filterItem]}
                  listView={true}
                  showLoadMore={showLoadMore && showLoadMore[filterItem]}
                  loadMore={loadMore}
                  inlinePlay={filterItem === 'clips' ? true : false}
                  dispatch={dispatch}
                  initialLoad={initialLoad}
                  isMutvMovie={isMutvMovie}
                />
              </div>
            )
        )}
    </div>
  );
};
export default withTranslation()(ShowCarouselListingChild);
