/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useRef, useState } from 'react';
import LazyLoad from 'react-lazyload';
import InlineVideo from '../../../Cards/Video/InlineVideo';
import ImgCrop from '../../../Common/ImgCrop';
import Sponsor from '../../../Common/Sponsor';
import {
  createDestinationUrl,
  createTagList,
  getContentAccessLocal,
  getLoggedInStatus,
  showCBS,
  onCloseMedia,
  getParameterByName,
  decodeXML,
} from '../../../../utils/utils';
import { CONTENT_TYPE, THRESHOLD } from '../../../../constants/globalConstants';
import * as track from '../../../../utils/analytics';
import { Video } from '../../../../data-transform/components/Video';
import MutvHeroShimmer from './MutvHeroShimmer';
import Widgets from './Widgets';
import EpisodeNote from './EpisodeNote';
import {
  bredcrumText,
  individualTag,
  setPlayerPosition,
  isContentLabelVisible,
  setInlineVideoContainerUtil,
  getOrientation,
} from '../../mutvutil';
import AccessBadge from '../../../Common/AccessBadge';
import { useModal } from '../../../Common/Modal';
import ErrorBoundary from '../../../Common/ErrorBoundary';
import Loader from '../../../Common/Loader';
import { Podcast } from '../../../../data-transform/components/Podcast';
import loadable from '@loadable/component';
import { useMutvListingValue } from '../../../../context/mutvMyListContext';
import { withTranslation } from 'react-i18next';
import { ACCOUNT_SUBSCRIPTION } from '../../../../constants/globalConstants';
import { pauseOnNextPrev, playOnNextPre } from '../../../../utils/brightcove';
import MU from '../../../../constants/muConstants';
const RegistrationModal = loadable(() =>
  import('../../../Modals/RegistrationModal/RegistrationModal')
);

const MutvHeroVideo = (props) => {
  const [
    { myListResponse, lastResponseTime, reportStatus, status },
    dispatch,
  ] = useMutvListingValue();
  const {
    heroData,
    listingData,
    isLoginChecked,
    isUserLogin,
    isUserSubscribe,
    isUserSkyId,
    isShowScreen,
    containerType,
    isClipVideo,
    initialLoad,
    setScreenShow,
    screenShow,
    isPodcast,
    maskRequired,
    t,
    isMutvMovie,
  } = props;
  const [playVideo, setPlayVideo] = useState(false);
  const [playerStatus, setPlayerStatus] = useState(null);
  const [showSwitching, setShowSwitching] = useState(false);
  const redirectUrl = useRef({});
  const contentAccessRef = useRef(null);
  const idRef = useRef(null);
  const isMediaObj = useRef(null);
  const [contentAccessState, setContentAccessState] = useState(null);
  const { showModal, closeModal, isModalVisible } = useModal();
  const [videoStyle, setVideoStyle] = useState({});
  const containerRef = useRef(null);
  const heroDataRef = useRef(null);
  const {
    contenttype_t,
    imagecropurl_s,
    heading,
    description,
    contentaccess_t,
    contentlabel_s,
    series_s,
    taglist_sm,
    sponsordetailinfo_s,
    appearance_tdt,
    authorname_s,
    __published_tdt,
    __publishedby_t,
    itemid_s,
    labelexpirytime_tl,
    celumimagesvariant_s,
    videoFileTranscript,
    category_s,
  } = heroData;
  heroDataRef.current = heroData;
  const ImageCropUrl = celumimagesvariant_s && celumimagesvariant_s.ImageCropUrl;
  let timeoutId;
  useEffect(() => {
    if (isPodcast && !isClipVideo) {
      if (screenShow && screenShow.video) {
        !playVideo && setPlayVideo(true);
        setPlayerStatus({ isPodcast: true, currentScreen: 'videoEpisode', video: 'playing' });
        heroDataRef.current && containerRef.current && !initialLoad && setInlineVideoContainer();
      } else {
        setPlayerStatus({ isPodcast: true, currentScreen: 'videoEpisode', video: 'pause' });
      }
    }
  }, [screenShow]);

  useEffect(() => {
    playVideo && setInlineVideoContainer();
  }, [playVideo]);

  useEffect(() => {
    window.addEventListener('resize', resizeListener);
    return () => window.addEventListener('resize', resizeListener);
  }, []);

  useEffect(() => {
    if (playVideo && itemid_s && idRef.current && itemid_s !== idRef.current) {
      idRef.current = itemid_s;
      setPlayVideo(false);
    }
  }, [itemid_s]);

  useEffect(() => {
    if (listingData) {
      redirectUrl.current = {
        redirect: !isClipVideo ? true : false,
        url: createDestinationUrl(listingData),
      };
    }
  }, [listingData, isClipVideo]);

  useEffect(() => {
    if (
      (!contentAccessState ||
        (contentAccessState &&
          itemid_s &&
          contentAccessRef.current &&
          itemid_s !== contentAccessRef.current)) &&
      Object.keys(heroData).length > 0
    ) {
      contentAccessRef.current = itemid_s;
      setContentAccessState(getContentAccessLocal(contentaccess_t, contenttype_t, taglist_sm));
    }
  }, [heroData]);

  const isMediaPlaying = (data) => {
    isMediaObj.current = data;
  };

  const getClassName = () => {
    const className = isShowScreen
      ? 'show-episode series-screen'
      : isPodcast
      ? 'show-episode podcast'
      : 'show-episode';
    return `${className} ${maskRequired ? 'mask' : ''}`;
  };

  const onCloseButton = () => {
    if (!isPodcast || (isPodcast && isClipVideo)) {
      setPlayVideo(false);
      setShowSwitching(false);
    } else if (isPodcast && !isClipVideo) {
      setScreenShow({ podcast: true, video: false });
    }
    if (contentAccessState === ACCOUNT_SUBSCRIPTION) {
      isMediaObj && isMediaObj.current && onCloseMedia(isMediaObj.current);
    }
  };

  const triggerPlayVideo = (e) => {
    trackCard(e);
    if (redirectUrl.current && redirectUrl.current.redirect) {
      window.location.href = redirectUrl.current.url;
    } else {
      if (showCBS({ isUserLogin, isUserSubscribe, contentAccessState })) {
        e.preventDefault();
        showModal();
      } else {
        idRef.current = itemid_s;
        setPlayVideo(true);
        e.preventDefault();
        e.stopPropagation();
      }
    }
  };

  const getAnalyticsData = () => {
    const initialData = track.data('button');
    return {
      ...initialData,
      card_name: heading,
      button_name: 'video fullscreen',
      container_type: containerType,
      subscription_status: getLoggedInStatus({
        isLoginChecked,
        isUserLogin,
        isUserSubscribe,
        isUserSkyId,
      }),
      item_id: itemid_s,
      content_type: contenttype_t,
      tag: taglist_sm && createTagList(taglist_sm, category_s),
      created_datetime: __published_tdt,
      content_status: contentAccessState,
      author: __publishedby_t,
      ...individualTag(taglist_sm),
    };
  };

  const trackCard = (e) => {
    track.analytics(getAnalyticsData(), e);
  };

  const renderBredcrum = (type) => {
    const tagListText = type && taglist_sm && bredcrumText(taglist_sm, type);
    if (tagListText) {
      return (
        <li>
          <span>{tagListText}</span>
          <span className="dots"></span>
        </li>
      );
    } else {
      return null;
    }
  };

  const getStyle = () => {
    if (isLoginChecked) {
      return {};
    }
    return { opacity: '0.3', pointerEvents: 'none' };
  };

  const retriveSuccessCallback = typeof window !== 'undefined' && getParameterByName('state', true);

  useEffect(() => {
    if (retriveSuccessCallback && isLoginChecked) {
      const successCallData = retriveSuccessCallback.split(',');
      if (successCallData && successCallData[0] === 'mutvHeroVideo') {
        const isSubscribedContent = successCallData[1] && successCallData[1] === 'true';
        const userSubscribed = isUserSubscribe;
        if (!isSubscribedContent || (isSubscribedContent && userSubscribed)) {
          idRef.current = itemid_s;
          setPlayVideo(true);
          const url = window.location.href;
          const urlMain = url.split('?')[0] ?? url;
          window.history.replaceState({}, null, urlMain);
        }
      }
    }
  }, [retriveSuccessCallback, isLoginChecked]);

  const successCallback = ({ showContent, isSubscribedContent }) => {
    if (showContent) {
      if (!MU.isIDMEnabled) {
        idRef.current = itemid_s;
        setPlayVideo(true);
      } else return ['mutvHeroVideo', isSubscribedContent];
    }
  };

  //For removing the black bar from the video for landsacpe and potrait mode
  const setInlineVideoContainer = () => {
    try {
      if (heroDataRef.current && containerRef.current && containerType) {
        const orientObj = {
          vD: getOrientation(heroDataRef.current, containerType),
          container: containerRef.current,
        };
        orientObj && orientObj.vD && setVideoStyle(setInlineVideoContainerUtil(orientObj));
      }
    } catch (e) {
      console.log('Error', e);
      return null;
    }
  };

  const resizeListener = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setInlineVideoContainer();
    }, THRESHOLD);
  };

  const onTranscriptClick = (showTranscript) => {
    if (playVideo && containerRef && containerRef.current) {
      const videoAttr = containerRef.current.querySelector('video-js');
      if (videoAttr) {
        const videoAttrId = videoAttr.getAttribute('id');
        showTranscript ? pauseOnNextPrev(videoAttrId) : playOnNextPre(videoAttrId);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="mutv-hero-container">
        <div
          className={`mutv-hero ${getClassName()}`}
          item-id={itemid_s}
          isclip={isClipVideo ? 'true' : 'false'}
          ref={containerRef}
          style={playVideo ? videoStyle : isShowScreen ? {} : { transition: 'all .4s ease' }}
        >
          <div className="mu-content">
            {initialLoad && (
              <MutvHeroShimmer type="video" isShowScreen={isShowScreen ? true : false} />
            )}
            {!initialLoad && Object.keys(heroData).length > 0 && (
              <div className="mu-item video">
                <div className="img-holder">
                  {ImageCropUrl && ImageCropUrl.CropUrl && (
                    <LazyLoad once={true} offset={100}>
                      <ImgCrop
                        imgCrop={ImageCropUrl.CropUrl}
                        alt={ImageCropUrl.AltText}
                        isWebpAvailable={ImageCropUrl.isWebpAvailable}
                        gridClass="grid-12"
                        isUpdateImage={true}
                      ></ImgCrop>
                    </LazyLoad>
                  )}
                  {playVideo && (
                    <React.Fragment>
                      <InlineVideo
                        setShowSwitching={setShowSwitching}
                        data={
                          isPodcast ? Podcast(heroData, true).fields : Video(heroData, true).fields
                        }
                        itemid={itemid_s}
                        playerStatus={playerStatus}
                        isMutv={{
                          container_type: containerType,
                          subscription_status: getLoggedInStatus({
                            isLoginChecked,
                            isUserLogin,
                            isUserSubscribe,
                            isUserSkyId,
                          }),
                          isPodcast: isPodcast ? true : false,
                          contentStatus: contentAccessState,
                          startTime: setPlayerPosition(myListResponse, itemid_s),
                          heroData: heroData,
                          isClipVideo: isClipVideo,
                        }}
                        myListResponse={myListResponse}
                        lastResponseTime={lastResponseTime}
                        reportStatus={reportStatus}
                        status={status}
                        dispatch={dispatch}
                        contentAccessState={contentAccessState}
                        playerStop={(status) => {
                          isPodcast &&
                            showSwitching &&
                            setScreenShow({ podcast: true, video: false });
                          setPlayVideo(status);
                        }}
                        isCloseIcon={true}
                        isMediaPlaying={isMediaPlaying}
                      />

                      {showSwitching && playVideo && (
                        <button className="close-btn" onClick={() => onCloseButton()}>
                          <i className="icon-cross" data-closemodal="close"></i>
                        </button>
                      )}
                    </React.Fragment>
                  )}
                </div>
                <div style={{ display: playVideo ? 'none' : 'block' }}>
                  <Sponsor
                    contentType={contenttype_t}
                    sponsorInfo={sponsordetailinfo_s}
                    type={CONTENT_TYPE}
                    author={__publishedby_t}
                    createdDateTime={__published_tdt}
                    itemId={itemid_s}
                    tags={taglist_sm}
                    cardName={heading}
                    container={containerType}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {!initialLoad && (
          <div className="container" style={{ display: playVideo ? 'none' : 'block' }}>
            <div className="mu-item__info">
              {!isShowScreen && !isMutvMovie && (
                <div className="mu-item__series">{decodeXML(series_s)}</div>
              )}
              {heading && (
                <h2 className="mu-item__title" id="mutv-main-heading">
                  {heading}
                </h2>
              )}
              {isShowScreen && <p className="mu-item__teasure">{description}</p>}
              <div className="breadcrum">
                <ul>
                  <AccessBadge
                    contentAcessLocal={contentaccess_t}
                    requiredInList={true}
                    className="badge"
                    contentTypeLocal={contenttype_t}
                    tagListLocal={taglist_sm}
                  />
                  {isContentLabelVisible({
                    labelexpirytime_tl,
                    __published_tdt,
                    appearance_tdt,
                  }) &&
                    contentlabel_s && (
                      <li>
                        <span>{contentlabel_s}</span>
                        <span className="dots"></span>
                      </li>
                    )}
                  {!isMutvMovie && renderBredcrum('series')}
                  {!isShowScreen && !isMutvMovie && renderBredcrum('episode')}
                </ul>
              </div>
              <div
                className={`hero-widgets hero-widgets-play ${
                  isClipVideo && isShowScreen ? 'remove-mylist' : ''
                }`}
              >
                <div
                  className="mu-item__play video_play_dir"
                  role="button"
                  aria-label="play video"
                  tabIndex="0"
                  aria-describedby="mutv-main-heading"
                  onClick={(e) => triggerPlayVideo(e)}
                  onKeyDown={(e) => (e.keyCode === 13 || e.which === 13) && triggerPlayVideo(e)}
                  style={getStyle()}
                >
                  <span className="icon-play"></span>
                  <span className="icon-play-text">{t('WatchVideo')}</span>
                </div>
                {isShowScreen && (
                  <Widgets
                    containerType={containerType}
                    data={heroData}
                    showMyList={!isClipVideo}
                    contentStatus={contentAccessState}
                    isUserSubscribe={isUserSubscribe}
                    isUserSkyId={isUserSkyId}
                  ></Widgets>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {!initialLoad && !isShowScreen && Object.keys(heroData).length > 0 && (
        <div className="container mutv-container">
          <EpisodeNote
            Description={description}
            showMyList={!isClipVideo}
            data={heroData}
            containerType={containerType}
            page={'videoDetail'}
            contentStatus={contentAccessState}
            isUserSubscribe={isUserSubscribe}
            isUserSkyId={isUserSkyId}
            videoFileTranscript={videoFileTranscript}
            onTranscriptClick={onTranscriptClick}
          />
        </div>
      )}
      {isShowScreen && (
        <div className="series-widget container mutv-container">
          <div className={`mutv-episode__note`}>
            <div className="hero-widgets">
              <Widgets
                containerType={containerType}
                data={heroData}
                showMyList={!isClipVideo}
                contentStatus={contentAccessState}
                isUserSubscribe={isUserSubscribe}
                isUserSkyId={isUserSkyId}
              ></Widgets>
            </div>
          </div>
        </div>
      )}
      {isModalVisible && (
        <ErrorBoundary>
          <RegistrationModal
            closeModal={closeModal}
            fallback={<Loader />}
            previousActiveElement={document.activeElement}
            accessType={contentAccessState}
            successCallback={successCallback}
            analyticsObj={getAnalyticsData()}
          />
        </ErrorBoundary>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(MutvHeroVideo);
