/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import HeroBannerLiveStreamViaEPG from '../../HeroCards/HeroBannerLiveStreamViaEPG/index';

const LiveStreamViaEPGDetail = (props) => {
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    setShowModal(true);
  }, []);
  return (
    <div className="modal-page live-stream">
      <div className="video-non-des live-card">
        {showModal && (
          <HeroBannerLiveStreamViaEPG
            showModal={showModal}
            isDestinationPage={true}
            fields={props.fields}
          />
        )}
      </div>
    </div>
  );
};

export default LiveStreamViaEPGDetail;
