import MU from '../constants/muConstants';
import axios from 'axios';
import { renderNewSearchResult } from './squizSearchUtils';
import * as track from '../utils/analytics';
import { getQueryParamValue } from './squizSearchUtils';

//Squiz search api call
export const newSearchUrl = (
  value,
  errorHandler,
  searchResultDisplayHandler,
  accessContent,
  isURLhasMUTV
) => {
  let str;
  if (isURLhasMUTV) {
    str = `${MU.squizData.squizUrl}/search.html?collection=${MU.squizData.collection}&profile=${MU.squizData.profile}&accessContent=${accessContent}&f.Tabs|mutv=MUTV&query=${value}`;
  } else
    str = `${MU.squizData.squizUrl}/search.html?collection=${MU.squizData.collection}&profile=${MU.squizData.profile}&accessContent=${accessContent}&query=${value}`;
  return fectchSearchResult(str, errorHandler, searchResultDisplayHandler);
};
const fectchSearchResult = (searchUrl, errorHandler, searchResultDisplayHandler) => {
  const query = getQueryParamValue(searchUrl, 'query');
  axios({
    url: searchUrl,
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
  })
    .then((response) => {
      if (response?.data) {
        const parser = new DOMParser();
        const doc = parser?.parseFromString(response.data, 'text/html');
        const noResultsElement = doc?.querySelector('.no-results');
        const resultCountElement = doc?.querySelector('.search-count-numeric');
        const resultCountText = resultCountElement && resultCountElement.textContent;
        const resultCount = resultCountText && parseInt(resultCountText.replace(/,/g, ''), 10);
        if (noResultsElement) {
          errorHandler(true, true, false);
          searchResultDisplayHandler('block', 'none');
          trackCard(query, 0);
        } else {
          searchResultDisplayHandler('none', 'block');
          renderNewSearchResult(response.data);
          trackCard(query, resultCount);
          track.cardImpression();
        }
      }
    })
    .catch((err) => {
      errorHandler(true, false, true);
      trackCard(query, 0);
      console.error('Error fetching search results:', err);
    });
};

export const fetchStaticConciergeAPI = (event) => {
  const route = `${MU.squizData.squizUrl}/resources/${MU.squizData.collection}/${MU.squizData.profile}/feeds/${MU.squizData.staticCategoryJson}`;

  axios({
    url: route,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      accept: 'application/json',
    },
  })
    .then((response) => {
      response && response.data && event(response.data);
    })
    .catch((err) => {
      console.log(err);
      event('');
    });
};

export const fetchSuggestionsAPI = async (searchInput, event) => {
  const route = `${MU.squizData.squizUrl}/suggest.json?collection=${MU.squizData.collection}&fmt=json%2B%2B&profile=${MU.squizData.profile}&partial_query=${searchInput}`;

  axios({
    url: route,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      accept: 'application/json',
    },
  })
    .then((response) => {
      response && response.data && event(response.data);
    })
    .catch((err) => {
      console.log(err);
      event('');
    });
};

const trackCard = (search, resultscount, e) => {
  const initialData = track.data('search');
  track.analytics({
    ...initialData,
    search_term: search,
    search_result: resultscount,
  });
};
