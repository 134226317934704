/**
 *  © 2022-2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useEffect } from 'react';
import { getRandomId } from '../../../utils/utils';
import { withTranslation } from 'react-i18next';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import {
  InputText,
  TextareaField,
  SelectBox,
  InputRadio,
  InputCheckbox,
  InputEmail,
  renderDate,
  setInputTextData,
  getValue,
  renderYear,
  DateValidation,
  arrayToObj,
} from '../../../utils/unitedFormUtils';
import { monthArr } from '../../../constants/formConstants';
const UnitedForm = (props) => {
  const {
    data,
    formError,
    formDetails,
    entryConfirm,
    userEnteredAPIData,
    onChangeFunction,
    formatedDate,
    setFormError,
    selectedData,
    setSelectedCreateDate,
    t,
    index,
  } = props;
  const { fields, id } = data;
  const {
    FieldType,
    QuestionHeadlineRTE,
    QuestionHeadline,
    HelpText,
    IsTextArea,
    RTELabel,
    Options,
    AdditionalSelectOptions,
    SecondayQuestionHeadlineRTE,
    SecondaryHelpTextRTE,
    SecondaryHelpText,
    SecondaryQuestionHeadline,
    manutds,
  } = fields;
  const [isHelpHovering, setIsHelpHovering] = useState(false);
  const handleHelpOver = () => {
    setIsHelpHovering(true);
  };
  const handleHelpOut = () => {
    setIsHelpHovering(false);
  };
  const dataType = FieldType && FieldType.value && FieldType.value;
  let formEnteredValue;
  let formTypeValue = formDetails[`FieldValue-${index + 1}`];
  if (entryConfirm) {
    if (userEnteredAPIData) {
      formEnteredValue = userEnteredAPIData[`FieldValue-${index + 1}`];
    }
  }
  useEffect(() => {
    if (formatedDate && dataType && dataType === 'Datetime') {
      const genericDate = formatedDate[`FieldValue-${index + 1}`];
      if (genericDate) {
        let error = DateValidation(genericDate, item);
        setFormError((formError) => {
          return { ...formError, [`FieldValue-${index + 1}`]: error ? error : false };
        });
      }
    }
  }, [formatedDate]);

  const item = setInputTextData(fields);
  const checkDataType = () => {
    if (dataType) {
      if (dataType === 'Text' && IsTextArea && !IsTextArea.value) {
        let validation = {};
        if (item.True) {
          validation.DataRequiredMessage = item.DataRequiredMessage;
        }
        validation = {
          ...validation,
          MinNumberMessage: item.DataRequiredMessage,
          MaxNumberMessage: item.DataRequiredMessage,
          MinNumber: item.MinNumber,
          MaxNumber: item.MaxNumber,
          AlphaNumericMessage: item.DataRequiredMessage,
          Regex: item.Regex,
        };
        return (
          <div className="formBox">
            <div className="formContainer textBox">
              {genericFields()}
              {fields && (
                <InputText
                  item={item}
                  index={index}
                  onChangeFunction={onChangeFunction}
                  formError={formError}
                  validation={validation}
                  formEnteredValue={
                    formTypeValue !== undefined
                      ? formTypeValue
                      : formEnteredValue && formEnteredValue
                  }
                />
              )}
            </div>
          </div>
        );
      } else if (dataType === 'DropDown') {
        const validation = item.True ? { DataRequiredMessage: item.DataRequiredMessage } : {};
        return (
          <div className="formBox">
            <div className="formContainer selectBox">
              {genericFields()}
              {fields && (
                <SelectBox
                  item={item}
                  index={index}
                  fields={fields}
                  onChangeFunction={onChangeFunction}
                  formError={formError}
                  validation={validation}
                  formEnteredValue={
                    formTypeValue ? formTypeValue : formEnteredValue && formEnteredValue
                  }
                />
              )}
            </div>
          </div>
        );
      } else if (dataType === 'Text' && IsTextArea && IsTextArea.value) {
        let validation = {};
        if (item.True) {
          validation.DataRequiredMessage = item.DataRequiredMessage;
        }
        validation = {
          ...validation,
          MinNumberMessage: item.DataRequiredMessage,
          MaxNumberMessage: item.DataRequiredMessage,
          MinNumber: item.MinNumber,
          MaxNumber: item.MaxNumber,
          AlphaNumericMessage: item.DataRequiredMessage,
          Regex: item.Regex,
        };
        return (
          <div className="formBox">
            <div className="formContainer textBox">
              {genericFields()}
              {fields && (
                <TextareaField
                  item={item}
                  index={index}
                  fields={fields}
                  onChangeFunction={onChangeFunction}
                  formError={formError}
                  validation={validation}
                  formEnteredValue={
                    formTypeValue !== undefined
                      ? formTypeValue
                      : formEnteredValue && formEnteredValue
                  }
                />
              )}
            </div>
          </div>
        );
      } else if (dataType === 'CheckBox') {
        const validation = item.True ? { DataRequiredMessage: item.DataRequiredMessage } : '';
        return (
          <div className="formBox">
            <div className="formContainer checkBox">
              {fields && (
                <InputCheckbox
                  item={item}
                  data={data}
                  index={index}
                  fields={fields}
                  onChangeFunction={onChangeFunction}
                  validation={validation}
                  formError={formError}
                  isMultiSelect={false}
                  entryConfirm={entryConfirm}
                  formEnteredValue={
                    formTypeValue === 'True' || formTypeValue === 'False'
                      ? formTypeValue
                      : (formEnteredValue === 'True' || formEnteredValue === 'False') &&
                        formEnteredValue
                  }
                />
              )}
              {HelpText && HelpText.value && (
                <>
                  <span
                    onMouseOver={handleHelpOver}
                    onMouseOut={handleHelpOut}
                    className="icon-helpForm-icon"
                  ></span>
                  {isHelpHovering && <p className="help-tooltip">{HelpText.value}</p>}
                </>
              )}
            </div>
          </div>
        );
      } else if (dataType === 'MultiSelect') {
        const validation = item.True
          ? { MultiSelectCheckBoxRequiredMessage: item.DataRequiredMessage }
          : {};
        const nameCheckBox = `FieldValue-${index + 1}`;
        const showError = getValue(formError, nameCheckBox);
        const options = manutds && manutds.value && manutds.value.Options && manutds.value.Options;
        let selectoptions = options && arrayToObj(options);
        const Options =
          AdditionalSelectOptions && AdditionalSelectOptions.length > 0
            ? AdditionalSelectOptions
            : selectoptions && selectoptions.length > 0
            ? selectoptions
            : null;
        return (
          <div className="formBox">
            <div
              className="formContainer MultiSelectBox"
              data-validation-object={JSON.stringify(validation)}
              name={`FieldValue-${index + 1}`}
            >
              {genericFields()}
              {Options &&
                Options.length > 0 &&
                Options.map((elem, key) => {
                  return (
                    <div className={`formContainer checkBox checkBox_${key}`} key={key}>
                      {elem && (
                        <InputCheckbox
                          item={item}
                          data={data}
                          index={index}
                          fields={fields}
                          onChangeFunction={onChangeFunction}
                          validation={validation}
                          formError={formError}
                          isMultiSelect={true}
                          elem={elem}
                          boxIndex={key}
                          formEnteredValue={
                            formTypeValue ? formTypeValue : formEnteredValue && formEnteredValue
                          }
                          entryConfirm={entryConfirm}
                        />
                      )}
                    </div>
                  );
                })}
              {SecondaryHelpTextRTE && SecondaryHelpTextRTE.value ? (
                <RichText
                  className="secondaryHelpTxt"
                  tag="span"
                  field={{ value: SecondaryHelpTextRTE.value }}
                ></RichText>
              ) : (
                SecondaryHelpText &&
                SecondaryHelpText.value && (
                  <label className="secondaryHelpTxt">{SecondaryHelpText.value}</label>
                )
              )}
              {showError && (
                <span role="alert" className="formError">
                  {showError}
                </span>
              )}
            </div>
          </div>
        );
      } else if (dataType === 'Radiochoice') {
        const validation = item.True ? { DataRequiredMessage: item.DataRequiredMessage } : '';
        const nameRadio = index !== 'undefined' && `FieldValue-${index + 1}`;
        const showError = getValue(formError, nameRadio);
        const options = manutds && manutds.value && manutds.value.Options && manutds.value.Options;
        let selectoptions = options && arrayToObj(options);
        const Options =
          AdditionalSelectOptions && AdditionalSelectOptions.length > 0
            ? AdditionalSelectOptions
            : selectoptions && selectoptions.length > 0
            ? selectoptions
            : null;
        return (
          <div className="formBox">
            <div className="formContainer radioBox">
              {genericFields()}
              <ul
                type="radio"
                data-validation-object={JSON.stringify(validation)}
                name={`FieldValue-${index + 1}`}
              >
                {Options &&
                  Options.length > 0 &&
                  Options.map((elem, key) => {
                    let radioId = `f-option${key}${getRandomId()}`;
                    return (
                      <li key={key}>
                        <InputRadio
                          item={item}
                          radioId={radioId}
                          data={data}
                          index={index}
                          fields={fields}
                          onChangeFunction={onChangeFunction}
                          validation={validation}
                          formError={formError}
                          dataVal={elem}
                          formDetails={formDetails}
                          nameRadio={nameRadio}
                          showError={showError}
                          formEnteredValue={
                            formTypeValue ? formTypeValue : formEnteredValue && formEnteredValue
                          }
                        />
                        {elem && elem.fields && elem.fields.name && elem.fields.name.value && (
                          <label htmlFor={radioId}>{elem.fields.name.value}</label>
                        )}
                        <div className="check"></div>
                      </li>
                    );
                  })}
              </ul>
              {SecondaryHelpTextRTE && SecondaryHelpTextRTE.value ? (
                <RichText
                  className="secondaryHelpTxt"
                  tag="span"
                  field={{ value: SecondaryHelpTextRTE.value }}
                ></RichText>
              ) : (
                SecondaryHelpText &&
                SecondaryHelpText.value && (
                  <label className="secondaryHelpTxt">{SecondaryHelpText.value}</label>
                )
              )}
              {showError && (
                <span role="alert" className="formError">
                  {showError}
                </span>
              )}
            </div>
          </div>
        );
      } else if (dataType === 'Datetime') {
        const DOBDateName = `FieldValue-${index + 1}_Date`;
        const DOBMonthName = `FieldValue-${index + 1}_Month`;
        const DOBYearName = `FieldValue-${index + 1}_Year`;
        const DOBDateValidName = `FieldValue-${index + 1}`;
        const validationMonth = item.True ? { DataRequiredMessage: item.DataRequiredMessage } : {};
        const validationDate = item.True ? { DataRequiredMessage: item.DataRequiredMessage } : {};
        const validationYear = item.True ? { DataRequiredMessage: item.DataRequiredMessage } : {};
        const validationSubmitDate = {
          ...validationDate,
          DateValidMessage: item.DataRequiredMessage,
        };
        const validationSubmitMonth = {
          ...validationMonth,
          DateValidMessage: item.DataRequiredMessage,
        };
        const validationSubmitYear = {
          ...validationYear,
          DateValidMessage: item.DataRequiredMessage,
        };
        let showDateError = getValue(formError, DOBDateName);
        let showMonthError = getValue(formError, DOBMonthName);
        let showYearError = getValue(formError, DOBYearName);
        let showDateValidError = getValue(formError, DOBDateValidName);
        let enteredDate;
        let MonthType;
        let enteredYear;
        if (formEnteredValue) {
          if (formEnteredValue.includes('-')) {
            enteredYear = formEnteredValue.split('-')[0];
            let enteredMonth = formEnteredValue.split('-')[1];
            enteredDate = formEnteredValue.split('-')[2];
            if (enteredMonth) {
              const MonthNum = Number(enteredMonth);
              if (!isNaN(MonthNum)) {
                MonthType = monthArr[MonthNum - 1].value;
              }
            }
          }
        }
        let typeDateValue;
        let typeMonthValue;
        let typeYearValue;
        if (selectedData) {
          typeDateValue = selectedData[DOBDateName];
          typeMonthValue = selectedData[DOBMonthName];
          typeYearValue = selectedData[DOBYearName];
        }
        return (
          <div className="formBox">
            <div className="formContainer selectBox">
              {genericFields()}
              {fields && (
                <>
                  <div className="selectDate select_date">
                    <select
                      id="dateBox"
                      className={`formElem inputSelectBox ${showDateError && 'is_error'} ${
                        showDateValidError && 'is_error'
                      }`}
                      aria-invalid={showDateError ? true : false}
                      aria-label={t('Date')}
                      data-date=""
                      aria-required={item.True}
                      name={DOBDateName}
                      onChange={(e) => onChangeFunction(e, item, validationDate)}
                      data-validation-object={JSON.stringify(validationSubmitDate)}
                      value={typeDateValue ? typeDateValue : enteredDate && enteredDate}
                    >
                      {renderDate(t, true)}
                    </select>
                    {showDateError && (
                      <span role="alert" className="formError">
                        {showDateError}
                      </span>
                    )}
                  </div>
                  <div className="selectDate select_month">
                    <select
                      id="dateBox"
                      className={`formElem inputSelectBox ${showMonthError && 'is_error'} ${
                        showDateValidError && 'is_error'
                      }`}
                      aria-invalid={showMonthError ? true : false}
                      aria-label={t('Month')}
                      data-month=""
                      aria-required={item.True}
                      name={DOBMonthName}
                      onChange={(e) => onChangeFunction(e, item, validationMonth)}
                      data-validation-object={JSON.stringify(validationSubmitMonth)}
                      value={typeMonthValue ? typeMonthValue : MonthType && MonthType}
                    >
                      <option value="" selected disabled="disabled" aria-label={t('Month')} key="0">
                        {t('Month')}
                      </option>
                      {monthArr &&
                        monthArr.length > 0 &&
                        monthArr.map((item, index) => (
                          <option value={t(item.value)} key={index}>
                            {t(item.name)}
                          </option>
                        ))}
                    </select>
                    {showMonthError && (
                      <span role="alert" className="formError">
                        {showMonthError}
                      </span>
                    )}
                  </div>
                  <div className="selectDate select_year">
                    <select
                      id="dateBox"
                      className={`formElem inputSelectBox ${showYearError && 'is_error'} ${
                        showDateValidError && 'is_error'
                      }`}
                      aria-invalid={showYearError ? true : false}
                      aria-label={t('Year')}
                      data-year=""
                      aria-required={item.True}
                      name={DOBYearName}
                      onChange={(e) => onChangeFunction(e, item, validationYear)}
                      data-validation-object={JSON.stringify(validationSubmitYear)}
                      value={typeYearValue ? typeYearValue : enteredYear && enteredYear}
                    >
                      {renderYear(t, true, data, fields)}
                    </select>
                  </div>
                  {showDateValidError && (
                    <span role="alert" className="formError">
                      {showDateValidError}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        );
      } else if (dataType === 'RichTextLabel') {
        return (
          <div className="formBox">
            <div className="formContainer richTxt">
              {fields && <RichText tag="span" field={RTELabel} editable={false} />}
            </div>
          </div>
        );
      }
    }
  };
  const genericFields = () => {
    return (
      <>
        {QuestionHeadlineRTE && QuestionHeadlineRTE.value ? (
          <RichText
            className="headTxt"
            tag="span"
            field={{ value: QuestionHeadlineRTE.value }}
          ></RichText>
        ) : QuestionHeadline && QuestionHeadline.value ? (
          <label className="headTxt">{QuestionHeadline.value}</label>
        ) : (
          HelpText && HelpText.value && <p className="emptyBox"></p>
        )}
        {SecondayQuestionHeadlineRTE && SecondayQuestionHeadlineRTE.value ? (
          <RichText
            className="secondaryTxt"
            tag="span"
            field={{ value: SecondayQuestionHeadlineRTE.value }}
          ></RichText>
        ) : (
          SecondaryQuestionHeadline &&
          SecondaryQuestionHeadline.value && (
            <label className="secondaryTxt">{SecondaryQuestionHeadline.value}</label>
          )
        )}
        {HelpText && HelpText.value && (
          <>
            <span
              onMouseOver={handleHelpOver}
              onMouseOut={handleHelpOut}
              className="icon-helpForm-icon"
            ></span>
            {isHelpHovering && <p className="help-tooltip">{HelpText.value}</p>}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <div className="formField">{checkDataType()}</div>
    </>
  );
};
export default withTranslation()(UnitedForm);
