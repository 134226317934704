/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { isEmpty } from 'lodash';
import { buildImgUrl } from '../../../utils/utils';
import { getAMPVideoIframeURL } from '../../../utils/articleUtils';

const AMPHeroBanner = ({ Image, Video }) => {
  const imgUrl = buildImgUrl(Image && Image.value && Image.value.Crops && Image.value.Crops.medium);
  return (
    <React.Fragment>
      {!isEmpty(Video && Video.value) && (
        <amp-iframe
          title="Ooyala Video Player"
          width="360"
          height="203"
          sandbox="allow-scripts allow-same-origin allow-popups"
          layout="responsive"
          frameborder="0"
          allowfullscreen
          allow="encrypted-media"
          src={getAMPVideoIframeURL(imgUrl, Video && Video.value && Video.value.MediaId)}
        >
          {imgUrl && imgUrl.length > 0 && (
            <amp-img
              layout="fill"
              src={imgUrl}
              alt={Image && Image.value && Image.value.AltText}
              placeholder
            />
          )}
        </amp-iframe>
      )}
      {isEmpty(Video && Video.value) && imgUrl && imgUrl.length > 0 && (
        <amp-img
          src={imgUrl}
          layout="responsive"
          height="246"
          width="328"
          alt={Image && Image.value && Image.value.AltText}
        />
      )}
    </React.Fragment>
  );
};

export default AMPHeroBanner;
