/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import { createTagList } from '../../utils/utils';
export const Quiz = (data, isAws) => {
  const sponsor = isAws
    ? data.sponsordetailinfo_s && data.sponsordetailinfo_s[0]
    : data.sponsordetailinfo_s && JSON.parse(data.sponsordetailinfo_s)[0];
  return {
    fields: {
      BackgroundImage: {
        value: {
          Crops: isAws
            ? data.celumimagesvariant_s &&
              data.celumimagesvariant_s.ImageCropUrl &&
              data.celumimagesvariant_s.ImageCropUrl.CropUrl
              ? data.celumimagesvariant_s.ImageCropUrl.CropUrl
              : data.imagecropurl_s
            : data.imagecropurl_s && JSON.parse(data.imagecropurl_s),

          AltText: isAws
            ? data.celumimagesvariant_s &&
              data.celumimagesvariant_s.ImageCropUrl &&
              data.celumimagesvariant_s.ImageCropUrl.AltText
            : data.alttext_s,
          isWebpAvailable: isAws
            ? data.celumimagesvariant_s &&
              data.celumimagesvariant_s.ImageCropUrl &&
              data.celumimagesvariant_s.ImageCropUrl.isWebpAvailable
            : data.iswebpavailablebool_b,
        },
      },
      Introduction: {
        value: data.introduction_t,
      },
      Title: {
        value: data.title_t ? data.title_t : data.title,
      },
      EndTime: {
        value: data.endtime_tdt,
      },
      Teaser: {
        value: data.teaser_t,
      },
      Variant: {
        value: data.variant_t && data.variant_t.toLowerCase(),
      },
      IsLoginRequire: {
        value: data.isloginrequire_b,
      },
      ContentType: {
        value: data.contenttype_t,
      },
      CardType: {
        value: data.cardtype_t,
      },
      ContentAccess: {
        value: data.contentaccess_t ? data.contentaccess_t : null,
      },
      IsNumber: {
        value: data.isnumber_b,
      },
      Sponsorby: sponsor
        ? {
            fields: {
              IsTelco: {
                value: false,
              },
              PartnerIconDark: {
                value: {
                  Crops: {
                    img1x: sponsor.PartnerIconDark,
                  },
                },
              },
              PartnerName: {
                value: sponsor.PartnerName,
              },
              ShortDescription: {
                value: '',
              },
              CTAURL: {
                value: {
                  href: sponsor.CTAURL,
                },
              },
              IsActive: {
                value: true,
              },
              MCC_MNC: {
                value: sponsor.MCC_MNC,
              },
              PartnerIconLight: {
                value: {
                  Crops: {
                    img1x: sponsor.PartnerIconLight,
                  },
                },
              },
              PartnerText: {
                value: sponsor.PartnerText,
              },
            },
          }
        : null,
      __Published: {
        value: data.appearance_tdt || data.__published_tdt,
      },
      __PublishedBy: {
        value: data.__publishedby_t,
      },
      manutds: {
        value: {
          DestinationUrl: data.destinationurl_s,
          Tags: createTagList(data.taglist_sm, data.category_s),
          Author: isAws ? data.authorname_s : data.author_t,
          Range: data.quizrangeandrangetext_s,
        },
      },
    },
    rendering: {
      dataSource: data.itemid_s,
    },
  };
};
