/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState } from 'react';
import Accordion from '../Accordion';
import { withTranslation } from 'react-i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import * as track from '../../../utils/analytics';
const ContactUs = (props) => {
  const { t, fields } = props;
  const [activeState, setActiveState] = useState(false);
  const trackLink = (data, e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: data.name,
        destination_url: data.url,
      },
      e
    );
  };
  return (
    <Accordion {...props} isContactUs={true} isOldUi={true} onChange={setActiveState}>
      {fields.Heading2 && fields.Heading2.value && (
        <h2 className="contact-us__title">
          <Text field={fields.Heading2} />
        </h2>
      )}
      {fields.PhoneList && fields.PhoneList.length > 0 && (
        <div className="contact-us__phone">
          <i className="contact-us__icon icon-phone"></i>
          {fields.PhoneList.map((value, key) => (
            <div className="contact-us__phone--list" key={key}>
              <p>{value.PhoneHeading}</p>
              <div className="contact-us__no">
                <a
                  href={`tel:${value.PhoneNumber}`}
                  className="tel-no hyper-link"
                  tabIndex={activeState ? null : -1}
                  aria-hidden={activeState ? null : true}
                  onClick={(e) =>
                    trackLink(
                      {
                        name: value.PhoneNumber,
                        url: `tel:${value.PhoneNumber}`,
                      },
                      e
                    )
                  }
                >
                  {value.PhoneNumber}
                </a>
              </div>
            </div>
          ))}
        </div>
      )}
      {fields.MailList && fields.MailList.length > 0 && (
        <div className="contact-us__mail">
          <i className="contact-us__icon icon-email"></i>
          {fields.MailList.map((value, key) => (
            <div className="contact-us__mail--list" key={key}>
              <p>{value.EmailHeading}</p>
              <div className="contact-us__no">
                <a
                  href={`mailto:${value.EmailAddress}`}
                  className="contact-us__id hyper-link"
                  tabIndex={activeState ? null : -1}
                  aria-hidden={activeState ? null : true}
                  onClick={(e) =>
                    trackLink(
                      {
                        name: value.EmailAddress,
                        url: `mailto:${value.EmailAddress}`,
                      },
                      e
                    )
                  }
                >
                  {value.EmailAddress}
                </a>
              </div>
            </div>
          ))}
        </div>
      )}
    </Accordion>
  );
};
export default withTranslation()(ContactUs);
