/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import ImgCrop from '../Common/ImgCrop';
import { withTranslation } from 'react-i18next';

const HeroBanner = ({ t, heroData }) => {
  return (
    heroData && (
      <div className="home-article-detail  clearfix">
        <div className="home-article-detail__top-panel">
          <div className="grid-12 ratio crop-type-article-detail" id="cropTypeArticleDetail">
            <div className="mu-content">
              <article className="mu-item xlarge">
                <div className="img-holder">
                  <ImgCrop
                    imgCrop={
                      heroData.celumimagesvariant_s &&
                      heroData.celumimagesvariant_s.ImageCropUrl &&
                      heroData.celumimagesvariant_s.ImageCropUrl.CropUrl
                    }
                    alt={
                      heroData.celumimagesvariant_s &&
                      heroData.celumimagesvariant_s.ImageCropUrl &&
                      heroData.celumimagesvariant_s.ImageCropUrl.altText
                    }
                    isWebpAvailable={
                      heroData.celumimagesvariant_s &&
                      heroData.celumimagesvariant_s.ImageCropUrl &&
                      heroData.celumimagesvariant_s.ImageCropUrl.isWebpAvailable
                    }
                  ></ImgCrop>
                </div>
                <div className="home-article-detail__info">
                  <h2 className="home-article-detail__title">{heroData.eventheading_t} </h2>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default withTranslation()(HeroBanner);
