/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import * as track from '../utils/analytics';
import MU from '../constants/muConstants';
import { getCookie, isApp } from '../utils/utils';
export const insertAdobeScript = (layoutId) => {
  let src = MU && MU.adobeSettingScript;
  const userConsent_off = getCookie('userConsentCookiePolicy') === 'off';
  if (!userConsent_off) {
    if (isApp()) {
      src = MU && MU.adobeSettingScriptApp;
    }
    if (src && !window._satellite) {
      const id = 'adobe-analytics';
      try {
        var sTag = document.createElement('script');
        sTag.src = src;
        sTag.id = id;
        sTag.classList = 'third-party-scripts';
        document.head.appendChild(sTag);
        var bTag = document.createElement('script');
        var bcode = '_satellite.pageBottom();';
        bTag.classList = 'third-party-scripts';
        try {
          bTag.appendChild(document.createTextNode(bcode));
        } catch (e) {
          bTag.text = bcode;
        }
        sTag.onload = () => {
          document.body.appendChild(bTag);
          setTimeout(function () {
            track.pageView(false, layoutId);
          }, 100);
        };
        sTag.onerror = () => sTag.parentNode.removeChild(sTag);
      } catch (err) {
        console.log('Adobe >> PlugIn Inject Error', err);
      }
    } else {
      console.log('Adobe >> PlugIn src not available!');
    }
  }
};
