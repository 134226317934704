/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import LiveBlogEvent from '../../Cards/LiveBlogEvent';
import { HERO_LIVE_EVENT } from '../../../constants/containerConstants';

const HeroBannerEvent = (props) => {
  return (
    <div className="home-row">
      <div className="home-hero">
        <LiveBlogEvent container={HERO_LIVE_EVENT} {...props} gridClass="grid-12" />
      </div>
    </div>
  );
};
export default HeroBannerEvent;
