/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { NEWS_LISTING, VIDEO_LISTING } from '../../constants/containerConstants';
import Transform from '../../data-transform';

const HeroContainer = (props) => {
  const { hero, setHeroClassName, filters, listing } = props;

  const getHeroClass = (filters) => {
    let heroClass = 'hero__container';
    if (filters) {
      if (listing === 'video') {
        heroClass = '';
      } else {
        heroClass = 'hero__container';
      }
    } else {
      if (listing === 'video') {
        heroClass = 'no-filter';
      } else {
        heroClass = 'hero__container no-filter';
      }
    }
    return heroClass;
  };

  const containerType = () => {
    if (listing === 'news') {
      return NEWS_LISTING;
    } else if (listing === 'video') {
      return VIDEO_LISTING;
    } else {
      return '';
    }
  };

  return (
    <React.Fragment>
      {hero && hero.length > 0 && (
        <div className={setHeroClassName}>
          <div className={getHeroClass(filters)}>
            <div className="news-list__block">
              {hero.map((item) => {
                return (
                  item &&
                  item.enablehero_b === true && (
                    <div className="hero-item news-list-hero-item">
                      <Transform
                        container={containerType()}
                        data={item}
                        source="aws"
                        gridClass="grid-12"
                      />
                    </div>
                  )
                );
              })}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default HeroContainer;
