/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import Quotes from '../../Cards/Quotes';
import { HERO_QUOTE } from '../../../constants/containerConstants';

const HeroBannerQuote = (props) => {
  return (
    <div className="home-row">
      <div className="home-hero">
        <Quotes {...props} container={HERO_QUOTE} isHeroBannerQuote={true} gridClass="grid-12" />
      </div>
    </div>
  );
};
export default HeroBannerQuote;
