/**
 *  © 2022-2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_COMINGUPDATA,
  SET_COMINGUPDATA,
  ERROR_COMINGUPDATA,
} from '../constants/actions/actionConstant';
import { GET_COMINGUP_CAROUSEL } from '../constants/endpoints';
import api from '../utils/muApi';
import { cominUp_Response } from '../components/Comingup';

export const fetchComingupData = (dispatch, status) => {
  try {
    const comingUpScreens = cominUp_Response.manutds.value.Tags.Screens[0].Tag;
    if (comingUpScreens) {
      const COMINGUP_ROUTE = comingUpScreens.substring(comingUpScreens.indexOf('/') + 1);
      if (COMINGUP_ROUTE) {
        let route = `${GET_COMINGUP_CAROUSEL}${encodeURI(COMINGUP_ROUTE)}`;

        dispatch(fetchingComingupData());
        return api
          .get({ route })
          .then((response) => {
            dispatch(setComingupData(response));
          })
          .catch((err) => {
            dispatch(error(err));
          });
      }
    }
  } catch (e) {
    console.error('Error in Screens Tags');
  }
};

export const fetchingComingupData = () => ({ type: FETCH_COMINGUPDATA });
export const setComingupData = (response) => ({ type: SET_COMINGUPDATA, response });
export const error = (response) => ({ type: ERROR_COMINGUPDATA, response });
