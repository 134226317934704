/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState, useRef } from 'react';
import { isExperienceEditorActive, RichText } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import MU from '../../../constants/muConstants';
import { getAllQueryParamsString, getWayinCookie } from '../../../utils/utils';

const getWayinFormData = (data) => {
  let formattedData = data;
  try {
    if (formattedData) {
      const paramsString = getAllQueryParamsString();
      const advancedCodeIdentification = 'iframe ';
      const responsiveModeString = '?mode=responsive';
      let replaceString = responsiveModeString;
      if (paramsString && paramsString.length) {
        replaceString = replaceString + paramsString;
      }
      if (formattedData.toLowerCase().includes(advancedCodeIdentification)) {
        formattedData = formattedData.replace(responsiveModeString, replaceString);
      }
    }
  } catch (e) {
    console.log(e);
    return data;
  }
  return formattedData;
};

const WayinForm = (props) => {
  const { WayinScript } = props.fields;
  const data = getWayinFormData(WayinScript && WayinScript.value);
  const wayinRef = useRef(null);
  const { pageEditing } = props.sitecoreContext;
  const [isExpEditor, setIsExpEditor] = useState(false);
  useEffect(() => {
    setIsExpEditor(isExperienceEditorActive());
  }, []);
  const createDom = (script, iframe) => {
    let dom = wayinRef && wayinRef.current;
    if (dom) {
      let s = document.createElement('script');
      if (script) {
        try {
          for (let index = script.attributes.length - 1; index > -1; --index) {
            const attribute = script.attributes[index];
            s[attribute.name] = attribute.value;
          }
        } catch (e) {
          console.log('error in way in script render', e);
          s = script;
        }
        dom.appendChild(s);
        eval(s);
      }
      if (iframe) {
        dom.append(iframe);
      }
    }
  };

  const checkCookie = (script, iframe) => {
    let src = iframe && iframe.src;
    let userConsent = getWayinCookie('userConsentCookiePolicy');
    let checkUserConsent;
    if (userConsent !== '') {
      if (userConsent === 'on') {
        let newGmT = src + '&Consent=True';
        setIframeSrc(iframe, newGmT);
        createDom(script, iframe);
      } else {
        let newGmF = src + '&Consent=False';
        setIframeSrc(iframe, newGmF);
        createDom(script, iframe);
      }
    } else {
      checkUserConsent = setInterval(() => {
        let isCurrentGeoinConsentList = MU && MU.isCurrentGeoinConsentList;
        userConsent = getWayinCookie('userConsentCookiePolicy');
        if (isCurrentGeoinConsentList) {
          if (userConsent === 'on') {
            let newGmT = src + '&Consent=True';
            setIframeSrc(iframe, newGmT);
            createDom(script, iframe);
            clearInterval(checkUserConsent);
          } else if (userConsent === 'off') {
            let newGmF = src + '&Consent=False';
            setIframeSrc(iframe, newGmF);
            createDom(script, iframe);
            clearInterval(checkUserConsent);
          }
        } else {
          let newInfo = src + '&Consent=True';
          setIframeSrc(iframe, newInfo);
          createDom(script, iframe);
          clearInterval(checkUserConsent);
        }
      }, 800);
    }
  };

  const setIframeSrc = (iframe, val) => {
    if (iframe) {
      iframe.src = val;
    }
  };

  useEffect(() => {
    if (wayinRef && wayinRef.current && data && data.includes('<iframe')) {
      let node = data && new DOMParser().parseFromString(data, 'text/html');
      let script = node && node.head && node.head.childNodes;
      script = script && script[0];
      let iframe = node && node.body && node.body.childNodes;
      iframe = iframe && iframe[0];
      checkCookie(script, iframe);
    }
  }, [wayinRef]);

  return (
    <div className="wide-form-panel" aria-hidden="true">
      {(isExpEditor || pageEditing) && (
        <div>
          <b>
            Wayin Form
            <br />
          </b>
        </div>
      )}
      {data ? (
        data.includes('<iframe') ? (
          <div ref={wayinRef} id="wayInWrap" />
        ) : (
          <RichText editable={false} field={{ value: data }}></RichText>
        )
      ) : null}
    </div>
  );
};
export default React.memo(withSitecoreContext()(WayinForm));
