/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { withTranslation } from 'react-i18next';
import MU from '../../constants/muConstants';
import * as track from '../../utils/analytics';
import ImgCrop from '../Common/ImgCrop';
import LazyLoad from 'react-lazyload';
const UserDetails = ({
  t,
  gigyaData,
  myUnitedStyleObj,
  EnableSegmentationRules,
  defaultSegmentRules,
  displayMembershipId,
}) => {
  const firstName = gigyaData && gigyaData.profile && gigyaData.profile.firstName;
  const lastName = gigyaData && gigyaData.profile && gigyaData.profile.lastName;
  const mufcNumber = gigyaData && gigyaData.data && gigyaData.data.mufcNumber;
  const photoUrl = gigyaData && gigyaData.profile && gigyaData.profile.photoURL;
  const getDecoderUrl = (url) => {
    try {
      return url ? decodeURIComponent(url) : '';
    } catch (e) {}
  };
  const getDefaultBadgeIcon = () => {
    return `${MU && MU.assetUrlResource}/assets/images/icons/verify.png`;
  };
  const getBadgeIcon = () => {
    return (
      <>
        {myUnitedStyleObj && myUnitedStyleObj.badgeIcon && (
          <span className="member__verify-icon">
            <LazyLoad>
              <ImgCrop
                imgCrop={myUnitedStyleObj.badgeIcon}
                isWebpAvailable={myUnitedStyleObj.iswebpavailable_s}
              ></ImgCrop>
            </LazyLoad>
          </span>
        )}
      </>
    );
  };
  const trackLink = (e, btnName, targetUrl) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: btnName,
        destination_url: btnName == 'membership' ? targetUrl : decodeURIComponent(targetUrl),
      },
      e
    );
  };
  return (
    <React.Fragment>
      <div>
        <span className="welcome-text">{t('appFirstLaunchNonMatchdayWelcomeText')}</span>
        <div>
          {photoUrl ? (
            <div className="profile-pic" style={{ background: `#c4c4c4 url(${photoUrl})` }}></div>
          ) : (
            <div className="profile-pic-default"></div>
          )}
        </div>
        <h1>
          <span>{firstName}</span>
          <span>{lastName}</span>
        </h1>
      </div>
      <React.Fragment>
        <div>
          {EnableSegmentationRules ? (
            <div>
              {!defaultSegmentRules ? (
                <div className="wide">
                  {myUnitedStyleObj && myUnitedStyleObj.textLabel && (
                    <div className="member">
                      {getBadgeIcon()}
                      <p>{myUnitedStyleObj.textLabel}</p>
                    </div>
                  )}
                  {displayMembershipId && mufcNumber && (
                    <span className="member__id">{mufcNumber}</span>
                  )}
                </div>
              ) : mufcNumber ? (
                <div className="wide">
                  <div className="member">
                    <span
                      className="member__verify-icon"
                      style={{ background: `url(${getDefaultBadgeIcon()}) no-repeat center` }}
                    ></span>
                    <p>{t('MEMBERSHIPID')}</p>
                  </div>
                  {mufcNumber && <span className="member__id">{mufcNumber}</span>}
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            <div>
              {mufcNumber ? (
                <div className="wide">
                  <div className="member">
                    <span
                      className="member__verify-icon"
                      style={{ background: `url(${getDefaultBadgeIcon()}) no-repeat center` }}
                    ></span>
                    <p>{t('MEMBERSHIPID')}</p>
                  </div>
                  {mufcNumber && <span className="member__id">{mufcNumber}</span>}
                </div>
              ) : (
                ''
              )}
            </div>
          )}
        </div>
        <div className="top-btn-container">
          {myUnitedStyleObj && myUnitedStyleObj.primaryCta && (
            <div
              className={`black-btn-skew ${
                myUnitedStyleObj &&
                myUnitedStyleObj.primaryBtnBackgrounColor &&
                myUnitedStyleObj.primaryBtnBackgrounColor === 'Transparent'
                  ? 'btn-border'
                  : ''
              }`}
              style={{
                backgroundColor: `${myUnitedStyleObj.primaryBtnBackgrounColor}`,
              }}
            >
              <a
                href={getDecoderUrl(myUnitedStyleObj.primaryCtaUrl)}
                role="button"
                target="_blank"
                onClick={(e) =>
                  trackLink(e, 'Match Tickets', myUnitedStyleObj && myUnitedStyleObj.primaryCtaUrl)
                }
              >
                <span>{myUnitedStyleObj.primaryCta.substring(0, 23)}</span>
              </a>
            </div>
          )}
          {myUnitedStyleObj && myUnitedStyleObj.secondaryCta && (
            <div className="white-btn-skew">
              <a
                href={getDecoderUrl(myUnitedStyleObj.secondaryCtaUrl)}
                role="button"
                tabIndex="0"
                target="_blank"
                onClick={(e) =>
                  trackLink(
                    e,
                    'football-kits',
                    myUnitedStyleObj && myUnitedStyleObj.secondaryCtaUrl
                  )
                }
              >
                <span className="text-align">{myUnitedStyleObj.secondaryCta.substring(0, 23)}</span>
              </a>
            </div>
          )}
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};

export default withTranslation()(React.memo(UserDetails));
