/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Modal from '../../Common/Modal';
import { getSchudleTime, getSchudleDate, checkHasAttachedVideo } from '../mutvutil';
import { getContentAccessLocal } from '../../../utils/utils';
import MU from '../../../constants/muConstants';
import { ACCOUNT_SUBSCRIPTION, REGISTERED } from '../../../constants/globalConstants';
import { withTranslation } from 'react-i18next';
import { useSponsorValue } from '../../../context/sponsorContext';
import LazyLoad from 'react-lazyload';
import ImgCrop from '../../Common/ImgCrop';
import moment from 'moment';
const EpgModal = (props) => {
  const { closeModal, item, getModalCtaAction, isEpgImage, destination, t } = props;
  const {
    schedulemetadata_t,
    contentaccess_t,
    contenttype_t,
    taglist_sm,
    recordedvideoitemid_t,
    startdatetime_tdt,
    scheduletype_t,
  } = item;
  const { Description, Title, Image } = schedulemetadata_t;
  const contentAccessState = getContentAccessLocal(contentaccess_t, contenttype_t, taglist_sm);
  const [{ isUserLogin, isUserSubscribe }] = useSponsorValue();
  const getCtaButton = () => {
    if (!isUserLogin && contentAccessState === REGISTERED) {
      return (
        <a
          href="#"
          className="content-access-btn"
          onClick={(e) => getModalCtaAction(e, t('RegisterToWatch'), true)}
        >
          {t('RegisterToWatch')}
        </a>
      );
    } else if (contentAccessState === ACCOUNT_SUBSCRIPTION && !isUserSubscribe) {
      return (
        <a
          href="#"
          className="content-access-btn"
          onClick={(e) => getModalCtaAction(e, t('SubscribeToWatch'), true)}
        >
          {t('SubscribeToWatch')}
        </a>
      );
    } else if (
      checkHasAttachedVideo(schedulemetadata_t, scheduletype_t) ||
      (scheduletype_t &&
        recordedvideoitemid_t &&
        scheduletype_t.toLowerCase() === 'live' &&
        moment(startdatetime_tdt).isBefore(moment()))
    ) {
      return (
        <a
          href={destination}
          className="content-access-btn"
          style={destination ? {} : { opacity: '0.7', pointerEvents: 'none' }}
          onClick={(e) => getModalCtaAction(e, t('WatchVideo'), false)}
        >
          {t('WatchVideo')}
        </a>
      );
    }
  };

  const getDomELment = () => {
    if (isEpgImage) {
      return (
        <div className="image-wrp">
          <div className="img-holder">
            {Image && Image.CropURL && (
              <LazyLoad once={true} offset={100}>
                <ImgCrop
                  imgCrop={Image.CropURL}
                  alt={Image.AltText}
                  title={Image.AltText}
                  isWebpAvailable={Image.isWebpAvailable}
                  singleSrc={false}
                  gridClass={'grid-6 child'}
                ></ImgCrop>
              </LazyLoad>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="crest-logo">
          <img
            src={`${MU && MU.assetUrlResource}/assets/images/bg/mu-crest@2x.png`}
            alt="Mutv logo"
          />
        </div>
      );
    }
  };
  const getAccessText = () => {
    if (!isUserLogin && contentAccessState === REGISTERED) {
      return (
        <div className="content-access-required">
          <span className="icon-register-icon-epg"></span>
          <span>{t('RegistrationRequired')}</span>
        </div>
      );
    } else if (contentAccessState === ACCOUNT_SUBSCRIPTION && !isUserSubscribe) {
      return (
        <div className="content-access-required">
          <span className="icon-subscription-gold-epg"></span>
          <span>{t('SubscribeRequired')}</span>
        </div>
      );
    }
  };
  const descriptionText = () => {
    return Description ? Description : t('ContentNotAvailable');
  };

  const getParagraphText = () => {
    if (scheduletype_t && scheduletype_t.toLowerCase() !== 'live') {
      return <p className="inner-text">{descriptionText()}</p>;
    } else if (
      scheduletype_t &&
      scheduletype_t.toLowerCase() === 'live' &&
      !recordedvideoitemid_t &&
      moment(startdatetime_tdt).isBefore(moment())
    ) {
      return <p className="inner-text">{t('ThisShowAsEnded')}</p>;
    } else {
      return <p className="inner-text">{descriptionText()}</p>;
    }
  };

  return (
    <div className="customMobile">
      <Modal containerClass="dialog epg-modal" customElm={true} closeModal={closeModal}>
        <div id="modelCustomPop">
          <div className="watch-widget">
            {getDomELment()}
            <div className="watch-widget-inner">
              <h3>{Title}</h3>
              <p className="date-time">
                {getSchudleTime(item.startdatetime_tdt)} • {getSchudleDate(item.startdatetime_tdt)}
              </p>
              {getAccessText()}
              {getParagraphText()}
              {getCtaButton()}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
EpgModal.defaultProps = {
  showClose: true,
  successCallback: false,
  isDestinationPage: false,
};
export default withTranslation()(EpgModal);
