/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect } from 'react';
import { fetchedGigya } from '../../actions/sponsorAction';
import MU from '../../constants/muConstants';
const GigyaInit = ({ dispatch }) => {
  const getGigyaUrl = () => {
    if (MU.language !== 'zh') {
      const temp1 = `https://cdns.gigya.com/js/gigya.js?apiKey=${MU.gigyaAPiKey}&lang=${MU.language}&v=${MU.buildRevision}`;
      console.log('IDM 23. getGigyaUrl', temp1);
      return temp1;
    } else {
      const temp2 = `https://cdns.gigya.com/js/gigya.js?apiKey=${MU.gigyaAPiKey}&lang=${MU.language}-cn&v=${MU.buildRevision}`;
      console.log('IDM 24. getGigyaUrl', temp2);
      return temp2;
    }
  };
  useEffect(() => {
    var v = document.createElement('script');
    console.log('IDM 20. GigyaInit createElement', v);
    v.src = getGigyaUrl();
    console.log('IDM 21. GigyaInit src', v.src);
    document.body.appendChild(v);
    v.onload = () => getGigya();
  }, []);

  const getGigya = () => {
    console.log('IDM 22. getGigya');
    fetchedGigya(dispatch);
  };

  return null;
};

export default GigyaInit;
