/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useEffect } from 'react';
import NextPrevious from './NextPrevious';
import { usePlayerDetailValue } from '../../context/playerDetailContext';
import { calculateAge } from '../../utils/utils';
import { withTranslation } from 'react-i18next';
import { useSponsorValue } from '../../context/sponsorContext';
import PlayerHubIcon from './PlayerHubIcon';
const PlayerArticle = ({ t, fields, itemId }) => {
  const {
    BirthDate,
    ShirtNumber,
    FullName,
    OnLoan,
    CTAUrl,
    CTATitle,
    Quote,
    Appearances,
    Cleansheets,
    Position,
    Goals,
    NextPlayer,
    FirstName,
    LastName,
    PlayerTag,
    IsEnablePlayerHub,
    CTATitlePlayerHub,
    CTAUrlPlayerHub,
  } = fields;
  const [
    { goals, appearances, cleanSheets, isMatchLive, isPlayerPlayingLive, preloader },
    dispatch,
  ] = usePlayerDetailValue();
  const [{ userLoginDetail }] = useSponsorValue();
  const age = BirthDate && BirthDate.value ? calculateAge(BirthDate.value) : null;

  const getPreLoader = () => {
    return (
      <div className={`preloader ${preloader ? 'hidden' : ''}`}>
        <p className="screenreader">{t('Updating')}</p>
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
  };
  const [starIcon, setStarIcon] = useState(false);
  useEffect(() => {
    if (
      PlayerTag &&
      userLoginDetail &&
      userLoginDetail.data &&
      PlayerTag.value === userLoginDetail.data.favoritePlayerID
    ) {
      setStarIcon(true);
    }
  }, [userLoginDetail]);
  return (
    <article className="grid-12 player-detail__main__wrapper">
      <div className="player-info">
        <div className={`player-info__wrapper ${starIcon && 'hasFav'}`} data-module="social-share">
          <div className="player-info__main live">
            {starIcon && (
              <div className="favourite-player">
                <i className="icon-star"></i>
              </div>
            )}
            {ShirtNumber && ShirtNumber.value && (
              <div className="player-shirt-number" aria-hidden="true">
                {ShirtNumber.value}
              </div>
            )}
            <div
              className="player-content"
              aria-live="polite"
              aria-atomic="true"
              aria-relevant="additions"
            >
              <h1 className="player-name">
                <span aria-hidden="true">
                  {FirstName && FirstName.value}
                  <br />
                  {LastName && LastName.value}
                </span>
                <span className="screenreader">
                  {`${FirstName && FirstName.value} ${LastName && LastName.value} ${
                    ShirtNumber && t('Shirtnumber')
                  } ${ShirtNumber && ShirtNumber.value}`}
                </span>
              </h1>
              {isMatchLive && isPlayerPlayingLive && (
                <span className={`player-status active`}>{t('PlayingNow')}</span>
              )}
              {!isMatchLive && !isPlayerPlayingLive && (
                <span className="screenreader offline-text">{t('NotPlaying')}</span>
              )}
            </div>
            {OnLoan && OnLoan.value && <span className="player-hire">{t('Onloan')}</span>}
          </div>
          {IsEnablePlayerHub && CTAUrlPlayerHub && (
            <PlayerHubIcon
              IsEnablePlayerHub={IsEnablePlayerHub}
              CTAUrlPlayerHub={CTAUrlPlayerHub}
              CTATitlePlayerHub={CTATitlePlayerHub}
            ></PlayerHubIcon>
          )}

          {CTAUrl && CTAUrl.value && CTAUrl.value.hrefMuCustom && (
            <a
              className="player_buy-shirt-cta"
              href={CTAUrl.value.hrefMuCustom}
              target="_blank"
              data-an-track="true"
              data-track-type="link"
              data-track-text={CTATitle && CTATitle.value}
            >
              <span className="red-btn-skew">
                {CTATitle && CTATitle.value}
                <span className="screenreader">{t('ExternalWebsiteLink')}</span>
              </span>
            </a>
          )}
          <div className="player-quote">
            <span className="screenreader">
              {`${FirstName && FirstName.value} ${LastName && LastName.value} ${t('Quote')}`}
            </span>
            <blockquote>
              <span>{Quote && Quote.value}</span>
            </blockquote>
          </div>
        </div>
      </div>
      <div className="deatil-stats" data-module="social-share">
        <div className="deatil-stats__stats">
          <ul>
            {age && (
              <li data-module="profileStats">
                <div className="stats-text">{t('Age')}</div>
                <div className="stats-deatil">{age}</div>
              </li>
            )}
            {Appearances && Appearances.value && (
              <li
                className="appearances"
                data-module="profileStats"
                aria-live="polite"
                aria-atomic="true"
                aria-relevant="additions"
              >
                <div className="stats-text">{t('Appearances')}</div>
                <div className="stats-deatil">
                  {appearances && <span>{appearances}</span>}
                  {getPreLoader()}
                </div>
              </li>
            )}
            {Position &&
              Position.value &&
              Position.value.toLowerCase() === 'goalkeeper' &&
              Cleansheets &&
              Cleansheets.value && (
                <li
                  className="cleanSheets"
                  data-module=" profilestats"
                  aria-live="polite"
                  aria-atomic="true"
                  aria-relevant="additions"
                >
                  <div className="stats-text">{t('Cleansheets')}</div>
                  <div className="stats-deatil">
                    {cleanSheets && <span>{cleanSheets}</span>}
                    {getPreLoader()}
                  </div>
                </li>
              )}
            {Goals && Goals.value && Goals.value > 0 && (
              <li className="goals" data-module="profileStats">
                <div className="stats-text">{t('Totalgoals')}</div>
                <div className="stats-deatil">
                  {goals && <span>{goals}</span>}
                  {getPreLoader()}
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
      {NextPlayer && <NextPrevious fields={fields} itemId={itemId} />}
    </article>
  );
};

export default withTranslation()(PlayerArticle);
