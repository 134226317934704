/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { withTranslation } from 'react-i18next';
import Sponsor from '../Common/Sponsor';
import * as track from '../../utils/analytics';
import { MODULE, DARK_THEME } from '../../constants/globalConstants';
import { useSponsorValue } from '../../context/sponsorContext';
import { doUserLogout } from '../../utils/idmUtils';

const UserMenu = ({ t }) => {
  const [{ isGigyaAvilable }] = useSponsorValue();
  const userLogout = (e) => {
    e.preventDefault();
    if (isGigyaAvilable) {
      trackLink(e, 'view-logout');
      doUserLogout();
    }
  };
  const trackLink = (e, btnName) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: btnName,
        destination_url: e && e.target && e.target.href,
      },
      e
    );
  };
  return (
    <div className="profile__links">
      <ul>
        <li>
          <span>
            <i className="icon-mailbox"></i>
          </span>
          <label>
            <a href="#" onClick={(e) => trackLink(e, 'Inbox')}>
              {t('Inbox')}
            </a>
          </label>
        </li>
        <li>
          <a aria-label="Log out" href="#" onClick={userLogout}>
            <span>
              <p className="log-out-icon"></p>
            </span>
          </a>

          <label>
            <a href="#" className="profile-text accord-title" onClick={userLogout}>
              {t('logout')}
            </a>
          </label>
        </li>
        <li>
          <a aria-label="Settings" href="myunited/settings">
            <span>
              <p className="settings-icon"></p>
            </span>
          </a>
          <label>
            <a href="myunited/settings" onClick={(e) => trackLink(e, 'view-logout')}>
              {t('Settings')}
            </a>
          </label>
        </li>
      </ul>

      <div className="sponsor myunitedpage">
        <ul>
          <li>
            <Sponsor
              type={MODULE}
              moduleName={'myunitedpage'}
              theme={DARK_THEME}
              cardName="myunited"
              container="myunited"
              contentType="myunited"
            ></Sponsor>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default withTranslation()(React.memo(UserMenu));
