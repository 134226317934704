/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import MU from '../../constants/muConstants';
import * as track from '../../utils/analytics';
import { showEditProfile } from '../../utils/utils';
import { indexOf } from 'lodash';
import ImgCrop from '../Common/ImgCrop';
import LazyLoad from 'react-lazyload';
const JerseyDetails = ({
  t,
  gigyaData,
  jerseyNo,
  myUnitedStyleObj,
  EnableSegmentationRules,
  defaultSegmentRules,
  shirtImage,
}) => {
  const photoUrl = gigyaData && gigyaData.profile && gigyaData.profile.photoURL;
  const mufcNumber = gigyaData && gigyaData.data && gigyaData.data.mufcNumber;

  const getTshirtImage = () => {
    return (
      <div className="image">
        <LazyLoad>
          <ImgCrop
            imgCrop={shirtImage.Crops}
            // isWebpAvailable={shirtImage.isWebpAvailable}
          ></ImgCrop>
        </LazyLoad>
      </div>
    );
  };

  const getDefaultShirtImage = () => {
    return `${MU && MU.assetUrlResource}/assets/images/bg/t-shirt.png?v=5`;
  };
  const getDefaultBadgeIcon = () => {
    return `${MU && MU.assetUrlResource}/assets/images/icons/verify.png`;
  };
  const getBadgeIcon = () => {
    return (
      <>
        {myUnitedStyleObj && myUnitedStyleObj.badgeIcon && (
          <span className="member__verify-icon">
            <LazyLoad>
              <ImgCrop
                imgCrop={myUnitedStyleObj.badgeIcon}
                isWebpAvailable={myUnitedStyleObj.iswebpavailable_s}
              ></ImgCrop>
            </LazyLoad>
          </span>
        )}
      </>
    );
  };
  const trackLink = (e, btnName, targetUrl) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: btnName,
        destination_url: targetUrl,
      },
      e
    );
  };
  return (
    <div className="profile__jersy">
      {gigyaData && (
        <div
          className="profile__jersy__number"
          style={{
            background:
              (!shirtImage || !shirtImage.Crops) &&
              `url(${getDefaultShirtImage()}) no-repeat center`,
          }}
        >
          {shirtImage && shirtImage.Crops && getTshirtImage()}
          <p className="lastname">
            <span className="screenreader">{`${t('LastName')} ${gigyaData.profile.lastName}`}</span>
            <span aria-hidden="true">{gigyaData.profile.lastName}</span>
          </p>
          <p aria-hidden="true" className="jersey-no">
            {jerseyNo ? jerseyNo : '00'}
          </p>
          <p className="screenreader">{`${jerseyNo ? jerseyNo : '00'} ${t('jerseynumber')}`}</p>
          <>
            <div className="pic_content">
              {photoUrl ? (
                <div
                  className="profile-pic"
                  style={{ background: `#c4c4c4 url(${photoUrl})` }}
                ></div>
              ) : (
                <div className="profile-pic-default"></div>
              )}
            </div>
          </>
          {EnableSegmentationRules ? (
            <div>
              {!defaultSegmentRules ? (
                <div className="narrow">
                  {myUnitedStyleObj && myUnitedStyleObj.textLabel && (
                    <div className="member">
                      {defaultSegmentRules && getBadgeIcon()}
                      <p>{myUnitedStyleObj.textLabel}</p>
                    </div>
                  )}
                </div>
              ) : mufcNumber ? (
                <div className="narrow">
                  <div className="member">
                    {
                      <span
                        className="member__verify-icon"
                        style={{ background: `url(${getDefaultBadgeIcon()}) no-repeat center` }}
                      ></span>
                    }
                    <p>{t('MEMBERSHIPID')}</p>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : mufcNumber ? (
            <div className="narrow">
              <div className="member">
                <span
                  className="member__verify-icon"
                  style={{ background: `url(${getDefaultBadgeIcon()}) no-repeat center` }}
                ></span>
                <p>{t('MEMBERSHIPID')}</p>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(React.memo(JerseyDetails));
