/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { usePlayerDetailValue } from '../../context/playerDetailContext';
import { formatDate } from '../../utils/momentDate';
import ProfileStatsListing from './ProfileStatsListing';
import { DFP } from '../../components/Common/Dfp';
const PlayerStats = ({ t, itemId, fields }) => {
  const [liveText, setLiveText] = useState('');
  const [showAd, setShowAd] = useState(false);
  useEffect(() => {
    // Show ad only on client side otherwise behaves abnormally due to react hydration
    setShowAd(true);
  }, []);
  const { manutds } = fields;
  const [
    {
      PlayerStatsGroupList,
      isPlayerPlayingLive,
      hideMatchStats,
      isMatchLive,
      matchKOTime,
      awayTeam,
      homeTeam,
      seasonstatsjson_t,
      hideseasonstats_b,
    },
  ] = usePlayerDetailValue();
  useEffect(() => {
    isMatchLive && isPlayerPlayingLive
      ? setLiveText(t('stats_LiveStats'))
      : setLiveText(t('stats_LastMatch'));
  }, [isMatchLive, isPlayerPlayingLive]);
  const gameNme = `${homeTeam && homeTeam.shortclubName} ${t('VS')} ${
    awayTeam && awayTeam.shortclubName
  }`;
  return (
    <React.Fragment>
      {!hideMatchStats && PlayerStatsGroupList && PlayerStatsGroupList.length > 0 && (
        <div className="player-detail__row-full live-data">
          <div className="career-wrap profile-stats">
            <div className="heading">
              {!isMatchLive && !isPlayerPlayingLive && (
                <span aria-hidden="true" className="matchdate" id="matchstats-date">
                  {formatDate(matchKOTime, 'playerprofile-date')}
                </span>
              )}

              <h2>{liveText}</h2>
              {!isMatchLive && !isPlayerPlayingLive && (
                <div className="screenreader" id="screenreader-date">
                  {formatDate(matchKOTime, 'playerprofile-date')}
                </div>
              )}
              <div className="teams">{gameNme}</div>
              <div className="divider" aria-hidden="true"></div>
            </div>
            <ProfileStatsListing statsType={'live'} statsData={PlayerStatsGroupList} />
          </div>
        </div>
      )}
      {!hideMatchStats && PlayerStatsGroupList && PlayerStatsGroupList.length > 0 && (
        <div
          className="grid-xl wide-add-panel ad-block dashboard"
          aria-hidden="true"
          data-an-track="true"
          tabIndex="-1"
        >
          {showAd && (
            <DFP
              slot="/11820346/Hercules_ROS_970x90//728x90//320x50"
              size={[
                [970, 90],
                [728, 90],
                [320, 50],
              ]}
              tag={manutds}
              itemId={itemId}
            />
          )}
        </div>
      )}
      {seasonstatsjson_t && seasonstatsjson_t.length > 0 && !hideseasonstats_b && (
        <div className="player-detail__row-full season-data">
          <div className="career-wrap profile-stats">
            <div className="heading">
              <h2>{t('Seasonstats')}</h2>
              <div className="stats-update">{t('SeasonStatsWillBeUpdatedAfterMatchesFinish')}</div>
              <div className="divider"></div>
            </div>
            <ProfileStatsListing statsType={'season'} statsData={seasonstatsjson_t} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default withTranslation()(PlayerStats);
