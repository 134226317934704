/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

const StepThree = ({ text, buttonText, thankYouText, setFormShow }) => {
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div className="step__three clearfix" style={{ display: 'block' }}>
      <div className="step__label">STEP 3</div>
      <div className="description">
        <RichText className="contentDescription" field={thankYouText} />
      </div>

      <div className="black-btn-skew">
        <input
          type="button"
          className="button submit_btn"
          value={buttonText && buttonText.value}
          onClick={() => refreshPage()}
        />
      </div>
      <p className="do-not-back">* Do Not Press Back Button</p>
      <div className="description">
        <RichText className="contentDescription" field={text} />
      </div>
    </div>
  );
};

export default React.memo(StepThree);
