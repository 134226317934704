/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  SpotlightConstantsLive,
  SpotlightConstantsResult,
  SpotlightConstantsFixture,
  MatchDayUrl,
  Match,
  Competition,
} from '../../../constants/globalConstants';
import MatchInfo from './MatchInfo';
import TeamsDetails from './TeamsDetails';
import TeamsName from './TeamsName';
import CompetitionLogo from './CompetitionLogo';
const NavSpotlight = ({ t, liveMatchData, matchData, isDateTbc }) => {
  const [liveMatch, setLiveMatch] = useState(false);
  useEffect(() => {
    liveMatchData && liveMatchData.length > 0 && setLiveMatch(true);
  }, []);
  let cardSequence = 1;
  /**
   * return boolan after Checking match data and length. max length should be 3
   */
  const checkIsavalibleData = () => {
    if (matchData && matchData.length > 0) {
      if (matchData.length > 3) {
        matchData.length = 3;
      }
      return true;
    } else {
      return false;
    }
  };
  /**
   * return cardtype for screenreader text
   */
  const getCardType = () => {
    let cardType;
    if (cardSequence === 1 && liveMatch) {
      cardType = SpotlightConstantsLive;
    } else if (cardSequence === 1) {
      cardType = SpotlightConstantsResult;
    } else if (cardSequence === 2 || cardSequence === 3) {
      cardType = SpotlightConstantsFixture;
    }
    return cardType;
  };

  /**
   *
   * @param {matchId for creating Url for matchcard} matchId
   */
  const getMatchCenterUrl = (matchId) => {
    return matchId && `${MatchDayUrl}${matchId}`;
  };
  /**
   *
   * @param {each index of match data} data
   * @param {loop index for set key} index
   */
  const renderCard = (data, index) => {
    return (
      <React.Fragment key={index}>
        {data && (
          <div className="ratio item grid-4">
            <div className="mu-content match-card">
              <a href={getMatchCenterUrl(data.opta_mid_t)}>
                <span className="screenreader">
                  {Match} {getCardType()}
                </span>
                <div className="mu-item match-item">
                  {data.celumimages_s && (
                    <div className="match-item__league">
                      <CompetitionLogo
                        celumimages={data.celumimages_s}
                        teamType={Competition}
                      ></CompetitionLogo>
                    </div>
                  )}
                  <div className="tbc-text">{data.ismatchdatetbc_b && data.matchdatetbc_t}</div>
                  <MatchInfo
                    matchDate={data.matchdate_tdt}
                    cardSequence={cardSequence}
                    venuename={data.venuename_t}
                  ></MatchInfo>
                  <TeamsDetails
                    celumimages={data.celumimages_s}
                    liveMatch={liveMatch}
                    matchDate={data.matchdate_tdt}
                    cardSequence={cardSequence}
                    venuename={data.venuename_t}
                    resultdata={data.resultdata_t}
                  ></TeamsDetails>
                  <TeamsName
                    liveMatch={liveMatch}
                    cardSequence={cardSequence}
                    data={data}
                  ></TeamsName>
                </div>
              </a>
            </div>
          </div>
        )}
        {increaseCardSequence()}
      </React.Fragment>
    );
  };
  /**
   * Increasing cardSequence value on basis of it we know card is result or fixture
   */
  const increaseCardSequence = () => {
    cardSequence++;
  };

  /**
   * Check matchdatetbc_t to set class 'tbc-card' on parent using isDateTbc callback function
   */
  const checkIsDateTbc = () => {
    if (matchData && matchData.length > 0 && checkIsavalibleData()) {
      for (let i = 0; i < matchData.length; i++) {
        if (matchData[i].ismatchdatetbc_b) {
          isDateTbc(true);
          break;
        }
      }
    }
  };

  return (
    <React.Fragment>
      {checkIsDateTbc()}
      {matchData &&
        matchData.length > 0 &&
        checkIsavalibleData() &&
        matchData.map((obj, key) => {
          return renderCard(obj, key);
        })}
    </React.Fragment>
  );
};

export default withTranslation()(React.memo(NavSpotlight));
