/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import { localeConfig } from '../../utils/cardDateFormat';
import ImgCrop from '../Common/ImgCrop';
import * as track from '../../utils/analytics';
import { SEARCH } from '../../constants/containerConstants';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import {
  createDestinationUrl,
  createTagList,
  validateCbr,
  getLoggedInStatus,
} from '../../utils/utils';
import { useSponsorValue } from '../../context/sponsorContext';
import AccessBadge from '../Common/AccessBadge';
const PodcastSearch = ({ results, resultsCount, t, language }) => {
  const [{ isUserLogin, isLoginChecked, isUserSubscribe, isUserSkyId }] = useSponsorValue();

  const getAnalyticsData = (result, contentStatus) => {
    const initialData = track.data('card');
    return {
      ...initialData,
      card_name: result.headline_t,
      container_type: SEARCH,
      item_id: result.itemid_s,
      content_type: result.contenttype_t,
      content_status: contentStatus,
      subscription_status: getLoggedInStatus({
        isLoginChecked,
        isUserLogin,
        isUserSubscribe,
        isUserSkyId,
      }),
      tag: result.taglist_sm && createTagList(result.taglist_sm, result.category_s),
      created_datetime: result.appearance_tdt,
      author: result.authorname_s,
      destination_url: result.destinationurl_s,
    };
  };

  const trackCard = (e, result, contentStatus) => {
    track.analytics(getAnalyticsData(result, contentStatus), e);
  };

  const onCardClick = (e, result) => {
    const showCbr = validateCbr({
      isUserLogin,
      isUserSubscribe,
      contentAccess: result.contentaccess_t,
      contentType: result.contenttype_t,
      manutds: result.taglist_sm,
      isAws: true,
    });
    trackCard(e, result, showCbr && showCbr.accessCheck);
  };

  const renderHtml = (result, index) => {
    result.appearance_tdt = result.__published_tdt;
    return (
      <React.Fragment>
        {result.contenttype_t && result.contenttype_t.toLowerCase() === 'podcast' && (
          <div>
            <div className="search__items">
              <div className="search__items__detail video podcast">
                <div className="details">
                  <div className="detail__left">
                    <div className="img-holder">
                      <ImgCrop
                        imgCrop={
                          result.celumimagesvariant_s &&
                          result.celumimagesvariant_s.ImageCropUrl.CropUrl
                        }
                        alt={result.AltText}
                        title={result.AltText}
                      />
                    </div>
                    <span>
                      <i className="icon-mic" aria-hidden="true"></i>
                    </span>
                  </div>
                  <div className="detail__right">
                    <a
                      className="search-card-click"
                      href={createDestinationUrl(result.destinationurl_s)}
                      onClick={(e) => onCardClick(e, result)}
                    >
                      <AccessBadge
                        contentAcessLocal={result.contentaccess_t}
                        contentTypeLocal={result.contenttype_t}
                        tagListLocal={result.taglist_sm}
                        isSearchPage={true}
                      />
                      <h2>
                        <span className="title">
                          <span>
                            {result.headline_t}
                            <span className="screenreader">{t('Podcast')}</span>
                          </span>
                        </span>
                      </h2>
                      <h2 className="mobile-view">
                        <span>{result.headline_t}</span>
                      </h2>
                      <p className="teasure">
                        {result.appearance_tdt && (
                          <RichText
                            tag="strong"
                            field={{ value: localeConfig(result.appearance_tdt, language, t) }}
                            editable={false}
                          />
                        )}
                        {result.appearance_tdt && <span>{' - '}</span>}
                        {result.episodedescription_t}
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {resultsCount > 0 && (
        <div className="grid-12">
          {results &&
            results.map((result, index) => <div key={index}>{renderHtml(result, index)}</div>)}
        </div>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(PodcastSearch);
