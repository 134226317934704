/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { LiveEventProvider, useLiveEventValue } from '../../context/liveEventContext';
import { fetchLiveEvent, viewPortAction, updateLiveListing } from '../../actions/liveEventAction';
import { getParameterByName, elmIsInViewport } from '../../utils/utils';
import HeroBanner from './HeroBanner';
import BlogHighlights from './BlogHighlights';
import Listing from '../Common/Listing';
import LiveNotification from '../Common/LiveNotification';
import SocialShareStrip from './SocialShareStrip';
import Loader from '../Common/Loader';
import { THRESHOLD } from '../../constants/globalConstants';
import { LIVE_EVENT } from '../../constants/containerConstants';
import ErrorBoundary from '../Common/ErrorBoundary';
const LiveEvent = (props) => (
  <React.Fragment>
    <LiveEventProvider>
      <LiveEventContainer {...props}></LiveEventContainer>
    </LiveEventProvider>
  </React.Fragment>
);

const LiveEventContainer = ({ t, props }) => {
  const [
    {
      HighlightResponse,
      LiveEventResponse,
      HeroItem,
      timestamp,
      rowsCount,
      displayLoadMore,
      reTry,
      lastCardTime,
      cronTimeInterval,
      status,
      pageLoad,
      notificationCount,
      loader,
    },
    dispatch,
  ] = useLiveEventValue();
  let timeoutId;
  const [eventId, setEventId] = useState(null);
  const [interValId, setIntervalId] = useState(null);
  const domElm = useRef(null);
  const listingContainer = useRef(null);
  useEffect(() => {
    setEventId(getEventId());
    eventId &&
      fetchLiveEvent(dispatch, eventId, timestamp, rowsCount, 'default') &&
      fetchLiveEventData();
    getViewPort();
    document.addEventListener('scroll', onscrollListner, {
      passive: true,
    });
    return () => {
      clearInterval(interValId);
      window.removeEventListener('scroll', onscrollListner);
    };
  }, [eventId]);
  const onscrollListner = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      getViewPort();
    }, THRESHOLD);
  };
  const getEventId = () => {
    let eventId = window.location.href;
    eventId = eventId.substr(eventId.lastIndexOf('/') + 1);
    if (eventId.indexOf('?eventtag=') != -1) {
      eventId = getParameterByName('eventtag');
      if (eventId && eventId.indexOf('#') != -1) {
        eventId = eventId.slice(0, eventId.indexOf('#'));
      }
    } else {
      eventId = eventId.split('-').join('');
    }
    return eventId;
  };
  const fetchLiveEventData = () => {
    setIntervalId(
      setInterval(() => {
        status !== 'fetching' && fetchLiveEvent(dispatch, eventId, timestamp, rowsCount, 'cronJob');
      }, cronTimeInterval)
    );
  };
  const getViewPort = () => {
    if (domElm.current) {
      let isInView = elmIsInViewport(domElm);
      viewPortAction(dispatch, isInView);
    }
  };

  const loadMoreRecords = () => {
    let stamp = 'all';
    if (lastCardTime) stamp = `b_${lastCardTime}`;
    fetchLiveEvent(dispatch, eventId, stamp, rowsCount, 'loadmore');
  };

  const updateLiveFeed = () => {
    updateLiveListing(dispatch);
    scrollUp();
  };

  const scrollUp = () => {
    listingContainer &&
      listingContainer.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <div className="nonmatchday-page">
      <div data-container="live-events">
        {HeroItem && <HeroBanner heroData={HeroItem}></HeroBanner>}
        {HeroItem && (
          <div className="non-matchday-panel__content" ref={domElm}>
            <div className="home-article-detail__content-panel no-margin">
              <SocialShareStrip item={HeroItem} />
              <div className="home-content-panel__details">
                <div className="home-content-panel__details-text">
                  <p className="heading">{HeroItem.eventdescription_t}</p>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className="non-matchday__bg__container"
          id="non-matchday-container"
          ref={listingContainer}
        >
          <div className="non-matchday">
            {notificationCount > 0 && (
              <LiveNotification
                count={notificationCount}
                updateLiveFeed={updateLiveFeed}
              ></LiveNotification>
            )}
            {HighlightResponse && (
              <ErrorBoundary>
                <BlogHighlights blogHighlights={HighlightResponse}></BlogHighlights>
              </ErrorBoundary>
            )}
            {LiveEventResponse && (
              <React.Fragment>
                <ErrorBoundary>
                  <Listing
                    listing={LiveEventResponse}
                    gridClassName={'grid-12'}
                    isMatchcenterCard={false}
                    isNonMatchDayCard={true}
                    classItem="non-matchday__inner"
                    container={LIVE_EVENT}
                  ></Listing>
                </ErrorBoundary>
                <div className="nonmatchday-loader">
                  {displayLoadMore && !reTry && !pageLoad && (
                    <button
                      type="button"
                      id="loadMore"
                      className="white-btn-skew-v2"
                      onClick={() => loadMoreRecords()}
                    >
                      {t('loadmore')}
                    </button>
                  )}
                  {status === 'fetching' && pageLoad && (
                    <span className="three-dot-loader">
                      {t('loading')}
                      <span className="one">.</span>
                      <span className="two">.</span>
                      <span className="three">.</span>
                    </span>
                  )}
                </div>
                {reTry && (
                  <div>
                    <div className="error-whoops__container">
                      <div className="grid-12 error-whoops__inner loadmore">
                        <h1>{t('Whoops')}</h1>
                        <p>{t('thereissomethingworngwithinternet')}</p>
                        <div className="red-btn-skew">
                          <a
                            role="button"
                            onClick={(e) => {
                              e.preventDefault();
                              loadMoreRecords();
                            }}
                          >
                            <span>{t('Retry')}</span>
                            <i className="icon-follow-live"></i>
                          </a>
                        </div>
                        <div className="white-btn-skew">
                          <a
                            href="#"
                            role="button"
                            onClick={(e) => {
                              e.preventDefault();
                              scrollUp();
                            }}
                          >
                            <span>{t('Latest')}</span>
                            <i className="tag-red-arrow"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
      {loader && <Loader></Loader>}
    </div>
  );
};

export default withTranslation()(LiveEvent);
