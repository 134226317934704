/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { calculatePercentage } from '../../utils/playerProfileStats';
const Shooting = ({ t, item, statsType }) => {
  const buildShootingData = (item) => {
    let graphData = {};
    if (item && item.stats && item.stats.length > 0) {
      let statsItems = item.stats;
      let shotsOff = 0,
        shotsBlocked = 0;
      for (var outer = 0; outer < statsItems.length; outer++) {
        var outerObj = statsItems[outer];
        if (outerObj && outerObj.statsKey == 'stats_TotalShots') {
          graphData.totalShotsKey = outerObj.statsKey;
          graphData.totalShotsStatsValue = outerObj.statsValue;
          graphData.totalShotsIsPercentage = outerObj.isPercentage;
        } else if (outerObj && outerObj.statsKey == 'stats_ShotsOnTargetincgoals') {
          graphData.shotsOnTargetKey = outerObj.statsKey;
          graphData.shotsOnTarget = outerObj.statsValue;
          graphData.shotsOnTargetStatsValue = calculatePercentage(
            graphData.shotsOnTarget,
            graphData.totalShotsStatsValue,
            true
          );
          graphData.shotsOnTargetIsPercentage = outerObj.isPercentage;
        } else if (outerObj && outerObj.statsKey == 'stats_ShotsOffTargetincwoodwork') {
          graphData.shotsOffTargetKey = outerObj.statsKey;
          graphData.shotsOffTarget = outerObj.statsValue;
          shotsOff =
            parseInt(graphData.shotsOnTarget ? graphData.shotsOnTarget : 0) +
            parseInt(outerObj.statsValue);
          graphData.shotsOffTargetStatsValue = calculatePercentage(
            shotsOff,
            graphData.totalShotsStatsValue,
            true
          );
          graphData.shotsOffTargetIsPercentage = outerObj.isPercentage;
        } else if (outerObj && outerObj.statsKey == 'stats_BlockedShots') {
          graphData.blockedShotsKey = outerObj.statsKey;
          graphData.blockedShots = outerObj.statsValue;
          shotsBlocked = parseInt(shotsOff ? shotsOff : 0) + parseInt(outerObj.statsValue);
          graphData.blockedShotsStatsValue = calculatePercentage(
            shotsBlocked,
            graphData.totalShotsStatsValue,
            true
          );
          graphData.blockedShotsIsPercentage = outerObj.isPercentage;
        } else if (outerObj && outerObj.statsKey == 'stats_ShootingAccuracy') {
          graphData.shootingAccuracyKey = outerObj.statsKey;
          graphData.shootingAccuracyStatsValue = Math.round(outerObj.statsValue);
          graphData.shootingAccuracyIsPercentage = outerObj.isPercentage;
        }
      }
    }
    return graphData;
  };
  const [graphData, setGraphData] = useState(null);
  useEffect(() => {
    setGraphData(buildShootingData(item));
  }, [item]);
  return (
    graphData && (
      <div className="chart-wrapper no-width">
        <h3>{t('stats_Shooting')}</h3>
        <div className="stats-shooting">
          <div className="stats-wrapper">
            <div className="stats-chart">
              <ul className="chart-pie shots labels" aria-hidden="true">
                <li data-value={graphData.shotsOnTargetStatsValue}>
                  <span data-label={graphData.shotsOnTargetStatsValue}>
                    {graphData.shotsOnTarget > 0 && <span>{graphData.shotsOnTarget}</span>}
                  </span>
                </li>
                <li data-value={graphData.shotsOffTargetStatsValue}>
                  <span data-label={graphData.shotsOffTargetStatsValue}>
                    {graphData.shotsOffTarget > 0 && <span>{graphData.shotsOffTarget}</span>}
                  </span>
                </li>
                <li data-value={graphData.blockedShotsStatsValue}>
                  <span data-label={graphData.blockedShotsStatsValue}>
                    {graphData.blockedShots > 0 && <span>{graphData.blockedShots}</span>}
                  </span>
                </li>
              </ul>
              <ul className="chart-pie arc">
                <li data-value={graphData.shotsOnTargetStatsValue}></li>
                <li data-value={graphData.shotsOnTargetStatsValue}></li>
                <li data-value={graphData.shotsOffTargetStatsValue}></li>
                <li data-value={graphData.blockedShotsStatsValue}></li>
              </ul>
              <ul
                className="chart-pie shots total"
                data-text={graphData.totalShotsStatsValue}
                aria-hidden="true"
              >
                {graphData.totalShotsStatsValue > 0 && <li data-value="100"></li>}
              </ul>
              <div className="score">
                <span aria-hidden="true">
                  {graphData.shootingAccuracyStatsValue}
                  {graphData.shootingAccuracyIsPercentage && <span>%</span>}
                </span>
                <span>{t('stats_ShootingAccuracy')}</span>
                <span className="screenreader">
                  {graphData.shootingAccuracyStatsValue}
                  {graphData.shootingAccuracyIsPercentage && <span>%</span>}
                </span>
              </div>
            </div>
          </div>
          <ul className="pass-notations">
            <li>
              <span className="pass-point"></span>
              <span>{t('stats_TotalShots')}</span>
              <span className="screenreader">{graphData.totalShotsStatsValue}</span>
            </li>
            <li>
              <span className="pass-point"></span>
              <span>{t('stats_ShotsOnTargetincgoals')}</span>
              <span className="screenreader">{graphData.shotsOnTarget}</span>
            </li>
            <li>
              <span className="pass-point"></span>
              <span>{t('stats_ShotsOffTargetincwoodwork')}</span>
              <span className="screenreader">{graphData.shotsOffTarget}</span>
            </li>
            {statsType === 'live' && (
              <li>
                <span className="pass-point"></span>
                <span>{t('stats_BlockedShots')}</span>
                <span className="screenreader">{graphData.blockedShots}</span>
              </li>
            )}
          </ul>
        </div>
      </div>
    )
  );
};
export default withTranslation()(Shooting);
