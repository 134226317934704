/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import ImgCrop from '../../Common/ImgCrop';
import * as track from '../../../utils/analytics';

const SignpostCard = ({
  BackgroundImage,
  CTATitle,
  CTAUrl,
  Headline,
  SubText,
  CardVariant,
  itemId,
}) => {
  const isChild =
    CardVariant && CardVariant.value && CardVariant.value.toLowerCase() === 'child' ? 'child' : '';

  const trackCard = (e) => {
    const initialData = track.data('card');
    track.analytics(
      {
        ...initialData,
        card_name: Headline && Headline.value,
        item_id: itemId,
        content_type: 'signpostblock',
        link_name: CTATitle && CTATitle.value,
        share_url: CTAUrl && CTAUrl.value && CTAUrl.value.hrefMuCustom,
        track_type: 'link',
        track_text: CTATitle && CTATitle.value,
      },
      e
    );
  };

  return (
    <a
      href={CTAUrl && CTAUrl.value && CTAUrl.value.hrefMuCustom}
      target={CTAUrl && CTAUrl.value && CTAUrl.value.target}
      tabIndex="0"
      onClick={(e) => trackCard(e)}
    >
      <div className="grid-6">
        <section
          className={`tpl-sign-post skin-white ${isChild}`}
          data-classNameobj="{'grid':'componenthero','cropType':'components'}"
        >
          <div className={`sign-post-tpl-common ${isChild ? 'sign-post__textonimg' : ''}`}>
            <div className="sign-post-image img-holder">
              {isChild && Headline && Headline.value && (
                <h2>
                  <Text field={Headline} />
                </h2>
              )}
              {BackgroundImage && BackgroundImage.value && BackgroundImage.value.Crops && (
                <LazyLoad once={true} offset={100}>
                  <ImgCrop
                    imgCrop={BackgroundImage.value.Crops}
                    alt={BackgroundImage.value.AltText}
                    title={BackgroundImage.value.AltText}
                    isWebpAvailable={BackgroundImage.value.isWebpAvailable}
                    singleSrc={false}
                    adaptiveClass="adaptive"
                  ></ImgCrop>
                </LazyLoad>
              )}

              <div className="clear-all-section"></div>
            </div>
            <div className="sign-post-desc">
              {!isChild && Headline && Headline.value && (
                <h2>
                  <Text field={Headline} />
                </h2>
              )}
              {SubText && SubText.value && (
                <div className="sign-post-summary">
                  <Text field={SubText} />
                </div>
              )}
              {CTATitle && CTATitle.value && (
                <div className="link-btn-white">
                  <span>
                    <Text field={CTATitle} />
                  </span>
                  <i className="icon-cta-arrow"></i>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </a>
  );
};

export default withTranslation()(SignpostCard);
