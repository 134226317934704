/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { fetchSearch } from '../../actions/searchAction';
import { SearchProvider, useSearchValue } from '../../context/searchContext';
import { validateObj } from '../../utils/utils';
import Loader from '../Common/Loader';
import SearchBox from './SearchBox';
import SearchFilter from './SearchFilter';
import SearchTemplate from './SearchTemplate';
import SearchPagination from './SearchPagination';
import SidebarItem from './SidebarItem';
import MU from '../../constants/muConstants';
import {
  getUrl,
  updateNtag,
  replaceAllValue,
  getSearchParameterByName,
  GetPager,
  range,
  Filters,
  itemsPerPage,
} from '../../utils/searchUtils';
import Sponsor from '../Common/Sponsor';
import { DARK_THEME, MODULE } from '../../constants/globalConstants';
import { SEARCH } from '../../constants/containerConstants';
import ErrorBoundary from '../Common/ErrorBoundary';

const Search = (props) => (
  <SearchProvider>
    <SearchResult {...props} />
  </SearchProvider>
);

const SearchResult = (props) => {
  const [
    {
      status,
      responseTime,
      filterKeyData,
      ImageSet,
      RelatedSearch,
      relatedKeyword,
      resultsCount,
      pagerFromReducer,
      results,
      noResulData,
      startListIndex,
      playerProfileList,
    },
    dispatch,
  ] = useSearchValue();
  const language = MU.language;
  const [searchText, setSearchText] = useState('');
  const [pager, setPager] = useState(pagerFromReducer);
  const setLastSegment = () => {
    if (typeof window !== 'undefined') {
      const hrefValue = window.location.href;
      const value = hrefValue.substr(hrefValue.lastIndexOf('/') + 1).toLowerCase();
      if (value === 'search') {
        return value;
      } else {
        let str = hrefValue.substr(hrefValue.lastIndexOf('&') + 1);
        str = str && str.substr(str.indexOf('=') + 1);
        return str.toLowerCase();
      }
    }
  };
  const [lastSegment] = useState(setLastSegment());
  const [selectedTab, setSelectedTab] = useState(lastSegment);
  const [selectedValue, setSelectedValue] = useState('all');
  const [showSponsor, setShowSponsor] = useState(true);
  const sponsorRef = useRef(null);
  useEffect(() => {
    setPager(pagerFromReducer);
  }, [pagerFromReducer]);
  useEffect(() => {
    if (sponsorRef) {
      setTimeout(() => {
        const sponsorList = sponsorRef.current && sponsorRef.current.querySelector('a');
        if (sponsorList) {
          setShowSponsor(true);
        } else {
          setShowSponsor(false);
        }
      }, 1000);
    }
  }, [sponsorRef]);
  useEffect(() => {
    const element = document.getElementsByTagName('BODY');
    if (element) {
      element[0].classList.add('search_page');
    }
    const pathName =
      typeof window !== 'undefined' && window.location && window.location.href.toLowerCase();
    if (
      lastSegment !== 'search' ||
      (lastSegment === 'search' && pathName && pathName.includes('search?q='))
    ) {
      initialization();
    }
  }, []);

  const initialization = () => {
    try {
      let searchFilter = MU.searchFilter;
      const pathName =
        typeof window !== 'undefined' && window.location && window.location.href.toLowerCase();
      const tbmIncluded = pathName && pathName.includes('&tbm=');
      try {
        searchFilter = replaceAllValue(searchFilter, '&quot;', '"');
        searchFilter = JSON.parse(searchFilter)[lastSegment];
        if (validateObj(searchFilter)) {
          if (searchFilter) {
            if (searchFilter.indexOf('/') !== -1) {
              searchFilter = replaceAllValue(searchFilter, '/', '%2F');
            }
            if (searchFilter.indexOf(',') !== -1) {
              searchFilter = replaceAllValue(searchFilter, ',', '_');
            }
          }
        } else {
          searchFilter = 'all';
        }
        if (tbmIncluded) {
          searchFilter = lastSegment === 'all' ? 'all' : searchFilter;
        } else {
          searchFilter = 'all';
        }
        setSelectedValue(searchFilter);
      } catch (e) {
        console.log('filter not found', e);
      }

      const searchUrlQuery = getSearchParameterByName('q');
      let searchQuery = '';
      let searchKey = null;
      let globalSearchCont = null;
      if (searchUrlQuery) {
        if ((searchUrlQuery && searchUrlQuery.indexOf('/') !== -1) || searchUrlQuery.length) {
          searchQuery = searchUrlQuery.split('/');
          searchQuery = searchQuery[searchQuery.length - 1];
        }
        if (searchQuery && searchQuery.length > 0) {
          searchKey = searchQuery;
          setSearchText(searchKey);
          globalSearchCont = searchKey;
        }
      }
      const filterIncluded = Filters.includes(lastSegment.toLowerCase());
      if (!filterIncluded || (filterIncluded && !tbmIncluded)) {
        updateQryParameter(`&tbm=all`, searchKey);
        setSelectedTab('all');
      }
      callAction(searchFilter, startListIndex, globalSearchCont, searchKey);
    } catch (e) {
      console.log('error in initialization');
    }
  };

  const callAction = (filterType, startItem, globalSearchCont, searchValue, type) => {
    if (globalSearchCont && globalSearchCont.indexOf('&') !== -1) {
      globalSearchCont = replaceAllValue(globalSearchCont, '&', '%26');
    }
    fetchSearch(dispatch, filterType, startItem, itemsPerPage, globalSearchCont, searchValue).then(
      () => {
        setTimeout(() => {
          if (type && type === 'pagination' && typeof window !== 'undefined') {
            window.scroll({
              top: 0,
              behavior: 'smooth',
            });
          }
        }, 300);
      }
    );
  };

  const getStrWithNumeric = (resultsCount, responseTime, str) => {
    resultsCount = `<strong>${resultsCount}</strong>`;
    let arrString = [resultsCount, responseTime];
    return updateNtag(arrString, 0, str);
  };

  const updateUrl = (key) => {
    try {
      let selectedTabs = selectedTab && selectedTab.toLowerCase();
      let selectedTbm = '&tbm=all';
      if (getSearchParameterByName('tbm')) {
        selectedTabs = decodeURIComponent(
          typeof window !== 'undefined' && window.location.href.split('=')[2]
        );
        setSelectedTab(selectedTabs);
      }

      if (selectedTabs == 'all') {
        selectedTbm = '&tbm=all';
      } else if (selectedTabs == 'news') {
        selectedTbm = '&tbm=news';
      } else if (selectedTabs == 'image' || selectedTabs == 'images') {
        selectedTbm = '&tbm=images';
      } else if (selectedTabs === 'video' || selectedTabs === 'videos') {
        selectedTbm = '&tbm=videos';
      } else if (selectedTabs) {
        selectedTbm = `&tbm=${selectedTabs}`;
      }
      if (searchText === '') {
        selectedTbm = '&tbm=all';
        setSelectedTab('all');
      }
      updateQryParameter(selectedTbm, key);
    } catch (e) {
      console.log('error in updating url');
    }
  };

  const updateTemplate = (tab, value) => {
    try {
      tab = tab.split('/').pop();
      if (tab.toLowerCase() === 'all') {
        value = 'all';
      } else {
        if (validateObj(value)) {
          if (value) {
            if (value.indexOf('/') !== -1) {
              value = replaceAllValue(value, '/', '%2F');
            }
            if (value.indexOf(',') !== -1) {
              value = replaceAllValue(value, ',', '_');
            }
          }
        } else {
          value = 'all';
        }
      }
      setSelectedTab(tab.toLowerCase());
      let globalSearchCont;
      globalSearchCont = replaceAllValue(searchText, '#', '');
      if (searchText && searchText.indexOf('&') !== -1) {
        globalSearchCont = replaceAllValue(searchText, '&', '%26');
      }
      updateQryParameter(`&tbm=${tab.toLowerCase()}`, searchText);
      setSelectedValue(value);
      callAction(value, 0, globalSearchCont, searchText);
    } catch (e) {
      console.log('error in updating template');
    }
  };

  const updateQryParameter = (selectedTbm, key) => {
    try {
      let globalSearchCont;
      if (typeof window !== 'undefined' && window.location.href.indexOf('?q') > 0) {
        globalSearchCont = replaceAllValue(key, '#', '');
        if (key && key.indexOf('&') !== -1) {
          globalSearchCont = replaceAllValue(key, '&', '%26');
        }
        let newurl = getUrl() + '?q=' + globalSearchCont + selectedTbm;
        if (typeof window !== 'undefined' && window.history.pushState) {
          window.history.pushState({ path: newurl }, '', newurl);
        }
      } else {
        globalSearchCont = key;
        if (key && key.indexOf('&') !== -1) {
          globalSearchCont = replaceAllValue(key, '&', '%26');
        }
        let newurl = getUrl() + '?q=' + globalSearchCont + selectedTbm;
        typeof window !== 'undefined' && window.history.pushState({ path: newurl }, '', newurl);
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  const setPage = (page) => {
    if (page < 1 || page > pager.totalPages) {
      return;
    }
    let totalItemsArr = range(1, resultsCount);
    let newPager = GetPager(totalItemsArr.length, page, itemsPerPage);
    setPager(newPager);
    let newStartItem = newPager.currentPage * 15 - 1 - 14;
    callAction(selectedValue, newStartItem, searchText, searchText, 'pagination');
  };

  const getStrWithPageNum = (resultsCount, responseTime, pageCount, str) => {
    resultsCount = `<strong>${resultsCount}</strong>`;
    let arrString = [pageCount, resultsCount, responseTime];
    return updateNtag(arrString, 0, str);
  };

  const onSearch = (key) => {
    setSearchText(key);
    updateUrl(key);
    callAction(selectedValue, 0, key, key);
  };

  return (
    <React.Fragment>
      <div className="container main">
        <div id="searchResults">
          <div className="grid__container search-accessibility-pannel">
            <div
              className="search-panel full-width"
              style={status && status === 'fetching' ? { zIndex: 9999 } : null}
            >
              <div className="container main">
                <div className="grid-9 no-margin-bottom">
                  <div className="search-box__panel grid-12">
                    <SearchBox searchText={searchText} onSearch={onSearch} />
                  </div>
                  <SearchFilter
                    filterKeyData={filterKeyData}
                    updateTemplate={updateTemplate}
                    lastSegment={selectedTab}
                  />
                </div>
                <ul className="search-sponsor grid-3 show-web">
                  <li>
                    <Sponsor
                      type={MODULE}
                      moduleName={'searchpage'}
                      theme={DARK_THEME}
                      container={SEARCH}
                      cardName={'search'}
                      contentType={'search'}
                    />
                  </li>
                </ul>
              </div>
            </div>

            <div className="search-panel">
              <ErrorBoundary>
                {showSponsor && (
                  <ul className="search-sponsor grid-12 show-mobile" ref={sponsorRef}>
                    <li>
                      <Sponsor
                        type={MODULE}
                        moduleName={'searchpage'}
                        theme={DARK_THEME}
                        container={SEARCH}
                        cardName={'search'}
                        contentType={'search'}
                      />
                    </li>
                  </ul>
                )}
                <div>
                  {resultsCount > 0 && responseTime && pager && pager.currentPage < 2 && (
                    <div
                      className="search__result-time grid-12"
                      dangerouslySetInnerHTML={{
                        __html: getStrWithNumeric(
                          resultsCount,
                          responseTime,
                          props.t('DisplayingNresultsNseconds')
                        ),
                      }}
                    />
                  )}
                  {resultsCount == 0 && (
                    <div className="search__result-time grid-12">{props.t('Noresultsfound')}</div>
                  )}
                  {pager && pager.currentPage > 1 && (
                    <div
                      className="search__result-time grid-12"
                      dangerouslySetInnerHTML={{
                        __html: getStrWithPageNum(
                          resultsCount,
                          responseTime,
                          pager.currentPage,
                          props.t('pageNofaboutNresultsinN')
                        ),
                      }}
                    />
                  )}
                </div>
                <section className="search-panel__result">
                  <div aria-label="Result page">
                    <div className="searchResults">
                      <SearchTemplate
                        resultsCount={resultsCount}
                        results={results}
                        template={setLastSegment()}
                        ImageSet={ImageSet}
                        searchText={searchText}
                        noResulData={noResulData}
                        language={language}
                        pager={pager}
                        onSearch={onSearch}
                        playerProfileList={playerProfileList}
                      />
                    </div>
                  </div>
                </section>
                <SearchPagination pager={pager} setPage={setPage} />
              </ErrorBoundary>
            </div>
            <SidebarItem
              relatedKeyword={relatedKeyword}
              RelatedSearch={RelatedSearch}
              onSearch={onSearch}
            />
          </div>
          {status === 'fetching' && <Loader className="loader-wrapper" areaHidden="true" />}
        </div>
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(Search);
