/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { importComponent } from './transformConstants';
/*
    -- Traform component will receive the data from AWS and solar
       and will return the component with transformed data in props
*/
const Transform = (props) => {
  const data = props.data;
  let source = props.source && props.source.toLowerCase();
  let componentName =
    props.componentName ||
    (data && data.contenttype_t) ||
    (data && data.fields && data.fields.ContentType && data.fields.ContentType.value);
  if (source) {
    if (source !== 'aws' && source !== 'solr' && source !== 'curate') {
      source = false;
    }
  }
  if (componentName && source) {
    let component = importComponent(componentName);
    if (!component.component) {
      return null;
    }
    if (source === 'curate') {
      return <component.component {...data} {...props} />;
    } else {
      return (
        <component.component
          {...component.data(data, source === 'aws' ? true : false)}
          {...props}
        />
      );
    }
  } else {
    return null;
  }
};
export default Transform;
