/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { getMatchDate } from '../../../utils/momentDate';
import { MODULE, CONTENT_TYPE } from '../../../constants/globalConstants';
import { isEmpty, find } from 'lodash';
import { buildImgUrl } from '../../../utils/utils';
import { getSponsorDataFromFields } from '../../../utils/articleUtils';

const AMPSocialHolder = ({ fields, sponsorClass, type, sponsorSpanClass, context }) => {
  const { __Published, AuthorName, __PublishedBy, Sponsorby } = fields;
  const dateString =
    __Published && __Published.value
      ? getMatchDate(__Published.value, 'dddd DD MMMM YYYY HH:mm')
      : '';
  const arrDate = dateString && dateString.length > 0 ? dateString.split(' ') : [];

  const getGlobalSponsor = (type, subtype, context) => {
    let sponsorElem = {};
    let sponsorObj = {};
    try {
      if (context) {
        if (type === MODULE) {
          if (context.SponsorModuleType) {
            const sponsorNode = find(context.SponsorModuleType, { groupValue: subtype });
            sponsorObj =
              sponsorNode &&
              sponsorNode.doclist &&
              sponsorNode.doclist.docs &&
              sponsorNode.doclist.docs[0] &&
              sponsorNode.doclist.docs[0].sponsordetailinfo_s &&
              sponsorNode.doclist.docs[0].sponsordetailinfo_s[0];
          }
        } else if (type === CONTENT_TYPE) {
          if (context.SponsorContentType) {
            const sponsorNode = find(context.SponsorContentType, { mappedcontenttype_t: subtype });
            sponsorObj =
              sponsorNode && sponsorNode.sponsordetailinfo_s && sponsorNode.sponsordetailinfo_s[0];
          }
        }
        sponsorElem.MCC_MNC = sponsorObj && sponsorObj.mcC_MNC;
        sponsorElem.PartnerIconLight = sponsorObj && sponsorObj.partnerIconLight;
        sponsorElem.PartnerIconDark = sponsorObj && sponsorObj.partnerIconDark;
        sponsorElem.PartnerText = sponsorObj && sponsorObj.partnerText;
        sponsorElem.CTAURL = sponsorObj && sponsorObj.ctaurl;
        sponsorElem.PartnerName = sponsorObj && sponsorObj.partnerName;
      }
    } catch (e) {
      sponsorElem = {};
    }
    return sponsorElem;
  };

  let sponsor = {};
  let imgUrl = '';
  if (type === 'articlefooter') {
    sponsor = getGlobalSponsor(MODULE, type, context);
    imgUrl = buildImgUrl(sponsor && sponsor.PartnerIconDark);
  }
  if (type === 'article') {
    if (Sponsorby && !isEmpty(Sponsorby)) {
      if (
        Sponsorby &&
        Sponsorby.fields &&
        Sponsorby.fields.IsActive &&
        Sponsorby.fields.IsActive.value
      ) {
        sponsor = getSponsorDataFromFields(Sponsorby && Sponsorby.fields);
        imgUrl = sponsor && sponsor.PartnerIconDark;
      } else {
        sponsor = {};
      }
    } else {
      sponsor = getGlobalSponsor(CONTENT_TYPE, type, context);
      imgUrl = buildImgUrl(sponsor && sponsor.PartnerIconDark);
    }
  }

  return (
    <React.Fragment>
      <div className="social__holder">
        <span className="social__author">
          <span>{`By ${
            (AuthorName &&
              AuthorName.fields &&
              AuthorName.fields.AuthorName &&
              AuthorName.fields.AuthorName.value) ||
            (__PublishedBy && __PublishedBy.value)
          }`}</span>
        </span>
        <span className="social__time">
          <span>{arrDate && arrDate.length >= 4 ? ` ${arrDate.slice(1, 4).join(' ')}` : ''}</span>
          <span>{arrDate && arrDate.length >= 5 ? ` ${arrDate[4]}` : ''}</span>
        </span>
      </div>
      <div className="social__holder social-icons">
        <amp-social-share
          class="rounded"
          data-param-text=""
          type="facebook"
          aria-label="Share with facebook"
          data-param-app_id="351989911881898"
          width="44"
          height="44"
        ></amp-social-share>
        <amp-social-share
          class="rounded"
          data-param-text=""
          type="twitter"
          aria-label="Share with twitter"
          width="44"
          height="44"
        ></amp-social-share>
        <amp-social-share
          class="rounded"
          type="whatsapp"
          aria-label="Share with whatsapp"
          width="44"
          height="44"
        ></amp-social-share>
        <amp-social-share
          class="rounded"
          data-param-text=""
          type="email"
          aria-label="Share with email"
          width="44"
          height="44"
        ></amp-social-share>
        <amp-social-share
          class="rounded"
          data-param-text=""
          type="gplus"
          aria-label="Share with gplus"
          width="44"
          height="44"
        ></amp-social-share>
      </div>

      {/*---- Sponsor----*/}
      {!isEmpty(sponsor) && (
        <div className={`mu-item__sponsor ${sponsorClass}`}>
          <a href={sponsor && sponsor.CTAURL} target="_blank">
            <span>{sponsor && sponsor.PartnerText}</span>
            <span className={sponsorSpanClass}>
              {imgUrl && imgUrl.length > 0 && (
                <amp-img
                  src={imgUrl}
                  height={type === 'article' ? '24' : '10'}
                  width="74"
                  alt={sponsor && sponsor.PartnerName}
                  noloading
                ></amp-img>
              )}
              <span className=" screenreader">External Website</span>
            </span>
          </a>
        </div>
      )}
      {/*---- Sponsor----*/}
    </React.Fragment>
  );
};

AMPSocialHolder.defaultProps = {
  sponsorClass: '',
  sponsorSpanClass: '',
  type: '',
};

export default AMPSocialHolder;
