/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import LazyLoad from 'react-lazyload';
import {
  getStatsBarData,
  getSponsorData,
  getRandomId,
  getItemId,
  isApp,
  isCollection,
  createTagArr,
  validateCbr,
  getLoggedInStatus,
  createDestinationUrl,
  deviceManageApi,
  deviceMangerData,
} from '../../../utils/utils';
import ImgCrop from '../../Common/ImgCrop';
import Sponsor from '../../Common/Sponsor';
import {
  ANONYMOUS,
  CONTENT_TYPE,
  REGISTERED,
  MUAppDeepLink,
  STOP,
} from '../../../constants/globalConstants';
import StatsBar from '../../Common/StatsBar';
import * as track from '../../../utils/analytics';
import Loader from '../../Common/Loader';
import { useModal } from '../../Common/Modal';
import loadable from '@loadable/component';
import ErrorBoundary from '../../Common/ErrorBoundary';
import AccessBadge from '../../Common/AccessBadge';
import { useSponsorValue } from '../../../context/sponsorContext';
import MU from '../../../constants/muConstants';
const CollectionModal = loadable(() => import('../../Modals/CollectionModal/CollectionModal'));
const RegistrationModal = loadable(() =>
  import('../../Modals/RegistrationModal/RegistrationModal')
);

const Collection = (props) => {
  const { t, gridClass, isMatchcenterCard, container, showStatsbar, isArticleDetail } = props;
  const {
    ShortHeadline,
    Teaser,
    ContentType,
    CoverImage,
    Sponsorby,
    manutds,
    __Published,
    AuthorName,
    ContentAccess,
  } = props.fields;
  const sponsorInfo = Sponsorby && getSponsorData(Sponsorby.fields);
  const itemId = getItemId(props);
  const [{ isUserLogin, isLoginChecked, isUserSubscribe, isUserSkyId }] = useSponsorValue();
  const { showModal, closeModal, isModalVisible } = useModal();
  const {
    showModal: cbrShowModal,
    closeModal: cbrCloseModal,
    isModalVisible: cbrModalVisible,
  } = useModal();
  const [successScrollState, setSuccessScrollState] = useState({
    x: '',
    y: '',
  });
  const destinationUrl = manutds && manutds.value && manutds.value.DestinationUrl;
  const [id] = useState(getRandomId());
  const isMediaObj = useRef(null);

  const getAnalyticsData = (fields, contentStatus) => {
    const initialData = track.data('card');
    return {
      ...initialData,
      card_name: ShortHeadline && ShortHeadline.value,
      container_type: container,
      item_id: itemId,
      content_type: ContentType && ContentType.value,
      content_status: contentStatus,
      subscription_status: getLoggedInStatus({
        isLoginChecked,
        isUserLogin,
        isUserSubscribe,
        isUserSkyId,
      }),
      tag: manutds && manutds.value && manutds.value.Tags,
      created_datetime: __Published && __Published.value,
      author: AuthorName && AuthorName.value,
      destination_url: destinationUrl,
    };
  };

  const trackCard = (fields, e, contentStatus) => {
    track.analytics(getAnalyticsData(fields, contentStatus), e);
  };

  const getCbrValue = () => {
    return validateCbr({
      isUserLogin,
      isUserSubscribe,
      contentAccess: ContentAccess && ContentAccess.value,
      contentType: ContentType && ContentType.value,
      manutds,
    });
  };
  const onCardClick = (e) => {
    setSuccessScrollState({ x: e.pageX, y: e.pageY });
    const showCbr = getCbrValue();
    trackCard(props.fields, e, showCbr && showCbr.accessCheck);
    if (isApp() && !isCollection()) {
      window.location.href = createDestinationUrl(destinationUrl) + MUAppDeepLink;
      return true;
    }
    if (showCbr && showCbr.value) {
      cbrShowModal(destinationUrl);
    } else {
      showModal(destinationUrl);
    }
  };
  const successCallback = ({ showContent, isSubscribedContent }) => {
    if (showContent) {
      if (!MU.isIDMEnabled) showModal(destinationUrl);
      else {
        return [
          'successScroll',
          isSubscribedContent,
          JSON.stringify(successScrollState.x),
          JSON.stringify(successScrollState.y),
        ];
      }
    }
  };
  const isMediaPlaying = (data) => {
    isMediaObj.current = data;
  };
  const onClose = () => {
    let playHereObj = isMediaObj && isMediaObj.current;
    if (playHereObj && playHereObj.status && playHereObj.userDetail) {
      const { userDetail } = playHereObj;
      const payloadData = deviceMangerData(
        STOP,
        userDetail.UID,
        userDetail.deviceID,
        userDetail.deviceName
      );
      deviceManageApi(payloadData);
    }
  };

  const renderCBSModal = () => {
    const accessType = getCbrValue().accessCheck;
    return (
      <ErrorBoundary>
        <RegistrationModal
          successCallback={successCallback}
          closeModal={cbrCloseModal}
          fallback={<Loader />}
          previousActiveElement={document.activeElement}
          accessType={accessType}
          analyticsObj={getAnalyticsData(props.fields, accessType)}
        />
      </ErrorBoundary>
    );
  };

  return (
    <React.Fragment>
      {cbrModalVisible && renderCBSModal()}
      <div
        className={`${gridClass} ${isMatchcenterCard ? 'ratio-matchday' : 'ratio'} item`}
        onClick={(e) => onCardClick(e)}
        data-impression={track.impression({
          card_name: ShortHeadline && ShortHeadline.value,
          content_type: ContentType && ContentType.value,
        })}
      >
        <div className="mu-content">
          <article
            className={`mu-item collection-card ${
              isArticleDetail && isApp() && isCollection()
                ? 'open-destinationpage'
                : 'open-collection'
            }`}
          >
            <div className="img-holder">
              {CoverImage && CoverImage.value && CoverImage.value.Crops && (
                <LazyLoad once={true} offset={100}>
                  <ImgCrop
                    imgCrop={CoverImage.value.Crops}
                    alt={CoverImage.value.AltText}
                    title={CoverImage.value.AltText}
                    isWebpAvailable={CoverImage.value.isWebpAvailable}
                    singleSrc={false}
                    gridClass={gridClass}
                  ></ImgCrop>
                </LazyLoad>
              )}
            </div>
            <div className="top-badges">
              <AccessBadge
                contentAcessLocal={ContentAccess && ContentAccess.value}
                contentTypeLocal={ContentType && ContentType.value}
                tagListLocal={createTagArr(manutds)}
              />
            </div>
            <Sponsor
              contentType={ContentType && ContentType.value}
              varient=""
              sponsorInfo={sponsorInfo}
              grid={gridClass}
              type={CONTENT_TYPE}
              author={AuthorName && AuthorName.value}
              cardName={ShortHeadline && ShortHeadline.value}
              container={container}
              createdDateTime={__Published && __Published.value}
              itemId={itemId}
              tags={manutds && manutds.value && manutds.value.Tags}
            ></Sponsor>
            <div className="mu-item__info">
              {ShortHeadline && ShortHeadline.value && (
                <React.Fragment>
                  <h2 id={id} className="mu-item__title" tabIndex="0">
                    <Text field={ShortHeadline} />
                  </h2>
                  <span className="screenreader">
                    &nbsp;{ContentType && ContentType.value && t('Collection')}
                  </span>
                </React.Fragment>
              )}
              {Teaser && Teaser.value && (
                <p className="mu-item__teasure">
                  <Text field={Teaser} />
                </p>
              )}
              <div className="link-btn-white">
                <a href="#" onClick={(e) => e.preventDefault()} aria-describedby={id}>
                  <span>
                    {t('viewCollection')}
                    <i className="icon-cta-arrow"></i>
                  </span>
                </a>
              </div>
            </div>
            {showStatsbar && <StatsBar item={getStatsBarData(props.fields)}></StatsBar>}
          </article>
        </div>
      </div>
      {isModalVisible && (
        <ErrorBoundary>
          <CollectionModal
            closeModal={closeModal}
            itemid={itemId}
            fallback={<Loader />}
            cardData={props.fields}
            onClose={onClose}
            isMediaPlaying={isMediaPlaying}
          />
        </ErrorBoundary>
      )}
    </React.Fragment>
  );
};

Collection.defaultProps = {
  showStatsbar: true,
  isArticleDetail: false,
};

export default withTranslation()(Collection);
