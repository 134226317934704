/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import VideosSearch from './VideosSearch';
import NewsSearch from './NewsSearch';
import AllSearch from './AllSearch';
import ImagesSearch from './ImagesSearch';
import NoResultSearch from './NoResultSearch';
import PodcastSearch from './PodcastSearch';
import MutvSearch from './MutvSearch';

const SearchTemplate = (props) => {
  const {
    template,
    results,
    resultsCount,
    ImageSet,
    searchText,
    noResulData,
    language,
    pager,
    onSearch,
    playerProfileList,
  } = props;

  const TemplateMapping = {
    all: AllSearch,
    'all#': AllSearch,
    news: NewsSearch,
    'news#': NewsSearch,
    images: ImagesSearch,
    'images#': ImagesSearch,
    videos: VideosSearch,
    'videos#': VideosSearch,
    podcasts: PodcastSearch,
    'podcasts#': PodcastSearch,
    mutv: MutvSearch,
    'mutv#': MutvSearch,
  };

  const getTemplate = () => {
    const Template = template && TemplateMapping[template];
    return Template ? (
      <Template
        results={results}
        resultsCount={resultsCount}
        ImageSet={ImageSet}
        search={searchText}
        noResulData={noResulData}
        language={language}
        pager={pager}
        onSearch={onSearch}
        playerProfileList={playerProfileList}
      />
    ) : null;
  };
  return (
    <React.Fragment>
      {resultsCount > 0 && getTemplate(template)}
      {resultsCount === 0 && (
        <section>
          <div>
            <NoResultSearch
              noResulData={noResulData}
              language={language}
              search={searchText}
              onSearch={onSearch}
            />
          </div>
        </section>
      )}
    </React.Fragment>
  );
};
export default SearchTemplate;
