/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_CARPARKING,
  SET_CARPARKING,
  ERROR_CARPARKING,
  FETCH_PERSIST_REPORT,
  SET_PERSIST_REPORT,
  ERROR_PERSIST_REPORT,
  GENERIC_FETCH_SEARCH_REPORT,
  SET_GENERIC_SEARCH_REPORT,
  ERROR_GENERIC_SEARCH_REPORT,
  GENERIC_FETCH_PERSISTS,
  GENERIC_SET_PERSISTS,
  GENERIC_ERROR_PERSISTS,
  FETCH_EMAIL_TO_USER,
  SET_EMAIL_TO_USER,
  ERROR_EMAIL_TO_USER,
  FETCH_FAN_FEEDBACK,
  SET_FAN_FEEDBACK,
  ERROR_FAN_FEEDBACK,
  FETCHED_CAPTCHA,
} from '../constants/actions/actionConstant';

import MU from '../constants/muConstants';
import {
  PERSIST_REPORT_PROXY,
  SEARCH_REPORT_PROXY,
  SEND_EMAIL_TO_USER,
  SEND_FAN_FEEDBACK,
} from '../constants/endpoints';
import api from '../utils/muApi';

/*****
 * Carparking *
 * ****/
export const fetchCarParking = (dispatch, userLoginDetail, matchIdNew, setduplicateMesg) => {
  const uId = userLoginDetail && userLoginDetail.UID;
  const lastName = userLoginDetail.profile.lastName;
  const route = `${SEARCH_REPORT_PROXY}?type=MuReports&oid=${uId}&MatchID=${matchIdNew}&ReportType=CarParking&Surname=${lastName}`;
  const baseURL = `${MU.awsEndPoint}/${MU.awsAPIVersion}`;
  const isHardcoredGeo = true;
  const header = {
    contenttype: 'application/x-www-form-urlencoded',
    Accept: '*/*',
  };
  dispatch({ type: FETCH_CARPARKING });
  return api
    .get({ baseURL, route, isHardcoredGeo, header })
    .then((response) => {
      dispatch(setCarParking(response));
    })
    .catch((err) => {
      dispatch(errorCarParking(err));
    });
};

export const sendCarParkingReport = (dispatch, userLoginDetail, parkingData) => {
  const route = `${PERSIST_REPORT_PROXY}`;
  const baseURL = `${MU.awsEndPoint}/${MU.awsAPIVersion}`;
  const header = { 'Content-Type': 'application/x-www-form-urlencoded', Accept: '*/*' };
  const data = `type=MuReports&oid=${userLoginDetail.UID}&data=${JSON.stringify(parkingData)}`;
  const isHardcoredGeo = true;
  dispatch({ type: FETCH_PERSIST_REPORT });
  return api
    .post({ baseURL, route, isHardcoredGeo, header, data })
    .then((response) => {
      dispatch(setPersistReport(response));
    })
    .catch((err) => {
      dispatch(errorPersistReport(err));
    });
};

export const setCarParking = (response) => ({ type: SET_CARPARKING, response });
export const errorCarParking = (response) => ({ type: ERROR_CARPARKING, response });
export const setPersistReport = (response) => ({ type: SET_PERSIST_REPORT, response });
export const errorPersistReport = (response) => ({ type: ERROR_PERSIST_REPORT, response });

/***** Closing Carparking *****/

/****
 * Generic Search report
 * */

export const fetchSearchReport = (dispatch, formName, formEmail) => {
  const route = `${SEARCH_REPORT_PROXY}?type=MuGenericReports&oid=${formEmail}&formName=${formName}`;
  const baseURL = `${MU.awsEndPoint}/${MU.awsAPIVersion}`;
  const isHardcoredGeo = true;
  const header = {
    contenttype: 'application/x-www-form-urlencoded',
    Accept: '*/*',
  };
  dispatch(fetchingGenericSearchReport());
  return api
    .get({ baseURL, route, isHardcoredGeo, header })
    .then((response) => {
      dispatch(setGenericSearchReport(response));
    })
    .catch((err) => {
      dispatch(genericSearchReportError(err));
    });
};
export const fetchingGenericSearchReport = () => ({ type: GENERIC_FETCH_SEARCH_REPORT });
export const setGenericSearchReport = (response) => ({
  type: SET_GENERIC_SEARCH_REPORT,
  response,
});
export const genericSearchReportError = (response) => ({
  type: ERROR_GENERIC_SEARCH_REPORT,
  response,
});

/****
 * Fetch Generic Persist report proxy
 * */

export const fetchGenericPersistReport = (dispatch, data) => {
  const route = PERSIST_REPORT_PROXY;
  const baseURL = `${MU.awsEndPoint}/${MU.awsAPIVersion}`;
  const isHardcoredGeo = true;

  const header = {
    contenttype: 'application/x-www-form-urlencoded',
    Accept: '*/*',
  };
  dispatch(fetchingGenericPersist());
  return api
    .post({ baseURL, route, isHardcoredGeo, header, data })
    .then((response) => {
      dispatch(setGenericPersist(response));
    })
    .catch((err) => {
      dispatch(genericPersistError(err));
    });
};

export const fetchingGenericPersist = () => ({ type: GENERIC_FETCH_PERSISTS });
export const setGenericPersist = (response) => ({
  type: GENERIC_SET_PERSISTS,
  response,
});
export const genericPersistError = (response) => ({
  type: GENERIC_ERROR_PERSISTS,
  response,
});

export const fetchedCaptcha = (dispatch) => {
  dispatch({ type: FETCHED_CAPTCHA });
};

export const postEmailData = (dispatch, data) => {
  dispatch({ type: FETCH_EMAIL_TO_USER });
  const route = SEND_EMAIL_TO_USER;
  const isHardcoredGeo = true;
  const header = {
    Accept: '*/*',
    contentType: false, // Not to set any content header
    processData: false, // Not to process data
  };
  const baseURL = window && window.location && window.location.origin;
  return api
    .post({ baseURL, route, isHardcoredGeo, header, data })
    .then((response) => {
      dispatch({ type: SET_EMAIL_TO_USER, response });
    })
    .catch((err) => {
      dispatch({ type: ERROR_EMAIL_TO_USER, err });
    });
};

export const postFeedbackData = (dispatch, data) => {
  dispatch({ type: FETCH_FAN_FEEDBACK });
  const route = SEND_FAN_FEEDBACK;
  const isHardcoredGeo = true;
  const header = {
    Accept: '*/*',
    contentType: 'application/json',
    processData: false,
  };
  const baseURL = window && window.location && window.location.origin;
  return api
    .post({ baseURL, route, isHardcoredGeo, header, data })
    .then((response) => {
      dispatch({ type: SET_FAN_FEEDBACK, response });
    })
    .catch((err) => {
      dispatch({ type: ERROR_FAN_FEEDBACK, err });
    });
};
