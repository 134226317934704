/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';

export const MutvHeroShimmer = (props) => {
  const { isShowScreen, type } = props;
  return (
    <div className="mu-item shimmer">
      <div className="img-holder" />
      <div className="mu-item__info">
        {!isShowScreen && <div className="mu-item__series"></div>}
        <div className="mu-item__title" />
        <div className="mu-item__teasure" />
        <div className="mu-item__teasure" />
        <div className="mu-item__breadcrum-wrapper">
          <div className="mu-item__breadcrum first" />
          <div className="mu-item__breadcrum second" />
          <div className="mu-item__breadcrum third" />
        </div>
        {isShowScreen ? (
          <div className="widgets_wrapper">
            <div className="widgets" />
            <div className="widgets" />
            <div className="widgets" />
          </div>
        ) : !isShowScreen && type === 'video' ? (
          <div className="widgets_wrapper video">
            <div className="widgets" />
          </div>
        ) : (
          <div className="widgets_wrapper podcast">
            <div className="widgets" />
            <div className="widgets" />
          </div>
        )}
      </div>
    </div>
  );
};
export default MutvHeroShimmer;
