/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';

const NoResultSearch = (props) => {
  const { t, noResulData, language, search, onSearch } = props;
  return (
    <div className="search__result-time">
      <div className="no-result grid-12">
        {language.toLowerCase() !== 'ja' && language.toLowerCase() !== 'fr' && (
          <p>
            {t('yoursearch')} - <b>{search}</b> - {t('didnotmatchanyofourresults')}
          </p>
        )}
        {language.toLowerCase() === 'ja' && (
          <p>
            <b>{search}</b> {t('yoursearch')} {t('didnotmatchanyofourresults')}
          </p>
        )}
        {language.toLowerCase() === 'fr' && (
          <p>
            {t('didnotmatchanyofourresults')} {t('yoursearch')} <b>{search}</b>
          </p>
        )}
        <div className="suggested">
          <div className="suggested__wrapper">
            <div className="suggested__box">
              <h6>{t('Suggestions')}</h6>
              <ul>
                <li>{t('Makesurethatallwordsarespelledcor')}</li>
                <li>{t('trydifferentkeywords')}</li>
                <li>{t('trymoregeneralkeywords')}</li>
              </ul>
            </div>
            <div className="suggested__keywords">
              <h4>{t('Tryanothersearchlike')}</h4>
              <ul>
                {noResulData &&
                  noResulData.map((noresultKey, i) => {
                    return (
                      <li key={`noResult${i}`} role="link" tabIndex="0">
                        <a
                          tabIndex="-1"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            onSearch(noresultKey);
                          }}
                          className="link"
                        >
                          <span>{noresultKey}</span>
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(NoResultSearch);
