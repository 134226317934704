/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import { buildImgUrl } from './utils';
export const getAMPpublisher = (publisher) => {
  return publisher
    ? {
        '@type': 'Organization',
        name: publisher.name,
        logo: {
          '@type': publisher.logo && publisher.logo.type,
          url: publisher.logo && publisher.logo.url,
          width: publisher.logo && publisher.logo.width && publisher.logo.width.name,
          height: publisher.logo && publisher.logo.height && publisher.logo.height.name,
        },
      }
    : {};
};
export const getPublisherType1 = (publisher) => {
  return publisher
    ? {
        '@type': publisher.type,
        url: publisher.url,
        name: publisher.name,
        sameAs: publisher.sameAs,
        logo: {
          '@type': publisher.logo && publisher.logo.type,
          url: publisher.logo && publisher.logo.url,
          width: publisher.logo && publisher.logo.width,
          height: publisher.logo && publisher.logo.height,
        },
      }
    : {};
};
export const getPublisherType2 = (publisher) => {
  return publisher
    ? {
        '@type': publisher.type,
        '@id': publisher.id,
        name: publisher.name,
        logo: {
          '@type': publisher.logo && publisher.logo.type,
          url: publisher.logo && publisher.logo.url,
          width: {
            '@type': publisher.logo && publisher.logo.width && publisher.logo.width.type,
            unitText: publisher.logo && publisher.logo.width && publisher.logo.width.unitText,
            value: publisher.logo && publisher.logo.width && publisher.logo.width.value,
          },
          height: {
            '@type': publisher.logo && publisher.logo.height && publisher.logo.height.type,
            unitText: publisher.logo && publisher.logo.height && publisher.logo.height.unitText,
            value: publisher.logo && publisher.logo.height && publisher.logo.height.value,
          },
        },
      }
    : {};
};
export const getPublisherType3 = (publisher) => {
  return publisher
    ? {
        '@type': publisher.type ? publisher.type : 'Organization',
        url: publisher.url,
        name: publisher.name,
        sameAs: publisher.sameAs,
        logo: {
          '@type': publisher.logo && publisher.logo.type,
          url: publisher.logo && publisher.logo.url,
          width: {
            '@type': publisher.logo && publisher.logo.width && publisher.logo.width.type,
            name: publisher.logo && publisher.logo.width && publisher.logo.width.name,
          },
          height: {
            '@type': publisher.logo && publisher.logo.height && publisher.logo.height.type,
            name: publisher.logo && publisher.logo.height && publisher.logo.height.name,
          },
        },
      }
    : {};
};
export const getLiveBlogUpdate = (data) => {
  try {
    return data && data.length > 0
      ? data.map((obj) => {
          return {
            '@type': obj.type,
            headline: obj.headline,
            author: {
              '@id': obj.author && obj.author.id,
              name: obj.author && obj.author.name,
            },
            publisher: {
              '@type': obj.publisher && obj.publisher.type,
              name: obj.publisher && obj.publisher.name,
              logo: {
                '@type': obj.publisher && obj.publisher.logo && obj.publisher.logo.type,
                url: obj.publisher && obj.publisher.logo && obj.publisher.logo.url,
                width: obj.publisher && obj.publisher.logo && obj.publisher.logo.width,
                height: obj.publisher && obj.publisher.logo && obj.publisher.logo.height,
              },
            },
            url: obj.url,
            datePublished: obj.datePublished,
            articleBody: obj.articleBody,
            image: {
              '@type': obj.image && obj.image.type,
              width: obj.image && obj.image.width,
              height: obj.image && obj.image.height,
              url: obj.image && obj.image.url,
            },
            mainEntityOfPage: obj.mainEntityOfPage,
            dateModified: obj.dateModified,
          };
        })
      : [];
  } catch (e) {
    console.log('Error in Structured Data', e);
    return [];
  }
};
export const getLiveBlogData = (data) => {
  return {
    '@context': data.context,
    '@type': data.type,
    url: data.url,
    headline: data.headline,
    description: data.description,
    datePublished: data.datePublished,
    coverageStartTime: data.coverageStartTime,
    coverageEndTime: data.coverageEndTime,
    dateModified: data.dateModified,
    publisher: getPublisherType2(data.publisher),
    liveBlogUpdate: getLiveBlogUpdate(data.liveBlogUpdate),
  };
};
export const getAddress = (data) => {
  return data && data.awayTeamaddress
    ? {
        '@type': data.awayTeamaddress.type,
        addressLocality: data.awayTeamaddress.addressLocality,
      }
    : data && data.homeTeamaddress
    ? {
        '@type': data.homeTeamaddress.type,
        addressLocality: data.homeTeamaddress.addressLocality,
        streetAddress: data.homeTeamaddress.streetAddress,
        addressRegion: data.homeTeamaddress.addressRegion,
        postalCode: data.homeTeamaddress.postalCode,
        addressCountry: data.homeTeamaddress.addressCountry,
      }
    : {};
};
export const getPerformer = (data) => {
  return data && data.length > 0
    ? data.map((obj) => {
        return {
          '@type': obj.type,
          name: obj.name,
        };
      })
    : {};
};
export const getSportsData = (data) => {
  let ogData = {
    '@context': data.context,
    '@type': data.type,
    name: data.name,
    url: data.url,
    description: data.description,
    startDate: data.startDate,
    endDate: data.endDate,
    eventStatus: data.eventStatus,
    eventAttendanceMode: data.eventAttendanceMode,
    location: {
      '@type': data.location && data.location.type,
      name: data.location && data.location.name,
      address: getAddress(data.location && data.location.address),
    },
    image: data.image,
    awayTeam: {
      '@type': data.awayTeam && data.awayTeam.type,
      name: data.awayTeam && data.awayTeam.name,
      image: data.awayTeam && data.awayTeam.image,
    },
    homeTeam: {
      '@type': data.homeTeam && data.homeTeam.type,
      name: data.homeTeam && data.homeTeam.name,
      image: data.homeTeam && data.homeTeam.image,
    },
    performer: getPerformer(data.performer),
  };
  if (data.offers && Object.keys(data.offers).length !== 0 && data.offers.constructor === Object) {
    ogData.offers = {
      '@type': data.offers.type,
      description: data.offers.description,
      url: data.offers.url,
    };
  }
  return ogData;
};
export const getLiveEventsBlogData = (data) => {
  return {
    '@context': data.context,
    '@type': data.type,
    url: data.url,
    articleBody: data.articleBody,
    headline: data.headline,
    description: data.description,
    datePublished: data.datePublished,
    about: {
      '@type': data.about && data.about.type,
      '@id': data.about && data.about.id,
      name: data.about && data.about.name,
      description: data.about && data.about.description,
      url: data.about && data.about.url,
    },
    image: {
      '@type': data.image && data.image.type,
      url: data.image && data.image.url,
      height: data.image && data.image.height,
      width: data.image && data.image.width,
    },
    coverageStartTime: data.coverageStartTime,
    coverageEndTime: data.coverageEndTime,
    liveBlogUpdate: getLiveBlogUpdate(data.liveBlogUpdate),
  };
};

export const parseJsonData = (data) => {
  try {
    return data && JSON.parse(data);
  } catch (e) {
    console.error('Error in parsing data of match carousel');
  }
};

export const dateformat = 'YYYY-MM-DDTHH:mm:ssZ';
export const defaultDescription =
  'Follow all the match action with our unrivalled live coverage, including stats, polls, images, videos and text commentary.';
export const OLD_TRAFFORD = 'OLD TRAFFORD';
export const Stadium = 'Stadium';
export const Organization = 'Organization';
export const Thing = 'Thing';
export const PostalAddress = 'PostalAddress';
export const EventScheduled = 'https://schema.org/EventScheduled';
export const EventCancelled = 'https://schema.org/EventCancelled';
export const EventPostponed = 'https://schema.org/EventPostponed';

export const getHomeAddress = (venuecity) => {
  return {
    '@type': PostalAddress,
    streetAddress: 'Sir Matt Busby Way',
    addressLocality: venuecity ? venuecity : 'Manchester',
    addressRegion: 'Old Trafford',
    postalCode: 'M16 0RA',
    addressCountry: 'UK',
  };
};

export const getImageUrl = (image) => {
  return image && image.CropUrl ? buildImgUrl(image.CropUrl.img1x) : '';
};

export const getDataObject = (e) => {
  return {
    '@context': 'http://schema.org/',
    '@type': 'SportsEvent',
    name: e.name,
    url: e.url,
    description: e.description,
    startDate: e.startDate,
    endDate: e.endDate,
    eventStatus: e.eventStatus,
    eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
    location: e.location,
    image: e.image,
    awayTeam: e.awayTeam,
    homeTeam: e.homeTeam,
    performer: e.performer,
  };
};

export const jsonStringify = (data) => {
  try {
    return JSON.stringify(data);
  } catch (e) {
    console.log('error in JSON stringify Structued Data');
    return {};
  }
};
