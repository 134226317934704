/**
 *  © 2022 -2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import Poll from '../../Cards/Poll';
import { HERO_POLL } from '../../../constants/containerConstants';
const HeroBannerPoll = (props) => {
  return (
    <>
      <div className="home-row">
        <div className="home-hero">
          <Poll container={HERO_POLL} {...props} nativeCard={true} gridClass="grid-12" />
        </div>
      </div>
    </>
  );
};

export default HeroBannerPoll;
