/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/**
 *
 * @param {returns curatedCard contextual data video content type has higher periority} cards
 */
export const mapCuratedCard = (cards) => {
  let arr = [];
  if (
    isNullOrEmpty(cards && cards.CardsInformation && cards.CardsInformation.Card1VideoDetails) &&
    (!isNullOrEmpty(cards && cards.CardsInformation && cards.CardsInformation.Card1Title) ||
      !isNullOrEmpty(cards && cards.CardsInformation && cards.CardsInformation.Card1Image))
  ) {
    arr.push({
      shortheadline_t: cards.CardsInformation.Card1Title,
      contentaccess_t: cards.CardsInformation.Card1ContentAccess,
      imagecropurl_s: cards.CardsInformation.Card1Image,
      iswebpavailablebool_b: cards.CardsInformation.Card1IsWebpAvailable,
      teaser_t: cards.CardsInformation.Card1ShortDescription,
      alttext_s: cards.CardsInformation.Card1ImageAlt,
      isloginrequire_b: '',
      contenttype_t: 'article',
      destinationurl_s: cards.CardsInformation.Card1Link,
      target: cards.CardsInformation.Card1LinkTarget,
      sponsordetailinfo_s: checkSponsor(cards.CardsInformation.Card1Sponsor),
    });
  } else if (
    !isNullOrEmpty(cards && cards.CardsInformation && cards.CardsInformation.Card1VideoDetails)
  ) {
    arr.push({
      contentaccess_t: cards.CardsInformation.Card1ContentAccess,
      celumimagesvariant_s: {
        ImageCropUrl: { CropUrl: JSON.parse(cards.CardsInformation.Card1Image) },
      },
      imagecropurl_s: cards.CardsInformation.Card1Image,
      iswebpavailablebool_b: cards.CardsInformation.Card1IsWebpAvailable,
      shortheadline_t: cards.CardsInformation.Card1Title,
      contenttype_t: 'video',
      destinationurl_s: cards.CardsInformation.Card1Link,
      author_t: '',
      isloginrequire_b: '',
      mediaid_s: cards.CardsInformation.Card1VideoDetails.MediaId,
      teaser_t: cards.CardsInformation.Card1VideoDetails.Teaser,
      dimension: cards.CardsInformation.Card1VideoDetails.Orientation,
      __published_tdt: cards.CardsInformation.Card1VideoDetails.Published,
      itemid_s: cards.CardsInformation.Card1VideoDetails.ItemId,
      sponsordetailinfo_s: checkSponsor(cards.CardsInformation.Card1Sponsor),
      isNormalVideo_b: cards.CardsInformation.Card1VideoDetails.IsNormalVideo,
      upsell_s: cards.CardsInformation.Card1VideoDetails.UpsellDetails,
    });
  }
  if (
    isNullOrEmpty(cards && cards.CardsInformation && cards.CardsInformation.Card2VideoDetails) &&
    (!isNullOrEmpty(cards && cards.CardsInformation && cards.CardsInformation.Card2Title) ||
      !isNullOrEmpty(cards && cards.CardsInformation && cards.CardsInformation.Card2Image))
  ) {
    arr.push({
      contentaccess_t: cards.CardsInformation.Card2ContentAccess,
      shortheadline_t: cards.CardsInformation.Card2Title,
      imagecropurl_s: cards.CardsInformation.Card2Image,
      iswebpavailablebool_b: cards.CardsInformation.Card2IsWebpAvailable,
      teaser_t: cards.CardsInformation.Card2ShortDescription,
      alttext_s: cards.CardsInformation.Card2ImageAlt,
      isloginrequire_b: '',
      contenttype_t: 'article',
      destinationurl_s: cards.CardsInformation.Card2Link,
      target: cards.CardsInformation.Card2LinkTarget,
      sponsordetailinfo_s: checkSponsor(cards.CardsInformation.Card2Sponsor),
    });
  } else if (
    !isNullOrEmpty(cards && cards.CardsInformation && cards.CardsInformation.Card2VideoDetails)
  ) {
    arr.push({
      contentaccess_t: cards.CardsInformation.Card2ContentAccess,
      celumimagesvariant_s: {
        ImageCropUrl: { CropUrl: JSON.parse(cards.CardsInformation.Card2Image) },
      },
      imagecropurl_s: cards.CardsInformation.Card2Image,
      iswebpavailablebool_b: cards.CardsInformation.Card2IsWebpAvailable,
      shortheadline_t: cards.CardsInformation.Card2Title,
      contenttype_t: 'video',
      destinationurl_s: cards.CardsInformation.Card2Link,
      author_t: '',
      isloginrequire_b: '',
      mediaid_s: cards.CardsInformation.Card2VideoDetails.MediaId,
      teaser_t: cards.CardsInformation.Card2VideoDetails.Teaser,
      dimension: cards.CardsInformation.Card2VideoDetails.Orientation,
      appearance_tdt: cards.CardsInformation.Card2VideoDetails.Published,
      itemid_s: cards.CardsInformation.Card2VideoDetails.ItemId,
      sponsordetailinfo_s: checkSponsor(cards.CardsInformation.Card2Sponsor),
      isNormalVideo_b: cards.CardsInformation.Card2VideoDetails.IsNormalVideo,
      upsell_s: cards.CardsInformation.Card2VideoDetails.UpsellDetails,
    });
  }
  if (
    isNullOrEmpty(cards && cards.CardsInformation && cards.CardsInformation.Card3VideoDetails) &&
    (!isNullOrEmpty(cards && cards.CardsInformation && cards.CardsInformation.Card3Title) ||
      !isNullOrEmpty(cards && cards.CardsInformation && cards.CardsInformation.Card3Image))
  ) {
    arr.push({
      contentaccess_t: cards.CardsInformation.Card3ContentAccess,
      shortheadline_t: cards.CardsInformation.Card3Title,
      celumimagesvariant_s: {
        ImageCropUrl: { CropUrl: JSON.parse(cards.CardsInformation.Card3Image) },
      },
      imagecropurl_s: cards.CardsInformation.Card3Image,
      iswebpavailablebool_b: cards.CardsInformation.Card3IsWebpAvailable,
      teaser_t: cards.CardsInformation.Card3ShortDescription,
      alttext_s: cards.CardsInformation.Card3ImageAlt,
      isloginrequire_b: '',
      contenttype_t: 'article',
      destinationurl_s: cards.CardsInformation.Card3Link,
      target: cards.CardsInformation.Card3LinkTarget,
      sponsordetailinfo_s: checkSponsor(cards.CardsInformation.Card3Sponsor),
    });
  } else if (
    !isNullOrEmpty(cards && cards.CardsInformation && cards.CardsInformation.Card3VideoDetails)
  ) {
    arr.push({
      contentaccess_t: cards.CardsInformation.Card3ContentAccess,
      celumimagesvariant_s: {
        ImageCropUrl: { CropUrl: JSON.parse(cards.CardsInformation.Card3Image) },
      },
      imagecropurl_s: cards.CardsInformation.Card3Image,
      iswebpavailablebool_b: cards.CardsInformation.Card3IsWebpAvailable,
      shortheadline_t: cards.CardsInformation.Card3Title,
      contenttype_t: 'video',
      destinationurl_s: cards.CardsInformation.Card3Link,
      author_t: '',
      isloginrequire_b: '',
      mediaid_s: cards.CardsInformation.Card3VideoDetails.MediaId,
      teaser_t: cards.CardsInformation.Card3VideoDetails.Teaser,
      dimension: cards.CardsInformation.Card3VideoDetails.Orientation,
      appearance_tdt: cards.CardsInformation.Card3VideoDetails.Published,
      itemid_s: cards.CardsInformation.Card3VideoDetails.ItemId,
      sponsordetailinfo_s: checkSponsor(cards.CardsInformation.Card3Sponsor),
      isNormalVideo_b: cards.CardsInformation.Card3VideoDetails.IsNormalVideo,
      upsell_s: cards.CardsInformation.Card3VideoDetails.UpsellDetails,
    });
  }
  return arr;
};
const isNullOrEmpty = (obj) => {
  return obj === '' || obj == null ? true : false;
};

// To check the if array is empty object or not
const checkSponsor = (obj) => {
  if (obj && Object.keys(obj).length > 0 && obj.constructor === Object) {
    return `[${JSON.stringify(obj)}]`;
  } else if (obj && typeof obj === 'string') {
    return obj;
  }
  return null;
};
