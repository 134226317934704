/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import ImgCrop from '../../Common/ImgCrop';
import {
  getStatsBarData,
  getSponsorData,
  getItemId,
  buildImgUrl,
  createDestinationUrl,
  getRandomId,
} from '../../../utils/utils';
import { CONTENT_TYPE } from '../../../constants/globalConstants';
import Sponsor from '../../Common/Sponsor';
import StatsBar from '../../Common/StatsBar';
import * as track from '../../../utils/analytics';
import { formatDate } from '../../../utils/momentDate';
const PlayerProfile = (props) => {
  const {
    t,
    gridClass,
    container,
    isCollection,
    navCards,
    isMatchcenterCard,
    isArticleDetail,
    showStatsbar,
  } = props;
  const {
    Appearances,
    Cleansheets,
    DebutOpposition,
    Goals,
    Position,
    ShirtNumber,
    LastName,
    FirstName,
    Country,
    CardBackgroundImage,
    BirthPlace,
    BirthDate,
    AuthorName,
    ContentType,
    CardType,
    Sponsorby,
    __Published,
    __PublishedBy,
    manutds,
    Trophies,
    Wins,
    Matches,
    DebutDate,
    LatestMatchId,
  } = props.fields;
  const imageCrops =
    CardBackgroundImage && CardBackgroundImage.value && CardBackgroundImage.value.Crops;
  const imageAltText =
    CardBackgroundImage && CardBackgroundImage.value && CardBackgroundImage.value.AltText;
  const isWebpAvailable =
    CardBackgroundImage && CardBackgroundImage.value && CardBackgroundImage.value.isWebpAvailable;
  const itemId = getItemId(props);
  const DestinationUrl = manutds && manutds.value && manutds.value.DestinationUrl;
  const PlayerName = `${FirstName && FirstName.value} ${LastName && LastName.value}`;
  const sponsorInfo = Sponsorby && getSponsorData(Sponsorby.fields);
  const isGoalKeeper =
    Position && Position.value && Position.value.toLowerCase() === 'goalkeeper' ? true : false;
  const randomId = getRandomId();
  const trackCard = (fields, e) => {
    const initialData = track.data('card');
    track.analytics(
      {
        ...initialData,
        card_name: PlayerName,
        container_type: container,
        item_id: itemId,
        content_type: ContentType && ContentType.value,
        tag: manutds && manutds.value && manutds.value.Tags,
        created_datetime: __Published && __Published.value,
        author: AuthorName && AuthorName.value,
        destination_url: DestinationUrl,
      },
      e
    );
  };
  const getImgCrop = (className) => {
    return (
      <ImgCrop
        imgCrop={imageCrops}
        alt={imageAltText}
        title={imageAltText}
        isWebpAvailable={isWebpAvailable}
        gridClass={gridClass}
        className={className}
      ></ImgCrop>
    );
  };
  const validateItem = (obj) => {
    return obj.hasOwnProperty('value') &&
      obj.value !== null &&
      obj.value !== undefined &&
      obj.value.toString() !== '' &&
      obj.value > 0
      ? true
      : false;
  };
  return (
    <React.Fragment>
      {!navCards && (
        <div
          className={
            isCollection
              ? 'grid-4 ratio centeritem profile-card'
              : isMatchcenterCard
              ? 'grid-12'
              : gridClass
          }
          aria-label="player profile"
          onClick={(e) => trackCard(props, e)}
          data-impression={track.impression({
            card_name: PlayerName,
            content_type: ContentType && ContentType.value,
          })}
        >
          <div
            className={
              isArticleDetail
                ? 'mu-content'
                : `match-day profile child ${props.isCarousel ? 'mu-content' : ''}`
            }
          >
            <div className={isArticleDetail ? 'match-day profile child' : ''}>
              <a
                className="search-card-click"
                href={createDestinationUrl(DestinationUrl)}
                tabIndex="-1"
                aria-hidden="true"
              ></a>
              {!isCollection && (
                <div className="img-holder">
                  <Sponsor
                    contentType={ContentType && ContentType.value}
                    varient={''}
                    sponsorInfo={sponsorInfo}
                    grid={gridClass}
                    type={CONTENT_TYPE}
                    author={AuthorName && AuthorName.value}
                    cardName={PlayerName}
                    container={container}
                    createdDateTime={__Published && __Published.value}
                    itemId={itemId}
                    tags={manutds && manutds.value && manutds.value.Tags}
                  ></Sponsor>
                  {imageCrops && props.isCarousel && getImgCrop('adaptive')}
                  {imageCrops && !props.isCarousel && (
                    <LazyLoad once={true} offset={100}>
                      {getImgCrop('figure-component')}
                    </LazyLoad>
                  )}
                </div>
              )}
              <div className="profile-pic">
                <ul>
                  <li>
                    {!props.isCarousel && (
                      <h2 id={randomId}>
                        <span aria-hidden={true}>{PlayerName}</span>
                        <span className="screenreader">{`${PlayerName} ${t(
                          'playerprofile'
                        )}`}</span>
                      </h2>
                    )}
                    {props.isCarousel && <span id={randomId}>{PlayerName}</span>}
                  </li>
                  <li>
                    <div className="profile-pic__thumb" aria-hidden="true">
                      <img
                        className={imageCrops && imageCrops.small ? 'profile-photo' : 'icon-avatar'}
                        src={buildImgUrl(imageCrops && imageCrops.small)}
                        alt=""
                        title=""
                      />
                    </div>
                  </li>
                  <li>{Position && Position.value}</li>
                </ul>
              </div>
              <div className="match-day__info">
                <div className="profile__score">
                  <ul>
                    {Matches && validateItem(Matches) && (
                      <li>
                        <span>{t('Matches')}</span>
                        <span className="card-val-1 card-val-2">{Matches.value}</span>
                      </li>
                    )}
                    {ShirtNumber && validateItem(ShirtNumber) && (
                      <li>
                        <span>
                          {!!isCollection || !props.isCarousel ? t('Shirtnumber') : t('number')}
                        </span>
                        <span className="card-val-1 card-val-2">{ShirtNumber.value}</span>
                      </li>
                    )}
                    {Appearances && validateItem(Appearances) && (
                      <li>
                        <span aria-hidden="true">{t('Apps')}</span>
                        <span className="screenreader">{`${t('Appearances')}`}</span>
                        <span className="card-val-1 card-val-2">{Appearances.value}</span>
                      </li>
                    )}
                    {Wins && validateItem(Wins) && (
                      <li>
                        <span>{t('Wins')}</span>
                        <span className="card-val-1 card-val-2">{Wins.value}</span>
                      </li>
                    )}
                    {!isGoalKeeper && Goals && validateItem(Goals) && (
                      <li>
                        <span>{t('Goals')} </span>
                        <span className="card-val-1 card-val-2">{Goals.value}</span>
                      </li>
                    )}
                    {isGoalKeeper && Cleansheets && validateItem(Cleansheets) && (
                      <li>
                        <span>{t('Cleansheets')}</span>
                        <span className="card-val-1 card-val-2">{Cleansheets.value}</span>
                      </li>
                    )}
                    {Trophies && validateItem(Trophies) && (
                      <li>
                        <span>{t('Trophies')}</span>
                        <span className="card-val-1 card-val-2">{Trophies.value}</span>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="profile__details">
                  <ul>
                    {DebutDate && DebutDate.value && (
                      <li>
                        <span>{t('Uniteddebut')}</span>
                        <span className="card-val-1 card-val-2">
                          <span>{formatDate(DebutDate.value, 'playerprofile-date')}</span>
                          <span> {DebutOpposition && DebutOpposition.value}</span>
                        </span>
                      </li>
                    )}
                    <li>
                      <span>{t('Birthplace')}</span>
                      <span className="card-val-1 card-val-2">
                        {BirthPlace && BirthPlace.value}{' '}
                        {Country && Country.value && BirthPlace && BirthPlace.value ? ', ' : ''}{' '}
                        {Country && Country.value}
                      </span>
                    </li>
                    <li>
                      <span>{t('Birthdate')}</span>
                      <span className="card-val-1 card-val-2">
                        {formatDate(BirthDate && BirthDate.value, 'playerprofile-date')}
                      </span>
                    </li>
                  </ul>
                  <div className="btn-wrapper">
                    <div className={isCollection ? `black-btn-skew` : `white-btn-skew`}>
                      <a href={createDestinationUrl(DestinationUrl)} aria-describedby={randomId}>
                        <span>{t('viewprofile')}</span>
                        <i className="tag-red-arrow"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {!isCollection && showStatsbar && (
                <StatsBar
                  classes="statsbar profile-Share-Item"
                  item={getStatsBarData(props.fields)}
                ></StatsBar>
              )}
            </div>
          </div>
        </div>
      )}
      {navCards && navCards.isMagaNavCard && (
        <div
          className="grid-4 ratio item"
          onClick={(e) => trackCard(props, e)}
          data-impression={track.impression({
            card_name: PlayerName,
            content_type: ContentType && ContentType.value,
          })}
        >
          <div className="mu-content">
            <div className="mu-item team-card">
              <a href={createDestinationUrl(DestinationUrl)}>
                <div className="img-holder">
                  {imageCrops && (
                    <LazyLoad once={true} offset={100}>
                      <ImgCrop
                        imgCrop={imageCrops}
                        alt={imageAltText}
                        title={imageAltText}
                        isWebpAvailable={isWebpAvailable}
                        gridClass={gridClass}
                      ></ImgCrop>
                    </LazyLoad>
                  )}
                </div>
              </a>
              <div className="mu-item__info">
                <p className="mu-item__jersey-number" aria-hidden="true">
                  {ShirtNumber && ShirtNumber.value}
                </p>
                <span className="mu-item__firstname">{FirstName && FirstName.value}</span>
                <span className="mu-item__lastname">{LastName && LastName.value}</span>
                <span className="screenreader">
                  {t('Shirtnumber')} {ShirtNumber && ShirtNumber.value}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

PlayerProfile.defaultProps = {
  showStatsbar: true,
};

export default withTranslation()(PlayerProfile);
