// this function will create code verifier using PKCE authentication

import { getCookie } from '../utils/utils';

// https://docs.cotter.app/sdk-reference/api-for-other-mobile-apps/api-for-mobile-apps#step-1-create-a-code-verifier
const createCodeVerifier = () => {
  const dec2hex = (dec) => {
    return ('0' + dec.toString(16)).substr(-2);
  };

  const generateRandomString = () => {
    if (typeof window !== 'undefined') {
      const array = new Uint32Array(56 / 2);
      window.crypto.getRandomValues(array);
      return Array.from(array, dec2hex).join('');
    }
  };

  return generateRandomString();
};

// this function will create code challenge using code verifier for PKCE authentication
// https://docs.cotter.app/sdk-reference/api-for-other-mobile-apps/api-for-mobile-apps#step-1-create-a-code-verifier
export const createCodeVerifierNChallenge = () => {
  const idmVerifier = getCookie('idm_verifier');
  const idmChallenge = getCookie('idm_challenge');

  if (idmVerifier && idmChallenge) {
    return Promise.resolve({ verifier: idmVerifier, challenge: idmChallenge });
  }
  const sha256 = (plain) => {
    // returns promise ArrayBuffer
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    if (typeof window !== 'undefined') {
      return window.crypto.subtle.digest('SHA-256', data);
    }
  };

  const base64urlencode = (a) => {
    let str = '';
    const bytes = new Uint8Array(a);
    const len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      str += String.fromCharCode(bytes[i]);
    }
    return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  };

  const challenge_from_verifier = (v) => sha256(v).then((hashed) => base64urlencode(hashed));

  const verifier = createCodeVerifier();

  return challenge_from_verifier(verifier).then((challenge) => ({ verifier, challenge }));
};
