/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

export const FanaticsProduct = (data, isAws) => {
  return {
    fields: {
      ImageLink: {
        value: data && data.ImageLink,
      },
      ProductID: {
        value: data && data.ProductID,
      },
      Rank: {
        value: data && data.Rank,
      },
      Title: {
        value: data && data.Title,
      },
      CTATitle: {
        value: data && data.CTATitle,
      },
      CTAUrl: {
        value: {
          href: data && data.CTAUrl && data.CTAUrl.href,
          hrefMuCustom: data && data.CTATitle && data.CTAUrl.hrefMuCustom,
          text: data && data.CTATitle && data.CTAUrl.text,
          target: data && data.CTATitle && data.CTAUrl.target,
          url: data && data.CTATitle && data.CTAUrl.Url,
          linktype: data && data.CTATitle && data.CTAUrl.linktype,
        },
      },
    },
  };
};
