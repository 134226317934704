/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

export const dialog = (
  dialogEl,
  focusableList,
  firstFocusable,
  lastFocusable,
  focusedElBeforeOpen,
  closeModal,
  showClose
) => {
  const dialog = dialogEl && dialogEl.current;
  if (dialog) {
    toggleAria(true);
    focusableList.current = dialog.querySelectorAll(
      'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), h2, [tabindex="0"]'
    );
    focusableList.current = Array.prototype.slice.call(focusableList.current);
    firstFocusable.current = focusableList.current[showClose ? 0 : 1];
    firstFocusable.current && firstFocusable.current.focus();
    lastFocusable.current = focusableList.current[focusableList.current.length - 1];
    dialog &&
      dialog.addEventListener('keydown', (e) => {
        dialogHandleKeyDown(
          e,
          focusedElBeforeOpen,
          focusableList,
          firstFocusable,
          lastFocusable,
          closeModal
        );
      });
    firstFocusable.current && firstFocusable.current.focus();
    return '';
  }
};

const dialogHandleKeyDown = (
  e,
  focusedElBeforeOpen,
  focusableList,
  firstFocusable,
  lastFocusable
) => {
  const KEY_TAB = 9;
  const KEY_ESC = 27;
  const handleBackwardTab = () => {
    if (document.activeElement === firstFocusable.current) {
      e.preventDefault();
      lastFocusable.current && lastFocusable.current.focus();
      document.activeElement.classList.add('non-keyboard-outline');
    }
  };
  const handleForwardTab = () => {
    if (document.activeElement === lastFocusable.current) {
      e.preventDefault();
      firstFocusable.current && firstFocusable.current.focus();
      document.activeElement.classList.add('non-keyboard-outline');
    }
  };
  switch (e.keyCode) {
    case KEY_TAB: {
      if (focusableList.current.length === 1) {
        e.preventDefault();
        break;
      }
      if (e.shiftKey) {
        handleBackwardTab();
      } else {
        handleForwardTab();
      }
      break;
    }
    case KEY_ESC:
      {
        toggleAria(false);
      }
      break;
    default:
      break;
  }
};

export const toggleAria = (status) => {
  const pageDocument = document.querySelector('div .page-wrap');
  if (status) {
    pageDocument &&
      pageDocument.classList &&
      pageDocument.classList.contains('page-wrap') &&
      pageDocument.setAttribute('aria-hidden', 'true');
  } else {
    pageDocument &&
      pageDocument.classList &&
      pageDocument.classList.contains('page-wrap') &&
      pageDocument.removeAttribute('aria-hidden');
  }
};
