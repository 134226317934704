/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';

const RelatedKeywords = ({ t, tags }) => {
  return (
    <div className="home-article-detail__tags ">
      <div className="home-article-detail__tags-container grid-6">
        <div className="player-tags">
          <h2 className="player-title">{t('RelatedKeywords')}</h2>
          <ul className="associated-tag">
            {tags.map((item, index) => (
              <li key={index}>
                <a href={`/en/search?q=${item.DisplayName || item.Name}`} tabIndex="0">
                  {item.DisplayName || item.Name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(RelatedKeywords);
