/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect } from 'react';
import MU from '../../constants/muConstants';
import {
  afterSubmit,
  loadScreenSet,
  onScreenLoad,
  errorHandler,
  getProviderList,
  redirectToDestination,
} from '../../utils/gigyaScreen';
import { useSponsorValue } from '../../context/sponsorContext';
import { redirectToIDMLogin } from '../../utils/idmUtils';

const SignIn = () => {
  let context = { containerID: 'registerDiv' };
  let providerList = getProviderList(MU && MU.countryCode);
  const [{ isGigyaAvilable, isUserLogin }] = useSponsorValue();

  useEffect(() => {
    if (isGigyaAvilable) {
      signInHandler();
    }
  }, [isGigyaAvilable, isUserLogin]);

  const signInHandler = () => {
    if (!isUserLogin) {
      if (!MU.isIDMEnabled) {
        typeof window !== 'undefined' &&
          window.gigya &&
          window.gigya.accounts.showScreenSet({
            screenSet: 'MufcWeb-RegistrationLogin',
            enabledProviders: providerList,
            onAfterSubmit: afterSubmit,
            onAfterScreenLoad: loadScreenSet,
            onBeforeScreenLoad: onScreenLoad,
            context: context,
            onError: errorHandler,
            containerID: 'registerDiv',
          });
      } else {
        redirectToIDMLogin();
      }
    } else {
      redirectToDestination();
    }
  };
  return (
    <div className="main_container gigya-ui-bg">
      <div id="registerDiv"></div>
    </div>
  );
};

export default SignIn;
