/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { getLiveBlogData, getSportsData, jsonStringify } from '../../utils/structureData';
const MatchcenterData = (props) => {
  const { sportsEventStructureData, liveBlogPostingStructureData } = props.structureData;
  return (
    <Helmet>
      {sportsEventStructureData && (
        <script type="application/ld+json">{`${jsonStringify(
          getSportsData(sportsEventStructureData)
        )}`}</script>
      )}
      {liveBlogPostingStructureData && (
        <script type="application/ld+json">{`${jsonStringify(
          getLiveBlogData(liveBlogPostingStructureData)
        )}`}</script>
      )}
    </Helmet>
  );
};
export default MatchcenterData;
