/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import SignPostHeroContainer from '../SignPostHeroContainer';

const SignPostGrid = (props) => <SignPostHeroContainer {...props} />;

export default SignPostGrid;
