/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { ArticleDetailContentRange } from '../../../constants/articleDetailConstants';
import AMPDfp from './AMPDfp';
import AMPCard from './AMPCard';
import AMPVideo from './AMPVideo';
import AMPImage from './AMPImage';
import AMPTextbox from './AMPTextbox';

const AMPCenterContent = ({ fields, isDFPScript }) => {
  const { manutds } = fields;
  return (
    <React.Fragment>
      {ArticleDetailContentRange.map((item, index) => {
        const textBoxKey = `TextBox${item}`;
        const videoKey = `Video${item}`;
        const imageKey = `Image${item}`;
        const captionKey = `Caption${item}`;
        const selectCardKey = `SelectCard${item}`;
        return (
          <React.Fragment key={index}>
            {/* --- Content Panel Start -----*/}
            {fields &&
              fields[textBoxKey] &&
              fields[textBoxKey].value &&
              fields[textBoxKey].value.length > 0 && (
                <AMPTextbox value={fields[textBoxKey] && fields[textBoxKey].value} />
              )}
            {/* - Content Panel End -----*/}
            {/* - Image Panel start ----*/}
            {!isEmpty(fields && fields[imageKey] && fields[imageKey].value) &&
              isEmpty(fields && fields[videoKey] && fields[videoKey].value) && (
                <AMPImage
                  image={
                    fields[imageKey] &&
                    fields[imageKey].value &&
                    fields[imageKey].value.Crops &&
                    fields[imageKey] &&
                    fields[imageKey].value &&
                    fields[imageKey].value.Crops.medium
                  }
                  altText={
                    fields[imageKey] && fields[imageKey].value && fields[imageKey].value.AltText
                  }
                  caption={fields[captionKey] && fields[captionKey].value}
                />
              )}
            {/* -- Image Panel Ends ----*/}
            {/* --- video panel---------*/}
            {!isEmpty(fields && fields[videoKey] && fields[videoKey].value) && (
              <AMPVideo
                image={
                  fields[imageKey] &&
                  fields[imageKey].value &&
                  fields[imageKey].value.Crops &&
                  fields[imageKey] &&
                  fields[imageKey].value &&
                  fields[imageKey].value.Crops.medium
                }
                altText={
                  fields[imageKey] && fields[imageKey].value && fields[imageKey].value.AltText
                }
                mediaId={
                  fields &&
                  fields[videoKey] &&
                  fields[videoKey].value &&
                  fields[videoKey].value.MediaId
                }
                caption={fields[captionKey] && fields[captionKey].value}
              />
            )}
            {/* --- video panel---------*/}
            {/* -selectcard rendering---*/}
            {fields &&
              fields[selectCardKey] &&
              fields[selectCardKey].map((item, index) => {
                return <AMPCard key={`SelectCard${Math.random()}`} fields={item && item.fields} />;
              })}
            {item === 2 && isDFPScript && (
              <AMPDfp
                fields={fields}
                width="300"
                height="250"
                slot="/11820346/Hercules_App_970x250//728x90//300x250"
                className="square"
              />
            )}
            {/*----- selectcard rendering ends ----*/}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default withTranslation()(AMPCenterContent);
