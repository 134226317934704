/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import React, { useState, useEffect } from 'react';
import { DFP } from '../Common/Dfp';
const DFPAd = (props) => {
  const { rendering, panelClassName } = props;
  const itemid = rendering && rendering.dataSource;
  const { Browser, Desktop, Tablet, Mobile, Slot, manutds } = props.fields;
  const { pageEditing } = props.sitecoreContext;
  const [data, setData] = useState({
    slot: null,
    size: null,
    browserSize: null,
    manutds: manutds,
    itemid: itemid,
    loaded: false,
  });
  useEffect(() => {
    setData((data) => ({
      ...data,
      slot: getSlot(),
      size: getSize(),
      browserSize: getBrowserSize(),
      loaded: true,
    }));
  }, []);
  const getBrowserSize = () => {
    try {
      return Browser && Browser.value && JSON.parse(Browser.value);
    } catch (e) {
      //console.info('Browser Size JSON Parse Error', e);
    }
  };
  const getSlot = () => {
    try {
      return Slot && Slot.value;
    } catch (e) {
      //console.info('DFP Slot Error', e);
    }
  };
  const getSize = () => {
    let size = [];
    try {
      let desktop,
        tablet,
        mobile = undefined;
      desktop = Desktop && Desktop.value && JSON.parse(Desktop.value);
      tablet = Tablet && Tablet.value && JSON.parse(Tablet.value);
      mobile = Mobile && Mobile.value && JSON.parse(Mobile.value);
      desktop && size.push(desktop);
      tablet && size.push(tablet);
      mobile && size.push(mobile);
    } catch (e) {
      //console.info('[Desktop, Tablet, Mobile] JSON Parse Error', e);
    }
    return size;
  };
  return (
    <React.Fragment>
      {data.loaded && (
        <div className={`wide-add-panel ${panelClassName}`} aria-hidden="true">
          {(isExperienceEditorActive() || pageEditing) && (
            <div style={{ textAlign: 'left' }}>
              <b>
                DFP Component
                <br />
              </b>
            </div>
          )}
          <DFP
            slot={data.slot}
            browserSize={data.browserSize}
            size={data.size}
            tag={data.manutds}
            itemid={data.itemid}
          />
        </div>
      )}
    </React.Fragment>
  );
};

DFPAd.defaultProps = {
  panelClassName: 'ad-block',
};
export default withSitecoreContext()(DFPAd);
