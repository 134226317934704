/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_SEARCH_RESULT,
  SET_SEARCH_RESULT,
  ERROR_SEARCH_RESULT,
} from '../constants/actions/actionConstant';
import { contentFilter, GetPager, itemsPerPage, range } from '../utils/searchUtils';
import * as track from '../utils/analytics';

export const searchState = {
  status: null,
  responseTime: null,
  filterKeyData: null,
  ImageSet: [],
  RelatedSearch: null,
  relatedKeyword: null,
  pagerFlag: true,
  resultsCount: null,
  pagerFromReducer: {},
  results: null,
  noResulData: null,
  startListIndex: 0,
  playerProfileList: [],
};

/**
 * Reducer for Search
 */
export const searchReducer = (
  state = searchState,
  { type, response, startTime, searchText } = {}
) => {
  switch (type) {
    case FETCH_SEARCH_RESULT:
      return {
        ...state,
        ...{ ImageSet: [], results: null },
        status: 'fetching',
      };
    case SET_SEARCH_RESULT: {
      return {
        ...state,
        status: 'fetched',
        ...setData(response && response.data, state, startTime, searchText),
      };
    }
    case ERROR_SEARCH_RESULT: {
      return {
        ...state,
        status: 'error',
      };
    }
    default:
      return state;
  }
};

const setData = (data, state, startTime, searchText) => {
  let newState = {};
  if (data) {
    newState.responseTime = ((new Date().getTime() - startTime) / 1000).toFixed(2);
    newState.filterKeyData =
      data.hasOwnProperty('PageFilterResponse') && setFilterResponse(data.PageFilterResponse);
    newState.ImageSet =
      data.hasOwnProperty('ImagesResponse') && setImageResponse(data.ImagesResponse);
    newState.RelatedSearch =
      data.hasOwnProperty('RelatedSearchResponse') &&
      setRelatedSearchResponse(data.RelatedSearchResponse);
    newState.playerProfileList =
      data.hasOwnProperty('PlayerProfileResponse') &&
      setPlayerProfileResponse(data.PlayerProfileResponse);
    newState = {
      ...newState,
      ...(data.hasOwnProperty('SearchContentResponse') &&
        setSearchContentResponse(data.SearchContentResponse, state, searchText)),
      ...(data.hasOwnProperty('NoContentResponse') &&
        setNoContentResponse(data.NoContentResponse, state, searchText)),
    };
  }
  return newState;
};

const setFilterResponse = (data) => {
  try {
    if (data.hasOwnProperty('response')) {
      if (data.response.hasOwnProperty('docs') && data.response.docs.length) {
        const docsObj = data.response.docs;
        let filterTabObj = [];
        for (let i = 0; i < docsObj.length; i++) {
          if (docsObj[i].hasOwnProperty('label_t') && docsObj[i].hasOwnProperty('pagefilter_sm')) {
            filterTabObj.push({
              key: docsObj[i].label_t,
              value: docsObj[i].pagefilter_sm[0],
              __sortorder_t: docsObj[i].__sortorder_t,
              destinationurl_s: docsObj[i].destinationurl_s,
            });
          }
        }
        return filterTabObj.sort((a, b) => {
          return parseFloat(a.__sortorder_t) - parseFloat(b.__sortorder_t);
        });
      }
    }
  } catch (e) {
    console.log('error in assignation of filter response');
  }
};

const setImageResponse = (data) => {
  try {
    if (data.hasOwnProperty('response')) {
      if (data.response.hasOwnProperty('docs') && data.response.docs.length > 0) {
        return data.response.docs;
      }
    }
  } catch (e) {
    console.log('error in assignation of Image response');
  }
};

const setRelatedSearchResponse = (data) => {
  try {
    if (data.hasOwnProperty('response')) {
      if (data.response.hasOwnProperty('docs') && data.response.docs.length > 0) {
        return data.response.docs;
      }
    }
  } catch (e) {
    console.log('error in assignation of related search response');
  }
};

const setPlayerProfileResponse = (data) => {
  try {
    if (data.hasOwnProperty('response')) {
      if (data.response.hasOwnProperty('docs') && data.response.docs.length > 0) {
        return data.response.docs;
      }
    }
  } catch (e) {
    console.log('error in assignation of player profile response');
  }
};

const setNoContentResponse = (data, state, searchText) => {
  try {
    let newState = {};
    let pagerFromReducer = {};
    newState.relatedKeyword = [];
    newState.RelatedSearch = [];
    pagerFromReducer.currentPage = 1;
    pagerFromReducer.pages = [];
    newState.pagerFromReducer = pagerFromReducer;
    newState.results = [];
    newState.ImageSet = [];
    if (data && data.hasOwnProperty('response')) {
      if (data.response.hasOwnProperty('docs') && data.response.docs.length > 0) {
        newState.resultsCount = 0;
        newState.pagerFromReducer.currentPage = 1;
        newState.noResulData =
          data.response.docs[0].description_t && data.response.docs[0].description_t.split(',');
        trackCard(searchText, 0);
      }
    }
    return newState;
  } catch (e) {
    console.log('error in assignation of no content response');
  }
};
const setSearchContentResponse = (data, state, searchText) => {
  try {
    let newState = {};
    let numFound = 0;
    if (data.hasOwnProperty('response')) {
      if (data.response.hasOwnProperty('docs') && data.response.docs.length > 0) {
        newState.startListIndex = data.response.start;
        newState.resultsCount = data.response.numFound;
        numFound = data.response.numFound;
        newState.results = removeNodes(data.response.docs);
        if (data.response.start == 0) {
          trackCard(searchText, data.response.numFound);
          newState.pagerFromReducer = pagination(numFound, state);
        }
      } else {
        let pagerFromReducer = {};
        pagerFromReducer.pages = [];
        newState.pagerFromReducer = pagerFromReducer;
      }
    }

    if (data.hasOwnProperty('facet_counts')) {
      if (data.facet_counts.hasOwnProperty('facet_fields')) {
        if (
          data.facet_counts.facet_fields.hasOwnProperty('relatedkeywords_sm') &&
          data.facet_counts.facet_fields.relatedkeywords_sm.length > 0
        )
          var relatedKey = data.facet_counts.facet_fields.relatedkeywordsnew_sm;
        let relatedKeyword = [];
        for (let key in relatedKey) {
          if (isNaN(relatedKey[key])) {
            if (
              typeof relatedKey[key].split != undefined &&
              typeof relatedKey[key].split != 'undefined'
            ) {
              let relKey = relatedKey[key].split(':');
              let relatedKeyObj = {};
              relatedKeyObj.key = relKey[0].replace(/^\s+|\s+$/gm, '');
              relatedKeyword.push(relatedKeyObj);
            }
          }
        }
        newState.relatedKeyword = relatedKeyword;
      }
    }
    return newState;
  } catch (e) {
    console.log('error in assignation of searchcontent response');
  }
};

const removeNodes = (data) => {
  const supportedContentTypes = updateContentFilter();
  let newArr = [];
  if (data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      let nodeElm = data[i];
      let contentType = nodeElm.contenttype_t;
      if (contentType) {
        contentType = contentType.toLowerCase();
      }
      if (supportedContentTypes.indexOf(contentType) != -1) {
        newArr.push(data[i]);
      }
    }
  }
  return newArr;
};

const updateContentFilter = () => {
  try {
    let filterVal = decodeURIComponent(window.location.href.split('=')[2]);
    let contentFilters = contentFilter(filterVal);
    return contentFilters;
  } catch (e) {
    console.log('error in updateContentFilter in reducer');
  }
};

const pagination = (resultsCount, state) => {
  state.pagerFlag = false;
  let totalItemsArr = range(1, resultsCount);
  const page = 1;
  return GetPager(totalItemsArr.length, page, itemsPerPage);
};

const trackCard = (search, resultscount, e) => {
  const initialData = track.data('search');
  track.analytics({
    ...initialData,
    search_term: search,
    search_result: resultscount,
  });
};
