/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { textTruncate } from '../../utils/searchUtils';
import { DFP } from '../Common/Dfp';
import * as track from '../../utils/analytics';

const SidebarItem = (props) => {
  const { RelatedSearch, relatedKeyword, t, onSearch } = props;
  const [showAd, setShowAd] = useState(false);
  useEffect(() => {
    // Show ad only on client side otherwise behaves abnormally due to react hydration
    setShowAd(true);
  }, []);
  const trackCard = (name, e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: name,
        destination_url: `${
          window && window.location && window.location.origin
        }/javascript:void(0);`,
      },
      e
    );
  };
  const fetchData = (val, e) => {
    onSearch(val);
    trackCard(val, e);
  };
  return (
    <div className="sidebar popular-items">
      <div className="search-related">
        {RelatedSearch && RelatedSearch.length > 0 && (
          <React.Fragment>
            <h2>{t('RelatedSearches')}</h2>
            <ul className="related-tag">
              {RelatedSearch.slice(0, 4).map((related, i) => (
                <li key={`relatedSearch${i}`}>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      fetchData(related.suggestText, e);
                    }}
                    title={related.suggestText}
                    alt={related.suggestText}
                    className="link"
                    tabIndex="0"
                  >
                    <span>{textTruncate(related.suggestText, 3)}</span>
                  </a>
                </li>
              ))}
            </ul>
          </React.Fragment>
        )}
      </div>
      <div className="search-related last">
        {relatedKeyword && relatedKeyword.length > 0 && (
          <React.Fragment>
            <h2>{t('RelatedKeywords')}</h2>
            <ul className="related-tag">
              {relatedKeyword.slice(0, 5).map((relatedKey, key) => (
                <li key={`relatedKeyword${key}`}>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      fetchData(relatedKey.key, e);
                    }}
                    className="link"
                  >
                    <span>{textTruncate(relatedKey.key, 3)}</span>
                  </a>
                </li>
              ))}
            </ul>
          </React.Fragment>
        )}
      </div>
      <div className="grid-xl ad-block" aria-hidden="true" tabIndex="-1">
        {showAd && <DFP slot="/11820346/Hercules_ROS_300x250" size={[[300, 250]]} />}
      </div>
    </div>
  );
};

export default withTranslation()(SidebarItem);
