/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { formSerialize } from '../../../utils/FormUtils';
import {
  travelInitialStateStep1,
  errorMessageGroup,
  initialValue,
} from '../../../constants/formConstants';
import { useMuTravelValue } from '../../../context/muTravelContext';
import { fetchVenuTravelReport, fetchMuTravelReport } from '../../../actions/muTravelAction';
import Loader from '../../Common/Loader';

const StepOne = ({ currentMatchId, Text, setAutoFill, setFormShow, globalMsg }) => {
  const [
    { venuTravelFormData, venuTravelFormStatus, muTravelFormStatus, muTravelFormData },
    dispatch,
  ] = useMuTravelValue();
  const [errorMsg, setErrorMsg] = useState({});
  const [convicted, setConvicted] = useState(initialValue);

  useEffect(() => {
    venuTravelFormStatus === 'fetched' &&
      venuTravelFormData &&
      venuTravelReports(venuTravelFormData);
  }, [venuTravelFormStatus]);

  useEffect(() => {
    muTravelFormStatus === 'fetched' && muTravelFormData && muTravelReports(muTravelFormData);
  }, [muTravelFormStatus]);

  const validateForm = () => {
    const formData = formSerialize('#formStepOne');
    const formErrors = {};
    let formIsValid = true;
    let isConvicted = false;
    if (formData) {
      travelInitialStateStep1 &&
        travelInitialStateStep1.length > 0 &&
        travelInitialStateStep1.forEach((item, index) => {
          if (!formData[item.name] && item.isRequired) {
            formErrors[item.name] = errorMessageGroup && errorMessageGroup[item.name];
            if (item.type === 'radio') {
              isConvicted = false;
            }
            formIsValid = false;
          } else {
            formErrors[item.name] = '';
            if (item.name === 'convictedOffences' && formData[item.name] === 'yes') {
              isConvicted = true;
              formIsValid = false;
            } else {
              isConvicted = false;
              formErrors[item.name] = '';
            }
          }
        });
      setErrorMsg({
        ...errorMsg,
        errors: { ...errorMsg.errors, ...formErrors },
      });
      setConvicted({
        ...convicted,
        isConvicted: isConvicted,
        isWrongInfo: false,
        isReEnter: false,
      });
      return formIsValid;
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formData = formSerialize('#formStepOne');
      const membership = formData['membership'];
      const surname = formData['surname'];
      fetchVenuTravelReport(dispatch, membership, currentMatchId, surname);
    }
  };

  const venuTravelReports = (response) => {
    if (response && response.errorCode === 0 && response.totalCount === 1) {
      const responseData = response && response.results[0] && response.results[0].data;
      const travelResponse = responseData && responseData.TravelReport[currentMatchId];
      if (travelResponse) {
        const dob = new Date(responseData.TravelReport[currentMatchId].DOB.replace(/-/g, '/'));
        const mufc = responseData.TravelReport[currentMatchId].MUFC;
        const lastName = responseData.TravelReport[currentMatchId].Surname;
        setAutoFill({
          mufcNumber: responseData.TravelReport[currentMatchId].MUFC,
          lastName: responseData.TravelReport[currentMatchId].Surname,
          dobDate: dob.getDate(),
          dobYear: dob.getFullYear(),
          dobMonth: dob.getMonth() + 1,
          matchid: responseData.TravelReport[currentMatchId].MatchID,
          postcode: responseData.TravelReport[currentMatchId].Postcode,
        });
        fetchMuTravelReport(dispatch, mufc, currentMatchId, lastName);
      }
    } else {
      setConvicted({ ...convicted, isWrongInfo: true });
    }
  };

  const muTravelReports = (response) => {
    if (response && response.objectsCount === 0) {
      setFormShow({ showform: 'step2' });
    } else {
      setConvicted({ ...convicted, isReEnter: true });
    }
  };

  return (
    <div className="step__one clearfix">
      <div className="step__label">STEP 1</div>
      <div className="travel__container">
        <form
          className="travel__step__one grid-12"
          id="formStepOne"
          onSubmit={(e) => submitForm(e)}
        >
          <div className="grid-6">
            <input
              type="text"
              className="inputStyle"
              placeholder="Membership #"
              name="membership"
              aria-label="Membership Number"
              aria-required="true"
            />

            {errorMsg.errors && errorMsg.errors.membership && (
              <span className="errorMessage" role="alert">
                {errorMsg.errors.membership}
              </span>
            )}
          </div>
          <div className="grid-6">
            <input
              type="text"
              className="inputStyle"
              placeholder="Surname (Please enter first letter in capitals)"
              name="surname"
              aria-label="Surname"
              aria-required="true"
            />
            {errorMsg.errors && errorMsg.errors.surname && (
              <span className="errorMessage" role="alert">
                {errorMsg.errors.surname}
              </span>
            )}
          </div>
          <div className="grid-12 convVal">
            <fieldset>
              <legend className="have-convicted">
                Have you been convicted of any relevant offences?
              </legend>
              <input
                type="radio"
                name="convictedOffences"
                data-check="yes"
                value="yes"
                id="y-option"
              />
              <label className="control control--radio" htmlFor="y-option">
                Yes
                <div className="control__indicator" />
              </label>
              <input
                type="radio"
                name="convictedOffences"
                data-check="no"
                id="n-option"
                value="no"
              />
              <label className="control control--radio" htmlFor="n-option">
                No
                <div className="control__indicator" />
              </label>
            </fieldset>

            {errorMsg.errors && errorMsg.errors.convictedOffences && (
              <div id="selectanyone" role="alert">
                {!convicted.isConvicted && errorMsg.errors.convictedOffences}
              </div>
            )}
            {convicted.isConvicted && (
              <RichText field={globalMsg && globalMsg.convicted_Error_Message} />
            )}
          </div>

          {convicted.isWrongInfo && (
            <RichText
              className="wrong-info"
              field={globalMsg && globalMsg.wrong_Info_Error_Message}
            />
          )}
          {convicted.isReEnter && (
            <RichText
              className="reenter-data-error"
              field={globalMsg && globalMsg.reEnter_Error_Message}
            />
          )}

          {/* 
          <RichText
            className="connectivity-error"
            field={globalMsg && globalMsg.connectivity_Error_Message}
          /> */}
          <div className="red-btn-skew">
            <input type="submit" className="button submit_btn" value="Submit" />
          </div>
        </form>
        <div className="description">
          <RichText className="contentDescription" field={Text} />
        </div>
      </div>
      {venuTravelFormStatus === 'fetching' && <Loader></Loader>}
    </div>
  );
};

export default withTranslation()(React.memo(StepOne));
