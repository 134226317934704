/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { Fragment } from 'react';
import * as track from '../../utils/analytics';

const PlayerHubIcon = (props) => {
  const { IsEnablePlayerHub, CTAUrlPlayerHub, CTATitlePlayerHub } = props;

  const trackCard = (e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: CTATitlePlayerHub.value,
        destination_url: CTAUrlPlayerHub.value.href,
      },
      e
    );
  };
  const getStyle = () => {
    return { display: 'block', height: 'inherit' };
  };

  return (
    <Fragment>
      {IsEnablePlayerHub.value && CTAUrlPlayerHub.value && CTAUrlPlayerHub.value.href && (
        <div>
          <div className="black-btn-skew btn playerHub-btn">
            <a
              href={CTAUrlPlayerHub.value.href}
              target={
                CTAUrlPlayerHub &&
                CTAUrlPlayerHub.value &&
                CTAUrlPlayerHub.value.linktype &&
                CTAUrlPlayerHub.value.linktype === 'external'
                  ? '_blank'
                  : ''
              }
              role="link"
              tabIndex="0"
              aria-label={CTATitlePlayerHub && CTATitlePlayerHub.value}
              className="iconDisplayFlex"
              style={{ ...getStyle() }}
              onClick={(e) => trackCard(e)}
            >
              <span className="icon-hub boxsetIcon"></span>
              {CTATitlePlayerHub && CTATitlePlayerHub.value && (
                <span className="playerhub-name">{CTATitlePlayerHub.value}</span>
              )}
            </a>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default PlayerHubIcon;
