/**
 *  © 2022 -2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import { SAVE_COMPETITION } from '../constants/endpoints';
import api from './muApi';
import MU from '../constants/muConstants';
import * as track from './analytics';
import { isApp } from '../utils/utils';

// API get sync
export const fetchAPIGetItem = (route) => {
  return api.get({ route });
};

// Stored locally Competation data
export const storeLocallyUserCompetitionData = (competitionID, userId) => {
  let userFound = false;
  let CompetitionFound = false;
  const userCompetitionStore = localStorage.getItem('userCompetitions');
  if (
    typeof Storage !== 'undefined' &&
    (!userCompetitionStore || userCompetitionStore === 'undefined')
  ) {
    let competitions = [];
    competitions.push(generateUserCompetitionData(competitionID));
    localStorage.setItem('userCompetitions', JSON.stringify([{ userId: userId, competitions }]));
  } else {
    const userCompetitionObj =
      userCompetitionStore &&
      userCompetitionStore !== 'undefined' &&
      typeof userCompetitionStore === 'string'
        ? JSON.parse(userCompetitionStore)
        : userCompetitionStore;
    userCompetitionObj.forEach((userCompetitionData) => {
      if (userCompetitionData.userId === userId) {
        userFound = true;
        userCompetitionData.competitions.forEach(function (competition, index) {
          if (competitionID && competitionID === competition.competitionId) {
            CompetitionFound = true;
            userCompetitionData.competitions[index] = generateUserCompetitionData(competitionID);
          }
        });
        if (!CompetitionFound) {
          userCompetitionData.competitions.push(generateUserCompetitionData(competitionID));
        }
      }
    });
    if (!userFound) {
      let competitions = [];
      competitions.push(generateUserCompetitionData(competitionID));
      userCompetitionObj.push({ userId: userId, competitions });
    }
    localStorage.setItem('userCompetitions', JSON.stringify(userCompetitionObj));
  }
};

const generateUserCompetitionData = (competitionID) => {
  return { competitionId: competitionID };
};
const isJsonObject = (data) => {
  try {
    JSON.parse(data);
  } catch (e) {
    return false;
  }
  return true;
};

export const getDrawerImage = (drawerimage) => {
  let checkJsonObj = isJsonObject(drawerimage);
  let Image;
  if (checkJsonObj) {
    Image = drawerimage && JSON.parse(drawerimage);
  } else {
    Image = drawerimage;
  }
  return Image;
};
const getRequestBody = (userId, userLoginInfo, competitionID, EndDate, allListData) => {
  const requestBody = {
    compInfo: {
      UidGigya: userId && userId,
      ...allListData,
      UserRegion: MU && MU.CountryCode ? MU.CountryCode.toLowerCase() : '',
      Client: isApp() ? 'App' : 'Web',
      Source: 'Site',
      CampaignID: competitionID && competitionID,
      FirstName: userLoginInfo && userLoginInfo.FirstName ? userLoginInfo.FirstName : '',
      LastName: userLoginInfo && userLoginInfo.LastName ? userLoginInfo.LastName : '',
      Email: userLoginInfo && userLoginInfo.Email ? userLoginInfo.Email : '',
      MarketingConsent:
        userLoginInfo && userLoginInfo.MarketingConsent ? userLoginInfo.MarketingConsent : '',
      CampaignEnddateTime: EndDate && EndDate.value ? EndDate.value : '',
    },
  };

  return requestBody;
};

// Analytics
const getAnalyticsData = (competitionID, userId, Headline, loginStatus) => {
  const initialData = track.data('unitedcompetitionSuccess');
  return {
    ...initialData,
    competition_name: Headline,
    item_id: competitionID,
    login_id: userId,
    subscription_status: loginStatus,
  };
};

const getAnalyticsError = (competitionID, userId, Headline, loginStatus, err) => {
  const initialData = track.data('unitedcompetitionError');
  return {
    ...initialData,
    error_message: err,
    error_type: 'competitionError',
    item_id: competitionID,
    login_id: userId,
    subscription_status: loginStatus,
  };
};

const trackCompetitionSuccess = (competitionID, userId, Headline, loginStatus) => {
  track.analytics(getAnalyticsData(competitionID, userId, Headline, loginStatus));
};

// const trackCompetitionError = (competitionID, userId, Headline, loginStatus, err) => {
//   track.analytics(getAnalyticsError(competitionID, userId, Headline, loginStatus, err));
// };

// fucntion to integrate united competition in app
const setCDHelpData = (entryState, destIsForm, NocelebrationConfetti) => {
  if (NocelebrationConfetti && !NocelebrationConfetti.value && window.MUhelp) {
    // Android code
    destIsForm ? window.MUhelp.updateCompForm(entryState) : window.MUhelp.updateComp(entryState);
  } else if (
    NocelebrationConfetti &&
    !NocelebrationConfetti.value &&
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.postHelp
  ) {
    try {
      if (entryState && destIsForm) {
        window.webkit.messageHandlers.postHelp.postMessage('ef');
      } else if (entryState && !destIsForm) {
        window.webkit.messageHandlers.postHelp.postMessage('cd');
      } else {
        window.webkit.messageHandlers.postHelp.postMessage('er');
      }
    } catch (err) {
      console.log('setCDHelpData iOS error');
    }
  } else {
    console.log('setCDHelpData error');
  }
};

export const retryCompetitionFormSubmission = (
  userId,
  userLoginInfo,
  competitionID,
  EndDate,
  setEntryConfirm,
  setEntryError,
  setShowPopUp,
  setShowLoader,
  allListData,
  Headline,
  loginStatus
) => {
  retryCompetitionSubmission(
    userId,
    userLoginInfo,
    competitionID,
    EndDate,
    setEntryConfirm,
    setEntryError,
    setShowPopUp,
    setShowLoader,
    allListData,
    Headline,
    loginStatus
  );
};

// Retry API Post sync
const retryCompetitionSubmission = (
  userId,
  userLoginInfo,
  competitionID,
  EndDate,
  setEntryConfirm,
  setEntryError,
  setShowPopUp,
  setShowLoader,
  allListData,
  Headline,
  loginStatus,
  setFormSuccessMsg,
  destIsForm,
  NocelebrationConfetti
) => {
  if (userId) {
    setTimeout(() => {
      api
        .post({
          route: SAVE_COMPETITION,
          baseURL: null,
          params: null,
          data: getRequestBody(userId, userLoginInfo, competitionID, EndDate, allListData),
        })
        .then(() => {
          setShowLoader(false);
          setEntryError(false);
          setEntryConfirm(true);
          setCDHelpData(true, destIsForm, NocelebrationConfetti);
          if (setFormSuccessMsg) {
            setFormSuccessMsg(true);
          }
          storeLocallyUserCompetitionData(competitionID, userId);
          trackCompetitionSuccess(competitionID, userId, Headline, loginStatus);
        })
        .catch((err) => {
          setShowLoader(false);
          console.log('err', err);
          setEntryError(true);
          setCDHelpData(false, destIsForm, NocelebrationConfetti);
          //trackCompetitionError(competitionID, userId, Headline, loginStatus, err);
          if (setShowPopUp) {
            setShowPopUp(false);
          }
          document.querySelector('body').classList.remove('no-scroll');
        });
    }, 5000);
  }
};

// API Post sync
export const saveCompetitionResponse = (
  userId,
  userLoginInfo,
  competitionID,
  EndDate,
  setEntryConfirm,
  setEntryError,
  setShowPopUp,
  setShowLoader,
  allListData,
  Headline,
  loginStatus,
  setFormSuccessMsg,
  destIsForm,
  NocelebrationConfetti
) => {
  if (userId) {
    api
      .post({
        route: SAVE_COMPETITION,
        baseURL: null,
        params: null,
        data: getRequestBody(userId, userLoginInfo, competitionID, EndDate, allListData),
      })
      .then(() => {
        setShowLoader(false);
        setEntryError(false);
        setEntryConfirm(true);
        setCDHelpData(true, destIsForm, NocelebrationConfetti);
        if (setFormSuccessMsg) {
          setFormSuccessMsg(true);
        }
        storeLocallyUserCompetitionData(competitionID, userId);
        trackCompetitionSuccess(competitionID, userId, Headline, loginStatus);
      })
      .catch((err) => {
        console.log('err', err);
        //trackCompetitionError(competitionID, userId, Headline, loginStatus, err);
        retryCompetitionSubmission(
          userId,
          userLoginInfo,
          competitionID,
          EndDate,
          setEntryConfirm,
          setEntryError,
          setShowPopUp,
          setShowLoader,
          allListData,
          Headline,
          loginStatus,
          setFormSuccessMsg,
          destIsForm,
          NocelebrationConfetti
        );
      });
  } else {
    setShowLoader(false);
    setEntryError(true);
    //trackCompetitionError(competitionID, userId, Headline, loginStatus, 'User ID not found.');
    setShowPopUp(false);
    setCDHelpData(false, destIsForm, NocelebrationConfetti);
    document.querySelector('body').classList.remove('no-scroll');
  }
};
