/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import { isLiveByPeriod } from '../../utils/utils';
import { formatDate } from '../../utils/momentDate';
const LeftPanel = ({ item, t }) => {
  const matchMinutes = (min, period, minTextFlag) => {
    try {
      if (min && period) {
        let matchMin;
        let minsText = '';
        if (minTextFlag) {
          minsText = ' mins';
        }
        if (period) {
          period = period.toLowerCase();
          if (period === 'halftime' || period === 'half time') {
            matchMin = t('HT');
            return matchMin;
          } else if (
            period === 'fulltime' ||
            period === 'fulltime90' ||
            period === 'full time 90' ||
            period === 'full time'
          ) {
            matchMin = t('FT');
            return matchMin;
          } else if (period === 'extrahalftime' || period === 'extra half time') {
            matchMin = t('HT') + ' <span class="extra__time">ET</span>';
            return matchMin;
          } else if (period === 'extrafulltime' || period === 'extra full time') {
            matchMin = t('FT') + ' <span class="extra__time">' + t('AET') + '</span>';
            return matchMin;
          } else if (period === 'fulltimeaet' || period === 'full time aet') {
            matchMin = t('FT') + ' <span class="extra__time">' + t('AET') + '</span>';
            return matchMin;
          } else if (period === 'shootout') {
            matchMin = t('FT') + ' <span class="extra__time">' + t('AET') + '</span>';
            return matchMin;
          } else if (period === 'extrahalftime') {
            matchMin = t('HT') + ' <span class="extra__time">ET</span>';
            return matchMin;
          }
        }

        if ((min > 45 && period == 'firsthalf') || period == 'first half') {
          matchMin = '45 +' + (min - 45) + minsText;
          return matchMin;
        } else if ((min > 90 && period == 'secondhalf') || period == 'second half') {
          matchMin = '90 +' + (min - 90) + minsText;
          return matchMin;
        } else if ((min > 105 && period == 'extrafirsthalf') || period == 'extra first half') {
          matchMin = '105 +' + (min - 105) + minsText;
          return matchMin;
        } else if ((min > 120 && period == 'extrasecondhalf') || period == 'extra second half') {
          matchMin = '120 +' + (min - 120) + minsText;
          return matchMin;
        } else if (
          period == 'unknown' ||
          period == 'un known' ||
          period == 'prematch' ||
          period == 'pre match'
        ) {
          matchMin = min + minsText;
          return matchMin;
        }
        if (min >= 0 && min < 10) {
          if (min.length > 1) {
            matchMin = min + ' min';
          } else {
            matchMin = '0' + min + ' min';
          }
        } else {
          matchMin = min + minsText;
        }

        return matchMin;
      }
    } catch (e) {
      console.log('Error in dictionary', e);
    }
  };
  return (
    <div className="stats-card__info__left">
      {(item.contenttype_t.toLowerCase() === 'seasonstats' ||
        item.contenttype_t.toLowerCase() === 'competitionstats') && (
        <span className="date-time">{item.seasonname_t}</span>
      )}
      {item.contenttype_t.toLowerCase() === 'matchdaystats' && (
        <div>
          {isLiveByPeriod(item.matchperiod_t) && !item.rightStats && (
            <div>
              <span
                className="date-time"
                aria-hidden="true"
                dangerouslySetInnerHTML={{
                  __html: matchMinutes(item.matchminutes_t, item.matchperiod_t, true),
                }}
              ></span>
              <span
                className="screenreader"
                dangerouslySetInnerHTML={{
                  __html: `Minutes of match ${matchMinutes(
                    item.matchminutes_t,
                    item.matchperiod_t,
                    true
                  )}`,
                }}
              ></span>
            </div>
          )}
          {item.rightStats && (
            <span className="date-time">{formatDate(item.matchdate_tdt, 'matches-page')}</span>
          )}
          {!isLiveByPeriod(item.matchperiod_t) && !item.rightStats && (
            <span className="date-time">{formatDate(item.matchdate_tdt, 'matches-page')}</span>
          )}
        </div>
      )}
      <div>
        <span className="type">{item.cardheading_t}</span>
      </div>
      <div>
        {(item.contenttype_t.toLowerCase() === 'seasonstats' ||
          item.contenttype_t.toLowerCase() === 'competitionstats') && (
          <span className="teams">{item.competitionname_t}</span>
        )}
      </div>
      {item.contenttype_t.toLowerCase() === 'matchdaystats' && (
        <span className="teams">{item.gamename_t}</span>
      )}
    </div>
  );
};

export default withTranslation()(LeftPanel);
