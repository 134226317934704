/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import LazyLoad from 'react-lazyload';
import {
  getStatsBarData,
  getSponsorData,
  createDestinationUrl,
  getItemId,
  isApp,
  isCollection,
  createTagArr,
  validateCbr,
  getLoggedInStatus,
} from '../../../utils/utils';
import ImgCrop from '../../Common/ImgCrop';
import Sponsor from '../../Common/Sponsor';
import { CONTENT_TYPE } from '../../../constants/globalConstants';
import StatsBar from '../../Common/StatsBar';
import * as track from '../../../utils/analytics';
import { withTranslation } from 'react-i18next';
import AccessBadge from '../../Common/AccessBadge';
import ErrorBoundary from '../../Common/ErrorBoundary';
import Loader from '../../Common/Loader';
import { useSponsorValue } from '../../../context/sponsorContext';
import { useModal } from '../../Common/Modal';
import loadable from '@loadable/component';
import MU from '../../../constants/muConstants';
const RegistrationModal = loadable(() =>
  import('../../Modals/RegistrationModal/RegistrationModal')
);

const Podcast = (props) => {
  const {
    fields,
    t,
    gridClass,
    isMatchcenterCard,
    heroVarient,
    container,
    showStatsbar,
    isRelative,
  } = props;
  const {
    Headline,
    EpisodeDescription,
    Variant,
    ContentType,
    BackgroundImage,
    Sponsorby,
    manutds,
    __Published,
    AuthorName,
    ContentAccess,
  } = props.fields;
  const { showModal, closeModal, isModalVisible } = useModal();
  const [{ isUserLogin, isLoginChecked, isUserSubscribe, isUserSkyId }] = useSponsorValue();

  const itemId = getItemId(props);
  const sponsorInfo = Sponsorby && getSponsorData(Sponsorby.fields);
  const getVariant = () => {
    if (Variant && Variant.value) {
      const a = Variant.value.toLowerCase();
      if (a === 'child' && gridClass && gridClass.includes('mini-card')) {
        return '';
      }
      return a;
    }
    return '';
  };

  const getAnalyticsData = (fields, contentStatus) => {
    const initialData = track.data('card');
    return {
      ...initialData,
      card_name: Headline && Headline.value,
      container_type: container,
      item_id: itemId,
      content_type: ContentType && ContentType.value,
      content_status: contentStatus,
      subscription_status: getLoggedInStatus({
        isLoginChecked,
        isUserLogin,
        isUserSubscribe,
        isUserSkyId,
      }),
      tag: manutds && manutds.value && manutds.value.Tags,
      created_datetime: __Published && __Published.value,
      author: AuthorName && AuthorName.value,
      destination_url: manutds && manutds.value && manutds.value.DestinationUrl,
    };
  };

  const trackCard = (fields, e, contentStatus) => {
    track.analytics(getAnalyticsData(fields, contentStatus), e);
  };

  const onCardClick = (e) => {
    const showCbr = getCbrValue();
    trackCard(fields, e, showCbr && showCbr.accessCheck);
    if (!isApp() && showCbr && showCbr.value) {
      e.preventDefault();
      showModal();
    }
  };
  const getCbrValue = () => {
    return validateCbr({
      isUserLogin,
      isUserSubscribe,
      contentAccess: ContentAccess && ContentAccess.value,
      contentType: ContentType && ContentType.value,
      manutds,
    });
  };
  const successCallback = ({ showContent, isSubscribedContent }) => {
    if (showContent) {
      const destinationUrl = manutds && manutds.value && manutds.value.DestinationUrl;
      if (!MU.isIDMEnabled) window.location = createDestinationUrl(destinationUrl);
      else {
        return ['successCallbck', isSubscribedContent, 'destinationUrl', destinationUrl];
      }
    }
  };
  const acessbadgesFunction = () => {
    return (
      <div className="top-badges">
        <AccessBadge
          contentAcessLocal={ContentAccess && ContentAccess.value}
          contentTypeLocal={ContentType && ContentType.value}
          tagListLocal={createTagArr(manutds)}
        />
      </div>
    );
  };

  const sponsorFunction = () => {
    return (
      <Sponsor
        contentType={ContentType && ContentType.value}
        varient={Variant && Variant.value && Variant.value.toLowerCase()}
        sponsorInfo={sponsorInfo}
        grid={gridClass}
        type={CONTENT_TYPE}
        author={AuthorName && AuthorName.value}
        cardName={Headline && Headline.value}
        container={container}
        isRelative={isRelative}
        createdDateTime={__Published && __Published.value}
        itemId={itemId}
        tags={manutds && manutds.value && manutds.value.Tags}
      ></Sponsor>
    );
  };

  const renderCBSModal = () => {
    const accessType = getCbrValue().accessCheck;
    return (
      <ErrorBoundary>
        <RegistrationModal
          successCallback={successCallback}
          closeModal={closeModal}
          fallback={<Loader />}
          previousActiveElement={document.activeElement}
          accessType={accessType}
          analyticsObj={getAnalyticsData(fields, accessType)}
        />
      </ErrorBoundary>
    );
  };

  return (
    <React.Fragment>
      {isModalVisible && renderCBSModal()}
      <div
        className={`${gridClass} ${isMatchcenterCard ? 'ratio-matchday' : 'ratio'} ${
          heroVarient ? heroVarient : getVariant()
        }`}
        onClick={(e) => onCardClick(e)}
        data-impression={track.impression({
          card_name: Headline && Headline.value,
          content_type: ContentType && ContentType.value,
        })}
      >
        <div className="mu-content">
          <div className="mu-item video podcast">
            <a
              href={createDestinationUrl(manutds.value.DestinationUrl)}
              tabIndex="-1"
              aria-hidden="true"
              target={isApp() && isCollection() ? '_blank' : ''}
            >
              <div className="img-holder">
                {BackgroundImage && BackgroundImage.value && BackgroundImage.value.Crops && (
                  <LazyLoad once={true} offset={100}>
                    <ImgCrop
                      imgCrop={BackgroundImage.value.Crops}
                      alt={BackgroundImage.value.AltText}
                      title={BackgroundImage.value.AltText}
                      isWebpAvailable={BackgroundImage.value.isWebpAvailable}
                      singleSrc={false}
                      gridClass={gridClass}
                    ></ImgCrop>
                  </LazyLoad>
                )}
              </div>
            </a>
            {acessbadgesFunction()}
            {sponsorFunction()}
            <div className="mu-item__info">
              <div className="podcast__play" tabIndex="" aria-hidden="true">
                <a
                  href={createDestinationUrl(manutds.value.DestinationUrl)}
                  tabIndex="-1"
                  aria-hidden="true"
                  target={isApp() && isCollection() ? '_blank' : ''}
                >
                  <span className="mu-item__play video_play_dir" role="button">
                    <span className="icon-mic"></span>
                  </span>
                </a>
              </div>
              <a
                href={createDestinationUrl(manutds.value.DestinationUrl)}
                target={isApp() && isCollection() ? '_blank' : ''}
              >
                {Headline && (
                  <React.Fragment>
                    <h2 className="mu-item__title">
                      <Text field={Headline} />
                    </h2>
                    <span className="screenreader">&nbsp;{ContentType && ContentType.value}</span>
                  </React.Fragment>
                )}
              </a>
              {EpisodeDescription && (
                <p className="mu-item__teasure">
                  <Text field={EpisodeDescription} />
                </p>
              )}
            </div>
            {showStatsbar && (
              <StatsBar
                item={getStatsBarData(fields)}
                tagListLocal={createTagArr(manutds)}
              ></StatsBar>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

Podcast.defaultProps = {
  showStatsbar: true,
  isRelative: false,
};

export default withTranslation()(Podcast);
