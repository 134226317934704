/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { createDestinationUrl } from '../../utils/utils';
import * as track from '../../utils/analytics';
import ImageFallback from '../Common/ImageFallback';
import { LIVE_EVENT } from '../../constants/containerConstants';

export const HighlightCard = ({ item }) => {
  const headingRef = useRef(null);
  const [heading, setHeading] = useState(item.blogheading_t);
  useEffect(() => {
    if (heading) {
      setEllipsis(item);
    }
  }, [heading, item]);

  /**
   * @param {data of each blogs item. onClick of card it will smooth scroll the page and show the particlar larger card} item
   */
  const scrollOnBlog = (item) => {
    if (item) {
      let elementId;
      let elmId = document.getElementById(item.rawitemid_s);
      let loadMore = document.getElementById('loadMore');
      const mainHeader = document.getElementsByClassName('mainHeader');
      let headerHeight =
        mainHeader && mainHeader[0] && mainHeader[0].clientHeight
          ? (mainHeader[0].clientHeight - 40) / 2
          : 33;
      elmId && elmId !== undefined ? (elementId = elmId) : (elementId = loadMore);
      if (elementId) {
        window.scroll({
          top: elementId && elementId.offsetTop ? elementId.offsetTop - headerHeight : 0,
          behavior: 'smooth',
        });
      }
      trackBlogCard(item);
    }
  };

  /**
   * @define {logic for add elipsis if heading data is large than two lines} item
   */
  const setEllipsis = () => {
    if (headingRef.current) {
      const wordArray = heading.split(' ');
      if (headingRef.current.scrollHeight > headingRef.current.offsetHeight) {
        if (wordArray && wordArray.length) {
          const newArr = wordArray.slice(0, wordArray.length - 1);
          setHeading(newArr.join(' ') + '...');
        }
      } else {
        setHeading(item.blogheading_t);
      }
    }
  };

  /**
   *
   * @param {tracking for blog highlights data} item
   */
  const trackBlogCard = (item) => {
    const data = track.data('card');
    const newData = {
      card_name: item.blogheading_t,
      container_type: LIVE_EVENT,
      content_type: item.contenttype_t,
      created_datetime: item.__published_tdt,
      destination_url: createDestinationUrl(item.destinationurl_s),
      item_id: item.rawitemid_s,
      tag: item.taglist_sm,
      author: item.personname_t,
    };
    track.analytics({ ...data, ...newData });
  };

  /**
   *
   * @param {return class name according to personname_t } item
   */
  const authorclassName = (item) => {
    return item && item.personname_t
      ? 'blog-image icon-author-image'
      : 'blog-image icon-arrow-down-circle';
  };

  return (
    <li
      className="non-matchday-blog-card"
      role="link"
      tabIndex="0"
      onClick={() => scrollOnBlog(item)}
    >
      <div className="blog-wrapper">
        <p className="blog-headline" ref={headingRef}>
          {heading}
        </p>
        <div className="blog-hightlight__info">
          <span className={authorclassName(item)}>
            <ImageFallback
              src={item.playerimageurl_t}
              alt="image"
              onErrorClass="no-img"
              className="img-responsive"
            ></ImageFallback>
          </span>
          <div className="blogtag_name">
            <div className="blogtag_name__text">{item.personname_t}</div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default withTranslation()(HighlightCard);
