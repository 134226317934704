/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { createContext, useContext, useReducer } from 'react';
import { playerGridReducer, playerGridState } from '../reducers/playerGridReducer';

export const PlayerGridContext = createContext();

export const PlayerGridProvider = ({ children }) => (
  <PlayerGridContext.Provider value={useReducer(playerGridReducer, playerGridState)}>
    {children}
  </PlayerGridContext.Provider>
);
export const usePlayerGridValue = () => useContext(PlayerGridContext);

export default PlayerGridContext;
