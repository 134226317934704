/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { Fragment, useRef } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import ImgCrop from '../../Common/ImgCrop';
import { getStatsBarData, getSponsorData, getItemId } from '../../../utils/utils';
import { CONTENT_TYPE } from '../../../constants/globalConstants';
import Sponsor from '../../Common/Sponsor';
import StatsBar from '../../Common/StatsBar';
import * as track from '../../../utils/analytics';
import { useModal } from '../../Common/Modal';
import loadable from '@loadable/component';
import Loader from '../../Common/Loader';
import { HERO_QUOTE } from '../../../constants/containerConstants';
import ErrorBoundary from '../../Common/ErrorBoundary';
const QuoteModal = loadable(() => import('../../Modals/QuoteModal/QuoteModal'));
const Quotes = (props) => {
  const {
    t,
    fields,
    gridClass,
    isMatchcenterCard,
    container,
    showStatsbar,
    isHeroBannerQuote,
    isArticleDetail,
  } = props;
  const { showModal, closeModal, isModalVisible } = useModal();
  const itemId = getItemId(props);
  const sponsorInfo = fields.Sponsorby && getSponsorData(fields.Sponsorby.fields);
  const trackCard = (fields, e) => {
    const initialData = track.data('card');
    track.analytics(
      {
        ...initialData,
        card_name: fields.ShortHeadline && fields.ShortHeadline.value,
        container_type: container,
        item_id: itemId,
        content_type: fields.ContentType && fields.ContentType.value,
        tag: fields.manutds && fields.manutds.value && fields.manutds.value.Tags,
        created_datetime: fields.__Published && fields.__Published.value,
        author: fields.Author && fields.Author.value,
        destination_url:
          fields.manutds && fields.manutds.value && fields.manutds.value.DestinationUrl,
      },
      e
    );
  };
  const imageCrops = fields.Image && fields.Image.value && fields.Image.value.Crops;
  const imageAltText = fields.Image && fields.Image.value && fields.Image.value.AltText;
  const isWebpAvailable = fields.Image && fields.Image.value && fields.Image.value.isWebpAvailable;

  const wrapper = useRef(null);
  const quote = useRef(null);
  const applyTint = () => {
    var quoteEl = quote && quote.current;
    var cardHeight = quoteEl && quoteEl.clientHeight;
    var textHeight = wrapper && wrapper.current && wrapper.current.clientHeight;
    if (cardHeight && textHeight) {
      var textTop = cardHeight - textHeight;
      var textTopPer = (textTop / cardHeight) * 100;
      var remaining = textTopPer - 20;
      textTopPer = textTopPer + '%';
      remaining = remaining + '%';
      var gradientElm = quoteEl.querySelector('.mu-item__tint');
      if (!gradientElm) {
        gradientElm = quoteEl.querySelector('.mu-item__gradient');
      }
      if (gradientElm) {
        var bgProp =
          'linear-gradient(180deg,rgba(0,0,0,0) ' +
          remaining +
          ',rgba(0,0,0,.6) ' +
          textTopPer +
          ',rgba(0,0,0,.6) ' +
          textTopPer +
          '';
        gradientElm.style.background = bgProp;
      }
    }
  };
  return (
    <Fragment>
      <div
        className={`main_class ${gridClass} ${isMatchcenterCard ? 'ratio-matchday' : 'ratio'} ${
          imageCrops ? 'with-img' : 'without-img'
        }`}
        onClick={(e) => {
          !isArticleDetail && !isHeroBannerQuote && showModal();
          trackCard(fields, e);
        }}
        data-impression={track.impression({
          card_name: fields.ShortHeadline && fields.ShortHeadline.value,
          content_type: fields.ContentType && fields.ContentType.value,
        })}
      >
        <div className={`mu-content`}>
          <div
            className={`${imageCrops ? 'mu-item quotes' : 'mu-item quotes child'} ${
              isHeroBannerQuote && 'remove-pointer'
            }`}
            ref={quote}
          >
            <div className="img-holder">
              {imageCrops && (
                <LazyLoad once={true} offset={100}>
                  <ImgCrop
                    onLoad={() => applyTint()}
                    onError={() => applyTint()}
                    imgCrop={imageCrops}
                    alt={imageAltText}
                    title={imageAltText}
                    isWebpAvailable={isWebpAvailable}
                    gridClass={gridClass}
                  ></ImgCrop>
                </LazyLoad>
              )}
            </div>
            <Sponsor
              contentType={fields.ContentType && fields.ContentType.value}
              varient={fields.Variant && fields.Variant.value}
              sponsorInfo={sponsorInfo}
              grid={gridClass}
              type={CONTENT_TYPE}
              author={fields.Author && fields.Author.value}
              cardName={fields.ShortHeadline && fields.ShortHeadline.value}
              container={container}
              createdDateTime={fields.__Published && fields.__Published.value}
              itemId={itemId}
              tags={fields.manutds && fields.manutds.value && fields.manutds.value.Tags}
            ></Sponsor>
            <div className="mu-quotes__wrapper" ref={wrapper}>
              <div className="mu-quotes__info">
                {fields.ShortHeadline && fields.ShortHeadline.value && (
                  <div className="mu-item__title quotes">
                    <Text field={fields.ShortHeadline} />
                  </div>
                )}
                <span className="screenreader">
                  {fields.Author && fields.Author.value} {t('says')}
                </span>
                {fields.QuoteValue && fields.QuoteValue.value && (
                  <blockquote>
                    <p className="mu-quotes__title" tabIndex="0" role="link">
                      <Text field={fields.QuoteValue} />
                    </p>
                  </blockquote>
                )}
                {fields.Author && fields.Author.value && (
                  <p aria-hidden="true" className="mu-quotes__author">
                    <cite>{fields.Author.value}</cite>
                  </p>
                )}
              </div>
            </div>
            {showStatsbar && container !== HERO_QUOTE && (
              <StatsBar item={getStatsBarData(fields)}></StatsBar>
            )}
          </div>
        </div>
      </div>
      {isModalVisible && !isHeroBannerQuote && (
        <ErrorBoundary>
          <QuoteModal
            closeModal={closeModal}
            itemid={itemId}
            fallback={<Loader />}
            quoteData={fields}
          />
        </ErrorBoundary>
      )}
    </Fragment>
  );
};
Quotes.defaultProps = {
  showStatsbar: true,
  isHeroBannerQuote: false,
  isArticleDetail: false,
};
export default withTranslation()(Quotes);
