/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { Fragment, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { isEmpty, isEqual } from 'lodash';
import ImgCrop from '../../Common/ImgCrop';
import { DFP } from '../../Common/Dfp';
import Socialholder from './SocialHolder';
import Recommendation from './Recommendation';
import CenterContent from './CenterContent';
import RelatedKeywords from './RelatedKeywords';
import ArticleNext from './ArticleNext';
import * as track from '../../../utils/analytics';
import { skip_focus, checkOrentation, throttle } from '../../../utils/articleUtils';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import Transform from '../../../data-transform';
import {
  inlineVideoData,
  getTagObjects,
  isApp,
  getDFPAdFields,
  isCollection,
} from '../../../utils/utils';
import loadable from '@loadable/component';
import MU from '../../../constants/muConstants';
import AMPArticleDetail from '../AMPArticleDetail';
import DFPAd from '../../DFPAd';
import ContentAccessModal from '../../Common/ContentAccessModal';
import { ARTICLE_DETAIL } from '../../../constants/containerConstants';
const HybridArticleCSS = loadable(() => import('./HybridArticleCSS.js'));

const ArticleDetail = (props) => {
  const { fields, t, isDFPScript } = props;
  const {
    Headline,
    Introduction,
    SelectCard6,
    RecommendationSectionTitle,
    manutds,
    NextArticle,
    ShortHeadline,
    ContentType,
    Image,
    Video,
    AuthorName,
    __Published,
    __PublishedBy,
    TextBox6,
    ContentAccess,
  } = fields;
  const appImagePortrait = Image && Image.value && Image.value.Crops && Image.value.Crops.portrait;
  const appImageLarge = Image && Image.value && Image.value.Crops && Image.value.Crops.xlarge;
  const [hybridReadMoreScrolledClass, setHybridReadMoreScrolledClass] = useState('');
  const [hybridImagePath, setHybridImagePath] = useState(appImagePortrait);

  useEffect(() => {
    if (isApp()) {
      if (appImagePortrait && appImageLarge) {
        try {
          const selector = document.getElementsByClassName('hybridArticleImg');
          const grid = selector && selector[0] && selector[0].offsetWidth;
          const screenOrientation = checkOrentation();
          /*ipad pro*/
          if (grid >= 1024 && grid < 1366 && screenOrientation === 'portrait') {
            // ipad pro portrait
            setHybridImagePath(appImagePortrait);
          } else if (grid >= 1024 && screenOrientation === 'landscape') {
            // ipad landscape
            setHybridImagePath(appImageLarge);
          }
        } catch (er) {
          setHybridImagePath('');
          console.log(er);
        }
      }
      window.addEventListener(
        'scroll',
        throttle(hybridScroll, 500, {
          leading: true,
          trailing: true,
        })
      );
    }
    return () => {
      if (isApp()) {
        window.removeEventListener(
          'scroll',
          throttle(hybridScroll, 500, {
            leading: true,
            trailing: true,
          })
        );
      }
    };
  }, []);

  const hybridScroll = () => {
    let noScroll = true;
    const position = window.pageYOffset;
    if (noScroll) {
      if (position > 1) {
        noScroll = false;
        setHybridReadMoreScrolledClass('scrolled');
      }
    }
  };

  const itemId = `{${manutds && manutds.value && manutds.value.Id}}`;

  const tags = getTagObjects(manutds && manutds.value && manutds.value.Tags);

  const trackLink = (e) => {
    const initialData = track.data('link');
    if (initialData && typeof initialData.destination_url !== 'undefined') {
      delete initialData.destination_url;
    }
    track.analytics(
      {
        ...initialData,
        button_name: t('ScrollToTop'),
      },
      e
    );
    e.preventDefault();
  };

  let tagsForAnalytics = '';
  if (tags && tags.length) {
    tags.map((item) => {
      tagsForAnalytics += tagsForAnalytics.length
        ? `,${item.Name}: ${item.Tag}`
        : `${item.Name}: ${item.Tag}`;
    });
  }

  if (MU && MU.isAmpPageUrl) {
    return <AMPArticleDetail {...props}></AMPArticleDetail>;
  }

  const getAnalyticsData = () => {
    const initialData = track.data('card');
    return {
      ...initialData,
      card_name: ShortHeadline && ShortHeadline.value,
      container_type: ARTICLE_DETAIL,
      item_id: itemId,
      content_type: ContentType && ContentType.value,
      tag: manutds && manutds.value && manutds.value.Tags,
      created_datetime: __Published && __Published.value,
      author: AuthorName && AuthorName.value,
      destination_url: manutds && manutds.value && manutds.value.DestinationUrl,
    };
  };

  return (
    <React.Fragment>
      {(typeof window === 'undefined' || process.env.NODE_ENV === 'development') && isApp() && (
        <HybridArticleCSS />
      )}
      <div
        className={`home-article-detail ${isApp() ? 'hybrid-article' : ''} clearfix`}
        data-article-detail={JSON.stringify({
          article_name: Headline && Headline.value,
          author:
            (AuthorName &&
              AuthorName.fields &&
              AuthorName.fields.AuthorName &&
              AuthorName.fields.AuthorName.value) ||
            (__PublishedBy && __PublishedBy.value),
          content_type: ContentType && ContentType.value,
          created_datetime: __Published && __Published.value,
          tag: tagsForAnalytics,
        })}
      >
        {/* ---- Top Panel start ----*/}
        {isEmpty(Video && Video.value) && (
          <div className="home-article-detail__top-panel">
            <div className="grid-12 ratio">
              <div className="mu-content">
                <article className="mu-item xlarge">
                  {Image &&
                  Image.value &&
                  Image.value.Crops &&
                  isApp() &&
                  !isCollection() &&
                  hybridImagePath &&
                  hybridImagePath.length > 0 ? (
                    <div className="img-holder nobg">
                      <ImgCrop
                        imgCrop={hybridImagePath}
                        alt={Image.value.AltText}
                        title={Image.value.AltText}
                        isWebpAvailable={Image.value.isWebpAvailable}
                        adaptiveClass="hybridArticleImg"
                        figureStyle={{
                          backfaceVisibility: 'hidden',
                          WebkitBackfaceVisibility: 'hidden',
                        }}
                        singleSrc={true}
                      />
                    </div>
                  ) : (
                    <div className="img-holder">
                      <ImgCrop
                        imgCrop={Image && Image.value && Image.value.Crops}
                        alt={Image && Image.value && Image.value.AltText}
                        title={Image && Image.value && Image.value.AltText}
                        isWebpAvailable={Image && Image.value && Image.value.isWebpAvailable}
                        className={'adaptive'}
                      />
                    </div>
                  )}
                  <div className="home-article-detail__info">
                    <h1
                      data-impression={track.impression({
                        card_name: ShortHeadline && ShortHeadline.value,
                        content_type: ContentType && ContentType.value,
                      })}
                      className="home-article-detail__title"
                      id="hybridTitle"
                      style={{
                        backfaceVisibility: 'hidden',
                        WebkitBackfaceVisibility: 'hidden',
                      }}
                    >
                      {Headline && Headline.value}
                    </h1>
                    {isApp() && (
                      <Fragment>
                        <div
                          data-track-ignore-anchor={false}
                          className="downArrow downBounce non-keyboard-outline"
                          tabIndex="0"
                          aria-label={t('ScrollToTop')}
                          onClick={(e) => {
                            trackLink(e);
                          }}
                        ></div>
                        <div id="hybridReadMore" className={hybridReadMoreScrolledClass}>
                          {t('ScrollToTop')}
                        </div>
                      </Fragment>
                    )}
                  </div>
                </article>
              </div>
            </div>
          </div>
        )}

        {!isEmpty(Video && Video.value) && (
          <div className="home-article-detail__video-panel">
            <Transform
              componentName="video"
              gridClass="grid-7"
              isInlineVideo={true}
              tracking={false}
              data={inlineVideoData({
                crops: fields.Image && fields.Image.value && fields.Image.value.Crops,
                video: Video && Video.value,
              })}
              source="aws"
              showStatsbar={false}
              isArticleDetail={true}
              noVariant={true}
              isEmbedVideo={true}
            />
          </div>
        )}
        {/* ------ Top Panel ends -------*/}

        {/*---- Content with social icons Start ----*/}
        <div className="home-article-detail__content-panel no-margin">
          <div className="home-content-panel__sponsor">
            <ul className="sponsor-tag"></ul>
          </div>
          <div className="home-content-panel__details">
            {!isEmpty(Video && Video.value) && (
              <h2 className="home-content-panel__video-heading">{Headline && Headline.value}</h2>
            )}
            <Socialholder gridClass="grid-7" fields={fields} itemId={itemId}></Socialholder>
            <div className="home-content-panel__details-text">
              <p className="heading">
                <Text field={Introduction} />
              </p>
            </div>
          </div>
        </div>
        {/*---- Content with social icons End ----*/}

        {/*--Center Section Starts here-*/}
        <CenterContent fields={fields} isDFPScript={isDFPScript} />
        {/*--Center Section Ends here-*/}

        {/* - Content Panel Start ---*/}
        {TextBox6 && TextBox6.value && TextBox6.value.length > 0 && (
          <div className="home-article-detail__content-panel">
            <div className="home-content-panel__details">
              <div className="home-content-panel__details-text">
                <div className="paragraph">
                  <RichText field={TextBox6} />
                </div>
              </div>
            </div>
          </div>
        )}
        {/* - Content Panel End -----*/}

        {/*--Recommendation Section Starts here-*/}
        {SelectCard6 &&
          SelectCard6.length > 0 &&
          RecommendationSectionTitle &&
          RecommendationSectionTitle.value &&
          RecommendationSectionTitle.value.length > 0 && <Recommendation fields={fields} />}
        {/*--Recommendation Section Ends here---*/}

        {/*-- Author & Sponsor Section Starts here ---*/}
        <div className="home-article-detail__content-panel no-margin">
          <div className="home-content-panel__details">
            <div className="home-content-panel__details-social bottom-panel-social">
              <Socialholder
                gridClass="grid-7"
                fields={fields}
                itemId={itemId}
                type="articlefooter"
              ></Socialholder>
              <div className="back-to-top-btn" role="button" tabIndex="0">
                <span
                  onClick={() => {
                    skip_focus('#content');
                  }}
                >
                  <span>{t('BacktoTop')}</span>
                  <i className="icon-arrow-up-black"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        {/*-- Author & Sponsor Section Ends here */}

        {/*-- Related keywords section start ---*/}
        {!isApp() && tags && tags.length > 0 && <RelatedKeywords tags={tags}></RelatedKeywords>}
        {/*--- Related keywords section end ---*/}
        {/*----- next section starts here  ----*/}

        {NextArticle &&
          NextArticle.Id &&
          !isEqual(
            NextArticle.Id.toLowerCase(),
            manutds && manutds.value && manutds.value.Id && manutds.value.Id.toLowerCase()
          ) && <ArticleNext nextArticle={NextArticle}></ArticleNext>}
        {/*  next section ends here */}
        {!isApp() && isDFPScript && (
          <DFPAd
            rendering={{ dataSource: manutds && manutds.value && manutds.value.Id }}
            fields={getDFPAdFields(
              '[1024, 768, 320]',
              '[970, 90]',
              '[728, 90]',
              '[320, 50]',
              '/11820346/Hercules_ROS_970x90//728x90//320x50',
              manutds
            )}
          />
        )}
      </div>
      <ContentAccessModal
        ContentAccess={ContentAccess && ContentAccess.value}
        ContentType={ContentType && ContentType.value}
        manutds={manutds}
        className={`home-article-detail ${isApp() ? 'hybrid-article' : ''} clearfix`}
        isDestinationPage={true}
        insideModal={false}
        analyticsObj={getAnalyticsData()}
      />
    </React.Fragment>
  );
};

export default withTranslation()(ArticleDetail);
