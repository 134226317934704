/**
 *  © 2024 -2025 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useRef, useState } from 'react';
import { isExperienceEditorActive, Text } from '@sitecore-jss/sitecore-jss-react';
import LazyLoad from 'react-lazyload';
import ImgCrop from '../Common/ImgCrop';
import Slider from 'react-slick';
import {
  HomePageHeroCarouselVarientLight,
  HomePageHeroCarouselVarientDark,
  THRESHOLD,
} from '../../constants/globalConstants';
import { checkDevice, getRandomId } from '../../utils/utils';
import { withTranslation } from 'react-i18next';
import Products from './Products';
import {
  PrevArrow,
  NextArrow,
  afterChangeAccessbility,
  beforeChangeAccessbility,
  onInitAccessibility,
  slideShowProductCount,
} from '../../utils/carouselUtils';
import MU from '../../constants/muConstants';
import ErrorBoundary from '../Common/ErrorBoundary';
import * as track from '../../utils/analytics';
const areEqual = (prevProps, nextProps) => true;
const ExternalProductCarousel = React.memo((props) => {
  const dataRender = props?.rendering?.fields;
  const externalProductList = dataRender?.EComProduct1?.value?.EComProduct1?.entities;
  const themeVariant = dataRender?.EComProduct1?.value?.EComProduct1?.Theme;
  const viewAllCTAText = dataRender?.ViewAllCTAText;
  const viewAllCTADestinationurl = dataRender?.ViewAllCTADestinationurl?.value?.url;
  const backGroundThemeImage = dataRender?.BackGroundThemeImage;
  const Title = dataRender?.Title;
  const [isTouchDevice, setIsTouchDevice] = useState(true);
  const [slicker, setSlicker] = useState({});
  const [screenReaderText, setScreenReaderText] = useState('');
  const [uniqueId, setUniqueId] = useState(getRandomId());
  const refElement = useRef(null);
  let timeoutId;

  useEffect(() => {
    setIsTouchDevice(checkDevice());
  }, []);
  useEffect(() => {
    const touchDevice = checkDevice();
    if (!touchDevice) {
      window.addEventListener('resize', resizeListener);
    }
    return () => {
      !touchDevice && window.removeEventListener('resize', resizeListener);
    };
  }, []);
  const resizeListener = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setIsTouchDevice(checkDevice());
      setUniqueId(getRandomId());
    }, THRESHOLD);
  };
  const slideCount = () => {
    if (externalProductList && externalProductList.length > 0) {
      return externalProductList.length;
    } else {
      return 0;
    }
  };
  const nextPrevArrow = (oldIndex, newIndex, slicker, bypass) => {
    setTimeout(() => {
      if (oldIndex < newIndex || bypass) {
        slicker && slicker.prevArrow && slicker.prevArrow.classList.remove('clicked');
        slicker && slicker.nextArrow && slicker.nextArrow.classList.add('clicked');
      }
      if (oldIndex > newIndex) {
        slicker && slicker.prevArrow && slicker.prevArrow.classList.add('clicked');
        slicker && slicker.nextArrow && slicker.nextArrow.classList.remove('clicked');
      }
    }, 100);
  };
  const settings = () => {
    return {
      className: 'carousal-list center',
      lazyLoad: 'progressive',
      variableWidth: true,
      infinite: false,
      speed: 400,
      dots: true,
      slidesToScroll: slideShowProductCount(),
      swipe: true,
      accessibility: false,
      slidesToShow: slideShowProductCount(),
      draggable: isTouchDevice ? true : false,
      swipeToSlide: true,
      touchMove: true,
      mobileFirst: true,
      touchThreshold: 20,
      initialSlide: 0,
      rtl: false,
      arabic: MU.language === 'ar' ? true : false,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow slideShow={slideShowProductCount()} />,
      appendDots: (dots) => {
        if (dots && dots.length) {
          let isActive = true;
          let list = dots.map((val, index) => {
            isActive = isActive ? val.props.className !== 'slick-active' : false;
            return (
              <li key={index} className={`${val.props.className} ${isActive ? 'active' : ''}`}>
                <i></i>
              </li>
            );
          });
          return (
            <div className="slick-dots">
              <ul tabIndex="-1" aria-hidden="true">
                {list}
              </ul>
            </div>
          );
        }
      },
      onLazyLoad: (e) => {
        if (e.includes(slideCount() - 1)) {
          setTimeout(() => {
            if (refElement) {
              const slider = refElement && refElement.current;
              let list = slider && slider.querySelector('.slick-list');
              let slideTrack = list && list.querySelector('.slick-track');
              let slides = slideTrack && slideTrack.querySelectorAll('.slick-slide');
              let nextArrow = slider && slider.querySelector('.slider-next.slick-arrow');
              let prevArrow = slider && slider.querySelector('.slider-prev.slick-arrow');
              onInitAccessibility(slideTrack, slides, 'shop');
              setSlicker({
                slider: slider,
                list: list,
                track: slideTrack,
                slides: slides,
                nextArrow: nextArrow,
                prevArrow: prevArrow,
              });
            }
          }, 500);
        }
      },
      beforeChange: (oldIndex, newIndex) => {
        setScreenReaderValue(newIndex);
        nextPrevArrow(oldIndex, newIndex, slicker);
        beforeChangeAccessbility(slicker, 'shop');
      },
      afterChange: () => {
        afterChangeAccessbility(slicker);
      },
    };
  };
  const setScreenReaderValue = (index) => {
    const a = index;
    const b = slideShowProductCount();
    const c = slideCount();
    if (a + b <= c) {
      setScreenReaderText(`Displayed ${a + 1} of ${a + b} out of ${c}`);
    }
    setTimeout(() => {
      setScreenReaderText('');
    }, 300);
  };
  const variantLight = `carousal-${HomePageHeroCarouselVarientLight.toLowerCase()}`;
  const variantDark = `carousal-${HomePageHeroCarouselVarientDark.toLowerCase()}`;
  const variantCustom = `carousal-custom background-content bg-ncta`;

  const trackLink = (ctaTitle, linkUrl, e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: ctaTitle,
        destination_url: linkUrl,
      },
      e
    );
  };

  const carousalDestination = () => {
    return (
      <React.Fragment>
        <a
          href={viewAllCTADestinationurl}
          target="_blank"
          rel="noreferrer"
          tabIndex="0"
          onClick={(e) => trackLink(viewAllCTAText.value, viewAllCTADestinationurl, e)}
        >
          <span aria-hidden="true">{viewAllCTAText && <Text field={viewAllCTAText} />}</span>
          {/* <span className="screenreader">{props.t('AllKitbagProducts')}</span> */}
        </a>
      </React.Fragment>
    );
  };
  return (
    <ErrorBoundary>
      <div className="home-row product-carousel-wrapper" key={uniqueId}>
        <div
          className={`carousal kitbag ${
            themeVariant === 'dark'
              ? variantDark
              : themeVariant === 'custom'
              ? variantCustom
              : variantLight
          } curated`}
        >
          {themeVariant === 'custom' &&
            backGroundThemeImage &&
            backGroundThemeImage.value &&
            backGroundThemeImage.value.Crops && (
              <div className="carousal-cover__wrapper">
                <LazyLoad once={true}>
                  <ImgCrop
                    className={'adaptive carousal-cover'}
                    imgCrop={backGroundThemeImage.value.Crops}
                    alt="backGroundThemeImage"
                    onErrorClass="no-img"
                  />
                </LazyLoad>
              </div>
            )}
          <div
            className={`container ${isTouchDevice ? 'custom-container' : 'nonTouchDevice'} ${
              slideCount() <= slideShowProductCount() ? 'adjust' : ''
            }`}
          >
            <div className="carousal-width" ref={refElement}>
              <div className="carousal-head">
                <div className="carousal_title_link kitbag">
                  <h2 className="carousal__title">
                    <span>{Title && <Text field={Title} />}</span>
                  </h2>
                  {viewAllCTAText && <div className="view-all">{carousalDestination()}</div>}
                </div>
              </div>
              {isExperienceEditorActive() && (
                <div className="carousal__info">Slider CurationMode :Add Slider Components</div>
              )}
              {!isExperienceEditorActive() && externalProductList?.length > 0 && (
                <Slider {...settings()}>
                  {externalProductList.map((items, index) => {
                    return (
                      <div key={index}>
                        <Products productList={items} rendering={props.rendering} />
                      </div>
                    );
                  })}
                </Slider>
              )}
            </div>
          </div>
        </div>
        {!isTouchDevice && (
          <div className="screenreader" aria-live="assertive">
            {screenReaderText}
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
}, areEqual);
export default withTranslation()(ExternalProductCarousel);
