/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import LeagueMatch from './LeagueMatch';
import { MuTravelProvider } from '../../../context/muTravelContext';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
const TravelForm = (props) => (
  <React.Fragment>
    <MuTravelProvider>
      <TravelReport {...props}></TravelReport>
    </MuTravelProvider>
  </React.Fragment>
);
const TravelReport = (props) => {
  const [autoFill, setAutoFill] = useState({});
  const [formShow, setFormShow] = useState({ showform: 'step1' });
  const {
    MatchData,
    MatchId,
    convicted_Error_Message,
    reEnter_Error_Message,
    wrong_Info_Error_Message,
    connectivity_Error_Message,
    Text,
    ButtonText,
    ThankYouText,
    travelByList,
    arrivalPoints,
    travelFromList,
  } = props && props.fields;
  const currentMatchId = MatchId && MatchId.value;
  const globalMsg = {
    convicted_Error_Message: {
      value: `${convicted_Error_Message && convicted_Error_Message.value}`,
    },
    reEnter_Error_Message: {
      value: `${reEnter_Error_Message && reEnter_Error_Message.value}`,
    },
    wrong_Info_Error_Message: {
      value: `${wrong_Info_Error_Message && wrong_Info_Error_Message.value}`,
    },
    connectivity_Error_Message: {
      value: `${connectivity_Error_Message && connectivity_Error_Message.value}`,
    },
  };

  return (
    <div>
      <section className="travel__report">
        {MatchData && <LeagueMatch matchData={MatchData} />}
        {formShow && formShow.showform === 'step1' && (
          <StepOne
            currentMatchId={currentMatchId}
            setAutoFill={setAutoFill}
            setFormShow={setFormShow}
            autoFill={autoFill}
            Text={Text}
            globalMsg={globalMsg}
          ></StepOne>
        )}
        {formShow && formShow.showform === 'step2' && (
          <StepTwo
            currentMatchId={currentMatchId}
            setFormShow={setFormShow}
            travelByList={travelByList}
            arrivalPoints={arrivalPoints}
            travelFromList={travelFromList}
            autoFill={autoFill}
            Text={Text}
          ></StepTwo>
        )}
        {formShow && formShow.showform === 'step3' && (
          <StepThree
            text={Text}
            buttonText={ButtonText}
            thankYouText={ThankYouText}
            setFormShow={setFormShow}
          ></StepThree>
        )}
      </section>
    </div>
  );
};

export default withTranslation()(React.memo(TravelForm));
