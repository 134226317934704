/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import Widgets from './Widgets';
import Transcript from '../../../Common/Transcript';
const EpisodeNote = ({
  Description,
  data,
  showMyList,
  containerType,
  page,
  t,
  contentStatus,
  isUserSubscribe,
  isUserSkyId,
  videoFileTranscript,
  onTranscriptClick,
}) => {
  const [toggle, setToggle] = useState(false);
  const [isViewLess, setIsViewLess] = useState(false);
  const [showTranscript, setShowTranscript] = useState(false);

  const showParagraph = () => {
    setToggle(!toggle);
  };

  const toggleTranscript = () => {
    onTranscriptClick && onTranscriptClick(!showTranscript);
    const element = document.getElementsByTagName('BODY');
    if (!showTranscript) {
      if (element) {
        element[0].style.overflow = 'hidden';
        element[0].style.position = 'static';
      }
    } else {
      element && element[0].removeAttribute('style');
    }
    setShowTranscript(!showTranscript);
  };

  const transcriptOnkeypress = (e) => {
    const code = e.keyCode || e.which;
    if (code === 32 || code === 13) {
      toggleTranscript();
    }
  };

  const truncateText = () => {
    //Using '\u2026' for three dots
    return Description.substr(0, 240) + '\u2026';
  };
  const shortEpisodeNote = () => {
    if (Description && Description.length > 240) {
      return (
        <React.Fragment>
          <p className="paragraph-web">
            <span>{`${truncateText()} `}</span>
            <span className="view-tag" onClick={() => setIsViewLess(!isViewLess)}>
              {t('ViewMore')}
            </span>
          </p>
        </React.Fragment>
      );
    } else {
      return <p className="paragraph-web short-tag">{Description}</p>;
    }
  };

  const fullEpisodeNote = () => {
    return (
      <React.Fragment>
        <p className="paragraph-web">
          <span>{`${Description} `}</span>
          <span className="view-tag" onClick={() => setIsViewLess(!isViewLess)}>
            {t('ViewLess')}
          </span>
        </p>
      </React.Fragment>
    );
  };

  const renderViewTranscript = (isMobile) => {
    if (videoFileTranscript && videoFileTranscript.value) {
      const className = isMobile
        ? `collection-item__transcript mobile`
        : 'collection-item__transcript web';
      return (
        <p
          tabIndex="0"
          role="link"
          className={className}
          onClick={() => toggleTranscript()}
          onKeyUp={(event) => transcriptOnkeypress(event)}
        >
          {t('ViewTranscript')}
        </p>
      );
    }
    return null;
  };

  return (
    <React.Fragment>
      <div className={`mutv-episode__note ${page}`}>
        <div className="hero-widgets">
          <Widgets
            data={data}
            showMyList={showMyList}
            containerType={containerType}
            contentStatus={contentStatus}
            isUserSubscribe={isUserSubscribe}
            isUserSkyId={isUserSkyId}
          ></Widgets>
        </div>
        <div className="mutv-episode__wrapper">
          <h2 className="heading">{t('EpisodeNotes')}</h2>
          <button className="showbtn" onClick={() => showParagraph()}>
            {toggle ? 'Hide' : 'Show'}
          </button>
          <div className={`paragraph__wrapper ${toggle ? 'showtext' : ''}`}>
            <p
              className={toggle ? 'paragraph showtext' : 'paragraph'}
              aria-hidden={toggle ? 'false' : 'true'}
              tabIndex={toggle ? 0 : -1}
            >
              {Description}
            </p>
            {renderViewTranscript(true)}
          </div>
          {!isViewLess && shortEpisodeNote()}
          {isViewLess && fullEpisodeNote()}
          {renderViewTranscript(false)}
        </div>
        {showTranscript && (
          <Transcript
            toggleTranscript={toggleTranscript}
            transcriptOnkeypress={transcriptOnkeypress}
            videoFileTranscript={videoFileTranscript}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default withTranslation()(EpisodeNote);
