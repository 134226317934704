/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import moment from 'moment';
import InlineVideo from '../../Cards/Video/InlineVideo';
import BackgroundVideo from '../../Cards/Video/BackgroundVideo';
import ImgCrop from '../../Common/ImgCrop';
import Sponsor from '../../Common/Sponsor';
import {
  createTagList,
  getContentAccessLocal,
  getMediaID,
  getRandomId,
  getLoggedInStatus,
  showCBS,
  onCloseMedia,
  getParameterByName,
} from '../../../utils/utils';
import {
  ACCOUNT_SUBSCRIPTION,
  CONTENT_TYPE,
  FREE_TO_VIEW,
  REGISTERED,
  THRESHOLD,
} from '../../../constants/globalConstants';
import { MUTV_HERO } from '../../../constants/containerConstants';
import * as track from '../../../utils/analytics';
import {
  individualTag,
  isContentLabelVisible,
  setInlineVideoContainerUtil,
  getOrientation,
} from '../mutvutil';
import { useModal } from '../../Common/Modal';
import ErrorBoundary from '../../Common/ErrorBoundary';
import Loader from '../../Common/Loader';
import loadable from '@loadable/component';
import { useSponsorValue } from '../../../context/sponsorContext';
import { setMutvHeroVideoStatus } from '../../../actions/sponsorAction';
import MU from '../../../constants/muConstants';
import { getAbbrTimeZone, getMatchDate } from '../../../utils/momentDate';
const RegistrationModal = loadable(() =>
  import('../../Modals/RegistrationModal/RegistrationModal')
);
const MutvHeroContent = ({
  heroDetails,
  AudioStreamResponse,
  MuTvHeroContainerResponse,
  PricePackResponse,
  maskRequired,
  heroCarousel,
  itemID,
  mutvHeroPlayMainVd,
  setMutvHeroPlayMainVd,
  setClosemutvHeroPlayMainVd,
  activeSlide,
  t,
  callApi,
  isSingleVideo,
}) => {
  const {
    celumimagesvariant_s: bannerimage,
    bannertitle_t,
    bannerdescription_t,
    displaypricepack_b,
    displaypricepackbanner_b,
  } = MuTvHeroContainerResponse;
  const {
    contenttype_t,
    contentaccess_t,
    taglist_sm,
    sponsordetailinfo_s,
    appearance_tdt,
    __published_tdt,
    __publishedby_t,
    itemid_s,
    contentlabel_s,
    authorname_s,
    labelexpirytime_tl,
    celumimagesvariant_s,
    schedulemetadata_t,
    scheduletype_t,
    shortheadline_t,
    startdatetime_tdt,
    enddatetime_tdt,
    category_s,
    teaser_t,
    showctatext_t,
    showdestinationurl_t,
    type,
  } = heroDetails;
  let timeoutId;
  const [
    { isLoginChecked, isUserLogin, isUserSubscribe, isUserSkyId },
    dispatch,
  ] = useSponsorValue();
  const { showModal, closeModal, isModalVisible } = useModal();
  const [playVideo, setPlayVideo] = useState(false);
  const [liveStreamMsg, setLiveStreamMsg] = useState({ show: false });
  const [showSwitching, setShowSwitching] = useState(false);
  const [liveRandomId, setLiveRandomId] = useState(null);
  const [videoStyle, setVideoStyle] = useState({});
  const [playBGVideo, setPlayBGVideo] = useState(false);
  const [contentAccessState, setContentAccessState] = useState(
    getContentAccessLocal(contentaccess_t, contenttype_t, taglist_sm)
  );
  const getImgCrop = () => {
    return type === 'preview'
      ? schedulemetadata_t.Image
      : displaypricepackbanner_b && bannerimage
      ? bannerimage.ImageDetails &&
        bannerimage.ImageDetails.find((item) => item.Name === 'BannerImage')
      : schedulemetadata_t && schedulemetadata_t.Image;
  };
  const prevRef = useRef({
    itemid_s,
    contenttype_t,
    startdatetime_tdt,
    enddatetime_tdt,
    imageCrops: getImgCrop(),
  });
  const containerRef = useRef(null);
  const randomId = getRandomId();
  const isMediaObj = useRef(null);
  const getCommonData = () => {
    let obj = {};
    if (contenttype_t && contenttype_t.toLowerCase() === 'video') {
      obj.isVideo = true;
      obj.heading = shortheadline_t;
      obj.imageCrops = celumimagesvariant_s && celumimagesvariant_s.ImageCropUrl;
    } else if (
      contenttype_t &&
      contenttype_t.toLowerCase() === 'schedule' &&
      type &&
      type === 'preview'
    ) {
      obj.isVideo = true;
      obj.heading = schedulemetadata_t && schedulemetadata_t.Title;
      obj.imageCrops = getImgCrop();
    } else if (contenttype_t && contenttype_t.toLowerCase() === 'schedule') {
      obj.isVideo = false;
      obj.heading = schedulemetadata_t && schedulemetadata_t.Title;
      obj.imageCrops = getImgCrop();
    }
    if (liveStreamMsg && liveStreamMsg.show) {
      obj.imageCrops = liveStreamMsg.img;
    }
    return obj;
  };
  const { isVideo, heading, imageCrops } = getCommonData();
  const showPricePack =
    !isUserSubscribe &&
    contentAccessState === ACCOUNT_SUBSCRIPTION &&
    displaypricepack_b &&
    PricePackResponse &&
    PricePackResponse.length > 0;

  useEffect(() => {
    if (itemid_s && prevRef.current && itemid_s !== prevRef.current.itemid_s) {
      const contentAccess = getContentAccessLocal(contentaccess_t, contenttype_t, taglist_sm);
      if (
        prevRef.current.contenttype_t &&
        prevRef.current.contenttype_t.toLowerCase() === 'schedule' &&
        playVideo
      ) {
        if (contenttype_t && contenttype_t.toLowerCase() === 'schedule') {
          if (showCBS({ isUserLogin, isUserSubscribe, contentAccessState: contentAccess })) {
            showModal();
            setPlayVideoStatus(false);
          } else {
            setLiveRandomId(getRandomId());
          }
        } else if (contenttype_t && contenttype_t.toLowerCase() === 'video') {
          const endTime = prevRef.current.enddatetime_tdt;
          const endLiveStream =
            moment(endTime).isBefore(moment()) || moment(endTime).isSame(moment());
          if (endLiveStream) {
            setLiveStreamMsg({ show: true, img: prevRef.current.imageCrops });
            setPlayVideoStatus(false);
          }
        }
      }
      prevRef.current = {
        itemid_s,
        contenttype_t,
        startdatetime_tdt,
        enddatetime_tdt,
        imageCrops,
      };
      setContentAccessState(contentAccess);
    }
  }, [itemid_s]);

  useEffect(() => {
    if (contentAccessState === ACCOUNT_SUBSCRIPTION && !playVideo) {
      isMediaObj && isMediaObj.current && onCloseMedia(isMediaObj.current);
    }
    playVideo && setInlineVideoContainer();
  }, [playVideo]);

  const autoPlayBGVideo = () => {
    setPlayVideoStatus(true);
    setPlayBGVideo(true);
  };

  useEffect(() => {
    if (
      heroDetails &&
      heroDetails.mutvbackgroundvideometadatadetails_s &&
      (heroDetails.mutvbackgroundvideometadatadetails_s.mediaId ||
        heroDetails.mutvbackgroundvideometadatadetails_s.MediaId)
    ) {
      autoPlayBGVideo();
    }
  }, [heroCarousel]);

  useEffect(() => {
    if (
      heroDetails &&
      heroDetails.schedulemetadata_t &&
      heroDetails.schedulemetadata_t.BackgroundVideo &&
      (heroDetails.schedulemetadata_t.BackgroundVideo.mediaId ||
        heroDetails.schedulemetadata_t.BackgroundVideo.Mediad)
    ) {
      autoPlayBGVideo();
    }
  }, [heroCarousel]);

  useEffect(() => {
    typeof window !== 'undefined' && window.addEventListener('resize', resizeListener);
    return () => typeof window !== 'undefined' && window.addEventListener('resize', resizeListener);
  }, []);

  const setInlineVideoContainer = () => {
    try {
      if (heroDetails && containerRef.current) {
        let containerType = '';
        const orientObj = {
          vD: isVideo ? getOrientation(heroDetails, containerType) : '16x9',
          container: containerRef.current,
        };
        orientObj && orientObj.vD && setVideoStyle(setInlineVideoContainerUtil(orientObj));
      }
    } catch (e) {
      console.log('Error', e);
      return null;
    }
  };

  const resizeListener = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      setInlineVideoContainer();
    }, THRESHOLD);
  };

  const setPlayVideoStatus = (status) => {
    setMutvHeroVideoStatus(dispatch, { value: status, img: imageCrops });
    setPlayVideo(status);
  };

  const showContent = () => {
    if (contentAccessState === FREE_TO_VIEW || isLoginChecked) {
      return true;
    }
    return false;
  };

  const getAnalyticsData = (data) => {
    const initialData = track.data('button');
    return {
      ...initialData,
      card_name: heading,
      button_name: 'video fullscreen',
      container_type: MUTV_HERO,
      subscription_status: getLoggedInStatus({
        isLoginChecked,
        isUserLogin,
        isUserSubscribe,
        isUserSkyId,
      }),
      item_id: itemid_s,
      content_type: contenttype_t,
      tag: taglist_sm && createTagList(taglist_sm, category_s),
      created_datetime: __published_tdt || appearance_tdt,
      content_status: contentAccessState,
      author: __publishedby_t,
      ...individualTag(taglist_sm),
      ...epgAnalytics(),
    };
  };

  const trackCard = (data, e) => {
    track.analytics(getAnalyticsData(data), e);
  };

  const epgAnalytics = () => {
    if (!isVideo) {
      return {
        program_starttime: startdatetime_tdt,
        program_endtime: enddatetime_tdt,
      };
    }
    return {};
  };

  const triggerPlayVideo = (e) => {
    e.preventDefault();
    trackCard(heroDetails, e);
    if (showCBS({ isUserLogin, isUserSubscribe, contentAccessState })) {
      showModal();
    } else {
      if (type && type === 'preview') {
        if (showdestinationurl_t) {
          window.location.href = showdestinationurl_t;
        }
      } else if (isSingleVideo || itemID === activeSlide) {
        setPlayVideoStatus(true);
        setMutvHeroPlayMainVd(true);
        e.stopPropagation();
      }
    }
  };

  const getCtaText = () => {
    if (!isUserLogin && contentAccessState === REGISTERED) {
      return t('RegisterToWatch_web');
    } else if (!isUserSubscribe && contentAccessState === ACCOUNT_SUBSCRIPTION) {
      return t('SubscribeToWatch_web');
    } else if (type && type === 'epg') {
      return t('FreeToWatch_web');
    } else if (type && type === 'preview') {
      return showctatext_t ? showctatext_t : t('FreeToWatch_web');
    }
    return t('FreeToWatch_web');
  };

  const getBGMediaID = (data) => {
    if (
      type &&
      (type === 'preview' || type === 'epg') &&
      data &&
      data.schedulemetadata_t &&
      data.schedulemetadata_t.BackgroundVideo &&
      (data.schedulemetadata_t.BackgroundVideo.mediaId ||
        data.schedulemetadata_t.BackgroundVideo.Mediad)
    ) {
      return data.schedulemetadata_t.BackgroundVideo.mediaId
        ? data.schedulemetadata_t.BackgroundVideo.mediaId
        : data.schedulemetadata_t.BackgroundVideo.MediaId;
    } else if (
      data &&
      data.mutvbackgroundvideometadatadetails_s &&
      (data.mutvbackgroundvideometadatadetails_s.mediaId ||
        data.mutvbackgroundvideometadatadetails_s.MediaId)
    ) {
      return data.mutvbackgroundvideometadatadetails_s.mediaId
        ? data.mutvbackgroundvideometadatadetails_s.mediaId
        : data.mutvbackgroundvideometadatadetails_s.MediaId;
    }
    return '';
  };

  const videoData = () => {
    return {
      VideoID: {
        value:
          type && type === 'preview'
            ? getBGMediaID(heroDetails)
            : isVideo
            ? mutvHeroPlayMainVd
              ? getMediaID(heroDetails)
              : getBGMediaID(heroDetails)
            : AudioStreamResponse && AudioStreamResponse.videostreamid_t,
      },
      ContentType: { value: contenttype_t },
      __Published: { value: __published_tdt || appearance_tdt },
      manutds: { value: { Author: authorname_s, Tags: createTagList(taglist_sm, category_s) } },
      ShortHeadline: { value: heading },
      ContentAccess: { value: contentAccessState },
    };
  };

  const retriveSuccessCallback = typeof window !== 'undefined' && getParameterByName('state', true);

  useEffect(() => {
    if (retriveSuccessCallback && isLoginChecked) {
      const successCallData = retriveSuccessCallback.split(',');
      if (successCallData && successCallData[0] === 'mutvHeroContainerVideo') {
        const isSubscribedContent = successCallData[1] && successCallData[1] === 'true';
        const userSubscribed = isUserSubscribe;
        if (!isSubscribedContent || (isSubscribedContent && userSubscribed)) {
          setPlayVideoStatus(true);
          const url = typeof window !== 'undefined' && window.location.href;
          const urlMain = url.split('?')[0] ?? url;
          window.history.replaceState({}, null, urlMain);
        }
      }
    }
  }, [retriveSuccessCallback, isLoginChecked]);

  const successCallback = ({ showContent, isSubscribedContent }) => {
    if (showContent) {
      if (!MU.isIDMEnabled) {
        setPlayVideoStatus(true);
      } else return ['mutvHeroContainerVideo', isSubscribedContent];
    }
  };

  const renderPricePack = () => {
    if (type && type === 'preview') {
      return false;
    }
    if (showContent()) {
      return (
        <React.Fragment>
          <div className="price-detail">
            {PricePackResponse.map((value, index) => {
              return (
                value.price_t &&
                value.validity_t && (
                  <React.Fragment>
                    <span className="price-month">
                      {value.price_t} <span> {value.validity_t} </span>
                      {index !== PricePackResponse.length - 1 && <div className="line" />}
                    </span>
                    {index !== PricePackResponse.length - 1 && <p className="screenreader" />}
                  </React.Fragment>
                )
              );
            })}
          </div>
          <p className="subscription-required">{t('NoCommitmentsSubscriptionRequired')}</p>
        </React.Fragment>
      );
    }
    return (
      <div className="price-shimmer-wrapper">
        <div className="price-shimmer" />
      </div>
    );
  };

  const isMediaPlaying = (data) => {
    isMediaObj.current = data;
  };

  const renderImgVideo = () => {
    try {
      return (
        <div className="img-holder">
          {imageCrops && (imageCrops.CropUrl || imageCrops.CropURL) && (
            <LazyLoad once={true} offset={100}>
              <ImgCrop
                imgCrop={imageCrops.CropUrl || imageCrops.CropURL}
                alt={imageCrops.AltText}
                isWebpAvailable={imageCrops.isWebpAvailable}
                gridClass="grid-12"
                isUpdateImage={true}
                titleAvailable={!heroCarousel}
              />
            </LazyLoad>
          )}
          {heroCarousel &&
            playVideo &&
            playBGVideo &&
            !mutvHeroPlayMainVd &&
            itemID === activeSlide &&
            ((heroDetails &&
              heroDetails.mutvbackgroundvideometadatadetails_s &&
              (heroDetails.mutvbackgroundvideometadatadetails_s.mediaId ||
                heroDetails.mutvbackgroundvideometadatadetails_s.MediaId)) ||
              (heroDetails &&
                heroDetails.schedulemetadata_t &&
                heroDetails.schedulemetadata_t.BackgroundVideo &&
                (heroDetails.schedulemetadata_t.BackgroundVideo.mediaId ||
                  heroDetails.schedulemetadata_t.BackgroundVideo.MediaId))) && (
              <>
                <LazyLoad once={true} offset={100}>
                  <BackgroundVideo
                    data={videoData()}
                    itemid={itemid_s}
                    isMutv={{
                      container_type: MUTV_HERO,
                      subscription_status: getLoggedInStatus({
                        isLoginChecked,
                        isUserLogin,
                        isUserSubscribe,
                        isUserSkyId,
                      }),
                      isPodcast: false,
                      isBGVideo: true,
                      contentStatus: contentAccessState,
                      additionalAnalytics: epgAnalytics(),
                    }}
                    setShowSwitching={setShowSwitching}
                    liveRandomId={liveRandomId}
                    contentAccessState={contentAccessState}
                    playerStop={setPlayVideoStatus}
                    isCloseIcon={false}
                    isMediaPlaying={isMediaPlaying}
                    type="BGVideoAutoPlay"
                  />
                </LazyLoad>
              </>
            )}
          {playVideo && mutvHeroPlayMainVd && (isSingleVideo || itemID === activeSlide) && (
            <InlineVideo
              data={videoData()}
              itemid={itemid_s}
              isMutv={{
                container_type: MUTV_HERO,
                subscription_status: getLoggedInStatus({
                  isLoginChecked,
                  isUserLogin,
                  isUserSubscribe,
                  isUserSkyId,
                }),
                isPodcast: false,
                contentStatus: contentAccessState,
                additionalAnalytics: epgAnalytics(),
              }}
              setShowSwitching={setShowSwitching}
              liveRandomId={liveRandomId}
              contentAccessState={contentAccessState}
              playerStop={setPlayVideoStatus}
              isCloseIcon={true}
              isMediaPlaying={isMediaPlaying}
            />
          )}
          {liveStreamMsg && liveStreamMsg.show && (
            <div className="livestream-ended">
              <h2 className="mu-item__title">{t('mutvvideoendweb')}</h2>
            </div>
          )}
          {mutvHeroPlayMainVd &&
            ((showSwitching && playVideo) || (liveStreamMsg && liveStreamMsg.show)) && (
              <button
                className="close-btn"
                onClick={() => {
                  playVideo && setPlayVideoStatus(false);
                  setMutvHeroPlayMainVd(false);
                  setShowSwitching(false);
                  setClosemutvHeroPlayMainVd(true);
                  callApi('close');
                  autoPlayBGVideo();
                  liveStreamMsg && liveStreamMsg.show && setLiveStreamMsg({ show: false });
                }}
              >
                <i className="icon-cross" data-closemodal="close" />
              </button>
            )}
        </div>
      );
    } catch (e) {
      console.log('Error in render ImgVideo', e);
    }
  };
  const displayCtaBtn = () => {
    if (showContent()) {
      return (
        <div className="hero-widgets large-btn">
          <div className="red-btn-skew btn" onClick={(e) => triggerPlayVideo(e)}>
            <a href="#" role="link" aria-describedby={randomId}>
              <span>{getCtaText()}</span>
            </a>
          </div>
        </div>
      );
    }
    return <div className="btn-shimmer large" />;
  };

  const renderCtaButton = () => {
    if (type === 'preview') {
      if (!showctatext_t || !showdestinationurl_t) {
        if (contentAccessState && [REGISTERED, ACCOUNT_SUBSCRIPTION].includes(contentAccessState)) {
          if (!isUserLogin || (contentAccessState === ACCOUNT_SUBSCRIPTION && !isUserSubscribe)) {
            return displayCtaBtn();
          }
        }
        return null;
      }
    }
    return displayCtaBtn();
  };

  const renderContainerInfo = () => {
    return (
      <div className="container mutvhero-home-container">
        <div
          className={`mu-item__info ${showContent() ? '' : 'info-shimmer'}`}
          style={{
            display: mutvHeroPlayMainVd || (liveStreamMsg && liveStreamMsg.show) ? 'none' : 'block',
          }}
        >
          <div
            className={`wrapper ${
              type && (type === 'preview' || type === 'epg') ? 'preview-text-wrapper' : ''
            }`}
          >
            {isVideo &&
              contentlabel_s &&
              isContentLabelVisible({
                labelexpirytime_tl,
                __published_tdt,
                appearance_tdt,
              }) && (
                <div className="content-label-wrapper">
                  <span className="content-label">{contentlabel_s}</span>
                </div>
              )}
            {!displaypricepackbanner_b &&
              (!type || (type && type !== 'preview' && type !== 'vod')) && (
                <div className="live-video-title">
                  <i className="icon-livevideo" />
                  <span className="blink">
                    <i className="icon-live-blink" />
                  </span>
                  <p>
                    <span>
                      {scheduletype_t.toLowerCase() === 'live' ? t('LiveNow') : t('OnNow')}
                    </span>
                  </p>
                </div>
              )}
            <h2
              className="mu-item__title"
              id={displaypricepackbanner_b && !isVideo ? null : randomId}
            >
              {displaypricepackbanner_b && (!type || (type && type !== 'preview' && type !== 'vod'))
                ? bannertitle_t
                : heading}
            </h2>
            {heroDetails && type && type === 'preview' && (
              <p className="info__kick-off" role="presentation">
                <span>
                  {t('ComingSoon')}
                  <span className="dot"></span>
                  {getMatchDate(startdatetime_tdt, 'ddd D MMM')}
                  <span className="dot"></span>
                </span>
                <span>
                  {getMatchDate(startdatetime_tdt, 'HH:mm')} {getAbbrTimeZone()}
                </span>
              </p>
            )}
            {heroCarousel && type && type === 'vod' && teaser_t && (
              <p className="mu-item__teasure video_teasure">{teaser_t}</p>
            )}
            {type &&
              type === 'preview' &&
              heroDetails &&
              heroDetails.schedulemetadata_t &&
              heroDetails.schedulemetadata_t.Description && (
                <p className="mu-item__teasure video_teasure">
                  {heroDetails.schedulemetadata_t.Description}
                </p>
              )}
            {displaypricepackbanner_b &&
              (!type || (type && type !== 'preview' && type !== 'vod')) &&
              scheduletype_t && (
                <React.Fragment>
                  {bannerdescription_t && <p className="mu-item__teasure">{bannerdescription_t}</p>}
                  {heading && (
                    <div className="live-video-title">
                      <p id={randomId}>
                        <i className="icon-livevideo" />
                        <span className="blink">
                          <i className="icon-live-blink" />
                        </span>
                        <span>{`${
                          scheduletype_t.toLowerCase() === 'live' ? t('LiveNow') : t('OnNow')
                        }: `}</span>
                        {heading}
                      </p>
                    </div>
                  )}
                </React.Fragment>
              )}
            {showPricePack && renderPricePack()}
            {renderCtaButton()}
          </div>
        </div>
      </div>
    );
  };

  const isDescription = () => {
    if (
      displaypricepackbanner_b &&
      (!type || (type && type !== 'preview' && type !== 'vod')) &&
      scheduletype_t &&
      bannerdescription_t
    ) {
      return 'description-wrap';
    }
    return '';
  };

  return (
    <React.Fragment>
      <div
        className={`mutv-hero free-trail hero-height ${maskRequired ? 'mask' : ''} ${
          showPricePack ? 'pricePack-wrap' : ''
        } ${isDescription()} ${heroCarousel && !mutvHeroPlayMainVd && 'heroBGVideo'}`}
        ref={containerRef}
        // style={playVideo ? videoStyle : { transition: 'all .4s ease' }}
        //style={playVideo ? { transition: 'all .4s ease' } : { transition: 'all .4s ease' }}
      >
        <div className="mu-content">
          <div className="mu-item video">
            {renderImgVideo()}
            <span
              style={{
                display:
                  mutvHeroPlayMainVd || (liveStreamMsg && liveStreamMsg.show) ? 'none' : 'block',
              }}
            >
              <Sponsor
                contentType={isVideo ? contenttype_t : 'livestream'}
                sponsorInfo={sponsordetailinfo_s}
                type={CONTENT_TYPE}
                author={__publishedby_t || authorname_s}
                createdDateTime={__published_tdt || appearance_tdt}
                itemId={itemid_s}
                tags={createTagList(taglist_sm, category_s)}
                cardName={heading}
                container={MUTV_HERO}
                grid={'grid-12'}
              />
            </span>
            {renderContainerInfo()}
          </div>
        </div>
      </div>
      {isModalVisible && (
        <ErrorBoundary>
          <RegistrationModal
            successCallback={successCallback}
            closeModal={closeModal}
            fallback={<Loader />}
            previousActiveElement={document && document.activeElement}
            accessType={contentAccessState}
            analyticsObj={getAnalyticsData(heroDetails)}
          />
        </ErrorBoundary>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(MutvHeroContent);
