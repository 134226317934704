/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_LIVE_EVENT,
  SET_LIVE_EVENT,
  ERROR_LIVE_EVENT,
  IS_IN_VIEW_PORT,
  UPDATE_LIVE_FEED,
} from '../constants/actions/actionConstant';
import { NON_MATCHDAY } from '../constants/endpoints';
import api from '../utils/muApi';

export const fetchLiveEvent = (dispatch, eventId, timestamp, rowsCount, action) => {
  const route = `${NON_MATCHDAY + eventId + '/0/' + '/' + rowsCount + '/' + timestamp}`;
  dispatch(fetchingLiveEvent(action));
  return api
    .get({ route })
    .then((response) => {
      dispatch(setLiveEvent(response, timestamp));
    })
    .catch((err) => {
      dispatch(error(err));
    });
};
export const viewPortAction = (dispatch, isInViewPort) => {
  dispatch(updateLiveViewPort(isInViewPort));
};
export const updateLiveViewPort = (isInViewPort) => ({
  type: IS_IN_VIEW_PORT,
  isInViewPort: isInViewPort,
});
export const updateLiveListing = (dispatch) => {
  dispatch(updateLiveFeedListing());
};
export const updateLiveFeedListing = () => ({ type: UPDATE_LIVE_FEED });

export const fetchingLiveEvent = (action) => ({ type: FETCH_LIVE_EVENT, action });
export const setLiveEvent = (response, timestamp) => ({
  type: SET_LIVE_EVENT,
  response,
  timestamp,
});
export const error = (response) => ({ type: ERROR_LIVE_EVENT, response });
