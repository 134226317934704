/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { createBaseUrl, getLanguage } from '../../utils/utils';
const MuLogo = ({ logo }) => {
  /**
   * set base url with language on logo link
   */
  const getUrl = () => {
    return `${createBaseUrl()}/${getLanguage()}`;
  };
  const setLogoBg = () => {
    let bgpath = logo && logo.value && logo.value.Preview;
    return bgpath
      ? {
          backgroundImage: `url(${bgpath})`,
        }
      : {};
  };
  return (
    <React.Fragment>
      <div className="secondary-header-logo">
        <a href={getUrl()}>
          <span
            className="icon-mu-logo-cms"
            role="img"
            aria-label={
              logo && logo.value && logo.value.AltText
                ? logo.value.AltText
                : 'Manchester United Logo'
            }
            style={setLogoBg()}
          ></span>
          <span
            className="icon-mu-logo-cms-small"
            role="img"
            aria-label={
              logo && logo.value && logo.value.AltText
                ? logo.value.AltText
                : 'Manchester United Logo'
            }
            style={setLogoBg()}
          ></span>
          <img
            src={logo && logo.value && logo.value.Preview}
            className="logo-icon visually-hidden"
            tabIndex="-1"
            title={logo && logo.value && logo.value.AltText}
            alt=""
            aria-hidden="true"
            style={{ opacity: 0 }}
          />
          <span className="screenreader">click to go to homepage</span>
        </a>
      </div>
    </React.Fragment>
  );
};

export default React.memo(MuLogo);
