/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import StatsBar from '../../Common/StatsBar';
import LazyLoad from 'react-lazyload';
import ImgCrop from '../../Common/ImgCrop';
import {
  getStatsBarData,
  getSponsorData,
  getItemId,
  isApp,
  isCollection,
} from '../../../utils/utils';
import { CONTENT_TYPE } from '../../../constants/globalConstants';
import Sponsor from '../../Common/Sponsor';
import * as track from '../../../utils/analytics';
import { useModal } from '../../Common/Modal';
import loadable from '@loadable/component';
import Loader from '../../Common/Loader';
import ErrorBoundary from '../../Common/ErrorBoundary';
const ImageModal = loadable(() => import('../../Modals/ImageModal/ImageModal'));

const Image = (props) => {
  const {
    t,
    fields,
    gridClass,
    isMatchcenterCard,
    data,
    container,
    tracking,
    isInlineImage,
    showStatsbar,
    isArticleDetail,
    isBlogCard,
    hideGradient,
  } = props;
  const { showModal, closeModal, isModalVisible } = useModal();
  const imageCrops =
    fields['Image File'] && fields['Image File'].value && fields['Image File'].value.Crops;
  const imageAltText =
    fields['Image File'] && fields['Image File'].value && fields['Image File'].value.AltText;
  const isWebpAvailable =
    fields['Image File'] &&
    fields['Image File'].value &&
    fields['Image File'].value.isWebpAvailable;
  const sponsorInfo = fields.Sponsorby && getSponsorData(fields.Sponsorby.fields);
  const itemId = getItemId(props);
  const trackCard = (fields, e) => {
    const initialData = track.data('card');
    track.analytics(
      {
        ...initialData,
        card_name: fields.Teaser && fields.Teaser.value,
        container_type: container,
        item_id: itemId,
        content_type: !isBlogCard ? fields.ContentType && fields.ContentType.value : 'blogentry',
        tag: fields.manutds && fields.manutds.value && fields.manutds.value.Tags,
        created_datetime: fields.__Published && fields.__Published.value,
        author: fields.AuthorName && fields.AuthorName.value,
        destination_url:
          fields.manutds && fields.manutds.value && fields.manutds.value.DestinationUrl,
      },
      e
    );
  };

  return (
    <image-card>
      <div
        className={`${gridClass} ${isMatchcenterCard ? 'ratio-matchday' : 'ratio'} item`}
        id={data && data.rawitemid_s}
        onClick={(e) => {
          !isInlineImage &&
            showModal(
              fields.manutds && fields.manutds.value && fields.manutds.value.DestinationUrl
            );
          tracking && trackCard(fields, e);
        }}
        data-impression={
          tracking
            ? track.impression({
                card_name: fields.Teaser && fields.Teaser.value,
                content_type: !isBlogCard
                  ? fields.ContentType && fields.ContentType.value
                  : 'blogentry',
              })
            : null
        }
      >
        <div className="mu-content">
          <article
            className={`mu-item image ${
              isArticleDetail
                ? isApp() && isCollection()
                  ? 'open-destinationpage'
                  : 'open-modal'
                : ''
            }`}
          >
            <div className="img-holder">
              {imageCrops && (
                <LazyLoad once={true} offset={100}>
                  <ImgCrop
                    imgCrop={imageCrops}
                    alt={imageAltText}
                    title={imageAltText}
                    isWebpAvailable={isWebpAvailable}
                    gridClass={gridClass}
                    titleAvailable={hideGradient ? false : true}
                  ></ImgCrop>
                </LazyLoad>
              )}
            </div>

            <Sponsor
              contentType={fields.ContentType && fields.ContentType.value}
              varient={fields.Variant && fields.Variant.value}
              sponsorInfo={sponsorInfo}
              grid={gridClass}
              type={CONTENT_TYPE}
              author={fields.AuthorName && fields.AuthorName.value}
              cardName={fields.Teaser && fields.Teaser.value}
              container={container}
              createdDateTime={fields.__Published && fields.__Published.value}
              itemId={itemId}
              tags={fields.manutds && fields.manutds.value && fields.manutds.value.Tags}
            ></Sponsor>
            {!isInlineImage && (
              <div className="mu-item__info">
                <p className="mu-item__teasure" tabIndex="0" role="link">
                  <i className="icon-camera-white"></i>
                  {((fields.Teaser && fields.Teaser.value) ||
                    (fields.BlogDescription && fields.BlogDescription.value)) && (
                    <Text field={fields.Teaser || fields.BlogDescription} />
                  )}
                  <span className="screenreader">
                    &nbsp;{fields.ContentType && fields.ContentType.value}
                  </span>
                </p>
              </div>
            )}

            {!isInlineImage && showStatsbar && (
              <StatsBar item={getStatsBarData(fields, isBlogCard)}></StatsBar>
            )}
          </article>
        </div>
        {isInlineImage && props.caption && (
          <div className="content-bar">
            <span className="icon-camera"></span>
            <span>{props.caption}</span>
          </div>
        )}
      </div>
      {isModalVisible && !isInlineImage && (
        <ErrorBoundary>
          <ImageModal
            closeModal={closeModal}
            itemid={itemId}
            fallback={<Loader />}
            imageData={fields}
          />
        </ErrorBoundary>
      )}
    </image-card>
  );
};
Image.defaultProps = {
  isInlineImage: false,
  tracking: true,
  showStatsbar: true,
  isArticleDetail: false,
};
export default withTranslation()(Image);
