/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import AppRoot from './AppRoot';
import config from './temp/config';
import i18ninit from './i18n';
import MU, { muMock } from './constants/muConstants';
import { loadableReady } from '@loadable/component';
import { checkBrowserWebpSupport } from './utils/webp';
import { WEBP_LOSSY } from './constants/globalConstants';

/* eslint-disable no-underscore-dangle */

let renderFunction = ReactDOM.render;

let initLanguage = config.defaultLanguage;

/*
  SSR Data
  If we're running in a server-side rendering scenario,
  the server will provide JSON in the #__JSS_STATE__ element
  for us to acquire the initial state to run with on the client.

  This enables us to skip a network request to load up the layout data.
  We are emitting a quiescent script with JSON so that we can take advantage
  of JSON.parse()'s speed advantage over parsing full JS, and enable
  working without needing `unsafe-inline` in Content Security Policies.

  SSR is initiated from /server/server.js.
*/
let __JSS_STATE__ = null;
const ssrRawJson = document.getElementById('__JSS_STATE__');
if (ssrRawJson) {
  __JSS_STATE__ = JSON.parse(ssrRawJson.innerHTML);
}
if (__JSS_STATE__) {
  if (
    __JSS_STATE__.sitecore &&
    __JSS_STATE__.sitecore.context &&
    __JSS_STATE__.sitecore.context.MU
  ) {
    const {
      MU: Mu,
      MUPageLevel,
      ContentAccessRulesResponse,
      MarketAccessRulesResponse,
    } = __JSS_STATE__.sitecore.context;
    MU.setMU(Mu);
    MU.setMU(MUPageLevel);
    MU.setMU({ ContentAccessRulesResponse, MarketAccessRulesResponse });
  }

  // when React initializes from a SSR-based initial state, you need to render with `hydrate` instead of `render`
  renderFunction = ReactDOM.hydrate;

  // set i18n language SSR state language instead of static config default language
  initLanguage = __JSS_STATE__.sitecore.context.language;
} else {
  process.env.NODE_ENV === 'development' && MU.setMU(muMock);
}

/*
  App Rendering
*/
// initialize the dictionary, then render the app
// note: if not making a multlingual app, the dictionary init can be removed.
i18ninit(initLanguage)
  .then(() => {
    // HTML element to place the app into
    initApp();
  })
  .catch(() => {
    //i18 fallback
    initApp();
  });

const initApp = () => {
  loadableReady(() => {
    checkBrowserWebpSupport(WEBP_LOSSY, () => {
      const rootElement = document.getElementById('root');
      renderFunction(
        <AppRoot path={window.location.pathname} Router={BrowserRouter} ssrState={__JSS_STATE__} />,
        rootElement
      );
    });
  });
};
