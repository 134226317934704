/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { withTranslation } from 'react-i18next';
import { isApp, isCollection, createDestinationUrl, getParameterByName } from '../../utils/utils';
import { dialog } from '../Modals/RegistrationModal/CBRModalAcessibility';
/**
 * Modal component to initialise all the modals inside #modal-root element.
 */
const Modal = React.memo(
  ({
    children,
    closeModal,
    onClose,
    t,
    headerChildren,
    containerClass,
    ariaDescribedBy,
    footerChildren,
    headerClass,
    modalType,
    animate,
    showClose,
    customElm,
    previousActiveElement,
    modalId,
  }) => {
    const modalRootElement = document.getElementById(!modalId ? 'modal-root' : modalId);
    //const modalRootElement = document.getElementById('modal-root');
    const closeButton = useRef(null);
    const dialogEl = useRef(null);
    const focusedElBeforeOpen = useRef(previousActiveElement);
    const firstFocusable = useRef(null);
    const lastFocusable = useRef(null);
    const focusableList = useRef(null);
    useEffect(() => {
      addAnimateClass();

      previousActiveElement &&
        dialog(
          dialogEl,
          focusableList,
          firstFocusable,
          lastFocusable,
          focusedElBeforeOpen,
          closeModal,
          showClose
        );
      closeButton && closeButton.current && closeButton.current.focus();
      window.addEventListener('keyup', handleKeyUp);
      return () => {
        window.removeEventListener('keyup', handleKeyUp);
      };
    }, []);

    const [parentElm, setParentElm] = useState('');
    const storedUrl = sessionStorage.getItem('currentSearchPageURL');

    const close = () => {
      if (storedUrl) {
        sessionStorage.removeItem('currentSearchPageURL');
        window.location.href = storedUrl;
        return;
      } else {
        setParentElm('close-modal-active close-modal-animation');
        closeModal();
        try {
          if (
            previousActiveElement &&
            document.activeElement &&
            document.activeElement.classList &&
            document.activeElement.classList.contains('non-keyboard-outline') &&
            focusedElBeforeOpen &&
            focusedElBeforeOpen.current
          ) {
            focusedElBeforeOpen.current.focus(); // To handle the enter button case.
            setTimeout(() => focusedElBeforeOpen.current.classList.add('non-keyboard-outline'), 50);
          }
        } catch (e) {
          console.log('Error', e);
        }
      }
      onClose();
    };

    const addAnimateClass = () => {
      setTimeout(() => {
        setParentElm('animate-active modal-animate');
      }, 100);
    };

    const renderModalDom = () => {
      return (
        <div
          className={containerClass}
          aria-describedby={ariaDescribedBy}
          ref={dialogEl}
          role="dialog"
        >
          <div className={`mu-modal cardsModalAccessibility ${modalType}`}>
            <div className={`mu-modal__header ${headerClass}`}>
              {showClose && (
                <button
                  className="close-btn accessibilityCloseModal"
                  tabIndex="0"
                  aria-label={t('close')}
                  onClick={() => close()}
                  ref={closeButton}
                >
                  <i className="icon-cross" data-closemodal="close"></i>
                </button>
              )}
              {headerChildren}
            </div>
            <div className="mu-modal__body">{children}</div>
            {footerChildren && <div className="mu-modal__footer">{footerChildren}</div>}
          </div>
        </div>
      );
    };

    const renderModal = () => {
      if (animate) {
        return (
          <div className="modal-outer">
            <div className={parentElm ? parentElm : 'modal-animate'}>{renderModalDom()}</div>
          </div>
        );
      } else if (customElm) {
        return <div className="modal-outer">{renderModalDom()}</div>;
      } else {
        return renderModalDom();
      }
    };

    const handleKeyUp = (event) => {
      //esc is pressed
      const activeEle = document.activeElement;
      const isContains = dialogEl && dialogEl.current && dialogEl.current.contains(activeEle);
      if (event.keyCode === 27 && isContains) {
        close();
        try {
          if (previousActiveElement && focusedElBeforeOpen && focusedElBeforeOpen.current) {
            setTimeout(() => focusedElBeforeOpen.current.focus(), 50); // To handle the enter button case.
            focusedElBeforeOpen.current.classList.add('non-keyboard-outline');
          }
        } catch (e) {
          console.log('Error', e);
        }
      }
    };

    if (!modalRootElement) return null;
    return ReactDOM.createPortal(renderModal(), modalRootElement);
  },
  areEqual
);
Modal.defaultProps = {
  onClose: () => {},
  containerClass: 'dialog modal',
  ariaDescribedBy: null,
  headerClass: '',
  modalType: '',
  animate: false,
  showClose: true,
  previousActiveElement: '',
};

/**
 * This is a custom hook to render a modal inside any component
 */
export const useModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = (destinationUrl = '', isShowModal) => {
    if (!isApp() && !isCollection()) {
      setIsModalVisible(true);
      const element = document.getElementsByTagName('BODY');
      if (element) {
        element[0].style.overflow = 'hidden';
        element[0].style.position = 'static';
      }
    } else if (isCollection() && !isShowModal) {
      const win = window.open(createDestinationUrl(destinationUrl), '_blank');
      win && win.focus();
    } else if (!isShowModal) {
      window.location.href = createDestinationUrl(destinationUrl) + '?request=muweb';
    } else {
      setIsModalVisible(true);
    }
  };

  const closeModal = (isShowModal = false) => {
    const modalRootElement = document.getElementById('modal-root');
    const divLength = modalRootElement && modalRootElement.childElementCount;
    setIsModalVisible(false);
    const element = document.getElementsByTagName('BODY');
    if (element) {
      if (divLength && divLength <= 1 && !isShowModal) {
        element[0].removeAttribute('style');
      }
      const hoverOverlayElement = document.getElementById('hover-overlay');
      if (hoverOverlayElement && hoverOverlayElement.style && hoverOverlayElement.style.opacity) {
        hoverOverlayElement.style.display = 'none';
        hoverOverlayElement.style.opacity = 0;
      }
    }
    const hoverOverlayElement = document.getElementById('hover-overlay');
    if (hoverOverlayElement && hoverOverlayElement.style && hoverOverlayElement.style.opacity) {
      hoverOverlayElement.style.display = 'none';
      hoverOverlayElement.style.opacity = 0;
    }
  };

  return {
    showModal,
    closeModal,
    isModalVisible,
  };
};
const areEqual = (prevProps, nextProps) => true;
Modal.displayName = 'Modal';
export default withTranslation()(Modal);
