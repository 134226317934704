/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { createContext, useContext, useReducer } from 'react';
import { matchCenterState, matchCenterReducer } from '../reducers/matchCenterReducer';

export const MatchCenterContext = createContext();

export const MatchCenterProvider = ({ children }) => (
  <MatchCenterContext.Provider value={useReducer(matchCenterReducer, matchCenterState)}>
    {children}
  </MatchCenterContext.Provider>
);
export const useMatchCenterValue = () => useContext(MatchCenterContext);

export default MatchCenterContext;
