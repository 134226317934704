/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { withTranslation } from 'react-i18next';
import * as track from '../../../utils/analytics';
import { triggerSignUp } from '../../../utils/idmUtils';
const SignUp = ({ t, itemName, ishumberger, getLoginDetail, setIsListOpen, isHeader, isMutv }) => {
  /**
   *
   * @param {clicked event object} e
   */
  const trackLink = (e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: !ishumberger ? t('SignUp') : itemName,
        destination_url: 'javascript:void(0)',
      },
      e
    );
  };

  const showSignUpModal = (e) => {
    e.preventDefault();
    trackLink(e);
    triggerSignUp(getLoginDetail, isMutv);
  };
  const updateAttrs = () => {
    if (isHeader) {
      return { tabIndex: '-1' };
    }
  };
  return (
    <div className={ishumberger ? 'white-btn-skew btn' : 'red-btn-skew btn'}>
      <a href="#" onClick={(e) => showSignUpModal(e)} role="link" {...updateAttrs()}>
        {!ishumberger ? <span>{t('SignUp')}</span> : <span>{itemName}</span>}
        <span className="screenreader">Open overlay</span>
      </a>
    </div>
  );
};

export default withTranslation()(SignUp);
