/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import { arr, calculateSliderHeight } from './mutvutil';
export const Shimmer = (props) => {
  const aspectRatioType = (aspectRatio) => {
    const isDiscover = props.isDiscover;
    const contentType = props.carouselContentType;
    return calculateSliderHeight(aspectRatio, contentType, isDiscover);
  };
  return (
    <React.Fragment>
      {arr.map((item, index) => {
        return !props.isEpg ? (
          <div className="mobile-listitem" role="listitem" key={index}>
            <div className={`shimmer`}>
              <div className={`mutv-wrapper `}>
                <div className={`grid-3 ratio ${aspectRatioType(props.aspectRatio)}`}>
                  <div className="mu-content">
                    <div className="mu-item video">
                      <div className="img-holder_shimmer"></div>
                      {((props.aspectRatio && props.aspectRatio !== '3x4') ||
                        (!props.aspectRatio &&
                          props.carouselContentType === 'continuelistening')) && (
                        <div className="mu-item__info_shimmer">
                          <h2 className="mu-item__title_shimmer"></h2>
                          <p className="mu-item__teasure_shimmer"></p>
                        </div>
                      )}
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="mobile-listitem shimmerbox" role="listitem" key={index}>
            <div className={`shimmer`}>
              <div className="mutv-wrapper">
                {props.noCard ? (
                  <div className="epg-box epg-box__emptyCard">
                    <div className="epg-box__nocard">
                      <p>{props.t('Schedulenotavailable')}</p>
                      <span>{props.t('Schedulenotavailabledesc')}</span>
                    </div>
                  </div>
                ) : (
                  <div className="epg-box">
                    <div className="epg-box__field"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};
export default withTranslation()(Shimmer);
