/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { isTouchDevice } from '../../utils/utils';
import { HighlightCard } from './HighlightCard';

const BlogHighlights = ({ t, blogHighlights }) => {
  const [touchDevice, setTouchDevice] = useState(false);
  const [slidermove, setSlidermove] = useState(0);
  const [cardVisible, setCardVisible] = useState(0);
  const [carousalWidth, setCarousalWidth] = useState(0);
  const [card] = useState({ cardWidth: 169 });
  const sliderRef = useRef(null);

  useEffect(() => {
    setTouchDevice(isTouchDevice);
    setCarousalWidth(sliderRef.current.getBoundingClientRect().width);
  }, [carousalWidth]);

  /**
   * @define {slider movement logic for next slide}
   */
  const nextCard = () => {
    let totalWidth = card.cardWidth * blogHighlights.length;
    let totalSlidedWidth = carousalWidth + Math.abs(slidermove) + card.cardWidth;
    let lastSideToMove = totalSlidedWidth - totalWidth;
    if (cardVisible < blogHighlights.length) {
      totalWidth > totalSlidedWidth
        ? setSlidermove(slidermove - card.cardWidth)
        : setSlidermove(slidermove - (card.cardWidth - lastSideToMove));
      setCardVisible(cardVisible + 1);
    }
  };

  /**
   * @define {slider movement logic for previous slide}
   */
  const prevCard = () => {
    if (cardVisible <= blogHighlights.length) {
      Math.abs(slidermove) > card.cardWidth
        ? setSlidermove(slidermove + card.cardWidth)
        : setSlidermove(0);
      setCardVisible(cardVisible - 1);
    }
  };

  /**
   * @define {hide arrow, if item is less than screen width}
   */
  const arrowStatus = () => {
    if (!touchDevice) {
      let wWidth = window.innerWidth;
      let status = false;
      if (wWidth <= 420) {
        blogHighlights.length < 3 ? (status = false) : (status = true);
      } else if (wWidth > 420 && wWidth <= 668) {
        blogHighlights.length < 4 ? (status = false) : (status = true);
      } else if (wWidth > 668 && wWidth <= 820) {
        blogHighlights.length < 5 ? (status = false) : (status = true);
      } else if (wWidth > 820) {
        blogHighlights.length < 5 ? (status = false) : (status = true);
      }
      return status;
    }
    return true;
  };

  return (
    <React.StrictMode>
      {blogHighlights && (
        <div className="non-matchday-blog" ref={sliderRef}>
          <div className="non-matchday_hightlights">
            <div
              className="non-matchday-buttons"
              role="region"
              aria-labelledby="non-matchdayhighlights"
            >
              <h2 id="non-matchdayhighlights">{t('Highlights')}</h2>
              {!touchDevice && arrowStatus() && (
                <React.StrictMode>
                  <span
                    className="icon-arrow-next blog-arrow_button"
                    aria-hidden="true"
                    onClick={() => nextCard()}
                  ></span>
                  <span
                    className="icon-arrow-prev blog-arrow_button"
                    aria-hidden="true"
                    onClick={() => prevCard()}
                  ></span>
                </React.StrictMode>
              )}
            </div>
            <div className={touchDevice === true ? 'non-matchday__swipe' : ''}>
              <ul
                className="non-matchday-blogs__slider"
                style={{
                  width: touchDevice !== true ? `${169 * blogHighlights.length}px` : '100%',
                  left: slidermove,
                }}
              >
                {blogHighlights.map((item, index) => (
                  <HighlightCard item={item} key={index} />
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </React.StrictMode>
  );
};

export default withTranslation()(BlogHighlights);
