/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useEffect } from 'react';
import { DARK_THEME, CONTENT_TYPE } from '../../constants/globalConstants';
import ImageFallback from '../Common/ImageFallback';
import Sponsor from '../Common/Sponsor';
import { getSponsorData } from '../../utils/utils';
import { PLAYER_PROFILE } from '../../constants/containerConstants';
import { useSponsorValue } from '../../context/sponsorContext';
import NextPrevious from './NextPrevious';
import { withTranslation } from 'react-i18next';
import Profile from './Profile';
import PlayerHubIcon from './PlayerHubIcon';
const Academy = ({ t, fields, itemId }) => {
  const {
    ProfileImage,
    ContentType,
    Sponsorby,
    AuthorName,
    FullName,
    __Published,
    manutds,
    OnLoan,
    CTAUrl,
    CTATitle,
    NextPlayer,
    FirstName,
    LastName,
    PlayerTag,
    Variant,
    IsEnablePlayerHub,
    CTATitlePlayerHub,
    CTAUrlPlayerHub,
  } = fields;
  const sponsorInfo = Sponsorby && getSponsorData(Sponsorby.fields);
  const [{ userLoginDetail }] = useSponsorValue();
  const [starIcon, setStarIcon] = useState(false);
  useEffect(() => {
    if (
      PlayerTag &&
      userLoginDetail &&
      userLoginDetail.data &&
      PlayerTag.value === userLoginDetail.data.favoritePlayerID
    ) {
      setStarIcon(true);
    }
  }, [userLoginDetail]);
  return (
    <div
      className="player-detail"
      id="playerDetail"
      data-test="academy"
      data-player-tag={PlayerTag && PlayerTag.value}
      data-optimizelycontentid={itemId && itemId}
    >
      <div className="player-detail__wrapper">
        <div className="bg-light-gray-mask"></div>
        <div className="player-detail__bg-black-mask">
          <div className="img__thumb">
            <ImageFallback
              src={ProfileImage && ProfileImage.value && ProfileImage.value.Preview}
              alt={ProfileImage && ProfileImage.value && ProfileImage.value.AltText}
              title={ProfileImage && ProfileImage.value && ProfileImage.value.Title}
              isWebpAvailable={
                ProfileImage && ProfileImage.value && ProfileImage.value.isWebpAvailable
              }
              relativePath={false}
              onErrorClass="no-img"
            />
          </div>
          <div className="player-detail__bg-gray-mask"></div>
        </div>
        <div className="container player-detail__main">
          <div className="row">
            <Sponsor
              contentType={ContentType && ContentType.value}
              varient={Variant && Variant.value}
              sponsorInfo={sponsorInfo}
              grid={'grid-12'}
              type={CONTENT_TYPE}
              author={AuthorName && AuthorName.value}
              cardName={ContentType && ContentType.value}
              container={PLAYER_PROFILE}
              createdDateTime={__Published && __Published.value}
              itemId={itemId}
              tags={manutds && manutds.value && manutds.value.Tags}
              theme={DARK_THEME}
            ></Sponsor>
            <article className="grid-12 player-detail__main__wrapper">
              <div className="player-info">
                <div
                  className={`player-info__wrapper academy ${starIcon && 'hasFav'}`}
                  data-module="social-share"
                >
                  <div className={`player-info__main`}>
                    {starIcon && (
                      <div className="favourite-player">
                        <i className="icon-star"></i>
                      </div>
                    )}
                    <h1 className="player-name">
                      {FirstName && FirstName.value}
                      <br />
                      {LastName && LastName.value}
                    </h1>
                    {OnLoan && OnLoan.value && <span className="player-hire">{t('Onloan')}</span>}
                  </div>
                  {IsEnablePlayerHub && CTAUrlPlayerHub && (
                    <PlayerHubIcon
                      IsEnablePlayerHub={IsEnablePlayerHub}
                      CTAUrlPlayerHub={CTAUrlPlayerHub}
                      CTATitlePlayerHub={CTATitlePlayerHub}
                    ></PlayerHubIcon>
                  )}
                  {CTAUrl && CTAUrl.value && CTAUrl.value.hrefMuCustom && (
                    <a
                      className="player_buy-shirt-cta"
                      href={CTAUrl.value.hrefMuCustom}
                      target="_blank"
                      data-an-track="true"
                      data-track-type="link"
                      data-track-text={CTATitle && CTATitle.value}
                    >
                      <span className="red-btn-skew">
                        {CTATitle && CTATitle.value}
                        <span className="screenreader">{t('ExternalWebsiteLink')}</span>
                      </span>
                    </a>
                  )}
                </div>
              </div>
              {NextPlayer && <NextPrevious itemId={itemId} fields={fields} />}
            </article>
          </div>
          <div className="row">
            <Profile fields={fields} itemId={itemId} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default withTranslation()(Academy);
