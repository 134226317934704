/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { getItemId } from '../../utils/utils';
import Manager from '../PlayerProfileDetail/Manager';
const ManagerDetail = (props) => {
  const { fields } = props;
  const itemId = getItemId(props);
  return <Manager fields={fields} itemId={itemId}></Manager>;
};
export default ManagerDetail;
