/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import LeftPanel from './LeftPanel';
import StatsBar from '../Common/StatsBar';
import SponsorPanel from './SponsorPanel';
import ImagePanel from './ImagePanel';
import { getStatsBarData } from '../../utils/utils';
import ErrorBoundary from '../Common/ErrorBoundary';

const BreakdownPercentage = ({ item, t, gridClass, carousalClass }) => {
  const isPlayer =
    item && item.opta_statentitytype_t && item.opta_statentitytype_t.toLowerCase() === 'player'
      ? true
      : false;
  return (
    <div className={`${gridClass} matchday-stats`}>
      <div className={carousalClass}>
        <div
          className={`stats-card brekdown-percentage ${item.IsMUEntityClass} ${
            isPlayer ? 'player-section' : 'team-section'
          }`}
        >
          {item.percntVal > 0 && (
            <div
              className={`brekdown-percentage__value ${
                item.percntVal === 100 || item.percntVal === '100' ? 'is-completed' : ''
              }`}
              style={{ width: `${item.percntVal}%` }}
            ></div>
          )}
          <div className="brekdown-percentage__info">
            <SponsorPanel item={item} />
            <div className="stats-card__info">
              <LeftPanel item={item} />
              <div className="stats-card__info__right">
                <div className="chart-area">
                  <div className="brekdown-percentage__details">
                    <div className="brekdown-percentage__details__left">
                      <div className="players__notification" aria-hidden="true">
                        <div className={`player-img ${isPlayer ? 'player-image' : 'team-image'}`}>
                          <ErrorBoundary>
                            <ImagePanel item={item} statsType={isPlayer} />
                          </ErrorBoundary>
                          {isPlayer && <span className="notification">{item.JerseyNumber}</span>}
                        </div>
                        {isPlayer && <h3>{item.KnownName ? item.KnownName : item.LastName}</h3>}
                      </div>
                      <div className="player__percentage-value" aria-hidden="true">
                        {` ${item.percntVal}${
                          item.IsPercentage === 'true' || item.IsPercentage === true ? '%' : ''
                        }`}
                      </div>

                      <span className="screenreader">
                        {isPlayer
                          ? `${item.percntVal} ${item.IsPercentage ? '%' : ''} ${
                              item.cardheading_t
                            } ${t('by')} ${item.KnownName ? item.KnownName : item.LastName} ${t(
                              'Shirtnumber'
                            )} ${item.JerseyNumber}`
                          : `${item.percntVal} ${item.IsPercentage ? '%' : ''} ${
                              item.cardheading_t
                            } ${t('by')} ${item.TeamName}`}
                      </span>
                    </div>
                    <div className="brekdown-percentage__details__right">
                      <ul>
                        {item.opta_statentities_t[0].statValues &&
                          item.opta_statentities_t[0].statValues.map(
                            (value, key) =>
                              key > 0 && (
                                <li key={key} className="brekdown-percentage__info">
                                  <span aria-hidden="true">{value.StatsName} </span>
                                  <span className="screenreader">
                                    {isPlayer
                                      ? `${value.StatsName} ${t('by')} ${
                                          item.KnownName ? item.KnownName : item.LastName
                                        } ${value.Value} ${
                                          value.IsPercentage === 'true' ||
                                          value.IsPercentage === true
                                            ? '%'
                                            : ''
                                        }`
                                      : `${value.StatsName} ${t('by')}  ${item.TeamName} ${
                                          value.Value
                                        } ${
                                          value.IsPercentage === 'true' ||
                                          value.IsPercentage === true
                                            ? '%'
                                            : ''
                                        }`}
                                  </span>

                                  <span aria-hidden="true">
                                    {`${value.Value}${
                                      value.IsPercentage === 'true' || value.IsPercentage === true
                                        ? '%'
                                        : ''
                                    }`}
                                  </span>
                                </li>
                              )
                          )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <StatsBar item={getStatsBarData(item)} type="statsCard"></StatsBar>
        </div>
      </div>
    </div>
  );
};
export default withTranslation()(BreakdownPercentage);
