/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import Video from '../Video';

const Video360Degree = (props) => <Video {...props} />;

export default Video360Degree;
