/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import muApi from '../../../utils/muApi';
import Video from '../../Cards/Video';
import { LIVESTREAMVIAEPG } from '../../../constants/endpoints';
import { HERO_VIDEO } from '../../../constants/containerConstants';
import { checkResponseDocs, transformLiveStreamViaEPG, getItemId } from '../../../utils/utils';
import ErrorBoundary from '../../Common/ErrorBoundary';
import Loader from '../../Common/Loader';
import loadable from '@loadable/component';
import MU from '../../../constants/muConstants';
import { redirectTo } from '../../../utils/utils';
import { REGISTERED } from '../../../constants/globalConstants';
const LiveStreamModal = loadable(() => import('../../Modals/VideoModal/LiveStreamModal'));

const HeroBannerLiveStreamViaEPG = ({ fields, t, isDestinationPage, showModal }) => {
  const timer = useRef(null);
  const retryRef = useRef(0);
  const language = MU.language ? MU.language : '';
  const currentLiveStream = (item) => {
    const startTime = moment(item.startdatetime_tdt);
    const endTime = moment(item.enddatetime_tdt);
    return moment().isBetween(startTime, endTime);
  };

  const timerFunction = () => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      callApi();
    }, 60000);
  };

  const getData = ({ Response, LiveVideoStreamId, IsEnableHomePageLiveStream }) => {
    if (IsEnableHomePageLiveStream && Response.enablehomepagelivestream_b) {
      timerFunction();
      const destinationUrl =
        fields &&
        fields.EPGLiveStreamResponse &&
        fields.EPGLiveStreamResponse.EPGLiveStreamDestinationUrl;
      return transformLiveStreamViaEPG({ data: Response, LiveVideoStreamId, destinationUrl });
    }
    return null;
  };

  const setStateData = () => {
    if (fields && fields.EPGLiveStreamResponse && fields.EPGLiveStreamResponse.Response) {
      const {
        Response,
        LiveVideoStreamId,
        IsEnableHomePageLiveStream,
      } = fields.EPGLiveStreamResponse;
      return getData({ Response, LiveVideoStreamId, IsEnableHomePageLiveStream });
    }
    return null;
  };

  const [state, setState] = useState(setStateData());
  const [mediaStatus, setMediaStatus] = useState({});
  const [liveStreamEnded, setLiveStreamEnded] = useState({ value: false });

  const callApi = () => {
    const route = LIVESTREAMVIAEPG;
    return muApi
      .get({ route })
      .then((apiData) => {
        let finalData;
        retryRef.current = 0;
        if (apiData && apiData.data) {
          const Response =
            checkResponseDocs(apiData.data.EpgLiveStreamResponse) &&
            apiData.data.EpgLiveStreamResponse.response.docs.find((item) =>
              currentLiveStream(item)
            );
          const LiveVideoStreamId =
            checkResponseDocs(apiData.data.AudioStreamResponse) &&
            apiData.data.AudioStreamResponse.response.docs[0].videostreamid_t;
          const IsEnableHomePageLiveStream =
            checkResponseDocs(apiData.data.MuTvContainerResponse) &&
            apiData.data.MuTvContainerResponse.response.docs[0].isenablehomepagelivestream_b;
          finalData =
            Response && getData({ Response, LiveVideoStreamId, IsEnableHomePageLiveStream });
        }
        setState(finalData);
      })
      .catch((err) => {
        retryRef.current = retryRef.current + 1;
        if (retryRef.current > 2) {
          setState(null);
        } else {
          callApi();
        }
      });
  };

  const renderEndedMsg = () => {
    if (liveStreamEnded && liveStreamEnded.value) {
      return liveStreamEnded.contentAccess === REGISTERED
        ? t('LiveStreamEpgNoRegAccess')
        : t('LiveStreamEpgNoSubAccess');
    }
    return t('LiveStreamEpgEnded');
  };

  return (
    <React.Fragment>
      {state && !isDestinationPage && (
        <div className="home-row">
          <div className="home-hero">
            <Video
              {...state}
              container={HERO_VIDEO}
              heroVarient="parent"
              gridClass="grid-12"
              isLiveStreamViaEPG={true}
              LiveStreamViaEPGCallback={setMediaStatus}
              setLiveStreamEnded={setLiveStreamEnded}
            />
          </div>
        </div>
      )}
      {state && isDestinationPage && (
        <ErrorBoundary>
          <LiveStreamModal
            fallback={<Loader />}
            showModal={showModal}
            videoData={state.fields}
            onModalClose={() => redirectTo('/', language)}
            is360Video={false}
            isDestinationPage={true}
            isLiveStreamViaEPG={true}
            itemid={getItemId(state)}
            LiveStreamViaEPGCallback={setMediaStatus}
            setLiveStreamEnded={setLiveStreamEnded}
          />
        </ErrorBoundary>
      )}
      {((liveStreamEnded && liveStreamEnded.value) ||
        (!state &&
          ((!isDestinationPage && mediaStatus && mediaStatus.playing) || isDestinationPage))) && (
        <div className="dialog" role="dialog">
          <div className="live-card">
            <div className={`videoModalWrapper open live-error-msg`}>
              <div className="close-btn-wrapper" tabIndex="0">
                <button
                  className="close-btn icon-panel live-dialog-close-btn accessibilityCloseModal videoCloseIcon"
                  tabIndex="0"
                  title="close"
                  aria-label="close"
                  onClick={(e) => {
                    if (isDestinationPage) {
                      redirectTo('/', language);
                    } else if (liveStreamEnded && liveStreamEnded.value) {
                      setLiveStreamEnded({ value: false });
                    } else {
                      callApi();
                      setMediaStatus({ playing: false });
                      const element = document.getElementsByTagName('BODY');
                      if (element) {
                        element[0].removeAttribute('style');
                      }
                    }
                  }}
                >
                  <span>
                    <i className="icon-cross" data-closemodal="close" />
                  </span>
                </button>
              </div>
              <div
                className={`videoModal card-expan`}
                style={mediaStatus && mediaStatus.modalCss ? mediaStatus.modalCss : {}}
              >
                <div
                  className={`stream__content ${
                    mediaStatus && mediaStatus.modalCss ? '' : 'img-holder'
                  }`}
                >
                  <div className="mu-item__gradient" />
                  <h1 className="stream__title">{renderEndedMsg()}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(HeroBannerLiveStreamViaEPG);
