/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';
import { getCssUrl } from '../utils/utils';
import MU from '../constants/muConstants';
import layout from '../constants/layoutConstants';
import SkipToContent from '../components/Common/SkipToContent';

const HomeLayout = ({ route, context }) => {
  const getLoction = () => {
    return getCssUrl(layout.HOME_LAYOUT, MU.language);
  };
  const { pageEditing } = context;
  return (
    <React.Fragment>
      <Helmet>
        {pageEditing && (
          <link href={getCssUrl(layout.EDITOR_LAYOUT, MU.language)} rel="stylesheet" />
        )}
      </Helmet>
      {(typeof window === 'undefined' || process.env.NODE_ENV === 'development') && (
        <Helmet>
          <link href={getLoction()} rel="stylesheet" />
        </Helmet>
      )}
      <div
        className={`page-wrap ${MU.reverseTheme} ${MU.gapClass} ${pageEditing ? 'expeditor' : ''}`}
      >
        <SkipToContent></SkipToContent>
        <Placeholder
          name="mufc-ph-body"
          rendering={route}
          missingComponentComponent={() => {
            return null;
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default HomeLayout;
