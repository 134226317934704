/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import api from '../../utils/muApi';
import { getProviderList, loadScreenSet } from '../../utils/gigyaScreen';
import { CHANGE_EMAIL } from '../../constants/endpoints';
const ChangeEmail = ({ t, gigyaData, closeEmailModal }) => {
  const regEmail = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const [emailError, setEmailError] = useState({
    emailCheck: '',
    confirmEmailCheck: '',
    submitError: '',
  });

  const isValidEmail = (target) => {
    if (target && target.value === '') {
      if (target && target.id === 'email-check') {
        setEmailError({ ...emailError, emailCheck: t('Pleasecompletetheinformationrequired') });
      } else {
        setEmailError({
          ...emailError,
          confirmEmailCheck: t('Pleasecompletetheinformationrequired'),
        });
      }
    } else if (!regEmail.test(target && target.value)) {
      if (target.id === 'email-check') {
        setEmailError({ ...emailError, emailCheck: t('InvalidemailaddressformatPleasechec') });
      } else {
        setEmailError({
          ...emailError,
          confirmEmailCheck: t('InvalidemailaddressformatPleasechec'),
        });
      }
    } else {
      if (target && target.id === 'email-check') {
        setEmailError({ ...emailError, emailCheck: '' });
      } else {
        setEmailError({
          ...emailError,
          confirmEmailCheck: '',
        });
      }
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    let emailTextBox = document.getElementById('email-check').value;
    let confirmEmailTextBox = document.getElementById('email-check2').value;
    if (emailTextBox === '' || confirmEmailTextBox === '') {
      if (emailError.confirmEmailCheck === '') {
        setEmailError({ ...emailError, submitError: t('Pleasecompletetheinformationrequired') });
      }
    } else if (!regEmail.test(emailTextBox) || !regEmail.test(confirmEmailTextBox)) {
      if (emailError.confirmEmailCheck === '') {
        setEmailError({ ...emailError, submitError: t('InvalidemailaddressformatPleasechec') });
      }
    } else if (
      gigyaData &&
      gigyaData.profile &&
      gigyaData.profile.email &&
      emailTextBox.toLowerCase() === gigyaData.profile.email.toLowerCase()
    ) {
      setEmailError({
        ...emailError,
        submitError: t('YourNewEmailAddressCannotBeTheSameAsYourOldEmailAddress'),
      });
    } else if (emailTextBox.trim() !== '' && emailTextBox.trim() === confirmEmailTextBox.trim()) {
      let providerList = getProviderList();
      let context = { loginType: 'reAuth' };
      let params = {
        screenSet: 'MufcWeb-ReAuthentication',
        startScreen: 'gigya-reauthentication-screen',
        enabledProviders: providerList,
        context: context,
        onAfterScreenLoad: loadScreenSet,
        onHide: handleReauthResult,
      };
      window.gigya.accounts.showScreenSet(params);
    } else {
      setEmailError({
        ...emailError,
        submitError: t('TheNewEmailAddressesYouHaveEnteredDoNotMatch'),
      });
    }
  };

  const handleReauthResult = (eventObj) => {
    if (eventObj.reason === 'finished') {
      const dataObj = {
        uId: gigyaData.UID,
        addEmails: document.getElementById('email-check').value,
        removeEmails: gigyaData.profile.email,
      };
      const header = {
        Authorization: `${gigyaData.UID}xa#po#cop3f${gigyaData.UIDSignature}xa#po#cop3f${gigyaData.signatureTimestamp}`,
      };
      const route = CHANGE_EMAIL;
      const data = dataObj;
      const isHardcoredGeo = true;
      return api
        .post({ route, data, header, isHardcoredGeo })
        .then((response) => {
          successResponse(response);
        })
        .catch((err) => {
          errorResponse(err);
        });
    }
  };

  const successResponse = (response) => {
    if (response.data.message === 'success') {
      window.gigya.accounts.logout();
      let context = 'success';
      let params = {
        screenSet: 'MufcWeb-RegistrationLogin',
        startScreen: 'gigya-change-email-message-screen',
        onBeforeScreenLoad: onChangeEmail,
        context: context,
      };
      window.gigya.accounts.showScreenSet(params);
    } else {
      let context = 'error';
      let params = {
        screenSet: 'MufcWeb-RegistrationLogin',
        startScreen: 'gigya-change-email-message-screen',
        onAfterScreenLoad: onChangeEmail,
        context: context,
      };
      window.gigya.accounts.showScreenSet(params);
    }
    setTimeout(() => {
      closeEmailModal();
    }, 100);
  };

  const errorResponse = (response) => {
    if (
      response &&
      response.error &&
      response.error.message === 'Login identifier exists' &&
      response.error.httpStatus === 403
    ) {
      let context = 'exist';
      let params = {
        screenSet: 'MufcWeb-RegistrationLogin',
        startScreen: 'gigya-change-email-message-screen',
        onBeforeScreenLoad: onChangeEmail,
        context: context,
      };
      window.gigya.accounts.showScreenSet(params);
    } else {
      let context = 'error';
      let params = {
        screenSet: 'MufcWeb-RegistrationLogin',
        startScreen: 'gigya-change-email-message-screen',
        onAfterScreenLoad: onChangeEmail,
        context: context,
      };
      window.gigya.accounts.showScreenSet(params);
    }
    setTimeout(() => {
      closeEmailModal();
    }, 100);
  };
  const onChangeEmail = (eventObj) => {
    if (eventObj.context === 'success') {
      let sucessElm = document.querySelector('.change-email-success');
      if (sucessElm) {
        sucessElm.style.display = 'block';
      }
    } else if (eventObj.context === 'error') {
      let errorElm = document.querySelector('.change-email-error');
      if (errorElm) {
        errorElm.style.display = 'block';
      }
    } else if (eventObj.context === 'exist') {
      let exitElm = document.querySelector('.change-email-exist');
      if (exitElm) {
        exitElm.style.display = 'block';
      }
    }
  };
  const closeModal = () => {
    closeEmailModal();
  };

  return (
    <div className="row">
      <div
        className="modal email-modal gigya-screen-dialog gigya-style-modern gigya-windows gigya-chrome non-keyboard-outline"
        style={{
          zIndex: '1000000000',
          visibility: 'visible',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        role="dialog"
        aria-modal="true"
        aria-labelledby="myDialog"
        tabIndex="-1"
        aria-hidden="false"
      >
        <div id="gigya-screen-dialog-page-overlay" style={{ display: 'block' }}></div>
        <div className="gigya-screen-dialog-main">
          <div className="gigya-screen-dialog-top">
            <div className="gigya-screen-dialog-caption" tabIndex="0">
              {t('ChangeEmailaddress')}
            </div>
            <div className="gigya-screen-dialog-close">
              <a
                href="javascript:void(0)"
                aria-label={t('CloseButton')}
                role="button"
                className="close email-modal-close accessablity-closebtn accessibilityCloseModal"
                onClick={() => closeModal()}
              ></a>
            </div>
          </div>
          <div className="modal-content gigya-screen gigya-skinning">
            <div className="previous-credentials errorBox" style={{ textAlign: 'center' }}>
              <p
                className="gigya-composite-control gigya-composite-control-header"
                style={{ textAlign: 'center' }}
              >
                {t('Tochangeyouremailaddresspleasecom')}
              </p>
              <div className="old-email email-title">{t('Oldemailaddress')}&nbsp;</div>
              <div className="old-email">
                {gigyaData && gigyaData.profile && gigyaData.profile.email}
              </div>
            </div>
          </div>
          <div className="gigya-screen-dialog-inner modal-content gigya-screen gigya-skinning">
            <form name="changeEmailForm" noValidate>
              <div className="changeemail-input">
                <label htmlFor="email-check" className="gigya-composite-control-label clearfix">
                  {t('Newemailaddress')}
                </label>
                <input
                  id="email-check"
                  type="email"
                  name="newEmail"
                  tabIndex="0"
                  aria-required="true"
                  onChange={(e) => {
                    isValidEmail(e.target);
                  }}
                  onFocus={() => {
                    setEmailError({
                      ...emailError,
                      submitError: '',
                    });
                  }}
                  required
                />
              </div>
              <div>
                <p className="error-msg" role="alert">
                  {emailError.emailCheck}
                </p>
              </div>

              <div>
                <label htmlFor="email-check2" className="gigya-composite-control-label clearfix">
                  {t('confirmemail')}
                </label>
                <input
                  id="email-check2"
                  type="email"
                  name="confirmEmail"
                  tabIndex="0"
                  aria-required="true"
                  onChange={(e) => {
                    isValidEmail(e.target);
                  }}
                  onFocus={() => {
                    setEmailError({
                      ...emailError,
                      submitError: '',
                    });
                  }}
                  required
                />
              </div>
              <div>
                <p className="error-msg" role="alert">
                  {emailError.confirmEmailCheck}
                </p>
              </div>
              <p id="change-email-error" className="error-msg" aria-live="assertive">
                {emailError.submitError}
              </p>
              <div className="gigya-composite-control gigya-composite-control-submit">
                <input
                  type="submit"
                  value={t('Submit')}
                  tabIndex="0"
                  onClick={(e) => submitForm(e)}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(React.memo(ChangeEmail));
