/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/**
 * Layout Constant
 */
const HOME_LAYOUT = '1509b1b6-17a5-486c-b0c6-49b4a78b46b5';
const NEWS_LAYOUT = 'News';
const VIDEO_LAYOUT = 'VideoPage';
const CLIENT_LAYOUT = 'e200bff9-33e2-4394-9fe2-e6d566ac08df';
const NON_DESTINATION_LAYOUT = 'NonDestination';
const EDITOR_LAYOUT = 'Editor';
const SECRETARY = 'secretary';
const HYBRID_ARTICLE = 'HybridArticalDetail';

const TEMPLATE_LAYOUT = '18b65ba1-69ba-48ab-b8cb-538e8e098c08';
const NON_MATCHDAY_LAYOUT = 'NonMatchday';
const SEARCH_LAYOUT = 'Search';
const NEW_SEARCH_lAYOUT = 'newsearch';
const PLAYER_PROFILE_LAYOUT = 'Playerprofile';
const ARTICLE_DETAIL = 'ArticalDetail';
const MYUNITED_LAYOUT = 'MyUnited';
const MATCHCENTER_LAYOUT = 'MatchCenter';
const MATCHES_LAYOUT = 'Matches';
const LISTING_LAYOUT = 'Listing';
const NEWS_DETAIL_LAYOUT = 'd5dd4225-6a27-42d5-8d81-b97d2a74db53';
const MUTV_LAYOUT = '424fe94f-5e53-4266-8ae3-28abe0f3242a';

export default {
  HOME_LAYOUT,
  NEWS_LAYOUT,
  VIDEO_LAYOUT,
  PLAYER_PROFILE_LAYOUT,
  ARTICLE_DETAIL,
  MYUNITED_LAYOUT,
  TEMPLATE_LAYOUT,
  CLIENT_LAYOUT,
  MATCHCENTER_LAYOUT,
  MATCHES_LAYOUT,
  NON_DESTINATION_LAYOUT,
  NON_MATCHDAY_LAYOUT,
  SEARCH_LAYOUT,
  NEW_SEARCH_lAYOUT,
  EDITOR_LAYOUT,
  SECRETARY,
  HYBRID_ARTICLE,
  LISTING_LAYOUT,
  NEWS_DETAIL_LAYOUT,
  MUTV_LAYOUT,
};
