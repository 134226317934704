/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import moment from 'moment';
import i18n from 'i18next';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/ar';
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/zh-cn';
import MU from '../constants/muConstants';
import { getCookie } from './utils';
moment.locale('en');

const arShortMonthName = (month) => {
  if (month) {
    const arLongMonthsName = [
      'كانون الثاني يناير',
      'شباط فبراير',
      'آذار مارس',
      'نيسان أبريل',
      'أيار مايو',
      'حزيران يونيو',
      'تموز يوليو',
      'آب أغسطس',
      'أيلول سبتمبر',
      'تشرين الأول أكتوبر',
      'تشرين الثاني نوفمبر',
      'كانون الأول ديسمبر',
    ];
    const dicShortMonth = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    var i = 0;
    while (i != 12) {
      if (arLongMonthsName[i].includes(month)) {
        var shortMonth = i18n.t(dicShortMonth[i]);
        if (shortMonth) {
          return i18n.t(dicShortMonth[i]);
        } else {
          return dicShortMonth[i];
        }
      }
      i++;
    }
  }
};

export const formatDate = (dateParam, dateFormat) => {
  try {
    let locale = MU.language;
    if (locale === 'zh') {
      locale = 'zh-cn';
    }
    const d = new Date(dateParam);
    let dddd = moment(d).locale(locale).format('dddd');
    dddd = dddd.charAt(0).toUpperCase() + dddd.slice(1);
    const yyyy = moment(d).locale('en').format('YYYY');

    let mmmm, mmm;
    if (locale === 'ar') {
      mmmm = moment(d).locale(locale).format('MMMM');
      mmmm = arShortMonthName(mmmm);
      mmm = moment(d).locale(locale).format('MMM');
      mmm = arShortMonthName(mmm);
    } else {
      mmmm = moment(d).locale(locale).format('MMMM');
      mmm = moment(d).locale(locale).format('MMM');
    }
    const dd = moment(d).locale('en').format('D');
    let date = dddd + ' ' + dd + ' ' + mmmm + ' ' + yyyy;
    // Article and Print Product Page
    if (locale === 'ja' || locale === 'zh-cn') {
      date = moment(d).locale(locale).format('lldddd');
    }
    if (locale === 'ko') {
      date = moment(d).locale(locale).format('ll dddd');
    }
    if (locale === 'fr' || locale === 'es') {
      date = moment(d).locale(locale).format('dddd LL');
      date = date.charAt(0).toUpperCase() + date.slice(1);
    }
    //pagePlayerprofile || pageMyUnited || pageMatches || dateFormat === "playerprofile-date" || dateFormat === "matches-page"
    if (
      dateFormat === 'playerprofile-date' ||
      dateFormat === 'matches-page' ||
      dateFormat === 'myunited'
    ) {
      date = moment(d).locale(locale).format('ll');
      if (locale === 'en' || locale === 'fr') {
        date = dd + ' ' + mmm + ' ' + yyyy;
      }
      if (locale === 'ar') {
        date = dd + ' ' + mmmm + ' ' + yyyy;
      }
    }
    return date;
  } catch (e) {
    console.error('Error parsing day', e);
  }
};

export const fixtureTitle = (dateParam) => {
  try {
    let locale = MU.language;
    if (locale === 'zh') {
      locale = 'zh-cn';
    }
    const d = new Date(dateParam);
    const yyyy = moment(d).locale('en').format('YYYY');
    let date;
    if (locale === 'ar') {
      const mmmm = moment(d).locale(locale).format('MMMM');
      date = mmmm + ' ' + yyyy;
    }
    if (locale === 'fr' || locale === 'es' || locale === 'en') {
      date = moment(d).locale(locale).format('MMMM YYYY');
      date = date.charAt(0).toUpperCase() + date.slice(1);
    }
    if (locale === 'zh-cn' || locale === 'ja') {
      date = moment(d).locale(locale).format('YYYY年MMM');
    }
    if (locale === 'ko') {
      date =
        moment(d).locale(locale).format('ll').slice(0, 5) +
        ' ' +
        moment(d).locale(locale).format('MMMM');
    }
    return date;
  } catch (e) {
    console.log(e);
    return '';
  }
};

export const fixtureSubtitle = (dateParam) => {
  try {
    let locale = MU.language;
    if (locale === 'zh') {
      locale = 'zh-cn';
    }
    const d = new Date(dateParam);
    let date;

    if (locale === 'ar') {
      const dddd = moment(d).locale('en').format('DD');
      const mmmm = moment(d).locale(locale).format('MMMM');
      date = 'س' + ' ' + dddd + ' ' + mmmm;
    }
    if (locale === 'fr') {
      date = moment(d).locale(locale).format('ddd DD/MM');
      date = date.charAt(0).toUpperCase() + date.slice(1);
    }
    if (locale === 'en' || locale === 'es') {
      date = moment(d).locale(locale).format('ddd DD MMM');
      date = date.charAt(0).toUpperCase() + date.slice(1);
    }
    if (locale === 'zh-cn') {
      date = moment(d).locale(locale).format('MMMDD日ddd');
    }
    if (locale === 'ja') {
      date = moment(d).locale(locale).format('MMMDD日(ddd)');
    }
    if (locale === 'ko') {
      date = moment(d).locale(locale).format('MMM DD일 (ddd)');
    }
    return date;
  } catch (e) {
    console.log(e);
    return '';
  }
};

export const getMatchDate = (dateParam, dateType, noFormate) => {
  try {
    const d = new Date(dateParam);
    let locale = MU.language;
    if (locale === 'zh') {
      locale = 'zh-cn';
    }
    if (noFormate) {
      return moment(d).format(dateType);
    } else {
      return moment(d).locale(locale).format(dateType);
    }
  } catch (e) {
    console.log(e);
    return '';
  }
};

export const getMatchTime = (dateParam) => {
  try {
    const d = new Date(dateParam);
    let locale = MU.language;
    if (locale === 'zh') {
      locale = 'zh-cn';
    }
    if (locale === 'ko') {
      return moment(d).locale(locale).format('HH시 mm분');
    } else if (locale === 'fr') {
      return moment(d).locale('en').format('HH') + 'h' + moment(d).format('mm');
    } else {
      return moment(d).locale('en').format('HH:mm');
    }
  } catch (e) {
    console.log(e);
    return '';
  }
};

export const getCurrentDate = () => {
  return moment().format('DD-MM-YYYY');
};

export const checkisExpiredDate = (EndDate) => {
  try {
    let endDate = getMatchDate(EndDate, 'YYYY-MM-DD');
    let now = moment().format('YYYY-MM-DD');
    return moment(now).isBefore(endDate);
  } catch (e) {
    console.log(e);
    return '';
  }
};

export const getAbbrTimeZone = () => {
  try {
    const geoCookie = getCookie('geo');
    const zone = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
    if (geoCookie && (geoCookie === 'GB' || geoCookie === 'gb')) {
      return zone === 'GMT' ? zone : 'BST';
    } else {
      return '';
    }
  } catch (e) {
    console.error('Error getting time zone');
    return '';
  }
};

export const getPublishedDate = (publishedDt) => {
  if (publishedDt) {
    return moment(publishedDt).format('YYYY-MM-DDTHH:mm:ss.000[Z]');
  } else {
    return moment().utc().format('YYYY-MM-DDTHH:mm:ss.000[Z]');
  }
};
