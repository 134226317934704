/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import MU from '../../../constants/muConstants';
import { withTranslation } from 'react-i18next';
const NoDataFound = (props) => {
  const { t } = props;
  const redirectToHome = () => {
    window.location = `${MU.assetUrlResource}/${MU.language}/mutv`;
  };
  const setUrl = () => {
    return `${MU && MU.assetUrlResource}/${MU.language}/mutv`;
  };
  return (
    <div className="mutv-shows__filter-data nodataFound">
      <div className="actions_details">
        <div className="addMylistBtn" onClick={() => redirectToHome()}>
          <i className="icon-addmylist"></i>
        </div>
        <p>{t('AddYourFavouriteEpisodes')}</p>
        <div className="black-btn-skew">
          <a href={setUrl()} role="button">
            <span>{t('FindSomethingToAdd')}</span>
            <i className="icon-follow-live"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(NoDataFound);
