/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
const Goalkeeping = ({ t, item, statsType }) => {
  return (
    <div className="chart-wrapper">
      <h3>{t('stats_Goalkeeping')}</h3>
      <div className="goalkeeping">
        <ul>
          {item &&
            item.stats &&
            item.stats.length > 0 &&
            item.stats.map((goalkeeping, key) => (
              <li key={key}>
                <span className="list" aria-hidden="true">
                  {t(goalkeeping.statsKey)}
                </span>
                <div className="screenreader">
                  {t(goalkeeping.statsKey)} {goalkeeping.statsValue}
                  {goalkeeping.isPercentage === 'true' ? '%' : ''}
                </div>
                <span aria-hidden="true">
                  {goalkeeping.statsValue}
                  {goalkeeping.isPercentage === 'true' ? '%' : ''}
                </span>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
export default withTranslation()(Goalkeeping);
