/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect } from 'react';
import * as track from '../../utils/analytics';
import { withTranslation } from 'react-i18next';
import MU from '../../constants/muConstants';
import { tabbable } from '../../utils/tabbable';
const SubMenuChild = ({ subChild, isSetMoreMenuActive }) => {
  useEffect(() => {
    if (subChild.Id == MU.contextItemId || subChild.Id == MU.contextItemParentId) {
      isSetMoreMenuActive(true);
    } else if (
      subChild.Childrens &&
      subChild.Childrens.length > 0 &&
      subChild.Childrens.find(
        (val) => val.Id == MU.contextItemId || val.Id == MU.contextItemParentId
      )
    ) {
      isSetMoreMenuActive(true);
    }
  }, []);
  /**
   *
   * @param {each item data to track link analytics} item
   * @param {clicked event object} e
   */
  const trackLink = (item, e) => {
    const initialData = track.data('link');
    track.analytics(
      { ...initialData, button_name: item && item.Name, destination_url: item && item.TargetURL },
      e
    );
  };
  const handleMobileAccessibility = (e) => {
    if (typeof window !== 'undefined' && window.outerWidth < 1200) {
      const rootNode = e.target && e.target.closest('.home-header__sub-data');
      const tabbableArr = rootNode && tabbable(rootNode);
      const activeElm = document.activeElement;
      const currentIndex = tabbableArr && activeElm && tabbableArr.indexOf(activeElm);
      if (subChild.Childrens && currentIndex === subChild.Childrens.length - 1) {
        e.preventDefault();
        tabbableArr && tabbableArr[0].focus();
      }
    }
  };

  const updateHrefAndTarget = (targetUrl, target) => {
    return { href: targetUrl ? targetUrl.toLowerCase() : '', target: target ? target : '' };
  };

  return (
    <ul className="home-header__sub-data">
      <li>
        <a
          {...updateHrefAndTarget(subChild && subChild.TargetURL, subChild.TargetURLTarget)}
          onClick={(e) => trackLink(subChild, e)}
          onKeyDown={(e) => handleMobileAccessibility(e)}
        >
          {subChild.Name}
          {subChild.Caption && (
            <span className="nav-item__caption">
              <span className="icon-live"></span>
              <span>{subChild.Caption}</span>
            </span>
          )}
        </a>
      </li>
      {subChild &&
        subChild.Childrens &&
        !subChild.IsHidden &&
        subChild.Childrens.length > 0 &&
        subChild.Childrens.map(
          (subChildren, i) =>
            subChildren.Name && (
              <li key={i}>
                <a
                  href={subChildren.TargetURL}
                  target={subChildren.TargetURLTarget}
                  onClick={(e) => trackLink(subChildren, e)}
                  onKeyDown={(e) => handleMobileAccessibility(e)}
                  index={i}
                >
                  {subChildren.Name}
                  {subChildren.Caption && (
                    <span className="nav-item__caption">
                      <span className="icon-live"></span>
                      <span>{subChildren.Caption}</span>
                    </span>
                  )}
                </a>
              </li>
            )
        )}
    </ul>
  );
};

export default withTranslation()(React.memo(SubMenuChild));
