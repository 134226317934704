/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

class Webp {
  constructor() {
    this.webpSupport = false;
  }
  setWebp(value) {
    this.webpSupport = value;
  }
}

const webp = new Webp();

export const isWebpSupported = () => webp.webpSupport;

// 	checkBrowserWebpSupport
//  'feature' can be one of 'lossy', 'lossless', 'alpha' or 'animation'.
//  'callback()' will be passed back the detection result (in an asynchronous way!)
export const checkBrowserWebpSupport = (feature, callback) => {
  try {
    const webpSupport = typeof Storage !== 'undefined' && sessionStorage.getItem('webpSupport');
    if (webpSupport) {
      const webpSupportVal = webpSupport === 'true';
      webp.setWebp(webpSupportVal);
      callback();
    } else {
      const kTestImages = {
        lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
        lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
        alpha:
          'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
        animation:
          'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
      };
      const img = new Image();
      img.onload = function () {
        const result = img.width > 0 && img.height > 0;
        webp.setWebp(result);
        typeof Storage !== 'undefined' && sessionStorage.setItem('webpSupport', result);
        callback();
      };
      img.onerror = function () {
        typeof Storage !== 'undefined' && sessionStorage.setItem('webpSupport', false);
        webp.setWebp(false);
        callback();
      };
      img.src = 'data:image/webp;base64,' + kTestImages[feature];
    }
  } catch (error) {
    callback();
  }
};

export const replaceExtWithWebp = (isWebpAvailable, imgUrl) => {
  const webpSupport = isWebpSupported();
  if (isWebpAvailable && imgUrl && webpSupport) {
    const fileNameWebp = imgUrl.replace(/\.[^/.]+$/, '.webp');
    return fileNameWebp;
  }
  return imgUrl;
};
