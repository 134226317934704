/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import Podcast from '../../Cards/Podcast';
import { HERO_PODCAST } from '../../../constants/containerConstants';

const HeroBannerPodcast = (props) => (
  <div className="home-row">
    <div className="home-hero">
      <Podcast container={HERO_PODCAST} {...props} heroVarient="parent" gridClass="grid-12" />
    </div>
  </div>
);

export default HeroBannerPodcast;
