/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import DatePicker from 'react-date-picker';
import Loader from '../../Common/Loader';
import {
  renderDate,
  renderYear,
  formSerialize,
  validateEmail,
  getCurrentDate,
  getEmailData,
  getDate,
} from '../../../utils/FormUtils';
import {
  monthArr,
  countryArr,
  travelInitialStateStep2,
  errorMessageGroup,
} from '../../../constants/formConstants';
import { useMuTravelValue } from '../../../context/muTravelContext';
import { fetchPersistProxy, sendEmailUserData } from '../../../actions/muTravelAction';

const StepTwo = ({
  currentMatchId,
  setFormShow,
  autoFill,
  travelByList,
  arrivalPoints,
  travelFromList,
  Text,
}) => {
  const [{ persistProxyStatus, persistProxyData }, dispatch] = useMuTravelValue();
  const [errorMsgStep2, setErrorMsgStep2] = useState({});
  const [departureDate, setDepartureDate] = useState();
  const [returnDate, setReturnDate] = useState();
  const [emailData, setEmailData] = useState(null);
  const [showState, setShowState] = useState({});
  useEffect(() => {
    persistProxyStatus === 'fetched' && persistProxyData && sendEmail(persistProxyData);
  }, [persistProxyStatus]);

  const renderCountry = () => {
    return (
      <React.Fragment>
        {countryArr &&
          countryArr.length > 0 &&
          countryArr.map((item, index) => (
            <option value={item.value} aria-label={item.name} key={index}>
              {item.name}
            </option>
          ))}
      </React.Fragment>
    );
  };

  const sendEmail = (response) => {
    setFormShow({ showform: 'step3' });
    window.scroll({ top: 0, behavior: 'smooth' });
    if (response && emailData && emailData.data) {
      const emailObj = getEmailData(emailData.data);
      sendEmailUserData(dispatch, emailObj);
    }
  };

  const autoFillValue = (name, isDisabled) => {
    if (autoFill && autoFill[name]) {
      if (isDisabled) {
        return {
          value: autoFill && autoFill[name],
          disabled: 'disabled',
        };
      } else {
        return {
          value: autoFill && autoFill[name],
        };
      }
    }
  };

  const getFormData = () => {
    return formSerialize('#formStepTwo');
  };

  const validateTravelDetail = () => {
    const formData = getFormData();
    const formErrors = {};
    let formIsValid = true;
    if (formData) {
      travelInitialStateStep2 &&
        travelInitialStateStep2.length > 0 &&
        travelInitialStateStep2.forEach((item, index) => {
          if (!formData[item.name] && item.isRequired) {
            formErrors[item.name] = errorMessageGroup && errorMessageGroup[item.name];
            formIsValid = false;
          } else {
            formErrors[item.name] = '';
          }
        });
    }
    formIsValid = validateOptionalFields(formErrors, formIsValid);
    setErrorMsgStep2(formErrors);
    return formIsValid;
  };

  const submitTravelDetail = (e) => {
    e.preventDefault();
    if (validateTravelDetail()) {
      const formData = getFormData();
      const travellingFrom =
        formData['travellingFrom'] === '0'
          ? formData['travellingOther']
          : formData['travellingFrom'];
      const arrivalPoint =
        formData['arrivalPoint'] === '0' ? formData['otherPoint'] : formData['arrivalPoint'];
      const travellingBy = formData['travellingBy'] === '0' ? 'Air' : formData['travellingBy'];
      const mufcNumber = autoFill && `${autoFill.mufcNumber}`;
      const dataMuTravel = {
        TravelReport: {
          [currentMatchId]: {
            Offences: 'FALSE',
            CityTown: formData['city'],
            DOB: autoFill && `${autoFill.dobDate}-${autoFill.dobMonth}-${autoFill.dobYear}`,
            TravellingFrom: travellingFrom,
            MUFC: mufcNumber,
            Surname: autoFill && `${autoFill.lastName}`,
            PassportNumber: formData['passport'],
            Postcode: autoFill && `${autoFill.postcode}`,
            ArrivalPoint: arrivalPoint,
            Forename: formData['firstName'],
            ReturnDate: getDate(formData['returnDate']),
            Nationality: formData['nationality'],
            TravellingBy: travellingBy,
            Hotel: formData['hotel'],
            DepartureDate: getDate(formData['departureDate']),
            Email: formData['email'],
            FlightNumber: formData['newAir'],
            CreatedDate: getCurrentDate(),
            DOBEdited: 'N',
            PostcodeEdited: 'N',
            Cancelled: 'FALSE',
            MatchID: currentMatchId,
          },
        },
      };
      fetchPersistProxy(dispatch, mufcNumber, dataMuTravel);
      setEmailData({ data: dataMuTravel.TravelReport[currentMatchId] });
    }
  };

  const onChangeInputError = (elmName, type) => {
    let formdata = getFormData();
    let error = '';
    if (elmName && !formdata[elmName]) {
      error = errorMessageGroup && errorMessageGroup[elmName];
    } else if (type === 'email') {
      let isvalid = validateEmail(formdata[elmName]);
      if (!isvalid) {
        error = errorMessageGroup && errorMessageGroup[elmName];
      } else {
        error = '';
      }
    } else if (elmName) {
      error = '';
    }
    setErrorMsgStep2({ ...errorMsgStep2, [elmName]: error });
  };

  const onChangeDropDown = (elmName, otherElmName) => {
    let formdata = getFormData();
    let error = '';
    if (formdata[elmName] === '0') {
      error = errorMessageGroup && errorMessageGroup[elmName];
      setShowState({ ...showState, [otherElmName]: 'show' });
      if (otherElmName === 'newAir') {
        error = errorMessageGroup && errorMessageGroup['newAir'];
      }
    } else {
      error = '';
      setShowState({ ...showState, [otherElmName]: '' });
    }
    setErrorMsgStep2({ ...errorMsgStep2, [elmName]: error });
  };

  const onChangeOtherError = (elmName, optionalElm) => {
    let formdata = getFormData();
    let error = '';
    if (elmName && !formdata[optionalElm]) {
      error = errorMessageGroup && errorMessageGroup[elmName];
      if (optionalElm === 'newAir') {
        error = errorMessageGroup && errorMessageGroup['newAir'];
      }
    } else {
      error = '';
    }
    setErrorMsgStep2({ ...errorMsgStep2, [elmName]: error });
  };

  const onChangeDate = (value, dateElm) => {
    let error = '';
    if (dateElm === 'departureDate') {
      setDepartureDate(value);
      error = '';
    } else if (dateElm === 'returnDate') {
      setReturnDate(value);
      error = '';
    }
    setErrorMsgStep2({ ...errorMsgStep2, [dateElm]: error });
  };

  const validateOptionalFields = (formErrors, formIsValid) => {
    const formData = getFormData();
    if (formData['travellingFrom'] === '0') {
      if (!formData['travellingOther']) {
        formErrors['travellingFrom'] = errorMessageGroup && errorMessageGroup['travellingFrom'];
        formIsValid = false;
      }
    }
    if (formData['arrivalPoint'] === '0') {
      if (!formData['otherPoint']) {
        formErrors['arrivalPoint'] = errorMessageGroup && errorMessageGroup['arrivalPoint'];
        formIsValid = false;
      }
    }
    if (formData['travellingBy'] === '0') {
      if (!formData['newAir']) {
        formErrors['travellingBy'] = errorMessageGroup && errorMessageGroup['newAir'];
        formIsValid = false;
      }
    }
    return formIsValid;
  };

  return (
    <div className="step__two clearfix" style={{ display: 'block' }}>
      <div className="step__label">STEP 2</div>
      <div className="travel__container">
        <form
          className="travel__step__two"
          id="formStepTwo"
          onSubmit={(e) => submitTravelDetail(e)}
        >
          <fieldset>
            <legend className="field__heading">Personal Details</legend>
            <div className="grid-12">
              <div className="grid-6">
                <input
                  type="text"
                  className="grid-6 inputStyle"
                  aria-label="First Name"
                  placeholder="First Name"
                  name="firstName"
                  aria-required="true"
                  onChange={() => onChangeInputError('firstName', 'text')}
                />
                {errorMsgStep2 && errorMsgStep2.firstName && (
                  <span className="errorMessage" role="alert">
                    {errorMsgStep2.firstName}
                  </span>
                )}
              </div>
              <div className="grid-6">
                <input
                  type="text"
                  className="inputStyle"
                  aria-label="Last Name"
                  placeholder="Last Name"
                  name="lastName"
                  aria-required="true"
                  onChange={() => onChangeInputError('lastName', 'text')}
                  {...autoFillValue('lastName', true)}
                />
                {errorMsgStep2 && errorMsgStep2.lastName && (
                  <span className="errorMessage" role="alert">
                    {errorMsgStep2.lastName}
                  </span>
                )}
              </div>
            </div>
            <div className="grid-12">
              <div className="grid-6">
                <input
                  type="text"
                  className="inputStyle"
                  placeholder="Postcode"
                  name="postcode"
                  aria-label="Postcode"
                  aria-required="true"
                  onChange={() => onChangeInputError('postcode', 'text')}
                  {...autoFillValue('postcode', true)}
                />
                {errorMsgStep2 && errorMsgStep2.postcode && (
                  <span className="errorMessage" role="alert">
                    {errorMsgStep2.postcode}
                  </span>
                )}
              </div>
              <div className="grid-6">
                <select
                  className="inputStyle"
                  aria-label="Nationality"
                  name="nationality"
                  onChange={() => onChangeDropDown('nationality', '')}
                >
                  <option value="" disabled="">
                    Nationality
                  </option>
                  {renderCountry()}
                </select>
                {errorMsgStep2 && errorMsgStep2.nationality && (
                  <span className="errorMessage" role="alert">
                    {errorMsgStep2.nationality}
                  </span>
                )}
              </div>
            </div>
          </fieldset>
          <fieldset>
            <legend className="field__heading">Date of Birth</legend>
            <div className="grid-12 seperator-col">
              <div className="grid-2">
                <select
                  className="inputStyle"
                  name="dobDate"
                  aria-label="Date"
                  aria-required="true"
                  {...autoFillValue('dobDate', true)}
                >
                  {renderDate()}
                </select>
              </div>
              <div className="grid-2">
                <select
                  className="inputStyle"
                  name="dobMonth"
                  data-month
                  aria-label="Month"
                  aria-required="true"
                  {...autoFillValue('dobMonth', true)}
                >
                  <option value="" disabled>
                    Month
                  </option>
                  {monthArr &&
                    monthArr.length > 0 &&
                    monthArr.map((item, index) => (
                      <option value={index + 1} key={index}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="grid-2">
                <select
                  className="inputStyle"
                  name="dobYear"
                  data-year
                  aria-label="Year"
                  aria-required="true"
                  {...autoFillValue('dobYear', true)}
                >
                  {renderYear()}
                </select>
              </div>
              <span className="error">This field is required</span>
            </div>
          </fieldset>
          <div className="grid-12">
            <div className="grid-6">
              <input
                type="text"
                className="inputStyle"
                placeholder="Email"
                name="email"
                aria-label="Email"
                aria-required="true"
                onChange={() => onChangeInputError('email', 'email')}
              />
              {errorMsgStep2 && errorMsgStep2.email && (
                <span className="errorMessage" role="alert">
                  {errorMsgStep2.email}
                </span>
              )}
            </div>
            <div className="grid-6">
              <input
                type="text"
                className="inputStyle"
                placeholder="Passport Number"
                name="passport"
                aria-label="Passport Number"
                aria-required="true"
                data-validate
                maxLength={12}
                onChange={() => onChangeInputError('passport', 'text')}
              />
              {errorMsgStep2 && errorMsgStep2.passport && (
                <span className="errorMessage" role="alert">
                  {errorMsgStep2.passport}
                </span>
              )}
            </div>
          </div>
          <fieldset>
            <legend className="field__heading">Travel Details</legend>
            <div className="grid-12 seperator-col">
              <div className="grid-6">
                <select
                  className="inputStyle"
                  data-travelby
                  data-validate
                  aria-label="Travelling By"
                  aria-required="true"
                  name="travellingBy"
                  onChange={() => onChangeDropDown('travellingBy', 'newAir')}
                >
                  <option value="" disabled="disabled" selected={true}>
                    Travelling By
                  </option>
                  {travelByList &&
                    travelByList.length > 0 &&
                    travelByList.map((item, index) => (
                      <option value={index !== 0 ? item : '0'} key={index}>
                        {item}
                      </option>
                    ))}
                </select>
                {errorMsgStep2 && errorMsgStep2.travellingBy && (
                  <span className="errorMessage" role="alert">
                    {errorMsgStep2.travellingBy}
                  </span>
                )}
                {showState && showState.newAir && (
                  <input
                    type="text"
                    className="inputStyle"
                    placeholder="Flight Number"
                    aria-label="Flight Number"
                    aria-required="true"
                    name="newAir"
                    maxLength={8}
                    onChange={() => onChangeOtherError('travellingBy', 'newAir')}
                  ></input>
                )}
              </div>
              <div className="grid-6">
                <select
                  className="inputStyle"
                  aria-required="true"
                  aria-label="Travelling From"
                  name="travellingFrom"
                  onChange={() => onChangeDropDown('travellingFrom', 'travellingOther')}
                >
                  <option value="" disabled="disabled" selected={true}>
                    Travelling from
                  </option>
                  {travelFromList &&
                    travelFromList.length > 0 &&
                    travelFromList.map((item, index) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))}
                  <option value="0">Other (Please Specify)</option>
                </select>
                {errorMsgStep2 && errorMsgStep2.travellingFrom && (
                  <span className="errorMessage" role="alert">
                    {errorMsgStep2.travellingFrom}
                  </span>
                )}
                {showState && showState.travellingOther && (
                  <input
                    type="text"
                    className="inputStyle"
                    name="travellingOther"
                    onChange={() => onChangeOtherError('travellingFrom', 'travellingOther')}
                  />
                )}
              </div>
            </div>
            <div className="grid-12">
              <div className="grid-6">
                <span className="date_lbl">Departure Date</span>
                <DatePicker
                  id="from"
                  className="inputStyle"
                  name="departureDate"
                  yearAriaLabel="Departure Date DD-MM-YYYY"
                  aria-required="true"
                  onChange={(value) => onChangeDate(value, 'departureDate')}
                  value={departureDate}
                  calendarAriaLabel="Calendar"
                  prevAriaLabel="Previous Month"
                  nextAriaLabel="Next Month"
                  prev2Label={null}
                  next2Label={null}
                  showNeighboringMonth={false}
                />
                {errorMsgStep2 && errorMsgStep2.departureDate && (
                  <span className="errorMessage" role="alert">
                    {errorMsgStep2.departureDate}
                  </span>
                )}
              </div>
              <div className="grid-6">
                <span className="date_lbl">Return Date</span>
                <DatePicker
                  className="inputStyle"
                  placeholder="Return Date"
                  id="to"
                  name="returnDate"
                  aria-label="Return Date DD-MM-YYYY"
                  aria-required="true"
                  onChange={(value) => onChangeDate(value, 'returnDate')}
                  value={returnDate}
                  calendarAriaLabel="Calendar"
                  prevAriaLabel="Previous Month"
                  nextAriaLabel="Next Month"
                  prev2Label={null}
                  next2Label={null}
                  showNeighboringMonth={false}
                />
                {errorMsgStep2 && errorMsgStep2.returnDate && (
                  <span className="errorMessage" role="alert">
                    {errorMsgStep2.returnDate}
                  </span>
                )}
              </div>
            </div>
            <div className="grid-12">
              <div className="grid-6">
                <select
                  className="inputStyle"
                  data-arrival
                  data-validate-arrival
                  aria-label="Arrival Point"
                  aria-required="true"
                  name="arrivalPoint"
                  onChange={() => onChangeDropDown('arrivalPoint', 'otherPoint')}
                >
                  <option value="" disabled="disabled" selected={true}>
                    Arrival Point
                  </option>
                  {arrivalPoints &&
                    arrivalPoints.length > 0 &&
                    arrivalPoints.map((item, index) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))}
                  <option value="0">Other (Please Specify)</option>
                </select>
                {errorMsgStep2 && errorMsgStep2.arrivalPoint && (
                  <span className="errorMessage" role="alert">
                    {errorMsgStep2.arrivalPoint}
                  </span>
                )}
              </div>
              {showState && showState.otherPoint && (
                <div className="grid-6">
                  <input
                    type="text"
                    className="inputStyle"
                    name="otherPoint"
                    placeholder="Please Specify Point"
                    onChange={() => onChangeOtherError('arrivalPoint', 'otherPoint')}
                  />
                </div>
              )}
            </div>
          </fieldset>
          <fieldset>
            <legend className="field__heading">
              Accommodation details <span>(If staying overnight)</span>
            </legend>
            <div className="grid-6">
              <input
                type="text"
                className="inputStyle"
                placeholder="City/Town"
                aria-label="City/Town"
                name="city"
              />
              {errorMsgStep2 && errorMsgStep2.city && (
                <span className="errorMessage" role="alert">
                  {errorMsgStep2.city}
                </span>
              )}
            </div>
            <div className="grid-6">
              <input
                type="text"
                className="inputStyle"
                placeholder="Hotel"
                aria-label="Hotel"
                name="hotel"
              />
              {errorMsgStep2 && errorMsgStep2.hotel && (
                <span className="errorMessage" role="alert">
                  {errorMsgStep2.hotel}
                </span>
              )}
            </div>
            <div className="red-btn-skew">
              <input type="submit" className="button submit_btn" value="Submit" />
              {/* <a href="javascript:void(0)" role="button">
                <span>Submit</span>
                <i className="icon-follow-live" />
              </a> */}
            </div>
            <div className="description">
              <RichText className="contentDescription" field={Text} />
            </div>
          </fieldset>
        </form>
      </div>
      {persistProxyStatus === 'fetching' && <Loader></Loader>}
    </div>
  );
};

export default withTranslation()(React.memo(StepTwo));
