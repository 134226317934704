/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState, useRef, Fragment } from 'react';
import MU from '../../constants/muConstants';
import { getCtaUrlLink, transformPrimarySecondarySponsor } from '../../utils/utils';
import ImageFallback from '../Common/ImageFallback';
import * as track from '../../utils/analytics';
import LazyLoad from 'react-lazyload';
import { FOOTER } from '../../constants/containerConstants';
import SignIn from '../Common/Login/SignIn';
import SignUp from '../Common/Login/SignUp';
import { THRESHOLD } from '../../constants/globalConstants';
import { errorHandler } from '../../utils/gigyaScreen';
import { useSponsorValue } from '../../context/sponsorContext';
import { userLoginStatus } from '../../actions/sponsorAction';
import { withTranslation } from 'react-i18next';
import AMPFooter from '../AMPFooter';
let footerIntervalId, timeoutId;
const Footer = (props) => {
  const [pageUrl, setPageUrl] = useState(null);
  const footerElm = useRef(null);
  const [footerStyle, setFooterStyle] = useState({});
  const [{ isUserLogin }, dispatch] = useSponsorValue();

  useEffect(() => {
    pageUrl === null && setPageUrl(window.location.href);
    setFooterStyle(getFooterStyle());
    footerIntervalId = setInterval(() => {
      if (footerElm && footerElm.current) {
        const domRect = footerElm.current.getBoundingClientRect();
        setFooterStyle({ bottom: `-${domRect.height}px` });
        clearInterval(footerIntervalId);
      }
    }, 100);
    return () => clearInterval(footerIntervalId);
  }, [pageUrl]);

  useEffect(() => {
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const getLoginDetail = () => {
    if (window.gigya) {
      var evalResponse = function (response) {
        if (response.errorCode == 0) {
          userLoginStatus(dispatch, response);
        } else {
          errorHandler(response);
        }
      };
      window.gigya && window.gigya.accounts.getAccountInfo({ callback: evalResponse });
    }
  };

  const resizeListener = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      getFooterStyle();
    }, THRESHOLD);
  };

  const {
    PrimarySponsors,
    SecondarySponsors,
    SocialMediaLinks,
    NavigationLinks,
    CopyRightText,
    SocialLinkButton,
    FooterSponsorsPrimary,
    FooterSponsorsSecondary,
  } = props.fields;

  const primarySecondarySponsor = (sponsorItem) => {
    return (
      sponsorItem &&
      sponsorItem.length > 0 &&
      sponsorItem.map((obj, key) => {
        if (obj) {
          let {
            PartnerIconDark,
            PartnerIconLight,
            partnerName: PartnerName,
            CTAURL,
            isActive: IsActive,
            partnerText: PartnerText,
            CTATarget,
          } = obj;
          if (IsActive) {
            let imgUrl =
              (MU && MU.reverseTheme) || getMunichClass() ? PartnerIconLight : PartnerIconDark;
            return (
              <li
                data-cardname="footer primary sponsor"
                data-an-track="true"
                data-track-type="page"
                key={key}
              >
                <LazyLoad once={true} offset={50}>
                  <a
                    href={CTAURL && CTAURL}
                    target={CTATarget && CTATarget}
                    onClick={(e) =>
                      trackSponsor(
                        {
                          button_name: PartnerName,
                          destination_url: CTAURL,
                          card_name: PartnerText,
                          container_type: FOOTER,
                        },
                        e
                      )
                    }
                  >
                    <ImageFallback
                      src={imgUrl}
                      onErrorClass="no-img"
                      alt={PartnerName && PartnerName}
                      relativePath={false}
                      onLoad={getFooterStyle}
                    />
                    <span className="screenreader">{props.t('Externalwebsite')}</span>
                  </a>
                </LazyLoad>
              </li>
            );
          }
        }
      })
    );
  };
  const getSocialMediaLinks = () => {
    return (
      SocialMediaLinks &&
      SocialMediaLinks.length > 0 &&
      SocialMediaLinks.map((obj, key) => {
        let { link, Title, Image } = obj.fields;
        return (
          <li key={key}>
            <a
              href={link && link.value && link.value.hrefMuCustom}
              target={link && link.value && link.value.target}
              onClick={(e) =>
                trackCard(
                  {
                    destination_url: link && link.value && link.value.hrefMuCustom,
                    button_name: link && link.value && link.value.hrefMuCustom,
                  },
                  e
                )
              }
            >
              <ImageFallback
                src={Image && Image.value && Image.value.Preview}
                onErrorClass="no-img"
                alt={`Follow us on ${Title && Title.value} External Website`}
                relativePath={false}
              />
            </a>
          </li>
        );
      })
    );
  };
  const getSocialLinkButton = () => {
    return (
      SocialLinkButton &&
      SocialLinkButton.length > 0 &&
      SocialLinkButton.map((obj, key) => {
        let { manutds, Name } = obj.fields;
        return (
          <React.Fragment key={key}>
            {!isUserLogin && (
              <Fragment>
                {manutds &&
                manutds.value &&
                manutds.value.__Name &&
                manutds.value.__Name.toLowerCase() === 'signin' ? (
                  <SignIn
                    isMutv={props.isMutv}
                    itemName={Name && Name.value}
                    getLoginDetail={getLoginDetail}
                  />
                ) : (
                  <SignUp isMutv={props.isMutv} getLoginDetail={getLoginDetail}></SignUp>
                )}
              </Fragment>
            )}
          </React.Fragment>
        );
      })
    );
  };

  const getNavigationLinks = () => {
    return (
      NavigationLinks &&
      NavigationLinks.length > 0 &&
      NavigationLinks.map((obj, key) => {
        let { TargetURL, Name } = obj.fields;
        let linkUrl = TargetURL && TargetURL.value && TargetURL.value.hrefMuCustom;
        return (
          <li key={key}>
            <a
              href={TargetURL ? getCtaUrlLink(TargetURL.value) : '#'}
              target={TargetURL && TargetURL.value && TargetURL.value.target}
              onClick={(e) =>
                trackCard({ button_name: Name && Name.value, destination_url: linkUrl }, e)
              }
            >
              {Name && Name.value}
            </a>
          </li>
        );
      })
    );
  };

  const trackCard = (data, e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: data.button_name,
        destination_url: data.destination_url,
      },
      e
    );
  };
  const trackSponsor = (data, e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: data.button_name,
        destination_url: data.destination_url,
        card_name: data.card_name,
        container_type: data.container_type,
      },
      e
    );
  };
  const getFooterStyle = () => {
    if (footerElm && footerElm.current) {
      const domRect = footerElm.current.getBoundingClientRect();
      setFooterStyle({ bottom: `-${domRect.height}px` });
    }
  };

  const getMunichClass = () =>
    props.fields.IsMunich && props.fields.IsMunich.value && 'reversetheme';

  if (MU && MU.isAmpPageUrl) {
    return <AMPFooter {...props}></AMPFooter>;
  }

  return (
    props &&
    props.fields && (
      <footer
        className={`footer ${getMunichClass()}`}
        data-module="footer-elm"
        data-container="footer"
        style={footerStyle}
        ref={footerElm}
      >
        <div className="sponsor">
          <div className="sponsor__primary">
            <ul className="footer-sponsor">
              {PrimarySponsors && PrimarySponsors.length > 0
                ? primarySecondarySponsor(transformPrimarySecondarySponsor(PrimarySponsors))
                : primarySecondarySponsor(FooterSponsorsPrimary)}
            </ul>
          </div>
          <div className="sponsor__secondary">
            <ul className="footer-sponsor">
              {SecondarySponsors && primarySecondarySponsor(SecondarySponsors)}
              {SecondarySponsors && SecondarySponsors.length > 0
                ? primarySecondarySponsor(transformPrimarySecondarySponsor(SecondarySponsors))
                : primarySecondarySponsor(FooterSponsorsSecondary)}
            </ul>
          </div>
        </div>
        <div className="footer__social-links">
          <div className="social-icons">
            {!isUserLogin && (
              <div className="social-button" id="footerloginRegisterLink">
                {SocialLinkButton && getSocialLinkButton()}
              </div>
            )}
            <ul className="social-media-link">{SocialMediaLinks && getSocialMediaLinks()}</ul>
          </div>
        </div>
        <div className="footer__menu">
          <div className="footer__menu__links">
            <ul>{NavigationLinks && getNavigationLinks()}</ul>
          </div>
          <div className="footer__menu__copyright">
            <ul>{CopyRightText && CopyRightText.value && <li>{CopyRightText.value}</li>}</ul>
          </div>
        </div>
      </footer>
    )
  );
};

export default withTranslation()(Footer);
