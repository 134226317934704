/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useRef, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import Slider from 'react-slick';
//import { cardContenttype, cardDataExtractorUtil, mutvslideShowCount } from '../mutvutil';
import {
  PrevArrow,
  NextArrow,
  afterChangeAccessbility,
  beforeChangeAccessbility,
  onInitAccessibility,
  slideShowCount,
} from '../../../utils/carouselUtils';
import MU from '../../../constants/muConstants';
import EpgCard from './EpgCard';
import { mutvslideShowCount } from '../mutvutil';
const EpgSliderCarousel = ({
  isTouchDevice,
  refCallBack,
  data,
  aspectRatio,
  contentType,
  isEpg,
  selectedDate,
  activeIndex,
  weekDate,
  oncardClick,
  isEpgImage,
  toggleState,
  intialToggleState,
}) => {
  const slickElement = useRef(null);
  const [slicker, setSlicker] = useState({});
  const [screenReaderText, setScreenReaderText] = useState('');
  const [initialLoad, setInitialLoad] = useState(true);
  useEffect(() => {
    if (slickElement && !isTouchDevice && isEpg) {
      if (slickElement.current && weekDate && weekDate[0] !== selectedDate) {
        slickElement.current.slickGoTo(0);
      } else {
        slickElement.current.slickGoTo(activeIndex);
      }
    } else if (isTouchDevice && isEpg) {
      if (weekDate && weekDate[0] !== selectedDate) {
        scrollToInTouchDevice(data.length);
      } else {
        scrollToInTouchDevice();
      }
    }
  }, [selectedDate, activeIndex, intialToggleState]);

  useEffect(() => {
    if (isTouchDevice && isEpg) {
      window.addEventListener('orientationchange', orientationChange, false);
      return () => {
        window.removeEventListener('orientationchange', orientationChange, false);
      };
    }
  }, []);
  useEffect(() => {
    if (slicker && slicker.slider && !isTouchDevice && isEpg) {
      let ariaEPG = slicker.track && slicker.track.querySelectorAll('div[aria-hidden="false"]');
      let prevElm = slicker.prevArrow;
      let nextElm = slicker.nextArrow;
      ariaEPG &&
        ariaEPG.length > 0 &&
        ariaEPG.forEach((item) => {
          setTimeout(() => {
            let epgElm = item.querySelectorAll('a');
            if (epgElm && epgElm.length > 0) {
              epgElm.forEach((i) => {
                i.setAttribute('tabindex', toggleState ? '0' : '-1');
              });
            }
          }, 500);
        });
      if (prevElm) {
        prevElm.setAttribute('tabindex', toggleState ? '0' : '-1');
      }
      if (nextElm) {
        nextElm.setAttribute('tabindex', toggleState ? '0' : '-1');
      }
    }
  }, [toggleState, slicker]);

  const orientationChange = () => {
    setTimeout(() => {
      if (isTouchDevice && isEpg) {
        if (weekDate && weekDate[0] !== selectedDate) {
          scrollToInTouchDevice(data.length);
        } else {
          scrollToInTouchDevice();
        }
      }
    }, 100);
  };

  const slideCount = () => {
    if (data && data.length > 0) {
      return data.length;
    } else {
      return 0;
    }
  };

  const setScreenReaderValue = (index) => {
    const a = index;
    const b = slideShowCounter(aspectRatio);
    const c = slideCount();
    if (a + b <= c) {
      setScreenReaderText(`Displayed ${a + 1} of ${a + b} out of ${c}`);
    }
    setTimeout(() => {
      setScreenReaderText('');
    }, 300);
  };

  const slideShowCounter = (aspectRatio, carouselContentType) => {
    return mutvslideShowCount();
  };

  const nextPrevArrow = (oldIndex, newIndex, slicker, bypass) => {
    setTimeout(() => {
      if (oldIndex < newIndex || bypass) {
        slicker && slicker.prevArrow && slicker.prevArrow.classList.remove('clicked');
        slicker && slicker.nextArrow && slicker.nextArrow.classList.add('clicked');
      }
      if (oldIndex > newIndex) {
        slicker && slicker.prevArrow && slicker.prevArrow.classList.add('clicked');
        slicker && slicker.nextArrow && slicker.nextArrow.classList.remove('clicked');
      }
    }, 100);
  };

  const settings = (res, carouselContentType) => {
    const aspectRatioCheck = res;
    return {
      dots: true,
      infinite: false,
      //adaptiveHeight: true,
      speed: 400,
      className: 'carousal-list center',
      //lazyLoad: 'ondemand',
      slidesToScroll: slideShowCounter(aspectRatioCheck, carouselContentType),
      swipe: true,
      accessibility: false,
      slidesToShow: slideShowCounter(aspectRatioCheck, carouselContentType),
      touchMove: true,
      mobileFirst: true,
      touchThreshold: 20,
      initialSlide: 0,
      rtl: false,
      arabic: MU.language === 'ar' ? true : false,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow slideShow={slideShowCounter(aspectRatioCheck, carouselContentType)} />,
      draggable: false,
      swipeToSlide: true,
      variableWidth: true,
      onInit: (e) => {
        const element = refCallBack();
        setTimeout(() => {
          if (slickElement.current) {
            slickElement.current.slickGoTo(activeIndex);
          }
        }, 1000);
        if (element) {
          const slider = element && element.current;
          let list = slider && slider.querySelector('.slick-list');
          let slideTrack = list && list.querySelector('.slick-track');
          let slides = slideTrack && slideTrack.querySelectorAll('.slick-slide');
          let nextArrow = slider && slider.querySelector('.slider-next.slick-arrow');
          let prevArrow = slider && slider.querySelector('.slider-prev.slick-arrow');
          onInitAccessibility(slideTrack, slides, 'epg');
          setSlicker({
            slider: slider,
            list: list,
            track: slideTrack,
            slides: slides,
            nextArrow: nextArrow,
            prevArrow: prevArrow,
          });
        }
      },
      beforeChange: (oldIndex, newIndex) => {
        setScreenReaderValue(newIndex);
        nextPrevArrow(oldIndex, newIndex, slicker);
        beforeChangeAccessbility(slicker, 'EPG', toggleState);
      },
      afterChange: () => {
        afterChangeAccessbility(slicker);
      },
      appendDots: (dots) => {
        if (dots && dots.length) {
          let isActive = true;
          let list = dots.map((val, index) => {
            isActive = isActive ? val.props.className !== 'slick-active' : false;
            return (
              <li key={index} className={`${val.props.className} ${isActive ? 'active' : ''}`}>
                <i></i>
              </li>
            );
          });
          return (
            <div className="slick-dots">
              <ul tabIndex="-1" aria-hidden="true">
                {list}
              </ul>
            </div>
          );
        }
      },
    };
  };

  const renderMobileView = () => {
    return (
      <React.Fragment>
        <div className={`carousal-list custom-mobile-slider`}>
          {data.map((item, itemIndex) => {
            return (
              <div className="mobile-listitem" role="listitem" key={itemIndex}>
                <EpgCard
                  key={itemIndex}
                  item={item}
                  activeIndex={activeIndex}
                  weekDate={weekDate}
                  itemIndex={itemIndex}
                  selectedDate={selectedDate}
                  oncardClick={oncardClick}
                  isEpgImage={isEpgImage}
                ></EpgCard>
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  };

  const rednerDesktopView = (data, index) => {
    return (
      <React.Fragment>
        {data && data.length && (
          <Slider {...settings(aspectRatio, contentType)} ref={slickElement}>
            {data.map((item, itemIndex) => {
              return (
                <EpgCard
                  key={itemIndex}
                  item={item}
                  activeIndex={activeIndex}
                  weekDate={weekDate}
                  itemIndex={itemIndex}
                  selectedDate={selectedDate}
                  oncardClick={oncardClick}
                  isEpgImage={isEpgImage}
                  //showModal={showModal}
                ></EpgCard>
              );
            })}
          </Slider>
        )}
      </React.Fragment>
    );
  };

  const getGapBetweenCards = () => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth < 768) {
        return 10; //gap is 8px
      } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
        return 16; //gap is 8px
      } else if (window.innerWidth >= 1024 && window.innerWidth < 1440) {
        return 24;
      } else if (window.innerWidth >= 1440) {
        return 20;
      }
    }
    return 16;
  };
  const scrollToInTouchDevice = (totalLength) => {
    const element = refCallBack();
    const sliderContainer = element && element.current.querySelector('.custom-mobile-slider');
    const itemWidth = sliderContainer && sliderContainer.querySelector('.mobile-listitem');
    if (itemWidth && itemWidth.offsetWidth) {
      const gap = getGapBetweenCards();
      const liWidth = itemWidth.offsetWidth ? itemWidth.offsetWidth + gap : 150;
      sliderContainer.scroll({
        left: totalLength ? -(totalLength * liWidth) : activeIndex * liWidth,
        behavior: 'smooth',
      });
    }
  };

  return (
    <React.Fragment>
      {data && data.length > 0 && (
        <div
          className={`${isTouchDevice ? 'touchdevice' : 'not touch'} ${
            initialLoad ? 'initialLoad' : 'notinitialload'
          }`}
        >
          {!isTouchDevice && rednerDesktopView(data)}
          {isTouchDevice && renderMobileView()}
          {!isTouchDevice && (
            <div className="screenreader" aria-live="assertive">
              {screenReaderText}
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default withTranslation()(EpgSliderCarousel);
