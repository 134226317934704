/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import Helmet from 'react-helmet';
const Kameleoon = ({ kameleoonReferenceScript, iframeURL }) => {
  return (
    <Helmet>
      <script type="text/javascript">
        {`function checkIEBrowser() {
            try {
              var ua = window.navigator.userAgent;
              var msie = ua.indexOf("MSIE ");        
              if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))
              { return true; }
            } catch (e) {
              console.log('error in removing Kameleoon for IE');
              return false;
            }
            return false;
          }`}
      </script>
      <script type="text/javascript">
        {`
          var kameleoonLoadingTimeout = 1000;

          window.kameleoonQueue = window.kameleoonQueue || [];
          window.kameleoonStartLoadTime = new Date().getTime();
            if (!document.getElementById("kameleoonLoadingStyleSheet") && !window.kameleoonDisplayPageTimeOut) {
                var kameleoonS = document.getElementsByTagName("script")[0];
                var kameleoonCc = "* {visibility: hidden !important; background-image: none !important; }";
                var kameleoonStn = document.createElement("style");
                kameleoonStn.type = "text/css";
                kameleoonStn.id = "kameleoonLoadingStyleSheet";
                if (kameleoonStn.styleSheet) {
            kameleoonStn.styleSheet.cssText = kameleoonCc;
                }
                else {
            kameleoonStn.appendChild(document.createTextNode(kameleoonCc));
                }
                kameleoonS.parentNode.insertBefore(kameleoonStn, kameleoonS);
                window.kameleoonDisplayPage = function (fromEngine) {
                    if (!fromEngine) {
            window.kameleoonTimeout = true;
                    }
                    if (kameleoonStn.parentNode) {
            kameleoonStn.parentNode.removeChild(kameleoonStn);
                    }
                };
                window.kameleoonDisplayPageTimeOut = window.setTimeout(window.kameleoonDisplayPage, kameleoonLoadingTimeout);
            }
            `}
      </script>
      <script type="text/javascript">
        {`
        var kameleoonIframeURL = "${iframeURL}";

        var kameleoonIframeOriginElement = document.createElement("a");
        var kameleoonLightIframe = false;
        kameleoonIframeOriginElement.href = kameleoonIframeURL;
        var kameleoonIframeOrigin = kameleoonIframeOriginElement.origin || (kameleoonIframeOriginElement.protocol + "//" + kameleoonIframeOriginElement.hostname);
        if (location.href.indexOf(kameleoonIframeOrigin) == -1) {
            kameleoonLightIframe = true;
            var kameleoonProcessMessageEvent = function (event) {
                if (kameleoonIframeOrigin == event.origin && event.data.slice && event.data.slice(0, 9) == "Kameleoon") {
                    window.removeEventListener("message", kameleoonProcessMessageEvent);
                    window.kameleoonExternalIFrameLoaded = true;
                    if (window.Kameleoon) {
                        eval(event.data);
                        Kameleoon.Analyst.load();
                    }
                    else {
                        window.kameleoonExternalIFrameLoadedData = event.data;
                    }
                }
            };
            if (window.addEventListener) {
                window.addEventListener("message", kameleoonProcessMessageEvent, false);
            }
            var iframeNode = document.createElement("iframe");
            iframeNode.src = kameleoonIframeURL;
            iframeNode.id = "kameleoonExternalIframe";
            iframeNode.style = "float: left !important; opacity: 0.0 !important; width: 0px !important; height: 0px !important;";
            document.head.appendChild(iframeNode);
        }
      `}
      </script>
      <script type="text/javascript">
        {`
          var IE = checkIEBrowser();
          if (!IE){
            try {
              var v = document.createElement('script');
              v.type = "text/javascript";
              v.src = "${kameleoonReferenceScript}";
              document.head.appendChild(v);
            } catch (e) {
              console.log('error in adding Kameleoon for other browsers');
            }
          }
        `}
      </script>
    </Helmet>
  );
};

export default React.memo(Kameleoon);
