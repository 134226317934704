/**
 *  © 2022 -2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

export let userData = (response, age) => {
  const gigyaDataFlow = {
    callId: '',
    errorCode: 0,
    apiVersion: 2,
    statusCode: 200,
    statusReason: 'OK',
    time: '',
    registeredTimestamp: response.registeredTimestamp,
    UID: response.sub,
    UIDSignature: response.UIDSignature,
    signatureTimestamp: response.signatureTimestamp,
    created: +response.registeredTimestamp,
    createdTimestamp: +response.createdTimestamp,
    data: {
      favoritePlayerID: response.favoritePlayerID,
      mufcNumber: response.ticketingRef,
      title: '',
      ExpiredDate: '',
      jerseyNo: response.jerseyNo,
      emails: {
        parentConsent: '',
        AccountVerificationMail: '',
      },
      mobileTelephone: '',
      terms: true,
      countryCode: '',
      isSocialAllowed: 'true',
      customerReference: {
        venue: response.ticketingRef,
      },
      marketingConsent: response.optIn,
      addressLine2: '',
      addressLine3: '',
      MemberStatus: response.memberStatus,
      PurchaseChannel: response.purchaseChannel,
    },
    isActive: true,
    isRegistered: true,
    isVerified: true,
    lastLogin: '',
    lastLoginTimestamp: '',
    lastUpdated: '',
    lastUpdatedTimestamp: '',
    loginProvider: response.loginProvider,
    oldestDataUpdated: '',
    oldestDataUpdatedTimestamp: '',
    password: {
      created: '',
    },
    profile: {
      firstName: response.firstName,
      lastName: response.lastName,
      age: age,
      birthDay: response.birthDay,
      birthMonth: response.birthMonth,
      birthYear: response.birthYear,
      country: response.country,
      email: response.email,
      photoURL: response.picture,
      thumbnailURL: response.picture,
    },
    registered: '',
    socialProviders: response.socialProviders,
    verified: response.isVerified,
    verifiedTimestamp: '',
  };
  return gigyaDataFlow;
};
