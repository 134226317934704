/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_SHOW_LIST,
  SET_SHOW_LIST,
  ERROR_SHOW_LIST,
  SET_MUTV_VIDEO_DATA,
} from '../constants/actions/actionConstant';
import { getFilters } from '../components/Mutv/mutvutil';
import { checkResponseDocs, createDestinationUrl } from '../utils/utils';
import { reverseDataMapping } from '../components/Mutv/mutvutil';

export const showListingState = {
  status: null,
  cardData: {},
  startListIndex: {
    clips: 0,
    episode: 0,
    mlt: 0,
  },
  showLoadMore: {
    clips: false,
    episode: false,
    mlt: false,
  },
  carouselDetails: null,
  filters: [],
  filterObj: null,
  sortOrder: null,
  seriesType: null,
  itemsNumber: 24,
  heroDetails: null,
  initialLoad: true,
  isClipVideo: false,
  isMutvMovie: false,
};

/**
 * Reducer for mutv episode-carousel-listing/video-episode/podcast-episode
 */
export const showListingReducer = (
  state = showListingState,
  {
    type,
    response,
    actionType,
    activeFilter,
    videoData,
    isShowScreen,
    defaultFilter,
    tabSelected,
    heroLayoutData,
  } = {}
) => {
  switch (type) {
    case SET_MUTV_VIDEO_DATA: {
      return {
        ...state,
        status: 'fetched',
        heroDetails: videoData.heroData,
        isClipVideo: videoData.isClipVideo,
      };
    }
    case FETCH_SHOW_LIST:
      return {
        ...state,
        status: 'fetching',
        showLoadMore: fetchShowLoadMore(state, isShowScreen, actionType, tabSelected),
        ...additionalFetchData(state, tabSelected, actionType, isShowScreen),
      };
    case SET_SHOW_LIST: {
      return {
        ...setData(
          response && response.data,
          state,
          actionType,
          activeFilter,
          isShowScreen,
          defaultFilter,
          tabSelected,
          heroLayoutData
        ),
        status: 'fetched',
      };
    }
    case ERROR_SHOW_LIST: {
      if (state.initialLoad) {
        window.location = createDestinationUrl('PageLoadFail');
      }
      return {
        ...state,
        status: 'error',
      };
    }
    default:
      return state;
  }
};

const fetchShowLoadMore = (state, isShowScreen, actionType, tabSelected) => {
  if (isShowScreen) {
    return { clips: false, episode: false, mlt: false };
  } else {
    if (actionType === 'loadMore') {
      return { ...state.showLoadMore, [tabSelected]: false };
    }
    return state.showLoadMore;
  }
};

const additionalFetchData = (state, tabSelected, actionType, isShowScreen) => {
  if (isShowScreen && actionType === 'default') {
    return { cardData: { episodeNumFound: 0, defaultActiveTab: tabSelected } };
  } else if (!isShowScreen && tabSelected === 'episode' && actionType === 'default') {
    return {
      cardData: {
        ...state.cardData,
        episode: [],
        episodeNumFound: 0,
        defaultActiveTab: tabSelected,
      },
    };
  }
  return {};
};

const setData = (
  response,
  state,
  actionType,
  activeFilter,
  isShowScreen,
  defaultFilter,
  tabSelected,
  heroLayoutData
) => {
  if (response) {
    const ClipsResponse = response.ClipsResponse && response.ClipsResponse.grouped;
    const UpNextResponse = response.UpNextResponse && response.UpNextResponse.grouped;
    const GetCarouselResponse =
      response.GetCarouselResponse &&
      response.GetCarouselResponse.response &&
      response.GetCarouselResponse.response.docs &&
      response.GetCarouselResponse.response.docs.length > 0 &&
      response.GetCarouselResponse.response.docs[0];
    const sortOrder =
      GetCarouselResponse &&
      GetCarouselResponse.carouselsortorder_s &&
      GetCarouselResponse.carouselsortorder_s.toLowerCase();
    const seriesType =
      GetCarouselResponse.seriestype_s && GetCarouselResponse.seriestype_s.toLowerCase();
    const EpisodeResponse =
      response.EpisodeResponse &&
      response.EpisodeResponse.response &&
      response.EpisodeResponse.response.docs &&
      response.EpisodeResponse.response.docs.length > 0 &&
      response.EpisodeResponse.response.docs[0];
    let filters = state.filters;

    //set filters data
    if (actionType === 'default' && state.initialLoad) {
      const FacetResponse =
        response.FacetResponse &&
        response.FacetResponse.facet_counts &&
        response.FacetResponse.facet_counts.facet_fields;
      const filtersData = getFilters(seriesType, FacetResponse, sortOrder);
      filters = filtersData.filters;
      state.filters = filtersData.filters;
      state.filterObj = filtersData.filterObj;
      state.carouselDetails = {
        aspectRatio: GetCarouselResponse.aspectratio_t,
        heading: GetCarouselResponse.heading_t,
      };
      state.sortOrder = sortOrder;
      state.seriesType = seriesType;
      if (tabSelected === 'clips') {
        state.isClipVideo = true;
        state.heroDetails = heroLayoutData && reverseDataMapping(heroLayoutData);
      } else {
        state.heroDetails = isShowScreen ? GetCarouselResponse : EpisodeResponse;
      }
    }
    /* set All Listing Data CLIPS And MLT*/
    if (
      state.initialLoad ||
      (!isShowScreen && tabSelected && tabSelected !== 'episode') ||
      isShowScreen
    ) {
      /* For CLIP Tab */
      let mltStart, clipsData, clipsStart, mltData;
      let clipsShowMore = false;
      let mltShowMore = false;

      if (
        seriesType &&
        seriesType === 'nonseasonal' &&
        ClipsResponse &&
        ClipsResponse.seriesnumberunique_s
      ) {
        let groups = ClipsResponse.seriesnumberunique_s.groups;
        let item =
          groups &&
          groups.find(
            (item) =>
              item.groupValue ===
              (activeFilter ? activeFilter : defaultFilter ? defaultFilter : filters[0])
          );
        if (item && item.doclist) {
          clipsData = item.doclist.docs;
          clipsStart = item.doclist.start;
          let numFound = item.doclist.numFound;
          clipsShowMore = numFound - clipsStart > state.itemsNumber ? true : false;
        }
      } else if (
        seriesType &&
        seriesType === 'seasonal' &&
        ClipsResponse &&
        ClipsResponse.seasonnumberunique_s
      ) {
        let groups = ClipsResponse.seasonnumberunique_s.groups;
        let item =
          groups &&
          groups.find(
            (item) =>
              item.groupValue ===
              (activeFilter ? activeFilter : defaultFilter ? defaultFilter : filters[0])
          );
        if (item && item.doclist) {
          clipsData = item.doclist.docs;
          clipsStart = item.doclist.start;
          let numFound = item.doclist.numFound;
          clipsShowMore = numFound - clipsStart > state.itemsNumber ? true : false;
        }
      }

      /* For MltResponse Tab */
      if (response.MltResponse && checkResponseDocs(response.MltResponse)) {
        mltData = response.MltResponse.response.docs;
        let numFound = response.MltResponse.response.numFound;
        mltStart = response.MltResponse.response.start;
        mltShowMore = numFound - mltStart > state.itemsNumber ? true : false;
      }

      if (clipsData) {
        state.cardData =
          actionType === 'default'
            ? { ...state.cardData, clips: clipsData }
            : { ...state.cardData, clips: state.cardData.clips.concat(clipsData) };
        state.startListIndex = { ...state.startListIndex, clips: clipsStart };
        state.showLoadMore = { ...state.showLoadMore, clips: clipsShowMore };
      }
      if (mltData) {
        state.cardData =
          actionType === 'default'
            ? { ...state.cardData, mlt: mltData }
            : { ...state.cardData, mlt: state.cardData.mlt.concat(mltData) };
        state.startListIndex = { ...state.startListIndex, mlt: mltStart };
        state.showLoadMore = { ...state.showLoadMore, mlt: mltShowMore };
      }
    }

    /* For Episode Tab */
    if (state.initialLoad || (!isShowScreen && tabSelected === 'episode') || isShowScreen) {
      let episodeStart, episodeData;
      let episodeShowMore = false;
      let episodeNumFound;
      if (
        seriesType &&
        seriesType === 'nonseasonal' &&
        UpNextResponse &&
        UpNextResponse.seriesnumberunique_s
      ) {
        let groups = UpNextResponse.seriesnumberunique_s.groups;
        let item =
          groups &&
          groups.find(
            (item) =>
              item.groupValue ===
              (activeFilter ? activeFilter : defaultFilter ? defaultFilter : filters[0])
          );
        if (item && item.doclist) {
          episodeData = item.doclist.docs;
          episodeStart = item.doclist.start;
          episodeNumFound = item.doclist.numFound;
          episodeShowMore = episodeNumFound - episodeStart > state.itemsNumber ? true : false;
        }
      } else if (
        seriesType &&
        seriesType === 'seasonal' &&
        UpNextResponse &&
        UpNextResponse.seasonnumberunique_s
      ) {
        let groups = UpNextResponse.seasonnumberunique_s.groups;
        let item =
          groups &&
          groups.find(
            (item) =>
              item.groupValue ===
              (activeFilter ? activeFilter : defaultFilter ? defaultFilter : filters[0])
          );
        if (item && item.doclist) {
          episodeData = item.doclist.docs;
          episodeStart = item.doclist.start;
          episodeNumFound = item.doclist.numFound;
          episodeShowMore = episodeNumFound - episodeStart > state.itemsNumber ? true : false;
        }
      }
      if (episodeData) {
        state.cardData =
          actionType === 'default'
            ? { ...state.cardData, episode: episodeData, episodeNumFound: episodeNumFound }
            : { ...state.cardData, episode: state.cardData.episode.concat(episodeData) };
        state.startListIndex = { ...state.startListIndex, episode: episodeStart };
        state.showLoadMore = { ...state.showLoadMore, episode: episodeShowMore };
      }
      state.isMutvMovie =
        filters && filters.length === 1 && (!episodeNumFound || episodeNumFound === 1);
    }

    state.cardData.defaultActiveTab = tabSelected;
    //Handling if hero data is blank in api response then it will show hero banner from layout data
    if (actionType === 'default' && state.initialLoad && !state.heroDetails && heroLayoutData) {
      state.heroDetails = reverseDataMapping(heroLayoutData);
    }
    if (state.initialLoad) {
      state.initialLoad = false;
    }
  }

  return state;
};
