/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState, useRef } from 'react';
import LazyLoad from 'react-lazyload';
import ImgCrop from '../../Common/ImgCrop';
import {
  buildImgUrl,
  getLiveTimerData,
  getAbbreviationPeriod,
  getKeyValueByName,
} from '../../../utils/utils';
import { withTranslation } from 'react-i18next';
import { MODULE, DARK_THEME, LIGHT_THEME } from '../../../constants/globalConstants';
import Sponsor from '../../Common/Sponsor';
import { getMatchDate, getAbbrTimeZone } from '../../../utils/momentDate';
import * as track from '../../../utils/analytics';
import api from '../../../utils/muApi';
import { SPOTLIGHT } from '../../../constants/endpoints';
import { SPOTLIGHT as spotlightContainer } from '../../../constants/containerConstants';
import { calculateTimeLeft } from '../../../utils/matchUtils';
import { cloneDeep } from 'lodash';
import MU from '../../../constants/muConstants';
import ErrorBoundary from '../../Common/ErrorBoundary';

const Spotlight = (props) => {
  const { t } = props;
  let cronTimeInterval = 30000;
  const IntId = useRef(0);
  const [crestErrImg, setCrestErrImg] = useState({ away: false, home: false });
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(spotlightResponse));
  const ctaUrlFromFields = props?.fields?.Spotlight?.value?.CTAUrl;

  const formattedSpotlightData = props?.fields?.Spotlight?.value?.Response;

  const [spotlightResponse, setSpotlightResponse] = useState(
    formattedSpotlightData?.SpotlightResponse?.response?.docs?.[0]
  );
  const [timerResponse, setTimerResponse] = useState(
    formattedSpotlightData?.TimerResponse?.response?.docs?.[0]
  );
  const [contentType, setContentType] = useState('');
  const getImageDetail = () => {
    return {
      imgCrop: getKeyValueByName(
        spotlightResponse && spotlightResponse.defaultimage_t,
        'PreviewURL'
      ),
      altText: getKeyValueByName(spotlightResponse && spotlightResponse.defaultimage_t, 'AltText'),
      singleSrc: true,
      isWebpAvailable: false,
    };
  };
  const [imageDetail, setImageDetail] = useState(getImageDetail());
  const imgRef = useRef({ initialLoad: true, imgArray: [] });
  const intervalRef = useRef(null);
  const [matchDate, setMatchDate] = useState('');

  let spotlightCss,
    moduleName,
    ctaTitle,
    ctaUrl,
    isAbandoned,
    isPostponed,
    period,
    image,
    optaContentValue,
    competationDetails,
    matchDetails,
    matchType,
    isSecondLeg,
    secondLegAwayTeamAggregateScore,
    secondLegHomeTeamAggregateScore,
    homeTeam,
    awayTeam,
    homeTeamFid,
    awayTeamFid,
    awayTeamName,
    homeTeamName,
    secondLegResultString,
    timeoutId,
    containerName,
    timerSeconds,
    timerMinutes,
    liveTimerData,
    liveHomeTeamScore,
    liveAwayTeamScore;

  const fetchSpotlight = () => {
    const route = `${SPOTLIGHT}`;
    return api
      .get({ route })
      .then((response) => {
        if (response && response.data) {
          const spotlightResponseData = response.data.SpotlightResponse;
          const timerResponseData = response.data.TimerResponse;
          const imageResponseData = response.data.ImageResponse;
          setContentType(spotlightResponseData?.response?.docs?.[0]?.contenttype_t);
          setSpotlightResponse(spotlightResponseData?.response?.docs?.[0]);
          setTimerResponse(timerResponseData?.response?.docs?.[0]);
          imageResponseData &&
            imageResponseData.response &&
            imageResponseData.response.docs &&
            imageResponseData.response.docs.length > 0 &&
            setImageResponse(imageResponseData.response.docs);
        }
      })
      .catch((err) => {
        console.log('fetchSpotlight err', err);
      });
  };

  const setImageResponse = (imageResponseDocs) => {
    if (imgRef.current && imgRef.current.initialLoad) {
      imgRef.current = { initialLoad: false, imgArray: imageResponseDocs };
      populateImages();
    } else {
      imgRef.current = { ...imgRef.current, imgArray: imageResponseDocs };
    }
  };

  const populateImages = () => {
    clearInterval(intervalRef.current);
    let imgIndex = 0;
    const firstImg =
      imgRef.current.imgArray[imgIndex] &&
      imgRef.current.imgArray[imgIndex].celumimagesvariant_s &&
      imgRef.current.imgArray[imgIndex].celumimagesvariant_s.ImageCropUrl;
    firstImg &&
      firstImg.CropUrl &&
      setImageDetail({
        imgCrop: firstImg.CropUrl,
        singleSrc: false,
        isWebpAvailable: firstImg.isWebpAvailable,
        altText: firstImg.AltText,
      });
    imgIndex++;
    intervalRef.current = setInterval(() => {
      const nextImg =
        imgRef.current.imgArray[imgIndex] &&
        imgRef.current.imgArray[imgIndex].celumimagesvariant_s &&
        imgRef.current.imgArray[imgIndex].celumimagesvariant_s.ImageCropUrl;
      nextImg &&
        nextImg.CropUrl &&
        setImageDetail({
          imgCrop: nextImg.CropUrl,
          singleSrc: false,
          isWebpAvailable: nextImg.isWebpAvailable,
          altText: nextImg.AltText,
        });
      if (imgIndex === imgRef.current.imgArray.length - 1) {
        imgIndex = 0;
      } else {
        imgIndex++;
      }
    }, 10000);
  };

  useEffect(() => {
    setContentType(
      spotlightResponse && spotlightResponse.contenttype_t
        ? spotlightResponse.contenttype_t.toLowerCase()
        : ''
    );
    setMatchDate(spotlightResponse && spotlightResponse.matchdate_tdt);
  }, []);

  useEffect(() => {
    clearInterval(IntId.current);
    if (contentType && contentType === 'live') {
      cronTimeInterval = 15000;
    } else {
      cronTimeInterval = 30000;
    }
    contentType && (contentType === 'result' || contentType === 'live') && enableCronJob();
    contentType && (contentType === 'live' || contentType === 'result') && fetchSpotlight();
    return () => clearInterval(IntId.current);
  }, [contentType]);

  useEffect(() => {
    if (contentType && contentType === 'timer') {
      timeoutId = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(spotlightResponse));
      }, 1000);
    }
    return () => clearTimeout(timeoutId);
  });

  const enableCronJob = () => {
    clearInterval(IntId.current);
    IntId.current = setInterval(() => {
      fetchSpotlight();
    }, cronTimeInterval);
  };

  if (spotlightResponse) {
    ctaTitle = spotlightResponse.ctatitle_t;
    ctaUrl = spotlightResponse.ctaurl_t;
    isAbandoned = spotlightResponse.isabandoned_b;
    isPostponed = spotlightResponse.ispostponed_b;

    if (spotlightResponse.contenttype_t) {
      if (spotlightResponse.contenttype_t.toLowerCase() === 'live') {
        spotlightCss = 'live state__live';
        containerName = 'Match clock';
        moduleName = 'matchclock';
      } else if (spotlightResponse.contenttype_t.toLowerCase() === 'timer') {
        spotlightCss = 'countdown state__timer';
        containerName = 'Count Down Timer';
        moduleName = 'countdownclock';
        ctaUrl = ctaUrl + ctaUrlFromFields;
      } else if (spotlightResponse.contenttype_t.toLowerCase() === 'fixture') {
        spotlightCss = 'default state__fixture';
        ctaUrl = ctaUrl + ctaUrlFromFields;
        moduleName = 'matchclock';
      } else if (spotlightResponse.contenttype_t.toLowerCase() === 'result') {
        spotlightCss = 'state__result';
        moduleName = 'matchclock';
      }
    }

    if (spotlightResponse.optacontent) {
      optaContentValue = spotlightResponse.optacontent.value;
      if (optaContentValue && optaContentValue.length > 0) {
        competationDetails = optaContentValue[0];
        if (competationDetails) {
          matchDetails = competationDetails.MatchDataList;
          if (matchDetails != null && matchDetails.length > 0 && matchDetails[0]) {
            if (timerResponse) {
              const timerOptaContent = timerResponse.optacontent;
              if (timerOptaContent && timerOptaContent.Period) {
                period = timerOptaContent.Period.toLowerCase();
              }
            }

            if (!period && matchDetails[0].Period) {
              period = matchDetails[0].Period.toLowerCase();
            }
            if (matchDetails[0].MatchType) {
              matchType = matchDetails[0].MatchType.toLowerCase();
              if (matchType) {
                if (matchType.includes('2nd leg') || matchType.includes('2ndleg')) {
                  isSecondLeg = true;
                  if (matchDetails[0].SecondLegAwayTeamAggregateScore >= 0) {
                    secondLegAwayTeamAggregateScore =
                      matchDetails[0].SecondLegAwayTeamAggregateScore.toString() ||
                      (matchDetails[0].SecondLegAwayTeamAggregateScore.Value &&
                        matchDetails[0].SecondLegAwayTeamAggregateScore.Value.toString());
                  }
                  if (matchDetails[0].SecondLegHomeTeamAggregateScore >= 0) {
                    secondLegHomeTeamAggregateScore =
                      matchDetails[0].SecondLegHomeTeamAggregateScore.toString() ||
                      matchDetails[0].SecondLegHomeTeamAggregateScore.Value ||
                      matchDetails[0].SecondLegHomeTeamAggregateScore.Value.toString();
                  }
                }
              }
            }

            homeTeam = cloneDeep(matchDetails[0].HomeTeam);
            awayTeam = cloneDeep(matchDetails[0].AwayTeam);

            if (homeTeam && homeTeam.FID) {
              homeTeamFid = homeTeam.FID;
            }

            if (homeTeam && homeTeam.TeamName) {
              homeTeamName = homeTeam.TeamName;
              if (!homeTeam.ShortClubName && homeTeamName) {
                homeTeam.ShortClubName = homeTeamName;
              }
            }

            if (awayTeam && awayTeam.FID) {
              awayTeamFid = awayTeam.FID;
            }

            if (awayTeam && awayTeam.TeamName) {
              awayTeamName = awayTeam.TeamName;
              if (!awayTeam.ShortClubName && awayTeamName) {
                awayTeam.ShortClubName = awayTeamName;
              }
            }

            if (contentType === 'live' || contentType === 'result') {
              const timerOptaContent = timerResponse && timerResponse.optacontent;
              if (timerOptaContent) {
                timerMinutes = timerOptaContent.TimerMinutes;
                timerSeconds = timerOptaContent.TimerSeconds;
                homeTeam.ShortClubName =
                  matchDetails[0].HomeTeam && matchDetails[0].HomeTeam.ShortClubName;
                awayTeam.ShortClubName =
                  matchDetails[0].AwayTeam && matchDetails[0].AwayTeam.ShortClubName;
                const homeScore =
                  homeTeamFid == 1
                    ? timerOptaContent.GoalByManUtd
                    : timerOptaContent.GoalByOpponent;
                const awayScore =
                  awayTeamFid == 1
                    ? timerOptaContent.GoalByManUtd
                    : timerOptaContent.GoalByOpponent;

                if (contentType === 'live') {
                  liveHomeTeamScore = homeScore;
                  liveAwayTeamScore = awayScore;
                }
                liveTimerData = getLiveTimerData(timerMinutes, timerSeconds, period);
              }
            }

            if (isSecondLeg && matchDetails[0].ResultType) {
              let winnerTeamName,
                winnerTeamScore,
                looserTeamScore = '';

              const matchWinner =
                matchDetails[0].MatchWinner && matchDetails[0].MatchWinner.toString();
              if (matchWinner === awayTeamFid) {
                winnerTeamName = awayTeamName;
                winnerTeamScore = secondLegAwayTeamAggregateScore;
                looserTeamScore = secondLegHomeTeamAggregateScore;
              } else if (matchWinner === homeTeamFid) {
                winnerTeamName = homeTeamName;
                winnerTeamScore = secondLegHomeTeamAggregateScore;
                looserTeamScore = secondLegAwayTeamAggregateScore;
              }
              if (matchDetails[0].ResultType) {
                if (matchDetails[0].ResultType.toUpperCase() === 'AGGREGATE') {
                  if (winnerTeamName && winnerTeamScore && looserTeamScore) {
                    secondLegResultString = `${winnerTeamName} wins ${winnerTeamScore} - ${looserTeamScore} on aggregate`;
                  }
                } else if (matchDetails[0].ResultType.toUpperCase() === 'AWAY_GOALS') {
                  if (winnerTeamName) {
                    secondLegResultString = `${winnerTeamName} win on away goals`;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  const trackCard = (e) => {
    const initialData = track.data('spotlight');
    track.analytics(
      {
        ...initialData,
        container_type: 'spotlight',
        item_id: `${homeTeam.ShortClubName} ${t('versus')} ${awayTeam.ShortClubName}`,
        content_type: 'spotlight',
        tag: '',
        created_datetime: '',
        author: '',
        spotlight_name: containerName ? containerName : contentType,
        destination_url: ctaUrl,
      },
      e
    );
  };

  const trackLink = (e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: ctaTitle,
        destination_url: ctaUrl,
      },
      e
    );
    e.stopPropagation();
  };

  /**
   * @method {getSponsorTheam sponsor theme}
   */
  const getSponsorTheme = () => {
    return liveTimerData && liveTimerData.injuryTime ? DARK_THEME : LIGHT_THEME;
  };
  return formattedSpotlightData && competationDetails && awayTeam && homeTeam ? (
    <ErrorBoundary>
      <div
        className={`Spotlight follow ${spotlightCss}`}
        onClick={(e) => {
          trackCard(e);
          MU && MU.language === 'en' && ctaTitle && ctaUrl && window.location.replace(ctaUrl);
        }}
      >
        <div className="grid-12 ratio">
          <div className="mu-content xlarge">
            {contentType === 'result' ? (
              <span className="screenreader">{t('Matchinformationresult')}</span>
            ) : (
              <span className="screenreader">{t('MatchInformation')}</span>
            )}
            <div className="img-holder">
              {imageDetail && imageDetail.imgCrop && (
                <LazyLoad once={true} offset={100}>
                  <ImgCrop
                    imgCrop={imageDetail.imgCrop}
                    altText={imageDetail.altText}
                    title={imageDetail.altText}
                    className={'mu-item__gradient'}
                    isWebpAvailable={imageDetail.isWebpAvailable}
                    singleSrc={imageDetail.singleSrc}
                    gridClass={'grid-12'}
                    isUpdateImage={true}
                  ></ImgCrop>
                </LazyLoad>
              )}
            </div>
            <div className="info">
              <div className="info__league" role="presentation">
                <img
                  src={
                    competationDetails.LightBgLogo &&
                    buildImgUrl(competationDetails.LightBgLogo.img1x)
                  }
                  alt={competationDetails.CompetitionName}
                  title=""
                />
              </div>
              {/* <!-- start --> */}
              {isAbandoned && (
                <p className="info__kick-off" role="presentation">
                  <span>{matchDate && getMatchDate(matchDate, 'dddd D MMMM yyyy')}</span>
                  <span>{t('Matchabandoned')}</span>
                </p>
              )}
              {isPostponed && (
                <p className="info__kick-off" role="presentation">
                  <span>{t('Datetobeconfirmed')}</span>
                </p>
              )}
              {!isAbandoned && !isPostponed && matchDate && (
                <p className="info__kick-off" role="presentation">
                  <span>{getMatchDate(matchDate, 'dddd D MMMM yyyy')}</span>
                  <span>
                    {t('KickOff')} {getMatchDate(matchDate, 'HH:mm')} {getAbbrTimeZone()}
                  </span>
                </p>
              )}
              {(contentType === 'live' || contentType === 'timer' || contentType === 'result') && (
                <div
                  className={`timer ${
                    liveTimerData && liveTimerData.injuryTime ? 'timer-result' : ''
                  }`}
                  role="presentation"
                >
                  <div className="timer__logo">
                    {moduleName && (
                      <Sponsor
                        type={MODULE}
                        hidePartnerText={true}
                        moduleName={moduleName}
                        theme={getSponsorTheme()}
                        container={spotlightContainer}
                        contentType={contentType}
                        cardName={`Match Clock`}
                      ></Sponsor>
                    )}
                  </div>
                  {contentType === 'live' && period && (
                    <>
                      {liveTimerData && !liveTimerData.injuryTime && (
                        <div className="timer__time live-content" id="update-timer">
                          <div id="timer__time" data-time="true">
                            <span
                              className="timer__time-hour"
                              dangerouslySetInnerHTML={{
                                __html: liveTimerData && liveTimerData.timerText,
                              }}
                            ></span>
                          </div>
                        </div>
                      )}
                      {liveTimerData && liveTimerData.injuryTime && (
                        <>
                          <span className="screenreader">
                            {' '}
                            {getAbbreviationPeriod('timeelapsed')}{' '}
                          </span>
                          <div className="result__time injury-content">
                            <span
                              className="timer__time-hour"
                              dangerouslySetInnerHTML={{
                                __html: liveTimerData && liveTimerData.timerText,
                              }}
                            ></span>
                            <span className="injury-time">{liveTimerData.injuryTime}</span>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {contentType === 'timer' && (
                    <div className="timer__time timer-content" id="update-timer">
                      <div className="" id="timer__time">
                        <span className="timer__time-hour">
                          {timeLeft.hours === 'zero' ? '00' : timeLeft.hours}
                        </span>
                        <span className="countdown-seprator">:</span>
                        <span className="timer__time-min">{timeLeft.minutes}</span>
                        <span className="countdown-seprator">:</span>
                        <span className="timer__time-sec">{timeLeft.seconds}</span>
                      </div>
                    </div>
                  )}
                  {contentType === 'result' && liveTimerData && liveTimerData.timerText && (
                    <div className="timer__time result-content" id="update-timer">
                      <div id="timer__time" data-time="true">
                        <span
                          className="timer__time-hour"
                          dangerouslySetInnerHTML={{
                            __html: liveTimerData && liveTimerData.timerText,
                          }}
                        ></span>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* <!-- end --> */}
              <div className="info__matches">
                <ul role="none">
                  <li>
                    <span aria-hidden="true" className="team__logo">
                      <img
                        src={homeTeam.TeamCrestImage && buildImgUrl(homeTeam.TeamCrestImage.img1x)}
                        alt={homeTeam.TeamName}
                        title=""
                        className={`${crestErrImg.home ? 'no-img' : ''} img-responsive`}
                        onError={() => setCrestErrImg({ ...crestErrImg, home: true })}
                      />
                    </span>
                    <span className="team__name">
                      {homeTeam.ShortClubName}
                      <span className="screenreader">
                        &nbsp;{`${t('versus')} ${awayTeam.ShortClubName}`}&nbsp;
                      </span>
                    </span>
                  </li>

                  {contentType === 'live' && (
                    <li className="match-score">
                      <span role="presentation">
                        {(MU && MU.language === 'ar' ? liveAwayTeamScore : liveHomeTeamScore) || 0}
                      </span>
                      <span role="presentation">-</span>
                      <span role="presentation">
                        {(MU && MU.language === 'ar' ? liveHomeTeamScore : liveAwayTeamScore) || 0}
                      </span>
                      {isSecondLeg &&
                      secondLegAwayTeamAggregateScore &&
                      secondLegHomeTeamAggregateScore ? (
                        <div className="agg-score">{`(${t(
                          'Agg'
                        )}${secondLegHomeTeamAggregateScore}-${secondLegAwayTeamAggregateScore})`}</div>
                      ) : (
                        <div className="agg-score"> </div>
                      )}
                    </li>
                  )}

                  {contentType && contentType !== 'live' && (
                    <li className="match-score">
                      <span role="presentation">
                        {MU && MU.language === 'ar' ? awayTeam.Score : homeTeam.Score}
                      </span>
                      <span role="presentation">-</span>
                      <span role="presentation">
                        {MU && MU.language === 'ar' ? homeTeam.Score : awayTeam.Score}
                      </span>
                      {isSecondLeg &&
                        secondLegAwayTeamAggregateScore &&
                        secondLegHomeTeamAggregateScore && (
                          <div className="agg-score">{`(${t(
                            'Agg'
                          )}${secondLegHomeTeamAggregateScore}-${secondLegAwayTeamAggregateScore})`}</div>
                        )}
                    </li>
                  )}

                  <li className="match-vs" aria-hidden="true">
                    <span>{t('VS')}</span>
                    {isSecondLeg &&
                      secondLegAwayTeamAggregateScore &&
                      secondLegHomeTeamAggregateScore && (
                        <div className="agg-score">{`(${t(
                          'Agg'
                        )}${secondLegHomeTeamAggregateScore}-${secondLegAwayTeamAggregateScore})`}</div>
                      )}
                  </li>
                  <li aria-hidden="true">
                    <span className="team__logo">
                      <img
                        src={awayTeam.TeamCrestImage && buildImgUrl(awayTeam.TeamCrestImage.img1x)}
                        alt={awayTeam.TeamName}
                        title=""
                        className={`${crestErrImg.away ? 'no-img' : ''} img-responsive`}
                        onError={() => setCrestErrImg({ ...crestErrImg, away: true })}
                      />
                    </span>
                    <span className="team__name">{awayTeam.ShortClubName}</span>
                  </li>
                </ul>
              </div>
              {ctaTitle && ctaUrl && (
                <div className="spotlight-btn-wrapper">
                  <div className="red-btn-skew">
                    <a href={ctaUrl} onClick={(e) => trackLink(e)} role="button">
                      <span style={{ outline: 'none' }}>{ctaTitle}</span>
                      <i className="icon-follow-live"></i>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  ) : (
    <></>
  );
};

export default withTranslation()(Spotlight);
