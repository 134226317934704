/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import StatsBar from '../../Common/StatsBar';
import LazyLoad from 'react-lazyload';
import ImgCrop from '../../Common/ImgCrop';
import {
  getStatsBarData,
  getSponsorData,
  getItemId,
  isApp,
  isCollection,
  getRandomId,
  createTagArr,
  validateCbr,
  getLoggedInStatus,
  createDestinationUrl,
} from '../../../utils/utils';
import {
  ANONYMOUS,
  CONTENT_TYPE,
  REGISTERED,
  MUAppDeepLink,
} from '../../../constants/globalConstants';
import Sponsor from '../../Common/Sponsor';
import * as track from '../../../utils/analytics';
import Loader from '../../Common/Loader';
import { useModal } from '../../Common/Modal';
import loadable from '@loadable/component';
import ErrorBoundary from '../../Common/ErrorBoundary';
import AccessBadge from '../../Common/AccessBadge';
import { useSponsorValue } from '../../../context/sponsorContext';
import MU from '../../../constants/muConstants';
const ImageGalleryModal = loadable(() =>
  import('../../Modals/ImageGalleryModal/ImageGalleryModal')
);
const RegistrationModal = loadable(() =>
  import('../../Modals/RegistrationModal/RegistrationModal')
);

const ImageGallery = (props) => {
  const {
    t,
    fields,
    gridClass,
    isMatchcenterCard,
    container,
    showStatsbar,
    isArticleDetail,
  } = props;
  const { ContentType, manutds, ContentAccess } = props.fields;
  const itemId = getItemId(props);
  const [{ isUserLogin, isLoginChecked, isUserSubscribe, isUserSkyId }] = useSponsorValue();
  const { showModal, closeModal, isModalVisible } = useModal();
  const {
    showModal: cbrShowModal,
    closeModal: cbrCloseModal,
    isModalVisible: cbrModalVisible,
  } = useModal();
  const [id] = useState(getRandomId());
  const [successScrollState, setSuccessScrollState] = useState({
    x: '',
    y: '',
  });
  const imageCrops = fields.CoverImage && fields.CoverImage.value && fields.CoverImage.value.Crops;
  const imageAltText =
    fields.CoverImage && fields.CoverImage.value && fields.CoverImage.value.AltText;
  const isWebpAvailable =
    fields.CoverImage && fields.CoverImage.value && fields.CoverImage.value.isWebpAvailable;
  const sponsorInfo = fields.Sponsorby && getSponsorData(fields.Sponsorby.fields);
  const destinationUrl =
    fields.manutds && fields.manutds.value && fields.manutds.value.DestinationUrl;

  const getAnalyticsData = (fields, contentStatus) => {
    const initialData = track.data('card');
    return {
      ...initialData,
      card_name: fields.ShortHeadline && fields.ShortHeadline.value,
      container_type: container,
      item_id: itemId,
      content_type: fields.ContentType && fields.ContentType.value,
      content_status: contentStatus,
      subscription_status: getLoggedInStatus({
        isLoginChecked,
        isUserLogin,
        isUserSubscribe,
        isUserSkyId,
      }),
      tag: fields.manutds && fields.manutds.value && fields.manutds.value.Tags,
      created_datetime: fields.__Published && fields.__Published.value,
      author: fields.AuthorName && fields.AuthorName.value,
      destination_url: destinationUrl,
    };
  };

  const trackCard = (fields, e, contentStatus) => {
    track.analytics(getAnalyticsData(fields, contentStatus), e);
  };

  const onCardClick = (e) => {
    setSuccessScrollState({ x: e.pageX, y: e.pageY });
    const showCbr = getCbrValue();
    trackCard(fields, e, showCbr && showCbr.accessCheck);
    if (isApp() && !isCollection()) {
      window.location.href = createDestinationUrl(destinationUrl) + MUAppDeepLink;
      return true;
    }
    if (showCbr && showCbr.value) {
      cbrShowModal(destinationUrl);
    } else {
      showModal(destinationUrl);
    }
  };
  const getCbrValue = () => {
    return validateCbr({
      isUserLogin,
      isUserSubscribe,
      contentAccess: ContentAccess && ContentAccess.value,
      contentType: ContentType && ContentType.value,
      manutds,
    });
  };

  const successCallback = ({ showContent, isSubscribedContent }) => {
    if (showContent) {
      if (!MU.isIDMEnabled) showModal(destinationUrl);
      else {
        return [
          'successScroll',
          isSubscribedContent,
          JSON.stringify(successScrollState.x),
          JSON.stringify(successScrollState.y),
        ];
      }
    }
  };

  const renderCBSModal = () => {
    const accessType = getCbrValue().accessCheck;
    return (
      <ErrorBoundary>
        <RegistrationModal
          successCallback={successCallback}
          closeModal={cbrCloseModal}
          fallback={<Loader />}
          previousActiveElement={document.activeElement}
          accessType={accessType}
          analyticsObj={getAnalyticsData(fields, accessType)}
        />
      </ErrorBoundary>
    );
  };

  return (
    <React.Fragment>
      {cbrModalVisible && renderCBSModal()}
      <div
        className={`${gridClass} ${isMatchcenterCard ? 'ratio-matchday' : 'ratio'} item`}
        onClick={(e) => onCardClick(e)}
        data-impression={track.impression({
          card_name: fields.Teaser && fields.Teaser.value,
          content_type: fields.ContentType && fields.ContentType.value,
        })}
      >
        <div className="mu-content">
          <article
            className={`mu-item image-gallery ${
              isArticleDetail
                ? isApp() && isCollection()
                  ? 'open-destinationpage'
                  : 'open-modal'
                : ''
            }`}
          >
            <div className="img-holder">
              {imageCrops && (
                <LazyLoad once={true} offset={100}>
                  <ImgCrop
                    imgCrop={imageCrops}
                    alt={imageAltText}
                    title={imageAltText}
                    isWebpAvailable={isWebpAvailable}
                    gridClass={gridClass}
                  ></ImgCrop>
                </LazyLoad>
              )}
            </div>
            <div className="top-badges">
              <AccessBadge
                contentAcessLocal={ContentAccess && ContentAccess.value}
                contentTypeLocal={ContentType && ContentType.value}
                tagListLocal={createTagArr(manutds)}
              />
            </div>
            <Sponsor
              contentType={fields.ContentType && fields.ContentType.value}
              varient={fields.Variant && fields.Variant.value}
              sponsorInfo={sponsorInfo}
              grid={gridClass}
              type={CONTENT_TYPE}
              author={fields.AuthorName && fields.AuthorName.value}
              cardName={fields.ShortHeadline && fields.ShortHeadline.value}
              container={container}
              createdDateTime={fields.__Published && fields.__Published.value}
              itemId={itemId}
              tags={fields.manutds && fields.manutds.value && fields.manutds.value.Tags}
            ></Sponsor>
            <div className="mu-item__info">
              {fields.ShortHeadline && fields.ShortHeadline.value && (
                <React.Fragment>
                  <h2 id={id} className="mu-item__title">
                    <Text field={fields.ShortHeadline} />
                  </h2>
                  <span className="screenreader">
                    &nbsp;{fields.ContentType && fields.ContentType.value && t('Gallery')}
                  </span>
                </React.Fragment>
              )}
              {fields.Teaser && fields.Teaser.value && (
                <p className="mu-item__teasure" data-share-desc>
                  <Text field={fields.Teaser} />
                </p>
              )}
              <div className="link-btn-white">
                <a href="#" onClick={(e) => e.preventDefault()} aria-describedby={id}>
                  <span>
                    {t('ViewGallery')}
                    <i className="icon-cta-arrow"></i>
                  </span>
                </a>
              </div>
            </div>
            {showStatsbar && (
              <StatsBar
                item={getStatsBarData(fields)}
                tagListLocal={createTagArr(manutds)}
              ></StatsBar>
            )}
          </article>
        </div>
      </div>
      {isModalVisible && (
        <ErrorBoundary>
          <ImageGalleryModal
            cardName={fields.Teaser && fields.Teaser.value}
            author={fields.AuthorName && fields.AuthorName.value}
            closeModal={closeModal}
            itemid={itemId}
            fallback={<Loader />}
            galleryData={fields}
          />
        </ErrorBoundary>
      )}
    </React.Fragment>
  );
};

ImageGallery.defaultProps = {
  showStatsbar: true,
  isArticleDetail: false,
};

export default withTranslation()(ImageGallery);
