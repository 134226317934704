/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import { createTagList, getKeyValueByName, transformUpsellData } from '../../utils/utils';
export const Video = (data, isAws) => {
  const sponsor = isAws
    ? data.sponsordetailinfo_s && data.sponsordetailinfo_s[0]
    : data.sponsordetailinfo_s && JSON.parse(data.sponsordetailinfo_s)[0];
  return {
    fields: {
      ThumbnailImage: {
        value: {
          Crops: isAws
            ? data.celumimagesvariant_s &&
              data.celumimagesvariant_s.ImageCropUrl &&
              data.celumimagesvariant_s.ImageCropUrl.CropUrl
              ? data.celumimagesvariant_s.ImageCropUrl.CropUrl
              : data.imagecropurl_s
            : data.imagecropurl_s && JSON.parse(data.imagecropurl_s),
          isWebpAvailable: isAws
            ? data.celumimagesvariant_s &&
              data.celumimagesvariant_s.ImageCropUrl &&
              data.celumimagesvariant_s.ImageCropUrl.isWebpAvailable
              ? data.celumimagesvariant_s.ImageCropUrl.isWebpAvailable
              : data.iswebpavailablebool_b
            : data.iswebpavailablebool_b,
        },
      },
      VideoFile: {
        value: {
          MediaId: getMediaID(data),
          Orientation: getOrientation(data),
        },
      },
      VideoID: {
        value: getMediaID(data),
      },
      isHeroSlider: {
        value: data.isHeroSlider,
      },
      currentIndex: {
        value: data.currentIndex,
      },
      MUTVHeroVideoBackground: {
        value: data.mutvbackgroundvideometadatadetails_s,
      },
      AuthorName: isAws ? data.authorname_s : data.author_t,
      Teaser: {
        value: data.teaser_t || data.blogdescription_t,
      },
      VideoFileTranscript: {
        value: data.videofiletranscript_t,
      },
      ShortHeadline: {
        value: data.shortheadline_t || data.blogheading_t,
      },
      Variant: {
        value: data.variant_t && data.variant_t.toLowerCase(),
      },
      IsLoginRequire: {
        value: data.isloginrequire_b,
      },
      ContentType: {
        value: data.contenttype_t,
      },
      CardType: {
        value: data.cardtype_t,
      },
      ContentAccess: {
        value: data.contentaccess_t ? data.contentaccess_t : null,
      },
      UpsellMapping:
        !isAws && data.upsell_s && typeof data.upsell_s === 'string'
          ? transformUpsellData(JSON.parse(data.upsell_s))
          : data.upsell_s && transformUpsellData(data.upsell_s),
      Sponsorby: sponsor
        ? {
            fields: {
              PartnerIconDark: {
                value: {
                  Crops: {
                    img1x: sponsor.PartnerIconDark,
                  },
                },
              },
              PartnerName: {
                value: sponsor.PartnerName,
              },

              CTAURL: {
                value: {
                  href: sponsor.CTAURL,
                },
              },

              MCC_MNC: {
                value: sponsor.MCC_MNC,
              },
              PartnerIconLight: {
                value: {
                  Crops: {
                    img1x: sponsor.PartnerIconLight,
                  },
                },
              },
              PartnerText: {
                value: sponsor.PartnerText,
              },
            },
          }
        : null,
      __Published: {
        value: data.appearance_tdt || data.__published_tdt,
      },
      __PublishedBy: {
        value: data.__publishedby_t,
      },
      manutds: {
        value: {
          DestinationUrl: data.destinationurl_s,
          Author: isAws ? data.authorname_s : data.author_t,
          Tags: createTagList(data.taglist_sm, data.category_s),
          isvideoasset_b: isAws && data.isvideoasset_b,
        },
      },
    },
    rendering: {
      dataSource: data.itemid_s,
    },
  };
};

const getMediaID = (data) => {
  if (data.mediaid_s) {
    return data.mediaid_s;
  } else if (data.videofile_t) {
    return getKeyValueByName(data.videofile_t, 'MediaId');
  }
  return '';
};

const getOrientation = (data) => {
  try {
    if (data.celummetadatadetails_s && typeof data.celummetadatadetails_s === 'string') {
      const meta = JSON.parse(data.celummetadatadetails_s);
      return meta.dimension;
    } else if (data.celummetadatadetails_s && typeof data.celummetadatadetails_s === 'object') {
      return data.celummetadatadetails_s.dimension;
    }
  } catch (error) {
    return '';
  }
  return '';
};
