/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useEffect, useRef } from 'react';
import { formatDate } from '../../utils/momentDate';
import { withTranslation } from 'react-i18next';
import { DFP } from '../../components/Common/Dfp';
import PlayerProfileCarousel from './PlayerProfileCarousel';
import * as track from '../../utils/analytics';
import { PLAYER_PROFILE } from '../../constants/containerConstants';
import { createDestinationUrl } from '../../utils/utils';
const Profile = ({ t, fields, theamType, itemId }) => {
  const {
    Teaser,
    Bio,
    FullName,
    Position,
    Nationality,
    Country,
    BirthDate,
    JoinedDate,
    DebutDate,
    DebutOpposition,
    Quote,
    LatestUrl,
    LeftDate,
    manutds,
    ContentType,
    __Published,
    FirstName,
    LastName,
  } = fields;
  const DestinationUrl =
    manutds &&
    manutds.value &&
    manutds.value.DestinationUrl &&
    createDestinationUrl(manutds.value.DestinationUrl);
  const countryCss =
    Country && Country.value ? `flag flag-${Country.value.toLowerCase().replace(' ', '-')}` : '';
  const [toggleClass, setToggleClass] = useState('show-more');
  const [toggleText, setToggleText] = useState(t('Readmore'));
  const [ariaExp, setAriaExp] = useState('false');
  const [collapsed, setCollapsed] = useState('collapsed');
  const [showAd, setShowAd] = useState(false);
  const carouselRef = useRef(null);
  useEffect(() => {
    // Show ad only on client side otherwise behaves abnormally due to react hydration
    setShowAd(true);
  }, []);
  useEffect(() => {
    if (ariaExp === 'true') {
      const a = document.querySelector('.hiddenClass');
      if (a) {
        a.focus();
        setTimeout(() => {
          a.classList.remove('non-keyboard-outline');
          a.setAttribute('tabindex', '-1');
          a.setAttribute('aria-hidden', 'true');
        }, 500);
      }
    }
  }, [ariaExp]);
  const toggleShowMore = (e) => {
    if (ariaExp === 'false') {
      setCollapsed('');
      setAriaExp('true');
      setToggleClass('hide-more');
      setToggleText(t('Readless'));
    } else {
      setCollapsed('collapsed');
      setAriaExp('false');
      setToggleClass('show-more');
      setToggleText(t('Readmore'));
      setTimeout(() => {
        if (carouselRef && carouselRef.current) {
          const a = document.getElementsByClassName('mainHeader');
          const headerHeight = a && a[0] && a[0].clientHeight;
          const scrollheight = carouselRef.current.getBoundingClientRect().top + window.pageYOffset;
          headerHeight &&
            scrollheight &&
            window.scroll({
              top: headerHeight + scrollheight + 50,
              behavior: 'smooth',
            });
        }
      }, 100);
    }
    trackCard();
    e.stopPropagation();
  };
  const trackCard = () => {
    const initialData = track.data('card');
    track.analytics({
      ...initialData,
      card_name: 'playerprofile',
      container_type: PLAYER_PROFILE,
      item_id: itemId,
      content_type: ContentType && ContentType.value,
      tag: manutds && manutds.value && manutds.value.Tags,
      created_datetime: __Published && __Published.value,
      destination_url: DestinationUrl,
    });
  };
  return (
    <div>
      <div className="tab-content">
        <div className="tab-pane active" data-tabcontent="tabContent" id="tab1">
          <div className="container">
            <div className="player-detail__bio">
              <div className="player-detail-container">
                <div className="player-detail__row-full">
                  <div className="player-content">
                    <h2 className="player-title baseline">{t('Biography')}</h2>
                    <div className="player-content__inner">
                      {Teaser && Teaser.value && <p>{Teaser.value}</p>}
                      {/* <div className="row player-content__cta accordion-item" style={slide}> */}
                      <span
                        className="hiddenClass screenreader"
                        tabIndex={ariaExp === 'false' ? '-1' : '0'}
                        aria-hidden={ariaExp === 'false' ? 'true' : 'false'}
                      />
                      <div
                        className={`row player-content__cta ${collapsed}`}
                        style={{ display: ariaExp === 'false' ? 'none' : 'block' }}
                      >
                        {Bio && Bio.value && <p>{Bio.value}</p>}
                      </div>
                      <a
                        className={`togglecode ${toggleClass}`}
                        onClick={(e) => toggleShowMore(e)}
                        onKeyPress={(e) => {
                          (e.which === 13 || e.keyCode === 13) && toggleShowMore(e);
                        }}
                        tabIndex="0"
                        aria-expanded={ariaExp}
                        style={{ display: 'block' }}
                        ref={carouselRef}
                      >
                        <span aria-hidden="true">{toggleText}</span>
                        {FirstName && FirstName.value && (
                          <span className="screenreader">
                            {`${toggleText} ${t('About')} ${FirstName.value} ${
                              LastName && LastName.value
                            }`}
                          </span>
                        )}
                      </a>
                    </div>
                  </div>
                  <div className="player-history">
                    <ul className="player-history__group">
                      {Position && Position.value && (
                        <li className="player-history__group__float">
                          <span className="player-title baseline">{t('Position')}</span>
                          <p>
                            <i
                              className={`icon-player-${Position.value.toLowerCase()}${
                                theamType === 'legend' ? '-golden' : ''
                              }`}
                              data-player-type=""
                            ></i>
                            {Position.value}
                          </p>
                        </li>
                      )}
                      {Nationality && Nationality.value && (
                        <li className="player-history__group__float">
                          <span className="player-title baseline">{t('Country')}</span>
                          <p>
                            <i className={countryCss}></i>
                            {Nationality.value}
                          </p>
                        </li>
                      )}
                      {BirthDate && BirthDate.value && BirthDate.value !== '0001-01-01T00:00:00Z' && (
                        <li className="player-history__group__float">
                          <span className="player-title baseline">{t('Dateofbirth')}</span>
                          <p className="history-digit">
                            <span>{formatDate(BirthDate.value, 'playerprofile-date')}</span>
                          </p>
                        </li>
                      )}
                      {JoinedDate &&
                        JoinedDate.value &&
                        JoinedDate.value !== '0001-01-01T00:00:00Z' && (
                          <li className="player-history__group__float">
                            <span className="player-title baseline">{t('Joined')}</span>
                            <p className="history-digit">
                              <span>{formatDate(JoinedDate.value, 'playerprofile-date')}</span>
                            </p>
                          </li>
                        )}
                      {DebutDate &&
                        DebutDate.value &&
                        DebutDate.value !== '0001-01-01T00:00:00Z' &&
                        theamType !== 'coach' &&
                        theamType !== 'reserve' && (
                          <li className="player-history__group__float">
                            <span className="player-title baseline">
                              {theamType === 'manager' ? t('FirstMatch') : t('Uniteddebut')}
                            </span>
                            <p className="history-digit">
                              <span>{formatDate(DebutDate.value, 'playerprofile-date')}</span>
                            </p>
                            {DebutOpposition && DebutOpposition.value && (
                              <p className="history-bottom-text">{DebutOpposition.value}</p>
                            )}
                          </li>
                        )}
                      {LeftDate &&
                        LeftDate.value &&
                        LeftDate.value !== '0001-01-01T00:00:00Z' &&
                        theamType === 'legend' && (
                          <li className="player-history__group__float">
                            <span className="player-title baseline">{t('LeftUnited')}</span>
                            <p className="history-digit">
                              <span>{formatDate(LeftDate.value, 'playerprofile-date')}</span>
                            </p>
                          </li>
                        )}
                    </ul>
                  </div>
                  {Quote && Quote.value && theamType !== 'coach' && theamType !== 'legend' && (
                    <div className="player-quote">
                      <span>{Quote.value}</span>
                    </div>
                  )}
                </div>
                <div
                  className="wide-add-panel ad-block dashboard"
                  aria-hidden="true"
                  data-an-track="true"
                  data-track-type="dfp"
                  tabIndex="-1"
                >
                  {showAd && (
                    <DFP
                      slot="/11820346/Hercules_ROS_970x250//750x200//300x250"
                      size={[
                        [970, 250],
                        [750, 200],
                        [300, 250],
                      ]}
                      tag={manutds}
                      itemId={itemId}
                    />
                  )}
                </div>
              </div>
              {LatestUrl && LatestUrl.value && (
                <div className="home-row playerProfile">
                  <div className="carousal player-prof-carousal">
                    <PlayerProfileCarousel data={fields} />
                  </div>
                </div>
              )}
              <div className="player-detail-container">
                <div
                  className="wide-add-panel ad-block billboard"
                  aria-hidden="true"
                  data-an-track="true"
                  data-track-type="dfp"
                  tabIndex="-1"
                >
                  {showAd && (
                    <DFP
                      slot="/11820346/Hercules_ROS_970x90//728x90//320x50"
                      size={[
                        [970, 90],
                        [728, 90],
                        [320, 50],
                      ]}
                      tag={manutds}
                      itemId={itemId}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
Profile.defaultProps = {
  theamType: null,
};
export default withTranslation()(Profile);
