/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_MATCHES,
  SET_MATCHES_FIXTURE,
  SET_MATCHES_RESULT,
  ERROR_MATCHES,
  FETCH_TABLES,
  SET_TABLES,
} from '../constants/actions/actionConstant';
import { extend, groupBy, mergeWith, sortBy, uniqBy } from 'lodash';
import { getCookie } from '../utils/utils';
import MU from '../constants/muConstants';
import {
  calculatePenalty,
  customizer,
  isLiveByPeriod,
  isLivePrePeriod,
  isMUTeam,
  matchMinutes,
  updateGoal,
} from '../utils/matchUtils';

export const noOfListingRecords = 30;

export const matchesState = {
  status: null,
  listingLoader: true,
  loadMoreListing: false,
  postponedMatcheslisting: null,
  listing: null,
  currentIndex: 0,
  teamTypeFilters: null,
  filterGroups: null,
  resultData: null,
  cronTiming: 60000,
  rawListing: [],
  isLoadMore: false,
};

export const matchesReducer = (state = matchesState, { type, response, actionType } = {}) => {
  switch (type) {
    case FETCH_MATCHES:
      return {
        ...state,
        status: 'fetching',
        listingLoader: actionType !== 'cronJob',
        listing: actionType === 'default' ? null : state.listing,
        postponedMatcheslisting: actionType === 'default' ? null : state.postponedMatcheslisting,
        isLoadMore: actionType === 'loadMore',
      };
    case SET_MATCHES_FIXTURE: {
      return {
        ...state,
        status: 'fetched',
        ...populateFixtures(response.data, state, actionType, false),
        ...populateData(response.data, 'default'),
      };
    }
    case SET_MATCHES_RESULT: {
      return {
        ...state,
        status: 'fetched',
        ...populateFixtures(response.data, state, actionType, true),
        ...populateData(response.data, 'default'),
      };
    }
    case FETCH_TABLES:
      return {
        ...state,
        status: 'fetching',
        listingLoader: true,
        tables: null,
        isLoadMore: actionType === 'loadMore',
      };
    case SET_TABLES: {
      return {
        ...state,
        status: 'fetched',
        listingLoader: false,
        ...populateData(response.data, 'default'),
      };
    }
    case ERROR_MATCHES:
      return { ...state, status: 'error', matchesData: null, listingLoader: false };
    default:
      return state;
  }
};

const populateData = (data, actionType) => {
  let defaultData = {};
  if (actionType === 'default') {
    if (
      data.PageFilterResponse &&
      data.PageFilterResponse.grouped &&
      data.PageFilterResponse.grouped._parent &&
      data.PageFilterResponse.grouped._parent.groups &&
      data.PageFilterResponse.grouped._parent.groups.length > 0
    ) {
      const filterGroups = data.PageFilterResponse.grouped._parent.groups;
      defaultData.filterGroups = filterGroups;
    }
    if (
      data.TeamListResponse &&
      data.TeamListResponse.response &&
      data.TeamListResponse.response.docs &&
      data.TeamListResponse.response.docs.length > 0
    ) {
      const teamTypeFilters = data.TeamListResponse.response.docs;
      defaultData.teamTypeFilters = teamTypeFilters;
    }
    if (
      data.LatestResultsResponse &&
      data.LatestResultsResponse.response &&
      data.LatestResultsResponse.response.docs &&
      data.LatestResultsResponse.response.docs.length > 0
    ) {
      const resultData = data.LatestResultsResponse.response.docs[0];
      defaultData.resultData = resultData;
    }
    if (
      data.LiveTableResponse &&
      data.LiveTableResponse.response &&
      data.LiveTableResponse.response.docs &&
      data.LiveTableResponse.response.docs.length > 0
    ) {
      defaultData.tables = data.LiveTableResponse.response.docs;
    }
  }
  return defaultData;
};

const populateFixtures = (data, state, actionType, isResults) => {
  const objectKey = isResults ? 'ResultListResponse' : 'FixtureListResponse';
  let newSate = {
    listingLoader: false,
    postponedMatcheslisting: null,
    listing: null,
  };
  if (
    data &&
    data[objectKey] &&
    data[objectKey].response &&
    data[objectKey].response.docs &&
    data[objectKey].response.docs.length > 0
  ) {
    let resultsDocs = data[objectKey].response.docs;
    let postponedMatcheslisting;
    const totalListingRecords = data[objectKey].response.numFound;

    if (!isResults) {
      if (actionType === 'cronJob') {
        resultsDocs = filterPollingData(state.rawListing, resultsDocs);
      } else if (actionType === 'loadMore') {
        newSate.rawListing = [...state.rawListing, ...resultsDocs];
      } else {
        newSate.rawListing = [...resultsDocs];
      }
      postponedMatcheslisting = resultsDocs.filter((resultDoc) => resultDoc.ispostponed_b == true);
      resultsDocs = resultsDocs.filter((resultDoc) => resultDoc.ispostponed_b != true);
    }

    const result = groupBy(resultsDocs, (resultDoc) => {
      const matchDate = resultDoc.matchdate_tdt;
      if (resultDoc && resultDoc.cardtype_t === 'opta') {
        const returnedCronTimer = updateLiveData(resultDoc);
        if (returnedCronTimer) newSate.cronTiming = returnedCronTimer;
      }

      // group goals && red card
      let groupedAwayGoalDetails = [];
      let groupedHomeGoalDetails = [];
      // away goals & red cards
      if (resultDoc.resultdata_t && resultDoc.resultdata_t.AwayResult) {
        if (resultDoc.resultdata_t.AwayResult.GoalDetailEntityList) {
          groupedAwayGoalDetails = [
            ...groupedAwayGoalDetails,
            ...resultDoc.resultdata_t.AwayResult.GoalDetailEntityList,
          ];
        }
        if (resultDoc.resultdata_t.AwayResult.BookingEntityList) {
          groupedAwayGoalDetails = [
            ...groupedAwayGoalDetails,
            ...resultDoc.resultdata_t.AwayResult.BookingEntityList,
          ];
        }
      }
      resultDoc.awayGoals = updateGoal(groupedAwayGoalDetails);

      // home goals & red cards
      if (resultDoc.resultdata_t && resultDoc.resultdata_t.HomeResult) {
        if (resultDoc.resultdata_t.HomeResult.GoalDetailEntityList) {
          groupedHomeGoalDetails = [
            ...groupedHomeGoalDetails,
            ...resultDoc.resultdata_t.HomeResult.GoalDetailEntityList,
          ];
        }
        if (resultDoc.resultdata_t.HomeResult.BookingEntityList) {
          groupedHomeGoalDetails = [
            ...groupedHomeGoalDetails,
            ...resultDoc.resultdata_t.HomeResult.BookingEntityList,
          ];
        }
      }
      resultDoc.homeGoals = updateGoal(groupedHomeGoalDetails);

      // set penaltyScore
      if (
        resultDoc.resultdata_t &&
        resultDoc.resultdata_t &&
        resultDoc.resultdata_t.AwayResult &&
        resultDoc.resultdata_t.AwayResult.PenaltyShootEntityList &&
        resultDoc.resultdata_t.AwayResult.PenaltyShootEntityList.length > 0
      ) {
        resultDoc.awayPenalty = calculatePenalty(
          resultDoc.resultdata_t.AwayResult.PenaltyShootEntityList,
          resultDoc.hometeamid_t,
          resultDoc.awayteamid_t
        );
      }
      if (
        resultDoc.resultdata_t &&
        resultDoc.resultdata_t &&
        resultDoc.resultdata_t.HomeResult &&
        resultDoc.resultdata_t.HomeResult.PenaltyShootEntityList &&
        resultDoc.resultdata_t.HomeResult.PenaltyShootEntityList.length > 0
      ) {
        resultDoc.homePenalty = calculatePenalty(
          resultDoc.resultdata_t.HomeResult.PenaltyShootEntityList,
          resultDoc.hometeamid_t,
          resultDoc.awayteamid_t
        );
      }

      // set broadcaster
      resultDoc.showBroadcasterLogo = false;
      if (resultDoc && resultDoc.broadcasterdetails_s) {
        const broadCasterDetails = resultDoc.broadcasterdetails_s;
        let geoFromCookie = getCookie('geo');
        let broadCasterGeo = resultDoc.broadcasterdetails_s.Geo;

        if (geoFromCookie) geoFromCookie = geoFromCookie.toLowerCase();

        if (broadCasterGeo) broadCasterGeo = broadCasterGeo.toLowerCase();

        if (
          broadCasterDetails.GlobalOverride === '1' ||
          (broadCasterGeo && broadCasterGeo === geoFromCookie)
        )
          resultDoc.showBroadcasterLogo = true;
      }

      if (isResults) {
        if (isMUTeam(resultDoc.awayteamid_t) || isMUTeam(resultDoc.hometeamid_t)) {
          resultDoc.ctatitle_t = resultDoc.matchdayresultctatitle_t;
          resultDoc.ctaurl_t = resultDoc.matchdayctaurl_t;
        }
      } else if (!isResults) {
        // Change CTA in case of live match
        if (
          (resultDoc.resultdata_t && isLiveByPeriod(resultDoc.resultdata_t.Period)) ||
          isLivePrePeriod(resultDoc.matchdate_tdt)
        ) {
          if (isMUTeam(resultDoc.awayteamid_t) || isMUTeam(resultDoc.hometeamid_t)) {
            resultDoc.ctatitle_t = resultDoc.matchdaylivectatitle_t;
            resultDoc.ctaurl_t = resultDoc.matchdayctaurl_t;
          }
        } else {
          try {
            const cta_s_config = MU.matchesFixtureBuyTicketUrl;
            resultDoc.ctaurl_t = resultDoc.ticketctaurl_t
              ? `${resultDoc.ticketctaurl_t}${cta_s_config}`
              : '';
            resultDoc.ctatitle_t = resultDoc.ticketctatitle_t;
          } catch (e) {
            console.error(
              'config not defined for ticket CTA : field value- ' +
                resultDoc.ctaurl_t +
                ' config value - ' +
                MU.matchesFixtureBuyTicketUrl,
              e
            );
          }
        }
      }

      if (matchDate) {
        let date;
        const d = new Date(matchDate);
        let month = d.getMonth() + 1;
        let day = d.getDate();
        if (month < 10) {
          month = `0${month}`;
        }
        if (day < 10) {
          day = `0${day}`;
        }
        resultDoc.month = month;
        date = `${d.getFullYear()}-${month}-${day}`;

        return [date, resultDoc.competitionname_t];
      }
    });

    if (result) {
      var monthsArr = {};
      for (var key in result) {
        var monthYear = key.substring(0, 7);
        if (monthYear) {
          monthYear += '-02';
        }
        if (monthsArr[monthYear]) {
          monthsArr[monthYear][key] = result[key];
        } else {
          monthsArr[monthYear] = {};
          monthsArr[monthYear][key] = result[key];
        }
      }
      if (state.listing && actionType === 'loadMore')
        newSate.listing = mergeWith(state.listing, monthsArr, customizer);
      else newSate.listing = monthsArr;

      // postponedMatcheslisting
      if (postponedMatcheslisting && postponedMatcheslisting.length > 0) {
        if (state.postponedMatcheslisting && actionType === 'loadMore') {
          newSate.postponedMatcheslisting = [
            ...state.postponedMatcheslisting,
            ...postponedMatcheslisting,
          ];
        } else {
          newSate.postponedMatcheslisting = postponedMatcheslisting;
        }
      } else {
        newSate.postponedMatcheslisting = state.postponedMatcheslisting;
      }

      if (actionType !== 'cronJob') {
        newSate.currentIndex = data[objectKey].response.start;
        if (
          actionType !== 'cronJob' &&
          newSate.currentIndex + noOfListingRecords < totalListingRecords
        ) {
          newSate.loadMoreListing = true;
        } else {
          newSate.loadMoreListing = false;
        }
      }
    }
  } else {
    newSate.loadMoreListing = false;
  }
  return newSate;
};

const updateLiveData = (fixture) => {
  const optaContent = fixture.optacontent;
  let cronTiming;
  if (optaContent && optaContent.value && optaContent.value.length > 0) {
    const optaData = optaContent.value[0];
    if (optaData && optaData.MatchDataList && optaData.MatchDataList.length > 0) {
      const matchData = optaData.MatchDataList[0];
      if (matchData) {
        fixture.resultdata_t.Period = matchData.Period;
        fixture.resultdata_t.AwayResult.Score = matchData.AwayTeam.Score;
        fixture.resultdata_t.HomeResult.Score = matchData.HomeTeam.Score;
        fixture.resultdata_t.AwayResult.GoalDetailEntityList = matchData.AwayTeam
          .GoalDetailEntityList
          ? matchData.AwayTeam.GoalDetailEntityList
          : matchData.AwayTeam.GoalDetails;
        fixture.resultdata_t.HomeResult.GoalDetailEntityList = matchData.HomeTeam
          .GoalDetailEntityList
          ? matchData.HomeTeam.GoalDetailEntityList
          : matchData.HomeTeam.GoalDetails;
        fixture.resultdata_t.AwayResult.PenaltyShootEntityList = matchData.AwayTeam
          .PenaltyShootEntityList
          ? matchData.AwayTeam.PenaltyShootEntityList
          : matchData.AwayTeam.PenaltyShots;
        fixture.resultdata_t.HomeResult.PenaltyShootEntityList = matchData.HomeTeam
          .PenaltyShootEntityList
          ? matchData.HomeTeam.PenaltyShootEntityList
          : matchData.HomeTeam.PenaltyShots;
        if (isLiveByPeriod(fixture.resultdata_t.Period)) {
          cronTiming = 15000;
        }
      }
    }
  }
  return cronTiming;
};

const filterPollingData = (rawListing, responseData) => {
  if (responseData) {
    let mergedData = extend(rawListing, responseData);
    mergedData = uniqBy(mergedData, 'itemid_s');
    return mergedData;
  }
};
