/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState } from 'react';
import { buildImgUrl, getAssetUrlJpeg } from '../../utils/utils';

const ImagePanel = ({ item, statsType }) => {
  const [avtarClass, setAvtarClass] = useState('');
  const [avatarErrorSrc, setAvatarErrorSrc] = useState('');
  const isPlayer = statsType;
  const stgPlayerAssetUrl = getAssetUrlJpeg() + '/headShots/p';
  const getImageURLfromString = (relPath) => {
    if (typeof relPath === 'string') {
      relPath = JSON.parse(relPath);
    }
    return buildImgUrl(relPath.img1x);
  };
  const onErrorImg = (e) => {
    if (isPlayer) {
      setAvtarClass('icon-avatar');
      setAvatarErrorSrc('../../assets/images/bg/avatar.png');
    }
  };
  return (
    <React.StrictMode>
      {item && item.CrestImage && !item.LastName && !item.KnownName && (
        <img
          className={avtarClass}
          src={avatarErrorSrc !== '' ? avatarErrorSrc : getImageURLfromString(item.CrestImage)}
          alt={item.Name}
          onError={(e) => onErrorImg(e)}
        />
      )}
      {(item.KnownName || item.LastName) && item.ID && (
        <img
          className={avtarClass}
          src={avatarErrorSrc !== '' ? avatarErrorSrc : `${stgPlayerAssetUrl}${item.ID}.jpg`}
          alt={item.Name}
          onError={(e) => onErrorImg(e)}
        />
      )}

      {!item.CrestImage && !item.LastName && (
        <img
          className={isPlayer ? 'icon-avatar' : ''}
          src={isPlayer ? '../../assets/images/bg/avatar.png' : ''}
          title=""
          tabIndex="0"
          alt={isPlayer ? 'avtar image' : item.AbbrevTeamName}
        />
      )}
    </React.StrictMode>
  );
};

export default ImagePanel;
