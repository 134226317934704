/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { getCssUrl, isApp } from '../utils/utils';
import MU from '../constants/muConstants';
import layout from '../constants/layoutConstants';
import SkipToContent from '../components/Common/SkipToContent';
import { APP_ONLY } from '../constants/globalConstants';
import AppOnly from '../components/Common/AppOnly';

const TemplateLayout = ({ route, context }) => {
  const name = route && route.fields && route.fields.Name && route.fields.Name.value;
  const contentType =
    route &&
    route.fields &&
    route.fields.ContentType &&
    route.fields.ContentType.value &&
    route.fields.ContentType.value.toLowerCase();
  const isProfileType =
    contentType &&
    (contentType === 'playerprofile' ||
      contentType === 'managerprofile' ||
      contentType === 'coachprofile')
      ? true
      : false;
  const location = useLocation();
  const getLoction = () => {
    let { pathname } = location;
    pathname = pathname && pathname.toLowerCase();
    if (route?.placeholders['mufc-ph-body'][0]?.componentName === 'NewSearch') {
      return getCssUrl(layout.NEW_SEARCH_lAYOUT, MU.language);
    } else if (pathname.includes('/search')) {
      return getCssUrl(layout.SEARCH_LAYOUT, MU.language);
    } else if (pathname.includes('news/detail/') || pathname.includes('printproduct/detail/')) {
      return getCssUrl(layout.ARTICLE_DETAIL, MU.language);
    } else if (pathname.includes('players-and-staff/detail/') || isProfileType) {
      return getCssUrl(layout.PLAYER_PROFILE_LAYOUT, MU.language);
    } else if (pathname.includes('myunited')) {
      return getCssUrl(layout.MYUNITED_LAYOUT, MU.language);
    } else if (pathname.includes('events/liveevents') || pathname.includes('Events/LiveEvents/')) {
      return getCssUrl(layout.NON_MATCHDAY_LAYOUT, MU.language);
    } else {
      return getCssUrl(layout.TEMPLATE_LAYOUT, MU.language);
    }
  };
  const isMuscPage = () => {
    const { pathname } = location;
    if (pathname.includes('/musc')) {
      return getCssUrl(layout.SECRETARY, MU.language);
    }
  };

  const { pageEditing } = context;
  return (
    <React.Fragment>
      <Helmet>
        {pageEditing && (
          <link href={getCssUrl(layout.EDITOR_LAYOUT, MU.language)} rel="stylesheet" />
        )}
      </Helmet>
      {(typeof window === 'undefined' || process.env.NODE_ENV === 'development') && (
        <Helmet>
          <link href={getLoction()} rel="stylesheet" />
          <link href={isMuscPage()} rel="stylesheet" />
        </Helmet>
      )}
      {!isApp() &&
      (route &&
        route.fields &&
        route.fields.Media &&
        route.fields.Media.value &&
        route.fields.Media.value.toLowerCase()) === APP_ONLY ? (
        <AppOnly data={context && context.AppOnly} />
      ) : (
        <div
          className={`page-wrap ${MU.reverseTheme} ${MU.gapClass} ${
            pageEditing ? 'expeditor' : ''
          }`}
        >
          <SkipToContent />
          {!isApp() && (
            <Placeholder
              missingComponentComponent={() => {
                return null;
              }}
              name="mufc-ph-header"
              rendering={route}
            />
          )}
          <div className="main-container" id="content">
            {name && <h1 className="screenreader">{name}</h1>}
            <Placeholder name="mufc-ph-body" rendering={route} />
            <div>
              {!isApp() && (
                <Placeholder
                  missingComponentComponent={() => {
                    return null;
                  }}
                  name="mufc-ph-footercontainer"
                  rendering={route}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default TemplateLayout;
