/**
 *  © 2022-2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */
import { createDestinationUrl } from '../utils/utils';

let g_API_KEY = '';
let g_DISCOVERY_DOC = '';
let tokenClient = '';
let g_calendarId = '';
let g_destinationurl = '';

/**
 * Callback after api.js is loaded.
 */
export const gapiLoaded = (API_KEY, DISCOVERY_DOC, destinationurl) => {
  if (typeof window !== 'undefined' && window.gapi) {
    g_API_KEY = API_KEY;
    g_DISCOVERY_DOC = DISCOVERY_DOC;
    g_destinationurl = destinationurl;
    window.gapi.load('client', initializeGapiClient);
  }
};

/**
 * Callback after the API client is loaded. Loads the
 * discovery doc to initialize the API.
 */
const initializeGapiClient = async () => {
  await window.gapi.client.init({
    apiKey: g_API_KEY && g_API_KEY.trim(),
    discoveryDocs: g_DISCOVERY_DOC && [g_DISCOVERY_DOC],
  });
};

/**
 * Callback after Google Identity Services are loaded.
 */
export const gisLoaded = (CLIENT_ID, SCOPES) => {
  if (typeof window !== 'undefined' && window.google) {
    tokenClient = window.google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      callback: '', // defined later
    });
    //setTokenClient(tokenClient);
  }
};

/**
 *  Sign in the user upon button click.
 */
export const handleAuthClick = (calendarId) => {
  g_calendarId = calendarId;
  if (tokenClient) {
    tokenClient.callback = async (resp) => {
      if (resp.error !== undefined) {
        return resp;
      }
      window.gapi.client.load('calendar', 'v3', subscribeToCalendar);
    };

    if (window.gapi.client.getToken() === null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      tokenClient.requestAccessToken({ prompt: 'consent' });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      tokenClient.requestAccessToken({ prompt: '' });
    }
  }
};

const subscribeToCalendar = () => {
  var calendarId = g_calendarId; // Set the calendar ID to subscribe

  var request = window.gapi.client.calendar.calendarList.insert({
    id: calendarId,
    defaultReminders: [{ method: 'popup', minutes: 15 }],
  });

  request.execute(function (response) {
    console.log('Calendar subscribed successfully:', response);
    g_destinationurl && (window.location.href = createDestinationUrl(g_destinationurl));
  });
};
