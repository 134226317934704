/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import { leagueTableConstants } from '../../constants/leagueTableConstants';

const TableHeader = ({ headerValue, t }) => {
  return (
    <tr className="table-head">
      <td className="first-col"></td>
      <th scope="col" className="second-col">
        <span aria-hidden="true">{headerValue.position_t}</span>
        <span className="screenreader">{t(leagueTableConstants.position_t)}</span>
      </th>
      <th scope="col" className="third-col">
        <span aria-hidden="true">{headerValue.team_t}</span>
        <span className="screenreader">{headerValue.team_t}</span>
      </th>
      <th scope="col" className="fourth-col">
        <span aria-hidden="true">{headerValue.gamesplayed_t}</span>
        <span className="screenreader">{t(leagueTableConstants.gamesplayed_t)}</span>
      </th>
      <th scope="col" className="fifth-col">
        <span aria-hidden="true">{headerValue.wins_t}</span>
        <span className="screenreader">{t(leagueTableConstants.wins_t)}</span>
      </th>
      <th scope="col" className="seven-col">
        <span aria-hidden="true">{headerValue.draws_t}</span>
        <span className="screenreader">{t(leagueTableConstants.draws_t)}</span>
      </th>
      <th scope="col" className="sixth-col">
        <span aria-hidden="true">{headerValue.lost_t}</span>
        <span className="screenreader">{t(leagueTableConstants.lost_t)}</span>
      </th>
      {headerValue.goalsfor_t && (
        <th scope="col" className="for-col">
          <span aria-hidden="true">{headerValue.goalsfor_t}</span>
          <span className="screenreader">{t(leagueTableConstants.goalsfor_t)}</span>
        </th>
      )}
      {headerValue.goalsagainst_t && (
        <th scope="col" className="against-col">
          <span aria-hidden="true">{headerValue.goalsagainst_t}</span>
          <span className="screenreader">{t(leagueTableConstants.goalsagainst_t)}</span>
        </th>
      )}
      <th scope="col" className="eight-col">
        <span aria-hidden="true">{headerValue.goalsdifference_t}</span>
        <span className="screenreader">{t(leagueTableConstants.goalsdifference_t)}</span>
      </th>
      <th scope="col" className="ninth-col">
        <span aria-hidden="true">{headerValue.teampoints_t}</span>
        <span className="screenreader">{t(leagueTableConstants.teampoints_t)}</span>
      </th>
      <th scope="col" className="tenth-col">
        <span aria-hidden="true">{headerValue.last5games_t}</span>
        <span className="screenreader">{headerValue.last5games_t}</span>
      </th>
    </tr>
  );
};

export default withTranslation()(TableHeader);
