/**
 *  © 2022 -2023 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import LazyLoad from 'react-lazyload';
import {
  getSponsorData,
  getItemId,
  getContentAccessLocal,
  createTagArr,
  getLoggedInStatus,
  showCBS,
  checkDevice,
} from '../../../utils/utils';
import Helmet from 'react-helmet';
import ImgCrop from '../../Common/ImgCrop';
import { CONTENT_TYPE, REGISTERED, FREE_TO_VIEW } from '../../../constants/globalConstants';
import Sponsor from '../../Common/Sponsor';
import * as track from '../../../utils/analytics';
import { useSponsorValue } from '../../../context/sponsorContext';
import { useModal } from '../../Common/Modal';
import ErrorBoundary from '../../Common/ErrorBoundary';
import Loader from '../../Common/Loader';
import loadable from '@loadable/component';
import { gapiLoaded, gisLoaded, handleAuthClick } from '../../../utils/calendarUtils';
import MU from '../../../constants/muConstants';
const RegistrationModal = loadable(() =>
  import('../../Modals/RegistrationModal/RegistrationModal')
);

const Googleprovider = (props) => {
  const { fields, gridClass, isMatchcenterCard, container } = props;
  const [{ isUserLogin, isLoginChecked, isUserSubscribe, isUserSkyId }] = useSponsorValue();
  const setContentAccessState = () => {
    if (fields) {
      const tags =
        (!fields.ContentAccess || !fields.ContentAccess.value) && createTagArr(fields.manutds);
      return getContentAccessLocal(
        fields.ContentAccess && fields.ContentAccess.value,
        fields.ContentType && fields.ContentType.value,
        tags
      );
    }
    return null;
  };
  const [contentAccessState] = useState(setContentAccessState());
  const { showModal, closeModal, isModalVisible } = useModal();

  if (!fields) {
    return null;
  }
  const {
    API_KEY,
    CLIENT_ID,
    DISCOVERY_DOC,
    SCOPES,
    calendarId,
    Image,
    ShortHeadline,
    Theme,
    destinationurl,
    CTATitle,
    CTAUrl,
    ContentType,
    Sponsorby,
    manutds,
    CTATitleSubscribedUser,
    CTATitleRegisteredUser,
  } = fields;
  const itemId = getItemId(props);
  const sponsorInfo = Sponsorby && getSponsorData(Sponsorby.fields);
  const cardTarget = CTAUrl && CTAUrl.value && CTAUrl.value.target;

  const getAnalyticsData = (fields) => {
    const initialData = track.data('card');
    return {
      ...initialData,
      card_name: ShortHeadline && ShortHeadline.value,
      container_type: container,
      item_id: itemId,
      content_type: ContentType && ContentType.value,
      tag: manutds && manutds.value && manutds.value.Tags,
      created_datetime: fields.__Published && fields.__Published.value,
      author: fields.Author && fields.Author.value,
      destination_url: CTAUrl && CTAUrl.value && CTAUrl.value.href,
      w_theme: Theme && Theme.value,
      w_item_name: manutds && manutds.value && manutds.value.__Name,
      subscription_status: getLoggedInStatus({
        isLoginChecked,
        isUserLogin,
        isUserSubscribe,
        isUserSkyId,
      }),
      content_status: contentAccessState,
    };
  };

  const trackCard = (fields, e) => {
    track.analytics(getAnalyticsData(fields), e);
  };

  const successCallback = ({ showContent, isSubscribedContent }) => {
    if (showContent) {
      const calendarIdValue = calendarId && calendarId.value;
      if (calendarIdValue) {
        if (!MU.isIDMEnabled) handleAuthClick(calendarIdValue);
        else return ['successCallbck', isSubscribedContent, 'googleCalendarUrl', calendarIdValue];
      }
    }
  };

  const onCardClick = (e) => {
    if (showCBS({ isUserLogin, isUserSubscribe, contentAccessState })) {
      e.preventDefault();
      showModal();
    } else {
      trackLink(e);
      calendarId && calendarId.value && handleAuthClick(calendarId.value);
    }
  };

  const trackLink = (e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: ShortHeadline && ShortHeadline.value,
        destination_url: CTAUrl && CTAUrl.value && CTAUrl.value.href,
      },
      e
    );
  };
  const getTheme = function (themeName) {
    let themeClass;
    if (themeName !== undefined && themeName !== 'undefined') {
      themeClass = themeName.toLowerCase() === 'stroke' ? 'ad-stroke' : '';
    }
    return themeClass;
  };
  const getCTAValue = () => {
    const isRegistered = contentAccessState === REGISTERED;
    if (isLoginChecked && showCBS({ isUserLogin, isUserSubscribe, contentAccessState })) {
      return isRegistered ? CTATitleRegisteredUser : CTATitleSubscribedUser;
    } else if (!isLoginChecked && contentAccessState !== FREE_TO_VIEW) {
      return isRegistered ? CTATitleRegisteredUser : CTATitleSubscribedUser;
    }
    return CTATitle;
  };

  const getA11yProps = () => {
    return checkDevice()
      ? {
          tabIndex: 0,
          role: 'link',
        }
      : {};
  };
  const getCTATitle = () => {
    const title = getCTAValue();
    if (title && title.value) {
      return (
        <span className="dfp-ads__cta red-btn-skew">
          <span className="dfp-ads-link" {...getA11yProps()}>
            <Text field={title} />
            <i className="tag-white-arrow"></i>
          </span>
        </span>
      );
    }
    return null;
  };

  return (
    <React.Fragment>
      <Helmet>
        {API_KEY && API_KEY.value && DISCOVERY_DOC && DISCOVERY_DOC.value && (
          <script
            async
            defer
            src="https://apis.google.com/js/api.js"
            onLoad={gapiLoaded(
              API_KEY.value,
              DISCOVERY_DOC.value,
              destinationurl && destinationurl.value
            )}
          ></script>
        )}
        {CLIENT_ID && CLIENT_ID.value && SCOPES && SCOPES.value && (
          <script
            async
            defer
            src="https://accounts.google.com/gsi/client"
            onLoad={gisLoaded(CLIENT_ID.value, SCOPES.value)}
          ></script>
        )}
      </Helmet>
      <div
        className={`${gridClass} ${isMatchcenterCard ? 'ratio-matchday' : 'ratio'}`}
        onClick={(e) => {
          trackCard(fields, e);
          onCardClick(e);
        }}
        data-impression={track.impression({
          card_name: ShortHeadline && ShortHeadline.value,
          content_type: ContentType && ContentType.value,
        })}
      >
        <div className="mu-content">
          <div className={`dfp-ads-card mu-item ${Theme && Theme.value && getTheme(Theme.value)}`}>
            <a
              href={'javascript:void(0)'}
              target={cardTarget}
              tabIndex="-1"
              aria-hidden="true"
              className="dfp-ads-card-anchor"
            >
              <div tabIndex="-1" className="img-holder">
                {Image && Image.value && Image.value.Crops && (
                  <LazyLoad once={true} offset={100}>
                    <ImgCrop
                      imgCrop={Image.value.Crops}
                      alt={Image.value.AltText}
                      title={Image.value.AltText}
                      isWebpAvailable={Image.value.isWebpAvailable}
                      singleSrc={false}
                      gridClass={gridClass}
                    ></ImgCrop>
                  </LazyLoad>
                )}
              </div>
            </a>
            <div className="mu-item__info">
              <Sponsor
                contentType={ContentType && ContentType.value}
                varient=""
                sponsorInfo={sponsorInfo}
                grid={gridClass}
                type={CONTENT_TYPE}
                author={fields.Author && fields.Author.value}
                cardName={ShortHeadline && ShortHeadline.value}
                container={container}
                createdDateTime={fields.__Published && fields.__Published.value}
                itemId={itemId}
                tags={manutds && manutds.value && manutds.value.Tags}
                isAdCard={true}
              ></Sponsor>
              <a href={'javascript:void(0)'}>
                {ShortHeadline && ShortHeadline.value && (
                  <h2 className="mu-item__title">
                    <Text field={ShortHeadline} />
                    <span className="screenreader">&nbsp;{ContentType && ContentType.value}</span>
                  </h2>
                )}
                {getCTATitle()}
              </a>
            </div>
          </div>
        </div>
      </div>
      {isModalVisible && (
        <ErrorBoundary>
          <RegistrationModal
            successCallback={successCallback}
            closeModal={closeModal}
            fallback={<Loader />}
            previousActiveElement={document.activeElement}
            accessType={contentAccessState}
            analyticsObj={getAnalyticsData(fields)}
          />
        </ErrorBoundary>
      )}
    </React.Fragment>
  );
};
export default Googleprovider;
