/**
 *  © 2023 -2024 DXC Technology, all rights reserved.
 *  Material published by DXC Technology on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useEffect } from 'react';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import moment from 'moment';
import { Hero_Slider_Auto } from '../../constants/containerConstants';
import {
  HomePageHeroCarouselVarientDark,
  HomePageHeroCarouselVarientLight,
  SUPPORTED_STATS_CONTENT_TYPE,
} from '../../constants/globalConstants';
import Transform from '../../data-transform';
import ImgCrop from '../Common/ImgCrop';
import Stats from '../Stats';
import * as track from '../../utils/analytics';
import ErrorBoundary from '../Common/ErrorBoundary';
import {
  checkDevice,
  handleTouchStart,
  swiperProgress,
  swiperTouchStart,
  swiperSetTransition,
  defaultSwiperAnimTime,
  swiperDragEnd,
} from '../../utils/utils';

const HeroSliderAutomation = (props) => {
  const ctaUrl = null;
  const [currentActiveSlide, setCurrentActiveSlide] = useState(0);
  const value = props?.fields?.carouselResponse?.value;
  const cardData = props?.fields?.carousel?.value?.documentsFromSolr?.response?.docs;
  const [isTouchDevice, setIsTouchDevice] = useState(true);
  useEffect(() => {
    setIsTouchDevice(checkDevice());
  }, []);

  const trackLink = (ctaTitle, linkUrl, e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: ctaTitle,
        destination_url: linkUrl,
      },
      e
    );
  };
  const varientClass = () => {
    if (value && value.Skin && value.Skin === HomePageHeroCarouselVarientLight) {
      return `carousal-${HomePageHeroCarouselVarientLight.toLowerCase()}`;
    } else {
      return `carousal-${HomePageHeroCarouselVarientDark.toLowerCase()}`;
    }
  };
  const bgnCTA = () => {
    if (value && value.Image && !value.CTATitle && !ctaUrl) {
      return 'bg-ncta';
    } else {
      return '';
    }
  };
  const isCurated = () => {
    if (isExperienceEditorActive() && value && !value.IsAuto) {
      return 'curated';
    } else {
      return '';
    }
  };

  const renderExpEditior = () => {
    if (value && value.IsAuto) {
      return (
        <div className="carousal__info">
          <b>
            Slider Auto Mode
            <br />
          </b>
        </div>
      );
    }
  };

  const sliderArray = props?.fields?.carousel?.value?.documentsFromSolr?.response?.docs;
  const isSwiperEnableAnimation = props?.fields?.carouselResponse?.value?.EnableAnimation;
  const sliderPlaySpeed = () => {
    if (isSwiperEnableAnimation && sliderArray && Array.isArray(sliderArray)) {
      let bgVdSlideAnim, bgImgSlideAnim;
      const durationValue = sliderArray[currentActiveSlide]?.mutvbackgroundvideometadatadetails_s;
      const bgVdSlideDuration = durationValue && JSON.parse(durationValue)?.duration;
      const bgImgSlideDuration = props?.fields?.carouselResponse?.value?.ScrollDuration;
      if (bgVdSlideDuration) {
        bgVdSlideAnim = moment.duration(bgVdSlideDuration).asMilliseconds();
      }
      if (bgImgSlideDuration) {
        const parsedDuration = parseInt(bgImgSlideDuration);
        if (!isNaN(parsedDuration)) {
          bgImgSlideAnim = parsedDuration * 1000; // Convert seconds to milliseconds
        }
      }
      return bgVdSlideAnim
        ? bgVdSlideAnim
        : bgImgSlideAnim
        ? bgImgSlideAnim
        : defaultSwiperAnimTime;
    }
  };

  const renderCarouselSlides = () => {
    return (
      <React.Fragment>
        <Swiper
          slidesPerView={1}
          autoplay={{
            enabled: isSwiperEnableAnimation,
            delay: sliderPlaySpeed(),
            disableOnInteraction: false,
          }}
          speed={1000}
          watchSlidesProgress={true}
          navigation={!isTouchDevice}
          allowTouchMove={isTouchDevice ? true : false}
          pagination={{ clickable: true }}
          modules={[Navigation, Pagination, Autoplay]}
          onActiveIndexChange={(swiper) => setCurrentActiveSlide(swiper?.activeIndex)}
          onProgress={(swiper) => swiperProgress(swiper)}
          onTouchStart={(swiper) => swiperTouchStart(swiper)}
          onSetTransition={(swiper) => swiperSetTransition(swiper)}
          onTouchMove={(Swiper) => handleTouchStart(Swiper)}
        >
          {cardData.map((item, i) => {
            return (
              <SwiperSlide key={i}>
                <div className="slide-inner">
                  <div className="bg_vd__gradient"></div>
                  {renderFromSolr(item, i)}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </React.Fragment>
    );
  };

  const renderFromSolr = (item, i) => {
    if (
      item &&
      item.contenttype_t &&
      SUPPORTED_STATS_CONTENT_TYPE.includes(item.contenttype_t.toLowerCase())
    ) {
      return <Stats item={item} isAws={true} isCarousel={true} />;
    } else {
      return (
        <Transform
          key={i}
          currentIndex={i}
          currentActiveSlide={currentActiveSlide}
          data={item}
          source="solr"
          container={Hero_Slider_Auto}
          gridClass="grid-12"
          isCarousel={true}
          nativeCard={true}
          isHeroSlider={true}
          CTATitle={props?.fields?.carouselResponse?.value?.CTATitle}
          CTATitleRegisteredUser={props?.fields?.carouselResponse?.value?.CTATitleRegisteredUser}
          CTATitleSubscribedUser={props?.fields?.carouselResponse?.value?.CTATitleSubscribedUser}
        />
      );
    }
  };

  return (
    <ErrorBoundary>
      <div
        className={`heroSliderAuto heroBGVideo automated ${varientClass()} ${bgnCTA()} ${isCurated()}`}
      >
        <div className="hero-carousal container">
          <div className="carousal">
            {value && value.Image && (
              <div className="mu-content">
                <div className="img-holder">
                  <LazyLoad once={true} offset={100}>
                    <ImgCrop
                      className={'adaptive carousal-cover'}
                      imgCrop={value?.Image && JSON.parse(value.Image)}
                      alt={value.AltText}
                      title={value.AltText}
                      isWebpAvailable={value?.isWebpAvailable}
                      onErrorClass="no-img"
                    />
                  </LazyLoad>
                </div>
              </div>
            )}
            {isExperienceEditorActive() && renderExpEditior()}
            {/* value && value.IsAuto && */}
            {cardData && cardData.length > 0 && renderCarouselSlides()}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
export default withTranslation()(HeroSliderAutomation);
