/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect } from 'react';
import { useSponsorValue } from '../../../context/sponsorContext';
import { useMuscValue, MuscProvider } from '../../../context/muscContext';
import { redirectToSignIn } from '../../../utils/gigyaScreen';
import { fetchPreviousOrder } from '../../../actions/muscAction';
import Loader from '../../Common/Loader';
import loadable from '@loadable/component';
const PrevSubmission = loadable(() => import('./PrevSubmission'));

const PreviousSubmission = (props) => (
  <MuscProvider>
    <ViewPreviousSubmission {...props}></ViewPreviousSubmission>
  </MuscProvider>
);

const ViewPreviousSubmission = (props) => {
  const [{ isGigyaAvilable, userLoginDetail }] = useSponsorValue();
  const [{ matchTicket, prevOrderDetail, prevOrderStatus }, dispatch] = useMuscValue();
  useEffect(() => {
    if (isGigyaAvilable) {
      if (userLoginDetail && userLoginDetail.data && userLoginDetail.data.mufcNumber) {
        fetchPreviousOrder(dispatch, userLoginDetail);
      } else {
        redirectToSignIn();
      }
    }
  }, [isGigyaAvilable, userLoginDetail]);

  return prevOrderStatus === 'fetched' ? (
    <PrevSubmission
      matchTicket={matchTicket}
      prevOrderDetail={prevOrderDetail}
      userLoginDetail={userLoginDetail}
    ></PrevSubmission>
  ) : (
    <Loader />
  );
};

export default PreviousSubmission;
