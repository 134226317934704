/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { Fragment, useEffect } from 'react';
import { useModal } from '../../Common/Modal';
import MU from '../../../constants/muConstants';
import Loader from '../../Common/Loader';
import { redirectTo } from '../../../utils/utils';
import loadable from '@loadable/component';
import ErrorBoundary from '../../Common/ErrorBoundary';
const ImageGalleryModal = loadable(() =>
  import('../../Modals/ImageGalleryModal/ImageGalleryModal')
);

const ImageGalleryDetail = (props) => {
  const { showModal, closeModal, isModalVisible } = useModal();
  const { manutds, Teaser, Author } = props.fields;
  const language = MU.language ? MU.language : '';
  const itemId = manutds && manutds.value && manutds.value.Id;
  useEffect(() => {
    showModal(manutds && manutds.value && manutds.value.DestinationUrl);
  }, []);
  return (
    <Fragment>
      {isModalVisible && (
        <ErrorBoundary>
          <ImageGalleryModal
            cardName={Teaser && Teaser.value}
            author={Author && Author.value}
            closeModal={closeModal}
            itemid={`{${itemId}}`}
            fallback={<Loader />}
            onClose={() => redirectTo('/', language)}
            isDestinationPage={true}
            galleryData={props.fields}
          />
        </ErrorBoundary>
      )}
    </Fragment>
  );
};
export default React.memo(ImageGalleryDetail);
