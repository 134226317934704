/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import Loader from '../../Common/Loader';
const CManager = (props) => {
  const [spinner, setSpinner] = useState(true);
  const [height, setHeight] = useState('auto');
  let src = 'https://www.candidatemanager.net/cm/p/pJobs.aspx?mid=YFDU&sid=YAZAZEV';
  const displayMessage = (evt) => {
    let message;
    if (evt.origin == 'https://candidatemanager.net') {
      message = '[iframe] Height ' + evt.data.pageHeight + ' from ' + evt.origin;
      // this console.info is required to validate that the candidatemanager.net is responding to the manutd.com
      console.info('info from candidatemanager', {
        message: message,
        evt: evt,
      });
      setHeight(evt.data.pageHeight + 'px');
    }
  };
  const hideSpinner = () => {
    setSpinner(false);
    if (window.addEventListener) {
      // For standards-compliant web browsers
      window.addEventListener('message', displayMessage, false);
    } else {
      window.attachEvent('onmessage', displayMessage);
    }
  };
  const removeEvents = () => {
    if (window.addEventListener) {
      // For standards-compliant web browsers
      window.removeEventListener('message', displayMessage, false);
    } else {
      window.detachEvent('onmessage', displayMessage);
    }
  };
  useEffect(() => {
    return removeEvents;
  }, []);
  return (
    <div className="tpl-basic">
      <div className="row">
        <div className="basic-tpl-block iframeresize">
          <h2>Permanent &amp; Temporary Opportunities</h2>
          <div className="basic-tpl-block__intro">
            <p>
              To apply for any of these roles you will be required to Log In/Register with our Jobs
              Portal within this section.
            </p>
          </div>
          {spinner && <Loader></Loader>}
          <div className="resize-loader">
            <iframe
              src={src}
              onLoad={hideSpinner}
              className="iframeresizesrc"
              border="0"
              outline="0"
              scrolling="yes"
              width="100%"
              style={{ height: height }}
              title="templatepage"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CManager;
