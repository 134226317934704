/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { getPublisherType2, jsonStringify } from '../../utils/structureData';
const ImageData = (props) => {
  const { imageStructureData } = props.structureData;
  const getPublisher = (publisher) => {
    return publisher
      ? Object.assign(
          {
            '@type': publisher.type,
            url: publisher.url,
            name: publisher.name,
            sameAs: publisher.sameAs,
          },
          getPublisherType2(publisher)
        )
      : {};
  };
  const data = imageStructureData && {
    '@context': imageStructureData.context,
    '@type': imageStructureData.type,
    url: imageStructureData.url,
    width: {
      '@type': imageStructureData.width && imageStructureData.width.type,
      name: imageStructureData.width && imageStructureData.width.name,
    },
    height: {
      '@type': imageStructureData.height && imageStructureData.height.type,
      name: imageStructureData.height && imageStructureData.height.name,
    },
    publisher: getPublisher(imageStructureData.publisher),
  };
  return imageStructureData ? (
    <Helmet>
      <script data-schema="ImageObject" type="application/ld+json">
        {`${jsonStringify(data)}`}
      </script>
    </Helmet>
  ) : null;
};
export default ImageData;
