/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import ErrorBoundary from '../../Common/ErrorBoundary';
import MutvHeroContent from './MutvHeroContent';
import moment from 'moment';
import {
  checkDevice,
  handleTouchStart,
  swiperProgress,
  swiperTouchStart,
  swiperSetTransition,
  defaultSwiperAnimTime,
} from '../../../utils/utils';
const MutvHeroContainer = ({ maskRequired, callApi, data, t }) => {
  const {
    heroDetails,
    heroDetailSortedArray,
    AudioStreamResponse,
    MuTvHeroContainerResponse,
    PricePackResponse,
    Settings,
  } = data;
  const [mutvHeroPlayMainVd, setMutvHeroPlayMainVd] = useState(false);
  const [currentActiveSlide, setCurrentActiveSlide] = useState(0);
  const [closemutvHeroPlayMainVd, setClosemutvHeroPlayMainVd] = useState(false);

  const [isTouchDevice, setIsTouchDevice] = useState(true);
  useEffect(() => {
    setIsTouchDevice(checkDevice());
  }, []);

  const isSwiperEnableAnimation = Settings?.EnableAnimation;

  const sliderPlaySpeed = () => {
    if (isSwiperEnableAnimation && heroDetailSortedArray && Array.isArray(heroDetailSortedArray)) {
      let bgVdSlideAnim, bgImgSlideAnim;
      const bgVdSlideDuration =
        heroDetailSortedArray[currentActiveSlide]?.mutvbackgroundvideometadatadetails_s?.duration ||
        heroDetailSortedArray[currentActiveSlide]?.mutvbackgroundvideometadatadetails_s?.Duration ||
        heroDetailSortedArray[currentActiveSlide]?.schedulemetadata_t?.BackgroundVideo?.duration ||
        heroDetailSortedArray[currentActiveSlide]?.schedulemetadata_t?.BackgroundVideo?.Duration;
      const bgImgSlideDuration = Settings && Settings.ScrollDuration && Settings.ScrollDuration;
      if (bgVdSlideDuration) {
        bgVdSlideAnim = moment.duration(bgVdSlideDuration).asMilliseconds();
      }
      if (bgImgSlideDuration) {
        const parsedDuration = parseInt(bgImgSlideDuration);
        if (!isNaN(parsedDuration)) {
          bgImgSlideAnim = parsedDuration * 1000; // Convert seconds to milliseconds
        }
      }
      return bgVdSlideAnim
        ? bgVdSlideAnim
        : bgImgSlideAnim
        ? bgImgSlideAnim
        : defaultSwiperAnimTime;
    }
  };

  const heroSwiper = useRef(null);

  useEffect(() => {
    if (heroSwiper?.current?.swiper) {
      if (mutvHeroPlayMainVd) {
        heroSwiper.current.swiper.autoplay.stop();
        heroSwiper.current.swiper.pagination.disable();
        heroSwiper.current.swiper.navigation.disable();
        heroSwiper.current.swiper.allowTouchMove = false;
      } else if (closemutvHeroPlayMainVd) {
        Settings?.EnableAnimation && heroSwiper.current.swiper.autoplay.start();
        heroSwiper.current.swiper.pagination.enable();
        heroSwiper.current.swiper.navigation.enable();
        heroSwiper.current.swiper.allowTouchMove = true;
        setClosemutvHeroPlayMainVd(false);
      }
    }
  }, [mutvHeroPlayMainVd, closemutvHeroPlayMainVd]);

  const renderCarouselSlides = () => {
    try {
      if (MuTvHeroContainerResponse && MuTvHeroContainerResponse.containertype_t) {
        const containerType = MuTvHeroContainerResponse.containertype_t.toLowerCase();

        if (containerType === 'vod') {
          if (heroDetailSortedArray && Array.isArray(heroDetailSortedArray)) {
            if (heroDetailSortedArray.length > 0) {
              return (
                <ErrorBoundary>
                  <div className="mutv-hero-carousal container">
                    <div className="carousal">
                      <Swiper
                        ref={heroSwiper}
                        slidesPerView={1}
                        autoplay={{
                          enabled: isSwiperEnableAnimation,
                          delay: sliderPlaySpeed(),
                          disableOnInteraction: false,
                        }}
                        speed={1000}
                        watchSlidesProgress={true}
                        navigation={!isTouchDevice}
                        allowTouchMove={isTouchDevice ? true : false}
                        pagination={{ clickable: true }}
                        modules={[Navigation, Pagination, Autoplay]}
                        onActiveIndexChange={(swiper) => setCurrentActiveSlide(swiper?.activeIndex)}
                        onProgress={(swiper) => swiperProgress(swiper)}
                        onTouchStart={(swiper) => swiperTouchStart(swiper)}
                        onSetTransition={(swiper) => swiperSetTransition(swiper)}
                        onTouchMove={(Swiper) => handleTouchStart(Swiper)}
                      >
                        {heroDetailSortedArray.map((item, index) => {
                          return (
                            <SwiperSlide key={index}>
                              <div className="slide-inner">
                                <div className="bg_vd__gradient"></div>
                                <MutvHeroContent
                                  key={index}
                                  itemID={index}
                                  maskRequired={maskRequired}
                                  callApi={callApi}
                                  heroDetails={item}
                                  AudioStreamResponse={AudioStreamResponse}
                                  MuTvHeroContainerResponse={MuTvHeroContainerResponse}
                                  PricePackResponse={PricePackResponse}
                                  heroCarousel={true}
                                  mutvHeroPlayMainVd={mutvHeroPlayMainVd}
                                  setMutvHeroPlayMainVd={setMutvHeroPlayMainVd}
                                  setClosemutvHeroPlayMainVd={setClosemutvHeroPlayMainVd}
                                  activeSlide={currentActiveSlide}
                                  t={t}
                                />
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                  </div>
                </ErrorBoundary>
              );
            }
          }
        }
      }
    } catch (e) {
      console.log('Error in fetching MuTV Hero container', e);
    }
    // If none of the conditions are met, render MutvHeroContent directly
    return (
      <MutvHeroContent
        maskRequired={maskRequired}
        callApi={callApi}
        heroDetails={heroDetails}
        AudioStreamResponse={AudioStreamResponse}
        MuTvHeroContainerResponse={MuTvHeroContainerResponse}
        PricePackResponse={PricePackResponse}
        mutvHeroPlayMainVd={mutvHeroPlayMainVd}
        setMutvHeroPlayMainVd={setMutvHeroPlayMainVd}
        isSingleVideo={true}
        t={t}
      />
    );
  };

  return (
    <>
      <ErrorBoundary>
        <div className="hero_contentBox">{heroDetails && renderCarouselSlides()}</div>
      </ErrorBoundary>
    </>
  );
};
export default withTranslation()(MutvHeroContainer);
