/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
  chartPercentageValue,
  popluteCircle,
  chartColor,
  chartIndex,
} from '../../utils/playerProfileStats';
const Saves = ({ t, item, statsType }) => {
  const buildSavesData = (item) => {
    let graphData = {};
    graphData.circleData = [[], []];
    graphData.noValue = [false, false];
    let seasonStatsItems = item && item.stats;
    if (seasonStatsItems && seasonStatsItems.length > 0) {
      for (var i = 0; i < seasonStatsItems.length; i++) {
        var statsItem = seasonStatsItems[i];
        if (statsItem && statsItem.statsKey === 'stats_SavesMade') {
          graphData.saveMade = statsItem.statsKey;
          graphData.saveMadeValue = statsItem.statsValue;
        } else if (statsItem && statsItem.statsKey === 'stats_SavesInsideTheBox') {
          graphData.saveMadeInside = statsItem.statsKey;
          graphData.saveMadeInsideValue = statsItem.statsValue;
        } else if (statsItem && statsItem.statsKey === 'stats_SavesOutsideTheBox') {
          graphData.saveMadeOutside = statsItem.statsKey;
          graphData.saveMadeOutsideValue = statsItem.statsValue;
        } else if (statsItem && statsItem.statsKey === 'stats_SavesMadeCaught') {
          graphData.saveMadeCaught = statsItem.statsKey;
          graphData.saveMadeCaughtValue = statsItem.statsValue;
        } else if (statsItem && statsItem.statsKey === 'stats_SavesMadeParried') {
          graphData.saveMadeParried = statsItem.statsKey;
          graphData.saveMadeParriedValue = statsItem.statsValue;
        }
      }
    }
    //Calculating Percentage Value from after getting service Values
    graphData = {
      ...graphData,
      ...chartPercentageValue(
        item,
        graphData,
        graphData.saveMadeInsideValue,
        graphData.saveMadeOutsideValue,
        graphData.saveMadeCaughtValue,
        graphData.saveMadeParriedValue
      ),
    };
    //Populating First Chart
    if (graphData.saveMadeInsideValue != undefined && graphData.saveMadeOutsideValue != undefined) {
      graphData = {
        ...graphData,
        ...popluteCircle(
          graphData,
          graphData.saveMadeInsidePerValue,
          graphData.saveMadeOutsidePerValue,
          chartColor[0],
          chartColor[1],
          chartIndex[0]
        ),
      };
    }
    //Populating Second Chart
    if (graphData.saveMadeCaughtValue != undefined && graphData.saveMadeParriedValue != undefined) {
      graphData = {
        ...graphData,
        ...popluteCircle(
          graphData,
          graphData.saveMadeCaughtPerValue,
          graphData.saveMadeParriedPerValue,
          chartColor[2],
          chartColor[3],
          chartIndex[1]
        ),
      };
    }
    return graphData;
  };
  const [graphData, setGraphData] = useState(null);
  useEffect(() => {
    setGraphData(buildSavesData(item));
  }, [item]);
  return (
    graphData && (
      <div className="chart-wrapper no-width">
        <h3>{t('stats_Saves')}</h3>
        <div className="season-stats-goals">
          <div className="stats-goal-score">
            <span aria-hidden="true">{graphData.saveMadeValue}</span>
            <span>
              {t('stats_Saves')}
              <span className="screenreader">&nbsp;{graphData.saveMadeValue}</span>
            </span>
          </div>
          <ul className="goals-passing-charts" aria-hidden="true">
            <li>
              <svg width="124" height="106" viewBox="2 0 42 37">
                {!graphData.noValue[0] && (
                  <circle
                    className="donut-ring"
                    cx="23"
                    cy="18"
                    r="15.8"
                    fill="transparent"
                    stroke={graphData.circleData[0][0].fillcolor}
                    strokeWidth="1.5"
                    strokeDasharray={`${graphData.circleData[0][0].CdashArrayValue1} ${graphData.circleData[0][0].CdashArrayValue2}`}
                    strokeDashoffset={graphData.circleData[0][0].offsetValue}
                  ></circle>
                )}
                {graphData.saveMadeInsideValue > 0 && (
                  <text x="34" y="36">
                    {graphData.saveMadeInsideValue}
                  </text>
                )}
                {!graphData.noValue[0] && (
                  <circle
                    className="donut-ring"
                    cx="23"
                    cy="18"
                    r="15.8"
                    fill="transparent"
                    stroke={graphData.circleData[0][1].fillcolor}
                    strokeWidth="1.5"
                    strokeDasharray={`${graphData.circleData[0][1].CdashArrayValue1} ${graphData.circleData[0][1].CdashArrayValue2}`}
                    strokeDashoffset={graphData.circleData[0][1].offsetValue}
                  ></circle>
                )}
                {graphData.saveMadeOutsideValue > 0 && (
                  <text x="2" y="4">
                    {graphData.saveMadeOutsideValue}
                  </text>
                )}
                {graphData.noValue[0] && (
                  <circle
                    className="donut-ring"
                    cx="23"
                    cy="18"
                    r="15.8"
                    fill="transparent"
                    stroke="#7b7b7b"
                    strokeWidth="1"
                    strokeDasharray="100 0"
                    strokeDashoffset="33"
                  ></circle>
                )}
              </svg>
            </li>
            <li>
              <svg width="124" height="106" viewBox="2 0 42 37">
                {!graphData.noValue[1] && (
                  <circle
                    className="donut-ring"
                    cx="23"
                    cy="18"
                    r="15.8"
                    fill="transparent"
                    stroke={graphData.circleData[1][0].fillcolor}
                    strokeWidth="1.5"
                    strokeDasharray={`${graphData.circleData[1][0].CdashArrayValue1} ${graphData.circleData[1][0].CdashArrayValue2}`}
                    strokeDashoffset={graphData.circleData[1][0].offsetValue}
                  ></circle>
                )}
                {graphData.saveMadeCaughtValue > 0 && (
                  <text x="34" y="36">
                    {graphData.saveMadeCaughtValue}
                  </text>
                )}
                {!graphData.noValue[1] && (
                  <circle
                    className="donut-ring"
                    cx="23"
                    cy="18"
                    r="15.8"
                    fill="transparent"
                    stroke={graphData.circleData[1][1].fillcolor}
                    strokeWidth="1.5"
                    strokeDasharray={`${graphData.circleData[1][1].CdashArrayValue1} ${graphData.circleData[1][1].CdashArrayValue2}`}
                    strokeDashoffset={graphData.circleData[1][1].offsetValue}
                  ></circle>
                )}
                {graphData.saveMadeParriedValue > 0 && (
                  <text x="2" y="4">
                    {graphData.saveMadeParriedValue}
                  </text>
                )}
                {graphData.noValue[1] && (
                  <circle
                    className="donut-ring"
                    cx="23"
                    cy="18"
                    r="15.8"
                    fill="transparent"
                    stroke="#7b7b7b"
                    strokeWidth="1"
                    strokeDasharray="100 0"
                    strokeDashoffset="33"
                  ></circle>
                )}
              </svg>
            </li>
          </ul>
          <ul className="pass-notations">
            <li>
              <span className="pass-point"></span>
              <span aria-hidden="true">{t('stats_SavesInsideTheBox')}</span>
              <span className="screenreader">{`${t('stats_SavesInsideTheBox')} ${
                graphData.saveMadeInsideValue
              }`}</span>
            </li>
            <li>
              <span className="pass-point"></span>
              <span aria-hidden="true">{t('stats_SavesOutsideTheBox')}</span>
              <span className="screenreader">{`${t('stats_SavesOutsideTheBox')} ${
                graphData.saveMadeOutsideValue
              }`}</span>
            </li>
            <li>
              <span className="pass-point"></span>
              <span aria-hidden="true">{t('stats_SavesMadeCaught')}</span>
              <span className="screenreader">{`${t('stats_SavesMadeCaught')} ${
                graphData.saveMadeCaughtValue
              }`}</span>
            </li>
            <li>
              <span className="pass-point"></span>
              <span aria-hidden="true">{t('stats_SavesMadeParried')}</span>
              <span className="screenreader">{`${t('stats_SavesMadeParried')} ${
                graphData.saveMadeParriedValue
              }`}</span>
            </li>
          </ul>
        </div>
      </div>
    )
  );
};
export default withTranslation()(Saves);
