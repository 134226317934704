/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import { LIGHT_THEME, CONTENT_TYPE } from '../../constants/globalConstants';
import { PLAYER_PROFILE } from '../../constants/containerConstants';
import { getSponsorData } from '../../utils/utils';
import NextPrevious from './NextPrevious';
import Sponsor from '../Common/Sponsor';
import Profile from './Profile';
import PlayerHubIcon from './PlayerHubIcon';
import { getPlayerImg, maskButtonClass } from './PlayerProfileCommon';
const Manager = ({ t, fields, itemId }) => {
  const {
    ProfileImage,
    ContentType,
    Sponsorby,
    AuthorName,
    FullName,
    __Published,
    manutds,
    CTAUrl,
    CTATitle,
    Quote,
    NextPlayer,
    FirstName,
    LastName,
    Matches,
    Wins,
    Trophies,
    PlayerTag,
    Variant,
    IsEnablePlayerHub,
    CTATitlePlayerHub,
    CTAUrlPlayerHub,
  } = fields;
  const sponsorInfo = Sponsorby && getSponsorData(Sponsorby.fields);

  return (
    <div
      className="player-detail"
      id="playerDetail"
      data-test="manager"
      data-player-tag={PlayerTag && PlayerTag.value}
      data-optimizelycontentid={itemId && itemId}
    >
      <div
        className={`player-detail__wrapper ${maskButtonClass(IsEnablePlayerHub, CTAUrlPlayerHub)}`}
      >
        <div className="bg-light-gray-mask"></div>
        <div className="player-detail__bg-black-mask">
          <div
            className={`player-avatar ${getPlayerImg('class', CTAUrl)}`}
            style={{ backgroundImage: `url(${getPlayerImg('bgImg', ProfileImage)})` }}
          ></div>
          <div className="player-detail__bg-gray-mask"></div>
        </div>
        <div className="container player-detail__main">
          <div className="row">
            <Sponsor
              contentType={ContentType && ContentType.value}
              varient={Variant && Variant.value}
              sponsorInfo={sponsorInfo}
              grid={'grid-12'}
              type={CONTENT_TYPE}
              author={AuthorName && AuthorName.value}
              cardName={ContentType && ContentType.value}
              container={PLAYER_PROFILE}
              createdDateTime={__Published && __Published.value}
              itemId={itemId}
              tags={manutds && manutds.value && manutds.value.Tags}
              theme={LIGHT_THEME}
            ></Sponsor>
            <article className="grid-12 player-detail__main__wrapper">
              <div className="player-info">
                <div className="player-info__wrapper" data-module="social-share">
                  <div className="player-info__main">
                    <h1 className="player-name">
                      {FirstName && FirstName.value}
                      <br />
                      {LastName && LastName.value}
                    </h1>
                  </div>
                  {IsEnablePlayerHub && CTAUrlPlayerHub && (
                    <PlayerHubIcon
                      IsEnablePlayerHub={IsEnablePlayerHub}
                      CTAUrlPlayerHub={CTAUrlPlayerHub}
                      CTATitlePlayerHub={CTATitlePlayerHub}
                    ></PlayerHubIcon>
                  )}
                  {CTAUrl && CTAUrl.value && CTAUrl.value.hrefMuCustom && (
                    <a
                      className="player_buy-shirt-cta"
                      href={CTAUrl.value.hrefMuCustom}
                      target="_blank"
                      data-an-track="true"
                      data-track-type="link"
                      data-track-text={CTATitle && CTATitle.value}
                    >
                      <span className="red-btn-skew">
                        {CTATitle && CTATitle.value}
                        <span className="screenreader">{t('ExternalWebsiteLink')}</span>
                      </span>
                    </a>
                  )}
                  <div className="player-quote" aria-hidden="true">
                    <span>{Quote && Quote.value}</span>
                  </div>
                </div>
              </div>
              <div className="deatil-stats" data-module="social-share">
                <div className="deatil-stats__stats">
                  <ul>
                    {Matches && Matches.value && (
                      <li data-module="profileStats">
                        <div className="stats-text">{t('Matches')}</div>
                        <div className="stats-deatil">{Matches.value}</div>
                        <span></span>
                      </li>
                    )}
                    {Wins && Wins.value && (
                      <li data-module="profileStats">
                        <div className="stats-text">{t('Wins')}</div>
                        <div className="stats-deatil">{Wins.value}</div>
                        <span></span>
                      </li>
                    )}
                    {Trophies && Trophies.value && (
                      <li data-module="profileStats">
                        <div className="stats-text">{t('Trophies')}</div>
                        <div className="stats-deatil">{Trophies.value}</div>
                        <span></span>
                      </li>
                    )}
                  </ul>
                </div>
                {Quote && Quote.value && (
                  <span className="screenreader">
                    {`${FirstName && FirstName.value} ${LastName && LastName.value} ${t('Quote')} ${
                      Quote.value
                    }`}
                  </span>
                )}
              </div>
              {NextPlayer && <NextPrevious itemId={itemId} fields={fields} />}
            </article>
          </div>
          <div className="row">
            <div data-module="navTabs">
              <div className="profile-tab-contentholder">
                <section id="profile-tab" className="profile-tab-content active">
                  <Profile fields={fields} theamType={`manager`} itemId={itemId} />
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withTranslation()(Manager);
