/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import LeftPanel from './LeftPanel';
import StatsBar from '../Common/StatsBar';
import SponsorPanel from './SponsorPanel';
import ImagePanel from './ImagePanel';
import { getStatsBarData } from '../../utils/utils';
import { addColorClass } from '../../data-transform/components/StatsTransform';
import ErrorBoundary from '../Common/ErrorBoundary';

const BreakdownNumber = ({ item, t, gridClass, carousalClass }) => {
  const isPlayer =
    item && item.opta_statentitytype_t && item.opta_statentitytype_t.toLowerCase() === 'player'
      ? true
      : false;
  return (
    <div className={`${gridClass} matchday-stats`}>
      <div className={carousalClass}>
        <div
          className={`stats-card brekdown-number ${item.IsMUEntityClass} ${
            isPlayer ? 'player-section' : 'team-section'
          }`}
        >
          <div className="brekdown-number__info">
            <SponsorPanel item={item} />
            <div className="stats-card__info">
              <LeftPanel item={item} />
              <div className="stats-card__info__right">
                <div className="chart-area">
                  <div className="brekdown-number__details">
                    <div className="brekdown-number__details__left">
                      <div className="screenreader">
                        {isPlayer
                          ? `${item.opta_statentities_t[0].statValues[0].Value} ${
                              item.opta_statentities_t[0].statValues[0].StatsName
                            } ${t('by')} ${
                              item.opta_statentities_t[0].KnownName
                                ? item.opta_statentities_t[0].KnownName
                                : item.opta_statentities_t[0].LastName
                            } ${t('Shirtnumber')} ${item.opta_statentities_t[0].JerseyNumber}`
                          : `${item.opta_statentities_t[0].statValues[0].Value} ${
                              item.opta_statentities_t[0].statValues[0].StatsName
                            } ${t('by')} ${item.opta_statentities_t[0].TeamName}`}
                      </div>
                      <div className="players__notification" aria-hidden="true">
                        <div className={`player-img ${isPlayer ? 'player-image' : 'team-image'}`}>
                          <ErrorBoundary>
                            <ImagePanel item={item.opta_statentities_t[0]} statsType={isPlayer} />
                          </ErrorBoundary>
                          {isPlayer && (
                            <span className="notification">
                              {item.opta_statentities_t[0].JerseyNumber}
                            </span>
                          )}
                        </div>
                        <h3>
                          {item.opta_statentities_t[0].KnownName
                            ? item.opta_statentities_t[0].KnownName
                            : item.opta_statentities_t[0].LastName}
                        </h3>
                      </div>
                      <div className="player__detail-chart" aria-hidden="true">
                        <svg width="140" height="140" viewBox="3 3 36 33" className="donut">
                          {item.opta_statentities_t &&
                            item.opta_statentities_t[0].statValues.map(
                              (chartObj, key) =>
                                chartObj &&
                                chartObj.max > 0 &&
                                chartObj.Value &&
                                key > 0 && (
                                  <circle
                                    key={key}
                                    className="donut-ring"
                                    cx="21"
                                    cy="21"
                                    r="16"
                                    fill="transparent"
                                    stroke={chartObj.colorVal}
                                    strokeWidth="1"
                                    strokeDasharray={chartObj.strokDashArr}
                                    strokeDashoffset={chartObj.strokDashOffset}
                                  />
                                )
                            )}
                        </svg>
                        <div className="chart-text">
                          <div className="text-score">
                            {item.opta_statentities_t[0].statValues[0].Value}
                          </div>
                          <div className="total">
                            {item.opta_statentities_t[0].statValues[0].StatsName}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="brekdown-number__details__right">
                      <ul>
                        {item.opta_statentities_t &&
                          item.opta_statentities_t[0].statValues.map(
                            (numberBrekDownItem, key) =>
                              key > 0 && (
                                <li className="brekdown-number__info" key={key}>
                                  <span aria-hidden="true">{numberBrekDownItem.StatsName}</span>
                                  <span aria-hidden="true">
                                    {`${numberBrekDownItem.Value}${
                                      numberBrekDownItem.IsPercentage === 'true' ||
                                      numberBrekDownItem.IsPercentage === true
                                        ? '%'
                                        : ''
                                    }`}
                                  </span>
                                  <div
                                    aria-hidden="true"
                                    className={`line-chart line ${
                                      numberBrekDownItem.IsPercentage === 'false' ||
                                      numberBrekDownItem.IsPercentage === false
                                        ? 'ispercentage-false-'
                                        : ''
                                    }`}
                                  >
                                    <span
                                      style={{ width: `${numberBrekDownItem.percentVal}%` }}
                                      className={addColorClass(item)}
                                    ></span>
                                  </div>
                                  <div className="screenreader">
                                    {isPlayer
                                      ? `${numberBrekDownItem.StatsName} ${t('by')} ${
                                          item.opta_statentities_t[0].KnownName
                                            ? item.opta_statentities_t[0].KnownName
                                            : item.opta_statentities_t[0].LastName
                                        }
                                  ${numberBrekDownItem.Value} ${
                                          numberBrekDownItem.IsPercentage === 'true' ||
                                          numberBrekDownItem.IsPercentage === true
                                            ? '%'
                                            : ''
                                        }`
                                      : `${numberBrekDownItem.StatsName} ${'by'} ${
                                          item.opta_statentities_t[0].TeamName
                                        }
                                    ${numberBrekDownItem.Value} ${
                                          numberBrekDownItem.IsPercentage == 'true' ||
                                          numberBrekDownItem.IsPercentage == true
                                            ? '%'
                                            : ''
                                        }`}
                                  </div>
                                </li>
                              )
                          )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <StatsBar item={getStatsBarData(item)} type="statsCard"></StatsBar>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(BreakdownNumber);
