/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { createContext, useContext, useReducer } from 'react';
import { myUnitedState, myUnitedReducer } from '../reducers/myUnitedReducer';

export const MyUnitedContext = createContext();

export const MyUnitedProvider = ({ children }) => (
  <MyUnitedContext.Provider value={useReducer(myUnitedReducer, myUnitedState)}>
    {children}
  </MyUnitedContext.Provider>
);
export const useMyUnitedValue = () => useContext(MyUnitedContext);

export default MyUnitedContext;
