/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import * as track from '../../utils/analytics';
import SubMenuChild from './SubMenuChild';
import ImgCrop from '../Common/ImgCrop';
import { buildImgUrl } from '../../utils/utils';
const MoreMenu = ({
  t,
  subChild,
  ishero,
  onMoreMenuClick,
  toggleMoreMenuAnchor,
  isSetMoreMenuActive,
  moreAlternateHero,
}) => {
  const [showMoreActive, setShowMoreActive] = useState(false);
  const toggleMoreActiveMenu = (e, item) => {
    if (typeof window !== 'undefined' && window.outerWidth < 1200) {
      const toggleValue = !showMoreActive;
      trackLink(e);
      setShowMoreActive(toggleValue);
      onMoreMenuClick(toggleValue);
      toggleMoreMenuAnchor(toggleValue);
    }
  };
  /**
   *
   * @param {open link url} targetUrl
   * @param {open link target ex:'_blank/_self'} target
   */
  const updateHrefAndTarget = (targetUrl, target) => {
    return typeof window !== 'undefined' && window.outerWidth < 1200
      ? { href: 'javascript:void(0)', target: '' }
      : { href: targetUrl ? targetUrl.toLowerCase() : '', target: target ? target : '' };
  };
  /**
   *
   * @param {clicked event object} e
   */
  const trackLink = (e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: subChild && subChild.Name,
        destination_url: subChild && subChild.TargetURL,
      },
      e
    );
  };

  /**
   * render more menu name/back links and screenreader text
   */
  const getAnchorDetails = () => {
    return (
      <React.Fragment>
        <span className="more test">{subChild.Name}</span>
        <span className="more back">
          <span aria-label={`${t('back')} ${t('To')} ${subChild.ShortName}`}>{t('back')}</span>
        </span>
        <span className="icon-header-arrow sub-menu-toggle">
          <span className="screenreader expand">{`Expand ${subChild.Name}`}</span>
          <span className="screenreader collapse">{`Collapse ${subChild.ShortName}`}</span>
        </span>
      </React.Fragment>
    );
  };
  /**
   * render moremenu brand image
   */

  const moreBrandImage = () => {
    const heroImg = subChild && subChild.CardImage && JSON.parse(subChild.CardImage);
    const heroAlternateImg =
      moreAlternateHero && moreAlternateHero.Image && JSON.parse(moreAlternateHero.Image);
    return (
      <div className="img-holder">
        <div
          className="img-holder-alternate"
          style={
            heroAlternateImg
              ? {
                  backgroundImage: `url(${buildImgUrl(
                    heroAlternateImg && heroAlternateImg.large
                  )})`,
                  backgroundSize: 'cover',
                }
              : {}
          }
        >
          {heroImg ? (
            <ImgCrop
              imgCrop={heroImg}
              singleSrc={false}
              alt={subChild && subChild.CardImageAlt}
              title={subChild && subChild.CardImageTitle}
            ></ImgCrop>
          ) : (
            <div className="mu-item__gradient"></div>
          )}
        </div>
      </div>
    );
  };
  const hasSubchildren = (subChild) => {
    if (subChild && subChild.Childrens && subChild.Childrens.length > 0) {
      if (typeof window !== 'undefined' && window.outerWidth < 1200) {
        return {
          'aria-expanded': showMoreActive ? 'true' : 'false',
        };
      }
    }
  };
  const handleMobileAccessibility = (e) => {
    if (typeof window !== 'undefined' && window.outerWidth < 1200) {
      if (e.target && e.target.closest('.sub-nav-group')) {
        const items = [
          ...e.target.closest('.sub-nav-group').querySelectorAll('li.more-menu__heading '),
        ];
        const activeElm = document.activeElement && document.activeElement.closest('li');
        const activeIndex =
          items && items.length && activeElm && items.indexOf(document.activeElement.closest('li'));
        if (activeIndex === items.length - 1) {
          e.preventDefault();
          items[0].querySelector('a').focus();
        }
      }
    }
  };
  return (
    <li className={`more-menu__heading ${showMoreActive ? 'clicked-sub-menu' : ''}`}>
      {ishero && <div className="more-hero">{moreBrandImage()}</div>}
      <a
        {...updateHrefAndTarget(subChild && subChild.TargetURL, subChild.TargetURLTarget)}
        onClick={(e) => toggleMoreActiveMenu(e)}
        {...hasSubchildren(subChild)}
        onKeyDown={(e) => {
          handleMobileAccessibility(e);
        }}
      >
        {getAnchorDetails()}
      </a>
      {subChild && subChild.Childrens && subChild.Childrens.length > 0 && (
        <SubMenuChild subChild={subChild} isSetMoreMenuActive={isSetMoreMenuActive}></SubMenuChild>
      )}
    </li>
  );
};
export default withTranslation()(React.memo(MoreMenu));
