/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import MU from '../../constants/muConstants';
import SubMenu from './SubMenu';
import * as track from '../../utils/analytics';
import { ActiveClass, Mutv } from '../../constants/globalConstants';
import { tabbable } from '../../utils/tabbable';
import ErrorBoundary from '../Common/ErrorBoundary';
import { getParameterByName } from '../../utils/utils';
import layout from '../../constants/layoutConstants';
let enterCount = 0;
const Menu = ({
  t,
  item,
  navconstant,
  myUnitedSettingItemChildrens,
  onSubMenuClick,
  onMenuHover,
  itemIndex,
  totalLength,
  setActiveMenuIndex,
  activeMenuIndex,
  moreUniqueClass,
  moreAlternateHero,
  layoutId,
  showContextualCard,
}) => {
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [hideMoreanchor, setHideMoreanchor] = useState(false);
  const [isMoreActive, setIsMoreActive] = useState(false);
  const location = useLocation();
  const toggleSubMenu = (item, e) => {
    if (typeof window !== 'undefined' && window.outerWidth < 1200) {
      const toggleValue = !showSubmenu;
      setShowSubmenu(toggleValue);
      onSubMenuClick(toggleValue);
    }
    trackLink(item, e);
  };
  /**
   *
   * @param {each item data to track link analytics} item
   * @param {clicked event object} e
   */
  const trackLink = (item, e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: item && item.Name,
        destination_url: item && item.TargetURL,
      },
      e
    );
  };

  /**
   * adding classname when menu is in opensate
   */
  const setSubmenuClass = () => (showSubmenu ? 'clicked-sub-menu' : '');
  /**
   *
   * @param {hiding back link if user in in more second level menu} toggle
   */
  const toggleMoreMenuAnchor = (toggle) => {
    setHideMoreanchor(toggle);
  };

  const [mounted, setMounted] = useState(null);
  useEffect(() => {
    setMounted(true);
  }, []);
  /**
   *
   * @param {open link url} targetUrl
   * @param {open link target ex:'_blank/_self'} target
   */
  const updateHrefAndTarget = (targetUrl, target, mounted) => {
    if (mounted && typeof window !== 'undefined' && window.outerWidth < 1200) {
      return {
        href: 'javascript:void(0)',
        target: '',
        style: { display: hideMoreanchor ? 'none' : 'block' },
      };
    } else {
      return {
        href: targetUrl ? targetUrl.toLowerCase() : '',
        target: target ? target : '',
      };
    }
  };
  /**
   *
   * @param {if more menu update heref to 'javascript:void(0)' so more is not clickable} isMoreMenu
   */
  const updateMoreHrefAndTarget = (isMoreMenu) => {
    if (isMoreMenu) {
      return {
        href: 'javascript:void(0)',
        target: '',
      };
    }
  };

  const setActiveClass = (item) => {
    if (item && MU && navconstant && navconstant.SiteStartItemId) {
      if (item.Id == MU.contextItemId && MU.contextItemParentId == navconstant.SiteStartItemId) {
        return ActiveClass;
      } else if (location && location.pathname) {
        let urlArr = location.pathname.toLowerCase().split('/');
        const retParam = typeof window !== 'undefined' && getParameterByName('ret');
        const retParamSplit = retParam && retParam.toLowerCase().split('/');
        if (layoutId && layoutId === layout.MUTV_LAYOUT) {
          urlArr = [Mutv];
        }
        if (
          (urlArr && urlArr.includes(item.ItemName && item.ItemName.toLowerCase())) ||
          (retParamSplit && retParamSplit.includes(item.ItemName && item.ItemName.toLowerCase()))
        ) {
          return ActiveClass;
        }
        return '';
      } else if (item.Id == MU.contextItemId) {
        return ActiveClass;
      }
    }
    return '';
  };
  /**
   *
   * @param {true/false this callback is called by
   * sumenu and submenuchild to match contexId/contextParentId from menu/sumenu items to
   * set as active to parentmenu} status
   */
  const isSetMoreMenuActive = (status) => {
    if (status) {
      setIsMoreActive(true);
    }
  };

  const handleAccessibility = (e) => {
    let code = e && (e.keyCode || e.which);
    const Keyboard = { UP: 38, DOWN: 40, LEFT: 37, ENTER: 13, RIGHT: 39, ESCAPE: 27, TAB: 9 };
    if (typeof window !== 'undefined' && window.outerWidth < 1200) {
    } else {
      if (code === Keyboard.ENTER || e.type === 'click') {
        if (enterCount === 0) {
          onMenuHover(e, true); // overlay open in background
          enterCount++; // increase Enter to check double enter click
          e.stopPropagation();
          e.preventDefault();
          setActiveMenuIndex(itemIndex);
        } else if (enterCount === 1) {
          enterCount = 0;
        }
      } else if (code === Keyboard.LEFT) {
        e.stopPropagation();
        e.preventDefault();
        if (itemIndex !== 0) {
          e.target &&
            e.target.parentElement &&
            e.target.parentElement.previousSibling &&
            e.target.parentElement.previousSibling.querySelector('a') &&
            e.target.parentElement.previousSibling.querySelector('a').focus();
        }
      } else if (code === Keyboard.RIGHT) {
        e.stopPropagation();
        e.preventDefault();
        if (itemIndex < totalLength) {
          e.target &&
            e.target.parentElement &&
            e.target.parentElement.nextSibling &&
            e.target.parentElement.nextSibling.querySelector('a') &&
            e.target.parentElement.nextSibling.querySelector('a').focus();
        }
      } else if (code === Keyboard.DOWN) {
        e.stopPropagation();
        e.preventDefault();
        if (activeMenuIndex === itemIndex) {
          enterCount = 0;
          const activeElm = document.activeElement;
          const rootNode = activeElm && activeElm.parentElement;
          const tabbableArr = rootNode && tabbable(rootNode);
          const currentIndex = tabbableArr && tabbableArr.indexOf(activeElm);
          tabbableArr && tabbableArr[currentIndex + 1] && tabbableArr[currentIndex + 1].focus();
        } else {
          enterCount = 1;
          setActiveMenuIndex(itemIndex);
        }
      } else if (code === Keyboard.ESCAPE) {
        setActiveMenuIndex(null);
        onMenuHover(e, false);
        enterCount = 0;
        const activeElm = document.activeElement;
        const activeMenu = activeElm && activeElm.closest('.parent.nav-item');
        activeMenu && activeMenu.querySelector('a') && activeMenu.querySelector('a').focus();
      } else if (e.shiftKey && code === Keyboard.TAB) {
        if (activeMenuIndex === itemIndex) {
          onMenuHover(e, false);
          setActiveMenuIndex(itemIndex - 1);
        } else {
          enterCount = 0;
        }
      } else if (code === Keyboard.TAB) {
        if (itemIndex < totalLength) {
          if (e.target.parentElement.classList.contains('has-no-submenu')) {
            e.stopPropagation();
            e.preventDefault();
            e.target &&
              e.target.parentElement &&
              e.target.parentElement.nextSibling &&
              e.target.parentElement.nextSibling.querySelector('a') &&
              e.target.parentElement.nextSibling.querySelector('a').focus();
          }
        }
      }
    }
  };

  const onMenuFocus = () => {
    const activeNav = document.querySelector('#navigation li.parent.nav-item.hover');
    if (activeNav) {
      enterCount = 1;
      setActiveMenuIndex(itemIndex);
    } else {
      enterCount = 0;
    }
  };

  const hasSubchild = (item) => {
    if (item && item.Childrens && item.Childrens.length > 0) {
      if (typeof window !== 'undefined' && window.outerWidth < 1200) {
        return {
          'aria-expanded': showSubmenu ? 'true' : 'false',
        };
      } else {
        return {
          'aria-haspopup': 'true',
          'aria-expanded': activeMenuIndex === itemIndex ? 'true' : 'false',
        };
      }
    }
  };

  return (
    <li
      className={`parent nav-item ${setSubmenuClass()} ${setActiveClass(item)} ${
        isMoreActive ? ActiveClass : ''
      } ${activeMenuIndex === itemIndex ? 'hover' : ''} ${
        item && item.Childrens && item.Childrens.length > 0 ? '' : 'has-no-submenu'
      } ${moreUniqueClass ? moreUniqueClass : ''}`}
      role="none"
    >
      {item && (
        <a
          {...updateHrefAndTarget(item && item.TargetURL, item.TargetURLTarget, mounted)}
          {...updateMoreHrefAndTarget(item && item.IsMoreMenu)}
          onClick={(e) => toggleSubMenu(item, e)}
          onMouseEnter={(e) => {
            onMenuHover(e, true);
            setActiveMenuIndex(null);
          }}
          onMouseLeave={(e) => onMenuHover(e, false)}
          onKeyDown={(e) => handleAccessibility(e)}
          aria-controls={item.Id}
          id={`accessible-megamenu-${item.Id}`}
          {...hasSubchild(item)}
          role="menuitem"
          tabIndex="0"
          onFocus={() => onMenuFocus()}
        >
          <span className="web">{item.Name}</span>
          <span className="mobile">{item.ShortName}</span>
          <span className="mobile back">
            <span aria-label={`${t('back')} ${t('To')} ${item.ShortName}`}>{t('back')}</span>
          </span>
          <span className="icon-header-arrow">
            {/* <span className="screenreader expand">{`Expand ${item.ShortName}`}</span> */}
            {/* <span className="screenreader collapse"> {`Collapse ${item.ShortName}`}</span> */}
          </span>
        </a>
      )}
      {typeof window !== 'undefined' && item && item.Childrens && item.Childrens.length > 0 && (
        <ErrorBoundary>
          <SubMenu
            subMenu={item}
            navconstant={navconstant}
            toggleMoreMenuAnchor={toggleMoreMenuAnchor}
            myUnitedSettingItemChildrens={myUnitedSettingItemChildrens}
            onMenuHover={onMenuHover}
            isSetMoreMenuActive={isSetMoreMenuActive}
            setActiveMenuIndex={setActiveMenuIndex}
            activeMenuIndex={activeMenuIndex}
            menuIndex={itemIndex}
            moreAlternateHero={moreAlternateHero}
            showContextualCard={showContextualCard}
          ></SubMenu>
        </ErrorBoundary>
      )}
    </li>
  );
};

export default withTranslation()(React.memo(Menu));
