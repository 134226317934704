/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

export const StatsTransform = (data) => {
  if (data) {
    return {
      cardheading_t: data.CardHeading && data.CardHeading.value,
      competitionname_t: data.CompetitionName && data.CompetitionName.value,
      seasonname_t: data.SeasonName && data.SeasonName.value,
      matchminutes_t: data.MatchMinutes && data.MatchMinutes.value,
      opta_statentities_t:
        data.Opta_StatEntities &&
        data.Opta_StatEntities.value &&
        JSON.parse(data.Opta_StatEntities.value),
      layout_t: data.Layout && data.Layout.value,
      appearance_tdt: data.__Published && data.__Published.value,
      contenttype_t: data.ContentType && data.ContentType.value,
      template_t: data.Template && data.Template.value,
      itemid_s: data.itemid_s,
      destinationurl_s: data.manutds && data.manutds.value && data.manutds.value.DestinationUrl,
      gamename_t: data.GameName && data.GameName.value,
      __published_tdt: data.__Published && data.__Published.value,
      statscapturedat_tdt: data.StatsCapturedAt && data.StatsCapturedAt.value,
      opta_statsscopeid_t: data.Opta_StatsScopeID && data.Opta_StatsScopeID.value,
      opta_statentitytype_t: data.Opta_StatEntityType && data.Opta_StatEntityType.value,
      islive_b: data.IsLive && data.IsLive.value,
      matchperiod_t: data.MatchPeriod && data.MatchPeriod.value,
      matchdate_tdt: data.MatchDate && data.MatchDate.value,
      entitiescount_t: data.EntitiesCount && data.EntitiesCount.value,
      opta_statsscope_t: data.Opta_StatsScope && data.Opta_StatsScope.value,
    };
  } else return null;
};
const round = (inputVal) => {
  if (inputVal >= 1) {
    inputVal = inputVal.toString();
    inputVal = inputVal.split('.');
    let actualVal = parseInt(inputVal[0]);
    if (typeof inputVal[1] != undefined && typeof inputVal[1] != 'undefined') {
      let floatVal = '.' + inputVal[1];
      if (floatVal == 0.5) {
        actualVal = parseInt(inputVal[0]) + 0.5;
      } else if (floatVal < 0.5) {
        actualVal = parseInt(inputVal[0]);
      } else if (floatVal > 0.5) {
        actualVal = parseInt(inputVal[0]) + 1;
      }
    }
    return actualVal;
  } else {
    return parseFloat(inputVal);
  }
};
export const filterDataArray = (data) => {
  if (data && data.opta_statentities_t) {
    let filterEntitiObj = [];
    let statEntArr = data.opta_statentities_t;
    for (let j = 0; j < statEntArr.length; j++) {
      for (let k = 0; k < statEntArr[j].statValues.length; k++) {
        let statsVal = statEntArr[j].statValues[k].Value;
        statsVal =
          statsVal != null && statsVal != undefined && statsVal != 'undefined' && statsVal != ''
            ? statsVal
            : 0;
        statsVal = round(statsVal);
        statEntArr[j].statValues[k].Value = statsVal;
        statEntArr[j].statValues[k].opta_statentities_t = statEntArr[j];
        /* replace filterEntitiObj from object to Array */
        // if (!filterEntitiObj.hasOwnProperty('index_' + k)) {
        //   filterEntitiObj['index_' + k] = new Array();
        //   filterEntitiObj['index_' + k].push(statEntArr[j].statValues[k]);
        // } else if (filterEntitiObj.hasOwnProperty('index_' + k)) {
        //   filterEntitiObj['index_' + k].push(statEntArr[j].statValues[k]);
        // }
        if (typeof filterEntitiObj[k] === 'undefined') {
          filterEntitiObj[k] = new Array();
          filterEntitiObj[k].push(statEntArr[j].statValues[k]);
        } else if (filterEntitiObj[k]) {
          filterEntitiObj[k].push(statEntArr[j].statValues[k]);
        }
      }
    }
    data.filterEntitiObj = filterEntitiObj;
    return data;
  }
};
const percentageBreakDown = (item) => {
  try {
    let optaStateEntityObj = item.opta_statentities_t;
    if (optaStateEntityObj.length > 0) {
      let IsMUEntityClass = 'IsMUEntity-true';
      item.AbbrevTeamName = optaStateEntityObj[0].AbbrevTeamName;
      item.CrestImage = optaStateEntityObj[0].CrestImage;
      item.ID = optaStateEntityObj[0].ID;
      item.IsMUEntity = optaStateEntityObj[0].IsMUEntity;
      item.JerseyNumber = optaStateEntityObj[0].JerseyNumber;
      item.LastName = optaStateEntityObj[0].LastName;
      item.KnownName = optaStateEntityObj[0].KnownName;
      item.Name = optaStateEntityObj[0].Name;
      item.TeamName = optaStateEntityObj[0].TeamName;
      item.percentageValue = optaStateEntityObj[0].statValues[0].Value;
      item.IsPercentage = optaStateEntityObj[0].statValues[0].IsPercentage;
      let homeVal = optaStateEntityObj[0].statValues[0].Value;
      let awayVal = Math.abs(100 - homeVal);
      if (item.opta_statentities_t[0].IsMUEntity) {
        IsMUEntityClass = 'IsMUEntity-true';
      } else {
        IsMUEntityClass = 'IsMUEntity-false';
      }
      if (optaStateEntityObj.length > 1 && optaStateEntityObj[1] != null) {
        if (optaStateEntityObj[1] != null && optaStateEntityObj[1].statValues[0].Value != null) {
          if (!optaStateEntityObj[1].statValues[0].Value) {
            optaStateEntityObj[1].statValues[0].Value = 0;
          }
          awayVal = optaStateEntityObj[1].statValues[0].Value;
        }
      }
      item.percntVal = (homeVal / (homeVal + awayVal)) * 100;
      item.percntVal = round(item.percntVal.toFixed(2));
      item.item = item;
      item.IsMUEntityClass = IsMUEntityClass;
      return item;
    }
  } catch (e) {
    console.log('error in percentage breakdown', e);
  }
};
const headToHead4 = (item) => {
  try {
    let IsMUEntityClass = 'IsMUEntity-true';
    let optaStateEntityObj = item.opta_statentities_t;
    for (let perVal in item.filterEntitiObj) {
      let index_0 = item.filterEntitiObj[perVal][0] ? item.filterEntitiObj[perVal][0].Value : 0;
      let index_1 = item.filterEntitiObj[perVal][1] ? item.filterEntitiObj[perVal][1].Value : 0;
      let calPercentage = (index_0 / (index_0 + index_1)) * 100;
      item.filterEntitiObj[perVal][0].percentVal = calPercentage;
    }
    if (!optaStateEntityObj[0].IsMUEntity) {
      IsMUEntityClass = 'IsMUEntity-false';
    }
    item.IsMUEntityClass = IsMUEntityClass;
    if (item.opta_statentitytype_t.toLowerCase() == 'team') {
      if (item.layout_t.toLowerCase() == 'bar' || item.layout_t.toLowerCase() == 'simple bar') {
        let comVal = Math.random();
        for (let perVal in item.filterEntitiObj) {
          if (comVal != item.filterEntitiObj[perVal][0].SelectionOrder) {
            comVal = item.filterEntitiObj[perVal][0].SelectionOrder;
          } else if (comVal == item.filterEntitiObj[perVal][0].SelectionOrder) {
            comVal = item.filterEntitiObj[perVal][0].SelectionOrder;
            delete item.filterEntitiObj[perVal];
          }
        }
      }
    }
    return item;
  } catch (e) {
    console.log('erron in json head to head 4', e);
  }
};
const top3Line = (item) => {
  return item && item;
};
const headToHeadNumber = (item) => {
  try {
    if (
      item &&
      item.opta_statentities_t &&
      item.opta_statentities_t[0] &&
      item.opta_statentities_t[0].statValues &&
      item.opta_statentities_t[0].statValues.length > 0
    ) {
      let color = { red: '#c70101', gray: '#383838' };
      if (item.opta_statentities_t[0].statValues.length > 3) {
        item.rowsClasses = 'four-rows';
      } else {
        item.rowsClasses = 'less-than-four-rows';
      }
      let calDonut = () => {
        let homeVal = item.opta_statentities_t[0].statValues[0].Value;
        let awayVal = item.opta_statentities_t[1].statValues[0].Value;
        let sum = homeVal + awayVal;
        homeVal = (homeVal / sum) * 100;
        awayVal = (awayVal / sum) * 100;
        item['dashArrHome'] = homeVal + ' ' + (100 - homeVal);
        item['dashArrAway'] = awayVal + ' ' + (100 - awayVal);
        item['dashOffsetHome'] = homeVal + (100 - homeVal) / 2;
        item['dashOffsetAway'] = 100 - item['dashOffsetHome'];
        if (item.opta_statentities_t[0].IsMUEntity) {
          item['homeColor'] = color['red'];
          item['awayColor'] = color['gray'];
        } else {
          item['homeColor'] = color['gray'];
          item['awayColor'] = color['red'];
        }
      };
      if (item.opta_statentitytype_t.toLowerCase() == 'player') {
        if (item.layout_t.toLowerCase() == 'donut') {
          calDonut();
        }
      } else if (item.opta_statentitytype_t.toLowerCase() == 'team') {
        if (item.layout_t.toLowerCase() == 'donut') {
          calDonut();
        }
      }
      return item;
    }
  } catch (e) {
    console.log('error in head to head number [bar or donut] json data ', e);
  }
};
const numberBreakDown = (item) => {
  try {
    let gap = 2,
      defaultPos = 30,
      strokDashOffset,
      sumOfStatsValue = 0,
      max = 0,
      flag = 1,
      color = { red: '#c70101', gray: '#383838' };

    let getCommonData = (item) => {
      let optaStateEntityObj = item.opta_statentities_t;
      let statValues = optaStateEntityObj[0].statValues;
      let colorVal = color['red'];
      if (!item.opta_statentities_t[0].IsMUEntity) {
        colorVal = color['gray'];
      }
      for (let i = 0; i < statValues.length; i++) {
        let homeVal = statValues[i].Value;
        statValues[i].Value = homeVal;

        if (homeVal == 0 && i > 0) {
          flag++;
        }
        if (i > 0) {
          sumOfStatsValue = sumOfStatsValue + homeVal;
        }
      }
      max = sumOfStatsValue;
      for (let i = 0; i < statValues.length; i++) {
        let value = (statValues[i].Value / max) * 100;
        value = value - gap;
        let x = value;
        let y = 100 - x;
        let strokDashArr = x + ' ' + y;
        if (i == 1) {
          strokDashOffset = defaultPos;
        } else if (i > 1) {
          defaultPos = gap + value + defaultPos;
          strokDashOffset = defaultPos;
        }
        if (flag == statValues.length - 1) {
          strokDashOffset = 0;
          strokDashArr = 100;
        }
        let percentVal = (statValues[i].Value / max) * 100;
        statValues[i].percentVal = percentVal;
        statValues[i].strokDashArr = strokDashArr;
        statValues[i].strokDashOffset = strokDashOffset;
        statValues[i].gap = gap;
        statValues[i].sumOfStatsValue = sumOfStatsValue;
        statValues[i].colorVal = colorVal;
        statValues[i].max = max;
      }
    };
    if (item.opta_statentitytype_t.toLowerCase() == 'player') {
      if (item.layout_t.toLowerCase() == 'line') {
        getCommonData(item);
      }
    } else if (item.opta_statentitytype_t.toLowerCase() == 'team') {
      if (item.layout_t.toLowerCase() == 'line') {
        getCommonData(item);
      }
    }
    return item;
  } catch (e) {
    console.log('erron in top 3 json data', e);
  }
};
const singleStats = (item) => {
  try {
    let getCommonData = (item) => {
      let optaStateEntityObj = item.opta_statentities_t;
      let IsMUEntityClass = 'IsMUEntity-true';
      let homeVal = optaStateEntityObj[0].statValues[0].Value;
      let awayVal = Math.abs(100 - homeVal);
      if (item.opta_statentities_t[0].IsMUEntity) {
        IsMUEntityClass = 'IsMUEntity-true';
      } else {
        IsMUEntityClass = 'IsMUEntity-false';
      }
      if (optaStateEntityObj.length > 1 && optaStateEntityObj[1] != null) {
        if (optaStateEntityObj[1] != null && optaStateEntityObj[1].statValues[0].Value != null) {
          if (!optaStateEntityObj[1].statValues[0].Value) {
            optaStateEntityObj[1].statValues[0].Value = 0;
          }
          awayVal = optaStateEntityObj[1].statValues[0].Value;
        }
      }
      item.percntVal = (homeVal / (homeVal + awayVal)) * 100;
      item.IsMUEntityClass = IsMUEntityClass;
      item.homeVal = homeVal;
      item.awayVal = awayVal;
      item.IsPercentage = optaStateEntityObj[0].statValues[0].IsPercentage;
    };
    if (item.opta_statentitytype_t.toLowerCase() == 'player') {
      if (item.layout_t.toLowerCase() == 'full') {
        getCommonData(item);
      }
    } else if (item.opta_statentitytype_t.toLowerCase() == 'team') {
      if (item.layout_t.toLowerCase() == 'full') {
        getCommonData(item);
      }
    }
    return item;
  } catch (e) {
    console.log('error in Single Stat (2) json data', e);
  }
};
const playerVteam = (item) => {
  return item && item;
};
export const renderStatsView = (item) => {
  if (item) {
    let template_t = item.template_t && item.template_t.toLowerCase();
    if (template_t == 'percentage breakdown') {
      return percentageBreakDown(item);
    } else if (template_t == 'head to head 4') {
      return headToHead4(item);
    } else if (template_t == 'top 3' || template_t == 'single stat (3)') {
      return top3Line(item);
    } else if (template_t == 'head to head number') {
      return headToHeadNumber(item);
    } else if (template_t == 'head to head percentage') {
      return headToHeadNumber(item);
    } else if (template_t == 'number breakdown') {
      return numberBreakDown(item);
    } else if (template_t == 'single stat (2)' || template_t == 'single stat') {
      return singleStats(item);
    } else if (template_t == 'player v team') {
      return playerVteam(item);
    }
  }
  return item;
};
export const addColorClass = (itemData) => {
  if (
    itemData &&
    itemData.opta_statentities_t &&
    itemData.opta_statentities_t.length > 0 &&
    itemData.hasOwnProperty('opta_statentities_t')
  ) {
    if (itemData.opta_statentities_t[0].IsMUEntity) {
      return 'red-line';
    } else {
      return 'gray-line';
    }
  } else {
    if (itemData.IsMUEntity) {
      return 'red-line';
    } else {
      return 'gray-line';
    }
  }
};
