/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import MU from '../../constants/muConstants';
import * as track from '../../utils/analytics';
const LanguageItems = ({
  languageItem,
  totalLength,
  activeIndex,
  setIsListOpen,
  humbergerLangIndex,
  isHumbergerLang,
}) => {
  const { manutds, Iso } = languageItem && languageItem.fields;
  /**
   *
   * @param {current language from MU} lang
   */
  const createUrl = (lang) => {
    return MU && `${MU.assetUrlResource}/${lang}`;
  };
  /**
   *
   * @param {clicked event object} e
   */
  const trackLink = (e) => {
    const initialData = track.data('link');
    track.analytics(
      {
        ...initialData,
        button_name: manutds && manutds.value && manutds.value.LanguageDisplayName,
        destination_url: createUrl(Iso && Iso.value),
      },
      e
    );
  };
  const activeList = (e) => {
    const code = e.keyCode || e.which;
    const listItems = e.target && e.target.parentElement.parentElement.querySelectorAll('li');
    if (isHumbergerLang) {
      if (humbergerLangIndex === totalLength - 1) {
        e.preventDefault();
        document.querySelector('nav .language__dropdown a').focus();
      }
    } else if (e && e.shiftKey && code === 9 && activeIndex === 0) {
      setIsListOpen(false);
    } else if (e.shiftKey && code === 9) {
      if (activeIndex === totalLength - 1) {
        setIsListOpen(true);
      }
    } else if (code === 9) {
      if (activeIndex === totalLength - 1) {
        setIsListOpen(false);
      }
    } else if (code === 13) {
      e.target.querySelector('a').click();
    } else if (code === 38) {
      e.preventDefault();
      const focusEl = activeIndex - 1;
      listItems && listItems[focusEl] && listItems[focusEl].focus();
    } else if (code === 40) {
      e.preventDefault();
      const focusEl = activeIndex + 1;
      listItems && listItems[focusEl] && listItems[focusEl].focus();
    } else if (code === 27) {
      setIsListOpen(false);
      const elm = document.querySelector('.home-dropdown .accessible-dropdown');
      elm && elm.focus();
    }
  };

  return (
    <li tabIndex="0" role="option" lang={Iso.value} onKeyDown={(e) => activeList(e)}>
      <a tabIndex="-1" href={createUrl(Iso && Iso.value)} onClick={(e) => trackLink(e)}>
        {manutds && manutds.value && manutds.value.LanguageDisplayName}
      </a>
    </li>
  );
};

export default withTranslation()(React.memo(LanguageItems));
