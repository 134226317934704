/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React, { createContext, useContext, useReducer } from 'react';
import { searchState, searchReducer } from '../reducers/searchReducer';

export const SearchContext = createContext();

export const SearchProvider = ({ children }) => (
  <SearchContext.Provider value={useReducer(searchReducer, searchState)}>
    {children}
  </SearchContext.Provider>
);
export const useSearchValue = () => useContext(SearchContext);

export default SearchContext;
