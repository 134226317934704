/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { createBaseUrl, getLanguage, isTeamViewer } from '../../utils/utils';
import MU from '../../constants/muConstants';
import AMPNavigation from './AMPNavigation';
import { isEmpty } from 'lodash';
import { getSponsorDataFromFields } from '../../utils/articleUtils';

const AMPHeader = (props) => {
  const {
    Logo,
    SponsorBy,
    HeaderIT,
    Sponsors,
    HeaderSponsors,
    HamburgerNavigationLinks,
    AllNav,
  } = props.fields;

  const getUrl = () => {
    return `${createBaseUrl()}/${getLanguage()}`;
  };

  let sponsorLink = '';
  let sponsorLinkTarget = '';
  let sponsorImage = '';
  let sponsorName = '';
  let sponsorText = '';
  let sponsorObj = {};

  if (SponsorBy && SponsorBy.fields) {
    sponsorObj = getSponsorDataFromFields(SponsorBy.fields);
  } else if (HeaderIT && HeaderIT.length > 0) {
    sponsorObj = HeaderIT && HeaderIT[0];
  }

  let { CTAURL, CTATarget, PartnerIconLight, partnerName, partnerText } = sponsorObj;

  sponsorImage = PartnerIconLight && PartnerIconLight;
  sponsorText = partnerText && partnerText;
  sponsorName = partnerName && partnerName;
  sponsorLink = CTAURL && CTAURL;
  sponsorLinkTarget = CTATarget && CTATarget;

  const headerBottomSponsors = HeaderSponsors || Sponsors;
  return (
    <React.Fragment>
      <header className="headerbar">
        <div className="top-container">
          <a href={getUrl()}>
            {Logo && Logo.value && Logo.value.Preview && Logo.value.Preview.length > 0 && (
              <amp-img
                src={Logo.value.Preview}
                height="46"
                width="46"
                class="mu-logo"
                alt={Logo && Logo.value && Logo.value.AltText}
              ></amp-img>
            )}
          </a>

          <div className="home-header__sponsor">
            {!isEmpty(sponsorImage) > 0 && (
              <a
                href={sponsorLink}
                target={sponsorLinkTarget || '_self'}
                aria-label="powered by HCL Technologies"
              >
                <span className="sponsor-txt">{sponsorText}</span>
                <amp-img src={sponsorImage} height="8" width="58" alt={sponsorName}></amp-img>
              </a>
            )}
          </div>
        </div>
        <div className="bottom-container">
          <div
            role="button"
            on="tap:sidebar1.toggle"
            tabIndex="0"
            className="hamburger"
            aria-label="menu"
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="bottom-sponsor">
            <ul>
              {headerBottomSponsors &&
                headerBottomSponsors.length > 0 &&
                headerBottomSponsors.map((header, i) => {
                  const headerObj = getSponsorDataFromFields(header && header.fields);
                  if (headerObj && headerObj.IsActive) {
                    return (
                      <li key={`bottom-sponsor${i}`}>
                        <a
                          href={headerObj && headerObj.CTAURL}
                          target={(headerObj && headerObj.CTATarget) || '_self'}
                        >
                          {headerObj &&
                            headerObj.PartnerIconLight &&
                            headerObj.PartnerIconLight.length > 0 && (
                              <amp-img
                                src={headerObj && headerObj.PartnerIconLight}
                                height="26"
                                width={isTeamViewer(headerObj.partnerName) ? '90' : '48'}
                                alt={headerObj && headerObj.partnerName}
                                noloading
                              >
                                <span className=" screenreader">External Website</span>
                              </amp-img>
                            )}
                        </a>
                      </li>
                    );
                  }
                })}
            </ul>
          </div>
        </div>
      </header>
      <amp-sidebar
        id="sidebar1"
        layout="nodisplay"
        side={MU && MU.language && MU.language === 'ar' ? 'right' : 'left'}
      >
        <div
          role="button"
          aria-label="close sidebar"
          on="tap:sidebar1.toggle"
          tabIndex="0"
          className="close-sidebar"
        >
          X
        </div>
        <AMPNavigation navLinks={HamburgerNavigationLinks} allNav={AllNav}></AMPNavigation>
      </amp-sidebar>
    </React.Fragment>
  );
};
export default AMPHeader;
