/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import React from 'react';
import { withTranslation } from 'react-i18next';
import LeftPanel from './LeftPanel';
import StatsBar from '../Common/StatsBar';
import SponsorPanel from './SponsorPanel';
import ImagePanel from './ImagePanel';
import { getStatsBarData } from '../../utils/utils';
import ErrorBoundary from '../Common/ErrorBoundary';
const HeadToHeadNumber = ({ item, gridClass, t, carousalClass }) => {
  const isPlayer =
    item && item.opta_statentitytype_t && item.opta_statentitytype_t.toLowerCase() === 'player'
      ? true
      : false;
  return (
    <div className={gridClass}>
      <div className={carousalClass}>
        <div
          className={`stats-card head-to-head-number ${item.IsMUEntityClass} ${
            isPlayer ? 'player-section' : 'team-section'
          }`}
        >
          <SponsorPanel item={item} />
          <div className="stats-card__info">
            <LeftPanel item={item} />
            <div className="stats-card__info__right">
              <div className="chart-area">
                <div className="players">
                  {!isPlayer && (
                    <div className="screenreader">
                      {`${item.opta_statentities_t[0].TeamName} ${t('VS')} ${
                        item.opta_statentities_t[1].TeamName
                      }`}
                    </div>
                  )}
                  <div
                    className={`players-vs-player ${isPlayer ? 'player-only' : 'only-team-img'}`}
                  >
                    {isPlayer && (
                      <div className="screenreader">
                        {`${
                          item.opta_statentities_t[0].KnownName
                            ? item.opta_statentities_t[0].KnownName
                            : item.opta_statentities_t[0].LastName
                        } ${t('Shirtnumber')} ${item.opta_statentities_t[0].JerseyNumber} ${t(
                          'VS'
                        )} ${
                          item.opta_statentities_t[1].KnownName
                            ? item.opta_statentities_t[1].KnownName
                            : item.opta_statentities_t[1].LastName
                        } ${t('Shirtnumber')} ${item.opta_statentities_t[1].JerseyNumber}`}
                      </div>
                    )}
                    <div className="player__host" aria-hidden="true">
                      <div className={`player-img ${isPlayer ? 'player-image' : 'team-image'}`}>
                        <ErrorBoundary>
                          <ImagePanel item={item.opta_statentities_t[0]} statsType={isPlayer} />
                        </ErrorBoundary>
                        {isPlayer && (
                          <React.Fragment>
                            <span className="notification">
                              {item.opta_statentities_t[0].JerseyNumber}
                            </span>
                            <h3>
                              {item.opta_statentities_t[0].KnownName
                                ? item.opta_statentities_t[0].KnownName
                                : item.opta_statentities_t[0].LastName}
                            </h3>{' '}
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                    <div className="vs" aria-hidden="true">
                      <div>
                        <span>{t('VS')}</span>
                      </div>
                    </div>
                    <div className="player__opponent" aria-hidden="true">
                      <div className={`player-img ${isPlayer ? 'player-image' : 'team-image'}`}>
                        <ErrorBoundary>
                          <ImagePanel item={item.opta_statentities_t[1]} statsType={isPlayer} />
                        </ErrorBoundary>
                        {isPlayer && (
                          <React.Fragment>
                            <span className="notification">
                              {item.opta_statentities_t[1].JerseyNumber}
                            </span>
                            <h3>
                              {item.opta_statentities_t[1].KnownName
                                ? item.opta_statentities_t[1].KnownName
                                : item.opta_statentities_t[1].LastName}
                            </h3>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="players__match-details {{rowsclasses}}">
                    <ul>
                      {item.filterEntitiObj.map((keyObj, key) => (
                        <li key={key}>
                          <span className="scoreA" aria-hidden="true">{`${keyObj[0].Value}${
                            keyObj[0].IsPercentage === 'true' || keyObj[0].IsPercentage === true
                              ? '%'
                              : ''
                          }`}</span>
                          <span className="score-info" aria-hidden="true">
                            {keyObj[0].StatsName}
                          </span>
                          <span className="scoreB" aria-hidden="true">{`${keyObj[1].Value}${
                            keyObj[1].IsPercentage === 'true' || keyObj[1].IsPercentage === true
                              ? '%'
                              : ''
                          }`}</span>
                          <div className="screenreader">
                            {isPlayer
                              ? `${keyObj[0].Value} ${
                                  keyObj[0].IsPercentage === 'true' ||
                                  keyObj[0].IsPercentage === true
                                    ? '%'
                                    : ''
                                } ${keyObj[0].StatsName} ${t('by')} ${
                                  item.opta_statentities_t[0].KnownName
                                    ? item.opta_statentities_t[0].KnownName
                                    : item.opta_statentities_t[0].LastName
                                }
                            ${keyObj[1].Value} ${
                                  keyObj[1].IsPercentage === 'true' ||
                                  keyObj[1].IsPercentage === true
                                    ? '%'
                                    : ''
                                } ${keyObj[1].StatsName} ${t('by')} ${
                                  item.opta_statentities_t[1].KnownName
                                    ? item.opta_statentities_t[1].KnownName
                                    : item.opta_statentities_t[1].LastName
                                }`
                              : `${keyObj[0].Value} ${
                                  keyObj[0].IsPercentage === 'true' ||
                                  keyObj[0].IsPercentage === true
                                    ? '%'
                                    : ''
                                } ${keyObj[0].StatsName} ${t('by')} ${
                                  item.opta_statentities_t[0].TeamName
                                } 
                               ${keyObj[1].Value} ${
                                  keyObj[1].IsPercentage === 'true' ||
                                  keyObj[1].IsPercentage === true
                                    ? '%'
                                    : ''
                                } ${keyObj[1].StatsName} ${t('by')} ${
                                  item.opta_statentities_t[1].TeamName
                                }`}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <StatsBar item={getStatsBarData(item)} type="statsCard" />
        </div>
      </div>
    </div>
  );
};
export default withTranslation()(HeadToHeadNumber);
