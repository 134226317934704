/**
 *  © 2015 -2022 HCL Technologies Limited, all rights reserved.
 *  Material published by HCL Technologies on these web pages/mobile
 *  app may not be reproduced without permission.
 */

import {
  FETCH_NEWS_VIDEO_LIST,
  SET_NEWS_VIDEO_LIST,
  ERROR_NEWS_VIDEO_LIST,
} from '../constants/actions/actionConstant';
import { NEWS_LISTING, VIDEO_LISTING } from '../constants/endpoints';
import api from '../utils/muApi';
import MU from '../constants/muConstants';

/**
 * Action for both news & video listing
 */
export const fetchListing = (dispatch, startIndex, itemsPerPage, actionType, listing, filter) => {
  let listingFilter = null;
  if (listing === 'news') {
    listingFilter = NEWS_LISTING;
  } else if (listing === 'video') {
    listingFilter = VIDEO_LISTING;
  }
  if (!filter) {
    filter = 'all';
  }
  const route = `${listingFilter}${filter}/${startIndex}/${itemsPerPage}`;
  let params = {};
  if (MU && MU.language && MU.language.toLowerCase() === 'en') {
    params = { enableLatestMenu: true };
  }
  dispatch(fetchList(actionType));
  return api
    .get({ route, params })
    .then((response) => {
      dispatch(setList(response, actionType));
    })
    .catch((err) => {
      dispatch(error(err));
    });
};

export const fetchList = (actionType) => ({ type: FETCH_NEWS_VIDEO_LIST, actionType });

export const setList = (response, actionType) => ({
  type: SET_NEWS_VIDEO_LIST,
  response,
  actionType,
});

export const error = (response) => ({ type: ERROR_NEWS_VIDEO_LIST, response });
